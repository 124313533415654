import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpGuidService } from '@core/services/mp-guid.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { TcSearchParamsService } from './../../../../services/tc-search-params.service';

/**
 * This class provides the functionalities
 * for the travel activities redirect page.
 */
@Component({
  selector: 'mp-rk-travel-activity-redirect',
  templateUrl: './travel-activity-redirect.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TravelActivityRedirectComponent implements OnInit, OnDestroy {

  private _role: string = '';
  private _getActivitySubscription: Subscription | undefined;
  private _routeParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _svgLoader: SvgLoaderService,
    private _mpGuid: MpGuidService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _location: Location
  ) { }

  /**
   * Gets the activity and redirects
   * to it.
   */
  ngOnInit(): void {
    this._svgLoader.startLoading();

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = '/' + window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = '/' + this._roleMapping.getReverseMappedRole(role);
    }

    if (this._role === 'Reiseberichte') {
      this._role = '';
    }

    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._getActivitySubscription = this._apiService.getRequest(`/api/RkAktion/GetAktion?id=${params['aid']}`).subscribe((data: any) => {
        const activity = data.Records[0];
        const parameter = JSON.parse(activity);
        parameter['Aktionscode'] = activity['Aktionscode'];
        parameter['SearchId'] = this._mpGuid.newGuid();
        this._tcSearchParamsService.setParams(parameter);
        this._location.replaceState('');

        if (parameter['Reiseart'] === 'NurFlug') {
          this._router.navigateByUrl(`${this._role}/FlugAuswahl?sid=${parameter['SearchId']}`);
        } else {
          this._router.navigateByUrl(`${this._role}/HotelAuswahl?sid=${parameter['SearchId']}`);
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getActivitySubscription !== 'undefined') {
      this._getActivitySubscription.unsubscribe();
    }

    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }
  }

}
