import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

interface LocsData extends Record<string, any> {
  [key: string]: any
}

/**
 * This class provides the functions to fetch the
 * localuzation data.
 */
@Injectable({
  providedIn: 'root'
})
export class MpLocalizationService {

  private _locsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public locs: LocsData = {};
  public locsLoaded: Observable<boolean> = this._locsLoaded.asObservable();

  constructor(private _apiService: ApiService) { }

  /**
   * Calls the api to get the localization data.
   */
  getLocalization(): void {
    this._apiService.getRequest('/api/Language/getLocs/').subscribe((data: any) => {
      data.Records.forEach((record: any) => {
        this.locs = Object.assign(this.locs, {
          [record.Name]: record.Value
        });
      });

      this._locsLoaded.next(true);
    },
    (error: any) => {
      console.log(error.message);
      });
  }
}
