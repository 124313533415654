import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This class provides functions
 * for the inbox.
 */
@Injectable({
  providedIn: 'root'
})
export class InboxService {

  public activeItem: { [key: string]: any } = {};

  private _parentCickFunctionTriggered: BehaviorSubject<[any, string]> = new BehaviorSubject<[any, string]>([null, '']);
  public parentCickFunctionTriggered: Observable<Array<any>> = this._parentCickFunctionTriggered.asObservable();

  constructor() { }

  /**
   * Sets the active item.
   */
  setActiveItem(item: { [key: string]: any }): void {
    if (this.activeItem !== item) {
      this.activeItem['showChild'] = false;
    }

    this.activeItem = item;
  }

  /**
   * Can be used to trigger a
   * function of the parent
   * component within a child
   * component (component outlet);
   */
  triggerParentClickFunction(item: any, funcName: string): void {
    this._parentCickFunctionTriggered.next([item, funcName]);
  }
}
