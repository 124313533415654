<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].LoginStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].LoginStatistik }}"
                 infotextShorten="LoginStatistik" 
                 fieldsAction="/api/Statistik/getLoginStatistikFields" 
                 listAction="/api/Statistik/getLoginStatistik" 
                 loaderName="loginStatisticLoader"
                 [paging]="true"
                 [footer]="false"
                 [recLoadedFunc]="recLoadedFunc"
                 defaultSorting="Datum DESC"
                 [showDatePicker]="true"
                 [alwaysShowFilter]="true" 
                 [disableCache]="true"></mp-core-data-table>
    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a href="{{excelService}}?{{loader.getExcelParams()}}">
        <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
      </a>
    </div>
</div>

<div class="ui-helper-clearfix"></div>
