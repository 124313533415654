import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';

import shave from 'shave';

/**
 * This class provides the functionalities
 * to shave a text.
 */
@Directive({
  selector: '[mpCoreMpShave]'
})
export class MpShaveDirective implements OnChanges {

  @Input() public shaveHeight: number = -1;
  @Input() public binding: string = '';

  constructor(
    private _elementRef: ElementRef
  ) { }

  /**
   * Watches for changes of the shave height
   * or the bound content.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['binding'] !== 'undefined' && this.binding !== '') {
      this._doShave();
    }

    if (typeof changes['shaveHeight'] !== 'undefined' && this.shaveHeight !== -1) {
      this._doShave();
    }
  }

  /**
   * Does the shave.
   */
  private _doShave(): void {
    const elem = this._elementRef.nativeElement;

    const contentAvailableInterval = setInterval(() => {
      if (elem.innerHTML !== '') {
        clearInterval(contentAvailableInterval);
        shave(`#${elem.id}`, this.shaveHeight);
      }
    }, 50);
  }

}
