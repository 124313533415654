<div themesStagesAnimations #themesStagesAnimationsRef="themesStagesAnimationsRef" class="stage-container" *ngIf="(mpSettings.settingsLoaded | async) && themeStage.length > 0">
  <div class="stage"
       *ngIf="themesStagesAnimationsRef.owlPropertiesSet"
       [hidden]="showDetails">
    <owl-carousel-o #owlElem id="home-carousel"
                    class="owl-theme"
                    owl-items="themeStage"
                    [options]="themesStagesAnimationsRef.owlProperties"
                    (translated)="themesStagesAnimationsRef.onTranslated()"
                    (initialized)="themesStagesAnimationsRef.onInitialized(owlElem)">
      <ng-container class="item" *ngFor="let item of themeStage">
        <ng-template carouselSlide [width]="0" style="width: auto;" [id]="'stage-item-id-'+item.Id">
          <div class="owl-inner buehne-{{item.Id}}">
            <img mpCoreStageImageLoaded
                 id="{{item.Id}}"
                 [src]="item.ImagePath + '?width=' + mpSettings.settings['DisplaySettings'].BuehnenBildHoehe + '&height=' + mpSettings.settings['DisplaySettings'].BuehnenBildBreite + '&mode=crop'"
                 alt="" />
            <div class="caption"
                 [ngClass]="[item.CssClass ? item.CssClass : '', !item.Teaser && !item.Button1Bezeichnung && !item.Button2Bezeichnung ? '_only-title' : '']"
                 *ngIf="item.Titel">
              <div>
                <h1 [innerHTML]="trustAsHtml(item.Titel)"></h1>
              </div>
              <span [hidden]="!item.Teaser" [innerHTML]="trustAsHtml(item.Teaser)" [ngClass]="[!item.Button1Bezeichnung  && !item.Button2Bezeichnung ? '_no-buttons-following' : '']"></span>
              <div class="read-more-buttons">
                <div class="mp-button _prim button-large read-more"
                     [hidden]="item.Button1Bezeichnung.length === 0 || item.Button1TypId === 1 || item.Button1TypId === 2 || item.Button1TypId === 7">
                  {{item.Button1Bezeichnung}}
                </div>
                <div class="mp-button _prim button-large read-more"
                     [hidden]="item.Button2Bezeichnung.length === 0 || item.Button2TypId === 1 || item.Button2TypId === 2 || item.Button2TypId === 7">
                  {{item.Button2Bezeichnung}}
                </div>
                <a class="mp-button _prim button-large read-more"
                   [hidden]="item.Button1Bezeichnung.length === 0 || (item.Button1TypId !== 2 && item.Button1TypId !== 7)"
                   href="{{item.Button1LinkURL | angularjsToAnuglarUrl}}"
                   target="{{item.Button1InNeuemTab ? '_blank' : '_self'}}">
                  {{item.Button1Bezeichnung}}
                </a>
                <a class="mp-button _prim button-large read-more"
                   [hidden]="item.Button2Bezeichnung.length === 0 || (item.Button2TypId !== 2 && item.Button2TypId !== 7)"
                   href="{{item.Button2LinkURL | angularjsToAnuglarUrl}}"
                   target="{{item.Button2InNeuemTab ? '_blank' : '_self'}}">
                  {{item.Button2Bezeichnung}}
                </a>
                <div class="mp-button _prim button-large read-more"
                     [hidden]="item.Button1Bezeichnung.length === 0 || item.Button1TypId !== 1"
                     (click)="toggleShowDetails()">
                  {{item.Button1Bezeichnung}}
                </div>
                <div class="mp-button _prim button-large read-more"
                     [hidden]="item.Button2Bezeichnung.length === 0 || item.Button2TypId !== 1"
                     (click)="toggleShowDetails()">
                  {{item.Button2Bezeichnung}}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="layout-container"
       [hidden]="!showDetails">
    <div>
      <mp-core-detail-page-content imgSrc="{{themeStage[0].ImagePath}}?width={{mpSettings.settings['DisplaySettings'].BuehnenBildHoehe}}&height={{mpSettings.settings['DisplaySettings'].BuehnenBildBreite}}&mode=crop"
                            [title]="trustAsHtml(themeStage[0].Titel)"
                            [text]="trustAsHtml(themeStage[0].Text || themeStage[0].Teaser)">
      </mp-core-detail-page-content>
    </div>
  </div>
</div>
