import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { MpTcPermissionsService } from './../../services/mp-tc-permissions.service';

import { TcPermissions } from './../../services/interfaces/tc-permissions';

/**
 * This class provides the functions
 * for the flight card.
 */
@Component({
  selector: 'mp-rk-flight-card',
  templateUrl: './flight-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FlightCardComponent implements OnInit, OnDestroy {

  @Input() public flight: { [key: string]: any } = {};
  @Input() public showDetails: boolean = false;
  @Input() public setOpenedOffersFn: any;
  @Input() public calculateDurationFn: any;
  @Input() public searchId: string = '';

  @Output() showDetailsChange = new EventEmitter<boolean>();

  public permissions: TcPermissions = {
    CanUseReisekonfigurator: false
  };
  public role: string = '';

  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _permissionService: MpTcPermissionsService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService
  ) { }

  /**
   * Gets the travel configurator permissions,
   * and the role.
   */
  ngOnInit(): void {
    if (typeof this.flight['open'] === 'undefined' || this.flight['open'] === null) {
      this.flight['open'] = false;
    }

    if (!this.flight['SelectedHinflug']) {
      this.flight['SelectedHinflug'] = this.flight['Hinfluege'][0];
    }

    if (!this.flight['SelectedRueckflug']) {
      this.flight['SelectedRueckflug'] = this.flight['Rueckfluege'][0];
    }

    if (this._permissionService.permissionsReadyLoaded) {
      this.permissions = this._permissionService.permissions;

      if (typeof this._getPermissionsSubscription !== 'undefined') {
        this._getPermissionsSubscription.unsubscribe();
      }
    } else {
      this._getPermissionsSubscription = this._permissionService.permissionsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.permissions = this._permissionService.permissions;

          if (typeof this._getPermissionsSubscription !== 'undefined') {
            this._getPermissionsSubscription.unsubscribe();
          }
        }
      });

      this._permissionService.getPermissions();
    }

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the setOpenedOffers of the
   * parent component.
   */
  setOpenedOffers(): void {
    if (typeof this.setOpenedOffersFn !== 'undefined') {
      this.setOpenedOffersFn();
    }
  }

  /**
   * Triggers the calculateDuration of the
   * parent component.
   */
  calculateDuration(flight: any): void {
    if (typeof this.calculateDurationFn !== 'undefined') {
      this.calculateDurationFn(flight);
    }
  }

  /**
   * Returns the object keys
   * forthe given object.
   */
  objectKeys(obj: { [key: string]: any }): Array<string> {
    return Object.keys(obj);
  }

}
