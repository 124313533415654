import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { ParticipantCockpitService } from './../participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the current score
 * table.
 */
@Component({
  selector: 'mp-core-current-score',
  templateUrl: './current-score.component.html'
})
export class CurrentScoreComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Statistik/AktuellerPunktestand2Excel';
  public loader: MpLoader | null = null;
  public enddate: Date = new Date();
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    }
  ];

  private _currentScoreTableLoader: MpLoader = {
    name: 'currentScoreTableLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _participantCockpitService: ParticipantCockpitService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('AktuellerPunktestand');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._currentScoreTableLoader);

    this._mpLoaderService.extendLoader('currentScoreTableLoader', {
      refillExtParamsFunc: (params: any) => { },
      isReady: true,
      params: {
        EndDate: this.enddate
      }
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('currentScoreTableLoader');
  }

  /**
   * Triggered by change in datepicker,
   * and updates the enddate.
   */
  updateEnddate(): void {
    //this._recLoadedFunc();
    this._recLoadedFunc.bind(this);
    if (this.loader !== null && typeof this.loader.params !== 'undefined' && typeof this.loader.load !== 'undefined') {
    
      this.loader.params['EndDate'] = this.enddate;
      this.loader.load();
    }
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['TnID']);
  }

}
