<div id="sidebar-calendar-flight" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)"
     class="rk-sidebar-form">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="texst-align-left">
      {{ ls.locs['locReisekonfigurator'].AnUndAbreiseSidebarHeader }}
    </div>
    <div class="divider margin-left-16 margin-bottom-8"></div>
    <mp-core-select *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].AllowOneWay" label="{{ ls.locs['locReisekonfigurator'].Flug }}"
               mpId="rkFlugart"
               [(model)]="selection['Flugart']"
               [elements]="mpSidebar.params['flugOptions']"
               textProperty="Text"
               class="padding-left-right-16 margin-left-0 box-sizing-border-box">

    </mp-core-select>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex"
         id="calendar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': (selection['rangeStart']  || (selection['Flugart'] && selection['Flugart'].id === 1)) }"
           (click)="setCurrentSelection('start')">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['locReisekonfigurator'].Anreise }}</span>
        <div *ngIf="selection['rangeStart']">
          <span class="font-size-xs"
                [innerText]="selection['rangeStart'].format('dddd')">
          </span>
          <span [innerText]="selection['rangeStart'].format('L')"></span>
        </div>
      </div>
      <div [hidden]="selection['Flugart'].id !== 0 "
           class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeEnd'] && selection['rangeEnd'] != selection['rangeStart'] && !(selection['Flugart'] && selection['Flugart'].id === 1) }"
           (click)="setCurrentSelection('end')">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['locReisekonfigurator'].Abreise }}</span>
        <div *ngIf="selection['rangeEnd']">
          <span class="font-size-xs"
                [innerText]="selection['rangeEnd'].format('dddd')">
          </span>
          <span [innerText]="selection['rangeEnd'].format('L')"></span>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#flugzeiten-scroll-area"
                   scrollDirection="up"
                   desktopItemHeight=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="flugzeiten-scroll-area-container">
      <div id="flugzeiten-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="mpSidebar.params['minDate']"
                    [(rangeStart)]="selection['rangeStart']"
                    [(rangeEnd)]="selection['rangeEnd']"
                    [isRange]="!selection['Flugart'].id"
                    [minRange]="0"
                    [(maxRange)]="maxRange"
                    [defaultRange]="0"
                    [(currentSelection)]="selection['currentSelection']">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#flugzeiten-scroll-area"
                   scrollDirection="down"
                   desktopItemHeight=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         [ngClass]="{disabled: !(selection['rangeStart'] && selection['rangeEnd'])}"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}
      </a>
    </div>
  </div>
</div>
