import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';

/**
 * This class provides a simple masked-input, that
 * can be configured with different input attributes
 * (e.g. for required fields, for optional fields, ...).
 */
@Component({
  selector: 'mp-core-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss']
})
export class MaskedInputComponent implements OnInit {
  @Input() public label: string = '';
  @Input() public mask: string = '';
  @Input() public model: string = '';
  @Input() public mpId: string = '';
  @Input() public mpType: string = '';
  @Input() public mpRequired: boolean = false;
  @Input() public mpDisabled: boolean = false;
  @Input() public keyup: Function | undefined;
  @Input() public focusOn: boolean = false;
  @Input() public mpPlaceholder: string = '';
  @Input() public mpEnter: Function | undefined;
  @Input() public borderline: any;
  @Input() public mpOptional: boolean = false;
  @Input() public hasError: boolean = false;

  errClass: string = '';
  public labelText: string = '';

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputBlur: EventEmitter<object> = new EventEmitter<object>();
  @Output() inputChange: EventEmitter<object> = new EventEmitter<object>();
  @Output() inputFocus: EventEmitter<object> = new EventEmitter<object>();

  constructor(public ls: MpLocalizationService) { }

  ngOnInit(): void {
    this.errClass = '';
  }

  /**
   * A lifecycle hook that is called when a data-bound property of a hasError.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['hasError'] !== 'undefined' && typeof changes['hasError'].currentValue !== 'undefined') {
      () => {
        if (changes['hasError'].currentValue) {
          this.errClass = 'invalid-error';
        } else {
          this.errClass = '';
        }
      };
    }
  }

  /**
   * Listens to keyup event and executes the given
   * functions for mpEnter and / or keyup.
   */
  executeKeyup(event: any): void {
    if (event.keyCode === 13 && this.mpEnter) {
      this.mpEnter();
    }
    if (this.keyup) {
      this.keyup();
    }
  }

  /**
  * Listens to blur event and
  * emits those events back to the parent
  * component.
  */
  executeEvent(event: object): void {
    if(event)
      this.inputBlur.emit(event);
  }

  /**
   * Triggers the modelChange event.
   */
  modelChanged(): void {
    this.modelChange.emit(this.model);
  }

}
