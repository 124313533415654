import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '../../services/mp-localization.service';
import { CookieService } from '../../services/cookie.service';
import { Subscription } from 'rxjs';

/**
 * This class handles the notification of used
 * cookies, that is shown to the user (if cookies
 * are not already set).
 */
@Component({
  selector: 'mp-core-cookie-overlay',
  templateUrl: './cookie-overlay.component.html',
  styleUrls: ['./cookie-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookieOverlayComponent implements OnInit, OnDestroy {
  @Input() public cookie: string = '';
  @Output() public showChange = new EventEmitter<boolean>();

  public showContent: boolean = false;
  private _cookieUpdateSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this._checkConsent();

    this._cookieUpdateSubscription = this.cookieService.cookiesUpdated.subscribe(() => this._checkConsent());
  }

  ngOnDestroy(): void {
    this._cookieUpdateSubscription?.unsubscribe();
  }

  acceptCookie(): void {
    this.cookieService.acceptCookie(this.cookie).then(_ => {
      this._checkConsent();
    });
  }

  private _checkConsent() {
    this.cookieService.canSetCookie(this.cookie).then(canSet => {
      this.showContent = canSet;
      this.showChange.emit(this.showContent);
    });
  }

}
