import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from './../..//services/api.service';

@Injectable({
  providedIn: 'root'
})

/**
 * The class describes updateting menu after changes
 */
export class MpMenuRefreshService {

  public menuData: any = null;

  isMenuLoaded$ = new BehaviorSubject<boolean>(false);
  menuData$ = new BehaviorSubject<any>(this.menuData);

  constructor(private _apiService: ApiService) { }

  /**
   *  Updates menu after changes
   */
  loadMenuData(force: boolean): Observable<any> {

      if (!this.menuData || force) {
        this._apiService.getRequest('/api/Teilnehmer/getMenuData').subscribe((data: any) => {
          this.menuData = data.Records[0];
          this.menuData$.next(this.menuData);
          //this.isMenuLoaded$.next(true);
        },
          (error: any) => {
            console.log(error.Message);
            //this.isMenuLoaded$.next(false);
          });
      } else {
        this.menuData$.next(this.menuData);
      }
    return this.menuData$.asObservable();
  }
}
