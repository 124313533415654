import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';
import { ManagerModule } from '@core/modules/manager/manager.module';
import { AdminModule } from '@core/modules/admin/admin.module';

import { GfcRoutingModule } from './gfc-routing.module';
import { GfcComponent } from './gfc.component';
import { RacingOverviewComponent } from './modules/participant/pages/racing-overview/racing-overview.component';
import { RacingPitComponent } from './modules/participant/pages/racing-pit/racing-pit.component';
import { RacingRaceTrackComponent } from './modules/participant/pages/racing-race-track/racing-race-track.component';
import { SpringCampaignComponent } from './modules/participant/pages/spring-campaign/spring-campaign.component';
import { RankingLightPlayComponent } from './modules/participant/pages/ranking-light-play/ranking-light-play.component';
import { WintergamesTwentyTwentyoneStatisticComponent } from './modules/manager/pages/wintergames-twenty-twentyone-statistic/wintergames-twenty-twentyone-statistic.component';
import { WintergamesTwentyTwentyoneStatisticChildComponent } from './modules/manager/pages/wintergames-twenty-twentyone-statistic/wintergames-twenty-twentyone-statistic-child/wintergames-twenty-twentyone-statistic-child.component';
import { RankingLightStatisticComponent } from './modules/manager/pages/ranking-light-statistic/ranking-light-statistic.component';
import { RankingLightStatisticChildComponent } from './modules/manager/pages/ranking-light-statistic/ranking-light-statistic-child/ranking-light-statistic-child.component';

@NgModule({
  declarations: [
    GfcComponent,
    RacingOverviewComponent,
    RacingPitComponent,
    RacingRaceTrackComponent,
    SpringCampaignComponent,
    RankingLightPlayComponent,
    WintergamesTwentyTwentyoneStatisticComponent,
    WintergamesTwentyTwentyoneStatisticChildComponent,
    RankingLightStatisticComponent,
    RankingLightStatisticChildComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    ManagerModule,
    AdminModule,
    FormsModule,
    GfcRoutingModule
  ],
  exports: [
    GfcComponent
  ],
  providers: [],
  bootstrap: [
    GfcComponent
  ]
})
export class GfcModule { }

@NgModule({})
export class GfcSharedModule {
  static forRoot(): ModuleWithProviders<GfcModule> {
    return {
      ngModule: GfcModule,
      providers: []
    }
  }
}
