import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../../services/mp-settings.service';
import { ParticipantCockpitService } from './../participant-cockpit.service';
import { ApiService } from './../../../../../services/api.service';
import { MpLoaderService } from './../../../../../services/mp-loader.service';
import { MpMessagingService } from './../../../../../services/mp-messaging.service';

import { MpLoader } from './../../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the participant account
 * within the participant cockpit.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-account',
  templateUrl: './ptcpnt-cockpit-account.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitAccountComponent implements OnInit, OnDestroy {

  public accountExcelService: string = '/api/Konto/Konto2Excel';
  public loader: MpLoader | null = null;
  public bookingTypes: Array<any> = [];
  public showPointsBooking: boolean = false;
  public bookingData: { [key: string]: any } = {};
  public errors: { [key: string]: any } = {};
  public error: string = '';
  public tnIdAvailable: boolean = false;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public cancelPointsBooking = this._cancelPointsBooking.bind(this);
  public confirmPointsBooking = this._confirmPointsBooking.bind(this);

  private _checkboxes: Array<any> = [];
  private _selection: Array<any> = [];
  private _ptcpntCockpitAccountLoader: MpLoader = {
    name: 'ptcpntCockpitAccountLoader',
    refillExtParamsFunc: (params: any) => {
      if (params['Array'] && this._checkboxes.length > 0) {
        this._checkboxes.forEach((c: any) => {
          if (params['Array'].indexOf(c['Status']) > -1) {
            this._selection.push(c);
          }
        });
      }
    },
    params: {}
  };
  private _cockpitData: { [key: string]: any } = {};
  private _getParticipantCockpitDataSubscription: Subscription | undefined;
  private _getAccountTypesSubscription: Subscription | undefined;
  private _getAccountTypesForBookingTypesSubscription: Subscription | undefined;
  private _savePointsBookingSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _participantCockpitService: ParticipantCockpitService,
    private _mpLoaderService: MpLoaderService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the participant cockpit data.
   * Registers the loader, and
   * gets the account types.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._ptcpntCockpitAccountLoader);
    this.loader = this._mpLoaderService.getLoader('ptcpntCockpitAccountLoader');

    this._getParticipantCockpitDataSubscription = this._participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
      if (typeof this._cockpitData['tnId'] === 'undefined' || this._cockpitData['tnId'] !== data['tnId'] || typeof this._cockpitData['reloadCounter'] === 'undefined' || this._cockpitData['reloadCounter'] !== data['reloadCounter']) {
        this._cockpitData = data;

        this._getAccountTypesSubscription = this._apiService.getRequest('/api/Konto/getKontoarten', false, {
          params: {
            tnid: this._cockpitData['tnId']
          }
        }).subscribe((data: any) => {
          this._checkboxes = data.Records;
          this._selection = [];

          this._checkboxes.forEach((c: any) => {
            if (this.loader !== null) {
              if (!this.loader.params['Array'] || this.loader.params['Array'].indexOf(c['Status']) > -1) {
                this._selection.push(c);
              }
            }
          });

          if (this.loader !== null) {
            this._mpLoaderService.extendLoader('ptcpntCockpitAccountLoader', {
              isReady: true,
              params: Object.assign(this.loader.params, {
                Array: this._selection.map((selection: any) => {
                  return selection['Status'];
                }),
                tnId: this._cockpitData['tnId']
              })
            });

            this.tnIdAvailable = this._cockpitData['tnId'] && this._cockpitData['tnId'] !== -1;
          }
        });
      }
    });

    this._getAccountTypesForBookingTypesSubscription = this._apiService.getRequest('/api/Konto/getKontoartenForManuelleBuchung').subscribe((data: any) => {
      this.bookingTypes = data.Records;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getParticipantCockpitDataSubscription !== 'undefined') {
      this._getParticipantCockpitDataSubscription.unsubscribe();
    }

    if (typeof this._getAccountTypesSubscription !== 'undefined') {
      this._getAccountTypesSubscription.unsubscribe();
    }

    if (typeof this._getAccountTypesForBookingTypesSubscription !== 'undefined') {
      this._getAccountTypesForBookingTypesSubscription.unsubscribe();
    }

    if (typeof this._savePointsBookingSubscription !== 'undefined') {
      this._savePointsBookingSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('ptcpntCockpitAccountLoader');
  }

  /**
   * Toggles the points booking modal.
   */
  togglePointsBooking(): void {
    this.showPointsBooking = !this.showPointsBooking;
    this.bookingData['Anzahl'] = 1;
  }

  /**
   * Cancels the points booking.
   */
  private _cancelPointsBooking(): void {
    this.showPointsBooking = false;

    this.bookingData['Status'] = '';
    this.bookingData['Art'] = '';
    this.bookingData['TnId'] = '';
    this.bookingData['Bezeichnung'] = '';
    this.bookingData['LeistungsID'] = '';
    this.bookingData['Punkte'] = '';
  }

  /**
   * Confirms the points booking.
   */
  private _confirmPointsBooking(): void {
    this.bookingData['Status'] = this.bookingData['TypeObject'].Status;
    this.bookingData['Art'] = this.bookingData['TypeObject'].Art;
    this.bookingData['TnId'] = this._cockpitData['tnId'];

    this._savePointsBookingSubscription = this._apiService.postRequest('/api/Konto/setManuellePunktebuchung/', this.bookingData).subscribe((data: any) => {
      this._mpMessaging.openSuccessPanel(data.Records[0]);

      if (this.loader !== null && typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }

      this._cancelPointsBooking();
    });
  }

}
