<div class="card" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="card-inner sidebar-grid"
       [hidden]="!sBItems || sBItems && sBItems['length'] === 0">
    <div *ngIf="itemAdded && isNotSbOverlay" id="overlay-headline">{{ ls.locs['loc'].WarenkorbHinzugefuegt }}</div>
    <div *ngIf="itemAdded && isNotSbOverlay" class="divider margin-top-0 margin-bottom-0"></div>
    <div id="overlay-buttons" *ngIf="itemAdded && isNotSbOverlay"
         class="padding-top-bottom-0 padding-left-right-16 margin-top-bottom-16">
      <a *ngIf="orderUrl && itemAdded && isNotSbOverlay"
         (click)="closeSidebar()"
         [href]="'#/' + role + '/' + orderUrl"
         class="mp-button _prim">
        {{ ls.locs['loc'].ZurBestellung }}
      </a>
      <a href="#/{{role}}/Warenkorb"
         *ngIf="itemAdded && isNotSbOverlay"
         (click)="closeSidebar()"
         class="mp-button _sec margin-top-8">
        {{ ls.locs['loc'].ZumWarenkorb }}
      </a>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   *ngIf="(sBItems && sBItems['length'] > 2) && itemAdded && isNotSbOverlay"
                   [ifNeeded]="true"
                   desktopItemHeight=".overlay-item.scroll-item"
                   scrollArea="#scrollbutton-scroll-area-sidebar"
                   scrollDirection="up"
                   scrollFactor="2"
                   [scopeObject]="sBItems"
                   class="scrollbutton-up inactive-mp-core-scroll-button margin-top-8">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div *ngIf="!itemAdded && !isNotSbOverlay"
         id="overlay-headline"
         class="text-transform-uppercase display-flex flex-justify-cont-center-imp flex-align-items-center-imp padding-top-bottom-0 padding-left-right-16">
      {{ ls.locs['loc'].MeinWarenkorb }} ({{ sBCount }})
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   *ngIf="(sBItems && sBItems['length'] > 2) && !itemAdded && !isNotSbOverlay"
                   [ifNeeded]="true"
                   desktopItemHeight=".overlay-item.scroll-item"
                   scrollArea="#scrollbutton-scroll-area"
                   scrollDirection="up"
                   scrollFactor="2"
                   [scopeObject]="sBItems"
                   class="scrollbutton-up inactive-mp-core-scroll-button">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="divider margin-top-0 margin-bottom-0"></div>
    <div class="wk-overlay-scrollarea scroll-area-container overflow-hidden">
      <div *ngIf="itemAdded && isNotSbOverlay"
           id="scrollbutton-scroll-area-sidebar"
           class="position-relative sidebar-scroll-area"
           [ngClass]="(sBItems && sBItems['length'] <= 2) ? 'scroll-area-top-0' : ''">
        <div *ngFor="let item of sBItems; let last = last"
             class="overlay-item scroll-item text-align-left padding-t">
          <div class="grid col-medium-3 padding-left-0 item-image display-flex flex-direction-column flex-justify-cont-center flex-align-items-center flex-wrap overflow-hidden">

            <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}"
               [hidden]="!item.ShowDetailSeite">
              <img src="{{item.Bild}}?width=100"
                   alt="{{item.ArtNr+'-'+item.PIN}}" />
            </a>
            <div [hidden]="item.ShowDetailSeite">
              <img src="{{item.Bild}}?width=100"
                   alt="{{item.ArtNr+'-'+item.PIN}}" />
            </div>

          </div>
          <div class="grid col-medium-6 item-labels display-flex flex-direction-column flex-justify-cont-center flex-align-items-start flex-wrap overflow-hidden">

            <a class="text-align-left"
               href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}"
               [hidden]="!item.ShowDetailSeite">
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.Hersteller}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez2}}</span>
            </a>
            <div [hidden]="item.ShowDetailSeite">
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.Hersteller}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez2}}</span>
            </div>

            <span class="item-count overflow-hidden text-overflow-ellipsis display-block">{{ ls.locs['loc'].Anzahl }}: {{item.Anzahl}}</span>
          </div>
          <div class="grid col-medium-3 padding-right-0 item-points display-flex flex-direction-column flex-justify-cont-between flex-align-items-end flex-wrap overflow-visible">
            <i mpCoreScrollButtonDeleteItem
               [attr.itemIndex]="sBItems.indexOf(item)"
               data-scroll-area="#scrollbutton-scroll-area-sidebar"
               (click)="deleteSbItem(item.Key)"
               class="svg-icon __clr-default __size-18 __top--1 __clear">
            </i>
            <span *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte" class="no-word-wrap">{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
          </div>
          <div [hidden]="last"
               class="divider margin-top-8 margin-bottom-0 grid col-medium-12 display-flex flex-direction-column flex-justify-cont-center flex-align-items-start flex-wrap overflow-hidden">
          </div>
        </div>
      </div>
      <div *ngIf="!itemAdded && !isNotSbOverlay"
           id="scrollbutton-scroll-area"
           class="position-relative sidebar-scroll-are"
           [ngClass]="(sBItems && sBItems['length'] <= 2) ? 'scroll-area-top-0' : ''">
        <div *ngFor="let item of sBItems; let last = last"
             class="overlay-item scroll-item">
          <div class="grid col-medium-3 padding-left-0 item-image display-flex flex-direction-column flex-justify-cont-center flex-align-items-center flex-wrap overflow-hidden">

            <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}"
               [hidden]="!item.ShowDetailSeite">
              <img src="{{item.Bild}}?width=100"
                   alt="{{item.ArtNr+'-'+item.PIN}}" />
            </a>
            <div [hidden]="item.ShowDetailSeite">
              <img src="{{item.Bild}}?width=100"
                   alt="{{item.ArtNr+'-'+item.PIN}}" />
            </div>

          </div>
          <div class="grid col-medium-6 item-labels display-flex flex-direction-column flex-justify-cont-center flex-align-items-start flex-wrap overflow-hidden">

            <a class="text-align-left"
               href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}"
               [hidden]="!item.ShowDetailSeite">
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.Hersteller}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez2}}</span>
            </a>
            <div [hidden]="item.ShowDetailSeite">
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.Hersteller}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez}}</span>
              <span class="overflow-hidden text-overflow-ellipsis display-block">{{item.ArtBez2}}</span>
            </div>

            <span class="item-count overflow-hidden text-overflow-ellipsis display-block">{{ ls.locs['loc'].Anzahl }}: {{item.Anzahl}}</span>
          </div>
          <div class="grid col-medium-3 padding-right-0 item-points display-flex flex-direction-column flex-justify-cont-between flex-align-items-end flex-wrap overflow-visible">
            <i mpCoreScrollButtonDeleteItem
               [attr.itemIndex]="sBItems.indexOf(item)"
               data-scroll-area="#scrollbutton-scroll-area"
               (click)="deleteSbItem(item.Key)"
               class="svg-icon __clr-default __size-18 __top--1 __clear">
            </i>
            <span *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte" class="no-word-wrap">{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
          </div>
          <div [hidden]="last"
               class="divider margin-top-8 margin-bottom-0 grid col-medium-12 display-flex flex-direction-column flex-justify-cont-center flex-align-items-start flex-wrap overflow-hidden">
          </div>
        </div>
      </div>
    </div>
    <div class="divider margin-top-0 margin-bottom-0"></div>
    <div id="overlay-footer"
         class="padding-top-8 padding-bottom-16 padding-left-right-16">
      <mp-core-scroll-button id="overlay-scroll-down"
                     *ngIf="itemAdded && (sBItems && sBItems['length'] > 2) && isNotSbOverlay"
                     [ifNeeded]="true"
                     desktopItemHeight=".overlay-item.scroll-item"
                     scrollArea="#scrollbutton-scroll-area-sidebar"
                     scrollDirection="down"
                     scrollFactor="2"
                     [scopeObject]="sBItems"
                     class="scrollbutton-down">
        <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
      </mp-core-scroll-button>
      <mp-core-scroll-button id="overlay-scroll-down"
                     *ngIf="!itemAdded && (sBItems && sBItems['length'] > 2) && !isNotSbOverlay"
                     [ifNeeded]="true"
                     desktopItemHeight=".overlay-item.scroll-item"
                     scrollArea="#scrollbutton-scroll-area"
                     scrollDirection="down"
                     scrollFactor="2"
                     [scopeObject]="sBItems"
                     class="scrollbutton-down">
        <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
      </mp-core-scroll-button>
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !mpSettings.settings['BestellprozessSettings'].HideVersandkosten"
           class="items-shipment display-flex flex-justify-cont-between"
           [hidden]="(sBItems | sumOfItems:'AuslandsversandPunkte') === 0">
        <span>
          {{ ls.locs['loc'].Lieferkosten }}
        </span>
        <span>
          {{sBItems | sumOfItems:'AuslandsversandPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </span>
      </div>
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !mpSettings.settings['BestellprozessSettings'].HideVersandkosten"
           class="items-shipment display-flex flex-justify-cont-between"
           [hidden]="(sBItems | sumOfItems:'ExpressServicezuschlag') === 0">
        <span>
          {{ ls.locs['loc'].Express }}
        </span>
        <span>
          {{sBItems | sumOfItems:'ExpressServicezuschlag' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </span>
      </div>
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !mpSettings.settings['BestellprozessSettings'].HideZusatzkosten" class="items-shipment display-flex flex-justify-cont-between"
           [hidden]="(sBItems | sumOfItems:'ZusatzPunkte') === 0">
        <span>
          {{ ls.locs['loc'].Zusatzkosten }}
        </span>
        <span>
          {{sBItems | sumOfItems:'ZusatzPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </span>
      </div>
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte"
           class="items-total-cost display-flex flex-justify-cont-between">
        <span>
          {{ ls.locs['loc'].Gesamtkosten }}
        </span>
        <span>
          {{sBSum | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </span>
      </div>
      <div *ngIf="!itemAdded && !isNotSbOverlay" id="overlay-buttons"
           class="display-flex flex-direction-column">
        <a *ngIf="orderUrl && mpShoppingBasket.blockingValidationMessages['length'] === 0"
           (click)="toOrder($event)"
           class="mp-button _prim">
          {{ ls.locs['loc'].ZurBestellung }}
        </a>
        <a href="#/{{role}}/Warenkorb"
           (click)="closeSidebar()"
           class="mp-button margin-top-8"
           [ngClass]="mpShoppingBasket.blockingValidationMessages['length'] > 0 ? '_prim' : '_sec'">
          {{ ls.locs['loc'].ZumWarenkorb }}
        </a>
      </div>
    </div>
  </div>
  <div class="card-inner"
       [hidden]="(sBItems && sBItems['length'] > 0)">
    <div id="overlay-empty"
         class="text-align-center">
      <div id="overlay-headline-empty"
           class="text-transform-uppercase display-flex flex-justify-cont-center flex-align-items-center padding-bottom-0 padding-left-right-16">
        {{ ls.locs['loc'].IhrWarenkorbIstLeer }}
      </div>
      <div>{{ ls.locs['loc'].LegenSieLos }}</div>
    </div>
    <div id="overlay-footer-empty"
         class="padding-top-32 padding-bottom-16 padding-left-right-16 text-align-center">
      <div id="overlay-empty-howtostart">{{ ls.locs['loc'].SieWissenNichtWoSieAnfangenSollen }}</div>
      <div id="overlay-buttons"
           class="margin-top-24 display-flex flex-direction-column">
        <a href="#/{{role}}/Shop/{{ mpSettings.settings['NetcentiveCoreSettings'].LeererWarenkorbKategorieId }}"
           (click)="closeSidebar()"
           class="mp-button _sec display-block margin-top-8">
          {{ ls.locs['loc'].LeererWarenkorbKategorieLink }}
        </a>
      </div>
    </div>
  </div>
</div>
