<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <ul class="tab pointer">
    <li class="text-transform-uppercase bg-color-brand-primary padding-left-16 padding-right-16">{{ ls.locs['loc'].Filter }}</li>
  </ul>
  <div class="side-filter margin-bottom-8 padding-left-right-24 padding-top-24 padding-bottom-0 display-flex flex-direction-column">
    <div class="flex-grid flex-direction-row">
      <div class="bold flex-col-12">
        {{ ls.locs['locReisekonfigurator'].Fahrzeugkategorie }}
      </div>
      <div class="flex-col-4 flex-col-6-l" *ngFor="let category of options['Kategorien'] | orderBy:'Sterne'; let index = index">
        <mp-core-checkbox mpId="chkFahrzeugkategorie{{filterId}}{{index}}" [model]="category.Active" (modelChange)="category.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12" for="chkFahrzeugkategorie{{filterId}}{{index}}">{{category.Bezeichnung}}</label>
      </div>
    </div>
    <hr [hidden]="!options['MaxPreis']" class="margin-top-12 margin-bottom-24">
    <div class="flex-grid flex-direction-column" [hidden]="!options['MaxPreis']">
      <div class="padding-left-8 padding-right-8">
        <div class="bold display-inline-block">
          {{ ls.locs['locReisekonfigurator'].Gesamtbudget }}
        </div>
        <div class="text display-inline-block text-color-info">
          {{ ls.locs['loc'].BisMax }} {{filter['Punkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </div>
      </div>
      <div class="form-group padding-left-8 padding-right-8" *ngIf="rangeSliderConfigDone">
        <ngx-slider class="mp-ngx-slider"
                    [(value)]="filterPoints"
                    [options]="rangeSliderOptions"
                    (userChangeEnd)="filterClicked(false, $event)">
        </ngx-slider>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasPunktekonto" class="filter-checkbox display-flex">
          <mp-core-checkbox mpId="chkBestellbareReisen{{filterId}}" [model]="orderableTravels" (modelChange)="orderableTravels = $event; onlyOrderableTravels(); filterClicked(false);"></mp-core-checkbox>
          <label class="font-size-s margin-top-4" for="chkBestellbareReisen{{filterId}}">{{ ls.locs['locReisekonfigurator'].NurReisenImPunktebereich }}</label>
        </div>
      </div>
    </div>
    <hr class="margin-top-8 margin-bottom-24">
    <div class="flex-grid flex-direction-row flex-wrap">
      <div class="bold flex-col-12 flex-justify-cont-between pointer" (click)="showProvider = !showProvider">
        <div>
          {{ ls.locs['locReisekonfigurator'].MietwagenAnbieter }}
        </div>
        <div class="svg-icon __clr-default __size-18 __top-0" [ngClass]="showProvider === true ? '__sidebar-arrow-up' : '__sidebar-arrow-down'"></div>
      </div>
      <div [hidden]="!showProvider" class="flex-col-12 font-size-s margin-bottom-12">
        <a (click)="selectAll(filter['Anbieter'], $event)">
          {{ ls.locs['loc'].Alle }}
        </a> /
        <a (click)="selectNone(filter['Anbieter'], $event)">{{ ls.locs['loc'].Keine }}</a>
      </div>
      <div [hidden]="!showProvider" class="flex-col-6 flex-col-6-l flex-col-6-xs" *ngFor="let provider of options['Anbieter']; let index = index">
        <mp-core-checkbox mpId="chkAnbieter{{filterId}}{{index}}" [model]="provider.Active" (modelChange)="provider.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12" for="chkAnbieter{{filterId}}{{index}}">{{provider.Bezeichnung}}</label>
      </div>
    </div>
    <div id="side-filter-footer" class="display-flex flex-direction-column flex-align-items-center">
      <hr class="margin-top-24" />
      <button class="mp-button margin-bottom-8" [ngClass]="{disabled: defaultFilter}" (click)="resetFilter()">
        {{ ls.locs['loc'].AlleFilterZuruecksetzen }}
        <i class="svg-icon __clr-default __size-22 __top--1 margin-left-4 __restore float-none-important"></i>
      </button>
    </div>
  </div>
</div>
