<div class="support-lug" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div *ngIf="mpSettings.settings['BestellprozessSettings'].ShowKontaktInBestellUebersicht"
       class="support display-flex flex-align-items-center"
       [ngClass]="openSupport === true ? 'open-support' : ''"
       (clickOutside)="closeSupport()">
    <div class="display-flex flex-align-items-center flex-justify-cont-center" (click)="openSupport = !openSupport">
      <i *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer || mpSettings.settings['DisplaySettings'].HideHotline" class="svg-icon __clr-specific-3 __size-32 __top-0 __at-sign"></i>
      <i *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="svg-icon __clr-specific-3 __size-32 __top-0 __phone"></i>
    </div>
    <div class="display-flex flex-justify-cont-center flex-align-items-center flex-direction-column padding-48 text-align-center">
      <h1>{{ ls.locs['loc'].Kundenservice }}</h1>
      <p *ngIf="subline === ''">{{ ls.locs['loc'].FragenZurBestellung }}</p>
      <p [innerHTML]="subline" *ngIf="subline !== ''"></p>

      <p *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline"
         class="margin-bottom-0">
        {{ ls.locs['loc'].Telefon }}:
        <a href="tel:{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}">{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}</a>
      </p>

      <p *ngIf="!mpSettings.settings['BestellprozessSettings'].HideEmailInSupportPopover">
        {{ ls.locs['loc'].EMail }}:
        <a href="mailto:{{ mpSettings.settings['NetcentiveCoreSettings'].KontaktEMailAddress }}">{{ mpSettings.settings['NetcentiveCoreSettings'].KontaktEMailAddress }}</a>
      </p>

      <p *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="font-size-xxs margin-bottom-0">{{ ls.locs['loc'].Supporttool_Zeiten }}</p>
      <p *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline && ls.locs['loc'].SupportKosten" class="font-size-xxs">{{ ls.locs['loc'].SupportKosten }}</p>
    </div>
  </div>
</div>
