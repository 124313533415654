<div id="reisebericht" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" class="layout-container bg-color-white padding-bottom-48">
  <mp-core-breadcrumbs></mp-core-breadcrumbs>

  <img class="header-image" src="{{travelogue['BuehnenPfad']}}">

  <h1 class="flex-col-12 margin-top-12" [innerText]="travelogue['Titel']"></h1>
  <h2 class="flex-col-12 margin-bottom-16" [innerText]="travelogue['Teaser']"></h2>

  <div class="reisebericht-text border-bottom" [innerHTML]="travelogue['Text']"></div>


  <div class="row margin-top-48">
    <div class="grid col-large-4 text-align-center">
      <h1 [innerHTML]="travelogue['AutorBiografieTitel']"></h1>
      <img src="{{travelogue['AutorBild']}}">
    </div>
    <div class="grid col-large-8 margin-top-72">
      <div [innerHTML]="travelogue['AutorBiografie']"></div>
    </div>
  </div>

  <mp-rk-tc-support class="hide-on-medium"></mp-rk-tc-support>
  <div *ngIf="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length > 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer !== null) && !mpSettings.settings['DisplaySettings'].HideHotline" id="rk-bottom-buttons" class="show-on-medium position-fixed bottom-0 width-full left-0-important" stickyBottom>
    <a class="mp-button _light-grey width-100-pcnt display-flex-important flex-justfiy-cont-center flex-align-items-center flex-direction-column padding-top-bottom-8" href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">
      <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
      <span class="display-block">{{ls.locs['locReisekonfigurator'].Reisebuero}}</span>
    </a>
  </div>
</div>
  
<div id="reisebericht-slider" class="layout-container">
    <div id="reisebericht-cards">
        <mp-rk-travelogue-card-list></mp-rk-travelogue-card-list>
    </div>
</div>




