import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';
import { ManagerModule } from '@core/modules/manager/manager.module';
import { AdminModule } from '@core/modules/admin/admin.module';

import { TawRoutingModule } from './taw-routing.module';
import { TawComponent } from './taw.component';
import { TipAndWinComponent } from './modules/participant/pages/tip-and-win/tip-and-win.component';
import { TipAndWinRankingComponent } from './components/tip-and-win-ranking/tip-and-win-ranking.component';
import { TipAndWinTipsComponent } from './components/tip-and-win-tips/tip-and-win-tips.component';
import { TipAndWinMatchTipsComponent } from './components/tip-and-win-match-tips/tip-and-win-match-tips.component';
import { TipAndWinDailyQuizComponent } from './components/tip-and-win-daily-quiz/tip-and-win-daily-quiz.component';
import { TipAndWinAdditionalQuestionsComponent } from './components/tip-and-win-additional-questions/tip-and-win-additional-questions.component';
import { TipAndWinRulesOfTheGameComponent } from './components/tip-and-win-rules-of-the-game/tip-and-win-rules-of-the-game.component';
import { TipAndWinYieldsComponent } from './components/tip-and-win-yields/tip-and-win-yields.component';
import { TipAndWinPerformanceComponent } from './components/tip-and-win-performance/tip-and-win-performance.component';
import { TipAndWinEditorComponent } from './modules/manager/pages/tip-and-win-editor/tip-and-win-editor.component';
import { TipAndWinGameAcceptanceComponent } from './modules/manager/pages/tip-and-win-game-acceptance/tip-and-win-game-acceptance.component';
import { TipAndWinGameStatisticComponent } from './modules/manager/pages/tip-and-win-game-statistic/tip-and-win-game-statistic.component';
import { TipAndWinGameStatisticChildComponent } from './modules/manager/pages/tip-and-win-game-statistic/tip-and-win-game-statistic-child/tip-and-win-game-statistic-child.component';

@NgModule({
  declarations: [
    TawComponent,
    TipAndWinComponent,
    TipAndWinRankingComponent,
    TipAndWinTipsComponent,
    TipAndWinMatchTipsComponent,
    TipAndWinDailyQuizComponent,
    TipAndWinAdditionalQuestionsComponent,
    TipAndWinRulesOfTheGameComponent,
    TipAndWinYieldsComponent,
    TipAndWinPerformanceComponent,
    TipAndWinEditorComponent,
    TipAndWinGameAcceptanceComponent,
    TipAndWinGameStatisticComponent,
    TipAndWinGameStatisticChildComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    ManagerModule,
    AdminModule,
    FormsModule,
    TawRoutingModule
  ],
  exports: [
    TawComponent
  ],
  providers: [],
  bootstrap: [
    TawComponent
  ]
})
export class TawModule { }

@NgModule({})
export class TawSharedModule {
  static forRoot(): ModuleWithProviders<TawModule> {
    return {
      ngModule: TawModule,
      providers: []
    }
  }
}
