<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-breadcrumbs></mp-core-breadcrumbs>
  <mp-core-detail-page-content *ngIf="errorMessage === '' && themeLoaded && campaignLoaded"
                        imgSrc="/themes/{{currentTheme}}/media/aktionen/uebersicht/{{campaign['Key']}}.jpg?h=427&w=1280&mode=crop"
                        [title]="trustAsHtml(campaign['Title'])"
                        [text]="trustAsHtml(campaign['Text'])"
                        [showProgress]="!hideTimeline"
                        [progressPercent]="((daysTotal - daysLeft)*100/daysTotal)"
                        progressBarTextActive="Noch {{daysLeft | integer}} Tage"
                        progressBarTextDone="{{ ls.locs['loc'].AktionBeendet }}"></mp-core-detail-page-content>
  <div class="card" *ngIf="errorMessage !== ''">
    <div class="error" [innerHTML]="errorMessage"></div>
    <div class="bg-color-light-grey padding-top-32 padding-bottom-32"></div>
  </div>
</div>
