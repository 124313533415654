import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, ViewEncapsulation, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { ApiService } from './../../services/api.service';
import { MpBreadcrumbService } from './../../services/mp-breadcrumb.service';

import { Topic } from './topic';
import { Faq } from './faq';
import { text } from 'd3';

/**
 * This class provides the faq data
 * and functions.
 */
@Component({
  selector: 'mp-core-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public showWithoutFilter: boolean = false;
  @Input() public hideLabel: boolean = false;
  @Input() public showAllTopics: boolean = true;
  @Input() public showPlaceholder: boolean = true;
  @Input() public errors: { [key: string]: any } = {};
  @Input() public showRequired: boolean = false;
  @Input() public showEmpty: boolean = false;
  @Input() public hideSearchbox: boolean = false;
  @Input() public ignoreBreadcrumbs: boolean = false;
  @Input() public hideFilter: boolean = false;
  @Input() public excludeFAQText: boolean = false;
  @Input() public choice: Topic = {
    ThemaID: null,
    Thema: '',
    HelplineBezeichnung: null,
    AnzahlTexte: 0
  };

  @Output() choiceChange = new EventEmitter<Topic>();

  public faq: Array<Faq> = [];
  public topics: Array<Topic> = [];
  public faqCount: number = 0;
  public searchTerm: string = '';
  public filteredFAQFunc = this.filteredFAQ.bind(this);

  private _openFAQ: Array<boolean> = [];
  private _orderTAN: string = '';
  private _queryParamsSubscription: Subscription | undefined;
  private _getTextsSubscription: Subscription | undefined;
  private _getTopicsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    public sanitizer: DomSanitizer,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
  ) { }

  /**
   * Gets the params from the route
   * and handles the breadcrumb.
   */
  ngOnInit(): void {
    if (!this.ignoreBreadcrumbs) {
      this._breadcrumbService.setCurrentSiteWithLoc('FAQ', 'loc', 'FAQ');
    }

    this._queryParamsSubscription = this._route.queryParamMap.subscribe((params: any) => {
      const orderNr = params.get('bestnr');

      if (orderNr !== null) {
        this._orderTAN = orderNr;
      }

      this._getTopic();
    });
  }

  /**
   * Unsubscribes set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._getTextsSubscription !== 'undefined') {
      this._getTextsSubscription.unsubscribe();
    }

    if (typeof this._getTopicsSubscription !== 'undefined') {
      this._getTopicsSubscription.unsubscribe();
    }
  }

  /**
   * Watches for changes of choice.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['choice'] !== 'undefined' && typeof changes['choice'].currentValue !== 'undefined') {
      if (typeof changes['choice'].currentValue['ThemaID'] !== 'undefined' && changes['choice'].currentValue['ThemaID'] !== null) {
        this._setChoiceByTopicId();
        this.faqCount = this.filteredFAQ().length;
      }
    }
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Gets the faqs from the backend.
   */
  private _getFAQ(): void {
    this._getTextsSubscription = this._apiService.getRequest('/api/FAQ/getAlleTexte', false, (this.choice.ThemaID !== null ? { params: { theme: this.choice.ThemaID } } : {})).subscribe((data: any) => {
      this.faq = data.Records;

      if (this.excludeFAQText === true) {
        var faqText2exclude = 'Die Informationen zu den obenstehenden FAQ konnten meine Fragen leider nicht beantworten. Wie kann mir trotzdem geholfen werden?';

        this.faq = this.faq.filter((faq: Faq) => {
          return !faq.FAQUeberschrift.includes(faqText2exclude);
        });
      }

      this.faq.forEach((faq: Faq) => {
          faq.accID = faq.FAQTextID;
          faq.accText = faq.FAQText;
          faq.accHeadline = faq.FAQUeberschrift;

          this._openFAQ[faq.accID] = true;
      })

      this.faqCount = this.filteredFAQ().length;
    });
  }

  /**
   * Sets the choice by the topic id.
   */
  private _setChoiceByTopicId(): void {
    if (!this.topics || this.choice.ThemaID !== null)
      return;

    this.choice = (this.choice.ThemaID !== null && this.topics.find((topic: Topic) => { return topic.ThemaID === this.choice.ThemaID; })) || this.topics[0];
  }

  /**
   * Gets the topics from the
   * backend.
   */
  private _getTopic(): void {
    this._getTopicsSubscription = this._apiService.getRequest(`/api/FAQ/getThema?inklusiveAlle=${!!this.showAllTopics}`).subscribe((data: any) => {
      this.topics = data.Records.filter((topic: Topic) => {
        return this.showEmpty || topic.AnzahlTexte > 0;
      });

      if (this._orderTAN !== '') {
        const choice = this.topics.find((topic: Topic) => {
          return topic.ThemaID === 1;
        });

        if (typeof choice !== 'undefined') {
          this.choice = choice;
        }
      }

      if (this.showWithoutFilter || this.choice.ThemaID !== null) {
        this._setChoiceByTopicId()
      }

      this._getFAQ();
    });
  }

  /**
   * Filters and returns the filtered
   * faqs.
   */
  filteredFAQ(): Array<Faq> {
    let faqs: Array<Faq> = [];
    if (this.choice && this.choice.ThemaID !== null) {
      faqs = this.faq.filter((faq: Faq) => faq.ThemaID === this.choice.ThemaID);
    } else if (this.showWithoutFilter) {
      faqs = this.faq;
    }

    const filtered = faqs.filter((faq: Faq) => {
      const searchTermEscaped = this._htmlEscape(this.searchTerm);


      return this.searchTerm === '' || faq.FAQUeberschrift.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        faq.FAQText.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        faq.FAQUeberschrift.toLowerCase().indexOf(searchTermEscaped.toLowerCase()) > -1 ||
        faq.FAQText.toLowerCase().indexOf(searchTermEscaped.toLowerCase()) > -1;
    });

    return filtered.filter((faq: Faq, index: number, self: Array<Faq>) => {
      return index === self.findIndex((val: Faq) => faq.FAQUeberschrift + faq.FAQText === val.FAQUeberschrift + val.FAQText);
    });
  }

  /**
   * Closes all opened faqs.
   */
  public closeFAQ(): void {
    this._openFAQ = [];
    this.choiceChange.emit(this.choice);
  }

  /**
   * Escapes the html within the
   * given string.
   */
  private _htmlEscape(str: string): string {
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/ä/g, '&auml;')
      .replace(/ö/g, '&ouml;')
      .replace(/ü/g, '&uuml;')
      .replace(/Ä/g, '&Auml;')
      .replace(/Ö/g, '&Ouml;')
      .replace(/Ü/g, '&Uuml;')
      .replace(/ß/g, '&szlig;');
  }

}
