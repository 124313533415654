<div class="top-marken floating-container margin-bottom-32" *ngIf="(ls.locsLoaded | async)">
  <h2>{{ ls.locs['loc'].TopMarken }}</h2>
  <owl-carousel-o *ngIf="brands.length > 0" id="marken-carousel" class="owl-theme" [options]="brandsSliderProperties">
    <ng-container *ngFor="let item of brands; let idx = index">
      <ng-template carouselSlide [id]="'brand-slider-slide-' + idx">
        <div class="item">
          <a href="#/{{role}}/Shop?m={{encode(item['Hersteller'])}}">
            <img src="{{item['Datei']}}" alt="{{item['Hersteller']}}" class="img-responsive" />
          </a>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
