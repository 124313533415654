<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'quickboardingStatistik'"></mp-core-infotext>
  <h1 class="mobile-only">{{ ls.locs['locLeas'].QuickboardingStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].QuickboardingStatistik }}" 
                 infotextShorten="quickboardingStatistik" 
                 fieldsAction="/api/Statistik/GetQuickboardingStatistikFields" 
                 listAction="/api/Statistik/GetQuickboardingStatistik" 
                 loaderName="leasQuickboardingStatisticLoader"
                 [recLoadedFunc]="recLoadedFunc"
                 [paging]="true"></mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
