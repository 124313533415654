<div *ngIf="(ls.locsLoaded | async)">
  <h1>{{ ls.locs['locLeas'].WoherKennenSieUns }}</h1>
  <p class="margin-top-24">{{ ls.locs['locLeas'].WieSindSieAufDenBubbleClubAufmerksamGeworden }}</p>
  <mp-core-select label="{{ ls.locs['locLeas'].Auswaehlen }}" [(model)]="adWay" [elements]="adWays" textProperty="text" valueProperty="value"></mp-core-select>
  <div class="margin-top-24" [hidden]="adWay !== 'a' && adWay !== 'b'">
    <p>{{ ls.locs['locLeas'].ErlaeuterungTnWerbenTnVk }}</p>
    <mp-core-input label="{{ ls.locs['locLeas'].Verkaeufer }}" [(model)]="user" type="text"></mp-core-input>
  </div>
  <div class="margin-top-24" [hidden]="adWay !== 'c'">
    <p>{{ ls.locs['locLeas'].ErlaeuterungTnWerbenTnAd }}</p>
    <mp-core-input label="{{ ls.locs['locLeas'].Aussendienstmitarbeiter }}" [(model)]="user" type="text"></mp-core-input>
  </div>
</div>
