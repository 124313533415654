import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { RoleMappingService } from '@core/services/role-mapping.service';

/**
 * This class provides the data and
 * the functions for the schooling
 * start page.
 */
@Component({
  selector: 'mp-leas-schooling-start-leas',
  templateUrl: './schooling-start-leas.component.html',
  styleUrls: ['./../../../../../../mp.Core/app/modules/participant/pages/award-details/award-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SchoolingStartLeasComponent implements OnInit, OnDestroy {

  public schoolingData: { [key: string]: any } = {};
  public tn2schoolingData: { [key: string]: any } = {};
  public schoolingId: number = 0;
  public role: string = '';
  public isTrainingCompleted: boolean = false;
  public trainingCompletedText: string = '';

  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _roleMapping: RoleMappingService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Gets the role. Gets the locs.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }

    this._route.queryParams.subscribe((params: any) => {
      if (params['trainingCompleted']) {
        this._mpMessaging.openSuccessPanel(this.ls.locs['locSchulung'].SchulungAbgeschlossen);
      }
      this.schoolingId = typeof params['id'] !== 'undefined' ? params['id'] : 0;
      if (this.schoolingId > 0) {
        this._apiService.postRequest('/api/Schulung/GetSchulung', { Id: this.schoolingId }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.schoolingData = data.Records[0];
            }
        },
        (error: any) => {
          console.log(error.Message);
        });
        this._apiService.postRequest('/api/Schulung/CheckSchulungCompletedExists', { Id: this.schoolingId }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.isTrainingCompleted = data.Records[0];
              //this.trainingCompletedText = this.ls.locs['locSchulung'].ErfolgreichAbsolviertAm + this.schoolingData.;
            }
          },
          (error: any) => {
            console.log(error.Message);
          });
        this._apiService.postRequest('/api/Schulung/GetTn2Schulung', { SchulungsId: this.schoolingId }).subscribe((data: any) => {
          if (data.Result === 'OK') {
            if (data.Records[0] !== null) {
              this.tn2schoolingData = data.Records[0];
                this.trainingCompletedText = this.ls.locs['locSchulung'].ErfolgreichAbsolviertAm + moment(this.tn2schoolingData['AbschlussDatum']).format('DD.MM.YYYY');
            }
          }
        },
          (error: any) => {
            console.log(error.Message);
          });
      }
    });
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

}
