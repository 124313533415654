import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mp-wkzz-root',
  templateUrl: './wkzz.component.html',
  styleUrls: [
    './../../mp.Zuzahlung/app/zz.component.scss',
    './wkzz.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class WkzzComponent { }
