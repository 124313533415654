import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { Angulartics2Matomo } from 'angulartics2';

import { MpSettingsService } from './mp-settings.service';

/**
 * This class provides the functionality
 * for triggering a Matomo tracking event.
 */
@Injectable({
  providedIn: 'root'
})
export class MpAnalyticsService {

  private _settingsSubscription: Subscription | undefined;

  constructor(
    private _matomo: Angulartics2Matomo,
    private _mpSettings: MpSettingsService
  ) { }

  /**
   * Tracks the event.
   */
  trackEvent(category: string, action: string, name: string, value?: string | number): void {
    if (Object.keys(this._mpSettings.settings).length > 0) {
      if (this._mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
        this._matomo.eventTrack(action, {
          category: category,
          name: name,
          value: typeof value !== 'undefined' ? value : ''
        });
      }
    } else {
      this._settingsSubscription = this._mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (this._mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
            this._matomo.eventTrack(action, {
              category: category,
              name: name,
              value: typeof value !== 'undefined' ? value : ''
            });
          }

          if (typeof this._settingsSubscription !== 'undefined') {
            this._settingsSubscription.unsubscribe();
          }
        }
      });
    }    
  }
}
