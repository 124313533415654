import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(filterArr: Array<any>, key: string, filterProperty: string): Array<any> {
    return filterArr.filter((item: any) => {
      return item[filterProperty] === key;
    });
  }

}
