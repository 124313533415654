import { Directive, ElementRef, OnInit } from '@angular/core';

/**
 * This class provides the possibility
 * to load view parts synchronous.
 */
@Directive({
  selector: '[mpCoreLoadSync]',
  exportAs: 'mpCoreLoadSync'
})
export class LoadSyncDirective {

  public loads: { [key: string]: any } = {};

  constructor(private _element: ElementRef) { }

  /**
   * Fetchs data attributes and performs
   * the synchronously load.
   */
  ngOnInit(): void {
    const loadSync = this._element.nativeElement;
    let index = 0;
    const loadOrder = loadSync.dataset.loadOrder;

    if (typeof loadOrder === 'undefined') {
      return;
    }

    const startDelay = typeof loadSync.dataset.startDelay !== 'undefined' ? parseInt(loadSync.dataset.startDelay) : 0;
    const loadDelay = typeof loadSync.dataset.loadDelay !== 'undefined' ? parseInt(loadSync.dataset.loadDelay) : 300;

    loadOrder.split(',').forEach((order: string) => {
      setTimeout(() => {
        this.loads = Object.assign(this.loads, { [order]: true });
      }, startDelay + (loadDelay * (index++)));
    });
  }

}
