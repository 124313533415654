<div class="mietwagen-bestaetigung padding-top-64 padding-bottom-64 rk-two-columns-layout" id="rental-confirmation" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div reisekonfigurator-background class="rk-background" style="background-image: url('/themes/default/media/images/rk-video.png')"></div>
  <div id="rk-booking-error" [hidden]="message === ''" class="padding-left-16 padding-right-16 padding-bottom-16 hide-on-medium">
    <div id="rk-error" class="padding-top-16 padding-bottom-8 width-100-pcnt">
      <div id="rk-back-btn" class="display-inline-flex flex-justify-cont-end __top-0 padding-8 position-relative">
        <a href="/" class="mp-button display-flex">
          <span class="display-inline-block position-relative top-2">{{ls.locs['loc'].ZurueckZurStartseite}}</span>
        </a>
      </div>
    </div>
  </div>
  <div id="rk-main-section" class="noBookingMessage padding-bottom-16" [hidden]="message === ''">
    <mp-core-info-notification iconPosition="left" infoState="info" headline="{{ls.locs['locReisekonfigurator'].BestellBestaetigungFehlerTitel}}" copyText="{{ls.locs['locReisekonfigurator'].BestellungHeaderFehlertext}}<br> {{message}}" class="margin-top-8 margin-left-0 margin-right-0 padding-left-4 padding-right-8 display-block"></mp-core-info-notification>
  </div>
  <div id="rk-booking-confirm" [hidden]="orderNo === ''" class="margin-left-right-16 margin-top-16 bg-color-white padding-left-40 padding-right-40 padding-bottom-16">
    <div class="border-bottom flex-grid margin-0 flex-align-items-center">
      <div class="flex-col-9 flex-col-8-s flex-col-8-m padding-left-0">
        <i class="svg-icon __clr-info __size-40 __top-4 __done-all margin-bottom-8 margin-right-8 default-cursor-hover float-none-important"></i>
        <h2 class="margin-bottom-0 display-flex flex-align-items-center">{{ls.locs['locReisekonfigurator'].BestellBestaetigungTitel}}</h2>
      </div>
      <div class="flex-col-3 flex-col-4-s flex-col-4-m flex-justify-cont-end padding-right-0 hide-on-medium">
        <a href="/" class="mp-button">{{ls.locs['loc'].ZurueckZurStartseite}}</a>
      </div>
    </div>
    <div id="order-confirmation-header-text" class="margin-top-16 bold" [innerText]="ls.locs['locReisekonfigurator'].BestellungHeadertext.replace('{0}', orderNo)"></div>
  </div>
  <div id="rk-main-section" *ngIf="bookingAvailable" class="rk-mietwagen-bestaetigung">
    <div id="rk-offers" class="margin-top-16 margin-bottom-16">
      <div id="rk-additional-pay">
        <div class="rk-offers-headline">
          <h2>
            {{ls.locs['locReisekonfigurator'].IhreKostenuebersichtMietwagen}}
          </h2>
        </div>
        <div class="margin-0 padding-top-16 padding-bottom-16 padding-left-40 padding-right-40 flex-grid flex-direction-row flex-wrap flex-align-items-end bg-color-white">
          <div class="flex-col-12 padding-0">
            {{ls.locs['locReisekonfigurator'].Reisepreis}}
          </div>
          <div class="flex-col-8 flex-col-8-xs padding-left-0 font-size-s">
            {{rental['Titel']}}
          </div>
          <div class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
            <span>{{rental['Punkte'] | integer}}</span>
            <span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
          </div>
        </div>
        <div id="rk-overview-sum" class="padding-top-16 padding-bottom-16 padding-left-40 padding-right-40 text-color-white bg-color-info font-size-l">
          <div class="flex-grid flex-direction-row flex-wrap flex-align-items-end">
            <div class="flex-col-8 flex-col-8-xs">
              {{ls.locs['locReisekonfigurator'].Gesamtbetrag}}
            </div>
            <div class="flex-col-4 flex-col-4-xs flex-justify-cont-end">
              <span>{{rental['Punkte'] | integer}}</span>
              <span class="currency margin-left-4">
                {{ls.locs['loc'].WaehrungPunkte}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="margin-top-32 order-process rk-bestaetigung">
        <mp-core-info-notification iconPosition="top" infoState="info" class="margin-0 padding-bottom-32 display-block">
          <span [innerHTML]="ls.locs['locReisekonfigurator'].BestellBestaetigungInfo.replace('{0}', role).replace('{1}', orderNo)"></span>
        </mp-core-info-notification>
        <div class="bg-color-dark flex-grid margin-0 flex-col-12 flex-justify-cont-center flex-align-items-center padding-top-8 padding-bottom-8 margin-bottom-4 margin-top-24">
          {{ls.locs['locReisekonfigurator'].IhreBuchungsnummer}}:
          <span class="bold margin-left-8">{{orderNo}}</span>
        </div>
        <div *ngIf="!mpSettings.settings['DisplaySettings'].HideHotline" id="order-confirmation-info-block" class="bg-color-dark flex-grid margin-0 flex-col-12 flex-justify-cont-center flex-align-items-center padding-top-32 padding-bottom-32 flex-direction-column">
          <p class="text-transform-uppercase font-size-px-24 margin-bottom-4 bold">{{ls.locs['locReisekonfigurator'].FragenZurBestellung}}</p>
          <p>
            {{ls.locs['loc'].ServiceZurVerfuegung}}
          </p>
          <div class="flex-grid margin-left-0 margin-right-0 margin-top-16" id="order-confirmation-info-contacts">
            <div id="order-confirmation-contact" class="flex-col-6 flex-justify-cont-center text-align-center">
              <span class="font-size-px-24" [innerHTML]="ls.locs['loc'].KontaktformularBestellung.replace('{0}', role).replace('{1}', orderNo)"></span>
            </div>
            <div id="order-confirmation-hotline" class="flex-col-6 flex-align-items-center flex-direction-column">
              <div class="font-size-px-24">
                {{ls.locs['loc'].RufenSieUnsAn}}
              </div>
              <span class="text-color-info font-size-px-24">{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}</span>
              <div class="kontaktServiceTimes">
                <span>{{ls.locs['loc'].Supporttool_Zeiten}}</span>
              </div>
              <div class="kontaktServiceTimes">
                <span>{{ls.locs['loc'].SupportKosten}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mp-core-micro-copy infoState="inactive" copyText="{{ls.locs['locReisekonfigurator'].BuchungsnummerBeiKontaktNennen}}" class="margin-top-16"></mp-core-micro-copy>
      <hr class="show-on-medium margin-top-16 margin-botto-40 full-hr" />
      <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-end hide-on-medium">
        <a class="mp-button" href="/">
          {{ls.locs['loc'].ZurueckZurStartseite}}
        </a>
      </div>
    </div>
    <div class="margin-top-16 margin-bottom-16">
      <div id="rk-overview-mietwagen" class="bg-color-white">
        <div id="rk-overview-img" style="background-image: url('{{rental['Image']}}')"></div>
        <div class="flex-grid flex-direction-column padding-top-16 padding-bottom-16 padding-left-32 padding-right-32">
          <div class="flex-col-12 padding-top-12">
            <h3 class="margin-bottom-0">{{rental['Titel']}}</h3>
          </div>
          <div class="flex-col-12 flex-align-items-center">
            <span class="font-size-s">{{rental['Fahrzeugtyp']}}, {{rental['Anbieter']}}</span>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">
              {{ls.locs['locReisekonfigurator'].Abholort}}
            </div>
            <div class="font-size-s margin-top-4 display-flex flex-direction-column">
              <span [innerText]="rental['StandortAbholung'].Name"></span>
              <span [hidden]="!rental['StandortAbholung'].AddressLine && !rental['StandortAbholung'].AddressLine2">{{rental['StandortAbholung'].AddressLine}} {{rental['StandortAbholung'].AddressLine2}}</span>
              <span>{{rental['StandortAbholung'].Street}} {{rental['StandortAbholung'].Street2}} {{rental['StandortAbholung'].StreetNumber}}</span>
              <span>{{rental['StandortAbholung'].Country}}-{{rental['StandortAbholung'].ZipCode}} {{rental['StandortAbholung'].CityName}}<span [hidden]="!rental['StandortAbholung'].County"> ({{rental['StandortAbholung'].County}})</span></span>
              <span>{{rental['Abholzeitpunkt']}}</span>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">
              {{ls.locs['locReisekonfigurator'].Rueckgabeort}}
            </div>
            <div class="font-size-s margin-top-4 display-flex flex-direction-column">
              <span [innerText]="rental['StandortRueckgabe'].Name"></span>
              <span [hidden]="!rental['StandortRueckgabe'].AddressLine && !rental['StandortRueckgabe'].AddressLine2">{{rental['StandortRueckgabe'].AddressLine}} {{rental['StandortRueckgabe'].AddressLine2}}</span>
              <span>{{rental['StandortRueckgabe'].Street}} {{rental['StandortRueckgabe'].Street2}} {{rental['StandortRueckgabe'].StreetNumber}}</span>
              <span>{{rental['StandortRueckgabe'].Country}}-{{rental['StandortRueckgabe'].ZipCode}} {{rental['StandortRueckgabe'].CityName}}<span [hidden]="!rental['StandortRueckgabe'].County"> ({{rental['StandortRueckgabe'].County}})</span></span>
              <span>{{rental['Rueckgabezeitpunkt']}}</span>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">{{ls.locs['locReisekonfigurator'].Mietdauer}}</div>
            <div class="font-size-s margin-top-4">
              <span>{{ls.locs['locReisekonfigurator'].NTage | format:[rental['MietdauerInTagen']]}}</span>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">{{ls.locs['locReisekonfigurator'].Fahrzeugausstattung}}</div>
            <div class="font-size-s margin-top-4">
              <span *ngIf="rental['Gangschaltung'] === gearBox['Automatik']">Automatik</span>
              <span *ngIf="rental['Gangschaltung'] === gearBox['Manuell']">Manuell</span>
              <span *ngIf="rental['HasKlimaanlage']">Klimaanlage</span>
              <span *ngIf="rental['AnzahlTueren']">{{rental['AnzahlTueren'] | integer}} Türen</span>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">
              {{ls.locs['locReisekonfigurator'].Platzangebot}}
            </div>
            <div class="font-size-s margin-top-4">
              <span *ngIf="rental['AnzahlPersonen']">{{rental['AnzahlPersonen'] | integer}} Personen empfohlen</span>
              <span *ngIf="rental['AnzahlKoffer']">{{rental['AnzahlKoffer'] | integer}} Koffer empfohlen</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="rk-bottom-buttons" class="show-on-medium bg-color-white position-fixed bottom-0 width-full" sticky-bottom>
    <div class="flex-grid flex-direction-row flex-wrap">
      <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-end">
        <a class="mp-button" href="/">
          {{ls.locs['loc'].ZurueckZurStartseite}}
        </a>
      </div>
    </div>
  </div>
</div>
