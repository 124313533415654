import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpTcPermissionsService } from './../../services/mp-tc-permissions.service';

import { TcPermissions } from './../../services/interfaces/tc-permissions';

/**
 * This class provides the functions
 * for the hotel card.
 */
@Component({
  selector: 'mp-rk-hotel-card',
  templateUrl: './hotel-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HotelCardComponent implements OnInit, OnDestroy {

  @Input() public hotel: { [key: string]: any } = {};
  @Input() public searchParams: { [key: string]: any } = {};
  @Input() public grouping: { [key: string]: any } = {};
  @Input() public filteredHotels: Array<any> = [];
  @Input() public getParamsForGetHotels: any;
  @Input() public filterOptionsMaxPrice: number = 0;;
  @Input() public selectedCategory: { [key: string]: any } = {};
  @Input() public filter: { [key: string]: any } = {};
  @Input() public sorting: { [key: string]: any } = {};
  @Input() public travelType: number = 0;
  @Input() public setOpenedOffersFunc: any;

  @Output() filterOptionsMaxPriceChange = new EventEmitter<number>();

  public permissions: TcPermissions = {
    CanUseReisekonfigurator: false
  };

  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _permissionService: MpTcPermissionsService
  ) { }

  /**
   * Gets the travel configurator permissions.
   */
  ngOnInit(): void {
    if (typeof this.hotel['open'] === 'undefined' || this.hotel['open'] === null) {
      this.hotel['open'] = false;
    }

    if (this._permissionService.permissionsReadyLoaded) {
      this.permissions = this._permissionService.permissions;

      if (typeof this._getPermissionsSubscription !== 'undefined') {
        this._getPermissionsSubscription.unsubscribe();
      }
    } else {
      this._getPermissionsSubscription = this._permissionService.permissionsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.permissions = this._permissionService.permissions;

          if (typeof this._getPermissionsSubscription !== 'undefined') {
            this._getPermissionsSubscription.unsubscribe();
          }
        }
      });

      this._permissionService.getPermissions();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the opened offers.
   */
  setOpenedOffers(): void {
    if (typeof this.setOpenedOffersFunc !== 'undefined') {
      this.setOpenedOffersFunc();
    }
  }

}
