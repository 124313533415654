import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the prize celendar
 * statistic table.
 */
@Component({
  selector: 'mp-gk-prize-calendar-statistic',
  templateUrl: './prize-calendar-statistic.component.html'
})
export class PrizeCalendarStatisticComponent implements OnInit {

  public excelService: string = '/api/GewinnkalenderStatistik/GewinnkalenderStatistik2Excel';
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openGewinnerStatistik',
      func: this.openWinnerStatistic.bind(this)
    }
  ];
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _prizeCalendarStatisticLoader: MpLoader = {
    name: 'prizeCalendarStatisticLoader',
    params: {}
  };
  private _role: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoader: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets the loader for the table data.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this._mpLoader.registerLoader(this._prizeCalendarStatisticLoader);
    this._mpLoader.extendLoader('prizeCalendarStatisticLoader', {
      isReady: true
    });
    this.loader = this._mpLoader.getLoader('prizeCalendarStatisticLoader');

    this._titleService.setTitleFromLoc('GewinnkalenderStatistik', 'locGewinnkalender');
    this._mpMenuHighlight.setMenuHighlight('statistiken');
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('prizeCalendarStatisticLoader');
  }

  /**
   * Openes the clicked prize calendar for
   * detailed statistics.
   */
  openWinnerStatistic(row: any): void {
    this._router.navigateByUrl(`/${this._role}/GewinnkalenderGewinnerStatistik?key=${row['GewinnkalenderKey']}&nr=${row['Nummer']}`);
  }

}
