<div class="border-top border-bottom flex-grid flex-direction-row flex-justify-cont-center flex-align-items-center padding-bottom-16 padding-top-16 margin-bottom-32">
  <div *ngFor="let step of steps"
       [ngClass]="{twoStepsDone: step['Index'] === 0 && currentStepIndex > 1, done: step['Index'] < currentStepIndex, current: step['Index'] === currentStepIndex, following: step['Index'] > currentStepIndex}"
       class="display-flex flex-direction-row margin-right-40 single-step repeat-order-steps">
    <a (click)="goToStep(step, $event)"
       class="flex-col-12 flex-direction-row flex-justify-cont-center flex-align-items-center">
      <mp-core-tooltip class="order-process-step-cnt margin-right-16 display-flex flex-justify-cont-center flex-align-items-center" tooltipTitle="{{step['Title']}}">
        <i class="svg-icon __clr-process-stepper __size-20 __top-0 text-align-center"
           [ngClass]="step['Index'] < currentStepIndex ? '__done' : ''">
          {{step['Index'] < currentStepIndex ? '' : (step['Index'] + 1)}}
        </i>
      </mp-core-tooltip>
      <span [innerText]="step['Title']"></span>
    </a>
  </div>
</div>
