<div class="layout-container bg-color-white padding-bottom-48" id="cookies-page" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="white-content-container" *ngIf="textsLoaded">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <h1 [innerHTML]="text['PreparedTitle']"></h1>
    <div class="margin-top-32" [innerHTML]="text['PreparedText']"></div>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowDisableTrackingSwitch && !optOutInText && mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik" id="matomo-opt-out" class="margin-top-32">
      <mp-core-checkbox class="margin-bottom-24" mpId="datenschutzCheckbox" [model]="trackingOn" (modelChange)="trackingOn = $event; handleTrackingOn()" label="{{ ls.locs['loc'].DisableCookieText }}"></mp-core-checkbox>
    </div>
  </div>
</div>
