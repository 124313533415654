import { Injectable } from '@angular/core';

import { SizeCheckService } from './size-check.service';

/**
 * This class provides the function to
 * adjust the calendar within the document.
 */
@Injectable({
  providedIn: 'root'
})
export class AdjustCalendarService {

  constructor(
    private _sizeCheck: SizeCheckService
  ) { }

  /**
   * Adjusts the calendar.
   */
  adjustCalendar(): void {
    if (this._sizeCheck.sizeCheck()) {
      const calendarSection = document.getElementById('calender');

      if (calendarSection !== null) {
        if (!document.body.classList.contains('win-calender-body')) {
          document.body.classList.add('win-calender-body');
        }

        const benefitDoor = document.querySelector('.benefit-door');

        if (benefitDoor !== null) {
          const pixelsToMove = ((calendarSection.getBoundingClientRect().width - window.innerWidth) / benefitDoor.getBoundingClientRect().width * benefitDoor.getBoundingClientRect().width) / 2;
          calendarSection.style.marginLeft = `-${pixelsToMove}px`;
        }
      }
    }
  }
}
