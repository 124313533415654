import { Directive, Input, ElementRef, HostListener } from '@angular/core';

/**
 * This class provides the functions to
 * set an error src for images.
 */
@Directive({
  selector: '[mpCoreErrSrc]'
})
export class ErrSrcDirective {

  @Input() public errSrc: string = '';
  @Input() public errSrc2: string = '';

  constructor(
    private _elementRef: ElementRef
  ) { }

  /**
   * Switches to the err-src, when src
   * is not loaded.
   */
  @HostListener('error')
  onerror() {
    if (this.errSrc !== '') {
      const imgElement = this._elementRef.nativeElement;

      if (this.errSrc === imgElement.getAttribute('src') && this.errSrc2 !== '') {
        imgElement.setAttribute('src', this.errSrc2);
      } else if (this.errSrc !== imgElement.getAttribute('src')) {
        imgElement.setAttribute('src', this.errSrc);
      }
    }
  }

}
