<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].PunkteverfallReporting }}</h1>
  <mp-core-data-table *ngIf="quartersLoaded" title="{{ ls.locs['locLeas'].PunkteverfallReporting }}"
                      infotextShorten="PunkteverfallReporting"
                      fieldsAction="/api/Punkteverfall/getPunkteverfallReportingFields"
                      listAction="/api/Punkteverfall/getPunkteverfallReporting"
                      loaderName="leasPointsDecayReportingLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="Benutzer"
                      [headerSelectTransclude]="true">
    <mp-core-select label="{{ ls.locs['locLeas'].Quartal }}" mpId="punkteverfall-reporting-quartal" [model]="quarter" [mpRequired]="true" [elements]="quarterList" textProperty="Bezeichnung" (modelChange)="quarter = $event; changeQuarter()"></mp-core-select>
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
