<section *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)" id="kontaktCam" class="layout-container">
  <div class="card">
    <h2 class="margin-top-12 margin-left-16 text-color-brand-primary">Seite {{pageIndex}} von {{schoolingData['Seiten']}}</h2>
    <div class="rich-media">
      <img style="width: 100%" src="{{schoolingData['FolienPfad']}}{{schoolingData['Id']}}/Folie{{pageIndex}}.jpg?width={{ mpSettings.settings['DisplaySettings'].BuehnenBildBreite }}&mode=crop"
           alt="">
    </div>
    <a *ngIf="pageIndex > 1 && pageIndex <= schoolingData['Seiten']" href="#/{{role}}/SchulungSeite" (click)="indexDecrement($event)">
      <button class="mp-button _prim margin-top-bottom-24">
        {{ ls.locs['locSchulung'].VorherigeSeite }}
      </button>
    </a>
    <a *ngIf="pageIndex < schoolingData['Seiten']" href="#/{{role}}/SchulungSeite?schoolingId={{schoolingData['Id']}}" (click)="indexIncrement($event)">
      <button class="mp-button _prim margin-top-bottom-24 " style="float: right;">
        {{ ls.locs['locSchulung'].NaechsteSeite }}
      </button>
    </a>
    <!--<button *ngIf="pageIndex === 7 || pageIndex === 8 ||pageIndex === 9 || pageIndex === 11" class="mp-button _prim margin-top-bottom-24 margin-right-48" style="float: right;" target="_blank" (click)="startDetails('/themes/default/media/schulung/20210810_Demov5_Schulung_Detailseite.pdf', $event)">
      {{ ls.locs['locSchulung'].Details }}
    </button>-->
    <a href="#/{{role}}/SchulungStart?trainingCompleted=true&id={{schoolingData['Id']}}" *ngIf="pageIndex === schoolingData['Seiten'] && !isTrainingCompleted" (click)="saveClosingSchooling()">
      <button [hidden]="isTrainingCompleted" class="mp-button _prim margin-top-bottom-24 " style="float: right;">
        {{ ls.locs['locSchulung'].SchulungBestaetigen }}
      </button>
    </a>
  </div>
</section>
