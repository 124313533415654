<div class="margin-bottom-24 repeat-address" *ngFor="let adr of ptcpntAddresses">
  <div *ngIf="(ls.locsLoaded | async)" class="row card" [ngClass]="adr['IsStdAdresse'] ? 'isStdAdresse' : 'padding-left-right-24'">
    <div class="grid col-large-4">
      <mp-core-select class="margin-bottom-8 display-block"
                      label="{{ ls.locs['loc'].LKZ }}"
                      mpId="land"
                      [elements]="ddlData['Laenderliste']"
                      textProperty="Land"
                      valueProperty="Kuerzel"
                      [(model)]="adr['LKZ']"
                      [mpDisabled]="profileCanUserEditDisabled || adr['AdressTyp'] === 1"
                      [mpRequired]="true">
      </mp-core-select>
      <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.LKZ']" [model]="ptcpntAddressesErrors['data.LKZ']"></mp-core-form-error>

      <mp-core-select class="margin-bottom-8 display-block"
                      label="{{ ls.locs['loc'].Anrede }}"
                      mpId="anrede"
                      [elements]="adr['AnredenListe']"
                      textProperty="Anrede"
                      [mpRequired]="true"
                      [(model)]="adr['AnredeEntityObjekt']"
                      [mpDisabled]="profileCanUserEditDisabled || adr['AdressTyp'] === 1">
      </mp-core-select>
      <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Anrede']" [model]="ptcpntAddressesErrors['data.Anrede']"></mp-core-form-error>

      <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Vorname }}" [mpRequired]="true" [(model)]="adr['Vorname']" [mpDisabled]="profileCanUserEditDisabled || adr['AdressTyp'] === 1"></mp-core-input>
      <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Vorname']" [model]="ptcpntAddressesErrors['data.Vorname']"></mp-core-form-error>

      <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Name }}" [mpRequired]="true" [(model)]="adr['Name']" [mpDisabled]="profileCanUserEditDisabled || adr['AdressTyp'] === 1"></mp-core-input>
      <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Name']" [model]="ptcpntAddressesErrors['data.Name']"></mp-core-form-error>

      <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].FirmaInAdresse }}" [(model)]="adr['Firma']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>

    </div>
    <div class="grid col-large-4">
      <div [ngSwitch]="adr['Land'].Kuerzel">
        <div *ngSwitchCase="'DE'">
          <div [ngSwitch]="adr['AdressArtID']">
            <div *ngSwitchCase="2">
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Packstation }}" [mpRequired]="true" [(model)]="adr['Strasse']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Strasse']" [model]="ptcpntAddressesErrors['data.Strasse']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].PostNummer }}" [mpRequired]="true" [(model)]="adr['Adresszusatz']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Adresszusatz']" [model]="ptcpntAddressesErrors['data.Adresszusatz']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].PLZ }}" [mpRequired]="true" [(model)]="adr['PLZ']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.PLZ']" [model]="ptcpntAddressesErrors['data.PLZ']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Ort }}" [mpRequired]="true" [(model)]="adr['Ort']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Ort']" [model]="ptcpntAddressesErrors['data.Ort']"></mp-core-form-error>
              <a href="http://standorte.dhl.de/packstationen_paketboxen" target="_blank">Packstation finden</a>
            </div>
            <div *ngSwitchCase="3">
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].NummerDerPostfiliale }}" [mpRequired]="true" [(model)]="adr['Strasse']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Strasse']" [model]="ptcpntAddressesErrors['data.Strasse']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].PostNummer }}" [mpRequired]="true" [(model)]="adr['Adresszusatz']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Adresszusatz']" [model]="ptcpntAddressesErrors['data.Adresszusatz']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].PLZ }}" [mpRequired]="true" [(model)]="adr['PLZ']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.PLZ']" [model]="ptcpntAddressesErrors['data.PLZ']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Ort }}" [mpRequired]="true" [(model)]="adr['Ort']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Ort']" [model]="ptcpntAddressesErrors['data.Ort']"></mp-core-form-error>
              <a href="http://standorte.dhl.de/packstationen_paketboxen" target="_blank">Postfiliale finden</a>
            </div>
            <div *ngSwitchDefault>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Strasse_Nr }}" [mpRequired]="true" [(model)]="adr['Strasse']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Strasse']" [model]="ptcpntAddressesErrors['data.Strasse']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Adresszusatz }}" [(model)]="adr['Adresszusatz']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Adresszusatz']" [model]="ptcpntAddressesErrors['data.Adresszusatz']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].PLZ }}" [mpRequired]="true" [(model)]="adr['PLZ']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.PLZ']" [model]="ptcpntAddressesErrors['data.PLZ']"></mp-core-form-error>
              <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Ort }}" [mpRequired]="true" [(model)]="adr['Ort']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
              <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Ort']" [model]="ptcpntAddressesErrors['data.Ort']"></mp-core-form-error>
            </div>
          </div>
        </div>
        <div *ngSwitchDefault>
          <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Strasse_Nr }}" [mpRequired]="true" [(model)]="adr['Strasse']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
          <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Strasse']" [model]="ptcpntAddressesErrors['data.Strasse']"></mp-core-form-error>
          <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Adresszusatz }}" [(model)]="adr['Adresszusatz']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
          <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Adresszusatz']" [model]="ptcpntAddressesErrors['data.Adresszusatz']"></mp-core-form-error>
          <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].PLZ }}" [mpRequired]="true" [(model)]="adr['PLZ']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
          <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.PLZ']" [model]="ptcpntAddressesErrors['data.PLZ']"></mp-core-form-error>
          <mp-core-input class="margin-bottom-8 display-block" label="{{ ls.locs['loc'].Ort }}" [mpRequired]="true" [(model)]="adr['Ort']" [mpDisabled]="profileCanUserEditDisabled"></mp-core-input>
          <mp-core-form-error *ngIf="ptcpntAddressesErrors['data.Ort']" [model]="ptcpntAddressesErrors['data.Ort']"></mp-core-form-error>
        </div>
      </div>
      <div *ngIf="canCurrentUserEdit">
        <button class="mp-button margin-bottom-8 _prim" (click)="saveAddress(adr)">{{ ls.locs['loc'].AdresseAendern }}</button>
      </div>
      <button class="mp-button _prim margin-bottom-8" *ngIf="isAdmin && !adr['IsStdAdresse']" (click)="setPtcpntStdAddress(adr)">{{ ls.locs['loc'].AlsStandardadresseDefinieren }}</button>
    </div>
  </div>
</div>
