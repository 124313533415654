import { Component, OnInit } from '@angular/core';

import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class does a redirect to
 * the first step of the communication
 * process (just to clean cache).
 */
@Component({
  selector: 'mp-core-communication-process-start',
  templateUrl: './communication-process-start.component.html'
})
export class CommunicationProcessStartComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Get's the role and redirects
   * to first step of communication
   * process.
   */
  ngOnInit(): void {
    const i = window.location.href.indexOf('?');
    const params = i < 0 ? '' : window.location.href.substr(i);
    const role = this._authService.getRole();
    let roleForRoute = '';

    if (typeof role === 'object') {
      roleForRoute = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      roleForRoute = this._roleMapping.getReverseMappedRole(role);
    }

    window.location.href = `/#/${roleForRoute}/TeilnehmerAuswahl${params}`;
  }

}
