<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].KontoStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].KontoStatistik }}"
                 infotextShorten="KontoStatistik"
                 fieldsAction="/api/Statistik/getKontoStatistikFields"
                 listAction="/api/Statistik/getKontoStatistik"
                 [recLoadedFunc]="recLoadedFunc"
                 [showDatePicker]="true"
                 loaderName="adminAccountStatisticLoader"
                 [execClickFunctions]="execClickFunctions"
                 [paging]="true"
                 [footer]="true"
                 [alwaysShowFilter]="true"
                 defaultSorting="AnlageDatum DESC"></mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}" class="mp-button _prim margin-top-24 float-right">
      {{ ls.locs['loc'].Export }}
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>

<mp-core-modal [show]="confirmDelete"
          title="{{ ls.locs['loc'].KontomodulConfirmDeleteTitle }}"
          [buttons]="[{text: ls.locs['loc'].Nein, action: toggleDeleteAccount}, {text: ls.locs['loc'].Ja, action: deleteAccount, class: '_prim'}]">
  <span>
    {{ ls.locs['loc'].KontomodulConfirmDeleteText }}
  </span>
</mp-core-modal>
