import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpCoreService } from './../../../../services/mp-core.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the points-decay-euro per 
 * month details table.
 */
@Component({
  selector: 'mp-core-points-decay-euro-per-month-details',
  templateUrl: './points-decay-euro-per-month-details.component.html'
})
export class PointsDecayEuroPerMonthDetailsComponent implements OnInit, OnDestroy {

  public dateParamsLoaded: boolean = false;
  public excelService: string = '/api/Statistik/PunkteverfallEuroProMonatDetails2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public title: string = '';

  private _pointsDecayEuroPerMonthDetailsLoader: MpLoader = {
    name: 'pointsDecayEuroPerMonthDetailsLoader',
    params: {}
  };
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _route: ActivatedRoute,
    private _mpCoreService: MpCoreService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions. Gets the query params.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('PunkteverfallEuroProMonat');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._pointsDecayEuroPerMonthDetailsLoader);

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['m'] !== 'undefined' && typeof params['y'] !== 'undefined') {
        this._mpLoaderService.extendLoader('pointsDecayEuroPerMonthDetailsLoader', {
          isReady: true,
          params: {
            Monat: params['m'],
            Jahr: params['y']
          }
        });

        this.title = moment(new Date(params['y'], params['m'] - 1, 1)).format('MMMM YYYY');
        this.dateParamsLoaded = true;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('pointsDecayEuroPerMonthDetailsLoader');
  }

  /**
   * Triggers the mpCoreService goBack.
   */
  goBack(): void {
    this._mpCoreService.goBack();
  }

}
