<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1>{{ ls.locs['loc'].ImportiereAktionsbestellungen }}</h1>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].Aktionsname }}"
                   mpId="txtAktionsKey"
                   mpType="text"
                   [(model)]="campaignKey"
                   class="fallback-input"
                   [hasError]="errorCampaignKey !== '' ? true : false"
                   [mpRequired]="true">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorCampaignKey !== ''" [model]="errorCampaignKey"></mp-core-form-error>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['locLeas'].SboVerrechnungshinweis }}"
                   mpId="txtVerrechnungshinweis"
                   mpType="text"
                   [(model)]="offsettingHint"
                   [hasError]="errorOffsettingHint !== '' ? true : false"
                   class="fallback-input"
                   [mpRequired]="true">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorOffsettingHint !== ''" [model]="errorOffsettingHint"></mp-core-form-error>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['locLeas'].Lager }}"
                   mpId="txtLager"
                   mpType="text"
                   [(model)]="warehouse"
                   [hasError]="errorWarehouse !== '' ? true : false"
                   class="fallback-input"
                   [mpRequired]="true">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorWarehouse !== ''" [model]="errorWarehouse"></mp-core-form-error>
    <br />
    <div class="margin-left-32" [innerHTML]="ls.locs['locLeas'].LagerHinweis"></div>
  </div>
  <mp-core-checkbox class="margin-bottom-24"
                    mpId="chkAktionsnameAlsKontoBezeichnung"
                    [(model)]="campaignNameInAccount"
                    label="{{ ls.locs['locLeas'].AktionsnameAlsKontoBezeichnung }}">
  </mp-core-checkbox>
  <mp-core-checkbox class="margin-bottom-24"
                    mpId="chkBestellMailVersenden"
                    [(model)]="sendOrderMail"
                    label="{{ ls.locs['locLeas'].BestellMailVersenden }}">
  </mp-core-checkbox>
  <div class="upload-buehne margin-bottom-24">
    <button type="button"
            id="btn-upload-buehne"
            class="mp-button _prim nonefloat distanceforButton"
            (click)="fileUpload.click()">
      {{ ls.locs['locLeas'].ExcelListeHochladen }}
    </button>
    <input #fileUpload
           name="fileUpload"
           type="file"
           class="display-none"
           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           (change)="addAttachment($event)">
    <div *ngIf="file"
         class="margin-top-16">
      <span>{{file.name}}</span>
      <span class="icon icon-Icons_Check ampelGelb"
            [hidden]="uploadSuccessfully">
      </span>
      <span class="icon icon-Icons_Check ampelGruen"
            [hidden]="!uploadSuccessfully">
      </span>
    </div>
    <br />
    <span class="inputRequired"
          [hidden]="errorUpload === ''"
          [innerText]="errorUpload">
    </span>
  </div>
  <mp-core-checkbox class="margin-bottom-24"
                    mpId="chkEmailVersenden"
                    [(model)]="sendCampaignEmail"
                    label="{{ ls.locs['locLeas'].ZusaetzlicheEMailVersenden }}">
  </mp-core-checkbox>
  <div *ngIf="sendCampaignEmail">
    <div>
      <mp-core-input type="text"
                     id="txtBetreff"
                     class="form-control fallback-input"
                     label="{{ ls.locs['loc'].Betreff }}"
                     [(model)]="subject"
                     [hasError]="errorSubject !== '' ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errorSubject !== ''"
                          [model]="errorSubject">
      </mp-core-form-error>
    </div>
    <div class="position-relative z-index-3">
      <mp-core-select mpId="ddlTbs"
                      class="margin-bottom-24 margin-top-24 fallback-select"
                      [(model)]="selectedTBS"
                      label="{{ ls.locs['loc'].Textbausteineinfuegen }}"
                      (ngChange)="tbsChange(selectedTBS)"
                      textProperty="Text"
                      [elements]="tbsList">
      </mp-core-select>
    </div>
    <div class="ui-helper-clearfix"></div>
    <textarea class="margin-bottom-24"
              *ngIf="froalaOptionsLoaded"
              [froalaEditor]="froalaOptions"
              (froalaInit)="getFroalaControls($event)"
              [(froalaModel)]="body"
              id="editorAktionsMail"
              name="editorAktionsMail"
              [ngClass]="errorBody ? 'invalid-error' : ''"
              [attr.placeholder]="ls.locs['loc'].Nachrichtentext + ' ' + ls.locs['loc'].VerfassenKlein">
    </textarea>
    <mp-core-form-error *ngIf="errorBody !== ''"
                        [model]="errorBody">
    </mp-core-form-error>
  </div>
  <div class="margin-top-24">
    <button type="button"
            class="mp-button _prim"
            [attr.disabled]="saveInProgress ? '' : null"
            (click)="importOrders()">
      {{ ls.locs['locLeas'].ImportiereAktionsbestellungen }}
    </button>
  </div>
  <div class="margin-top-24"
       [hidden]="messages === ''">
    <h2>{{ ls.locs['locLeas'].Ergebnis }}</h2>
    <span [innerHTML]="messages"></span>
  </div>
</div>
