<div *ngIf="(ls.locsLoaded | async)">
  <div class="card margin-bottom-16 height-full">
    <div class="flex-grid height-full">
      <div class="flex-col-12 show-on-mobile">
        <div class="primary-title">
          <div class="subtitle display-block font-size-general"
                [hidden]="!dash['Subtitle']"
                [innerText]="dash['Subtitle']"></div>
          <div class="title display-block font-size-general bold"
                [innerHTML]="dash['Title']"></div>
        </div>
      </div>
      <div class="flex-col-5 flex-col-4-m flex-col-12-s padding-0 padding-left-8">
        <div class="rich-media overflow-hidden text-align-center font-size-xxl width-full display-flex flex-align-items-center padding-left-12 padding-right-12">
          <img class="float-left z-index-1 width-full"
                *ngIf="dash['BackgroundImage']"
                src="dash['BackgroundImage']" />
          <div class="width-full">
            <span class="manager-dashboard-value">{{dash['Value']}}</span>
          </div>
        </div>
      </div>
      <div class="flex-col-12-s flex-col-7 flex-col-8-m flex-direction-column flex-justify-cont-between padding-0">
        <div class="primary-title hide-on-mobile">
          <div class="title display-block font-size-general bold"
               [innerHTML]="dash['Title']"></div>
          <div class="subtitle display-block font-size-general"
                [hidden]="!dash['Subtitle']"
                [innerText]="dash['Subtitle']"></div>
        </div>
        <div class="supplemental-actions padding-top-bottom-8 padding-left-right-0 bg-color-white">
          <div class="row-inner">
            <div>
              <a class="mp-button details bold margin-left-0"
                 [hidden]="!dash['Link']"
                 href="{{dash['Link']}}">{{ ls.locs['loc'].WeitereDetails }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
