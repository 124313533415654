<section id="summer-race-overview" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="width-full position-relative">
    <img src="/themes/default/media/racing/titelgrafik_desktop.svg" class="hide-on-mobile width-full" alt="{{ ls.locs['locGamification'].SummerRace }}" title="{{ ls.locs['locGamification'].SummerRace }}">
    <img src="/themes/default/media/racing/titelgrafik_mobile.svg" class="show-on-mobile width-full" alt="{{ ls.locs['locGamification'].SummerRace }}" title="{{ ls.locs['locGamification'].SummerRace }}">
    <div id="summer-race-buttons" class="display-flex flex-align-items-center flex-justify-cont-center position-absolute width-full padding-left-right-32">
      <h1 class="text-align-center margin-bottom-32">{{ ls.locs['locGamification'].SummerRaceTitel }} '{{summerRaceTitle}}'</h1>
      <a href="/themes/default/media/racing/Spielregeln.pdf" target="_blank" class="mp-button _sec margin-right-16">{{ ls.locs['loc'].Spielregeln }}</a>

      <a *ngIf="mpSettings.settings['GamificationSettings'].AlwaysGotoBoxengasse" href="" class="mp-button _prim" (click)="toPit($event)">
        <div>{{ ls.locs['locGamification'].JetztTeilnehmen }}</div>
      </a>

      <a *ngIf="!mpSettings.settings['GamificationSettings'].AlwaysGotoBoxengasse" href="" class="mp-button _prim" (click)="participate($event)">
        <div [hidden]="!isRacer">{{ ls.locs['locGamification'].ZurRennstrecke }}</div>
        <div [hidden]="isRacer">{{ ls.locs['locGamification'].JetztTeilnehmen }}</div>
      </a>

      <a href="/themes/default/media/racing/Gewinne.pdf" target="_blank" class="mp-button _sec margin-left-16">{{ ls.locs['locGamification'].Gewinne }}</a>
    </div>
  </div>
</section>
