import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { MpLocalizationService } from '../../services/mp-localization.service';

/**
 * This class provides the category
 * dropdown for the filter in shops.
 */
@Component({
  selector: 'mp-core-category-card-list',
  templateUrl: './category-card-list.component.html',
  styleUrls: ['./category-card-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryCardListComponent implements OnInit, OnDestroy {

  @Input() public showCategories: boolean = false;
  @Input() public showNewItems: boolean = false;
  @Input() public showThemeAreas: boolean = false;
  @Input() public showMegatrends: boolean = false;
  @Input() public showRandom: boolean = false;

  public filteredCategories: any[] = [];

  private _getActionsSusbcription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService) { }

  /**
   * Angulars init function. Loads the
   * categories to show and filters by the
   * defined parameters
   */
  ngOnInit(): void {
    var url: string = this.showRandom ? '/api/Kategorien/getRandomForHome' : '/api/Kategorien/getKategorienForMenu';

    this._getActionsSusbcription = this._apiService.getRequest(url)
      .subscribe((data: any) => {
        if (data.Result === "OK") {
          var records = data.Records;

          this.filteredCategories = records.filter((r: any) => {
            return this.showRandom ||
              r.Typ === 1 && this.showCategories ||
              r.Typ === 5 && this.showNewItems ||
              r.Typ === 3 && this.showMegatrends ||
              r.Typ === 4 && this.showThemeAreas;
          })
        }
      },
      (error: any) => {
        console.log(error);
      });
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getActionsSusbcription !== 'undefined') {
      this._getActionsSusbcription.unsubscribe();
    }
  }

}
