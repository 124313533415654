import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLoaderService } from '@core/services/mp-loader.service';
import { ApiService } from '@core/services/api.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';


/**
 * This class provides the data and functions
 * for the account table.
 */
@Directive({
  selector: '[mpLeasAccount]',
  exportAs: 'mpLeasAccount'
})
export class AccountDirective implements OnInit, OnDestroy {

  @Input() searchTerm: string = '';

  public loaderRegistered: boolean = false;

  private _leasAccountTableLoader: MpLoader = {
    name: 'leasAccountTableLoader',
    params: {}
  };
  private _selection: Array<any> = [];
  private _getAccountTypesSubscription: Subscription | undefined;

  constructor(
    private _mpLoaderService: MpLoaderService,
    private _apiService: ApiService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Gets tht account types.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._leasAccountTableLoader);
    this.loaderRegistered = true;

    this._getAccountTypesSubscription = this._apiService.getRequest('/api/Konto/getKontoarten').subscribe((data: any) => {
      this._selection = data.Records;

      this._mpLoaderService.extendLoader(this._leasAccountTableLoader.name, {
        params: {
          Array: this._selection.map((item: any) => { return item['Status']; })
        },
        isReady: true
      });
    });

    this._mpMenuHighlight.setMenuHighlight('konto');

    if (this._route.snapshot.pathFromRoot[2].routeConfig !== null && this._route.snapshot.pathFromRoot[2].routeConfig.path === 'Konto') {
      this._titleService.setTitleFromLoc('Konto');
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getAccountTypesSubscription !== 'undefined') {
      this._getAccountTypesSubscription.unsubscribe();
    }
  }

  /**
   * Triggers a search.
   */
  search(): void {
    const loader = this._mpLoaderService.getLoader(this._leasAccountTableLoader.name);

    this._mpLoaderService.extendLoader(this._leasAccountTableLoader.name, {
      params: Object.assign(loader.params || {}, {
        SuchtextOutside: this.searchTerm
      })
    });

    if (typeof loader.load !== 'undefined') {
      loader.load();
    }
  }

}
