import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';

import { IsMobile } from './../../services/interfaces/is-mobile';

/**
 * This class provides the browser check routine.
 */
@Component({
  selector: 'mp-core-browser-support-modal',
  templateUrl: './browser-support-modal.component.html',
  styleUrls: ['./browser-support-modal.component.scss']
})
export class BrowserSupportModalComponent implements OnInit, OnDestroy {

  private _isMobile: IsMobile = {
    Android: () => { return navigator.userAgent.match(/Android/i); },
    BlackBerry: () => { return navigator.userAgent.match(/BlackBerry/i); },
    iOS: () => { return navigator.userAgent.match(/iPhone|iPad|iPod/i) || (navigator.userAgent.match(/Safari/i) && navigator.maxTouchPoints > 1); },
    Opera: () => { return navigator.userAgent.match(/Opera Mini/i); },
    Windows: () => { return navigator.userAgent.match(/IEMobile/i); },
    any: () => { return (this._isMobile.Android() || this._isMobile.BlackBerry() || this._isMobile.iOS() || this._isMobile.Opera() || this._isMobile.Windows()); }
  };

  public showBrowserNotSupported: boolean = false;
  public supportedBrowsers: any = {
    mobile: {
      chrome: {
        version: 72,
        text: 'Chrome'
      },
      firefox: {
        version: 64,
        text: 'Firefox'
      },
      firefoxiOS: {
        version: 14,
        text: 'Firefox (iOS)'
      },
      safari: {
        version: 11,
        text: 'Safari'
      },
      samsung: {
        version: 8,
        text: 'Samsung Mobile'
      }
    },
    desktop: {
      chrome: {
        version: 72,
        text: 'Chrome'
      },
      firefox: {
        version: 64,
        text: 'Firefox'
      },
      safari: {
        version: 11,
        text: 'Safari'
      },
      ie: {
        version: 11,
        text: 'Internet Explorer'
      },
      edge: {
        version: 14,
        text: 'MS Edge'
      }
    }
  };
  public nAgent: any = navigator.userAgent;
  public versionOffset: any;
  public agent: any;
  public mobileOrDesktop: any;
  public isMobile: any;

  private _getLocsSubscription: Subscription | undefined;

  public toggleBrowserNotSupported = this._toggleBrowserNotSupported.bind(this);

  constructor(
    public ls: MpLocalizationService
  ) { }

  ngOnInit(): void {
    this.checkForBrowserSupport();
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  checkOthers(): void {
    if ((this.versionOffset = this.nAgent.indexOf('Edge')) !== -1) {
      this.agent = 'edge';
    } else if ((this.versionOffset = this.nAgent.indexOf('Chrome')) !== -1) {
      this.agent = 'chrome';
    } else if ((this.versionOffset = this.nAgent.indexOf('Safari')) !== -1) {
      this.agent = 'sfari';
    } else if ((this.versionOffset = this.nAgent.indexOf('MSIE')) !== -1) {
      this.agent = 'ie';
    }
  }

  /**
  * Checks if browser is supported.
  */
  public checkForBrowserSupport(): any {
    if (this._isMobile.any()) {
      this.mobileOrDesktop = 'mobile';

      if ((this.versionOffset = this.nAgent.indexOf('Firefox')) !== -1) {
        if (this._isMobile.iOS()) {
          this.agent = 'firefoxiOS';
        } else {
          this.agent = 'firefox';
        }
      } else if ((this.versionOffset = this.nAgent.indexOf('SamsungBrowser')) !== -1) {
        this.agent = 'samsung';
      } else {
        this.checkOthers();
      }
    } else {
      this.mobileOrDesktop = 'desktop';

      if ((this.versionOffset = this.nAgent.indexOf('Firefox')) !== -1) {
        this.agent = 'firefox';
      } else {
        this.checkOthers();
      }
    }

    if (typeof this.agent === 'undefined') {
      return false;
    }

    if (typeof this.versionOffset === 'undefined') {
      return false;
    }

    var version = this.nAgent.substring(this.versionOffset + (this.agent.length + 1));

    if (version.indexOf(';') !== -1) {
      version = version.substring(0, version.indexOf(';'));
    }

    if (version.search(/([0-9])/) !== -1) {
      version = version.substring(version.search(/([0-9])/), version.length - 1);
    }

    if (version.indexOf(' ') !== -1) {
      version = version.substring(0, version.indexOf(' '));
    }

    if (version.indexOf('.') !== -1) {
      version = version.substring(0, version.indexOf('.'));
    }

    version = parseInt('' + version, 10);

    if (typeof this.supportedBrowsers[this.mobileOrDesktop][this.agent] === 'undefined') {
      return false;
    }
    if (version < this.supportedBrowsers[this.mobileOrDesktop][this.agent].version) {
      var browsers = '';

      Object.values(this.supportedBrowsers[this.mobileOrDesktop]).forEach((browser: any) => {
        browsers += browser.text + ' Version ' + browser.version + ', ';
      });

      browsers = browsers.replace(/,(?=[^,]*$)/, '');

      this.supportedBrowsers = this.ls.locs['loc'].BrowserNichtUnterstuetztHinweis.replace('{browser}', browsers);
      this.showBrowserNotSupported = true;
    }
  }

  /**
  * Toggles the browser not supported modal.
  */
  private _toggleBrowserNotSupported(): void {
    this.showBrowserNotSupported = !this.showBrowserNotSupported;
  }
}
