import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewEncapsulation, TemplateRef } from '@angular/core';

import { ModalButton } from './modal-button';

/**
 * This class provides the functionalities
 * for a modal.
 */
@Component({
  selector: 'mp-core-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {

  @Input() public title: string = '';
  @Input() public modalClass: string = '';
  @Input() public buttons: Array<ModalButton> = [];
  @Input() public show: boolean = false;
  @Input() public fixedLine: string = '';
  @Input() public centerButtons: boolean = false;
  @Input() public setModalWideClass: boolean = false;
  @Input() public actionsTemplate: TemplateRef<any> | undefined;

  private _setFixedLineWidthFunc = this._setFixedLineWidth.bind(this);
  private _handleScrolledClassFunc = this._handleScrolledClass.bind(this);

  constructor(
    private _hostElem: ElementRef
  ) { }

  /**
   * Sets event listeners, if fixedLine
   * is set.
   */
  ngOnInit(): void {
    if (this.fixedLine !== '') {
      window.removeEventListener('scroll', this._handleScrolledClassFunc);
      window.addEventListener('scroll', this._handleScrolledClassFunc);
      window.removeEventListener('resize', this._setFixedLineWidthFunc);
      window.addEventListener('resize', this._setFixedLineWidthFunc);
    }
  }

  /**
   * Watches for changes of the show
   * attribute.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['show'] !== 'undefined' && this.fixedLine !== '') {
      if (changes['show'].previousValue !== changes['show'].currentValue) {
        if (changes['show'].currentValue) {
          const copytextArea = this._hostElem.nativeElement.querySelector('.magma-modal-copy');
          this._setFixedLineWidth();
          this._handleScrolledClass({ currentTarget: copytextArea });
        }
      }
    }
  }

  /**
   * Sets the width of the fixed line.
   */
  private _setFixedLineWidth(): void {
    if (!this.show) {
      return;
    }

    const fixedLine = this._hostElem.nativeElement.querySelector(this.fixedLine);
    const copytextArea = this._hostElem.nativeElement.querySelector('.magma-modal-copy');

    if (fixedLine === null || copytextArea === null) {
      return;
    }

    fixedLine.style.width = copytextArea.clientWidth + 'px';
  }

  /**
   * Sets and removes the class, that
   * identifies whether or not the
   * modal area was scrolled.
   */
  private _handleScrolledClass(evt: any): void {
    if (evt.currentTarget === null || typeof evt.currentTarget === 'undefined') {
      return;
    }

    const copytextArea = evt.currentTarget;
    const modalScrolledClass = '_modal-scrolled';

    if (copytextArea.scrollTop > 0) {
      if (typeof copytextArea.classList !== 'undefined') {
        if (copytextArea.classList.contains(modalScrolledClass) === false) {
          copytextArea.classList.add(modalScrolledClass);
        }
      }
    } else {
      if (typeof copytextArea.classList !== 'undefined') {
        if (copytextArea.classList.contains(modalScrolledClass) !== false) {
          copytextArea.classList.remove(modalScrolledClass);
        }
      }
    }
  }

  /**
   * Executes the action of the button.
   */
  executeClick(button: ModalButton): void {
    if (typeof button.actionParam !== 'undefined') {
      button.action(button.actionParam);
    } else {
      button.action();
    }
  }

}
