<div class="row-inner" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async) && !loading">
  <div class="showbox" [hidden]="!loading">
    <div class="loader">
      <svg class="circular"
           viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50"  r="10" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
  <div class="details-header header padding-top-bottom-16 floating-container">
    <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Close }}"
                class="float-right">
      <a class="mp-button padding-left-right-8 display-flex-important flex-align-items-center flex-justify-content-center"
         (click)="onClick($event, item)">
        <i class="svg-icon __clr-default __size-18 __clear"></i>
      </a>
    </mp-core-tooltip>
    <div class="title display-block font-size-m font-weight-400">
      {{ ls.locs['loc'].BestellNr }}:&nbsp;
      <span>{{item.Key}}</span>
    </div>
  </div>
<div class="card margin-left-right-24 margin-bottom-16 border-top border-bottom border-right border-left" *ngFor="let bp of b['BestellPos']; let index = index">
<div class="row-inner">
      <div class="rich-media text-align-center grid col-medium-4">
        <img [src]="bp['Bild']"
             class="z-index-1 width-full"
             [attr.alt]="bp['ArtNrPIN']"/>
      </div>
      <div class="grid col-medium-8 border-left">
        <div class="row-inner">
          <div class="grid col-large-12">
            <div class="header padding-top-bottom-16 background--brand-primary__lighten"
                 [innerHTML]="(index+1)+'. '+bp['ArtBez']"
                 style="margin-left: -1px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="grid col-large-6">
            <div class="primary-title">
              <div class="subtitle display-block font-size-general">{{ ls.locs['loc'].Details }}</div>
            </div>
            <div class="supporting-text">
              <span [hidden]="!bp['Auspraegung']">
                <span class="bold">{{ ls.locs['loc'].Auspraegung }}:&nbsp;</span>
                <span>{{bp['Auspraegung']}}</span>
                <br />
              </span>
              <span class="bold">{{ ls.locs['loc'].ArtNrPIN }}:&nbsp;</span>
              <span>{{bp['ArtNrPIN']}}</span>
              <br />
              <span class="bold margin-top-16 display-inline-block"> {{ ls.locs['loc'].Anzahl }}:&nbsp;</span>
              <span>{{bp['Anzahl']}}</span>
              <br />
              <span class="bold margin-top-16 display-inline-block" *ngIf="bp['ExpressZusatzPunkte'] || bp['VersandZusatzPunkte']">{{ ls.locs['loc'].Gesamtpraemienwert }}:&nbsp;</span>
              <span *ngIf="bp['ExpressZusatzPunkte'] || bp['VersandZusatzPunkte']">{{bp['Praemienwert'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
              <br *ngIf="bp['ExpressZusatzPunkte'] || bp['VersandZusatzPunkte']" />
              <span class="bold" *ngIf="bp['ExpressZusatzPunkte']">{{ ls.locs['loc'].ExpressPunkte }}:&nbsp;</span>
              <span *ngIf="bp['ExpressZusatzPunkte']">{{bp['ExpressZusatzPunkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
              <br *ngIf="bp['ExpressZusatzPunkte']" />
              <span class="bold" *ngIf="bp['VersandZusatzPunkte']">{{ ls.locs['loc'].VersandPunkte }}:&nbsp;</span>
              <span *ngIf="bp['VersandZusatzPunkte']">{{bp['VersandZusatzPunkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
              <br *ngIf="bp['VersandZusatzPunkte']" />
              <span class="bold"> {{ ls.locs['loc'].Gesamtkosten }}:&nbsp;</span>
              <span>{{bp['Punkte']  | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
              <br />
              <span class="bold margin-top-16 display-inline-block" *ngIf="bp['ZuzahlungPunkte']">{{ ls.locs['locZuzahlung'].VerwendetePunkte }}:&nbsp;</span>
              <span *ngIf="bp['ZuzahlungPunkte']">{{bp['EingeloestePunkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
              <br *ngIf="bp['ZuzahlungPunkte']" />
              <span class="bold" *ngIf="bp['ZuzahlungPunkte']">{{ ls.locs['locZuzahlung'].Zuzahlung }}:&nbsp;</span>
              <span *ngIf="bp['ZuzahlungPunkte']">{{bp['ZuzahlungWert']}}</span>
              <br *ngIf="bp['ZuzahlungPunkte']" />
              <span *ngIf="bp['Verrechnungshinweis']" class="bold margin-top-16 display-inline-block">{{ ls.locs['loc'].Aktion }}:&nbsp;</span>
              <span *ngIf="bp['Verrechnungshinweis']">{{bp['Verrechnungshinweis']}}</span>
              <br />
              <a class="optionBtn"
                 *ngIf="bp['Optionen'].length > 0"
                 [ngClass]="{active: bp['showOptionen']}"
                 (click)="toggleOptions($event, bp)">
                {{ ls.locs['loc'].GewaehlteOptionen }}
              </a>
            </div>
          </div>
          <div class="grid col-large-6">
            <div class="primary-title">
              <div class="subtitle display-block font-size-general">{{ ls.locs['loc'].Lieferstatus }}</div>
            </div>
            <div class="supporting-text">
              <p [hidden]="bp['Storno']">
                <span [innerHTML]="bp['Versandstatus']"
                      [ngClass]="bp['OrderState']"
                      class="icon-text">
                </span>
              </p>
              <p [hidden]="!bp['Storno']">
                <span class="icon-text storniert">{{ ls.locs['loc'].Storno }}</span>
              </p>
              <span class="bold">{{ ls.locs['loc'].ZuletztAktualisiert }}:&nbsp;</span> <span>{{bp['LetzteAenderungAm']}}</span>
              <a class="optionBtn"
                 *ngIf="bp['Sendungsverlauf'].length > 0"
                 [ngClass]="{active: bp['showSendungsverlauf']}"
                 (click)="toggleShipmentTracking($event, bp)">
                {{ ls.locs['loc'].Sendungsverlauf }}
              </a>
            </div>
          </div>
        </div>
        <div class="orderFooter">
          <div class="optionWrapper">
            <table class="table optionItem sendungsverlauf"
                   *ngIf="bp['showSendungsverlauf']">
              <tr *ngFor="let s of bp['Sendungsverlauf']">
                <td [innerText]="s.Datum"></td>
                <td [innerText]="s.Ort"></td>
                <td class="lightFont"
                    [innerHTML]="s.Action">
                </td>
              </tr>
            </table>
            <div class="optionItem gewaelteOptionen"
                 *ngIf="bp['showOptionen']">
              <div *ngFor="let za of bp['Optionen']"
                   class="row"
                   [ngSwitch]="za.Ctrl"
                   [hidden]="za.hidden">
                <div class="col-xs-12"
                     *ngSwitchCase="'Leerzeile'">
                </div>
                <hr *ngSwitchCase="'Linie'" />
                <div class="col-xs-12"
                     *ngSwitchCase="'Label'">
                  <span [innerHTML]="za.Caption"
                        class="caption">
                  </span>
                </div>
                <fieldset class="form-group"
                          *ngSwitchCase="'Textbox'">
                  <label class="col-sm-4"
                         for="ctrl_{{za.CtrlID}}"
                         [innerHTML]="za.Caption">
                  </label>
                  <span class="col-sm-6"
                        id="ctrl_{{za.CtrlID}}"
                        [innerText]="za.Value || '-'">
                  </span>
                </fieldset>
                <fieldset class="form-group"
                          *ngSwitchCase="'Textarea'">
                  <div class="col-sm-6"
                       id="ctrl_{{za.CtrlID}}"
                       [innerText]="za.Value || '-'">
                   </div>
                </fieldset>
                <div class="form-group"
                     *ngSwitchCase="'NameVorname'">
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_name"
                           [innerHTML]="za.Captions[0]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_name"
                          [innerText]="za.Values[0]">
                    </span>
                  </fieldset>
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_vorname"
                           [innerHTML]="za.Captions[1]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_vorname"
                          [innerText]="za.Values[1]">
                    </span>
                  </fieldset>
                </div>
                <div class="form-group"
                     *ngSwitchCase="'NameVornameGebDatum'">
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_name"
                           [innerHTML]="za.Captions[0]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_name"
                          [innerText]="za.Values[0]">
                    </span>
                  </fieldset>
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_vorname"
                           [innerHTML]="za.Captions[1]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_vorname"
                          [innerText]="za.Values[1]">
                    </span>
                  </fieldset>
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_gebdatum"
                           [innerHTML]="za.Captions[2]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_gebdatum"
                          [innerText]="za.Values[2]">
                    </span>
                  </fieldset>
                </div>
                <fieldset class="form-group"
                          *ngSwitchCase="'Datum'"
                          [hidden]="za.hidden['ctrl_' + za.CtrlID]">
                  <label class="col-sm-4"
                         for="ctrl_{{za.CtrlID}}"
                         [innerHTML]="za.Caption">
                  </label>
                  <span class="col-sm-6"
                        id="ctrl_{{za.CtrlID}}"
                        [innerText]="za.Value">
                  </span>
                </fieldset>
                <div class="form-group"
                     *ngSwitchCase="'Zeitraum'">
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_start"
                           [innerHTML]="za.Captions[0]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_start"
                          [innerText]="za.Values[0]">
                    </span>
                  </fieldset>
                  <fieldset>
                    <label class="col-sm-4"
                           for="ctrl_{{za.CtrlID}}_end"
                           [innerHTML]="za.Captions[1]">
                    </label>
                    <span class="col-sm-6"
                          id="ctrl_{{za.CtrlID}}_end"
                          [innerText]="za.Values[1]">
                    </span>
                  </fieldset>
                </div>
                <fieldset class="form-group"
                          *ngSwitchCase="'DropDown'">
                  <label class="col-md-4"
                         for="ctrl_{{za.CtrlID}}"
                         [innerHTML]="za.Caption">
                  </label>
                  <span class="col-md-6"
                        id="ctrl_{{za.CtrlID}}"
                        [innerText]="za.Value">
                  </span>
                  <span class="col-sm-2"
                        [hidden]="!za.Punkte"
                        [innerText]="za.Punkte">
                  </span>
                </fieldset>
                <fieldset class="form-group"
                          *ngSwitchCase="'CheckBox'">
                  <!--@TODO DK: mpClick muss an Funktionen aus Zusatzabfragen.js gebunden werden, wenn diese umgezogen wird.-->
                  <!--<mp-core-checkbox class="col-sm-2"
                               type="checkbox"
                               mpId="ctrl_{{za.CtrlID}}"
                               [(model)]="za.Result"
                               (mpClick)="doShowHide(za); updateVK(za)"
                               label="za.Value">
                  </mp-core-checkbox>-->
                  <span class="col-sm-2"
                        [hidden]="!za.Punkte"
                        [innerText]="za.Punkte">
                  </span>
                </fieldset>
                <fieldset *ngSwitchCase="'Radio'">
                  <div class="col-sm-10 form-group"
                       *ngFor="let v of za.Values; trackBy: trackByIndex; let index = index"
                       [hidden]="v != 'True'">
                    <span class="col-sm-7"
                          [innerHTML]="za.Captions[index]">
                    </span>
                  </div>
                  <span class="col-sm-2"
                        [hidden]="!za.Punkte"
                        [innerText]="za.Punkte">
                  </span>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div class="row-inner">
          <div class="grid col-large-12">
            <div class="supplemental-actions floating-container flex-grid flex-align-items-start flex-wrap margin-bottom-16 padding-8">

                <div *ngIf="mpSettings.settings['DisplaySettings'].ShowRetourenscheinButton">
                  <a class="mp-button float-left"
                     target="_blank"
                     href="https://hr-demo.praemienshop.net/media/Retoureformular.pdf">
                    {{ ls.locs['loc'].Retourenschein }}
                  </a>
                </div>

                <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasKontaktformular">
                  <span class="mp-button float-left"
                        (click)="askRequest(bp)">
                    {{ ls.locs['loc'].RueckfrageStellen }}
                  </span>
                </div>

              <div *ngIf="bp['CanStornieren']">
                <span class="mp-button float-left"
                      (click)="cancelOrder(bp)">
                  {{ ls.locs['loc'].BestellungStornieren }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="animate-show"
       [ngClass]="{open: !loading}">
    <div class="header floating-container padding-top-bottom-16 margin-top-32 border-top">
      <div class="row-inner">
        <div class="grid col-small-6">
          <div class="inbox-item-title">
            <i class="svg-icon __clr-primary __size-18 margin-right-16 default-cursor-hover"
               [ngClass]="item.Type">
            </i>
            <div class="icon-text text-overflow-ellipsis">
              <span *ngIf="item.Titel" [innerText]="item.Titel"></span>
              <span *ngIf="item.Titel">&nbsp;</span>
              <span *ngIf="item.Betreff" [innerText]="item.Betreff"></span>
              <span *ngIf="item.Betreff">&nbsp;</span>
              <span *ngIf="item.text" [innerText]="item.text"></span>
            </div>
          </div>
        </div>
        <div class="grid col-small-6">
          <div>{{item.ChangeDate}}</div>
          <div class="subtitle float-right display-block font-size-general">
            {{ ls.locs['loc'].BestellDatum }}:&nbsp;
            <span>{{item.Datum}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="supporting-text margin-top-bottom-24">
      <h3 class="bold">{{ ls.locs['loc'].Lieferadresse }}</h3>
      <span class="display-block"
            [innerText]="b['VersFirma']">
      </span>
      <span class="display-block">{{b['VersVorname']}} {{b['VersName']}}</span>
      <span class="display-block"
            *ngIf="b['VersAdresszusatz']">
        {{b['VersAdresszusatz']}}
      </span>
      <span class="display-block">{{b['VersStrasse']}}</span>
      <span class="display-block">{{b['VersPLZ']}} {{b['VersOrt']}}</span>
      <span class="display-block">{{b['VersLand']}}</span>
    </div>
    <div class="primary-title">
      <div class="title display-block font-size-general bold">
        <h2>{{ ls.locs['loc'].BestellNr }}: {{b['BestellNr']}}</h2>
      </div>
      <div class="subtitle display-block font-size-general"
           *ngIf="item.Attribut">
        <h3 class="bold">
          <span [innerText]="item.Attribut"></span>
        </h3>
      </div>
    </div>
    <div [hidden]="b['BestellPos'].Count <= 1" *ngIf="tnInfostreamOrderPlural !== ''"
         class="supporting-text" [innerHTML]="tnInfostreamOrderPlural">
    </div>
    <div [hidden]="b['BestellPos'].Count > 1" *ngIf="tnInfostreamOrder !== ''"
         class="supporting-text" [innerHTML]="tnInfostreamOrder">
    </div>
  </div>
</div>
