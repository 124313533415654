<div *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async) && campaign !== ''">
  <mp-core-svg-loader [hidden]="!loading" svg="UEFA_Euro_2020_Logo" class="svg-loader" svgAnimation="draw" [time]="1.9" loadingText="{{ ls.locs['locTipAndWin'].LadeanimationBitteWarten }}"></mp-core-svg-loader>
  <div class="padding-top-64 padding-bottom-64" id="tip-and-win">
    <div id="taw-background"></div>
    <section id="taw-tab-section" class="padding-16">
      <div class="flex-grid margin-left-right-0" id="taw-tabs">
        <div class="flex-col-3 flex-col-4-s flex-col-4-xs padding-left-right-0 flex-align-items-center flex-justify-cont-center pointer font-size-s"
             (click)="setTab('tipps')"
             [ngClass]="tabVisible == 'tipps' || !tabVisible ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-white'">
          <i class="svg-icon __size-22 __top-0 __soccer margin-right-8" [ngClass]="tabVisible == 'tipps' || !tabVisible ? '__clr-white' : '__clr-default'"></i>
          <span>{{ ls.locs['locTipAndWin'].MeineTipps }}</span>
        </div>
        <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideSpielregeln"
             class="flex-col-3 flex-col-4-s flex-col-4-xs padding-left-right-0 flex-align-items-center flex-justify-cont-center pointer font-size-s"
             (click)="setTab('spielregeln')"
             [ngClass]="tabVisible == 'spielregeln' ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-white'">
          <i class="svg-icon __size-22 __top-0 __file margin-right-8" [ngClass]="tabVisible == 'spielregeln' ? '__clr-white' : '__clr-default'"></i>
          <span>{{ ls.locs['locTipAndWin'].Spielregeln }}</span>
        </div>
        <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideGewinne" class="flex-col-3 flex-col-4-s flex-col-4-xs padding-left-right-0 flex-align-items-center flex-justify-cont-center pointer font-size-s" (click)="setTab('gewinne')" [ngClass]="tabVisible == 'gewinne' ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-white'">
          <i class="svg-icon __size-22 __top-0 __star-circle margin-right-8" [ngClass]="tabVisible == 'gewinne' ? '__clr-white' : '__clr-default'"></i>
          <span>{{ ls.locs['locTipAndWin'].Gewinne }}</span>
        </div>
        <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideFanshop" class="flex-col-3 flex-col-12-s padding-left-right-0 bg-color-light-grey flex-justify-cont-end">
          <a href="{{ mpSettings.settings['TipAndWinSettings'].FanshopLink | angularjsToAnuglarUrl }}" class="mp-button _prim">{{ ls.locs['locTipAndWin'].ZumFanshop }}</a>
        </div>
      </div>
    </section>
    <section id="taw-main-section" class="padding-16">
      <div id="taw-tab-content" class="padding-right-8">
        <mp-taw-tip-and-win-tips *ngIf="tabVisible === 'tipps' || !tabVisible"></mp-taw-tip-and-win-tips>
        <mp-taw-tip-and-win-rules-of-the-game *ngIf="tabVisible === 'spielregeln'"></mp-taw-tip-and-win-rules-of-the-game>
        <mp-taw-tip-and-win-yields *ngIf="tabVisible === 'gewinne'"></mp-taw-tip-and-win-yields>
        <mp-taw-tip-and-win-performance *ngIf="tabVisible === 'performance'"></mp-taw-tip-and-win-performance>
      </div>
      <div id="taw-list" class="padding-left-8">
        <mp-taw-tip-and-win-ranking></mp-taw-tip-and-win-ranking>
      </div>
    </section>
  </div>
</div>
