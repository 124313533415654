import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

import { CoreSharedModule } from '@core/core.module';
import { ZzSharedModule } from '@zz/zz.module';
import { WkzzSharedModule } from '@wkzz/wkzz.module';
import { RkSharedModule } from '@rk/rk.module';
import { RkzzSharedModule } from '@rkzz/rkzz.module';
import { GfcSharedModule } from '@gfc/gfc.module';
import { GkSharedModule } from '@gk/gk.module';
import { TawSharedModule } from '@taw/taw.module';
import { SchoolingSharedModule } from '@schooling/schooling.module';

import { ExternComponent } from '@core/modules/extern/extern.component';
import { ExternFixedComponent } from '@core/modules/extern-fixed/extern-fixed.component';
import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { LoginComponent } from '@core/components/login/login.component';
import { ForgotPasswordComponent } from '@core/modules/extern/pages/forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from '@core/modules/extern-fixed/pages/confirm-email/confirm-email.component';
import { EmailOptOutComponent } from '@core/modules/extern-fixed/pages/email-opt-out/email-opt-out.component';
import { OptOutComponent } from '@core/modules/extern-fixed/pages/opt-out/opt-out.component';
import { HomeComponent } from '@core/modules/participant/pages/home/home.component';
import { ShopComponent } from '@core/modules/participant/pages/shop/shop.component';
import { AwardDetailsComponent } from '@core/modules/participant/pages/award-details/award-details.component';
import { WishlistComponent } from '@core/modules/participant/pages/wishlist/wishlist.component';
import { ShoppingBasketComponent } from '@core/modules/participant/pages/shopping-basket/shopping-basket.component';
import { ShippingComponent } from '@core/modules/participant/pages/shipping/shipping.component';
import { OrderCheckComponent } from '@core/modules/participant/pages/order-check/order-check.component';
import { OrderConfirmationComponent } from '@core/modules/participant/pages/order-confirmation/order-confirmation.component';
import { ContactComponent } from '@core/modules/participant/pages/contact/contact.component';
import { FaqComponent } from '@core/modules/participant/pages/faq/faq.component';
import { ManagerComponent } from '@core/modules/manager/manager.component';
import { HomeComponent as ManagerHomeComponent } from '@core/modules/manager/pages/home/home.component';
import { ThemeStageEditorComponent } from '@core/modules/manager/pages/theme-stage-editor/theme-stage-editor.component';
import { ThemeStageManagementComponent } from '@core/modules/manager/pages/theme-stage-management/theme-stage-management.component';
import { ThemeStageSliderManagementComponent } from '@core/modules/manager/pages/theme-stage-slider-management/theme-stage-slider-management.component';
import { CommunicationProcessStartComponent } from '@core/modules/manager/pages/communication-process-start/communication-process-start.component';
import { CommunicationDataComponent } from '@core/modules/manager/pages/communication-data/communication-data.component';
import { CommunicationManagementComponent } from '@core/modules/manager/pages/communication-management/communication-management.component';
import { ParticipantChoiceComponent } from '@core/modules/manager/pages/participant-choice/participant-choice.component';
import { EmailScheduledManagementComponent } from '@core/modules/manager/pages/email-scheduled-management/email-scheduled-management.component';
import { EmailProtocolComponent } from '@core/modules/manager/pages/email-protocol/email-protocol.component';
import { AdminComponent } from '@core/modules/admin/admin.component';
import { HomeComponent as AdminHomeComponent } from '@core/modules/admin/pages/home/home.component';
import { EmailTextEditorComponent } from '@core/modules/manager/pages/email-text-editor/email-text-editor.component';
import { EmailModuleComponent } from '@core/modules/manager/pages/email-module/email-module.component';
import { CheckNotificationComponent } from '@core/modules/manager/pages/check-notification/check-notification.component';
import { Error404Component } from '@core/modules/error/pages/error404/error404.component';
import { Error500Component } from '@core/modules/error/pages/error500/error500.component';
import { Error401Component } from '@core/modules/error/pages/error401/error401.component';
import { AccountComponent } from '@core/modules/participant/pages/account/account.component';
import { CampaignDetailsComponent } from '@core/modules/participant/pages/campaign-details/campaign-details.component';
import { PictureCreditComponent } from '@core/modules/participant/pages/picture-credit/picture-credit.component';
import { StageDetailsComponent } from '@core/modules/participant/pages/stage-details/stage-details.component';
import { DataPrivacyComponent } from '@core/modules/participant/pages/data-privacy/data-privacy.component';
import { SettingsComponent } from '@core/modules/participant/pages/settings/settings.component';
import { FeatureDetailsComponent } from '@core/modules/participant/pages/feature-details/feature-details.component';
import { RulesOfTheGameComponent } from '@core/modules/participant/pages/rules-of-the-game/rules-of-the-game.component';
import { ConditionsOfParticipationComponent } from '@core/modules/participant/pages/conditions-of-participation/conditions-of-participation.component';
import { AdditionalQuestionsComponent } from '@core/modules/participant/pages/additional-questions/additional-questions.component';
import { CurrentScoreComponent } from '@core/modules/manager/pages/current-score/current-score.component';
import { AnonymizationOverviewComponent } from '@core/modules/manager/pages/anonymization-overview/anonymization-overview.component';
import { ParticipantManagementComponent } from '@core/modules/manager/pages/participant-management/participant-management.component';
import { ParticipantCockpitComponent } from '@core/modules/manager/pages/participant-cockpit/participant-cockpit.component';
import { GitlogComponent } from '@core/modules/admin/pages/gitlog/gitlog.component';
import { ImportCampaignOrdersComponent } from '@core/modules/admin/pages/import-campaign-orders/import-campaign-orders.component';
import { InactiveParticipantsComponent } from '@core/modules/admin/pages/inactive-participants/inactive-participants.component';
import { AccountStatisticComponent } from '@core/modules/admin/pages/account-statistic/account-statistic.component';
import { ManagerManagementComponent } from '@core/modules/admin/pages/manager-management/manager-management.component';
import { CreateParticipantComponent } from '@core/modules/admin/pages/create-participant/create-participant.component';
import { OrderStatisticComponent } from '@core/modules/manager/pages/order-statistic/order-statistic.component';
import { OrderOverviewComponent } from '@core/modules/manager/pages/order-overview/order-overview.component';
import { ImminentPointsDecayComponent } from '@core/modules/manager/pages/imminent-points-decay/imminent-points-decay.component';
import { GroupsStatisticComponent } from '@core/modules/manager/pages/groups-statistic/groups-statistic.component';
import { CreateGroupingComponent } from '@core/modules/manager/pages/create-grouping/create-grouping.component';
import { GroupingOverviewComponent } from '@core/modules/manager/pages/grouping-overview/grouping-overview.component';
import { GroupingOverviewDetailsComponent } from '@core/modules/manager/pages/grouping-overview-details/grouping-overview-details.component';
import { LoginStatisticComponent } from '@core/modules/manager/pages/login-statistic/login-statistic.component';
import { ChangePasswordComponent } from '@core/modules/manager/pages/change-password/change-password.component';
import { AwardManagerComponent } from '@core/modules/manager/pages/award-manager/award-manager.component';
import { AwardStatisticComponent } from '@core/modules/manager/pages/award-statistic/award-statistic.component';
import { PointsEuroPerMonthComponent } from '@core/modules/manager/pages/points-euro-per-month/points-euro-per-month.component';
import { PointsEuroPerMonthDetailsComponent } from '@core/modules/manager/pages/points-euro-per-month-details/points-euro-per-month-details.component';
import { FurtherTextsEditorComponent } from '@core/modules/manager/pages/further-texts-editor/further-texts-editor.component';
import { PointsDecayEuroPerMonthComponent } from '@core/modules/manager/pages/points-decay-euro-per-month/points-decay-euro-per-month.component';
import { PointsDecayEuroPerMonthDetailsComponent } from '@core/modules/manager/pages/points-decay-euro-per-month-details/points-decay-euro-per-month-details.component';
import { InvoiceControlComponent } from '@core/modules/manager/pages/invoice-control/invoice-control.component';
import { ConditionsOfParticipationEditorComponent } from '@core/modules/manager/pages/conditions-of-participation-editor/conditions-of-participation-editor.component';
import { ParticipantRankingComponent } from '@core/modules/manager/pages/participant-ranking/participant-ranking.component';
import { PaymentOfTaxBaseComponent } from '@core/modules/manager/pages/payment-of-tax-base/payment-of-tax-base.component';
import { PaymentOfTaxListComponent } from '@core/modules/manager/pages/payment-of-tax-list/payment-of-tax-list.component';
import { ComplaintManagementComponent } from './modules/participant/pages/complaint-management/complaint-management.component';
import { CharityCurrentlyInfosComponent } from './modules/participant/pages/charity-currently-infos/charity-currently-infos.component';
import { CharityDescriptionComponent } from './modules/participant/pages/charity-description/charity-description.component';
import { CharityDonateComponent } from './modules/participant/pages/charity-donate/charity-donate.component';
import { RedeemVoucherComponent } from './modules/participant/pages/redeem-voucher/redeem-voucher.component';
import { CampaignsHistoryComponent } from './modules/manager/pages/campaigns-history/campaigns-history.component';
import { CampaignsHistoryDetailsComponent } from './modules/manager/pages/campaigns-history-details/campaigns-history-details.component';
import { CharityStatisticComponent } from './modules/manager/pages/charity-statistic/charity-statistic.component';
import { CompaniesRankingComponent } from './modules/manager/pages/companies-ranking/companies-ranking.component';
import { VoucherStatisticComponent } from './modules/manager/pages/voucher-statistic/voucher-statistic.component';
import { AppreciationOverviewComponent } from './modules/manager/pages/appreciation-overview/appreciation-overview.component';
import { ProductStatisticComponent } from './modules/manager/pages/product-statistic/product-statistic.component';
import { PointsSharingOverviewComponent } from './modules/manager/pages/points-sharing-overview/points-sharing-overview.component';
import { PointsDecayComponent } from './modules/manager/pages/points-decay/points-decay.component';
import { PointsDecayReportingComponent } from './modules/manager/pages/points-decay-reporting/points-decay-reporting.component';
import { QuickboardingStatisticComponent } from './modules/manager/pages/quickboarding-statistic/quickboarding-statistic.component';
import { SpecialArrangementAgencyAgreementComponent } from './modules/manager/pages/special-arrangement-agency-agreement/special-arrangement-agency-agreement.component';
import { ParticipantEvaluationComponent } from './modules/manager/pages/participant-evaluation/participant-evaluation.component';
import { ParticipantSurveyStatisticComponent } from './modules/manager/pages/participant-survey-statistic/participant-survey-statistic.component';
import { TestuserManagementComponent } from './modules/manager/pages/testuser-management/testuser-management.component';
import { PrtcpntAdvertisePrtcpntStatisticComponent } from './modules/manager/pages/prtcpnt-advertise-prtcpnt-statistic/prtcpnt-advertise-prtcpnt-statistic.component';
import { ExpactedPointsReleaseComponent } from './modules/manager/pages/expacted-points-release/expacted-points-release.component';
import { AdditionalPaymentOverviewComponent } from '@zz/modules/manager/pages/additional-payment-overview/additional-payment-overview.component';
import { PrizeCalendarWinnerStatisticComponent } from '@gk/modules/manager/pages/prize-calendar-winner-statistic/prize-calendar-winner-statistic.component';
import { PrizeCalendarOneComponent } from '@gk/modules/participant/pages/prize-calendar/prize-calendar-one/prize-calendar-one.component';
import { PrizeCalendarTwoComponent } from '@gk/modules/participant/pages/prize-calendar/prize-calendar-two/prize-calendar-two.component';
import { ImprintComponent } from '@core/modules/manager/pages/imprint/imprint.component';
import { TipAndWinEditorComponent } from '@taw/modules/manager/pages/tip-and-win-editor/tip-and-win-editor.component';
import { TipAndWinGameAcceptanceComponent } from '@taw/modules/manager/pages/tip-and-win-game-acceptance/tip-and-win-game-acceptance.component';
import { ImportCampaignOrdersLeasComponent } from './modules/admin/pages/import-campaign-orders-leas/import-campaign-orders-leas.component';
import { CreditProposalComponent } from './modules/admin/pages/credit-proposal/credit-proposal.component';
import { AdmComponent } from './modules/adm/adm.component';
import { HomeComponent as AdmHomeComponent } from './modules/adm/pages/home/home.component';
import { CharityComponent } from './modules/charity/charity.component';
import { HomeComponent as CharityHomeComponent } from './modules/charity/pages/home/home.component';
import { AwardDetailsComponent as CharityAwardDetailsComponent } from './modules/charity/pages/award-details/award-details.component';
import { AccountComponent as CharityAccountComponent } from './modules/charity/pages/account/account.component';
import { PaymentComponent } from '@wkzz/modules/participant/pages/payment/payment.component';
import { PaymentFailedComponent } from '@wkzz/modules/participant/pages/payment-failed/payment-failed.component';
import { RequestAccountChangeComponent } from '@core/modules/participant/pages/request-account-change/request-account-change.component';
import { ConfirmAccountChangeComponent } from '@core/modules/manager/pages/confirm-account-change/confirm-account-change.component';
import { FirstLoginComponent } from '@core/modules/first-login/first-login.component';
import { HomeComponent as FirstLoginHomeComponent } from '@core/modules/first-login/pages/home/home.component';
import { SchoolingStartLeasComponent } from './modules/participant/pages/schooling-start-leas/schooling-start-leas.component';
import { SchoolingPageLeasComponent } from './modules/participant/pages/schooling-page-leas/schooling-page-leas.component';
import { SchoolingStatisticLeasComponent } from './modules/manager/pages/schooling-statistic-leas/schooling-statistic-leas.component';
import { TaxReportingComponent } from '@core/modules/manager/pages/tax-reporting/tax-reporting.component';

import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  {path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'AktionDetails',canActivateChild: [AuthGuard],children: [{path: '',component: CampaignDetailsComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Beschwerdemanagement',canActivateChild: [AuthGuard],children: [{path: '',component: ComplaintManagementComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'BestellBestaetigung',canActivateChild: [AuthGuard],children: [{path: '',component: OrderConfirmationComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'BestellungPruefen',canActivateChild: [AuthGuard],children: [{path: '',component: OrderCheckComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Bildernachweis',canActivateChild: [AuthGuard],children: [{path: '',component: PictureCreditComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'BuehnenDetails',canActivateChild: [AuthGuard],children: [{path: '',component: StageDetailsComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'CharityAktuelleInfos',canActivateChild: [AuthGuard],children: [{path: '',component: CharityCurrentlyInfosComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'CharityBeschreibung',canActivateChild: [AuthGuard],children: [{path: '',component: CharityDescriptionComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'CharitySpenden',canActivateChild: [AuthGuard],children: [{path: '',component: CharityDonateComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Datenschutz',canActivateChild: [AuthGuard],children: [{path: '',component: DataPrivacyComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Einstellungen',canActivateChild: [AuthGuard],children: [{path: '',component: SettingsComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'FAQ',canActivateChild: [AuthGuard],children: [{path: '',component: FaqComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'FeatureDetails',canActivateChild: [AuthGuard],children: [{path: '',component: FeatureDetailsComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'GutscheinEinloesen',canActivateChild: [AuthGuard],children: [{path: '',component: RedeemVoucherComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Home',canActivateChild: [AuthGuard],children: [{path: '',component: HomeComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Kontakt',canActivateChild: [AuthGuard],children: [{path: '',component: ContactComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Konto',canActivateChild: [AuthGuard],children: [{path: '',component: AccountComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'KontowechselBeantragen',canActivateChild: [AuthGuard],children: [{path: '',component: RequestAccountChangeComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'PraemienDetails',canActivateChild: [AuthGuard],children: [{path: '',component: AwardDetailsComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Shop/:id',canActivateChild: [AuthGuard],children: [{path: '',component: ShopComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Shop',canActivateChild: [AuthGuard],children: [{path: '',component: ShopComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Spielregeln',canActivateChild: [AuthGuard],children: [{path: '',component: RulesOfTheGameComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Teilnahmebedingungen',canActivateChild: [AuthGuard],children: [{path: '',component: ConditionsOfParticipationComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Warenkorb',canActivateChild: [AuthGuard],children: [{path: '',component: ShoppingBasketComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Wunschliste',canActivateChild: [AuthGuard],children: [{path: '',component: WishlistComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Versand',canActivateChild: [AuthGuard],children: [{path: '',component: ShippingComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Zusatzabfragen',canActivateChild: [AuthGuard],children: [{path: '',component: AdditionalQuestionsComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'SchulungStart',canActivateChild: [AuthGuard],children: [{path: '',component: SchoolingStartLeasComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'SchulungSeite',canActivateChild: [AuthGuard],children: [{path: '',component: SchoolingPageLeasComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'Gewinnkalender',canActivateChild: [AuthGuard],children: [{path: '',component: PrizeCalendarOneComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'GewinnkalenderZwei',canActivateChild: [AuthGuard],children: [{path: '',component: PrizeCalendarTwoComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'err500',canActivateChild: [AuthGuard],children: [{path: '',component: Error500Component, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: 'err401',canActivateChild: [AuthGuard],children: [{path: '',component: Error401Component, outlet: 'mainContentParticipant', pathMatch: 'full' }]},
      {path: '**',canActivateChild: [AuthGuard],children: [{path: '',component: Error404Component, outlet: 'mainContentParticipant', pathMatch: 'full' }]}
    ]
  },
  {path: 'firstlogin',
    component: FirstLoginComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'Home',canActivateChild: [AuthGuard],children: [{path: '',component: FirstLoginHomeComponent, outlet: 'mainContentFirstLogin', pathMatch: 'full' }]},
      {path: 'Impressum',canActivateChild: [AuthGuard],children: [{path: '',component: ImprintComponent, outlet: 'mainContentFirstLogin', pathMatch: 'full' }]},
      {path: 'Teilnahmebedingungen',canActivateChild: [AuthGuard],children: [{path: '',component: ConditionsOfParticipationComponent, outlet: 'mainContentFirstLogin', pathMatch: 'full' }]},
      {path: 'err500',canActivateChild: [AuthGuard],children: [{path: '',component: Error500Component, outlet: 'mainContentFirstLogin', pathMatch: 'full' }]},
      {path: 'err401',canActivateChild: [AuthGuard],children: [{path: '',component: Error401Component, outlet: 'mainContentFirstLogin', pathMatch: 'full' }]},
      {path: '**',canActivateChild: [AuthGuard],children: [{path: '',component: Error404Component, outlet: 'mainContentFirstLogin', pathMatch: 'full' }]}
    ]
  },
  {path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'Aktionshistorie',canActivateChild: [AuthGuard],children: [{path: '',component: CampaignsHistoryComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'AktionshistorieDetails',canActivateChild: [AuthGuard],children: [{path: '',component: CampaignsHistoryDetailsComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'AktuellerPunktestand',canActivateChild: [AuthGuard],children: [{path: '',component: CurrentScoreComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'AnonymisierungsUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: AnonymizationOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Bestelluebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: OrderOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'BestellStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: OrderStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'BenachrichtigungPruefen',canActivateChild: [AuthGuard],children: [{path: '',component: CheckNotificationComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'BuehnenDetails',canActivateChild: [AuthGuard],children: [{path: '',component: StageDetailsComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'CharityStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: CharityStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Datenschutz',canActivateChild: [AuthGuard],children: [{path: '',component: DataPrivacyComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'DrohenderPunkteverfall',canActivateChild: [AuthGuard],children: [{path: '',component: ImminentPointsDecayComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'EMailModul',canActivateChild: [AuthGuard],children: [{path: '',component: EmailModuleComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'EMailProtokoll',canActivateChild: [AuthGuard],children: [{path: '',component: EmailProtocolComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'EmailScheduledVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: EmailScheduledManagementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'FirmenRanking',canActivateChild: [AuthGuard],children: [{path: '',component: CompaniesRankingComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'GutscheinStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: VoucherStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'GruppenStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: GroupsStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'GruppierungAnlage',canActivateChild: [AuthGuard],children: [{path: '',component: CreateGroupingComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'GruppierungUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: GroupingOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'GruppierungUebersichtDetails',canActivateChild: [AuthGuard],children: [{path: '',component: GroupingOverviewDetailsComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Home',canActivateChild: [AuthGuard],children: [{path: '',component: ManagerHomeComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'HonorierungUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: AppreciationOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Impressum',canActivateChild: [AuthGuard],children: [{path: '',component: ImprintComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Kommunikationsdaten',canActivateChild: [AuthGuard],children: [{path: '',component: CommunicationDataComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'KommunikationsprozessStart',canActivateChild: [AuthGuard],children: [{path: '',component: CommunicationProcessStartComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'KommunikationsVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: CommunicationManagementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'KontowechselGenehmigen',canActivateChild: [AuthGuard],children: [{path: '',component: ConfirmAccountChangeComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'LoginStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: LoginStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PasswortAendern',canActivateChild: [AuthGuard],children: [{path: '',component: ChangePasswordComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Praemienmanager',canActivateChild: [AuthGuard],children: [{path: '',component: AwardManagerComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PraemienStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: AwardStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PunktebeteiligungUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: PointsSharingOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'ProduktStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: ProductStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PunkteEuroProMonat',canActivateChild: [AuthGuard],children: [{path: '',component: PointsEuroPerMonthComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PunkteEuroProMonatDetails',canActivateChild: [AuthGuard],children: [{path: '',component: PointsEuroPerMonthDetailsComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Punkteverfall',canActivateChild: [AuthGuard],children: [{path: '',component: PointsDecayComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PunkteverfallReporting',canActivateChild: [AuthGuard],children: [{path: '',component: PointsDecayReportingComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PunkteverfallEuroProMonat',canActivateChild: [AuthGuard],children: [{path: '',component: PointsDecayEuroPerMonthComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'PunkteverfallEuroProMonatDetails',canActivateChild: [AuthGuard],children: [{path: '',component: PointsDecayEuroPerMonthDetailsComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'QuickboardingStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: QuickboardingStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Rechnungskontrolle',canActivateChild: [AuthGuard],children: [{path: '',component: InvoiceControlComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'SchulungStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: SchoolingStatisticLeasComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'SonderregelungAgenturvertrag',canActivateChild: [AuthGuard],children: [{path: '',component: SpecialArrangementAgencyAgreementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'SonstigeTexteEditor',canActivateChild: [AuthGuard],children: [{path: '',component: FurtherTextsEditorComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TeilnahmebedingungenEditor',canActivateChild: [AuthGuard],children: [{path: '',component: ConditionsOfParticipationEditorComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TeilnehmerAuswahl',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantChoiceComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Teilnehmerauswertung',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantEvaluationComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TeilnehmerbefragungStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantSurveyStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TeilnehmerRanking',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantRankingComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Teilnehmerverwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantManagementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TestuserVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: TestuserManagementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TNCockpit/:TnID',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantCockpitComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'TnWerbenTnStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: PrtcpntAdvertisePrtcpntStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'ThemenbuehnenEditor',canActivateChild: [AuthGuard],children: [{path: '',component: ThemeStageEditorComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'ThemenbuehnenVerwalten',canActivateChild: [AuthGuard],children: [{path: '',component: ThemeStageManagementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'ThemenbuehnenSliderVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: ThemeStageSliderManagementComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'VersteuerungsgrenzeReporting',canActivateChild: [AuthGuard],children: [{path: '',component: TaxReportingComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Versteuerungsgrundlage',canActivateChild: [AuthGuard],children: [{path: '',component: PaymentOfTaxBaseComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'Versteuerungsliste',canActivateChild: [AuthGuard],children: [{path: '',component: PaymentOfTaxListComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'VoraussichtlichePunkteausgabe',canActivateChild: [AuthGuard],children: [{path: '',component: ExpactedPointsReleaseComponent, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'err500',canActivateChild: [AuthGuard],children: [{path: '',component: Error500Component, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: 'err401',canActivateChild: [AuthGuard],children: [{path: '',component: Error401Component, outlet: 'mainContentManager', pathMatch: 'full' }]},
      {path: '**',canActivateChild: [AuthGuard],children: [{path: '',component: Error404Component, outlet: 'mainContentManager', pathMatch: 'full' }]}
    ]
  },
  {path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'Aktionshistorie',canActivateChild: [AuthGuard],children: [{path: '',component: CampaignsHistoryComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'AktionshistorieDetails',canActivateChild: [AuthGuard],children: [{path: '',component: CampaignsHistoryDetailsComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'AktuellerPunktestand',canActivateChild: [AuthGuard],children: [{path: '',component: CurrentScoreComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'AnonymisierungsUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: AnonymizationOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Bestelluebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: OrderOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'BestellStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: OrderStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'BuehnenDetails',canActivateChild: [AuthGuard],children: [{path: '',component: StageDetailsComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GitLog',canActivateChild: [AuthGuard],children: [{path: '',component: GitlogComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'CharityStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: CharityStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'EMailModul',canActivateChild: [AuthGuard],children: [{path: '',component: EmailModuleComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'EMailProtokoll',canActivateChild: [AuthGuard],children: [{path: '',component: EmailProtocolComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'EmailScheduledVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: EmailScheduledManagementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'EMailTexteEditor',canActivateChild: [AuthGuard],children: [{path: '',component: EmailTextEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'FirmenRanking',canActivateChild: [AuthGuard],children: [{path: '',component: CompaniesRankingComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GewinnkalenderGewinnerStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: PrizeCalendarWinnerStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GutscheinStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: VoucherStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GruppenStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: GroupsStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GruppierungAnlage',canActivateChild: [AuthGuard],children: [{path: '',component: CreateGroupingComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GruppierungUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: GroupingOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'GruppierungUebersichtDetails',canActivateChild: [AuthGuard],children: [{path: '',component: GroupingOverviewDetailsComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Home',canActivateChild: [AuthGuard],children: [{path: '',component: AdminHomeComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'HonorierungUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: AppreciationOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ImportAktionsbestellungenLeas',canActivateChild: [AuthGuard],children: [{path: '',component: ImportCampaignOrdersLeasComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Impressum',canActivateChild: [AuthGuard],children: [{path: '',component: ImprintComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ImportAktionsbestellungen',canActivateChild: [AuthGuard],children: [{path: '',component: ImportCampaignOrdersComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'InaktiveTeilnehmer',canActivateChild: [AuthGuard],children: [{path: '',component: InactiveParticipantsComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Kommunikationsdaten',canActivateChild: [AuthGuard],children: [{path: '',component: CommunicationDataComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'KontoStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: AccountStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'KontowechselGenehmigen',canActivateChild: [AuthGuard],children: [{path: '',component: ConfirmAccountChangeComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'LoginStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: LoginStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Managerverwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: ManagerManagementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Praemienmanager',canActivateChild: [AuthGuard],children: [{path: '',component: AwardManagerComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'PraemienStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: AwardStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ProposalGutschreiben',canActivateChild: [AuthGuard],children: [{path: '',component: CreditProposalComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'PunktebeteiligungUebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: PointsSharingOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ProduktStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: ProductStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'PunkteEuroProMonat',canActivateChild: [AuthGuard],children: [{path: '',component: PointsEuroPerMonthComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'PunkteEuroProMonatDetails',canActivateChild: [AuthGuard],children: [{path: '',component: PointsEuroPerMonthDetailsComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Punkteverfall',canActivateChild: [AuthGuard],children: [{path: '',component: PointsDecayComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'PunkteverfallReporting',canActivateChild: [AuthGuard],children: [{path: '',component: PointsDecayReportingComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'QuickboardingStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: QuickboardingStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Rechnungskontrolle',canActivateChild: [AuthGuard],children: [{path: '',component: InvoiceControlComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'SonderregelungAgenturvertrag',canActivateChild: [AuthGuard],children: [{path: '',component: SpecialArrangementAgencyAgreementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'SonstigeTexteEditor',canActivateChild: [AuthGuard],children: [{path: '',component: FurtherTextsEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TeilnahmebedingungenEditor',canActivateChild: [AuthGuard],children: [{path: '',component: ConditionsOfParticipationEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TeilnehmerAnlegen',canActivateChild: [AuthGuard],children: [{path: '',component: CreateParticipantComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Teilnehmerauswertung',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantEvaluationComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TeilnehmerbefragungStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantSurveyStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TeilnehmerRanking',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantRankingComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Teilnehmerverwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantManagementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TestuserVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: TestuserManagementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TNCockpit/:TnID',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantCockpitComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TnWerbenTnStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: PrtcpntAdvertisePrtcpntStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ThemenbuehnenEditor',canActivateChild: [AuthGuard],children: [{path: '',component: ThemeStageEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ThemenbuehnenVerwalten',canActivateChild: [AuthGuard],children: [{path: '',component: ThemeStageManagementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'ThemenbuehnenSliderVerwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: ThemeStageSliderManagementComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TipAndWinEditor',canActivateChild: [AuthGuard],children: [{path: '',component: TipAndWinEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'TipAndWinSpielakzeptanz',canActivateChild: [AuthGuard],children: [{path: '',component: TipAndWinGameAcceptanceComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'VersteuerungsgrenzeReporting',canActivateChild: [AuthGuard],children: [{path: '',component: TaxReportingComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Versteuerungsliste',canActivateChild: [AuthGuard],children: [{path: '',component: PaymentOfTaxListComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'VoraussichtlichePunkteausgabe',canActivateChild: [AuthGuard],children: [{path: '',component: ExpactedPointsReleaseComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'Zuzahlungsuebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: AdditionalPaymentOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'err500',canActivateChild: [AuthGuard],children: [{path: '',component: Error500Component, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: 'err401',canActivateChild: [AuthGuard],children: [{path: '',component: Error401Component, outlet: 'mainContentAdmin', pathMatch: 'full' }]},
      {path: '**',canActivateChild: [AuthGuard],children: [{path: '',component: Error404Component, outlet: 'mainContentAdmin', pathMatch: 'full' }]}
    ]
  },
  {path: 'adm',
    component: AdmComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'Home', canActivateChild: [AuthGuard],children: [{path: '',component: AdmHomeComponent, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: 'ProduktStatistik',canActivateChild: [AuthGuard],children: [{path: '',component: ProductStatisticComponent, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: 'Teilnehmerverwaltung',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantManagementComponent, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: 'TeilnehmerRanking',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantRankingComponent, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: 'TNCockpit/:TnID',canActivateChild: [AuthGuard],children: [{path: '',component: ParticipantCockpitComponent, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: 'err500',canActivateChild: [AuthGuard],children: [{path: '',component: Error500Component, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: 'err401',canActivateChild: [AuthGuard],children: [{path: '',component: Error401Component, outlet: 'mainContentAdm', pathMatch: 'full' }]},
      {path: '**',canActivateChild: [AuthGuard],children: [{path: '',component: Error404Component, outlet: 'mainContentAdm', pathMatch: 'full' }]}
    ]
  },
  {path: 'charity',
    component: CharityComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'Bezahlung',canActivateChild: [AuthGuard],children: [{path: '',component: PaymentComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'BuehnenDetails',canActivateChild: [AuthGuard],children: [{path: '',component: StageDetailsComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'ZuzahlungFailed',canActivateChild: [AuthGuard],children: [{path: '',component: PaymentFailedComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'BestellBestaetigung',canActivateChild: [AuthGuard],children: [{path: '',component: OrderConfirmationComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Bestelluebersicht',canActivateChild: [AuthGuard],children: [{path: '',component: OrderOverviewComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'BestellungPruefen',canActivateChild: [AuthGuard],children: [{path: '',component: OrderCheckComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Einstellungen',canActivateChild: [AuthGuard],children: [{path: '',component: SettingsComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Home',canActivateChild: [AuthGuard],children: [{path: '',component: CharityHomeComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Konto',canActivateChild: [AuthGuard],children: [{path: '',component: CharityAccountComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'PraemienDetails',canActivateChild: [AuthGuard],children: [{path: '',component: CharityAwardDetailsComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Shop/:id',canActivateChild: [AuthGuard],children: [{path: '',component: ShopComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Versand',canActivateChild: [AuthGuard],children: [{path: '',component: ShippingComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Warenkorb',canActivateChild: [AuthGuard],children: [{path: '',component: ShoppingBasketComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'Wunschliste',canActivateChild: [AuthGuard],children: [{path: '',component: WishlistComponent, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'err500',canActivateChild: [AuthGuard],children: [{path: '',component: Error500Component, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: 'err401',canActivateChild: [AuthGuard],children: [{path: '',component: Error401Component, outlet: 'mainContentCharity', pathMatch: 'full' }]},
      {path: '**',canActivateChild: [AuthGuard],children: [{path: '',component: Error404Component, outlet: 'mainContentCharity', pathMatch: 'full' }]}
    ]
  },
  {path: '',component: ExternComponent, pathMatch: 'full',children: [{path: '',component: LoginComponent, outlet: 'mainContent', pathMatch: 'full' }]},
  {path: 'Datenschutz',component: ExternComponent,children: [{path: '',component: DataPrivacyComponent, outlet: 'mainContent', pathMatch: 'full' }]},
  {path: 'Login',component: ExternComponent,children: [{path: '', redirectTo: '/', pathMatch: 'full' }]},
  {path: 'PWDVergessen',component: ExternComponent,children: [{path: '',component: ForgotPasswordComponent, outlet: 'mainContent', pathMatch: 'full' }]},
  {path: 'ConfirmEmail',component: ExternFixedComponent,children: [{path: '',component: ConfirmEmailComponent, outlet: 'mainContentExtFixed', pathMatch: 'full' }]},
  {path: 'EmailOptOut',component: ExternFixedComponent,children: [{path: '',component: EmailOptOutComponent, outlet: 'mainContentExtFixed', pathMatch: 'full' }]},
  {path: 'OptOut',component: ExternFixedComponent,children: [{path: '',component: OptOutComponent, outlet: 'mainContentExtFixed', pathMatch: 'full' }]},
  {path: 'err500',component: ExternComponent,children: [{path: '',component: Error500Component, outlet: 'mainContent', pathMatch: 'full' }]},
  {path: 'err401',component: ExternComponent,children: [{path: '',component: Error401Component, outlet: 'mainContent', pathMatch: 'full' }]},
  {path: '**',component: ExternComponent,children: [{path: '',component: Error404Component, outlet: 'mainContent', pathMatch: 'full' }]}
];
@NgModule({
  imports: [
    CoreSharedModule.forRoot(),
    RkSharedModule.forRoot(),
    ZzSharedModule.forRoot(),
    WkzzSharedModule.forRoot(),
    RkzzSharedModule.forRoot(),
    GfcSharedModule.forRoot(),
    GkSharedModule.forRoot(),
    TawSharedModule.forRoot(),
    SchoolingSharedModule.forRoot(),
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class LeasRoutingModule {
  constructor(router: Router) {
    const routerConfigPaths = [...new Set(router.config.map(conf => conf.path))];
    const mergedRoutes: Array<any> = [];

    routerConfigPaths.forEach(path => {
      const filteredRouterConfig = router.config.filter(conf => conf.path === path);

      if (filteredRouterConfig.length >= 1) {
        filteredRouterConfig.forEach((filteredRoute, index) => {
          if (index > 0) {
            if (typeof filteredRoute.children !== 'undefined') {
              filteredRoute.children.forEach(child => {
                if (typeof filteredRouterConfig[0].children !== 'undefined') {
                  const childExisting = filteredRouterConfig[0].children.find(checkedChild => checkedChild.path === child.path);
                  const childExistingIndex = typeof childExisting !== 'undefined' ? filteredRouterConfig[0].children.indexOf(childExisting) : -1;

                  if (childExistingIndex !== -1) {
                    filteredRouterConfig[0].children[childExistingIndex] = child;
                  } else {
                    filteredRouterConfig[0].children.push(child);
                  }
                }
              });
            }
          }
        });

        mergedRoutes.push(filteredRouterConfig[0]);
      } else {
        mergedRoutes.push(filteredRouterConfig);
      }
    });

    //router.config.forEach(route => {
    //  if (!routes.find(shopRoute => typeof shopRoute.outlet !== 'undefined' ? shopRoute.path === route.path && typeof route.outlet !== 'undefined' && shopRoute.outlet === route.outlet : shopRoute.path === route.path)) {
    //    routes.push(route);
    //  }
    //});

    router.config = mergedRoutes;
  }
}
