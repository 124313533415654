import { Component, OnInit, Input, OnDestroy, OnChanges, ViewEncapsulation, SimpleChanges, ElementRef, ViewChild, HostListener, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ApiService } from './../../services/api.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { ImageCarouselService } from './image-carousel.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MpDebounceService } from './../../services/mp-debounce.service';

/**
 * This class provides the slides for
 * the images and videos in the award
 * details pages.
 */
@Component({
  selector: 'mp-core-image-video-carousel',
  templateUrl: './image-video-carousel.component.html',
  styleUrls: ['./image-video-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageVideoCarouselComponent implements OnInit, OnDestroy {

  public selectedCarouselItem: number = 0;
  public visuals: Array<any> = [];
  public imageZoomModels: Array<object> = [];
  public slickThumbsConfig: any;
  public slickConfig: any;
  public role: string = '';

  public imageZoomOptions = {
    zoomType: 'lens',
    lensShape: 'round',
    containLensZoom: true,
    lensSize: 350,
    zIndex: 3
  };

  @Input() public sourceUrl: string = '';

  @ViewChild('imageVideoCarousel') imageVideoCarousel: SlickCarouselComponent | undefined;
  @ViewChild('imageVideoCarouselNav') imageVideoCarouselNav: SlickCarouselComponent | undefined;

  private _visual: any = -1;
  private _buildCliplisterHtml: any;
  private _pinSubscription: Subscription | undefined;

  constructor(
    public sanitizer: DomSanitizer,
    private _apiService: ApiService,
    private _imageCarouselService: ImageCarouselService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _mpDebounce: MpDebounceService
  ) { }

  /**
   * Angulars init function. Gets the images
   * and sets up the slick slider configs.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._loadVisuals();

    this._pinSubscription = this._imageCarouselService.pinObserver.subscribe((pin: string) => {
      let visualIndex = 0;

      visualIndex = this.visuals.findIndex((vis: any) => {
        return vis.PIN === pin;
      });

      if (visualIndex >= 0) {
        if (typeof this.imageVideoCarousel !== 'undefined') {
          this.imageVideoCarousel.slickGoTo(visualIndex);
        }
      }
    });

    this._buildCliplisterHtml = this._mpDebounce.debounce(() => {
      const clVideo = document.querySelector(`#clVideo${this._visual.ID}`);

      if (clVideo !== null) {
        if (clVideo.querySelector('video') === null) {
          // @ts-ignore
          new Cliplister.Viewer({
            parentId: clVideo.getAttribute('id'),
            customer: 99051,
            assetKeys: [clVideo.getAttribute('ean')],
            keyType: 0,
            languages: ['de', '##'],
            stage: {
              width: '100%',
              aspectRatio: 'asset'
            },
            slot: 5,
            plugins: {
              InnerControls: {
                whitelist: [
                  'play',
                  'pause',
                  'volume',
                  'fullscreen-enter',
                  'fullscreen-leave',
                  'duration-minute',
                  'duration-second',
                  'duration-seconds',
                  'time-minute',
                  'time-second',
                  'time-seconds'
                ],
                layer: 6
              },
              ClickableVideo: {
                layer: 1
              },
              PlayButton: {
                image: 'https://mycliplister.com/static/player/custom/99051/img/playButton.png',
                width: '100px',
                height: '100px',
                layer: 7
              },
              PreviewImage: {
                layer: 5
              }
            }
          });
        }
      }
    }, 1000);
  }

  /**
   * Loads the visuals and provides
   * the informations to the view.
   */
  private _loadVisuals(reinit?: boolean): void {
    this._apiService.getRequest(this.sourceUrl).subscribe((data: any) => {
      let visualIndex = 0;

      if (data && data.Records.length > 0) {
        this.visuals = data.Records;

        this.visuals.forEach((visual: any) => {
          if (visual.Typ === 1) {
            this.imageZoomModels[visual.ID] = {
              small: visual.Source + '?width=630&height=630&mode=crop',
              large: visual.Source + '?width=1100&height=1100&mode=crop',
              thumb: null
            };
          }
        });

        const visualIndex = this.visuals.findIndex((vis: any) => {
          return vis.PIN === this._imageCarouselService.pin;
        });
      }

      if (visualIndex >= 0) {
        this.selectedCarouselItem = visualIndex;
      } else {
        this.selectedCarouselItem = 0;
      }

      this.slickConfig = {
        pauseOnHover: true,
        initOnLoad: true,
        data: this.visuals,
        speed: 500,
        infinite: false,
        currentIndex: this.selectedCarouselItem,
        arrows: false,
        swipeToSlide: false,
        draggable: false,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              draggable: true,
              swipeToSlide: true
            }
          }
        ]
      };

      this.slickThumbsConfig = {
        initOnLoad: true,
        data: this.visuals,
        swipeToSlide: true,
        focusOnSelect: false,
        infinite: false,
        currentIndex: this.selectedCarouselItem,
        prevArrow: '<span class="slick-slider-nav"><i class="svg-icon __clr-default __nav-prev __top-0 __size-40 __slick-nav-prev-arrow"></i></span>',
        nextArrow: '<span class="slick-slider-nav"><i class="svg-icon __clr-default __nav-next __top-0 __size-40 __slick-nav-next-arrow"></i></span>',
        slidesToShow: 5,
        slidesToScroll: 5,
        centerMode: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

      if (reinit) {
        if (typeof this.imageVideoCarousel !== 'undefined') {
          if (this.imageVideoCarousel.initialized) {
            this.imageVideoCarousel.unslick();
          }

          this.imageVideoCarousel.initSlick();
        }

        if (typeof this.imageVideoCarouselNav !== 'undefined') {
          if (this.imageVideoCarouselNav.initialized) {
            this.imageVideoCarouselNav.unslick();
          }

          this.imageVideoCarouselNav.initSlick();
        }
      }
    },
    (error: any) => {
      console.log(error);
    });
  }

  /**
   * Watch for changes of source url, and
   * reinit, if there are changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['sourceUrl'] !== 'undefined') {
      if (!changes['sourceUrl'].firstChange && changes['sourceUrl'].currentValue !== changes['sourceUrl'].previousValue) {
        this.sourceUrl = changes['sourceUrl'].currentValue;
        this._loadVisuals(true);
      }
    }
  }

  /**
   * Unsubscribes set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._pinSubscription !== 'undefined') {
      this._pinSubscription.unsubscribe();
    }
  }

  /**
   * Used for trackBy within ngFor.
   */
  trackById(index: number, item: any): number {
    return item.ID;
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Event listener for after change
   * slide event. Sets the current
   * selected item, and shows / hides
   * image containers.
   */
  slickAfterChange(evt: any): void {
    const newIndex = evt.currentSlide;
    const visual = this.visuals[newIndex];

    if (newIndex !== this.selectedCarouselItem && this.visuals.length > 0) {
      this._showZoomContainer(this.selectedCarouselItem, false);
      this._showZoomContainer(newIndex, true);
      this.selectedCarouselItem = newIndex;

      if (typeof this.imageVideoCarouselNav !== 'undefined') {
        this.imageVideoCarouselNav.slickGoTo(newIndex);

        const currrentNavSlideElem = document.querySelector(`#imageVideoCarouselNav .slick-slide[data-slick-index="${newIndex}"]`);
        const activeSlideElem = document.querySelector('#imageVideoCarouselNav .slick-slide.slick-current');

        if (activeSlideElem !== null) {
          activeSlideElem.classList.remove('slick-current');
        }

        if (currrentNavSlideElem !== null) {
          if (!currrentNavSlideElem.classList.contains('slick-current')) {
            currrentNavSlideElem.classList.add('slick-current');
          }
        }
      }
    }

    if (typeof visual !== 'undefined') {
      if (visual.PIN !== '00' && this._imageCarouselService.pin !== visual.PIN) {
        this._imageCarouselService.setPin(visual.PIN);
      }
    }
  }

  /**
   * Event listener for before change
   * slide event. Stops running videos, and
   * sets options for threesixty or
   * cliplister.
   */
  slickBeforeChange(evt: any): void {
    const newIndex = evt.nextSlide;
    const visual = this.visuals[newIndex];

    if (visual) {
      const videos = document.querySelectorAll('[id^="clVideo"] video');

      if (videos.length > 0) {
        videos.forEach((video: any) => {
          video.pause();
        });
      }

      if (visual.Typ === 4) {
        this._visual = visual;
        this._buildCliplisterHtml();
      }
    }
  }

  /**
   * Shows / hides image zoom container
   * for given item index.
   */
  private _showZoomContainer(visualIndex: number, show: boolean): void {
    if (visualIndex === null) {
      return;
    }

    const visual = this.visuals[visualIndex];
    if (!visual) return;

    var container = document.getElementById('image' + visual.ID + '-zoomContainer');
    if (!container) return;

    if (show)
      container.style.display = 'block';
    else
      container.style.display = 'none';
  }

  /**
   * Loads image zoom of current item,
   * and unloads all other image zooms.
   */
  imageZoomLoaded(): void {
    this._showZoomContainer(this.selectedCarouselItem, true);

    this.visuals.forEach((visual, index) => {
      if (index !== this.selectedCarouselItem) {
        this._showZoomContainer(index, false);
      }
    });
  }

  /**
   * Prevents the default action for
   * a-tags of slick slider navigation.
   */
  slickNavClicked(evt: MouseEvent): void {
    evt.preventDefault();
  }

  /**
   * Triggers the change of the image slider.
   */
  navSlideChanged(evt: MouseEvent): void {
    evt.preventDefault();
    // @ts-ignore
    const slideIndex = evt.currentTarget.dataset.slickIndex;

    if (slideIndex !== 'undefined') {
      if (typeof this.imageVideoCarousel !== 'undefined') {
        this.imageVideoCarousel.slickGoTo(slideIndex);
      }
    }
  }

  /**
   * Reinits the sliders after orientation
   * changed.
   */
  @HostListener('window:orientationchange')
  onorientationchange() {
    if (typeof this.imageVideoCarouselNav !== 'undefined') {
      this.imageVideoCarouselNav.$instance.slick('resize');
    }

    if (typeof this.imageVideoCarousel !== 'undefined') {
      this.imageVideoCarousel.$instance.slick('resize');
    }
  }

}
