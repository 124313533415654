<div id="praemien-details" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div *ngIf="artNr !== '' && wlLoaded">
    <div id="praemien-details-top">
      <div class="row layout-container">
        <div class="grid col-large-6">
          <mp-core-image-video-carousel sourceUrl="/api/Artikel/getVisuals/{{artNr}}"></mp-core-image-video-carousel>
        </div>
        <div class="grid col-large-6">
          <div class="floating-container" (click)="goBack()" [hidden]="!showBack">
            <button class="mp-button float-right">{{ ls.locs['loc'].Zurueck }}</button>
          </div>
          <div class="margin-top-48">
            <div class="margin-bottom-16">
              <img [src]="brandImagePath+'?maxwidth=150&maxheight=70&s.grayscale=true'"
                   mpCoreErrSrc errSrc="/themes/default/media/images/trans.png" />
            </div>
            <h2>{{artlabel1}}</h2>
            <h1>{{artlabel2}}</h1>
            <div class="margin-top-bottom-24"
                 [ngClass]="{selectContainer: dropDown.values.length > 1}"
                 *ngFor="let dropDown of dropDownItems">
              <mp-core-select class="max-width-256"
                              *ngIf="dropDown.values.length > 1"
                              (modelChange)="selectedCharacteristic[dropDown.dropDownIndex] = $event; setDropDownSelectedValue(dropDown.dropDownIndex)"
                              [model]="selectedCharacteristic[dropDown.dropDownIndex]"
                              [elements]="dropDown.values">
              </mp-core-select>
              <span *ngIf="dropDown.values.length <= 1">{{selectedCharacteristic[dropDown.dropDownIndex]}}</span>
            </div>
            <div [ngSwitch]="availability">
              <span *ngSwitchCase="1">
                <i class="svg-icon  __top-4 __size-18 default-cursor-hover __clr-success __check-circle"></i>
                <span class="icon-text margin-left-8">{{ ls.locs['loc'].AmpelGruen }}</span>
              </span>
              <span *ngSwitchCase="2">
                <i class="svg-icon  __top-4 __size-18 default-cursor-hover __clr-warning __check-circle"></i>
                <span class="icon-text margin-left-8">{{ ls.locs['loc'].AmpelGelb }}</span>
              </span>
              <span *ngSwitchCase="3">
                <i class="svg-icon  __top-4 __size-18 default-cursor-hover __clr-danger __oneway"></i>
                <span class="icon-text margin-left-8">{{ ls.locs['loc'].AmpelRot }}</span>
              </span>
            </div>
            <div [hidden]="!showExpress">
              <span>
                <i class="svg-icon __clr-specific-4 __top-5 __size-18 __express default-cursor-hover"></i>
              </span>
              <span class="hinweis icon-text margin-left-8">{{ ls.locs['loc'].ExpressMoeglich }}</span>
            </div>
            <div [hidden]="diliveryNotice === ''">
              <span>
                <i class="svg-icon __clr-info __top-5 __size-18 __express default-cursor-hover __info"></i>
              </span>
              <span class="hinweis icon-text margin-left-8 lieferhinweis">{{diliveryNotice}}</span>
            </div>
          </div>

          <div class="action">
            <div class="text-align-right">
              <span class="font-size-xl bold" [attr.data-tooltip]="getTooltipText()">{{points | integer}}</span>
              <div class="margin-bottom-16">{{ ls.locs['loc'].Punkte }}</div>
              <div>
                <button [hidden]="!pin"
                        class="mp-button _prim margin-bottom-8"
                        [ngClass]="canOrder ? '' : 'disabled'"
                        (click)="addToShoppingbasket()">
                  <i class="svg-icon __top--1 margin-right-4 __size-18 __basket __clr-white float-none-important margin-right-8"></i>
                  <span class="icon-text">{{ ls.locs['loc'].WarenkorbHinzufuegen }}</span>
                </button>
              </div>
              <div>
                <button class="mp-button" (click)="toggleWishlist($event)">
                  <i [hidden]="!pin" class="svg-icon __top--1 margin-right-8 __size-18 __clr-default" [ngClass]="isInWishlist() ? '__favorite-filled' : '__favorite'"></i>
                  <span [hidden]="isInWishlist()" class="icon-text">{{ ls.locs['loc'].WunschlisteHinzufuegen }}</span>
                  <span [hidden]="!isInWishlist()" class="icon-text">{{ ls.locs['loc'].WLentfernen }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="praemien-details-bottom">
      <mp-core-breadcrumbs *ngIf="mpSettings.settings['DisplaySettings'].ShowBreadcrumbs"></mp-core-breadcrumbs>
      <div class="layout-container">
        <div class="row">
          <div class="grid col-large-6">
            <h3>{{ ls.locs['loc'].ProduktdetailBeschreibung }}</h3>
            <div [innerHTML]="teaser"
                 [hidden]="!teaser">
            </div>
            <div [innerHTML]="description"></div>
          </div>
          <div class="grid col-large-6">
            <h3>{{ ls.locs['loc'].Eigenschaften }}</h3>
            <ul [innerHTML]="properties"
                *ngIf="!showPropertiesList"></ul>
            <table class="table simple"
                   *ngIf="showPropertiesList">
              <tr class="eigenschaftenLine"
                  *ngFor="let eig of propertiesList">
                <td class="eigenschaftKey">{{eig.Item}}</td>
                <td class="eigenschaftValue"
                    [innerHTML]="eig.Value"></td>
              </tr>
            </table>
            <div class="margin-top-bottom-24 font-style-italic">{{ ls.locs['loc'].AenderungenUndIrrtuermer }}</div>
            <div class="text-align-right">{{ ls.locs['loc'].ArtNr }} {{artNr}}</div>
          </div>
        </div>
        <div class="divider"></div>
        <div [hidden]="!showLinks">
          <h2>{{ ls.locs['loc'].Links }}</h2>
          <div [innerHTML]="links"></div>
        </div>
      </div>
    </div>
  </div>
</div>
