import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the global functions
 * for the participant cockpit.
 */
@Injectable({
  providedIn: 'root'
})
export class ParticipantCockpitService implements OnInit {

  public saveFuncs: { [key: string]: any } = {};

  private _role: string = '';
  private _defaultCockpitData: { [key: string]: any } = {
    open: false,
    tnId: -1,
    benutzer: '',
    reloadCounter: 0
  };
  private _cockpitData: { [key: string]: any } = this._defaultCockpitData;
  private _cockpitDataSubj = new Subject<{ [key: string]: any }>();
  private _cockpitDataObservable = this._cockpitDataSubj.asObservable();

  constructor(
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _router: Router
  ) { }

  /**
   * Gets the users role.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }
  }

  /**
   * Returns the cockpit data.
   */
  getData(): Observable<{ [key: string]: any }> {
    return this._cockpitDataObservable;
  }

  /**
   * Triggers the subject / observable.
   */
  triggerObservable(): void {
    this._cockpitDataSubj.next(this._cockpitData);
  }

  /**
   * Registers the cockpit data.
   */
  registerData(cockpitData: { [key: string]: any }): void {
    this._cockpitData = cockpitData;
    this._cockpitDataSubj.next(this._cockpitData);
  }

  /**
   * Opens the participant cockpit
   * for the given participant id.
   */
  showParticipantCockpit(id: number): void {
    if (this._role === '') {
      const role = this._authService.getRole();

      if (typeof role === 'object') {
        this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
      } else {
        this._role = this._roleMapping.getReverseMappedRole(role);
      }
    }

    this._router.navigateByUrl(`/${this._role}/TNCockpit/${id}`);
  }

  /**
   * Closes the cockpit.
   */
  close(): void {
    this._cockpitData['open'] = false;
  }

  /**
   * Reloads the cockpit data.
   */
  reload(): void {
    this._cockpitData['reloadCounter'] += 1;
    this._cockpitDataSubj.next(this._cockpitData);
  }

  /**
   * Resets the cockpit data to the
   * default data.
   */
  resetCockpitData(): void {
    this._cockpitData = this._defaultCockpitData;
  }
}
