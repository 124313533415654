<div class="row" *ngIf="(ls.locsLoaded | async)">
  <div class="grid col-large-6">
    <form name="formProfile" novalidate>
      <mp-core-input mpId="name" label="{{ ls.locs['loc'].Name }}" [(model)]="participant['Name']" class="margin-bottom-24" [hasError]="errorName ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errorName" [model]="ls.locs['validate'].NameRequired"></mp-core-form-error>
      <mp-core-input mpId="vorname" label="{{ ls.locs['loc'].Vorname }}" [(model)]="participant['Vorname']" class="margin-bottom-24" [hasError]="errorFirstname ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errorFirstname" [model]="ls.locs['validate'].VornameRequired"></mp-core-form-error>
      <mp-core-input mpId="strasse" label="{{ ls.locs['loc'].Strasse }}" [(model)]="participant['Strasse']" class="margin-bottom-24" [hasError]="errorStreet ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errorStreet" [model]="ls.locs['validate'].StrasseRequired"></mp-core-form-error>
      <mp-core-input mpId="adresszusatz" label="{{ ls.locs['loc'].Adresszusatz }}" [(model)]="participant['Adresszusatz']" class="margin-bottom-24"></mp-core-input>
      <mp-core-select *ngIf="CountriesList.length > 0" label="{{ ls.locs['loc'].LKZ }}" [(model)]="participant['Land']" [elements]="CountriesList" mpId="ddlLKZ" textProperty="Land" [mpRequired]="true" class="margin-bottom-24" [hasError]="errorCountry ? true : false"></mp-core-select>
      <mp-core-form-error *ngIf="errorCountry" [model]="ls.locs['validate'].LandRequired"></mp-core-form-error>
      <mp-core-input mpId="plz" label="{{ ls.locs['loc'].PLZ }}" [(model)]="participant['PLZ']" class="margin-bottom-24" [hasError]="errorZip ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errorZip" [model]="ls.locs['validate'].PLZRequired"></mp-core-form-error>
      <mp-core-input mpId="ort" label="{{ ls.locs['loc'].Ort }}" [(model)]="participant['Ort']" class="margin-bottom-24" [hasError]="errorCity ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errorCity" [model]="ls.locs['validate'].OrtRequired"></mp-core-form-error>
      <mp-core-input mpId="email" label="{{ ls.locs['loc'].EMail }}" [(model)]="participant['EMail']" class="margin-bottom-24" [hasError]="errorEMail ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errorEMail" [model]="ls.locs['validate'].EMailRequired"></mp-core-form-error>
    </form>
  </div>
</div>
