import { Component, OnInit, AfterViewInit, OnChanges, Input, ViewEncapsulation, SimpleChanges, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ScrollButtonService } from './scroll-button.service';
import { MpSidebarService } from './../sidebar/mp-sidebar.service';
import { CustomEventService, CustomEventType, CustomEvent } from './../../services/custom-event.service';

/**
 * This class provides the scroll buttons within a
 * sidebar. User can use the buttons to scroll
 * down / up the sidebars content.
 */
@Component({
  selector: 'mp-core-scroll-button',
  templateUrl: './scroll-button.component.html',
  styleUrls: ['./scroll-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollButtonComponent implements OnInit, OnDestroy {

  @Input() public scrollArea: string = '';
  @Input() public scrollDirection: string = '';
  @Input() public scrollFactor: string = '';
  @Input() public scrollItemHeight: string = '';
  @Input() public desktopItemHeight: string = '';
  @Input() public ifNeeded: boolean = false;
  @Input() public scopeObject: any | undefined;
  @Input() public secondScopeObject: any | undefined;
  @Input() public thirdScopeObject: any | undefined;
  @Input() public fourthScopeObject: any | undefined;
  @Input() public showWkOverlay: any | undefined;

  private _scrollArea: HTMLElement | null = null;
  private _scrollFactor: number = 1;
  private _scrollItemHeight: number | undefined;
  private _desktopItemHeight: string | number | undefined;
  private _scrollAreaCssHeight: number = 0;
  private _scrollAreaParentCssHeight: number = 0;
  private _scrollItemCssHeight: number = 0;
  private _sbItemsPosArr: number[] = [];
  private _sbCurrItem: number = 0;
  private _scrollPos: number | undefined;
  private _waitForScrollAreaIntervalCounter: number = 0;
  private _sendGroupingBoolEventSubscription: Subscription | undefined;

  constructor(
    private _scrollButtonService: ScrollButtonService,
    private _mpSidebarService: MpSidebarService,
    private _customEventService: CustomEventService,
    private _scrollButtonElement: ElementRef
  ) { }

  /**
   * Asigns some given input values to private
   * variables.
   */
  ngOnInit(): void {
    if (this.scrollFactor !== '') {
      this._scrollFactor = parseInt(this.scrollFactor);
    }

    if (this.scrollItemHeight !== '') {
      this._scrollItemHeight = parseInt(this.scrollItemHeight);
    }

    if (this.desktopItemHeight !== '') {
      if (!isNaN(parseInt(this.desktopItemHeight))) {
        this._desktopItemHeight = parseInt(this.desktopItemHeight);
      } else {
        this._desktopItemHeight = this.desktopItemHeight;
      }
    }

    this._sendGroupingBoolEventSubscription = this._customEventService.on(CustomEventType.ShowHideScrollButtons).subscribe((event: CustomEvent<any>) => {
      this._showHideScrollButtons(event.payload, true);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sendGroupingBoolEventSubscription !== 'undefined') {
      this._sendGroupingBoolEventSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the showing / hiding of the scroll
   * buttons on resize or removes this resize
   * listener, if not needed anymore.
   */
  private _resizeEventShowHideFunc(): void {
    if (!this._mpSidebarService.visible) {
      window.removeEventListener('resize', this._resizeEventShowHideFunc.bind(this), false);
    } else {
      this._showHideScrollButtons(this._scrollArea, this.ifNeeded);
    }
  }

  /**
   * Checks if the content area of the
   * sidebar is rendered completly, and
   * triggers the needed functions to
   * handle the scroll buttons, the scroll
   * distances, and so on.
   */
  ngAfterViewInit(): void {
    let waitForScrollAreaInterval = setInterval(() => {
      this._scrollArea = document.querySelector(this.scrollArea);

      if (this._scrollArea !== null) {
        clearInterval(waitForScrollAreaInterval);
        this._setScrollHeight();
        window.addEventListener('resize', this._setScrollHeight.bind(this), false);
        const scrollAreaParent = this._scrollArea.parentElement;

        if (scrollAreaParent !== null) {
          scrollAreaParent.addEventListener('scroll', this._scrollByMouse.bind(this), false);
        }

        this._scrollButtonElement.nativeElement.addEventListener('click', this._scroll.bind(this), false);

        if (typeof this.ifNeeded !== 'undefined') {
          if (this.ifNeeded) {
            this._showHideScrollButtons(this._scrollArea, this.ifNeeded);
          }
        }
      }

      if (this._waitForScrollAreaIntervalCounter >= 40) {
        clearInterval(waitForScrollAreaInterval);
        return false;
      }

      this._waitForScrollAreaIntervalCounter++;
    }, 50);

    this._mpSidebarService.toggleSidebar.subscribe((visible: boolean) => {
      this._scrollArea = document.querySelector(this.scrollArea);
      let intervalCounter = 0;

      let scrollAreaInterval = setInterval(() => {
        this._scrollArea = document.querySelector(this.scrollArea);

        if (this._scrollArea !== null && typeof this._scrollArea !== 'undefined') {
          clearInterval(scrollAreaInterval);
          const scrollDownButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
          const scrollUpButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);

          if (scrollDownButton !== null && scrollUpButton !== null) {
            if (scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
              scrollDownButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
            }

            if (!scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
              scrollUpButton.classList.add(this._scrollButtonService.inactiveButtonClass);
            }

            scrollDownButton.setAttribute('hidden', '');
            scrollUpButton.setAttribute('hidden', '');

            if (!scrollUpButton.classList.contains('buttonHidden')) {
              scrollUpButton.classList.add('buttonHidden');
            }
          }

          if (this._scrollArea.querySelector('[first-active-day]') === null) {
            const scrollAreaParent = this._scrollArea.parentElement;

            if (scrollAreaParent !== null) {
              if (typeof scrollAreaParent.scroll !== 'undefined') {
                scrollAreaParent.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              } else {
                scrollAreaParent.scrollTop = 0;
              }
            }
          }

          setTimeout(() => {
            if (visible) {
              if (this.ifNeeded) {
                window.addEventListener('resize', this._resizeEventShowHideFunc.bind(this), false);
              } else {
                if (scrollDownButton !== null && scrollUpButton !== null) {
                  scrollDownButton.removeAttribute('hidden');
                  scrollUpButton.removeAttribute('hidden');

                  if (scrollUpButton.classList.contains('buttonHidden')) {
                    scrollUpButton.classList.remove('buttonHidden');
                  }
                }
              }
            }
          }, 500);
        }

        if (intervalCounter >= 40) {
          clearInterval(scrollAreaInterval);
          return false;
        }

        intervalCounter++;
      }, 100);
    });
  }

  /**
   * Updates the scroll position of the
   * sidebars content on scroll via mouse
   * wheel.
   */
  private _scrollByMouse(): void {
    this._scrollArea = document.querySelector(this.scrollArea);

    if (this._scrollArea !== null) {
      const scrollAreaParent = this._scrollArea.parentElement;

      if (scrollAreaParent !== null) {
        const scrollDownButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
        const scrollUpButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);

        this._scrollAreaCssHeight = this._scrollArea.getBoundingClientRect().height;
        this._scrollAreaParentCssHeight = scrollAreaParent.getBoundingClientRect().height;
        this._scrollPos = scrollAreaParent.scrollTop;
        const dataSbcurritem = this._scrollArea.getAttribute('data-sbcurritem');

        if (dataSbcurritem !== null && dataSbcurritem !== '') {
          this._sbCurrItem = parseInt(dataSbcurritem);
        }

        if (this._sbItemsPosArr.length > 0 && this._scrollButtonService.scrollBtnClicked === false) {
          if (this._scrollPos > this._sbItemsPosArr[this._sbCurrItem + 1]) {
            this._sbCurrItem++;
            this._scrollArea.setAttribute('data-sbcurritem', this._sbCurrItem.toString());
          } else if (this._scrollPos < this._sbItemsPosArr[this._sbCurrItem - 1]) {
            this._sbCurrItem--;
            this._scrollArea.setAttribute('data-sbcurritem', this._sbCurrItem.toString());
          }
        }

        if ((this._scrollPos - this._scrollItemCssHeight) <= -this._scrollItemCssHeight) {
          if (scrollUpButton !== null && !scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
            scrollUpButton.classList.add(this._scrollButtonService.inactiveButtonClass);
          }
        } else {
          if (scrollUpButton !== null && scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
            scrollUpButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
          }
        }

        if (this._scrollPos >= (this._scrollAreaCssHeight - this._scrollAreaParentCssHeight)) {
          if (scrollDownButton !== null && !scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
            scrollDownButton.classList.add(this._scrollButtonService.inactiveButtonClass);
          }
        } else {
          if (scrollDownButton !== null && scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
            scrollDownButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
          }
        }
      }
    }
  }

  /**
   * Calculates the height of a 'scroll' (= click
   * on scroll button).
   */
  private _setScrollHeight(): void {
    if (window.innerHeight >= 768) {
      if (typeof this._desktopItemHeight !== 'undefined') {
        if (typeof this._desktopItemHeight === 'number') {
          this._scrollItemCssHeight = this._desktopItemHeight;
        } else {
          let intervCnt = 0;

          let searchItemInterv = setInterval(() => {
            intervCnt++;
            const scrollItem = document.querySelector(`${this.scrollArea} ${this._desktopItemHeight}`);

            if (scrollItem !== null) {
              clearInterval(searchItemInterv);

              const scrollItems = document.querySelectorAll(`${this.scrollArea} ${this._desktopItemHeight}`);

              if (scrollItems.length > 0) {
                if (this._sbItemsPosArr.length === 0) {
                  scrollItems.forEach((item, index) => {
                    if (index === 0) {
                      this._sbItemsPosArr.push(0);
                    } else {
                      this._sbItemsPosArr.push(this._sbItemsPosArr[index - 1] + parseInt(window.getComputedStyle(item).getPropertyValue('height').replace('px', '')) + parseInt(window.getComputedStyle(scrollItem).getPropertyValue('margin-bottom').replace('px', '')));
                    }
                  });
                }
              } else {
                const cssHeight = window.getComputedStyle(scrollItem).getPropertyValue('height');
                this._scrollItemCssHeight = cssHeight ? parseInt(cssHeight.replace('px', '')) : 0;
              }
            } else if (intervCnt === 20) {
              clearInterval(searchItemInterv);
              this._sbItemsPosArr = [];
              const itemForHeight = this._scrollArea !== null ? this._scrollArea.querySelector('.scroll-item') : null;

              if (typeof this._scrollItemHeight !== 'undefined') {
                this._scrollItemCssHeight = this._scrollItemHeight;
              } else if (itemForHeight !== null) {
                this._scrollItemCssHeight = parseInt(window.getComputedStyle(itemForHeight).getPropertyValue('height').replace('px', ''));
              } else {
                this._scrollItemCssHeight = 100;
              }
            }
          }, 50);
        }
      } else {
        this._sbItemsPosArr = [];
        const itemForHeight = this._scrollArea !== null ? this._scrollArea.querySelector('.scroll-item') : null;

        if (typeof this._scrollItemHeight !== 'undefined') {
          this._scrollItemCssHeight = this._scrollItemHeight;
        } else if (itemForHeight !== null) {
          this._scrollItemCssHeight = parseInt(window.getComputedStyle(itemForHeight).getPropertyValue('height').replace('px', ''));
        } else {
          this._scrollItemCssHeight = 100;
        }
      }
    } else {
      this._sbItemsPosArr = [];
      const itemForHeight = this._scrollArea !== null ? this._scrollArea.querySelector('.scroll-item') : null;

      if (typeof this._scrollItemHeight !== 'undefined') {
        this._scrollItemCssHeight = this._scrollItemHeight;
      } else if (itemForHeight !== null) {
        this._scrollItemCssHeight = parseInt(window.getComputedStyle(itemForHeight).getPropertyValue('height').replace('px', ''));
      } else {
        this._scrollItemCssHeight = 100;
      }
    }
  }

  /**
   * Performs the scroll, when the user
   * clicks a scroll button, and sets the
   * up / down button inactive / active,
   * when the user reaches / leaves the
   * beginning / end of the sidebar.
   */
  private _scroll(): void | boolean {
    this._scrollArea = document.querySelector(this.scrollArea);

    if (this._scrollArea !== null) {
      if (!this._scrollButtonService.scrollBtnClicked) {
        this._scrollButtonService.scrollBtnClicked = true;
        const scrollAreaParent = this._scrollArea.parentElement;

        if (scrollAreaParent !== null) {
          const scrollDownButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
          const scrollUpButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);
          const dataSbcurritem = this._scrollArea.getAttribute('data-sbcurritem');

          if (dataSbcurritem !== null && dataSbcurritem !== '') {
            this._sbCurrItem = parseInt(dataSbcurritem);
          }

          this._scrollAreaCssHeight = this._scrollArea.getBoundingClientRect().height;
          this._scrollAreaParentCssHeight = scrollAreaParent.getBoundingClientRect().height;
          this._scrollPos = scrollAreaParent.scrollTop;

          if (this.scrollDirection === 'down' && scrollDownButton !== null && scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
            this._scrollButtonService.scrollBtnClicked = false;
            return false;
          }

          if (this.scrollDirection === 'up' && scrollUpButton !== null && scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
            this._scrollButtonService.scrollBtnClicked = false;
            return false;
          }

          const scrollStepsPx = this._scrollItemCssHeight * this._scrollFactor;

          if (this._sbItemsPosArr.length > 0) {
            if (this.scrollDirection === 'down' && this._sbCurrItem < this._sbItemsPosArr.length) {
              this._sbCurrItem++;

              if (typeof scrollAreaParent.scroll !== 'undefined') {
                scrollAreaParent.scroll({
                  top: this._sbItemsPosArr[this._sbCurrItem],
                  left: 0,
                  behavior: 'smooth'
                })
              } else {
                scrollAreaParent.scrollTop = this._sbItemsPosArr[this._sbCurrItem];
              }
            } else if (this.scrollDirection === 'up' && this._sbCurrItem > 0) {
              this._sbCurrItem--;

              if (typeof scrollAreaParent.scroll !== 'undefined') {
                scrollAreaParent.scroll({
                  top: this._sbItemsPosArr[this._sbCurrItem],
                  left: 0,
                  behavior: 'smooth'
                })
              } else {
                scrollAreaParent.scrollTop = this._sbItemsPosArr[this._sbCurrItem];
              }
            }

            this._scrollArea.setAttribute('data-sbcurritem', this._sbCurrItem.toString());
          }

          if (this._sbItemsPosArr.length === 0) {
            if (this.scrollDirection === 'up' && !(this._scrollPos <= 0)) {

              if (typeof scrollAreaParent.scroll !== 'undefined') {
                scrollAreaParent.scroll({
                  top: this._scrollPos - scrollStepsPx,
                  left: 0,
                  behavior: 'smooth'
                })
              } else {
                scrollAreaParent.scrollTop = this._scrollPos - scrollStepsPx;
              }
            } else if (this.scrollDirection === 'down' && !(this._scrollPos <= -(this._scrollAreaCssHeight - this._scrollAreaParentCssHeight))) {

              if (typeof scrollAreaParent.scroll !== 'undefined') {
                scrollAreaParent.scroll({
                  top: this._scrollPos + scrollStepsPx,
                  left: 0,
                  behavior: 'smooth'
                })
              } else {
                scrollAreaParent.scrollTop = this._scrollPos + scrollStepsPx;
              }
            }
          }

          this._sbItemsPosArr = [];
          this._setScrollHeight();

          setTimeout(() => {
            this._scrollPos = scrollAreaParent.scrollTop;

            if ((this._scrollPos - this._scrollItemCssHeight) <= -this._scrollItemCssHeight) {
              if (scrollUpButton !== null && !scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                scrollUpButton.classList.add(this._scrollButtonService.inactiveButtonClass);
              }
            } else {
              if (scrollUpButton !== null && scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                scrollUpButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
              }
            }

            if (this._scrollPos >= (this._scrollAreaCssHeight - this._scrollAreaParentCssHeight)) {
              if (scrollDownButton !== null && !scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                scrollDownButton.classList.add(this._scrollButtonService.inactiveButtonClass);
              }
            } else {
              if (scrollDownButton !== null && scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                scrollDownButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
              }
            }

            this._scrollButtonService.scrollBtnClicked = false;
          }, 500);
        }
      }
    }
  }

  /**
   * Shows / hides the scroll buttons, if
   * they are not needed / needed.
   */
  private _showHideScrollButtons(scrollArea: HTMLElement | null, ifNeeded: boolean): void | boolean {
    if (!ifNeeded) {
      return false;
    }

    setTimeout(() => {
      if (scrollArea !== null || typeof scrollArea === 'undefined') {
        if (this.scrollArea !== '') {
          scrollArea = document.querySelector(this.scrollArea);
        } else {
          return false;
        }
      }

      if (scrollArea !== null) {
        const scrollAreaParent = scrollArea.parentElement;

        if (scrollAreaParent !== null) {
          const scrollAreaParentCssHeight = scrollAreaParent.getBoundingClientRect().height;
          const scrollAreaCssHeight = scrollArea.getBoundingClientRect().height;
          const scrollDownButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
          const scrollUpButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);

          if (scrollDownButton !== null && scrollUpButton !== null) {
            if (scrollAreaCssHeight <= scrollAreaParentCssHeight) {
              scrollDownButton.setAttribute('hidden', '');
              scrollUpButton.setAttribute('hidden', '');
              
              if (!scrollUpButton.classList.contains('buttonHidden')) {
                scrollUpButton.classList.add('buttonHidden');
              }
            } else {
              scrollDownButton.removeAttribute('hidden');
              scrollUpButton.removeAttribute('hidden');

              if (scrollUpButton.classList.contains('buttonHidden')) {
                scrollUpButton.classList.remove('buttonHidden');
              }
            }
          }
        }
      }
    }, 700);
  }

  /**
   * Gets scroll area (content of sidebar),
   * and triggers the showing / hiding of the
   * scroll buttons, when a 'watched' object
   * has changed.
   */
  private _handleScopeObjectChanges(): void {
    if (this.ifNeeded) {
      this._scrollArea = document.querySelector(this.scrollArea);

      if (this._scrollArea !== null && typeof this._scrollArea !== 'undefined') {
        this._showHideScrollButtons(this._scrollArea, this.ifNeeded);
      }
    }
  }

  /**
   * Watches given objects for changes, and
   * triggers some functions to refresh
   * the scroll button handling, when
   * the watched objects changed.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.scopeObject !== 'undefined' && typeof changes['scopeObject'] !== 'undefined' && changes['scopeObject'] !== null) {
      if (changes['scopeObject'].currentValue !== changes['scopeObject'].previousValue) {
        this._handleScopeObjectChanges();
      }
    }

    if (typeof this.secondScopeObject !== 'undefined' && typeof changes['secondScopeObject'] !== 'undefined' && changes['secondScopeObject'] !== null) {
      if (changes['secondScopeObject'].currentValue !== changes['secondScopeObject'].previousValue) {
        this._handleScopeObjectChanges();
      }
    }

    if (typeof this.thirdScopeObject !== 'undefined' && typeof changes['thirdScopeObject'] !== 'undefined' && changes['thirdScopeObject'] !== null) {
      if (changes['thirdScopeObject'].currentValue !== changes['thirdScopeObject'].previousValue) {
        this._handleScopeObjectChanges();
      }
    }

    if (typeof this.fourthScopeObject !== 'undefined' && typeof changes['fourthScopeObject'] !== 'undefined' && changes['fourthScopeObject'] !== null) {
      if (changes['fourthScopeObject'].currentValue !== changes['fourthScopeObject'].previousValue) {
        this._handleScopeObjectChanges();
      }
    }

    if (typeof this.showWkOverlay !== 'undefined' && typeof changes['showWkOverlay'] !== 'undefined' && changes['showWkOverlay'] !== null) {
      if (changes['showWkOverlay'].currentValue === true) {
        this._scrollArea = document.querySelector(this.scrollArea);
        let intervalCounter = 0;

        let scrollAreaInterval = setInterval(() => {
          this._scrollArea = document.querySelector(this.scrollArea);

          if (this._scrollArea !== null) {
            clearInterval(scrollAreaInterval);
            const scrollDownButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
            const scrollUpButton = document.querySelector(`[scrollArea="#${this._scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);

            if (scrollDownButton !== null && scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
              scrollDownButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
            }

            if (scrollUpButton !== null && !scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
              scrollUpButton.classList.add(this._scrollButtonService.inactiveButtonClass);
            }

            const scrollAreaParent = this._scrollArea.parentElement;

            if (scrollAreaParent !== null) {
              if (typeof scrollAreaParent.scroll !== 'undefined') {
                scrollAreaParent.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              } else {
                scrollAreaParent.scrollTop = 0;
              }
            }

            this._showHideScrollButtons(this._scrollArea, this.ifNeeded);
          }

          if (intervalCounter >= 40) {
            clearInterval(scrollAreaInterval);
            return false;
          }

          intervalCounter++;
        }, 100);
      }
    }
  }
}
