<div [hidden]="hideFilter" *ngIf="(ls.locsLoaded | async)"
     class="form-group margin-bottom-16">
  <mp-core-select label="{{ ls.locs['loc'].Thema }}"
             *ngIf="topics.length > 0"
             mpId="ddlFAQ"
             (ngChange)="closeFAQ()"
             [(model)]="choice"
             [hasError]="errors['data.ThemaID'] ? true : false"
             [elements]="topics"
             textProperty="Thema"
             [mpRequired]="true">
  </mp-core-select>
  <mp-core-form-error *ngIf="errors['data.ThemaID']" [model]="errors['data.ThemaID']"></mp-core-form-error>
</div>
<mp-core-search [mpPlaceholder]="showPlaceholder ? ls.locs['loc'].FaqSuche : ''"
                [hidden]="hideSearchbox"
                mpId="faq-suche"
                [(model)]="searchTerm"
                class="sb-search margin-bottom-16">
  <i class="position-absolute-top-right svg-icon __clr-default __size-24 margin-top-8 margin-right-4 __search"></i>
</mp-core-search>
<mp-core-scroll-button id="overlay-scroll-up"
               [ifNeeded]="true"
               desktopItemHeight=".faqTextWeiterhelfen"
               scrollItemHeight="80"
               scrollArea="#faq-scroll-accordion"
               [scopeObject]="filteredFAQ"
               scrollDirection="up"
               scrollFactor="1"
               class="scrollbutton-up inactive-scroll-button">
  <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
</mp-core-scroll-button>
<div class="divider margin-top-bottom-32"></div>
<h3 [hidden]="hideLabel || faqCount <= 0 || !choice.ThemaID"
   class="faqTextWeiterhelfen">
  <span> {{ ls.locs['loc'].Supporttool_Kontaktform_weiterhelfentext }}</span>
</h3>

<div class="faqTexte accordion scroll-area-container"
     id="scroll-accordion">
  <div [hidden]="faqCount > 0 || hideSearchbox || searchTerm === ''">{{ ls.locs['loc'].KeineErgebnisse }}</div>
  <mp-core-accordion class="sidebar-scroll-area position-relative"
                     id="faq-scroll-accordion"
                     [accToggle]="true"
                     [accDataExpression]="filteredFAQFunc"
                     [sidebarAcc]="true"
                     dataScrollArea="#faq-scroll-accordion"
                     [hasTranscluded]="true"
                     [dataScrollItemHeight]="80">
  </mp-core-accordion>
</div>
<mp-core-scroll-button id="overlay-scroll-down"
               [ifNeeded]="true"
               desktopItemHeight=".faqTextWeiterhelfen"
               scrollItemHeight="80"
               scrollArea="#faq-scroll-accordion"
               [scopeObject]="filteredFAQ"
               scrollDirection="down"
               scrollFactor="1"
               class="scrollbutton-down margin-top-16">
  <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
</mp-core-scroll-button>
