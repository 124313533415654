import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './../../services/api.service';
import { CookieService } from './../../services/cookie.service';
import { AuthService } from './../../services/auth.service';
import { MpMessagingService } from './../../services/mp-messaging.service';

/**
 * This class provides the functions to log in the
 * user into the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn: Observable<boolean> = this._isLoggedIn.asObservable();

  constructor(
    private _apiService: ApiService,
    private _cookieService: CookieService,
    private _authService: AuthService,
    private _mpMessagingService: MpMessagingService,
    private _router: Router
  ) { }

  /**
   * Success callback for login function.
   * Sets the cookies and stores the token in
   * the session storage. Routes the user to
   * given (from api) route.
   */
  successCallback(data: any, user: string | null, keepPageAndSessionStorage?: boolean) {
    this._authService.setToken(data.Token);

    if (!keepPageAndSessionStorage) {
      sessionStorage.clear();
    }

    sessionStorage.setItem('token', data.Token);
    this._authService.setRoleByToken(data.Token);

    if (data.SetCurrentLoginCookie && user) {
      this._cookieService.setCookie(`CurrentLogin=${user}`, { secure: true, expires: 14 });
    }

    if (typeof data.Redirect !== 'undefined' && data.Redirect !== '') {
      this._router.navigateByUrl(data.Redirect.replace('/#', ''));
    } else if (!keepPageAndSessionStorage) {
      this._router.navigateByUrl('/');
    }

    this._isLoggedIn.next(true);
    this._isLoggedIn.complete();
  }

  /**
   * Sets the users logged in state to true.
   */
  setUserLoggedIn(): void {
    this._authService.setUserLoggedIn();
  }

  /**
   * Error callback for the login function.
   * Sets the users logged in state to false and
   * shows the error messages.
   */
  private _errorCallback(error: any) {
    if (error.ModelState) {
      var errors = Object.keys(error.ModelState);

      if (errors[0]) {
        console.log(error.ModelState[errors[0]]);
        this._mpMessagingService.openDangerPanel(error.ModelState[errors[0]]);
      }
    } else {
      console.log(error.Message);
      this._mpMessagingService.openDangerPanel(error.Message);
    }

    this._isLoggedIn.next(false);
    this._isLoggedIn.complete();
  }

  /**
   * Trys to log in the user with the typed in
   * credentials.
   */
  login(user: string, pwd: string, keepPageAndSessionStorage?: boolean): void {
    let loginParameters = {
      "Username": user,
      "Password": pwd
    };

    if (sessionStorage['redirectPath']) {
      let redirectUri = sessionStorage['redirectPath'];

      if (sessionStorage['redirectSearch']) {
        redirectUri = redirectUri + '?' + encodeURI(sessionStorage['redirectSearch']);
      }

      loginParameters = Object.assign(loginParameters, { RedirectUri: redirectUri });
    }

    this._apiService.postRequest('/api/Auth/Login', loginParameters).subscribe((data: any) => {
      this.successCallback(data, user, keepPageAndSessionStorage);
    },
      (error: any) => {
        this._errorCallback(error);
      });
  }
}
