import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { RoleMappingService } from './role-mapping.service';

/**
 * This class provides functions, that
 * can be used globally.
 */
@Injectable({
  providedIn: 'root'
})
export class MpCoreService {

  public lastLocation: string = '';
  public lastNavigationId: number = 0;
  public usedBack: boolean = false;
  public role: string = '';

  constructor(
    private _location: Location,
    private _router: Router,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService
  ) { }

  /**
   * Changes the location to the
   * given path / tab.
   */
  goTo(tab?: string, extern?: boolean): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (typeof tab === 'undefined') {
      window.history.back();
    }

    if (extern) {
      this._router.navigate([`/${tab}`]);
    } else {
      this._router.navigate([`/${this.role}/Home`], { queryParams: { tab: tab } });
    }
  }

  /**
   * Goes back to the previous
   * loacation.
   */
  goBack(): void {
    if (history.length <= 1)
      this._router.navigate(['/']);

    window.history.back();
  }

  /**
   * Reload a component
   */
  reloadComponent() {
    let currentUrl = this._router.url;
    const hasQueryParams = currentUrl.indexOf('?');
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';

    if (hasQueryParams !== -1) {
      const currentUrlSplit = currentUrl.split('?');
      currentUrl = currentUrlSplit[0];

      this._router.navigate([currentUrl], {
        queryParamsHandling: 'preserve'
      });
    } else {
      this._router.navigate([currentUrl]);
    }
  }

}
