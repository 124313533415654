import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

/**
 * This class provides major functions
 * for the tip and win module.
 */
@Injectable({
  providedIn: 'root'
})
export class TipAndWinService {

  public campaign: string = '';
  public openTips = 0;
  public openQuestions = 0;
  public openAdditionalQuestions = 0;
  public openPerformance = 0;

  private _rankingUpdated: Subject<boolean> = new Subject<boolean>();
  public rankingUpdated: Observable<boolean> = this._rankingUpdated.asObservable();
  private _tabHasFocus: Subject<{ [key: string]: any }> = new Subject<{ [key: string]: any }>();
  public tabHasFocus: Observable<{ [key: string]: any }> = this._tabHasFocus.asObservable();

  constructor() { }

  /**
   * Triggers the update of the ranking.
   */
  updateRanking(): void {
    this._rankingUpdated.next(true);
  }

  /**
   * Toggles the focus state of the given
   * tab.
   */
  setTabHasFocus(tab: string, focus: boolean): void {
    this._tabHasFocus.next({
      tab: tab,
      focus: focus
    });
  }
}
