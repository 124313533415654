import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpUrlEncodingCodec } from '@angular/common/http';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { ApiService } from './../../services/api.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * This class provides the data and
 * functions for the brands slider.
 */
@Component({
  selector: 'mp-core-brands-slider',
  templateUrl: './brands-slider.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BrandsSliderComponent implements OnInit, OnDestroy {

  public role: string = '';
  public brands: Array<any> = [];
  public brandsSliderProperties: OwlOptions = {
    loop: true,
    nav: true,
    navText: ['<i class="svg-icon __size-48 __top-0 __clr-default __nav-prev">', '<i class="svg-icon __size-48 __top-0 __clr-default __nav-next">'],
    margin: 0,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 16,
        stagePadding: 24
      },
      331: {
        items: 2,
        margin: 16,
        stagePadding: 24
      },
      551: {
        items: 3,
        margin: 16,
        stagePadding: 24
      },
      719: {
        items: 4,
        margin: 16,
        stagePadding: 24
      },
      943: {
        items: 5,
        margin: 16,
        stagePadding: 24
      },
      1151: {
        items: 7,
        margin: 0,
        stagePadding: 0
      }
    }
  };

  private _codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
  private _getBrandsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the role and the brands.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._getBrandsSubscription = this._apiService.getRequest('/api/Marken/GetMarken').subscribe((data: any) => {
      this.brands = data.Records;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getBrandsSubscription !== 'undefined') {
      this._getBrandsSubscription.unsubscribe();
    }
  }

  /**
   * Encodes the given string.
   */
  encode(str: string): string {
    return this._codec.encodeValue(str);
  }

}
