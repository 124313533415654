import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ParticipantCockpitService } from './../../../manager/pages/participant-cockpit/participant-cockpit.service';
import { DataTableChildsService } from './../../../../components/data-table/data-table-childs.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

import { OrderOverviewChildComponent } from './order-overview-child/order-overview-child.component';

/**
 * This class provides the data and functions for
 * the order overview table.
 */
@Component({
  selector: 'mp-core-manager-order-overview',
  templateUrl: './order-overview.component.html'
})
export class OrderOverviewComponent implements OnInit, OnDestroy {
  
  public showEditOverlay: boolean = false;
  public selection: { [key: string]: any } = {
    tnAdresse: {}
  };
  public best: { [key: string]: any } = {};
  public excelService: string = '/api/Statistik/GetBestelluebersicht2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    },
    {
      backendName: 'openEditBestellung',
      func: this.openEditOrder.bind(this)
    }
  ];
  public ptcnptAddresses: Array<any> = [];
  public ddlData: { [key: string]: any } = {};
  public modalDataLoaded: boolean = false;
  public errorSalutation: string = '';
  public errorName: string = '';
  public errorPrename: string = '';
  public errorStreet: string = '';
  public errorCity: string = '';
  public errorZIP: string = '';
  public errorCountry: string = '';
  public cancel = this._cancel.bind(this);
  public confirm = this._confirm.bind(this);

  private _managerOrderOverviewTableLoader: MpLoader = {
    name: 'managerOrderOverviewTableLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;
  private _getParticipantAddressesSusbcription: Subscription | undefined;
  private _getCurrentAddressSubscription: Subscription | undefined;
  private _saveOrderPosAddressSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _participantCockpitService: ParticipantCockpitService,
    private _dataTableChilds: DataTableChildsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('/Views/ng-templates/BestellUebersichtChild', OrderOverviewChildComponent);
    this._titleService.setTitleFromLoc('Bestelluebersicht');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._managerOrderOverviewTableLoader);

    this._mpLoaderService.extendLoader('managerOrderOverviewTableLoader', {
      isReady: true
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getParticipantAddressesSusbcription !== 'undefined') {
      this._getParticipantAddressesSusbcription.unsubscribe();
    }

    if (typeof this._getCurrentAddressSubscription !== 'undefined') {
      this._getCurrentAddressSubscription.unsubscribe();
    }

    if (typeof this._saveOrderPosAddressSubscription !== 'undefined') {
      this._saveOrderPosAddressSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('managerOrderOverviewTableLoader');
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['TnID']);
  }

  /**
   * Opens the edit order modal.
   */
  openEditOrder(best: { [key: string]: any }): void {
    this.best = best;
    this.ptcnptAddresses = [];
    this.selection['tnAdresse'] = null;
    this.ddlData = {};
    this.showEditOverlay = true;
    this._getPtcpntAddresses();
  }

  /**
   * Gets the participant address.
   */
  private _getPtcpntAddresses(): void {
    this._getParticipantAddressesSusbcription = this._apiService.getRequest(`/api/Teilnehmer/getTNAdressen/${this.best['TnID']}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.ptcnptAddresses = data.Records;
        this.ddlData = data.footer[0];
        this._getCurrentAddress();
      }
    });
  }

  /**
   * Gets the current address.
   */
  private _getCurrentAddress(): void {
    this._getCurrentAddressSubscription = this._apiService.getRequest(`/api/Bestellung/getBestellPosAdresse/${this.best['BestellNr']}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.selection['tnAdresse'] = data.Records[0];
        this.modalDataLoaded = true;
      }
    });
  }

  /**
   * Cancels the edit of an order.
   */
  private _cancel(): void {
    this.best = {};
    this.selection['tnAdresse'] = {};
    this.ptcnptAddresses = [];
    this.errorSalutation = '';
    this.errorName = '';
    this.errorPrename = '';
    this.errorStreet = '';
    this.errorCity = '';
    this.errorZIP = '';
    this.errorCountry = '';
    this.showEditOverlay = false;
    this.modalDataLoaded = false;
  }

  /**
   * Confirms the edit of an order.
   */
  private _confirm(): void {
    if (this._verify()) {
      const setAddress = {
        BestNr: this.best['BestellNr'],
        Adresse: this.selection['tnAdresse']
      };

      this._saveOrderPosAddressSubscription = this._apiService.postRequest('/api/Bestellung/setBestellPosAdresse/', setAddress).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._mpMessaging.openSuccessPanel(data.Message);
          this._cancel();
        }
      });
    }
  }

  /**
   * Verifies the form data of the edit
   * order modal.
   */
  private _verify(): boolean {
    this.errorSalutation = '';
    this.errorName = '';
    this.errorPrename = '';
    this.errorStreet = '';
    this.errorCity = '';
    this.errorZIP = '';
    this.errorCountry = '';

    if (!this.selection['tnAdresse'].AnredenID) {
      this.errorSalutation = 'Bitte eine Anrede auswählen.';
      return false;
    }

    if (!this.selection['tnAdresse'].Name) {
      this.errorName = 'Bitte einen Namen eingeben.';
      return false;
    }

    if (!this.selection['tnAdresse'].Vorname) {
      this.errorPrename = 'Bitte einen Vornamen eingeben.';
      return false;
    }

    if (!this.selection['tnAdresse'].Strasse) {
      this.errorStreet = 'Bitte eine Strasse eingeben.';
      return false;
    }

    if (!this.selection['tnAdresse'].LKZ) {
      this.errorCountry = 'Bitte ein Land auswählen.';
      return false;
    }

    if (!this.selection['tnAdresse'].PLZ || this.selection['tnAdresse'].PLZ.length !== 5) {
      this.errorZIP = 'Bitte eine PLZ eingeben.';
      return false;
    }

    if (!this.selection['tnAdresse'].Ort) {
      this.errorCity = 'Bitte einen Ort eingeben.';
      return false;
    }

    return true;
  }

}
