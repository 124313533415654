import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * This class provides the data and functions
 * for the charity currently description page.
 */
@Component({
  selector: 'mp-leas-charity-description',
  templateUrl: './charity-description.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CharityDescriptionComponent implements OnInit {

  public charitySliderProperties: OwlOptions = {
    loop: true,
    nav: true,
    margin: 16,
    items: 1,
    dots: true
  };

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpBreadcrumbService: MpBreadcrumbService
  ) { }

  /**
   * Sets the breadcrumb.
   */
  ngOnInit(): void {
    this._mpBreadcrumbService.setCurrentSiteWithLoc('CharityBeschreibung', 'locLeas', 'LeasePlanGoZeigtHerz');
  }

}
