import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { RentalConfirmationComponent } from '@rk/modules/participant/pages/rental-confirmation/rental-confirmation.component';

/**
 * This class provides the data and functions
 * for the rental confirmsation page.
 */
@Component({
  selector: 'mp-rkzz-rental-confirmation-additional-payment',
  templateUrl: './rental-confirmation-additional-payment.component.html',
  styleUrls: [
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/elements/rk-select.scss',
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/sites/rk-main-page-styles.scss',
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/sites/rk-two-column-pages.scss',
    './../../../../../../mp.Reisekonfigurator/app/components/search-area/styles/tc-search.scss',
    './../../../../../../mp.Reisekonfigurator/app/components/sidebar/styles/tc-sidebars.scss',
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/sites/rk-browser-hacks.scss',
    './../../../../../../mp.Zuzahlung/app/zz.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RentalConfirmationAdditionalPaymentComponent extends RentalConfirmationComponent implements OnInit, OnDestroy {

  private _getParamsSubscription: Subscription | undefined;
  private _getDataForAdditionalPaymentSubscription: Subscription | undefined;

  /**
   * Gets the data for the additional payment.
   */
  ngOnInit(): void {
    super.ngOnInit();

    this._getParamsSubscription = this.route.queryParams.subscribe((params: any) => {
      if (typeof params['Zuzahlung'] !== 'undefined') {
        this.bookingAvailable = false;

        this._getDataForAdditionalPaymentSubscription = this.apiService.getRequest(`/api/RkZuzahlung/GetBuchungForZuzahlung?guid=${params['Zuzahlung']}`).subscribe((data: any) => {
          this.rental = data.Records[0].Mietwagen;
          this.orderNo = data.footer[0];
          this.bookingAvailable = true;
        });
      }
    });
  }

  /**
   * Unsibscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (typeof this._getParamsSubscription !== 'undefined') {
      this._getParamsSubscription.unsubscribe();
    }

    if (typeof this._getDataForAdditionalPaymentSubscription !== 'undefined') {
      this._getDataForAdditionalPaymentSubscription.unsubscribe();
    }
  }

}
