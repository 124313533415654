<div *ngIf="(ls.locsLoaded | async) && tnIdAvailable">
  <mp-core-data-table fieldsAction="/api/StatusKonto/getStatusKontoFields"
                      title="{{ ls.locs['loc'].Statuskonto }}"
                      listAction="/api/StatusKonto/getStatusKonto"
                      loaderName="ptcpntCockpitStatusAccountLoader"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="Datum DESC"
                      [recLoadedFunc]="recLoadedFunc"
                      [disableCache]="true"></mp-core-data-table>
  <div class="inline-block" *ngFor="let q of quarters">
    <div class="mp-button _prim" [ngClass]="'statusButton-' + q['StatusId']" [innerText]="q['QuartalsErgebnis']" (click)="setQuarter(q['QuartalsCheck'])"></div>
  </div>
  <div *ngIf="loaderStatusAccount !== null && loaderStatusAccount.getExcelParams" class="floating-container">
    <a href="{{statusAccountExcelService}}?{{loaderStatusAccount.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
