<div class="layout-container" *ngIf="(ls.locsLoaded | async) && dateParamsLoaded">
  <h1 class="mobile-only">{{ ls.locs['loc'].PunkteEuroProMonat }}: {{title}}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].PunkteEuroProMonat }}: {{title}}"
                      infotextShorten="PunkteEuroProMonatDetails"
                      fieldsAction="/api/Statistik/getPunkteEuroProMonatDetailsFields"
                      listAction="/api/Statistik/getPunkteEuroProMonatDetails"
                      loaderName="pointsEuroPerMonthDetailsLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="">
  </mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <button class="mp-button _prim" (click)="goBack()">{{ ls.locs['loc'].Zurueck }}</button>
  <div class="ui-helper-clearfix"></div>
</div>
