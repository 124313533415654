<div class="display-flex flex-align-items-start"
     [ngClass]="{'pointer-event-none disabled': ngDisabled || mpDisabled, 'invalid-error': hasError, 'tristate-checkbox-container': tristate, 'checkbox-container': !tristate, 'indetermined': tristate && this.model === null}">
  <input type="checkbox"
         id="{{mpId}}"
         name="{{mpId}}"
         (click)="onClick()"
         [disabled]="mpDisabled"
         [ngModel]="model"
         (ngModelChange)="model = $event; modelChanged()">
  <label class="checkbox position-relative vertical-align-middle pointer margin-right-8 display-inline-block"
         for="{{mpId}}"
         [ngClass]="{'margin-right-0': !label}">
  </label>
  <label class="text-label noselect vertical-align-middle pointer"
         for="{{mpId}}"
         [ngClass]="{'margin-right-0': !label}"
         [innerHTML]="label">
  </label>
</div>
