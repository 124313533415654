import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the participant advertise 
 * participant statistic table.
 */
@Component({
  selector: 'mp-leas-prtcpnt-advertise-prtcpnt-statistic',
  templateUrl: './prtcpnt-advertise-prtcpnt-statistic.component.html'
})
export class PrtcpntAdvertisePrtcpntStatisticComponent implements OnInit, OnDestroy {

  public adWays: Array<any> = [];
  public filter: string = '';
  public excelService: string = '/api/TnWerbenTn/TnWerbenTnStatistik2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _leasPrtcpntAdvertisePrtcpntStatisticLoader: MpLoader = {
    name: 'leasPrtcpntAdvertisePrtcpntStatisticLoader',
    refillExtParamsFunc: (params: any) => {
      if (params['Entscheidung'] && this.adWays.length > 0) {
        this.filter = this.adWays.find((adWay: { [key: string]: any }) => {
          return adWay['Value'] === params['Entscheidung'];
        });
      }
    },
    params: {}
  };
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader and gets the
   * locs.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._leasPrtcpntAdvertisePrtcpntStatisticLoader);

    this._mpLoaderService.extendLoader('leasPrtcpntAdvertisePrtcpntStatisticLoader', {
      isReady: true
    });

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._setAdWays();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._setAdWays();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the advertising ways list.
   */
  private _setAdWays(): void {
    this.adWays = [
      { text: this.ls.locs['loc'].WaehleEinenFilter, value: '' },
      { text: this.ls.locs['locLeas'].VerkaeuferAusMeinemAutohaus, value: 'a' },
      { text: this.ls.locs['locLeas'].VerkaeuferAusEinemAnderenAutohaus, value: 'b' },
      { text: this.ls.locs['locLeas'].AussendienstmitarbeiterVonLeasePlanGo, value: 'c' },
      { text: this.ls.locs['locLeas'].QuartalsrundschreibenVonLeasePlanGo, value: 'd' },
      { text: this.ls.locs['locLeas'].EMailNewsletterVonLeasePlanGo, value: 'e' }
    ];
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasPrtcpntAdvertisePrtcpntStatisticLoader');
  }

  /**
   * Loads the data for the set filter.
   */
  filterChange(): void {
    if (this.loader !== null) {
      this.loader.params['Entscheidung'] = this.filter;

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

}
