import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the functions for
 * the request of an account change.
 */
@Component({
  selector: 'mp-core-request-account-change',
  templateUrl: './request-account-change.component.html'
})
export class RequestAccountChangeComponent implements OnInit, OnDestroy {

  public request: { [key: string]: any } = {
    BenutzerAlt: ''
  };
  public errors: { [key: string]: any } = {};

  private _role: string = '';
  private _getLocsSubscription: Subscription | undefined;
  private _sendRequestAccountChangeSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _router: Router
  ) { }

  /**
   * Gets the locs and sets the breadcrumbs.
   * Gets the role.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._mpBreadcrumbService.setCurrentSiteWithLoc('Kontowechsel', 'loc', 'AntragKontowechsel');
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }

        this._mpBreadcrumbService.setCurrentSiteWithLoc('Kontowechsel', 'loc', 'AntragKontowechsel');
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._sendRequestAccountChangeSubscription !== 'undefined') {
      this._sendRequestAccountChangeSubscription.unsubscribe();
    }
  }

  /**
   * Validates the request.
   */
  private _validate(): void {
    this.errors = {};

    if (!this.request['BenutzerAlt'] || this.request['BenutzerAlt'].length === 0) {
      this.errors['data.BenutzerAlt'] = [this.ls.locs['loc'].VorherigerBenutzerRequired];
    }
  }

  /**
   * Sends the request.
   */
  sendRequest(evt: MouseEvent): void {
    evt.preventDefault();
    this._validate();

    if (Object.keys(this.errors).length === 0) {
      this._sendRequestAccountChangeSubscription = this._apiService.postRequest('/api/Kontowechsel/KontowechselBeantragen', this.request).subscribe((data: any) => {
        if (data.Result == 'OK') {
          this._mpMessaging.openSuccessPanel(data.Message);
          this._router.navigateByUrl(`/${this._role}/Home`);
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });
    }
  }

}
