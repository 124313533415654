<div mpCoreMpContentFullHeight *ngIf="(ls.locsLoaded | async)"
     class="layout-container manager-text-editor communication-process position-relative">
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb"
         class="display-flex flex-col-12 flex-align-items-center margin-bottom-8 padding-left-4">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info"
           href="#/{{role}}/Home">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Kommunikationsprozess }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular position-relative top-2" [innerHTML]="ls.locs['loc'].KommunikationAnlegenBreadcrumb"></a>
      </div>
    </div>
    <mp-core-communication-process-stepper *ngIf="dataLoaded"></mp-core-communication-process-stepper>

    <section id="mngr-text-editor-content"
             class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-16">

      <div class="width-full bg-color-white padding-left-right-16 padding-top-16 padding-bottom-40 box-sizing-border-box">
        <h3 class="width-full margin-top-8 margin-bottom-16">{{ ls.locs['loc'].TeilnehmerAuswaehlen }}</h3>

        <mp-core-sidebar-input class="width-full-important margin-top-16 display-block"
                               [hidden]="!participantChoiceData['Gruppen'] || participantChoiceData['Gruppen'] && participantChoiceData['Gruppen'].length === 0 "
                               label="{{ ls.locs['loc'].Gruppe }}"
                               text="{{groupsParams['text']}}"
                               sidebarComponent="SidebarMultiselect"
                               [sidebarParams]="groupsParams"
                               [mpRequired]="true">
        </mp-core-sidebar-input>

        <mp-core-sidebar-input class="width-full-important margin-top-16 display-block"
                               label="{{ ls.locs['loc'].Teilnehmer }}"
                               text="{{participantParams['text']}}"
                               sidebarComponent="SidebarMultiselect"
                               [sidebarParams]="participantParams"
                               [mpRequired]="true">
        </mp-core-sidebar-input>

        <div class="clear-both"></div>

      </div>
      <div class="flex-grid width-full-important hide-on-mobile position-absolute bottom-48 left-0">
        <div class="flex-col-9 flex-col-12-l flex-col-12-m padding-left-right-16 display-flex flex-direction-row flex-justify-cont-between">
          <a class="mp-button"
             (click)="cpShowClearConfirm($event)">
            {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
          </a>
          <div>
            <a class="mp-button _prim"
               (click)="goToNextStep($event)"
               *ngIf="cp.currentStep !== null"
               [ngClass]="{disabled: !validateStep()}">
              {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
            </a>
          </div>
        </div>
      </div>
    </section>

    <section id="mngr-text-editor-datapanel"
             class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-16">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20">
        <div class="width-full">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}"
                           alignment="left"
                           class="float-right-important">
            <button class="mp-button"
                    (click)="cp.showResetConfirm(loadStepFunc)">
              <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
                 id="elig-requ-refresh-icon">
              </i>
            </button>
          </mp-core-tooltip>
        </div>
        <a class="mp-button _sec margin-top-16 width-full"
           (click)="saveCommunicationProcess($event)">
          {{ ls.locs['loc'].Speichern }}
        </a>
        <div class="margin-top-16">
          <a class="mp-button _sec width-full" [ngClass]="{disabled: communicationGroup === '' || !cp.communicationId || cp.communicationId == 0}" href="{{excelServiceCommunicationRecipient}}?communicationId={{cp.communicationId}}">{{ ls.locs['loc'].KommunikationsEmpfaengerliste }}</a>
          <span class="font-size-xs">{{ ls.locs['loc'].KommunikationsEmpfaengerlisteTip }}</span>
        </div>
        <a class="mp-button _prim margin-top-16 width-full"
           (click)="goToNextStep($event)"
           *ngIf="cp.currentStep !== null"
           [ngClass]="{disabled: !validateStep()}">
          {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
        </a>
      </div>
    </section>

    <div stickyBottom
         class="bestellung-nav-row floating-container show-on-mobile bg-color-white padding-top-bottom-0 width-full text-align-center wk-bottom-buttons">
      <a class="mp-button _prim padding-top-bottom-8 width-full display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
         (click)="goToNextStep($event)"
         *ngIf="cp.currentStep !== null"
         [ngClass]="{disabled: !validateStep()}">
        <i class="svg-icon __size-24 __top--2 __navigate-next float-none-important margin-bottom-4 __clr-navbar"></i>
        <span class="display-block">{{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}</span>
      </a>
      <div class="width-full padding-left-right-8 box-sizing-border-box display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           (click)="cpShowClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
      </div>
    </div>
  </div>


</div>
