import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { ParticipantCockpitService } from './../participant-cockpit.service';
import { MpPermissionsService } from './../../../../../services/mp-permissions.service';
import { ApiService } from './../../../../../services/api.service';
import { MpMessagingService } from './../../../../../services/mp-messaging.service';

/**
 * This class provides the functions and
 * the data for the addresses within the
 * participant cockpit.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-addresses',
  templateUrl: './ptcpnt-cockpit-addresses.component.html',
  styleUrls: ['./../../../../../components/addresses/address-card/address-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitAddressesComponent implements OnInit, OnDestroy {

  public isAdmin: boolean = false;
  public canCurrentUserEdit: boolean = false;
  public profileCanUserEditDisabled: boolean = true;
  public ptcpntAddresses: Array<any> = [];
  public ptcpntAddressesErrors: { [key: string]: any } = {};
  public ddlData: { [key: string]: any } = {};

  private _cockpitData: { [key: string]: any } = {};
  private _getPermissionsSubscription: Subscription | undefined;
  private _getParticipantCockpitDataSubscription: Subscription | undefined;
  private _getAddressesSubscription: Subscription | undefined;
  private _setStdAddressSubscription: Subscription | undefined;
  private _saveAddressSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _participantCockpitService: ParticipantCockpitService,
    private _mpPermissions: MpPermissionsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the participant data and the
   * permissions.
   */
  ngOnInit(): void {
    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.isAdmin = this._mpPermissions.permissions.IsAdmin;
        this.canCurrentUserEdit = this._mpPermissions.permissions.CanCurrentUserEdit;
        this.profileCanUserEditDisabled = !this._mpPermissions.permissions.CanCurrentUserEdit;
      }
    });

    this._mpPermissions.getPermissions();

    this._getParticipantCockpitDataSubscription = this._participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
      if (typeof this._cockpitData['tnId'] === 'undefined' || this._cockpitData['tnId'] !== data['tnId'] || typeof this._cockpitData['reloadCounter'] === 'undefined' || this._cockpitData['reloadCounter'] !== data['reloadCounter']) {
        this._cockpitData = data;
        this._loadAddresses();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getParticipantCockpitDataSubscription !== 'undefined') {
      this._getParticipantCockpitDataSubscription.unsubscribe();
    }

    if (typeof this._getAddressesSubscription !== 'undefined') {
      this._getAddressesSubscription.unsubscribe();
    }

    if (typeof this._setStdAddressSubscription !== 'undefined') {
      this._setStdAddressSubscription.unsubscribe();
    }

    if (typeof this._saveAddressSubscription !== 'undefined') {
      this._saveAddressSubscription.unsubscribe();
    }
  }

  /**
   * Loads the participant addresses.
   */
  private _loadAddresses(): void {
    if (this._cockpitData['tnId'] && parseInt(this._cockpitData['tnId']) > 0) {
      this._getAddressesSubscription = this._apiService.getRequest(`/api/Teilnehmer/getTNAdressen/${this._cockpitData['tnId']}`).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.ptcpntAddresses = data.Records;
          this.ptcpntAddressesErrors = data.Records.map((r: any) => { return { }; });
          this.ddlData = data.footer[0];
        }
      });
    }
  }

  /**
   * Sets the standard address.
   */
  setPtcpntStdAddress(adr: { [key: string]: any }): void {
    const data = {
      Id: adr['AdressID'],
      Typ: adr['AdressTyp'],
      TnId: adr['TnID']
    };

    this._setStdAddressSubscription = this._apiService.postRequest('/api/Teilnehmer/SetTnStdAdresse', data).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);

      if (data.Result === 'OK') {
        this._loadAddresses();
      }
    });
  }

  /**
   * Saves the address.
   */
  saveAddress(adr: { [key: string]: any }): void {
    if (typeof adr !== 'undefined') {
      adr['editierbar'] = true;

      this._saveAddressSubscription = this._apiService.postRequest('/api/Teilnehmer/saveTNAdresse', adr).subscribe((data: any) => {
        if (data.Result === 'OK')
          this._mpMessaging.openSuccessPanel(data.Message);
        else
          this._mpMessaging.openWarningPanel(data.Message);
      },
      (error: any) => {
        if (error.status === 400) {
          this.ptcpntAddressesErrors = error.ModelState;
        } else {
          this.ptcpntAddressesErrors = {};

          if (error.Message) {
            this._mpMessaging.openWarningPanel(error.Message);
          }
        }
      });
    }
  }

  /**
   * Returns the salutations for
   * the given country.
   */
  getSalutationForCountry(values: Array<any>, lkz: string): Array<any> {
    return values.filter((value: any) => {
      return value['Land'] === lkz || !value['Land'];
    });;
  }

}
