import { Component, OnInit, Input, OnDestroy, Injector, ViewChild, ElementRef, OnChanges, SimpleChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';
import { MpDebounceService } from './../../services/mp-debounce.service';
import { ApiService } from './../../services/api.service';
import { MpLoaderService } from './../../services/mp-loader.service';
import { DataTableChildsService } from './data-table-childs.service';
import { MpMessagingService } from './../../services/mp-messaging.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';

import { DataTableHeader } from './data-table-header';
import { MpLoader } from './../../services/interfaces/mp-loader';
import { ParentId, ParentTableRow, ParentTableRowIndex } from './table-injectors';

/**
 * This class provides the data and
 * functions for the data table.
 */
@Component({
  selector: 'mp-core-data-table',
  templateUrl: './data-table.component.html'
})
export class DataTableComponent implements OnInit, OnDestroy {

  @Input() public alwaysShowSearch: boolean | null = null;
  @Input() public alwaysShowFilter: boolean | null = null;
  @Input() public globallyAlwaysShowSearch: boolean | null = null;
  @Input() public globallyAlwaysShowFilter: boolean | null = null;
  @Input() public loaderName: string | undefined;
  @Input() public listAction: string = '';
  @Input() public fieldsAction: string = '';
  @Input() public recLoadedFunc: Function | undefined;
  @Input() public sorting: string = '';
  @Input() public hideSearch: boolean = false;
  @Input() public hideFilter: boolean = false;
  @Input() public defaultSorting: string = '';
  @Input() public searchTexts: { [key: string]: any } = {};
  @Input() public searchText: string = '';
  @Input() public onlyReloadOnClick: boolean = false;
  @Input() public showDatePicker: boolean = false;
  @Input() public disableCache: boolean = false;
  @Input() public globallyDisableCache: boolean = false;
  @Input() public footer: boolean = false;
  @Input() public minimizedStyle: boolean = false;
  @Input() public headerSelectTransclude: boolean = false;
  @Input() public hideRowNumbers: boolean = false;
  @Input() public infotextShorten: string = '';
  @Input() public title: string = '';
  @Input() public disableResponsiveToggling: boolean = false;
  @Input() public hideColumnSelection: boolean = false;
  @Input() public startEmpty: boolean = false;
  @Input() public paging: boolean = false;
  @Input() public execClickFunctions: Array<any> = [];
  @Input() public execChangeFunctions: Array<any> = [];

  @ViewChild('dataTableRef') dataTableRef: ElementRef<HTMLDivElement> | undefined;

  public guid: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });

  public header: DataTableHeader = {
    columns: []
  }

  public loader: MpLoader = {
    name: '',
    params: {
      Suchtexte: [],
      Suchtext: '',
      StartDate: null,
      EndDate: new Date()
    },
    picker: {
      Von: null,
      Bis: new Date()
    }
  };

  public load: any;
  public loading: boolean = false;
  public page: number = 1;
  public pageSize: number = 10;
  public totalPages: number = 1;
  public pages: Array<number> = [1];
  public showSearch: boolean = false;
  public rows: Array<any> = [];
  public footerRows: Array<any> = [];
  public rowCount: Array<number> = [];
  public availablePageSizes: Array<number> = [10, 20, 50, 100];
  public parentId: number = 1;
  public headerChildTemplate: any = null;
  public isResponsiveWidth: boolean = false;
  public showSizeWarning: boolean = false;
  public hideSizeWarning: boolean = false;
  public showFilter: boolean = false;
  public loadViaLoaderFunc = this.loadViaLoader.bind(this);
  public checkWidth = this._checkWidth.bind(this);

  private _role: string = '';
  private _codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
  private sortString: string = '';
  private _trc: number = 0;
  private _oldHiddenColumns: Array<any> = [];
  private _settingsSubscription: Subscription | undefined;
  private _dataSubcription: Subscription | undefined;
  private _childServiceSubscription: Subscription | undefined;
  private _saveColumnSettingsSusbcription: Subscription | undefined;
  private _initHeaderSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpDebounce: MpDebounceService,
    public sanitizer: DomSanitizer,
    private _apiService: ApiService,
    private _injector: Injector,
    private _mpLoaderService: MpLoaderService,
    private _dataTableChilds: DataTableChildsService,
    private _mpMessaging: MpMessagingService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets data from injectors, sets some
   * settings, extends the loader object,
   * and creates the load function.
   * Initializes the header.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    if (typeof this.dataTableRef !== 'undefined') {
      if (typeof this.dataTableRef.nativeElement.getAttribute('globally-disable-cache') !== 'undefined') {
        this.globallyDisableCache = this.dataTableRef.nativeElement.getAttribute('globally-disable-cache') === 'true' ? true : false;
      }
    }

    this.disableCache = this.globallyDisableCache ? this.globallyDisableCache : this.disableCache;

    if (this.defaultSorting !== '') {
      this.sortString = this.defaultSorting;
    }

    if (typeof ParentId === 'number') {
      this.parentId = ParentId;
    }

    if (Object.keys(this.mpSettings.settings).length > 0) {
      this.globallyAlwaysShowSearch = this.globallyAlwaysShowSearch !== null ? this.globallyAlwaysShowSearch : this.mpSettings.settings['DisplaySettings'].GloballyAlwaysShowSuche;
      this.globallyAlwaysShowFilter = this.globallyAlwaysShowFilter !== null ? this.globallyAlwaysShowFilter : this.mpSettings.settings['DisplaySettings'].GloballyAlwaysShowFilter;
    } else {
      this.mpSettings.getSettings();

      this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.globallyAlwaysShowSearch = this.globallyAlwaysShowSearch !== null ? this.globallyAlwaysShowSearch : this.mpSettings.settings['DisplaySettings'].GloballyAlwaysShowSuche;
          this.globallyAlwaysShowFilter = this.globallyAlwaysShowFilter !== null ? this.globallyAlwaysShowFilter : this.mpSettings.settings['DisplaySettings'].GloballyAlwaysShowFilter;
        }
      });
    }

    if (typeof this.loaderName !== 'undefined' && this._mpLoaderService.loaderExists(this.loaderName)) {
      this.loader = this._mpLoaderService.getLoader(this.loaderName);

      this._mpLoaderService.extendLoader(this.loader.name,
        {
          params: Object.assign((this.loader.params || {}),
            {
              Suchtexte: this.searchTexts,
              Suchtext: this.searchText
            }),
          getExcelParams: () => {
            const paramsOfStorage = sessionStorage.getItem('params_' + this.listAction.replace(/\//g, '_'));
            const requesstObject = paramsOfStorage !== null ? JSON.parse(paramsOfStorage) : {};
            let params = new HttpParams();
            return params.append('data', JSON.stringify(requesstObject)).toString();
          },
          setHeaderVisisbility: (columnFields: Array<string>) => {
            if (columnFields.length === 0) {
              return;
            }

            if (typeof this.header.filledColumns !== 'undefined') {
              this.header.selectedColumns = this.header.filledColumns.filter((col: any) => {
                return columnFields.indexOf(col.field) !== -1 ? true : false;
              });
            }

            this.updateColums();
          },
          isFirst: typeof this.loader.isFirst === 'undefined' ? true : this.loader.isFirst,
          isReady: typeof this.loader.isReady === 'undefined' ? true : this.loader.isReady
        });
    }

    if (this.showDatePicker &&
    (typeof this.loader.picker === 'undefined' ||
      this.loader.picker === null ||
      typeof this.loader.picker.Von === 'undefined' ||
      this.loader.picker.Von === null ||
      typeof this.loader.picker.Bis === 'undefined' ||
      this.loader.picker.Bis === null)) {
      this._mpLoaderService.extendLoader(this.loader.name,
        {
          picker: {
            Von: null,
            Bis: new Date()
          }
        });
    }

    this.load = this._mpDebounce.debounce(() => {
      if (!this._isDatePickerOk() || this.loading || !this.loader.isReady)
        return;

      this.loading = true;
      this._updatePageParameters();

      let startIndex = (this.page - 1) * this.pageSize;

      const url = this.listAction +
        '?jtStartIndex=' +
        startIndex +
        '&jtPageSize=' +
        this.pageSize +
        (this.sortString ? '&jtSorting=' + this._codec.encodeValue(this.sortString) : '');

      const storageParams = sessionStorage.getItem('params_' + this.listAction.replace(/\//g, '_'));
      const prevPostParams = storageParams !== null ? JSON.parse(storageParams) : '';
      let postParams;

      if (!this.loader.loadPreviousDataTable) {
        if (this.anySearchable() && Object.keys(this.searchTexts).length > 0) {
          this.loader.params.Suchtexte = this._getFilledSearchtexts();
        }

        if (this.anySearchable()) {
          this.loader.params.Suchtext = this.searchText;
        }

        if (typeof this.loader.params.SuchtextOutside !== 'undefined' && this.loader.params.SuchtextOutside !== '') {
          this.loader.params.Suchtext = this.loader.params.SuchtextOutside;
        }

        if (this.showDatePicker && typeof this.loader.picker !== 'undefined') {
          this.loader.params.StartDate = this.loader.picker.Von;
          this.loader.params.EndDate = this.loader.picker.Bis;
        }

        postParams = Object.assign(this.loader.params, {
          StartIndex: startIndex,
          PageSize: this.pageSize,
          SortString: this.sortString,
          Page: this.page
        });
      } else {
        this.loader.loadPreviousDataTable = false;
        postParams = prevPostParams;
        this._reloadPostParams(postParams);
        startIndex = postParams.StartIndex;
      }

      const storageData = sessionStorage.getItem('data_' + this.listAction.replace(/\//g, '_'));
      const data = storageData !== null ? JSON.parse(storageData) : '';

      const isSameParamsAsBeforeOrFirstRequest = data !== '' &&
        data.RecordCount > 0 &&
        ((this.loader.isFirst && prevPostParams !== '' && prevPostParams === postParams) || prevPostParams === postParams) === true;

      this.loader.isFirst = false;

      sessionStorage.setItem('params_' + this.listAction.replace(/\//g, '_'),
        JSON.stringify(isSameParamsAsBeforeOrFirstRequest ? prevPostParams : postParams));

      if (isSameParamsAsBeforeOrFirstRequest && !this.disableCache) {
        this._reloadPostParams(prevPostParams);

        this._fillScope(data);
        this.loading = false;
      } else {
        this._dataSubcription = this._apiService.postRequest(url, postParams).subscribe((data: any) => {
          this._fillScope(data);
          this.loading = false;
        },
        (error: any) => {
          if (typeof this.recLoadedFunc !== 'undefined') {
            this.recLoadedFunc([], [], this.header, error);
          }

          this.loading = false;
        });
      }
    }, 200);

    this.loader.load = this.load;

    this._initHeaderSubscription = this._apiService.postRequest(this.fieldsAction, {}).subscribe((data: any) => {
      if (data.Result == 'OK' && data.Records.length > 0) {
        this.header = data.Records[0];

        this.header.selectedColumns = this.header.columns.filter((c: any) => { return c.visibility === '' });
        this.header.filledColumns = this.header.columns.filter((c: any) => { return c.title; });
        this._checkWidth();

        this.header.columns.forEach((c: any) => {
          const trustTitle = this._trust(c.title);
          c.trustTitle = trustTitle;
        });

        if (typeof this.loader.load !== 'undefined') {
          this.loader.load();
        }
      }
    });

    window.addEventListener('resize', this._autoCheckWidth.bind(this));

    setTimeout(() => {
      this._autoCheckWidth();
    }, 1000);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._settingsSubscription !== 'undefined') {
      this._settingsSubscription.unsubscribe();
    }

    if (typeof this._dataSubcription !== 'undefined') {
      this._dataSubcription.unsubscribe();
    }

    if (typeof this._childServiceSubscription !== 'undefined') {
      this._childServiceSubscription.unsubscribe();
    }

    if (typeof this._saveColumnSettingsSusbcription !== 'undefined') {
      this._saveColumnSettingsSusbcription.unsubscribe();
    }

    if (typeof this._initHeaderSubscription !== 'undefined') {
      this._initHeaderSubscription.unsubscribe();
    }

    this.loader.isFirst = true;
    this._mpLoaderService.unregisterLoader(this.loader.name);
    window.removeEventListener('resize', this._autoCheckWidth.bind(this));
  }

  /*
   * Handles when date changes.
   */
  handleDateChange(): void {
    this._reloadOnChange(this.loader.picker, null, true);
  }

  /**
   * Sets event listeners for title changes
   * on mouseleave / moueseenter on the
   * datatable.
   */
  ngAfterViewInit(): void {
    const dataTable = document.querySelector('.dataTable');

    if (dataTable !== null) {
      dataTable.addEventListener('mouseenter', (evt: any) => {
        const evtTarget = evt.currentTarget;
        const title = evtTarget.getAttribute('title');

        if (typeof title !== 'undefined') {
          evtTarget.setAttribute('tmp_title', title);
          evtTarget.setAttribute('title', '');
        }
      });

      dataTable.addEventListener('mouseleave', (evt: any) => {
        const evtTarget = evt.currentTarget;
        const title = evtTarget.getAttribute('tmp_title');

        if (typeof title !== 'undefined') {
          evtTarget.setAttribute('title', title);
          evtTarget.removeAttribute('tmp_title');
        }
      });
    }
  }

  /**
   * Checks whether HTML Sanitizer must
   * be used for content.
   */
  private _trust(data: any): any {
    if (typeof data == 'string' || data instanceof String) {
      // @ts-ignore
      return this._trustAsHtml(data);
    }

    return data;
  }

  /**
   * Sanitizer for HTML.
   */
  private _trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Gets the filled search texts.
   */
  private _getFilledSearchtexts(): any {
    let filled, key, keys, i;
    keys = Object.keys(this.searchTexts);
    filled = {};

    for (i = 0; i < keys.length; i++) {
      key = keys[i];

      if (this.searchTexts[key]) {
        // @ts-ignore
        filled[key] = this.searchTexts[key];
      }
    }
    return filled;
  }

  /**
   * Checks whether or not any column
   * is searchable.
   */
  anySearchable(): boolean {
    return typeof this.header.columns.find((col: any) => { return col.searchable === true; }) !== 'undefined' ? true : false;
  }

  /**
   * Applies the set date filter.
   */
  applyDateFilter(): void {
    this._reloadOnChange(this.loader.params, null, true);
  }

  /**
   * Reloads the date on change.
   */
  private _reloadOnChange(newVal: any, oldVal: any, forceLoad?: boolean): void {
    if (this.loader.loadPreviousDataTable || (this.onlyReloadOnClick && !forceLoad))
      return;

    if (newVal &&
      (!oldVal || JSON.stringify(this._omit(oldVal, ['Suchtexte'])) != JSON.stringify(this._omit(newVal, ['Suchtexte'])))) {
      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Fallback for underscore.js omit func.
   */
  private _omit = (obj: any, props: any) => {
    obj = { ...obj };
    props.forEach((prop: any) => delete obj[prop]);
    return obj;
  }

  /**
   * Checks whether or not date picket
   * should be shown.
   */
  private _isDatePickerOk(): boolean {
    const datePickerFilled = (typeof this.loader.picker !== 'undefined' && this.loader.picker !== null)
                          && (typeof this.loader.picker.Von !== 'undefined' && this.loader.picker.Von !== null)
                          && (typeof this.loader.picker.Bis !== 'undefined' && this.loader.picker.Bis !== null);
    return this.showDatePicker ? datePickerFilled : true;
  }

  /**
   * Polyfill for underscore.js range.
   */
  private _range(start: number, end?: number, increment?: number): Array<number> {
    const isEndDef = typeof end !== 'undefined';
    end = isEndDef ? end : start;
    start = isEndDef ? start : 0;
    let length = 0;

    if (typeof increment === 'undefined' && typeof end !== 'undefined') {
      increment = Math.sign(end - start);
    }

    if (typeof end !== 'undefined') {
      length = Math.abs((end - start) / (increment || 1));
    }

    const { result } = Array.from({ length }).reduce(
      ({ result, current }) => ({
        result: [...result, current],
        current: current + increment,
      }),
      { current: start, result: [] }
    );

    return result;
  }


  /**
   * Gets the pages.
   */
  private _getPages(page: number, pageCount: number): Array<number> {
    let pages = this._range(page - 10, page + 10);
    const union = (arr: Array<any>, ...args: Array<any>) => [...new Set(arr.concat(...args))];

    if (pageCount > 10) {
      pages = union(pages, this._range(page - 100, page + 100).filter((p: number) => { return p % 10 === 0; }));
    }

    if (pageCount > 100) {
      pages = union(pages, this._range(page - 1000, page + 1000).filter((p: number) => { return p % 100 === 0; }));
    }

    if (pageCount > 1000) {
      pages = union(pages, this._range(0, pageCount / 1000).map((a: number) => { return a * 1000; }));
    }

    return pages.filter((p: number) => { return p > 0 && p <= pageCount; }).concat().sort((a: number, b: number) => { return a - b; });
  }

  /**
   * Updates the page parameters.
   */
  private _updatePageParameters(): void {
    if (!this._trc || this._trc === 0) {
      return;
    }

    this.totalPages = Math.ceil((this._trc) / this.pageSize);

    if (this.page == undefined || this.page > this.totalPages) {
      this.page = this.totalPages;
    }

    if (this.page < 1) {
      this.page = 1;
    }

    this.pages = this._getPages(this.page, this.totalPages);
    this._setRowCount();
  }

  /**
   * Reloads the given post parameters.
   */
  private _reloadPostParams(postParams: any): void {
    if (!postParams) return;

    if (this.loader && typeof this.loader.refillExtParamsFunc !== 'undefined') {
      this.loader.refillExtParamsFunc(postParams);
    }

    if (this.anySearchable() && postParams.Suchtexte) {
      this.searchTexts = postParams.Suchtexte;

      if (JSON.stringify(postParams.Suchtexte) !== "{}") {
        this.showSearch = true;
      }
    }

    if (this.anySearchable()) {
      this.searchText = postParams.Suchtext;

      if (this.searchText) {
        this.showSearch = true;
      }
    }

    if (postParams.SortString) {
      this.sortString = postParams.SortString;
    }

    if (postParams.Page) {
      this.page = postParams.Page;
    }

    if (postParams.PageSize) {
      this.pageSize = postParams.PageSize;
    }
  }

  /**
   * Updates the data for the template
   * and the session storage.
   */
  private _fillScope(data: any): void {
    if (data.Result === 'OK') {
      sessionStorage.setItem('data_' + this.listAction.replace(/\//g, "_"), JSON.stringify(data));

      this.rows = data.Records;

      if (this.rows && this.rows[0]) {
        this.rows[0].expandedResponsive = true;
      }

      this.footerRows = data.footer;
      this._trc = data.TotalRecordCount || data.RecordCount;

      this._updatePageParameters();

      if (typeof this.recLoadedFunc !== 'undefined') {
        this.recLoadedFunc(this.rows, this.footerRows, this.header);
      }

      for (let i = 0; i < this.rows.length; i++) {
        this.header.columns.forEach((c: any) => {
          const trustField: any = this.sanitizer.bypassSecurityTrustHtml(c.options ? c.options[this.rows[i][c.field]] : this.rows[i][c.field] !== null ? this.rows[i][c.field] : '');
          const trustHeader = this._trust(this.rows[i].ResponsiveHeaderContent);
          this.rows[i][c.field + '-trustField'] = trustField !== null ? trustField : '';
          this.rows[i].trustResponsiveHeaderContent = trustHeader;
        });
      }

      if (this.footerRows.length > 0) {
        this.footerRows.forEach((footerRow: any) => {
          this.header.columns.forEach((c: any) => {
            const trustFooterField = this._trust(c.options ? c.options[footerRow[c.field]] : footerRow[c.field]);
            footerRow[c.field + '-trustFooterField'] = trustFooterField;
          });
        });
      } else {
        this.header.columns.forEach((c: any) => {
          const trustTitle = this._trust(c.title);
          c.trustTitle = trustTitle;
        });
      }
    } else {
     this._mpMessaging.openWarningPanel(data.Message);
    }
  }

  /**
   * Sets the row count.
   */
  private _setRowCount(): void {
    let rowCount = this.rows.length;

    if (this._trc < this.pageSize) {
      rowCount = this._trc;
    }

    this.rowCount = Array.from({ length: rowCount }, (_, i) => i);
  }

  /**
   * Goes to the first page of table.
   */
  goToFirstPage(): void {
    if (this.page > 1) {
      this.page = 1;

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Goes to the previous page of table-.
   */
  goToPreviousPage(): void {
    if (this.page > 1) {
      this.page--;

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Goes to the next page of table.
   */
  goToNextPage(): void {
    if (this.page < this.totalPages) {
      this.page++;

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Goes to the last page of table.
   */
  goToLastPage(): void {
    if (this.page < this.totalPages) {
      this.page = this.totalPages;

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Gets the correct page.
   */
  private _getCorrectPage(): void {
    if (this._trc && (this.page - 1) * this.pageSize > this._trc) {
      this.page = this._trc / this.pageSize + 1;
    }

    if (this.page < 1) {
      this.page = 1;
    }
  }

  /**
   * Toggles the child row (responsive).
   */
  toggleChildResponsive(r: any, evt: MouseEvent): void {
    evt.preventDefault();
    r.expandedResponsive = !r.expandedResponsive;
  }

  /**
   * Toggles the child row.
   */
  toggleChild(r: any, evt: MouseEvent, index: number): void {
    evt.preventDefault();

    if (!r.expanded) {
      if (typeof this.header.childService !== 'undefined' && this.header.childService !== '') {
        const params = this._getChildServiceParams(r);

        this._childServiceSubscription = this._apiService.postRequest(this.header.childService, params).subscribe((data: any) => {
          if (data.Result == 'OK') {
            r.child = data.Records[0];

            if (typeof this.header.childTemplate !== 'undefined' && typeof this._dataTableChilds.getTemplate(this.header.childTemplate) !== 'undefined') {
              this.headerChildTemplate = this._dataTableChilds.getTemplate(this.header.childTemplate);
            }
          }
        });
      } else if (typeof this.header.childTemplate !== 'undefined' && typeof this._dataTableChilds.getTemplate(this.header.childTemplate) !== 'undefined') {
        this.headerChildTemplate = this._dataTableChilds.getTemplate(this.header.childTemplate);
      }

      if (typeof r['injector'] === 'undefined') {
        r['injector'] = Injector.create({
          providers: [
            { provide: ParentId, useValue: this.parentId },
            { provide: ParentTableRow, useValue: r },
            { provide: ParentTableRowIndex, useValue: index }
          ],
          parent: this._injector
        });
      }
    }

    r.expanded = !r.expanded;
  }

  /**
   * Creates the injector for the child
   * table.
   */
  getInjectorForRow(r: any): Injector {
    return r['injector'];
  }

  /**
   * Gets the service params of the
   * child row.
   */
  private _getChildServiceParams(r: any): any {
    const params = {}
    let colName;

    if (typeof this.header.childServiceParams !== 'undefined') {
      for (let i = 0; i < this.header.childServiceParams.length; i++) {
        colName = this.header.childServiceParams[i];
        // @ts-ignore
        params[colName] = r[colName];
      }
    }

    return params;
  }

  /**
   * Executes a parent function from
   * the child.
   */
  execClickFunction(func: Function, row: any, column: any, evt: MouseEvent): void {
    evt.preventDefault();

    if (this.execClickFunctions.length > 0) {
      const execFunc = this.execClickFunctions.find((execClickFunc: any) => {
        return execClickFunc.backendName === func;
      });

      if (typeof execFunc !== 'undefined') {
        execFunc.func(row, column);
      }
    }
  }

  /**
   * Executes a parent change
   * function from the child.
   */
  execChangeFunction(func: Function, row: any, column: any): any {
    if (this.execChangeFunctions.length > 0) {
      const execFunc = this.execChangeFunctions.find((execChangeFunc: any) => {
        return execChangeFunc.backendName === func;
      });

      if (typeof execFunc !== 'undefined') {
        execFunc.func(row, column);
      }
    }
  }

  /**
   * Checks whether or not the link
   * function should be shown.
   */
  showLinkFunction(col: any, row: any): any {
    if (col && col.showFunctionLink && row) {
      return row[col.showFunctionLink];
    }

    return true;
  }

  /**
   * Gets the total width of the table.
   */
  private _getTotalColumnWidth(): number {
    if (typeof this.dataTableRef !== 'undefined') {
      const table = this.dataTableRef.nativeElement.querySelector('table');

      if (table !== null) {
        return table.getBoundingClientRect().width;
      }
    }

    return 0;
  }

  /**
   * Checks the width and sets the
   * booleans to show / hide size
   * warning and do some responsive
   * actions.
   */
  private _checkWidth(): void {
    this.isResponsiveWidth = window.innerWidth < 992;
    this.showSizeWarning = window.innerWidth < this._getTotalColumnWidth();
  }

  /**
   * Toggles the highlight style for
   * the given row.
   */
  toggleHighlight(r: any): void {
    r.highlight = !r.highlight;
  }

  /**
   * Sets the sorting of a table.
   */
  setSorting(field: string, sorting: boolean, defaultSortingDesc: boolean) {
    if (sorting) {
      if (this.sortString == field + " ASC" ||
        this.sortString == field ||
        (defaultSortingDesc && this.sortString != field + " DESC")) {
        this.sortString = field + " DESC";
      } else {
        this.sortString = field + " ASC";
      }

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Gets and returns the correct sorting
   * class for styling in frontend.
   */
  getSortingClass(field: string): string {
    if (this.sortString !== '') {
      const sortStrings = this.sortString.split(',');

      for (let i = 0; i < sortStrings.length; i++) {
        const sortString = sortStrings[i].trim();

        if (sortString == field + " ASC" || sortString == field) {
          return "arrow-up";
        } else if (sortString == field + " DESC") {
          return "arrow-down";
        } else {
          return "empty";
        }
      }
    }

    return "";
  }

  /**
   * Updates the columns.
   */
  updateColums(): void {
    this.header.columns.forEach((c: any) => {
      c.visibility = typeof this.header.selectedColumns !== 'undefined' && (this.header.selectedColumns.indexOf(c) > -1 || !c.title) ? '' : 'hidden';
    });

    // @ts-ignore
    var hiddenColumns = [this.header.columns, this.header.selectedColumns].reduce((a: any, b: any) => a.filter((c: any) => !b.includes(c))).map((col: any) => {
      return col.title;
    });

    if (hiddenColumns === this._oldHiddenColumns)
      return;

    var postContent = {
      TableName: this.header.tableName,
      HiddenColumns: hiddenColumns
    };

    this._saveColumnSettingsSusbcription = this._apiService.postRequest('/api/Grid/SaveColumnSettings', postContent).subscribe(() => { });
    this._oldHiddenColumns = JSON.parse(JSON.stringify(hiddenColumns));
    this._checkWidth();
  }

  /**
   * Gets the width of the given
   * column.
   */
  getWidth(c: any): string {
    if (typeof this.header.selectedColumns !== 'undefined') {
      if (typeof this.header.selectedColumns.find((selected: any) => { return selected.width === 'auto'; }) !== 'undefined' ||
        this.header.selectedColumns.indexOf(c) < this.header.selectedColumns.length - 1) {
        return c.width;
      }
    }

    return 'auto';
  }

  /**
   * Gets the text of the selected
   * columns.
   */
  getSelectedColumnsText(): Array<string> {
    if (typeof this.header.selectedColumns !== 'undefined') {
      const selectedColumnHeaders = this.header.selectedColumns.map((col: any) => {
        return col.title;
      });

      const result = selectedColumnHeaders.reduce((memo: any, item: any) => {
        return memo ? memo + ', ' + item : item;
      });

      return result;
    }

    return [];
  }

  /**
   * Gets the count of the visible
   * columns.
   */
  getVisibleColumnCount(): number {
    if (this.header.columns.length > 0) {
      return this.header.columns.filter((c: any) => {
        return c.visibility !== 'hidden';
      }).length;
    }

    return 0;
  }

  /**
   * Gets the class for min and
   * max values.
   */
  getMinMaxClass(cellValue: any, cell: any): string {
    if (typeof cell.minMaxStyling === 'undefined') {
      return '';
    }

    cellValue = cellValue.match(/\d+/gi) !== null ? cellValue.match(/\d+/gi).join('') : cellValue;
    const highlightBackground = typeof cell.minMaxStyling.highlightBackground !== 'undefined'
      ? cell.minMaxStyling.highlightBackground === true
        ? ' __highlight-background'
        : ''
      : '';

    if (typeof cell.minMaxStyling.max !== 'undefined' && typeof cell.minMaxStyling.min !== 'undefined') {
      if (cellValue <= cell.minMaxStyling.max && cellValue >= cell.minMaxStyling.min) {
        return '_between-min-max' + highlightBackground;
      } else if (cellValue > cell.minMaxStyling.max) {
        return '_greater-than-max' + highlightBackground;
      } else if (cellValue < cell.minMaxStyling.min) {
        return '_less-than-min' + highlightBackground;
      }
    } else if (typeof cell.minMaxStyling.max !== 'undefined' && typeof cell.minMaxStyling.min === 'undefined') {
      if (cellValue > cell.minMaxStyling.max) {
        return '_greater-than-max' + highlightBackground;
      }
    } else if (typeof cell.minMaxStyling.max === 'undefined' && typeof cell.minMaxStyling.min !== 'undefined') {
      if (cellValue < cell.minMaxStyling.min) {
        return '_less-than-min' + highlightBackground;
      }
    }

    return '';
  }

  /**
   * Automatically checks the width
   * to handle responsive functionalities.
   */
  private _autoCheckWidth(): void {
    this._checkWidth();
  }

  /**
   * Watches for changes of the
   * showDatePicker, the loader, and
   * the sorting.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['loader'] !== 'undefined' && typeof changes['loader'].previousValue['params'] !== 'undefined' && typeof changes['loader'].currentValue['params'] !== 'undefined') {
      if (changes['loader'].previousValue['params'] !== changes['loader'].currentValue['params']) {
        this._reloadOnChange(changes['loader'].currentValue['params'], changes['loader'].previousValue['params'], false);
      }
    }

    if (typeof changes['sortString'] !== 'undefined') {
      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Loads the data via the load
   * function of the loader.
   */
  loadViaLoader(): void {
    if (typeof this.loader.load !== 'undefined') {
      this.loader.load();
    }
  }

  /**
   * Closed the filter.
   */
  closeFilter() {
    if (this.hideFilter)
      this.hideFilter = false;
  };

  /**
   * Modifies the (AngularJS) url for the new url
   * structure of Angular.
   */
  modifyLinkForAngular(url: string): string {
    if (url.charAt(0) === '#') {
      if (url.charAt(1) === '/') {
        return url.replace('#', `/#/${this._role}`);
      } else {
        return url.replace('#', `/#/${this._role}/`);
      }
    } else if (url.charAt(0) === '/') {
      if (url.charAt(1) === '#') {
        if (url.charAt(2) === '/') {
          return url.replace('/#', `/#/${this._role}`);
        } else {
          return url.replace('/#', `/#/${this._role}/`);
        }
      }
    }

    return url;
  }

  isNullLInk(par: any): void {
    console.log(par);
  }
}
