import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';
import { CalendarService } from './../../calendar/calendar.service';
import { CustomEventService, CustomEvent, CustomEventType } from './../../../services/custom-event.service';

/**
 * This class provides a sidebar with a
 * calendar to select a date & a time
 * range (period).
 */
@Component({
  selector: 'mp-core-sidebar-period-time',
  templateUrl: './sidebar-period-time.component.html',
  styleUrls: ['./sidebar-period-time.component.scss']
})
export class SidebarPeriodTimeComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public minRange: number = 0;
  public timeHours: any[] = [];
  public timeMinutes: any[] = [];

  private _sidebarParamsSubscription: Subscription | undefined;
  private _baseDate: moment.Moment | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _calendarService: CalendarService,
    private _customEventServicee: CustomEventService
  ) { }

  /**
   * Watches for sidebar params changes of
   * and triggers updating the ranges for date
   * and time. Sets the selectable hours and
   * minutes.
   */
  ngOnInit(): void {
    this._baseDate = moment.utc([1900, 0, 1]);

    for (let loopCount = 0; loopCount <= 24; loopCount++) {
      this.timeHours.push({
        text: this._baseDate.clone().local().hour(loopCount).format('HH'),
        value: loopCount
      });
    }

    for (let loopCount = 0; loopCount <= 60; loopCount++) {
      this.timeMinutes.push({
        text: this._baseDate.clone().local().minute(loopCount).format('mm'),
        value: loopCount
      });
    }

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      if (Object.keys(this.mpSidebar.params).length > 0) {
        const rangeStart = this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'];
        const rangeEnd = this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd'];
        const fromHours = (typeof rangeStart === 'undefined' || rangeStart === null ? 0 : (this.selection['UhrzeitVonStunden'] || this.mpSidebar.params['UhrzeitVonStunden'] || typeof this.mpSidebar.params['rangeStart'] !== 'undefined' ? this.mpSidebar.params['rangeStart'].local().hours() : 0));
        const fromMinutes = (typeof rangeStart === 'undefined' || rangeStart === null ? 0 : (this.selection['UhrzeitVonMinuten'] || this.mpSidebar.params['UhrzeitVonMinuten'] || typeof this.mpSidebar.params['rangeStart'] !== 'undefined' ? this.mpSidebar.params['rangeStart'].local().minutes() : 0));
        const toHours = (typeof rangeEnd === 'undefined' || rangeEnd === null ? 23 : (this.selection['UhrzeitBisStunden'] || this.mpSidebar.params['UhrzeitBisStunden'] || typeof this.mpSidebar.params['rangeEnd'] !== 'undefined' ? this.mpSidebar.params['rangeEnd'].local().hours() : 23));
        const toMinutes = (typeof rangeEnd === 'undefined' || rangeEnd === null ? 59 : (this.selection['UhrzeitBisMinuten'] || this.mpSidebar.params['UhrzeitBisMinuten'] || typeof this.mpSidebar.params['rangeEnd'] !== 'undefined' ? this.mpSidebar.params['rangeEnd'].local().minutes() : 59));

        this.selection = Object.assign(this.selection, {
          rangeStart: rangeStart,
          rangeEnd: rangeEnd,
          UhrzeitVonStunden: fromHours,
          UhrzeitVonMinuten: fromMinutes,
          UhrzeitBisStunden: toHours,
          UhrzeitBisMinuten: toMinutes
        });
      }
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Changes the current selection to
   * 'start' or 'end'.
   */
  setCurrentSelection(selection: string): void {
    this._calendarService.changeCalendarSelection({ selection: selection });
  }

  /**
   * Sets date and time to the currently
   * moment (for the range start).
   */
  setDirectly(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['rangeStart'] = moment();
    this.mpSidebar.params['UhrzeitVonStunden'] = this.mpSidebar.params['rangeStart'].local().hours();
    this.mpSidebar.params['UhrzeitVonMinuten'] = this.mpSidebar.params['rangeStart'].local().minutes();

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }
  }

  /**
   * Sets date and time for end range
   * to 'forever'.
   */
  setForever(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['rangeEnd'] = null;
    this.mpSidebar.params['UhrzeitBisStunden'] = 23;
    this.mpSidebar.params['UhrzeitBisMinuten'] = 59;

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }
  }

  /**
   * Merges the selection with the sidebar
   * params and triggers the save function,
   * if exists.
   */
  save(event: MouseEvent): void {
    event.preventDefault();
    this.selection['rangeStart'].local().hours(this.selection['UhrzeitVonStunden']);
    this.selection['rangeStart'].local().minutes(this.selection['UhrzeitVonMinuten']);

    if (typeof this.selection['rangeEnd'] !== 'undefined') {
      if (this.selection['rangeEnd'] !== null) {
        this.selection['rangeEnd'].local().hours(this.selection['UhrzeitBisStunden']);
        this.selection['rangeEnd'].local().minutes(this.selection['UhrzeitBisMinuten']);
      }
    }

    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
    this._customEventServicee.dispatch(new CustomEvent(CustomEventType.SendTimeFromSidebarPeriodTime, 'von ' + this.selection['rangeStart'].local().format('DD.MM.YYYY HH:MM') + ' bis ' + (typeof this.selection['rangeEnd'] !== 'undefined' && this.selection['rangeEnd'] !== null ? this.selection['rangeEnd'].local().format('DD.MM.YYYY HH:MM') : 'unbegrenzt')));

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }
}
