<div class="card margin-bottom-32 display-flex __flex-1 overflow-hidden width-full">
  <div class="row display-flex __flex-1">
    <div class="position-relative display-flex float-left z-index-1 col-medium-8 col-large-7 col-xlarge-6">
      <div class="z-index-1 position-relative"
           [ngClass]="{'card-text-background': action.Key != 'LeereAktion'}"
           [ngStyle]="{'background-color': bgColor || 'inherit'}">
        <a href="{{detailLink | angularjsToAnuglarUrl}}"
           target="{{detailInNewTab ? '_blank':''}}"
           class="display-flex __flex-1 flex-direction-column flex-align-items-stretch height-100-perc">
          <div class="flex-direction-column flex-align-items-stretch display-block">
            <div class="primary-title">
              <div class="title last"
                   [innerText]="action.Title">
              </div>
            </div>
            <div id="action-card-text" class="supporting-text"
                 mpCoreMpShave
                 [shaveHeight]="90">
              <p [innerHTML]="trustAsHtml(action.Text)"
                 class="margin-bottom-0 padding-bottom-16">
              </p>
            </div>
          </div>
          <div class="supporting-features display-block">
            <i class="svg-icon __clr-specific-1 __size-18 __time margin-right-16"
               [hidden]="daysLeft > 0 || hideTimeline ">
            </i>
            <div class="progressbar-container top-8 position-relative"
                 [hidden]="daysLeft <= 0 && hideTimeline ">
              <div class="progressbar position-relative">
                <span [ngStyle]="{width: ((daysTotal - daysLeft)*100/daysTotal)+'%'}" class="overflow-hidden position-relative display-block height-full"></span>
              </div>
              <div class="progressbar-text font-weight-light font-size-xxs"
                   [innerText]="timeLeftText">
              </div>
            </div>
            <div class="progressbar-text font-weight-light font-size-s"
                 [hidden]="daysLeft > 0 ">
              {{ ls.locs['loc'].actionBeendet }}
            </div>
          </div>
          <div class="supplemental-actions display-block">
            <!--<div href="{{detailLink}}"
                 target="{{detailInNewTab ? '_blank':''}}"
                 class="mp-button"
                 [innerText]="action.ButtonText">
            </div>-->
            <div class="mp-button"
                 [innerText]="action.ButtonText">
            </div>
          </div>
        </a>
      </div>
    </div>
    <img class="bg-img position-absolute top-0 right-0 margin-bottom-0 "
         [src]="bgImg">
  </div>
</div>
