import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from './../services/auth.service';
import { RoleMappingService } from './../services/role-mapping.service';

/**
 * This class provides a pipe, that
 * transforms old AngularJS urls into
 * new Angular url structure.
 */
@Pipe({
  name: 'angularjsToAnuglarUrl'
})
export class AngularjsToAnuglarUrlPipe implements PipeTransform {

  constructor(
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  transform(url: string): string {
    if (url === null || (!url || url.length === 0)) {
      return url;
    }

    const roleFromService = this._authService.getRole();
    let role = '';

    if (typeof roleFromService === 'object') {
      role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      role = this._roleMapping.getReverseMappedRole(roleFromService);
    }

    if (url.charAt(0) === '#' && url.charAt(1) === '/') {
      return url.replace('#/', `#/${role}/`);
    } else if (url.indexOf(location.origin) !== -1) {
      if (url.indexOf(`${location.origin}/${role}#/`) !== -1) {
        return url.replace(`${role}#/`, `#/${role}/`);
      } else {
        const roleKeys = this._roleMapping.getRoleKeys();
        let replacedUrl = '';

        roleKeys.forEach((roleKey: string) => {
          if (url.indexOf(`${location.origin}/${roleKey}#/`) !== -1) {
            replacedUrl = url.replace(`${roleKey}#/`, `#/${roleKey}/`);
          } 
        });

        if (replacedUrl !== '') {
          return replacedUrl;
        } else {
          return url;
        }
      }
    }

    return url;
  }

}
