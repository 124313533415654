<div class="magma-input flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8"
     [ngClass]="{'invalid-error': hasError == true}">
  <label class="flex-col-12 font-weight-700 font-size-xxs padding-left-right-0 display-block-important"
         for="{{mpId}}">
    <span class="display-inline"
          [ngClass]="((!mpRequired && mpDisabled != true) || (mpRequired == false && mpDisabled != true) || (!mpRequired && !mpDisabled) || (mpRequired == false && !mpDisabled)) && label ? 'margin-right-4' : ''">
      {{label}}
    </span>
    <span *ngIf="((!mpRequired && mpDisabled != true) || (mpRequired == false && mpDisabled != true) || (!mpRequired && !mpDisabled) || (mpRequired == false && !mpDisabled)) && label"
          class="font-size-xxs text-color-grey-info display-inline">
      {{ ls.locs['loc'].OptionalField }}
    </span>
  </label>
  <div class="flex-col-12 padding-8 magma-textarea">
    <textarea class="flex-col-12 padding-0 font-size-general"
              [(ngModel)]="model"
              (change)="textChanged()"
              id="{{mpId}}"
              [ngClass]="{active: model}"
              mpRequired="mpRequired"
              (focus)="focusMe"
              [ngStyle]="{'height': height}"
              placeholder="{{mpPlaceholder}}"
              [attr.maxlength]="maxlength">
    </textarea>
  </div>
</div>
