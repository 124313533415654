import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';

/**
 * This class provides the data for the
 * rules of game page.
 */
@Component({
  selector: 'mp-core-rules-of-the-game',
  templateUrl: './rules-of-the-game.component.html'
})
export class RulesOfTheGameComponent implements OnInit, OnDestroy {

  public rulesOfGame: Array<any> = [];
  public defaultOpen: boolean = false;
  public rulesOfGameOutside: { [key: string]: any } | undefined;
  public rulesOfGameLoaded: boolean = false;
  public openRuleOfGame: { [key: string]: any } = {};
  public getRulesOfGame = this._getRulesOfGame.bind(this);
  public trustedHtml: any;

  private _getSettingsSubscription: Subscription | undefined;
  private _getRouteParamsSubscription: Subscription | undefined;
  private _getRulesOfGameSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _titleService: MpTitleService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpSettings: MpSettingsService,
    private _route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Sets the title, the breadcrumbs and the menu
   * highlighting. Gets the settings.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('Spielregeln');
    this._mpMenuHighlight.setMenuHighlight('support');
    this._mpBreadcrumbService.setCurrentSiteWithLoc('Spielregeln', 'loc', 'Spielregeln');

    if (Object.keys(this._mpSettings.settings).length > 0) {
      this._loadRulesOfGame();

      if (typeof this._getSettingsSubscription !== 'undefined') {
        this._getSettingsSubscription.unsubscribe();
      }
    } else {
      this._getSettingsSubscription = this._mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._loadRulesOfGame();

          if (typeof this._getSettingsSubscription !== 'undefined') {
            this._getSettingsSubscription.unsubscribe();
          }
        }
      });

      this._mpSettings.getSettings();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }

    if (typeof this._getRouteParamsSubscription !== 'undefined') {
      this._getRouteParamsSubscription.unsubscribe();
    }

    if (typeof this._getRulesOfGameSubscription !== 'undefined') {
      this._getRulesOfGameSubscription.unsubscribe();
    }
  }

  /**
   * Loads the rules of games from the
   * backend.
   */
  private _loadRulesOfGame(): void {
    this.defaultOpen = this._mpSettings.settings['NetcentiveCoreSettings'].SpielregelnOffenDefault;

    this._getRouteParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._getRulesOfGameSubscription = this._apiService.getRequest('/api/Texte/getSpielregeln').subscribe((data: any) => {
        this.rulesOfGame = data.Records.filter((rule: any) => {
          return rule['Inside'] === true;
        });

        this.rulesOfGame.forEach((rule: any) => {
          rule['accID'] = rule['Key'];
          rule['accText'] = rule['Text'];
          rule['accHeadline'] = rule['Title'];
          rule['accOpenOnLoad'] = (this.defaultOpen || typeof params['OpenKey'] !== 'undefined' && params['OpenKey'] === rule['Key']);
          this.openRuleOfGame[rule['Key']] = (this.defaultOpen || typeof params['OpenKey'] !== 'undefined' && params['OpenKey'] === rule['Key']);
        });

        this.rulesOfGameOutside = data.Records.find((rule: any) => {
          return rule['Inside'] === false;
        });

        this.rulesOfGameLoaded = true;
      });
    });
  }

  /**
   * Returns the rules of game.
   */
  private _getRulesOfGame(): Array<any> {
    return this.rulesOfGame;
  }

  /*
  * Sanitizer for HTML.
  */
  trustAsHtml(str: string): string {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
