import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DatetimerangepickerModule } from 'angular-datetimerangepicker';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { CommonInternComponentsLoaderModule } from './../common-intern-components-loader.module';
import { ImageVideoCarouselModule } from './../../components/image-video-carousel/image-video-carousel.module';

import { ParticipantComponent } from './participant.component';

import { HomeComponent } from './pages/home/home.component';
import { ShowroomComponent } from './pages/showroom/showroom.component';
import { ShopComponent } from './pages/shop/shop.component';
import { AwardDetailsComponent } from './pages/award-details/award-details.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { ShoppingBasketComponent } from './pages/shopping-basket/shopping-basket.component';
import { SbItemComponent } from './pages/shopping-basket/sb-item/sb-item.component';
import { OrderProcessStepperComponent } from './../../components/order-process/order-process-stepper/order-process-stepper.component';
import { OrderOverviewComponent } from './../../components/order-process/order-overview/order-overview.component';
import { OrderShoppingBasketPanelComponent } from './../../components/order-process/order-shopping-basket-panel/order-shopping-basket-panel.component';
import { OrderCheckSidePanelComponent } from './../../components/order-process/order-check-side-panel/order-check-side-panel.component';
import { ShoppingBasketCheckDirective } from './pages/shopping-basket/shopping-basket-check.directive';
import { ShippingComponent } from './pages/shipping/shipping.component';
import { OrderCheckComponent } from './pages/order-check/order-check.component';
import { OrderConfirmationComponent } from './pages/order-confirmation/order-confirmation.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CampaignDetailsComponent } from './pages/campaign-details/campaign-details.component';
import { PictureCreditComponent } from './pages/picture-credit/picture-credit.component';
import { StageDetailsComponent } from './pages/stage-details/stage-details.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { DataPrivacyComponent } from './pages/data-privacy/data-privacy.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { FeatureDetailsComponent } from './pages/feature-details/feature-details.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { RulesOfTheGameComponent } from './pages/rules-of-the-game/rules-of-the-game.component';
import { ConditionsOfParticipationComponent } from './pages/conditions-of-participation/conditions-of-participation.component';
import { AdditionalQuestionsComponent } from './pages/additional-questions/additional-questions.component';
import { RequestAccountChangeComponent } from './pages/request-account-change/request-account-change.component';
import { MyCampaignsComponent } from './pages/my-campaigns/my-campaigns.component';
import { PaymentOfTaxBaseParticipantComponent } from './pages/payment-of-tax-base-participant/payment-of-tax-base-participant.component';


@NgModule({
  declarations: [
    ParticipantComponent,
    HomeComponent,
    ShowroomComponent,
    ShopComponent,
    AwardDetailsComponent,
    WishlistComponent,
    ShoppingBasketComponent,
    SbItemComponent,
    OrderProcessStepperComponent,
    OrderOverviewComponent,
    OrderShoppingBasketPanelComponent,
    OrderCheckSidePanelComponent,
    ShoppingBasketCheckDirective,
    ShippingComponent,
    OrderCheckComponent,
    OrderConfirmationComponent,
    ContactComponent,
    FaqComponent,
    CampaignDetailsComponent,
    PictureCreditComponent,
    StageDetailsComponent,
    CookiesComponent,
    DataPrivacyComponent,
    SettingsComponent,
    FeatureDetailsComponent,
    ImprintComponent,
    RulesOfTheGameComponent,
    ConditionsOfParticipationComponent,
    AdditionalQuestionsComponent,
    RequestAccountChangeComponent,
    MyCampaignsComponent,
    PaymentOfTaxBaseParticipantComponent
  ],
  exports: [
    ParticipantComponent,
    OrderProcessStepperComponent,
    OrderOverviewComponent,
    ShopComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DatetimerangepickerModule,
    CommonInternComponentsLoaderModule,
    NgxPaginationModule,
    CarouselModule,
    ImageVideoCarouselModule,
    InfiniteScrollModule
  ]
})
export class ParticipantModule { }
