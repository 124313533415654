<div id="shipping" mpCoreShoppingBasketCheck class="layout-container padding-bottom-80" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-order-process-stepper key="versand"></mp-core-order-process-stepper>
  <div class="row margin-top-40 op-shipping order-process padding-top-0">
    <div class="grid"
         [ngClass]="'col-medium-' + (mpSettings.settings['DisplaySettings'].ShowPunkte ? '9' : '12')"
         id="shipping-addresses">
      <div>
        <h2 class="border-bottom padding-bottom-24 margin-bottom-16">{{ ls.locs['loc'].VersandTitel }}</h2>
        <mp-core-order-addresses [(selAddress)]="selAddress"
                                 [selectable]="true"
                                 [colCount]="3"
                                 lkz="{{ mpSettings.settings['ShopSettings'].ProjektLand }}"
                                 [shippingAddresses]="true"
                                 (selAddressChange)="updateExpressService($event)">
        </mp-core-order-addresses>
      </div>
      <div id="warenkorb">
        <h2 class="margin-top-32">{{ ls.locs['loc'].VersandartWahl }}</h2>
        <span *ngIf="ls.locs['loc'].VersandartWahlHinweis">{{ ls.locs['loc'].VersandartWahlHinweis }}</span>
        <h2 class="border-bottom padding-bottom-24"></h2>
        <div *ngFor="let sBItems of groupedShoppingBasket"
             class="margin-top-24 wk-group">
          <div [innerText]="sBItems[0].Versandzeit"
               class="shipping-status margin-bottom-16 padding-top-bottom-8 padding-left-right-16 display-flex flex-align-items-center flex-justify-cont-center">
          </div>
          <div *ngFor="let item of sBItems"
               class="card-flat bg-color-white wk-card margin-top-8 padding-0 display-flex flex-grid flex-direction-column margin-right-8 margin-left-8 ">
            <div class="floating-container wk-item flex-grid"
                 [ngClass]="{'warning': item.ShownValidationMessages && item.ShownValidationMessages.length > 0, 'danger': item.HasBlockingValidationMessages}">
              <div class="flex-col-1 flex-col-3-m flex-col-3-s flex-col-3-xs flex-justify-cont-center flex-align-items-center wk-item-img-container">
                <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}">
                  <img class="wk-item-img"
                       src="{{item.Bild}}?width=100"
                       alt="{{item.ArtNr+'-'+item.PIN}}" />
                </a>
              </div>
              <div class="flex-col-3 flex-col-9-m flex-col-9-s flex-col-9-xs flex-justify-cont-start flex-align-items-center wk-item-description-container">
                <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}">
                  <span class="wk-company">{{item.Hersteller}}</span>
                  <strong class="wk-item-label overflow-hidden display-inline-block">{{item.ArtBez}} {{item.ArtBez2}}</strong>
                </a>
              </div>
              <div class="flex-col-8 flex-col-12-m flex-col-12-s flex-justify-cont-end flex-align-items-center flex-direction-row wk-shipping-method-buttons">
                <div class="flex-col-9 flex-col-12-m flex-align-items-start flex-direction-column">
                  <mp-core-radio-button mpGroup="versand"
                                        name="{{item.Key}}"
                                        *ngIf="selAddress['LKZ'] === 'DE'"
                                        label="{{ ls.locs['loc'].StandardService }} ({{item.VersandzeitKurz}})"
                                        mpId="radio-standard-service-{{item.Key}}"
                                        [value]="false"
                                        [model]="item.Express"
                                        (modelChange)="item.Express = $event; updateItem(item, false)">
                  </mp-core-radio-button>
                  <mp-core-radio-button mpGroup="versand"
                                        name="{{item.Key}}"
                                        *ngIf="selAddress['LKZ'] !== 'DE'"
                                        label="{{ ls.locs['loc'].StandardServiceAusland.replace('[Land]', (selAddress['Land']!==undefined && selAddress['Land'].Land!==undefined ? selAddress['Land'].Land : 'Deutschland') ) }} ({{item.VersandzeitKurz}})"
                                        mpId="radio-standard-service-{{item.Key}}"
                                        [value]="false"
                                        [model]="item.Express"
                                        (modelChange)="item.Express = $event; updateItem(item, false)">
                  </mp-core-radio-button>

                  <span *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte" class="show-on-medium">{{item.AuslandsversandPunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                  <mp-core-radio-button class="radio-express-service padding-top-16 sidebar-open"
                                        *ngIf="item.CanExpress && selAddress['LKZ'] === 'DE'"
                                        mpGroup="versand"
                                        name="{{item.Key}}"
                                        label="{{ ls.locs['loc'].Express }} {{ ls.locs['loc'].ExpressLieferzeit }}"
                                        mpId="radio-express-service-{{item.Key}}"
                                        [value]="true"
                                        [model]="item.Express"
                                        (modelChange)="item.Express = $event; updateItem(item, true)">
                    <mp-core-popover content="{{ ls.locs['loc'].ExpressTooltip }}"
                                     popoverTitle="{{ ls.locs['loc'].Express }}"
                                     class="pointer no-word-wrap hide-on-medium">
                      <i class="svg-icon __clr-info __size-18 __top-3 __info margin-left-4"></i>
                    </mp-core-popover>
                  </mp-core-radio-button>
                  <span class="show-on-medium"
                        *ngIf="item.CanExpress && selAddress['LKZ'] && selAddress['LKZ'].toUpperCase() === 'DE' && expressPoints !== null">
                    <mp-core-popover *ngIf="item.CanExpress && selAddress['LKZ'] === 'DE'"
                                     content="{{ls.locs['loc'].ExpressTooltip}}"
                                     popoverTitle="{{ ls.locs['loc'].Express }}"
                                     class="pointer no-word-wrap shwo-on-medium">
                      <i class="svg-icon __clr-info __size-18 __top--3 __info margin-left-0 margin-right-8"></i>
                    </mp-core-popover>
                    {{ expressPoints }} {{ ls.locs['loc'].WaehrungPunkte }}
                  </span>
                </div>
                <div class="flex-col-3 flex-align-items-end flex-direction-column hide-on-medium">
                  <span *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && mpSettings.settings['BestellprozessSettings'].HideVersandkosten">{{ ls.locs['loc'].WaehrungPunkte }}</span>

                  <span *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !mpSettings.settings['BestellprozessSettings'].HideVersandkosten">{{item.AuslandsversandPunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                  <span class="padding-top-16"
                        *ngIf="item.CanExpress && selAddress['LKZ'] && selAddress['LKZ'].toUpperCase() === 'DE' && expressPoints !== null">
                    {{ expressPoints }} {{ ls.locs['loc'].WaehrungPunkte }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col-5 flex-grid flex-wrap wk-infos"
             *ngIf="shoppingBasket['length'] > 1">
          <mp-core-micro-copy *ngIf="ls.locs['loc'].VersandHinweis"
                              infoState="inactive"
                              copyText="{{ ls.locs['loc'].VersandHinweis }}"
                              class="margin-left-8 padding-right-32 margin-top-16">
          </mp-core-micro-copy>
        </div>
      </div>
      <div class="bestellung-nav-row floating-container margin-top-56 hide-on-mobile">
        <a href="#/{{role}}/Warenkorb"
           class="mp-button text-color-back-btn float-left"
           id="back-to-shop-button">
          {{ ls.locs['loc'].BestellungAbbrechen }}
          <i class="svg-icon __clr-default __size-18 margin-left-8 __restore float-none-important"></i>
        </a>
        <a *ngIf="!mpSettings.settings['BestellprozessSettings'].BestellungAusgesetzt" class="mp-button _prim float-right"
           id="to-payment-button"
           (click)="validateShoppingBasket($event)">
          {{ ls.locs['loc'].VersandWeiter }}
        </a>
        <a href="#/{{role}}/{{currentStep['PreviousUrl']}}"
           id="go-back-button"
           class="mp-button text-color-back-btn float-right margin-right-32">
          {{ ls.locs['loc'].Zurueck }}
        </a>
      </div>
      <div stickyBottom class="bestellung-nav-row floating-container show-on-mobile bg-color-white wk-bottom-buttons">
        <div class="flex-grid flex-direction-row flex-wrap margin-left-right-0">
          <a *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="mp-button _light-grey padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column margin-top-0"
             href="tel:{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}">
            <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].Support }}</span>
          </a>

          <a *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline && !mpSettings.settings['BestellprozessSettings'].BestellungAusgesetzt" class="mp-button _prim padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
             (click)="validateShoppingBasket($event)">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].VersandWeiter }}</span>
          </a>

          <a *ngIf="!(mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline) && !mpSettings.settings['BestellprozessSettings'].BestellungAusgesetzt" class="mp-button _prim padding-top-bottom-8 flex-col-12-xs flex-col-12-s flex-col-12-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
             (click)="validateShoppingBasket($event)">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].VersandWeiter }}</span>
          </a>

          <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-between">
            <a class="mp-button"
               href="#/{{role}}/{{currentStep['PreviousUrl']}}">
              {{ ls.locs['loc'].Zurueck }}
            </a>
            <a class="mp-button"
               href="#/{{role}}/Home">
              {{ ls.locs['loc'].Abbrechen }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && mpSettings.settings['BestellprozessSettings'].BestellungAusgesetzt" class="grid col-medium-3" id="order-overview-container">
      <mp-core-order-overview nextText="{{ ls.locs['loc'].BestellungAusgesetztText }}" [buttonFunction]="cancelBound"></mp-core-order-overview>
    </div>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !mpSettings.settings['BestellprozessSettings'].BestellungAusgesetzt" class="grid col-medium-3" id="order-overview-container">
      <mp-core-order-overview nextText="{{ ls.locs['loc'].VersandWeiter }}" [buttonFunction]="validateShoppingBasketBound"></mp-core-order-overview>
    </div>
    <mp-core-support class="hide-on-medium"></mp-core-support>
  </div>
</div>
