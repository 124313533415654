import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpStatusService } from './../../../../services/mp-status.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the functions for
 * for the account.
 */
@Component({
  selector: 'mp-core-account',
  templateUrl: './account.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit, OnDestroy {

  public hasStatus: boolean = false;
  public role: string = '';

  private _getStatusSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpStatus: MpStatusService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Checks whether or not the user has status.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (typeof this._mpStatus.currentHasStatus === 'undefined') {
      this._getStatusSubscription = this._mpStatus.status.subscribe((hasStatus: boolean) => {
        this.hasStatus = hasStatus;

        if (typeof this._getStatusSubscription !== 'undefined') {
          this._getStatusSubscription.unsubscribe();
        }
      });

      this._mpStatus.hasStatus();
    } else {
      this.hasStatus = this._mpStatus.currentHasStatus;

      if (typeof this._getStatusSubscription !== 'undefined') {
        this._getStatusSubscription.unsubscribe();
      }
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getStatusSubscription !== 'undefined') {
      this._getStatusSubscription.unsubscribe();
    }
  }

}
