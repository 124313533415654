<div *ngIf="ls.locsLoaded | async"
     class="row">
  <div class="grid col-large-6">
    <mp-core-date-picker mpId="date-picker-von"
                         class="form-control suchKontainerDPDatum date-picker"
                         [minDate]="minDate"
                         [maxDate]="maxDate"
                         rangeType="from"
                         [model]="startdate"
                         (modelChange)="startdate = $event; startDateChanged();"
                         mpType="text">
    </mp-core-date-picker>
  </div>
  <div class="grid col-large-6">
    <mp-core-date-picker mpId="date-picker-bis"
                         class="form-control suchKontainerDPDatum date-picker"
                         [minDate]="minDate"
                         [maxDate]="maxDate"
                         rangeType="to"
                         [model]="enddate"
                         (modelChange)="enddate = $event; endDateChanged();"
                         mpType="text">
    </mp-core-date-picker>
  </div>
</div>
