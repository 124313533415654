import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { TimelineMax, gsap, Power0 } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { GameSvgFetcherService } from './../../../../services/game-svg-fetcher.service';

/**
 * This class provides the data and
 * the functions for the racing
 * race track.
 */
@Component({
  selector: 'mp-gfc-racing-race-track',
  templateUrl: './racing-race-track.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RacingRaceTrackComponent implements OnInit, OnDestroy {

  public availableColors: Array<string> = [
    'green-car',
    'yellow-car',
    'orange-car',
    'red-car',
    'pink-car',
    'darkblue-car',
    'lightblue-car',
    'aqua-car',
    'purple-car',
    'gray-car',
    'black-car',
    'white-car'
  ];
  public availableCars: Array<string> = [
    'racing/car-one',
    'racing/car-two',
    'racing/car-three',
    'racing/car-four',
    'racing/car-five',
    'racing/car-six',
  ];
  public carElementIds: Array<string> = [
    'summer-race-track-car-prev-one',
    'summer-race-track-car-prev-two',
    'summer-race-track-car-current',
    'summer-race-track-car-next-one',
    'summer-race-track-car-next-two'
  ];
  public positionOld: { [key: string]: any } = {};
  public raceCarsArrayOld: Array<any> = [];
  public raceCarsArrayNew: Array<any> = [];
  public tnPos: number = 0;
  public allTn: number = 0;
  public points: number = 0;
  public ranking: Array<any> = [];
  public rankingComplete: Array<any> = [];
  public kmToDrive: number = 0;
  public hideRaceButton: boolean = false;
  public leftTime: string = '';
  public showTargetPositions: boolean = false;
  public showRanking: boolean = false;
  public animationsLoaded: boolean = false;
  public showCollectMore: boolean = false;
  public showReset: boolean = false;
  public carsSvgs: { [key: string]: any } = {};
  public trackBlankSvg: SafeHtml = '';
  public trackSvg: SafeHtml = '';
  public loading: boolean = true;
  public toggleRankingFunc = this.toggleRanking.bind(this);
  public closeCollectMore = this._closeCollectMore.bind(this);

  private _trackBlankPath = 'racing/race-track-blank';
  private _trackPath = 'racing/race-track';
  private _role: string = '';
  private _campaignKey: string = '';
  private _racer: { [key: string]: any } = {};
  private _rankingOld: Array<any> = [];
  private _rankingNew: Array<any> = [];
  private _rankingShortOld: Array<any> = [];
  private _rankingShortNew: Array<any> = [];
  private _positionNew: { [key: string]: any } = {};
  private _backupLastPoints: number = -1;
  private _getQueryParamsSubscription: Subscription | undefined;
  private _fetchSvgContentSubscriptions: Array<Subscription> = [];
  private _fetchTrackBlankSvgContentSubscription: Subscription | undefined;
  private _fetchTrackSvgContentSubscription: Subscription | undefined;
  private _getRaceTrackSubscription: Subscription | undefined;
  private _saveRacerSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _gameSvgFetcher: GameSvgFetcherService,
    private _svgLoader: SvgLoaderService
  ) { }

  /**
   * Gets the campaign key and the role.
   * Fetches the svgs.
   */
  ngOnInit(): void {
    gsap.registerPlugin(CSSPlugin);
    this._svgLoader.startLoading();
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this.availableCars.forEach((car: string) => {
      const subscr = this._gameSvgFetcher.fetchSvg(car).subscribe((svgContent: SafeHtml) => {
        this.carsSvgs[car] = svgContent;
      });

      this._fetchSvgContentSubscriptions.push(subscr);
    });

    this._fetchTrackBlankSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._trackBlankPath).subscribe((svgContent: SafeHtml) => {
      this.trackBlankSvg = svgContent;
    });

    this._fetchTrackSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._trackPath).subscribe((svgContent: SafeHtml) => {
      this.trackSvg = svgContent;
    });

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._campaignKey = params['key'];
      this._getRaceTrack();
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (this._fetchSvgContentSubscriptions.length > 0) {
      this._fetchSvgContentSubscriptions.forEach((subscr: Subscription) => {
        subscr.unsubscribe();
      });
    }

    if (typeof this._fetchTrackBlankSvgContentSubscription !== 'undefined') {
      this._fetchTrackBlankSvgContentSubscription.unsubscribe();
    }

    if (typeof this._fetchTrackSvgContentSubscription !== 'undefined') {
      this._fetchTrackSvgContentSubscription.unsubscribe();
    }

    if (typeof this._getRaceTrackSubscription !== 'undefined') {
      this._getRaceTrackSubscription.unsubscribe();
    }

    if (typeof this._saveRacerSubscription !== 'undefined') {
      this._saveRacerSubscription.unsubscribe();
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Gets the race track data.
   */
  private _getRaceTrack(): void {
    this._getRaceTrackSubscription = this._apiService.postRequest('/api/Gamification/GetRennstrecke', {
      AktionenKey: this._campaignKey
    }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        const raceTrack = data.Records[0];
        this._racer = raceTrack['Rennfahrer'];
        this.positionOld = raceTrack['PositionAlt'];
        this._rankingOld = raceTrack['RanglisteAlt'];
        this._rankingShortOld = raceTrack['RanglisteKurzAlt'];
        this.raceCarsArrayOld = raceTrack['RennwagenArrayAlt'];
        this._positionNew = raceTrack['Position'];
        this._rankingNew = raceTrack['Rangliste'];
        this._rankingShortNew = raceTrack['RanglisteKurz'];
        this.raceCarsArrayNew = raceTrack['RennwagenArray'];
        this.tnPos = this.positionOld !== null ? this.positionOld['RangNr'] : 0;
        this.allTn = this.positionOld !== null ? this._rankingOld.length : 0;
        this.points = this.positionOld !== null ? this.positionOld['Punkte'] : 0;
        this.ranking = this._rankingShortOld;
        this.rankingComplete = this._rankingOld;
        this.kmToDrive = this.positionOld !== null && this._positionNew !== null ? this._positionNew['Punkte'] - this.positionOld['Punkte'] : 0;
        this._backupLastPoints = this._racer !== null ? this._racer['LetzterPunktestand'] : 0;

        if (this.positionOld !== null) {
          setInterval(() => {
            this.leftTime = this._calculateLeftTime();
          }, 1000);
        }

        if (this.kmToDrive > 0) {
          this.hideRaceButton = false;
        } else {
          this.hideRaceButton = true;
        }
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }

      this._animateTrack();
    });
  }

  /**
   * Calculates the left over time.
   */
  private _calculateLeftTime(): string {
    const dateCurrent = new Date();
    const dateEnd = new Date(this.positionOld['Aktionsende']);
    let dateDiff = Math.abs(dateEnd.getTime() - dateCurrent.getTime()) / 1000;

    const days = Math.floor(dateDiff / 86400);
    dateDiff -= days * 86400;

    let hours: any = Math.floor(dateDiff / 3600) % 24;
    dateDiff -= hours * 3600;

    if (hours < 10) {
      hours = '0' + hours;
    }

    let minutes: any = Math.floor(dateDiff / 60) % 60;
    dateDiff -= minutes * 60;

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    let seconds: any = Math.floor(dateDiff % 60);

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return days + 'T' + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  /**
   * Aniamtes the race track.
   */
  private _animateTrack(): void {
    const checkSvgTrackLoaded = setInterval(() => {
      const raceTrack = document.querySelector('#summer-race-track-svg #rennstrecke');
      const raceTrackBlank = document.querySelector('#summer-race-track-svg-blanko > svg');

      if (raceTrack !== null && raceTrackBlank !== null) {
        clearInterval(checkSvgTrackLoaded);
        const trackAnimationDuration = 7;

        new TimelineMax().fromTo(raceTrack, (trackAnimationDuration * 1.25), {
          x: '25%',
          y: '-50%'
        }, {
          x: '-100%',
          y: '-50%',
          ease: Power0.easeNone,
          overwrite: false,
          repeat: -1
        });

        new TimelineMax().fromTo(raceTrackBlank, (trackAnimationDuration * .25), {
          x: '-25%',
          y: '-50%'
        }, {
          x: '-100%',
          y: '-50%',
          ease: Power0.easeNone,
          overwrite: false,
          repeat: -1,
          onStart: () => {
            this.animationsLoaded = true;
            this._svgLoader.finishLoading();
            this.loading = false;
          }
        });
      }
    }, 50);
  }

  /**
   * Animates the overtaking.
   */
  private _animateOvertaking(): void {
    this.hideRaceButton = true;
    const raceTrackCars = document.getElementById('summer-race-track-cars');
    const raceTrackCarsTarget = document.getElementById('summer-race-track-cars-target');

    if (raceTrackCars !== null) {
      if (!raceTrackCars.classList.contains('summer-race-overtaking-running')) {
        raceTrackCars.classList.add('summer-race-overtaking-running');
      }
    }

    if (raceTrackCarsTarget !== null) {
      if (!raceTrackCarsTarget.classList.contains('summer-race-overtaking-running')) {
        raceTrackCarsTarget.classList.add('summer-race-overtaking-running');
      }
    }

    let animationDuration = 4;
    let transOrig = 'left top';
    const currCarTargetX = '-50%';
    const currCarTargetY = -(window.innerWidth / 2);
    const nextCarOneTargetX = '-40%';
    const nextCarOneTargetY = 0;
    const nextCarTwoTargetX = '50%';
    const nextCarTwoTargetY = 0;
    const overtakingCar = document.querySelector('#summer-race-track-cars #summer-race-track-car-current svg');
    const overtakingNextCarOne = document.querySelector('#summer-race-track-cars #summer-race-track-car-next-one svg')
    let overtakingNextCarOneContainer: HTMLElement | null = null;
    const overtakingNextCarTwo = document.querySelector('#summer-race-track-cars #summer-race-track-car-next-two svg')
    let overtakingNextCarTwoContainer: HTMLElement | null = null;
    const carCurrent: HTMLElement | null = document.querySelector('#summer-race-track-cars #summer-race-track-car-current');

    if (carCurrent !== null) {
      carCurrent.style.animationPlayState = 'paused';
    }

    if (overtakingNextCarOne !== null) {
      overtakingNextCarOneContainer = document.querySelector('#summer-race-track-cars #summer-race-track-car-next-one');

      if (overtakingNextCarOneContainer !== null) {
        overtakingNextCarOneContainer.style.animationPlayState = 'paused';
      }
    }

    if (overtakingNextCarTwo !== null) {
      overtakingNextCarTwoContainer = document.querySelector('#summer-race-track-cars #summer-race-track-car-next-two');

      if (overtakingNextCarTwoContainer !== null) {
        overtakingNextCarTwoContainer.style.animationPlayState = 'paused';
      }
    }

    if (window.innerHeight < 768 || window.innerWidth < 768 || window.orientation === 0 && window.innerWidth <= 768 || window.orientation === 180 && window.innerWidth < 768) {
      animationDuration = 2;
      const htmlElem: HTMLHtmlElement | null = document.getElementsByTagName('html')[0];

      if (htmlElem !== null) {
        if (htmlElem.classList.contains('iOSDevice') && htmlElem.classList.contains('Chrome') ||
          htmlElem.classList.contains('iOSDevice') && htmlElem.classList.contains('Safari')) {
          transOrig = 'center';

          if (carCurrent !== null) {
            carCurrent.style.animation = 'none';
          }

          if (overtakingNextCarOneContainer !== null) {
            overtakingNextCarOneContainer.style.animation = 'none';
          }

          if (overtakingNextCarTwoContainer !== null) {
            overtakingNextCarTwoContainer.style.animation = 'none';
          }
        }
      }
    }

    if (overtakingCar !== null) {
      new TimelineMax().fromTo(overtakingCar, animationDuration, {
        y: 0,
        x: 0,
        rotation: 0,
        transformOrigin: transOrig
      }, {
        y: currCarTargetY,
        x: currCarTargetX,
        overwrite: false,
        ease: Power0.easeNone,
        rotation: -4,
        transformOrigin: transOrig,
        delay: .5,
        onStart: () => {
          setTimeout(() => {
            const raceTrack = document.querySelector('#summer-race-track');

            if (raceTrack !== null) {
              if (!raceTrack.classList.contains('summer-race-overtaking-fade')) {
                raceTrack.classList.add('summer-race-overtaking-fade');
              }
            }
          }, ((animationDuration * 1000) - 1500));
        },
        onComplete: () => {
          this.showTargetPositions = true;

          const raceTrack = document.querySelector('#summer-race-track');

          if (raceTrack !== null) {
            if (raceTrack.classList.contains('summer-race-overtaking-fade')) {
              raceTrack.classList.remove('summer-race-overtaking-fade');
            }
          }

          const trackCarTargetOne = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-one svg');
          const trackCarTargetTwo = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-two svg');

          if (trackCarTargetOne !== null) {
            const trackCarTargetOneContainer: HTMLElement | null = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-one');

            if (trackCarTargetOneContainer !== null) {
              trackCarTargetOneContainer.style.animation = 'none';
            }
          }

          if (trackCarTargetTwo !== null) {
            const trackCarTargetTwoContainer: HTMLElement | null = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-two');

            if (trackCarTargetTwoContainer !== null) {
              trackCarTargetTwoContainer.style.animation = 'none';
            }
          }

          this._animateOvertakingTarget();
        }
      });

      if (overtakingNextCarOne !== null) {
        new TimelineMax().fromTo(overtakingNextCarOne, (animationDuration / 2.5), {
          x: 0,
          y: 0,
          rotation: 0,
          transformOrigin: transOrig
        }, {
          x: nextCarOneTargetX,
          y: nextCarOneTargetY,
          rotation: (90 - (overtakingNextCarOneContainer !== null ? this._rotationInfo(overtakingNextCarOneContainer)['deg'] : 0)),
          delay: .1,
          overwrite: false
        });
      }

      if (overtakingNextCarTwo !== null) {
        new TimelineMax().fromTo(overtakingNextCarTwo, (animationDuration / 2), {
          x: 0,
          y: 0,
          rotation: 0,
          transformOrigin: transOrig
        }, {
          x: nextCarTwoTargetX,
          y: nextCarTwoTargetY,
          rotation: (90 - (overtakingNextCarTwoContainer !== null ? this._rotationInfo(overtakingNextCarTwoContainer)['deg'] : 0)),
          delay: .1,
          overwrite: false
        });
      }
    }
  }

  /**
   * Animates the target of overtaking.
   */
  private _animateOvertakingTarget(): void {
    this.tnPos = this._positionNew['RangNr'];
    this.allTn = this._rankingNew.length;
    this.points = this._positionNew['Punkte'];
    this.ranking = this._rankingShortNew;
    this.rankingComplete = this._rankingNew;
    const overtakingCarTarget = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-current svg');
    const overtakingPrevCarOne = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-one svg');
    const overtakingPrevCarTwo = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-two svg');
    let animationDuration = 4;

    if (window.innerHeight < 768 || window.innerWidth < 768 || window.orientation === 0 && window.innerWidth < 768 || window.orientation === 180 && window.innerWidth < 768) {
      animationDuration = 2;
    }

    if (overtakingCarTarget !== null) {
      new TimelineMax().fromTo(overtakingCarTarget, animationDuration, {
        y: (window.innerWidth / 2),
        x: '-30%',
        rotation: 4,
        transformOrigin: 'left top'
      }, {
        y: 0,
        x: '0%',
        overwrite: false,
        ease: Power0.easeNone,
        rotation: 0,
        transformOrigin: 'left top',
        onComplete: () => {
          const overtakingCarTargetContainer = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-current');

          if (overtakingCarTargetContainer !== null) {
            overtakingCarTargetContainer.removeAttribute('style');
          }

          const targetCars = document.querySelector('#summer-race-track-cars-target');

          if (targetCars !== null) {
            if (targetCars.classList.contains('summer-race-overtaking-running')) {
              targetCars.classList.remove('summer-race-overtaking-running');
            }
          }

          this.showCollectMore = true;
        }
      });
    }

    if (overtakingPrevCarOne !== null) {
      new TimelineMax().fromTo(overtakingPrevCarOne, (animationDuration * 2.5), {
        x: '80%',
        rotation: -4,
        transformOrigin: 'left top'
      }, {
        x: '0%',
        rotation: 0,
        overwrite: false,
        onComplete: () => {
          const overtakingPrevCarOneContainer = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-one');

          if (overtakingPrevCarOneContainer !== null) {
            overtakingPrevCarOneContainer.removeAttribute('style');
          }
        }
      });
    }

    if (overtakingPrevCarTwo !== null) {
      new TimelineMax().fromTo(overtakingPrevCarTwo, (animationDuration * 2), {
        x: '-50%',
        rotation: 4,
        transformOrigin: 'left top'
      }, {
        x: '0%',
        rotation: 0,
        overwrite: false,
        onComplete: () => {
          const overtakingPrevCarTwoContainer = document.querySelector('#summer-race-track-cars-target #summer-race-track-car-prev-two');

          if (overtakingPrevCarTwoContainer !== null) {
            overtakingPrevCarTwoContainer.removeAttribute('style');
          }
        }
      });
    }
  }

  /**
   * Toggles the ranking.
   */
  toggleRanking(): void {
    this.showRanking = !this.showRanking;
  }

  /**
   * Closes the collect more modal.
   */
  private _closeCollectMore(): void {
    this.showCollectMore = false;
    this.showReset = true;
  }

  /**
   * Saves the racer data.
   */
  private _saveRacer(): void {
    this._racer['LetzterPunktestand'] = this._positionNew['Punkte'];
    this._racer['AktionenKey'] = this._campaignKey;

    this._saveRacerSubscription = this._apiService.postRequest('/api/Gamification/UpdateRennfahrer', this._racer).subscribe((data: any) => {
      if (data.Result !== 'OK') {
        this._mpMessaging.openDangerPanel(data.Message);
      }
    });
  }

  /**
   * Starts the race.
   */
  startRace(): void {
    this._animateOvertaking();
    this._saveRacer();
  }

  /**
   * Resets the position.
   */
  resetPosition(): void {
    this.showReset = false;
    this._positionNew['Punkte'] = this._backupLastPoints;
    this._saveRacer();
    this._router.navigateByUrl(`/${this._role}/RacingBoxengasse?key=${this._campaignKey}`);
  }

  private _rotationInfo(elem: HTMLElement): { [key: string]: any } {
    let info = { rad: 0, deg: 0 };

    let tr: any = window.getComputedStyle(elem).getPropertyValue('-webkit-transform') ||
      window.getComputedStyle(elem).getPropertyValue('-moz-transform') ||
      window.getComputedStyle(elem).getPropertyValue('-ms-transform') ||
      window.getComputedStyle(elem).getPropertyValue('-o-transform') ||
      '';

    if (tr = tr.match('matrix\\((.*)\\)')) {
      tr = tr[1].split(',');

      if (typeof tr[0] != 'undefined' && typeof tr[1] != 'undefined') {
        info.rad = Math.atan2(tr[1], tr[0]);
        info.deg = parseFloat((info.rad * 180 / Math.PI).toFixed(1));
      }
    }

    return info;
  }

}
