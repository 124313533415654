import { Component, OnInit, Input } from '@angular/core';

/**
 * This class provides an error string under
 * a form element, if an error occurs.
 */
@Component({
  selector: 'mp-core-form-errorstring',
  templateUrl: './form-errorstring.component.html',
  styleUrls: ['./form-errorstring.component.scss']
})
export class FormErrorstringComponent implements OnInit {
  @Input() public errstring: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
