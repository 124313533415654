import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { ApiService } from '@core/services/api.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the ranking light
 * play page.
 */
@Component({
  selector: 'mp-gfc-ranking-light-play',
  templateUrl: './ranking-light-play.component.html'
})
export class RankingLightPlayComponent implements OnInit, OnDestroy {

  public showNewRanking: boolean = false;
  public currentAccess = moment().format('DD.MM.YYYY');
  public lastAccess: string = 'aktuell'; // default
  public play: { [key: string]: any } = {};

  private _playName: string = '';
  private _gamificationRankingLightPlayNewRankingLoader: MpLoader = {
    name: 'gamificationRankingLightPlayNewRankingLoader',
    params: {}
  };
  private _gamificationRankingLightPlayOldRankingLoader: MpLoader = {
    name: 'gamificationRankingLightPlayOldRankingLoader',
    params: {}
  };
  private _getQueryParamsSubscription: Subscription | undefined;
  private _getCurrentPlaySubscription: Subscription | undefined;
  private _getLastAccessSubscription: Subscription | undefined;
  private _saveRankingSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _apiService: ApiService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) { }

  /**
   * Gets the query params, sets the
   * loaders for the tables, sets the
   * breadcrumb and gets the play data.
   */
  ngOnInit(): void {
    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._playName = params['Name'] || params['name'];

      this._mpLoaderService.registerLoader(this._gamificationRankingLightPlayNewRankingLoader);

      this._mpLoaderService.extendLoader('gamificationRankingLightPlayNewRankingLoader', {
        params: {
          PlayName: this._playName
        },
        isReady: true
      });

      this._mpLoaderService.registerLoader(this._gamificationRankingLightPlayOldRankingLoader);

      this._mpLoaderService.extendLoader('gamificationRankingLightPlayOldRankingLoader', {
        params: {
          PlayName: this._playName
        },
        isReady: true
      });

      this._getCurrentPlaySubscription = this._apiService.getRequest('/api/Gamification/GetCurrentPlay?PlayTypName=RankingLight').subscribe((data: any) => {
        if (data.Result === "OK") {
          this.play = data.Records[0];

          if (this.play !== null) {
            this._getLastAccess();

            this._mpBreadcrumbService.setCurrentSite(
              {
                Path: 'RankingLightPlay',
                Loc: this.play['Titel']
              });
          }
        }
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._getCurrentPlaySubscription !== 'undefined') {
      this._getCurrentPlaySubscription.unsubscribe();
    }

    if (typeof this._getLastAccessSubscription !== 'undefined') {
      this._getLastAccessSubscription.unsubscribe();
    }

    if (typeof this._saveRankingSubscription !== 'undefined') {
      this._saveRankingSubscription.unsubscribe();
    }
  }

  /**
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this._sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Gets the last access data.
   */
  private _getLastAccess(): void {
    this._getLastAccessSubscription = this._apiService.getRequest(`/api/Gamification/GetLastAccess?playName=${this._playName}`).subscribe((data: any) => {
      if (data.Result === "OK") {
        this.lastAccess = moment(data.Records[0]).format('DD.MM.YYYY');
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Saves the ranking and shows the
   * new ranking.
   */
  refresh(): void {
    this.showNewRanking = true;
    const saveParams = this._mpLoaderService.getLoader('gamificationRankingLightPlayOldRankingLoader').params;

    this._saveRankingSubscription = this._apiService.postRequest('/api/Gamification/SaveTnRang', saveParams).subscribe((data: any) => {},
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

}
