<div class="card" id="help-sidebar" *ngIf="(ls.locsLoaded | async)">
  <div class="card-inner">
    <div id="overlay-headline">{{params.title}}</div>
    <div class="divider margin-top-bottom-0"></div>
    <div class="padding-left-16 padding-right-16 padding-top-16 padding-bottom-16 sidebar-grid">
      <mp-core-search mpPlaceholder="{{ ls.locs['loc'].HelpSidebarSuche }}"
                mpId="help-sidebar-suche"
                [(model)]="searchTerm"
                class="sb-search">
        <i class="position-absolute-top-right svg-icon __clr-default __size-24 margin-top-4 margin-right-4 __search"></i>
      </mp-core-search>
      <mp-core-scroll-button id="overlay-scroll-up"
                     [ifNeeded]="true"
                     scrollItemHeight="80"
                     scrollArea="#help-sidebar-scroll-accordion"
                     [scopeObject]="filtered"
                     scrollDirection="up"
                     scrollFactor="1"
                     class="scrollbutton-up inactive-scroll-button">
        <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
      </mp-core-scroll-button>
      <div class="divider margin-top-bottom-12"></div>
      <div class="accordion scroll-area-container"
           id="help-sidebar-scroll-accordion-container">
        <div [hidden]="helpCount !== 0 || searchTerm === ''">{{ ls.locs['loc'].KeineErgebnisse }}</div>
        <mp-core-accordion class="sidebar-scroll-area position-relative"
                   id="help-sidebar-scroll-accordion"
                   [accToggle]="true"
                   [accDataExpression]="filteredHelpFunc"
                   [sidebarAcc]="true"
                   dataScrollArea="#help-sidebar-scroll-accordion"
                   [dataScrollItemHeight]="80">
        </mp-core-accordion>
      </div>
      <mp-core-scroll-button id="overlay-scroll-down"
                     [ifNeeded]="true"
                     scrollItemHeight="80"
                     scrollArea="#help-sidebar-scroll-accordion"
                     [scopeObject]="filtered"
                     scrollDirection="down"
                     scrollFactor="1"
                     class="scrollbutton-down margin-top-16">
        <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
      </mp-core-scroll-button>
    </div>
  </div>
</div>
