<div class="layout-container">
  <h1>Willkommen im Adminbereich!</h1>
  <div class="form-group">
    <div>
      <mp-core-search [(model)]="participant"
                      mpId="search-participant-by-participantrname"
                      [action]="searchParticipantByNameFunc"
                      [mpPlaceholder]="ls.locs['loc'].Suchen"
                      class="fallback-input">
        <i class="position-absolute-top-right svg-icon __clr-default __size-24 __top-8 margin-right-12 __search"></i>
      </mp-core-search>
    </div>
  </div>
</div>
