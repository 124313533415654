import { Directive, HostListener, ElementRef } from '@angular/core';

/**
 * This class changes the dimenstions
 * of an iframe, when its content
 * is loaded.
 */
@Directive({
  selector: '[mpCoreIframeSetDimensionsOnLoad]'
})
export class IframeSetDimensionsOnLoadDirective {

  constructor(
    private _elementRef: ElementRef<HTMLIFrameElement>
  ) { }

  /**
   * Changes the dimensions.
   */
  @HostListener('load')
  onLoad() {
    const iframe = this._elementRef.nativeElement;
    if (iframe.contentWindow !== null) {
      const iFrameHeight = iframe.contentWindow.document.body.scrollHeight + 30 + 'px';
      const iFrameWidth = '100%';
      iframe.style.width = iFrameWidth;
      iframe.style.height = iFrameHeight;
    }
  }

}
