import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './../../services/api.service';

/**
 * This class provides functions for
 * the deleted messages in inbox.
 */
@Injectable({
  providedIn: 'root'
})
export class InboxDeletedService {

  private _deleted: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public deleted: Observable<number> = this._deleted.asObservable();

  private _getDeletedSubscription: Subscription | undefined;

  constructor(
    private _apiService: ApiService
  ) { }

  /**
   * Gets the deleted messages of the
   * inbox.
   */
  getUnread(): void {
    this._getDeletedSubscription = this._apiService.getRequest('/api/Postfach/GetDeleted').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._deleted.next(data.Records[0]);

        if (typeof this._getDeletedSubscription !== 'undefined') {
          this._getDeletedSubscription.unsubscribe();
        }
      }
    });
  }
}
