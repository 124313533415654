<div id="sidebar-inbox"
     *ngIf="(ls.locsLoaded | async)"
     class="sidebar-form">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="text-align-left">
    </div>
    <div class="divider margin-bottom-8"></div>
    <div class="overflow-hidden">
      <mp-core-search mpId="postfach-sidebar-suche"
                      [(model)]="sidebarData['searchTerm']"
                      (keyup)="sidebarSearchFunction(true)"
                      class="display-block sb-search padding-left-right-16 padding-top-bottom-12"
                      [mpPlaceholder]="ls.locs['loc'].PostfachSuchePlaceholder">
        <i class="position-absolute-top-right svg-icon __clr-default __wide __top-0 __search akf-sidebar-search-icon"></i>
        <i *ngIf="sidebarData['searchTerm'].length > 2"
           class="svg-icon __clr-default __size-18 __top-8 __clear position-absolute-top-right"
           (click)="clearSearch($event)">
        </i>
      </mp-core-search>
    </div>
    <div>
      <mp-core-scroll-button id="overlay-scroll-up"
                     [ifNeeded]="true"
                     scrollItemHeight="48"
                     scrollArea="#postfach-scroll-area"
                     scrollDirection="up"
                     scrollFactor="2"
                     class="scrollbutton-up inactive-scroll-button margin-top-16">
        <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
      </mp-core-scroll-button>
      <div class="padding-left-16 padding-right-16 scroll-area-container"
           id="postfach-scroll-area-container">

        <div id="postfach-scroll-area"
             class="sidebar-scroll-area position-relative">
          <div class="menu-item" [ngClass]="{active: sidebarData['active'] === 'all'}">
            <a (click)="sidebarFilterFunction($event)">
              <div class="display-flex">
                <i class="svg-icon __wide __wide-18 __inbox" [ngClass]="sidebarData['active'] === 'all' ? '__clr-primary' : '__clr-default'"></i>
                <div class="icon_nav-text menu-item-text display-inline-flex flex-align-items-center">{{ ls.locs['loc'].AlleNachrichten }}</div>
              </div>
            </a>
          </div>
          <div class="menu-item" *ngFor="let cat of sidebarCategories" [ngClass]="{active: sidebarData['active'] === cat.class}">
            <a (click)="sidebarFilterFunction($event, cat)">
              <div class="display-flex">
                <i class="svg-icon __wide __wide-18 {{cat.class}}" [ngClass]="sidebarData['active'] === cat.class ? '__clr-primary' : '__clr-default'"></i>
                <div class="icon_nav-text menu-item-text display-inline-flex flex-align-items-center">{{cat.text}}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <mp-core-scroll-button id="overlay-scroll-down"
                     [ifNeeded]="true"
                     scrollItemHeight="48"
                     scrollArea="#postfach-scroll-area"
                     scrollDirection="down"
                     scrollFactor="2"
                     class="scrollbutton-down inactive-scroll-button margin-top-16">
        <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
      </mp-core-scroll-button>
    </div>
  </div>
</div>
