import { Component, OnInit, Injector } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { InboxService } from './../inbox.service';

/**
 * This class provides functions and data
 * for the details of a stage in
 * the inbox.
 */
@Component({
  selector: 'mp-core-inbox-details-stage',
  templateUrl: './inbox-details-stage.component.html'
})
export class InboxDetailsStageComponent implements OnInit {

  public loading: boolean = true;
  public item: any;

  constructor(
    public ls: MpLocalizationService,
    private _inboxService: InboxService,
    private _injector: Injector
  ) { }

  /**
   * Gets the injected inbox item.
   */
  ngOnInit(): void {
    this.item = this._injector.get('iboxItem');
    this.loading = false;
  }

  /**
   * Triggers the on click function of
   * the parent component.
   */
  onClick(evt: MouseEvent, item: any): void {
    evt.preventDefault();

    this._inboxService.triggerParentClickFunction(item, 'onClick');
  }

}
