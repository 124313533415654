import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpStatusService } from './../../../../services/mp-status.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { ParticipantCockpitService } from './participant-cockpit.service';
import { ApiService } from './../../../../services/api.service';

/**
 * This class provides the data and the
 * functions for the participant cockpit.
 */
@Component({
  selector: 'mp-core-participant-cockpit',
  templateUrl: './participant-cockpit.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ParticipantCockpitComponent implements OnInit, OnDestroy {

  public tabVisible: string = 'Stammdaten';
  public cockpitData: { [key: string]: any } = {
    tnId: -1,
    reloadCounter: 0
  };
  public hasStatus: boolean = false;
  public isAdm: boolean = false;
  public isAdmin: boolean = false;
  public isManager: boolean = false;
  public canCurrentUserEdit: boolean = false;

  public getRouteParamsSubscription: Subscription | undefined;
  public getStatusSubscription: Subscription | undefined;
  public getPermissionsSubscription: Subscription | undefined;
  public getCockpitDataSubscription: Subscription | undefined;
  public cockpitDataChangeSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public mpStatus: MpStatusService,
    public mpPermissions: MpPermissionsService,
    public participantCockpitService: ParticipantCockpitService,
    public route: ActivatedRoute,
    public apiService: ApiService
  ) { }

  /**
   * Gets the status, the permissions
   * and the query params.
   */
  ngOnInit(): void {
    if (typeof this.mpStatus.currentHasStatus === 'undefined') {
      this.getStatusSubscription = this.mpStatus.status.subscribe((hasStatus: boolean) => {
        this.hasStatus = hasStatus;

        if (typeof this.getStatusSubscription !== 'undefined') {
          this.getStatusSubscription.unsubscribe();
        }
      });

      this.mpStatus.hasStatus();
    } else {
      this.hasStatus = this.mpStatus.currentHasStatus;

      if (typeof this.getStatusSubscription !== 'undefined') {
        this.getStatusSubscription.unsubscribe();
      }
    }

    this.getRouteParamsSubscription = this.route.paramMap.subscribe((params: any) => {
      if (typeof params.params['TnID'] !== 'undefined') {
        this.cockpitData['tnId'] = params.params['TnID'];
      }

      if (this.cockpitData['tnId'] == -1)
        return;

      this.loadCockpitData();
    });

    this.getPermissionsSubscription = this.mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.isAdm = this.mpPermissions.permissions.IsAdm || false;
        this.isAdmin = this.mpPermissions.permissions.IsAdmin;
        this.isManager = this.mpPermissions.permissions.IsManager;
        this.canCurrentUserEdit = this.mpPermissions.permissions.CanCurrentUserEdit;
      }
    });

    this.mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.getRouteParamsSubscription !== 'undefined') {
      this.getRouteParamsSubscription.unsubscribe();
    }

    if (typeof this.getStatusSubscription !== 'undefined') {
      this.getStatusSubscription.unsubscribe();
    }

    if (typeof this.getPermissionsSubscription !== 'undefined') {
      this.getPermissionsSubscription.unsubscribe();
    }

    if (typeof this.getCockpitDataSubscription !== 'undefined') {
      this.getCockpitDataSubscription.unsubscribe();
    }

    if (typeof this.cockpitDataChangeSubscription !== 'undefined') {
      this.cockpitDataChangeSubscription.unsubscribe();
    }

    this.participantCockpitService.resetCockpitData();
  }

  /**
   * Loads the cockpit data.
   */
  public loadCockpitData(): void {
    this.getCockpitDataSubscription = this.apiService.getRequest(`/api/Teilnehmer/getCockpitData/${this.cockpitData['tnId']}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.cockpitData = data.Records[0];
        this.participantCockpitService.registerData(this.cockpitData);

        if (typeof this.cockpitDataChangeSubscription === 'undefined') {
          this.cockpitDataChangeSubscription = this.participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
            if (this.cockpitData['reloadCounter'] !== data['reloadCounter']) {
              this.loadCockpitData();
            }
          });
        }
      }
    });
  }

  /**
   * Closes the participant cockpit.
   */
  close(): void {
    window.history.back();
  }

  /**
   * Changes the active tab.
   */
  setTab(tab: string): void {
    this.tabVisible = tab;
  }

}
