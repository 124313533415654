<div id="sticky-element-width" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div [hidden]="!hotel['showHeader']" class="rk-offers-headline" stickyOnScroll data-stick-element="navbar" id="rk-sticky-headlines">
    <h2 *ngIf="(!grouping || !grouping['GruppierFeld']) && !(searchParams['reisezieleHotel'] && searchParams['reisezieleHotel'].ziele && searchParams['reisezieleHotel'].ziele.length > 0) && selectedCategory"><span>{{(filteredHotels.length === 1 ? ls.locs['locReisekonfigurator'].AngebotWeltweitKategorie : ls.locs['locReisekonfigurator'].AngeboteWeltweitKategorie).replace('{0}', filteredHotels.length).replace('{1}', selectedCategory['ErgebnisseInText'])}}</span></h2>
    <h2 *ngIf="(!grouping || !grouping['GruppierFeld']) && !(searchParams['reisezieleHotel'] && searchParams['reisezieleHotel'].ziele && searchParams['reisezieleHotel'].ziele.length > 0) && !selectedCategory"><span>{{(filteredHotels.length === 1 ? ls.locs['locReisekonfigurator'].AngebotWeltweit : ls.locs['locReisekonfigurator'].AngeboteWeltweit).replace('{0}', filteredHotels.length)}}</span></h2>
    <h2 *ngIf="(!grouping || !grouping['GruppierFeld']) && (searchParams['reisezieleHotel'] && searchParams['reisezieleHotel'].ziele && searchParams['reisezieleHotel'].ziele.length > 0) && selectedCategory"><span>{{(filteredHotels.length === 1 ? ls.locs['locReisekonfigurator'].AngebotInKategorie : ls.locs['locReisekonfigurator'].AngeboteInKategorie).replace('{0}', filteredHotels.length).replace('{1}', selectedCategory['ErgebnisseInText']).replace('{2}', searchParams['reisezielTextHotel'])}}</span></h2>
    <h2 *ngIf="(!grouping || !grouping['GruppierFeld']) && (searchParams['reisezieleHotel'] && searchParams['reisezieleHotel'].ziele && searchParams['reisezieleHotel'].ziele.length > 0) && !selectedCategory"><span>{{(filteredHotels.length === 1 ? ls.locs['locReisekonfigurator'].AngebotIn : ls.locs['locReisekonfigurator'].AngeboteIn).replace('{0}', filteredHotels.length).replace('{1}', searchParams['reisezielTextHotel'])}}</span></h2>
    <h2 *ngIf="grouping['GruppierFeld'] === 'Zielgebiet'"><span>{{(hotel['groupCount'] === 1 ? ls.locs['locReisekonfigurator'].AngebotIn : ls.locs['locReisekonfigurator'].AngeboteIn).replace('{0}', hotel['groupCount']).replace('{1}', hotel['Zielgebiet'])}}</span></h2>
    <h2 *ngIf="grouping['GruppierFeld'] === 'Stadt'"><span>{{(hotel['groupCount'] === 1 ? ls.locs['locReisekonfigurator'].AngebotIn : ls.locs['locReisekonfigurator'].AngeboteIn).replace('{0}', hotel['groupCount']).replace('{1}', hotel['Stadt'])}}</span></h2>
    <h2 *ngIf="grouping['GruppierFeld'] === 'IsInWunschliste'"><span>{{(hotel['groupCount'] === 1 ? ls.locs['locReisekonfigurator'].AngebotIn : ls.locs['locReisekonfigurator'].AngeboteIn).replace('{0}', hotel['groupCount']).replace('{1}', hotel['Stadt'])}}</span></h2>
  </div>
  <article class="margin-top-8 rk-offers-section" id="hotelDetails{{hotel['HotelCode']}}" [ngClass]="{detailsOpen: hotel['open'] === true}" [hidden]="hotel['hidden']">
    <div class="rk-offer-imgs overflow-hidden" *ngIf="hotel['Bilder'] && hotel['Bilder'].length !== 0">
      <owl-carousel-o id="home-carousel" class="owl-theme" [options]="{items: 1, loop: hotel['Bilder'].length > 1, nav: hotel['Bilder'].length > 1, dots: false, navText: ['', ''], lazyLoad: true}">
        <ng-container class="item"
                      *ngFor="let bild of hotel['Bilder']">
          <ng-template carouselSlide>
            <div class="item">
              <div class="blur">
                <img class="owl-lazy" data-src="{{bild}}" />
              </div>
              <div class="item-img">
                <img class="position-relative owl-lazy" data-src="{{bild}}" />
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <div class="rk-offer-summary padding-16">
      <div class="rk-offer-breadcrumb text-color-grey-info">
        <div class="display-inline">
          <span class="icon-text font-size-s">{{hotel['Land']}}</span>
        </div>
        <div class="display-inline">
          <i class="svg-icon __clr-default __size-14 __top-3 __nav-next margin-left-2 margin-right-2 float-none-important default-cursor-hover"></i>
          <span class="icon-text font-size-s">{{hotel['Zielgebiet']}}</span>
        </div>
        <div class="display-inline">
          <i class="svg-icon __clr-default __size-14 __top-3 __nav-next margin-left-2 margin-right-2 float-none-important default-cursor-hover"></i>
          <span class="icon-text font-size-s">{{hotel['Stadt']}}</span>
        </div>
      </div>
      <div class="rk-offer-title padding-top-8">
        <h3 class="margin-bottom-0">{{hotel['Name']}}</h3>
      </div>
      <div class="rk-offer-stars">
        <span [ngClass]="'sterne' + hotel['Kategorie']"></span>
        <span class="font-size-s">{{ ls.locs['locReisekonfigurator'].Hotel }}</span>
      </div>
      <div class="rk-offer-categories">
        <mp-core-popover *ngFor="let kat of hotel['Themen']" content="{{kat.Bezeichnung}}" alignment="left" class="margin-right-8">
      <span [ngSwitch]="kat.CssClass">
        <i *ngSwitchCase="'winter'" class="svg-icon __clr-info __size-22 __top-2 __snow margin-right-4"></i>
        <i *ngSwitchCase="'familie'" class="svg-icon __clr-info __size-22 __top-2 __family margin-right-4"></i>
        <i *ngSwitchCase="'kreuzfahrt'" class="svg-icon __clr-info __size-22 __top-2 __ship margin-right-4"></i>
        <i *ngSwitchCase="'wellness'" class="svg-icon __clr-info __size-22 __top-2 __spa margin-right-4"></i>
        <i *ngSwitchCase="'luxus'" class="svg-icon __clr-info __size-22 __top-2 __diamond margin-right-4"></i>
        <i *ngSwitchCase="'sport'" class="svg-icon __clr-info __size-22 __top-2 __sport margin-right-4"></i>
        <i *ngSwitchCase="'hochzeit'" class="svg-icon __clr-info __size-22 __top-2 __rings margin-right-4"></i>
        <i *ngSwitchCase="'stadt'" class="svg-icon __clr-info __size-22 __top-2 __city margin-right-4"></i>
        <i *ngSwitchCase="'rundreise'" class="svg-icon __clr-info __size-22 __top-2 __rotate-right margin-right-4"></i>
        <i *ngSwitchCase="'weihnachten'" class="svg-icon __clr-info __size-22 __top-2 __flare margin-right-4"></i>
        <i *ngSwitchCase="'club'" class="svg-icon __clr-info __size-22 __top-2 __cocktail margin-right-4"></i>
        <i *ngSwitchCase="'strand'" class="svg-icon __clr-info __size-22 __top-2 __beach margin-right-4"></i>
        <i *ngSwitchCase="'adult'" class="svg-icon __clr-info __size-22 __top-2 __people margin-right-4"></i>
        <i *ngSwitchCase="'haustiere'" class="svg-icon __clr-info __size-22 __top-2 __pets margin-right-4"></i>
        <i *ngSwitchCase="'wlan'" class="svg-icon __clr-info __size-22 __top-2 __wlan margin-right-4"></i>
      </span>
    </mp-core-popover>
      </div>
      <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].HideZuzahlungHinweis" class="rk-offer-zuzahlung-hinweis">
        <i class="svg-icon __clr-inactive __size-16 __top-5 __info margin-right-4 default-cursor-hover" [hidden]="!hotel['CanZuzahlen']"></i>
        <span class="font-size-xs" [hidden]="!hotel['CanZuzahlen']">{{ ls.locs['locRkZuzahlung'].ZuzahlungMoeglich }}</span>
      </div>
      <div *ngIf="mpSettings.settings['RkZuzahlungSettings'].HideZuzahlungHinweis"></div>
      <div class="rk-offer-price text-color-info">
        <span class="text-transform-uppercase font-weight-light">{{ ls.locs['loc'].ab }}&nbsp;</span>
        <span class="font-size-xl">{{hotel['Punkte'] | integer}}</span>
        <span class="font-size-xl">{{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div *ngIf="!permissions.CanUseReisekonfigurator"><span class="font-size-xs">{{ ls.locs['locReisekonfigurator'].RkNichtErlaubt }}</span></div>
      <div *ngIf="permissions.CanUseReisekonfigurator" class="rk-offer-details-btn">
        <button [ngClass]="hotel['open'] ? 'detailsOpen _grey' : 'detailsClosed _prim'" (click)="hotel['open'] = !hotel['open']; setOpenedOffers();" class="mp-button">
          {{hotel['open'] ? ls.locs['locReisekonfigurator'].DetailsAusblenden : ls.locs['locReisekonfigurator'].DetailsAnsehen}}
        </button>
      </div>
    </div>
  </article>
  <mp-rk-hotel-details [getParamsForGetHotels]="getParamsForGetHotels" [(filterOptionsMaxPrice)]="filterOptionsMaxPrice" [hotel]="hotel" [show]="hotel['open']" [params]="searchParams" [travelType]="travelType" [filter]="filter" [sorting]="sorting" class="offer-details bg-color-white display-block margin-top-8"></mp-rk-hotel-details>
</div>
