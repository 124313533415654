import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from "d3";

import { MpD3StylesService } from './../mp-d3-styles.service';
import { MpDebounceService } from './../../../services/mp-debounce.service';
import { ApiService } from './../../../services/api.service';
import { MpLoaderService } from './../../../services/mp-loader.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';


import { MpLoader } from './../../../services/interfaces/mp-loader';

/**
 * This class provides a d3 pie chart.
 */
@Component({
  selector: 'mp-core-d3-pie-chart',
  templateUrl: './d3-pie-chart.component.html',
  styleUrls: ['./d3-pie-chart.component.scss']
})
export class D3PieChartComponent implements OnInit {

  @Input() public mpid: string = '';
  @Input() public width: number = 600;
  @Input() public height: number = 400;
  @Input() public dataurl: string = '';
  @Input() public loader: string | undefined;
  @Input() public isDonut: boolean = false;

  private _fillcolorRange: Array<string> = [];
  private _linecolor: string = '';

  constructor(
    private _mpD3StylesService: MpD3StylesService,
    private _mpDebounce: MpDebounceService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService,
    private _chartElem: ElementRef,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Loads the colors and the data
   * for the chart.
   */
  ngOnInit(): void {
    this._fillcolorRange = this._mpD3StylesService.mpD3Styles().d3PieChartStyles.fillcolorRange;
    this._linecolor = this._mpD3StylesService.mpD3Styles().d3PieChartStyles.linecolor;

    let parentLoader: MpLoader;

    const load = this._mpDebounce.debounce(() => {
      const p = (typeof (this.loader) === 'undefined' ? {} : parentLoader.params);

      this._apiService.postRequest(this.dataurl, p).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._drawChart(data.Records);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
      });
    }, 200);

    if (typeof this.loader !== 'undefined') {
      if (this._mpLoaderService.loaderExists(this.loader)) {
        parentLoader = this._mpLoaderService.getLoader(this.loader);

        this._mpLoaderService.extendLoader(parentLoader.name, {
          load: load
        });
      }
    }

    load();
  }

  /**
   * Draws the d3 chart.
   */
  private _drawChart(inputData: any) {
    if (typeof inputData === 'undefined' || typeof inputData !== 'undefined' && typeof inputData[0] === 'undefined')
      return;

    inputData.map(
      (d: any) => {
        if (d.xDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.xDate);
        if (d.yDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.yDate);
      });

    const widthChart = this.width;
    const heightChart = this.height;
    const radius = Math.min(widthChart, heightChart) / 2;

    const xData = (d: any) => { return d.xDate ? d.xDate : d.xInt ? d.xInt : d.xDecimal ? d.xDecimal : d.xString ? d.xString : ''; };
    const yData = (d: any) => { return d.yDate ? d.yDate : d.yInt ? d.yInt : d.yDecimal ? d.yDecimal : d.yString ? d.yString : 0; };
    const colorGroup = (d: any) => { return d.xString; };

    const colorGroupNames: Array<any> = [];

    inputData.forEach((d: any) => {
      var g = colorGroup(d);
      if (colorGroupNames.indexOf(g) < 0) colorGroupNames.push(g);

      d.tooltip = typeof d.tooltip === 'undefined' || d.tooltip === null ? yData(d) : d.tooltip;
    });

    d3.select('#' + this.mpid)
      .selectAll('*')
      .remove();

    const svg = d3.select('#' + this.mpid)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewBox', '0, 0, ' + this.width + ', ' + this.height)
      .append('g');

    const tooltip = d3.select('#' + this.mpid)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '8px')
      .style('padding', '5px');

    const mouseover = (d: any) => {
      tooltip
        .style('opacity', 0.9);
    };

    const mousemove = (evt: any, d: any) => {
      tooltip
        .html(d.data.value.tooltip);
    };

    const mouseleave = (d: any) => {
      tooltip
        .style('opacity', 0);
    };

    svg.append('g').attr('class', 'slices');
    svg.append('g').attr('class', 'labels');
    svg.append('g').attr('class', 'lines');
    svg.attr('transform', 'translate(' + widthChart / 2 + "," + heightChart / 2 + ')');

    const arc = d3.arc()
      .outerRadius(radius)
      .innerRadius(radius * (this.isDonut ? .4 : 0));

    const outerArc = d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    const color = d3.scaleOrdinal()
      .domain(inputData.map(xData))
      .range(this._fillcolorRange);

    const pie = d3.pie()
      .value((d: any) => { return yData(d[1]); });

    // @ts-ignore
    const data_ready = pie(Object.entries(inputData));

    const slices = svg.select('.slices').selectAll('path.slice')
      .data(data_ready)
      .enter()
      .append('path')
      // @ts-ignore
      .attr('d', arc)
      .attr('class', 'slice')
      // @ts-ignore
      .style('fill', (d: any) => { return color(xData(d.data[1])); })
      .style("stroke-width", "2px")
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    slices
      .transition().duration(1000)
      .attrTween('d',
        // @ts-ignore
        function (this: void, d: any) {
            // @ts-ignore
          this._current = this._current || d;
          // @ts-ignore
          var interpolate = d3.interpolate(this._current, d);
          // @ts-ignore
          this._current = interpolate(0);
          return (t: any) => { return arc(interpolate(t)); };
        });

    slices.exit()
      .remove();

    if (colorGroupNames.length > 1) {
      const legend = this._chartElem.nativeElement.parentElement.querySelector('.chart-legend');

      while (legend.hasChildNodes()) {
        // @ts-ignore
        legend.removeChild(legend.firstChild);
      }

      if (colorGroupNames.length > 1) {
        colorGroupNames.forEach((colorName: any, index: number) => {
          const legendElem = document.createElement('div');
          const legendDotElem = document.createElement('span');
          const legendTextElem = document.createElement('span');

          // @ts-ignore
          legendDotElem.style.backgroundColor = color(colorName);
          legendTextElem.innerHTML = colorName;
          legendElem.appendChild(legendDotElem);
          legendElem.appendChild(legendTextElem);

          legend.appendChild(legendElem);
        });
      }
    }
  }

}
