import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from '@core/services/mp-permissions.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { ApiService } from '@core/services/api.service';
import { ParticipantCockpitService } from '@core/modules/manager/pages/participant-cockpit/participant-cockpit.service';
import { MpSettingsService } from '@core/services/mp-settings.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the participant ranking 
 * table.
 */
@Component({
  selector: 'mp-core-tax-reporting',
  templateUrl: './tax-reporting.component.html'
})
export class TaxReportingComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Statistik/VersteuerungsgrenzeReporting2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    }
  ];
  public yearList: Array<number> = [];
  public year: number = new Date().getFullYear();

  public changeYearAndReload = this._changeYearAndReload.bind(this);

  private _taxReportingLoader: MpLoader = {
    name: 'taxReportingLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _participantCockpitService: ParticipantCockpitService,
    public mpSettings: MpSettingsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('VersteuerungsgrenzeReporting');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._taxReportingLoader);

    this._mpLoaderService.extendLoader('taxReportingLoader', {
      isReady: true,
      params: {
        Jahr: this.year
      }
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();

    this.yearList = Array.from({ length: this.year - 2009 }, (v, k) => k + 2010); // get array of years since 2010
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('taxReportingLoader');
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['ID']);
  }

  private _changeYearAndReload(): void {
    if (this.loader?.params) {
      this.loader.params.Jahr = this.year;
      this.loader.load?.();
    }
  }

}
