import { Component, OnInit, ViewEncapsulation, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';

/**
 * This class provides the data and
 * functions for the theme stage preview.
 */
@Component({
  selector: 'mp-core-theme-stage-preview',
  templateUrl: './theme-stage-preview.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ThemeStagePreviewComponent implements OnInit {

  public themeStage: Array<any> = [];
  public showDetails: boolean = false;

  constructor(
    public mpSettings: MpSettingsService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
    private _sanitizer: DomSanitizer
  ) { }

  /**
   * Sets the title and the menu higlight.
   * Gets the data for the theme stage from
   * the localStorage.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('ThemenbuehnenVorschau', 'loc');
    this._mpMenuHighlight.setMenuHighlight('Themenbuehnen');

    const themeStageData = localStorage.getItem('themenbuehne');

    if (themeStageData !== null) {
      this.themeStage.push(JSON.parse(themeStageData));
    }
  }

  /**
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this._sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Toggles the details of the
   * theme stage.
   */
  toggleShowDetails(): void {
    this.showDetails = !this.showDetails;
  }

}
