<div class="manager-dashboard margin-bottom-16">
  <h2 [hidden]="title === ''" [innerText]="title"></h2>
  <ng-content></ng-content>
  <div class="row display-flex flex-wrap">
    <div class="grid col-small-12 col-medium-6 col-xlarge-4"
         *ngFor="let src of sources">
      <mp-core-manager-dashboard-card class="padding-bottom-16 box-sizing-border-box height-full" src="{{src}}"></mp-core-manager-dashboard-card>
    </div>
  </div>
</div>
