import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../../services/mp-loader.service';

import { MpLoader } from './../../../../../services/interfaces/mp-loader';
import { ParentTableRow, ParentTableRowIndex } from './../../../../../components/data-table/table-injectors';

/**
 * This class provides the data for the
 * orders table child.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-orders-child',
  templateUrl: './ptcpnt-cockpit-orders-child.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitOrdersChildComponent implements OnInit {

  public loaderName = '';

  private _ptcpntCockpitOrdersChildLoader: MpLoader = {
    name: 'ptcpntCockpitOrdersChildLoader',
    isFirst: false,
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  /**
   * Sets the loader for the table data,
   * and gets the key from the parent data
   * table.
   */
  ngOnInit(): void {
    this._ptcpntCockpitOrdersChildLoader.name = `${this._ptcpntCockpitOrdersChildLoader.name}--${this._parentTableRowIndex}`;
    this._mpLoaderService.registerLoader(this._ptcpntCockpitOrdersChildLoader);
    let params = {};

    if (this._parentTableRow !== null) {
      params = {
        // @ts-ignore
        BestellNr: this._parentTableRow['BestellNr']
      };
    }

    this._mpLoaderService.extendLoader(this._ptcpntCockpitOrdersChildLoader.name, {
      isReady: true,
      params: params
    });

    this.loaderName = this._ptcpntCockpitOrdersChildLoader.name;
    const loader = this._mpLoaderService.getLoader(this._ptcpntCockpitOrdersChildLoader.name);

    setTimeout(() => {
      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    }, 400);
  }

}
