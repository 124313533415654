import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

/**
 * This class gets enum data from the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class MpEnumsService {

  private _enumsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public enums: { [key: string]: any } = {};
  public enumsLoaded: Observable<boolean> = this._enumsLoaded.asObservable();

  constructor(private _apiService: ApiService) { }

  /**
   * Gets the enums from the backend.
   */
  getEnums(): void {
    this._apiService.getRequest('/api/Enum/GetEnums').subscribe((data: any) => {
      this.enums = data.Records[0];
      this._enumsLoaded.next(true);
    },
    (error: any) => {
      console.log(error.message);
    });
  }
}
