<div express-edit *ngIf="(ls.locsLoaded | async) && item !== null">
  <div id="overlay-headline">{{ ls.locs['loc'].ExpressNichtMoeglich }}</div>
  <div class="divider margin-top-bottom-0"></div>
  <div class="padding-left-16 padding-right-16 padding-top-16 padding-bottom-64">
    <mp-core-info-notification iconPosition="left" infoState="warning" copyText="{{ls.locs['error'].ErrorExpress | format:[count,item.MaxExpress]}}"></mp-core-info-notification>
    <div class="flex-grid padding-0">
      <div class="flex-col-12 flex-col-12-xs flex-direction-column padding-top-32">
        <div class="radio-button-container">
          <input type="radio" id="express-edit-standard-radio" name="express-edit-radio" [value]="false" [(ngModel)]="item.Express" />
          <label class="radio float-left" for="express-edit-standard-radio">
            <svg version="1.1" class="radiobutton_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
              <g>
                <path class="radiobutton_svg__outer" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z" />
	            </g>
              <circle class="radiobutton_svg__inner" cx="8" cy="8" r="4" />
            </svg>
          </label>
          <label for="express-edit-standard-radio">
            <span>{{ ls.locs['loc'].StandardService }}</span>
          </label>
        </div>
        <mp-core-select [hidden]="item.Express" [(model)]="count" [elements]="countListStandard" class="express-edit-select wk-select"></mp-core-select>
      </div>
      <div class="flex-col-12 flex-col-12-xs flex-direction-column padding-top-32">
        <div class="radio-button-container">
          <input type="radio" id="express-edit-express-radio" name="express-edit-radio" [value]="true" [ngModel]="item.Express" (ngModelChange)="item.Express = $event; setCount()" />
          <label class="radio float-left" for="express-edit-express-radio">
            <svg version="1.1" class="radiobutton_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
              <g>
                <path class="radiobutton_svg__outer" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z" />
	            </g>
                <circle class="radiobutton_svg__inner" cx="8" cy="8" r="4" />
            </svg>
          </label>
          <label for="express-edit-express-radio">
            <span>{{ ls.locs['loc'].Express }}</span>
          </label>
        </div>
        <mp-core-select [hidden]="!item.Express" [(model)]="countExpress" [elements]="countListExpress" class="express-edit-select wk-select"></mp-core-select>
      </div>
    </div>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="updateItem($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].AenderungenUebernehmen }}</a>
    </div>
  </div>
</div>
