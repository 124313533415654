import { Injectable } from '@angular/core';

/**
 * This class generates and returns a guid.
 */
@Injectable({
  providedIn: 'root'
})
export class MpGuidService {

  constructor() { }

  /**
   * Creates and returns a new guid.
   */
  public newGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c: any) => {
      let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
