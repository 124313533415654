import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { ManagerComponent } from '@core/modules/manager/manager.component';
import { AdminComponent } from '@core/modules/admin/admin.component';

import { AuthGuard } from '@core/helpers/auth.guard';

import { TipAndWinComponent } from './modules/participant/pages/tip-and-win/tip-and-win.component';
import { TipAndWinEditorComponent } from './modules/manager/pages/tip-and-win-editor/tip-and-win-editor.component';
import { TipAndWinGameAcceptanceComponent } from './modules/manager/pages/tip-and-win-game-acceptance/tip-and-win-game-acceptance.component';
import { TipAndWinGameStatisticComponent } from './modules/manager/pages/tip-and-win-game-statistic/tip-and-win-game-statistic.component';

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'TipAndWin', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }] }
    ]
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'TipAndWinEditor', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinEditorComponent, outlet: 'mainContentManager', pathMatch: 'full' }] },
      { path: 'TipAndWinSpielakzeptanz', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinGameAcceptanceComponent, outlet: 'mainContentManager', pathMatch: 'full' }] },
      { path: 'TipAndWinSpielstatistik', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinGameStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }] }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'TipAndWinEditor', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }] },
      { path: 'TipAndWinSpielakzeptanz', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinGameAcceptanceComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }] },
      { path: 'TipAndWinSpielstatistik', canActivateChild: [AuthGuard], children: [{ path: '', component: TipAndWinGameStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }] }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class TawRoutingModule { }
