import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { RentalCheckComponent } from '@rk/modules/participant/pages/rental-check/rental-check.component';

/**
 * This class provides the data and functions
 * for the rental check page.
 */
@Component({
  selector: 'mp-rkzz-rental-check-additional-payment',
  templateUrl: './rental-check-additional-payment.component.html',
  styleUrls: [
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/elements/rk-select.scss',
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/sites/rk-main-page-styles.scss',
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/sites/rk-two-column-pages.scss',
    './../../../../../../mp.Reisekonfigurator/app/components/search-area/styles/tc-search.scss',
    './../../../../../../mp.Reisekonfigurator/app/components/sidebar/styles/tc-sidebars.scss',
    './../../../../../../mp.Reisekonfigurator/app/modules/styles/sites/rk-browser-hacks.scss',
    './../../../../../../mp.Zuzahlung/app/zz.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RentalCheckAdditionalPaymentComponent extends RentalCheckComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
