import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';
import { ParticipantCockpitService } from './../../../manager/pages/participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the manager 
 * management table.
 */
@Component({
  selector: 'mp-core-manager-management',
  templateUrl: './manager-management.component.html'
})
export class ManagerManagementComponent implements OnInit {

  public excelService: string = '/api/Managerverwaltung/Managerverwaltung2Excel';
  public loader: MpLoader | null = null;
  public enddate: Date = new Date();
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;
  public searchTexts: { [key: string]: any } = {};
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    },
    {
      backendName: 'KontoEntsperren',
      func: this.unlockAccount.bind(this)
    }
  ];

  private _managerManagementTableLoader: MpLoader = {
    name: 'managerManagementTableLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;
  private _unlockParticipantSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _participantCockpitService: ParticipantCockpitService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions. Gets the route param for the
   * search.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('Managerverwaltung');
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._managerManagementTableLoader);

    this._mpLoaderService.extendLoader('managerManagementTableLoader', {
      isReady: true
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._unlockParticipantSubscription !== 'undefined') {
      this._unlockParticipantSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('managerManagementTableLoader');
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['ID']);
  }

  /**
   * Unlocks the account of the given
   * manager.
   */
  unlockAccount(row?: any, column?: any): void {
    if (typeof row !== 'undefined') {
      this._unlockParticipantSubscription = this._apiService.postRequest('/api/Teilnehmer/KontoEntsperren', row).subscribe((data: any) => {
        this._mpMessaging.openPanelFromResultResponse(data);
      },
        (error: any) => {
          this._mpMessaging.openWarningPanel(error.Message);
        });
    }
  }

}

