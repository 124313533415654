<section id="einstellungen" class="layout-container bg-color-white padding-bottom-48" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="white-content-container" *ngIf="profilLoaded && contactLoaded">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <h1>{{ ls.locs['loc'].Einstellungen }}</h1>
    <div class="row">
      <div class="grid col-large-6">
        <form name="formProfile"
              #formProfile="ngForm"
              novalidate>
          <h3>{{ ls.locs['loc'].Profil }}</h3>
          <mp-core-input mpId="anrede"
                         label="{{ ls.locs['loc'].Anrede }}"
                         [(model)]="profile['Anrede']"
                         [mpDisabled]="true"
                         [mpRequired]="true"
                         class="margin-bottom-16">
          </mp-core-input>
          <mp-core-input mpId="vorname"
                         label="{{ ls.locs['loc'].Vorname }}"
                         [(model)]="profile['Vorname']"
                         [mpDisabled]="true"
                         [mpRequired]="true"
                         class="margin-bottom-16">
          </mp-core-input>
          <mp-core-input mpId="name"
                         label="{{ ls.locs['loc'].Name }}"
                         [(model)]="profile['Name']"
                         [mpDisabled]="true"
                         [mpRequired]="true"
                         class="margin-bottom-16">
          </mp-core-input>
          <mp-core-input mpId="benutzername"
                         label="{{ ls.locs['loc'].Benutzername }}"
                         [(model)]="profile['Benutzer']"
                         [mpDisabled]="true"
                         [mpRequired]="true"
                         class="margin-bottom-16">
          </mp-core-input>
          <div *ngIf="mpSettings.settings['DisplaySettings'].ShowGeburtsdatum" class="display-flex flex-direction-row flex-wrap margin-bottom-16">

            <mp-core-sidebar-input class="width-full"
                                   text="{{profile['GeburtsdatumParams'].date ? profile['GeburtsdatumParams'].date.format('L') : ''}}"
                                   label="{{ ls.locs['loc'].Geburtsdatum }}"
                                   [sidebarParams]="profile['GeburtsdatumParams']"
                                   sidebarComponent="SidebarDate"
                                   [hasError]="errors['profile.GeburtsdatumString'] ? true : false">
            </mp-core-sidebar-input>
            <mp-core-form-error *ngIf="errors['profile.GeburtsdatumString']"
                                [model]="errors['profile.GeburtsdatumString']">
            </mp-core-form-error>
          </div>
          <mp-core-input mpId="firma"
                         label="{{ ls.locs['loc'].Firma }}"
                         [(model)]="profile['Firma']"
                         [mpDisabled]="true"
                         class="margin-bottom-16">
          </mp-core-input>
          <mp-core-input mpId="anlagedatum-tn"
                         label="{{ ls.locs['loc'].AnlagedatumTn }}"
                         [(model)]="profile['AnlagedatumTn']"
                         [mpDisabled]="true">
          </mp-core-input>
          <div *ngIf="mpSettings.settings['DisplaySettings'].ShowGeburtsdatum" class="buttons">
            <button type="button"
                    class="mp-button _prim float-right"
                    (click)="saveProfile()">
              {{ ls.locs['loc'].Speichern }}
            </button>
            <button type="button"
                    class="mp-button text-color-back-btn float-right"
                    (click)="undoProfile()">
              {{ ls.locs['loc'].Verwerfen }}
            </button>
          </div>
        </form>
      </div>
      <div class="divider hidden-breakpoint-large float-left"></div>
      <div class="grid col-large-6">
        <form name="formKontakt"
              #formKontakt="ngForm"
              id="contact-form"
              novalidate>
          <h3>{{ ls.locs['loc'].Kontakt }}</h3>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input name="Telefon_P"
                           [(model)]="kontakt['Telefon_G']"
                           mpId="kontaktTelefon_G"
                           [ngClass]="{active: kontakt['Telefon_G']}"
                           [hasError]="errors['kontakt.Telefon_G'] ? true : false"
                           label="{{ ls.locs['loc'].Telefon }} ({{ ls.locs['loc'].Geschaeftlich }})"
                           [mpRequired]="true"
                           [mpDisabled]="!mpSettings.settings['ProfilSettings'].CanEditKontaktdataG">
            </mp-core-input>

            <span [hidden]="!kontakt['Telefon_G']"
                  class="setDefault"
                  [ngClass]="{active: kontakt['TelefonStd'] == 'TG'}">
              <mp-core-tooltip tooltipTitle="{{ kontakt['TelefonStd'] !== 'TG' ? ls.locs['loc'].AlsStandardFestlegen : '' }}" alignment="right">
                <i class="svg-icon __size-18 __top-8 __check-circle"
                   data-val="TG"
                   (click)="kontakt['TelefonStd'] !== 'TG' ? openTelModal($event) : showSetTelStandardDialog = false"
                   [ngClass]="kontakt['TelefonStd'] == 'TG' ? '__clr-success' : '__clr-default'">
                </i>
              </mp-core-tooltip>
            </span>

            <mp-core-form-error *ngIf="errors['kontakt.Telefon_G']"
                                [model]="errors['kontakt.Telefon_G']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input name="Telefon_P"
                           [mpOptional]="true"
                           [(model)]="kontakt['Telefon_P']"
                           mpId="kontaktTelefon_P"
                           [ngClass]="{active: kontakt['Telefon_P']}"
                           [hasError]="errors['kontakt.Telefon_P'] ? true : false"
                           label="{{ ls.locs['loc'].Telefon }} ({{ ls.locs['loc'].Privat }})">
            </mp-core-input>
            <span [hidden]="!kontakt['Telefon_P']"
                  class="setDefault"
                  [ngClass]="{active: kontakt['TelefonStd'] == 'TP'}">
              <mp-core-tooltip tooltipTitle="{{ kontakt['TelefonStd'] !== 'TP' ? ls.locs['loc'].AlsStandardFestlegen : '' }}" alignment="right">
                <i class="svg-icon __size-18 __top-8 __check-circle"
                   data-val="TP"
                   (click)="kontakt['TelefonStd'] !== 'TP' ? openTelModal($event) : showSetTelStandardDialog = false"
                   [ngClass]="kontakt['TelefonStd'] == 'TP' ? '__clr-success' : '__clr-default'">
                </i>
              </mp-core-tooltip>
            </span>
            <mp-core-form-error *ngIf="errors['kontakt.Telefon_P']"
                                [model]="errors['kontakt.Telefon_P']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input name="Mobil_G"
                           [mpOptional]="true"
                           [(model)]="kontakt['Mobil_G']"
                           mpId="kontaktMobil_G"
                           [ngClass]="{active: kontakt['Mobil_G']}"
                           [hasError]="errors['kontakt.Mobil_G'] ? true : false"
                           label="{{ ls.locs['loc'].Mobil }} ({{ ls.locs['loc'].Geschaeftlich }})">
            </mp-core-input>
            <span [hidden]="!kontakt['Mobil_G']"
                  class="setDefault"
                  [ngClass]="{active: kontakt['TelefonStd'] == 'MG'}">
              <mp-core-tooltip tooltipTitle="{{ kontakt['TelefonStd'] !== 'MG' ? ls.locs['loc'].AlsStandardFestlegen : '' }}" alignment="right">
                <i class="svg-icon __size-18 __top-8 __check-circle"
                   data-val="MG"
                   (click)="kontakt['TelefonStd'] !== 'MG' ? openTelModal($event) : showSetTelStandardDialog = false"
                   [ngClass]="kontakt['TelefonStd'] == 'MG' ? '__clr-success' : '__clr-default'">
                </i>
              </mp-core-tooltip>
            </span>
            <mp-core-form-error *ngIf="errors['kontakt.Mobil_G']"
                                [model]="errors['kontakt.Mobil_G']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input name="Mobil_P"
                           [mpOptional]="true"
                           [(model)]="kontakt['Mobil_P']"
                           mpId="kontaktMobil_P"
                           [ngClass]="{active: kontakt['Mobil_P']}"
                           [hasError]="errors['kontakt.Mobil_P'] ? true : false"
                           label="{{ ls.locs['loc'].Mobil }} ({{ ls.locs['loc'].Privat }})">
            </mp-core-input>
            <span [hidden]="!kontakt['Mobil_P']"
                  class="setDefault"
                  [ngClass]="{active: kontakt['TelefonStd'] == 'MP'}">
              <mp-core-tooltip tooltipTitle="{{ kontakt['TelefonStd'] !== 'MP' ? ls.locs['loc'].AlsStandardFestlegen : '' }}" alignment="right">
                <i class="svg-icon __size-18 __top-8 __check-circle"
                   data-val="MP"
                   (click)="kontakt['TelefonStd'] !== 'MP' ? openTelModal($event) : showSetTelStandardDialog = false"
                   [ngClass]="kontakt['TelefonStd'] == 'MP' ? '__clr-success' : '__clr-default'">
                </i>
              </mp-core-tooltip>
            </span>
            <mp-core-form-error *ngIf="errors['kontakt.Mobil_P']"
                                [model]="errors['kontakt.Mobil_P']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input [(model)]="kontakt['Email_G']"
                           mpId="einstellungenLabel"
                           [ngClass]="{active: kontakt['Email_G']}"
                           [mpRequired]="true"
                           [hasError]="errors['kontakt.Email_G'] ? true : false"
                           label="{{ ls.locs['loc'].EMail }} ({{ ls.locs['loc'].Geschaeftlich }})"
                           [mpDisabled]="!mpSettings.settings['ProfilSettings'].CanEditKontaktdataG">
            </mp-core-input>
            <span [hidden]="!kontakt['Email_G']"
                  class="setDefault"
                  [ngClass]="{active: kontakt['EmailStd'] == 'G'}">
              <mp-core-tooltip tooltipTitle="{{ kontakt['EmailStd'] !== 'G' ? ls.locs['loc'].AlsStandardFestlegen : '' }}" alignment="right">
                <i class="svg-icon __size-18 __top-8 __check-circle"
                   data-val="G"
                   (click)="kontakt['EmailStd'] !== 'G' ? openEmailModal($event) : showSetEmailStandardDialog = false"
                   [ngClass]="kontakt['EmailStd'] == 'G' ? '__clr-success' : '__clr-default'">
                </i>
              </mp-core-tooltip>
            </span>
            <mp-core-form-error *ngIf="errors['kontakt.Email_G']"
                                [model]="errors['kontakt.Email_G']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input [(model)]="kontakt['Email_P']"
                           mpId="kontaktEmail_P"
                           [mpOptional]="true"
                           [ngClass]="{active: kontakt['Email_P']}"
                           [hasError]="errors['kontakt.Email_P'] ? true : false"
                           label="{{ ls.locs['loc'].EMail }} ({{ ls.locs['loc'].Privat }})">
            </mp-core-input>
            <span [hidden]="!kontakt['Email_P']"
                  class="setDefault"
                  [ngClass]="{active: kontakt['EmailStd'] == 'P'}">
              <mp-core-tooltip tooltipTitle="{{ kontakt['EmailStd'] !== 'P' ? ls.locs['loc'].AlsStandardFestlegen : '' }}" alignment="right">
                <i class="svg-icon __size-18 __top-8 __check-circle"
                   data-val="P"
                   (click)="kontakt['EmailStd'] !== 'P' ? openEmailModal($event) : showSetEmailStandardDialog = false"
                   [ngClass]="kontakt['EmailStd'] == 'P' ? '__clr-success' : '__clr-default'">
                </i>
              </mp-core-tooltip>
            </span>
            <mp-core-form-error *ngIf="errors['kontakt.Email_P']"
                                [model]="errors['kontakt.Email_P']">
            </mp-core-form-error>
          </div>
          <div class="buttons floating-container">
            <button type="button"
                    class="mp-button _prim float-right"
                    (click)="saveContact()">
              {{ ls.locs['loc'].Speichern }}
            </button>
            <button type="button"
                    class="mp-button text-color-back-btn float-right margin-right-8"
                    (click)="undoContact()">
              {{ ls.locs['loc'].Verwerfen }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowAdressen" class="grid col-large-12 margin-bottom-16">
        <h3>{{ ls.locs['loc'].Adressen }}</h3>
        <mp-core-order-addresses [selectable]="false"
                                 [colCount]="3"
                                 lkz="{{ mpSettings.settings['ShopSettings'].ProjektLand }}">
        </mp-core-order-addresses>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HasSingleSignOn" class="grid col-large-6">
        <form name="formPasswort"
              #formPasswort="ngForm"
              class="margin-top-16"
              novalidate>
          <h3>{{ ls.locs['loc'].Passwort }}</h3>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input mpType="password"
                           [(model)]="pwd['OldPassword']"
                           mpId="pwdOldPassword"
                           [mpRequired]="true"
                           [ngClass]="{active: pwd['OldPassword']}"
                           [hasError]="errors['model.OldPassword'] ? true : false"
                           label="{{ ls.locs['loc'].PasswortAlt }}">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['model.OldPassword']"
                                [model]="errors['model.OldPassword']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input mpType="password"
                           [(model)]="pwd['NewPassword']"
                           mpId="pwdNewPassword"
                           [mpRequired]="true"
                           [ngClass]="{active: pwd['NewPassword']}"
                           [hasError]="errors['model.NewPassword'] ? true : false"
                           label="{{ ls.locs['loc'].PasswortNeu }}">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['model.NewPassword']"
                                [model]="errors['model.NewPassword']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input mpType="password"
                           [(model)]="pwd['ConfirmPassword']"
                           mpId="pwdConfirmPassword"
                           [mpRequired]="true"
                           [ngClass]="{active: pwd['ConfirmPassword']}"
                           [hasError]="errors['model.ConfirmPassword'] ? true : false"
                           label="{{ ls.locs['loc'].PasswortNeuConfirm }}">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['model.ConfirmPassword']"
                                [model]="errors['model.ConfirmPassword']">
            </mp-core-form-error>
          </div>
        </form>
        <div class="buttons">
          <button type="button"
                  class="mp-button _prim float-right"
                  (click)="savePassword()">
            {{ ls.locs['loc'].Speichern }}
          </button>
          <button type="button"
                  class="mp-button text-color-back-btn float-right margin-right-8"
                  (click)="undoPassword()">
            {{ ls.locs['loc'].Verwerfen }}
          </button>
        </div>
      </div>
      <div class="grid col-large-6">
        <h3>{{ ls.locs['loc'].CookieSettings }}</h3>
        <p>{{ ls.locs['loc'].CookieSettingsText }}</p>
        <div class="buttons">
          <button class="mp-button _prim float-right" (click)="cookieService.openCookieConsentBanner()">
            {{ ls.locs['loc'].CookieSettings }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasNewsletter" class="divider"></div>
    <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasNewsletter && newsletterLoaded" class="row">
      <div class="grid col-large-12">
        <h3>
          {{ ls.locs['loc'].Newsletter }}
        </h3>
        <p [innerHTML]="ls.locs['loc'].NewsletterInfo"></p>
        <div [hidden]="newsletter['Newsletter'] || newsletter['OptIn']"
             class="buttons">
          <button type="button"
                  class="mp-button _prim float-left"
                  (click)="saveNewsletter()">
            {{ ls.locs['loc'].NewsletterAbonnieren }}
          </button>
        </div>
        <div [hidden]="newsletter['Newsletter'] || !newsletter['OptIn']"
             class="buttons">
          <button type="button"
                  class="mp-button _prim float-left"
                  (click)="saveNewsletter()">
            {{ ls.locs['loc'].NewsletterMailErneutSenden }}
          </button>
          <span class="display-block clear-both padding-top-4 font-size-xs position-relative text-danger">{{ ls.locs['loc'].NewsletterAusstehend }}</span>
        </div>
        <div [hidden]="!newsletter['Newsletter']"
             class="buttons">
          <button type="button"
                  class="mp-button _prim float-left"
                  (click)="saveNewsletter()">
            {{ ls.locs['loc'].NewsletterAbmelden }}
          </button>
          <span class="display-block clear-both padding-top-4 font-size-xs position-relative text-success">{{ ls.locs['loc'].NewsletterBestaetigtAm }} {{newsletter['DoubleOptInZeitpunktString']}}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="divider"></div>
      <div class="row"
           [hidden]="emailSubscriptions.length <= 1 ">
        <div class="divider hidden-breakpoint-large float-left"></div>
        <div class="grid col-large-12">
          <h2>{{ ls.locs['loc'].Newsletter }}</h2>
          <div *ngFor="let type of emailSubscriptions; let index = index">
            <mp-core-checkbox label="{{type.Bezeichnung}}" mpId="chkEmailType{{index}}" [(model)]="type.Aktiv"></mp-core-checkbox>
          </div>
          <div class="buttons margin-top-8">
            <button type="button"
                    class="mp-button _prim float-left"
                    (click)="saveEmailSubscriptions()">
              {{ ls.locs['loc'].Speichern }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mp-core-modal [show]="showSetTelStandardDialog"
                 modalClass="size-warning"
                 id="wk-modal"
                 [buttons]="[{text: ls.locs['loc'].Ja, action: setTelefonStd, class: '_prim'}, {text: ls.locs['loc'].Nein, action: closeTelModal, class: '_sec'}]">
    <span [innerHTML]="ls.locs['loc'].FrageAlsStandardFestlegen"></span>
    <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important" (click)="closeTelModal()"></i>
  </mp-core-modal>
  <mp-core-modal [show]="showSetEmailStandardDialog" modalClass="size-warning" id="wk-modal" [buttons]="[{text: ls.locs['loc'].Ja, action: setEmailStd, class: '_prim'}, {text: ls.locs['loc'].Nein, action: closeEmailModal, class: '_sec'}]">
    <span [innerHTML]="ls.locs['loc'].FrageAlsStandardFestlegen"></span>
    <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important" (click)="closeEmailModal()"></i>
  </mp-core-modal>
</section>
