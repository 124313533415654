<div class="support-lug" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="support display-flex flex-align-items-center" [ngClass]="openSupport === true ? 'open-support' : ''" (clickOutside)="closeSupport()">
    <div class="display-flex flex-align-items-center flex-justify-cont-center" (click)="openSupport = !openSupport">
      <i *ngIf="!mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer || mpSettings.settings['DisplaySettings'].HideHotline" class="svg-icon __clr-specific-3 __size-32 __top-0 __at-sign"></i>
      <i *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="svg-icon __clr-specific-3 __size-32 __top-0 __phone"></i>
    </div>
    <div class="display-flex flex-justify-cont-center flex-align-items-center flex-direction-column padding-12 text-align-center">
      <h1 class="font-size-xxl">{{ls.locs['locReisekonfigurator'].Reisebuero}}</h1>
      <p>{{ls.locs['locReisekonfigurator'].WirBeratenSieGerne}}</p>
      <p *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="margin-bottom-0">{{ls.locs['loc'].Telefon}}: <a href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}</a></p>
      <p>{{ls.locs['loc'].EMail}}: <a href="mailto:{{ mpSettings.settings['ReisekonfiguratorSettings'].ReisenSupportEmailAdresse }}">{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenSupportEmailAdresse}}</a></p>

      <p *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline && ls.locs['loc'].Supporttool_Zeiten" class="font-size-xxs margin-bottom-0">{{ls.locs['loc'].Supporttool_Zeiten}}</p>
      <p *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline && ls.locs['loc'].SupportKosten" class="font-size-xxs">{{ls.locs['loc'].SupportKosten}}</p>

    </div>
  </div>
</div>
