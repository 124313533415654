<div class="layout-container" *ngIf="(ls.locsLoaded | async) && campaignLoaded">
  <h1>{{ ls.locs['locLeas'].Aktion }}: {{campaign['TitelFuerMenu']}}</h1>
  <h2>{{ ls.locs['locLeas'].Aktionszeitraum }}</h2>
  <div class="margin-top-24 margin-bottom-24">
    <span [innerText]="campaign['Von']"></span> - 
    <span [innerText]="campaign['Bis']"></span>
  </div>
  <h2>{{ ls.locs['locLeas'].Themenbuehne }}</h2>
  <div class="margin-top-24 margin-bottom-24">
    <img class="max-width-full" src="{{campaign['Path']}}" />
  </div>
  <h2>{{ ls.locs['locLeas'].TitelDerAktion }}</h2>
  <div class="margin-top-24 margin-bottom-24">
    <div [innerHTML]="campaign['Titel']" class="buehnen-titel"></div>
  </div>
  <h2>{{ ls.locs['locLeas'].TeaserDerAktion }}</h2>
  <div class="margin-top-24 margin-bottom-24">
    <div [innerHTML]="campaign['Teaser']" class="buehnen-teaser"></div>
  </div>
  <div [hidden]="!campaign['Text']">
    <h2>{{ ls.locs['locLeas'].TextDerAktionsdetailseite }}</h2>
    <div class="margin-top-24 margin-bottom-24">
      <div [innerHTML]="campaign['Text']" class="buehnen-text"></div>
    </div>
  </div>
</div>
