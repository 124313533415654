  <div id="main" class="position-relative" layout-extern>
    <header id="header" class="position-relative">
      <div class="layout-inner position-container">
        <div id="shop-logo" [hidden]="!mpSettings.settings['DisplaySettings'].LogoSecondary">
          <a href="#/">
            <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoSecondary}}" alt="Pr&auml;emienshop Logo">
          </a>
        </div>
        <div id="company-logo" [hidden]="!mpSettings.settings['DisplaySettings'].LogoPrimary">
          <a href="#/">
            <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoPrimary}}" alt="Firmenlogo">
          </a>
        </div>
      </div>
    </header>
    <router-outlet name="mainContentExtFixed"></router-outlet>
    <footer id="footer">
      <div class="layout-inner position-container">
        <div id="company-address" [innerHTML]="ls.locs['loc'].FooterText"></div>
        <div id="imprint">

          <a *ngIf="mpSettings.settings['DisplaySettings'].ShowSpielregelnInFooter && !mpSettings.settings['DisplaySettings'].HideSpielregelnExtern" href="#/Spielregeln">{{ ls.locs['loc'].Spielregeln }}</a>
          <span *ngIf="mpSettings.settings['DisplaySettings'].ShowSpielregelnInFooter && !mpSettings.settings['DisplaySettings'].HideSpielregelnExtern" class="margin-left-right-4"> | </span>

          <a *ngIf="mpSettings.settings['DisplaySettings'].ShowTeilnahmebedingungenInFooter && !mpSettings.settings['DisplaySettings'].HideTeilnahmebedingungenlnExtern" href="#/Teilnahmebedingungen">{{ ls.locs['loc'].Teilnahmebedingungen }}</a>
          <span *ngIf="mpSettings.settings['DisplaySettings'].ShowTeilnahmebedingungenInFooter && !mpSettings.settings['DisplaySettings'].HideTeilnahmebedingungenlnExtern" class="margin-left-right-4"> | </span>

          <a *ngIf="mpSettings.settings['DisplaySettings'].ShowDatenschutzInFooter && !mpSettings.settings['NetcentiveCoreSettings'].DatenschutzLink" href="#/Datenschutz/">{{ ls.locs['loc'].Datenschutz }}</a>
          <a *ngIf="mpSettings.settings['DisplaySettings'].ShowDatenschutzInFooter && mpSettings.settings['NetcentiveCoreSettings'].DatenschutzLink" href="{{ mpSettings.settings['NetcentiveCoreSettings'].DatenschutzLink }}">{{ ls.locs['loc'].Datenschutz }}</a>
          <span *ngIf="mpSettings.settings['DisplaySettings'].ShowDatenschutzInFooter" class="margin-left-right-4"> | </span>

          <a *ngIf="mpSettings.settings['DisplaySettings'].ShowCookiesInFooter" (click)="cookieService.openCookieConsentBanner()">{{ ls.locs['loc'].CookieSettings }}</a>
          <span *ngIf="mpSettings.settings['DisplaySettings'].ShowCookiesInFooter" class="margin-left-right-4"> | </span>

          <a *ngIf="!mpSettings.settings['DisplaySettings'].ImpressumLink" href="#/Impressum">{{ ls.locs['loc'].Impressum }}</a>
          <a *ngIf="mpSettings.settings['DisplaySettings'].ImpressumLink" href="{{mpSettings.settings['DisplaySettings'].ImpressumLink}}" target="_blank">{{ ls.locs['loc'].Impressum }}</a>

        </div>
      </div>
    </footer>
  </div>
  <mp-core-cookie-consent></mp-core-cookie-consent>
