import { Component, OnInit, Injector, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { InboxService } from './../inbox.service';

@Component({
  selector: 'mp-core-inbox-details-campaign',
  templateUrl: './inbox-details-campaign.component.html'
})
export class InboxDetailsCampaignComponent implements OnInit {

  public loading: boolean = true;
  public item: any;

  constructor(
    public sanitizer: DomSanitizer,
    public ls: MpLocalizationService,
    private _injector: Injector,
    private _inboxService: InboxService
  ) { }

  /**
   * Sets the loading to false;
   */
  ngOnInit(): void {
    this.item = this._injector.get('iboxItem');
    this.loading = false;
  }

  /**
   * Triggers the delete function of
   * the parent component.
   */
  onItemDelete(item: any): void {
    this._inboxService.triggerParentClickFunction(item, 'onItemDelete');
  }

  /**
   * Triggers the on click function of
   * the parent component.
   */
  onClick(evt: MouseEvent, item: any): void {
    evt.preventDefault();

    this._inboxService.triggerParentClickFunction(item, 'onClick');
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
