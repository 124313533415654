import { Injectable } from '@angular/core';

/**
 * Provides the functions for error
 * handling.
 */
@Injectable({
  providedIn: 'root'
})
export class MpErrorHandlerService {

  constructor() { }

  /**
   * Handles the error response
   * and returns the error strings.
   */
  handleResponse(error: any): Array<string> {
    const asMessages: Array<string> = [];

    if (typeof error['ModelState'] !== 'undefined') {
      const modelstate = error['ModelState'];
      let vbSetFocus = true;

      Object.keys(modelstate).forEach((key: string) => {
        const item = modelstate[key];
        const itemElem = document.getElementById(key);

        if (vbSetFocus) {
          if (itemElem !== null) {
            itemElem.focus();
            vbSetFocus = false;
          }
        }

        if (itemElem !== null) {
          if (!itemElem.classList.contains('inputError')) {
            itemElem.classList.add('inputError');
          }
        }

        if (asMessages.indexOf(item[0]) === -1) {
          asMessages.push(item[0]);
        }
      });
    }

    return asMessages;
  }
}
