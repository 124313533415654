import { Injectable } from '@angular/core';

/**
 * This class provides a check of the
 * document size.
 */
@Injectable({
  providedIn: 'root'
})
export class SizeCheckService {

  constructor() { }

  /**
   * Checks the document size.
   */
  sizeCheck(): boolean {
    if (window.innerWidth < 1000) {
      return true;
    } else {
      return false;
    }
  }
}
