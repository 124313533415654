<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].Teilnehmerbefragung }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].Teilnehmerbefragung }}"
                      infotextShorten="Teilnehmerbefragung"
                      fieldsAction="/api/Teilnehmerbefragung/getTeilnehmerbefragungStatistikFields"
                      listAction="/api/Teilnehmerbefragung/getTeilnehmerbefragungStatistik"
                      loaderName="leasParticipantSurveyStatisticLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [showDatePicker]="true"
                      [footer]="false"
                      defaultSorting="Benutzer">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
