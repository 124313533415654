import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';

/**
 * This class provides the functionalities
 * for the mobile search filter.
 */
@Component({
  selector: 'mp-rk-tc-mobile-search-filter',
  templateUrl: './tc-mobile-search-filter.component.html',
  styleUrls: ['./tc-mobile-search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TcMobileSearchFilterComponent implements OnInit, OnChanges {

  @Input() public toggleSearchFilter: any;
  @Input() public travelStyle: number = 0;
  @Input() public filterButtonText: string = '';
  @Input() public filteredHotels: Array<any> = [];
  @Input() public filteredFlights: Array<any> = [];
  @Input() public openFilter: boolean = false;

  public buttonText: string = '';
  public flightCnt: number = 0;
  public hotelCnt: number = 0;

  constructor(
    public ls: MpLocalizationService,
    private _elementRef: ElementRef
  ) { }

  /**
   * Gets the element ref, sets the
   * button text, and adds event listener.
   */
  ngOnInit(): void {
    const element = this._elementRef.nativeElement;
    element.removeEventListener('scroll', this._reduceHeight.bind(this));
    element.addEventListener('scroll', this._reduceHeight.bind(this));
    this.buttonText = this.filterButtonText;
  }

  /**
   * Watches for options and filter
   * changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['openFilter'] !== 'undefined') {
      if (changes['openFilter'].firstChange || changes['openFilter'].currentValue !== changes['openFilter'].previousValue) {
        const element = this._elementRef.nativeElement;
        const mainSection = document.getElementById('rk-main-section');

        if (this.openFilter) {
          element.style.display = 'block';

          if (mainSection !== null) {
            mainSection.style.minHeight = mainSection.getBoundingClientRect().height + 'px';
          }

          this._reduceHeight();
        } else {
          element.style.top = '100vh';
          element.style.display = 'block';

          if (mainSection !== null) {
            mainSection.style.minHeight = '0px';
          }

          setTimeout(() => {
            if (!this.openFilter) {
              element.style.display = 'none';
            }
          }, 250);
        }
      }
    }

    if (typeof changes['filteredHotels'] !== 'undefined') {
      if (changes['filteredHotels'].firstChange || JSON.stringify(changes['filteredHotels'].currentValue) !== JSON.stringify(changes['filteredHotels'].previousValue)) {
        this.hotelCnt = this.filteredHotels.length;
        this._setButtonText(this.filteredHotels);
      }
    }

    if (typeof changes['filteredFlights'] !== 'undefined') {
      if (changes['filteredFlights'].firstChange || JSON.stringify(changes['filteredFlights'].currentValue) !== JSON.stringify(changes['filteredFlights'].previousValue)) {
        this.flightCnt = this.filteredFlights.length;
        this._setButtonText(this.filteredFlights);
      }
    }
  }

  /**
   * Toggles the search filter.
   */
  toggleMobileSearchFilter(evt: MouseEvent): void {
    if (typeof this.toggleSearchFilter !== 'undefined') {
      this.toggleSearchFilter(evt);
    }
  }

  /**
   * Reduces the height of the mobile
   * search filter, so it fits the
   * viewport.
   */
  private _reduceHeight(): void {
    const navbar = document.getElementById('navbar');
    const rkBottomButtons = document.getElementById('rk-bottom-buttons');

    if (navbar !== null && rkBottomButtons !== null) {
      const navHeight = navbar.getBoundingClientRect().top + parseInt(getComputedStyle(navbar).getPropertyValue('height').replace('px', ''));
      const rkBottomButtonsPos = rkBottomButtons.getBoundingClientRect().height;
      const element = this._elementRef.nativeElement;
      element.style.height = (window.innerHeight - navHeight - rkBottomButtonsPos) + 'px';
      element.style.top = navHeight + 'px';
    }
  }

  /**
   * Sets the button text for the filter,
   * regarding the count of filter results.
   */
  private _setButtonText(newVal: Array<any>): void {
    if (newVal.length > 1 || newVal.length < 1) {
      switch (this.filterButtonText) {
        case 'Hotel':
          this.buttonText = 'Hotels';

          break;
        case 'Flug':
          this.buttonText = 'Flüge';

          break;
        case 'Hotel mit Flug':
          this.buttonText = 'Hotels mit Flug';

          break;
        default:
          this.buttonText = this.filterButtonText;

          break;
      }

      window.scrollTo(0, window.scrollY + 1);
      this._reduceHeight();
    } else {
      this.buttonText = this.filterButtonText;
    }
  }

}
