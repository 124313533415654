import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import clone from 'clone';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { ApiService } from '@core/services/api.service';

/**
 * This class provides the functionalities
 * for the aiport sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-airport',
  templateUrl: './sidebar-airport.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarAirportComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public focusOnInit: boolean = false;
  public noResults: boolean = false;
  public noSelection: boolean = false;
  public countries: Array<any> = [];

  private _lastSearchSuggestRequestId: number = Math.ceil(Math.random() * 1000);
  private _sidebarParamsSubscription: Subscription | undefined;
  private _getGeoFilterSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the sidebar params.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        Suchbegriff: this.selection['Suchbegriff'] || this.mpSidebar.params['Suchbegriff'],
        flughaefen: this.selection['flughaefen'] || clone(this.mpSidebar.params['flughaefen']) || []
      });

      this.noResults = this.mpSidebar.params['noResults'];
      this.noSelection = this.mpSidebar.params['noSelection'];
      this.focusOnInit = false;

      setTimeout(() => {
        this.focusOnInit = true;
      }, 0);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getGeoFilterSubscription !== 'undefined') {
      this._getGeoFilterSubscription.unsubscribe();
    }
  }

  /**
   * Gets the geo filter, so the airports
   * can be filtered.
   */
  private _getGeoFilter(): void {
    if ((this.selection['Suchbegriff'] && this.selection['Suchbegriff'].length > 2)) {
      const geoFilterData = {
        Suchbegriff: this.selection['Suchbegriff'],
        RequestId: ++this._lastSearchSuggestRequestId
      };

      this._getGeoFilterSubscription = this._apiService.postRequest('/api/RkCommon/Flughaefen/', geoFilterData).subscribe((data: any) => {
        if (this._lastSearchSuggestRequestId === data.footer[0]) {
          const sortBy = (key: any) => {
            return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
          };

          this.countries = data.Records.concat().sort(sortBy('Land'));

          this.countries.forEach((country: any) => {
            country['Flughaefen'].forEach((airport: any) => {
              airport['active'] = this.selection['flughaefen'] && typeof this.selection['flughaefen'].find((target: any) => {
                return target['Kuerzel'] === airport['Kuerzel'];
              }) !== 'undefined';
            });

            country['Flughaefen'].concat().sort(sortBy('Bezeichnung'));
          });
        }
      });
    }
  }

  /**
   * Triggers the geo filter.
   */
  searchTermChange(): void {
    this._getGeoFilter();
  }

  /**
   * Clears the search field.
   */
  clearSearch(evt: MouseEvent): void {
    this.selection['Suchbegriff'] = '';
    // @ts-ignore
    evt.currentTarget.parentElement.parentElement.querySelector('input').focus();
  }

  /**
   * Sets the selected airport.
   */
  setAirport(airport: any): void {
    const existing = this.selection['flughaefen'].find((airp: any) => {
      return airp['Kuerzel'] === airport['Kuerzel'];
    });

    if (!this.selection) {
      this.selection = {};
    }

    if (!this.selection['flughaefen']) {
      this.selection['flughaefen'] = [];
    }

    if (typeof existing !== 'undefined') {
      const index = this.selection['flughaefen'].indexOf(existing);
      this.selection['flughaefen'].splice(index, 1);
      airport.active = false;
    } else {
      this.selection['flughaefen'].push(airport);
      airport.active = true;
    }

    this.noSelection = Object.keys(this.selection).length === 0;
  }

  /**
   * Saves the selected airports.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpSidebar.params['flughaefen'] = this.selection['flughaefen'];
    this.mpSidebar.params['noSelection'] = this.noSelection;

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

}
