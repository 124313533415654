import { Injectable } from '@angular/core';

/**
 * This class provides a debounce
 * functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class MpDebounceService {

  constructor() { }

  /**
   * Delays the invoking of the given
   * function.
   */
  debounce(func: Function, wait: number, immediate?: boolean, context?: any): Function {
    // @ts-ignore
    let timeout;

    return (...args: any[]) => {
  	  context = context ?? this;
      // @ts-ignore
      clearTimeout(timeout);

  	  timeout = setTimeout(() => {
  		  timeout = null;
  		  if (!immediate) func.apply(context, args);
  	  }, wait);

      if (immediate && !timeout) func.apply(context, args);
    };
  }
}
