<div *ngIf="(ls.locsLoaded | async)">
  <div id="user-profile__navbar">
    <div class="user-profile__navbar--inner">
      <ul class="tab">
        <li>
          <a class="tablinks" (click)="changeTab(1, $event)" [ngClass]="{'active':activeTab === 1}">
            <div class="display-inline-block hide-on-mobile">
              <i class="svg-icon __wide __dashboard" [ngClass]="activeTab === 1 ? '__clr-primary' : '__clr-inactive'"></i>
              <div class="system-icon-text float-left">{{ ls.locs['loc'].MeinDashboard }}</div>
            </div>
            <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].MeinDashboard }}" class="mobile-only">
              <i class="svg-icon __wide __dashboard" [ngClass]="activeTab === 1 ? '__clr-primary' : '__clr-inactive'"></i>
            </mp-core-tooltip>
          </a>
        </li>
        <li>
          <a class="tablinks" (click)="changeTab(3, $event)" [ngClass]="{'active':activeTab === 3}">
            <div class="display-inline-block hide-on-mobile">
              <i class="svg-icon __wide __compare" [ngClass]="activeTab === 3 ? '__clr-primary' : '__clr-inactive'"></i>
              <div class="system-icon-text float-left">{{ ls.locs['loc'].MeinKonto }}</div>
            </div>
            <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].MeinKonto }}" class="mobile-only">
              <i class="svg-icon __wide __compare" [ngClass]="activeTab === 3 ? '__clr-primary' : '__clr-inactive'"></i>
            </mp-core-tooltip>
          </a>
        </li>
        <li>
          <a class="tablinks last" (click)="changeTab(4, $event)" [ngClass]="{'active':activeTab === 4}">
            <div class="display-inline-block badge-container position-relative height-full visible-breakpoint-medium padding-right-24">
              <i class="svg-icon __wide __inbox" [ngClass]="activeTab === 4 ? '__clr-primary' : '__clr-inactive'"></i>
              <div class="system-icon-text float-left">{{ ls.locs['loc'].MeinPostfach }}</div>
              <span [hidden]="inboxUnread === 0" class="badge position-absolute right-0 display-flex flex-align-items-center flex-justify-cont-center">{{inboxUnread}}</span>
            </div>
            <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].MeinPostfach }}" class="hidden-breakpoint-medium badge-container position-relative height-full">
              <i class="svg-icon __wide __inbox" [ngClass]="activeTab === 4 ? '__clr-primary' : '__clr-inactive'"></i>
              <span [hidden]="inboxUnread === 0" class="badge position-absolute right-0 display-flex flex-align-items-center flex-justify-cont-center">{{inboxUnread}}</span>
            </mp-core-tooltip>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div id="dashbaord" class="tabcontent" [ngClass]="{'active':activeTab === 1}" *ngIf="activeTab === 1">
    <mp-core-dashboard></mp-core-dashboard>
  </div>
  <div id="successes" class="tabcontent" [ngClass]="{'active':activeTab === 2}" *ngIf="activeTab === 2">
    <div class="layout-container">
      <p>Inhalt zu "Meine Erfolge".</p>
    </div>
  </div>
  <div id="account" class="tabcontent" [ngClass]="{'active':activeTab === 3}" *ngIf="activeTab === 3">
    <mp-core-account></mp-core-account>
  </div>
  <div id="inbox" class="tabcontent" [ngClass]="{'active':activeTab === 4}" *ngIf="activeTab === 4">
    <div class="layout-container padding-top-0">
      <mp-core-inbox></mp-core-inbox>
    </div>
  </div>
</div>
