import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';
import { ParentTableRow, ParentTableRowIndex } from '@core/components/data-table/table-injectors';

/**
 * This class provides the data for the
 * award statistic table child.
 */
@Component({
  selector: 'mp-core-payment-of-tax-base-child',
  templateUrl: './payment-of-tax-base-child.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PaymentOfTaxBaseChildComponent implements OnInit {

  public loaderName = '';

  private _managerPaymentOfTaxBaseChildLoader: MpLoader = {
    name: 'managerPaymentOfTaxBaseChildLoader',
    isFirst: false,
    isReady: false,
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  /**
   * Sets the loader for the table data,
   * and gets the key from the parent data
   * table.
   */
  ngOnInit(): void {
    this._managerPaymentOfTaxBaseChildLoader.name = `${this._managerPaymentOfTaxBaseChildLoader.name}--${this._parentTableRowIndex}`;
    this._mpLoaderService.registerLoader(this._managerPaymentOfTaxBaseChildLoader);
    const parentLoader = this._mpLoaderService.getLoader('paymentOfTaxBaseLoader');
    let params = {};

    if (this._parentTableRow !== null) {
      params = {
        // @ts-ignore
        TnID: this._parentTableRow['TnID'],
        Jahr: parentLoader.params.Jahr,
        Monat: parentLoader.params.Monat
      };
    }

    this._mpLoaderService.extendLoader(this._managerPaymentOfTaxBaseChildLoader.name, {
      isReady: true,
      params: params
    });

    this.loaderName = this._managerPaymentOfTaxBaseChildLoader.name;
    const loader = this._mpLoaderService.getLoader(this._managerPaymentOfTaxBaseChildLoader.name);

    setTimeout(() => {
      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    }, 400);
  }

}
