import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { TcSearchParamsService } from './../../../../services/tc-search-params.service';

import { TravelTopic } from './travel-topic';

/**
 * Provides the data and functionalities
 * for the travel configurators main page.
 */
@Component({
  selector: 'mp-rk-travel-configurator',
  templateUrl: './travel-configurator.component.html',
  styleUrls: [
    './travel-configurator.component.scss',
    './../../../styles/sites/rk-main-page-styles.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './../../../styles/sites/rk-browser-hacks.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TravelConfiguratorComponent implements OnInit, OnDestroy {

  public showNotModal: boolean = false;
  public minHotelCategory: number = 0;
  public travelTypeLoaded: boolean = false;
  public travelType: { [ key: string]: any } = {};
  public tab: number = 0;
  public params: { [ key: string]: any } = {};
  public stagePath: string = '';
  public travelTopics: Array<TravelTopic> = [];
  public closeNotModalFunc = this._closeNotModal.bind(this);
  public searchFunc = this.search.bind(this);

  private _role: string = '';
  private _loadSettingsSubscription: Subscription | undefined;
  private _getEnumsSubscription: Subscription | undefined;
  private _getEmbargoCountries: Subscription | undefined;
  private _getStageSubscription: Subscription | undefined;
  private _getTravelTopicsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpEnums: MpEnumsService,
    private _mpSidebar: MpSidebarService,
    private _authService: AuthService,
    private _router: Router,
    private _tcSearchParamsService: TcSearchParamsService,
    private _roleMapping: RoleMappingService
  ) { }

  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('reisekonfigurator');
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.mpSettings.settings).length > 0) {
      this._getHotelCatsAndEnums();
    } else {
      this._loadSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._getHotelCatsAndEnums();
        }
      });

      this.mpSettings.getSettings();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._loadSettingsSubscription !== 'undefined') {
      this._loadSettingsSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }

    if (typeof this._getEmbargoCountries !== 'undefined') {
      this._getEmbargoCountries.unsubscribe();
    }

    if (typeof this._getStageSubscription !== 'undefined') {
      this._getStageSubscription.unsubscribe();
    }

    if (typeof this._getTravelTopicsSubscription !== 'undefined') {
      this._getTravelTopicsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the min available hotel categories.
   * Gets the enums for the travel types.
   * Checks whether or not the country of the
   * user is an embargo country - if so, redirects
   * the user to the home page.
   */
  private _getHotelCatsAndEnums(): void {
    const min = (nums: Array<any>) => {
      if (nums.length) {
        return Math.min(...nums);
      } else {
        return 0;
      }
    }

    this.minHotelCategory = min(this.mpSettings.settings['ReisekonfiguratorSettings'].AvailableHotelCategories);

    this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        const enums = this._mpEnums.enums;
        this.travelType = enums['Reisetyp'];
        this.travelTypeLoaded = true;

        if (this.tab === 0) {
          this.setTab(this.mpSettings.settings['ReisekonfiguratorSettings'].ShowHotel ? this.travelType['NurHotel'] : (this.mpSettings.settings['ReisekonfiguratorSettings'].ShowHotelUndFlug ? this.travelType['HotelUndFlug'] : (this.mpSettings.settings['ReisekonfiguratorSettings'].ShowNurFlug ? this.travelType['NurFlug'] : 0)));
        }

        this._getEmbargoCountries = this._apiService.getRequest('/api/Teilnehmer/GetTnIsInEmbargoCountry').subscribe((data: any) => {
          if (data.Records[0]) {
            this._router.navigateByUrl(`/${this._role}/Home`);
          } else {
            this._loadData();
            this._getParams();
          }
        },
        (error: any) => {
          this._loadData();
          this._getParams();
        });
      }
    });

    this._mpEnums.getEnums();
  }

  /**
   *  Sets the given tab to active.
   */
  setTab(tab: number): void {
    this.tab = tab;
  }

  /**
   * Gets the search parameters.
   */
  private _getParams(): void {
    const parameters = this._tcSearchParamsService.getParams() || {};
    this.tab = parameters['Reiseart'] || this.tab;
  }

  /**
   * Loads the data for the travel configurators
   * mein page.
   */
  private _loadData(): void {
    this._getStageSubscription = this._apiService.getRequest('/api/RkCommon/getBuehne').subscribe((data: any) => {
      this.stagePath = data.Records[0];
    },
    (error: any) => {
    });

    this._getTravelTopicsSubscription = this._apiService.getRequest('/api/RkCommon/getReisethemen').subscribe((data: any) => {
      if (data && data.Records.length > 0) {
        this.travelTopics = data.Records.filter((topic: TravelTopic) => {
          return topic.ShowOnRkHome === true;
        });
      }
    },
    (error: any) => {
    });
  }

  /**
   * Opens the api not available modal.
   */
  setNotModal(): void {
    this.showNotModal = true;
  }

  /**
   * Closes the api not available modal.
   */
  private _closeNotModal(): void {
    this.showNotModal = false;
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Validates the search params, and the
   * travel type.
   */
  private _validate(params: { [ key: string]: any }, travelType: number): boolean {
    if (travelType === this.travelType['NurFlug']) {
      if (!(params['flugZielflughafen'] &&
        params['flugZielflughafen'].flughaefen &&
        params['flugZielflughafen'].flughaefen.length > 0)) {
        params['flugZielflughafen'].noSelection = true;
        this._mpSidebar.open('sidebarAirport', params['flugZielflughafen']);
        return false;
      }

      if (!(params['flugAbflughafen'] &&
        params['flugAbflughafen'].flughaefen &&
        params['flugAbflughafen'].flughaefen.length > 0)) {
        params['flugAbflughafen'].noSelection = true;
        this._mpSidebar.open('sidebarAirport', params['flugAbflughafen']);
        return false;
      }
    }
    if (travelType === this.travelType['HotelUndFlug'] || travelType === this.travelType['NurHotel']) {
      if (!(params['reisezieleHotel'] &&
        params['reisezieleHotel'].ziele &&
        params['reisezieleHotel'].ziele.length > 0)) {
        params['reisezieleHotel'].noSelection = true;
        this._mpSidebar.open('sidebarTravelDestinationsHotel', params['reisezieleHotel']);
        return false;
      }
    }
    if (travelType === this.travelType['HotelUndFlug']) {
      if (!(params['flugHotelUndFlug'] &&
        params['flugHotelUndFlug'].flughaefen &&
        params['flugHotelUndFlug'].flughaefen.length > 0)) {
        params['flugHotelUndFlug'].noSelection = true;
        this._mpSidebar.open('sidebarAirport', params['flugHotelUndFlug']);
        return false;
      }
    }

    return true;
  }

  /**
   * Triggers the search for the clicked
   * travel topic, or the typed in travel
   * data.
   */
  search(evt: MouseEvent, tcCategory?: any): void {
    evt.preventDefault();
    const travelType = tcCategory ? this.travelType['NurHotel'] : this.tab;
    const params = tcCategory ?
      (tcCategory.DefaultParams ?
        JSON.parse(tcCategory.DefaultParams) :
        { Startindex: 0, Pagesize: 200 }) :
      this.params;

    if (!tcCategory && !this._validate(params, travelType)) {
      return;
    }

    const searchId = this._tcSearchParamsService.setParamsForSearch(params, travelType);

    if (travelType === this.travelType['NurFlug']) {
      if (typeof tcCategory !== 'undefined' && typeof searchId !== 'undefined') {
        this._router.navigateByUrl(`/${this._role}/FlugAuswahl?k=${tcCategory?.RkKategorieId}&sid=${searchId}`);
      } else if (typeof searchId !== 'undefined') {
        this._router.navigateByUrl(`/${this._role}/FlugAuswahl?sid=${searchId}`);
      }
    } else {
      if (typeof tcCategory !== 'undefined' && typeof searchId !== 'undefined') {
        this._router.navigateByUrl(`/${this._role}/HotelAuswahl?k=${tcCategory?.RkKategorieId}&sid=${searchId}`);
      } else if (typeof searchId !== 'undefined') {
        this._router.navigateByUrl(`/${this._role}/HotelAuswahl?sid=${searchId}`);
      }
    }
  }

}
