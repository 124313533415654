import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

import { Angulartics2 } from 'angulartics2';

import { MpLocalizationService } from './services/mp-localization.service';
import { MpSettingsService } from './services/mp-settings.service';
import { MpTitleService } from './services/mp-title.service';
import { MpSidebarService } from './components/sidebar/mp-sidebar.service';
import { MpCoreService } from './services/mp-core.service';

import { IsMobile } from './services/interfaces/is-mobile';
import { CookieService } from './services/cookie.service';

/**
 * This class provides the core functions.
 */
@Component({
  selector: 'mp-core-root',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit  {
  private _isMobile: IsMobile = {
    Android: () => { return navigator.userAgent.match(/Android/i); },
    BlackBerry: () => { return navigator.userAgent.match(/BlackBerry/i); },
    iOS: () => { return navigator.userAgent.match(/iPhone|iPad|iPod/i) || (navigator.userAgent.match(/Safari/i) && navigator.maxTouchPoints > 1); },
    Opera: () => { return navigator.userAgent.match(/Opera Mini/i); },
    Windows: () => { return navigator.userAgent.match(/IEMobile/i); },
    any: () => { return (this._isMobile.Android() || this._isMobile.BlackBerry() || this._isMobile.iOS() || this._isMobile.Opera() || this._isMobile.Windows()); }
  };

  constructor(
    private _ls: MpLocalizationService,
    private _mpSettings: MpSettingsService,
    private _router: Router,
    private _titleService: MpTitleService,
    private _mpSidebar: MpSidebarService,
    private _location: Location,
    private _mpCoreService: MpCoreService,
    private _cookieService: CookieService,
    private _angulartics: Angulartics2
  ) {
    this._ls.getLocalization();
    this._mpSettings.getSettings();
  }

  /**
   * Calls some basic functions and
   * sets watchers for location changes.
   */
  ngOnInit(): void {
    if (typeof window.ontouchstart !== 'undefined') {
      // @ts-ignore
      if (!document.documentElement.classList.contains('touch')) {
      // @ts-ignore
        document.documentElement.classList.add('touch');
      }

      // @ts-ignore
      if (document.documentElement.classList.contains('no-touch')) {
      // @ts-ignore
        document.documentElement.classList.remove('no-touch');
      }
    } else {
      // @ts-ignore
      if (document.documentElement.classList.contains('touch')) {
      // @ts-ignore
        document.documentElement.classList.remove('touch');
      }

      // @ts-ignore
      if (!document.documentElement.classList.contains('no-touch')) {
      // @ts-ignore
        document.documentElement.classList.add('no-touch');
      }
    }

    if (this._cookieService.getCookieValue('disableTracking')) {
      this._angulartics.settings.pageTracking.autoTrackVirtualPages = false;
    }

    this._stickyFooterFix();
    this._browsercheck();

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this._titleService.setTitle('');
        this._mpSidebar.close();

        if (event.navigationTrigger === 'popstate') {
          this._mpCoreService.usedBack = true;
        } else {
          this._mpCoreService.usedBack = false;
        }
      }
    });

    this._location.onUrlChange((url: string, state: any) => {
      if (url.charAt(0) !== '#') {
        url = `#${url}`;
      }

      this._mpCoreService.lastLocation = url;

      const body = document.body;

      if (body.getAttribute('openfilter') !== null) {
        if (body.getAttribute('openfilter') == 'true') {
          body.removeAttribute('openfilter');
        }
      }
    });
  }

  /**
   * Checks the browser name and
   * version and adds them as
   * classes to the html root element.
   */
  private _browsercheck(): void {
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let nameOffset, verOffset, ix;

    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);

      if ((verOffset = nAgt.indexOf("Version")) != -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "IE";
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("Edge")) != -1) {
      browserName = "Edge";
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);

      if ((verOffset = nAgt.indexOf("Version")) != -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);

      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    if ((ix = fullVersion.indexOf(";")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    if ((ix = fullVersion.indexOf(" ")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    if ((ix = fullVersion.indexOf(".")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    var majorVersion = parseInt('' + fullVersion, 10);

    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    var browser = browserName + '-' + majorVersion;

    if (/rv:11.0/i.test(navigator.userAgent)) {
      browser = 'IE-11';
    }

    if (/SamsungBrowser\/\d./i.test(navigator.userAgent)) {
      browser = 'Samsung-Browser';
    }

    const htmlElement = document.documentElement;

    if (htmlElement !== null) {
      if (!htmlElement.classList.contains(browser)) {
        htmlElement.classList.add(browser);
      }

      if (!htmlElement.classList.contains(browserName)) {
        htmlElement.classList.add(browserName);
      }

      if (this._isMobile.any()) {
        if (!htmlElement.classList.contains('MobileDevice')) {
          htmlElement.classList.add('MobileDevice');
        }
      }

      if (this._isMobile.iOS()) {
        if (!htmlElement.classList.contains('iOSDevice')) {
          htmlElement.classList.add('iOSDevice');
        }
      }
    }
  }

  /**
   * Fix the main conten and the footer
   * dimenions for fixed footer.
   */
  private _stickyFooterFix(): void {
    setTimeout(function () {
      if (window.innerWidth < 992) {
        const footer = document.getElementById('footer');
        const mainContent = document.getElementById('main-content');

        if (footer !== null && mainContent !== null) {
          if (footer.classList.contains('footer-margin')) {
            mainContent.style.paddingBottom = `${(footer.getBoundingClientRect().height + 48)}px`;
          } else {
            mainContent.style.paddingBottom = `${footer.getBoundingClientRect().height}px`;
          }
        }
      }
    }, 5000);

    window.addEventListener('resize', () => {
      const footer = document.getElementById('footer');
      const mainContent = document.getElementById('main-content');

      if (footer !== null && mainContent !== null) {
        if (window.innerWidth < 992) {
          if (footer.classList.contains('footer-margin')) {
            mainContent.style.paddingBottom = `${(footer.getBoundingClientRect().height + 48)}px`;
          } else {
            mainContent.style.paddingBottom = `${footer.getBoundingClientRect().height}px`;
          }
        } else {
          mainContent.removeAttribute('style');
        }
      }
    });

    const support = {};
    let last = +new Date();
    let delay = 300;
    const stack: Array<any> = [];

    const callback = () => {
      let now = +new Date();
      if (now - last > delay) {
        for (let i = 0; i < stack.length; i++) {
          stack[i]();
        }
        last = now;
      }
    };

    //@ts-ignore
    if (window.addEventListener) {
      this._testEvent('DOMSubtreeModified', support, callback, delay);
      this._testEvent('DOMNodeInserted', support, callback, delay);
      this._testEvent('DOMNodeRemoved', support, callback, delay);
    } else {
      this._decide(support, callback, delay);
    }

    const dummy = document.createElement("div");
    const el = document.documentElement;

    if (el !== null) {
      el.appendChild(dummy);
      el.removeChild(dummy);
    }

    const onDomChange = (fn: any, newdelay: number) => {
      if (newdelay) delay = newdelay;
      stack.push(fn);
    };

    // @ts-ignore
    window.onDomChange = onDomChange;

    onDomChange(() => {
      setTimeout(function () {
        if (window.innerWidth < 992) {
          const footer = document.getElementById('footer');
          const mainContent = document.getElementById('main-content');

          if (footer !== null && mainContent !== null) {
            if (footer.classList.contains('footer-margin')) {
              mainContent.style.paddingBottom = `${(footer.getBoundingClientRect().height + 48)}px`;
            } else {
              mainContent.style.paddingBottom = `${footer.getBoundingClientRect().height}px`;
            }
          }
        }
      }, 300);
    }, delay);
  }

  /**
   * Tests whether or not the browser
   * supports the given event.
   */
  private _testEvent(event: string, support: any, callback: any, delay: number): void {
    const el = document.documentElement;
    let remain = 3;

    if (el !== null) {
      const fn = () => {
        support[event] = true;
        el.removeEventListener(event, fn.bind(this), false);
        if (--remain === 0) this._decide(support, callback, delay);
      };

      el.addEventListener(event, fn.bind(this));
    }
  }

  /**
   * Callback for event tests.
   */
  private _decide(support: any, callback: any, delay: number): void {
    const el = document.documentElement;

    if (el !== null) {
      if (support.DOMNodeInserted) {
        window.addEventListener("DOMContentLoaded",
          () => {
            if (support.DOMSubtreeModified) {
              el.addEventListener('DOMSubtreeModified', callback, false);
            } else {
              el.addEventListener('DOMNodeInserted', callback, false);
              el.addEventListener('DOMNodeRemoved', callback, false);
            }
          },
          false);
        // @ts-ignore
      } else if (document.onpropertychange) {
        // @ts-ignore
        document.onpropertychange = callback;
      } else {
        this._naive(delay, callback);
      }
    }
  }

  /**
   * Naive approach for compatibility
   */
  private _naive(delay: number, callback: any) {
    let last = document.getElementsByTagName('*');
    let lastlen = last.length;

    const timer = setTimeout(function check() {
      let current = document.getElementsByTagName('*');
      let len = current.length;

      if (len != lastlen) {
        // @ts-ignore
        last = [];
      }

      for (let i = 0; i < len; i++) {
        if (current[i] !== last[i]) {
          callback();
          last = current;
          lastlen = len;
          break;
        }
      }

      setTimeout(check, delay);
    }, delay);
  }
}
