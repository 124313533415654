<div class="layout-container padding-bottom-80" id="payment" mpCoreShoppingBasketCheck *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-order-process-stepper key="bezahlung"></mp-core-order-process-stepper>
  <div class="row order-process"
       id="additional-pay">
    <div [hidden]="!sBItems || sBItems.length === 0"
         class="grid col-medium-9"
         id="payment-section">
      <div class="flex-grid margin-left-0 margin-right-8 flex-direction-row border-bottom padding-bottom-16 margin-top-8"
           id="additional-pay-headline">
        <h2 [hidden]="pointWorth - sBSum >= 0 "
            class="flex-col-10 padding-left-0 margin-bottom-8" [innerHTML]="ls.locs['locWkZuzahlung'].WkZuzahlungsseiteSubheader"></h2>
        <h2 [hidden]="pointWorth - sBSum < 0"
            class="flex-col-10 padding-left-0 margin-bottom-8" [innerHTML]="ls.locs['locWkZuzahlung'].WkZuzahlungsseiteSubheaderKeineZuzahlung"></h2>
        <div class="flex-col-2 flex-align-items-center flex-justify-cont-end"
             id="additional-pay-hdl-info-icon-container">
          <i (click)="showInfoSidebar()"
             class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open">
          </i>
        </div>
      </div>
      <div class="warenkorb-wrapper margin-bottom-8 padding-bottom-8"
           id="warenkorb">
        <div class="grid-container">
          <div class="shipping-status flex-grid margin-bottom-16 margin-left-0 margin-right-0 hide-on-medium padding-top-bottom-8 padding-left-right-16 display-flex flex-align-items-center flex-justify-cont-center">
            <div class="flex-col-4 flex-col-3-l flex-col-12-xs flex-justify-cont-center flex-align-items-center">{{ ls.locs['loc'].Praemie }}</div>
            <div class="flex-col-1 flex-col-2-m flex-justify-cont-center flex-align-items-start flex-direction-column"></div>
            <div class="flex-col-1 flex-col-2-m flex-justify-cont-center flex-align-items-center">{{ ls.locs['loc'].Anzahl }}</div>
            <div class="flex-col-2 flex-col-3-s flex-justify-cont-end flex-align-items-center">{{ ls.locs['locWkZuzahlung'].Praemienwert }}</div>
            <div class="flex-col-4 flex-col-5-l flex-justify-cont-center flex-align-items-center">
              <div class="flex-col-6 flex-col-7-l flex-justify-cont-center flex-align-items-center no-word-wrap">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}</div>
              <div class="flex-col-6 flex-col-5-l flex-justify-cont-end flex-align-items-center">{{ ls.locs['locWkZuzahlung'].Zuzahlung }}</div>
            </div>
          </div>
          <div *ngFor="let gsBItems of groupedShoppingBasket; trackBy: trackByIndex"
               class="wk-group">
            <div *ngFor="let c of gsBItems; let index = index; trackBy: trackByIndex">
              <div class="card-flat bg-color-white wk-card margin-bottom-24 padding-0 display-flex flex-grid flex-direction-column margin-right-8 margin-left-8"
                   [ngClass]="{'warning': c.ShownValidationMessages && c.ShownValidationMessages.length > 0, 'danger': c.HasBlockingValidationMessages}">
                <div *ngIf="!mpSettings.settings['WkZuzahlungSettings'].ManuelleZuzahlung" class="additional-pay-automatic"></div>
                <div class="floating-container wk-item flex-grid"
                     [ngClass]="{'warning': c.ZuzahlungFehler || c.ZuzahlungFehlerOhneWunschliste}">
                  <div class="flex-col-1 flex-col-3-m flex-col-3-xs flex-justify-cont-center flex-align-items-center wk-item-img-container">
                    <img class="wk-item-img"
                         src="{{c.Bild}}?width=100"
                         alt="{{c.ArtNr}}-{{c.PIN}}" />
                  </div>
                  <div class="flex-col-3 flex-col-2-l flex-col-9-m flex-col-9-xs flex-justify-cont-center flex-direction-column flex-align-items-start wk-item-description-container">
                    <span class="wk-company padding-right-8 width-100-pcnt text-overflow-ellipsis">{{c.Hersteller}}</span>
                    <strong class="wk-item-label padding-right-8 width-100-pcnt text-overflow-ellipsis display-block">{{c.ArtBez}} {{c.ArtBez2}}</strong>
                  </div>
                  <div class="flex-col-1 flex-col-2-m flex-justify-cont-center flex-align-items-start flex-direction-column wk-icon-buttons">
                    <div class="fav-heart" *ngIf="wlLoaded">
                      <mp-core-tooltip tooltipTitle="{{ isInWishlist(c.ArtNr) ? ls.locs['loc'].AusWunschlisteEntfernen : ls.locs['loc'].WunschlisteHinzufuegen }}"
                                       alignment="right">
                        <button class="mp-button padding-left-right-8"
                                (click)="toggleWishlist(c.ArtNr, c.CurrentAuspraegung.PIN, c.Key)">
                          <i class="svg-icon __size-18 __top-0 float-none-important vertical-align-middle"
                             [ngClass]="isInWishlist(c.ArtNr) ? '__clr-primary __favorite-filled' : '__clr-default __favorite'">
                          </i>
                        </button>
                      </mp-core-tooltip>
                    </div>
                    <div>
                      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].WKentfernen }}"
                                       alignment="right">
                        <button class="mp-button padding-left-right-8 display-block wk-clear-btn"
                                (click)="deleteItem(c.Key)">
                          <i class="svg-icon __clr-default __size-20 __top-0 __clear float-none-important vertical-align-middle"></i>
                        </button>
                      </mp-core-tooltip>
                    </div>
                  </div>
                  <div class="flex-col-1 flex-col-7-m flex-justify-cont-center flex-align-items-center wk-item-count">
                    <div>{{c.Anzahl}}</div>
                  </div>
                  <div class="flex-col-2 flex-col-3-m flex-justify-cont-center flex-align-items-end flex-direction-column wk-points padding-right-16">
                    <span>{{c.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                    <p class="wk-value-label shipping-costs text-align-center"
                       *ngIf="c.AuslandsversandPunkte">
                      {{ ls.locs['loc'].InklVersandkosten }}
                    </p>
                    <p class="wk-value-label shipping-costs text-align-center"
                       *ngIf="c.ExpressServicezuschlag">
                      {{ ls.locs['loc'].Inkl }} {{ ls.locs['loc'].Express }}
                    </p>
                    <p class="wk-value-label shipping-costs text-align-center"
                       *ngIf="c.ZusatzfragenPunkte">
                      {{ ls.locs['loc'].InklZusatzoptionen }}
                    </p>
                  </div>
                  <div class="wk-mobile-wrapper">
                    <div class="wk-item-count">
                      <span class="padding-right-4">{{ ls.locs['loc'].Stueckzahl }}:</span>
                      <span>{{c.Anzahl}}</span>
                    </div>
                    <div class="wk-points padding-right-48">
                      <div>
                        <span class="padding-right-4">{{ ls.locs['loc'].Praemienwert }}:</span>
                        <span>{{c.Punkte | integer}} {{ ls.locs['loc'].Punkte }}</span>
                        <p class="wk-value-label shipping-costs">{{ ls.locs['loc'].InklVersandkosten }}</p>
                        <p class="wk-value-label shipping-costs"
                           *ngIf="c.ZusatzfragenPunkte">
                          {{ ls.locs['loc'].InklZusatzoptionen }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-col-4 flex-col-5-l flex-justify-cont-center flex-align-items-center flex-wrap wk-additional-pay-slider padding-top-8 padding-bottom-8"
                       mpWkzzSbAdditionalPaymentSlider
                       #slider="mpWkzzSbAdditionalPaymentSlider"
                       [step]="mpSettings.settings['ZuzahlungSettings'].ManuelleZuzahlungStep"
                       [enforceStep]="mpSettings.settings['ZuzahlungSettings'].EnforceStep"
                       [item]="c"
                       *ngIf="mpSettings.settings['WkZuzahlungSettings'].ManuelleZuzahlung && c.CanZuzahlung && c.MinEingeloestePunkte < c.MaxEingeloestePunkte && mpSettings.settings['ZuzahlungSettings'].ManuelleZuzahlungStep">
                    <div class="flex-col-6 flex-col-12-m flex-justify-cont-end flex-align-items-center additional-payment-slider padding-right-16">
                      <span class="show-on-medium">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}:</span>
                      <mp-core-input [(model)]="c.EingeloestePunkteText"
                                     (blur)="slider.updateShoppingBasket(c.EingeloestePunkteText, c.EingeloestePunkte)"
                                     (keydown.enter)="slider.updateShoppingBasket(c.EingeloestePunkteText, c.EingeloestePunkte)"
                                     currencySign="{{ ls.locs['loc'].WaehrungPunkte }}">
                      </mp-core-input>
                    </div>
                    <div class="flex-col-6 flex-col-12-m flex-justify-cont-start flex-align-items-center additional-payment-slider padding-left-16">
                      <span class="show-on-medium">{{ ls.locs['locWkZuzahlung'].Zuzahlung }}:</span>
                      <mp-core-input [(model)]="c.ZuzahlungEuro"
                                     [mpDisabled]="c.ZuzahlungEuro === '-'"
                                     (blur)="slider.updateShoppingBasketEuro(c.ZuzahlungEuro)"
                                     (keydown.enter)="slider.updateShoppingBasketEuro(c.ZuzahlungEuro)"
                                     currencySign="€">
                      </mp-core-input>
                    </div>
                    <div class="flex-col-12 flex-col-12-m flex-justify-cont-center flex-align-items-center additional-payment-slider-slide">
                      <ngx-slider *ngIf="c.sliderOptions"
                                  class="mp-ngx-slider"
                                  [(value)]="c.EingeloestePunkte"
                                  [options]="c.sliderOptions"
                                  (userChangeStart)="slider.onSliderChangeStart($event)"
                                  (userChangeEnd)="slider.onSliderChangeEnd($event)">
                      </ngx-slider>
                    </div>
                  </div>
                  <div class="flex-col-4 flex-col-5-l flex-justify-cont-center flex-align-items-center wk-additional-pay-auto flex-align-cont-center"
                       *ngIf="mpSettings.settings['WkZuzahlungSettings'].ManuelleZuzahlung && (!c.CanZuzahlung || c.MinEingeloestePunkte >= c.MaxEingeloestePunkte)">
                    <div class="flex-col-6 flex-col-12-m flex-justify-cont-end flex-align-items-center additional-payment-auto-points padding-right-56 margin-right-8">
                      <span class="show-on-medium padding-right-8">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}:</span>
                      <span class="hide-on-medium">{{c.EingeloestePunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                      <span class="show-on-medium">{{c.EingeloestePunkte | integer}} {{ ls.locs['loc'].Punkte }}</span>
                    </div>
                    <div class="flex-col-6 flex-col-12-m flex-justify-cont-end flex-align-items-center additional-payment-auto-currency padding-right-16">
                      <span class="show-on-medium padding-right-8">{{ ls.locs['locWkZuzahlung'].Zuzahlung }}:</span>
                      <span [innerText]="c.ZuzahlungWert+' €'"></span>
                    </div>
                  </div>

                  <div *ngIf="!mpSettings.settings['WkZuzahlungSettings'].ManuelleZuzahlung" class="flex-col-4 flex-col-5-l flex-justify-cont-center flex-align-items-center wk-additional-pay-auto flex-align-cont-center">
                    <div class="flex-col-6 flex-col-12-m flex-justify-cont-end flex-align-items-center additional-payment-auto-points padding-right-40">
                      <span class="show-on-medium padding-right-8">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}:</span>
                      <span class="hide-on-medium">{{c.EingeloestePunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                      <span class="show-on-medium">{{c.EingeloestePunkte | integer}} {{ ls.locs['loc'].Punkte }}</span>
                    </div>
                    <div class="flex-col-6 flex-col-12-m flex-justify-cont-end flex-align-items-center additional-payment-auto-currency padding-right-40">
                      <span class="show-on-medium padding-right-8">{{ ls.locs['locWkZuzahlung'].Zuzahlung }}:</span>
                      <span [innerText]="c.ZuzahlungWert+' €'"></span>
                    </div>
                  </div>
                </div>
              </div>
              <mp-core-info-notification *ngFor="let message of c.ShownValidationMessages"
                                 iconPosition="left"
                                 infoState="warning"
                                 headline="{{ ls.locs['loc'].PraemieUeberpruefen }}"
                                 copyText="{{message.Message}}"
                                 class="margin-bottom-24 display-block">
              </mp-core-info-notification>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grid margin-top-32">
        <div *ngIf="ls.locs['locWkZuzahlung'].ZuzahlungRechnungHinweis" class="flex-col-5 flex-col-12-m flex-grid flex-wrap wk-infos">
          <mp-core-micro-copy [hidden]="(sBItems | sumOfItems:'ZuzahlungEuro') === 0 || !canAdditionalPayment"
                              infoState="inactive"
                              copyText="{{ ls.locs['locWkZuzahlung'].ZuzahlungRechnungHinweis }}"
                              class="flex-col-12 padding-bottom-8">
          </mp-core-micro-copy>
        </div>

        <div *ngIf="!ls.locs['locWkZuzahlung'].ZuzahlungRechnungHinweis" class="flex-col-5 flex-col-12-m flex-grid flex-wrap"></div>
        <div class="flex-col-7 flex-col-12-m flex-direction-column wk-sum">
          <div class="items-shipment flex-grid">
            <span class="flex-col-7 flex-col-8-xs flex-justify-cont-end wk-shipping-service-lkz">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}</span>
            <span class="flex-col-5 flex-col-4-xs flex-justify-cont-end padding-right-48 no-word-wrap">{{sBItems | sumOfItems:'EingeloestePunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
          </div>
          <div class="items-total-cost flex-grid">
            <span class="flex-col-7 flex-col-8-xs flex-justify-cont-end">{{ ls.locs['locWkZuzahlung'].Gesamtzuzahlung }}</span>
            <span class="flex-col-5 flex-col-4-xs flex-justify-cont-end padding-right-48 no-word-wrap">{{sBItems | sumOfItems:'ZuzahlungEuro' | currency:'EUR':''}} €</span>
          </div>
        </div>
      </div>
      <div [hidden]="(sBItems | sumOfItems:'ZuzahlungEuro') === 0 || !canAdditionalPayment">
        <div class="row margin-top-32">
          <div class="grid col-large-12">
            <form name="formPunkte"
                  class="margin-bottom-24"
                  novalidate=""
                  id="additional-payment-methods">
              <h2 class="padding-bottom-24"
                  *ngIf="provider.length > 1">
                {{ ls.locs['locZuzahlung'].ZuzahlungSchrittZahlungsartWaehlenTitel }}
              </h2>
              <div class="margin-bottom-24 col-medium-4 grid"
                   id="methods-panel"
                   *ngIf="provider.length > 1">
                <div id="methods-panel-inner">
                  <mp-core-radio-button *ngFor="let p of provider"
                            mpId="radio-{{p.Key}}"
                            label="{{p.Bezeichnung}}"
                            [(model)]="mpOrderProcess.paymentType"
                            [ngClass]="p.possible ? mpOrderProcess.paymentType == p.Key ? 'zuzahlung-type-active' : '' : 'additional-pay-method-inactive'"
                            (click)="setType(p.Key)"
                            value="{{p.Key}}"
                            mpGroup="zuzahlung-typen">
                  </mp-core-radio-button>
                </div>
              </div>
              <div class="col-medium-1 grid"
                   *ngIf="provider.length > 1">
              </div>
              <ng-container *ngFor="let p of provider">
                <div class="col-medium-7 grid"
                     id="method-notes"
                     *ngIf="mpOrderProcess.paymentType === p.Key && p.TemplateText && provider.length > 1">
                  <h3 [innerText]="p.TemplateTitel"></h3>
                  <div class="margin-bottom-24"
                       [innerHTML]="p.TemplateText">
                  </div>
                </div>
                <div *ngIf="mpOrderProcess.paymentType === p.Key && p.TemplateText && provider.length > 1">
                  <ng-container *ngComponentOutlet="sbAdditionalPaymentService.getChildComponent(p.TemplateUrl); injector: getInjector(p);"></ng-container>
                </div>
              </ng-container>
              <h2 *ngIf="provider.length === 1 && provider[0].TemplateText">{{provider[0].TemplateTitel}}</h2>
              <div *ngIf="provider.length === 1 && provider[0].TemplateText"
                   class="margin-bottom-24"
                   [innerHTML]="provider[0].TemplateText">
              </div>
              <div *ngIf="provider.length === 1 && provider[0].TemplateText">
                <ng-container *ngComponentOutlet="sbAdditionalPaymentService.getChildComponent(provider[0].TemplateUrl); injector: getInjector(provider[0]);"></ng-container>
              </div>
            </form>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div [hidden]="!errors"
               class="grid col-large-6">
            <p class="inputRequired"
               *ngFor="let e of errors"
               [innerText]="e">
            </p>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="bestellung-nav-row floating-container margin-top-16 hide-on-mobile">
        <a href="#/{{role}}/Home"
           class="mp-button float-left"
           id="back-to-shop-button">
          {{ ls.locs['loc'].BestellungAbbrechen }}
          <i class="svg-icon __clr-default __size-18 margin-left-4 __restore float-none-important"></i>
        </a>
        <a class="mp-button _prim float-right"
           id="to-payment-button"
           (click)="goToNextIfCanOrder($event)"
           [ngClass]="{disabled: getAdditionalPaymentAmount() > 0 &&(!canOrder || !hasValidPaymentMethod())}">
          {{ ls.locs['locWkZuzahlung'].BezahlungWeiter }}
        </a>
        <a *ngIf="currentStep['PreviousUrl']"
           href="#/{{role}}/{{currentStep['PreviousUrl']}}"
           id="go-back-button"
           class="mp-button float-right margin-right-32">
          {{ ls.locs['loc'].Zurueck }}
        </a>
      </div>
      <div stickyBottom
           class="bestellung-nav-row floating-container show-on-mobile bg-color-white wk-bottom-buttons z-index-3">
        <div class="flex-grid flex-direction-row flex-wrap margin-left-right-0">

          <a *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer"
             class="mp-button _light-grey padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column margin-top-0"
             href="tel:{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}">
            <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].Support }}</span>
          </a>
          <a *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer"
             class="mp-button _prim padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
             (click)="goToNextIfCanOrder($event)"
             [ngClass]="{disabled: getAdditionalPaymentAmount() > 0 &&(!canOrder || !hasValidPaymentMethod())}">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['locWkZuzahlung'].BezahlungWeiter }}</span>
          </a>

          <a *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer"
             class="mp-button _prim padding-top-bottom-8 flex-col-12-xs flex-col-12-s flex-col-12-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
             (click)="goToNextIfCanOrder($event)"
             [ngClass]="{disabled: getAdditionalPaymentAmount() > 0 &&(!canOrder || !hasValidPaymentMethod())}">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['locWkZuzahlung'].BezahlungWeiter }}</span>
          </a>
          <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-between">
            <a class="mp-button"
               *ngIf="currentStep['PreviousUrl']"
               href="#/{{role}}/{{currentStep['PreviousUrl']}}">
              {{ ls.locs['loc'].Zurueck }}
            </a>
            <a class="mp-button"
               href="#/{{role}}/Home">
              {{ ls.locs['loc'].Abbrechen }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid col-medium-3"
         id="additional-pay-order-overview">
      <mp-core-order-overview nextText="{{ ls.locs['locWkZuzahlung'].BezahlungWeiter }}" [buttonFunction]="goToNextIfCanOrderFunc" [buttonDisabled]="getAdditionalPaymentAmount() > 0 &&(!canOrder || !hasValidPaymentMethod())"></mp-core-order-overview>
    </div>
  </div>
  <mp-core-modal [show]="showDeleteDialog"
            modalClass="size-warning"
            id="wk-modal"
            [buttons]="[{text: ls.locs['loc'].Ja, action: deleteItemFunc, actionParam: addToWlKey, class: '_prim'}, {text: ls.locs['loc'].Nein, action: hideDeleteDialog, class: '_sec'}]">
    <span [innerHTML]="ls.locs['loc'].PraemieAusWarenkorbLoeschen"></span>
    <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important"
       (click)="closeWkModal()">
    </i>
  </mp-core-modal>
  <mp-core-support class="hide-on-medium"></mp-core-support>
</div>
