import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';
import { CookieService } from '../../../../services/cookie.service';

/**
 * This class provides the data and
 * functions for the data privacy page.
 */
@Component({
  selector: 'mp-core-data-privacy',
  templateUrl: './data-privacy.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DataPrivacyComponent implements OnInit, OnDestroy {

  public trackingOn: boolean = false;
  public text: { [key: string]: any } = {};
  public textsLoaded: boolean = false;
  public optOutInText: boolean = false;

  private _getTextAndTitleSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _menuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
    private _cookieService: CookieService
  ) { }

  /**
   * Checks the disableTracking cookie, sets
   * the title and the menu highlighting, gets
   * the content for the page.
   */
  ngOnInit(): void {
    this.trackingOn = !(this._cookieService.getCookieValue('disableTracking') !== '');
    this._breadcrumbService.setCurrentSiteWithLoc('Datenschutz', 'loc', 'Datenschutz');
    this._titleService.setTitleFromLoc('Datenschutz');
    this._menuHighlight.setMenuHighlight('support');

    this._getTextAndTitleSubscription = this._apiService.getRequest('/api/Texte/getTextAndTitle?key=tn-datenschutz').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.text = data.Records[0];
        this.optOutInText = this.text['PreparedText'].indexOf('matomo-opt-out') > -1;
        this.textsLoaded = true;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTextAndTitleSubscription !== 'undefined') {
      this._getTextAndTitleSubscription.unsubscribe();
    }
  }

  /**
   * Handles the change of the cookies
   * checkbox.
   */
  handleTrackingOn(): void {
    if (this.trackingOn) {
      this._cookieService.deleteCookie('disableTracking');
    } else {
      this._cookieService.setCookie('disableTracking=1', { secure: true });
    }
  }

}
