import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * This class provides the data and functions
 * for the redeem voucher page.
 */
@Component({
  selector: 'mp-leas-redeem-voucher',
  templateUrl: './redeem-voucher.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RedeemVoucherComponent implements OnInit, OnDestroy {

  public redeemVoucherSliderProperties: OwlOptions = {
    loop: true,
    nav: true,
    margin: 16,
    items: 1,
    dots: true
  };
  public errorCode: string = '';
  public errorInvalid: boolean = false;
  public loading: boolean = false;
  public code: string = '';

  private _redeemVoucherSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._redeemVoucherSubscription !== 'undefined') {
      this._redeemVoucherSubscription.unsubscribe();
    }
  }

  /**
   * Redeems the voucher.
   */
  redeem(): void {
    this.errorInvalid = false;
    this.errorCode = '';

    if (this.code === '') {
      this.errorInvalid = true;
      return;
    }

    this.loading = true;

    this._redeemVoucherSubscription = this._apiService.postRequest('/api/Gutschein/einloesen', { Code: this.code }).subscribe((data: any) => {
      if (data.Result === 'ERROR') {
        this.errorCode = data.Message;
        this._mpMessaging.openDangerPanel(data.Message);
      } else {
        this.code = '';
        this._mpMessaging.openSuccessPanel(data.Message);
      }

      this.loading = false;
    },
    (error: any) => {
      this.loading = false;
    });
  }

}
