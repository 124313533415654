import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';

import { TcPermissions } from './interfaces/tc-permissions';

/**
 * This class provides the functions to fetch the
 * permissions from the backend api.
 */
@Injectable({
  providedIn: 'root'
})
export class MpTcPermissionsService {

  private _permissionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public permissions: TcPermissions = {
    CanUseReisekonfigurator: false
  };
  public permissionsLoaded: Observable<boolean> = this._permissionsLoaded.asObservable();
  public permissionsReadyLoaded: boolean = false;

  constructor(private _apiService: ApiService) { }

  /**
   * Calls the api to get the settings data.
   */
  getPermissions(): void {
    this._apiService.getRequest('/api/RkCommon/GetRkPermissions/').subscribe((data: any) => {
      this.permissions = data.Records[0];
      this.permissionsReadyLoaded = true;
      this._permissionsLoaded.next(true);
    },
    (error: any) => {
      console.log(error.message);
    });
  }
}
