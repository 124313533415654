<div id="jtTNCockpitKonto" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async) && tnIdAvailable">
  <mp-core-data-table fieldsAction="/api/Konto/getKontoFieldsLeas"
                 title="{{ ls.locs['loc'].Praemienkonto }}"
                 listAction="/api/Konto/getKontoLeas"
                 loaderName="ptcpntCockpitAccountLoader"
                 [paging]="true"
                 [footer]="true"
                 [recLoadedFunc]="recLoadedFunc"
                 defaultSorting="Datum DESC"
                 [disableCache]="true">
  </mp-core-data-table>
  <div class="floating-container margin-top-24">
    <button class="mp-button _prim" (click)="togglePointsBooking()">
      {{ ls.locs['loc'].ManuellePunktebuchung }}
    </button>

    <a *ngIf="loader !== null && loader.getExcelParams && loader.isReady" href="{{accountExcelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <mp-core-modal [show]="showPointsBooking"
            title="{{ ls.locs['loc'].ManuellePunktebuchung }}"
            [buttons]="[{text: ls.locs['loc'].Abbrechen, action: cancelPointsBooking}, {text: ls.locs['loc'].Bestaetigen, action: confirmPointsBooking}]">
    <mp-core-select label="{{ ls.locs['loc'].Art }}"
               mpId="punktebuchung-art"
               [(model)]="bookingData['TypeObject']"
               textProperty="Art"
               [elements]="bookingTypes"
               [mpRequired]="true">
    </mp-core-select>
    <mp-core-input label="{{ ls.locs['loc'].Bezeichnung }}"
              [(model)]="bookingData['Bezeichnung']"
              mpId="punktebuchung-bezeichnung"
                [mpRequired]="true">
    </mp-core-input>
    <mp-core-input label="{{ ls.locs['loc'].Anzahl }}"
              [(model)]="bookingData['Anzahl']"
              mpType="number"
              mpId="punktebuchung-anzahl"
                [mpRequired]="true">
    </mp-core-input>
    <mp-core-input label="{{ ls.locs['loc'].Punkte }}"
              [(model)]="bookingData['Punkte']"
              mpType="number"
              mpId="punktebuchung-punkte"
                [mpRequired]="true">
    </mp-core-input>
  </mp-core-modal>
</div>
