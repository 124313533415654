import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';

/**
 * This class provides the data
 * and functions for the managers
 * home page.
 */
@Component({
  selector: 'mp-core-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public homeStages: Array<any> = [];
  public text: string = '';
  public title: string = '';
  public getStagesSubscription: Subscription | undefined;
  public getTextAndTitleSubscription: Subscription | undefined;

  public imgWidth: string = '';
  public imgHeight: string = '';

  constructor(
    public mpSettings: MpSettingsService,
    public sanitizer: DomSanitizer,
    public apiService: ApiService
  ) { }

  /**
   * Gets stages, text, and title for
   * the manager home page.
   */
  ngOnInit(): void {
    this.getStagesSubscription = this.apiService.getRequest('/api/Home/getHomeBuehne?exactOnly=true').subscribe((data: any) => {
      this.homeStages = data.Records;
      this.imgHeight = this.mpSettings.settings['DisplaySettings'].BuehnenBildHoehe;
      this.imgWidth = this.mpSettings.settings['DisplaySettings'].BuehnenBildBreite;

      this.getTextAndTitleSubscription = this.apiService.getRequest('/api/Texte/getTextAndTitle', false, {
        params: {
          key: 'manager-home-text'
        }
      }).subscribe((data: any) => {
        if (data.Records[0] !== null) {
          this.text = this.trustAsHtml(data.Records[0].PreparedText); // Warning: trustAsHtml removes <iframe>
          this.title = this.trustAsHtml(data.Records[0].PreparedTitle);
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.getStagesSubscription !== 'undefined') {
      this.getStagesSubscription.unsubscribe();
    }

    if (typeof this.getTextAndTitleSubscription !== 'undefined') {
      this.getTextAndTitleSubscription.unsubscribe();
    }
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
