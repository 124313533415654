import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { MpLocalizationService } from './../services/mp-localization.service';

/**
 * This class provides a service to building
 * breadcrumbs that enable navigation in store
 */
@Injectable({
  providedIn: 'root'
})
export class MpBreadcrumbService {

  public redirectedByBreadcrumb: boolean = false;

  private _dashboardTabValue: any = {};

  private _getDashboardTab: Subscription | undefined;

  private _dashboardTab: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public dashboardTab: Observable<any> = this._dashboardTab.asObservable();
  private _currentSite: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public currentSite: Observable<any> = this._currentSite.asObservable();
  private _currentSteps: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public currentSteps: Observable<any> = this._currentSteps.asObservable();
  private _locsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService
  ) { }

  /**
  * Sets designation for breadcrunb from current page from locs
  */
  setCurrentSiteWithLoc(newPath: any, loc: any, locProperty: any, steps?: any) {
    this.ls.getLocalization();
    this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {

        this._currentSite.next({
            Path: newPath,
            Loc: this.ls.locs[loc][locProperty]
          });

          if (steps) {
            steps.forEach((step: any) => {
              if (step.LocType) {
                step.Loc = this.ls.locs[step.LocType][step.locProperty];
              }
            });
          }

          this._currentSteps.next(steps);

          this.redirectedByBreadcrumb = false;
      }
    });
  }

  /**
  * Sets designation for breadcrunb from current page with text
  */
  setCurrentSiteWithText(newPath: any, title: any, steps?: any) {
    this._currentSite.next({
      Path: newPath,
      Loc: title
    });

    this._currentSteps.next(steps);
      
    this.redirectedByBreadcrumb = false;
  }

  /**
  * Sets current page from component
  */
  setCurrentSite(newPath: any, steps?: any){
    this._currentSite.next(newPath);
    this.redirectedByBreadcrumb = false;

    this._currentSteps.next(steps);
  }

  /**
  * Sets current dashboard tab from component
  */
  setDashboardTab(newPath: any) {
    this._dashboardTab.next(newPath);
    this._dashboardTabValue = newPath;
    this.redirectedByBreadcrumb = false;
  }

  /**
  * Fallback since it was previously called
  */
  getPath() {
    this.getDashboardTab();
  }

  /**
  * Fallback since it was previously called
  */
  setPath(newPath: any) {
    this.setDashboardTab(newPath);
  };

  /*
   * Gets value of dashboard tab
   */
  getDashboardTab() {
    const bakPath = this._dashboardTabValue;
    this._dashboardTab.next({});
    this._dashboardTabValue = {};
    return bakPath;
  };
}
