<div id="sidebar-date-time" class="sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="texst-align-left">
      {{mpSidebar.params['Headline']}}
    </div>
    <div class="divider margin-bottom-8"></div>
    <div>
      <mp-core-select label=""
                      [elements]="yearRange"
                      mpId="sidebar-datum"
                      [(model)]="selection['year']"
                      (ngChange)="selection['year'] = $event; yearChange();"
                      class="padding-left-right-16 display-block margin-left-0 box-sizing-border-box margin-top-16">
      </mp-core-select>
    </div>
    <div id="sidebar-time-selects"
         class="display-flex margin-left-12 margin-right-12 margin-top-12">
      <mp-core-select [elements]="timeHours"
                      mpId="uhrzeit-stunden"
                      valueProperty="value"
                      textProperty="text"
                      [(model)]="selection['timeHours']"
                      class="width-full">
      </mp-core-select>
      <mp-core-select [elements]="timeMinutes"
                      mpId="uhrzeit-minuten"
                      valueProperty="value"
                      textProperty="text"
                      [(model)]="selection['timeMinutes']"
                      class="width-full">
      </mp-core-select>
    </div>
    <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 width-100-pcnt bg-color-white z-index-3">
      <div id="overlay-buttons">
        <a href=""
           (click)="setDirectly($event)"
           class="mp-button _prim display-block">
          {{ ls.locs['loc'].Sofort }}
        </a>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                           [ifNeeded]="false"
                           scrollItemHeight="80"
                           desktopItemHeight=".calendar > div"
                           scrollArea="#datum-scroll-area"
                           scrollDirection="up"
                           scrollFactor="1"
                           class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="datum-scroll-area-container">
      <div id="datum-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="minDate"
                            [(maxDate)]="maxDate"
                            [(model)]="selection['date']">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                           [ifNeeded]="false"
                           scrollItemHeight="80"
                           desktopItemHeight=".calendar > div"
                           scrollArea="#datum-scroll-area"
                           scrollDirection="down"
                           scrollFactor="1"
                           class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].EingabenSpeichern }}
      </a>
    </div>
    <div id="overlay-buttons">
      <a href=""
         [hidden]="!mpSidebar.params['initDate'] && !mpSidebar.params['noDateText']"
         (click)="delete($event)"
         class="mp-button _sec display-block">
        {{ ls.locs['loc'].DatumLoeschen }}
      </a>
    </div>
  </div>
</div>
