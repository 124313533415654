import { Component, OnInit } from '@angular/core';

import { MpTitleService } from '@core/services/mp-title.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';

/**
 * This class provides the functionalities
 * for the home page of the charity
 * module.
 */
@Component({
  selector: 'mp-leas-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  constructor(
    public ls: MpLocalizationService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
  ) { }

  /**
   * Sets the title and the menu highlight.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('home');
    this._titleService.setTitleFromLoc('Home');
  }

}
