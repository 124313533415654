import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

/**
 * This class provides the container for
 * manager daahbaord cards.
 */
@Component({
  selector: 'mp-core-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ManagerDashboardComponent implements OnInit {

  @Input() public title: string = '';
  @Input() public sources: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
