<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].TipAndWinSpielakzeptanz }}</h1>
  <mp-core-data-table *ngIf="campaignsLoaded" title="{{ ls.locs['loc'].TipAndWinSpielakzeptanz }}"
                 infotextShorten="Spielakzeptanz"
                 fieldsAction="/api/TipAndWin/GetSpielakzeptanzFields"
                 listAction="/api/TipAndWin/GetSpielakzeptanz"
                 loaderName="tipAndWinGameAcceptanceLoader"
                 [paging]="true"
                 [footer]="true"
                 [showDatePicker]="false"
                 [recLoadedFunc]="recLoadedFunc"
                 [disableCache]="true"
                 [headerSelectTransclude]="true">
    <mp-core-select [mpRequired]="true" [elements]="campaigns" textProperty="Name" valueProperty="AktionId" [model]="selectedCampaignId" (modelChange)="selectedCampaignId = $event; changeCampaign()" label="{{ ls.locs['loc'].Aktion }}"></mp-core-select>
  </mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
