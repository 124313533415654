<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table title=""
                      *ngIf="loaderName !== ''"
                      fieldsAction="/api/Statistik/GetWinterspiele2021StatistikChildFields" 
                      listAction="/api/Statistik/GetWinterspiele2021StatistikChild" 
                      loaderName="{{loaderName}}"
                      [paging]="false" 
                      [footer]="false" 
                      [startEmpty]="true" 
                      defaultSorting="Anfragedatum DESC">
  </mp-core-data-table>
</div>
