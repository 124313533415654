import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { TipAndWinService } from './../../../../services/tip-and-win.service';

/**
 * This class provides the main data
 * and functions for tip and win.
 */
@Component({
  selector: 'mp-taw-tip-and-win',
  templateUrl: './tip-and-win.component.html',
  styleUrls: ['./tip-and-win.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinComponent implements OnInit, OnDestroy {

  public tabVisible: string = 'tipps';
  public loading: boolean = false;
  public campaign: string = '';

  private _checkQualificationSubscription: Subscription | undefined;
  private _getQueryParamsSubscription: Subscription | undefined;
  private _loaderSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _svgLoader: SvgLoaderService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mpMessaging: MpMessagingService,
    private _tipAndWinSerice: TipAndWinService
  ) { }

  /**
   * Gets the campaign param, checks whether
   * or not the participant ist qualified for
   * this campaign, and subscribes to the
   * svg loading animations.
   */
  ngOnInit(): void {
    this._loaderSubscription = this._svgLoader.svgLoaderState.subscribe((count: number) => {
      if (count > 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });

    this._svgLoader.startLoading();

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['aktion'] !== 'undefined') {
        this.campaign = params['aktion'];
        this._tipAndWinSerice.campaign = this.campaign;

        this._checkQualificationSubscription = this._apiService.getRequest(`/api/TipAndWin/IsQualifiziert?aktion=${this.campaign}`).subscribe((data: any) => {
          this._svgLoader.finishLoading();

          if (data.Result === 'OK') {
            if (!data.Records[0]) {
              this._router.navigateByUrl('/');
            }
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
          }
        },
        (error: any) => {
          this._svgLoader.finishLoading();
          this._mpMessaging.openErrorPanel(error);
        });
      } else {
        this._svgLoader.finishLoading();
        this._router.navigateByUrl('/');
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._checkQualificationSubscription !== 'undefined') {
      this._checkQualificationSubscription.unsubscribe();
    }

    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._loaderSubscription !== 'undefined') {
      this._loaderSubscription.unsubscribe();
    }
  }

  /**
   * Sets the active tab.
   */
  setTab(tab: string): void {
    this.tabVisible = tab;
  }

}
