<div class="calendar">
  <div *ngFor="let month of months; let monthIndex = index"
       class="display-flex flex-direction-column month-block">
    <span class="bold font-size-s text-align-center"
          [innerText]="month.month.clone().format('MMMM	YYYY')">
    </span>
    <table>
      <tr class="font-size-xs">
        <th class="text-align-center"
            *ngFor="let weekday of month.weekdays"
            [innerText]="weekday">
        </th>
      </tr>
      <tr *ngFor="let week of month.weeks"
          class="calendar-week">
        <td class="text-align-center font-size-s"
            *ngFor="let day of week; trackBy: trackByIndex"
            (click)="setRange(day.day)"
            [ngClass]="{empty: !day, active: day && day.active, disabled: day && day.disabled, start: day && day.start && isRange, end: day && day.end && isRange}">
          <span *ngIf="day && !day.firstActiveDay" [innerText]="day.day && day.day.format('D')"></span>
          <span *ngIf="day && day.firstActiveDay" mpCoreFirstActiveDay attr.data-month-index="{{monthIndex}}" [innerText]="day.day && day.day.format('D')"></span>
        </td>
      </tr>
    </table>
  </div>
</div>
