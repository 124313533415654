import { Component, OnInit, Input, OnDestroy, ElementRef, ViewEncapsulation  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { SvgLoaderService } from './svg-loader.service';

@Component({
  selector: 'mp-core-svg-loader',
  templateUrl: './svg-loader.component.html',
  styleUrls: ['./svg-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SvgLoaderComponent implements OnInit, OnDestroy {

  public showLoading: boolean = false;
  public svgPath: string = '';
  public svgCode: SafeHtml = '';

  @Input() public svg: string = '';
  @Input() public svgAnimation: string = '';
  @Input() public time: number = 0;
  @Input() public loadingText: string = '';

  private _loaderSubscription: Subscription | undefined;
  private _svgSubscription: Subscription | undefined;
  private _svg: SVGPathElement | null = null;
  private _svgLoadInterv: any | undefined;
  private _headerHeight: number = 0;
  private _navbarHeight: number = 0;
  private _footerHeight: number = 0;
  private _headerBCR: number = 0;
  private _creditsHeight: number = 0;
  private _header: HTMLElement | null = null;
  private _navbar: HTMLElement | null = null;
  private _footer: HTMLElement | null = null;
  private _credits: HTMLElement | null = null;
  private _animationStarted: boolean = false;
  private _styleElemAdded: boolean = false;

  constructor(
    private _svgLoader: SvgLoaderService,
    private _element: ElementRef,
    private _httpClient: HttpClient,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this._header = document.getElementById('header');
    this._navbar = document.getElementById('navbar');
    this._footer = document.getElementById('footer');
    this._credits = document.getElementById('credits');
    this._animationStarted = false;
    this.svgPath = '/themes/default/media/animation-svg/' + this.svg + '.svg';
    this._element.nativeElement.id = this.svg;


    if (this.time === 0) {
      this.time = 1.5;
    }

    this._svgSubscription = this._httpClient.get(this.svgPath, { responseType: 'text' }).subscribe((svgContent: any) => {
      this.svgCode = this._sanitizer.bypassSecurityTrustHtml(svgContent);

      setTimeout(() => {
        this._loadNecessary();
        this._setLoaderHeight(true);
      }, 1000);

      this._loaderSubscription = this._svgLoader.svgLoaderState.subscribe((count: number) => {
        setTimeout(() => {
          this._loadNecessary();
          this._setLoaderHeight(false);

          if (count > 0) {
            this.showLoading = true;
          } else {
            this.showLoading = false;
          }
        }, 1000);
      });

      window.addEventListener('scroll', () => {
        if (this._svgLoader.svgLoading > 0) {
          this._setLoaderHeight(false);
        }
      });
    });
  }

  /**
   * Unsets subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._loaderSubscription !== 'undefined') {
      this._loaderSubscription.unsubscribe();
    }

    if (typeof this._svgSubscription !== 'undefined') {
      this._svgSubscription.unsubscribe();
    }
  }

  /**
   * Checks whether or not the
   * loading animation is neccessary.
   */
  private _loadNecessary(): void {
    if (this._svgLoader.svgLoading > 0 && !this._animationStarted) {
      this.showLoading = true;

      switch (this.svgAnimation) {
        case 'draw':
          this._drawLoader();
          break;
        case 'show':
          break;
        default:
          this._drawLoader();
      }
    }
  }

  /**
   * Sets the height of the svg
   * loader.
   */
  private _setLoaderHeight(initial: boolean): void {
    if (this._navbar !== null && this._navbar.classList.contains('hl-sticky')) {
      this._headerHeight = 0;
    } else {
      this._headerHeight = this._header !== null ? parseInt(getComputedStyle(this._header).getPropertyValue('height').replace('px', '')) : 0;
      this._headerBCR = this._header !== null ? this._header.getBoundingClientRect().top : 0;
      this._creditsHeight = this._credits !== null ? !this._credits.classList.contains('credits-hidden') ? parseInt(getComputedStyle(this._credits).getPropertyValue('height').replace('px', '')) : 0 : 0;

      if (window.innerWidth >= 992) {
        this._creditsHeight = 0;
      }

      this._headerHeight = this._headerHeight + this._headerBCR + this._creditsHeight;
    }

    const windowHeight = window.innerHeight;
    const footerTop = this._footer !== null ? this._footer.getBoundingClientRect().top : 0;

    this._navbarHeight = this._navbar !== null ? parseInt(getComputedStyle(this._navbar).getPropertyValue('height').replace('px', '')) : 0;
    this._footerHeight = this._footer !== null ? windowHeight - footerTop > 0 ? windowHeight - footerTop : 0 : 0;

    const svgloader: HTMLElement | null = document.querySelector('.svg-loader');

    if (svgloader !== null) {
      svgloader.style.height = 'calc(100% - ' + (this._headerHeight + this._navbarHeight + this._footerHeight).toString() + 'px)';
      svgloader.style.top = (this._headerHeight + this._navbarHeight).toString() + 'px';
    }

    if (initial) {
      const obeserveFooterInterv = setInterval(() => {
        if (window.innerHeight - (this._footer !== null ? this._footer.getBoundingClientRect().top : 0) !== this._footerHeight) {
          if (typeof obeserveFooterInterv !== 'undefined') {
            clearInterval(obeserveFooterInterv);
          }

          this._footerHeight = 0;

          if (svgloader !== null) {
            svgloader.style.height = 'calc(100% - ' + (this._headerHeight + this._navbarHeight + this._footerHeight).toString() + 'px)';
          }
        }
      }, 50);
    }
  }

  /**
   * Draws the svg, that means it
   * animates the svg.
   */
  private _drawLoader(): void {
    this._svgLoadInterv = setInterval(() => {
      const firstPathInSvg: SVGPathElement | null = document.querySelector(`#${this.svg} svg path:first-of-type`);
      this._svg = firstPathInSvg;

      if (this._svg !== null) {
        this._stopSvgLoadInterv();
        let svgGrey;
        const secondPathInSvg: SVGPathElement | null = document.querySelector(`#${this.svg} svg path:nth-of-type(2)`);

        if (secondPathInSvg === null) {
          // @ts-ignore
          this._svg.parentElement.insertAdjacentElement('afterbegin', this._svg.cloneNode(true));
          svgGrey = firstPathInSvg;
          this._svg = document.querySelector(`#${this.svg} svg path:nth-of-type(2)`);
        } else {
          svgGrey = firstPathInSvg;
          this._svg = secondPathInSvg;
        }

        if (firstPathInSvg !== null && this._svg !== null && this._svg.parentElement !== null && !this._styleElemAdded) {
          const pathLength = firstPathInSvg.getTotalLength();

          this._svg.parentElement.insertAdjacentHTML('afterbegin', `
            <style>
              .svg-loader:not([hidden]) svg > path:last-of-type {
                stroke-dasharray: ${pathLength};
                stroke-dashoffset: ${pathLength};
                animation: svg-loading-animation ${this.time * 2}s linear infinite;
              }

              @keyframes svg-loading-animation {
                0% {
                  stroke-dashoffset: ${pathLength};
                }

                0.01% {
                  stroke-dashoffset: ${pathLength};
                }

                50% {
                  stroke-dashoffset: ${pathLength * 2};
                }

                50.01% {
                  stroke-dashoffset: ${pathLength * 2};
                }

                99.9% {
                  stroke-dashoffset: ${pathLength * 3};
                }

                100% {
                  stroke-dashoffset: ${pathLength * 3};
                }
              }
            </style>
          `);

          this._styleElemAdded = true;
        }

        if (this._svg !== null && this._svg.parentElement !== null) {
          this._svg.parentElement.style.display = 'block';
        }
      }

      if (this._animationStarted === false) {
        this._animationStarted = true;
      }
    }, 50);
  }

  /**
   * Stops the svg animation interval.
   */
  private _stopSvgLoadInterv() {
    if (typeof this._svgLoadInterv !== 'undefined') {
      clearInterval(this._svgLoadInterv);
    }
  }

}
