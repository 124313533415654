import { SidebarAirportComponent } from './sidebar-airport/sidebar-airport.component';
import { SidebarTravelDestinationsHotelComponent } from './sidebar-travel-destinations-hotel/sidebar-travel-destinations-hotel.component';
import { SidebarGuestsHotelComponent } from './sidebar-guests-hotel/sidebar-guests-hotel.component';
import { SidebarCalendarHotelComponent } from './sidebar-calendar-hotel/sidebar-calendar-hotel.component';
import { SidebarCalendarFlightComponent } from './sidebar-calendar-flight/sidebar-calendar-flight.component';
import { SidebarCalendarBirthdayComponent } from './sidebar-calendar-birthday/sidebar-calendar-birthday.component';
import { SidebarCalendarRentalComponent } from './sidebar-calendar-rental/sidebar-calendar-rental.component';
import { SidebarPassengersFlightComponent } from './sidebar-passengers-flight/sidebar-passengers-flight.component';
import { SidebarTravelDestinationsFilterHotelComponent } from './sidebar-travel-destinations-filter-hotel/sidebar-travel-destinations-filter-hotel.component';
import { SidebarLocationsRentalComponent } from './sidebar-locations-rental/sidebar-locations-rental.component';
import { SidebarTargetAirportFilterFlightComponent } from './sidebar-target-airport-filter-flight/sidebar-target-airport-filter-flight.component';

export class RkSidebars {
  public sidebarComponents: { [key: string]: any } = {
    'sidebarAirport': SidebarAirportComponent,
    'sidebarTravelDestinationsHotel': SidebarTravelDestinationsHotelComponent,
    'sidebarGuestsHotel': SidebarGuestsHotelComponent,
    'sidebarCalendarHotel': SidebarCalendarHotelComponent,
    'sidebarCalendarFlight': SidebarCalendarFlightComponent,
    'sidebarCalendarBirthday': SidebarCalendarBirthdayComponent,
    'sidebarCalendarRental': SidebarCalendarRentalComponent,
    'sidebarPassengersFlight': SidebarPassengersFlightComponent,
    'sidebarTravelDestinationsFilterHotel': SidebarTravelDestinationsFilterHotelComponent,
    'sidebarLocationsRental': SidebarLocationsRentalComponent,
    'sidebarTargetAirportFilterFlight': SidebarTargetAirportFilterFlightComponent
  };
}
