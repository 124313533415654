import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';

import { Tile } from './../../../../components/card/shop-card/tile';

/**
 * This class provides the functions to get
 * and edit the data of the wish list.
 */
@Injectable({
  providedIn: 'root'
})
export class MpWishlistService {

  private _loading: boolean = false;
  private _wishlistLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public wishlistLoaded: Observable<boolean> = this._wishlistLoaded.asObservable();
  public loaded: boolean = false;
  public wishlist: Array<Tile> | undefined;

  constructor(
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService) { }

  /**
   * Gets the data of the wish list, if not
   * already loaded or reload is forced.
   */
  getWishlist(force?: boolean): void {
    if ((!this.loaded && !this._loading) || force) {
      this._loading = true;

      this._apiService.getRequest('/api/Wunschliste/get').subscribe((data: any) => {
        this._setWlFromResponse(data);
      },
      (error: any) => {
        console.log(error.message);
      });
    }
  }

  /**
   * Adds an item to the wish list.
   */
  addItem(vsArtNr: number | string, vsPin: number | string, callback?: Function): void {
    this._apiService.postRequest('/api/Wunschliste/addItem', { ArtNr: vsArtNr, Pin: vsPin }).subscribe((data: any) => {
      if (typeof callback !== 'undefined') {
        this._setWlFromResponse(data, callback);
      } else {
        this._setWlFromResponse(data);
      }   
    },
    (error: any) => {
      console.log(error.message);
    });
  }

  /**
   * Deletes an item from the wish list.
   */
  deleteItem(vlID: number | string, callback?: Function): void {
    this._apiService.postRequest('/api/Wunschliste/deleteItem', { id: vlID }).subscribe((data: any) => {
      if (typeof callback !== 'undefined') {
        this._setWlFromResponse(data, callback);
      } else {
        this._setWlFromResponse(data);
      }      
    },
    (error: any) => {
      console.log(error.message);
    });
  }

  /**
   * Checks whether or not an item is in
   * the wish list.
   */
  isIn(artNr: number | string): boolean {
    if (typeof this.wishlist !== 'undefined') {
      const inWl = this.wishlist.find(wlItem => {
        return wlItem.ArtNr === artNr;
      });

      if (typeof inWl !== 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Sets the data of the wish list to
   * public variable.
   */
  private _setWlFromResponse(data: any, callback?: Function): void {
    this.wishlist = data.Records;
    this._wishlistLoaded.next(true);
    this.loaded = true;
    this._loading = false;

    if (typeof callback !== 'undefined') {
      callback(this.wishlist);
    }

    this._mpMessaging.openPanelFromResultResponse(data);
  }
}
