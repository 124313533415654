<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'gewinnkalenderGewinnerStatistik'"></mp-core-infotext>
  <h1 class="mobile-only">{{ ls.locs['locGewinnkalender'].GewinnkalenderGewinnerStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['locGewinnkalender'].GewinnkalenderGewinnerStatistik }}" infotextShorten="gewinnkalenderGewinnerStatistik" 
                 fieldsAction="/api/GewinnkalenderStatistik/GetGewinnkalenderGewinnerStatistikFields" 
                 listAction="/api/GewinnkalenderStatistik/GetGewinnkalenderGewinnerStatistik"
                 [recLoadedFunc]="recLoadedFunc"
                 loaderName="prizeCalendarWinnerStatisticLoader" [paging]="true" [disableCache]="true"></mp-core-data-table>
  <div class="floating-container" *ngIf="loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
