import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../../services/mp-loader.service';
import { ApiService } from './../../../../../services/api.service';
import { ParticipantCockpitService } from './../../participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the status account
 * table.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-status-account',
  templateUrl: './ptcpnt-cockpit-status-account.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitStatusAccountComponent implements OnInit, OnDestroy {

  public statusAccountExcelService: string = '/api/StatusKonto/StatusKonto2Excel';
  public loaderStatusAccount: MpLoader | null = null;
  public quarters: Array<any> = [];
  public tnIdAvailable: boolean = false;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _selection: Array<any> = [];
  private _checkboxes: Array<any> = [];
  private _cockpitData: { [key: string]: any } = {};
  private _ptcpntCockpitStatusAccountLoader: MpLoader = {
    name: 'ptcpntCockpitStatusAccountLoader',
    params: {}
  };
  private _getParticipantCockpitDataSubscription: Subscription | undefined;
  private _getQuartersSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _apiService: ApiService,
    private _participantCockpitService: ParticipantCockpitService
  ) { }

  /**
   * Registers and extends the loader,
   * gets the quarters.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._ptcpntCockpitStatusAccountLoader);

    this._getParticipantCockpitDataSubscription = this._participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
      if (data['tnId'] === -1) {
        this.tnIdAvailable = false;
        return;
      }

      if (typeof this._cockpitData['tnId'] === 'undefined' || this._cockpitData['tnId'] !== data['tnId'] || typeof this._cockpitData['reloadCounter'] === 'undefined' || this._cockpitData['reloadCounter'] !== data['reloadCounter']) {
        this._cockpitData = data;

        this._mpLoaderService.extendLoader('ptcpntCockpitStatusAccountLoader', {
          isReady: true,
          params: Object.assign(this._ptcpntCockpitStatusAccountLoader.params, {
            TnID: this._cockpitData['tnId']
          }),
          refillExtParamsFunc: (params: any) => {
            if (params['Array'] && this._checkboxes.length > 0) {
              this._checkboxes.forEach((c: any) => {
                if (params['Array'].indexOf(c['Status']) > -1) {
                  this._selection.push(c);
                }
              });
            }
          }
        });

        this._getQuartersSubscription = this._apiService.postRequest('/api/StatusKonto/getStatusForPreviousQuarters/', {
          TnId: this._cockpitData['tnId'],
          Quarters: 4
        }).subscribe((data: any) => {
          this.quarters = data.Records;
        });

        if (this.loaderStatusAccount !== null) {
          if (typeof this.loaderStatusAccount.load !== 'undefined') {
            this.loaderStatusAccount.load();
          }
        }

        this.tnIdAvailable = this._cockpitData['tnId'] && this._cockpitData['tnId'] !== -1;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getParticipantCockpitDataSubscription !== 'undefined') {
      this._getParticipantCockpitDataSubscription.unsubscribe();
    }

    if (typeof this._getQuartersSubscription !== 'undefined') {
      this._getQuartersSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loaderStatusAccount = this._mpLoaderService.getLoader('ptcpntCockpitStatusAccountLoader');
  }

  /**
   * Sets the quarter and reloads the
   * data for the quarter.
   */
  setQuarter(quarterCheck: string): void {
    if (this.loaderStatusAccount !== null) {
      this.loaderStatusAccount.params.Quartal = quarterCheck.split(":")[0];
      this.loaderStatusAccount.params.Jahr = quarterCheck.split(":")[1];

      if (typeof this.loaderStatusAccount.load !== 'undefined') {
        this.loaderStatusAccount.load();
      }
    }
  }

}
