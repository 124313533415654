import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { SbAdditionalPaymentService } from './../../services/sb-additional-payment.service';

/**
 * This class provides the functions
 * for the additional payment payplace.
 */
@Component({
  selector: 'mp-wkzz-sb-additional-payment-payplace',
  templateUrl: './sb-additional-payment-payplace.component.html'
})
export class SbAdditionalPaymentPayplaceComponent implements OnInit, OnDestroy {

  public options: Array<any> = [];
  public shoppingCart: Array<any> = [];
  public provider: { [key: string]: any } = {};

  private _locsLoadedSubscription: Subscription | undefined;

  constructor(
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _sbAdditionalPaymentService: SbAdditionalPaymentService,
    private _ls: MpLocalizationService,
    private _injector: Injector
  ) { }

  /**
   * Gets injected data, and locs.
   */
  ngOnInit(): void {
    this.shoppingCart = this._injector.get('sbAdditionalPaymentShoppingCart');
    this.provider = this._injector.get('sbAdditionalPaymentProvider');

    if (Object.keys(this._ls.locs).length > 0) {
      this._setInititalData();
    } else {
      this._locsLoadedSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setInititalData();
        }
      })

      this._ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }
  }

  /**
   * Sets initial data, after localizations are
   * loaded.
   */
  private _setInititalData(): void {
    this.options = [
      {
        text:
          '<div class="payplace-select-img display-inline-block float-left"><img class="padding-right-8" class="margin-right-12" src="https://cdn.netcentive.de/logos/visa.png" height="24"/></div>' +
          '<div class="payplace-select-img display-inline-block float-left"><img img class="padding-right-16" src="https://cdn.netcentive.de/logos/mastercard.png" height="24"/></div> ' +
          ((this._ls.locs['locZuzahlung'] && this._ls.locs['locZuzahlung'].Kreditkarte) || ''),
        value: 'creditcard'
      },
      {
        text:
          '<div class="payplace-select-img display-inline-block float-left"><img class="padding-right-16" src="https://cdn.netcentive.de/logos/eckarte.png" height="24" /></div> ' +
          ((this._ls.locs['locZuzahlung'] && this._ls.locs['locZuzahlung'].Lastschrift) || ''),
        value: 'directdebit'
      }
    ];

    this._sbAdditionalPaymentService.register('payplace-express', this.setPayplaceExpress.bind(this));
  }

  /**
   * Sets Payplace as method for the
   * additional payment.
   */
  setPayplaceExpress(successCallback: Function, errorCallback: Function): void {
    if (Object.keys(this.provider).length === 0 || !this.provider['paymentMethod']) {
      this._mpMessaging.openDangerPanel(this._ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
    } else {
      const memo = [0];

      this.shoppingCart.forEach((cart: any) => {
        memo.push(cart['Punkte'] - cart['EingeloestePunkte']);
      });

      const data = {
        PunkteList: memo,
        Cart: this.shoppingCart,
        Provider: 'payplace-express',
        PaymentMethod: this.provider['paymentMethod'].value
      };

      this._apiService.postRequest('/api/WkZuzahlung/zuzahlen', data).subscribe((responseData: any) => {
        if (responseData.Result !== 'OK') {
          this._mpMessaging.openWarningPanel(responseData.Message);
        } else {
          this._mpMessaging.openWarningPanel(responseData.Message);
        }

        successCallback(responseData);
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.ExceptionMessage);
        errorCallback(error);
      });
    }
  }

}
