import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';

/**
 * This class provides the page for a failed
 * additional payment.
 */
@Component({
  selector: 'mp-wkzz-payment-failed',
  templateUrl: './payment-failed.component.html'
})
export class PaymentFailedComponent implements OnInit {

  constructor(
    public ls: MpLocalizationService
  ) { }

  ngOnInit(): void {
  }

}
