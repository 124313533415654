import { Directive, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../services/mp-localization.service';
import { MpMessagingService } from './../services/mp-messaging.service';
import { ApiService } from './../services/api.service';

/**
 * This class provides the functions to
 * validate the input of a voucher.
 */
@Directive({
  selector: '[mpCoreVoucherInput]',
  exportAs: 'mpCoreVoucherInput'
})
export class VoucherInputDirective implements OnInit, OnDestroy, OnChanges {

  @Input() public maximalWorth: number = 0;
  @Input() public voucherTotal: number = 0;
  @Input() public vouchers: Array<any> = [];

  @Output() voucherTotalChange = new EventEmitter<number>();
  @Output() vouchersChange = new EventEmitter<Array<any>>();

  public voucherErrors: Array<any> = [];

  private _error: { [key: string]: any } = {};
  private _getLocsSubscription: Subscription | undefined;
  private _validateVoucherSubscription: Subscription | undefined;
  private _validateAndAddVoucherSubscription: Subscription | undefined;

  constructor(
    private _ls: MpLocalizationService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService
  ) { }

  ngOnInit(): void {
    if (Object.keys(this._ls.locs).length > 0) {
      this._error = this._ls.locs['error'];

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
        this._error = this._ls.locs['error'];

        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }
      });

      this._ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._validateVoucherSubscription !== 'undefined') {
      this._validateVoucherSubscription.unsubscribe();
    }

    if (typeof this._validateAndAddVoucherSubscription !== 'undefined') {
      this._validateAndAddVoucherSubscription.unsubscribe();
    }
  }

  /**
   * Validates the voucher.
   */
  private _validateVouchers(): void {
    if (this.vouchers.length > 0) {
      this.vouchers.forEach((voucher: { [key: string]: any }) => {
        this._validateVoucherSubscription = this._apiService.getRequest('/api/Gutschein/Validate', false, {
          params: {
            code: voucher['Code'],
            maximalWert: this.maximalWorth
          }
        }).subscribe((data: any) => {
          if (data.Result !== 'OK') {
            const removeIndex = this.vouchers.indexOf(voucher);
            this.removeVoucher(removeIndex);
          } else {
            this.voucherErrors = [data.Message];
          }
        },
        (error: any) => {
          this._mpMessaging.openErrorPanel(error);
        });
      });
    }
  }

  /**
   * Removes the given voucher from the
   * vouchers array.
   */
  removeVoucher(index: number): void {
    if (index > -1) {
      this.vouchers.splice(index, 1);
      this.vouchersChange.emit(this.vouchers);
    }

    if (typeof this._caluclateRedemption !== 'undefined') {
      this._caluclateRedemption();
    }
  }

  /**
   * Calculates the total redemption worth.
   */
  private _caluclateRedemption(): void {
    let residualValue = this.maximalWorth;
    this.voucherTotal = 0;

    if (this.vouchers.length === 0) {
      return;
    }

    let i = 0;

    for (; i < this.vouchers.length && residualValue > 0; i++) {
      const voucher = this.vouchers[i];
      voucher['Einloesung'] = Math.min(residualValue, voucher['Restwert']);
      residualValue -= voucher['Einloesung'];
      this.voucherTotal += voucher['Einloesung'];
      this.voucherTotalChange.emit(voucher['Einloesung']);
    }

    for (; i < this.vouchers.length; i++) {
      const voucher = this.vouchers[i];
      voucher['Einloesung'] = residualValue;
    }
  }

  /**
   * Adds a given voucher to the vouchers
   * array.
   */
  private _addVoucher(voucher: { [key: string]: any }): void {
    if (this.vouchers.length > 0) {
      const voucherFound = this.vouchers.find((vou: { [key: string]: any }) => {
        return vou['Code'] === voucher['Code'];
      });

      if (typeof voucherFound !== 'undefined') {
        return;
      }

      const voucherMinimumWorthFound = this.vouchers.find((vou: { [key: string]: any }) => {
        return vou['Mindestbestellwert'] === voucher['Mindestbestellwert'];
      });

      if (typeof voucherMinimumWorthFound !== 'undefined') {
        this.voucherErrors = [this._error['NurEinGutscheinMitMindestbestellwertEinloesbar']];
        return;
      }

      this.vouchers.push(voucher);
      this.vouchersChange.emit(this.vouchers);
    } else {
      this.vouchers = [voucher];
      this.vouchersChange.emit(this.vouchers);
    }

    this._caluclateRedemption();
  }

  /**
   * Validates and adds the given voucher code.
   */
  validateAndAdd(code: string): void {
    this.voucherErrors = [];

    this._validateAndAddVoucherSubscription = this._apiService.getRequest('/api/Gutschein/Validate', false, {
      params: {
        code: code,
        maximalWert: this.maximalWorth
      }
    }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        const voucherRedemptionResult = data.Records[0];
        this._addVoucher(voucherRedemptionResult);

        if (voucherRedemptionResult['EinloesungMessageTn'] && voucherRedemptionResult['EinloesungMessageTn'] !== '') {
          this._mpMessaging.openSuccessPanel(voucherRedemptionResult['EinloesungMessageTn']);
        }
      } else {
        this.voucherErrors = [data.Message];
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Handles changes of the maximum worth.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['maximalWorth'] !== 'undefined') {
      this._validateVouchers();

      if (typeof this._caluclateRedemption !== 'undefined') {
        this._caluclateRedemption();
      }
    }
  }

}
