import { Injectable, OnDestroy } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';

/**
 * This class provides the functionalities
 * for the search of the travel configurator.
 */
@Injectable({
  providedIn: 'root'
})
export class TcFilterService {

  /**
   * Updates the range slider options.
   */
  public updateRangeSliderOptions(filter: TcFilter, accountBalance: number): void {
    filter.rangeSliderConfigDone = false;
    const newOptions: Options = Object.assign({}, filter.rangeSliderOptions);
    newOptions.ceil = (Object.keys(filter.options).length > 0 && filter.options['MaxPreis']) || 1;
    newOptions.floor = (Object.keys(filter.options).length > 0 && filter.options['MinPreis']) || 0;
    filter.filter['Punkte'] = (Object.keys(filter.options).length > 0 && filter.options['MaxPreis']) || 1;
    filter.filterPoints = filter.filter['Punkte'];

    if (accountBalance > -1 && Object.keys(filter.options).length > 0 && filter.options['MaxPreis']) {
      newOptions.stepsArray = this._setStepArray(filter, newOptions, accountBalance);
      filter.rangeSliderConfigDone = true;
      filter.rangeSliderOptions = newOptions;
    }
  }

  /**
   * Sets the steps array for the range
   * slider.
   */
  private _setStepArray(filter: TcFilter, newOptions: Options, accountBalance: number): Array<any> {
    let stepArray: Array<any> = [];

    if (typeof newOptions.ceil !== 'undefined') {
      let minValue = newOptions.floor ?? 0;

      let step = Math.round((newOptions.ceil - minValue) / (filter.step !== -1 ? filter.step : 10));
      step = step > 0 ? step : 1;

      for (let i = minValue; i < newOptions.ceil; i = i + step) {
        stepArray.push(i);
      }

      stepArray.push(newOptions.ceil);

      if (newOptions.ceil > accountBalance) {
        stepArray.push(accountBalance);
      }

      stepArray = [...new Set(stepArray)];

      const sortedStepArray = stepArray.sort((a: number, b: number) => {
        return a - b;
      });

      const sortedStepsObjectArray: Array<any> = [];

      sortedStepArray.forEach((st: any, index: number) => {
        sortedStepsObjectArray.push({
          value: st
        });
      });

      return sortedStepsObjectArray;
    }

    return stepArray;
  }

}

export interface TcFilter {
  step: number;
  options: { [key: string]: any };
  rangeSliderOptions: Options;
  rangeSliderConfigDone: boolean;
  filter: { [key: string]: any };
  filterPoints: number;
}
