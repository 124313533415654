import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { CommonInternComponentsLoaderModule } from './../common-intern-components-loader.module';
import { ImageVideoCarouselModule } from './../../components/image-video-carousel/image-video-carousel.module';

import { FirstLoginComponent } from './first-login.component';

import { HomeComponent } from './pages/home/home.component';
import { ConditionsOfParticipationComponent } from './../../components/first-login/conditions-of-participation/conditions-of-participation.component';
import { MajorDataConditionsOfParticipationComponent } from './../../components/first-login/major-data-conditions-of-participation/major-data-conditions-of-participation.component';
import { MajorDataComponent } from './../../components/first-login/major-data/major-data.component';
import { NewsletterComponent } from './../../components/first-login/newsletter/newsletter.component';
import { CheckStagesComponent } from './../../components/first-login/check-stages/check-stages.component';
import { PrtcpntAdvertisePrtcpntComponent } from '@leas/components/first-login/prtcpnt-advertise-prtcpnt/prtcpnt-advertise-prtcpnt.component';
import { SetGroupComponent } from '@leas/components/first-login/set-group/set-group.component';
import { QuarterSurveyComponent } from '@leas/components/first-login/quarter-survey/quarter-survey.component';

@NgModule({
  declarations: [
    FirstLoginComponent,
    HomeComponent,
    ConditionsOfParticipationComponent,
    MajorDataConditionsOfParticipationComponent,
    MajorDataComponent,
    NewsletterComponent,
    CheckStagesComponent,
    PrtcpntAdvertisePrtcpntComponent,
    SetGroupComponent,
    QuarterSurveyComponent
  ],
  exports: [
    FirstLoginComponent,
    ConditionsOfParticipationComponent,
    MajorDataConditionsOfParticipationComponent,
    MajorDataComponent,
    NewsletterComponent,
    CheckStagesComponent,
    PrtcpntAdvertisePrtcpntComponent,
    SetGroupComponent,
    QuarterSurveyComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonInternComponentsLoaderModule,
    NgxPaginationModule,
    CarouselModule,
    ImageVideoCarouselModule
  ]
})
export class FirstLoginModule { }
