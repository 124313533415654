import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from "d3";

import { MpD3StylesService } from './../mp-d3-styles.service';
import { MpDebounceService } from './../../../services/mp-debounce.service';
import { ApiService } from './../../../services/api.service';
import { MpLoaderService } from './../../../services/mp-loader.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';

import { MpLoader } from './../../../services/interfaces/mp-loader';

/**
 * This class provides a d3 bar plot.
 */
@Component({
  selector: 'mp-core-d3-bar-plot',
  templateUrl: './d3-bar-plot.component.html',
  styleUrls: ['./d3-bar-plot.component.scss']
})
export class D3BarPlotComponent implements OnInit {

  @Input() public mpid: string = '';
  @Input() public width: number = 600;
  @Input() public height: number = 400;
  @Input() public dataurl: string = '';
  @Input() public textxachse: string = '';
  @Input() public textyachse: string = '';
  @Input() public animationms: number = 800;
  @Input() public loader: string | undefined;
  @Input() public showaverage: boolean = false;

  private _fillcolorRange: Array<string> = [];
  private _linecolor: string = '';

  constructor(
    private _mpD3StylesService: MpD3StylesService,
    private _mpDebounce: MpDebounceService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService,
    private _chartElem: ElementRef,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Loads the colors and the data
   * for the chart.
   */
  ngOnInit(): void {
    this._fillcolorRange = this._mpD3StylesService.mpD3Styles().d3BarPlotStyles.fillcolorRange;
    this._linecolor = this._mpD3StylesService.mpD3Styles().d3BarPlotStyles.linecolor;

    let parentLoader: MpLoader;

    const load = this._mpDebounce.debounce(() => {
      const p = (typeof (this.loader) === 'undefined' ? {} : parentLoader.params);

      this._apiService.postRequest(this.dataurl, p).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this._drawChart(data.Records);
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
          }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
      });
    }, 200);

    if (typeof this.loader !== 'undefined') {
      if (this._mpLoaderService.loaderExists(this.loader)) {
        parentLoader = this._mpLoaderService.getLoader(this.loader);

        this._mpLoaderService.extendLoader(parentLoader.name, {
          load: load
        });
      }
    }

    load();
  }

  /**
   * Draws the d3 chart.
   */
  private _drawChart(inputData: any) {
    if (typeof inputData === 'undefined' || typeof inputData !== 'undefined' && typeof inputData[0] === 'undefined')
      return;

    inputData.map(
      (d: any) => {
        if (d.xDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.xDate);
        if (d.yDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.yDate);
      });

    const margin = { top: 25, right: 70, bottom: 80, left: 70 };
    const widthChart = this.width - margin.left - margin.right;
    const heightChart = this.height - margin.top - margin.bottom;

    if (this.textyachse !== '') {
      margin.top = margin.top + 6;
    }

    const xData = (d: any) => { return d.xDate ? d.xDate : d.xInt ? d.xInt : d.xDecimal ? d.xDecimal : d.xString ? d.xString : ''; };
    const yData = (d: any) => { return d.yDate ? d.yDate : d.yInt ? d.yInt : d.yDecimal ? d.yDecimal : d.yString ? d.yString : 0; };
    const yAvgData = (d: any) => { return d.yAverage; };
    const xGroup = (d: any) => { return d.xGroup ? d.xGroup : ''; };
    const colorGroup = (d: any) => { return d.xGroup ? d.xGroup : d.xString; };
    const xLabelGroupString = (d: any) => { return d.xString; };

    const inputGroupNames: Array<any> = [];

    inputData.forEach(
      (d: any) => {
        var g = xGroup(d);
        if (inputGroupNames.indexOf(g) < 0) inputGroupNames.push(g);
      });

    const colorGroupNames: Array<any> = [];

    inputData.forEach((d: any) => {
        var g = colorGroup(d);
        if (colorGroupNames.indexOf(g) < 0) colorGroupNames.push(g);

        d.tooltip = typeof d.tooltip === 'undefined' || d.tooltip === null ? yData(d) : d.tooltip;
      });

    d3.select('#' + this.mpid)
      .selectAll('*')
      .remove();

    const svg = d3.select('#' + this.mpid)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewBox', '0, 0, ' + this.width + ', ' + this.height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const tooltip = d3.select('#' + this.mpid)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '8px')
      .style('padding', '5px');

    const mouseover = (d: any) => {
      tooltip
        .style('opacity', 0.9);
    };

    const mousemove = (evt: any, d: any) => {
      tooltip
        .html(d.tooltip)
        .style('left', (d3.pointer(evt)[0] + 20).toString() + 'px')
        .style('top', (d3.pointer(evt)[1]).toString() + 'px');
    };

    const mouseleave = (d: any) =>  {
      tooltip
        .style('opacity', 0);
    };

    const x = d3.scaleBand()
      .domain(inputData.map(xData))
      .range([0, widthChart])
      .padding(0.2);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + heightChart + ')')
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    if (this.textxachse !== '') {
      svg.append('text')
        .attr('transform', 'translate(' + (widthChart) + ' ,' + (heightChart + margin.top + 20) + ')')
        .attr('class', 'axis-text _x')
        .style('text-anchor', 'start')
        .text(this.textxachse);
    }

    const yScale = inputData[0].yDate ? d3.scaleTime() : d3.scaleLinear();

    // @ts-ignore
    const y = yScale
      // @ts-ignore
      .domain([0, Math.max(d3.max(inputData, yData), 1)])
      // @ts-ignore
      .range([heightChart, 0]);

    svg
      .append('g')
      .call(d3.axisLeft(y));

    if (this.textyachse !== '') {
      svg.append('text')
        .attr('y', 0 - margin.top)
        .attr('x', 0 - margin.left)
        .attr('dy', '1em')
        .attr('class', 'axis-text _y')
        .style('text-anchor', 'start')
        .text(this.textyachse);
    }

    const color = d3.scaleOrdinal()
      .domain(inputGroupNames)
      .range(this._fillcolorRange);

    svg.selectAll('myrect')
      .data(inputData)
      .enter()
      .append('rect')
      // @ts-ignore
      .attr('x', (d: any) => { return x(xData(d)) + inputGroupNames.indexOf(xGroup(d)) * x.bandwidth() / inputGroupNames.length; })
      .attr('y', (d: any) => { return y(0); })
      .attr('width', x.bandwidth() / inputGroupNames.length)
      .attr('height', (d: any) => { return heightChart - y(0); })
      // @ts-ignore
      .attr('fill', (d: any) => { return color(colorGroup(d)); })
      .attr('stroke', this._linecolor)
      .attr('stroke-width', 0.5)
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    if (this.showaverage) {
      const average = inputData[0].yAverage;

      const averageLine = d3.line()
        // @ts-ignore
        .x((d: any, i: any) => {
          if (i === 0)
            return 0;
          if (i + 1 < inputData.length)
            return x(xData(d));
          return widthChart;
        })
        .y((d: any, i: any) => { return y(yAvgData(d)); });

      svg.append("path")
        .datum(inputData)
        .attr('stroke', this._linecolor)
        .attr("d", averageLine);

      svg.append("text")
        .attr("transform", "translate(" + (widthChart + 3) + "," + y(average) + ")")
        .attr("dy", "1em")
        .attr("text-anchor", "end")
        .html("Ø = " + average);
    }

    svg.selectAll('rect')
      .transition()
      .duration(this.animationms)
      .attr('y', (d: any) => { return y(yData(d)); })
      .attr('height', (d: any) => { return heightChart - y(yData(d)); })
      .delay((d: any, i: any) => {
        return i * 100;
      });

    if (inputGroupNames.length > 1) {
      const legend = this._chartElem.nativeElement.parentElement.querySelector('.chart-legend');

      while (legend.hasChildNodes()) {
        // @ts-ignore
        legend.removeChild(list.firstChild);
      }

      colorGroupNames.forEach((colorName: any, index: number) => {
        const legendElem = document.createElement('div');
        const legendDotElem = document.createElement('span');
        const legendTextElem = document.createElement('span');

        // @ts-ignore
        legendDotElem.style.backgroundColor = color(colorName);
        legendTextElem.innerHTML = colorName;
        legendElem.appendChild(legendDotElem);
        legendElem.appendChild(legendTextElem);

        legend.appendChild(legendElem);
      });
    } else if (colorGroupNames.length > 1) {
      const legend = this._chartElem.nativeElement.parentElement.querySelector('.chart-legend');

      while (legend.hasChildNodes()) {
        // @ts-ignore
        legend.removeChild(list.firstChild);
      }

      colorGroupNames.forEach((colorName: any, index: number) => {
        const legendElem = document.createElement('div');
        const legendDotElem = document.createElement('span');
        const legendTextElem = document.createElement('span');

        // @ts-ignore
        legendDotElem.style.backgroundColor = color(colorName);
        legendTextElem.innerHTML = colorName;
        legendElem.appendChild(legendDotElem);
        legendElem.appendChild(legendTextElem);

        legend.appendChild(legendElem);
      });
    }
  }

}
