import { Component, OnInit, ViewEncapsulation, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

/**
 * This class provides the functions for
 * the stage details page.
 */
@Component({
  selector: 'mp-core-stage-details',
  templateUrl: './stage-details.component.html',
  styleUrls: ['./../../../../components/card/styles/card.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StageDetailsComponent implements OnInit, OnDestroy {

  public stageLoaded: boolean = false;
  public errorMessage: string = '';
  public stage: { [key: string]: any } = {};
  public headline: string = '';
  public teaser: string = '';
  public text: string = '';

  private _getStageSubscription: Subscription | undefined;
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    private _apiService: ApiService,
    public sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _titleService: MpTitleService
  ) { }

  /**
   * Gets the stage data.
   */
  ngOnInit(): void {
    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      const stageId = typeof params['buehnenId'] !== 'undefined' ? params['buehnenId'] : '';

      this._getStageSubscription = this._apiService.getRequest(`/api/Home/getEinzelneBuehne?buehnenId=${stageId}`).subscribe((data: any) => {
        if (data.Result === 'OK' && data.Records.length === 1) {
          this.stage = data.Records[0];

          this.headline = this._trustAsHtml(this.stage['Headline']);
          this._titleService.setTitle(this.headline);
          this.teaser = this._trustAsHtml(this.stage['Teaser']);
          this.text = this._trustAsHtml(this.stage['Text']);

          this._breadcrumbService.setCurrentSiteWithText('BuehnenDetails?buehnenId=' + stageId, this.headline);
          this.stageLoaded = true;
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getStageSubscription !== 'undefined') {
      this._getStageSubscription.unsubscribe();
    }

    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Sanitizer for HTML.
   */
  private _trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
