import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from "d3";

import { MpD3StylesService } from './../mp-d3-styles.service';
import { MpDebounceService } from './../../../services/mp-debounce.service';
import { ApiService } from './../../../services/api.service';
import { MpLoaderService } from './../../../services/mp-loader.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';

import { MpLoader } from './../../../services/interfaces/mp-loader';

/**
 * This class provides a d3 histogram.
 */
@Component({
  selector: 'mp-core-d3-histogram',
  templateUrl: './d3-histogram.component.html',
  styleUrls: ['./d3-histogram.component.scss']
})
export class D3HistogramComponent implements OnInit {

  @Input() public mpid: string = '';
  @Input() public width: number = 600;
  @Input() public height: number = 400;
  @Input() public dataurl: string = '';
  @Input() public textxachse: string = '';
  @Input() public textyachse: string = '';
  @Input() public loader: string | undefined;

  private _fillcolor: string = '';
  private _linecolor: string = '';

  constructor(
    private _mpD3StylesService: MpD3StylesService,
    private _mpDebounce: MpDebounceService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService,
    private _chartElem: ElementRef,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Loads the colors and the data
   * for the chart.
   */
  ngOnInit(): void {
    this._fillcolor = this._mpD3StylesService.mpD3Styles().d3HistogramStyles.fillcolor;
    this._linecolor = this._mpD3StylesService.mpD3Styles().d3HistogramStyles.linecolor;

    let parentLoader: MpLoader;

    const load = this._mpDebounce.debounce(() => {
      const p = (typeof (this.loader) === 'undefined' ? {} : parentLoader.params);

      this._apiService.postRequest(this.dataurl, p).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._drawChart(data.Records);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
      });
    }, 200);

    if (typeof this.loader !== 'undefined') {
      if (this._mpLoaderService.loaderExists(this.loader)) {
        parentLoader = this._mpLoaderService.getLoader(this.loader);

        this._mpLoaderService.extendLoader(parentLoader.name, {
          load: load
        });
      }
    }

    load();
  }

  /**
   * Draws the d3 chart.
   */
  private _drawChart(inputData: any) {
    if (typeof inputData === 'undefined' || typeof inputData !== 'undefined' && typeof inputData[0] === 'undefined')
      return;

    inputData.map(
      (d: any) => {
        if (d.xDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.xDate);
        if (d.yDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.yDate);
      });

    const margin = { top: 10, right: 30, bottom: 30, left: 50 };
    const widthChart = this.width - margin.left - margin.right;
    const heightChart = this.height - margin.top - margin.bottom;

    const xData = (d: any) => { return d.xDate ? d.xDate : d.xInt ? d.xInt : d.xDecimal ? d.xDecimal : d.xString ? d.xString : ''; };
    const yData = (d: any) => { return d.yDate ? d.yDate : d.yInt ? d.yInt : d.yDecimal ? d.yDecimal : d.yString ? d.yString : 0; };

    d3.select('#' + this.mpid)
      .selectAll('*')
      .remove();

    const svg = d3.select('#' + this.mpid)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const xScale = inputData[0].xDate ? d3.scaleTime() : d3.scaleLinear();

    // @ts-ignore
    const x = xScale
      // @ts-ignore
      .domain(d3.extent(inputData, xData))
      // @ts-ignore
      .range([0, widthChart]);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + heightChart + ')')
      // @ts-ignore
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end');

    if (this.textxachse) {
      svg.append('text')
        .attr('transform', 'translate(' + (widthChart) + ' ,' + (heightChart + margin.top + 20) + ')')
        .style('text-anchor', 'end')
        .text(this.textxachse);
    }

    const bin = d3
      .bin()
      .value(yData)
      .domain(x.domain())
      .thresholds(x.ticks(70));

    // @ts-ignore
    const bins = bin(inputData);

    const yScale = inputData[0].yDate ? d3.scaleTime() : d3.scaleLinear();

    // @ts-ignore
    const y = yScale
      // @ts-ignore
      .domain([0, d3.max(bins, (d: any) => { return d.length; })])
      // @ts-ignore
      .range([heightChart, 0]);

    svg
      .append('g')
      // @ts-ignore
      .call(d3.axisLeft(y));

    if (this.textyachse) {
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.top)
        .attr('x', 0 - margin.left)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text(this.textyachse);
    }

    svg.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 1)
      .attr("transform", (d: any) => { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
      .attr("width", (d: any) => { return x(d.x1) - x(d.x0) - 1; })
      .attr("height", (d: any) => { return heightChart - y(d.length); })
      .style("fill", this._fillcolor);
  }

}
