import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides a sidebar with a
 * calendar to select a date range (period).
 */
@Component({
  selector: 'mp-core-sidebar-period',
  templateUrl: './sidebar-period.component.html',
  styleUrls: ['./sidebar-period.component.scss']
})
export class SidebarPeriodComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = { };
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public minRange: number = 0;
  public yearRange: any[] = [];
  public endDatesLimitedToLastDayOfQuarter: boolean = false;

  private _sidebarParamsSubscription: Subscription | undefined;
  
  constructor(public ls: MpLocalizationService, public mpSidebar: MpSidebarService) { }

  /**
   * Watches for sidebar params changes of
   * and triggers updating year and year range.
   * Sets the current date.
   */
  ngOnInit(): void {
    this._setYearRange();
    this.yearChange();

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      if (this.mpSidebar.params['endDatesLimitedToLastDayOfQuarter'] !== 'undefined') {
        this.endDatesLimitedToLastDayOfQuarter = this.mpSidebar.params['endDatesLimitedToLastDayOfQuarter'];
      }

      this.selection = Object.assign(this.selection, {
        rangeStart: this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'],
        rangeEnd: this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd'],
        year: moment().year()
      });

      this._setYearRange();
      this.yearChange();
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Calculates and sets the range of the
   * slectable years.
   */
  private _setYearRange(): void {
    let firstYear = 2000;
    let lastYear = moment().year() + 3;
    
    if (typeof this.mpSidebar.params !== 'undefined') {
      if (typeof this.mpSidebar.params['minDate'] !== 'undefined') {
        firstYear = moment(this.mpSidebar.params['minDate']).year();
      }

      if (typeof this.mpSidebar.params['maxDate'] !== 'undefined') {
        lastYear = moment(this.mpSidebar.params['maxDate']).year();
      }
    }

    this.yearRange = [];

    for (let loopCnt = firstYear; loopCnt <= lastYear; loopCnt++) {
      this.yearRange.push(moment().set('year', loopCnt).year());
    }
  }

  /**
   * Triggered, when year selection is changed.
   * Sets the new min and max dates.
   */
  yearChange(): void {
    const startOfYear = moment([this.selection['year'], 0, 1]);
    const endOfYear = moment([this.selection['year'], 11, 31, 23, 59]);
    this.minDate = this.mpSidebar.params['minDate'];
    this.maxDate = this.mpSidebar.params['maxDate'];
    this.minDate = typeof this.minDate !== 'undefined' && this.minDate > startOfYear ? this.minDate : startOfYear;
    this.maxDate = typeof this.maxDate !== 'undefined' && this.maxDate < endOfYear ? this.maxDate : endOfYear;
  }

  /**
   * Merges the selection with the sidebar
   * params and triggers the save function,
   * if exists.
   */
  save(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

}
