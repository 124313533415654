import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { UserProfileNavbarService } from './../user-profile-navbar/user-profile-navbar.service';
import { MpPageLoadService } from './../../services/mp-page-load.service';
import { ApiService } from './../../services/api.service';
import { MpSidebarService } from './../sidebar/mp-sidebar.service';

/**
 * This class provides the functions for
 * the users inbox.
 */
@Component({
  selector: 'mp-core-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxComponent implements OnInit, OnDestroy {

  @Input() public onlyType: string = '';
  @Input() public hideCategories: boolean = false;

  public showDeleteItemModal: boolean = false;
  public itemToDelete: { [key: string]: any } | null = null;
  public searchTerm: string = '';
  public active: string = '';
  public categories: Array<any> = [];
  public openInboxFilter: boolean = false;
  public elementsToday: Array<any> = [];
  public elementsYesterday: Array<any> = [];
  public elementsLastWeek: Array<any> = [];
  public elementsLastMonth: Array<any> = [];
  public elementsLastYear: Array<any> = [];
  public elementsBefore: Array<any> = [];
  public startindex: number = 0;
  public totalrecordcount: number = 0;
  public inboxItemsLoaded: boolean = false;
  public deleteItemFunc = this.deleteItem.bind(this);
  public toggleDeleteItemModalFunc = this.toggleDeleteItemModal.bind(this);
  public loadFunc = this.loadByMpSearch.bind(this);

  private _inbox: any = {
    startindex: 0,
    totalrecordcount: 1,
    pagesize: 15
  };
  private _type: string = '';
  private _key: string = '';
  private _loadByMpSearch: boolean = false;
  private _upnParamsSubscription: Subscription | undefined;
  private _typesSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _userProfileNavbar: UserProfileNavbarService,
    private _mpPageLoad: MpPageLoadService,
    private _apiService: ApiService,
    private _mpSidebar: MpSidebarService,
  ) { }

  ngOnInit(): void {
    const upnParams = this._userProfileNavbar.getParams();
    this._type = upnParams['PostfachType'];
    this._key = upnParams['PostfachKey'];
    //mpMenuHighlight.setMenuHighlight("postfach");
    this.active = this._type ? this._type : 'all';

    this._upnParamsSubscription = this._userProfileNavbar.paramsObserver.subscribe((params: { [key: string]: any }) => {
      if (params && params['PostfachKey'] && params['PostfachType']) {
        this._type = params['PostfachType'];
        this._key = params['PostfachKey'];
        this._userProfileNavbar.setParams('PostfachKey', '');
        this._userProfileNavbar.setParams('PostfachType', '');
        this._checkTypeAndKey();
      }
    });

    this._mpPageLoad.reset(this._inbox, 'postfach');

    this._typesSubscription = this._apiService.getRequest('/api/Postfach/GetTypes').subscribe((data: any) => {
      this.categories = data.Records.map((record: any) => {
        return {
          text: record.Bezeichnung,
          class: record.Type
        };
      });

      if (this._type) {
        const category = this.categories.find((cat: any) => {
          return cat.class === this._type;
        });

        this.filterCategory(null, category);
      } else {
        this.load();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._upnParamsSubscription !== 'undefined') {
      this._upnParamsSubscription.unsubscribe();
    }

    if (typeof this._typesSubscription !== 'undefined') {
      this._typesSubscription.unsubscribe();
    }
  }

  /**
   * Checks the type and the key of
   * the inbox to get the correct
   * items.
   */
  private _checkTypeAndKey(): void {
    if (this._type && this._key && this._inbox && this._inbox['postfach']) {
      const resultItem = this._inbox['postfach'].find((item: { [key: string]: any }) => {
        return item['Type'] === this._type && item['Key'] === this._key;
      });

      if (typeof resultItem === 'undefined') {
        this.load();
      } else {
        resultItem['showChildFromPreview'] = true;
        this._key = '';
        this._type = '';
      }
    }
  }

  /**
   * Triggers the load by search (instead of
   * load by sidebar).
   */
  loadByMpSearch(): void {
    this._loadByMpSearch = true;
    this.load(true);
  }

  /**
   * Loads the inbox items.
   */
  load(reset?: boolean, evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    reset = reset || false;

    if (reset) {
      this.inboxItemsLoaded = false;
      const postfachItemsContainer = document.getElementById('inbox-items-container');

      if (postfachItemsContainer !== null) {
        if (postfachItemsContainer.classList.contains('inbox-item-opened')) {
          postfachItemsContainer.classList.remove('inbox-item-opened');
        }
      }

      this.elementsToday = [];
      this.elementsYesterday = [];
      this.elementsLastWeek = [];
      this.elementsLastMonth = [];
      this.elementsLastYear = [];
      this.elementsBefore = [];
      this._mpPageLoad.reset(this._inbox, 'postfach');
    }

    if (!this._loadByMpSearch) {
      if (typeof this._mpSidebar.params['sidebarData'] !== 'undefined') {
        if (typeof this._mpSidebar.params['sidebarData'].searchTerm !== 'undefined') {
          this.searchTerm = this._mpSidebar.params['sidebarData'].searchTerm;
        }
      }
    }

    this._loadByMpSearch = false;

    this._mpPageLoad.loadNext(this._inbox, 'postfach', '/api/Postfach/GetPostfach', {
      Key: this.onlyType !== '' ? this.onlyType : typeof this._type !== 'undefined' && this._type !== '' ? this._type : '',
      Suchtext: this.searchTerm !== '' ? this.searchTerm : ''
    }, () => {
      if (location.host === 'afs-partner.consorsfinanz.de') {
        for (let i = 0; i < this._inbox['postfach'].length; i++) {
          if (this._inbox['postfach'][i].Text.includes('[SchulungStartSeite]')) {
            this._inbox['postfach'][i].Text = this._inbox['postfach'][i].Text.replace('[SchulungStartSeite]', 'https://afs-schulungsportal.praemienshop.net/api/auth/Redirect?page=SchulungStart&username=' + localStorage.getItem('CurrentLogin'));
            this._inbox['postfach'][i].Text = this._inbox['postfach'][i].Text.replace('[target]', '_blank');
          }
        }
      }

      if (location.host === 'afs-schulungsportal.praemienshop.net') {
        for (let i = 0; i < this._inbox['postfach'].length; i++) {
          if (this._inbox['postfach'][i].Text.includes('[SchulungStartSeite]')) {
            this._inbox['postfach'][i].Text = this._inbox['postfach'][i].Text.replace('[SchulungStartSeite]', '#SchulungStart');
            this._inbox['postfach'][i].Text = this._inbox['postfach'][i].Text.replace('[target]', '_self');
          }
        }
      }

      this.totalrecordcount = this._inbox['totalrecordcount'];
      this.startindex = this._inbox['startindex'];

      this.elementsToday = this._inbox['postfach'].filter((item: any) => {
        return item.AlterInTagen === 0 && !item.Deleted;
      });

      this.elementsYesterday = this._inbox['postfach'].filter((item: any) => {
        return item.AlterInTagen === 1 && !item.Deleted;
      });

      this.elementsLastWeek = this._inbox['postfach'].filter((item: any) => {
        return item.AlterInTagen > 1 && item.AlterInTagen <= 7 && !item.Deleted;
      });

      this.elementsLastMonth = this._inbox['postfach'].filter((item: any) => {
        return item.AlterInTagen > 7 && item.AlterInTagen <= 30 && !item.Deleted;
      });

      this.elementsLastYear = this._inbox['postfach'].filter((item: any) => {
        return item.AlterInTagen > 30 && item.AlterInTagen <= 365 && !item.Deleted;
      });

      this.elementsBefore = this._inbox['postfach'].filter((item: any) => {
        return item.AlterInTagen > 365 && !item.Deleted;
      });

      this._checkTypeAndKey();
      this.inboxItemsLoaded = true;
    });
  }

  /**
   * Filters the inbox by given
   * category.
   */
  filterCategory(evt: MouseEvent | null, cat?: any): void {
    if (typeof evt !== 'undefined' && evt !== null) {
      evt.preventDefault();
    }

    this._type = typeof cat !== 'undefined' ? cat.class : '';
    this.active = typeof cat !== 'undefined' ? cat.class : 'all';
    this.searchTerm = '';
    this.load(true);
    this.openInboxFilter = false;

    if (this._mpSidebar.visible) {
      this._mpSidebar.close();
    }
  }

  /**
   * Opens the inbxo sidebar.
   */
  openSidebar(): void {
    const sidebarParams = {
      sidebarData: {
        type: this._type,
        key: this._key,
        active: this.active,
        searchTerm: this.searchTerm
      },
      sidebarCategories: this.categories,
      sidebarFilterFunction: this.filterCategory.bind(this),
      sidebarSearchFunction: this.load.bind(this)
    };

    const sidebarElem = document.getElementById('sidebar');

    if (sidebarElem !== null) {
      if (!sidebarElem.classList.contains('_postfach-sidebar')) {
        sidebarElem.classList.add('_postfach-sidebar')
      }
    }

    this._mpSidebar.open('SidebarInbox', sidebarParams);
  }

  /**
   * Toggles the delete iem modal.
   */
  toggleDeleteItemModal(item?: any): void {
    item = item || false;

    if (!item) {
      this.showDeleteItemModal = false;
    } else {
      this.itemToDelete = item;
      this.showDeleteItemModal = true;
    }
  }

  /**
   * Deletes the given item.
   */
  deleteItem(item: any): void {
    item.Deleted = true;

    this._apiService.postRequest('/api/Postfach/SetItemDeleted', {
      TypId: item.TypId,
      Key: item.Key
    }).subscribe(() => {
      this.toggleDeleteItemModal();
      this.load(true);
      const inboxItemsContainer = document.getElementById('inbox-items-container');

      if (inboxItemsContainer !== null) {
        if (inboxItemsContainer.classList.contains('inbox-item-opened')) {
          inboxItemsContainer.classList.remove('inbox-item-opened');
        }
      }
    });
  }

}
