import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * This class provides the data and functions
 * for the charity currently donate page.
 */
@Component({
  selector: 'mp-leas-charity-donate',
  templateUrl: './charity-donate.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CharityDonateComponent implements OnInit, OnDestroy {

  public charitySliderProperties: OwlOptions = {
    loop: true,
    nav: true,
    margin: 16,
    items: 1,
    dots: true
  };
  public errorBubbles: string = '';
  public bubbles: string = '';
  public accountBalanceText: string = '';
  public loading: boolean = false;
  public role: string = '';

  private _bubbles: number = 0;
  private _getAccountBalanceTextSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;
  private _donateSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _apiService: ApiService,  
    private _mpMessaging: MpMessagingService,
    private _mpMenuRefresh: MpMenuRefreshService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets the breadcrumb, gets the locs
   * and triggers the function to get
   * the account balance text.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._mpBreadcrumbService.setCurrentSiteWithLoc('CharitySpenden', 'locLeas', 'BubblesSpenden');

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._refreshAccountBalanceText();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._refreshAccountBalanceText();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getAccountBalanceTextSubscription !== 'undefined') {
      this._getAccountBalanceTextSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._donateSubscription !== 'undefined') {
      this._donateSubscription.unsubscribe();
    }
  }

  /**
   * Donates the given points worth.
   */
  donate(): void {
    this.errorBubbles = '';
    this._bubbles = 0;

    if (typeof this.bubbles !== 'number') {
      let digits: any = this.bubbles.replace(/[^0-9]*/g, '');
      digits = parseInt(digits, 10);

      if (isNaN(digits)) {
        digits = 0;
      }

      this._bubbles = digits;
      this.bubbles = digits.toString();
    }

    if (this._bubbles <= 0) {
      this.errorBubbles = this.ls.locs['locLeas'].SieMuessenMindestend49BubblesSpenden;
      return;
    }

    this._mpMessaging.openConfirm({
      text: `Wollen Sie wirklich ${this.bubbles} Bubbles spenden?`,
      submitText: this.ls.locs['loc'].Ja,
      cancelText: this.ls.locs['loc'].Nein
    }, () => {
      this.loading = true;

      this._donateSubscription = this._apiService.postRequest('/api/Charity/spenden', { Bubbles: this.bubbles }).subscribe((data: any) => {
        if (data.Result === 'ERROR') {
          this.errorBubbles = data.Message;
          this._mpMessaging.openDangerPanel(data.Message);
        } else {
          this.bubbles = '';
          this._mpMessaging.openSuccessPanel(data.Message);
          this._mpMenuRefresh.loadMenuData(true);
          this._refreshAccountBalanceText();
        }

        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      });

      this._mpMessaging.closeOverlay();
    }, () => {
      this._mpMessaging.closeOverlay();
    });
  }

  /**
   * Refreshs the account balance text.
   */
  private _refreshAccountBalanceText(): void {
    this._getAccountBalanceTextSubscription = this._apiService.getRequest('/api/Teilnehmer/getMenuData').subscribe((data: any) => {
      this.accountBalanceText = this.ls.locs['locLeas'].BubblesSpendenKontostand.replace('{0}', data.Records[0].KontostandDisplay);
    });
  }

}
