import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, SimpleChanges } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';
import { MpAddressesService } from '../../../services/mp-addresses.service';
import { MpShoppingBasketService } from './../../../modules/participant/pages/shopping-basket/mp-shopping-basket.service';

import { Address } from './../address';

/**
 * This class provides the functions and data
 * for the addresses within the shipping
 * page of the order process.
 */
@Component({
  selector: 'mp-core-order-addresses',
  templateUrl: './order-addresses.component.html',
  styleUrls: ['./order-addresses.component.scss']
})
export class OrderAddressesComponent implements OnInit, OnDestroy {

  @Input() public selAddress: { [key: string]: any } = {};
  @Input() public selectable: boolean = false;
  @Input() public colCount: number = 1;
  @Input() public lkz: string = 'DE';
  @Input() public ddlData: any;
  @Input() public shippingAddresses: boolean = false;

  @Output() selAddressChange = new EventEmitter<{ [key: string]: any }>();

  public canAddAddress: boolean = false;
  public addresses: Array<any> = [];
  public shownTnAddresses: Array<any> = [];
  public setSelAddress: Function = () => { };
  public addressesLoaded: boolean = true;

  private _shownAddressCount: number = 0;
  private _cols: number = 1;
  private _selectedAdressId: number = 0;
  private _loadAddressesSubscription: Subscription | undefined;
  private _canAddAddressSubscription: Subscription | undefined;
  private _setAddressSubscriotion: Subscription | undefined;
  private _setSelAddressSubscription: Subscription | undefined;
  private _loadAddressesAfterAddSubscription: Subscription | undefined;
  private _setSelAddressAfterAddSubscription: Subscription | undefined;

  public address: Address | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpAddressService: MpAddressesService,
    private _mpShoppingBasket: MpShoppingBasketService
  ) { }

  /**
   * Loads the available addresses and
   * the CanAddAdresse value.
   */
  ngOnInit(): void {
    this.setSelAddress = this._setSelAddress.bind(this);

    this._loadAddressesSubscription = this._mpAddressService.loadAddresses().subscribe((data: any) => {
      if (data.length > 0) {
        this.addresses = data;
        this._changeSelAddress(data);
      }
    });

    this._canAddAddressSubscription = this._apiService.getRequest('/api/Teilnehmer/CanAddAdresse').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.canAddAddress = data.Records[0];
      }
    });

    this._setCols(this.colCount);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._loadAddressesSubscription !== 'undefined') {
      this._loadAddressesSubscription.unsubscribe();
    }

    if (typeof this._canAddAddressSubscription !== 'undefined') {
      this._canAddAddressSubscription.unsubscribe();
    }

    if (typeof this._setAddressSubscriotion !== 'undefined') {
      this._setAddressSubscriotion.unsubscribe();
    }

    if (typeof this._setSelAddressSubscription !== 'undefined') {
      this._setSelAddressSubscription.unsubscribe();
    }

    if (typeof this._loadAddressesAfterAddSubscription !== 'undefined') {
      this._loadAddressesAfterAddSubscription.unsubscribe();
    }

    if (typeof this._setSelAddressAfterAddSubscription !== 'undefined') {
      this._setSelAddressAfterAddSubscription.unsubscribe();
    }
  }

  /**
   * Sets the cols initial and on
   * change.
   */
  private _setCols(cols: number): void {
    if (!isNaN(cols)) {
      this._cols = cols;
    } else {
      this._cols = 4;
    }

    this._shownAddressCount = this._cols - 1;
  }

  /**
   * Watches for changes of the cols
   * count and addresses.
   */
  ngOnChange(changes: SimpleChanges): void {
    if (typeof changes['colCount'] !== 'undefined') {
      if (changes['colCount'].previousValue !== changes['colCount'].currentValue) {
        this._setCols(changes['colCount'].currentValue);
      }
    }

    if (typeof changes['addresses'] !== 'undefined') {
      if (changes['addresses'].currentValue !== changes['addresses'].previousValue) {
        const addresses = changes['addresses'].currentValue;
        this._changeSelAddress(addresses);
      }
    }
  }

  /**
   * Triggers the changing of the
   * selected address.
   */
  private _changeSelAddress(addresses: any) {
    if (!(addresses && addresses.length)) {
      return;
    }

    const storAddressRaw = localStorage.getItem('selectedBestellAdresse');
    const storAddress = storAddressRaw !== null ? JSON.parse(storAddressRaw) : null;

    if (storAddress && storAddress['AdressID']) {
      this.selAddress = addresses.find((address: any) => address.AdressID === storAddress['AdressID']);
    }

    if (!this.selAddress || Object.keys(this.selAddress).length === 0) {
      this.selAddress = addresses.find((address: any) => address.IsStAdresse === true);
    }

    if (!this.selAddress) {
      this.selAddress = addresses[0];
    }

    this.selAddressChange.emit(this.selAddress);

    this._setSelAddressSubscription = this._setSelAddress(this.selAddress).subscribe((data: boolean) => {
      if (data) {
        this.shownTnAddresses = this._getSlicedAddresses(this._shownAddressCount);
        this._selectedAdressId = this.selAddress['selectedAdressId'];
        this._mpAddressService.mpAddresses.selectedAdressId = this._selectedAdressId;
        this._mpAddressService.mpAddresses.selectedAdressTyp = this.selAddress['selectedAdressTyp'];

        if (typeof this._setSelAddressSubscription !== 'undefined') {
          this._setSelAddressSubscription.unsubscribe();
        }
      }
    });
  }

  /**
   * Gets the sliced addresses to
   * show.
   */
  private _getSlicedAddresses(slice: number): Array<any> {
    return this._mpAddressService.addresses.concat().sort((a: any) => {
      return this.selectable && a.AdressID === this.selAddress['AdressID'] && a.AdressTyp === this.selAddress['AdressTyp'] ? 0 : a.AdressID;
    }).slice(0, slice);
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Loads more addresses.
   */
  loadMoreAddresses(): void {
    this._shownAddressCount = this.shownTnAddresses.length + this._cols;
    this.shownTnAddresses = this._getSlicedAddresses(this._shownAddressCount);
  }

  /**
   * Callback for setting new addresses.
   */
  private _newAddressCallback(newAddress: any): void {
    this.addressesLoaded = false;

    this._loadAddressesAfterAddSubscription = this._mpAddressService.loadAddresses().subscribe((data: any) => {
      if (data.length > 0) {
        this.selAddress = newAddress;

        this._setSelAddressAfterAddSubscription = this._setSelAddress(this.selAddress).subscribe((data: boolean) => {
          if (data) {
            this.shownTnAddresses = this._getSlicedAddresses(this._shownAddressCount);
            this._selectedAdressId = this.selAddress['selectedAdressId'];
            this._mpAddressService.mpAddresses.selectedAdressId = this._selectedAdressId;
            this._mpAddressService.mpAddresses.selectedAdressTyp = this.selAddress['selectedAdressTyp'];
            this.addressesLoaded = true;

            if (typeof this._setSelAddressAfterAddSubscription !== 'undefined') {
              this._setSelAddressAfterAddSubscription.unsubscribe();
            }
          }
        });

        if (typeof this._loadAddressesAfterAddSubscription !== 'undefined') {
          this._loadAddressesAfterAddSubscription.unsubscribe();
        }
      }
    });
  }

  /**
   * Adds a new address.
   */
  addAddress(evt: MouseEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    if (!this._mpAddressService.mpAddresses.editAddress ||
      Object.keys(this._mpAddressService.mpAddresses.editAddress).length === 0 ||
      !this._mpAddressService.mpAddresses.editAddress.IsNeu) {
      this._resetNewAddress();
    }

    if (this.shippingAddresses) {
      this._mpAddressService.edit(this.address, this._newAddressCallback.bind(this));
    } else {
      this._mpAddressService.edit(this.address);
    }
  }

  /**
   * Resets the new (editted) address, if there
   * already exists one.
   */
  private _resetNewAddress(): void {
    const country = this._mpAddressService.mpAddresses['ddlData'].Laenderliste.find((country: any) => { return country['Kuerzel'] === this.lkz; });

    this.address = {
      TnID: -1,
      AdressID: -1,
      Anrede: '',
      AnredenID: -1,
      IsNeu: true,
      Titel: '',
      Name: '',
      Vorname: '',
      Firma: '',
      Adresszusatz: '',
      Strasse: '',
      PLZ: '',
      Ort: '',
      LKZ: this.lkz,
      Editierbar: true,
      AdressArtID: 1,
      IsStdAdresse: false,
      Land: {
        Kuerzel: this.lkz,
        Land: typeof country !== 'undefined' ? country['Land'] : ''
      }
    };
  }

  /**
   * Sets the selected address.
   */
  private _setSelAddress(address: any): Observable<any> {

    this.selAddress = address;
    this.selAddressChange.emit(this.selAddress);
    this._mpAddressService.mpAddresses.selectedAdressId = address.AdressID;
    this._mpAddressService.mpAddresses.selectedAdressTyp = address.AdressTyp;
    localStorage.setItem('selectedBestellAdresse', JSON.stringify(address));
    const setSelAdressObservable = new Subject<boolean>();

    this._setAddressSubscriotion = this._apiService.postRequest('/api/Warenkorb/setAdresse/', {
      ID: address.AdressID,
      AdrTyp: address.AdressTyp
    }).subscribe((data: any) => {
      this._mpShoppingBasket.refreshShoppingBasket(true);
      setSelAdressObservable.next(true);
    });

    return setSelAdressObservable.asObservable();
  }

}
