import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';
import { TcSearchParamsService } from './../../../services/tc-search-params.service';

/**
 * This class provides the functionalities
 * for the search area 'only flight'.
 */
@Component({
  selector: 'mp-rk-search-area-only-flight',
  templateUrl: './search-area-only-flight.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SearchAreaOnlyFlightComponent implements OnInit, OnDestroy {

  @Input() public params: { [key: string]: any } = {
    dateRangeFlug: {},
    gaesteHotel: {},
    reisezieleHotel: {},
    flugAbflughafen: {},
    flugZielflughafen: {}
  };
  @Input() public travelType: number = 0;
  @Input() public hideButton: boolean = false;
  @Input() public searchFunction: Function | undefined;

  @Output() paramsChange = new EventEmitter<{ [key: string]: any }>();

  public paramsSet: boolean = false;

  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _customEventService: CustomEventService
  ) { }

  /**
   * Gets the localization data.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this._setParams();

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setParams();

          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the search.
   */
  search(evt: MouseEvent): void {
    evt.preventDefault();

    if (typeof this.searchFunction !== 'undefined') {
      this.searchFunction(evt);
    }
  }

  /**
   * Sets this params for the search.
   */
  private _setParams(): void {
    if (this.paramsSet) {
      return;
    }

    this.params['dateRangeFlug'] = Object.assign(this.params['dateRangeFlug'] || {}, {
      minDate: moment().add(4, 'days').hour(0).minute(0).second(0).millisecond(0),
      isRange: true,
      save: this._dateRangeFlightSave.bind(this),
      flugOptions: [
        { Text: this.ls.locs['locReisekonfigurator'].HinUndRueckflug, id: 0 },
        { Text: this.ls.locs['locReisekonfigurator'].NurHinflug, id: 1 }
      ],
      reisedauerOptionsHin: [
        { Text: this.ls.locs['locReisekonfigurator'].ExaktesDatum, id: 0, TageDavor: 0, TageDanach: 0 },
        { Text: this.ls.locs['locReisekonfigurator'].EinTagVorher, id: 1, TageDavor: 1, TageDanach: 0 },
        { Text: this.ls.locs['locReisekonfigurator'].EinTagNachher, id: 2, TageDavor: 0, TageDanach: 1 },
        { Text: this.ls.locs['locReisekonfigurator'].PlusMinusEinTag, id: 3, TageDavor: 1, TageDanach: 1 },
        { Text: this.ls.locs['locReisekonfigurator'].PlusMinusZweiTage, id: 4, TageDavor: 2, TageDanach: 2 },
        { Text: this.ls.locs['locReisekonfigurator'].PlusMinusDreiTage, id: 5, TageDavor: 3, TageDanach: 3 }
      ],
      reisedauerOptionsZurueck: [
        { Text: this.ls.locs['locReisekonfigurator'].ExaktesDatum, id: 0, TageDavor: 0, TageDanach: 0 },
        { Text: this.ls.locs['locReisekonfigurator'].EinTagVorher, id: 1, TageDavor: 1, TageDanach: 0 },
        { Text: this.ls.locs['locReisekonfigurator'].EinTagNachher, id: 2, TageDavor: 0, TageDanach: 1 },
        { Text: this.ls.locs['locReisekonfigurator'].PlusMinusEinTag, id: 3, TageDavor: 1, TageDanach: 1 },
        { Text: this.ls.locs['locReisekonfigurator'].PlusMinusZweiTage, id: 4, TageDavor: 2, TageDanach: 2 },
        { Text: this.ls.locs['locReisekonfigurator'].PlusMinusDreiTage, id: 5, TageDavor: 3, TageDanach: 3 }
      ]
    });

    this.params['dateRangeFlug'].Flugart = this.params['dateRangeFlug'].Flugart || this.params['dateRangeFlug'].flugOptions[0];
    this.params['dateRangeFlug'].VarianzHin = this.params['dateRangeFlug'].VarianzHin || this.params['dateRangeFlug'].reisedauerOptionsHin[0];
    this.params['dateRangeFlug'].VarianzRueck = this.params['dateRangeFlug'].VarianzRueck || this.params['dateRangeFlug'].reisedauerOptionsZurueck[0];

    this.params['dateRangeFlug'].save(true);

    this.params['passagiereFlug'] = Object.assign(this.params['passagiereFlug'] || {}, {
      save: this._passengersFlightSave.bind(this)
    });

    this.params['passagiereFlug'].save(true);

    this.params['flugAbflughafen'] = Object.assign(this.params['flugAbflughafen'] || {}, {
      save: this._airportOfDepartureSave.bind(this)
    });

    this.params['flugAbflughafen'].save(true);


    this.params['flugZielflughafen'] = Object.assign(this.params['flugZielflughafen'] || {}, {
      save: this._targetAirportSave.bind(this)
    });

    this.params['flugZielflughafen'].save(true);

    this.params = this._tcSearchParamsService.getParamsForSearchArea(this.params, this.travelType);
    this.paramsSet = true;
  }

  /**
   * Save function for dateRangeFlug
   * sidebar param.
   */
  private _dateRangeFlightSave(initialSave?: boolean): void {
    if (this.params['dateRangeFlug'].Flugart && this.params['dateRangeFlug'].Flugart.id === 1) {
      this.params['dateRangeFlugText'] = this.params['dateRangeFlug'].rangeStart.format('L');
    } else if (this.params['dateRangeFlug'].rangeStart && this.params['dateRangeFlug'].rangeEnd) {
      this.params['dateRangeFlugText'] = this.ls.locs['locReisekonfigurator'].ZeitraumText
        .replace('{0}', this.params['dateRangeFlug'].rangeStart.format('L'))
        .replace('{1}', this.params['dateRangeFlug'].rangeEnd.format('L'))
        .replace('{2}', this.params['dateRangeFlug'].Flugart.Text);
    } else {
      this.params['dateRangeFlugText'] = undefined;
    }

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for passagiereFlug
   * sidebar param.
   */
  private _passengersFlightSave(initialSave?: boolean): void {
    if (this.params['passagiereFlug'].AnzahlKinder > 0 || this.params['passagiereFlug'].AnzahlErwachsene > 0) {
      this.params['passagiereFlugText'] = this.ls.locs['locReisekonfigurator'].PassagiereText
        .replace('{0}', this.params['passagiereFlug'].AnzahlErwachsene || '0')
        .replace('{1}', this.params['passagiereFlug'].AnzahlKinder || '0');
    } else {
      this.params['passagiereFlugText'] = undefined;
    }

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for flugAbflughafen
   * sidebar param.
   */
  private _airportOfDepartureSave(initialSave?: boolean): void {
    if (this.params['flugAbflughafen'].flughaefen && this.params['flugAbflughafen'].flughaefen.length > 0) {
      this.params['abflughafenTextFlug'] = this.params['flugAbflughafen'].flughaefen.map((airport: any) => {
        return airport['Bezeichnung'];
      }).reduce((a: string, b: string) => {
        return a + ', ' + b;
      });
    } else {
      this.params['abflughafenTextFlug'] = undefined;
    }

    this.params['flugAbflughafen'].header = this.ls.locs['locReisekonfigurator'].AbflughafenSidebarHeader;
    this.params['flugAbflughafen'].noResults = false;
    this.params['flugAbflughafen'].noSelection = false;

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for flugZielflughafen
   * sidebar param.
   */
  private _targetAirportSave(initialSave?: boolean): void {
    if (this.params['flugZielflughafen'].flughaefen && this.params['flugZielflughafen'].flughaefen.length > 0) {
      this.params['zielflughafenTextFlug'] = this.params['flugZielflughafen'].flughaefen.map((airport: any) => {
        return airport['Bezeichnung'];
      }).reduce((a: string, b: string) => {
        return a + ', ' + b;
      });
    } else {
      this.params['zielflughafenTextFlug'] = undefined;
    }

    this.params['flugZielflughafen'].header = this.ls.locs['locReisekonfigurator'].ZielflughafenSidebarHeader;
    this.params['flugZielflughafen'].noResults = false;
    this.params['flugZielflughafen'].noSelection = false;

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

}
