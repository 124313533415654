import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, SimpleChanges, Injector, InjectionToken, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import animateScrollTo from 'animated-scroll-to';

import { InboxService } from './../inbox.service';
import { UserProfileNavbarService } from './../../user-profile-navbar/user-profile-navbar.service';
import { InboxUnreadService } from './../inbox-unread.service';
import { InboxDeletedService } from './../inbox-deleted.service';
import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';
import { MpAnalyticsService } from './../../../services/mp-analytics.service';

import { InboxDetailsCampaignComponent } from './../inbox-details-campaign/inbox-details-campaign.component';
import { InboxDetailsMessageComponent } from './../inbox-details-message/inbox-details-message.component';
import { InboxDetailsOrderComponent } from './../inbox-details-order/inbox-details-order.component';
import { InboxDetailsStageComponent } from './../inbox-details-stage/inbox-details-stage.component';

/**
 * This class provides the functions
 * for the single inbox items in the
 * users inbox.
 */
@Component({
  selector: 'mp-core-inbox-item',
  templateUrl: './inbox-item.component.html',
  styleUrls: ['./../../card/styles/card.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxItemComponent implements OnInit, OnDestroy {

  @Input() public item: any;
  @Input() public isPreview: boolean = false;

  @Output() deleteItem = new EventEmitter<any>()

  public hoverItem: boolean = false;
  public isEMailType: boolean = false;
  public cuttedText: string = '';
  public cuttedTeaser: string = '';
  public injector: Injector | any;
  public trustedHtml: any;

  private _eMailItemTypes: Array<string> = [
    'nachricht',
    'marketingportal',
    'praemienportal',
    'schulungsportal',
    'serviceportal',
    'werbemittelportal'
  ];

  private _setReadSubscription: Subscription | undefined;
  private _parentCickFunctionTriggeredSubscription: Subscription | undefined;

  constructor(
    public sanitizer: DomSanitizer,
    private _inboxService: InboxService,
    private _userProfileNavbar: UserProfileNavbarService,
    private _inboxUnread: InboxUnreadService,
    private _inboxDeleted: InboxDeletedService,
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _injector: Injector,
    private _mpAnalytics: MpAnalyticsService
  ) { }

  /**
   * Checks whether or not the item is
   * an email. Triggers the cut of
   * teaser and text of the item.
   */
  ngOnInit(): void {
    if (this.item !== null) {
      if (this._eMailItemTypes.indexOf(this.item.Type) !== -1) {
        this.isEMailType = true;
      }

      this._setCuttedTexts();
      window.addEventListener('resize', this._setCuttedTexts.bind(this));

      this._parentCickFunctionTriggeredSubscription = this._inboxService.parentCickFunctionTriggered.subscribe((data: Array<any>) => {
        const item = data[0];
        const funcName = data[1];

        if (item === this.item) {
          if (funcName === 'onItemDelete') {
            this.onItemDelete(item);
          } else if (funcName === 'onClick') {
            this.onClick(item);
          }
        }
      });
    }
  }

  /**
   * Unsubscribes the set subscriptions
   * and removes the set event listeners.
   */
  ngOnDestroy(): void {
    window.removeEventListener('resize', this._setCuttedTexts.bind(this));

    if (typeof this._setReadSubscription !== 'undefined') {
      this._setReadSubscription.unsubscribe();
    }

    if (typeof this._parentCickFunctionTriggeredSubscription !== 'undefined') {
      this._parentCickFunctionTriggeredSubscription.unsubscribe();
    }
  }

  /**
   * Sets the cutted teaser and
   * the cutted text.
   */
  private _setCuttedTexts(): void {
    this.cuttedTeaser = this._cutText(this.item.Teaser, true);
    this.cuttedText = this._cutText(this.item.Text);
  }

  /**
   * Maps the child template path
   * from backendto the child template
   * in Angular.
   */
  getChildComponent(childTemplate: string): any {
    switch (childTemplate) {
      case '/Views/ng-templates/postfach-details-aktion':
        return InboxDetailsCampaignComponent;
      case '/Views/ng-templates/postfach-details-nachricht':
        return InboxDetailsMessageComponent;
      case '/Views/ng-templates/postfach-details-bestellung':
        return InboxDetailsOrderComponent;
      case '/Views/ng-templates/postfach-details-buehne':
        return InboxDetailsStageComponent;
    }
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
  }

  /**
   * Cuts the given text.
   */
  private _cutText(text: string, isTeaser?: boolean): string {
    isTeaser = isTeaser || false;

    let cut = text;
    let cutlimit;
    let tempCut;

    if (window.innerWidth < 768) {
      if (isTeaser) {
        cutlimit = 100;
      } else {
        cutlimit = 150;
      }
    } else {
      if (isTeaser) {
        cutlimit = 200;
      } else {
        cutlimit = 250;
      }
    }

    if (cutlimit >= cut.length) {
      return cut;
    }

    tempCut = cut.substring(0, cutlimit);

    if (tempCut !== ' ') {
      cutlimit = cut.indexOf(' ', cutlimit);

      if (cutlimit >= cut.length) {
        return cut;
      }
    }

    cut = cut.substring(0, cutlimit) + '...';
    return cut;
  }

  /**
   * Sets the clicked item to read state,
   * opens the link (if exists) in a new
   * window, or scrolls (if link doesn't
   * exist) to the item.
   */
  onClick(item: any, openedPreview?: boolean): void {
    if (typeof item.showChild === 'undefined') {
      item.showChild = false;
    };

    const postfachItemsContainer = document.getElementById('inbox-items-container');
    this._inboxService.setActiveItem(item);

    if (typeof openedPreview === 'undefined') {
      this._mpAnalytics.trackEvent('Nachricht-Klick', item.Teaser || item.Betreff, item.Key);
    }

    if (postfachItemsContainer !== null) {
      if (postfachItemsContainer.classList.contains('inbox-item-opened')) {
        postfachItemsContainer.classList.remove('inbox-item-opened');
      }
    }

    item.Gelesen = true;

    this._setReadSubscription = this._apiService.postRequest('/api/Postfach/SetGelesen', { TypId: item.TypId, Key: item.Key }).subscribe(() => {
      this._inboxUnread.getUnread();
    });

    if (this.isPreview) {
      this._userProfileNavbar.setParams('PostfachType', item.Type);
      this._userProfileNavbar.setParams('PostfachKey', item.Key);
      this._userProfileNavbar.changeTab(4, true);
    } else {
      if (item.Link) {
        window.open(item.Link, '_blank');
      } else {
        item.showChild = !item.showChild;

        if (item.showChild) {
          if (postfachItemsContainer !== null) {
            if (!postfachItemsContainer.classList.contains('inbox-item-opened')) {
              postfachItemsContainer.classList.add('inbox-item-opened');
            }
          }

          const navbar = document.getElementById('navbar');
          const userProfileNavbar = document.getElementById('user-profile__navbar');
          const itemElem = document.getElementById(item.Key);

          if (navbar !== null && itemElem !== null) {
            const navbarHeight = navbar.getBoundingClientRect().height;
            const userProfileNavbarHeight = userProfileNavbar === null ? 0 : userProfileNavbar.getBoundingClientRect().height;

            setTimeout(() => {
              animateScrollTo(itemElem.offsetTop - navbarHeight - userProfileNavbarHeight - 70, { maxDuration: 100, easing: (t) => { return 1 - (--t) * t * t * t } });
            }, 500);
          }
        } else {
          const inboxHeader = document.getElementById('inbox-header');

          if (inboxHeader !== null) {
            inboxHeader.style.display = '';
          }
        }
      }
    }

    this._createInjector();
  }

  /**
   * Triggers the delete item modal.
   */
  onItemDelete(item: any): void {
    this.deleteItem.emit(item);
  }

  /**
   * Watches for changes of items
   * scrollTo property.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.item !== null) {
      if (typeof changes['item'] !== 'undefined' && changes['item'] !== null) {
        const itemElem = document.getElementById(this.item.Key);

        if (itemElem !== null) {
          if (typeof changes['item'].previousValue !== 'undefined' && changes['item'].previousValue['scrollTo'] !== changes['item'].currentValue['scrollTo']) {
            const scrollDistance = itemElem.offsetTop;
            animateScrollTo(scrollDistance, { maxDuration: scrollDistance / 2, easing: (t) => { return 1 - (--t) * t * t * t } });
          }
        }
      }
    }
  }

  /**
   * Scrolls to opened item, if
   * showChild is triggered from
   * outside (e.g. dashboard).
   */
  ngAfterViewInit(): void {
    if (typeof this.item.showChildFromPreview !== 'undefined') {
      if (this.item.showChildFromPreview) {
        this.onClick(this.item, true);
      }
    }
  }

  /**
   * Creates the injector for the
   * dynamically loaded (sub-)components.
   */
  private _createInjector() {
    this.injector = Injector.create({
      providers: [
        { provide: 'iboxItem', useValue: this.item }
      ],
      parent: this._injector
    });
  }

}
