import { Directive, OnInit } from '@angular/core';

import { MpLoaderService } from './../../../../services/mp-loader.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';


/**
 * This class provides the data and functions
 * for the status account table.
 */
@Directive({
  selector: '[mpCoreStatusAccount]',
  exportAs: 'mpCoreStatusAccount'
})
export class StatusAccountDirective implements OnInit {

  public statusSearchTerm: string = '';

  private _statusAccountTableLoader: MpLoader = {
    name: 'statusAccountTableLoader',
    params: {}
  };
  
  constructor(
    private _mpLoaderService: MpLoaderService
  ) { }

  /**
   * Registers and extends the loader.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._statusAccountTableLoader);
    const loader = this._mpLoaderService.getLoader(this._statusAccountTableLoader.name);

    this._mpLoaderService.extendLoader(this._statusAccountTableLoader.name, {
      params: loader.params || {},
      isReady: true
    });
  }

  /**
   * Triggers a search.
   */
  statusSearch(): void {
    const loader = this._mpLoaderService.getLoader(this._statusAccountTableLoader.name);

    this._mpLoaderService.extendLoader(this._statusAccountTableLoader.name, {
      params: Object.assign(loader.params || {}, {
        SuchtextOutside: this.statusSearchTerm
      })
    });

    if (typeof loader.load !== 'undefined') {
      loader.load();
    }
  }

}
