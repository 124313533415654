<div class="layout-container" *ngIf="(ls.locsLoaded | async) && groupingLoaded">
  <h1 class="mobile-only">{{ ls.locs['loc'].Gruppierungsuebersicht + ': ' + grouping['Name']}}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Gruppierungsuebersicht + ': ' + grouping['Name']}}"
                      infotextShorten="GruppierungsuebersichtDetails"
                      fieldsAction="/api/Gruppierung/getGruppierungsuebersichtDetailsFields?id={{groupingId}}"
                      listAction="/api/Gruppierung/getGruppierungsuebersichtDetails"
                      loaderName="managerGroupingOverviewDetailsLoader"
                      [paging]="true"
                      [footer]="true"
                      [disableCache]="true"
                      defaultSorting="Anlagedatum DESC">
  </mp-core-data-table>
  <div class="floating-container">
    <button class="mp-button _prim margin-top-24 float-left" (click)="toggleAddOverlay()" [hidden]="!grouping['TnIdMapping']">{{ ls.locs['loc'].GruppierungAddTeilnehmer }}</button>
    <button class="mp-button _prim margin-top-24 float-left" (click)="toggleAddOverlay()" [hidden]="!grouping['FirmenIdMapping']">{{ ls.locs['loc'].GruppierungAddFirma }}</button>
    <a *ngIf="loader !== null && loader.getExcelParams" href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <mp-core-modal [show]="showAddOverlay" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: toggleAddOverlay}, {text: ls.locs['loc'].Speichern, action: addMember, class: '_prim'}]">
    <span [innerText]="grouping['AddMemberText']"></span>
    <mp-core-input class="margin-top-16" label="{{ ls.locs['loc'].FirmenName }}" mpId="new-member" mpType="text" [hidden]="!grouping['FirmenIdMapping'] || grouping['TnIdMapping']" [(model)]="grouping['MemberId']"></mp-core-input>
    <mp-core-input class="margin-top-16" label="{{ ls.locs['loc'].Benutzername }}" mpId="new-member" mpType="text" [hidden]="grouping['FirmenIdMapping'] || !grouping['TnIdMapping']" [(model)]="grouping['MemberId']"></mp-core-input></mp-core-modal>
  <div class="ui-helper-clearfix"></div>
</div>
