import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CommonComponentsLoaderModule } from './../common-components-loader.module';

import { ExternFixedComponent } from './extern-fixed.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';
import { EmailOptOutComponent } from './pages/email-opt-out/email-opt-out.component';
import { OptOutComponent } from './pages/opt-out/opt-out.component';

@NgModule({
  declarations: [
    ExternFixedComponent,
    ConfirmEmailComponent,
    EmailOptOutComponent,
    OptOutComponent
  ],
  exports: [
    ExternFixedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonComponentsLoaderModule
  ]
})
export class ExternFixedModule { }
