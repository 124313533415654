import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, ChangeDetectorRef, SimpleChanges, OnDestroy, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';

import { Subject } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';

/**
 * This class provides a select
 * can be configured with different select atributes
 * (e.g. for editable, for multiple, ...).
 */
@Component({
  selector: 'mp-core-select',
  templateUrl: './select.component.html',
  styleUrls: [
    './select.component.scss',
    './../../checkbox/checkbox.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public model: any;
  @Input() public label: string = '';
  @Input() public multiple: boolean = false;
  @Input() public editable: boolean = false;
  @Input() public elements: any;
  @Input() public textProperty: string = '';
  @Input() public valueProperty: string = '';
  @Input() public mpPlaceholder: string = '';
  @Input() public mpRequired: boolean = false;
  @Input() public mpId: string = '';
  @Input() public keepPlaceholderText: boolean = false;
  @Input() public focus: boolean = false;
  @Input() public comboModus: boolean = false;
  @Input() public allowHtml: boolean = false;
  @Input() public mpOptional: boolean = false;
  @Input() public hasError: boolean = false;
  @Input() public mpDisabled: boolean = false;
  @Input() public placeholder: string = '';
  @Input() public deselectable: boolean = false;

  @Output() ngChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdown', { static: false }) dropdown!: ElementRef;
  @ViewChild('selectContainer', { static: false }) selectContainer!: ElementRef;
  @ViewChildren('dropdownSelects') dropdownSelects!: QueryList<ElementRef>;

  public selectNotifier$ = new Subject();
  public selectDropdown: any;
  public mainContent: any;
  public footer: any;
  public navbar: any;
  public selectDropdownHeight: number = 0;
  public containerWindowDiff: number = 0;
  public openUp: boolean = false;
  public showDropdown: boolean = false;
  public elementsSet: boolean = false;
  public modelText: string = '';
  public errClass: string = '';
  public currentlyFocused: any;
  public modelSelected: boolean = false;

  constructor(
    public ls: MpLocalizationService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private ref: ChangeDetectorRef
  ) { }

  /**
   * Angulars init function of the class. 
   */
  ngOnInit(): void {
    this.errClass = '';

    if (typeof this.elements !== 'undefined') {
      if (this.elements.length > 1000) {
        this.elements = this.elements.slice(0, 1000);
      }

      this._updateItemsByModel();
    }

    this.elementsSet = true;
  }

  private _updateItemsByModel() {
    if (this.multiple && this.model) {
      this.elements.forEach((elem: any) => {
        elem['isSelected'] = this.isSelected(elem);
      });
    } else {
      this.elements.forEach((elem: any) => {
          this.modelSelected = elem == this.model;
      });
    }
  }

  /**
   * A lifecycle hook that is called when a data-bound property of a directive changes.
   * Since the changes of class properties like model, hasError and elements are processed
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['hasError'] !== 'undefined' && typeof changes['hasError'].currentValue !== 'undefined') {
      () => {
        if (changes['hasError'].currentValue) {
          this.errClass = 'invalid-error';
        } else {
          this.errClass = '';
        }
      };
    }
    if (typeof this.model !== 'undefined' && typeof changes['model'] !== 'undefined') {
      if (changes['model'].currentValue !== changes['model'].previousValue || changes['model'].isFirstChange()) {
        this.model = changes['model'].currentValue;
        this._updateItemsByModel();
        this.setModelText();
      }
    }
    if (typeof this.elements !== 'undefined') {
      if (typeof changes['elements'] !== 'undefined' && typeof changes['elements'].previousValue !== 'undefined') {
        if (changes['elements'].currentValue !== changes['elements'].previousValue) {
          this.elements = changes['elements'].currentValue;
          this.setModelText();
        }
      } else if (typeof changes['elements'] !== 'undefined' && typeof changes['elements'].previousValue === 'undefined') {
        this.elements = changes['elements'].currentValue;
        this.setModelText();
      }
    }
    if (typeof this.focus !== 'undefined') {
      if (typeof changes['focus'] != 'undefined' && typeof changes['focus'] != 'undefined') {
        if (changes['focus'].currentValue !== changes['focus'].previousValue) {
          this.setFocus();
        }
      }
    }

    //@ts-ignore
    if (document.querySelector('html').classList.contains('MobileDevice') && document.querySelector('html').classList.contains('iOSDevice')) {
      let elem = this._elementRef.nativeElement;
      let maxSelectHeight = Math.abs(window.innerHeight * 0.6);

      () => {
        let posTop = Math.abs(window.pageYOffset + (window.innerHeight * 0.5));

        if (changes['showDropdown'].currentValue !== changes['showDropdown'].previousValue) {
          if (changes['showDropdown'].currentValue === true) {
            this._renderer.setAttribute(elem.querySelector('.select-dropdown'), 'style', 'max-height: ' + maxSelectHeight + 'px; top: ' + posTop + 'px;');

            if (elem.parentElement.parentElement.parentElement.parentElement.hasClass('scroll-area-container')) {
              elem.parentElement.parentElement.parentElement.parentElement.css({ 'overflow-y': 'visible' });
            }
          } else {
            this._renderer.removeAttribute(elem.querySelector('.select-dropdown'), 'style');

            if (elem.parentElement.parentElement.parentElement.parentElement.hasClass('scroll-area-container')) {
              elem.parentElement.parentElement.parentElement.parentElement.removeAttr('style');
            }
          }
        }
      };
    }
  }

  /**
   * Checks if it was keyup and calls the function jumpToLetter of class
   */
  ngOnDestroy(): void {
    document.removeEventListener('keyup', this.jumpToLetter, false);
  }

  /**
   * Toggles dropdown from select
   */
  toggleDropdown(): void {
    if (this.mpDisabled)
      return;

    this.openUp = false;

    if (this.dropdown) {
      this.selectDropdown = this.dropdown.nativeElement;

      if (this.dropdownSelects) {
        this.mainContent = document.getElementById('main');
        this.footer = document.getElementById('footer');
        this.navbar = document.getElementById('navbar');
        this.containerWindowDiff = this.mainContent.offsetHeight - (this.selectContainer.nativeElement.getBoundingClientRect().top + this.selectContainer.nativeElement.getBoundingClientRect().height + window.scrollY);

        if (this.footer !== null) {
          this.containerWindowDiff = this.containerWindowDiff - this.footer.getBoundingClientRect().height;
        }

        if (this.navbar !== null) {
          if (this.navbar.classList.contains('is-sticky') !== false) {
            this.containerWindowDiff = this.containerWindowDiff + 28;
          }
        }

        this.selectDropdownHeight = this.selectDropdown.offsetHeight;

        if (this.dropdownSelects.length > 0) {
          let selectDropdownMaxHeight = parseInt(window.getComputedStyle(this.selectDropdown)
            .getPropertyValue('max-height').replace('px', ''));
          let selectionHeight = parseInt(window.getComputedStyle(this.dropdownSelects.get(0)?.nativeElement)
            .getPropertyValue('height')
            .replace('px', ''));
          this.selectDropdownHeight = selectionHeight * this.dropdownSelects.length > selectDropdownMaxHeight
            ? selectDropdownMaxHeight
            : selectionHeight * this.dropdownSelects.length;
        }

        if (this.containerWindowDiff < this.selectDropdownHeight) {
          this.openUp = true;
        } else {
          this.openUp = false;
        }

        this.showDropdown = !this.showDropdown;
      } else {
        return;
      }
    } else {
      return;
    }
  }

  /**
   * Opens dropdown from selectge
   */
  openDropdown(): void {
    if (this.comboModus) {
      this.model = this.modelText;
    } else {
      this.showDropdown = true;
    }
  }

  /**
   * Closes dropdown from select
   */
  closeDropdown() {
    this.showDropdown = false;
  }

  /**
   * Sends modified model to father component
   */
  select(element: any): void {
    if (this.multiple) {
      if (!this.model || !Array.isArray(this.model)) {
        this.model = [];
      }

      let e = this.getValue(element);
      let indexOfE = this.model.findIndex((x: any) => {
        if (typeof x === 'object') {
          return JSON.stringify(x, this._isSelectedReplacer) === JSON.stringify(e, this._isSelectedReplacer);
        } else {
          return x === e;
        }
      });

      if (indexOfE > -1) {
        this.model.splice(indexOfE, 1);
      } else {
        this.model.push(e);
      }

      this.modelChange.emit(this.model);
    } else {
      this.model = this.getValue(element);
      this.modelChange.emit(this.model);

      setTimeout(() => {
        this.closeDropdown();
      }, 0);
    }

    if (this.ngChange) {
      setTimeout(() => {
        this.ngChange.emit(this.model);
      }, 0);
    }

    this.ref.markForCheck();
    this.setModelText();
  }

  /**
   * Checks if element is selected and returns it as model
   */
  isSelected(e: any) {
    if (this.multiple && this.model) {
      return this.model.map((value: any) => this.getElementByValue(value)).indexOf(e) > -1;
    }
    return e == this.model;
  }

  /**
   * Gets value of element
   */
  getValue(e: any): any {
    return this.valueProperty !== '' && e ? e[this.valueProperty] : e;
  }

  /**
   * Gets text of element
   */
  getText(e: any): string {
    let val = this.textProperty !== '' && e ? e[this.textProperty] : e;
    if (val === 0) {
      val = val + '';
    }
    return val;
  }

  /**
   * Checks whether or not given
   * variable is type of array.
   */
  isArray(a: any): any {
    return a && a.constructor === Array;
  }

  /**
   * Gets element by value
   */
  getElementByValue(v: any) {
    return this.elements.find(
      (el: any) => {
        if (typeof v === 'object') {
          return JSON.stringify(this.getValue(el), this._isSelectedReplacer) === JSON.stringify(v, this._isSelectedReplacer);
        } else {
          return this.getValue(el) === v;
        }
      }
    );
  }

  /**
   * Checks whether or not the element
   * should be shown in the dropdown
   * of the select-input.
   */
  showElement(value: any) {
    if (this.comboModus)
      return true;

    return this.multiple ||
      !this.editable ||
      !value ||
      !this.modelText ||
      this.getText(value).toString().toLowerCase().indexOf(this.modelText.toString().toLowerCase()) > -1;
  }

  /**
   * Sets a focus of element in select
   */
  setFocus(): void {
    this.showDropdown = this.focus;
  }

  /**
   * Sets text of model
   */
  setModelText() {
    if (!(this.placeholder && this.keepPlaceholderText)) {
      this.modelText = '';
      if (this.multiple && Array.isArray(this.model) && this.model.length > 0) {
        let texts = this.model.map(
          (e: any) => {
            return this.getText(this.getElementByValue(e));
          });
        this.modelText = texts.reduce(
          (t1: string, t2: string) => {
            return t1 + ', ' + t2;
          });
      } else if (!this.multiple && this.model !== undefined) {
        let e = this.valueProperty
          ? this.getElementByValue(this.model)
          : this.model;
        this.modelText = this.getText(e);
      }
    }

    if (this.modelText === undefined || this.modelText === null) {
      this.modelText = this.placeholder || '';
    }
  }

  /**
   * Jump to the found letter, if select is editable
   */
  jumpToLetter(event: any) {
    if (this.showDropdown && !this.editable) {
      let toFocus = this.elements.find(
        (e: any) => {
          let text = this.textProperty ? e[this.textProperty] : e;

          if (typeof text !== 'string')
            return false;

          if (text.toString().toLowerCase()[0] == event.key.toString().toLowerCase()) {
            if (this.currentlyFocused &&
              this.currentlyFocused[0].toString().toLowerCase() == event.key.toString().toLowerCase() &&
              this.currentlyFocused >= text)
              return false;

            return true;
          }

          return false;
        });

      if (!toFocus)
        return;

      let text = this.textProperty ? toFocus[this.textProperty] : toFocus;

      let elements = this._elementRef.nativeElement.querySelectorAll('.text-label');
      let element = elements.find(
        (e: any) => {
          return e.text() == text;
        });

      if (!element)
        return;

      let scrollElement = this._elementRef.nativeElement.querySelector('.scroll-section');
      scrollElement.scrollTop(scrollElement.scrollTop() + element.position().top);
    }
  }

  _isSelectedReplacer(key: string, value: any) : any {
    if (key == "isSelected") return undefined;
    return value;
  }

  /**
   * Deselects the selcted model.
   */
  deselect(evt: MouseEvent): void {
    evt.preventDefault();
    this.model = null;
    this.closeDropdown();
    this.modelChange?.emit(this.model);
  };
}

