<div *ngIf="(ls.locsLoaded | async) && !loading">
  <div class="showbox" [hidden]="!loading">
    <div class="loader">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
  <div class="details-header header padding-top-16 floating-container padding-bottom-0">
    <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Close }}" class="float-right">
      <a class="mp-button" (click)="onClick($event, item)">
        <i class="svg-icon __clr-default __size-18 __clear __top-10"></i>
      </a>
    </mp-core-tooltip>
    <div class="title display-block padding-0 font-size-m font-weight-400 float-left text-overflow-ellipsis">
      <span [innerHTML]="item.Betreff"></span>
    </div>
  </div>
  <div class="animate-show" [ngClass]="{open: !loading}">
    <div class="rich-media text-align-center">
      <img class="max-width-full z-index-1 width-full" *ngIf="item.SubItems[0].Image" src="{{item.SubItems[0].Image}}?h=427&w=1280&mode=crop" alt="{{item.Title}}" />
    </div>
    <div class="header padding-top-bottom-16 floating-container border-top">
      <div class="row-inner">
        <div class="grid col-small-6">
          <div class="inbox-item-title">
            <i class="svg-icon __clr-primary __size-18 margin-right-16" [ngClass]="item.Type"></i>
            <div class="icon-text">
              <h1 [innerHTML]="item.SubItems[0].Titel"></h1>
              <p [innerHTML]="item.SubItems[0].Teaser"></p>
              <p [innerText]="'Online vom: ' + item.SubItems[0].AnzeigeVon + ' - ' + item.SubItems[0].AnzeigeBis"></p>
              <span [innerHTML]="item.SubItems[0].Text"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
