<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">
    {{ ls.locs['loc'].PraemienStatistik }}
    <mp-core-infotext [sitename]="'praemienStatistik'"></mp-core-infotext>
  </h1>
  <mp-core-data-table title="{{ ls.locs['loc'].PraemienStatistik }}"
                 infotextShorten="PraemienStatistik"
                 fieldsAction="/api/Statistik/getPraemienStatistikParentFields"
                 listAction="/api/Statistik/getPraemienStatistik"
                 [recLoadedFunc]="recLoadedFunc"
                 loaderName="managerAwardStatisticLoader"
                 [paging]="true"
                 [footer]="true"
                 defaultSorting="Anzahl DESC"
                 [showDatePicker]="true"
                 [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways">
  </mp-core-data-table>
    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a href="{{excelService}}?{{loader.getExcelParams()}}">
        <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
      </a>
    </div>
  <div class="ui-helper-clearfix"></div>
</div>
