<div id="order-overview" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" class="padding-8">
  <div class="card bg-color-transparent hide-on-mobile">
    <div class="margin-bottom-4 bg-color-white order-overview-headline display-flex flex-align-items-center flex-justify-cont-center text-transform-uppercase font-weight-700 padding-top-bottom-0 padding-left-right-16">{{ ls.locs['loc'].Bestelluebersicht }}</div>
    <div class="margin-bottom-4 bg-color-white padding-16">
      <p class="margin-0">
        <strong>{{sBCount}} {{sBCount != 1 ? ls.locs['loc'].Praemien : ls.locs['loc'].Praemie}}</strong>
      </p>

      <div class="flex-grid flex-justify-cont-between">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s">{{ ls.locs['loc'].Gesamtpraemienwert }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end">{{sBItems | sumOfItems:'BasisPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        <span *ngIf="mpSettings.settings['BestellprozessSettings'].ShowInklVersandkosten" class="flex-col-12 flex-col-12-m flex-col-12-s">{{ ls.locs['loc'].InklVersandkosten }}</span>
      </div>

      <div *ngIf="!mpSettings.settings['BestellprozessSettings'].HideVersandkosten"
           class="flex-grid flex-justify-cont-between"
           [hidden]="(sBItems | sumOfItems:'AuslandsversandPunkte') === 0">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s">{{ ls.locs['loc'].Lieferkosten }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end">{{sBItems | sumOfItems:'AuslandsversandPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div *ngIf="!mpSettings.settings['BestellprozessSettings'].HideVersandkosten"
           class="flex-grid flex-justify-cont-between"
           [hidden]="(sBItems | sumOfItems:'ExpressServicezuschlag') === 0">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s">{{ ls.locs['loc'].Express }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end">{{sBItems | sumOfItems:'ExpressServicezuschlag' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>

      <div *ngIf="!mpSettings.settings['BestellprozessSettings'].HideZusatzkosten"
           class="flex-grid flex-justify-cont-between"
           [hidden]="(sBItems | sumOfItems:'ZusatzPunkte') === 0">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s">
          {{ ls.locs['loc'].Zusatzkosten }}
          <mp-core-popover content="{{ ls.locs['loc'].ZusatzkostenInfo }}"
                           alignment="left"
                           popoverTitle="{{ ls.locs['loc'].Zusatzkosten }}">
            <i class="svg-icon __clr-info __size-18 __top-4 __help-outline order-process-icon"></i>
          </mp-core-popover>
        </span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end">{{sBItems | sumOfItems:'ZusatzPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>

      <div class="divider margin-top-bottom-16" *ngIf="!sBItems[0].TnIsOnPaymentBlacklist"></div>
      <div class="flex-grid flex-justify-cont-between" *ngIf="!sBItems[0].TnIsOnPaymentBlacklist">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end">{{sBItems | sumOfItems:'EingeloestePunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div class="flex-grid flex-justify-cont-between" *ngIf="!sBItems[0].TnIsOnPaymentBlacklist">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s order-overview-additional-pay-label">
          {{ ls.locs['locWkZuzahlung'].Gesamtzuzahlung  }}
          <mp-core-popover content="{{ ls.locs['locWkZuzahlung'].GesamtzuzahlungInfo }}" alignment="left" popoverTitle="{{ ls.locs['locWkZuzahlung'].Gesamtzuzahlung }}">
            <i class="svg-icon __clr-info __size-18 __top-4 __help-outline order-process-icon"></i>
          </mp-core-popover>
        </span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end order-overview-additional-pay-euro">{{sBItems | sumOfItems:'ZuzahlungEuro' | currency:'EUR':''}} €</span>
      </div>

    </div>
    <div class="bg-color-white padding-16 flex-grid flex-justify-cont-center flex-direction-row flex-wrap margin-0">
      <div class="flex-grid flex-justify-cont-between flex-col-12 padding-0">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s bold padding-0">{{ ls.locs['loc'].Gesamtkosten }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end bold padding-0">{{sBItems | sumOfItems:'EingeloestePunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div class="flex-grid flex-justify-cont-between flex-col-12 padding-0" *ngIf="!tnIsOnPaymentBlacklist">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s padding-0 text-color-notice">{{ ls.locs['locWkZuzahlung'].plus }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s padding-0 flex-justify-cont-end bold">{{sBItems | sumOfItems:'ZuzahlungEuro' | currency:'EUR':''}} €</span>
      </div>
      <a class="mp-button _prim margin-top-24 flex-col-12"
         (click)="buttonFunction && buttonFunction($event)"
         [ngClass]="{disabled: buttonDisabled}"
         *ngIf="currentStepNextUrl !== ''"
         href="#/{{role}}/{{currentStepNextUrl}}">
        {{nextText}}
      </a>
    </div>

    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte"
         class="points-after-order padding-16">
      <div class="flex-grid flex-justify-cont-between">
        <span class="flex-col-8 flex-col-12-m flex-col-12-s">{{ ls.locs['loc'].KontostandNachBestellung }}</span>
        <span class="flex-col-4 flex-col-12-m flex-col-12-s flex-justify-cont-end">{{getRemainPoints('EingeloestePunkte') | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="mpSettings.settings['BestellprozessSettings'].GeldwerterVorteil" class="versand-hinweis-geldwerter-vorteil margin-top-12 padding-8">
  <mp-core-micro-copy infoState="white"
                      copyText="{{ ls.locs['loc'].VersandHinweisGeldwerterVorteil }}"
                      class="margin-left-8 padding-right-32 padding-top-64 margin-top-16">
  </mp-core-micro-copy>
</div>
</div>
