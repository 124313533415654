<div [hidden]="!show || !rental['infoText'] " class="bg-color-white hotel-details tabbed-details" *ngIf="(ls.locsLoaded | async)">
  <div class="tap-wrapper">
    <div [ngClass]="'visible-tabs-'+tabCounter" class="flex-grid margin-0">
      <div class="flex-col-6 flex-col-6-xs text-transform-uppercase padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 pointer-hover" (click)="tabIndex = 1" [ngClass]="[tabIndex == 1 ? 'active bg-color-brand-primary' : 'not-active']" counter="tabCounter">{{ ls.locs['loc'].Details }}</div>
      <div class="flex-col-6 flex-col-6-xs text-transform-uppercase padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 pointer-hover" (click)="tabIndex = 2" [ngClass]="[tabIndex == 2 ? 'active bg-color-brand-primary' : 'not-active']" counter="tabCounter">{{otherDropOffLocation === false ? ls.locs['locReisekonfigurator'].Standort : ls.locs['locReisekonfigurator'].Standorte}}</div>
    </div>
    <div class="rk-tab-content">
      <div [hidden]="tabIndex !== 1" class="rk-hotel-details">
        <div class="bold margin-bottom-16">{{ ls.locs['locReisekonfigurator'].Mietbedingungen }}</div>
        <div [innerHTML]="rental['infoText']"></div>
      </div>
      <div *ngIf="tabIndex === 2" class="rk-hotel-details">
        <div *ngIf="rental['StandortAbholung']">
          <div class="bold margin-bottom-16" [hidden]="rental['StandortAbholung'].Code === rental['StandortRueckgabe'].Code ">
            {{ ls.locs['locReisekonfigurator'].WeitereInformationenAbholung }}
          </div>
          <div class="bold margin-bottom-16" [hidden]="rental['StandortAbholung'].Code !== rental['StandortRueckgabe'].Code ">{{ ls.locs['locReisekonfigurator'].WeitereInformationenAbholungUndRueckgabe }}</div>
          <div>
            <span [innerText]="rental['StandortAbholung'].Name"></span>
            <span [hidden]="!rental['StandortAbholung'].AddressLine && !rental['StandortAbholung'].AddressLine2 ">{{rental['StandortAbholung'].AddressLine}} {{rental['StandortAbholung'].AddressLine2}}</span>
            <span>{{rental['StandortAbholung'].Street}} {{rental['StandortAbholung'].Street2}} {{rental['StandortAbholung'].StreetNumber}}</span>
            <span>{{rental['StandortAbholung'].Country}}-{{rental['StandortAbholung'].ZipCode}} {{rental['StandortAbholung'].CityName}}<span [hidden]="!rental['StandortAbholung'].County"> ({{rental['StandortAbholung'].County}})</span></span>
          </div>
          <div>
            <ng-container *ngFor="let officehour of rental['StandortAbholung'].OfficeHours">
              <div>{{officehour.DayOfWeekDisplay}}</div>
              <div>{{officehour.OpeningTime}}</div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="rental['StandortRueckgabe'] && rental['StandortAbholung'].Code !== rental['StandortRueckgabe'].Code">
          <div class="bold margin-bottom-16">
            {{ ls.locs['locReisekonfigurator'].WeitereInformationenRueckgabe }}
          </div>
          <div>
            <span [innerText]="rental['StandortRueckgabe'].Name"></span>
            <span [hidden]="!rental['StandortRueckgabe'].AddressLine && !rental['StandortRueckgabe'].AddressLine2 ">{{rental['StandortRueckgabe'].AddressLine}} {{rental['StandortRueckgabe'].AddressLine2}}</span>
            <span>{{rental['StandortRueckgabe'].Street}} {{rental['StandortRueckgabe'].Street2}} {{rental['StandortRueckgabe'].StreetNumber}}</span>
            <span>{{rental['StandortRueckgabe'].Country}}-{{rental['StandortRueckgabe'].ZipCode}} {{rental['StandortRueckgabe'].CityName}}<span [hidden]="!rental['StandortRueckgabe'].County"> ({{rental['StandortRueckgabe'].County}})</span></span>
          </div>
          <div>
            <ng-container *ngFor="let officehour of rental['StandortRueckgabe'].OfficeHours">
              <div>{{officehour.DayOfWeekDisplay}}</div>
              <div>{{officehour.OpeningTime}}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
