<section *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-svg-loader [hidden]="loading.length === 0" svg="LadeanimationenIconsFlugzeug" animation="draw" class="svg-loader" svgAnimation="draw" [time]="1.9" loadingText="{{ls.locs['locReisekonfigurator'].LadeanimationBitteWarten}}"></mp-core-svg-loader>
  <div class="flug-pruefung padding-top-64 padding-bottom-64 rk-two-columns-layout" id="flight-check">
    <div reisekonfigurator-background class="rk-background" style="background-image: url('/themes/default/media/images/rk-video.png')"></div>
    <div id="rk-search-wrapper" class="display-flex flex-justify-cont-center flex-align-items-center hide-on-medium">
      <div id="rk-search" class="padding-top-16 padding-left-16 padding-right-16 padding-bottom-0 width-100-pcnt display-flex flex-nowrap flex-justify-cont-between">
        <div id="rk-back-btn" class="display-inline-flex flex-justify-cont-end __top-0 padding-left-right-8 padding-top-bottom-8 padding-top-0 position-relative">
          <a class="mp-button display-flex flex-align-items-center display-flex" (click)="goBack($event)">
            <span class="display-inline-block position-relative top-2">{{ ls.locs['locReisekonfigurator'].ZurueckZuDenErgebnissen }}</span>
          </a>
        </div>
      </div>
    </div>
    <div id="rk-main-section" class="rk-flug-pruefung">
      <div id="rk-offers" class="margin-top-16 margin-bottom-16">
        <div *ngIf="offerLoaded">
          <div class="rk-offers-headline">
            <h2>{{ ls.locs['locReisekonfigurator'].WerReistMit }}</h2>
          </div>
          <div id="rk-check-guests" class="bg-color-white padding-16">
            <div class="flex-grid flex-direction-row flex-wrap rk-guest-data" *ngFor="let e of checkingParameters['Erwachsene']; let index = index">
              <div class="flex-col-12">
                <span>{{ls.locs['locReisekonfigurator'].NterErwachsener.replace('{0}', index + 1)}}</span>
                <mp-core-popover class="margin-left-8" content="{{ls.locs['locReisekonfigurator'].AngabenAusReisepass}}" alignment="right" popoverTitle="{{ls.locs['locReisekonfigurator'].DatenReisende}}">
                  <i class="svg-icon __clr-info __size-18 __top-2 __info"></i>
                </mp-core-popover>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap flex-align-items-start margin-bottom-16">
                <mp-core-select [mpRequired]="true" [hasError]="e.AnredeRequired ? true : false" mpId="rk-anrede-e-{{index}}" label="{{ls.locs['loc'].Anrede}}" [model]="e.AnredeId" (modelChange)="e.AnredeId = $event; checkParamChanged()" [elements]="salutations" textProperty="Anrede" valueProperty="ID"></mp-core-select>
                <p *ngIf="e.AnredeRequired" class="inputRequired">{{ls.locs['validate'].AnredeRequired}}</p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-input [mpRequired]="true" [hasError]="e.NameRequired ? true : false" mpId="rk-name-e-{{index}}" label="{{ls.locs['loc'].Name}}" [model]="e.Name" (modelChange)="e.Name = $event; checkParamChanged()"></mp-core-input>
                <p *ngIf="e.NameRequired" class="inputRequired">
                  {{ls.locs['validate'].NameRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-input [mpRequired]="true" [hasError]="e.VornameRequired ? true : false" mpId="rk-vorname-e-{{index}}" label="{{ls.locs['loc'].Vorname}}" [model]="e.Vorname" (modelChange)="e.Vorname = $event; checkParamChanged()"></mp-core-input>
                <p *ngIf="e.VornameRequired" class="inputRequired">
                  {{ls.locs['validate'].VornameRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap flex-align-items-start margin-bottom-16">
                <mp-core-select [mpRequired]="true" [hasError]="e.NationalitaetRequired ? true : false" mpId="rk-nationalitaet-e-{{index}}" label="{{ls.locs['locReisekonfigurator'].Nationalitaet}}" [model]="e.Nationalitaet" (modelChange)="e.Nationalitaet = $event; checkParamChanged()" [elements]="countries" textProperty="Land" valueProperty="Kuerzel"></mp-core-select>
                <p *ngIf="e.NationalitaetRequired" class="inputRequired">
                  {{ls.locs['validate'].NationalitaetRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap flex-align-items-start margin-bottom-16">
                <mp-core-select [mpRequired]="true" [hasError]="e.WohnsitzRequired ? true : false" mpId="rk-wohnsitz-e-{{index}}" label="{{ls.locs['locReisekonfigurator'].Wohnsitz}}" [model]="e.Wohnsitz" (modelChange)="e.Wohnsitz = $event; checkParamChanged()" [elements]="countries" textProperty="Land" valueProperty="Kuerzel"></mp-core-select>
                <p *ngIf="e.WohnsitzRequired" class="inputRequired">
                  {{ls.locs['validate'].WohnsitzRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-sidebar-input [mpRequired]="true" [hasError]="e.GeburtsdatumRequired ? true : false" class="padding-left-right-0" text="{{e.Geburtsdatum ? e.Geburtsdatum.format('L') : ''}}" label="{{ls.locs['loc'].Geburtsdatum}}" [sidebarParams]="e" (sidebarParamsChange)="checkParamsChangedSidebar()" sidebarComponent="sidebarCalendarBirthday"></mp-core-sidebar-input>
                <p *ngIf="e.GeburtsdatumRequired" class="inputRequired">{{ls.locs['validate'].GeburtsdatumRequired}}</p>
              </div>
            </div>
            <div class="flex-grid flex-direction-row flex-wrap rk-guest-data" *ngFor="let k of checkingParameters['Kinder']; let index = index">
              <div class="flex-col-12">
                <span>{{ls.locs['locReisekonfigurator'].NtesKind.replace('{0}', index + 1)}}</span>
                <mp-core-popover class="margin-left-8" content="{{ls.locs['locReisekonfigurator'].AngabenAusReisepass}}" alignment="right" popoverTitle="{{ls.locs['locReisekonfigurator'].DatenReisende}}">
      <i class="svg-icon __clr-info __size-18 __top-2 __info"></i>
    </mp-core-popover>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap flex-align-items-start margin-bottom-16">
                <mp-core-select [mpRequired]="true" [hasError]="k.AnredeRequired ? true : false" mpId="rk-anrede-e-{{index}}" label="{{ls.locs['loc'].Anrede}}" [model]="k.AnredeId" (modelChange)="k.AnredeId = $event; checkParamChanged()" [elements]="salutations" textProperty="Anrede" valueProperty="ID"></mp-core-select>
                <p *ngIf="k.AnredeRequired" class="inputRequired">
                  {{ls.locs['validate'].AnredeRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-input [mpRequired]="true" [hasError]="k.NameRequired ? true : false" mpId="rk-name-e-{{index}}" label="{{ls.locs['loc'].Name}}" [model]="k.Name" (modelChange)="k.Name = $event; checkParamChanged()"></mp-core-input>
                <p *ngIf="k.NameRequired" class="inputRequired">
                  {{ls.locs['validate'].NameRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-input [mpRequired]="true" [hasError]="k.VornameRequired ? true : false" mpId="rk-vorname-e-{{index}}" label="{{ls.locs['loc'].Vorname}}" [model]="k.Vorname" (modelChange)="k.Vorname = $event; checkParamChanged()"></mp-core-input>
                <p *ngIf="k.VornameRequired" class="inputRequired">
                  {{ls.locs['validate'].VornameRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap flex-align-items-start margin-bottom-16">
                <mp-core-select [mpRequired]="true" [hasError]="k.NationalitaetRequired ? true : false" mpId="rk-nationalitaet-e-{{index}}" label="{{ls.locs['locReisekonfigurator'].Nationalitaet}}" [model]="k.Nationalitaet" (modelChange)="k.Nationalitaet = $event; checkParamChanged()" [elements]="countries" textProperty="Land" valueProperty="Kuerzel"></mp-core-select>
                <p *ngIf="k.NationalitaetRequired" class="inputRequired">
                  {{ls.locs['validate'].NationalitaetRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap flex-align-items-start margin-bottom-16">
                <mp-core-select [mpRequired]="true" [hasError]="k.WohnsitzRequired ? true : false" mpId="rk-wohnsitz-e-{{index}}" label="{{ls.locs['locReisekonfigurator'].Wohnsitz}}" [model]="k.Wohnsitz" (modelChange)="k.Wohnsitz = $event; checkParamChanged()" [elements]="countries" textProperty="Land" valueProperty="Kuerzel"></mp-core-select>
                <p *ngIf="k.WohnsitzRequired" class="inputRequired">
                  {{ls.locs['validate'].WohnsitzRequired}}
                </p>
              </div>
              <div class="flex-col-4 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-sidebar-input [mpRequired]="true" [hasError]="k.GeburtsdatumRequired ? true : false" class="padding-left-right-0" text="{{k.Geburtsdatum ? k.Geburtsdatum.format('L') : ''}}" label="{{ls.locs['loc'].Geburtsdatum}}" [sidebarParams]="k" (sidebarParamsChange)="checkParamsChangedSidebar()" sidebarComponent="sidebarCalendarBirthday"></mp-core-sidebar-input>
                <p *ngIf="k.GeburtsdatumRequired" class="inputRequired">{{ls.locs['validate'].GeburtsdatumRequired}}</p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HideVersicherungen && offerLoaded" class="margin-top-32">
          <div class="rk-offers-headline">
            <h2>{{ls.locs['locReisekonfigurator'].MoechtenSieSichAbsichern}}</h2>
          </div>
          <div id="rk-insurance" class="bg-color-white flex-grid margin-0 flex-direction-row flex-wrap flex-justify-cont-between flex-align-items-end padding-16">
            <span class="flex-col-12 padding-0">{{ls.locs['locReisekonfigurator'].Reiseschutz}}</span>
            <div class="flex-col-9 padding-0 flex-col-8-xs">
              <mp-core-radio-button name="travelProtectionRadio"
                                    [model]="checkingParameters['Reiseschutz']"
                                    (modelChange)="checkingParameters['Reiseschutz'] = $event; setTravelProtection(travelProtectionOptions['KeineVersicherung'])"
                                    label="{{ls.locs['locReisekonfigurator'].KeineVersicherung}}"
                                    mpId="rbOhne"
                                    [value]="travelProtectionOptions['KeineVersicherung']"
                                    class="display-inline-block">

              </mp-core-radio-button>
            </div>
            <div class="flex-col-3 padding-0 flex-col-4-xs flex-justify-cont-end text-color-info">
              <span>{{offer['Preise']['KeineVersicherung'] | integer}}</span>
              <span class="margin-left-8">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>
            <div class="flex-col-9 padding-0 flex-col-8-xs">
              <mp-core-radio-button name="travelProtectionRadio"
                                    [model]="checkingParameters['Reiseschutz']"
                                    (modelChange)="checkingParameters['Reiseschutz'] = $event; setTravelProtection(travelProtectionOptions['Reiseruecktritt'])"
                                    label="{{ls.locs['locReisekonfigurator'].Reiseruecktritt}}"
                                    mpId="rbReiseruecktritt"
                                    [value]="travelProtectionOptions['Reiseruecktritt']"
                                    class="display-inline-block">
                <mp-core-popover class="margin-left-8" content="{{(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length === 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer === null) || mpSettings.settings['DisplaySettings'].HideHotline ? ls.locs['locReisekonfigurator'].ReiseRueckVersTarifDetailsHideHotline : ls.locs['locReisekonfigurator'].ReiseRueckVersTarifDetails | format:[mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer,mpSettings.settings['NetcentiveCoreSettings'].ReisenSupportEmailAdresse]}}" popoverTitle="{{ls.locs['locReisekonfigurator'].ReiseRueckVersTarifTitel}}">
          <i class="svg-icon __clr-info __size-18 __top-4 __help-outline"></i>
        </mp-core-popover>
              </mp-core-radio-button>
            </div>
            <div class="flex-col-3 padding-0 flex-col-4-xs flex-justify-cont-end text-color-info">
              <span>{{offer['Preise']['Reiseruecktritt'] | integer}}</span>
              <span class="margin-left-8">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>
            <div class="flex-col-12 flex-col-12-xs flex-justify-cont-start text-link padding-left-6 margin-left-12 margin-bottom-12">
              <a href="https://secure-pro.hmrv.de/rda-web/servlet/DokumentAdapter?dokumentTyp=PIB&uuidGroup=1db2b9f6-87e5-4ae0-ad6e-ec663d6d3013/168711" target="_blank" class="margin-left-8">
                {{ls.locs['locReisekonfigurator'].WeitereProduktinformationen}}
              </a>
            </div>
            <div class="flex-col-9 padding-0 flex-col-8-xs">
              <mp-core-radio-button name="travelProtectionRadio"
                                    [model]="checkingParameters['Reiseschutz']"
                                    (modelChange)="checkingParameters['Reiseschutz'] = $event; setTravelProtection(travelProtectionOptions['RundumSorglosSchutz'])"
                                    label="{{ls.locs['locReisekonfigurator'].RundumSorglosSchutz}}"
                                    mpId="rbRundumSorglos"
                                    [value]="travelProtectionOptions['RundumSorglosSchutz']"
                                    class="display-inline-block">
                <mp-core-popover class="margin-left-8" content="{{(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length === 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer === null) || mpSettings.settings['DisplaySettings'].HideHotline ? ls.locs['locReisekonfigurator'].RundmSorglosSchutzTarifDetailsHideHotline : ls.locs['locReisekonfigurator'].RundmSorglosSchutzTarifDetails | format:[mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer,mpSettings.settings['NetcentiveCoreSettings'].ReisenSupportEmailAdresse]}}" popoverTitle="{{ls.locs['locReisekonfigurator'].RundmSorglosSchutzTarifTitel}}">
      <i class="svg-icon __clr-info __size-18 __top-4 __help-outline"></i>
    </mp-core-popover>
              </mp-core-radio-button>
            </div>
            <div class="flex-col-3 padding-0 flex-col-4-xs flex-justify-cont-end text-color-info">
              <span>{{offer['Preise']['RundumSorglosSchutz'] | integer}}</span>
              <span class="margin-left-8">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>
            <div class="flex-col-12 flex-col-12-xs flex-justify-cont-start text-link padding-left-6 margin-left-12 margin-bottom-12">
              <a href="https://secure-pro.hmrv.de/rda-web/servlet/DokumentAdapter?dokumentTyp=PIB&uuidGroup=9a232645-b107-442e-b45e-7ee944fe2fee/168711" target="_blank" class="margin-left-8">{{ls.locs['locReisekonfigurator'].WeitereProduktinformationen}}</a>
            </div>
          </div>
        </div>

        <div *ngIf="offerLoaded" class="rk-accordion-block margin-top-32">
          <div class="rk-offers-headline" (click)="showAnnotations = !showAnnotations">
            <h2 class="padding-right-32">
              {{ls.locs['locReisekonfigurator'].WuenscheUndAnmerkungen}}
              <i class="svg-icon __clr-specific-1 __size-24 __top-0" [ngClass]="showAnnotations === true ? '__remove' : '__add'"></i>
            </h2>
          </div>
          <div [hidden]="!showAnnotations" class="bg-color-white padding-16">
            <mp-core-textarea class="multilinelabel" label="{{ls.locs['locReisekonfigurator'].WuenscheUndAnmerkungenText}}" mpId="anmerkungen" [(model)]="checkingParametersWishes"></mp-core-textarea>
          </div>
        </div>

        <div *ngIf="offerLoaded" id="rk-additional-pay" class="margin-top-32">
          <div class="rk-offers-headline">
            <h2 [hidden]="!checkingParameters['Kinder'].length">{{ls.locs['locReisekonfigurator'].IhreKostenuebersicht}} {{checkingParameters['Erwachsene'].length}} {{checkingParameters['Erwachsene'].length > 1 ? ls.locs['locReisekonfigurator'].Erwachsene : ls.locs['locReisekonfigurator'].Erwachsener}}, {{checkingParameters['Kinder'].length}} {{checkingParameters['Kinder'].length > 1 ? ls.locs['locReisekonfigurator'].Kinder : ls.locs['locReisekonfigurator'].Kind}}</h2>
            <h2 [hidden]="checkingParameters['Kinder'].length">{{ls.locs['locReisekonfigurator'].IhreKostenuebersicht}} {{checkingParameters['Erwachsene'].length}} {{checkingParameters['Erwachsene'].length > 1 ? ls.locs['locReisekonfigurator'].Erwachsene : ls.locs['locReisekonfigurator'].Erwachsener}}</h2>
          </div>
          <div *ngIf="totalPoints > pointWorth" id="rk-additional-pay-error" class="flex-grid margin-0 padding-16 flex-direction-row flex-wrap flex-align-items-start bg-color-warning">
            <div class="flex-col-1 flex-col-2-xs padding-left-0">
              <i class="svg-icon __clr-warning __size-48 __top-0 __error-outline default-cursor-hover"></i>
            </div>
            <div class="flex-col-11 flex-col-10-xs padding-right-0 flex-direction-column">
              <h3 class="text-transform-uppercase font-size-m">{{ ls.locs['locReisekonfigurator'].BestellungNichtMoeglich }}</h3>
              <span [innerHTML]="orderNotPossibleText"></span>
            </div>
          </div>
          <div class="margin-0 padding-16 flex-grid flex-direction-row flex-wrap flex-align-items-end bg-color-white">
            <div [hidden]="!offer['Rueckfluege'] || offer['Rueckfluege'].length === 0" class="flex-col-12 padding-0">{{ ls.locs['locReisekonfigurator'].PreisHinUndRueckFlug }}</div>
            <div [hidden]="offer['Rueckfluege'] && offer['Rueckfluege'].length > 0" class="flex-col-12 padding-0">{{ ls.locs['locReisekonfigurator'].PreisHinFlug }}</div>
            <div class="flex-col-8 flex-col-8-xs padding-left-0 font-size-s">
              {{offer['Hinfluege'][0].FlughafenDisplay}}{{offer['Rueckfluege'] && offer['Rueckfluege'].length ? ', '+offer['Rueckfluege'][0].FlughafenDisplay : ''}}
            </div>
            <div class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
              <span>{{offer['Punkte'] | integer}}</span>
              <span class="currency margin-left-4">{{ ls.locs['loc'].WaehrungPunkte }}</span>
            </div>
            <div [hidden]="travelProtectionPoints == 0" class="flex-col-12 padding-0 margin-top-16">{{ ls.locs['locReisekonfigurator'].Reiseschutz }}</div>
            <div [hidden]="travelProtectionPoints == 0" class="flex-col-8 flex-col-8-xs padding-left-0 font-size-s display-inline-block">
              {{protectionKey == travelProtectionOptions['Reiseruecktritt'] ? ls.locs['locReisekonfigurator'].Reiseruecktritt : ls.locs['locReisekonfigurator'].RundumSorglosSchutz}}
              <span class="margin-left-12">|</span>
              <i angular-ripple class="svg-icon __clr-default __size-18 __top-4 __clear float-none-important margin-left-12" (click)="setTravelProtection(travelProtectionOptions['KeineVersicherung'])"></i>
            </div>
            <div [hidden]="travelProtectionPoints === 0" class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
              <span>{{travelProtectionPoints | integer}}</span>
              <span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>
          </div>
          <div id="rk-overview-sum" class="padding-16 text-color-white bg-color-info font-size-l">
            <div class="flex-grid flex-direction-row flex-wrap flex-align-items-end">
              <div class="flex-col-8 flex-col-8-xs">{{ ls.locs['locReisekonfigurator'].Gesamtbetrag }}</div>
              <div class="flex-col-4 flex-col-4-xs flex-justify-cont-end">
                <span>{{totalPoints | integer}}</span>
                <span class="currency margin-left-4">{{ ls.locs['loc'].WaehrungPunkte }}</span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="offerLoaded" id="rk-accept" class="margin-top-0 padding-top-bottom-24 padding-left-16 padding-right-16 font-size-s bg-color-white">
          <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowVersicherungHinweis && mpSettings.settings['ReisekonfiguratorSettings'].HideVersicherungen" class="margin-bottom-32 margin-top-0">
            <span [innerHTML]="ls.locs['locReisekonfigurator'].EigeneVersicherungHinweis" class="font-size-s"></span>
          </div>
          <div [innerHTML]="ls.locs['locReisekonfigurator'].HinweisEinreisebestimmungen | format:[offer['Hinfluege'][0].Ankunftflughafen.Land,offer['Hinfluege'][0].Ankunftflughafen.Lkz,hinweis.Abreiseland,hinweis.Nationalitaet,lang,getPeopleHint(hinweis)]" *ngFor="let hinweis of groupedNationalities" class="margin-bottom-32 margin-top-0" [hidden]="!hinweis.Abreiseland || hinweis.Nationalitaet ">
          </div>
          <div [innerHTML]="ls.locs['locReisekonfigurator'].HinweisFormblattEinzel" class="margin-bottom-32 margin-top-0">
          </div>
          <div class="display-flex margin-top-bottom-12">
            <mp-core-checkbox [model]="checkingParameters['BedingungenAkzeptiert']" (modelChange)="checkingParameters['BedingungenAkzeptiert'] = $event; checkParamChanged()" class="form-control" mpId="chkBedingungen" label=""></mp-core-checkbox>
            <span [innerHTML]="ls.locs['locReisekonfigurator'].TeilnahmebedingungenBestaetigen | format:[role]" class="font-size-s">
            </span>
          </div>
          <div [innerHTML]="ls.locs['locReisekonfigurator'].HinweisVertragsruecktritt" class="margin-top-bottom-12 margin-left-20 padding-left-6">
          </div>
          <div [innerHTML]="ls.locs['locReisekonfigurator'].HinweisDatenpruefung" class="margin-top-12 margin-left-20 padding-left-6">
          </div>
          <p *ngIf="conditionsInvalid" class="inputRequired">{{ ls.locs['validate'].TbBestaetigtRequired }}</p>
        </div>

        <div *ngIf="offerLoaded" class="margin-top-16">
          <button type="button" class="mp-button _prim _wide" (click)="book()">
            {{ls.locs['locReisekonfigurator'].JetztKostenpflichtigBuchen}}
          </button>
        </div>
      </div>
      <div *ngIf="offerLoaded" class="margin-top-16 margin-bottom-16">
        <div id="rk-overview-flug" class="bg-color-white">
          <div id="rk-overview-img" style="background-image: url(/themes/default/media/images/flugziele/'{{offer['Hinfluege'][0].Ankunftflughafen.Bild}}.jpg')"></div>
          <div class="flex-grid flex-direction-column padding-top-16 padding-bottom-24 padding-left-32 padding-right-32">
            <div class="flex-col-12 padding-top-12">
              <span class="margin-bottom-0 font-size-m">{{ ls.locs['locReisekonfigurator'].Hinflug }}: {{offer['Hinfluege'][0].Abflughafen.KurzText}} {{ls.locs['loc'].nach}} {{offer['Hinfluege'][0].Ankunftflughafen.KurzText}}</span>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ ls.locs['locReisekonfigurator'].Reisetermin }}</div>
              <div class="font-size-s margin-top-4">
                <span>{{offer['Hinfluege'][0].AbflugdatumDetailsDisplay}}, {{offer['Hinfluege'][0].FlugzeitenDisplay}}</span>
              </div>
              <div class="font-size-s margin-top-4">
                <span>{{offer['Hinfluege'][0].FlugdauerDisplay}}, {{offer['Hinfluege'][0].AnzahlStopsDisplay}}</span>
              </div>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ ls.locs['locReisekonfigurator'].Reiseteilnehmer }}</div>
              <div class="font-size-s margin-top-4">
                {{checkingParameters['Erwachsene'].length}} {{checkingParameters['Erwachsene'].length > 1 ? ls.locs['locReisekonfigurator'].Erwachsene : ls.locs['locReisekonfigurator'].Erwachsener}}
                <span [hidden]="checkingParameters['Kinder'].length === 0">, {{checkingParameters['Kinder'].length}} {{checkingParameters['Kinder'].length > 1 ? ls.locs['locReisekonfigurator'].Kinder : ls.locs['locReisekonfigurator'].Kind}}</span>
              </div>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ls.locs['locReisekonfigurator'].Airline}}</div>
              <div class="font-size-s display-flex flex-direction-column margin-top-4">
                <span>{{offer['Hinfluege'][0].Airline.Name}}</span>
                <span class="font-size-xs" [hidden]="!offer['Hinfluege'][0].Airline.AdditionalInfo">{{offer['Hinfluege'][0].Airline.AdditionalInfo}}</span>
              </div>
              <div class="font-size-s display-flex flex-direction-column margin-top-4">
                <span>{{offer['Hinfluege'][0].Klasse}}</span>
              </div>
            </div>
            <div [hidden]="!offer['Rueckfluege'] || offer['Rueckfluege'].length === 0" class="flex-col-12 padding-top-40">
              <span class="margin-bottom-0 font-size-m">{{ls.locs['locReisekonfigurator'].Rueckflug}}: {{offer['Rueckfluege'][0].Abflughafen.KurzText}} {{ ls.locs['loc'].nach }} {{offer['Rueckfluege'][0].Ankunftflughafen.KurzText}}</span>
            </div>
            <div [hidden]="!offer['Rueckfluege'] || offer['Rueckfluege'].length === 0" class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ls.locs['locReisekonfigurator'].Reisetermin}}</div>
              <div class="font-size-s margin-top-4">
                <span>{{offer['Rueckfluege'][0].AbflugdatumDetailsDisplay}}, {{offer['Rueckfluege'][0].FlugzeitenDisplay}}</span>
              </div>
              <div class="font-size-s margin-top-4">
                <span>{{offer['Rueckfluege'][0].FlugdauerDisplay}}, {{offer['Rueckfluege'][0].AnzahlStopsDisplay}}</span>
              </div>
            </div>
            <div [hidden]="!offer['Rueckfluege'] || offer['Rueckfluege'].length === 0" class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ls.locs['locReisekonfigurator'].Reiseteilnehmer}}</div>
              <div class="font-size-s margin-top-4">
                {{checkingParameters['Erwachsene'].length}} {{checkingParameters['Erwachsene'].length > 1 ? ls.locs['locReisekonfigurator'].Erwachsene : ls.locs['locReisekonfigurator'].Erwachsener}}
                <span [hidden]="checkingParameters['Kinder'].length === 0">, {{checkingParameters['Kinder'].length}} {{checkingParameters['Kinder'].length > 1 ? ls.locs['locReisekonfigurator'].Kinder : ls.locs['locReisekonfigurator'].Kind}}</span>
              </div>
            </div>
            <div [hidden]="!offer['Rueckfluege'] || offer['Rueckfluege'].length === 0" class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ls.locs['locReisekonfigurator'].Airline}}</div>
              <div class="font-size-s display-flex flex-direction-column margin-top-4">
                <span>{{offer['Rueckfluege'][0].Airline.Name}}</span>
                <span class="font-size-xs" [hidden]="!offer['Rueckfluege'][0].Airline.AdditionalInfo">{{offer['Rueckfluege'][0].Airline.AdditionalInfo}}</span>
              </div>
              <div class="font-size-s display-flex flex-direction-column margin-top-4">
                <span>{{offer['Rueckfluege'][0].Klasse}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mp-rk-tc-support class="hide-on-medium"></mp-rk-tc-support>
  </div>
  <div id="rk-bottom-buttons" class="show-on-medium position-fixed bottom-0 width-full" stickyBottom>
    <a *ngIf="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length > 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer !== null) && !mpSettings.settings['DisplaySettings'].HideHotline" class="mp-button _prim width-100-pcnt display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column padding-top-bottom-8" href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">
      <i class="svg-icon __clr-navbar __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
      <span class="display-block">{{ls.locs['locReisekonfigurator'].Reisebuero}}</span>
    </a>
    <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-between">
      <a class="mp-button" (click)="goBack($event)">{{ ls.locs['loc'].Zurueck }}</a>
      <a class="mp-button" href="#/{{role}}/Reisekonfigurator">{{ ls.locs['loc'].Abbrechen }}</a>
    </div>
  </div>
</section>
