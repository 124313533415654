<div class="card">
  <div class="rich-media text-align-center">
    <img class="z-index-1 width-full" src="{{imgSrc}}" />
  </div>
  <div class="primary-title">
    <div class="title last">
      <h1 [innerHTML]="title"></h1>
    </div>
  </div>
  <div class="supporting-text" [innerHTML]="text"></div>
  <div class="bg-color-light-grey padding-top-32 padding-bottom-32">
    <div class="padding-top-bottom-0 padding-left-right-24">
      <i class="svg-icon __clr-default __size-18 __time margin-right-16" [hidden]="progressPercent < 100 || !showProgress"></i>
      <div class="progressbar-container top-8 position-relative" [hidden]="(progressPercent || 0) >= 100 || !showProgress">
        <div class="progressbar position-relative">
          <span style="width: {{progressPercent}}%" class="overflow-hidden position-relative display-block height-full"></span>
        </div>
        <div class="progressbar-text font-weight-light font-size-xxs">{{progressBarTextActive}}</div>
      </div>
      <div class="progressbar-text font-weight-light font-size-xxs" [hidden]="progressPercent < 100 || !showProgress">{{progressBarTextDone}}</div>
    </div>
  </div>
</div>
