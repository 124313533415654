import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the travel activities
 * table.
 */
@Component({
  selector: 'mp-rk-travel-activities-overview',
  templateUrl: './travel-activities-overview.component.html'
})
export class TravelActivitiesOverviewComponent implements OnInit {

  public loader: MpLoader | null = null;

  private _travelActivitiesLoader: MpLoader = {
    name: 'travelActivitiesLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService
  ) { }

  /**
   * Loads the data for the table.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._travelActivitiesLoader);
    this.loader = this._mpLoaderService.getLoader('travelActivitiesLoader');

    if (typeof this.loader.load !== 'undefined') {
      this.loader.isReady = true;
      this.loader.load();
    }
  }

}
