import { Injectable, Inject } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import * as metaInterfaces from './interfaces/meta-interfaces';

/**
 * This class provides functions to
 * set meta tags, link tags, and
 * so on in the head.
 */
@Injectable({
  providedIn: 'root'
})
export class MpMetaService {

  constructor(private _metaService: Meta, @Inject(DOCUMENT) private _dom: any) { }

  /**
   * Sets basic meta tags, needed in
   * every module / shop.
   */
  setBasicTags(): void {
    if (this._metaService.getTag('charset') === null) {
      this._metaService.addTag({ charset: 'utf-8' });
    } else {
      this._metaService.updateTag({ charset: 'utf-8' }, 'charset');
    }

    if (this._metaService.getTag('http-equiv') === null) {
      this._metaService.addTag({ 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' });
    } else {
      this._metaService.updateTag({ 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' }, 'http-equiv');
    }

    if (this._metaService.getTag('name="viewport"') === null) {
      this._metaService.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    } else {
      this._metaService.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' }, 'name="viewport"');
    }
  }

  /**
   * Sets the description meta tag.
   */
  setDescriptionTag(description: string): void {
    this._metaService.addTag({ name: 'description', content: description });
  }

  /**
   * Sets the theme color meta tag.
   */
  setThemeColorTag(color?: string): void {
    if (this._metaService.getTag('name="theme-color"') === null) {
      this._metaService.addTag({ name: 'theme-color', content: color || '#ffffff' });
    } else {
      this._metaService.updateTag({ name: 'theme-color', content: color || '#ffffff' }, 'name="theme-color"');
    }
  }

  /**
   * Sets the favicon link tag.
   */
  setFavicon(href?: string): void {
    if (this._dom.head.querySelector('link[rel="icon"]') !== null) {
      this._dom.head.querySelector('link[rel="icon"]').remove();
    }

    this._addLinkTag({
      rel: 'icon',
      href: href || '/themes/default/media/icons/favicon.ico?v=1'
    });
  }

  /**
   * Sets meta and link tags, given
   * by settings.
   */
  setTagsBySettings(settings: metaInterfaces.MetaSetting[]): void {
    settings.forEach((tag: metaInterfaces.MetaSetting) => {
      if (tag.IsMeta) {
        this._addMetaTag({
          property: tag.Property || '',
          name: tag.Name || '',
          content: tag.Content || ''
        });
      }

      if (tag.IsLink) {
        this._addLinkTag({
          rel: tag.Rel || '',
          sizes: tag.Sizes || '',
          href: tag.Href || ''
        });
      }
    });
  }

  /**
   * Sets a single link tag.
   */
  setLinkTag(tag: metaInterfaces.LinkTag): void {
    this._addLinkTag(tag);
  }

  /**
   * Sets a script tag, that includes
   * the given source.
   */
  setScriptTag(src: string, appendToBody?: boolean, type?: string, async?: boolean, defer?: boolean, callback?: any): void {
    const scriptTag = this._dom.createElement('script');
    scriptTag.setAttribute('src', src);

    if (typeof type !== 'undefined' && type !== '') {
      scriptTag.setAttribute('type', type);
    }

    if (typeof async !== 'undefined') {
      scriptTag.setAttribute('async', async);
    }

    if (typeof defer !== 'undefined') {
      scriptTag.setAttribute('defer', defer);
    }

    if (typeof callback !== 'undefined') {
      scriptTag.onload = () => {
        callback();
      };
    }

    if (typeof appendToBody !== 'undefined' && appendToBody === true) {
      this._dom.body.appendChild(scriptTag);
    } else {
      this._dom.head.appendChild(scriptTag);
    }
  }

  /**
   * Sets a link tag in the head.
   */
  private _addLinkTag(tag: metaInterfaces.LinkTag): void {
    if (tag.rel === '' || tag.href === '') {
      return;
    }

    const link = this._dom.createElement('link');
    link.setAttribute('rel', tag.rel);

    if (typeof tag.sizes !== 'undefined' && tag.sizes !== '') {
      link.setAttribute('sizes', tag.sizes);
    }

    if (typeof tag.type !== 'undefined' && tag.type !== '') {
      link.setAttribute('type', tag.type);
    }

    if (typeof tag.color !== 'undefined' && tag.color !== '') {
      link.setAttribute('color', tag.color);
    }

    this._dom.head.appendChild(link);
    link.setAttribute('href', tag.href);
  }

  /**
   * Sets a meta tag in the head.
   */
  private _addMetaTag(tag: metaInterfaces.MetaTag): void {
    this._metaService.addTag({ property: tag.property, name: tag.name, content: tag.content });
  }
}
