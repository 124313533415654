import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { HotelCheckAdditionalPaymentComponent } from './modules/participant/pages/hotel-check-additional-payment/hotel-check-additional-payment.component';
import { HotelConfirmationAdditionalPaymentComponent } from './modules/participant/pages/hotel-confirmation-additional-payment/hotel-confirmation-additional-payment.component';
import { FlightConfirmationAdditionalPaymentComponent } from './modules/participant/pages/flight-confirmation-additional-payment/flight-confirmation-additional-payment.component';
import { FlightCheckAdditionalPaymentComponent } from './modules/participant/pages/flight-check-additional-payment/flight-check-additional-payment.component';
import { RentalConfirmationAdditionalPaymentComponent } from './modules/participant/pages/rental-confirmation-additional-payment/rental-confirmation-additional-payment.component';
import { RentalCheckAdditionalPaymentComponent } from './modules/participant/pages/rental-check-additional-payment/rental-check-additional-payment.component';

import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'FlugBestaetigung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: FlightConfirmationAdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'FlugPruefung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: FlightCheckAdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'HotelBestaetigung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: HotelConfirmationAdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'HotelPruefung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: HotelCheckAdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'MietwagenBestaetigung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RentalConfirmationAdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'MietwagenPruefung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RentalCheckAdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class RkzzRoutingModule { }
