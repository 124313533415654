import { Pipe, PipeTransform, LOCALE_ID, Inject  } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'integer'
})
export class IntegerPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  transform(inputNumber: any): unknown {
    if (typeof inputNumber === 'undefined') {
      return 0;
    }

    return formatNumber(inputNumber, this.locale, '1.0-0');
  }

}
