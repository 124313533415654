import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { ApiService } from '@core/services/api.service';
import moment from 'moment';

/**
 * This class provides the data and functions
 * for the complaint management page.
 */
@Component({
  selector: 'mp-leas-complaint-management',
  templateUrl: './complaint-management.component.html'
})
export class ComplaintManagementComponent implements OnInit, OnDestroy {

  public complaint: { [key: string]: any } = {};
  public errors: { [key: string]: any } = {};
  public contractTypesListe: Array<any> = [];
  public contractType: { [key: string]: any } = {};
  public activationDateParams: { [key: string]: any } = {
    Headline: '',
    maxYearDif: 10,
    minYearDif: -1,
    defaultYearDif: 0
  };

  private _getLocsSubscription: Subscription | undefined;
  private _getComplaintPrefillSubscription: Subscription | undefined;
  private _sendComplaintSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService
  ) { }

  /**
   * Seets the breadcrumb, gets the locs and
   * triggers the reset.
   */
  ngOnInit(): void {
    this._mpBreadcrumbService.setCurrentSiteWithLoc('Beschwerdemanagement', 'locLeas', 'BeschwerdemanagementTitle');

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._reset();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._reset();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getComplaintPrefillSubscription !== 'undefined') {
      this._getComplaintPrefillSubscription.unsubscribe();
    }

    if (typeof this._sendComplaintSubscription !== 'undefined') {
      this._sendComplaintSubscription.unsubscribe();
    }
  }

  /**
   * Restes the complaint management form.
   */
  private _reset(): void {
    this.contractTypesListe = [
      {
        Bezeichnung: this.ls.locs['locLeas'].Systemvertrag
      },
      {
        Bezeichnung: this.ls.locs['locLeas'].ManuellerVertrag
      }
    ];

    this.activationDateParams = {
      Headline: this.ls.locs['locLeas'].Aktivierungsdatum,
      maxYearDif: 10,
      minYearDif: -1,
      defaultYearDif: 0
    };

    this._getComplaintPrefillSubscription = this._apiService.getRequest('/api/Beschwerdemanagement/GetBeschwerdemanagementPrefillData').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records[0]) {
        this.complaint = data.Records[0]
      } else {
        this._mpMessaging.openDangerPanel(this.ls.locs['locLeas'].BeschwerdemanagementErrorLoadingData);
      }
    },
    (error: any) => {
      this._mpMessaging.openDangerPanel(this.ls.locs['locLeas'].BeschwerdemanagementErrorLoadingData);
    });
  }

  /**
   * Triggers the validation and
   * the sending of the form.
   */
  send(evt: MouseEvent): void {
    evt.preventDefault();
    this._validate(true);
  }

  /**
   * Checks whether or not the given
   * field is empty or undefined.
   */
  private _isEmpty(fieldName: string, checkUndefined: boolean): boolean {
    const fieldNameArr = fieldName.split('.');

    if (fieldNameArr.length == 1) {
      // @ts-ignore
      if (checkUndefined && !this[fieldName])
        return true;
      // @ts-ignore
      return this[fieldName] === '';
    }

    if (fieldNameArr.length == 2) {
      // @ts-ignore
      if (checkUndefined && !this[fieldNameArr[0]][fieldNameArr[1]])
        return true;
      // @ts-ignore
      return this[fieldNameArr[0]][fieldNameArr[1]] === '';
    }

    return false;
  }

  /**
   * Validates and sends the form.
   */
  private _validate(checkUndefined: boolean): void {
    this.errors = {};

    if (typeof checkUndefined === 'undefined')
      checkUndefined = false;

    if (this._isEmpty('complaint.Name', checkUndefined)) {
      this.errors['data.Name'] = [this.ls.locs['locLeas'].NameRequired];
    }

    if (this._isEmpty('complaint.Firma', checkUndefined)) {
      this.errors['data.Firma'] = [this.ls.locs['locLeas'].FirmaRequired];
    }

    if (this._isEmpty('complaint.Benutzer', checkUndefined)) {
      this.errors['data.Benutzer'] = [this.ls.locs['locLeas'].BenutzerRequired];
    }

    if (this.contractType && this.contractType['Bezeichnung']) {
      this.complaint['Vertragsart'] = this.contractType['Bezeichnung'];
    }
    else {
      this.errors['data.Vertragsart'] = [this.ls.locs['locLeas'].VertragsartRequired];
    }

    if (this._isEmpty('complaint.Vertragsart', checkUndefined)) {
      this.errors['data.Vertragsart'] = [this.ls.locs['locLeas'].VertragsartRequired];
    }

    if (this._isEmpty('complaint.Proposal', checkUndefined)) {
      this.errors['data.Proposal'] = [this.ls.locs['locLeas'].ProposalRequired];
    }

    if (this.activationDateParams['text']) {
      this.complaint['Aktivierungsdatum'] = moment(this.activationDateParams['text'], 'DD.MM.YYYY');
    }
    else {
      this.errors['data.Aktivierungsdatum'] = [this.ls.locs['locLeas'].AktivierungsdatumRequired];
    }

    if (this._isEmpty('complaint.Aktivierungsdatum', checkUndefined)) {
      this.errors['data.Aktivierungsdatum'] = [this.ls.locs['locLeas'].AktivierungsdatumRequired];
    }

    if (Object.keys(this.errors).length === 0) {
      this._sendComplaintSubscription = this._apiService.postRequest('/api/Beschwerdemanagement/SendBeschwerde', this.complaint).subscribe((data: any) => {
        this._mpMessaging.openPanelFromResultResponse(data);

        if (data.Result === 'OK') {
          this._reset();
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openDangerPanel(error.Message);
      });
    }
  }

}
