<div class="layout-container text-align-right" *ngIf="(ls.locsLoaded | async)" id="email-scheduled-management">
  <mp-core-infotext [sitename]="'emailScheduledVerwalten'"></mp-core-infotext>
  <div class="display-flex flex-direction-row flex-wrap flex-justify-cont-end flex-align-items-end">
    <a class="mp-button _prim" href="#/{{role}}/EMailModul" style="margin-bottom: -10px" [innerHTML]="ls.locs['loc'].NachrichtVerfassenButton">
    </a>
  </div>
  <h1 class="mobile-only">{{ ls.locs['loc'].NachrichtenscheduledVerwaltung }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].NachrichtenscheduledVerwaltung }}"
                 infotextShorten="themenbuehnen-verwalten"
                 fieldsAction="/api/EMailModul/GetEmailsForVerwaltungFields"
                 listAction="/api/EMailModul/GetEmailsForVerwaltung"
                 loaderName="getScheduledEmailsLoader"
                 [paging]="true"
                 defaultSorting="Anlagedatum DESC"
                 [execClickFunctions]="execClickFunctions"
                 [disableCache]="true">
  </mp-core-data-table>
</div>

<mp-core-modal [show]="confirmDelete"
          title="{{ ls.locs['loc'].EmailmodulConfirmDeleteTitle }}"
          [buttons]="[{text: ls.locs['loc'].Nein, action: toggleDeleteEmailFunc}, {text: ls.locs['loc'].Ja, action: deleteEmailFunc, class: '_prim'}]">

  <span>{{ ls.locs['loc'].EmailmodulConfirmDeleteText }}</span>
</mp-core-modal>
