import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { ParticipantCockpitService } from './../../../manager/pages/participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the inactive 
 * participants table.
 */
@Component({
  selector: 'mp-core-inactive-participants',
  templateUrl: './inactive-participants.component.html'
})
export class InactiveParticipantsComponent implements OnInit {
  
  public excelService: string = '/api/Teilnehmer/InaktiveTeilnehmer2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    }
  ];

  private _inactiveParticipantLoader: MpLoader = {
    name: 'inactiveParticipantLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _participantCockpitService: ParticipantCockpitService
  ) { }

  /**
   * Sets the menu highlighting and
   * registers the loader.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('benutzer');
    this._mpLoaderService.registerLoader(this._inactiveParticipantLoader);

    this._mpLoaderService.extendLoader('inactiveParticipantLoader', {
      isReady: true
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('inactiveParticipantLoader');
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['ID']);
  }

}
