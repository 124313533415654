<section *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-svg-loader [hidden]="loading.length === 0" svg="LadeanimationenIconsFlugzeug" animation="draw" class="svg-loader" svgAnimation="draw" [time]="1.9" loadingText="{{ls.locs['locReisekonfigurator'].LadeanimationBitteWarten}}"></mp-core-svg-loader>
  <div class="mietwagen-pruefung padding-top-64 padding-bottom-64 rk-two-columns-layout" id="rental-check">
    <div reisekonfigurator-background class="rk-background" style="background-image: url('/themes/default/media/images/rk-video.png')"></div>
    <div id="rk-search-wrapper" class="display-flex flex-justify-cont-center flex-align-items-center hide-on-medium">
      <div id="rk-search" class="padding-top-16 padding-left-16 padding-right-16 padding-bottom-0 width-100-pcnt display-flex flex-nowrap flex-justify-cont-between">
        <div id="rk-back-btn" class="display-inline-flex flex-justify-cont-end __top-0 padding-left-right-8 padding-top-bottom-8 padding-top-0 position-relative">
          <a class="mp-button display-flex flex-align-items-center display-flex" (click)="goBack($event)">
            <span class="display-inline-block position-relative top-2">{{ ls.locs['locReisekonfigurator'].ZurueckZuDenErgebnissen }}</span>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="offerLoaded" id="rk-main-section" class="rk-mietwagen-pruefung"
         mpRkzzRentalCheckAdditionalPayment
         #rentalCheckAdditionalPay="mpRkzzRentalCheckAdditionalPayment"
         [(checkingParameters)]="checkingParameters"
         [(offer)]="offer"
         [pointWorth]="pointWorth"
         [routeParams]="routeParams"
         [(totalPoints)]="totalPoints"
         [bookFunc]="bookFunc"
         [checkingParametersWishes]="checkingParametersWishes"
         [validate]="validateFunc"
         [(isValid)]="isValid">
      <div id="rk-offers" class="margin-top-16 margin-bottom-16">
        <div *ngIf="offerLoaded">
          <div class="rk-offers-headline">
            <h2>{{ls.locs['locReisekonfigurator'].Fahrerdaten}}</h2>
          </div>
          <div id="rk-check-guests" class="bg-color-white padding-16">
            <div class="flex-grid flex-direction-row flex-wrap rk-guest-data">
              <div class="flex-col-12">
                <span>{{ls.locs['locReisekonfigurator'].ErsterFahrer}}</span>
                <mp-core-popover class="margin-left-8" content="{{ls.locs['locReisekonfigurator'].AngabenAusReisepass}}" alignment="right" popoverTitle="{{ls.locs['locReisekonfigurator'].DatenReisende}}">
            <i class="svg-icon __clr-info __size-18 __top-2 __info"></i>
          </mp-core-popover>
              </div>
              <div class="flex-col-3 flex-col-12-m flex-wrap flex-align-items-start padding-left-0 margin-bottom-16">
                <mp-core-select mpId="rk-anrede-e" label="{{ls.locs['loc'].Anrede}}" [(model)]="checkingParameters['Erwachsene'][0].AnredeId" [model]="checkingParameters['Erwachsene'][0].AnredeId" (modelChange)="checkingParameters['Erwachsene'][0].AnredeId = $event; checkParamChanged()" [elements]="salutations" textProperty="Anrede" valueProperty="ID"></mp-core-select>
                <p *ngIf="checkingParameters['Erwachsene'][0].AnredeRequired" class="inputRequired">
                  {{ls.locs['validate'].AnredeRequired}}
                </p>
              </div>
              <div class="flex-col-3 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-input mpId="rk-name-e" label="{{ls.locs['loc'].Name}}" [model]="checkingParameters['Erwachsene'][0].Name" (modelChange)="checkingParameters['Erwachsene'][0].Name = $event; checkParamChanged()"></mp-core-input>
                <p *ngIf="checkingParameters['Erwachsene'][0].NameRequired" class="inputRequired">
                  {{ls.locs['validate'].NameRequired}}
                </p>
              </div>
              <div class="flex-col-3 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-input mpId="rk-vorname-e" label="{{ls.locs['loc'].Vorname}}" [model]="checkingParameters['Erwachsene'][0].Vorname" (modelChange)="checkingParameters['Erwachsene'][0].Vorname = $event; checkParamChanged()"></mp-core-input>
                <p *ngIf="checkingParameters['Erwachsene'][0].VornameRequired" class="inputRequired">
                  {{ls.locs['validate'].VornameRequired}}
                </p>
              </div>
              <div class="flex-col-3 flex-col-12-m flex-wrap margin-bottom-16">
                <mp-core-sidebar-input class="padding-left-right-0" text="{{checkingParameters['Erwachsene'][0].Geburtsdatum ? checkingParameters['Erwachsene'][0].Geburtsdatum.format('L') : ''}}" label="{{ls.locs['loc'].Geburtsdatum}}" [sidebarParams]="checkingParameters['Erwachsene'][0]" (sidebarParamsChange)="checkParamsChangedSidebar()" sidebarComponent="sidebarCalendarBirthday"></mp-core-sidebar-input>
                <p *ngIf="checkingParameters['Erwachsene'][0].GeburtsdatumRequired" class="inputRequired">{{ls.locs['validate'].GeburtsdatumRequired}}</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="offerLoaded" class="rk-accordion-block margin-top-32">
          <div class="rk-offers-headline" (click)="showAnnotations = !showAnnotations">
            <h2 class="padding-right-32">
              {{ls.locs['locReisekonfigurator'].WuenscheUndAnmerkungen}}
              <i class="svg-icon __clr-specific-1 __size-24 __top-0" [ngClass]="showAnnotations === true ? '__remove' : '__add'"></i>
            </h2>
          </div>
          <div [hidden]="!showAnnotations" class="bg-color-white padding-16">
            <mp-core-textarea class="multilinelabel" label="{{ls.locs['locReisekonfigurator'].WuenscheUndAnmerkungenText}}" mpId="anmerkungen" [(model)]="checkingParametersWishes"></mp-core-textarea>
          </div>
        </div>
        <div *ngIf="offerLoaded" id="rk-additional-pay" class="margin-top-32">
          <div class="rk-offers-headline">
            <h2>{{ls.locs['locReisekonfigurator'].IhreKostenuebersichtMietwagen}}</h2>
          </div>
          <div *ngIf="!offer['CanZuzahlen'] && offer['MaxEingeloestePunkte'] < rentalCheckAdditionalPay.totalPoints" id="rk-additional-pay-error" class="flex-grid margin-0 padding-16 flex-direction-row flex-wrap flex-align-items-start bg-color-warning">
            <div class="flex-col-1 flex-col-2-xs padding-left-0">
              <i class="svg-icon __clr-warning __size-48 __top-0 __error-outline default-cursor-hover"></i>
            </div>
            <div class="flex-col-11 flex-col-10-xs padding-right-0 flex-direction-column">
              <h3 class="text-transform-uppercase font-size-m">{{ls.locs['locReisekonfigurator'].BestellungNichtMoeglich}}</h3>
              <span [innerHTML]="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length === 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer === null) || mpSettings.settings['DisplaySettings'].HideHotline ? ls.locs['locReisekonfigurator'].BestellungNichtMoeglichTextHideHotline : ls.locs['locReisekonfigurator'].BestellungNichtMoeglichText | format:[mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer]"></span>
            </div>
          </div>

          <div *ngIf="offerLoaded" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" id="rk-additional-pay-info" class="flex-grid margin-0 padding-16 flex-direction-row flex-wrap flex-align-items-center" [ngClass]="(rentalCheckAdditionalPay.additionalPaymentRequired && rentalCheckAdditionalPay.totalPoints < rentalCheckAdditionalPay.pointWorth) ? 'bg-color-warning' : 'bg-color-white'">
            <div class="flex-col-11 padding-left-0 font-size-l" [hidden]="!(rentalCheckAdditionalPay.additionalPaymentRequired && rentalCheckAdditionalPay.totalPoints >= rentalCheckAdditionalPay.pointWorth)" [innerHTML]="ls.locs['locRkZuzahlung'].ZuzahlungFuerBuchungNutzen">
              <i class="svg-icon __clr-info __size-32 __top-0 __info show-on-small-mobile" (click)="rentalCheckAdditionalPay.showInfoSidebar()"></i>
            </div>
            <div class="flex-col-11 padding-left-0 font-size-l" [hidden]="!(rentalCheckAdditionalPay.additionalPaymentRequired && rentalCheckAdditionalPay.totalPoints < rentalCheckAdditionalPay.pointWorth)" [innerHTML]="rentalCheckAdditionalPay.redemptionlimitWarning">
              <i class="svg-icon __clr-info __size-32 __top-0 __info show-on-small-mobile" (click)="rentalCheckAdditionalPay.showInfoSidebar()"></i>
            </div>
            <div class="flex-col-11 padding-left-0 font-size-l" [hidden]="rentalCheckAdditionalPay.additionalPaymentRequired" [innerHTML]="ls.locs['locRkZuzahlung'].ZuzahlungFuerBuchungNutzenManuellNichtNotwendig">
              <i class="svg-icon __clr-info __size-32 __top-0 __info show-on-small-mobile" (click)="rentalCheckAdditionalPay.showInfoSidebar()"></i>
            </div>
            <div class="flex-col-1 padding-right-0 flex-justify-cont-end text-color-info">
              <i class="svg-icon __clr-info __size-32 __top-0 __info hide-on-small-mobile" (click)="rentalCheckAdditionalPay.showInfoSidebar()"></i>
            </div>
          </div>

          <div *ngIf="offerLoaded" class="margin-0 padding-16 flex-grid flex-direction-row flex-wrap flex-align-items-end bg-color-white">
            <div class="flex-col-12 padding-0">
              {{ls.locs['locReisekonfigurator'].Mietwagen}}
            </div>
            <div class="flex-col-8 flex-col-8-xs padding-left-0 font-size-s">
              {{offer['Titel']}}
            </div>
            <div class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
              <span [innerText]="offer['Punkte'] | integer"></span><span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>

            <hr *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="margin-top-16 margin-bottom-16" />
            <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-8 flex-col-8-xs padding-left-0">{{ ls.locs['locReisekonfigurator'].GesamterReisebetrag }}</div>
            <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
              <span [innerText]="rentalCheckAdditionalPay.totalPoints | integer"></span>
              <span class="currency margin-left-4">{{ ls.locs['loc'].WaehrungPunkte }}</span>
            </div>
            <hr *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="margin-top-16 margin-bottom-16" />
            <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-8 flex-col-8-xs padding-left-0">{{ ls.locs['locRkZuzahlung'].VerwendetePunkte }}</div>
            <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
              <span [innerText]="offer['EingeloestePunkte'] | integer"></span>
              <span class="currency margin-left-4">{{ ls.locs['loc'].WaehrungPunkte }}</span>
            </div>
            <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-8 flex-col-8-xs padding-left-0">{{ ls.locs['locRkZuzahlung'].Zuzahlung }}</div>
            <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung" [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
              <span [innerText]="rentalCheckAdditionalPay.amountValue | decimal"></span>
              <span class="currency margin-left-4">€</span>
            </div>

            <div mpRkzzTcAdditionalPaymentSlider
                 #slider="mpRkzzTcAdditionalPaymentSlider"
                 *ngIf="mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung && offerLoaded && rentalCheckAdditionalPay.showAdditionalPayment"
                 class="flex-grid padding-left-right-8 width-full-important margin-top-16"
                 [step]="mpSettings.settings['ZuzahlungSettings'].ManuelleZuzahlungStep"
                 [enforceStep]="mpSettings.settings['ZuzahlungSettings'].EnforceStep"
                 [item]="offer"
                 [getAmountFunc]="rentalCheckAdditionalPay.getAmountFunc"
                 [routeParams]="routeParams"
                 [checkingParameters]="checkingParameters"
                 [totalPoints]="totalPoints"
                 [updateAdditionalPaymentgValues]="rentalCheckAdditionalPay.updateAdditionalPaymentgValues">
              <div class="flex-col-5 flex-col-5-xs padding-left-0">{{ ls.locs['locRkZuzahlung'].VerwendetePunkte }}</div>
              <div class="flex-col-2 flex-col-2-xs"></div>
              <div class="flex-col-5 flex-col-5-xs padding-right-0 flex-justify-cont-end">{{ ls.locs['locRkZuzahlung'].Zuzahlung }}</div>
              <div class="flex-col-12 padding-left-right-0">
                <ngx-slider *ngIf="offer['sliderOptions']"
                            class="mp-ngx-slider width-full-important margin-top-16"
                            [value]="offer['EingeloestePunkte']"
                            [options]="offer['sliderOptions']"
                            (userChangeStart)="slider.onSliderChangeStart($event)"
                            (userChangeEnd)="slider.onSliderChangeEnd($event)">
                </ngx-slider>
              </div>
              <div class="flex-col-5 flex-col-5-xs padding-left-0">
                <mp-core-input *ngIf="rentalCheckAdditionalPay.getAmount" class="text-color-info rk-ap-field" [(model)]="offer['EingeloestePunkteText']" (change)="rentalCheckAdditionalPay.getAmount()" currencySign="{{ ls.locs['loc'].WaehrungPunkte }}"></mp-core-input>
              </div>
              <div class="flex-col-2 flex-col-2-xs"></div>
              <div class="flex-col-5 flex-col-5-xs padding-right-0 flex-justify-cont-end">
                <mp-core-input class="text-color-info rk-ap-field" [(model)]="offer['ZuzahlungEuro']" [mpDisabled]="offer['ZuzahlungEuro'] === '-'" (change)="slider.updateOfferEuro(offer['ZuzahlungEuro'], 0)" currencySign="€"></mp-core-input>
              </div>
            </div>
          </div>
        </div>

        <div id="rk-overview-sum" class="padding-16 text-color-white bg-color-info font-size-l">
          <div class="flex-grid flex-direction-row flex-wrap flex-align-items-end">
            <div class="flex-col-8 flex-col-8-xs">
              {{ls.locs['locReisekonfigurator'].Gesamtbetrag}}
            </div>
            <div class="flex-col-4 flex-col-4-xs flex-justify-cont-end">
              <span>{{offer['EingeloestePunkte'] | integer}}</span>
              <span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>
            <div [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-8 flex-col-8-xs">{{ ls.locs['locReisekonfigurator'].Plus }}</div>
            <div [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment" class="flex-col-4 flex-col-4-xs flex-justify-cont-end">
              <span>{{rentalCheckAdditionalPay.amountValue | decimal}}</span>
              <span class="currency margin-left-4">€</span>
            </div>
          </div>
        </div>

        <div [hidden]="!rentalCheckAdditionalPay.showAdditionalPayment || offer['EingeloestePunkte'] >= rentalCheckAdditionalPay.totalPoints" id="rk-pay-method" class="margin-top-32">
          <div class="rk-offers-headline">
            <h2>{{ ls.locs['locRkZuzahlung'].WieBezahlen }}</h2>
            <form name="formPunkte" class="font-size-s margin-bottom-24 padding-16 bg-color-white" novalidate="" id="additional-payment-methods">
              <div class="col-medium-4 grid" id="methods-panel">
                <div id="methods-panel-inner">
                  <mp-core-radio-button *ngFor="let p of rentalCheckAdditionalPay.provider"
                                        mpId="radio-{{p.Key}}"
                                        label="{{p.Bezeichnung}}"
                                        [(model)]="rentalCheckAdditionalPay.tcAdditionalPaymentService.paymentType"
                                        [ngClass]="p.possible ? rentalCheckAdditionalPay.tcAdditionalPaymentService.paymentType == p.Key ? 'zuzahlung-type-active' : '' : 'additional-pay-method-inactive'"
                                        (click)="rentalCheckAdditionalPay.setType(p.Key)"
                                        value="{{p.Key}}"
                                        mpGroup="zuzahlung-typen">
                  </mp-core-radio-button>
                </div>
              </div>
              <div class="col-medium-1 grid" *ngIf="rentalCheckAdditionalPay.provider.length > 1"></div>
              <ng-container *ngFor="let p of rentalCheckAdditionalPay.provider">
                <div class="col-medium-7 grid"
                     id="method-notes"
                     *ngIf="rentalCheckAdditionalPay.tcAdditionalPaymentService.paymentType === p.Key && p.TemplateText && rentalCheckAdditionalPay.provider.length > 1">
                  <h3 [innerText]="p.TemplateTitel"></h3>
                  <div class="margin-bottom-24"
                       [innerHTML]="p.TemplateText">
                  </div>
                </div>
                <div *ngIf="rentalCheckAdditionalPay.tcAdditionalPaymentService.paymentType === p.Key && p.TemplateText && rentalCheckAdditionalPay.provider.length > 1">
                  <ng-container *ngComponentOutlet="rentalCheckAdditionalPay.tcAdditionalPaymentService.getChildComponent(p.TemplateUrl); injector: rentalCheckAdditionalPay.getInjector(p);"></ng-container>
                </div>
              </ng-container>
              <h2 *ngIf="rentalCheckAdditionalPay.provider.length === 1 && rentalCheckAdditionalPay.provider[0].TemplateText">{{rentalCheckAdditionalPay.provider[0].TemplateTitel}}</h2>
              <div *ngIf="rentalCheckAdditionalPay.provider.length === 1 && rentalCheckAdditionalPay.provider[0].TemplateText"
                   class="margin-bottom-24"
                   [innerHTML]="rentalCheckAdditionalPay.provider[0].TemplateText">
              </div>
              <div *ngIf="rentalCheckAdditionalPay.provider.length === 1 && rentalCheckAdditionalPay.provider[0].TemplateText">
                <ng-container *ngComponentOutlet="rentalCheckAdditionalPay.tcAdditionalPaymentService.getChildComponent(rentalCheckAdditionalPay.provider[0].TemplateUrl); injector: rentalCheckAdditionalPay.getInjector(rentalCheckAdditionalPay.provider[0]);"></ng-container>
              </div>
            </form>
          </div>
        </div>

        <div *ngIf="offerLoaded" id="rk-accept" class="margin-top-0 padding-top-bottom-24 padding-left-16 padding-right-16 font-size-s bg-color-white">
          <div class="margin-bottom-32 margin-top-0">
            <span [innerHTML]="ls.locs['locReisekonfigurator'].EigeneVersicherungHinweis" class="font-size-s"></span>
          </div>
          <div class="display-flex margin-top-bottom-12">
            <mp-core-checkbox [model]="checkingParameters['BedingungenAkzeptiert']" (modelChange)="checkingParameters['BedingungenAkzeptiert'] = $event; checkParamChanged()" class="form-control" mpId="chkBedingungen" label="{{ls.locs['locReisekonfigurator'].TeilnahmebedingungenBestaetigen | format:[role]}}"></mp-core-checkbox>
          </div>
          <div [innerHTML]="ls.locs['locReisekonfigurator'].HinweisVertragsruecktritt" class="margin-top-bottom-12 margin-left-20 padding-left-6">
          </div>
          <div [innerHTML]="ls.locs['locReisekonfigurator'].HinweisDatenpruefung" class="margin-top-12 margin-left-20 padding-left-6">
          </div>
          <p *ngIf="conditionsInvalid" class="inputRequired">{{ls.locs['validate'].TbBestaetigtRequired}}</p>
        </div>
        <div *ngIf="offerLoaded" class="margin-top-16">
          <button type="button" [attr.disabled]="rentalCheckAdditionalPay.lockButton === true ? 'disabled' : null" class="mp-button _prim _wide" (click)="rentalCheckAdditionalPay.book()">
            {{ls.locs['locReisekonfigurator'].JetztKostenpflichtigBuchen}}
          </button>
        </div>
      </div>
      <div *ngIf="offerLoaded" class="margin-top-16 margin-bottom-16">
        <div id="rk-overview-mietwagen" class="bg-color-white">
          <div id="rk-overview-img" style="background-image: url('{{offer['Image']}}')"></div>
          <div class="flex-grid flex-direction-column padding-top-16 padding-bottom-16 padding-left-32 padding-right-32">
            <div class="flex-col-12 padding-top-12">
              <h3 class="margin-bottom-0">{{offer['Titel']}}</h3>
            </div>
            <div class="flex-col-12 flex-align-items-center">
              <span class="font-size-s">{{offer['Fahrzeugtyp']}}, {{offer['Anbieter']}}</span>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">
                {{ls.locs['locReisekonfigurator'].Abholort}}
              </div>
              <div class="font-size-s margin-top-4 display-flex flex-direction-column">
                <span [innerText]="offer['StandortAbholung'].Name"></span>
                <span [hidden]="!offer['StandortAbholung'].AddressLine && !offer['StandortAbholung'].AddressLine2 ">{{offer['StandortAbholung'].AddressLine}} {{offer['StandortAbholung'].AddressLine2}}</span>
                <span>{{offer['StandortAbholung'].Street}} {{offer['StandortAbholung'].Street2}} {{offer['StandortAbholung'].StreetNumber}}</span>
                <span>{{offer['StandortAbholung'].Country}}-{{offer['StandortAbholung'].ZipCode}} {{offer['StandortAbholung'].CityName}}<span [hidden]="!offer['StandortAbholung'].County"> ({{offer['StandortAbholung'].County}})</span></span>
                <span>{{offer['Abholzeitpunkt']}}</span>
              </div>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">
                {{ls.locs['locReisekonfigurator'].Rueckgabeort}}
              </div>
              <div class="font-size-s margin-top-4 display-flex flex-direction-column">
                <span [innerText]="offer['StandortRueckgabe'].Name"></span>
                <span [hidden]="!offer['StandortRueckgabe'].AddressLine && !offer['StandortRueckgabe'].AddressLine2 ">{{offer['StandortRueckgabe'].AddressLine}} {{offer['StandortRueckgabe'].AddressLine2}}</span>
                <span>{{offer['StandortRueckgabe'].Street}} {{offer['StandortRueckgabe'].Street2}} {{offer['StandortRueckgabe'].StreetNumber}}</span>
                <span>{{offer['StandortRueckgabe'].Country}}-{{offer['StandortRueckgabe'].ZipCode}} {{offer['StandortRueckgabe'].CityName}}<span [hidden]="!offer['StandortRueckgabe'].County"> ({{offer['StandortRueckgabe'].County}})</span></span>
                <span>{{offer['Rueckgabezeitpunkt']}}</span>
              </div>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ls.locs['locReisekonfigurator'].Mietdauer}}</div>
              <div class="font-size-s margin-top-4">
                <span>{{ls.locs['locReisekonfigurator'].NTage | format:[offer['MietdauerInTagen']]}}</span>
              </div>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">{{ls.locs['locReisekonfigurator'].Fahrzeugausstattung}}</div>
              <div class="font-size-s margin-top-4">
                <span *ngIf="offer['Gangschaltung'] === gearBox['Automatik']">Automatik</span>
                <span *ngIf="offer['Gangschaltung'] === gearBox['Manuell']">Manuell</span>
                <span *ngIf="offer['HasKlimaanlage']">Klimaanlage</span>
                <span *ngIf="offer['AnzahlTueren']">{{offer['AnzahlTueren'] | integer}} Türen</span>
              </div>
            </div>
            <div class="flex-col-12 flex-direction-column padding-top-16">
              <div class="bold">
                {{ls.locs['locReisekonfigurator'].Platzangebot}}
              </div>
              <div class="font-size-s margin-top-4">
                <span *ngIf="offer['AnzahlPersonen']">{{offer['AnzahlPersonen'] | integer}} Personen empfohlen</span>
                <span *ngIf="offer['AnzahlKoffer']">{{offer['AnzahlKoffer'] | integer}} Koffer empfohlen</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mp-rk-tc-support class="hide-on-medium"></mp-rk-tc-support>
  </div>
  <div id="rk-bottom-buttons" class="show-on-medium position-fixed bottom-0 width-full" stickyBottom>
    <a *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="mp-button _prim width-100-pcnt display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column padding-top-bottom-8" href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">
      <i class="svg-icon __clr-navbar __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
      <span class="display-block">{{ls.locs['locReisekonfigurator'].Reisebuero}}</span>
    </a>
    <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-between">
      <a class="mp-button" (click)="goBack($event)">{{ls.locs['loc'].Zurueck}}</a>
      <a class="mp-button" href="#/{{role}}/Reisekonfigurator">
        {{ls.locs['loc'].Abbrechen}}
      </a>
    </div>
  </div>
</section>
