<article *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" (mouseleave)="showIconLegend = false" class="card-flat bg-color-white padding-0 box-sizing-border-box margin-0">
  <div class="mouseenter-catcher width-full height-full" (mouseenter)="showIconLegendFnc()">
    <a class="shopKachelAnchor padding-top-16 padding-bottom-8 padding-left-right-16 box-sizing-border-box" href="#/{{role}}/PraemienDetails?art={{ tile.ArtNr }}&k={{ categoryId }}&s={{ encodedSsearchTerm }}&m={{ brand }}" (click)="onClick($event)" [ngClass]="showIconLegend == true ? 'show-icon-legend' : 'hide-icon-legend'">
      <div class="card-ribbon-wrapper overflow-hidden" [hidden]="!tile.IsNeu">
        <div class="card-new-bubble bg-color-info font-size-xs text-align-center position-relative display-inline-block padding-top-bottom-0 padding-left-right-4">{{ ls.locs['loc'].NeuCaps }}</div>
      </div>
      <div class="card-uvp-diff position-relative" [hidden]="!tile.ProzentRabatt || (tile.ProzentRabatt && tile.ProzentRabatt < 5)">
        <div class="card-uvp-diff-badge text-uppercase no-word-wrap top-16 position-absolute padding-top-bottom-8 padding-left-right-16 text-align-right font-size-xs">{{ ls.locs['loc'].ProzentRabatt | format:[tile.ProzentRabatt] }}</div>
      </div>
      <div class="product-image">
        <div class="article-img-container display-flex flex-align-items-center" [ngClass]="showIconLegend && tile.CellWidth == 1 ? 'flex-justify-cont-start' : 'flex-justify-cont-center'">
          <img [src]="(tile.CellWidth == 2 ? getMoodImage() : getAwardImage()) + (tile.CellWidth == 1 ? '?width=150&height=150&mode=crop' : tile.CellWidth == 2 ? '?height=315' : '' )" [attr.alt]="tile.ArtNr">
          <img [src]="getAwardImage() + '?width=150&height=150&mode=crop'" [attr.alt]="tile.ArtNr">
        </div>
        <div class="logo-container" *ngIf="tile.CellWidth == 1">
          <img class="logo" [src]="tile.HerstellerLogoPath" mpCoreErrSrc errSrc="/themes/default/media/images/trans.png">
        </div>
      </div>
      <div class="pretitle-wrapper max-width-full">
        <div class="width-full display-block">
          <div class="pretitle text-overflow-ellipsis float-left padding-right-8 font-size-general bold">
            <span class="product-category" [innerText]="tile.ArtNr.substr(0, 1) == 'R' ? tile.ArtBez2 : tile.ArtBez"></span>
          </div>
          <div class="pretitle company-logo text-overflow-ellipsis float-left padding-right-8 font-size-general bold">
            <span>{{ tile.HerstellerName }}</span>
          </div>
        </div>
        <span class="title width-full top-4 position-relative display-block text-overflow-ellipsis" [innerText]="tile.ArtNr.substr(0, 1) == 'R' ? tile.ArtBez : tile.ArtBez2"></span>
      </div>
      <div class="logo-container" *ngIf="tile.CellWidth > 1">
        <img class="logo" [src]="tile.HerstellerLogoPath" mpCoreErrSrc errSrc="/themes/default/media/images/trans.png">
      </div>
      <div class="supporting-text card-m-three-rows" *ngIf="tile.CellWidth > 1 && tile.Teaser !== null">{{ tile.Teaser | slice:0:150 }}{{ tile.Teaser.length > 150 ? '...' : '' }}</div>
      <div class="icon-legend display-flex">
        <ul class="display-flex">
          <li *ngIf="hasStatus && mpSettings.settings['DisplaySettings'].ShowPraemienStatus===true" class="display-flex">
            <img *ngIf="mpSettings.settings['DisplaySettings'].StatusIconsAsImages" class="float-left icon status-icon-img margin-right-16" src="/themes/default/media/icons/status-icon-{{ tile.StatusID }}.png" />
            <span *ngIf="!mpSettings.settings['DisplaySettings'].StatusIconsAsImages">
              <i class="svg-icon __clr-status-{{ tile.StatusID }} __size-18 __top-0 __state-round"></i>
            </span>
            <div [ngSwitch]="tile.StatusID">
              <span *ngSwitchCase="1">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusBronzeVerfuegbar }}</span>
              </span>
              <span *ngSwitchCase="2">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusSilberVerfuegbar }}</span>
              </span>
              <span *ngSwitchCase="3">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusGoldVerfuegbar }}</span>
              </span>
            </div>
          </li>
          <li [ngSwitch]="tile.VerfuegbarkeitID" class="display-flex">
            <span class="display-flex flex-align-items-center" *ngSwitchCase="1">
              <i class="svg-icon __clr-success __top-0 __size-18 __check-circle"></i>
              <span class="icon-legend-text" [innerText]="tile.AmpelGruenText"></span>
            </span>
            <span class="display-flex flex-align-items-center" *ngSwitchCase="2">
              <i class="svg-icon __clr-warning __top-0 __size-18 __check-circle"></i>
              <span class="icon-legend-text" [innerText]="tile.Lieferhinweis || tile.AmpelGelbText"></span>
            </span>
            <span class="display-flex flex-align-items-center" *ngSwitchCase="3">
              <i class="svg-icon __clr-danger __top-0 __size-18 __oneway"></i>
              <span class="icon-legend-text" [innerText]="tile.Lieferhinweis || ls.locs['loc'].AmpelRot"></span>
            </span>
          </li>
          <li *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasExpress && tile.CanExpress" class="display-flex">
            <span>
              <i class="svg-icon __clr-specific-4 __top-0 __size-18 __express"></i>
            </span>
            <span class="icon-legend-text">{{ ls.locs['loc'].ExpressMoeglich }}</span>
          </li>
        </ul>
      </div>
    </a>
    <div (mouseenter)="showIconLegendFnc()" class="border-top padding-top-8 padding-bottom-16 padding-left-right-16 display-flex flex-align-items-center flex-justify-cont-between">
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte"
           class="price margin-top-6">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Praemienpunkte }}" alignment="right">
          <i class="svg-icon __clr-default __size-18 __top-4 __points default-cursor-hover margin-right-4"></i>
        </mp-core-tooltip>
        <span [hidden]="!tile.ShowAb"
              class="points-from-span vertical-align-middle padding-top-2">{{ ls.locs['loc'].ab }} </span>
        <span class="icon-text padding-top-2">{{ tile.Punkte | integer }} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        <span *ngIf="tile.Vergleichspreis && tile.ProzentRabatt && tile.ProzentRabatt >= 5"
              class="icon-text price-uvp position-relative margin-left-8 padding-top-2">{{ tile.Vergleichspreis | integer }} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowWunschlisteButton && wlLoaded"
           class="fav-heart">
        <mp-core-tooltip tooltipTitle="{{ isInWishlist() ? ls.locs['loc'].AusWunschlisteEntfernen : ls.locs['loc'].WunschlisteHinzufuegen }}" alignment="left">
          <button class="mp-button padding-left-right-8 margin-top-8"
                  (click)="toggleWishlist($event)">
            <i class="svg-icon __size-18 __top-0"
               [ngClass]="isInWishlist() ? '__clr-primary __favorite-filled' : '__clr-default __favorite'"></i>
          </button>
        </mp-core-tooltip>
 
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].WarenkorbHinzufuegen }}" alignment="left">
            <button class="mp-button padding-left-right-8" (click)="addToShoppingbasket(tile)">
              <i class="svg-icon __clr-default __top-0 __basket __size-18"></i>
            </button>
          </mp-core-tooltip>
          <!--<mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AmpelRot }}" alignment="left" *ngIf="!wishCard.CanOrder">
      <button class="mp-button padding-left-right-8" *ngIf="!wishCard.CanOrder" [ngClass]="{disabledWK: !wishCard.CanOrder}">
        <i class="svg-icon __clr-inactive __top-0 __basket __size-18"></i>
      </button>
    </mp-core-tooltip>-->
       
      </div>
      
    </div>
  </div>
</article>
