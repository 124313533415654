import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

import { ChangeContext, Options } from '@angular-slider/ngx-slider';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpDebounceService } from '@core/services/mp-debounce.service';
import { CalendarService } from '@core/components/calendar/calendar.service';

/**
 * This class provides the functionalities
 * for the travel time sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-calendar-hotel',
  templateUrl: './sidebar-calendar-hotel.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarCalendarHotelComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public selectionLoaded: boolean = false;

  private _maxRangeDefault: number = 35;
  private _updateTravelTime: any;
  private _maxRange: number = this._maxRangeDefault;

  public sliderOptions: Options = {
    floor: 1,
    ceil: this._maxRangeDefault,
    step: 1
  };

  private _updateTravelTimeSubject: Subject<boolean> = new Subject<boolean>();
  private _updateTravelTimeObservable: Observable<boolean> = this._updateTravelTimeSubject.asObservable()
  private _sidebarParamsSubscription: Subscription | undefined;
  private _travelTimeUpdatedSubsription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpDebounce: MpDebounceService,
    public mpSidebar: MpSidebarService,
    private _calendarService: CalendarService
  ) { }

  /**
   * Gets the sidebar params, and sets the
   * function for updating the travel time.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        rangeStart: this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'] || this.mpSidebar.params['minDate'],
        rangeEnd: this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd'],
        Reisedauer: this.selection['Reisedauer'] || this.mpSidebar.params['Reisedauer'] || this.mpSidebar.params['reisedauerOptions'][1],
        MinTage: this.selection['MinTage'] || this.mpSidebar.params['MinTage'] || 7,
        MaxTage: this.selection['MaxTage'] || this.mpSidebar.params['MaxTage'] || 10
      });

      this.selectionLoaded = true;
    });

    this._updateTravelTime = this._mpDebounce.debounce(() => {
      if(this.selection['Reisedauer'] && this.selection['rangeEnd']) {
        let daysSet = false;

        const timeDays = this.selection['rangeStart'] && this.selection['rangeEnd']
          ? this.selection['rangeEnd'].diff(this.selection['rangeStart'], 'days')
          : 1;

        this._maxRange = this.selection['Reisedauer'].id === 2 ? this._maxRangeDefault : 1000;

        if (this.selection['Reisedauer'].id === 1) {
          this.selection['MinTage'] = 1;
          this.selection['MaxTage'] = this._maxRangeDefault;
          daysSet = false;
        } else if (this.selection['Reisedauer'].id === 2) {
          this.selection['MinTage'] = timeDays;
          this.selection['MaxTage'] = timeDays;
          daysSet = false;
        } else {
          if (this.selection['rangeStart'] && this.selection['rangeEnd']) {
            if (this.selection['MinTage'] > timeDays) {
              this.selection['MinTage'] = timeDays;
            }

            const newOptions: Options = Object.assign({}, this.sliderOptions);
            newOptions.ceil = Math.min(timeDays, this._maxRangeDefault);
            this.sliderOptions = newOptions;
            this.selection['MaxTage'] = this.selection['MaxTage'] && this.selection['MaxTage'] < (this.sliderOptions.ceil || 0) ? this.selection['MaxTage'] : this.sliderOptions.ceil;
          }

          if (!daysSet) {
            this.selection['MinTage'] = this.selection['MinTage'] && this.selection['MinTage'] < this.selection['MaxTage'] ? this.selection['MinTage'] : this.sliderOptions.floor;
            this.selection['MaxTage'] = this.selection['MaxTage'] || this.sliderOptions.ceil;
            daysSet = true;
          }
        }

        this._updateTravelTimeSubject.next(true);
      }
    }, 100);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._travelTimeUpdatedSubsription !== 'undefined') {
      this._travelTimeUpdatedSubsription.unsubscribe();
    }
  }

  /**
   * Saves the selected travel time.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this._updateTravelTime();

    this._travelTimeUpdatedSubsription = this._updateTravelTimeObservable.subscribe((updated: boolean) => {
      if (updated) {
        if (!(this.selection['rangeStart'] && this.selection['rangeEnd'])) {
          return;
        }

        this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
        this.selection = {};
        this.mpSidebar.changeParams(this.mpSidebar.params);

        if (typeof this.mpSidebar.params['save'] !== 'undefined') {
          this.mpSidebar.params['save']();
        }

        this.mpSidebar.close();
      }
    });
  }

  /**
   * Changes the current selection to
   * 'start' or 'end'.
   */
  setCurrentSelection(selection: string): void {
    this._calendarService.changeCalendarSelection({ selection: selection });
  }

}
