import { Directive, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpThemesService } from '@core/services/mp-themes.service';
import { ApiService } from '@core/services/api.service';

@Directive({
  selector: '[mpGkPrizeCalendar]',
  exportAs: 'mpGkPrizeCalendar'
})
export class PrizeCalendarDirective implements OnInit, OnDestroy {

  public calendar: { [key: string]: any } = {};
  public calendarLoaded: boolean = false;
  public snowflakes = Array.from({ length: 64 }, (_, i) => i);
  public currentTheme: string = '';
  public openDoorIndex: number = -1;
  public showOpenDoorResult: boolean = false;
  public openDoorResult: { [key: string]: any } = {};
  public openDoorDay: number = 0;
  public openDoorGewinnkalenderKey: string = '';
  public noPoints: boolean = false;

  private _prizeCalendarKey: string = '';
  private _getCalendarContentSubscription: Subscription | undefined;
  private _getRouteParamsSubscription: Subscription | undefined;
  private _getCurrentThemeSubscription: Subscription | undefined;
  private _getPrizeSubscription: Subscription | undefined;


  constructor(
    private _route: ActivatedRoute,
    private _mpMenuRefresh: MpMenuRefreshService,
    private _mpMessaging: MpMessagingService,
    private _mpThemes: MpThemesService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the calendar key from the
   * route params, gets the current
   * theme, and gets the calendar
   * content data.
   */
  ngOnInit(): void {
    this._getRouteParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['key'] !== 'undefined') {
        this._prizeCalendarKey = params['key'];

        this._getCurrentThemeSubscription = this._mpThemes.theme.subscribe((currentTheme: string) => {
          this.currentTheme = currentTheme;
        });

        this._mpThemes.getCurrentTheme();


       var gk = { GewinnkalenderKey: this._prizeCalendarKey }

       this._getCalendarContentSubscription = this._apiService.postRequest('/api/Gewinnkalender/GetGewinnkalenderContent', gk).subscribe((data: any) => {
          if (data.Result === 'ERROR') {
            this._mpMessaging.openWarningPanel(data.Message);
          } else {
            const currentDate = new Date();
            let date = null;
            let dayPast = false;

            const currentDateInt = (currentDate.getUTCFullYear() * 10000) +
              ((currentDate.getUTCMonth() + 1) * 100) +
              currentDate.getUTCDate();

            data.Records[0].Tage.forEach((record: any) => {
              date = new Date(record.Tag);
              const dateInt = (date.getUTCFullYear() * 10000) + ((date.getUTCMonth() + 1) * 100) + date.getUTCDate();

              if (dateInt < currentDateInt) {
                dayPast = true;
              } else {
                dayPast = false;
              }

              record['dayPast'] = dayPast;
            });

            this.calendar = data.Records[0];
            this.calendarLoaded = true;
          }
        },
        (error: any) => {
          this._mpMessaging.openWarningPanel(error.Message);
        });
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getCalendarContentSubscription !== 'undefined') {
      this._getCalendarContentSubscription.unsubscribe();
    }

    if (typeof this._getRouteParamsSubscription !== 'undefined') {
      this._getRouteParamsSubscription.unsubscribe();
    }

    if (typeof this._getCurrentThemeSubscription !== 'undefined') {
      this._getCurrentThemeSubscription.unsubscribe();
    }

    if (typeof this._getPrizeSubscription !== 'undefined') {
      this._getPrizeSubscription.unsubscribe();
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Closes the modal of the benefit / prize.
   */
  closeBenefitModal(openDoorIndex: number, eventTrigger: string): void {
    if (document.body.classList.contains('opened-modal')) {
      document.body.classList.remove('opened-modal');
    }

    const openedDoor = document.getElementById(`door-${this.calendar['Tage'][openDoorIndex].Nummer}`);

    if (openedDoor !== null) {
      if (openedDoor.classList.contains('trapezoid')) {
        openedDoor.classList.remove('trapezoid');
      }

      const openedDoorDivs = openedDoor.querySelectorAll('div');

      if (openedDoorDivs.length > 0) {
        openedDoorDivs.forEach((doorDiv: HTMLDivElement) => {
          doorDiv.removeAttribute('style');
        });
      }
    }

    this.calendar['Tage'][openDoorIndex].openDoors = false;
    this.calendar['Tage'][openDoorIndex].IsToday = false;
    this.calendar['Tage'][openDoorIndex].dayPast = false;
    this.showOpenDoorResult = false;
  }

  /**
   * Checks whether or not the user is able
   * to open the clicked door.
   */
  checkAbleToOpen(day: { [key: string]: any }, index: number): void {
    if (day['IsToday'] || (day['dayPast'] && this.calendar['AllowPast'])) {
      day['openDoors'] = true;
      day['HasBeenOpened'] = true;

      this._executeOrGetPrize(day, index);
    } else {
      return;
    }
  }

  /**
   * Executes the door opening, and gets
   * the prize.
   */
  private _executeOrGetPrize(day: { [key: string]: any }, index: number): void {
    const dayDate = new Date(day['Tag']);
    const path = (day['IsToday'] ? '/api/Gewinnkalender/ExecuteGewinnResult' : '/api/Gewinnkalender/GetGewinnResult');
    const gk = {
      GewinnkalenderKey: this._prizeCalendarKey,
      Tag: dayDate.getFullYear() + '-' + (dayDate.getMonth() + 1) + '-' + dayDate.getDate()
    };

    this._getPrizeSubscription = this._apiService.postRequest(path, gk).subscribe((data: any) => {
      this.openDoorResult = data.Records[0];
      this.openDoorDay = day['Nummer'];
      this.openDoorIndex = index;
      this.openDoorGewinnkalenderKey = day['GewinnkalenderKey'];
      this.showOpenDoorResult = true;
      this.noPoints = false;

      if (typeof data.Records[0].Punkte === 'undefined' || data.Records[0].Punkte === 0 || data.Records[0].ArtNr) {
        this.noPoints = true;
      }

      if (data.Records[0].IsMessage === true) {
        setTimeout(() => {
          this.closeBenefitModal(day['Nummer'], 'event');
        }, 4000);
      }

      if (data.Records[0].IsGewinn) {
        this._mpMenuRefresh.loadMenuData(true);
      }

      if (!document.body.classList.contains('opened-modal')) {
        document.body.classList.add('opened-modal');
      }
    });
  }

}
