
<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1>{{ ls.locs['loc'].ImportiereAktionsbestellungen }}</h1>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].Aktionsname }}"
              mpId="txtAktionsKey"
              mpType="text"
              [(model)]="campaignKey"
              class="fallback-input"
              [mpRequired]="true">
    </mp-core-input>
    <br/>
    <span class="inputRequired"
          [hidden]="errorCampaignKey === ''"
          [innerText]="errorCampaignKey">
    </span>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].SboVerrechnungshinweis }}"
              mpId="txtVerrechnungshinweis"
              mpType="text"
              [(model)]="offsettingHint"
              class="fallback-input"
              [mpRequired]="true">
    </mp-core-input>
    <br/>
    <span class="inputRequired"
          [hidden]="errorOffsettingHint === ''"
          [innerText]="errorOffsettingHint">
    </span>
  </div>
  <div class="margin-bottom-24">
    <mp-core-select mpId="txtLager" [mpRequired]="true" class="fallback-select" [(model)]="warehouse" label="{{ ls.locs['loc'].Lager }}" [elements]="warehousesList" textProperty="Name" valueProperty="Id"></mp-core-select>
    <br/>
    <span class="inputRequired"
          [hidden]="errorWarehouse === ''"
          [innerText]="errorWarehouse">
    </span>
    <br/>
    <div class="margin-left-32" [innerHTML]="ls.locs['loc'].LagerHinweis"></div>
  </div>
  <mp-core-checkbox class="margin-bottom-24"
               mpId="chkAktionsnameAlsKontoBezeichnung"
               [(model)]="campaignNameInAccount"
               label="{{ ls.locs['loc'].AktionsnameAlsKontoBezeichnung }}">
  </mp-core-checkbox>
  <mp-core-checkbox class="margin-bottom-24"
               mpId="chkBestellMailVersenden"
               [(model)]="sendOrderMail"
               label="{{ ls.locs['loc'].BestellMailVersenden }}">
  </mp-core-checkbox>
  <mp-core-checkbox class="margin-bottom-24"
               mpId="chkExternePersonen"
               [(model)]="externalPeople"
               label="{{ ls.locs['loc'].ExternePersonen }}">
  </mp-core-checkbox>
  <div class="upload-buehne margin-bottom-24">
    <button type="button"
            id="btn-upload-buehne"
            class="mp-button _prim nonefloat distanceforButton"
            (click)="fileUpload.click()">
      {{ ls.locs['loc'].ExcelListeHochladen }}
    </button>
    <input #fileUpload
           name="fileUpload"
           type="file"
           class="display-none"
           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           (change)="addAttachment($event)">
    <div *ngIf="file"
         class="margin-top-16">
      <span>{{file.name}}</span>
      <span class="svg-icon __clr-danger __top-4 __size-18 __oneway default-cursor-hover float-none-important"
            [hidden]="uploadSuccessfully">
      </span>
      <span class="svg-icon __clr-success __top-4 __size-18 __check-circle default-cursor-hover float-none-important"
            [hidden]="!uploadSuccessfully">
      </span>
    </div>
    <br />
    <span class="inputRequired"
          [hidden]="errorUpload === ''"
          [innerText]="errorUpload">
    </span>
  </div>
  <mp-core-checkbox class="margin-bottom-24"
               mpId="chkEmailVersenden"
               [hidden]="externalPeople"
               [(model)]="sendCampaignEmail"
               label="{{ ls.locs['loc'].ZusaetzlicheEMailVersenden }}">
  </mp-core-checkbox>
  <div *ngIf="sendCampaignEmail">
    <div>
      <mp-core-input type="text"
                     id="txtBetreff"
                     class="form-control fallback-input"
                     label="{{ ls.locs['loc'].Betreff }}"
                     [(model)]="subject"
                     [hasError]="errorSubject !== '' ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errorSubject !== ''"
                          [model]="errorSubject">
      </mp-core-form-error>
    </div>
    <div class="position-relative z-index-3">
      <mp-core-select mpId="ddlTbs"
                      class="margin-bottom-24 margin-top-24 fallback-select"
                      [(model)]="selectedTBS"
                      label="{{ ls.locs['loc'].Textbausteineinfuegen }}"
                      (ngChange)="tbsChange(selectedTBS)"
                      textProperty="Text"
                      [elements]="tbsList">
      </mp-core-select>
    </div>
    <div class="ui-helper-clearfix"></div>
    <textarea class="margin-bottom-24"
              *ngIf="froalaOptionsLoaded"
              [froalaEditor]="froalaOptions"
              (froalaInit)="getFroalaControls($event)"
              [(froalaModel)]="body"
              id="editorAktionsMail"
              name="editorAktionsMail"
              [ngClass]="errorBody ? 'invalid-error' : ''"
              [attr.placeholder]="ls.locs['loc'].Nachrichtentext + ' ' + ls.locs['loc'].VerfassenKlein">
    </textarea>
    <mp-core-form-error *ngIf="errorBody !== ''"
                        [model]="errorBody">
    </mp-core-form-error>
  </div>
  <div class="margin-top-24">
    <button type="button"
            class="mp-button _prim"
            [attr.disabled]="saveInProgress ? '' : null"
            (click)="importOrders()">
      {{ ls.locs['loc'].ImportiereAktionsbestellungen }}
    </button>
  </div>
  <div class="margin-top-24"
       [hidden]="messages === ''">
    <h2>{{ ls.locs['loc'].Ergebnis }}</h2>
    <span [innerHTML]="messages"></span>
  </div>
</div>
