import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

@Component({
  selector: 'mp-leas-schooling-statistic-leas',
  templateUrl: './schooling-statistic-leas.component.html',
  styleUrls: ['./schooling-statistic-leas.component.scss']
})
export class SchoolingStatisticLeasComponent implements OnInit, OnDestroy {

  public schoolings: Array<any> = [];
  public selectedSchoolingId: number = 0;
  public schoolingsLoaded: boolean = false;
  public loader: MpLoader | null = null;
  public searchTexts: { [key: string]: any } = {};
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public excelService: string = '/api/Schulung/SchulungStatistik2Excel';

  private _schoolingStatisticLoader: MpLoader = {
    name: 'schoolingStatisticLoader',
    params: {}
  };

  private _getSchulungenSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    public mpSettings: MpSettingsService
  ) { }

  /**
  * Registers the loader
  */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._schoolingStatisticLoader);
    this._getSchulungenSubscription = this._apiService.getRequest('/api/Schulung/GetSchulungen').subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.schoolings = data.Records;
          //this.selectedSchoolingId = this.schoolings[0]['Id'];
          this.schoolingsLoaded = true;
          this.doData();
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getSchulungenSubscription !== 'undefined') {
      this._getSchulungenSubscription.unsubscribe();
    }
  }

  /**
  * Loads the data for the table.
  */
  doData(init?: boolean): void {
    this.loader = this._mpLoaderService.getLoader('schoolingStatisticLoader');
    this.loader.params['SchoolingId'] = this.selectedSchoolingId;

    if (init) {
      this._mpLoaderService.extendLoader('schoolingStatisticLoader', {
        isReady: true
      });
    }

    if (typeof this.loader.load !== 'undefined') {
      this.loader.load();
    }
  }
  /**
  * Gets the final loader, after
  * the table data are loaded.
  */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('schoolingStatisticLoader');
  }
}
