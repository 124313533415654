<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table *ngIf="loaderName !== ''"
                      title=""
                      fieldsAction="/api/Bestellung/getBestellungenCockpitChildFields"
                      listAction="/api/Bestellung/getBestellungenCockpitChild"
                      loaderName="{{loaderName}}"
                      [paging]="false"
                      [footer]="false"
                      [startEmpty]="true"
                      defaultSorting="BestellTAN DESC"></mp-core-data-table>
</div>
