import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { ApiService } from './../../services/api.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * This class provides the data and the
 * functions for the topseller awards slider.
 */
@Component({
  selector: 'mp-core-topseller-awards',
  templateUrl: './topseller-awards.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TopsellerAwardsComponent implements OnInit, OnDestroy {

  @Input() count: number = 0;

  public awards: Array<any> = [];
  public categoryId: number = 50;
  public role: string = '';
  public header: string = '';
  public awardsOwlOptions: OwlOptions = {
    loop: false,
    nav: true,
    margin: 16,
    dots: false,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: true
      },
      980: {
        items: 3,
        nav: true
      },
      1280: {
        items: 4,
        nav: true,
        loop: false
      }
    }
  };

  private _getAwardsSusbcription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the role, the awards and
   * the header.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._getAwardsSusbcription = this._apiService.getRequest(`/api/Home/GetTopsellerHome?anzahl=${this.count}`).subscribe((data: any) => {
      if (data.Result === "OK") {
        this.awards = data.Records;

        if (data.Message) {
          this.header = data.Message;
        } else {
          if (Object.keys(this.ls.locs).length > 0) {
            if (typeof this._getLocsSubscription !== 'undefined') {
              this._getLocsSubscription.unsubscribe();
            }

            this.header = this.ls.locs['loc'].Topseller;
          } else {
            this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
              if (loaded) {
                this.header = this.ls.locs['loc'].Topseller;

                if (typeof this._getLocsSubscription !== 'undefined') {
                  this._getLocsSubscription.unsubscribe();
                }
              }
            })

            this.ls.getLocalization();
          }
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getAwardsSusbcription !== 'undefined') {
      this._getAwardsSusbcription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

}
