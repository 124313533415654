import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { AuthService } from './../../../../services/auth.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the scheduled email
 * management table.
 */
@Component({
  selector: 'mp-core-email-scheduled-management',
  templateUrl: './email-scheduled-management.component.html',
  styleUrls: ['./email-scheduled-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailScheduledManagementComponent implements OnInit, OnDestroy {

  public execClickFunctions: Array<any> = [
    {
      backendName: 'duplicateMail',
      func: this.duplicateMail.bind(this)
    },
    {
      backendName: 'toggleDeleteEmail',
      func: this.toggleDeleteEmail.bind(this)
    }
  ];
  public toggleDeleteEmailFunc = this.toggleDeleteEmail.bind(this);
  public deleteEmailFunc = this._deleteEmail.bind(this);
  public confirmDelete: boolean = false;
  public role: string = '';

  private _getScheduledEmailsLoader: MpLoader = {
    name: 'getScheduledEmailsLoader',
    params: {},
    isReady: true
  };
  private _deleteId: number = 0;
  private _duplicateMailSubscription: Subscription | undefined;
  private _deleteMailSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    public _breadcrumbService: MpBreadcrumbService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _authService: AuthService,
    private _mpMessaging: MpMessagingService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the role, sets the title, and
   * the breadcrumbs. Registers the loader.
   */
  ngOnInit(): void {
    this.role = this._authService.getRole();

    if (typeof this.role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(this.role);
    }

    this._titleService.setTitleFromLoc('EmailscheduledVerwalten', 'loc');
    this._breadcrumbService.setCurrentSiteWithLoc('EmailscheduledVerwalten', 'loc', 'EmailscheduledVerwalten');
    this._mpLoaderService.registerLoader(this._getScheduledEmailsLoader);

  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._duplicateMailSubscription !== 'undefined') {
      this._duplicateMailSubscription.unsubscribe();
    }

    if (typeof this._deleteMailSubscription !== 'undefined') {
      this._deleteMailSubscription.unsubscribe();
    }
  }

  /**
   * Duplicates the given email.
   */
  duplicateMail(email: any): void {
    this._duplicateMailSubscription = this._apiService.postRequest('/api/EMailModul/DuplicateMail', email['Id']).subscribe((data: any) => {
      const loader = this._mpLoaderService.getLoader('getScheduledEmailsLoader');

      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    });
  }

  /**
   * Deletes the clicked email.
   */
  private _deleteEmail(): void {
    this._deleteMailSubscription = this._apiService.getRequest(`/api/EMailModul/DeleteEmail?id=${this._deleteId}`).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this.confirmDelete = false;
      const loader = this._mpLoaderService.getLoader('getScheduledEmailsLoader');

      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    });
  }

  /**
   * Toggles the delete email modal.
   */
  toggleDeleteEmail(row?: any): void {
    this.confirmDelete = !this.confirmDelete;

    if (typeof row !== 'undefined') {
      this._deleteId = row['Id'];
    } else {
      this._deleteId = 0;
    }
  }

}
