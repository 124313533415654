import { Component, OnDestroy, OnInit, ViewEncapsulation, TemplateRef, QueryList, ViewChildren, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '../../services/mp-localization.service';
import { CookieInfo } from '../../services/interfaces/cookie-info';
import { CookieType } from '../../services/interfaces/cookie-type';
import { CookieService } from '../../services/cookie.service';
import { MpMessagingService } from '../../services/mp-messaging.service';
import { RoleMappingService } from '../../services/role-mapping.service';
import { AuthService } from '../../services/auth.service';
import { Router, Event as RouterEvent } from '@angular/router';
import { MpSettingsService } from '../../services/mp-settings.service';
import { DOCUMENT } from '@angular/common';

/**
 * This class provides the functions and data
 * for the cookie consent layer.
 */
@Component({
  selector: 'mp-core-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  @ViewChildren('singleCookies') singleCookies!: QueryList<TemplateRef<any>>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any> | undefined;

  public showIndividualSettings: boolean = false;
  public cookies: CookieInfo[] = [];
  public cookieTypes: CookieType[] = [];
  public showCookieConsentModal: boolean = false;
  public role: string = '';
  public accCookieDataFunc = this.accCookieData.bind(this);
  public cookiesLoaded: boolean = false;
  public hideBackButton: boolean = false;
  public locsLoaded: boolean = false;

  private _saveConsentsSubscription: Subscription | undefined;
  private _cookieWindowTriggerSubscription: Subscription | undefined;
  private _cookieUpdateSubscription: Subscription | undefined;

  private _settingsLoaded: boolean = false;
  private _bodyClassSet: boolean = false;

  constructor(
    public ls: MpLocalizationService,
    public cookieService: CookieService,
    @Inject(DOCUMENT) private _document: Document,
    private _mpMessagingService: MpMessagingService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    public settings: MpSettingsService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    if (this._authService.getUserLoggedIn()) {
      const role = this._authService.getRole();

      if (typeof role === 'object') {
        this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
      } else {
        this.role = this._roleMapping.getReverseMappedRole(role);
      }
    }

    //let locLoader = this.ls.locsLoaded.subscribe((loaded: boolean) => {
    //  this.locsLoaded = loaded;
    //  if (this.locsLoaded) {
    //    locLoader.unsubscribe();
    //  }
    //})

    this.settings.settingsLoaded.subscribe((loaded: boolean) => {
      this._settingsLoaded = loaded;
      this._autoOpenModal();
    });

    this._cookieWindowTriggerSubscription = this.cookieService.consentBannerOpened.subscribe(() => {
      this.showCookieConsentModal = true;
      this.showIndividualSettings = true;
      this.hideBackButton = true;
      this._toggleBodyScroll();
    });

    this._router.events.subscribe((_: RouterEvent) => {
      this._autoOpenModal();
    });

    this._loadCookies();
  }

  private _loadCookies() {
    this.cookieService.getCookieTypes().then((cookieTypes: CookieType[]) => {
      this.cookieTypes = cookieTypes;
      this.cookiesLoaded = true;
      this._autoOpenModal();

      this._cookieUpdateSubscription = this.cookieService.cookiesUpdated.subscribe(() => this.cookieService.getCookieTypes().then((cookieTypes: CookieType[]) => {
        this.cookieTypes = cookieTypes;
      }));
    }, (err: any) => {
      this._loadCookies();
    });
  }

  private _autoOpenModal() {
    this.showCookieConsentModal = !!(this._settingsLoaded
      && this.settings.settings['CookieConsentSettings'].ConsentNecessary
      && !this.isAllowedRoute()
      && !this.cookieService.isCookieSet());

    this._toggleBodyScroll();
  }

  private _toggleBodyScroll() {
    let bodyClass = 'overflow-hidden';
    if (this._bodyClassSet && !this.showCookieConsentModal) {
      this._document.body.classList.remove(bodyClass);
      this._bodyClassSet = false;
    } else if (!this._document.body.classList.contains(bodyClass) && this.showCookieConsentModal) {
      this._document.body.classList.add(bodyClass);
      this._bodyClassSet = true;
    }
  }

  private isAllowedRoute(): boolean {
    for (let route of this.settings.settings['CookieConsentSettings'].AllowedRoutes ?? []) {
      if (route.toLowerCase().endsWith(this._router.url.toLowerCase()))
        return true;
    }
    return false;
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    this._saveConsentsSubscription?.unsubscribe();
    this._cookieWindowTriggerSubscription?.unsubscribe();
    this._cookieUpdateSubscription?.unsubscribe
  }

  /**
   * Generates the data for the accordions.
   */
  accCookieData(): Array<any> {
    const accData: Array<any> = [];

    this.cookieTypes.forEach((cookieType: any, index: number) => {
      let accTemplates: Array<TemplateRef<any>> = [];

      accData.push({
        accID: cookieType.Identifier,
        accHeadline: `${cookieType.Name} (${cookieType.Cookies.length})`,
        accSubline: cookieType.Description,
      });

      if (!cookieType.Mandatory) {
        accData[index] = Object.assign(accData[index], {
          accButtonOne: {
            label: cookieType.HasConsent ? this.ls.locs['loc'].An : this.ls.locs['loc'].Aus,
            func: this.toggleAllForType.bind(this),
            funcParameter: cookieType,
            useSwotch: true,
            switchActive: cookieType.HasConsent
          }
        });
      }

      if (this.singleCookies) {
        const template = this.singleCookies.get(index);

        if (template) {
          accTemplates.push(template);
        }
      }

      if (accTemplates.length > 0) {
        accData[index] = Object.assign(accData[index], {
          accTemplates: accTemplates
        });
      }
    });

    return accData;
  }

  /**
   * Accepts or refuses all cookies of
   * given type.
   */
  toggleAllForType(cookieType: CookieType): void {
    const consent = !cookieType.HasConsent;

    cookieType.Cookies?.forEach((cookie: CookieInfo) => {
      cookie.HasConsent = consent;
    });

    cookieType.HasConsent = consent;
  }

  /**
   * Handles accept all cookies.
   */
  acceptAll(): void {
    this.cookieTypes.forEach((cookieType: CookieType) => {
      cookieType.Cookies?.forEach((cookie: CookieInfo) => {
        cookie.HasConsent = true;
      });

      cookieType.HasConsent = true;
    });

    this.saveConsents();
  }

  /**
   * Handles accept only essential cookies.
   */
  acceptEssential(): void {
    this.cookieTypes.forEach((cookieType: CookieType) => {
      cookieType.Cookies?.forEach((cookie: CookieInfo) => {
        cookie.HasConsent = cookie.DefaultAcceptance;
      });

      this.cookieService.setTypeConsent(cookieType);
    });

    this.saveConsents();
  }

  /**
   * Handles the saving of the consent choices.
   */
  saveConsents(): void {
    this.cookieService.saveCookieConsent(this.cookieTypes).then((success: boolean | string) => {
      if (success === true) {
        this.showCookieConsentModal = false;
        this._toggleBodyScroll();
      } else {
        this._mpMessagingService.openWarningPanel(success.toString());
      }
    });
  }

  setCookieConsent(cookies: CookieInfo[], value: boolean | null) {
    if (value === null)
      return;

    cookies.forEach((cookie: CookieInfo) => {
      cookie.HasConsent = value;
    })
  }

  /**
   * Toggles the indivual settings panel.
   */
  toggleIndividualSettings(): void {
    this.showIndividualSettings = !this.showIndividualSettings;
  }

}
