import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})

/**
 * This class provides a directive to handle event
 * from click outside the html element.
 */
export class ClickOutsideDirective {

  public registered: any= [];

  @Output() clickOutside = new EventEmitter();

  constructor(private _elementRef: ElementRef) { }

  /**
  * Fills registered array with current element
  */
  ngOnInit(): void {
    let fn = document.querySelectorAll('[clickOutside]');
    this.register({
      element: this._elementRef
    });
  }

  /**
   * Extracted registered array
   */
  ngOnDestroy(): void {
    (reg: any) => {
      let idx = this.registered.indexOf(reg);
      if (idx > -1) {
        this.registered.splice(idx, 1);
      }
    };
  }

  /**
   * Decorator for handling a click outside a particular Angular Component or DOM element
   */
  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent) {
    this.registered.forEach((reg: any) => {
      
        if (!reg) 
          return;

      let clickOutsideExcept = reg.element.nativeElement.attributes['click-outside-except'];

      const exceptSelector = clickOutsideExcept ? clickOutsideExcept.value : '';

      let selectors = exceptSelector.replace(" ", "").split(",");

      //@ts-ignore
      let clickedElement = event.originalEvent &&
        //@ts-ignore
        (event.originalEvent.srcElement /* Chrome */ || event.originalEvent.target /* Firefox */) ||
        //@ts-ignore
        event.target;

      let matched = false;
      let parentNode = clickedElement.parentElement;
  
      if (exceptSelector !== '') {
        selectors.forEach((selector: string) => {
          if (clickedElement.matches(selector)) {
            matched = true;
          }
        });

        while (!matched && parentNode !== null) {
          selectors.forEach((selector: string) => {
            if (parentNode.matches(selector)) {
              matched = true;
            }
          });
          parentNode = parentNode.parentElement;
        }
      }

      let except = exceptSelector &&
        exceptSelector.indexOf('{') < 0 && matched;

      if (clickedElement === reg.element ||
        reg.element.nativeElement.contains(clickedElement) ||
        clickedElement === document ||
        except) {
        return;
      }
      this.clickOutside.emit();
    });

  };

  /**
   * Pushes next element in registered array
   */
  register(reg: any) {
    this.registered.push(reg);
  }
}
