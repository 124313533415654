import { Component, OnInit, Input, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';

import fitty from 'fitty';

@Component({
  selector: 'mp-core-manager-dashboard-card',
  templateUrl: './manager-dashboard-card.component.html',
  styleUrls: ['./manager-dashboard-card.component.scss']
})
export class ManagerDashboardCardComponent implements OnInit, OnDestroy {

  @Input() public src: string = '';

  public dash: { [key: string]: any } = {};
  public managerDashCardColor: string = '';

  private _getSourceSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _cardElem: ElementRef
  ) { }

  /**
   * Gets the data for the dashboard
   * card.
   */
  ngOnInit(): void {
    this._getSourceSubscription = this._apiService.getRequest(this.src).subscribe((data: any) => {
      this.dash = data.Records[0];
      this._resizeValue();
      this.managerDashCardColor = this.dash['BackgroundColor'];
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getSourceSubscription !== 'undefined') {
      this._getSourceSubscription.unsubscribe();
    }
  }

  /**
   * Resizes the cards content to fit the
   * card.
   */
  private _resizeValue(): void {
    const card = this._cardElem.nativeElement;
    const cardValue = card.querySelector('.manager-dashboard-value');

    if (cardValue !== null) {
      setTimeout(() => {
        fitty(cardValue, {
          minSize: 12,
          maxSize: 50
        }).fit();
      }, 5);
    }
  }

}
