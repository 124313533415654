import { Component, OnInit, OnDestroy, DoCheck, ViewEncapsulation, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpCoreService } from '@core/services/mp-core.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { ApiService } from '@core/services/api.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpDebounceService } from '@core/services/mp-debounce.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';
import { TcSearchParamsService } from './../../../../services/tc-search-params.service';

/**
 * This class provides the data and functions
 * for the hotel choice page.
 */
@Component({
  selector: 'mp-rk-hotel-choice',
  templateUrl: './hotel-choice.component.html',
  styleUrls: [
    './../../../styles/elements/rk-select.scss',
    './../../../styles/sites/rk-main-page-styles.scss',
    './../../../styles/sites/rk-two-column-pages.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../styles/sites/rk-browser-hacks.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class HotelChoiceComponent implements OnInit, OnDestroy, DoCheck {

  //@ts-ignore
  @ViewChild('rkOffersContainer') rkOffersContainer: ElementRef;

  public searchParams: { [key: string]: any } = {};
  public filter: { [key: string]: any } = {};
  public targetFilter: { [key: string]: any } = {};
  public openFilter: boolean = false;
  public showInnerError: boolean = false;
  public openedOffers: boolean = false;
  public travelType: number = 0;
  public hotels: Array<any> | null | undefined;
  public keepFilters: boolean = false;
  public message: string = '';
  public travelTypeEnums: { [key: string]: any } = {};
  public filterOptions: { [key: string]: any } = {};
  public filterOptionsSet: boolean = false;
  public groupings: Array<any> = [];
  public grouping: { [key: string]: any } = {};
  public sortings: Array<any> = [];
  public sorting: { [key: string]: any } = {};
  public filteredHotels: Array<any> = [];
  public visibleHotels: Array<any> = [];
  public role: string = '';
  public selectedCategory: { [key: string]: any } = {};
  public loading: boolean = false;
  public pagesize: number = 3;
  public getParamsForGetHotels = this._getParamsForGetHotels.bind(this);
  public toggleSearchFilterFunc = this.toggleSearchFilter.bind(this);
  public setOpenedOffers = this._setOpenedOffers.bind(this);

  private _loadHotels: any;
  private _groupings: { [key: string]: any } | null = null;
  private _hotelCount: number = 0;
  private _sidebarOpened: boolean = false;
  private _parameters: { [key: string]: any } = {};
  private _routeParams: { [key: string]: any } = {};
  private _initialLoadDone: boolean = false;
  private _increaseRunning: boolean = true;
  private _locsLoadedSubscription: Subscription | undefined;
  private _routeParamsSubscription: Subscription | undefined;
  private _getEnumsSubscription: Subscription | undefined;
  private _getHotelsSubscription: Subscription | undefined;
  private _showSvgLoadingSubscription: Subscription | undefined;
  private _hideSvgLoadingSubscription: Subscription | undefined;
  private _sidebarParamsChangeSubscription: Subscription | undefined;
  private _searchParamsUpdatedSubscription: Subscription | undefined;
  private _getHotelImages: Function | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    private _mpCoreService: MpCoreService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _route: ActivatedRoute,
    private _svgLoader: SvgLoaderService,
    private _mpSidebar: MpSidebarService,
    private _mpDebounce: MpDebounceService,
    private _mpEnums: MpEnumsService,
    private _customEventService: CustomEventService
  ) { }

  /**
   * Gets the search params, the travel type
   * enums, the locs, and provides the function
   * for loading hotels.
   */
  ngOnInit(): void {
    this._parameters = this._tcSearchParamsService.getParams();
    this.travelType = this._parameters['Reiseart'] || this.travelType;

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._routeParams = params;
    });

    this._loadHotels = this._mpDebounce.debounce(() => {
      if (Object.keys(this.searchParams).length === 0) {
        return;
      }

      this._svgLoader.startLoading();
      this.loading = true;
      this._tcSearchParamsService.setParamsForSearch(this.searchParams, this.travelType, this._routeParams['sid'], this.travelTypeEnums);
      this.message = '';

      if (!this.keepFilters) {
        this.hotels = null;
      }

      this._getHotelsSubscription = this._apiService.postRequest('/api/RkHotelUndFlug/GetHotels', this._getParamsForGetHotels()).subscribe((data: any) => {
        if (data.Result === 'OK') {
          const result = data.Records[0];
          this.hotels = result.Hotels;

          if (!this.keepFilters) {
            this.filterOptions = result.Filter;
            this.filterOptionsSet = true;

            this.selectedCategory = result.Filter.Reisekategorien.find((cat: any) => {
              return cat['RkKategorieId'] === parseInt(this._routeParams['k'] || '0');
            });

            this.targetFilter['ziele'] = result.Orte.map((location: any) => {
              location['Active'] = false;
              return location;
            });

            this._updateTargetFilter();
            this.groupings = result.Gruppierungen;
            this.grouping = Object.keys(this.grouping).length > 0 ? this.grouping : this.groupings[0];
            this.sortings = result.Sortierungen;
            this.sorting = Object.keys(this.sorting).length > 0 ? this.sorting : this.sortings[0];
          }

          this.updateFiltered();
        } else {
          this.hotels = [];
          this.message = data.Message;
          this.updateFiltered();

          if (!this._sidebarOpened && !this.keepFilters) {
            this._mpSidebar.open('sidebarTravelDestinationsHotel', Object.assign(this.searchParams['reisezieleHotel'], { noResults: true }));
            this._sidebarOpened = true;
          }
        }

        this._svgLoader.finishLoading();
        this.loading = false;
      },
        (errors: any) => {
          this._svgLoader.finishLoading();
          this.loading = false;
        });
    }, 10);

    this._getHotelImages = /*this._mpDebounce.debounce(*/() => {
      let hotels = this.visibleHotels;

      if (!hotels || hotels.length == 0)
        return;

      let gid = '';

      for (let hotel of hotels) {
        if ((hotel.Bilder && hotel.Bilder.length > 1) || hotel.imagesLoading)
          continue;

        hotel.imagesLoading = true;
        gid += hotel.GiataCode + ',';
      }

      if (!gid)
        return;

      let getHotelImagesSubscription = this._apiService.getRequest(`/api/RkHotelUndFlug/GetHotelImages?gid=${gid}`).subscribe(data => {
        if (data.Records && data.Records.length) {
          for (let hotel of hotels) {
            hotel.imagesLoading = false;
            let images = data.Records[0][hotel.GiataCode];
            if (images && images.length > 0) {
              for (let img of images) {
                if (img !== hotel.Bilder[0]) {
                  hotel.Bilder.push(img);
                }
              }
            }
          }
        }
        getHotelImagesSubscription.unsubscribe();
      });
    };

    if (window.innerWidth < 992) {
      setTimeout(() => {
        this._setMainContentMinHeight();
      }, 6500);
    } else {
      this._setMainContentMinHeight();
    }

    window.removeEventListener('resize', this._windowResizeListener.bind(this));
    window.addEventListener('resize', this._windowResizeListener.bind(this));

    this._showSvgLoadingSubscription = this._customEventService.on(CustomEventType.ShowSvgLoader).subscribe((event: CustomEvent<any>) => {
      setTimeout(() => {
        this.loading = true;
      });
    });

    this._hideSvgLoadingSubscription = this._customEventService.on(CustomEventType.HideSvgLoader).subscribe((event: CustomEvent<any>) => {
      setTimeout(() => {
        this.loading = false;
      });
    });

    this._searchParamsUpdatedSubscription = this._customEventService.on(CustomEventType.TcSearchParamsUpdated).subscribe((event: CustomEvent<any>) => {
      if (event.payload === 'filterChanged') {
        this._updateTargetFilter();
        this.updateFiltered();
      } else {
        this.hotels = undefined;
        this.searchParams = event.payload;
        this.keepFilters = false;
        this._loadHotels();
      }
    });

    if (Object.keys(this._mpEnums.enums).length === 0) {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.travelTypeEnums = this._mpEnums.enums['Reisetyp'];

          if (Object.keys(this.ls.locs).length > 0) {
            this._updateTargetFilter();
            this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
          } else {
            this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
              if (loaded) {
                if (Object.keys(this.ls.locs).length > 0) {
                  if (typeof this._locsLoadedSubscription !== 'undefined') {
                    this._locsLoadedSubscription.unsubscribe();
                  }
                }

                this._updateTargetFilter();
                this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
              }
            });

            this.ls.getLocalization();
          }
        }
      });

      this._mpEnums.getEnums();
    } else {
      this.travelTypeEnums = this._mpEnums.enums['Reisetyp'];

      if (Object.keys(this.ls.locs).length > 0) {
        this._updateTargetFilter();
        this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
      } else {
        this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            if (Object.keys(this.ls.locs).length > 0) {
              if (typeof this._locsLoadedSubscription !== 'undefined') {
                this._locsLoadedSubscription.unsubscribe();
              }
            }

            this._updateTargetFilter();
            this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
          }
        });

        this.ls.getLocalization();
      }
    }
  }

  /**
   * Unsiubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }

    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }

    if (typeof this._getHotelsSubscription !== 'undefined') {
      this._getHotelsSubscription.unsubscribe();
    }

    if (typeof this._showSvgLoadingSubscription !== 'undefined') {
      this._showSvgLoadingSubscription.unsubscribe();
    }

    if (typeof this._hideSvgLoadingSubscription !== 'undefined') {
      this._hideSvgLoadingSubscription.unsubscribe();
    }

    if (typeof this._sidebarParamsChangeSubscription !== 'undefined') {
      this._sidebarParamsChangeSubscription.unsubscribe();
    }

    if (typeof this._searchParamsUpdatedSubscription != 'undefined') {
      this._searchParamsUpdatedSubscription.unsubscribe();
    }
  }

  /**
   * Updates the min height of the content.
   */
  ngDoCheck(): void {
    setTimeout(() => {
      this._setMainContentMinHeight();
    }, 1900);
  }

  /**
   * Scroll listener for loading more
   * results on scroll.
   */
  @HostListener('window:scroll')
  onscroll() {
    if (this._initialLoadDone) {
      if (typeof this.rkOffersContainer !== 'undefined' && this.rkOffersContainer !== null) {
        if (this.rkOffersContainer.nativeElement.getBoundingClientRect().top + this.rkOffersContainer.nativeElement.getBoundingClientRect().height <= window.innerHeight * 1.2) {
          if (!this._increaseRunning) {
            this._increaseRunning = true;
            this.increaseFilteredHotels();
          }
        }
      }
    }
  }

  /**
   * Sets the initialLoadDone to true,
   * so scroll listener can handle
   * load on scroll.
   */
  setInitialLoadDone(): void {
    setTimeout(() => {
      this._initialLoadDone = true;
    }, 1000);
  }

  /**
   * Updates the min height of the content.
   */
  private _windowResizeListener(): void {
    setTimeout(() => {
      this._setMainContentMinHeight();
    }, 1500);
  }

  /**
   * Handles changes search params.
   */
  searchParamsChanged(paramsNew: any): void {
    if (this.ls.locs['locReisekonfigurator'] && !this.loading && (!this.hotels || !(JSON.stringify(this._omit(paramsNew, ['reisezieleHotel'])) === JSON.stringify(this._omit(this.searchParams, ['reisezieleHotel']))) ||
      !(JSON.stringify(this._omit(paramsNew['reisezieleHotel'], ['noResults'])) === JSON.stringify(this._omit(this.searchParams['reisezieleHotel'], ['noResults']))))) {
      this.hotels = undefined;
      this.searchParams = paramsNew;
      this.keepFilters = false;
      this._loadHotels();
    }
  }

  /**
   * Gets and returns the params for the
   * "GetHotels" request.
   */
  private _getParamsForGetHotels(): { [key: string]: any } {
    const params = this._tcSearchParamsService.transformParamsForSearch(this.searchParams, this.travelType, this.travelTypeEnums);

    params['Thema'] = this._routeParams['k'];
    params['Verpflegungen'] = [];
    params['Zimmerarten'] = [];

    if (Object.keys(this.filter).length > 0) {
      if (this.filter['Verpflegungen']) {
        const activeCaterings = this.filter['Verpflegungen'].filter((catering: any) => {
          return catering['Active'] === true;
        });

        if (activeCaterings && activeCaterings.length > 0) {
          params['Verpflegungen'] = activeCaterings.map((catering: any) => {
            return catering['Typ'];
          });
        }
      }

      if (this.filter['Zimmerarten']) {
        const activeRoomtypes = this.filter['Zimmerarten'].filter((roomtype: any) => {
          return roomtype['Active'] === true;
        });

        if (activeRoomtypes && activeRoomtypes.length > 0) {
          params['Zimmerarten'] = activeRoomtypes.map((roomType: any) => {
            return roomType['ZimmerartId'];
          });
        }
      }
    }

    return params;
  }

  /**
   * Updates the target filter.
   */
  private _updateTargetFilter(): void {
    this.updateFiltered();

    if (!this.targetFilter['ziele'] || this.targetFilter['ziele'].length < 2) {
      this.targetFilter['text'] = '';
      return;
    }

    const activeFilter = this.targetFilter['ziele'].filter((target: any) => {
      return target['Active'] === true;
    });

    if (activeFilter && activeFilter.length > 0) {
      this.targetFilter['text'] = activeFilter.map((filter: any) => {
        return filter['Bezeichnung']
      }).reduce((a: any, b: any) => {
        return a + ', ' + b;
      });

      const firstActiveFilter = activeFilter[0];
      const gruppierungKey = firstActiveFilter.Typ === 3 ? 'Zielgebiet' : 'Stadt';

      const foundGrouping = this.groupings.find((grouping: any) => {
        return grouping['GruppierFeld'] === gruppierungKey;
      });

      this.grouping = typeof foundGrouping !== 'undefined' ? foundGrouping : this.grouping;

      return;
    }

    this.grouping = this.groupings.length > 0 ? this.groupings[0] : {};

    const first = this.targetFilter['ziele'][0];

    if (this.ls.locs['locReisekonfigurator']) {
      if (first.Typ === 2) {
        this.targetFilter['text'] = this.ls.locs['locReisekonfigurator'].AlleOrte;
        return;
      }

      if (first.Typ === 3) {
        this.targetFilter['text'] = this.ls.locs['locReisekonfigurator'].AlleRegionen;
        return;
      }
    }

    this.targetFilter['text'] = '';
  }

  /**
   * Updates the filtered hotels.
   */
  updateFiltered(increase?: boolean): void {
    if (!increase) {
      this._hotelCount = this.pagesize;
    } else {
      this._hotelCount += this.pagesize;
    }

    let hotels = this.hotels;

    if (typeof hotels !== 'undefined' && hotels !== null) {
      hotels = hotels.filter((h: any) => {
        return (!this.filter['Hotelkategorien'] || this.filter['Hotelkategorien'].length === 0 || this.filter['Hotelkategorien'].indexOf('' + h['Kategorie']) > -1) &&
          this.filter['Punkte'] >= h.Punkte &&
          this._matchesTargetFilter(h) &&
          (!this.filter['Reisekategorien'] || this.filter['Reisekategorien'].length === 0 || this.filter['Reisekategorien'].every((t: any) => {
            return h['Themen'].map((topic: any) => {
              return topic['RkKategorieId'];
            }).indexOf(t) > -1;
          })) &&
          (!this.filter['Extras'] || this.filter['Extras'].length === 0 || this.filter['Extras'].every((t: any) => {
            return h['Themen'].map((topic: any) => {
              return topic['RkKategorieId'];
            }).indexOf(t) > -1;
          }));
      });

      this.filteredHotels = this._sortHotels(hotels);
    }

    const filteredHotelsCount = this.filteredHotels.length;
    const visibleCount = this._hotelCount > filteredHotelsCount ? filteredHotelsCount : this._hotelCount;
    this.showInnerError = filteredHotelsCount === 0 && (this.keepFilters || (typeof this.hotels !== 'undefined' && this.hotels !== null && this.hotels.length > 0));

    this.visibleHotels = this.filteredHotels.slice(0, visibleCount);
    this._groupings = null;
    this._getHotelImages?.();

    for (let i = 0; i < visibleCount; i++) {
      const showHeader = this._getShowHeader(i);
      const hotel = this.filteredHotels[i];
      hotel.showHeader = showHeader;
      this._setGroupCount(hotel);
    }

    this._setOpenedOffers();
    this._increaseRunning = false;
  }

  /**
   * Gets whether or not to show the header.
   */
  private _getShowHeader(index: number): boolean {
    var hotels = this.filteredHotels;

    if (index === 0)
      return true;

    if (!this.grouping || !this.grouping['GruppierFeld'])
      return false;

    return hotels[index][this.grouping['GruppierFeld']] !== hotels[index - 1][this.grouping['GruppierFeld']];
  }

  /**
   * Sets the group count.
   */
  private _setGroupCount(hotel: { [key: string]: any }): void {
    if (!hotel['showHeader'])
      return;

    if (!this.grouping) {
      hotel['groupCount'] = this.filteredHotels.length;
      return;
    }

    var key = this.grouping['GruppierFeld'] || 'Zielgebietscode';

    if (!this._groupings) {
      this._groupings = this.filteredHotels.reduce((r: any, v: any, i: number, a: any[], k = v[key]) => ((r[k]++ || (r[k] = [1])), r), {});
    }

    hotel['groupCount'] = (this._groupings && this._groupings[hotel[key]]) || 0;
  }

  /**
   * Sets the opened offers.
   */
  private _setOpenedOffers(): void {
    if (this.hotels) {
      this.hotels.forEach((hotel: any) => {
        if (this.visibleHotels && this.visibleHotels.indexOf(hotel) < 0) {
          hotel['open'] = false;
        }
      });
    }

    this.openedOffers = typeof this.visibleHotels.find((hotel: any) => {
      return hotel['open'];
    }) !== 'undefined' ? true : false;
  }

  /**
   * Sorts the given hotels.
   */
  private _sortHotels(hotels: Array<any>): Array<any> {
    const sortedHotels = hotels.slice().sort((a: any, b: any) => {
      if (this.grouping && this.grouping['GruppierFeld']) {
        const groupValA = a[this.grouping['GruppierFeld']];
        const groupValB = b[this.grouping['GruppierFeld']];

        var groupFaktor = this.grouping['Reverse'] ? -1 : 1;

        if (groupValA < groupValB) {
          return -1 * groupFaktor;
        } else if (groupValA > groupValB) {
          return 1 * groupFaktor;
        }
      }

      let sortValA, sortValB, sortFaktor;

      if (this.sorting && this.sorting['SortierFeld']) {
        sortValA = a[this.sorting['SortierFeld']];
        sortValB = b[this.sorting['SortierFeld']];

        sortFaktor = this.sorting['Reverse'] ? -1 : 1;
      }

      if (sortValA === sortValB) {
        sortValA = a.Punkte;
        sortValB = b.Punkte;

        sortFaktor = 1;
      }

      // @ts-ignore
      return (sortValA < sortValB ? -1 : 1) * sortFaktor;
    });

    return sortedHotels;
  }

  /**
   * Checks whether or not the hotel
   * matches the target filter.
   */
  private _matchesTargetFilter(hotel: any): boolean {
    const activeTargets = this.targetFilter['ziele'].filter((target: any) => {
      return target['Active'] === true;
    });

    let match = true;

    if (activeTargets && activeTargets.length > 0) {
      match = activeTargets.find((activeTarget: any) => {
        return (activeTarget['Typ'] === 2 && hotel['StadtCode'] === activeTarget['Code']) || (activeTarget['Typ'] === 3 && hotel['Zielgebietscode'] === activeTarget['Code']);
      });
    }

    return !activeTargets || activeTargets.legnth === 0 || (typeof match === 'undefined' ? false : match);
  }

  /**
   * creates an object composed of
   * the own and inherited enumerable
   * property paths of object that
   * are not omitted
   */
  private _omit(obj: { [key: string]: any }, props: Array<string>): { [key: string]: any } {
    obj = { ...obj };
    props.forEach(prop => delete obj[prop]);
    return obj;
  }

  /**
   * Triggers the global goBack function.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Toggles the search filter.
   */
  toggleSearchFilter(evt: MouseEvent): void {
    evt.preventDefault();
    this.openFilter = !this.openFilter;
    document.body.setAttribute('openfilter', `${this.openFilter}`);
  }

  /**
   * Sets the min height of the content.
   */
  private _setMainContentMinHeight(): void {
    const mainContent = document.getElementById('main-content');
    const footer = document.getElementById('footer');
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');

    if (mainContent !== null && footer !== null && navbar !== null && header !== null) {
      mainContent.style.minHeight = `calc(100vh - ${footer.getBoundingClientRect().height + navbar.getBoundingClientRect().height + parseFloat(getComputedStyle(navbar).getPropertyValue('margin-bottom').replace('px', '')) + header.getBoundingClientRect().height}px)`;
    }
  };

  /**
   * Loads additional hotels on scroll.
   */
  increaseFilteredHotels(): void {
    this.updateFiltered(true);
  }

  /**
   * Triggers filtering, after filter changed.
   */
  filterChanged(init: boolean): void {
    if (!init) {
      this.keepFilters = false;
      this.updateFiltered();
    } else {
      this.keepFilters = true;
      this._loadHotels();
    }
  }

  /**
   * Used for trackBy within ngFor.
   */
  trackByHotelCode(index: number, item: any): number {
    return item.HotelCode;
  }

}
