import { Component, OnInit, OnDestroy, DoCheck, ViewEncapsulation, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpCoreService } from '@core/services/mp-core.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { ApiService } from '@core/services/api.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpDebounceService } from '@core/services/mp-debounce.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';
import { TcSearchParamsService } from './../../../../services/tc-search-params.service';

/**
 * This class provides the data and functions
 * for the flight choice page.
 */
@Component({
  selector: 'mp-rk-flight-choice',
  templateUrl: './flight-choice.component.html',
  styleUrls: [
    './../../../styles/elements/rk-select.scss',
    './../../../styles/sites/rk-main-page-styles.scss',
    './../../../styles/sites/rk-two-column-pages.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../styles/sites/rk-browser-hacks.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class FlightChoiceComponent implements OnInit, OnDestroy, DoCheck {

  //@ts-ignore
  @ViewChild('rkOffersContainer') rkOffersContainer: ElementRef;

  public searchParams: { [key: string]: any } = {};
  public filter: { [key: string]: any } = {};
  public targetFilter: { [key: string]: any } = {};
  public openFilter: boolean = false;
  public role: string = '';
  public travelType: number = 0;
  public flights: Array<any> | null | undefined;
  public keepFilters: boolean = false;
  public message: string = '';
  public openedOffers: boolean = false;
  public filteredFlights: Array<any> = [];
  public visibleFlights: Array<any> = [];
  public loading: boolean = false;
  public filterOptions: { [key: string]: any } = {};
  public filterOptionsSet: boolean = false;
  public groupings: Array<any> = [];
  public grouping: { [key: string]: any } = {};
  public sortings: Array<any> = [];
  public sorting: { [key: string]: any } = {};
  public travelTypeEnums: { [key: string]: any } = {};
  public airportOfDeparture: string = '';
  public destinationAirport: string = '';
  public searchId: string = '';
  public pagesize: number = 3;
  public toggleSearchFilterFunc = this.toggleSearchFilter.bind(this);
  public setOpenedOffers = this._setOpenedOffers.bind(this);
  public calculateDuration = this._calculateDuration.bind(this);

  private _loadFlights: any;
  private _parameters: { [key: string]: any } = {};
  private _routeParams: { [key: string]: any } = {};
  private _flightCount: number = 0;
  private _groupings: { [key: string]: any } | null = null;
  private _sidebarOpened: boolean = false;
  private _initialLoadDone: boolean = false;
  private _increaseRunning: boolean = true;
  private _routeParamsSubscription: Subscription | undefined;
  private _showSvgLoadingSubscription: Subscription | undefined;
  private _hideSvgLoadingSubscription: Subscription | undefined;
  private _sidebarParamsChangeSubscription: Subscription | undefined;
  private _searchParamsUpdatedSubscription: Subscription | undefined;
  private _locsLoadedSubscription: Subscription | undefined;
  private _getEnumsSubscription: Subscription | undefined;
  private _getFlightsSubscription: Subscription | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    private _mpCoreService: MpCoreService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _route: ActivatedRoute,
    private _svgLoader: SvgLoaderService,
    private _mpSidebar: MpSidebarService,
    private _mpDebounce: MpDebounceService,
    private _mpEnums: MpEnumsService,
    private _customEventService: CustomEventService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._parameters = this._tcSearchParamsService.getParams();
    this.travelType = this._parameters['Reiseart'] || this.travelType;

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._routeParams = params;

      if (typeof this._routeParams['sid'] !== 'undefined') {
        this.searchId = this._routeParams['sid'];
      }
    });

    this._loadFlights = this._mpDebounce.debounce(() => {
      if (Object.keys(this.searchParams).length === 0) {
        return;
      }

      this._svgLoader.startLoading();
      this.loading = true;
      this._tcSearchParamsService.setParamsForSearch(this.searchParams, this.travelType, this._routeParams['sid'], this.travelTypeEnums);
      this.message = '';

      if (!this.keepFilters) {
        this.flights = null;
      }

      this._getFlightsSubscription = this._apiService.postRequest('/api/RkNurFlug/GetFluege', this._tcSearchParamsService.transformParamsForSearch(this.searchParams, this.travelType, this.travelTypeEnums)).subscribe((data: any) => {
        if (data.Result === 'OK') {
          const result = data.Records[0];
          this.flights = result.Fluege;

          if (typeof this.flights !== 'undefined' && this.flights !== null) {
            this.flights.forEach((flight: any) => {
              flight['SelectedHinflug'] = flight['Hinfluege'][0];
              flight['SelectedRueckflug'] = flight['Rueckfluege'][0];
              flight['showDetails'] = false;
              this._calculateDuration(flight);
            });
          }
         
          this.filterOptions = result.Filter;
          this.sortings = result.Sortierungen;
          this.sorting = result.Sortierungen[0];
          this.groupings = result.Gruppierungen;
          this.grouping = result.Gruppierungen[0];
          this.targetFilter['ziele'] = result.Ziele;
          this.airportOfDeparture = result.Abflughaefen;
          this.destinationAirport = result.Zielflughaefen;
          this.filterOptionsSet = true;
          this.updateFiltered();
        } else {
          this.flights = [];
          this.message = data.Message;
          this.updateFiltered();

          if (!this._sidebarOpened && !this.keepFilters) {
            this._mpSidebar.open('sidebarAirport', Object.assign(this.searchParams['flugZielflughafen'], { noResults: true }));
            this._sidebarOpened = true;
          }
        }

        this._svgLoader.finishLoading();
        this.loading = false;
      },
      (errors: any) => {
        this._svgLoader.finishLoading();
        this.loading = false;
        this._router.navigateByUrl(`#/${this.role}/Reisekonfigurator`);
      });
    }, 20);

    if (window.innerWidth < 992) {
      setTimeout(() => {
        this._setMainContentMinHeight();
      }, 6500);
    } else {
      this._setMainContentMinHeight();
    }

    window.removeEventListener('resize', this._windowResizeListener.bind(this));
    window.addEventListener('resize', this._windowResizeListener.bind(this));

    this._showSvgLoadingSubscription = this._customEventService.on(CustomEventType.ShowSvgLoader).subscribe((event: CustomEvent<any>) => {
      setTimeout(() => {
        this.loading = true;
      });
    });

    this._hideSvgLoadingSubscription = this._customEventService.on(CustomEventType.HideSvgLoader).subscribe((event: CustomEvent<any>) => {
      setTimeout(() => {
        this.loading = false;
      });
    });

    this._searchParamsUpdatedSubscription = this._customEventService.on(CustomEventType.TcSearchParamsUpdated).subscribe((event: CustomEvent<any>) => {
      if (event.payload === 'filterChanged') {
        this._updateTargetFilter();
        this.updateFiltered();
      } else {
        this.flights = undefined;
        this.searchParams = event.payload;
        this.keepFilters = false;
        this._loadFlights();
      }
    });

    if (Object.keys(this._mpEnums.enums).length === 0) {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.travelTypeEnums = this._mpEnums.enums['Reisetyp'];

          if (Object.keys(this.ls.locs).length > 0) {
            this._updateTargetFilter();
            this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
          } else {
            this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
              if (loaded) {
                if (Object.keys(this.ls.locs).length > 0) {
                  if (typeof this._locsLoadedSubscription !== 'undefined') {
                    this._locsLoadedSubscription.unsubscribe();
                  }
                }

                this._updateTargetFilter();
                this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
              }
            });

            this.ls.getLocalization();
          }
        }
      });

      this._mpEnums.getEnums();
    } else {
      this.travelTypeEnums = this._mpEnums.enums['Reisetyp'];

      if (Object.keys(this.ls.locs).length > 0) {
        this._updateTargetFilter();
        this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
      } else {
        this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            if (Object.keys(this.ls.locs).length > 0) {
              if (typeof this._locsLoadedSubscription !== 'undefined') {
                this._locsLoadedSubscription.unsubscribe();
              }
            }

            this._updateTargetFilter();
            this.searchParamsChanged(this._tcSearchParamsService.getParamsForSearchArea(this._parameters, this.travelType, this.travelTypeEnums));
          }
        });

        this.ls.getLocalization();
      }
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }

    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }

    if (typeof this._getFlightsSubscription !== 'undefined') {
      this._getFlightsSubscription.unsubscribe();
    }

    if (typeof this._showSvgLoadingSubscription !== 'undefined') {
      this._showSvgLoadingSubscription.unsubscribe();
    }

    if (typeof this._hideSvgLoadingSubscription !== 'undefined') {
      this._hideSvgLoadingSubscription.unsubscribe();
    }

    if (typeof this._sidebarParamsChangeSubscription !== 'undefined') {
      this._sidebarParamsChangeSubscription.unsubscribe();
    }

    if (typeof this._searchParamsUpdatedSubscription != 'undefined') {
      this._searchParamsUpdatedSubscription.unsubscribe();
    }
  }

  /**
   * Updates the min height of the content.
   */
  ngDoCheck(): void {
    setTimeout(() => {
      this._setMainContentMinHeight();
    }, 1900);
  }

  /**
   * Scroll listener for loading more
   * results on scroll.
   */
  @HostListener('window:scroll')
  onscroll() {
    if (this._initialLoadDone) {
      if (typeof this.rkOffersContainer !== 'undefined' && this.rkOffersContainer !== null) {
        if (this.rkOffersContainer.nativeElement.getBoundingClientRect().top + this.rkOffersContainer.nativeElement.getBoundingClientRect().height <= window.innerHeight * 1.2) {
          if (!this._increaseRunning) {
            this._increaseRunning = true;
            this.increaseFilteredFlights();
          }
        }
      }
    }
  }

  /**
   * Sets the initialLoadDone to true,
   * so scroll listener can handle
   * load on scroll.
   */
  setInitialLoadDone(): void {
    setTimeout(() => {
      this._initialLoadDone = true;
    }, 1000);
  }

  /**
   * Updates the min height of the content.
   */
  private _windowResizeListener(): void {
    setTimeout(() => {
      this._setMainContentMinHeight();
    }, 1500);
  }

  /**
   * Calculates the flight duration.
   */
  private _calculateDuration(flight: any): void {
    const to = flight['SelectedHinflug'];
    const back = flight['SelectedRueckflug'];

    if (to && back) {
      flight['Dauer'] = moment(back['Abflugzeit']).startOf('day').diff(moment(to['Ankunftzeit']).startOf('day'), 'day');
    }
  }
  
  /**
   * Updates the target filter.
   */
  private _updateTargetFilter(): void {
    this.updateFiltered();

    if (!this.targetFilter['ziele'] || this.targetFilter['ziele'].length < 2) {
      this.targetFilter['text'] = '';
      return;
    }

    const activeFilter = this.targetFilter['ziele'].filter((target: any) => {
      return target['Active'] === true;
    });

    if (activeFilter && activeFilter.length > 0) {
      this.targetFilter['text'] = activeFilter.map((filter: any) => {
        return filter['Bezeichnung']
      }).reduce((a: any, b: any) => {
        return a + ', ' + b;
      });

      const foundGrouping = this.groupings.find((grouping: any) => {
        return grouping['GruppierFeld'] === 'Flughaefen';
      });

      this.grouping = typeof foundGrouping !== 'undefined' ? foundGrouping : this.grouping;

      return;
    }

    this.grouping = this.groupings.length > 0 ? this.groupings[0] : {};

    if (this.ls.locs['locReisekonfigurator']) {
      this.targetFilter['text'] = this.ls.locs['locReisekonfigurator'].AlleZielflughaefen;
      return;
    }

    this.targetFilter['text'] = '';
  }

  /**
   * Updates the filtered flights.
   */
  updateFiltered(increase?: boolean): void {
    if (!increase) {
      this._flightCount = this.pagesize;
    } else {
      this._flightCount += this.pagesize;
    }

    let flights = this.flights;

    if (typeof flights !== 'undefined' && flights !== null && Object.keys(this.filter).length > 0) {
      flights = flights.filter((flight: any) => {
        const filteredOutboundFlights = flight['Hinfluege'].filter((f: any) => {
          return ((!this.filter['Zwischenstopps'] && this.filter['Zwischenstopps'] !== 0) ||
            f['AnzahlStops'] <= this.filter['Zwischenstopps']) &&
            (!this.filter['HinflugRange'] ||
              f['AbflugUhrzeit'] <= this.filter['HinflugRange'][1] &&
              f['AbflugUhrzeit'] >= this.filter['HinflugRange'][0]) &&
            (!this.targetFilter['ziele'] ||
              typeof this.targetFilter['ziele'].find((z: any) => { return z['Active']; }) === 'undefined' ||
              this.targetFilter['ziele'].filter((tz: any) => { return tz['Active'] === true }).find((z: any) => { return z['Kuerzel'] === f['Ankunftflughafen'].Kuerzel })) &&
            (!this.filter['Klassen'] ||
              this.filter['Klassen'].length === 0 ||
              this.filter['Klassen'].indexOf(f['Klasse']) > -1);
        });

        flight['filteredHinfluege'] = filteredOutboundFlights.reduce((r: any, v: any, i: number, a: any[], k: any = v['Airline'].IATACode) => ((r[k] || (r[k] = [])).push(v), r), {});

        if (filteredOutboundFlights.indexOf(flight['SelectedHinflug']) < 0) {
          if (filteredOutboundFlights.length > 0) {
            flight['SelectedHinflug'] = filteredOutboundFlights[0];
          } else {
            flight['open'] = false;
          }
        }

        let filteredInwardFlights = [];
        flight['filteredRueckfluege'] = {};

        if (flight['Rueckfluege'] && flight['Rueckfluege'].length > 0) {
          filteredInwardFlights = flight['Rueckfluege'].filter((f: any) => {
            return ((!this.filter['Zwischenstopps'] && this.filter['Zwischenstopps'] !== 0) ||
              f['AnzahlStops'] <= this.filter['Zwischenstopps']) &&
              (!this.filter['RueckflugRange'] ||
                f['AbflugUhrzeit'] <= this.filter['RueckflugRange'][1] &&
                f['AbflugUhrzeit'] >= this.filter['RueckflugRange'][0]) &&
              (!this.targetFilter['ziele'] ||
                typeof this.targetFilter['ziele'].find((z: any) => { return z['Active']; }) === 'undefined' ||
                this.targetFilter['ziele'].filter((tz: any) => { return tz['Active'] === true }).find((z: any) => { return z['Kuerzel'] === f['Abflughafen'].Kuerzel })) &&
              (!this.filter['Klassen'] ||
                this.filter['Klassen'].length === 0 ||
                this.filter['Klassen'].indexOf(f['Klasse']) > -1);
          });

          flight['filteredRueckfluege'] = filteredInwardFlights.reduce((r: any, v: any, i: number, a: any[], k: any = v['Airline'].IATACode) => ((r[k] || (r[k] = [])).push(v), r), {});

          if (filteredInwardFlights.indexOf(flight['SelectedRueckflug']) < 0) {
            if (filteredInwardFlights.length > 0) {
              flight['SelectedRueckflug'] = filteredInwardFlights[0];
            } else {
              flight['open'] = false;
            }
          }
        }

        return flight['Punkte'] <= this.filter['Punkte'] &&
          Object.keys(flight['filteredHinfluege']).length > 0 &&
          (!flight['Rueckfluege'] || flight['Rueckfluege'].length === 0 || Object.keys(flight['filteredRueckfluege']).length > 0) &&
          (!this.filter['Zielflughafen'] || flight['AnkunftflughafenDisplay'] === this.filter['Zielflughafen']) &&
          (!this.filter['Airlines'] ||
            this.filter['Airlines'].length === 0 ||
            typeof filteredOutboundFlights.find((f: any) => {
              return this.filter['Airlines'].indexOf(f['Airline'].Code) > -1;
            }) !== 'undefined' ||
            typeof filteredInwardFlights.find((f: any) => {
              return this.filter['Airlines'].indexOf(f['Airline'].Code) > -1;
            }) !== 'undefined'
          ) &&
          (!this.filter['Airplanes'] ||
            this.filter['Airplanes'].length === 0 ||
            typeof typeof filteredOutboundFlights.find((h: any) => {
              return typeof h['Abschnitte'].find((a: any) => {
                return typeof this.filter['Airplanes'].find((ap: any) => {
                  return a['Flugzeug'] && a['Flugzeug'].IataCode === ap;
                }) !== 'undefined';
              }) !== 'undefined';
            }) !== 'undefined' ||
            typeof typeof filteredInwardFlights.find((h: any) => {
              return typeof h['Abschnitte'].find((a: any) => {
                return typeof this.filter['Airplanes'].find((ap: any) => {
                  return a['Flugzeug'] && a['Flugzeug'].IataCode === ap;
                }) !== 'undefined';
              }) !== 'undefined';
            }) !== 'undefined'
          );
      });

      this.filteredFlights = this._sortFlights(flights);
    }

    const filteredFlightsCount = this.filteredFlights.length;
    const visibleCount = this._flightCount > filteredFlightsCount ? filteredFlightsCount : this._flightCount;

    this.visibleFlights = this.filteredFlights.slice(0, visibleCount);
    this._groupings = null;

    for (let i = 0; i < visibleCount; i++) {
      const showHeader = this._getShowHeader(i);
      const flight = this.filteredFlights[i];
      flight.showHeader = showHeader;
      this._setGroupCount(flight);
    }

    this._setOpenedOffers();
    this._increaseRunning = false;
  }

  /**
   * Handles changes search params.
   */
  searchParamsChanged(paramsNew: any): void {
    if (this.ls.locs['locReisekonfigurator'] && !this.loading && (!this.flights || !(JSON.stringify(this._omit(paramsNew, ['flugZielflughafen'])) === JSON.stringify(this._omit(this.searchParams, ['flugZielflughafen']))) ||
      !(JSON.stringify(this._omit(paramsNew['flugZielflughafen'], ['noResults'])) === JSON.stringify(this._omit(this.searchParams['flugZielflughafen'], ['noResults']))))) {
      this.flights = undefined;
      this.searchParams = paramsNew;
      this.keepFilters = false;
      this._loadFlights();
    }
  }

  /**
   * creates an object composed of
   * the own and inherited enumerable
   * property paths of object that
   * are not omitted
   */
  private _omit(obj: { [key: string]: any }, props: Array<string>): { [key: string]: any } {
    obj = { ...obj };
    props.forEach(prop => delete obj[prop]);
    return obj;
  }

  /**
   * Gets whether or not to show the header.
   */
  private _getShowHeader(index: number): boolean {
    var flights = this.filteredFlights;

    if (index === 0)
      return true;

    if (!this.grouping || !this.grouping['GruppierFeld'])
      return false;

    return flights[index][this.grouping['GruppierFeld']] !== flights[index - 1][this.grouping['GruppierFeld']];
  }

  /**
   * Sets the group count.
   */
  private _setGroupCount(flight: { [key: string]: any }): void {
    if (!flight['showHeader'])
      return;

    if (!this.grouping) {
      flight['groupCount'] = this.filteredFlights.length;
      return;
    }

    var key = this.grouping['GruppierFeld'] || 'Zielgebietscode';

    if (!this._groupings) {
      this._groupings = this.filteredFlights.reduce((r: any, v: any, i: number, a: any[], k = v[key]) => ((r[k]++ || (r[k] = [1])), r), {});
    }

    flight['groupCount'] = (this._groupings && this._groupings[flight[key]]) || 0;
  }

  /**
   * Sorts the given flights.
   */
  private _sortFlights(flights: Array<any>): Array<any> {
    const sortedFlights = flights.slice().sort((a: any, b: any) => {
      if (this.grouping && this.grouping['GruppierFeld']) {
        const groupValA = a[this.grouping['GruppierFeld']];
        const groupValB = b[this.grouping['GruppierFeld']];

        var groupFaktor = this.grouping['Reverse'] ? -1 : 1;

        if (groupValA < groupValB) {
          return -1 * groupFaktor;
        } else if (groupValA > groupValB) {
          return 1 * groupFaktor;
        }
      }

      let sortValA, sortValB, sortFaktor;

      if (this.sorting && this.sorting['SortierFeld']) {
        sortValA = a[this.sorting['SortierFeld']];
        sortValB = b[this.sorting['SortierFeld']];

        sortFaktor = this.sorting['Reverse'] ? -1 : 1;
      }

      if (sortValA === sortValB) {
        sortValA = a.Punkte;
        sortValB = b.Punkte;

        sortFaktor = 1;
      }

      // @ts-ignore
      return (sortValA < sortValB ? -1 : 1) * sortFaktor;
    });

    return sortedFlights;
  }

  /**
   * Sets the opened offers.
   */
  private _setOpenedOffers(): void {
    if (this.flights) {
      this.flights.forEach((flight: any) => {
        if (this.visibleFlights && this.visibleFlights.indexOf(flight) < 0) {
          flight['open'] = false;
        }
      });
    }

    this.openedOffers = typeof this.visibleFlights.find((flight: any) => {
      return flight['open'];
    }) !== 'undefined' ? true : false;
  }

  /**
   * Toggles the search filter.
   */
  toggleSearchFilter(evt: MouseEvent): void {
    evt.preventDefault();
    this.openFilter = !this.openFilter;
    document.body.setAttribute('openfilter', `${this.openFilter}`);
  }

  /**
   * Triggers the global goBack function.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Loads additional Flights on scroll.
   */
  increaseFilteredFlights(): void {
    this.updateFiltered(true);
  }

  /**
   * Triggers filtering, after filter changed.
   */
  filterChanged(init: boolean): void {
    if (!init) {
      this.keepFilters = false;
      this.updateFiltered();
    } else {
      this.keepFilters = true;
      this._loadFlights();
    }
  }

  /**
   * Sets the min height of the content.
   */
  private _setMainContentMinHeight(): void {
    const mainContent = document.getElementById('main-content');
    const footer = document.getElementById('footer');
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');

    if (mainContent !== null && footer !== null && navbar !== null && header !== null) {
      mainContent.style.minHeight = `calc(100vh - ${footer.getBoundingClientRect().height + navbar.getBoundingClientRect().height + parseFloat(getComputedStyle(navbar).getPropertyValue('margin-bottom').replace('px', '')) + header.getBoundingClientRect().height}px)`;
    }
  };

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
