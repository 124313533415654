import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';
import { ParticipantCockpitService } from './../participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the confirm account 
 * change table.
 */
@Component({
  selector: 'mp-core-confirm-account-change',
  templateUrl: './confirm-account-change.component.html'
})
export class ConfirmAccountChangeComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Kontowechsel/KontowechselAntraege2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'antragGenehmigen',
      func: this.confirmRequest.bind(this)
    },
    {
      backendName: 'antragAblehnen',
      func: this.refuseRequest.bind(this)
    }
  ];

  private _confirmAccountChangeLoader: MpLoader = {
    name: 'confirmAccountChangeLoader',
    params: {}
  };
  private _sendEdittedRequestSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions. Gets the route param for the
   * search.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('KontowechselGenehmigen', 'loc');
    this._mpMenuHighlight.setMenuHighlight('verwaltung');
    this._mpLoaderService.registerLoader(this._confirmAccountChangeLoader);

    this._mpLoaderService.extendLoader('confirmAccountChangeLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sendEdittedRequestSubscription !== 'undefined') {
      this._sendEdittedRequestSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('confirmAccountChangeLoader');
  }

  /**
   * Sends the editted (confirm / refuse)
   * request to backend.
   */
  private _editRequest(request: any): void {
    this._sendEdittedRequestSubscription = this._apiService.postRequest('/api/Kontowechsel/KontowechselGenehmigen', request).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openSuccessPanel(data.Message);

        if (this.loader !== null) {
          if (typeof this.loader.load !== 'undefined') {
            this.loader.load();
          }
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Handles the confirm of an account
   * change request.
   */
  confirmRequest(row?: any, column?: any): void {
    row['Status'] = 1;
    this._editRequest(row);
  }

  /**
   * Handles the refuse of an account
   * change request.
   */
  refuseRequest(row?: any, column?: any): void {
    row['Status'] = -1;
    this._editRequest(row);
  }

}
