<div class="popover-container display-inline-block" (window:resize)="handleShowAsPopup()">
  <div class="popover-hover-element" (click)="openPopover()">
    <ng-content></ng-content>
  </div>
  <div class="popover" [ngClass]="showPopover ? 'popup-popover' : alignment" (clickOutside)="closePopover()" click-outside-except=".popover, .popoverTitle, .popover-content, .popover-hover-element, .radio-button-container">
    <div class="popover-title" [hidden]="popoverTitle === ''" [innerText]="popoverTitle"></div>
    <div class="popover-content" [innerHTML]="content"></div>
  </div>
  <div class="mobile-shadow" (click)="closePopover()" [hidden]="!showAsPopup || !showPopover"></div>
</div>
