import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This class provides the services
 * for the image video carousel.
 */
@Injectable({
  providedIn: 'root'
})
export class ImageCarouselService {

  private _pin: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public pinObserver: Observable<string> = this._pin.asObservable();
  public pin: string = '';

  constructor() { }

  /**
   * Set the pinned (last / currently
   * shown) image or video in the
   * slider.
   */
  setPin(pin: string): void {
    this.pin = pin;
    this._pin.next(pin);
  }
}
