<div id="sidebar-multiselect" class="sidebar-form"
     [ngClass]="{noResultsMessage: noResults}" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="text-align-left"
         [innerText]="mpSidebar.params['title']">
    </div>
    <div class="divider margin-bottom-8 margin-top-8"></div>
    <div [hidden]="mpSidebar.params['all']">
      <div class="overflow-hidden">
        <mp-core-search mpId="multiselect-suche"
                        [model]="mpSidebar.params['searchTerm']"
                        (modelChange)="mpSidebar.params['searchTerm'] = $event; search()"
                        class="sb-search padding-left-right-16 padding-top-bottom-12"
                        mpPlaceholder="{{ls.locs['loc'].Suche}}">
          <i class="position-absolute-top-right svg-icon __clr-default __wide __top-0 __search akf-sidebar-search-icon"></i>
          <i *ngIf="mpSidebar.params['searchTerm'] && mpSidebar.params['searchTerm'].length > 2"
             class="svg-icon __clr-default __size-18 __top-8 __clear position-absolute-top-right margin-right-40 margin-top-16"
             (click)="clearSearch($event)">
          </i>
        </mp-core-search>
      </div>
    </div>
    <div class="padding-bottom-8 padding-left-0 padding-right-0 width-100-pcnt bg-color-white z-index-3"
         [hidden]="!mpSidebar.params['all']">
      <div id="overlay-buttons">
        <a href=""
           (click)="selectAll(false, $event)"
           class="mp-button _prim display-block">
          {{ ls.locs['loc'].AlleEnfternen }}
        </a>
      </div>
    </div>
    <div class="padding-bottom-8 padding-left-0 padding-right-0 width-100-pcnt bg-color-white z-index-3"
         [hidden]="mpSidebar.params['all'] || mpSidebar.params['limit']">
      <div id="overlay-buttons">
        <a href=""
           (click)="selectAll(true, $event)"
           class="mp-button _prim display-block">
          {{ ls.locs['loc'].AlleAuswaehlen }}
        </a>
      </div>
    </div>
    <div *ngIf="selectedList && selectedList.length > 0 && !showAllSelectedElements"
         class="padding-16"
         id="selected-elements">
      <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['loc'].Ausgewaehlt }}:</h3>
      <div>
        <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open"
             *ngFor="let item of selectedList.slice(0, 4)">
          <span class="icon-text"
                [innerHTML]="item.Displaytext">
          </span>
          <span class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8"
                (click)="setItem(item)">
          </span>
        </div>
        <button class="mp-button"
                [hidden]="selectedList.length <= 4"
                (click)="toggleShowAllSelectedElements()"
                mpCoreScrollButtonRerenderer
                data-scroll-area="#multiselect-scroll-area">
          {{ ls.locs['loc'].AlleAnzeigen }}
        </button>
      </div>
    </div>
    <div [hidden]="showResults || mpSidebar.params['all']"
         class="padding-16">
      <div>{{ ls.locs['loc'].SuchbegrifflaengeHinweis }}</div>
    </div>
    <mp-core-info-notification *ngIf="noResults && !mpSidebar.params['all']"
                               iconPosition="left"
                               infoState="warning"
                               headline="{{ ls.locs['loc'].KeinErgebnisse }}"
                               copyText="{{ ls.locs['loc'].KeineErgebnisseText }}"
                               class="padding-left-right-16">
    </mp-core-info-notification>
    <mp-core-scroll-button id="overlay-scroll-up"
                           [ifNeeded]="true"
                           scrollItemHeight="80"
                           scrollArea="#multiselect-scroll-area"
                           scrollDirection="up"
                           scrollFactor="1"
                           [scopeObject]="selectedList"
                           [secondScopeObject]="filteredList"
                           [thirdScopeObject]="showAllSelectedElements"
                           *ngIf="showResults"
                           class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="multiselect-scroll-area-container"
         *ngIf="showResults">

      <div id="multiselect-scroll-area"
           class="sidebar-scroll-area position-relative"
           [ngClass]="{'__clr-inactive': mpSidebar.params['limit'] && selectedList && selectedList.length >= mpSidebar.params['limit']}">
        <span class="display-flex margin-bottom-8 sidebar-open __multiselect-entry"
              [hidden]="selectedList && selectedList.length > 0 && showAllSelectedElements"
              *ngFor="let item of filteredList"
              (click)="setItem(item)">
          <span class="svg-icon __clr-info __wide-26 __top--2 margin-right-4" [ngClass]="item.sbIcon && item.sbIcon !== null ? item.sbIcon : '__person'"></span>
          <span mpCoreHighlight
                #highlightDirective="mpCoreHighlight"
                highlight="{{ mpSidebar.params['searchTerm'] }}"
                originalText="{{ item.Displaytext }}"
                [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : item.Displaytext"
                class="pointer-hover"
                [ngClass]="{'text-color-grey-info': mpSidebar.params['limit'] && selectedList && selectedList.length >= mpSidebar.params['limit']}">
          </span>
        </span>
        <span [hidden]="!selectedList || selectedList && selectedList.length === 0 || !showAllSelectedElements">
          <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['loc'].Ausgewaehlt }}:</h3>
          <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open"
               *ngFor="let item of selectedList">
            <span class="icon-text"
                  [innerHTML]="item.Displaytext">
            </span>
            <span class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8"
                  (click)="setItem(item)">
            </span>
          </div>
          <button class="mp-button"
                  (click)="toggleShowAllSelectedElements()"
                  mpCoreScrollButtonRerenderer
                  data-scroll-area="#multiselect-scroll-area">
            {{ ls.locs['loc'].AuswahlOeffnen }}
          </button>
        </span>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                           [ifNeeded]="true"
                           scrollItemHeight="80"
                           scrollArea="#multiselect-scroll-area"
                           scrollDirection="down"
                           scrollFactor="1"
                           [scopeObject]="selectedList"
                           [secondScopeObject]="filteredList"
                           [thirdScopeObject]="showAllSelectedElements"
                           *ngIf="showResults"
                           class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}
      </a>
    </div>
  </div>
</div>
