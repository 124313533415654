import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

/**
 * This class provides functions to load
 * a next page (= set of data records);
 */
@Injectable({
  providedIn: 'root'
})
export class MpPageLoadService {

  private _scope: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public scope: any;
  public scopeObserve: Observable<boolean> = this._scope.asObservable();

  constructor(private _apiService: ApiService) { }

  /**
   * Resets the currently loaded data.
   */
  reset(scope: any, dataArrayName: string): void {
    if (typeof scope[dataArrayName] !== 'undefined') {
      scope[dataArrayName] = undefined;
    }

    scope.startindex = 0;
    scope.totalrecordcount = -1;
    this.scope = scope;
    this._scope.next(true);
  }

  /**
   * Loads the next page (set of data).
   */
  loadNext(scope: any, dataArrayName: string, apiPath: string, params: any, callback: Function): void {
    if (scope.totalrecordcount > scope.startindex || scope.totalrecordcount === -1) {
      let postParams = { Startindex: scope.startindex, Pagesize: scope.pagesize };
      postParams = Object.assign(postParams, params);
      const vlStartindex = scope.startindex;

      this._apiService.postRequest(apiPath, postParams).subscribe((data: any) => {
        scope.totalrecordcount = data.TotalRecordCount;

        if (!scope[dataArrayName] || scope[dataArrayName].length === 0) {
          scope[dataArrayName] = data.Records;
        } else {
          data.Records.forEach((element: any, index: number) => {
            scope[dataArrayName][index + vlStartindex] = data.Records[index];
          });
        }

        scope.startindex += scope.pagesize;
        this.scope = scope;

        if (callback) {
          callback(data);
        }

        this._scope.next(true);
      },
      (error: any) => {
        if (callback) {
          callback(error);
        }
      });
    }
  }
}
