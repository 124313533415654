import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { ApiService } from './../../../../services/api.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';

/**
 * This class provides the data and the
 * functions to change the order of theme
 * sages within the stage slider.
 */
@Component({
  selector: 'mp-core-theme-stage-slider-management',
  templateUrl: './theme-stage-slider-management.component.html',
  styleUrls: ['./theme-stage-slider-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeStageSliderManagementComponent implements OnInit, OnDestroy {

  public visibleStages: Array<any> = [];
  public hints: { [key: string]: any } = {
    showSaveThemesStage: false,
    showRevertChanges: false
  };
  public admin: boolean = false;
  public groups: Array<any> = [];
  public group: { [key: string]: any } = {};
  public groupsLoaded: boolean = false;
  public orderByUserGroups: boolean = false;
  public hideHint = this._hideHint.bind(this);
  public saveFunc = this.save.bind(this);
  public loadDataFunc = this.loadData.bind(this);
  public themestagearea: { [key: string]: any } = {};
  public typeStageAreaParams: Array<any> = [];

  private _stages: Array<any> = [];
  private _getPermissionsSubscription: Subscription | undefined;
  private _loadDataSubscription: Subscription | undefined;
  private _getGroupsForSliderManagementSubscription: Subscription | undefined;
  private _getThemeStageAreasForSliderManagementSubscription: Subscription | undefined;
  private _saveSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpPermissions: MpPermissionsService,
    private _apiService: ApiService,
    private _mpCoreService: MpCoreService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the permissions and the
   * data for the theme stage
   * slider management.
   */
  ngOnInit(): void {
    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.admin = this._mpPermissions.permissions.IsAdmin;
      }
    });

    this._mpPermissions.getPermissions();
    this.loadData();

    this._getGroupsForSliderManagementSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetGruppenForSliderVerwaltung').subscribe((data: any) => {
      this.groups = data.Records;
      this.group = this.groups[0];
      this._updateVisibleStages();
      this.groupsLoaded = true;
    });
        
    this._getThemeStageAreasForSliderManagementSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetThemeStageAreas').subscribe((data: any) => {
      this.typeStageAreaParams = data.Records;
      this.themestagearea = this.typeStageAreaParams[0];
      this._updateVisibleStages();
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._loadDataSubscription !== 'undefined') {
      this._loadDataSubscription.unsubscribe();
    }

    if (typeof this._getGroupsForSliderManagementSubscription !== 'undefined') {
      this._getGroupsForSliderManagementSubscription.unsubscribe();
    }

    if (typeof this._getThemeStageAreasForSliderManagementSubscription !== 'undefined') {
      this._getThemeStageAreasForSliderManagementSubscription.unsubscribe();
    }

    if (typeof this._saveSubscription !== 'undefined') {
      this._saveSubscription.unsubscribe();
    }
  }

  /**
   * Updates the visible stages.
   */
  private _updateVisibleStages(): void {
    if (this._stages.length > 0) {
      let stagelist = (Object.keys(this.groups).length === 0 || Object.keys(this.groups).length > 0 && typeof this.group['GruppenID'] === 'undefined' || !this.orderByUserGroups)
        ? this._stages.filter(
          (b: any) => {
            return b.Titel.indexOf('Geburtstag') === -1 && b.ThemeStageArea === this.themestagearea['Displaytext'];
          })
        : this._stages.filter(
          (b: any) => {
            return (b.GruppenId === undefined || b.GruppenId === this.group['GruppenID'] && b.ThemeStageArea === this.themestagearea['Displaytext']) && b.Titel.indexOf('Geburtstag') === -1;
          });

      const sortBy = (key: any) => {
        return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
      };

      this.visibleStages = stagelist.concat().sort(sortBy('SortOrder'));
    }
  }

  /**
   * Loads the theme stages data.
   */
  loadData(): void {
    this._loadDataSubscription = this._apiService.postRequestWithBoolean(`/api/Themenbuehnen/GetThemenbuehnenForSortierung`, this.orderByUserGroups).subscribe((data: any) => {
      this._stages = data.Records;
      this._updateVisibleStages();
    });

    this.hints['showRevertChanges'] = false;
  }

  /**
   * Triggers the browsers go back
   * function.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Updates the visible stages, after
   * the group was changed.
   */
  groupChanged(): void {
    this._updateVisibleStages();
  }

  themestageareaChanged(): void {
    this._updateVisibleStages();
  }

  /**
   * Saves the changes.
   */
  save(): void {
    this._saveSubscription = this._apiService.postRequest('/api/Themenbuehnen/SaveThemenbuehnenForSortierung', {
      Buehnen: this.visibleStages,
      OrderByUserGroups: this.orderByUserGroups
    }).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
    });

    this.hints['showSaveThemesStage'] = false;
  }

  /**
   * Pushes the given stage up.
   */
  pushUp(index: number): void {
    if (index > 0) {
      var s = this.visibleStages[index - 1].SortOrder;
      this.visibleStages[index - 1].SortOrder = this.visibleStages[index].SortOrder;
      this.visibleStages[index].SortOrder = s;

      var stage = this.visibleStages.splice(index, 1)[0];
      this.visibleStages.splice(index - 1, 0, stage);
    }
  }

  /**
   * Pushes the given stage down.
   */
  pushDown(index: number): void {
    if (index < this.visibleStages.length - 1) {
      var s = this.visibleStages[index + 1].SortOrder;
      this.visibleStages[index + 1].SortOrder = this.visibleStages[index].SortOrder;
      this.visibleStages[index].SortOrder = s;

      var stage = this.visibleStages.splice(index, 1)[0];
      this.visibleStages.splice(index + 1, 0, stage);
    }
  }

  /**
   * Hides the given hint modal.
   */
  private _hideHint(modal: string): void {
    this.hints[modal] = false;
  }
}
