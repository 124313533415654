import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mp-core-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss']
})
export class FeatureCardComponent implements OnInit {

  @Input() public feature: any;

  public detailLink: string = '';
  public detailInNewTab: string = '';

  constructor() { }

  ngOnInit(): void {
    this.detailLink = '#/';
    if (this.feature.Link) {
      this.detailLink = this.feature.Link;
      this.detailInNewTab = this.feature.Link.includes('http') || this.feature.Link.includes('www.');
    }
  }

}
