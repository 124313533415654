import { Component, OnInit, OnDestroy, DoCheck, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpCoreService } from '@core/services/mp-core.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { ApiService } from '@core/services/api.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpDebounceService } from '@core/services/mp-debounce.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';

/**
 * This class provides the data and functions
 * for the rental choice page.
 */
@Component({
  selector: 'mp-rk-rental-choice',
  templateUrl: './rental-choice.component.html',
  styleUrls: [
    './../../../styles/elements/rk-select.scss',
    './../../../styles/sites/rk-main-page-styles.scss',
    './../../../styles/sites/rk-two-column-pages.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../styles/sites/rk-browser-hacks.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class RentalChoiceComponent implements OnInit, OnDestroy, DoCheck {

  public searchParams: { [key: string]: any } = {};
  public filter: { [key: string]: any } = {};
  public targetFilter: { [key: string]: any } = {};
  public openFilter: boolean = false;
  public openedOffers: boolean = false;
  public travelType: number = 0;
  public rentals: Array<any> | null | undefined;
  public message: string = '';
  public travelTypeEnums: { [key: string]: any } = {};
  public filterOptions: { [key: string]: any } = {};
  public filterOptionsSet: boolean = false;
  public groupings: Array<any> = [];
  public grouping: { [key: string]: any } = {};
  public sortings: Array<any> = [];
  public sorting: { [key: string]: any } = {};
  public filteredRentals: Array<any> = [];
  public role: string = '';
  public selectedCategory: { [key: string]: any } = {};
  public gearBox: { [key: string]: any } = {};
  public loading: boolean = false;
  public noResults: boolean = false;
  public locations: Array<any> = [];
  public otherDropOffLocation: boolean = false;
  public toggleSearchFilterFunc = this.toggleSearchFilter.bind(this);
  public setOpenedOffers = this._setOpenedOffers.bind(this);
  public goToCheck = this._goToCheck.bind(this);

  private _loadRentals: any;
  private _locsLoadedSubscription: Subscription | undefined;
  private _getEnumsSubscription: Subscription | undefined;
  private _getRentalsSubscription: Subscription | undefined;
  private _showSvgLoadingSubscription: Subscription | undefined;
  private _hideSvgLoadingSubscription: Subscription | undefined;
  private _sidebarParamsChangeSubscription: Subscription | undefined;
  private _searchParamsUpdatedSubscription: Subscription | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    private _mpCoreService: MpCoreService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _svgLoader: SvgLoaderService,
    private _mpSidebar: MpSidebarService,
    private _mpDebounce: MpDebounceService,
    private _mpEnums: MpEnumsService,
    private _customEventService: CustomEventService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (!sessionStorage['mietwagenParams']) {
      this._router.navigateByUrl(`/${role}/Reisekonfigurator`);
    }

    this.searchParams = JSON.parse(sessionStorage['mietwagenParams']);

    if (this.searchParams['standorteMietwagen'].AndereRueckgabe) {
      this.otherDropOffLocation = true;
    }

    this._loadRentals = this._mpDebounce.debounce(() => {
      this._svgLoader.startLoading();
      this.loading = true;

      this._getRentalsSubscription = this._apiService.postRequest('/api/RkMietwagen/GetMietwagen', this.searchParams).subscribe((data: any) => {
        this._svgLoader.finishLoading();
        this.loading = false;

        if (data.Result === 'OK') {
          var result = data.Records[0];
          this.groupings = result.Gruppierungen;
          this.grouping = Object.keys(this.grouping).length > 0 ? this.grouping : this.groupings[0];
          this.sortings = result.Sortierungen;
          this.sorting = Object.keys(this.sorting).length > 0 ? this.sorting : this.sortings[0];
          this.locations = result.Standorte;
          this.filterOptions = result.Optionen;
          this.filterOptionsSet = true;
          this.rentals = result.Mietwagen;

          if (!this.rentals || !this.rentals.length) {
            this.rentals = null;
            this._openNoResultSidebar();
          } else {
            this.noResults = false;
            this.searchParams['standorteMietwagen'].noResults = false;
            this.searchParams['standorteMietwagen'].noSelection = false;
            this.setFilteredRentals();
          }
        } else {
          this._openNoResultSidebar();
          this.message = data.Message;
          this.rentals = [];
        }
      },
      (error: any) => {
        this._svgLoader.finishLoading();
        this.loading = false;
        this._openNoResultSidebar();
        this.rentals = [];
      });
    }, 500);
    
    if (window.innerWidth < 992) {
      setTimeout(() => {
        this._setMainContentMinHeight();
      }, 6500);
    } else {
      this._setMainContentMinHeight();
    }

    window.removeEventListener('resize', this._windowResizeListener.bind(this));
    window.addEventListener('resize', this._windowResizeListener.bind(this));

    this._showSvgLoadingSubscription = this._customEventService.on(CustomEventType.ShowSvgLoader).subscribe((event: CustomEvent<any>) => {
      setTimeout(() => {
        this.loading = true;
      });
    });

    this._hideSvgLoadingSubscription = this._customEventService.on(CustomEventType.HideSvgLoader).subscribe((event: CustomEvent<any>) => {
      setTimeout(() => {
        this.loading = false;
      });
    });

    this._searchParamsUpdatedSubscription = this._customEventService.on(CustomEventType.TcSearchParamsUpdated).subscribe((event: CustomEvent<any>) => {
      if (event.payload === 'filterChanged') {
        this.setFilteredRentals();
      } else {
        sessionStorage['mietwagenParams'] = JSON.stringify(this.searchParams);
        this._loadRentals();
      }
    });
    
    if (Object.keys(this._mpEnums.enums).length === 0) {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.gearBox = this._mpEnums.enums['Gangschaltung'];
          this._loadRentals();
        }
      });

      this._mpEnums.getEnums();
    } else {
      this.gearBox = this._mpEnums.enums['Gangschaltung'];
      this._loadRentals();
    }
  }

  /**
   * Unsiubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }

    if (typeof this._getRentalsSubscription !== 'undefined') {
      this._getRentalsSubscription.unsubscribe();
    }

    if (typeof this._showSvgLoadingSubscription !== 'undefined') {
      this._showSvgLoadingSubscription.unsubscribe();
    }

    if (typeof this._hideSvgLoadingSubscription !== 'undefined') {
      this._hideSvgLoadingSubscription.unsubscribe();
    }

    if (typeof this._sidebarParamsChangeSubscription !== 'undefined') {
      this._sidebarParamsChangeSubscription.unsubscribe();
    }

    if (typeof this._searchParamsUpdatedSubscription != 'undefined') {
      this._searchParamsUpdatedSubscription.unsubscribe();
    }
  }

  /**
   * Updates the min height of the content.
   */
  ngDoCheck(): void {
    setTimeout(() => {
      this._setMainContentMinHeight();
    }, 1900);
  }

  /**
   * Updates the min height of the content.
   */
  private _windowResizeListener(): void {
    setTimeout(() => {
      this._setMainContentMinHeight();
    }, 1500);
  }

  /**
   * Openes the no result sidebar.
   */
  private _openNoResultSidebar(): void {
    this.noResults = true;
    this.searchParams['standorteMietwagen'].noResults = true;
    this.searchParams['standorteMietwagen'].noSelection = false;
    this._mpSidebar.open('sidebarLocationsRental', this.searchParams['standorteMietwagen']);
  }

  /**
   * Sets the min height of the content.
   */
  private _setMainContentMinHeight(): void {
    const mainContent = document.getElementById('main-content');
    const footer = document.getElementById('footer');
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');

    if (mainContent !== null && footer !== null && navbar !== null && header !== null) {
      mainContent.style.minHeight = `calc(100vh - ${footer.getBoundingClientRect().height + navbar.getBoundingClientRect().height + parseFloat(getComputedStyle(navbar).getPropertyValue('margin-bottom').replace('px', '')) + header.getBoundingClientRect().height}px)`;
    }
  };

  /**
   * Triggers the global goBack function.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Toggles the search filter.
   */
  toggleSearchFilter(evt: MouseEvent): void {
    evt.preventDefault();
    this.openFilter = !this.openFilter;
    document.body.setAttribute('openfilter', `${this.openFilter}`);
  }

  /**
   * Sets the opened offers.
   */
  private _setOpenedOffers(): void {
    this.openedOffers = typeof this.filteredRentals.find((rental: any) => {
      return rental['open'];
    }) !== 'undefined' ? true : false;
  }

  /**
   * Used for trackBy within ngFor.
   */
  trackByBookingCode(index: number, item: any): number {
    return item.Buchungscode;
  }

  /**
   * Updates the filtered rentals.
   */
  setFilteredRentals(): void {
    if (!this.rentals) {
      return;
    }

    if (Object.keys(this.filter).length > 0) {
      this.filteredRentals = this.rentals.filter((rental: any) => {
        return rental['Punkte'] <= this.filter['Punkte'] &&
          (!(this.filter['Kategorien'] && this.filter['Kategorien'].length) ||
          this.filter['Kategorien'].indexOf(rental['CarGroup']) > -1) &&
          (!(this.filter['Anbieter'] && this.filter['Anbieter'].length) ||
          this.filter['Anbieter'].indexOf(rental.Anbieter) > -1);
      })
    }

    this.filteredRentals = this._sortRentals(this.filteredRentals || this.rentals);

    var groupingField = this.grouping && this.grouping['GruppierFeld'];

    for (var i = 0; i < this.filteredRentals.length; i++) {
      this.filteredRentals[i].showHeader = (i === 0 ||
        (groupingField &&
        this.filteredRentals[i - 1][groupingField] !== this.filteredRentals[i][groupingField]));
    }
  }

  /**
   * Sorts the given rentals.
   */
  private _sortRentals(hotels: Array<any>): Array<any> {
    const sortedRentals = hotels.slice().sort((a: any, b: any) => {
      if (this.grouping && this.grouping['GruppierFeld']) {
        const groupValA = a[this.grouping['GruppierFeld']];
        const groupValB = b[this.grouping['GruppierFeld']];

        var groupFaktor = this.grouping['Reverse'] ? -1 : 1;

        if (groupValA < groupValB) {
          return -1 * groupFaktor;
        } else if (groupValA > groupValB) {
          return 1 * groupFaktor;
        }
      }

      let sortValA, sortValB, sortFaktor;

      if (this.sorting && this.sorting['SortierFeld']) {
        sortValA = a[this.sorting['SortierFeld']];
        sortValB = b[this.sorting['SortierFeld']];

        sortFaktor = this.sorting['Reverse'] ? -1 : 1;
      }

      if (sortValA === sortValB) {
        sortValA = a.Punkte;
        sortValB = b.Punkte;

        sortFaktor = 1;
      }

      // @ts-ignore
      return (sortValA < sortValB ? -1 : 1) * sortFaktor;
    });

    return sortedRentals;
  }

  /**
   * Goes to the check page.
   */
  private _goToCheck(rental: any): void {
    this._router.navigateByUrl(`/${this.role}/MietwagenPruefung?ref=${rental['ResultReference']}`);
  }

  /**
   * Triggers filtering, after filter changed.
   */
  filterChanged(init: boolean): void {
    this.setFilteredRentals();
  }

}
