<div *ngIf="(ls.locsLoaded | async)" class="bg-color-white box-sizing-border-box width-full padding-left-right-24 padding-bottom-0 padding-top-24" id="taw-additional-questions-container">
  <div id="taw-aq-save" class="display-flex flex-align-items-center flex-justify-cont-end margin-bottom-40">
    <button *ngIf="hasAnswers" class="mp-button _prim taw-save-btn" type="button" (click)="clickedSaveButton()">{{ ls.locs['locTipAndWin'].TipAntwortenSpeichern }}</button>
    <button *ngIf="!hasAnswers" class="mp-button disabled taw-save-btn" type="button">{{ ls.locs['locTipAndWin'].AntwortenSpeichern }}</button>
  </div>
  <ng-container *ngFor="let zf of additionalQuestions; let index = index; trackBy: trackByQuestionId">
    <div *ngIf="zf['Aenderbar']" class="padding-bottom-48 taw-additional-question-container" [ngClass]="(zf['TnAntwort'] === '' && zf['Aenderbar'] === true) ? 'taw-additional-question-answer-empty' : ''">
      <div class="width-full margin-bottom-24 box-sizing-border-box padding-8 bg-color-light-grey taw-additional-question font-size-s display-flex flex-align-items-center">{{zf['Frage']}}</div>
      <mp-core-select class="width-full margin-bottom-0 font-size-s" mpId="taw-additional-question-{{index}}"
                      [mpDisabled]="zf['Aenderbar']===false"
                      [model]="zf['TnAntwortId']"
                      (modelChange)="zf['TnAntwortId'] = $event; setAnswer(zf['FrageId'], zf['TnAntwortId'])"
                      [elements]="zf['Antworten']" textProperty="Antwort" valueProperty="AntwortId"
                      placeholder="{{zf['Aenderbar']===false ? ls.locs['locTipAndWin'].KeineAntwortMehrMoeglich : ls.locs['locTipAndWin'].AntwortWaehlen}}"></mp-core-select>
    </div>
  </ng-container>
  <div class="padding-bottom-48 taw-done-additional-questions-container" *ngIf="hasDoneQuestions">
    <h3 class="bold margin-bottom-0">{{ ls.locs['locTipAndWin'].FragenGeschlossen }}</h3>
    <div class="flex-grid margin-left-right-0 font-size-xs hide-on-mobile">
      <div class="flex-col-5 flex-align-items-center">{{ ls.locs['locTipAndWin'].Frage }}</div>
      <div class="flex-col-3 flex-align-items-center">{{ ls.locs['locTipAndWin'].Antwort }}</div>
      <div class="flex-col-3 flex-align-items-center">{{ ls.locs['locTipAndWin'].AntwortKorrekt }}</div>
      <div class="flex-col-1 flex-align-items-center">{{ ls.locs['locTipAndWin'].Punkte }}</div>
    </div>
    <ng-container *ngFor="let zf of additionalQuestions; trackBy: trackByQuestionId">
      <div *ngIf="!zf['Aenderbar']" class="flex-grid margin-left-right-0 font-size-s taw-done-aq-data">
        <div class="flex-col-6-s flex-col-6-xs flex-align-items-start show-on-mobile">{{ ls.locs['locTipAndWin'].Frage }}</div>
        <div class="flex-col-5 flex-col-6-s flex-col-6-xs flex-align-items-center">{{zf['Frage']}}</div>
        <div class="flex-col-6-s flex-col-6-xs flex-align-items-start show-on-mobile">{{ ls.locs['locTipAndWin'].Antwort }}</div>
        <div *ngIf="zf['TnAntwort'] !== null && zf['TnAntwort'] !== ''" class="flex-col-3 flex-col-6-s flex-col-6-xs flex-align-items-center">{{zf['TnAntwort']}}</div>
        <div *ngIf="zf['TnAntwort'] === null || zf['TnAntwort'] === ''" class="flex-col-3 flex-col-6-s flex-col-6-xs flex-align-items-center">-</div>
        <div class="flex-col-6-s flex-col-6-xs flex-align-items-start show-on-mobile">{{ ls.locs['locTipAndWin'].AntwortKorrekt }}</div>
        <div class="flex-col-3 flex-col-6-s flex-col-6-xs flex-align-items-center">{{getCorrectAnswers(zf)}}</div>
        <div class="flex-col-6-s flex-col-6-xs flex-align-items-start show-on-mobile">{{ ls.locs['locTipAndWin'].Punkte }}</div>
        <div *ngIf="zf['Punkte'] !== null" class="flex-col-1 flex-col-6-s flex-col-6-xs flex-align-items-center" [ngClass]="zf['Punkte'] > 0 ? 'text-success' : 'text-danger'">{{zf['Punkte']}}</div>
        <div *ngIf="zf['Punkte'] === null" class="flex-col-1 flex-col-6-s flex-col-6-xs flex-align-items-center">-</div>
      </div>
    </ng-container>
  </div>
</div>
