
<div layout-tn id="main" class="position-relative" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <header id="header" class="position-relative" [ngClass]="{stickyNavbar: navbarIsSticky}">
    <div class="layout-inner position-container">
      <div id="shop-logo">
        <a href="#/charity/Home">
          <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoSecondary}}" alt="Pr&auml;emienshop Logo">
        </a>
      </div>
      <div id="company-logo">
        <a href="#/charity/Home">
          <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoPrimary}}" alt="Firmenlogo">
        </a>
      </div>
      <mp-core-menu *ngIf="userMenuItems.length > 0"
                    mpId="user-menu"
                    [items]="userMenuItems"
                    mpClass="user-menu"
                    sameMenuSelector="#mobile-user-menu, #navbar-user-menu"
                    [showLogout]="true"
                    [(anyOpen)]="userMenuOpen">
      </mp-core-menu>
    </div>
  </header>
  <nav id="navbar" [ngClass]="{'search-active': searchActive, 'is-sticky': navbarIsSticky}">
    <div id="navbar-inner" class="position-container">
      <div id="hamburger-menu-button" class="float-left pointer-hover" (click)="openHamburger()">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].NavigationOeffnen }}" alignment="right">
          <a class="display-block">
            <i class="svg-icon __clr-navbar __wide __menu"></i>
          </a>
        </mp-core-tooltip>
      </div>
      <div id="credits" class="floating-container">
        <div id="navbar-heading" class="float-left">
          <div *ngIf="mpSettings.settings['DisplaySettings'].LogoNavbar && mpSettings.settings['DisplaySettings'].LogoNavbar != 'trans.png'" id="shop-logo__invert" class="float-left margin-left-16">
            <a href="#/charity/Home">
              <img src="../../themes/default/media/logos/{{ mpSettings.settings['DisplaySettings'].LogoNavbar }}" alt="Pr&auml;emienshop Logo">
            </a>
          </div>
        </div>
        <a class="display-block float-left margin-right-16" href="#/charity/Konto">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Praemienpunkte }}" alignment="right">
            <i class="svg-icon __clr-navbar __wide __wide-22 __points"></i>
            <div class="system-icon-text float-left">
              <span [innerText]="pointValue | integer"></span>
              <span class="margin-left-4">{{ ls.locs['loc'].WaehrungPunkte }}</span>
            </div>
          </mp-core-tooltip>
        </a>
        <a *ngIf="hasStatus" class="display-block float-left" href="#/charity/Konto#StatusKonto">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].StatusPunkte }}">
            <i class="svg-icon __clr-navbar __wide __state"></i>
            <div class="system-icon-text float-left">
              <span [innerText]="pointValueState | integer"></span>
            </div>
          </mp-core-tooltip>
        </a>
        <div *ngIf="hasStatus" class="display-block float-left margin-left-24 status-icon">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Status }}">
            <div class="system-icon-text float-left">
              <img src="/themes/default/media/icons/status-icon-{{statusID}}.png">
            </div>
          </mp-core-tooltip>
        </div>
      </div>
      <div id="navbar-title" class="system-icon-text float-left">{{ ls.locs['loc'].Menu }}</div>
      <mp-core-menu *ngIf="mainMenuItems.length > 0"
                    mpId="main-menu"
                    [items]="mainMenuItems"
                    sameMenuSelector="#mobile-main-menu"
                    [showLogout]="true">
      </mp-core-menu>
      <div id="action-icons">
        <div class="display-block float-left">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Wunschliste }}">
            <a href="#/charity/Wunschliste">
              <div class="badge-container position-relative height-full">
                <i class="svg-icon __clr-navbar __wide __favorite-filled"></i>
                <span class="badge position-absolute right-4 top-4 display-flex flex-align-items-center flex-justify-cont-center" *ngIf="wLCount" [innerText]="wLCount"></span>
              </div>
            </a>
          </mp-core-tooltip>
        </div>
        <div class="display-block float-left" (mouseenter)="showWkOverlay = true;" (mouseleave)="showWkOverlay = false">
          <div [hidden]="showWkOverlay && isShoppingbasketOverlayEnabled() || !isShoppingbasketOverlayEnabled()">
            <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Warenkorb }}" alignment="left">
              <a *ngIf="disableWKIconClick()" href="#/charity/Warenkorb">
                <div class="badge-container position-relative height-full">
                  <i class="svg-icon __clr-navbar __wide __basket"></i>
                  <span class="badge position-absolute right-4 top-4 display-flex flex-align-items-center flex-justify-cont-center" *ngIf="basketCount > 0" [innerText]="basketCount"></span>
                </div>
              </a>
              <a *ngIf="!disableWKIconClick()" href="#/charity/Warenkorb">
                <div class="badge-container position-relative height-full">
                  <i class="svg-icon __clr-navbar __wide __basket"></i>
                  <span class="badge position-absolute right-4 top-4 display-flex flex-align-items-center flex-justify-cont-center" *ngIf="basketCount > 0" [innerText]="basketCount"></span>
                </div>
              </a>
            </mp-core-tooltip>
          </div>
          <div [hidden]="!showWkOverlay && isShoppingbasketOverlayEnabled()">
            <a *ngIf="disableWKIconClick()">
              <div class="badge-container position-relative height-full">
                <i class="svg-icon __clr-navbar __wide __basket"></i>
                <span class="badge position-absolute right-4 top-4 display-flex flex-align-items-center flex-justify-cont-center" *ngIf="basketCount > 0" [innerText]="basketCount"></span>
              </div>
            </a>
            <a *ngIf="!disableWKIconClick()" href="#/charity/Warenkorb">
              <div class="badge-container position-relative height-full">
                <i class="svg-icon __clr-navbar __wide __basket"></i>
                <span class="badge position-absolute right-4 top-4 display-flex flex-align-items-center flex-justify-cont-center" *ngIf="basketCount > 0" [innerText]="basketCount"></span>
              </div>
            </a>
          </div>
          <div class="sb-overlay" [hidden]="!showWkOverlay && isShoppingbasketOverlayEnabled() || !isShoppingbasketOverlayEnabled()">
            <ng-container *ngComponentOutlet="sbOverviewComponent"></ng-container>
          </div>
        </div>
        <mp-core-menu *ngIf="userMenuItems.length > 0"
                      mpId="navbar-user-menu"
                      [items]="userMenuItems"
                      mpClass="user-menu"
                      sameMenuSelector="#mobile-user-menu, #user-menu"
                      [showLogout]="true"
                      [(anyOpen)]="userMenuOpen"
                      firstItemType="icon">
        </mp-core-menu>
      </div>
    </div>
  </nav>
  <div id="main-content" class="overflow-hidden">
    <router-outlet name="mainContentCharity"></router-outlet>
  </div>
  <footer id="footer">
    <div class="layout-inner position-container">
      <div id="company-address" [innerHTML]="ls.locs['loc'].FooterText"></div>
      <div id="imprint" class="display-flex flex-direction-row flex-wrap flex-align-items-center flex-justify-cont-center">

        <a *ngIf="mpSettings.settings['DisplaySettings'].ShowDatenschutzInFooter" href="https://www.leaseplan.com/de-de/datenschutz/" target="_blank">{{ ls.locs['loc'].Datenschutz }}</a>
        <span *ngIf="mpSettings.settings['DisplaySettings'].ShowDatenschutzInFooter" class="margin-left-right-4"> | </span>

        <a *ngIf="mpSettings.settings['DisplaySettings'].ShowCookiesInFooter" (click)="cookieService.openCookieConsentBanner()">{{ ls.locs['loc'].CookieSettings }}</a>
        <span *ngIf="mpSettings.settings['DisplaySettings'].ShowCookiesInFooter" class="margin-left-right-4"> | </span>

        <a href="https://www.leaseplan.com/de-de/impressum/" target="_blank">{{ ls.locs['loc'].Impressum }}</a>
      </div>
    </div>
  </footer>
  <div id="shadow" [ngClass]="{open: hamburgerOpen || mpSidebar.visible}"></div>
  <div id="sb-overlay-shadow" [ngClass]="{open: mpSidebar.visible}"></div>
  <div id="mobile-menu" [ngClass]="{open: hamburgerOpen}" (clickOutside)="closeHamburger()" click-outside-except="#hamburger-menu-button">
    <div id="mobile-menu-inner-scroll">
      <mp-core-menu *ngIf="userMenuItems.length > 0"
                    mpId="mobile-user-menu"
                    [items]="userMenuItems"
                    sameMenuSelector="#user-menu, #navbar-user-menu"
                    [(anyOpen)]="userMenuOpen">
      </mp-core-menu>
      <mp-core-menu *ngIf="mainMenuItems.length > 0"
                    mpId="mobile-main-menu"
                    [items]="mainMenuItems"
                    sameMenuSelector="#main-menu"
                    [hidden]="userMenuOpen">
      </mp-core-menu>
    </div>
    <div id="mobile-menu-inner-fixed" [hidden]="!hamburgerOpen">
      <mp-core-logout></mp-core-logout>
    </div>
    <div id="menu-close" class="floating-container">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="right">
        <button class="mp-button padding-left-right-8 float-left" (click)="closeHamburger()">
          <i class="svg-icon __clr-navbar __size-18 __top-0 __clear"></i>
        </button>
      </mp-core-tooltip>
    </div>
  </div>
  <div mpCoreSidebar #sidebar="mpCoreSidebar" id="sidebar" class="sb-overlay sb-overview-sidebar" [ngClass]="{open: sidebar.mpSidebar.visible}">
    <div id="sidebar-inner-scroll">
      <div id="sidebar-content">
        <ng-container *ngComponentOutlet="sidebar.contentComponent"></ng-container>
      </div>
    </div>
    <div id="sidebar-close" class="floating-container">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="left">
        <button class="mp-button padding-left-right-8 float-right" (click)="sidebar.mpSidebar.close()">
          <i class="svg-icon __clr-default __size-18 __top-0 __clear"></i>
        </button>
      </mp-core-tooltip>
    </div>
  </div>
  <mp-core-browser-support-modal></mp-core-browser-support-modal>
</div>
<div mpCoreMpAntiForgery></div>
<mp-core-cookie-consent></mp-core-cookie-consent>
