import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpShoppingBasketService } from './../../shopping-basket/mp-shopping-basket.service';
import { MpSidebarService } from './../../../../../components/sidebar/mp-sidebar.service';
import { MpMessagingService } from './../../../../../services/mp-messaging.service';

import { ShoppingBasketItem } from './../../shopping-basket/shopping-basket-item';

/**
 * This class provides the functions for
 * the express edit sidebar.
 */
@Component({
  selector: 'mp-core-express-edit',
  templateUrl: './express-edit.component.html',
  styleUrls: ['./express-edit.component.scss']
})
export class ExpressEditComponent implements OnInit, OnDestroy {

  public countListStandard: Array<number> = [];
  public countListExpress: Array<number> = [];
  public count: number = 0;
  public countExpress: number = 0;
  public item: ShoppingBasketItem | null = null;

  private _locScubsription: Subscription | undefined;
  private _paramsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpSidebar: MpSidebarService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Refreshes the shopping basket, creates
   * the standard count list and watches
   * for sidebar params changes.
   */
  ngOnInit(): void {
    this._mpShoppingBasket.refreshShoppingBasket();
    this.countListStandard = Array.from({ length: 10 }, (_, i) => i + 1);
    this.item = this._mpSidebar.params['item'];

    if (this.item !== null) {
      this.count = this.item.Anzahl;
      this.countListExpress = Array.from({ length: this.item.MaxExpress }, (_, i) => i + 1);
      this.countExpress = this.item.MaxExpress;
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locScubsription !== 'undefined') {
      this._locScubsription.unsubscribe();
    }

    if (typeof this._paramsSubscription !== 'undefined') {
      this._paramsSubscription.unsubscribe();
    }
  }

  /**
   * Updates the item.
   */
  updateItem(evt: MouseEvent): void {
    evt.preventDefault();

    if (this.item !== null) {
      if (this.item.Express) {
        this.item.Anzahl = this.countExpress;
      } else {
        this.item.Anzahl = this.count;
      }

      this._mpShoppingBasket.updateItem(this.item.Key, this.item.ArtNr, this.item.PIN, this.item.Express, this.item.Anzahl, 0, () => {
        if (this.item !== null) {
          if (Object.keys(this.ls.locs).length > 0) {
            this._mpMessaging.openSuccessPanel(this.item.Express
              ? this.ls.locs['ExpressEditExpressErfolg']
              : this.ls.locs['ExpressEditStandardErfolg']);
          } else {
            this.ls.getLocalization();

            this._locScubsription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
              if (loaded && this.item !== null) {
                this._mpMessaging.openSuccessPanel(this.item.Express
                  ? this.ls.locs['ExpressEditExpressErfolg']
                  : this.ls.locs['ExpressEditStandardErfolg']);
              }
            });
          }
        }

        this._mpSidebar.close();
      });
    }
  }

  /**
   * Sets the count of the item.
   */
  setCount(): void {
    if (this.item !== null) {
      if (this.item.Express && this.item.Anzahl > this.item.MaxExpress) {
        this.item.Anzahl = this.item.MaxExpress;
      }
    }
  }

}
