import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from './../../services/api.service';
import { MpLocalizationService } from './../../services/mp-localization.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
* This class provides the data and
* functions for the list of the action card.
*/
@Component({
  selector: 'mp-core-action-card-list',
  templateUrl: './action-card-list.component.html',
  styleUrls: ['./action-card-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionCardListComponent implements OnInit {

  public count: number | undefined;
  public actions: any | undefined;
  public header: string = '';

  private _getActionsSusbcription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  public actioncardListProperties: OwlOptions = {
    loop: false,
    nav: true,
    navText: ['<', '>'],
    margin: 16,
    items: 2,
    dots: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        loop: false
      },
      768: {
        items: 2,
        nav: true,
        dots: true
      },
      1024: {
        items: 2,
        nav: true,
        dots: true
      }
    }
  };

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService
    ) { }

  /**
  * Gets the data of the action cards list fron the database for participant
  */
  ngOnInit(): void {
    this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.header = this.ls.locs['loc'].DashboardAktionen;
        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }
      }
    });
    this._getActionsSusbcription = this._apiService.postRequest('/api/Aktionen/getMeineAktionen', { Pagesize: this.count || 9999, Startindex: 0 }).subscribe((data: any) => {
      if (data.Result === "OK") {
        this.actions = data.Records;
      }
    });

  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getActionsSusbcription !== 'undefined') {
      this._getActionsSusbcription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }
}
