import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { InitialLoginService } from '@core/services/initial-login.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

/**
 * This class provides the functions and
 * data for the step "set group" of the
 * first login module.
 */
@Component({
  selector: 'mp-leas-first-login-set-group',
  templateUrl: './set-group.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SetGroupComponent implements OnInit, OnDestroy {

  public errorGroup: boolean = false;
  public group: number = 6;

  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _setPrtcpntGroupSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _initialLogonService: InitialLoginService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Subscribes to the validate step
   * and next step observabbles
   * of the InitialLoginService.
   */
  ngOnInit(): void {
    this._validateStepSubscription = this._initialLogonService.validateStep(6).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLogonService.validateCalled.next(false);
        const isValid = this._validateSetGroup();
        this._initialLogonService.validBool = isValid;
        this._initialLogonService.valid.next(isValid);
      }
    });

    this._nextStepSubscription = this._initialLogonService.nextCalledStep(6).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this._initialLogonService.validBool) {
          this._setPrtcpntGroupSubscription = this._apiService.postRequest('/api/Erstanmeldung/setTnGruppe', {
            GruppenId: this.group
          }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this._initialLogonService.nextSuccess();
            } else {
              this._mpMessaging.openWarningPanel(data.Message);
              this._initialLogonService.nextFailure();
            }
          },
          (error: any) => {
            this._mpMessaging.openWarningPanel(error.Message);
            this._initialLogonService.nextFailure();
          });
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._nextStepSubscription !== 'undefined') {
      this._nextStepSubscription.unsubscribe();
    }

    if (typeof this._setPrtcpntGroupSubscription !== 'undefined') {
      this._setPrtcpntGroupSubscription.unsubscribe();
    }
  }

  /**
   * Validates the 'set group' step
   * of the first login.
   */
  private _validateSetGroup(): boolean {
    this.errorGroup = false;

    if (!this.group) {
      this.errorGroup = true;
    }

    return !this.errorGroup;
  }

}
