<div class="radio-button-container">
  <label for="{{mpId}}">
    <input type="radio"
           id="{{mpId}}"
           [ngModel]="model"
           (ngModelChange)="model = $event; modelChanged()"
           [value]="value"
           [name]="name">
    <span class="radio" [attr.for]="mpId">
      <svg version="1.1" class="radiobutton_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
      <g>
      <path class="radiobutton_svg__outer" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z" />
	      </g>
      <circle class="radiobutton_svg__inner" cx="8" cy="8" r="4" />
      </svg>
    </span>
    <span class="text-label" [attr.for]="mpId" [innerHTML]="mpLabel ? mpLabel : label"></span>
    <ng-content></ng-content>
  </label>
</div>
