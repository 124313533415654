<div class="dataTable"
     *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)"
     globally-disable-cache="mpSettings.settings['NetcentiveCoreSettings'].GloballyDisableMpDataTableCache"
     [ngClass]="{ '_minimized-style': minimizedStyle }"
     #dataTableRef>
  <div class="scrolling-container">
    <table class="table dataTableTableVersion margin-top-16 rows-selectable"
           [attr.id]="parentId"
           [ngClass]="{'table-list': isResponsiveWidth}"
           mpCoreResizerFn [resizeFn]="checkWidth">
      <thead [ngClass]="{'data-table-has-header-select': headerSelectTransclude}">
        <tr [hidden]="title === ''" [ngClass]="{'margin-bottom-0 padding-bottom-0': headerSelectTransclude}">
          <th [attr.colspan]="getVisibleColumnCount() + (header.expandable && !header.hasExpandField ? '2' : '1')"
              [ngClass]="{'margin-bottom-0 padding-bottom-0': headerSelectTransclude}">
            <h2 [innerHTML]="title"></h2>
            <mp-core-infotext [sitename]="infotextShorten"></mp-core-infotext>
            <a mpCoreSidebarHelpIcon
               #helpIcon="mpCoreSidebarHelpIcon"
               id="help-sidebar-icon"
               class="floating-container margin-left-24 float-right"
               [hidden]="!helpIcon.showHelpSidebar"
               (click)="helpIcon.openHelpSidebar($event)">
              <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
                <i class="svg-icon __clr-info __size-32 __top-4 __info float-right-important sidebar-open"></i>
              </mp-core-tooltip>
            </a>
            <a id="filterOpenClose"
               class="floating-container float-right"
               [hidden]="hideFilter || alwaysShowFilter || globallyAlwaysShowFilter || (hideColumnSelection && !showDatePicker)"
               (click)="$event.preventDefault();showFilter=!showFilter"
               [ngClass]="{'active' : showFilter}">
              <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Filter }}" alignment="left">
                <button class="mp-button">
                  <i class="svg-icon __clr-default __size-18 __filter"></i>
                </button>
              </mp-core-tooltip>
            </a>
            <a id="searchOpenClose"
               class="floating-container float-right"
               [hidden]="alwaysShowSearch || globallyAlwaysShowSearch || !header.hasSearchField || hideSearch"
               (click)="$event.preventDefault();showSearch=!showSearch"
               [ngClass]="{'active' : showSearch}">
              <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Suche }}" alignment="left">
                <button class="mp-button">
                  <i class="svg-icon __clr-default __size-18 __search"></i>
                </button>
              </mp-core-tooltip>
            </a>
          </th>
        </tr>
        <tr class="data-table-header-select" *ngIf="headerSelectTransclude">
          <th class="padding-top-0" [attr.colspan]="getVisibleColumnCount() + (header.expandable && !header.hasExpandField ? '2' : '1')">
            <ng-content></ng-content>
          </th>
        </tr>
        <tr *ngIf="!hideFilter">
          <th [attr.colspan]="getVisibleColumnCount() + (header.expandable && !header.hasExpandField ? '2' : '1')"
              [hidden]="!showFilter && !globallyAlwaysShowFilter && !alwaysShowFilter"
              (clickOutside)="closeFilter()"
              class="filterContent row"
              click-outside-except="#kategorieOpenClose, #filterOpenClose, .filterContent">
            <div id="FilterBereich"
                 filter-active="filterActive">
              <div class="filterLeiste">
                <div class="grid"
                     [ngClass]="onlyReloadOnClick ? 'col-large-8' : 'col-large-9'">
                  <mp-core-date-range-picker-with-presets *ngIf="!(!loader) && !(!loader.picker)"
                                                          class="mp-data-table-date-range-picker"
                                                          [hidden]="!showDatePicker"
                                                          [von]="loader.picker.Von"
                                                          (vonChange)="loader.picker.Von = $event; handleDateChange();"
                                                          [bis]="loader.picker['Bis']"
                                                          (bisChange)="loader.picker['Bis'] = $event; handleDateChange();"
                                                          [minDate]="loader['minDate']"
                                                          [maxDate]="loader['maxDate']">
                  </mp-core-date-range-picker-with-presets>
                </div>
                <div class="grid"
                     [ngClass]="showDatePicker ? onlyReloadOnClick ? 'col-large-2 position-relative' : 'col-large-3 position-relative' : 'col-large-3'"
                     [hidden]="hideColumnSelection">
                  <div class="filter-selection" *ngIf="header.filledColumns && header.selectedColumns">
                    <mp-core-select class="multiselect mp-data-table-multiselect"
                               [multiple]="true"
                               placeholder="{{ ls.locs['loc'].SpaltenAuswaehlen }}"
                               [keepPlaceholderText]="true"
                               [model]="header.selectedColumns"
                               textProperty="title"
                               [elements]="header.filledColumns"
                               (modelChange)="header.selectedColumns = $event; updateColums()"
                               [allowHtml]="true">
                    </mp-core-select>
                  </div>
                </div>
                <div class="grid col-large-2"
                     [ngClass]="showDatePicker ? 'top-16 position-relative' : ''">
                  <div class="filter-selection">
                    <button [hidden]="!onlyReloadOnClick"
                            class="mp-button _prim float-left-important"
                            (click)="applyDateFilter()">
                      {{ ls.locs['loc'].FilterAnwenden }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr *ngIf="mpSettings.settings['NetcentiveCoreSettings'].UnifiedSearchbox && !hideSearch && anySearchable()"
            [hidden]="!showSearch && !globallyAlwaysShowSearch && !alwaysShowSearch"
            class="searchContent">
          <th [attr.colspan]="getVisibleColumnCount() + (header.expandable && !header.hasExpandField ? '2' : '1')">
            <div class="mp-data-table-suchfeld">
              <mp-core-search [(model)]="searchText"
                              mpId="search-id"
                              [action]="loadViaLoaderFunc"
                              [mpPlaceholder]="ls.locs['loc'].Suchen">
              </mp-core-search>
            </div>
          </th>
        </tr>
        <tr *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].UnifiedSearchbox && !hideSearch && anySearchable()"
            [hidden]="!showSearch && !globallyAlwaysShowSearch && !alwaysShowSearch"
            class="searchContent">
          <th></th>
          <th *ngIf="header.expandable && !header.hasExpandField"></th>
          <th [hidden]="c.visibility === 'hidden'"
              *ngFor="let c of header.columns; let index = index"
              [ngStyle]="{'width.px': c.width}"
              [ngClass]="c.headerClass"
              class="dataTableSearchfild">
            <mp-core-search *ngIf="c.searchable"
                            [(model)]="searchTexts[c.field]"
                            mpId="search-{{index}}-{{c.field}}"
                            [action]="loadViaLoaderFunc"
                            [mpPlaceholder]="ls.locs['loc'].Suchen">
            </mp-core-search>
          </th>
        </tr>
        <tr>
          <th scope="col"
              [hidden]="hideRowNumbers"
              class="dataTableNoBorder">
          </th>
          <th scope="col"
              *ngIf="header.expandable && !header.hasExpandField"
              class="expandableColumn">
          </th>
          <th scope="col"
              [hidden]="c.visibility === 'hidden'"
              (click)="setSorting(c.field, c.sorting, mpSettings.settings['NetcentiveCoreSettings'].DefaultSortingDesc)"
              *ngFor="let c of header.columns"
              [ngClass]="c.headerClass + ' ' + c.field + ' ' + (c.sorting ? 'pointer' : 'default') + ' ' + (getSortingClass(c.field)!== 'empty' ? 'sort-active' : '') + ' ' + (c.fixedColumn ? 'fixed': '')"
              (mouseenter)="c.hover = true"
              (mouseleave)="c.hover = false">
            <div class="sorting-container">
              
              <mp-core-tooltip tooltipTitle="{{c.tooltip}}" alignment="top">
                <i class="svg-icon __clr-default __size-18 __arrow-up"
                   [hidden]="getSortingClass(c.field) !== 'arrow-up'">
                </i>
                <i class="svg-icon __clr-default __size-18 __arrow-down"
                   [hidden]="getSortingClass(c.field) !== 'arrow-down'">
                </i>
                <span [innerHTML]="c.trustTitle" class="sorting-text icon-text"></span>
              </mp-core-tooltip>
            </div>
          </th>
        </tr>
      </thead>
      <tbody [hidden]="loading"
             *ngFor="let i of rowCount; let index = index; let even = even; let odd = odd;"
             class="dataTableTBody">
        <tr class="res-only"
            *ngIf="rows[i]"
            [hidden]="disableResponsiveToggling || !isResponsiveWidth">
          <td colspan="100"
              class="display-table">
            <a href=""
               (click)="toggleChildResponsive(rows[i], $event)"
               class="display-flex flex-align-items-center mobile-table-toggle">
              <i class="svg-icon __clr-default __size-16 __top-0"
                 [ngClass]="rows[i].expandedResponsive ? '__expand-more' : '__expand-less'">
              </i>
              <span [innerHTML]="rows[i].trustResponsiveHeaderContent"
                    class="margin-left-4 text-color-general table-toggler-text display-table-cell vertical-align-middle">
              </span>
            </a>
          </td>
        </tr>
        <tr class="not-res"
            *ngIf="rows[i]"
            [hidden]="!rows[i].expandedResponsive && isResponsiveWidth"
            (click)="toggleHighlight(rows[i])"
            [ngClass]="{active: rows[i].highlight, even: even, odd: odd}">
          <td [attr.data-label]=""
              [hidden]="hideRowNumbers"
              class="not-res dataTableNoBorder dataTableNumbers"
              [ngClass]="rows[i].HighlightedRow ? '_is-highlighted-column' : ''"
              [innerText]="rows[i] ? (page-1)*pageSize + index + 1 : '&nbsp;'">
          </td>
          <td [attr.data-label]=""
              *ngIf="header.expandable && !header.hasExpandField && !isResponsiveWidth">
            <a href=""
               [hidden]="!rows[i][header.visibilityProperty]"
               class="padding-left-16 display-inline-block"
               [ngClass]="rows[i].HighlightedRow ? '_is-highlighted-column' : ''"
               (click)="toggleChild(rows[i], $event, i)">
              <i class="svg-icon __clr-default __size-16"
                 [ngClass]="rows[i].expanded ? '__expand-more' : '__expand-less'">
              </i>
            </a>
          </td>
          <td [attr.data-label]="c.title"
              [hidden]="c.visibility === 'hidden'"
              *ngFor="let c of header.columns"
              [ngClass]="c.listClass + ' ' + c.field + ' ' + (c.minMaxStyling ? getMinMaxClass(rows[i][c.field], c) : '') + ' ' + (rows[i].HighlightedRow ? '_is-highlighted-column' : '')">
            <div class="table-cell-content">
              <span *ngIf="!(c.function && (c.showFunctionLink === '' || rows[i][c.showFunctionLink])) && !c.hasTriCB && !c.hasCB && !c.link && !c.hasRadio && !c.isExpandField"
                    [innerHTML]="rows[i][c.field + '-trustField']">
              </span>
              <a *ngIf="c.function && (c.showFunctionLink === '' || rows[i][c.showFunctionLink])"
                 (click)="execClickFunction(c.function, rows[i], c, $event)"
                 href=""
                 [innerHTML]="rows[i][c.field + '-trustField']"
                 [hidden]="!showLinkFunction(c,rows[i])">
              </a>
              <a *ngIf="c.link && rows[i][c.link]"
                 href="{{modifyLinkForAngular(rows[i][c.link])}}"
                 (click)="isNullLInk(rows[i][c.field])"
                 [innerHTML]="rows[i][c.field + '-trustField']"
                 target="{{c.openLinkInNewTab ? '_blank' : ''}}">
              </a>
              <mp-core-checkbox *ngIf="c.hasCB"
                                [(model)]="rows[i][c.field]"
                                (modelChange)="execChangeFunction(c.cbChangeFunction, rows[i], c)"
                                mpId="{{c.field}}-{{i}}-{{guid}}-checkbox">
              </mp-core-checkbox>
              <mp-core-checkbox *ngIf="c.hasTriCB"
                                [(model)]="rows[i][c.field]"
                                (modelChange)="execChangeFunction(c.cbChangeFunction, rows[i], c)"
                                [tristate]="true"
                                mpId="{{c.field}}-{{i}}-{{guid}}-checkbox">
              </mp-core-checkbox>
              <input *ngIf="c.hasRadio"
                     type="radio"
                     class="radio"
                     name="rd_{{c.field}}"
                     [(ngModel)]="rows[i][c.field]"
                     (click)="execChangeFunction(c.radioChangeFunction, rows[i], c)" />
              <a *ngIf="c.isExpandField"
                 href=""
                 [hidden]="!rows[i][header.visibilityProperty]"
                 [innerHTML]="rows[i][c.field + '-trustField']"
                 (click)="toggleChild(rows[i], $event, i)">
              </a>
            </div>
          </td>
          <td [attr.data-label]="'Bezeichnung'"
              *ngIf="header.expandable && isResponsiveWidth && rows[i].expandedResponsive">
            <a href=""
               [hidden]="!rows[i][header.visibilityProperty]"
               (click)="toggleChild(rows[i], $event, i)">
              <span [hidden]="rows[i].expanded">{{ ls.locs['loc'].DetailsEinblenden }}</span>
              <span [hidden]="!rows[i].expanded">{{ ls.locs['loc'].DetailsAusblenden }}</span>
            </a>
          </td>
        </tr>
        <tr *ngIf="header.expandable && rows[i] && rows[i].expanded"
            class="gridExpand dataTableTBody">
          <td [attr.data-label]=""
              class="dataTableNoBorder dataTableNumbers">
          </td>
          <td [attr.data-label]="'Punkte'"
              *ngIf="headerChildTemplate !== null"
              [attr.colspan]="getVisibleColumnCount()+1">
            <ng-container [ngClass]="{'showExpand' : rows[i].expanded, 'hideExpand': !rows[i].expanded, displayNone: rows[i].expanded === undefined}"
                 [attr.id]="'expanded'+i+parentId" *ngComponentOutlet="headerChildTemplate; injector: getInjectorForRow(rows[i]);"></ng-container>
          </td>
        </tr>
      </tbody>
      <tfoot [hidden]="loading" *ngIf="footer && footerRows.length > 0 || paging">
        <tr *ngFor="let f of footerRows"
            class="summary">
          <td *ngIf="header.expandable && !header.hasExpandField"></td>
          <td class="summary-placeholder-column"></td>
          <td [attr.data-label]="c.field"
              [hidden]="c.visibility === 'hidden'"
              *ngFor="let c of header.columns; let index = index;"
              [ngClass]="c.listClass + ' ' + c.field + ' ' + (index == 0 && 'total') + ' ' + (c.options ? c.options[f[c.field]] : f[c.field] ? '' : 'table-empty')">
            <span *ngIf="c.field != 'RecNo'"
                  [innerHTML]="f[c.field + '-trustFooterField']"
                  [ngClass]="c.field">
            </span>
          </td>
        </tr>
        <tr class="table-footer"
            [hidden]="!paging">
          <td *ngIf="header.expandable && !header.hasExpandField"></td>
          <td [attr.colspan]="getVisibleColumnCount()+1"
              class="pagination">
            <div>
              <mp-core-select class="display-inline-block inputWidthFix"
                         [model]="pageSize"
                         [elements]="availablePageSizes"
                         [mpRequired]="true"
                         (modelChange)="pageSize = $event; loadViaLoaderFunc()"
                         label="{{ ls.locs['loc'].AnzahlProSeite }}">
              </mp-core-select>
              <div [hidden]="pages.length <= 1"
                   class="display-inline-block">
                <button type="button"
                        (click)="goToFirstPage()"
                        class="mp-button dataTablePageSelect">
                  <i class="svg-icon __clr-default __size-18 __nav-first"></i>
                </button>
                <button type="button"
                        (click)="goToPreviousPage()"
                        class="mp-button">
                  <i class="svg-icon __clr-default __size-18 __nav-prev"></i>
                </button>
                <mp-core-select class="display-inline-block"
                           *ngIf="pages.length > 0"
                           [model]="page"
                           [elements]="pages"
                           [mpRequired]="true"
                           (modelChange)="page = $event; loadViaLoaderFunc()"
                           label="{{ ls.locs['loc'].Seite }}">
                </mp-core-select>
                <span class="pagedistance"> {{ ls.locs['loc'].von }} </span>
                {{totalPages}}
                <button type="button"
                        (click)="goToNextPage()"
                        class="mp-button dataTablePageSelect">
                  <i class="svg-icon __clr-default __size-18 __nav-next"></i>
                </button>
                <button type="button"
                        (click)="goToLastPage()"
                        class="mp-button">
                  <i class="svg-icon __clr-default __size-18 __nav-last"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
