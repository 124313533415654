<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table title=""
                      *ngIf="loaderName !== ''"
                      fieldsAction="/api/Statistik/getPraemienmanagerChildFields"
                      listAction="/api/Statistik/getPraemienmanagerChild"
                      loaderName="{{loaderName}}"
                      [paging]="false"
                      [footer]="false"
                      [startEmpty]="true"
                      defaultSorting="ArtNrPIN"
                      [hideColumnSelection]="true"
                      [disableResponsiveToggling]="true">
  </mp-core-data-table>
</div>
