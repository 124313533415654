import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { GameSvgFetcherService } from './../../../../services/game-svg-fetcher.service';

/**
 * This class provides the data and
 * the functions for the racing
 * pit.
 */
@Component({
  selector: 'mp-gfc-racing-pit',
  templateUrl: './racing-pit.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RacingPitComponent implements OnInit, OnDestroy {

  public availableColors: Array<string> = [
    'green-car',
    'yellow-car',
    'orange-car',
    'red-car',
    'pink-car',
    'darkblue-car',
    'lightblue-car',
    'aqua-car',
    'purple-car',
    'gray-car',
    'black-car',
    'white-car'
  ];
  public availableCars: Array<string> = [
    'racing/car-one',
    'racing/car-two',
    'racing/car-three',
    'racing/car-four',
    'racing/car-five',
    'racing/car-six',
  ];
  public selectedCar: string = this.availableCars[0];
  public selectedColor: string = this.availableColors[0];
  public carsSvgs: { [key: string]: any } = {};
  public pitRepairSvg: SafeHtml = '';
  public bollardSvg: SafeHtml = '';
  public trackSvg: SafeHtml = '';
  public wheelSvg: SafeHtml = '';

  private _pitRepairPath = 'racing/pit-repair';
  private _bollardPath = 'racing/pit-bollard';
  private _trackPath = 'racing/pit-race-track';
  private _wheelPath = 'racing/pit-wheels';
  private _role: string = '';
  private _campaignKey: string = '';
  private _racer: { [key: string]: any } = {};
  private _getQueryParamsSubscription: Subscription | undefined;
  private _fetchSvgContentSubscriptions: Array<Subscription> = [];
  private _fetchPitRepairSvgContentSubscription: Subscription | undefined;
  private _fetchBollardSvgContentSubscription: Subscription | undefined;
  private _fetchTrackSvgContentSubscription: Subscription | undefined;
  private _fetchWheelSvgContentSubscription: Subscription | undefined;
  private _getRacerSubscription: Subscription | undefined;
  private _saveRacerSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _gameSvgFetcher: GameSvgFetcherService
  ) { }

  /**
   * Gets the role, the campaign key
   * and the racer data.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this.availableCars.forEach((car: string) => {
      const subscr = this._gameSvgFetcher.fetchSvg(car).subscribe((svgContent: SafeHtml) => {
        this.carsSvgs[car] = svgContent;
      });

      this._fetchSvgContentSubscriptions.push(subscr);
    });

    this._fetchPitRepairSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._pitRepairPath).subscribe((svgContent: SafeHtml) => {
      this.pitRepairSvg = svgContent;
    });

    this._fetchBollardSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._bollardPath).subscribe((svgContent: SafeHtml) => {
      this.bollardSvg = svgContent;
    });

    this._fetchTrackSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._trackPath).subscribe((svgContent: SafeHtml) => {
      this.trackSvg = svgContent;
    });

    this._fetchWheelSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._wheelPath).subscribe((svgContent: SafeHtml) => {
      this.wheelSvg = svgContent;
    });

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._campaignKey = params['key'];

      this._getRacerSubscription = this._apiService.postRequest('/api/Gamification/GetRennfahrer', {
        AktionenKey: this._campaignKey
      }).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._racer = data.Records[0];
          this.selectedCar = this.availableCars[this._racer['Autotyp']];
          this.selectedColor = this.availableColors[this._racer['Farbe']];
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (this._fetchSvgContentSubscriptions.length > 0) {
      this._fetchSvgContentSubscriptions.forEach((subscr: Subscription) => {
        subscr.unsubscribe();
      });
    }

    if (typeof this._fetchPitRepairSvgContentSubscription !== 'undefined') {
      this._fetchPitRepairSvgContentSubscription.unsubscribe();
    }

    if (typeof this._fetchBollardSvgContentSubscription !== 'undefined') {
      this._fetchBollardSvgContentSubscription.unsubscribe();
    }

    if (typeof this._fetchTrackSvgContentSubscription !== 'undefined') {
      this._fetchTrackSvgContentSubscription.unsubscribe();
    }

    if (typeof this._fetchWheelSvgContentSubscription !== 'undefined') {
      this._fetchWheelSvgContentSubscription.unsubscribe();
    }

    if (typeof this._getRacerSubscription !== 'undefined') {
      this._getRacerSubscription.unsubscribe();
    }

    if (typeof this._saveRacerSubscription !== 'undefined') {
      this._saveRacerSubscription.unsubscribe();
    }
  }

  /**
   * Saves the data for the racer.
   */
  saveRacer(evt: MouseEvent): void {
    evt.preventDefault();
    this._racer['Autotyp'] = this.availableCars.indexOf(this.selectedCar);
    this._racer['Farbe'] = this.availableColors.indexOf(this.selectedColor);
    this._racer['AktionenKey'] = this._campaignKey;

    this._saveRacerSubscription = this._apiService.postRequest('/api/Gamification/UpdateRennfahrer', this._racer).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._router.navigateByUrl(`/${this._role}/RacingRennstrecke?key=/${this._campaignKey}`);
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }
    });
  }

  /**
   * Sets the selected car.
   */
  setSelectedCar(car: string): void {
    this.selectedCar = car;
  }

  /**
   * Sets the selected color.
   */
  setSelectedColor(color: string): void {
    this.selectedColor = color;
  }

}
