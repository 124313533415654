import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

/**
 * This class provides a simple divider.
 */
@Component({
  selector: 'mp-core-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DividerComponent implements OnInit {

  @Input() public model: any;

  constructor() { }

  ngOnInit(): void {
  }

}
