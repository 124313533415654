<div class="loginbackground row-inner">
  <div class="grid col-large-3 loginoverlay" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
    <div [hidden]="showPWDForgotten"
         class="logincontent">
      <h1>{{ ls.locs['loc'].LoginTitel }}</h1>
      <mp-core-input mpType="text"
                [(model)]="user"
                mpId="user"
                [mpEnter]="login.bind(this)"
                mpPlaceholder="{{ ls.locs['loc'].Benutzername }}"
                [mpRequired]="true">
      </mp-core-input>
      <mp-core-input mpType="password"
                [(model)]="pwd"
                mpId="pwd"
                [mpEnter]="login.bind(this)"
                mpPlaceholder="{{ ls.locs['loc'].Passwort }}"
                [mpRequired]="true"
                class="margin-top-16">
      </mp-core-input>
      <button class="mp-button _prim display-block margin-top-24"
              [disabled]="loading"
              (click)="login()">
        {{ ls.locs['locButtons'].Login }}
      </button>
      <mp-core-sidebar-input class="display-block margin-top-24 width-full-important"
                     text="{{ ls.locs['locButtons'].PasswortAendern }}"
                     sidebarComponent="SidebarForgotLoginData">
      </mp-core-sidebar-input>
      <div class="margin-top-24"><img width="55%" id="login-logo" src="../../themes/default/media/logos/Bubble_Club_Logo.png" alt="Pr&auml;emienshop Logo"></div>
    </div>
  </div>
</div>
