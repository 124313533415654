import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This class provides the service
 * to start and stop the svg loading
 * animation.
 */
@Injectable({
  providedIn: 'root'
})
export class SvgLoaderService {

  private _svgLoaderState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public svgLoaderState: Observable<number> = this._svgLoaderState.asObservable();
  public svgLoading: number = 0;

  constructor() { }

  /**
   * Starts a svg loading animation.
   */
  startLoading(count?: number) {
    this.svgLoading = this.svgLoading + (count || 1);
    this._svgLoaderState.next(this.svgLoading);
  }

  /**
   * Finishs a svg loading animation.
   */
  finishLoading(count?: number) {
    setTimeout(() => {
      this.svgLoading = this.svgLoading - (count || 1);
      this._svgLoaderState.next(this.svgLoading);
    }, 500);
  }
}
