import { Directive, ElementRef, OnInit } from '@angular/core';

/**
 * This class provides the functionality to
 * scroll to the first active day, in a
 * calendar sidebar.
 */
@Directive({
  selector: '[mpCoreFirstActiveDay]'
})
export class FirstActiveDayDirective {

  constructor(private _element: ElementRef) { }

  /**
   * Gets the month of the first active day,
   * and its position in sidebar. Scrolls to
   * the month of the first active day.
   */
  ngOnInit(): void {
    const activeDay = this._element.nativeElement;
    let scrollContainer = activeDay.parentElement;

    if (scrollContainer !== null) {
      let whileCounter = 0;

      while (!scrollContainer.classList.contains('scroll-area-container') && whileCounter < 20) {
        scrollContainer = scrollContainer.parentElement;
        whileCounter++;
      }

      if (scrollContainer.classList.contains('scroll-area-container')) {
        setTimeout(() => {
          const scrollContainerCBR = scrollContainer.getBoundingClientRect();
          const activeMonth = activeDay.parentElement.parentElement.parentElement.parentElement;
          const activeMonthCBR = activeMonth.getBoundingClientRect();
          const middle = activeMonthCBR.top + (activeMonthCBR.height / 2) - scrollContainerCBR.top - (scrollContainerCBR.height / 2);

          if (scrollContainer.scrollTop < middle) {
            scrollContainer.scrollTop = middle;
            scrollContainer.querySelector(scrollContainer.parentElement.querySelector('#overlay-scroll-up').getAttribute('scrollArea')).setAttribute('data-sbcurritem', activeDay.dataset.monthIndex);
          }
        }, 750);
      }
    }
  }

}
