<div id="charity-aktuelle-infos" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="stage">
    <owl-carousel-o id="charity-carousel" class="owl-theme" [options]="charitySliderProperties">
      <ng-container *ngFor="let item of [{ID:1}]">
        <ng-template carouselSlide>
          <div class="item">
            <img src="/themes/default/media/buehnen/seiten/charity.jpg?width={{ mpSettings.settings['DisplaySettings'].BuehnenBildBreite }}&height={{ mpSettings.settings['DisplaySettings'].BuehnenBildHoehe }}&mode=crop" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="layout-container">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <h1>{{ ls.locs['locLeas'].AktuelleInfos }}</h1>
    <div>
      <p [innerHTML]="ls.locs['locLeas'].CharityAktuelleInfos"></p>
    </div>
    <div class="row margin-top-48">
      <ng-container *ngFor="let article of articleList; trackBy: trackByArticleId">
        <div *ngIf="article['ID'] !== 1" class="charity-card">
          <div class="card card-s">
            <a class="row" href="/#/{{role}}/PraemienDetails?art={{article['ArtNr']}}&pin={{article['PIN']}}">
              <div class="product-image">
                <img src="{{article['Image']}}?height=500" alt="" />
              </div>
              <div class="primary-title">
                <div class="bezeichnung" [innerText]="article['ArtBez']"></div>
              </div>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
