import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the campaigns history
 * table.
 */
@Component({
  selector: 'mp-leas-campaigns-history',
  templateUrl: './campaigns-history.component.html'
})
export class CampaignsHistoryComponent implements OnInit {

  public excelService: string = '/api/Aktionshistorie/Aktionshistorie2Excel';
  public loader: MpLoader | null = null;
  public role: string = '';
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'reaktivieren',
      func: this.reactivate.bind(this)
    }
  ];

  private _leasCampaignsHistoryLoader: MpLoader = {
    name: 'leasCampaignsHistoryLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _router: Router
  ) { }

  /**
   * Sets the menu highlighting and
   * registers the loader. Gets the role.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._leasCampaignsHistoryLoader);

    this._mpLoaderService.extendLoader('leasCampaignsHistoryLoader', {
      isReady: true
    });

    this.role = this._authService.getRole();

    if (typeof this.role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(this.role);
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasCampaignsHistoryLoader');
  }

  /**
   * Reactivates the given campaign.
   */
  reactivate(row: any): void {
    this._router.navigateByUrl(`/${this.role}/BuehnenEditor?language=de&key=${row['Key']}`);
  }

}
