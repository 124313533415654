<section id="konto" class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div *ngIf="mpSettings.settings['DisplaySettings'].ShowBreadcrumbs" class="breadcrumb">
    <a class="breadcrumb-item" href="#/charity/Home">{{ ls.locs['loc'].Home }}</a>
    <i class="breadcrumb-splitter"></i>
    <a class="breadcrumb-item" href="#/charity/Konto" [innerText]="mpSettings.settings['NetcentiveCoreSettings'].HasStatus ? ls.locs['loc'].Konten : ls.locs['loc'].Konto"></a>
  </div>

  <div mpLeasAccount #mpAccount="mpLeasAccount" class="konto">
    <h2 class="mobile-only">{{ ls.locs['loc'].MeinPunktekonto }}</h2>

    <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].UnifiedSearchbox">
      <input type="text" [(ngModel)]="mpAccount.searchTerm" placeholder="{{ ls.locs['loc'].Suchen }}" />
      <button (click)="mpAccount.search()" class="mp-button">{{ ls.locs['loc'].Suchen }}</button>
    </div>

    <mp-core-data-table *ngIf="mpAccount.loaderRegistered" title="{{ ls.locs['loc'].MeinPunktekonto }}"
                        fieldsAction="/api/Konto/getKontoFields"
                        listAction="/api/Konto/getKonto"
                        loaderName="leasAccountTableLoader"
                        [paging]="true"
                        [footer]="true"
                        [hideSearch]="mpSettings.settings['NetcentiveCoreSettings'].UnifiedSearchbox"
                        defaultSorting="Datum DESC">
    </mp-core-data-table>
  </div>
</section>


