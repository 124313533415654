import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the imminent points 
 * decay table.
 */
@Component({
  selector: 'mp-core-imminent-points-decay',
  templateUrl: './imminent-points-decay.component.html'
})
export class ImminentPointsDecayComponent implements OnInit {

  public excelService: string = '/api/Statistik/DrohenderPunkteverfall2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _imminentPointsDecayLoader: MpLoader = {
    name: 'imminentPointsDecayLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('DrohenderPunkteverfall');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._imminentPointsDecayLoader);

    this._mpLoaderService.extendLoader('imminentPointsDecayLoader', {
      isReady: true
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('imminentPointsDecayLoader');
  }

}
