<article *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async) && wishCard" (mouseleave)="showIconLegend = false" class="card-flat bg-color-white padding-0 box-sizing-border-box margin-0">
  <div class="mouseenter-catcher width-full height-full" (mouseenter)="showIconLegendFnc()">
    <a class="shopKachelAnchor padding-top-16 padding-bottom-8 padding-left-right-16 box-sizing-border-box" href="#/{{role}}/PraemienDetails?art={{ wishCard.ArtNr }}&pin={{wishCard.PIN}}" (click)="onClick($event)" [ngClass]="showIconLegend == true ? 'show-icon-legend' : 'hide-icon-legend'">
      <div class="card-ribbon-wrapper overflow-hidden" [hidden]="!wishCard.IsNeu">
        <div class="card-new-bubble bg-color-info font-size-xs text-align-center position-relative display-inline-block padding-top-bottom-0 padding-left-right-4">{{ ls.locs['loc'].NeuCaps }}</div>
      </div>
      <div class="product-image">
        <div class="article-img-container display-flex flex-align-items-center" [ngClass]="showIconLegend && wishCard.CellWidth == 1 ? 'flex-justify-cont-start' : 'flex-justify-cont-center'">
          <img mpCoreErrSrc errSrc="/themes/default/media/images/trans.png" [src]="wishCard.Bild + '?width=150&height=150&mode=crop'" width="150" height="150" [attr.alt]="wishCard.ArtNr + '-' + wishCard.PIN">
        </div>
        <div class="logo-container">
          <img class="logo" [src]="wishCard.HerstellerLogoPath" mpCoreErrSrc errSrc="/themes/default/media/images/trans.png">
        </div>
      </div>
      <div class="pretitle-wrapper max-width-full">
        <div class="width-full display-block">
          <div class="pretitle text-overflow-ellipsis float-left padding-right-8 font-size-general bold">
            <span class="product-category" [innerText]="wishCard.ArtNr.substr(0, 1) == 'R' ? wishCard.ArtBez2 : wishCard.ArtBez"></span>
          </div>
          <div class="pretitle company-logo text-overflow-ellipsis float-left padding-right-8 font-size-general bold">
            <span>{{ wishCard.HerstellerName }}</span>
          </div>
        </div>
        <span class="title width-full top-4 position-relative display-block text-overflow-ellipsis" [innerText]="wishCard.ArtNr.substr(0, 1) == 'R' ? wishCard.ArtBez : wishCard.ArtBez2"></span>
      </div>
      <div class="icon-legend display-flex">
        <ul class="display-flex">
          <li *ngIf="hasStatus && mpSettings.settings['DisplaySettings'].ShowPraemienStatus===true" class="display-flex">
            <img *ngIf="mpSettings.settings['DisplaySettings'].StatusIconsAsImages" class="float-left icon status-icon-img margin-right-16" src="/themes/default/media/icons/status-icon-{{ wishCard.StatusID }}.png" />
            <div *ngIf="mpSettings.settings['DisplaySettings'].StatusIconsAsImages" [ngSwitch]="wishCard.StatusID">
              <span *ngSwitchCase="1">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusBronzeVerfuegbar }}</span>
              </span>
              <span *ngSwitchCase="2">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusSilberVerfuegbar }}</span>
              </span>
              <span *ngSwitchCase="3">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusGoldVerfuegbar }}</span>
              </span>
            </div>
            <span *ngIf="!mpSettings.settings['DisplaySettings'].StatusIconsAsImages">
              <i class="svg-icon __clr-status-{{ wishCard.StatusID }} __size-18 __top-0 __state-round"></i>
            </span>
            <div *ngIf="!mpSettings.settings['DisplaySettings'].StatusIconsAsImages" [ngSwitch]="wishCard.StatusID">
              <span *ngSwitchCase="1">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusBronzeVerfuegbar }}</span>
              </span>
              <span *ngSwitchCase="2">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusSilberVerfuegbar }}</span>
              </span>
              <span *ngSwitchCase="3">
                <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusGoldVerfuegbar }}</span>
              </span>
            </div>
          </li>
          <li [ngSwitch]="wishCard.VerfuegbarkeitID" class="display-flex">
            <span class="display-flex flex-align-items-center" *ngSwitchCase="1">
              <i class="svg-icon __clr-success __top-0 __size-18 __check-circle"></i>
              <span class="icon-legend-text" [innerText]="wishCard.AmpelGruenText"></span>
            </span>
            <span class="display-flex flex-align-items-center" *ngSwitchCase="2">
              <i class="svg-icon __clr-warning __top-0 __size-18 __check-circle"></i>
              <span class="icon-legend-text" [innerText]="wishCard.Lieferhinweis || wishCard.AmpelGelbText"></span>
            </span>
            <span class="display-flex flex-align-items-center" *ngSwitchCase="3">
              <i class="svg-icon __clr-danger __top-0 __size-18 __oneway"></i>
              <span class="icon-legend-text" [innerText]="wishCard.Lieferhinweis || ls.locs['loc'].AmpelRot"></span>
            </span>
          </li>
          <li *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasExpress && wishCard.CanExpress" class="display-flex">
            <span>
              <i class="svg-icon __clr-specific-4 __top-0 __size-18 __express"></i>
            </span>
            <span class="icon-legend-text">{{ ls.locs['loc'].ExpressMoeglich }}</span>
          </li>
        </ul>
      </div>
    </a>
    <div (mouseenter)="showIconLegendFnc()" class="border-top position-relative padding-top-12 padding-bottom-16 padding-left-right-16 display-flex flex-align-items-center flex-justify-cont-between">
      <div class="price margin-top-2">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Praemienpunkte }}" alignment="right">
          <i class="svg-icon __clr-default __size-18 __top-4 __points default-cursor-hover margin-right-4"></i>
        </mp-core-tooltip>
        <span [hidden]="!wishCard.ShowAb" class="points-from-span vertical-align-middle padding-top-2">{{ ls.locs['loc'].ab }}</span>
        <span class="icon-text padding-top-2">{{ wishCard.Punkte | integer }} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        <span *ngIf="wishCard.Vergleichspreis && wishCard.ProzentRabatt && wishCard.ProzentRabatt >= 5" class="icon-text price-uvp position-relative margin-left-8 padding-top-2">{{ wishCard.Vergleichspreis | integer }}{{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div class="fav-heart position-absolute right-48 margin-right-4">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AusWunschlisteEntfernen }}" alignment="left">
          <button class="mp-button padding-left-right-8" (click)="deleteItem(wishCard.ID, $event)">
            <i class="svg-icon __size-18 __top-0 __clr-primary __favorite-filled"></i>
          </button>
        </mp-core-tooltip>
      </div>
      <div class="position-absolute right-16">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].WarenkorbHinzufuegen }}" alignment="left" *ngIf="wishCard.CanOrder">
          <button class="mp-button padding-left-right-8" [ngClass]="{disabled: !wishCard.CanOrder}" [disabled]="!wishCard.CanOrder" (click)="addToShoppingbasket(wishCard)">
            <i class="svg-icon __clr-default __top-0 __basket __size-18"></i>
          </button>
        </mp-core-tooltip>
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AmpelRot }}" alignment="left" *ngIf="!wishCard.CanOrder">
          <button class="mp-button padding-left-right-8" *ngIf="!wishCard.CanOrder" [ngClass]="{disabledWK: !wishCard.CanOrder}">
            <i class="svg-icon __clr-inactive __top-0 __basket __size-18"></i>
          </button>
        </mp-core-tooltip>
      </div>
    </div>
  </div>
</article>
