import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the voucher statistic 
 * table.
 */
@Component({
  selector: 'mp-leas-voucher-statistic',
  templateUrl: './voucher-statistic.component.html'
})
export class VoucherStatisticComponent implements OnInit, OnDestroy {

  public editModalOpen: boolean = false;
  public voucher: { [key: string]: any } = {
    errors: []
  };
  public excelService: string = '/api/Gutschein/GutscheinStatistik2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public closeEditModal = this._closeEditModal.bind(this);
  public saveComment = this._saveComment.bind(this);

  private _loading: boolean = false;
  private _leasVoucherStatisticLoader: MpLoader = {
    name: 'leasVoucherStatisticLoader',
    params: {}
  };
  public execClickFunctions: Array<any> = [
    {
      backendName: 'toggleActivation',
      func: this.toggleActivation.bind(this)
    },
    {
      backendName: 'editKommentar',
      func: this.editComment.bind(this)
    }
  ];
  private _saveCommentSubscription: Subscription | undefined;
  private _activateVoucherSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._leasVoucherStatisticLoader);

    this._mpLoaderService.extendLoader('leasVoucherStatisticLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._saveCommentSubscription !== 'undefined') {
      this._saveCommentSubscription.unsubscribe();
    }

    if (typeof this._activateVoucherSubscription !== 'undefined') {
      this._activateVoucherSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasVoucherStatisticLoader');
  }

  /**
   * Saves the set comment.
   */
  private _saveComment(): void {
    if (this._loading) {
      return;
    }

    if (this._validate()) {
      this._loading = true;

      const sendData = {
        Id: this.voucher['ID'],
        Kommentar: this.voucher['KommentarKomplett']
      };

      this._saveCommentSubscription = this._apiService.postRequest('/api/Gutschein/saveKommentar', sendData).subscribe((data: any) => {
        this._loading = false;

        if (data.Result === 'ERROR') {
          this.voucher['errors'].push(data.Message);
          this._mpMessaging.openDangerPanel(data.Message);
        } else {
          this.editModalOpen = false;
          this._mpMessaging.openSuccessPanel(data.Message);

          if (this.loader !== null) {
            if (typeof this.loader.load !== 'undefined') {
              this.loader.load();
            }
          }
        }
      },
      (error: any) => {
        this._loading = false;
      });
    }
  }

  /**
   * Closes the edit modal.
   */
  private _closeEditModal(): void {
    this.editModalOpen = false;
  }

  /**
   * Toggles the activation of the
   * voucher.
   */
  toggleActivation(row: any): void {
    this._activateVoucherSubscription = this._apiService.postRequest('/api/Gutschein/aktivieren', { Id: row['ID'] }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (row['Aktiviert']) {
          this._mpMessaging.openSuccessPanel(this.ls.locs['locLeas'].DerGutscheinWurdeAktiviert);
        } else {
          this._mpMessaging.openSuccessPanel(this.ls.locs['locLeas'].DerGutscheinWurdeDeaktiviert);
        }
      } else {
        this._mpMessaging.openDangerPanel(this.ls.locs['locLeas'].DieAktivierungIstFehlgeschlagen);
      }
    },
    (error: any) => {
      this._mpMessaging.openDangerPanel(this.ls.locs['locLeas'].DieAktivierungIstFehlgeschlagen);
    });
  }

  /**
   * Openes the modal to edit the
   * voucher comment.
   */
  editComment(row: any): void {
    this.editModalOpen = true;
    this.voucher = row;
  }

  /**
   * Validates the voucher.
   */
  private _validate(): boolean {
    if (Object.keys(this.voucher).length === 0) {
      return false;
    }

    this.voucher['errors'] = [];

    if (this.voucher['KommentarKomplett'] && this.voucher['KommentarKomplett'].length > 250) {
      this.voucher['errors'].push(this.ls.locs['locLeas'].SieKoennenHoechstens250ZeichenErfassen);
    }

    return this.voucher['errors'].length === 0;
  }

}
