<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'kontowechselGenehmigen'"></mp-core-infotext>
  <h1 class="mobile-only" [innerText]="ls.locs['loc'].KontowechselGenehmigen"></h1>
  <mp-core-data-table title="{{ls.locs['loc'].KontowechselGenehmigen}}" 
                 infotextShorten="kontowechselGenehmigen" 
                 fieldsAction="/api/Kontowechsel/GetKontowechselAntraegeFields" 
                 listAction="/api/Kontowechsel/GetKontowechselAntraege" 
                 loaderName="confirmAccountChangeLoader" 
                 [recLoadedFunc]="recLoadedFunc"
                 [execClickFunctions]="execClickFunctions"
                 [paging]="true"
                 [showDatePicker]="true"></mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right" [innerText]="ls.locs['loc'].Export"></button>
    </a>
  </div>
</div>
