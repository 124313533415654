import { Pipe, PipeTransform } from '@angular/core';

/**
 * This class formats the given string (e.g. from
 * locs) in the way, that it replaces a part of
 * the string with another given argument.
 *
 * It matches {<number>} or {<number>:} or {<number>.} or {<number>+} or {<number>:<nN>0}
 */
@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(textToReplace: string, args: Array<any>): string {
    let result = textToReplace;
    if (!result) return '';

    for (var i = 0; i < args.length; i++) {
      result = result
        .replace(new RegExp("\\{" + i + "(:.+)?\\}", "g"), args[i])
        .replace(new RegExp("\\{" + i + ":[nN]0\\}", "g"), args[i] && args[i].toLocaleString());
    }

    return result;
  }

}
