import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';

/**
 * This class provides the functions for
 * the faq page.
 */
@Component({
  selector: 'mp-core-faq-page',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit, OnDestroy {

  public isAd: boolean = false;

  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _permissionsService: MpPermissionsService
  ) { }

  /**
   * Sets the title text and the menu
   * highlighting.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('FAQ');
    //mpMenuHighlight.setMenuHighlight('support');

    this._getPermissionsSubscription = this._permissionsService.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.isAd = this._permissionsService.permissions.IsAd;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

}
