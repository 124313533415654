import { Directive, ElementRef } from '@angular/core';

import { ScrollButtonService } from './scroll-button.service';

/**
 * This class updates the scroll position
 * and the scroll buttons of a sidebar, when
 * the user clicks on the rerenderer trigger.
 */
@Directive({
  selector: '[mpCoreScrollButtonRerenderer]'
})
export class ScrollButtonRerendererDirective {

  private _rerendererButtonNative: HTMLElement;

  constructor(private _rerendererButton: ElementRef, private _scrollButtonService: ScrollButtonService) {
    this._rerendererButtonNative = this._rerendererButton.nativeElement;

    this._rerendererButtonNative.addEventListener('click', this._rerendererButtonEventFunc.bind(this), false);
  }

  /**
   * Rerenders scroll position and scroll
   * buttons (show / hide).
   */
  private _rerendererButtonEventFunc(): void {
    setTimeout(() => {
      const dataScrollAreaAttr = this._rerendererButtonNative.getAttribute('data-scroll-area');
      const scrollArea = dataScrollAreaAttr !== null ? document.querySelector(dataScrollAreaAttr) : null;

      if (scrollArea !== null) {
        let scrollAreaParent: any = scrollArea.parentElement;

        if (scrollAreaParent !== null) {
          setTimeout(() => {
            const scrollAreaParentCssHeight = scrollAreaParent.getBoundingClientRect().height;
            const scrollAreaCssHeight = scrollArea.getBoundingClientRect().height;
            const scrollDownButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
            const scrollUpButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);

            if (scrollDownButton !== null && scrollUpButton !== null) {
              if (scrollAreaCssHeight <= scrollAreaParentCssHeight) {
                scrollDownButton.setAttribute('hidden', '');
                scrollUpButton.setAttribute('hidden', '');

                if (!scrollUpButton.classList.contains('buttonHidden')) {
                  scrollUpButton.classList.add('buttonHidden');
                }
              } else {
                scrollDownButton.removeAttribute('hidden');
                scrollUpButton.removeAttribute('hidden');

                if (scrollUpButton.classList.contains('buttonHidden')) {
                  scrollUpButton.classList.remove('buttonHidden');
                }
              }
            }
          }, 700);

          let intervalCounter = 0;

          let scrollAreaParentHeightIntval = setInterval(() => {
            if (typeof window.getComputedStyle(scrollAreaParent).getPropertyValue('height') !== 'undefined') {
              clearInterval(scrollAreaParentHeightIntval);

              const scrollAreaParentCssHeight = scrollAreaParent.getBoundingClientRect().height;
              const scrollAreaCssHeight = scrollArea.getBoundingClientRect().height;
              const scrollDownButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
              const scrollUpButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);
              const scrollItemHeight = this._rerendererButtonNative.getAttribute('scrollItemHeight');
              const scrollToTopZero = this._rerendererButtonNative.getAttribute('scrollTopZero');
              const scrollToPosAttr = this._rerendererButtonNative.getAttribute('scrollToPos');
              const scrollToPos = scrollToPosAttr !== null ? parseInt(scrollToPosAttr) : 0;
              let scrollPos = scrollAreaParent.scrollTop;
              let scrollItemCssHeight = 100;
              const scrollItem = scrollArea.querySelector('.scroll-item');

              if (scrollAreaCssHeight <= scrollAreaParentCssHeight) {
                if (typeof scrollAreaParent.scroll !== 'undefined') {
                  scrollAreaParent.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                } else {
                  scrollAreaParent.scrollTop = 0;
                }
              } else {
                if (scrollItemHeight !== null) {
                  scrollItemCssHeight = parseInt(scrollItemHeight);
                } else if (scrollItem !== null) {
                  scrollItemCssHeight = parseInt(window.getComputedStyle(scrollItem).getPropertyValue('height').replace('px', ''));
                }

                if (scrollToPos === 0) {
                  if (typeof scrollAreaParent.scroll !== 'undefined') {
                    scrollAreaParent.scroll({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  } else {
                    scrollAreaParent.scrollTop = 0;
                  }
                } else {
                  if (scrollToTopZero !== null && typeof scrollToTopZero !== 'undefined' && scrollToTopZero !== 'false') {
                    const errElem: any = scrollArea.querySelector('.invalid-error:first');

                    if (errElem !== null) {
                      if (typeof scrollAreaParent.scroll !== 'undefined') {
                        scrollAreaParent.scroll({
                          top: -(Math.round(parseInt(errElem.offsetTop) / scrollItemCssHeight) * scrollItemCssHeight),
                          left: 0,
                          behavior: 'smooth'
                        })
                      } else {
                        scrollAreaParent.scrollTop = -(Math.round(parseInt(errElem.offsetTop) / scrollItemCssHeight) * scrollItemCssHeight);
                      }

                      setTimeout(function () {
                        const errInput = errElem.querySelector('input');

                        if (errInput !== null) {
                          errInput.focus();
                        }
                      }, 300);
                    } else {
                      if (typeof scrollAreaParent.scroll !== 'undefined') {
                        scrollAreaParent.scroll({
                          top: 0,
                          left: 0,
                          behavior: 'smooth'
                        })
                      } else {
                        scrollAreaParent.scrollTop = 0;
                      }
                    }
                  } else {
                    if (typeof scrollAreaParent.scroll !== 'undefined') {
                      scrollAreaParent.scroll({
                        top: this._rerendererButtonNative.offsetTop,
                        left: 0,
                        behavior: 'smooth'
                      })
                    } else {
                      scrollAreaParent.scrollTop = this._rerendererButtonNative.offsetTop;
                    }
                  }
                }

                setTimeout(() => {
                  scrollPos = scrollAreaParent.scrollTop;

                  if (scrollUpButton !== null && scrollDownButton !== null) {
                    if ((scrollPos - scrollItemCssHeight) <= -scrollItemCssHeight) {
                      if (!scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                        scrollUpButton.classList.add(this._scrollButtonService.inactiveButtonClass)
                      }
                    } else {
                      if (scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                        scrollUpButton.classList.remove(this._scrollButtonService.inactiveButtonClass)
                      }
                    }

                    if (scrollPos >= scrollAreaCssHeight - scrollAreaParentCssHeight) {
                      if (!scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                        scrollDownButton.classList.add(this._scrollButtonService.inactiveButtonClass)
                      }
                    } else {
                      if (scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
                        scrollDownButton.classList.remove(this._scrollButtonService.inactiveButtonClass)
                      }
                    }
                  }
                }, 300);
              }
            }

            if (intervalCounter >= 40) {
              clearInterval(scrollAreaParentHeightIntval);
              return false;
            }

            intervalCounter++;
          }, 100);
        }
      }
    }, 500);
  }
}
