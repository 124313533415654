<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <ul class="tab pointer">
    <li class="text-transform-uppercase bg-color-brand-primary padding-left-16 padding-right-16">{{ ls.locs['loc'].Filter }}</li>
  </ul>
  <div class="side-filter margin-bottom-8 padding-left-right-24 padding-top-24 padding-bottom-0 display-flex flex-direction-column">
    <div class="flex-grid flex-direction-row" *ngIf="options['Kategorien']">
      <div class="bold flex-col-12">{{ ls.locs['locReisekonfigurator'].Hotelkategorie }}</div>
      <div class="flex-col-4 flex-col-6-l" *ngFor="let kategorie of options['Kategorien'] | orderBy:'Sterne'; let index = index">
        <mp-core-checkbox mpId="chkHotelkategorie{{filterId}}{{index}}" [model]="kategorie.Active" (modelChange)="kategorie.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12 margin-left-8" for="chkHotelkategorie{{filterId}}{{index}}">{{kategorie.Sterne}} {{ ls.locs['locReisekonfigurator'].Sterne }}</label>
      </div>
    </div>
    <hr *ngIf="filter['Verpflegungen']" class="margin-top-12 margin-bottom-24">
    <div class="flex-grid flex-direction-row flex-wrap">
      <div class="bold flex-col-12 flex-justify-cont-between pointer" (click)="showCaterings = !showCaterings">
        <div>{{ ls.locs['locReisekonfigurator'].Verpflegung }}</div>
        <div class="svg-icon __clr-default __size-18 __top-0" [ngClass]="showCaterings === true ? '__sidebar-arrow-up' : '__sidebar-arrow-down'"></div>
      </div>
      <div [hidden]="!showCaterings" class="flex-col-12 font-size-s margin-bottom-12">
        <a (click)="selectAll(filter['Verpflegungen'], 'Verpflegungen', $event)">{{ ls.locs['loc'].Alle }}</a> /
        <a (click)="selectNone(filter['Verpflegungen'], 'Verpflegungen', $event)">{{ ls.locs['loc'].Keine }}</a>
      </div>
      <div [hidden]="!showCaterings" class="flex-col-6 flex-col-6-l flex-col-6-xs" *ngFor="let verpflegung of filter['Verpflegungen']; let index = index">
        <mp-core-checkbox mpId="chkVerpflegung{{filterId}}{{index}}" [model]="verpflegung.Active" (modelChange)="verpflegung.Active = $event; filterClicked(true)"></mp-core-checkbox>
        <label class="font-size-s margin-top-12 margin-left-8" for="chkVerpflegung{{filterId}}{{index}}">{{verpflegung.Bezeichnung}}</label>
      </div>
    </div>
    <hr [hidden]="!options['MaxPreis']" class="margin-top-12 margin-bottom-24">
    <div class="flex-grid flex-direction-column" [hidden]="!options['MaxPreis']">
      <div class="padding-left-8 padding-right-8">
        <div class="bold display-inline-block">{{ ls.locs['locReisekonfigurator'].Gesamtbudget }}</div>
        <div class="text display-inline-block text-color-info">
          &nbsp;{{ ls.locs['loc'].BisMax }} {{filter['Punkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </div>
      </div>
      <div class="form-group padding-left-8 padding-right-8" *ngIf="rangeSliderConfigDone">
        <ngx-slider class="mp-ngx-slider"
                    [(value)]="filterPoints"
                    [options]="rangeSliderOptions"
                    (userChangeEnd)="filterClicked(false, $event)">
        </ngx-slider>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasPunktekonto" class="filter-checkbox display-flex">
          <mp-core-checkbox mpId="chkBestellbareReisen{{filterId}}" [model]="orderableTravels" (modelChange)="orderableTravels = $event; onlyOrderableTravels(); filterClicked(false);"></mp-core-checkbox>
          <label class="font-size-s margin-top-4 margin-left-8" for="chkBestellbareReisen{{filterId}}">{{ ls.locs['locReisekonfigurator'].NurReisenImPunktebereich }}</label>
        </div>
      </div>
    </div>
    <hr class="margin-top-8 margin-bottom-24">
    <div class="flex-grid flex-direction-row flex-wrap">
      <div class="bold flex-col-12 flex-justify-cont-between pointer" (click)="showRoomtypes = !showRoomtypes">
        <div>{{ ls.locs['locReisekonfigurator'].Zimmertyp }}</div>
        <div class="svg-icon __clr-default __size-18 __top-0" [ngClass]="showRoomtypes === true ? '__sidebar-arrow-up' : '__sidebar-arrow-down'"></div>
      </div>
      <div [hidden]="!showRoomtypes" class="flex-col-12 font-size-s margin-bottom-12">
        <a (click)="selectAll(filter['Zimmerarten'], 'Zimmerarten', $event)">{{ ls.locs['loc'].Alle }}</a> /
        <a (click)="selectNone(filter['Zimmerarten'], 'Zimmerarten', $event)">{{ ls.locs['loc'].Keine }}</a>
      </div>
      <div [hidden]="!showRoomtypes" class="flex-col-6 flex-col-6-l flex-col-6-xs" *ngFor="let zimmertyp of filter['Zimmerarten']; let index = index">
        <mp-core-checkbox mpId="chkZimmerart{{filterId}}{{index}}" [model]="zimmertyp.Active" (modelChange)="zimmertyp.Active = $event; filterClicked(true)"></mp-core-checkbox>
        <label class="font-size-s margin-top-12 margin-left-8" for="chkZimmerart{{filterId}}{{index}}">{{zimmertyp.Bezeichnung}}</label>
      </div>
    </div>
    <hr class="margin-top-8 margin-bottom-24" [hidden]="!options['Extras'] || options['Extras'].length === 0">
    <div class="flex-grid flex-direction-row flex-wrap" [hidden]="!options['Extras'] || options['Extras'].length === 0">
      <div class="bold flex-col-12 flex-justify-cont-between pointer" (click)="showExtras = !showExtras">
        <div>{{ ls.locs['locReisekonfigurator'].Extras }}</div>
        <div class="svg-icon __clr-default __size-18 __top-0" [ngClass]="showExtras === true ? '__sidebar-arrow-up' : '__sidebar-arrow-down'"></div>
      </div>
      <div [hidden]="!showExtras" class="flex-col-12 font-size-s margin-bottom-12">
        <a (click)="selectAll(options['Extras'], 'Extras', $event)">{{ ls.locs['loc'].Alle }}</a> /
        <a (click)="selectNone(options['Extras'], 'Extras', $event)">{{ ls.locs['loc'].Keine }}</a>
      </div>
      <div [hidden]="!showExtras" class="flex-col-6 flex-col-6-l flex-col-6-xs" *ngFor="let extra of options['Extras']; let index = index">
        <mp-core-checkbox mpId="chkExtra{{filterId}}{{index}}" [model]="extra.Active" (modelChange)="extra.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12 margin-left-8" for="chkExtra{{filterId}}{{index}}">{{extra.Bezeichnung}}</label>
      </div>
    </div>
    <hr class="margin-top-12 margin-bottom-24" [hidden]="!options['Reisekategorien'] || options['Reisekategorien'].length === 0" />
    <div class="flex-grid flex-direction-row flex-wrap" [hidden]="!options['Reisekategorien'] || options['Reisekategorien'].length === 0">
      <div class="bold flex-col-12 margin-bottom-8">{{ ls.locs['locReisekonfigurator'].Reisekategorien }}</div>
      <div *ngFor="let thema of options['Reisekategorien']; trackBy trackByIndex; let index = index" class="WasIstIhnenWichtig flex-col-6 flex-col-6-l flex-col-6-xs" [hidden]="thema.RkKategorieId === categoryParam">
        <input type="checkbox" name="reisethemen-{{index}}" id="reisethema{{filterId}}-{{index}}" [ngModel]="thema.Active" (ngModelChange)="thema.Active = $event; optionClicked()" class="thema-selection" />
        <label for="reisethema{{filterId}}-{{index}}" class="margin-bottom-8 font-size-s display-flex flex-align-items-center global-types-label">
          <span [ngSwitch]="thema.CssClass">
            <i *ngSwitchCase="'winter'" class="svg-icon __size-22 __top--1 __snow margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'familie'" class="svg-icon __size-22 __top--1 __family margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'kreuzfahrt'" class="svg-icon __size-22 __top--1 __ship margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'wellness'" class="svg-icon __size-22 __top--1 __spa margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'luxus'" class="svg-icon __size-22 __top--1 __diamond margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'sport'" class="svg-icon __size-22 __top--1 __sport margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'hochzeit'" class="svg-icon __size-22 __top--1 __rings margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'stadt'" class="svg-icon __size-22 __top--1 __city margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'rundreise'" class="svg-icon __size-22 __top--1 __rotate-right margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'weihnachten'" class="svg-icon __size-22 __top--1 __flare margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'club'" class="svg-icon __size-22 __top--1 __cocktail margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'strand'" class="svg-icon __size-22 __top--1 __beach margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'adult'" class="svg-icon __size-22 __top--1 __people margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'haustiere'" class="svg-icon __size-22 __top--1 __pets margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
            <i *ngSwitchCase="'wlan'" class="svg-icon __size-22 __top--1 __wlan margin-right-8" [ngClass]="thema.Active === true ? '__clr-primary': '__clr-default'"></i>
          </span>
          {{thema.Bezeichnung}}
        </label>
      </div>
    </div>
    <div id="side-filter-footer" class="display-flex flex-direction-column flex-align-items-center">
      <hr class="margin-top-24" />
      <button class="mp-button margin-bottom-8" [ngClass]="{disabled: defaultFilter}" (click)="resetFilter()">
        {{ ls.locs['loc'].AlleFilterZuruecksetzen }}
        <i class="svg-icon __clr-default __size-22 __top--1 margin-left-4 __restore float-none-important"></i>
      </button>
    </div>
  </div>
</div>
