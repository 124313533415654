import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';

import { ZzRoutingModule } from './zz-routing.module';
import { ZzComponent } from './zz.component';
import { AdditionalPaymentOverviewComponent } from './modules/manager/pages/additional-payment-overview/additional-payment-overview.component';
import { AdditionalPaymentPaypalComponent } from './components/additional-payment-paypal/additional-payment-paypal.component';
import { AdditionalPaymentPayplaceComponent } from './components/additional-payment-payplace/additional-payment-payplace.component';
import { AdditionalPaymentPrepaymentComponent } from './components/additional-payment-prepayment/additional-payment-prepayment.component';
import { AdditionalPaymentComponent } from './modules/participant/pages/additional-payment/additional-payment.component';

@NgModule({
  declarations: [
    ZzComponent,
    AdditionalPaymentOverviewComponent,
    AdditionalPaymentPaypalComponent,
    AdditionalPaymentPayplaceComponent,
    AdditionalPaymentPrepaymentComponent,
    AdditionalPaymentComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ZzRoutingModule
  ],
  exports: [
    ZzComponent
  ],
  providers: [],
  bootstrap: [
    ZzComponent
  ]
})
export class ZzModule { }

@NgModule({})
export class ZzSharedModule {
  static forRoot(): ModuleWithProviders<ZzModule> {
    return {
      ngModule: ZzModule,
      providers: []
    }
  }
}
