import { Directive, Input, OnInit, ElementRef, OnChanges, SimpleChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This class provides the functions for
 * the highlightinh of content parts.
 */
@Directive({
  selector: '[mpCoreHighlight]',
  exportAs: 'mpCoreHighlight'
})
export class HighlightDirective implements OnInit, OnChanges {

  @Input() public highlight: string = '';
  @Input() public originalText: string = '';

  public highlightedText: string = '';

  constructor(
    private _elementRef: ElementRef,
    private _sanitizer: DomSanitizer
  ) { }

  /**
   * Calls the function to highlight
   * string part.
   */
  ngOnInit(): void {
    this._doHighlighting();
  }

  /**
   * Watches for changes of the string
   * part to highlight.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['highlight'] !== 'undefined') {
      this._doHighlighting();
    }
  }

  /**
   * Highlights the given string part.
   */
  private _doHighlighting(): void {
    setTimeout(() => {
      if (this.highlight === '') {
        this.highlightedText = '';
      } else {
        const regex = "(" + this.highlight.replace(new RegExp("\\s+"), "|") + ")";
        this.highlightedText = this._trustAsHtml(this.originalText.replace(new RegExp(regex, 'gi'), '<em class="highlight">$&</em>'));
      }
    });
  }

  /*
   * Sanitizer for HTML.
   */
  private _trustAsHtml(str: string): string {
    return this._sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
