<div class="layout-container manager-text-editor" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)"
     id="email-module"
     [ngClass]="{'communication-process position-relative': communicationProcess}"
     mpCoreMpContentFullHeight>
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>

    <div id="nav-breadcrumb"
         class="display-flex flex-col-12 flex-align-items-center margin-bottom-8 padding-left-4">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info"
           [hidden]="communicationProcess"
           (click)="goBack($event)">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <a class="mp-button text-color-grey-info"
           [hidden]="!communicationProcess"
           href="#/{{role}}/Home">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Kommunikation }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular position-relative top-2"
           [hidden]="communicationProcess"
           [innerHTML]="ls.locs['loc'].NachrichtenVerfassenBreadcrumb">
        </a>
        <a class="mp-button text-color-grey-info font-weight-regular position-relative top-2"
           [hidden]="!communicationProcess"
           [innerHTML]="ls.locs['loc'].KommunikationAnlegenBreadcrumb">
        </a>
      </div>
    </div>

    <mp-core-communication-process-stepper *ngIf="communicationProcess"></mp-core-communication-process-stepper>


    <section id="mngr-text-editor-content"
             class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-16">

      <div class="width-full bg-color-white padding-left-right-16 padding-top-16 box-sizing-border-box padding-bottom-40"
           [hidden]="!communicationTypeParams['list'] || communicationTypeParams['list'].length <= 1 ">
        <h3 class="width-full margin-top-8 margin-bottom-16">{{ ls.locs['loc'].EmailtypeTitle }}</h3>
        <div class="width-full margin-top-8">
          <mp-core-sidebar-input class="width-full-important margin-top-16 display-block"
                                 mpPlaceholder="{{ ls.locs['loc'].NachrichtentypAuswaehlen }}"
                                 label="{{ ls.locs['loc'].Nachrichtentyp }}"
                                 [mpRequired]="true"
                                 text="{{communicationTypeParams['text']}}"
                                 sidebarComponent="SidebarMultiselect"
                                 [sidebarParams]="communicationTypeParams">
          </mp-core-sidebar-input>
        </div>
      </div>

      <div class="width-full bg-color-white padding-left-right-16 padding-top-16 box-sizing-border-box padding-bottom-40"
           [ngClass]="communicationTypeParams && communicationTypeParams['list'] && communicationTypeParams['list'].length > 1 ? 'margin-top-24' : ''"
           [hidden]="communicationProcess || (communicationTypeParams && communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length === 0)">
        <h3 class="width-full margin-top-8 margin-bottom-16">{{ ls.locs['loc'].WerSollMailEmpfangen }}</h3>
        <div class="width-full margin-top-8">
          <mp-core-select placeholder="{{ ls.locs['loc'].Empfaenger }}"
                          [elements]="recipientChoiceList"
                          mpId="empfaengerwahl"
                          textProperty="Displaytext"
                          [(model)]="recipientChoice"
                          [mpRequired]="true"
                          valueProperty="Identifier"
                          class="display-block">
          </mp-core-select>
        </div>
        <mp-core-select [hidden]="recipientChoice !== 'Filter'"
                        placeholder="Wähle einen Filter"
                        mpId="ddlFilter"
                        [(model)]="selectedFilter"
                        [elements]="filterList"
                        textProperty="Text"
                        class="margin-top-16 display-block"
                        [hasError]="errorRecipient ? true : false">
        </mp-core-select>
        <mp-core-form-errorstring *ngIf="errorRecipient"
                                  errstring="{{ ls.locs['validate'].FilterRequired }}">
        </mp-core-form-errorstring>
        <div [hidden]="recipientChoice !== 'Auswahl'"
             class="margin-top-16">
          <mp-core-data-table fieldsAction="/api/EMailModul/getTNAuswahlFields"
                              listAction="/api/EMailModul/getTNAuswahl"
                              loaderName="emailModuleEditorLoader"
                              [paging]="true"
                              [footer]="true"
                              [disableCache]="true"
                              defaultSorting="Benutzer"
                              [execChangeFunctions]="execChangeFunctions"
                              [hideColumnSelection]="true"
                              [recLoadedFunc]="recLoadedFunc"
                              [alwaysShowSearch]="true">
          </mp-core-data-table>
        </div>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages">
          <mp-core-checkbox mpId="chkLandSprache"
                            class="margin-top-16 display-block"
                            [(model)]="inputData['showCountryLanguage']"
                            label="{{ ls.locs['loc'].FilterLandSprache }}">
          </mp-core-checkbox>
          <div [hidden]="!inputData['showCountryLanguage']"
               class="margin-top-16">
            <mp-core-sidebar-input class="width-full-important"
                                   label="{{ ls.locs['loc'].Land }}"
                                   text="{{countryParams['text']}}"
                                   sidebarComponent="SidebarMultiselect"
                                   [sidebarParams]="countryParams">
            </mp-core-sidebar-input>

            <mp-core-sidebar-input class="width-full-important margin-top-16 display-block"
                                   label="{{ ls.locs['loc'].Sprache }}"
                                   text="{{languageParams['text']}}"
                                   sidebarComponent="SidebarMultiselect"
                                   [sidebarParams]="languageParams">
            </mp-core-sidebar-input>
          </div>
        </div>
      </div>

      <div class="width-full bg-color-white padding-left-right-16 padding-top-16 padding-bottom-40 box-sizing-border-box"
           [ngClass]="communicationTypeParams && communicationTypeParams['list'] && communicationTypeParams['list'].length > 1 || !communicationProcess ? 'margin-top-32' : ''"
           [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length === 0">
        <h3 class="width-full margin-top-8 margin-bottom-16">{{ ls.locs['loc'].WieLautetIhreNachricht }}</h3>
        <div [hidden]="communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier === 'sms'">
          <mp-core-input type="text"
                         id="txtBetreff"
                         label="{{ ls.locs['loc'].Betreff }}"
                         [mpRequired]="true"
                         mpPlaceholder="{{ ls.locs['loc'].Betreff }} {{ ls.locs['loc'].VerfassenKlein }}"
                         class="margin-top-16 display-block"
                         [(model)]="inputData['subject']"
                         [hasError]="errorSubject ? true : false">
          </mp-core-input>
          <mp-core-form-errorstring *ngIf="errorSubject"
                                    errstring="{{ ls.locs['validate'].SubjectRequired }}">
          </mp-core-form-errorstring>
        </div>
        <div *ngIf="mpSettings.settings['EMailModulSettings'].ShowPreheader"
             [hidden]="communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier === 'sms'">
          <mp-core-input type="text"
                         id="txtPreheader"
                         label="{{ ls.locs['loc'].Preheader }}"
                         [mpRequired]="true"
                         mpPlaceholder="{{ ls.locs['loc'].Preheader }} {{ ls.locs['loc'].VerfassenKlein }}"
                         class="margin-top-16 display-block"
                         [maxLength]="150"
                         [(model)]="inputData['preheader']">
          </mp-core-input>
        </div>
        <mp-core-select mpId="ddlTBS"
                        class="tbs margin-top-16 display-block position-relative z-index-3"
                        [(model)]="selectedTBS"
                        placeholder="{{ ls.locs['loc'].Textbausteineinfuegen }}"
                        (ngChange)="tbsChange(selectedTBS)"
                        textProperty="Text"
                        [elements]="tbsList"
                        (pointerdown)="storeEditorSelection()">
        </mp-core-select>
        <div id="editor-Container"
             class="width-full padding-left-right-0 margin-top-16 margin-bottom-0 editor-froala"
             [attr.has-error]="errorBody ? true : false">
          <div>
            <div class="display-block" *ngIf="froalaOptionsLoaded" [hidden]="(communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier === 'sms')">
              <textarea class="form-control margin-top-0"
                        [froalaEditor]="froalaOptions"
                        (froalaInit)="getFroalaControls($event)"
                        [(froalaModel)]="inputData['mailBody']"
                        id="editor1"
                        name="editor1"
                        [attr.placeholder]="ls.locs['loc'].Nachrichtentext + ' ' + ls.locs['loc'].VerfassenKlein">
              </textarea>
              <mp-core-form-errorstring *ngIf="errorBody"
                                        errstring="{{ ls.locs['validate'].BodyRequired }}">
              </mp-core-form-errorstring>
            </div>
            <div class="display-block fr-box" [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length === 0 || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier !== 'sms'">
              <mp-core-textarea mpId="sms-editor"
                                mpPlaceholder="{{ ls.locs['loc'].Nachrichtentext }} {{ ls.locs['loc'].VerfassenKlein }}"
                                [(model)]="mailBody"
                                [hasError]="errorBody ? true : false"
                                [maxlength]="320">
              </mp-core-textarea>
              <mp-core-form-errorstring *ngIf="errorBody"
                                        errstring="{{ ls.locs['validate'].BodyRequired }}">
              </mp-core-form-errorstring>
              <div class="fr-second-toolbar">
                <span class="fr-counter">{{ ls.locs['loc'].XvonYZeichen | format:[(mailBody || '').length,320] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length === 0 || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier !== 'sms'">
          <mp-core-micro-copy infoState="inactive"
                              copyText="{{ ls.locs['loc'].SmsKostenHinweis }}"
                              class="margin-top-16">
          </mp-core-micro-copy>
        </div>
        <div [hidden]="communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length === 1 && communicationTypeParams['selectedList'][0].Identifier === 'eilmeldung'">
          <div class="width-full">
            <button type="button"
                    id="btn-upload-pdf"
                    class="mp-button _prim nonefloat distanceforButton width-full margin-top-16"
                    (click)="fileUpload.click()">
              {{ ls.locs['loc'].DateianhangHinzufuegen }}
            </button>
            <input #fileUpload
                   name="fileUpload"
                   type="file"
                   class="display-none"
                   accept="image/jpeg,image/jpg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip"
                   (change)="addAttachment($event)">
          </div>
          <span [hidden]="attachments['length'] === 0 "
                class="FileAttach bold width-full display-block margin-top-16">
            {{ ls.locs['loc'].Dateianheange }}:
          </span>
          <div *ngFor="let file of attachments; let index = index"
               [attr.id]="'fileContainer' + index"
               class="flex-grid margin-top-16">
            <div class="flex-col-8 flex-col-6-l flex-col-12-m flex-col-6-s flex-align-items-center">
              <span class="svg-icon __clr-success __top-0 __size-18 __check-circle default-cursor-hover float-none-important"></span>
              <mp-core-input id="txtFilePath{{index}}"
                             [model]="file" class="display-block"
                             [ngClass]="'FileAttach'+index"
                             type="text" [mpDisabled]="true"></mp-core-input>
            </div>
            <div class="flex-col-4 flex-col-6-l flex-col-12-m flex-col-6-s mail-file-delete-button flex-align-items-center flex-justify-cont-end">
              <button class="mp-button _prim-text"
                      (click)="deleteAttachment(index)">
                {{ ls.locs['loc'].DateiEntfernen }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="mngr-text-editor-datapanel"
             class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-16">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20">
        <span [hidden]="!dateFixedByCp"
              class="width-full margin-top-8">
          Wird am Gutschrifttag gesendet
        </span>
        <mp-core-sidebar-input class="width-full-important margin-top-8"
                               [hidden]="dateFixedByCp"
                               text="{{executionDate['text']}}"
                               label="{{ ls.locs['loc'].Versanddatum }}"
                               sidebarComponent="SidebarDateTime"
                               [sidebarParams]="executionDate"
                               [mpRequired]="true"
                               [hasError]="errorDate ? true : false">
        </mp-core-sidebar-input>
        <mp-core-form-errorstring *ngIf="errorDate"
                                  errstring="{{ ls.locs['loc'].EmailModulDatumRequired }}">
        </mp-core-form-errorstring>
        <mp-core-checkbox *ngIf="mpSettings.settings['EMailModulSettings'].ShowNewsletterCheckbox"
                          class="width-full margin-bottom-16 margin-top-24 display-block"
                          mpId="chkNewsletter"
                          [(model)]="inputData['isNewsletter']"
                          label="{{ ls.locs['loc'].EmailIstNewsletter }}">
        </mp-core-checkbox>
        <div [hidden]="!emailTypes || emailTypes.length <= 1 "
             class="width-full margin-top-8">
          <mp-core-select class="width-full margin-top-8 display-block"
                          mpId="ddl-emailtypes"
                          [(model)]="emailTypeId"
                          placeholder="{{ ls.locs['loc'].EmailTypAuswhaehlen }}"
                          [elements]="emailTypes"
                          valueProperty="Id"
                          textProperty="Bezeichnung">
          </mp-core-select>
        </div>
        <div [hidden]="!communicationProcess">
          <div class="width-full float-right margin-top-40">
            <button class="mp-button ng-scope float-right"
                    *ngIf="cp !== null"
                    (click)="cp.showResetConfirm(loadStep)">
              <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
                 id="mngr-text-editor-refresh-icon">
              </i>
            </button>
          </div>
          <button class="mp-button _sec margin-top-16 width-full"
                  (click)="saveCommunicationProcess()">
            {{ ls.locs['loc'].Speichern }}
          </button>
          <a class="mp-button _prim margin-top-16 width-full"
             (click)="goToNextStep($event)"
             *ngIf="cp !== null && cp.currentStep !== null"
             [ngClass]="{disabled: !validateStep()}">
            {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
          </a>
        </div>
        <div [hidden]="communicationProcess"
             class="width-full">
          <div class="width-full float-right margin-top-40">
            <button class="mp-button ng-scope float-right"
                    (click)="reset()">
              <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
                 id="mngr-text-editor-refresh-icon">
              </i>
            </button>
          </div>
          <button class="mp-button _sec margin-top-16 width-full"
                  (click)="toggleOpenNew()">
            {{ ls.locs['loc'].EmailmodulOpenNew }}
          </button>
          <button class="mp-button _sec margin-top-16 width-full"
                  (click)="toggleSaveEmail()">
            {{ ls.locs['loc'].Speichern }}
          </button>
          <button class="mp-button _prim margin-top-16 width-full"
                  [hidden]="inputData['approved']"
                  [ngClass]="{disabled: !validate(false, true)}"
                  [disabled]="!validate(false, true)"
                  (click)="toggleSendEmail()">
            {{ ls.locs['loc'].VorschauAnsehen }}
          </button>
          <button class="mp-button _prim margin-top-16 width-full"
                  [hidden]="!inputData['approved'] || !loadedEMail || loadedEMail && !loadedEMail['Id']"
                  (click)="toggleRemoveApproval()">
            {{ ls.locs['loc'].EmailmodulConfirmFreigabeEntziehenTitle }}
          </button>
          <button *ngIf="isAdmin"
                  class="mp-button _prim margin-top-16 width-full"
                  [hidden]="!loadedEMail || loadedEMail && !loadedEMail['Id']"
                  (click)="toggleDeleteEmail()">
            {{ ls.locs['loc'].EmailmodulConfirmDeleteTitle }}
          </button>
        </div>
      </div>
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20 margin-top-24"
           [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'].length === 0 || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier !== 'email' ">
        <h3>{{ ls.locs['loc'].WerSollDieTestnachrichtErhalten }}</h3>
        <mp-core-input type="text"
                       mpId="emailtestempfaenger"
                       mpPlaceholder="{{ ls.locs['loc'].EMailAdressedesTestempfaengers }}"
                       id="txtTestEMail"
                       class="margin-top-8 width-full display-block"
                       [hasError]="errorTestRecipient ? true : false"
                       [(model)]="testMailAddress">
        </mp-core-input>
        <mp-core-form-errorstring *ngIf="errorTestRecipient"
                                  errstring="{{ ls.locs['loc'].ErrorTestEmpfaenger }}">
        </mp-core-form-errorstring>
        <button class="mp-button _sec margin-top-16 width-full"
                alt=""
                (click)="sendTestEMail()">
          {{ ls.locs['locButtons'].TestNachrichtSenden }}
        </button>
      </div>
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20 margin-top-24"
           [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'].length === 0 || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier !== 'eilmeldung'">
        <h3>{{ ls.locs['loc'].TestEilmeldungTitle }}</h3>
        <button class="mp-button _sec margin-top-16 width-full"
                alt=""
                (click)="toggleShowNewsflash()">
          {{ ls.locs['loc'].TestEilmeldungButtonText }}
        </button>
      </div>

      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20 margin-top-24"
           [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'].length === 0 || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier !== 'postfach' ">
        <h3>{{ ls.locs['loc'].TestPostfachnachrichtVersenden }}</h3>
        <mp-core-input type="text"
                       mpPlaceholder="{{ ls.locs['loc']['BenutzernameDesTestempfaengers'] }}"
                       class="margin-top-8 width-full display-block"
                       [hasError]="errorTestInboxMessage['hasError'] ? true : false"
                       [(model)]="testInboxData['Benutzer']"
                       (blur)="saveTestInbox()">
        </mp-core-input>
        <mp-core-form-errorstring *ngIf="errorTestInboxMessage['hasError']"
                                  errstring="{{errorTestInboxMessage['errMessage']}}">
        </mp-core-form-errorstring>
        <button class="mp-button _sec margin-top-16 width-full"
                alt=""
                (click)="sendTestInboxMessage()">
          {{ ls.locs['locButtons'].TestNachrichtSenden }}
        </button>
      </div>

      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20 margin-top-24"
           [hidden]="!communicationTypeParams['selectedList'] || communicationTypeParams['selectedList'].length === 0 || communicationTypeParams['selectedList'] && communicationTypeParams['selectedList'].length > 0 && communicationTypeParams['selectedList'][0].Identifier !== 'sms'">
        <h3>{{ ls.locs['loc'].TestSmsVersenden }}</h3>
        <mp-core-input type="text"
                       mpPlaceholder="{{ ls.locs['loc'].BenutzernameDesTestempfaengers }}"
                       class="margin-top-8 width-full"
                       [hasError]="errorTestSmsMessage['hasError'] ? true : false"
                       [(model)]="testSmsData['Benutzer']"
                       (blur)="saveTestSms()">
        </mp-core-input>
        <mp-core-form-errorstring *ngIf="errorTestSmsMessage['hasError']"
                                  errstring="{{errorTestSmsMessage['errMessage']}}">
        </mp-core-form-errorstring>
        <button class="mp-button _sec margin-top-16 width-full"
                alt=""
                (click)="sendTestSmsMessage()">
          {{ ls.locs['locButtons'].TestSmsSenden }}
        </button>
      </div>

    </section>
    <div *ngIf="communicationProcess"
         class="flex-grid width-full-important hide-on-mobile position-absolute bottom-48 left-0">
      <div class="flex-col-9 flex-col-12-l flex-col-12-m padding-left-right-16 display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           (click)="showClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
        <div>
          <a class="mp-button margin-right-16"
             *ngIf="cp !== null && cp.currentStep !== null"
             (click)="goToPreviousStep($event)">
            {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
          </a>
          <a class="mp-button _prim float-right"
             (click)="goToNextStep($event)"
             *ngIf="cp !== null && cp.currentStep !== null"
             [ngClass]="{disabled: !validateStep()}">
            {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
          </a>
        </div>
      </div>
    </div>
    <div stickyBottom
         *ngIf="communicationProcess"
         class="bestellung-nav-row floating-container show-on-mobile bg-color-white padding-top-bottom-0 width-full text-align-center wk-bottom-buttons">
      <a class="mp-button _prim padding-top-bottom-8 width-full display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
         (click)="goToNextStep($event)"
         [ngClass]="{disabled: !validateStep()}">
        <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
        <span *ngIf="cp !== null && cp.currentStep !== null" class="display-block">{{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}</span>
      </a>
      <div class="width-full padding-left-right-8 box-sizing-border-box display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           *ngIf="cp !== null && cp.currentStep !== null"
           (click)="goToPreviousStep($event)">
          {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
        </a>
        <a class="mp-button"
           (click)="showClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
      </div>
    </div>
  </div>

  <mp-core-modal [show]="confirmSave"
            title="{{ ls.locs['loc'].EmailmodulConfirmSave }}"
            [buttons]="[{text: ls.locs['loc'].Nein, action: toggleSaveEmail}, {text: ls.locs['loc'].SpeichernNichtVersenden, action: saveEmail, class: '_prim'}]">

    <div [hidden]="(recipientChoice !== 'Auswahl' || chosenRecipients['length'] === 0) && (recipientChoice !== 'Filter' || selectedFilter['Value'] === '-1')">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Empfaenger }}</h3>

        <div [hidden]="recipientChoice !== 'Auswahl' || chosenRecipients['length'] === 0 ">
          <div [innerText]="chosenRecipients['length'] + ' Empfänger'"
               class="font-size-s">
          </div>
        </div>

        <div [hidden]="recipientChoice !== 'Filter' || selectedFilter['Value'] === '-1' ">
          <div [innerText]="selectedFilter['Text']"
               class="font-size-s">
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="!inputData['showCountryLanguage']">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Laender }}</h3>
        <div [innerText]="countryParams['Text']"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div [hidden]="!inputData['showCountryLanguage']">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Sprache }}</h3>
        <div [innerText]="languageParams['Text']"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div class="margin-bottom-16">
      <h3 class="bold">{{ ls.locs['loc'].Betreff }}</h3>
      <div [innerHTML]="inputData['subject']"
           class="font-size-s">
      </div>
    </div>

    <div class="margin-bottom-16">
      <h3 class="bold">{{ ls.locs['loc'].Nachricht }}</h3>
      <div [innerHTML]="inputData['mailBody']"
           class="font-size-s">
      </div>
    </div>

    <div [hidden]="hideExecutionDate">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].VersendenAm }}</h3>
        <div [innerText]="executionDate['text'] + ' '+ ls.locs['loc'].Uhr"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div [hidden]="attachments.length === 0 || attachments[0] === ''">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Dateianheange }}</h3>
        <div *ngFor="let file of attachments; let index = index"
             [attr.id]="'fileContainer' + index"
             [innerText]="file"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div class="margin-bottom-16" *ngIf="mpSettings.settings['EMailModulSettings'].ShowNewsletterCheckbox">
      <h3 class="bold">{{ ls.locs['loc'].Newsletter }}</h3>
      <div class="font-size-s">{{(inputData['isNewsletter'] ? ls.locs['loc'].Ja : ls.locs['loc'].Nein)}}</div>
    </div>

  </mp-core-modal>

  <mp-core-modal [show]="confirmRemoveApproval"
            title="{{ ls.locs['loc'].EmailmodulConfirmFreigabeEntziehenTitle }}"
            [buttons]="[{text: ls.locs['loc'].Nein, action: toggleRemoveApproval}, {text: ls.locs['loc'].Ja, action: removeApproval, class: '_prim'}]">

    {{ ls.locs['loc'].EmailmodulConfirmFreigabeEntziehenText}}
  </mp-core-modal>

  <mp-core-modal [show]="confirmDelete"
            title="{{ ls.locs['loc'].EmailmodulConfirmDeleteTitle}}"
            [buttons]="[{text: ls.locs['loc'].Nein, action: toggleDeleteEmail}, {text: ls.locs['loc'].Ja, action: deleteEmail, class: '_prim'}]">
    {{ ls.locs['loc'].EmailmodulConfirmDeleteText }}
  </mp-core-modal>

  <mp-core-modal [show]="confirmOpenNew"
            title="{{ ls.locs['loc'].EmailmodulConfirmOpenNewTitle }}"
            [buttons]="[{text: ls.locs['loc'].Nein, action: toggleOpenNew}, {text: ls.locs['loc'].Ja, action: openNew, class: '_prim'}]">
    <span>{{ ls.locs['loc'].EmailmodulConfirmOpenNewText }}</span>
  </mp-core-modal>

  <mp-core-modal [show]="showNewsflash"
            title="{{inputData['subject']}}"
            [buttons]="[{text: ls.locs['loc'].Ok, action: toggleShowNewsflash, class: '_prim'}]">
    <span [innerHTML]="inputData['mailBody']"></span>
  </mp-core-modal>

  <mp-core-modal [show]="confirmSend"
            title="{{ ls.locs['loc'].EmailmodulConfirmSend }}"
            [buttons]="[{text: ls.locs['loc'].Bearbeiten, action: toggleSendEmail}, {text: ls.locs['loc'].Freigeben, action: sendEMail, class: '_prim'}]">

    <div class="margin-bottom-16">
      <h3 class="bold">{{ ls.locs['loc'].Empfaenger }}</h3>

      <div [hidden]="recipientChoice !== 'Auswahl' || chosenRecipients['length'] === 0 ">
        <div [innerText]="chosenRecipients['length'] + ' Empfänger'"
             class="font-size-s">
        </div>
      </div>

      <div [hidden]="recipientChoice !== 'Filter' || selectedFilter['Value'] === '-1' ">
        <div [innerText]="selectedFilter['Text']"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div [hidden]="!inputData['showCountryLanguage']">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Laender }}</h3>
        <div [innerText]="countryParams['Text']"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div [hidden]="!inputData['showCountryLanguage']">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Sprache }}</h3>
        <div [innerText]="languageParams['Text']"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div class="margin-bottom-16">
      <h3 class="bold">{{ ls.locs['loc'].Betreff }}</h3>
      <div [innerHTML]="inputData['subject']"
           class="font-size-s">
      </div>
    </div>

    <div class="margin-bottom-16">
      <h3 class="bold">{{ ls.locs['loc'].Nachricht }}</h3>
      <div [innerHTML]="inputData['mailBody']"
           class="font-size-s">
      </div>
    </div>

    <div class="margin-bottom-16">
      <h3 class="bold">{{ ls.locs['loc'].VersendenAm }}</h3>
      <div [innerText]="executionDate['text'] + ' '+ls.locs['loc'].Uhr"
           class="font-size-s">
      </div>
    </div>

    <div [hidden]="attachments.length === 0 || attachments[0] === ''">
      <div class="margin-bottom-16">
        <h3 class="bold">{{ ls.locs['loc'].Dateianheange }}</h3>
        <div *ngFor="let file of attachments; let index = index"
             [attr.id]="'fileContainer' + index"
             [innerText]="file"
             class="font-size-s">
        </div>
      </div>
    </div>

    <div class="margin-bottom-16" *ngIf="mpSettings.settings['EMailModulSettings'].ShowNewsletterCheckbox">
      <h3 class="bold">{{ ls.locs['loc'].Newsletter }}</h3>
      <div class="font-size-s">{{(inputData['isNewsletter'] ? ls.locs['loc'].Ja : ls.locs['loc'].Nein)}}</div>
    </div>
  </mp-core-modal>
</div>
