import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from "d3";

import { MpD3StylesService } from './../mp-d3-styles.service';
import { MpDebounceService } from './../../../services/mp-debounce.service';
import { ApiService } from './../../../services/api.service';
import { MpLoaderService } from './../../../services/mp-loader.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';

import { MpLoader } from './../../../services/interfaces/mp-loader';

/**
 * This class provides a d3 area chart.
 */
@Component({
  selector: 'mp-core-d3-area-chart',
  templateUrl: './d3-area-chart.component.html',
  styleUrls: ['./d3-area-chart.component.scss']
})
export class D3AreaChartComponent implements OnInit {

  @Input() public mpid: string = '';
  @Input() public width: number = 600;
  @Input() public height: number = 400;
  @Input() public dataurl: string = '';
  @Input() public textxachse: string = '';
  @Input() public textyachse: string = '';
  @Input() public loader: string | undefined;

  private _fillcolor: string = '';
  private _linecolor: string = '';

  constructor(
    private _mpD3StylesService: MpD3StylesService,
    private _mpDebounce: MpDebounceService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService,
    private _chartElem: ElementRef,
    private _mpMessaging: MpMessagingService
  ) { }
  
  /**
   * Loads the colors and the data
   * for the chart.
   */
  ngOnInit(): void {
    this._fillcolor = this._mpD3StylesService.mpD3Styles().d3ChartAreaStyles.fillcolor;
    this._linecolor = this._mpD3StylesService.mpD3Styles().d3ChartAreaStyles.linecolor;

    let parentLoader: MpLoader;

    const load = this._mpDebounce.debounce(() => {
      const p = (typeof (this.loader) === 'undefined' ? {} : parentLoader.params);

      this._apiService.postRequest(this.dataurl, p).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._drawChart(data.Records);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
      });
    }, 200);

    if (typeof this.loader !== 'undefined') {
      if (this._mpLoaderService.loaderExists(this.loader)) {
        parentLoader = this._mpLoaderService.getLoader(this.loader);

        this._mpLoaderService.extendLoader(parentLoader.name, {
          load: load
        });
      }
    }

    load();
  }

  /**
   * Draws the d3 chart.
   */
  private _drawChart(inputData: any) {
    if (typeof inputData === 'undefined' || typeof inputData !== 'undefined' && typeof inputData[0] === 'undefined')
      return;

    inputData.map(
      (d: any) => {
        if (d.xDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.xDate);
        if (d.yDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.yDate);
      });

    const margin = { top: 10, right: 10, bottom: 80, left: 80 };
    const widthChart = this.width - margin.left - margin.right;
    const heightChart = this.height - margin.top - margin.bottom;

    d3.select('#' + this.mpid)
      .selectAll('*')
      .remove();

    const svg = d3.select('#' + this.mpid)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // @ts-ignore
    let x;

    if (inputData[0] && inputData[0].xDate) {
      x = d3
        .scaleTime()
        // @ts-ignore
        .domain(d3.extent(inputData, (d: any) => { return d.xDate; }))
        .range([0, widthChart]);
    } else if (inputData[0] && inputData[0].xInt) {
      x = d3
        .scaleLinear()
        // @ts-ignore
        .domain(d3.extent(inputData, (d: any) => { return d.xInt; }))
        .range([0, widthChart]);
    } else if (inputData[0] && inputData[0].xDecimal) {
      x = d3
        .scaleLinear()
        // @ts-ignore
        .domain(d3.extent(inputData, (d: any) => { return d.xDecimal; }))
        .range([0, widthChart]);
    } else if (inputData[0] && inputData[0].xString) {
      x = d3
        .scaleBand()
        // @ts-ignore
        .domain(_.map(inputData, (d: any) => { return d.xString; }))
        .range([0, widthChart]);
    };

    svg
      .append('g')
      .attr('transform', 'translate(0,' + heightChart + ')')
      // @ts-ignore
      .call(d3.axisBottom(x));

    if (this.textxachse) {
      svg.append("text")
        .attr("transform", "translate(" + (widthChart / 2) + " ," + (heightChart + margin.top + 20) + ")")
        .style("text-anchor", "middle")
        .text(this.textxachse);
    }

    // @ts-ignore
    let y;

    if (inputData[0] && inputData[0].yDate) {
      y = d3
        .scaleTime()
        // @ts-ignore
        .domain([0, d3.max(inputData, (d: any) => { return d.yDate; })])
        .range([heightChart, 0]);
    } else if (inputData[0] && inputData[0].yInt) {
      y = d3
        .scaleLinear()
        // @ts-ignore
        .domain([0, d3.max(inputData, (d: any) => { return d.yInt; })])
        .range([heightChart, 0]);
    } else if (inputData[0] && inputData[0].yInt) {
      y = d3
        .scaleLinear()
        // @ts-ignore
        .domain([0, d3.max(inputData, (d: any) => { return d.yDecimal; })])
        .range([heightChart, 0]);
    };

    svg
      .append('g')
      // @ts-ignore
      .call(d3.axisLeft(y));

    if (this.textyachse) {
      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - (heightChart / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(this.textyachse);
    }

    var area = d3.area()
      .x((d: any) => {
        // @ts-ignore
        if (d.xDate) return x(d.xDate);
        // @ts-ignore
        if (d.xInt) return x(d.xInt);
        // @ts-ignore
        if (d.xDecimal) return x(d.xDecimal);
        // @ts-ignore
        if (d.xString) return x(d.xString);
        // @ts-ignore
        return x(0);
      })
      // @ts-ignore
      .y0(y(0))
      .y1((d: any) => {
        // @ts-ignore
        if (d.yDate) return y(d.yDate);
        // @ts-ignore
        if (d.yInt) return y(d.yInt);
        // @ts-ignore
        if (d.yDecimal) return y(d.yDecimal);
        // @ts-ignore
        return y(0);
      });

    svg.append('path')
      .datum(inputData)
      .attr('fill', this._fillcolor)
      .attr('stroke', this._linecolor)
      .attr('stroke-width', 1.5)
      .attr('d', area);
  }

}
