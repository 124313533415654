import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

/**
 * Provides the card for the travelogues
 * card list (in slider).
 */
@Component({
  selector: 'mp-rk-travelogue-card-list-card',
  templateUrl: './travelogue-card-list-card.component.html',
  styleUrls: ['./../../../../mp.Core/app/components/card/styles/card.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TravelogueCardListCardComponent implements OnInit {

  @Input() public travelogue: { [key: string]: any } = {};

  public rolePath: string = '';

  private _role: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the role for the url of the
   * travelogue.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = this._roleMapping.getMappedRole(window.location.href.replace(window.location.origin, '').split('/')[2]) === '' ? '' : window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    if (this._role === '') {
      this.rolePath = '#';
    } else {
      this.rolePath = `/${this._role}`;
    }
  }

}
