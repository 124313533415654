import { Component, OnInit } from '@angular/core';
import { Options } from 'angular-datetimerangepicker';
import { MpLoader } from './../../../../services/interfaces/mp-loader';
import { MpLoaderService } from './../../../../services/mp-loader.service';

@Component({
  selector: 'mp-core-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.scss']
})
export class ShowroomComponent implements OnInit {

  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  public daterangepickerOptions: Options = {
    addTouchSupport: true,
    showRanges: false,
    hideControls: true,
    singleCalendar: true,
    disableWeekEnds: false,
    disabledDays: [],
    disabledDates: []
  };

  private _loader: MpLoader = {
    name: 'showroomPunktestand',
    params: {
      Suchtexte: [],
      Suchtext: ''
    }
  };

  constructor(
    private _mpLoaderService: MpLoaderService
  ) { }

  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._loader);
    this._mpLoaderService.extendLoader('showroomPunktestand', {
      isReady: true
    });
  }

  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('adminAccountStatisticLoader');
  }

  rangeSelected(evt: any): void {
    console.log(evt);
  }

}
