import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';

/**
 * This class provides the functions to change
 * the password.
 */
@Component({
  selector: 'mp-core-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  @ViewChild('formPassword') formPassword: any;

  public pwd: { [key: string]: any } = {
    OldPassword: '',
    NewPassword: '',
    ConfirmPassword: ''
  };
  public errors: { [key: string]: any } = {};

  private _savePasswordSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._savePasswordSubscription !== 'undefined') {
      this._savePasswordSubscription.unsubscribe();
    }
  }

  /**
   * Empties the password fields.
   */
  private _undoPassword(): void {
    this.pwd = {
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: ''
    };

    this.errors = {};
    this.formPassword.control.markAsPristine();
  }

  /**
   * Saves the new password.
   */
  savePassword(): void {
    this._savePasswordSubscription = this._apiService.postRequest('/api/Teilnehmer/ChangePassword', this.pwd).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this._undoPassword();
    },
    (error: any) => {
      if (error.status === 400) {
        this.errors = error.ModelState;
        this.formPassword.control.markAsDirty();
      }
    });
  }

}
