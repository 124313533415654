import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides a sidebar with a
 * calendar to select a date.
 */
@Component({
  selector: 'mp-core-sidebar-date',
  templateUrl: './sidebar-date.component.html',
  styleUrls: ['./sidebar-date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarDateComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {
    date: null
  };
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public yearRange: any[] = [];

  private _now: moment.Moment | undefined;
  private _minYearDif: number = 16;
  private _maxYearDif: number = 100;
  private _defaultYearDif: number = 30;
  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(public ls: MpLocalizationService, public mpSidebar: MpSidebarService) { }

  /**
   * Watches for sidebar params changes of
   * and triggers updating year and year range.
   * Sets the current date.
   */
  ngOnInit(): void {
    this._now = moment();
    this._setYearRange();
    this.yearChange();

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this._setYearRange();
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Calculates and sets the range of the
   * slectable years.
   */
  private _setYearRange(): void {
    if (typeof this.mpSidebar.params['minYearDif'] !== 'undefined') {
      this._minYearDif = this.mpSidebar.params['minYearDif'];
    }

    if (typeof this.mpSidebar.params['maxYearDif'] !== 'undefined') {
      this._maxYearDif = this.mpSidebar.params['maxYearDif'];
    }

    if (typeof this.mpSidebar.params['defaultYearDif'] !== 'undefined') {
      this._defaultYearDif = this.mpSidebar.params['defaultYearDif'];
    }

    if (this._defaultYearDif > this._maxYearDif) {
      this._defaultYearDif = this._maxYearDif;
    }

    if (this._defaultYearDif < this._minYearDif) {
      this._defaultYearDif = this._minYearDif;
    }

    if (typeof this._now === 'undefined') {
      this._now = moment();
    }

    const minYear = this._now.year() - this._maxYearDif;
    const maxYear = this._now.year() - this._minYearDif;
    this.yearRange = [];

    if (minYear > maxYear) {
      for (let loopCnt = minYear; loopCnt >= maxYear; loopCnt--) {
        this.yearRange.push(moment().set('year', loopCnt).year());
      }
    } else if (minYear < maxYear) {
      for (let loopCnt = minYear; loopCnt <= maxYear; loopCnt++) {
        this.yearRange.push(moment().set('year', loopCnt).year());
      }
    }

    this.selection = Object.assign(this.selection, {
      year: typeof this.mpSidebar.params['date'] !== 'undefined' ? this.mpSidebar.params['date'].year() : this._now.year() - (typeof this.mpSidebar.params['age'] !== 'undefined' ? this.mpSidebar.params['age'] : this._defaultYearDif),
      date: typeof this.mpSidebar.params['date'] !== 'undefined' ? this.mpSidebar.params['date'] : this.selection['date']
    });
  }

  /**
   * Triggered, when date selection is changed.
   * Sets the age by currently selected date.
   */
  dateChange(): void {
    if (typeof this._now === 'undefined') {
      this._now = moment();
    }

    this.selection['age'] = this._now.diff(this.selection['date'], 'year');
  }

  /**
   * Triggered, when year selection is changed.
   * Sets the new min and max dates and changes
   * the selected day, if year is not leap year.
   */
  yearChange(): void {
    this.minDate = moment([this.selection['year'], 0, 1]);
    this.maxDate = moment([this.selection['year'], 11, 31, 23, 59]);

    if (typeof this._now === 'undefined') {
      this._now = moment();
    }

    if (this.selection['year'] == this._now.year() && typeof this.mpSidebar.params['maxDate'] !== 'undefined') {
      this.maxDate = this.mpSidebar.params['maxDate'];
    }

    const newYear = moment(this.selection['year'] + '-01-01');
    const isLeapYear = newYear.isLeapYear;

    if (typeof this.selection['date'] !== 'undefined' && this.selection['date'] !== null) {
      const month = this.selection['date'].month();
      const day = this.selection['date'].date();

      if (!isLeapYear && month === 1 && day === 29) {
        this.selection['date'].date(28);
      }

      this.selection['date'].year(this.selection['year']);
    }
  }

  /**
   * Merges the selection with the sidebar
   * params and triggers the save function,
   * if exists.
   */
  save(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['date'] = this.selection['date'];
    this.mpSidebar.params['age'] = this.selection['age'];
    this.mpSidebar.params['text'] = this.mpSidebar.params['date'] ? this.mpSidebar.params['date'].format('DD.MM.YYYY') : '';

    // wird in LeistungMeldung.js abgefangen
    //$rootScope.$broadcast("SendSelectedDateBool", true);

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

  /**
   * Resets the selected date.
   */
  resetDate(event: MouseEvent): void {
    event.preventDefault();
    this.selection = {
      date: null
    };
  }

}
