import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { ApiService } from '@core/services/api.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';

/**
 * This class provides the data and functions
 * for the rental confirmsation page.
 */
@Component({
  selector: 'mp-rk-rental-confirmation',
  templateUrl: './rental-confirmation.component.html',
  styleUrls: [
    './../../../styles/elements/rk-select.scss',
    './../../../styles/sites/rk-main-page-styles.scss',
    './../../../styles/sites/rk-two-column-pages.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../styles/sites/rk-browser-hacks.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RentalConfirmationComponent implements OnInit, OnDestroy {

  public role: string = '';
  public orderNo: string = '';
  public message: string = '';
  public bookingAvailable: boolean = false;
  public rental: { [key: string]: any } = {};
  public gearBox: { [key: string]: any } = {};
  public getBookingSubscription: Subscription | undefined;
  public getParamsSubscription: Subscription | undefined;
  public getEnumsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public mpMenuRefresh: MpMenuRefreshService,
    public route: ActivatedRoute,
    public apiService: ApiService,
    public mpEnums: MpEnumsService,
    public authService: AuthService,
    public roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the enum data by the order
   * number from the query params.
   */
  ngOnInit(): void {
    const role = this.authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this.roleMapping.getReverseMappedRole(role);
    }

    this.getParamsSubscription = this.route.queryParams.subscribe((params: any) => {
      if (typeof params['bestellNr'] !== 'undefined') {
        this.orderNo = params['bestellNr'];

        if (Object.keys(this.mpEnums.enums).length > 0) {
          this.getBookingData();

          if (typeof this.getEnumsSubscription !== 'undefined') {
            this.getEnumsSubscription.unsubscribe();
          }
        } else {
          this.getEnumsSubscription = this.mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
            if (loaded) {
              this.gearBox = this.mpEnums.enums['Gangschaltung'];
              this.getBookingData();

              if (typeof this.getEnumsSubscription !== 'undefined') {
                this.getEnumsSubscription.unsubscribe();
              }
            }
          });

          this.mpEnums.getEnums();
        }
      }

      if (typeof params['Message'] !== 'undefined') {
        this.message = params['Message'];
      }

      this.mpMenuRefresh.loadMenuData(true);
    });
  }

  /**
   * Gets the booking data.
   */
  getBookingData(): void {
    this.getBookingSubscription = this.apiService.getRequest(`/api/RkMietwagen/GetBuchung?bestellNr=${this.orderNo}`).subscribe((data: any) => {
      this.rental = data.Records[0].Mietwagen;
      this.bookingAvailable = true;
    },
    (error: any) => {
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.getBookingSubscription !== 'undefined') {
      this.getBookingSubscription.unsubscribe();
    }

    if (typeof this.getParamsSubscription !== 'undefined') {
      this.getParamsSubscription.unsubscribe();
    }

    if (typeof this.getEnumsSubscription !== 'undefined') {
      this.getEnumsSubscription.unsubscribe();
    }
  }

}
