<section *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" id="shop">
<mp-core-svg-loader [hidden]="!loading" svg="LadeanimationenIconsGeschenk" class="svg-loader" svgAnimation="draw" [time]="1.9" loadingText="{{ ls.locs['loc'].SVGLoaderBitteWarten }}"></mp-core-svg-loader>
<div>

  <div class="stage-container" *ngIf="hasStage && stages">
    <div class="stage">
      <div>
        <div class="stage-item" *ngFor="let item of stages">
          <img *ngIf="item.source && item.source !== ''" [src]="item.source + '?width=' + mpSettings.settings['DisplaySettings'].BuehnenBildBreite + '&height=' + mpSettings.settings['DisplaySettings'].BuehnenBildHoehe + '&mode=crop&test=0'" alt="" />
          <div class="caption" *ngIf="item.stageText">
            <span [innerHTML]="item.stageText"></span>
            <div class="read-more-buttons">
              <a href="{{item.link}}" class="mp-button _prim button-large read-more" [hidden]="!item.link && !item.linkText">{{item.linkText}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="filter" [ngClass]="{'no-header': !(hasStage && stages)}" class="box-sizing-border-box padding-8 box-shadow-default">
    <div class="row">
      <div *ngIf="!mpSettings.settings['DisplaySettings'].HidePraemienSucheInKategorie" class="grid col-medium-5 padding-left-0">
        <mp-core-search-awards mpId="praemien-suche"
                               [searchInCategory]="true"
                               class="position-relative top-2">
        </mp-core-search-awards>
      </div>
      <div class="grid col-medium-5">
        <mp-core-category-dropdown [showFilter]="showFilter" [showDropdown]="showCategories" class="position-relative top-2"></mp-core-category-dropdown>

      </div>
      <div class="grid col-medium-2 padding-right-0">
        <button id="filterOpenClose" class="mp-button floating-container float-right margin-right-0" (click)="toggleFilter()" [ngClass]="{'active' : showFilter}">
          <span class="filterText icon-text">{{ ls.locs['loc'].Filter }}</span>
          <i class="svg-icon __clr-default __size-18 __top--1 margin-left-4 __filter float-none-important"></i>
        </button>
      </div>
    </div>
    <div id="FilterBereich">
      <div class="filterLeiste">
        <div *ngIf="count" [hidden]="!showFilter" (clickOutside)="closeFilter()" class="filterContent row" click-outside-except="#kategorieOpenClose, #filterOpenClose, #filterActive, #FilterBereich, .filterContent">
          <div class="ghost-divider-48"></div>
          <div class="row">
            <div class="grid col-large-3 margin-bottom-24" id="sorting" *ngIf="dataSorting['SortierungsListe']">
              <h2>{{ ls.locs['loc'].Sortierung }}</h2>
              <div *ngFor="let s of dataSorting['SortierungsListe']">
                <mp-core-radio-button name="ncSorting"
                                      label="{{s.Bezeichnung}}"
                                      mpId="rdSortierung_{{s.ID}}"
                                      [value]="s['ID']"
                                      [model]="dataSorting['Sortierung']"
                                      (modelChange)="dataSorting['Sortierung'] = $event; changeSorting($event)">
                </mp-core-radio-button>
              </div>
            </div>

            <div class="grid col-large-3 margin-bottom-24">
              <h2>{{ ls.locs['loc'].Punkte }}</h2>
              <mp-core-radio-button label="{{ ls.locs['loc'].Alle }}"
                                    mpGroup="points"
                                    name="points"
                                    mpId="chkPunkteAlle"
                                    value="-1--1"
                                    [model]="data['SelectedIntervallValue']"
                                    (modelChange)="data['SelectedIntervallValue'] = $event; resetPointWorthFilter()">
              </mp-core-radio-button>
              <div *ngFor="let filter of data['Punktwerte']; let index = index">
                <mp-core-radio-button label="{{filter.Bis < 0 ? ls.locs['loc'].ab+' '+ filter.VonDisplay + ' '+ls.locs['loc'].WaehrungPunkte : filter.VonDisplay + ' '+ls.locs['loc'].WaehrungPunkte + ' - ' + filter.BisDisplay + ' '+ls.locs['loc'].WaehrungPunkte}} {{getPointWorthFilterCount(filter.Von, filter.Bis)}}"
                                      value="{{filter['Von']}}-{{filter['Bis']}}"
                                      [model]="data['SelectedIntervallValue']"
                                      mpGroup="points"
                                      name="points"
                                      mpId="chkPunkte{{index}}"
                                      (modelChange)="data['SelectedIntervallValue'] = $event; setPointWorthFilter(filter.Von, filter.Bis)">
                </mp-core-radio-button>
              </div>
            </div>
            <div class="grid col-large-3 margin-bottom-24">
              <h2>{{ ls.locs['loc'].Praemien }}</h2>
              <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasExpress">
                <mp-core-checkbox [model]="data['Express']" (modelChange)="data['Express'] = $event; changeExpress($event)" mpId="cbExpress" label="{{ ls.locs['loc'].FilterExpress }} {{count.ExpressFilter ? '('+count.ExpressFilter+')' : ''}}"></mp-core-checkbox>
              </div>
              <div>
                <mp-core-checkbox [model]="data['Neuheiten']" (modelChange)="data['Neuheiten'] = $event; changeNewAwards($event)" mpId="cbNeuheiten" label="{{ ls.locs['loc'].FilterNeuheiten | format:[mpSettings.settings['NetcentiveCoreSettings'].NeuheitenTage] }} {{count.NeuheitenFilter ? '('+count.NeuheitenFilter+')' : ''}}"></mp-core-checkbox>
              </div>
              <div>
                <mp-core-checkbox [model]="data['Verfuegbarkeit']" (modelChange)="data['Verfuegbarkeit'] = $event; changeAvailability($event)" mpId="cbVerfuegbarkeit" label="{{ ls.locs['loc'].FilterVerfuegbarkeit }} {{count.VerfuegbareArtikelFilter ? '('+count.VerfuegbareArtikelFilter+')' : ''}}"></mp-core-checkbox>
              </div>
              <div>
                <mp-core-checkbox [model]="data['Bestellbar']" (modelChange)="data['Bestellbar'] = $event; changeOderable($event)" mpId="cbOderable" label="{{ ls.locs['loc'].FilterBestellbar}} {{count.BestellbarePraemienFilter ? '('+count.BestellbarePraemienFilter+')' : ''}}"></mp-core-checkbox>
              </div>
            </div>
            <div *ngIf="hasStatus && mpSettings.settings['DisplaySettings'].ShowPraemienStatus===true" class="grid col-large-3 margin-bottom-24">
              <h2>{{ ls.locs['loc'].Bestellbar_im_Status}}</h2>
              <a *ngFor="let s of data['StatusList']; trackBy: trackByIndex" href="" (click)="setStatusFilter(s.StatusID, $event)" class="filter-status">
                <img *ngIf="mpSettings.settings['DisplaySettings'].StatusIconsAsImages" [src]="'/themes/'+currentTheme+'/media/icons/status-icon-'+s.StatusID+'.png'" />

                <i *ngIf="!mpSettings.settings['DisplaySettings'].StatusIconsAsImages && s.StatusID" class="svg-icon __clr-status-{{s.StatusID}} __size-22 __top-6 __state-round margin-right-8" [ngClass]="{inactive: s.StatusID > data['Status']}"></i>
              </a>
            </div>
          </div>
          <div class="floating-container margin-bottom-16">
            <button type="button" class="mp-button _prim float-left margin-right-8" (click)="closeFilter()">
              <i class="svg-icon __clr-secondary __size-18 __top--1 margin-right-4 __filter float-none-important"></i>
              <span class="icon-text">{{count.AktuelleFilterKombination}} {{ ls.locs['loc'].Treffer }}</span>
            </button>

            <button class="mp-button float-left" (click)="resetAllFilter()">
              <i class="svg-icon __clr-default __size-18 margin-right-4 __top--1 __restore float-none-important"></i>
              <span class="icon-text">{{ ls.locs['loc'].FilterZuruecksetzenAlle }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="layout-container" [hidden]="!articleData || (articleData && articleData.length === 0) || loading">  
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <div class="kategorie-titel no-teaser" [ngClass]="{'margin-bottom-24': visualText}">
      <h1 class="Buehne Titel" [hidden]="searchTerm !== ''">{{visualTitel}}</h1>
      <h1 class="Buehne Titel" [hidden]="searchTerm === '' || searchTerm !== '' && totalrecordcount === 1 || totalrecordcount === -1">{{totalrecordcount | integer}} <span [innerHTML]="ls.locs['loc'].SuchergebnisTitel.replace('[Suchbegriff]', searchTerm)"></span></h1>
      <h1 class="Buehne Titel" [hidden]="searchTerm === '' || searchTerm !== '' && totalrecordcount !== 1 || totalrecordcount === -1">{{totalrecordcount | integer}} <span [innerHTML]="ls.locs['loc'].SuchergebnisTitelEinErgebnis.replace('[Suchbegriff]', searchTerm)"></span></h1>
      <div [innerHTML]="visualText" class="teaser Buehne BuehneText"></div>
    </div>
    <div id="filterActive" class="filterActiveTrueHeight margin-bottom-16" [hidden]="!data['Express'] && !data['Neuheiten'] && (data['SelectedIntervall'] && data['SelectedIntervall'].Von === 0 && data['SelectedIntervall'].Bis === 0) && data['Status'] >= 3 && !data['Verfuegbarkeit']">

      <div *ngIf="data['Express']" (click)="data['Express'] = false;setExpressServiceFilter()" class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative"><span class="icon-text">{{ ls.locs['loc'].FilterExpress }}</span><i class="svg-icon __clr-specific-1 margin-left-4 __size-18 __top-0 __clear float-none-important"></i></div>
      <div *ngIf="data['Neuheiten']" (click)="data['Neuheiten'] = false;setNewFilter()" class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative"><span class="icon-text">{{ ls.locs['loc'].FilterNeuheiten | format:[mpSettings.settings['NetcentiveCoreSettings'].NeuheitenTage] }}</span><i class="svg-icon __clr-specific-1 margin-left-4 __size-18 __top-0 __clear float-none-important"></i></div>
      <div *ngIf="data['SelectedIntervall'] && data['SelectedIntervall'].Von > 0 || data['SelectedIntervall'] && data['SelectedIntervall'].Bis > 0" (click)="resetPointWorthFilter()" class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative">
        <span class="icon-text">
          <span *ngIf="data['SelectedIntervall'] && data['SelectedIntervall'].Bis > -1">{{data['SelectedIntervall'].Von}} - {{data['SelectedIntervall'].Bis}} </span>
          <span *ngIf="data['SelectedIntervall'] && data['SelectedIntervall'].Bis < 0 && data['SelectedIntervall'].Von > 0">{{ ls.locs['loc'].ab }} {{data['SelectedIntervall'].Von}} </span>
          {{ ls.locs['loc'].Punkte }}
        </span>
        <i class="svg-icon __clr-specific-1 margin-left-4 __size-18 __top-0 __clear float-none-important"></i>
      </div>
      <div *ngIf="hasStatus && data['Status'] < 3" (click)="setStatusFilter(3, $event)" class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative"> <span class="icon-text">{{ ls.locs['loc'].Bestellbar_im_Status }}: {{data['StatusText'] ? data['StatusText']+' '+getStatusFilterCount(data['Status']) : ls.locs['loc'].AlleStatus}} </span><i class="svg-icon __clr-specific-1 margin-left-4 __size-18 __top-0 __clear float-none-important"></i></div>
      <div *ngIf="data['Verfuegbarkeit']" (click)="data['Verfuegbarkeit'] = false;setAvailableFilter()" class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative"><span class="icon-text">{{ ls.locs['loc'].FilterVerfuegbarkeit }} </span><i class="svg-icon __clr-specific-1 margin-left-4 __size-18 __top-0 __clear float-none-important"></i></div>

    </div>

    <div>
      <!--<div [hidden]="!alternativen">{{ ls.locs['loc'].AlternativeSuchbegriffe }}: <span *ngFor="let alternative of alternativen; last as isLast"><a href="" (click)="setSuchbegriff(alternative)">{{alternative}}</a><span *ngIf="!isLast">, </span></span></div>-->
      <div #shopGrid id="shop-grid-outer">
        <div #shopGridCards id="shop-cards" class="css-grid _shop">
          <mp-core-shop-card *ngFor="let e of articleData | paginate: { itemsPerPage: renderSize, currentPage: currentPage }; let last = last"
                             [ngClass]="{ 'grid-card-s' : e.CellWidth == 1, 'grid-card-m': e.CellWidth == 2 }"
                             [isLast]="last"
                             (afterRenderItem)="scrollToLastPosition();"
                             [tile]="e"
                             [mpClick]="saveCurrentPositionFunc"
                             [addToShoppingbasketFunc]="addToShoppingbasket.bind(this)">
          </mp-core-shop-card>
          <pagination-controls (pageChange)="onPageChange($event)"
                               (pageBoundsCorrection)="onPageChange($event)"
                               [maxSize]="6"
                               [directionLinks]="true"
                               [responsive]="true"
                               [autoHide]="true"
                               previousLabel=""
                               nextLabel=""
                               screenReaderPaginationLabel=""
                               screenReaderPageLabel=""
                               screenReaderCurrentLabel="">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>

  <section class="layout-container flex-grid" id="error-404" [hidden]="!articleData || articleData && articleData.length > 0 || loading || initialLoad">
    <aside class="flex-col-12 flex-align-items-center flex-direction-column">
      <i class="svg-icon __clr-info __size-62 __error-outline"></i>
      <div class="text-align-center margin-top-16">
        <div [hidden]="searchTerm">
          <h1>{{ ls.locs['loc'].KeinePraemienGefunden }}</h1>
        </div>
        <div [hidden]="!searchTerm">
          <h1>{{ ls.locs['loc'].KeinePraemienGefundenSuche.replace('[Suchbegriff]', searchTerm) }}</h1>
        </div>
        <div [hidden]="showFilter">
          <p>{{ ls.locs['loc'].KeinePraemienGefundenSubtitle }}</p>
        </div>
      </div>
    </aside>
    <div [hidden]="showFilter" class="flex-col-12 flex-grid flex-justify-cont-center margin-top-56 padding-left-right-0">
      <article class="flex-col-12 flex-justify-cont-center margin-top-56" *ngIf="categoryId !== 0">
        <a id="error-page-button" href="/#/{{role}}/Shop" class="mp-button _grey">{{ ls.locs['loc'].AllePraemien }}</a>
      </article>
      <article class="flex-col-12 flex-justify-cont-center margin-top-88 error-slider display-block-important padding-left-right-0" *ngIf="categoryId !== currentCatId['Neuheiten']">
        <mp-core-newest-awards [count]="8"></mp-core-newest-awards>
      </article>
      <article class="flex-col-12 flex-justify-cont-center error-slider text-align-left display-block-important padding-left-right-0" *ngIf="categoryId !== currentCatId['Topseller']">
        <mp-core-topseller-awards [count]="8"></mp-core-topseller-awards>
      </article>
    </div>
  </section>
</div>
</section>
