<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="floating-container wk-item flex-grid">
    <div class="flex-col-1 flex-col-3-s flex-col-3-xs flex-col-3-m flex-justify-cont-center flex-align-items-center wk-item-img-container">
      <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}">
        <img class="wk-item-img" src="{{item.Bild}}?width=100" alt="{{item.ArtNr+'-'+item.PIN}}" />
      </a>
    </div>
    <div class="flex-col-3 flex-col-9-s flex-col-9-xs flex-col-9-m flex-justify-cont-start flex-align-items-center wk-item-description-container">
      <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}">
        <span class="wk-company">{{item.Hersteller}}</span>
        <strong class="wk-item-label overflow-hidden display-inline-block">{{item.ArtBez}} {{item.ArtBez2}}</strong>
      </a>
    </div>
    <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-center wk-item-count">
      <mp-core-input class="display-flex width-full" label="{{ ls.locs['loc'].Stueckzahl }}" [(model)]="itemCount" [mpDisabled]="true"></mp-core-input>
    </div>
    <div class="flex-col-4 flex-col-6-s flex-col-6-m flex-col-3-l flex-justify-cont-center flex-align-items-center wk-item-characteristic">
      <span>{{item.Auspraegung}}</span>
    </div>

    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !item.TnIsOnPaymentBlacklist" class="flex-col-2 flex-col-4-s flex-col-4-m flex-col-3-l flex-justify-cont-center flex-align-items-end flex-direction-column wk-points padding-right-16">
      <span class="bold">{{item.EingeloestePunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      <p class="margin-0 display-flex flex-justify-cont-between width-full">
        <span class="additional-pay-label-plus margin-bottom-2">{{ ls.locs['locWkZuzahlung'].plus }}</span>
        <span class="bold">{{item.ZuzahlungEuro | decimal}} €</span>
      </p>
      <p *ngIf="item.AuslandsversandPunkte" class="wk-value-label shipping-costs text-align-center">{{ ls.locs['loc'].InklVersandkosten }}</p>
      <p *ngIf="item.ExpressServicezuschlag" class="wk-value-label shipping-costs text-align-center">{{ ls.locs['loc'].Inkl }} {{ ls.locs['loc'].Express }}</p>
      <p *ngIf="item.ZusatzfragenPunkte" class="wk-value-label shipping-costs text-align-center">{{ ls.locs['loc'].InklZusatzoptionen }}</p>
    </div>

    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && item.TnIsOnPaymentBlacklist" class="flex-col-2 flex-col-4-s flex-col-4-m flex-col-3-l flex-justify-cont-center flex-align-items-end flex-direction-column wk-points padding-right-16">
      <span class="bold">{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>

      <p *ngIf="!mpSettings.settings['DisplaySettings'].HideNebenkosten" class="wk-value-label shipping-costs text-align-center">{{ ls.locs['loc'].InklVersandkosten }}</p>
      <p class="wk-value-label shipping-costs text-align-center" *ngIf="item.ExpressServicezuschlag && !mpSettings.settings['DisplaySettings'].HideNebenkosten">{{ ls.locs['loc'].Inkl }} {{ ls.locs['loc'].Express }}</p>
      <p *ngIf="!mpSettings.settings['DisplaySettings'].HideNebenkosten && item.ZusatzfragenPunkte" class="wk-value-label shipping-costs text-align-center">{{ ls.locs['loc'].InklZusatzoptionen }}</p>
    </div>
    <div class="wk-mobile-wrapper">
      <div class="wk-item-count">
        <span class="padding-right-4">{{ ls.locs['loc'].Stueckzahl }}:</span>
        <span>{{item.Anzahl}}</span>
      </div>
      <div class="padding-left-64 wk-item-characteristic" *ngIf="item.Auspraegung.length > 0">
        <span class="padding-right-4">{{ ls.locs['loc'].Auspraegung }}:</span>
        <span>{{item.Auspraegung}}</span>
      </div>
    </div>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && !item.TnIsOnPaymentBlacklist"
         class="flex-col-4 flex-col-5-l flex-align-items-center flex-wrap margin-top-24 padding-right-8 padding-top-8 padding-left-16 wk-pay-check show-on-small-mobile">
      <p class="width-full margin-0">
        <span class="padding-right-4">{{ ls.locs['locWkZuzahlung'].VerwendetePunkte }}:</span>
        <span>{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </p>
      <p class="width-full margin-0">
        <span class="padding-right-4">{{ ls.locs['locWkZuzahlung'].Zuzahlung }}:</span>
        <span>{{item.ZuzahlungEuro | decimal}} €</span>
      </p>
      <p class="wk-value-label shipping-costs">{{ ls.locs['loc'].InklVersandkosten }}</p>
      <p class="wk-value-label shipping-costs" *ngIf="item.ZusatzfragenPunkte">{{ ls.locs['loc'].InklZusatzoptionen }}</p>
    </div>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && item.TnIsOnPaymentBlacklist"
         class="flex-col-4 flex-col-5-l flex-align-items-center flex-wrap margin-top-24 padding-right-8 padding-top-8 padding-left-16 wk-pay-check show-on-small-mobile">
      <p class="width-full margin-0">
        <span class="padding-right-4">{{ ls.locs['loc'].VerwendetePunkte }}:</span>
        <span>{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </p>
      <p *ngIf="!mpSettings.settings['DisplaySettings'].HideNebenkosten" class="wk-value-label shipping-costs">{{ ls.locs['loc'].InklVersandkosten }}</p>
      <p class="wk-value-label shipping-costs" *ngIf="item.ZusatzfragenPunkte && !mpSettings.settings['DisplaySettings'].HideNebenkosten">{{ ls.locs['loc'].InklZusatzoptionen }}</p>
    </div>
  </div>
</div>
