<div id="kontowechselBeantragen" class="layout-container bg-color-white padding-bottom-48" *ngIf="(ls.locsLoaded | async)">
  <div class="white-content-container">

    <mp-core-breadcrumbs></mp-core-breadcrumbs>

    <h1 [innerText]="ls.locs['loc'].AntragKontowechsel"></h1>
    <div class="margin-top-16">
      <span [innerHTML]="ls.locs['loc'].KontowechselText"></span>
    </div>

    <div class="margin-top-16">
      <mp-core-input mpType="text"
                label="{{ls.locs['loc'].VorherigerBenutzer}}"
                [(model)]="request['BenutzerAlt']"
                [mpRequired]="true"
                mpId="Name"
                [hasError]="errors['data.BenutzerAlt'] ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.BenutzerAlt']"
                     [model]="errors['data.BenutzerAlt']">
      </mp-core-form-error>
    </div>

    <div id="overlay-buttons" class="margin-top-168 flex-grid flex-direction-row flex-wrap flex-align-items-start">
      <div class="flex-col-6 padding-left-8 padding-right-0">
        <a (click)="sendRequest($event)" class="mp-button _prim margin-top-bottom-24" [innerText]="ls.locs['loc'].AntragAbsenden"></a>
      </div>
    </div>

  </div>
</div>
