import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { InitialLoginService } from '@core/services/initial-login.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

/**
 * This class provides the functions and
 * data for the step "quarter survey"
 * of the first login module.
 */
@Component({
  selector: 'mp-leas-first-login-quarter-survey',
  templateUrl: './quarter-survey.component.html',
  encapsulation: ViewEncapsulation.None
})
export class QuarterSurveyComponent implements OnInit, OnDestroy {

  public reasonForVisit: string = '';
  public awardWish: string = '';
  public improvements: string = '';

  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _saveQuarterSurveySubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _initialLogonService: InitialLoginService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Subscribes to the validate step
   * and next step observabbles
   * of the InitialLoginService.
   */
  ngOnInit(): void {
    this._validateStepSubscription = this._initialLogonService.validateStep(7).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLogonService.validateCalled.next(false);
        const isValid = true;
        this._initialLogonService.validBool = isValid;
        this._initialLogonService.valid.next(isValid);
      }
    });

    this._nextStepSubscription = this._initialLogonService.nextCalledStep(7).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this._initialLogonService.validBool) {
          this._saveQuarterSurveySubscription = this._apiService.postRequest('/api/Erstanmeldung/setQuartalsbefragung', {
            Verbesserung: this.improvements,
            Wunsch: this.awardWish,
            Nutzerfuehrung: '-',
            Praemiendetail: '-',
            Neuerungen: '-',
            Anmerkungen: '',
            Besuchsgrund: this.reasonForVisit
          }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this._initialLogonService.nextSuccess();
            } else {
              this._mpMessaging.openWarningPanel(data.Message);
              this._initialLogonService.nextFailure();
            }
          },
            (error: any) => {
              this._mpMessaging.openWarningPanel(error.Message);
              this._initialLogonService.nextFailure();
            });
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._nextStepSubscription !== 'undefined') {
      this._nextStepSubscription.unsubscribe();
    }

    if (typeof this._saveQuarterSurveySubscription !== 'undefined') {
      this._saveQuarterSurveySubscription.unsubscribe();
    }
  }

}
