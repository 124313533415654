import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[coreOnlyNumber]'
})
export class OnlyDecimalDirective {

  @Input("decimals") decimals: number = 0;

  constructor(private _el: ElementRef) { }

  private check(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      var regExpString =
        "^\\s*((\\d+(\\.\\d{0," +
          this.decimals +
          "})?)|((\\d*(\\.\\d{1," +
          this.decimals +
          "}))))\\s*$";
      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue: string) {
    setTimeout(() => {
      let currentValue: string = this._el.nativeElement.value;
      if (currentValue !== '' && !this.check(currentValue)) {
        this._el.nativeElement.value = oldValue;
      }
    });
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run(this._el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this._el.nativeElement.value);
  }

}
