import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';
import { ManagerModule } from '@core/modules/manager/manager.module';
import { AdminModule } from '@core/modules/admin/admin.module';

import { GkRoutingModule } from './gk-routing.module';
import { GkComponent } from './gk.component';
import { PrizeCalendarOneComponent } from './modules/participant/pages/prize-calendar/prize-calendar-one/prize-calendar-one.component';
import { PrizeCalendarTwoComponent } from './modules/participant/pages/prize-calendar/prize-calendar-two/prize-calendar-two.component';
import { PrizeCalendarDirective } from './directives/prize-calendar.directive';
import { DoorMovementDirective } from './directives/door-movement.directive';
import { PrizeCalendarModalComponent } from './components/prize-calendar-modal/prize-calendar-modal.component';
import { CarouselSliderCalendarDirective } from './directives/carousel-slider-calendar.directive';
import { PrizeCalendarStatisticComponent } from './modules/manager/pages/prize-calendar-statistic/prize-calendar-statistic.component';
import { PrizeCalendarWinnerStatisticComponent } from './modules/manager/pages/prize-calendar-winner-statistic/prize-calendar-winner-statistic.component';
import { PrizeCalendarEvaluationComponent } from './modules/manager/pages/prize-calendar-evaluation/prize-calendar-evaluation.component';
import { PrizeCalendarEvaluationDetailsComponent } from './modules/manager/pages/prize-calendar-evaluation-details/prize-calendar-evaluation-details.component';
import { WinnersOverviewComponent } from './modules/manager/pages/winners-overview/winners-overview.component';

@NgModule({
  declarations: [
    GkComponent,
    PrizeCalendarOneComponent,
    PrizeCalendarTwoComponent,
    PrizeCalendarDirective,
    DoorMovementDirective,
    PrizeCalendarModalComponent,
    CarouselSliderCalendarDirective,
    PrizeCalendarStatisticComponent,
    PrizeCalendarWinnerStatisticComponent,
    PrizeCalendarEvaluationComponent,
    PrizeCalendarEvaluationDetailsComponent,
    WinnersOverviewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    ManagerModule,
    AdminModule,
    FormsModule,
    GkRoutingModule
  ],
  exports: [
    GkComponent
  ],
  providers: [],
  bootstrap: [
    GkComponent
  ]
})
export class GkModule { }

@NgModule({})
export class GkSharedModule {
  static forRoot(): ModuleWithProviders<GkModule> {
    return {
      ngModule: GkModule,
      providers: []
    }
  }
}
