<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1>{{ ls.locs['locLeas'].ProposalGutschreiben }}</h1>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['locLeas'].Proposal }}"
              mpId="txt-proposal"
              [(model)]="proposal"
              [mpRequired]="true"
              (blur)="getProposalInfo()"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].Benutzername }}"
              mpId="txt-benutzer"
              [(model)]="user"
              [mpRequired]="true"
              (blur)="getProposalInfo()"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="Status zum Datum der Finanzierung"
              mpId="txt-status"
              [(model)]="proposalInfo['StatusZumDatum']"
              [mpDisabled]="true"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="Punktefaktor zum Datum der Finanzierung"
              mpId="txt-punktefaktor"
              [(model)]="proposalInfo['PunktefaktorZumDatum']"
              [mpDisabled]="true"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['locLeas'].Marke }}"
              mpId="txt-marke"
              [(model)]="proposalInfo['Marke']"
              [mpDisabled]="true"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].Datum }}"
              mpId="txt-datum"
              [(model)]="proposalInfo['Datum']"
              [mpDisabled]="true"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div>
    <table class="table">
      <tr>
        <td>{{ ls.locs['locLeas'].Leistungsart }}</td>
        <td>{{ ls.locs['locLeas'].Finanzierungssumme }}</td>
        <td>{{ ls.locs['locLeas'].Gap }}</td>
        <td>{{ ls.locs['locLeas'].Instandhaltung }}</td>
        <td>{{ ls.locs['locLeas'].Reifen }}</td>
        <td>{{ ls.locs['locLeas'].Versicherung }}</td>
        <td>{{ ls.locs['locLeas'].Tankkarte }}</td>
      </tr>
      <tr>
        <td>{{ ls.locs['locLeas'].Wert }}</td>
        <td [innerText]="proposalInfo['Finanzierungssumme']"></td>
        <td [innerText]="proposalInfo['Gap']"></td>
        <td [innerText]="proposalInfo['Instandhaltung']"></td>
        <td [innerText]="proposalInfo['Reifen']"></td>
        <td [innerText]="proposalInfo['Versicherung']"></td>
        <td [innerText]="proposalInfo['Tankkarte']"></td>
      </tr>
      <tr>
        <td>{{ ls.locs['locLeas'].Honorierung }}</td>
        <td [innerText]="proposalInfo['LeasingPunkte']"></td>
        <td>0</td>
        <td [innerText]="proposalInfo['InstandhaltungPunkte']"></td>
        <td [innerText]="proposalInfo['ReifenPunkte']"></td>
        <td [innerText]="proposalInfo['VersicherungPunkte']"></td>
        <td [innerText]="proposalInfo['TankkartePunkte']"></td>
      </tr>
    </table>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].Punkte }}"
              mpId="txt-punkte"
              [(model)]="points"
              [mpRequired]="true"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-bottom-24">
    <mp-core-input label="{{ ls.locs['loc'].StatusPunkte }}"
              mpId="txt-statuspunkte"
              [(model)]="statusPoints"
              [mpRequired]="true"
              class="fallback-input">
    </mp-core-input>
  </div>
  <div class="margin-top-24">
    <button type="button"
            class="mp-button _prim"
            [hidden]="proposal === '' || user === '' || points === '0' || proposalInfo['PunktefaktorZumDatum'] === 0 || blockButton"
            (click)="saveProposal()">
      {{ ls.locs['locLeas'].Gutschreiben }}
    </button>
  </div>
</div>
