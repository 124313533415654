import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';
import { ManagerModule } from '@core/modules/manager/manager.module';
import { AdminModule } from '@core/modules/admin/admin.module';

import { SchoolingRoutingModule } from './schooling-routing.module';
import { SchoolingComponent } from './schooling.component';
import { SchoolingStatisticComponent } from './modules/manager/pages/schooling-statistic/schooling-statistic.component';
import { SchoolingPageComponent } from './modules/participant/pages/schooling-page/schooling-page.component';
import { SchoolingStartComponent } from './modules/participant/pages/schooling-start/schooling-start.component';

@NgModule({
  declarations: [
    SchoolingComponent,
    SchoolingStatisticComponent,
    SchoolingPageComponent,
    SchoolingStartComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    ManagerModule,
    AdminModule,
    FormsModule,
    SchoolingRoutingModule
  ],
  exports: [
    SchoolingComponent
  ],
  providers: [],
  bootstrap: [
    SchoolingComponent
  ]
})
export class SchoolingModule { }

@NgModule({})
export class SchoolingSharedModule {
  static forRoot(): ModuleWithProviders<SchoolingModule> {
    return {
      ngModule: SchoolingModule,
      providers: []
    }
  }
}
