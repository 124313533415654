import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { HttpUrlEncodingCodec } from '@angular/common/http';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSettingsService } from './../../../services/mp-settings.service';
import { ApiService } from './../../../services/api.service';
import { InboxService } from './../inbox.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';
import { AuthService } from './../../../services/auth.service';
import { RoleMappingService } from './../../../services/role-mapping.service';

/**
 * This class provides functions and data
 * for the details of an order item in
 * the inbox.
 */
@Component({
  selector: 'mp-core-inbox-details-order',
  templateUrl: './inbox-details-order.component.html'
})
export class InboxDetailsOrderComponent implements OnInit, OnDestroy {

  public loading: boolean = true;
  public tnInfostreamOrderPlural: string = '';
  public tnInfostreamOrder: string = '';
  public b: { [key: string]: any } = {};
  public item: any;
  public role: string = '';

  private _codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
  private _tnInfostreamOrderPluralSubscription: Subscription | undefined;
  private _tnInfostreamOrderSubscription: Subscription | undefined;
  private _orderDetailsSubscription: Subscription | undefined;
  private _cancelOrderSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _injector: Injector,
    private _inboxService: InboxService,
    private _mpMessaging: MpMessagingService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the texts for the infostream of
   * the order.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this.item = this._injector.get('iboxItem');

    //this._tnInfostreamOrderPluralSubscription = this._apiService.getRequest('/api/Texte/GetReplacedText?key=tn-infostream-bestellung-plural').subscribe((data: any) => {
    //  if (data.Result === 'OK' && data.Records.length > 0) {
    //    this.tnInfostreamOrderPlural = data.Records[0];
    //  }
    //});

    this._tnInfostreamOrderSubscription = this._apiService.getRequest('/api/Texte/GetReplacedText?key=tn-infostream-bestellung').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length > 0) {
        this.tnInfostreamOrder = data.Records[0];
      }
    });

    this._reload();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._tnInfostreamOrderPluralSubscription !== 'undefined') {
      this._tnInfostreamOrderPluralSubscription.unsubscribe();
    }

    if (typeof this._tnInfostreamOrderSubscription !== 'undefined') {
      this._tnInfostreamOrderSubscription.unsubscribe();
    }

    if (typeof this._orderDetailsSubscription !== 'undefined') {
      this._orderDetailsSubscription.unsubscribe();
    }

    if (typeof this._cancelOrderSubscription !== 'undefined') {
      this._cancelOrderSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the delete function of
   * the parent component.
   */
  onItemDelete(item: any): void {
    this._inboxService.triggerParentClickFunction(item, 'onItemDelete');
  }

  /**
   * Triggers the on click function of
   * the parent component.
   */
  onClick(evt: MouseEvent, item: any): void {
    evt.preventDefault();

    this._inboxService.triggerParentClickFunction(item, 'onClick');
  }

  /**
   * Loads the details of the order.
   */
  private _reload(): void {
    this._orderDetailsSubscription = this._apiService.getRequest('/api/Bestellung/getBestellDetails', false, {
      params: {
        bestellnr: this.item.Key
      }
    }).subscribe((data: any) => {
      this.b = data.Records[0];
      this.loading = false;
    });
  }

  /**
   * Toggles the shipment tracking details.
   */
  toggleShipmentTracking(evt: MouseEvent, bp: any): void {
    evt.preventDefault();
    bp.showSendungsverlauf = !bp.showSendungsverlauf;
    bp.showOptionen = false;
  }

  /**
   * Toggles the options of the order
   * details.
   */
  toggleOptions(evt: MouseEvent, bp: any): void {
    evt.preventDefault();
    bp.showOptionen = !bp.showOptionen;
    bp.showSendungsverlauf = false;
  }

  /**
   * Redirects to the contact page, so the
   * user can ask a request (to the support).
   */
  askRequest(bp: any): void {
    window.scrollTo(0, 0);
    window.location.href = "#/" + this.role +"/Kontakt?bestnr=" + this._codec.encodeValue(bp.BestellTAN);
  }

  /**
   * Cancels the order.
   */
  cancelOrder(bp: any): void {
    this._cancelOrderSubscription = this._apiService.postRequest('/api/Bestellung/setStorno', {
      BestellTan: bp.BestellTAN
    }).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this._reload();
    });
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
