<div *ngIf="(ls.locsLoaded | async)" #selectContainer [ngClass]="{ 'invalid-error': hasError }" class="magma-select flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8 position-relative {{errClass}} {{mpDisabled ? 'disabled' : ''}}"
     (clickOutside)="closeDropdown()">
  <div class="font-weight-700 font-size-xxs padding-left-right-0 magma-select-label width-full display-block-important"
       [hidden]="!label || editable">
    <span class="display-inline"
          [ngClass]="((!mpRequired && mpDisabled != true) || (mpRequired == false && mpDisabled != true) || (!mpRequired && !mpDisabled) || (mpRequired == false && !mpDisabled)) && label ? 'margin-right-4' : ''">
      {{label}}
    </span>
    <span *ngIf="((!mpRequired && mpDisabled != true) || (mpRequired == false && mpDisabled != true) || (!mpRequired && !mpDisabled) || (mpRequired == false && !mpDisabled)) && label"
          class="font-size-xxs text-color-grey-info display-inline">
      {{ ls.locs['loc'].OptionalField }}
    </span>
  </div>
  <div class="select-container display-flex flex-align-items-center padding-left-8 padding-right-32"
       [ngClass]="{open:showDropdown}"
       (click)="toggleDropdown()"
       [hidden]="editable">
    <div class="text-overflow-ellipsis"
         *ngIf="!allowHtml"
         [hidden]="modelText===undefined && modelText===null && !placeholder">{{modelText || placeholder}}</div>
    <div class="text-overflow-ellipsis"
         *ngIf="allowHtml"
         [hidden]="modelText===undefined && modelText===null && !placeholder"
         [innerHTML]="modelText || placeholder">
    </div>
    <i class="svg-icon __clr-default __size-24 __top-8 __sidebar-arrow-down right-4 position-absolute"></i>
  </div>
  <mp-core-select-input label="{{label}}"
                        mpId="{{mpId}}"
                        [hidden]="(!editable && multiple) || (!editable && !multiple) || (editable && multiple)"
                        [(model)]="modelText"
                        (change)="openDropdown()"
                        (click)="toggleDropdown()"
                        [mpRequired]="mpRequired"
                        [mpDisabled]="mpDisabled ? true : false"
                        mpPlaceholder="{{placeholder}}"
                        class="display-flex flex-align-items-center width-full">
  </mp-core-select-input>
  <i class="svg-icon __clr-default __size-24 __top-8 __sidebar-arrow-down right-12 position-absolute"
     [hidden]="(!editable && multiple) || (!editable && !multiple) || (editable && multiple)"
     [ngClass]="label || !editable ? 'margin-top-16' : 'margin-top-2'">
  </i>
  <div class="mobile-shadow"
       [hidden]="!showDropdown"
       (click)="closeDropdown()">
  </div>
  <div #dropdown [hidden]="!showDropdown"
       class="card select-dropdown"
       [ngClass]="{'margin-top-16': editable && !multiple, '_open-up': openUp}">
    <div class="primary-title border-bottom"
         *ngIf="label">
      <div class="title last">{{label}}</div>
      <a *ngIf="deselectable && model" class="text-link display-block-important margin-top-12" (click)="deselect($event)" [innerText]="ls.locs['loc'].AlleEnfternen"></a>
    </div>
    <div [ngClass]="{'scrolling-container': multiple}"
         *ngIf="elementsSet"
         class="scroll-section">
      <div #dropdownSelects
           *ngFor="let e of elements"
           (click)="select(e); multiple ? e.isSelected = !e.isSelected : modelSelected"
           class="selection"
           [ngClass]="{active: multiple && e.isSelected || !multiple && modelSelected, 'checkbox-container': multiple}">
        <ng-container *ngIf="showElement(e)">
          <div class="checkbox-container"
               *ngIf="multiple">
            <i class="checkbox position-relative vertical-align-middle pointer margin-right-8 display-inline-block top-0"></i>
          </div>
          <label *ngIf="!allowHtml"
                 class="text-label">
            {{textProperty ? e[textProperty] : e}}
          </label>
          <label *ngIf="allowHtml"
                 class="text-label"
                 [innerHTML]="textProperty ? e[textProperty] : e">
          </label>
        </ng-container>
      </div>
    </div>
  </div>
</div>
