import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';

/**
 * This class provides a dashbaord with
 * different d3 charts and an optional
 * data table.
 */
@Component({
  selector: 'mp-core-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardChartComponent implements OnInit {

  @Input() public sectionHeadline: string | undefined;
  @Input() public sectionSubline: string | undefined;
  @Input() public charts: Array<any> = [];
  @Input() public hasTable: boolean = false;
  @Input() public tableTitle: string | undefined;
  @Input() public tableText: string | undefined;
  @Input() public tableInfotextShorten: string | undefined;
  @Input() public tableFieldsAction: string | undefined;
  @Input() public tableListAction: string | undefined;
  @Input() public tableLoader: any;
  @Input() public tablePaging: any;
  @Input() public tableFooter: any;
  @Input() public tableDefaultSorting: string | undefined;
  @Input() public tableDisableCache: boolean = false;
  @Input() public exportDataLink: string | undefined;
  @Input() public mpId: string = '';

  @Output() tableLoaderChange = new EventEmitter<any>();
  @Output() tablePagingChange = new EventEmitter<any>();
  @Output() tableFooterChange = new EventEmitter<any>();

  public openCharts: Array<boolean> = [];
  public tableAccDataFunc = this.tableAccData.bind(this);

  constructor(public ls: MpLocalizationService) { }

  /**
   * Angulars init function. Pushes the
   * given charts to an array.
   */
  ngOnInit(): void {
    this.charts.forEach((chart: any, index: number) => {
      this.openCharts[index] = true;
    });
  }

  /**
   * Toggles the visiblity of the given
   * cahrt.
   */
  toggleChart(index: number): void {
    if (this.charts.length <= 1) {
      return;
    }

    this.openCharts[index] = !this.openCharts[index];
  }

  /**
   * Returns the data for the accordion.
   */
  tableAccData(): Array<object> {
    return [
      {
        accID: 1,
        accHeadline: 'Details'
      }
    ];
  }

}
