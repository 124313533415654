import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { CommonInternComponentsLoaderModule } from './../common-intern-components-loader.module';

import { ErrorPageDirective } from './error-page.directive';

import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error401Component } from './pages/error401/error401.component';

@NgModule({
  declarations: [
    Error404Component,
    ErrorPageDirective,
    Error500Component,
    Error401Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonInternComponentsLoaderModule,
    CarouselModule
  ]
})
export class ErrorModule { }
