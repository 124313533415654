import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ApiService } from './api.service';
import { MpLoadArticlesService } from './mp-load-articles.service';

/**
 * This class provides functions to get
 * information about categories.
 */
@Injectable({
  providedIn: 'root'
})
export class MpCategoriesService {

  private _categoryId: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public categoryId: Observable<string> = this._categoryId.asObservable();
  private _lastCatId: string = '0';
  private _categoryTree: Array<any> | null = null;
  private _categoryTreeSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public categoryTree: Observable<any> = this._categoryTreeSubj.asObservable();
  private _catList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public catList: Observable<Array<any>> = this._catList.asObservable();
  private _parents: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public parents: Observable<Array<any>> = this._parents.asObservable();
  private _loadRouteParamsSubscription: Subscription | undefined;

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _mpLoadArticles: MpLoadArticlesService
  ) { }

  /**
   * Gets the category id from the
   * rout parameters.
   */
  getCategoryId(): void {
    if (typeof this._loadRouteParamsSubscription !== 'undefined') {
      this._loadRouteParamsSubscription.unsubscribe();
    }

    this._loadRouteParamsSubscription = this._mpLoadArticles.routeParams.subscribe((params: any) => {
      const localStorageCatId = localStorage.getItem('categoryId');

      if (params !== null) {
        if (typeof params['id'] !== 'undefined' && params['id'] !== null) {
          if (!localStorageCatId || localStorageCatId && parseInt(localStorageCatId) !== parseInt(params['id'])) {
            this._categoryId.next(params['id']);
          }
        } else if (typeof params['k'] !== 'undefined') {
          if (!localStorageCatId || localStorageCatId && parseInt(localStorageCatId) !== parseInt(params['k'])) {
            this._categoryId.next(params['k']);
          }
        } else {
          if (!localStorageCatId || localStorageCatId && parseInt(localStorageCatId) !== 0) {
            this._categoryId.next('0');
          }
        }
      } else {
        if (!localStorageCatId || localStorageCatId && parseInt(localStorageCatId) !== 0) {
          this._categoryId.next('0');
        }
      }
    });
  }

  /**
   * Gets the category parents.
   */
  loadParents(): void {
    let catId;

    this._route.queryParams.subscribe((params: any) => {
      if (typeof params['k'] !== 'undefined') {
        catId = params['k'];
        this._categoryId.next(params['k']);
      } else {
        catId = '0';
        this._categoryId.next('0');
      }

      if (catId !== this._lastCatId) {
        this._lastCatId = catId;

        this._apiService.getRequest('/api/Kategorien/getKategorieBaum').subscribe((data: any) => {
          this._parents.next(data.Records);
        },
        (error: any) => {
          console.log(error.message);
        });
      }
    });
  }

  /**
   * Loads the category tree.
   */
  loadCategoryTree(force?: boolean): void {
    if (this._categoryTree === null || force) {
      this._apiService.getRequest('/api/Kategorien/getKategorieBaum').subscribe((data: any) => {
        this._categoryTreeSubj.next(data.Records[0]);
        this._categoryTree = data.Records[0];
        this._catList.next([]);

        if (this._categoryTree !== null) {
          // @ts-ignore
          this._getCatList(this._categoryTree.Children, this._catList);
        }
      },
      (error: any) => {
        console.log(error.message);
      });
    }
  }

  /**
   * Recursive function to build the
   * list of categories for the category
   * tree.
   */
  private _getCatList(source: any, target: Array<any>): void {
    if (source && source.length) {
      Array.prototype.push.apply(target, source);
      source.forEach((cat: any) => { this._getCatList(cat.Children, target); });
      this._catList.next(source);
    }
  }
}
