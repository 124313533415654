<div class="teilnahmebedingen-bestaetigen layout-container" id="teilnahmebedingungen" *ngIf="(ls.locsLoaded | async) && configLoaded">
  <h1>{{ ls.locs['loc'].Teilnahmebedingungen }}</h1>
  <div>
    <div class="accordion">
      <ul>
        <li id="{{tbThema['Thema']}}" *ngFor="let tbThema of data" (afterRenderItem)="scrollToHash()">
          <a [innerHTML]="tbThema['ThemenNr'] + '. '+ tbThema['Thema']"></a>
          <ul class="accordionitem">
            <li *ngFor="let text of tbThema['Texte']" [innerHTML]="text"></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <hr />
  <div class="teilnahmebedingungen-accept-area">
    <mp-core-checkbox [(model)]="voluntary" mpId="chkFreiwillig" label="{{ ls.locs['locLeas'].MeineTeilnahmeIstFreiwillig }}"></mp-core-checkbox>
    <mp-core-checkbox [(model)]="superior" mpId="chkVorgesetzter" label="{{ ls.locs['locLeas'].MeinVorgesetzterIstMitDerTeilnahmeEinverstanden }}"></mp-core-checkbox>
    <mp-core-checkbox [(model)]="accepted" mpId="chkAkzeptiert" label="{{ ls.locs['loc'].IchHabeDieTeilnahmebedingungenGelesenUndAkzeptiert | format:['firstlogin'] }}"></mp-core-checkbox>
    <mp-core-checkbox [(model)]="approved" mpId="chkGewilligt" label="{{ ls.locs['loc'].IchWilligeEinDatenZurAbwicklungDesPraemienprogrammsVerarbeitetWerden }}"></mp-core-checkbox>
    <mp-core-micro-copy infoState="inactive" copyText="{{ ls.locs['loc'].LinkZuDatenschutzerklaerung }}" class="padding-bottom-8"></mp-core-micro-copy>
    <div class="inputRequired" [hidden]="accepted && voluntary && superior && approved" *ngIf="error.length > 0 && error !== null" [innerText]="error"></div>
  </div>
</div>
