import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';
import { InitialLoginService } from './../../../services/initial-login.service';

/**
 * This class provides the functions and
 * data for the step "check stages" of the
 * first login module.
 */
@Component({
  selector: 'mp-core-first-login-check-stages',
  templateUrl: './check-stages.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CheckStagesComponent implements OnInit, OnDestroy {

  @Input() public nextFunc: any;

  private _validateStepSubscription: Subscription | undefined;
  private _stepSubscription: Subscription | undefined;
  private _getStagesSubscriptions: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _initialLoginService: InitialLoginService
  ) { }

  /**
   * Subscribes to the validate step and
   * the step observabbles of the
   * InitialLoginService.
   */
  ngOnInit(): void {
    this._validateStepSubscription = this._initialLoginService.validateStep(5).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLoginService.validateCalled.next(false);

        if (this._initialLoginService.validBool) {
          this._initialLoginService.nextSuccess();
        }
      }
    });

    this._stepSubscription = this._initialLoginService.stepObservable.subscribe((step: number) => {
      if (step === 5) {
        this._checkStages();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptione.
   */
  ngOnDestroy(): void {
    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._stepSubscription !== 'undefined') {
      this._stepSubscription.unsubscribe();
    }

    if (typeof this._getStagesSubscriptions !== 'undefined') {
      this._getStagesSubscriptions.unsubscribe();
    }
  }

  /**
   * Checks whether or not stages are
   * existing.
   */
  private _checkStages(): void {
    this._getStagesSubscriptions = this._apiService.getRequest('/api/Themenbuehnen/HasBuehnen').subscribe((data: any) => {
      if (data.Records && data.Records.length > 0 && data.Records[0] === true) {
        this._initialLoginService.validBool = true;
        this._initialLoginService.valid.next(true);

        if (typeof this.nextFunc !== 'undefined') {
          this.nextFunc();
        }
      } else {
        setTimeout(() => {
          this._checkStages();
        }, 5000);
      }
    },
    (error: any) => {
      this._initialLoginService.validBool = false;
      this._initialLoginService.valid.next(false);

      if (typeof this.nextFunc !== 'undefined') {
        this.nextFunc();
      }
    });
  }

}
