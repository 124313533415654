import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { TipAndWinService } from './../../services/tip-and-win.service';

@Component({
  selector: 'mp-taw-tip-and-win-daily-quiz',
  templateUrl: './tip-and-win-daily-quiz.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinDailyQuizComponent implements OnInit, OnDestroy {

  public question: string = '';
  public answers: Array<any> = [];
  public previousAnswers: Array<any> = [];
  public leftoverTime: string = '';
  public dailyQuizHint: string = '';

  private _localStorageLeftoverDateKey: string = 'mp_taw_leftover_date';
  private _localStorageLeftoverTimeKey: string = 'mp_taw_leftover_time';
  private _localStorageLeftoverKey: string = 'mp_taw_leftover';
  private _tabHasFocus: boolean = false;
  private _timerAlreadyStarted: boolean = false;
  private _leftoverTimeInt: number = 15;
  private _initialLeftoverTimeInt: number = this._leftoverTimeInt;
  private _leftoverInterval: any;
  private _dailyQuizId: number = -1;
  private _date: string = '';
  private _days: Array<string> = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  ];
  private _months: Array<string> = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];
  private _tabFocusSubscription: Subscription | undefined;
  private _getDailyQuizHintSubscription: Subscription | undefined;
  private _getDailyQuestionSubscription: Subscription | undefined;
  private _getPreviousAnswersSubscription: Subscription | undefined;
  private _saveAnswerSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _tipAndWinService: TipAndWinService,
    private _svgLoader: SvgLoaderService
  ) { }

  /**
   * Subscribes to the tab focus listener,
   * updates the timer, and triggers all
   * functions to get the data.
   */
  ngOnInit(): void {
    this._updateTimer();

    this._tabFocusSubscription = this._tipAndWinService.tabHasFocus.subscribe((focusedTab: { [key: string]: any }) => {
      if (focusedTab['tab'] === 'taeglichesQuiz' && focusedTab['focus']) {
        this._tabHasFocus = true;
        this._startTimer();
      }
    });

    this._getDailyQuizHint();
    this._getDailyQuestion();
    this._getPreviousAnswers();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._tabFocusSubscription !== 'undefined') {
      this._tabFocusSubscription.unsubscribe();
    }

    if (typeof this._getDailyQuizHintSubscription !== 'undefined') {
      this._getDailyQuizHintSubscription.unsubscribe();
    }

    if (typeof this._getDailyQuestionSubscription !== 'undefined') {
      this._getDailyQuestionSubscription.unsubscribe();
    }

    if (typeof this._getPreviousAnswersSubscription !== 'undefined') {
      this._getPreviousAnswersSubscription.unsubscribe();
    }

    if (typeof this._saveAnswerSubscription !== 'undefined') {
      this._saveAnswerSubscription.unsubscribe();
    }
  }

  /**
   * Updates the timer for answering
   * the question.
   */
  private _updateTimer(): void {
    const timeFromStorage = localStorage.getItem(this._localStorageLeftoverKey);
    this._leftoverTimeInt = timeFromStorage !== null ? parseInt(timeFromStorage) : this._leftoverTimeInt;
    this.leftoverTime = this._leftoverTimeInt < 10 ? '0' + this._leftoverTimeInt : '' + this._leftoverTimeInt;
  }

  /**
   * Updates the open questions count.
   */
  private _updateOpenQuestions(): void {
    if (this.question !== '') {
      this._tipAndWinService.openQuestions = 1;
      this._updateTimer();
      this._timerAlreadyStarted = false;
    } else {
      this._tipAndWinService.openQuestions = 0;
    }
  }

  /**
   * Removes the timer information from the
   * localStorage.
   */
  private _resetTimerInLocalStorage(): void {
    if (localStorage.getItem(this._localStorageLeftoverDateKey) !== null) {
      localStorage.removeItem(this._localStorageLeftoverDateKey);
    }

    if (localStorage.getItem(this._localStorageLeftoverKey) !== null) {
      localStorage.removeItem(this._localStorageLeftoverKey);
    }

    if (localStorage.getItem(this._localStorageLeftoverTimeKey) !== null) {
      localStorage.removeItem(this._localStorageLeftoverTimeKey);
    }
  }

  /**
   * Starts the timer to answer the
   * question.
   */
  private _startTimer(): void {
    const currentDate = new Date();
    const currentDateString = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    const currentTimeString = + currentDate.getTime();

    if (this.question !== '' && this._timerAlreadyStarted === false && this._tabHasFocus === true) {
      this._timerAlreadyStarted = true;

      if (localStorage.getItem(this._localStorageLeftoverDateKey) === null) {
        localStorage.setItem(this._localStorageLeftoverDateKey, currentDateString);
        localStorage.setItem(this._localStorageLeftoverTimeKey, currentTimeString.toString());
      } else {
        if (localStorage.getItem(this._localStorageLeftoverDateKey) === currentDateString) {
          if (localStorage.getItem(this._localStorageLeftoverTimeKey) === currentTimeString.toString()) {
            if (localStorage.getItem(this._localStorageLeftoverKey) !== null) {
              this._updateTimer();
            }
          } else {
            if (typeof this._leftoverInterval !== 'undefined') {
              clearInterval(this._leftoverInterval);
            }

            if (this.question !== '')
              this.saveAnswer();
            this._resetTimerInLocalStorage();
          }

        } else {
          localStorage.setItem(this._localStorageLeftoverDateKey, currentDateString);

          if (localStorage.getItem(this._localStorageLeftoverKey) !== null) {
            localStorage.removeItem(this._localStorageLeftoverKey);
            this._leftoverTimeInt = this._initialLeftoverTimeInt;
            localStorage.setItem(this._localStorageLeftoverKey, this._leftoverTimeInt.toString());
          }
        }
      }

      this._leftoverInterval = setInterval(() => {
        if (this._leftoverTimeInt <= 0) {
          if (typeof this._leftoverInterval !== 'undefined') {
            clearInterval(this._leftoverInterval);
          }

          if (this.question !== '')
            this.saveAnswer();

          this._resetTimerInLocalStorage();
        } else {
          localStorage.setItem(this._localStorageLeftoverKey, (--this._leftoverTimeInt).toString());
          this._updateTimer();
        }
      }, 1000);
    }
  }

  /**
   * Saves the answer for the daily
   * question.
   */
  saveAnswer(label?: string): void {
    this._svgLoader.startLoading();

    const reply = {
      Aktion: this._tipAndWinService.campaign,
      TagesQuizId: this._dailyQuizId,
      Label: typeof label !== 'undefined' ? label : '',
      Datum: this._date
    };

    this._saveAnswerSubscription = this._apiService.postRequest('/api/TipAndWin/SaveTagesQuizAntwort', reply).subscribe((data: any) => {
      this._svgLoader.finishLoading();

      if (data.Result === 'OK') {
        this._mpMessaging.openSuccessPanel(data.Message);
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      if (typeof this._leftoverInterval !== 'undefined') {
        clearInterval(this._leftoverInterval);
      }

      this._resetTimerInLocalStorage();
      this._getDailyQuestion();
      this._getPreviousAnswers();
      this._tipAndWinService.updateRanking();
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
      this._resetTimerInLocalStorage();
      this._getDailyQuestion();
      this._getPreviousAnswers();
      this._tipAndWinService.updateRanking();
    });
  }

  /**
   * Gets the hint for the daily quiz.
   */
  private _getDailyQuizHint(): void {
    this.dailyQuizHint = '';
    this._svgLoader.startLoading();

    this._getDailyQuizHintSubscription = this._apiService.getRequest(`/api/TipAndWin/GetTagesQuizHinweis?aktion=${this._tipAndWinService.campaign}`).subscribe((data: any) => {
      this._svgLoader.finishLoading();

      if (data.Result === 'OK') {
        this.dailyQuizHint = data.Records[0];
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Gets the daily question.
   */
  private _getDailyQuestion(): void {
    this._svgLoader.startLoading();

    this._getDailyQuestionSubscription = this._apiService.getRequest(`/api/TipAndWin/GetTagesQuizFrage?aktion=${this._tipAndWinService.campaign}`).subscribe((data: any) => {
      this._svgLoader.finishLoading();
      this._dailyQuizId = -1;
      this._date = '';
      this.question = '';
      this.answers = [];

      if (data.Result === 'OK') {
        if (data.Records.length > 0) {
          this._dailyQuizId = data.Records[0].TagesQuizId;
          this._date = data.Records[0].Datum;
          this.question = data.Records[0].Frage;

          this.answers = data.Records.map((record: any) => {
            return {
              label: record['Label'],
              antwort: record['Antwort'],
              korrekt: record['IsKorrekt']
            };
          });

          this._startTimer();
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      this._updateOpenQuestions();
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Gets the previous answers.
   */
  private _getPreviousAnswers(): void {
    this._svgLoader.startLoading();

    this._getPreviousAnswersSubscription = this._apiService.getRequest(`/api/TipAndWin/GetTnTagesQuizAntworten?aktion=${this._tipAndWinService.campaign}`).subscribe((data: any) => {
      this._svgLoader.finishLoading();

      if (data.Result === 'OK') {
        this.previousAnswers = data.Records;
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }

      this._updateOpenQuestions();
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Formats the given date.
   */
  formatQuestionDate(date: string): string {
    const dateToFormat = new Date(date);
    const day = dateToFormat.getDate() < 10 ? '0' + dateToFormat.getDate() : dateToFormat.getDate();

    return this._days[dateToFormat.getDay()] +
      ', ' +
      day +
      '. ' +
      this._months[dateToFormat.getMonth()] +
      ' ' +
      dateToFormat.getFullYear();
  };

}
