import { Directive, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from './../../services/api.service';

/**
 * This class provides the data for the
 * error pages.
 */
@Directive({
  selector: '[mpCoreErrorPage]',
  exportAs: 'mpCoreErrorPage'
})
export class ErrorPageDirective implements OnInit, OnDestroy {

  public isParticipant: boolean = false;
  public isParticipantInfoAvailable: boolean = false;

  private _getIsParticipantInformationSubscription: Subscription | undefined;

  constructor(
    private _apiService: ApiService
  ) { }

  /**
   * Gets the information, whether or
   * not the user is a participant.
   */
  ngOnInit(): void {
    this._getIsParticipantInformationSubscription = this._apiService.getRequest('/api/Teilnehmer/IsTn').subscribe((data: any) => {
      this.isParticipant = data.Records[0];
      this.isParticipantInfoAvailable = true;
    },
    (error: any) => {
      this.isParticipant = false;
      this.isParticipantInfoAvailable = true;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getIsParticipantInformationSubscription !== 'undefined') {
      this._getIsParticipantInformationSubscription.unsubscribe();
    }
  }

}
