import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the prize celendar
 * evalutation table.
 */
@Component({
  selector: 'mp-gk-prize-calendar-evaluation',
  templateUrl: './prize-calendar-evaluation.component.html'
})
export class PrizeCalendarEvaluationComponent implements OnInit {

  public excelService: string = '/api/Gewinnkalender/GewinnkalenderAuswertung2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _prizeCalendarEvaluationLoader: MpLoader = {
    name: 'prizeCalendarEvaluationLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoader: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the loader for the table data.
   */
  ngOnInit(): void {
    this._mpLoader.registerLoader(this._prizeCalendarEvaluationLoader);
    this._mpLoader.extendLoader('prizeCalendarEvaluationLoader', {
      isReady: true
    });
    this.loader = this._mpLoader.getLoader('prizeCalendarEvaluationLoader');

    this._titleService.setTitleFromLoc('GewinnkalenderAuswertung', 'locGewinnkalender');
    this._mpMenuHighlight.setMenuHighlight('statistiken');
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('prizeCalendarEvaluationLoader');
  }

}
