import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpShoppingBasketService } from './mp-shopping-basket.service';
import { ApiService } from './../../../../services/api.service';
import { MpOrderProcessService } from './../../../../components/order-process/mp-order-process.service';
import { MpWishlistService } from './../wishlist/mp-wishlist.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

import { ShoppingBasketItem } from './shopping-basket-item';
import { Tile } from './../../../../components/card/shop-card/tile';

/**
 * This class provides the shopping basket
 * functions.
 */
@Component({
  selector: 'mp-core-shopping-basket',
  templateUrl: './shopping-basket.component.html',
  styleUrls: [
    './shopping-basket.component.scss',
    './../../../../components/order-process/styles/order-process-shipping.scss',
    './../../../error/styles/error-page.scss'
  ]
})
export class ShoppingBasketComponent implements OnInit, OnDestroy {

  public sBCount: number = 0;
  public notices: Array<string> = [];
  public wlLoaded: boolean = false;
  public addToWlKey: string = '';
  public showDeleteDialog: boolean = false;
  public sBItems: Array<ShoppingBasketItem> = [];
  public sBSum: number = 0;
  public sbInvalid: boolean = false;
  public shoppingBasket: Array<ShoppingBasketItem> = [];
  public groupedBasket: Array<any> = [];
  public shoppingBasketLoaded: boolean = false;
  public deleteItemFunc = this.deleteItem.bind(this);
  public hideDeleteDialog = this._hideDeleteDialog.bind(this);
  public role: string = '';

  private _wl: Array<Tile> | undefined;
  private _noticeSubscription: Subscription | undefined;
  private _wishlistSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _locsSubscription: Subscription | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _apiService: ApiService,
    private _mpOrderProcess: MpOrderProcessService,
    private _mpWishlist: MpWishlistService,
    private _mpMessaging: MpMessagingService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the notices of the shopping basket,
   * gets the basket items, and gets the
   * wishlist data.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((sBItems: Array<ShoppingBasketItem>) => {
      if (sBItems['length'] > 0) {
        this.sBItems = sBItems;

        this.sBCount = sBItems.map((sBItem: ShoppingBasketItem) => {
            return sBItem.Anzahl;
          }).reduce((a: number, b: number) => {
            return a + b;
          });

        this.sBSum = sBItems.map((sBItem: ShoppingBasketItem) => {
            return sBItem.Punkte;
          }).reduce((a: number, b: number) => {
            return a + b;
          });
      } else {
        this.sBItems = [];
        this.sBSum = 0;
        this.sBCount = 0;
      }

      this.shoppingBasket = this._mpShoppingBasket.shoppingBasket;
      const groupedBasketKeys = Object.keys(this._mpShoppingBasket.groupedBasket);
      this.groupedBasket = [];

      if (groupedBasketKeys.length > 0) {
        groupedBasketKeys.forEach((key: string) => {
          
          for (let i = 0; i < this._mpShoppingBasket.groupedBasket[key].length; ++i) {
            let item = this._mpShoppingBasket.groupedBasket[key][i];
            let bild = item?.Bild ?? null;

            if (bild !== null) {
              for (const [k] of Object.entries(item)) {
                if (k === 'Bild') {
                  // <imgPath>\<ArtNr>_<PIN><separator><nrExtension> ==> <impPath>\<ArtNr>_<item.PIN><separator><nrExtension> (https://cdn.netcentive.de/v5/1800x1800\P22571_01-01.jpg)
                  let pin = bild.slice(-9, -7);
                  let underscore = bild.slice(-10, -9);
                  let idx = bild.indexOf(underscore) + 1;
                  let nrExtension = bild.slice(-7);
                  let pathArtNr = bild.slice(0, idx);
                  if (pin === '00') {
                    item.Bild = pathArtNr + '00' + nrExtension;
                  } else {
                    item.Bild = pathArtNr + item.PIN + nrExtension;
                  }
                }
              }
            }
          }
          this._mpShoppingBasket.groupedBasket[key].sort( (a: any, b: any) => {
            return (Date.parse(b.Anlagedatum) - Date.parse(a.Anlagedatum));
          });
          this.groupedBasket.push(this._mpShoppingBasket.groupedBasket[key]);
        });
      }
      this.groupedBasket = this.groupedBasket.slice().reverse();

      this.shoppingBasketLoaded = true;
    });

    this._noticeSubscription = this._apiService.getRequest('/api/Bestellprozess/GetWarenkorbhinweise').subscribe(
      (data: any) => {
        this.notices = data.Records;
      });

    this._mpWishlist.getWishlist();

    if (this._mpWishlist.loaded) {
      this._wl = this._mpWishlist.wishlist;
      this.wlLoaded = true;
    } else {
      this._wishlistSubscription = this._mpWishlist.wishlistLoaded.subscribe(() => {
        this._wl = this._mpWishlist.wishlist;
        this.wlLoaded = true;
      });
    }
  }

  /**
   * Unsubscribe set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._noticeSubscription !== 'undefined') {
      this._noticeSubscription.unsubscribe();
    }

    if (typeof this._wishlistSubscription !== 'undefined') {
      this._wishlistSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }
  }

  /**
   * Deletes an item from the shopping
   * basket.
   */
  deleteItem(key: string): void {
    this.showDeleteDialog = false;
    this._mpShoppingBasket.deleteItem(key);
  }

  /**
   * Triggers the updatint of a given item.
   */
  updatePos(item: ShoppingBasketItem): void {
    if (item.Express && item.Anzahl > item.MaxExpress) {
      item.Express = false;

      if (Object.keys(this.ls.locs).length > 0) {
        if (typeof this._locsSubscription !== 'undefined') {
          this._locsSubscription.unsubscribe();
        }

        this._mpMessaging.openWarningPanel(this.ls.locs['ExpressNichtMoeglichAnzahl'], 5);
      } else {

        this.ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            if (typeof this._locsSubscription !== 'undefined') {
              this._locsSubscription.unsubscribe();
            }

            this._mpMessaging.openWarningPanel(this.ls.locs['ExpressNichtMoeglichAnzahl'], 5);
          }
        });

        this.ls.getLocalization();
      }
    }

    this._mpShoppingBasket.updateItem(item.Key, item.ArtNr, item.CurrentAuspraegung.PIN, item.Express, item.Anzahl, window.scrollY);
  }

  /**
   * Triggers the validation (on step change)
   * of the shopping basket.
   */
  validateBasket(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpOrderProcess.next('warenkorb');
  }

  /**
   * Checks whether or not the article
   * of the tile is in the wish list.
   */
  isInWishlist(artNr: string): boolean {
    if (typeof this._wl !== 'undefined') {
      const inWl = this._wl.find(wlItem => {
        // @ts-ignore
        return wlItem.ArtNr === artNr;
      });

      if (typeof inWl !== 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Adds / removes the item to / from
   * the wish list.
   */
  toggleWishlist(artNr: string, pin: string, key: string): void {
    if (this.isInWishlist(artNr)) {
      const wlItem = this._getWlItem(artNr);

      this._mpWishlist.deleteItem(wlItem.ID, (wl: Array<Tile>) => {
        this._wl = wl;
      });
    } else {
      this._mpWishlist.addItem(artNr, pin, (wl: Array<Tile>) => {
        this._wl = wl;
        this.addToWlKey = key;
        this.showDeleteDialog = true;
      });
    }
  }

  /**
   * Gets the item from the wish list.
   */
  private _getWlItem(artNr: string): any {
    // @ts-ignore
    return this._wl.find(wlItem => {
      // @ts-ignore
      return wlItem.ArtNr === artNr;
    });
  }

  /**
   * Closes thie delete dialog.
   */
  closeWkModal(): void {
    this.showDeleteDialog = false;
  }

  /**
   * Hides the delete dialog.
   */
  private _hideDeleteDialog(): void {
    this.showDeleteDialog = false;
  }

}
