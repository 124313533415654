<div *ngIf="(ls.locsLoaded | async)" class="AdresskachelItemWrapper bg-color-transparent card margin-bottom-48 border-bottom pointer" (mouseover)="isTravelogueHover = true" (mouseleave)="isTravelogueHover = false">
  <div class="AdresskachelItem font-weight-light width-full display-table overflow-visible position-relative" (click)="showTravelogue()">  
    <div class="AdresskachelItemContent font-weight-regular text-align-center width-full height-full display-table-cell">
      <div class="position-relative">
        <img class="bg-img pointer" src="{{travelogue['BuehnenPfad']}}?h=410&w=630&mode=crop">
        <div class="ReiseberichtKachelOverlay position-absolute left-0 right-0 top-0 bottom-0" *ngIf="isTravelogueHover" (click)="showTravelogue()">
          <button class="mp-button _prim" (click)="showTravelogue()">Reisebericht ansehen</button>
        </div>
      </div>
      <div class="text-align-left position-relative padding-12 pointer">
        <h4 class="bold margin-bottom-0">{{travelogue['Titel']}}</h4>
        <div class="margin-bottom-24">{{travelogue['Teaser']}}</div>
        <div class="small">{{ ls.locs['loc'].Von }} {{travelogue['Autor']}}</div>
      </div>
    </div>
  </div>
</div>
