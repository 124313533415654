import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import animateScrollTo from 'animated-scroll-to';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { ApiService } from './../../../../services/api.service';

/**
 * This class provides the functions and
 * data for the my-campaigns page.
 */
@Component({
  selector: 'mp-core-my-campaigns',
  templateUrl: './my-campaigns.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MyCampaignsComponent implements OnInit, OnDestroy {

  public campaigns: Array<any> = [];

  private _startIndex: number = 0;
  private _pagesize: number = 0;
  private _totalrecordcount = this._pagesize * 3;
  private _campaignId: any = null;
  private _getQueryParamsSubscription: Subscription | undefined;
  private _getCampaignsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
    private _apiService: ApiService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Sets the title and the menu
   * highlighting. Triggers the initial
   * load and gets the campaign id from
   * the query params.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('MeineAktionen');
    this._mpMenuHighlight.setMenuHighlight('aktionen');

    this._getQueryParamsSubscription = this._route.paramMap.subscribe((params: any) => {
      if (typeof params.params['AktionsID'] !== 'undefined') {
        this._campaignId = params.params['AktionsID'];
      }

      this.load();
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._getCampaignsSubscription !== 'undefined') {
      this._getCampaignsSubscription.unsubscribe();
    }
  }

  /**
   * Loads the campaigns.
   */
  load(): void {
    if (this._totalrecordcount > this._startIndex) {
      this._getCampaignsSubscription = this._apiService.postRequest('/api/Aktionen/getMeineAktionen', {
        Startindex: this._startIndex,
        Pagesize: this._pagesize
      }).subscribe((data: any) => {
        this._totalrecordcount = data.TotalRecordCount;
        Array.prototype.push.apply(this.campaigns, data.Records);
      });

      this._startIndex = this._pagesize;
    }
  }

  /**
   * Handles the scroll, after the
   * last item is rendered.
   */
  afterRender(): void {
    if (this._campaignId !== null) {
      const anchor = document.getElementById(this._campaignId);
      // @ts-ignore
      const headerHeight = document.querySelector('header') !== null ? document.querySelector('header').getBoundingClientRect().height : 0;

      if (anchor !== null) {
        animateScrollTo(anchor.offsetTop - headerHeight, { maxDuration: 500 });
      }
    }
  }

}
