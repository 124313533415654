<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-select class="margin-bottom-16"
             [model]="button['TypId']"
             (ngChange)="buttonTypeIdChanged($event)"
             *ngIf="!buttonType || buttonType && buttonType['ButtonTypId'] !== 1"
             [elements]="filteredButtonTypes"
             textProperty="Bezeichnung"
             valueProperty="ButtonTypId"
             [mpRequired]="true"
             label="{{ ls.locs['loc'].ThemenbuehnenLinkTyp }}"
             placeholder="{{ ls.locs['loc'].BitteWaehlen }}">
  </mp-core-select>

  <div *ngIf="buttonTypeSet">
    <mp-core-input [ngClass]="{'margin-top-16 display-block': buttonType['ButtonTypId'] !== 1}"
              mpId="acc-editor-buttontext"
              [model]="button['Bezeichnung']"
              (modelChange)="buttonChangeEvent('Bezeichnung', $event)"
              label="{{ ls.locs['loc'].ButtonText }}"
              mpPlaceholder="{{ ls.locs['loc'].ButtonText }} {{ ls.locs['loc'].VerfassenKlein }}"
              [mpRequired]="true">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorLabel"
                   [model]="errorLabel">
    </mp-core-form-error>

    <div *ngIf="buttonType['ButtonTypId'] === 1">
      <div class="margin-bottom-16 margin-top-16 display-block">
        <mp-core-select mpId="ddlTBSText{{button['Id'] || 0}}"
                   [(model)]="button['selectedTBS']"
                   [elements]="tbsList"
                   (ngChange)="tbsChange(button, button['Id'] || 0)"
                   textProperty="Text"
                   [mpRequired]="true"
                   class="padding-right-0 position-relative z-index-3"
                   label="{{buttonType['LinkWertBezeichnung']}}"
                   placeholder="{{ ls.locs['loc'].Textbausteineinfuegen }}">
        </mp-core-select>
      </div>
      <textarea class="margin-top-16 form-control"
                id="editor-{{button['Id'] || 0}}"
                *ngIf="froalaOptionsLoaded"
                [froalaEditor]="froalaOptions"
                (froalaInit)="getFroalaControlsButton2($event)"
                [froalaModel]="content['Text']"
                (froalaModelChange)="changedButtonByFroale($event)"
                [attr.placeholder]="ls.locs['loc'].Seiteninhalt"
                name="editor-{{button['Id'] || 0}}"></textarea>
    </div>
    <mp-core-input *ngIf="buttonType['ButtonTypId'] === 2"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              mpPlaceholder="{{buttonType['LinkWertBezeichnung'] + ' '+ ls.locs['loc'].AngebenKlein}}"
              [mpRequired]="true"
              class="margin-top-16 display-block">
    </mp-core-input>
    <mp-core-input *ngIf="buttonType['ButtonTypId'] === 3"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              mpPlaceholder="{{buttonType['LinkWertBezeichnung'] + ' ' + ls.locs['loc'].VerfassenKlein}}"
              [mpRequired]="true"
              class="margin-top-16 display-block">
    </mp-core-input>
    <mp-core-input *ngIf="buttonType['ButtonTypId'] === 4"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              mpPlaceholder="{{buttonType['LinkWertBezeichnung'] + ' ' + ls.locs['loc'].VerfassenKlein}}"
              [mpRequired]="true"
              class="margin-top-16 display-block">
    </mp-core-input>
    <mp-core-input *ngIf="buttonType['ButtonTypId'] === 5"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              mpPlaceholder="{{buttonType['LinkWertBezeichnung'] + ' ' + ls.locs['loc'].VerfassenKlein}}"
              [mpRequired]="true"
              class="margin-top-16 display-block">
    </mp-core-input>
    <mp-core-input *ngIf="buttonType['ButtonTypId'] === 6"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              mpPlaceholder="{{buttonType['LinkWertBezeichnung'] + ' ' + ls.locs['loc'].VerfassenKlein}}"
              [mpRequired]="true"
              class="margin-top-16 display-block">
    </mp-core-input>
    <div *ngIf="buttonType['ButtonTypId'] === 7">
      <button type="button"
              id="btn-upload-pdf"
              class="mp-button _prim nonefloat distanceforButton width-full margin-top-16"
              (click)="fileUpload.click()">
        {{ ls.locs['loc'].PdfHochladen }}
      </button>
      <input #fileUpload name="fileUpload" type="file" class="display-none" accept="application/pdf, application/zip, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword" (change)="uploadFiles($event)">
      <div *ngIf="file"
           class="margin-top-16 display-block">
        <span [hidden]="!file.name"
              [innerText]="file.name">
        </span>
        <span class="svg-icon __clr-danger __top-4 __size-18 __oneway default-cursor-hover float-none-important"
              [hidden]="uploadSuccessfully">
        </span>
        <span class="svg-icon __clr-success __top-4 __size-18 __check-circle default-cursor-hover float-none-important"
              [hidden]="!uploadSuccessfully">
        </span>
      </div>
    </div>

    <mp-core-input *ngIf="buttonType['ButtonTypId'] > 7"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              [mpRequired]="true">
    </mp-core-input>
    <mp-core-input *ngIf="buttonType['ButtonTypId'] === 8"
              mpId="acc-editor-link"
              [model]="button['LinkWert']"
              (modelChange)="buttonChangeEvent('LinkWert', $event)"
              label="{{buttonType['LinkWertBezeichnung']}}"
              [mpRequired]="true">
    </mp-core-input>
  </div>
</div>
