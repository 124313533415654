<div id="travel-configurator" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-modal class="display-flex flex-justify-cont-center flex-align-items-center flex-direction-column padding-12 text-align-center" [show]="showNotModal"
            title="Wir sind für Sie da!"
            [buttons]="[{text: 'Schliessen', action: closeNotModalFunc, class: '_prim'}]">
    <div>
      <p>Kontaktieren Sie unsere Reiseexperten:</p>
      <p>
        Telefon: <a href="tel:+49 (0) 2602 68696-0" class="binding">+49 (0) 2602 68696-0</a><br />
        E-Mail: <a href="mailto:reisen@magmapool.de" class="binding">reisen@magmapool.de</a>
      </p>
      <p class="font-size-xxs margin-bottom-0">Montag - Freitag: 8:30 Uhr - 17:30 Uhr</p>
      <p class="font-size-xxs">Zum Ortstarif (Mobilfunk abweichend)</p>
    </div>
  </mp-core-modal>
  <div id="rk-head-wrapper" class="z-index-1 display-flex flex-align-items-center flex-justify-cont-center margin-0 padding-0 text-align-center">
    <h2>{{ls.locs['locReisekonfigurator'].TraumUrlaubZumGreifenNah}}</h2>
    <div id="rk-video-wrapper" class="overflow-hidden max-width-full">
      <mp-core-html-video width="100%" autoplay="autoplay" loop="loop" muted="true" [videoSources]='{mp4: "/themes/default/media/videos/rk-video.mp4", webm: "/themes/default/media/videos/rk-video.webm"}'></mp-core-html-video>
      <img class="position-relative max-width-full" src="/themes/default/media/images/rk-video.png" />
    </div>
    <div id="rk-search-wrapper" *ngIf="travelTypeLoaded" class="display-flex flex-justify-cont-center flex-align-items-center z-index-2">
      <div id="rk-search" class="padding-16 width-100-pcnt">
        <div id="tab-wrapper" class="display-flex flex-wrap">
          <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowHotel" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['NurHotel'])" [ngClass]="tab === 0 || tab === travelType['NurHotel'] ? 'active' : ''">
            <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].Hotel}}</span>
            <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].HotelSubHeader | format:[minHotelCategory]}}</span>
          </div>
          <article class="search-elements-wrapper">
            <div class="rk-such-bereich display-flex" [hidden]="tab !== 0 && tab !== travelType['NurHotel']">
              <mp-rk-search-area-hotel-and-flight [travelType]="travelType['NurHotel']" [(params)]="params" [searchFunction]="searchFunc"></mp-rk-search-area-hotel-and-flight>
            </div>
          </article>
          <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowHotelUndFlug" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['HotelUndFlug'])" [ngClass]="tab === travelType['HotelUndFlug'] ? 'active' : ''">
            <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].FlugUndHotel}}</span>
            <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].FlugUndHotelSubHeader}}</span>
          </div>
          <article class="search-elements-wrapper">
            <div class="rk-such-bereich display-flex" [hidden]="tab !== travelType['HotelUndFlug']">
              <mp-rk-search-area-hotel-and-flight [travelType]="travelType['HotelUndFlug']" [(params)]="params" [searchFunction]="searchFunc"></mp-rk-search-area-hotel-and-flight>
            </div>
          </article>
          <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowNurFlug" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['NurFlug'])" [ngClass]="tab === travelType['NurFlug'] ? 'active' : ''">
            <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].NurFlug}}</span>
            <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].NurFlugSubHeader}}</span>
          </div>
          <article class="search-elements-wrapper">
            <div class="rk-such-bereich display-flex" [hidden]="tab !== travelType['NurFlug']">
              <mp-rk-search-area-only-flight [travelType]="travelType['NurFlug']"  [(params)]="params" [searchFunction]="searchFunc"></mp-rk-search-area-only-flight>
            </div>
          </article>
          <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowMietwagen" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['Mietwagen'])" [ngClass]="tab === travelType['Mietwagen'] ? 'active' : ''">
            <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].Mietwagen}}</span>
            <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].MietwagenSubHeader}}</span>
          </div>
          <article class="search-elements-wrapper">
            <div class="rk-such-bereich display-flex" [hidden]="tab !== travelType['Mietwagen']">
              <mp-rk-search-area-rental [travelType]="travelType['Mietwagen']" [(params)]="params"></mp-rk-search-area-rental>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
  <div id="rk-categories-wrapper" class="z-index-1">
    <a (click)="search($event, topic)" *ngFor="let topic of travelTopics; trackBy: trackByIndex" class="position-relative rk-category">
      <div class="rk-category-banner padding-8 position-absolute width-100-pcnt text-align-center">{{ topic.Text }}</div>
      <img class="width-full" src="/themes/default/media/global-types/{{topic.CssClass}}.jpg" alt="{{topic.Text}}" />
    </a>
  </div>
  <mp-rk-tc-support class="hide-on-medium"></mp-rk-tc-support>
  <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" id="rk-bottom-buttons" class="show-on-medium position-fixed bottom-0 width-full" stickyBottom>
    <a class="mp-button _light-grey width-100-pcnt display-flex-important flex-justfiy-cont-center flex-align-items-center flex-direction-column padding-top-bottom-8" href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">
      <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
      <span class="display-block">{{ls.locs['locReisekonfigurator'].Reisebuero}}</span>
    </a>
  </div>
</div>
