import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';
import { ApiService } from './../..//services/api.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { MpMetaService } from './../../services/mp-meta.service';
import { MpTitleService } from './../../services/mp-title.service';
import { MatomoService } from './../../services/matomo.service';
import { CookieService } from '../../services/cookie.service';

/**
 * This class provides the external page for
 * not logged in users.
 */
@Component({
  selector: 'mp-core-extern-fixed',
  templateUrl: './extern-fixed.component.html',
  styleUrls: ['' +
    './extern-fixed.component.scss',
    './../../components/input/styles/input.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ExternFixedComponent implements OnInit, OnDestroy {

  public hamburgerOpen: boolean = false;

  private _apiSubscription: Subscription | undefined;
  private _settingsSubscription: Subscription | undefined;
  private _locsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public cookieService: CookieService,
    private _apiService: ApiService,
    private _router: Router,
    private _authService: AuthService,
    private _roleMappingService: RoleMappingService,
    private _mpMetaService: MpMetaService,
    private _titleService: MpTitleService,
    private _matomoService: MatomoService
  ) { }

  /**
   * Checks whether or not the user is already
   * logged in and redirects the user, if he
   * is logged in.
   *
   * Triggers the functions to set meta tags,
   * and some other 'head'-tags.
   */
  ngOnInit(): void {
    let currentLocation = '/' + location.hash;
    const currentLocationParam = currentLocation !== '/#/' ? currentLocation.replace('/#', '') : currentLocation.replace('/#/', '');

    this._apiSubscription = this._apiService.getRequest('/api/Auth/GetRedirect', false, { params: { redirectUri: currentLocationParam } }).subscribe((data: any) => {
      const newLocation = data.Records[0];
      let role = '';
      let roleFromUrl = '';

      if (newLocation.indexOf('#') === 1) {
        roleFromUrl = newLocation.split('/')[2];
        role = this._roleMappingService.getMappedRole(roleFromUrl);
      }

      if (currentLocation !== newLocation && role !== '') {
        if (this._authService.getRole() !== role) {
          this._authService.setRole(role);
        }

        this._authService.setUserLoggedIn();
        this._router.navigate([newLocation.replace('/#/', '')]);
      } else if (currentLocation === newLocation) {
        if (this._authService.getRole() !== role) {
          this._authService.setRole(role);
        }
      }
    },
      (error: any) => {
        console.log(error.message);
        this._authService.setRole('');
      });

    this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags !== null) {
          this._mpMetaService.setTagsBySettings(this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags);
        }

        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== null) {
          this._titleService.setTitle(this.mpSettings.settings['NetcentiveCoreSettings'].Shopname);
        }

        if (this.mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
          this._matomoService.setMatomo();
        }

        if (typeof this._settingsSubscription !== 'undefined') {
          this._settingsSubscription.unsubscribe();
        }
      }
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this.ls.getLocalization();
    }

    this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this._mpMetaService.setDescriptionTag(this.ls.locs['loc'].MetaText);

        if (typeof this._locsSubscription !== 'undefined') {
          this._locsSubscription.unsubscribe();
        }
      }
    });

    this._mpMetaService.setBasicTags();
    this._mpMetaService.setFavicon();
    this._mpMetaService.setThemeColorTag();
  }

  /**
   * Checks whether or not there are subscriptions,
   * and unsubscribes them.
   */
  ngOnDestroy(): void {
    if (typeof this._apiSubscription !== 'undefined') {
      this._apiSubscription.unsubscribe();
    }

    if (typeof this._settingsSubscription !== 'undefined') {
      this._settingsSubscription.unsubscribe();
    }

    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }
  }

}
