import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the companies ranking 
 * table.
 */
@Component({
  selector: 'mp-leas-companies-ranking',
  templateUrl: './companies-ranking.component.html'
})
export class CompaniesRankingComponent implements OnInit {

  public excelService: string = '/api/Firmen/FirmenRanking2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _leasCompaniesRankingLoader: MpLoader = {
    name: 'leasCompaniesRankingLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._leasCompaniesRankingLoader);

    this._mpLoaderService.extendLoader('leasCompaniesRankingLoader', {
      isReady: true
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasCompaniesRankingLoader');
  }

}
