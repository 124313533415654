<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table title=""
                 *ngIf="loaderName !== ''"
                 fieldsAction="/api/Statistik/getPraemienStatistikChildFields"
                 listAction="/api/Statistik/getPraemienStatistikChild"
                 loaderName="{{loaderName}}"
                 [paging]="false"
                 [footer]="false"
                 [startEmpty]="true"
                 defaultSorting="Punkte DESC">
  </mp-core-data-table>
</div>
