<div class="stage-container" *ngIf="(ls.locsLoaded | async)">
  <div class="stage">
    <div id="home-editor-carousel" class="owl-theme">
      <div class="owl-carousel owl-loaded">
        <div class="owl-stage-outer">
          <div class="owl-stage">
            <div class="owl-item active">
              <div class="item">
                <img src="/themes/default/media/buehnen/seiten/charity.jpg" />
                <div class="caption">
                  <span [innerHTML]="ls.locs['locLeas'].CharityBuehnenTeaser"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>
<mp-core-user-profile-navbar></mp-core-user-profile-navbar>
