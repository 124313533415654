import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';

import { AdmComponent } from './adm.component';

import { HomeComponent } from './pages/home/home.component';

@NgModule({
  declarations: [
    AdmComponent,
    HomeComponent
  ],
  exports: [
    AdmComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonInternComponentsLoaderModule,
    CarouselModule
  ]
})
export class AdmModule { }
