<div id="nav-breadcrumb" class="display-flex flex-align-items-center margin-bottom-8">
  <div id="breadcrumb">
    <a class="mp-button text-color-grey-info" (click)="goBackFromCore()">{{ ls.locs['loc'].Zurueck }}</a>

    <!-- authenticated -->
    <ng-container *ngIf="isAuthenticated">
      <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
      <a class="mp-button text-color-grey-info font-weight-regular" (click)="goToFromCore('dashboard')">{{ ls.locs['loc'].Home }}</a>
      <i *ngIf="dashboardTab.Path !== undefined  && dashboardTab.Path !== null"
         class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover">
      </i>
      <a *ngIf="dashboardTab.Path !== undefined  && dashboardTab.Path !== null"
         class="mp-button text-color-grey-info font-weight-regular"
         (click)="breadcrumbClickDashboard(dashboardTab.Path, false)">
        <span [innerHTML]="dashboardTab.Loc" class="text-overflow-ellipsis text-align-left"></span>
      </a>
      <ng-container *ngFor="let site of currentSteps">
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular"
           (click)="breadcrumbClickSite(site.Path)">
          <span [innerHTML]="site.Loc" class="text-overflow-ellipsis text-align-left"></span>
        </a>
      </ng-container>
      <i *ngIf="currentSite.Path !== undefined  && currentSite.Path !== null"
         class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover">
      </i>
      <a *ngIf="currentSite.Path !== undefined  && currentSite.Path !== null"
         class="mp-button text-color-grey-info font-weight-regular"
         (click)="breadcrumbClickSite(currentSite.Path)">
        <span [innerHTML]="currentSite.Loc" class="text-overflow-ellipsis text-align-left"></span>
      </a>
    </ng-container>

    <!-- unauthenticated -->
    <span *ngIf="!isAuthenticated"
          class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
    <a *ngIf="!isAuthenticated"
       class="mp-button text-color-grey-info font-weight-regular" (click)="goToFromCore('Login', true)">{{ ls.locs['loc'].LoginTitel }}</a>
    <i *ngIf="!isAuthenticated && currentSite.Path !== undefined  && currentSite.Path !== null"
       class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover">
    </i>
    <a *ngIf="!isAuthenticated && currentSite.Path !== undefined  && currentSite.Path !== null"
       class="mp-button text-color-grey-info font-weight-regular"
       (click)="breadcrumbClickSite(currentSite.Path)">
      <span [innerHTML]="currentSite.Loc" class="text-overflow-ellipsis text-align-left"></span>
    </a>
  </div>
</div>
