import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';
import { CustomEventService, CustomEvent, CustomEventType } from './../../../services/custom-event.service';

/**
 * This class provides a sidebar for multiselect.
 */
@Component({
  selector: 'mp-core-sidebar-multiselect',
  templateUrl: './sidebar-multiselect.component.html',
  styleUrls: ['./sidebar-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarMultiselectComponent implements OnInit, OnDestroy {

  public showAllSelectedElements: boolean = false;
  public list: any[] = [];
  public filteredList: any[] = [];
  public selectedList: any[] = [];
  public showResults: boolean = false;
  public noResults: boolean = false;

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _customEventServicee: CustomEventService
  ) { }

  /**
   * Watches for changes of the sidebar
   * parameters, and triggers all functions
   * to update the selected / unselected
   * items.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.list = this.mpSidebar.params['list'];
      this.filteredList = this.mpSidebar.params['filteredList'];
      this.selectedList = this.mpSidebar.params['selectedList'];
      this._setItemChecked();

      if (this.mpSidebar.params['all']) {
        this.selectAll(true);
      }

      this.showResults = typeof this.mpSidebar.params['searchTermMinLength'] === 'undefined' || this.mpSidebar.params['searchTerm'].length >= this.mpSidebar.params['searchTermMinLength'];
      this._filter();
      this._checkIfAllSelected();
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Toggles the view between all selected
   * elements and all elements.
   */
  toggleShowAllSelectedElements(): void {
    this.showAllSelectedElements = !this.showAllSelectedElements;
  }

  /**
   * Does the search.
   */
  search(): void {
    this._filter();
  }

  /**
   * Removes the search term from the search
   * input and sets the focus to the input.
   */
  clearSearch(event: MouseEvent): void {
    this.mpSidebar.params['searchTerm'] = '';
    // @ts-ignore
    event.currentTarget.parentElement.parentElement.querySelector('input').focus();
    this._filter();
  }

  /**
   * Filters the list of all items by
   * given conditions.
   */
  private _filter(): void {
    let searchResultsList: any = [];
    this.showResults = false;

    if (this.mpSidebar.params['searchTerm'] && this.mpSidebar.params['searchTerm'].length > 2) {
      searchResultsList = this.list.filter((item: any) => { return item['Displaytext'].toLowerCase().indexOf(this.mpSidebar.params['searchTerm'].toLowerCase()) > -1; });
    } else {
      searchResultsList = this.list;
    }

    this.noResults = !searchResultsList || searchResultsList.legnth === 0;

    if (typeof searchResultsList !== 'undefined' && searchResultsList.length > 0) {
      searchResultsList = searchResultsList.filter((searchResult: any) => { return searchResult.selected === false; });
    }

    if ((typeof this.mpSidebar.params['maxViewCount'] === 'undefined' ||
      searchResultsList.length <= this.mpSidebar.params['maxViewCount']) &&
      (typeof this.mpSidebar.params['searchTermMinLength'] === 'undefined' ||
        this.mpSidebar.params['searchTerm'].length >= this.mpSidebar.params['searchTermMinLength'])) {
      this.filteredList = searchResultsList;
      this.showResults = true;
    }
  }

  /**
   * Sets the checked / unchecked items.
   */
  private _setItemChecked(): void {
    if (typeof this.list !== 'undefined' && typeof this.selectedList !== 'undefined') {
      this.list.forEach((listItem: any) => {
        if (this.selectedList.some((item: any) => { return item['Identifier'] === listItem['Identifier']; })) {
          listItem['selected'] = true;
        } else {
          listItem['selected'] = false;
        }
      });
    }
  }

  /**
   * Checks whether or not all of the
   * available items are selected.
   */
  private _checkIfAllSelected(): void {
    this.mpSidebar.params['all'] =
      this.selectedList &&
      this.list &&
      this.selectedList.length === this.list.length &&
      this.list.length > 0; // 'all' is true only if there are items to select (MG, 2024-01-10)
  }

  /**
   * Selects all available items or
   * deselects all items.
   */
  selectAll(value: boolean, event?: MouseEvent) {
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }

    if (value) {
      this.selectedList = this.list;
    } else {
      this.selectedList = [];
    }

    this.list.forEach((listItem: any) => {
      listItem['selected'] = value;
    });

    this.mpSidebar.params['searchTerm'] = '';

    //this.mpSidebar.params['all'] = value;
    this._checkIfAllSelected();  // don't set 'all' to value, but check it (MG, 2024-01-10)

    this._filter();
  }

  /**
   * Selects / unselects the clicked item.
   */
  setItem(item: any): void {
    const existing = this.selectedList && this.selectedList.some((listItem: any) => { return listItem['Identifier'] === item['Identifier']; });

    if (this.mpSidebar.params['limit'] && this.selectedList.length >= this.mpSidebar.params['limit'] && !existing) {
      return;
    }

    if (existing) {
      item['selected'] = false;
    } else {
      item['selected'] = true;
    }

    this.selectedList = this.list.filter((listItem: any) => { return listItem['selected'] === true; });

    this._filter();
    this._checkIfAllSelected();
  }

  /**
   * Updates the sidebar params and calls
   * the save function (if exists).
   */
  save(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['list'] = this.list;
    this.mpSidebar.params['filteredList'] = this.filteredList;
    this.mpSidebar.params['selectedList'] = this.selectedList;

    this._customEventServicee.dispatch(new CustomEvent(CustomEventType.SendGroupingBool, true));

    this._checkIfAllSelected();

    if (this.mpSidebar.params['all'] && !this.mpSidebar.params['listAllInText']) {
      this.mpSidebar.params['text'] = this.ls.locs['loc'].Alle;
    } else {
      if (this.mpSidebar.params['selectedList'] && this.mpSidebar.params['selectedList'].length === 0) {
        this.mpSidebar.params['text'] = this.mpSidebar.params['defaultText'];
      } else {
        if (this.mpSidebar.params['selectedList'] && this.mpSidebar.params['selectedList'].length > 0) {
          let text = '';

          this.mpSidebar.params['selectedList'].forEach((listITem: any) => {
            text += `${listITem['DisplaytextKurz']},`;
          });

          this.mpSidebar.params['text'] = text.substr(0, text.length - 1);
        } else {
          this.mpSidebar.params['text'] = this.mpSidebar.params['defaultText'];
        }
      }
    }

    this._customEventServicee.dispatch(new CustomEvent(CustomEventType.SendSelectedBool, true));

    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }
}
