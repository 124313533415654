import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { GameSvgFetcherService } from './../../../../services/game-svg-fetcher.service';

/**
 * This class provides the data and
 * functions for the spring campaign page.
 */
@Component({
  selector: 'mp-gfc-spring-campaign',
  templateUrl: './spring-campaign.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SpringCampaignComponent implements OnInit, OnDestroy {

  public showDoors: boolean = false;
  public showRanking: boolean = false;
  public positionOld: { [key: string]: any } = {};
  public ranking: Array<any> = [];
  public filteredRanking: Array<any> = [];
  public rankingGroups: { [key: string]: any } = {};
  public toggleRankingFunc = this.toggleRanking.bind(this);
  public backgroundSvg: SafeHtml = '';
  public dealerSvg: SafeHtml = '';
  public signSvg: SafeHtml = '';

  private _carDealerColor: { [key: string]: any } = {
    light: '#e65f0f',
    primary: '#00915a',
    dark: '#807e7e'
  };
  private _campaignKey: string = '';
  private _participantId: number = 0;
  private _keys: number = 0;
  private _availableKeys: number = 0;
  private _doors: number = 0;
  private _branch: number = 0;
  private _rankingOld: Array<any> = [];
  private _rankingShortOld: Array<any> = [];
  private _positionNew: { [key: string]: any } = {};
  private _rankingNew: Array<any> = [];
  private _rankingShortNew: Array<any> = [];
  private _rankingComplete: Array<any> = [];
  private _svgColoringClasses: { [key: string]: any } = {
    light: [
      '#fj-auto-autohaus .st-fj-auto-haus-207'
    ],
    primary: [
      '#fj-auto-autohaus .st-fj-auto-haus-2'
    ],
    dark: [
      '#fj-auto-autohaus .st-fj-auto-50',
      '#fj-auto-autohaus .st-fj-auto-haus-1'
    ]
  };
  private _backgroundPath = 'spring-campaign/background';
  private _dealerPath = 'spring-campaign/car-dealer';
  private _signPath = 'spring-campaign/sign';
  private _fetchBackgroundSvgContentSubscription: Subscription | undefined;
  private _fetchDealerSvgContentSubscription: Subscription | undefined;
  private _fetchSignSvgContentSubscription: Subscription | undefined;
  private _getQueryParamsSubscription: Subscription | undefined;
  private _getSpringCampaignSubscription: Subscription | undefined;
  private _getGroupsSubscription: Subscription | undefined;
  private _generateCarDealerColor: Subscription | undefined;
  private _updateSpringCampaignSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute,
    private _gameSvgFetcher: GameSvgFetcherService
  ) { }

  /**
   * Gets the campaign key and the
   * spring campaign data. Fetches
   * the svgs.
   */
  ngOnInit(): void {
    this._fetchBackgroundSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._backgroundPath).subscribe((svgContent: SafeHtml) => {
      this.backgroundSvg = svgContent;
    });

    this._fetchDealerSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._dealerPath).subscribe((svgContent: SafeHtml) => {
      this.dealerSvg = svgContent;
    });

    this._fetchSignSvgContentSubscription = this._gameSvgFetcher.fetchSvg(this._signPath).subscribe((svgContent: SafeHtml) => {
      this.signSvg = svgContent;
    });

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._campaignKey = params['key'];

      this._getSpringCampaignSubscription = this._apiService.postRequest('/api/Fruehjahrsaktion/GetFruehjahrsaktion', {
        AktionenKey: this._campaignKey
      }).subscribe((data: any) => {
        if (data.Result === 'OK') {
          const springCampaign = data.Records[0];
          this._participantId = springCampaign['FruehjahrsaktionTeilnehmer'].TnId;
          this._branch = springCampaign['FruehjahrsaktionTeilnehmer'].Branche;
          this.positionOld = springCampaign['PositionAlt'];
          this._rankingOld = springCampaign['RanglisteAlt'];
          this._rankingShortOld = springCampaign['RanglisteKurzAlt'];
          this._positionNew = springCampaign['Position'];
          this._rankingNew = springCampaign['Rangliste'];
          this._rankingShortNew = springCampaign['RanglisteKurz'];
          this._keys = this.positionOld['Schluessel'];
          this._availableKeys = springCampaign['FruehjahrsaktionTeilnehmer'].SchluesselVerfuegbar;
          this._doors = springCampaign['FruehjahrsaktionTeilnehmer'].Tueren;
          this.ranking = this._rankingShortOld;
          this._rankingComplete = this._rankingOld;

          const colors = [{
            'primaryColor': springCampaign['FruehjahrsaktionTeilnehmer'].Farbe.split(';')[0],
            'darkColor': springCampaign['FruehjahrsaktionTeilnehmer'].Farbe.split(';')[1]
          }];

          this._carDealerColor['primary'] = colors[0]['primaryColor'];
          this._carDealerColor['dark'] = colors[0]['darkColor'];
          this._refreshColors();
          this._getGroups();
        }
        else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._fetchBackgroundSvgContentSubscription !== 'undefined') {
      this._fetchBackgroundSvgContentSubscription.unsubscribe();
    }

    if (typeof this._fetchDealerSvgContentSubscription !== 'undefined') {
      this._fetchDealerSvgContentSubscription.unsubscribe();
    }

    if (typeof this._fetchSignSvgContentSubscription !== 'undefined') {
      this._fetchSignSvgContentSubscription.unsubscribe();
    }

    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._getSpringCampaignSubscription !== 'undefined') {
      this._getSpringCampaignSubscription.unsubscribe();
    }

    if (typeof this._getGroupsSubscription !== 'undefined') {
      this._getGroupsSubscription.unsubscribe();
    }

    if (typeof this._generateCarDealerColor !== 'undefined') {
      this._generateCarDealerColor.unsubscribe();
    }

    if (typeof this._updateSpringCampaignSubscription !== 'undefined') {
      this._updateSpringCampaignSubscription.unsubscribe();
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Gets the groups for the spring
   * campaign.
   */
  private _getGroups(): void {
    this._getGroupsSubscription = this._apiService.postRequest('/api/Fruehjahrsaktion/GetGruppen', {}).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.rankingGroups = data.Records[0]
        this.filteredRanking = this._rankingComplete;
      } else {
        this._mpMessaging.openErrorPanel(data);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Refreshes the colors of the
   * embedded svgs.
   */
  private _refreshColors(): void {
    if (this.positionOld === null || Object.keys(this.positionOld).length === 0 || this.positionOld['Tueren'] === 0)
      return;

    Object.keys(this._svgColoringClasses).forEach((colorKey: string) => {
      this._svgColoringClasses[colorKey].forEach((colorClass: string) => {
        let intvalCnt = 0;

        const getColoringElementsIntval = setInterval(() => {
          const coloringElements = document.querySelectorAll(colorClass);

          if (coloringElements.length > 0) {
            clearInterval(getColoringElementsIntval);

            coloringElements.forEach((coloringElement: any) => {
              coloringElement.style.fill = this._carDealerColor[colorKey];
            });
          } else if (intvalCnt === 20) {
            clearInterval(getColoringElementsIntval);
          }

          intvalCnt++;
        }, 50);
      });
    });
  }

  /**
   * Toggles the ranking modal.
   */
  toggleRanking(): void {
    this.showRanking = !this.showRanking;
  }

  /**
   * Filters the spring campaign
   * groups.
   */
  filterGroups(): void {
    if (this.rankingGroups['Gruppe'].gruppe === 0) {
      this.filteredRanking = this._rankingComplete;
    } else {
      this.filteredRanking = this._rankingComplete.filter((group: { [key: string]: any }) => {
        return group['Branche'] === this.rankingGroups['Gruppe'].gruppe;
      });
    }
  }

  /**
   * Openes the door of the car
   * dealer.
   */
  openDoor(): void {
    this._generateCarDealerColor = this._apiService.postRequest('/api/Fruehjahrsaktion/GenerateCarDealerColor', {}).subscribe((data: any) => {
      if (data.Result === 'OK') {
        const updateData = data.Records[0];

        if (updateData[0]) {
          this._carDealerColor['primary'] = updateData[0];
          this._carDealerColor['dark'] = updateData[1];
          this._procceedWithOpenDoor();
        }
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Updates the spring campaign and triggers
   * the animation.
   */
  private _procceedWithOpenDoor(): void {
    this.positionOld['Tueren'] = this.positionOld['Tueren'] + this.positionOld['SchluesselVerfuegbar'];
    this.positionOld['Schluessel'] = this.positionOld['Schluessel'] + this.positionOld['SchluesselVerfuegbar'];

    const doorsOpened = this.positionOld['Tueren'];
    const keys = this.positionOld['Schluessel'];
    const keysAvail = 0;
    const branchenNr = this._branch;

    const updateData = {
      AktionenKey: this._campaignKey,
      tnId: this._participantId,
      schluessel: keys,
      schluesselVerfuegbar: keysAvail,
      tueren: doorsOpened,
      branche: branchenNr,
      farbe: this._carDealerColor['primary'] + ';' + this._carDealerColor['dark']
    }

    this._updateSpringCampaignSubscription = this._apiService.postRequest('/api/Fruehjahrsaktion/UpdateFruehjahrsaktion', updateData).subscribe((data: any) => {
      if (data.Result === 'OK') {
        const updatedData = data.Records[0];
        this._availableKeys = updatedData['SchluesselVerfuegbar'];
        this._doors = updatedData['Tueren'];

        const colors = [{
          'primaryColor': updatedData['Farbe'].split(';')[0],
          'darkColor': updatedData['Farbe'].split(';')[1]
        }];

        this._carDealerColor['primary'] = colors[0].primaryColor;
        this._carDealerColor['dark'] = colors[0].darkColor;
        this._openDoorAnimations();
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Aniamtions the door opening.
   */
  private _openDoorAnimations(): void {
    const fadeAnimationElem = document.getElementById('fj-auto-fade-animation');
    const door = document.querySelector('#fj-auto-autohaus #tuer');
    const button = document.querySelector('#fj-auto-game-information .mp-button._prim');

    if (fadeAnimationElem !== null && door !== null && button !== null) {
      if (typeof door.classList === 'undefined') {
        door.setAttribute('class', 'open');
      } else {
        if (door.classList.contains('open') === false) {
          door.classList.add('open');
        }
      }

      if (button.classList.contains('disabled') === false) {
        button.classList.add('disabled');
      }

      setTimeout(() => {
        if (fadeAnimationElem.classList.contains('fade') === false) {
          fadeAnimationElem.classList.add('fade');
        }

        setTimeout(() => {
          this._refreshColors();

          if (typeof door.classList === 'undefined') {
            door.removeAttribute('class');
          } else {
            if (door.classList.contains('open') !== false) {
              door.classList.remove('open');
            }
          }

          if (button.classList.contains('disabled') !== false && this.positionOld['SchluesselVerfuegbar'] > 0) {
            button.classList.remove('disabled');
          }

          if (fadeAnimationElem.classList.contains('fade') !== false) {
            fadeAnimationElem.classList.remove('fade');
          }
        }, 900);
      }, 3500);

      setTimeout(() => {
        setTimeout(() => {
          this.positionOld['SchluesselVerfuegbar'] = this._availableKeys;
          this.positionOld['Tueren'] = this._doors;

          this.ranking = this._rankingShortNew;
          this._rankingComplete = this._rankingNew;
        }, 500)

        this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].FruehjahrsaktionTnOpenDoorSuccessText);
      }, 4800);
    }
  }

}
