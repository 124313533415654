import { Directive, ElementRef, HostListener, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'

/**
 * This class provides a directive scroll event
 * on window in buttom
 */
@Directive({
  selector: '[stickyBottom]'
})
export class MpStickyBottomDirective {

  public elementToStick: HTMLElement|undefined = undefined;
  public footerOffset: number = 0;
  public footerHeight: number = 0;
  public scrollPos: number = 0;
  public footer: any;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) document: Document
  ) { }

  ngOnInit() {
    this.elementToStick = <HTMLElement>this._elementRef.nativeElement;
    this.stick();
  }

  ngAfterViewInit() {
    this.stick();
  }

  /**
  * Decorator with call of the stick() function
  */
  @HostListener('window:scroll', ['$event']) 
  onScroll(event: Event) {
    this.stick();
  }

  /**
  * Function for handling of the scroll event 
  */
  stick() {
    if (document.getElementById('footer')) {
      this.footer = document.getElementById('footer');
      let footerStyle = getComputedStyle(this.footer);
      this.footerHeight = parseInt(footerStyle.getPropertyValue('height').replace('px', '')) + (parseInt(footerStyle.getPropertyValue('padding-top').replace('px', '')) * 2);
      const rect = this.footer.getBoundingClientRect();
      this.footerOffset = rect.top + window.scrollY;
      this.scrollPos = window.pageYOffset + window.innerHeight;

      if (this.footerOffset <= this.scrollPos && typeof this.elementToStick !== 'undefined') {
        this._renderer.setAttribute(this.elementToStick, 'style', 'position: absolute !important; bottom: ' + this.footerHeight.toString() + 'px;');
      } else if (this.footerOffset > this.scrollPos && typeof this.elementToStick !== 'undefined') {
        this._renderer.setAttribute(this.elementToStick, 'style', 'position: fixed !important; bottom: 0px;');
      }
    }
  }
}
