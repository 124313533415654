import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[afterImageLoad]'
})

/**
* This class provides a directive to handle event
* after load of any image.
*/
export class MpAfterImageLoadDirective {

  @Output() afterImageLoad = new EventEmitter();

  constructor(private _elementRef: ElementRef<HTMLImageElement>) {
    if (this._elementRef.nativeElement.complete) {
      this.afterImageLoad.emit();
    }
  }

  /**
  * Decorator for handling a event
  * after load of any image
  */
  @HostListener('load')
  public onLoad() {
    this.afterImageLoad.emit();
  }
}
