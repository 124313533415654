import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the data for
 * the 500 error page.
 */
@Component({
  selector: 'mp-core-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./../../styles/error-page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Error500Component implements OnInit {

  public role: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }
  }

}
