import { Component, OnInit } from '@angular/core';

import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { ParticipantCockpitService } from '../participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the communication data
 * statistics.
 */
@Component({
  selector: 'mp-core-communication-data',
  templateUrl: './communication-data.component.html'
})
export class CommunicationDataComponent implements OnInit {

  public excelService = '/api/Com/Kommunikationsdaten2Excel';
  public getCommunicationDataLoader: MpLoader = {
    name: 'getCommunicationDataLoader',
    params: {}
  };
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    }
  ];
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  constructor(
    private _mpLoader: MpLoaderService,
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _participantCockpitService: ParticipantCockpitService
  ) { }

  /**
   * Registers the loader, sets the title,
   * and the menu highlighting.
   */
  ngOnInit(): void {
    this._mpLoader.registerLoader(this.getCommunicationDataLoader);
    this._mpLoader.extendLoader('getCommunicationDataLoader', {
      isReady: true
    });
    this.loader = this._mpLoader.getLoader('getCommunicationDataLoader');

    this._titleService.setTitleFromLoc('Kommunikationsdaten');
    this._mpMenuHighlight.setMenuHighlight('kommunikation');
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['TnID']);
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('getCommunicationDataLoader');
  }

}
