<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].TeilnehmerRanking }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].TeilnehmerRanking }}" 
                 infotextShorten="TeilnehmerRanking" 
                 fieldsAction="/api/Statistik/getTeilnehmerRankingFields" 
                 listAction="/api/Statistik/getTeilnehmerRanking" 
                 [recLoadedFunc]="recLoadedFunc" 
                 [showDatePicker]="true" 
                 loaderName="participantRankingLoader"
                 [execClickFunctions]="execClickFunctions"
                 [paging]="true" 
                 [footer]="true" 
                 [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways" 
                 defaultSorting="Punkte DESC"></mp-core-data-table>
    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a href="{{excelService}}?{{loader.getExcelParams()}}">
        <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
      </a>
    </div>
  <div class="ui-helper-clearfix"></div>
</div>
