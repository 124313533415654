  <div class="white-content-container" *ngIf="(ls.locsLoaded | async)">

    <div *ngIf="hasMajorData">
      <h1>{{ ls.locs['loc'].BitteErgaenzenSieIhreStammdaten }}</h1>
      <div class="margin-bottom-32">
        <h2>Ihre persönlichen Daten</h2>
        <div class="flex-grid _m-w-small-none">
          <div class="flex-col-4 flex-direction-column margin-bottom-16">
            <mp-core-select *ngIf="majorDataLoaded && salutationsLoaded" label="{{ ls.locs['loc'].Anrede }}"
                       [hasError]="errors['stammdaten.AnredenID'] ? true : false"
                       [(model)]="majorData['AnredenID']"
                       [elements]="salutations"
                       [mpDisabled]="changeMajorData && profile['AnredenID']"
                       textProperty="Anrede"
                       valueProperty="AnredenID"
                       [mpRequired]="true">
            </mp-core-select>
            <mp-core-form-error *ngIf="errors['stammdaten.AnredenID']"
                           [model]="errors['stammdaten.AnredenID']">
            </mp-core-form-error>
          </div>
          <div class="flex-col-4 flex-direction-column margin-bottom-16">
            <mp-core-input *ngIf="majorDataLoaded" [(model)]="majorData['Vorname']"
                      [hasError]="errors['stammdaten.Vorname'] ? true : false"
                      label="{{ ls.locs['loc'].Vorname }}"
                      mpId="vorname"
                      [mpDisabled]="changeMajorData && profile['Vorname']"
                      [mpRequired]="true">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['stammdaten.Vorname']"
                           [model]="errors['stammdaten.Vorname']">
            </mp-core-form-error>
          </div>
          <div class="flex-col-4 flex-direction-column margin-bottom-16">
            <mp-core-input *ngIf="majorDataLoaded" [(model)]="majorData['Name']"
                      [hasError]="errors['stammdaten.Name'] ? true : false"
                      label="{{ ls.locs['loc'].Name }}"
                      mpId="name"
                      [mpDisabled]="changeMajorData && profile['Name']"
                      [mpRequired]="true">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['stammdaten.Name']"
                           [model]="errors['stammdaten.Name']">
            </mp-core-form-error>
          </div>
          <div class="flex-col-12 flex-direction-column margin-bottom-16">
            <mp-core-sidebar-input *ngIf="majorDataLoaded" class="width-full-important"
                           text="{{majorData['GeburtsdatumParams'].date && majorData['GeburtsdatumParams'].date.isValid() ? majorData['GeburtsdatumParams'].date.format('L') : ls.locs['loc'].BitteWaehlen}}"
                           label="{{ ls.locs['loc'].Geburtsdatum }}"
                           [mpDisabled]="changeMajorData && majorData['Geburtsdatum']"
                           [sidebarParams]="majorData['GeburtsdatumParams']"
                           sidebarComponent="SidebarDate"
                           [hasError]="errors['stammdaten.Geburtsdatum'] ? true : false">
            </mp-core-sidebar-input>
            <mp-core-form-error *ngIf="errors['stammdaten.Geburtsdatum']"
                           [model]="errors['stammdaten.Geburtsdatum']">
            </mp-core-form-error>
          </div>
        </div>
      </div>
      <div class="margin-bottom-32">
        <h2>Ihre Kontaktdaten</h2>
        <div class="flex-grid _m-w-small-none">
          <div class="flex-col-6 flex-direction-column margin-bottom-16">
            <mp-core-input *ngIf="contactDataLoaded" [(model)]="majorData['Telefon_G']"
                      [hasError]="errors['stammdaten.Telefon_G'] ? true : false"
                      label="{{ ls.locs['loc'].Telefon }}"
                      mpId="telefon"
                      [mpDisabled]="changeMajorData && contact['Telefon_G']"
                      [mpRequired]="true">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['stammdaten.Telefon_G']"
                           [model]="errors['stammdaten.Telefon_G']">
            </mp-core-form-error>
          </div>
          <div class="flex-col-6 flex-direction-column margin-bottom-16">
            <mp-core-input *ngIf="contactDataLoaded" [(model)]="majorData['Email_G']"
                      [hasError]="errors['stammdaten.Email_G'] ? true : false"
                      label="{{ ls.locs['loc'].EMail }}"
                      mpId="email"
                      [mpDisabled]="changeMajorData && contact['Email_G']"
                      [mpRequired]="true">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['stammdaten.Email_G']"
                           [model]="errors['stammdaten.Email_G']">
            </mp-core-form-error>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="hasConditionsOfParticipation">
      <h1>{{ ls.locs['loc'].Teilnahmebedingungen }}</h1>
      <div class="teilnahmebedingungen-accept-area margin-bottom-32">
        <div class="margin-bottom-16">
          <mp-core-checkbox [(model)]="tbDs['teilnahmebedingungen']"
                            mpId="chkTeilnahmebedingungenAkzeptiert"
                            [hasError]="tbDs['errorTeilnahmebedingungen'] ? true : false"
                            label="{{ ls.locs['loc'].IchHabeDieTeilnahmebedingungenGelesenUndAkzeptiert | format:['firstlogin'] }}"></mp-core-checkbox>
          <mp-core-form-error *ngIf="tbDs['errorTeilnahmebedingungen'] === true && tbDs['teilnahmebedingungen']===false"
                              [model]="ls.locs['loc'].DieTeilnahmebedingungenMuessenAkzeptiertWerden"></mp-core-form-error>
        </div>
        <div class="margin-bottom-16">
          <mp-core-checkbox [(model)]="tbDs['datenschutzerklaerung']"
                            mpId="chkDatenschutzerklaerungAkzeptiert"
                            [hasError]="tbDs['errorDatenschutzerklaerung'] ? true : false"
                            label="{{ ls.locs['loc'].IchHabeDieDatenschutzerklaerungGelesenUndAkzeptiert | format:['firstlogin'] }}"></mp-core-checkbox>
          <mp-core-form-error *ngIf="tbDs['errorDatenschutzerklaerung'] === true && tbDs['datenschutzerklaerung']===false"
                              [model]="ls.locs['loc'].DieDatenschutzerklaerungMussAkzeptiertWerden"></mp-core-form-error>
        </div>
        <div *ngIf="mpSettings.settings['ErstanmeldungSettings'].NewsletterBestaetigen" class="margin-bottom-16">
          <mp-core-checkbox [(model)]="tbDs['newsletter']"
                            mpId="chkNewsletterAkzeptiert"
                            label="{{ ls.locs['loc'].IchHabeDenNewsletterGelesenUndAkzeptiert | format:['firstlogin'] }}"></mp-core-checkbox>
        </div>
        <div *ngIf="mpSettings.settings['ErstanmeldungSettings'].NewsletterBestaetigen && mpSettings.settings['ErstanmeldungSettings'].EmailTrackingBestaetigen && tbDs['newsletter']"
             class="margin-bottom-16 margin-left-32">
          <mp-core-checkbox [(model)]="tbDs['emailTracking']"
                            mpId="chkEmailTrackingAkzeptiert"
                            label="{{ ls.locs['loc'].IchHabeDasEmailTrackingGelesenUndAkzeptiert | format:['firstlogin'] }}"></mp-core-checkbox>
        </div>
      </div>
    </div>

  </div>

