import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { InitialLoginService } from './../../../services/initial-login.service';
import { ApiService } from './../../../services/api.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';

/**
 * This class provides the functions and
 * data for the step "major data" of the
 * first login module.
 */
@Component({
  selector: 'mp-core-first-login-major-data',
  templateUrl: './major-data.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MajorDataComponent implements OnInit, OnDestroy {

  @Input() public firstLoginSettings: { [key: string]: any } = {};

  public majorData: { [key: string]: any } = {};
  public contact: { [key: string]: any } = {};
  public profile: { [key: string]: any } = {};
  public majorDataLoaded: boolean = false;
  public contactDataLoaded: boolean = false;
  public changeMajorData: boolean = false;
  public salutations: Array<any> = [];
  public salutationsLoaded: boolean = false;
  public errors: { [key: string]: any } = {};

  private _getLocsSubscription: Subscription | undefined;
  private _getProfileSubscription: Subscription | undefined;
  private _getContactSubscription: Subscription | undefined;
  private _getSalutationsSubscription: Subscription | undefined;
  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _saveMajorDataSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _initialLoginService: InitialLoginService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the localization. Subscribes to
   * the validate step and next step observabbles
   * of the InitialLoginService. Gets the contact
   * data and the salutations.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._getProfileData();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._getProfileData();
        }
      });

      this.ls.getLocalization();
    }

    this.changeMajorData = this.firstLoginSettings['StammdatenAendern'];

    this._getContactSubscription = this._apiService.getRequest('/api/Teilnehmer/getKontakt').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.contact = data.Records[0];
        this.majorData = Object.assign(this.majorData, data.Records[0]);
        this.contactDataLoaded = true;
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });

    this._getSalutationsSubscription = this._apiService.getRequest('/api/Teilnehmer/getAnreden').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.salutations = data.Records.filter((salutation: { [key: string]: any }) => {
          return salutation['Land'] === 'DE';
        });

        this.salutationsLoaded = true;
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });

    this._validateStepSubscription = this._initialLoginService.validateStep(3).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLoginService.validateCalled.next(false);
        this._initialLoginService.validBool = true;
        this._initialLoginService.valid.next(true);
      }
    });

    this._nextStepSubscription = this._initialLoginService.nextCalledStep(3).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this._initialLoginService.validBool) {
          this._saveMajorData();
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getProfileSubscription !== 'undefined') {
      this._getProfileSubscription.unsubscribe();
    }

    if (typeof this._getContactSubscription !== 'undefined') {
      this._getContactSubscription.unsubscribe();
    }

    if (typeof this._getSalutationsSubscription !== 'undefined') {
      this._getSalutationsSubscription.unsubscribe();
    }

    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._nextStepSubscription !== 'undefined') {
      this._nextStepSubscription.unsubscribe();
    }

    if (typeof this._saveMajorDataSubscription !== 'undefined') {
      this._saveMajorDataSubscription.unsubscribe();
    }
  }

  /**
   * Gets the profile data from the
   * backend.
   */
  private _getProfileData(): void {
    this._getProfileSubscription = this._apiService.getRequest('/api/Teilnehmer/getProfil').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.profile = data.Records[0];

        this.profile['GeburtsdatumParams'] = {
          Headline: this.ls.locs['loc'].Geburtsdatum,
          date: moment(data.Records[0].Geburtsdatum)
        };

        this.majorData = Object.assign(this.majorData, this.profile);
        this.majorDataLoaded = true;
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });
  }

  /**
   * Saves the major data.
   */
  private _saveMajorData(): void {
    this.majorData['Geburtsdatum'] = this.majorData['GeburtsdatumParams'].date.toDate();

    this._saveMajorDataSubscription = this._apiService.postRequest('/api/Erstanmeldung/setStammdaten', this.majorData).subscribe((data: any) => {
      this._initialLoginService.nextSuccess();
    },
    (error: any) => {
      if (error.status === 400)
        this.errors = error.ModelState;

      this._initialLoginService.nextFailure();
    });
  }

}
