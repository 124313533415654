<div id="charity-beschreibung" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="stage">
    <owl-carousel-o id="charity-carousel" class="owl-theme" [options]="charitySliderProperties">
      <ng-container *ngFor="let item of [{ID:1}]">
        <ng-template carouselSlide>
          <div class="item">
            <img src="/themes/default/media/buehnen/seiten/charity.jpg?width={{ mpSettings.settings['DisplaySettings'].BuehnenBildBreite }}&height={{ mpSettings.settings['DisplaySettings'].BuehnenBildHoehe }}&mode=crop" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="layout-container">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <h1>{{ ls.locs['locLeas'].LeasePlanEngagiertSich }}</h1>
    <div [innerHTML]="ls.locs['locLeas'].CharityBeschreibung"></div>
  </div>
</div>
