import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';

@Component({
  selector: 'mp-core-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  @Input() public model: any;
  @Input() public startdate?: Date;
  @Input() public enddate?: Date;
  @Input() public minDate?: Date | undefined;
  @Input() public maxDate?: Date | undefined;

  @Output() change: EventEmitter<object> = new EventEmitter<object>();
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() startdateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() enddateChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(
    public ls: MpLocalizationService
  ) { }

  ngOnInit(): void {}

  /**
  * Passes start date and end date to parent component.
  */
  startDateChanged(): void {
    this.startdateChange.emit(this.startdate);
  }

  /**
  * Passes end date and end date to parent component.
  */
  endDateChanged(): void {
    this.enddateChange.emit(this.enddate);
  }

  /**
   * Executes change event.
   */
  executeEvent(event: object, eventType: string): void {
    switch (eventType) {
    case 'change':
      this.change.emit(event);
      break;
    default:
    }
  }
}
