<section id="konto" class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div mpCoreAccount #mpAccount="mpCoreAccount">

    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowBreadcrumbs" class="breadcrumb">
      <a class="breadcrumb-item" href="#/{{role}}/Home">{{ ls.locs['loc'].Home }}</a>
      <i class="breadcrumb-splitter"></i>
      <a class="breadcrumb-item" href="#/{{role}}/Konto" [innerText]="hasStatus ? ls.locs['loc'].Konten : ls.locs['loc'].Konto"></a>
    </div>

    <div class="konto">
      <h2 class="mobile-only">{{ ls.locs['loc'].MeinPunktekonto }}</h2>
      <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasPunkteverfall" class="alert alert-info">
        <h2>{{ ls.locs['loc'].PraemienpunkteVerfall }}</h2>
        {{ ls.locs['loc'].Verfallspunkte | format:[mpAccount.nextDecayDate,mpAccount.threatenedPoints,mpAccount.threatenedStatusPoints] }}
      </div>

      <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].UnifiedSearchbox">
        <input type="text" [(ngModel)]="mpAccount.searchTerm" placeholder="{{ ls.locs['loc'].Suchen }}" />
        <button class="mp-button _prim" (click)="mpAccount.search()">{{ ls.locs['loc'].Suchen }}</button>
      </div>

      <mp-core-data-table *ngIf="mpAccount.loaderRegistered" title="{{ ls.locs['loc'].MeinPunktekonto }}"
                          fieldsAction="/api/Konto/getKontoFields"
                          listAction="/api/Konto/getKonto"
                          loaderName="accountTableLoader"
                          [paging]="true"
                          [footer]="true"
                          defaultSorting="Datum DESC">
      </mp-core-data-table>

      <div *ngIf="hasStatus" id="StatusKonto"></div>
      <div *ngIf="hasStatus" mpCoreStatusAccount #mpStatusAccount="mpCoreStatusAccount" class="status-konto">
        <h2 class="mobile-only">{{ ls.locs['loc'].MeinStatusPunktekonto }}</h2>
        <a class="displayNone" href="StatusKonto"></a>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].UnifiedSearchbox">
          <input type="text" [(ngModel)]="mpStatusAccount.statusSearchTerm" placeholder="{{ ls.locs['loc'].Suchen }}" />
          <button class="mp-button _prim" (click)="mpStatusAccount.statusSearch()">{{ ls.locs['loc'].Suchen }}</button>
        </div>
        <mp-core-data-table title="{{ ls.locs['loc'].MeinStatusPunktekonto }}"
                            fieldsAction="/api/StatusKonto/getStatusKontoFields"
                            listAction="/api/StatusKonto/getStatusKonto"
                            loaderName="statusAccountTableLoader"
                            [paging]="true"
                            [footer]="true"
                            [recLoadedFunc]="mpAccount.scrollToStatusAccount"
                            defaultSorting="Datum DESC">
        </mp-core-data-table>
      </div>
    </div>
  </div>
</section>


