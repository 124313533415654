import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { withLatestFrom } from 'rxjs/operators';

import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpCategoriesService } from './../../services/mp-categories.service';
import { ApiService } from './../../services/api.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { MpMessagingService } from './../../services/mp-messaging.service';

/**
* This class provides a input for search of awards, that
* can be configured with different input attributes.
*/
@Component({
  selector: 'mp-core-search-awards',
  templateUrl: './search-awards.component.html',
  styleUrls: ['./search-awards.component.scss']
})
export class SearchAwardsComponent implements OnInit {
  @Input() public mpId: string = '';
  @Input() public focusOn: boolean = false;
  @Input() public searchInCategory: boolean = false;

  public lastSearchSuggestRequestId: number = 0;
  public data: any = { searchTerm: ''};
  public showSearch: boolean = false;
  public focus: boolean = false;
  public showSuggests: boolean = false;
  public results: any;
  public selectedCategory: any;
  public loading: boolean = false;
  public searchTextSuggests: Array<any> = [];

  private _categories: any;
  private _currentCategory: string = '';
  public _currentCategoryID: string = '';
  private _role: string = '';

  private _queryParamsSubscription: Subscription | undefined;
  private _routeChangeSubscription: Subscription | undefined;
  private _categoryTreeSubscription: Subscription | undefined;
  private _categoryIdSubscription: Subscription | undefined;
  private _articleForSuggestSubscription: Subscription | undefined;

  private _routeParams = {
    k: ''
  }

  public setSearchFilterFunc = this.setSearchFilter.bind(this);
    
  constructor(
    public ls: MpLocalizationService,
    private _mpCategories: MpCategoriesService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Angulars init function. Loads the
   * category tree and the currently
   * selected category.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this._queryParamsSubscription = this._route.url.pipe(
      withLatestFrom(this._route.paramMap, this._route.queryParamMap)
    ).subscribe((routeValues: any) => {
      const params = routeValues[1].params;
      const qParams = routeValues[2].params;

      if (typeof params['id'] !== 'undefined' && params['id'] !== null) {
        this._routeParams.k = params['id'];
      } else if (typeof qParams['k'] !== 'undefined' && qParams['k'] !== null) {
        this._routeParams.k = qParams['k'];
      } else {
        this._routeParams.k = '';
      }
    });

    this._routeChangeSubscription = this._router.events.subscribe(() => {
      this.closeSearch();
    });

    this._categoryTreeSubscription = this._mpCategories.categoryTree.subscribe((tree: any) => {
      if (tree !== null) {
        this._categories = tree;
      }

      this._updateShownCategories();
    });
    this._categoryIdSubscription = this._mpCategories.categoryId.subscribe((catId: string) => {
      this._currentCategory = catId;
      this._updateShownCategories();
    });

    this._mpCategories.loadCategoryTree();
    this._mpCategories.getCategoryId();
  }

  /**
   * Unsubscribes set subscribers.
   */
  ngOnDestroy(): void {
    if (typeof this._categoryTreeSubscription !== 'undefined') {
      this._categoryTreeSubscription.unsubscribe();
    }

    if (typeof this._categoryIdSubscription !== 'undefined') {
      this._categoryIdSubscription.unsubscribe();
    }

  }

  /**
   * Shows search award input field
   */
  openSearch() {
    this.showSearch = true;
    this.focus = true;
  }

  /**
  * Closes search award input field
  */
  closeSearch() {
    this.showSearch = false;
    this.showSuggests = false;
  }

  /**
  * Sets search filter
  */
  setSearchFilter() {
    const resWhere = typeof this.results !== 'undefined' ? this.results.Records.find((item: any) => {
      return item.IsSpecialSearchResult === true;
    }) : null;
    let specialResult = this.results && this.results.Records && resWhere;

    if (specialResult)
      this._router.navigate([specialResult.Link]);
    else {
      if (this.data.searchTerm) {
        this.showSearch = false;
        this.closeSearch();
        this.showSuggests = false;
        
        if (this._routeParams.k !== '' && this.searchInCategory) {
          this._router.navigate([`/${this._role}/Shop`], {
            queryParams: {
              s: this.data.searchTerm,
              k: this._routeParams.k
            }
          });
        } else {
          this._router.navigate([`/${this._role}/Shop`], {
            queryParams: {
              s: this.data.searchTerm 
            }
          });
        }
        this.data.searchTerm = '';
      }
    }
  }

  /**
   * Updates the categories in the
   * category tree.
   */
  _updateShownCategories() {
    if (this._categories && this._categories.length) {
      if (this._currentCategoryID) {
        this.selectedCategory = this._mpCategories.catList.subscribe((catList: any) => {
          const selectedCategory = catList.find((cat: any) => {
            return cat['KategorieID'] === parseInt(this._currentCategoryID);
          });

          if (selectedCategory) {
            this._currentCategory = selectedCategory.Bezeichnung;
          } else {
            let tree = this._categories;
            this._currentCategory = tree ? tree.Bezeichnung : "";
          }
        });
      }
    }
  }

  /**
   * Assesses search in categories
   */
  evalSucheInKategorie(value: any) {
    this.searchInCategory = value;
  }

  /**
   * Gets search suggestions
   */
  getSearchSuggests() {
    if (this.data.searchTerm.length > 2) {
      this.loading = true;
      this._articleForSuggestSubscription = this._apiService.postRequest('/api/Artikel/getArtikelBySearchtextForSuggest',
        {
          Suchtext: this.data.searchTerm,
          Kategorie: this.searchInCategory && this.selectedCategory
              ? this.selectedCategory.KategorieID
            : this.searchInCategory && this._routeParams.k && this._routeParams.k !== ''
              ? this._routeParams.k : 0,
          RequestId: ++this.lastSearchSuggestRequestId
        }).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this.loading = false;
            if (this.lastSearchSuggestRequestId === data.footer[0]) {
              if (data.RecordCount > 0) {
                this.results = data;
                this.searchTextSuggests = data.Records;

                this.showSuggests = true;
              } else {
                this.searchTextSuggests = [];
                this.showSuggests = false;
              }
            }
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
          }
        },
        (error: any) => {
          this._mpMessaging.openErrorPanel(error);
        });
    } else {
      this.searchTextSuggests = [];

      this.showSuggests = false;
    }
  }

  /**
   * Sets search suggestions
   */
  setSearchSuggest(searchTerm: any, event: MouseEvent) {
    event.preventDefault();

    const clickedItem = this.results.Records.find((item: any) => {
      return item.ArtBez === searchTerm;
    });
    if (clickedItem.IsSpecialSearchResult)
      this._router.navigateByUrl(clickedItem.Link);
    else {
      this.data.searchTerm = searchTerm;
      this.setSearchFilter();
    }
  }

  /**
   * Clears search suggestions
   */
  clearSearchSuggests() {
    this.searchTextSuggests = [];
    this.showSuggests = false;
  }

  /**
   * Hides or displayes search field
   */
  toggleSuche() {
    this.showSearch = !this.showSearch;
    this.focus = true;
  }
}
