import { Directive, ElementRef } from '@angular/core';

/**
 * This class provides functions to
 * stick & unstick headlines (on
 * scrolling) in the inbox.
 */
@Directive({
  selector: '[mpCoreInboxStickHeadline]'
})
export class InboxStickHeadlineDirective {

  /**
   * Sets the scroll event listener.
   */
  constructor(
    private _element: ElementRef
  ) {
    window.removeEventListener('scroll', this._handleHeadlineSticking.bind(this));
    window.addEventListener('scroll', this._handleHeadlineSticking.bind(this));
  }

  /**
   * Handles the stick / unstick of
   * headlines.
   */
  private _handleHeadlineSticking(): void {
    const headlineElem = this._element.nativeElement;
    const navbar = document.getElementById('navbar');
    const credits = document.getElementById('credits');
    const userProfileNavbar = document.getElementById('user-profile__navbar');
    const firstItemUnderHeadline = headlineElem.nextElementSibling;
    const headlineElemBCR = headlineElem.getBoundingClientRect();

    const stickLine = (navbar !== null ? navbar.getBoundingClientRect().height : 0)
      + (credits !== null ? !credits.classList.contains('credits-hidden') ? credits.getBoundingClientRect().height : 0 : 0)
      + (userProfileNavbar !== null ? userProfileNavbar.getBoundingClientRect().height : 0);

    if (headlineElemBCR.top <= stickLine) {
      if (firstItemUnderHeadline !== null) {
        if (firstItemUnderHeadline.getBoundingClientRect().top > headlineElemBCR.height + stickLine) {
          if (headlineElem.classList.contains('sticked')) {
            headlineElem.removeAttribute('style');
            headlineElem.classList.remove('sticked');
            const placeholderDiv = headlineElem.previousElementSibling;

            if (placeholderDiv !== null) {
              if (typeof placeholderDiv.id !== 'undefined' && placeholderDiv.id === 'handleHeadlineSticking') {
                placeholderDiv.remove();
              }
            }
          }
        } else {
          if (!headlineElem.classList.contains('sticked')) {
            headlineElem.classList.add('sticked');
            headlineElem.style.position = 'fixed';
            headlineElem.style.marginTop = '0';
            headlineElem.style.left = `${headlineElemBCR.left}px`;
            headlineElem.style.width = `${headlineElemBCR.width}px`;
            headlineElem.style.zIndex = '8';
            headlineElem.style.top = `${stickLine}px`;
            const placeholderDiv = document.createElement('div');
            placeholderDiv.style.height = headlineElemBCR.height + 'px';
            placeholderDiv.id = 'handleHeadlineSticking';
            headlineElem.insertAdjacentElement('beforebegin', placeholderDiv);
          }
        }
      }
    }
  }

}
