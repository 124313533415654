import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';

/**
 * This class provides a simple select-input, that
 * can be configured with different input attributes
 * (e.g. for required fields, for optional fields, ...).
 */
@Component({
  selector: 'mp-core-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {
  @Input() public label: string = '';
  @Input() public mpId: string = '';
  @Input() public mpType: string = '';
  @Input() public mpEnter: Function | undefined;
  @Input() public mpRequired: boolean = false;
  @Input() public mpDisabled: boolean = false;
  @Input() public keyup: Function | undefined;
  @Input() public mpPlaceholder: string = '';
  @Input() public mpOptional: boolean = false;
  @Input() public mpSup: string = '';
  @Input() public hasError: boolean = false;
  /*@Input() public currencySign: string = '';*/
  @Input() public maxLength: number = 0;
  @Input() public noTrim: boolean = false;
  @Input() public mpMin: number = 0;
  @Input() public mpSize: number = 0;
  @Input() public model: string = '';
  @Input() public focusOn: boolean = false;

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputBlur: EventEmitter<object> = new EventEmitter<object>();

  public mpIdHash: string = '';
  public labelText: string = '';

  constructor(public ls: MpLocalizationService) { }

  /**
  * Angulars init function of the class. It sets
  * an id hash to have an unique id on each input
  * element in DOM and it assignes the given
  * label to another variable.
  */
  ngOnInit(): void {
    this.mpIdHash = `${this.mpId}-${(Math.floor((Math.random() + 0.9) * 1000000000))}`;
    this.labelText = this.label;
  }

  /**
  * Listens to keyup event and executes the given
  * functions for mpEnter and / or keyup.
  */
  executeKeyup(event: any): void {
    if (event.keyCode === 13 && this.mpEnter) {
      this.mpEnter();
    }

    if (this.keyup) {
      this.keyup();
    }
  }

  /**
  * Listens to blur event and
  * emits those events back to the parent
  * component.
  */
  executeEvent(event: object, eventType: string): void {
    switch (eventType) {
    case 'blur':
      this.inputBlur.emit(event);
      break;
    default:
    }
  }

}
