import { Component, OnInit, Input, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';
import { ApiService } from './../../services/api.service';
import { MpErrorHandlerService } from './../../services/mp-error-handler.service';
import { MpMessagingService } from './../../services/mp-messaging.service';
import { MpBreadcrumbService } from './../../services/mp-breadcrumb.service';

import { Topic } from './../faq/topic';

/**
 * This class provides the functions
 * for the contact form.
 */
@Component({
  selector: 'mp-core-contact-form',
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit, OnDestroy {

  @Input() public showWithoutTopicFields: boolean = false;
  @Input() public showWithoutServiceTextAdditional: boolean = false;
  @Input() public showAllTopics: boolean = true;
  @Input() public defaultTopicID: number = 8;
  @Input() public showEmpty: boolean = false;

  public contact: { [key: string]: Topic } = {
    choiceOfTopics: {
      ThemaID: 0,
      Thema: '',
      HelplineBezeichnung: null,
      AnzahlTexte: 0
    }
  };

  public ddl: Array<Topic> = [];
  public orderPos: Array<any> = [];
  public data: { [key: string]: any } = {};
  public orderTAN: string = '';
  public businessnumbers: Array<any> = [];
  public errors: { [key: string]: any } = {};
  public errorMessages: Array<string> = [];
  public extern: boolean = false;
  public dataLoaded: boolean = false;

  private _requestKey: string = '';
  private _topics: Array<Topic> = [];
  private _locsSubscription: Subscription | undefined;
  private _queryParamsSubscription: Subscription | undefined;
  private _topicSubscription: Subscription | undefined;
  private _getOrderSubscription: Subscription | undefined;
  private _businessnumbersScubscription: Subscription | undefined;
  private _orderPosForContactSubscription: Subscription | undefined;
  private _contactDataSubscription: Subscription | undefined;
  private _paramsSubscription: Subscription | undefined;
  private _sendContacRequestSubscription: Subscription | undefined;
  private _getSettingsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    public mpSettings: MpSettingsService,
    public sanitizer: DomSanitizer,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _mpErrorHandler: MpErrorHandlerService,
    public _mpMessaging: MpMessagingService,
    private _router: Router
  ) { }

  /**
   * Loads the locs and triggers the
   * needed functions to get the contact
   * data.
   */
  ngOnInit(): void {
    this._breadcrumbService.setCurrentSiteWithLoc('Kontakt', 'loc', 'Kontakt');

    if (Object.keys(this.ls.locs).length > 0) {
      this._getTopic();
      this._handleQueryParams();
      this._getContactData();
    } else {
      this.ls.getLocalization();

      this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._getTopic();
          this._handleQueryParams();
          this._getContactData();
        }
      });
    }

    if (Object.keys(this.mpSettings.settings).length > 0) {
      if (this.contact['choiceOfTopics'].ThemaID === null) {
        this.contact['choiceOfTopics'].ThemaID = this.mpSettings.settings['NetcentiveCoreSettings'].KontaktDefaultThemaId;
      }
    } else {
      this._getSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (this.contact['choiceOfTopics'].ThemaID === null) {
            this.contact['choiceOfTopics'].ThemaID = this.mpSettings.settings['NetcentiveCoreSettings'].KontaktDefaultThemaId;
          }
        }
      });

      this.mpSettings.getSettings();
    }
    
    this._getBusinessnumbers();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }

    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._topicSubscription !== 'undefined') {
      this._topicSubscription.unsubscribe();
    }

    if (typeof this._getOrderSubscription !== 'undefined') {
      this._getOrderSubscription.unsubscribe();
    }

    if (typeof this._businessnumbersScubscription !== 'undefined') {
      this._businessnumbersScubscription.unsubscribe();
    }

    if (typeof this._orderPosForContactSubscription !== 'undefined') {
      this._orderPosForContactSubscription.unsubscribe();
    }

    if (typeof this._contactDataSubscription !== 'undefined') {
      this._contactDataSubscription.unsubscribe();
    }

    if (typeof this._paramsSubscription !== 'undefined') {
      this._paramsSubscription.unsubscribe();
    }

    if (typeof this._sendContacRequestSubscription !== 'undefined') {
      this._sendContacRequestSubscription.unsubscribe();
    }

    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the contact data.
   */
  private _getContactData(): void {
    this._contactDataSubscription = this._apiService.getRequest('/api/Com/getKontaktData').subscribe((data: any) => {
      this.data = data.Records[0];
      this._resetSubject();
      this.dataLoaded = true;
    });
  }

  /**
   * Handles query params and assigns them
   * to the variables.
   */
  private _handleQueryParams(): void {
    this._queryParamsSubscription = this._route.queryParamMap.subscribe((params: any) => {
      const key = params.get('key');
      const orderNr = params.get('bestnr');

      if (key !== null) {
        this._requestKey = key;
      }

      if (orderNr !== null) {
        this.orderTAN = orderNr;
      }

      if (this.orderTAN === '') {
        const bParam = params.get('b');

        if (bParam !== null) {
          this.orderTAN = bParam;

          this.contact['choiceOfTopics'] = {
            ThemaID: 3,
            Thema: this.ls.locs['loc'].ReklamationPraemie,
            HelplineBezeichnung: null,
            AnzahlTexte: 1
          }
        }
      }
    });

    this._paramsSubscription = this._route.paramMap.subscribe((params: any) => {
      if (typeof params['extern'] !== 'undefined') {
        this.extern = true;
      }
    });
  }

  /**
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Gets the topics and sets
   * the default choice of topics.
   */
  private _getTopic(): void {
    this._topicSubscription = this._apiService.getRequest(`/api/FAQ/getThema?inklusiveAlle=${!!this.showAllTopics}`).subscribe((data: any) => {
      this._topics = data.Records.filter((topic: Topic) => {
        return this.showEmpty || topic.AnzahlTexte > 0;
      });

      const defaultTopic = this._topics.find((topic: Topic) => {
        return topic.ThemaID === this.defaultTopicID;
      });

      if (typeof defaultTopic !== 'undefined') {
        this.contact['choiceOfTopics'] = defaultTopic;
      }
    });
  }

  /**
   * Resets the subject.
   */
  private _resetSubject(): void {
    if (this.orderTAN !== '') {
      this.data['BestellNr'] = this.orderTAN.substr(0, this.orderTAN.lastIndexOf('-'));
    }

    if (this._requestKey !== '') {
      this.data['Subject'] = this._requestKey + '';
    } else if (this.contact['choiceOfTopics'].ThemaID === null || this.contact['choiceOfTopics'].ThemaID !== null && this.contact['choiceOfTopics'].ThemaID < 1) {
      this.data['Subject'] = '';
    } else {
      this.data['Subject'] = this.contact['choiceOfTopics'].Thema;
    }
  }

  /**
   * Gets the order data, if the
   * choice of topics is "order".
   */
  getData(): void {
    if (this.contact['choiceOfTopics'].ThemaID === 1 || this.contact['choiceOfTopics'].ThemaID === 3) {
      this._getOrderSubscription = this._apiService.getRequest('/api/FAQ/getBestellung').subscribe((data: any) => {
        this.ddl = data.Records;
        if (this.ddl.length === 0) {
          this.data['BestellNr'] = '-';
        }
      },
      (error: any) => {
        this.ddl = [];
        this.data['BestellNr'] = '-';
      });
    } else {
      this.ddl = [];
    }
  }

  /**
   * Triggered, when changes of the
   * choice occurred.
   */
  changeChoice(): void {
    this._resetSubject();
    this.getData();
  }

  /**
   * Triggered, when changes of the
   * order occurred.
   */
  changeOrderData(): void {
    this.orderPos = [];
    this.data && (this.data['BestellTAN'] = null);

    this._orderPosForContactSubscription = this._apiService.getRequest(`/api/Bestellung/getBestellPosFuerKontakt?BestellNr=${this.data['BestellNr']}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.orderPos = data.Records.map((orderPos: any) => {
          orderPos.Text = `${orderPos.ArtNr}-${orderPos.PIN}-${orderPos.ArtBez}`;
          orderPos.Text = orderPos.Text.replace('<br /><br />', ', ');
          return orderPos;
        });

        this.orderPos.splice(0, 0, {
          BestellTAN: 'Alle',
          Text: this.ls.locs['loc'].Alle
        });

        this.orderTAN = 'Alle';
        this.selectOrderTAN();
      }
    });
  }

  /**
   * Gets the business numbers.
   */
  private _getBusinessnumbers(): void {
    this._businessnumbersScubscription = this._apiService.getRequest('/api/FAQ/getGeschaeftsnummer').subscribe((data: any) => {
      this.businessnumbers = data.Records;
    });
  }

  /**
   * Sets the order TAN.
   */
  selectOrderTAN(): void {
    this.data['BestellTAN'] = this.orderTAN;
  }

  /**
   * Sends the contact request to
   * the support.
   */
  send(): void {
    this.data['HL_Bezeichnung'] = this.contact['choiceOfTopics'].HelplineBezeichnung;
    this.data['THemaID'] = this.contact['choiceOfTopics'].ThemaID || 0;
    this.errorMessages = [];
    this.errors = {};
    this.data['Extern'] = this.extern;

    this._sendContacRequestSubscription = this._apiService.postRequest('/api/Com/sendeKontaktMail/', this.data).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openSuccessPanel(data.Message);
        this._router.navigate([this._router.url]);
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this.errors = error['ModelState'];
      this._updateErrMessage(this._mpErrorHandler.handleResponse(error));
    });
  }

  /**
   * Updates the error messages.
   */
  private _updateErrMessage(asErrMessages: Array<string>): void {
    this.errorMessages = asErrMessages;
    this._mpMessaging.openDangerPanel(this.ls.locs['loc'].KontaktFehler);
  }

}
