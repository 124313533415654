import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpSettingsService } from './../../../../../services/mp-settings.service';
import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpSidebarService } from './../../../../../components/sidebar/mp-sidebar.service';
import { MpShoppingBasketService } from './../mp-shopping-basket.service';
import { MpOrderProcessService } from './../../../../../components/order-process/mp-order-process.service';
import { AuthService } from './../../../../../services/auth.service';
import { RoleMappingService } from './../../../../../services/role-mapping.service';

import { ShoppingBasketItem } from './../shopping-basket-item';

/**
 * This class provides the overview (sidebar)
 * functions of the shopping basket.
 */
@Component({
  selector: 'mp-core-sb-overview',
  templateUrl: './sb-overview.component.html',
  styleUrls: ['./sb-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SbOverviewComponent implements OnInit, OnDestroy {

  public orderUrl: string | null = null;
  public itemAdded: boolean = false;
  public isNotSbOverlay: boolean = false;
  public sBItems: Array<ShoppingBasketItem> = [];
  public sBSum: number = 0;
  public sBCount: number = 0;
  public role: string = '';

  private _stepsSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    private _mpSidebar: MpSidebarService,
    public mpShoppingBasket: MpShoppingBasketService,
    private _mpOrderProcess: MpOrderProcessService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Watches for steps loaded, and sets
   * the order url (if exists).
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._shoppingBasketSubscription = this.mpShoppingBasket.shoppingBasketObserver.subscribe((sBItems: Array<ShoppingBasketItem>) => {
      if (sBItems['length'] > 0) {
        this.sBItems = sBItems;

        this.sBItems.sort((a: any, b: any) => {
          return (Date.parse(b.Anlagedatum) - Date.parse(a.Anlagedatum));
        });

        Object.values(this.sBItems).forEach(val => {
          if (val.Bild !== null && val.Bild.slice(-10, -9) === '_') {
            if (val.Bild !== null && val.Bild.slice(-9, -7) === '00') {
              val.Bild = val.Bild.slice(0, val.Bild.indexOf(val.Bild.slice(-10, -9)) + 1) + '00' + val.Bild.slice(-7);
            } else {
              val.Bild = val.Bild.slice(0, val.Bild.indexOf(val.Bild.slice(-10, -9)) + 1) + val.PIN + val.Bild.slice(-7);
            }
          }
        });

        this.sBSum = sBItems.map((sBItem: ShoppingBasketItem) => {
            return sBItem.Punkte;
          }).reduce((a: number, b: number) => {
            return a + b;
          });

        this.sBCount = sBItems.map((sBItem: ShoppingBasketItem) => {
            return sBItem.Anzahl;
          }).reduce((a: number, b: number) => {
            return a + b;
          });
      } else {
        this.sBItems = [];
        this.sBSum = 0;
        this.sBCount = 0;
      }
    });

    this._stepsSubscription = this._mpOrderProcess.steps.subscribe((steps: any) => {
      const secondStepKey = Object.keys(steps).find((key: string) => steps[key].Index === 1);
      let secondStep;

      if (typeof secondStepKey !== 'undefined') {
        secondStep = steps[secondStepKey];
      }

      if (typeof secondStep !== 'undefined') {
        this.orderUrl = secondStep.Url;
      }
    });

    const params = this._mpSidebar.params;

    if (typeof params['sbItemAdded'] !== 'undefined') {
      this.itemAdded = params['sbItemAdded'];
    } else {
      this.itemAdded = false;
    }

    this.isNotSbOverlay = this.itemAdded;
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Goes to the order into the
   * shopping basket.
   */
  toOrder(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpOrderProcess.next('warenkorb');
  }

  /**
   * Closes the sidebar.
   */
  closeSidebar(): void {
    this._unwatch();
    this._mpSidebar.close();
  }

  /**
   * Deletes the given item from the
   * shopping basket.
   */
  deleteSbItem(key: string): void {
    this.mpShoppingBasket.deleteItem(key);
  }

  /**
   * Gets the currently sidebar params
   * after sidebar was closed.
   */
  private _unwatch(): void {
    this._sidebarParamsSubscription = this._mpSidebar.paramsChange.subscribe((params: any) => {
      if (params) {
        if (typeof params.sbItemAdded !== 'undefined') {
          this.itemAdded = params.sbItemAdded;
        } else {
          this.itemAdded = false;
        }

        this.isNotSbOverlay = this.itemAdded;
      } else {
        this.itemAdded = false;
        this.isNotSbOverlay = this.itemAdded;
      }

      if (typeof this._sidebarParamsSubscription !== 'undefined') {
        this._sidebarParamsSubscription.unsubscribe();
      }
    });
  }

}
