import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';

/**
 * This class provides the
 * contact page functions.
 */
@Component({
  selector: 'mp-core-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService
  ) { }

  /**
   * Sets the title text and the menu
   * highlighting.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('Kontakt');
    //mpMenuHighlight.setMenuHighlight('support');
  }

}
