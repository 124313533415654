import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { Options, ChangeContext } from '@angular-slider/ngx-slider';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { TcFilter, TcFilterService } from '../../services/tc-filter.service';

/**
 * This class provides the functionalities
 * for the travel configurator rental filter.
 */
@Component({
  selector: 'mp-rk-rental-filter',
  templateUrl: './rental-filter.component.html',
  styleUrls: ['./../travel-configurator-filter/travel-configurator-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RentalFilterComponent implements OnInit, OnDestroy, TcFilter {

  @Input() public filter: { [key: string]: any } = {};
  @Input() public options: { [key: string]: any } = {};
  @Input() public step: number = -1;
  @Input() public filterId: string = '';

  @Output() filterChange = new EventEmitter<{ [key: string]: any }>();
  @Output() filterHasChanged = new EventEmitter<boolean>();

  public rangeSliderOptions: Options = {
    stepsArray: [
      {
        value: 0
      },
      {
        value: 1
      }
    ],
    ceil: 1,
    showSelectionBar: true,
    showTicks: true,
    hideLimitLabels: true,
    hidePointerLabels: true
  };
  public rangeSliderConfigDone: boolean = false;
  public defaultFilter: boolean = true;
  public orderableTravels: boolean = false;
  public showProvider: boolean = false;
  public filterPoints: number = 0;

  private _accountBalance: number = -1;
  private _getMenuDataSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _filterService: TcFilterService
  ) { }

  /**
   * Gets the account balance from the
   * menu data.
   */
  ngOnInit(): void {
    this._getMenuDataSubscription = this._apiService.getRequest('/api/Teilnehmer/getMenuData').subscribe((data: any) => {
      this._accountBalance = data.Records[0].Kontostand;
      this._filterService.updateRangeSliderOptions(this, this._accountBalance);
      this._changeOptions();
      this.filterHasChanged.emit(true);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getMenuDataSubscription !== 'undefined') {
      this._getMenuDataSubscription.unsubscribe();
    }
  }

  /**
   * Handles clicks on the options.
   */
  optionClicked(): void {
    this._changeOptions();
    this.filterHasChanged.emit(false);
  }

  /**
   * Handles clicks on the filters.
   */
  filterClicked(keepFilters: boolean, changeContext?: ChangeContext): void {
    if (typeof changeContext !== 'undefined') {
      this.filter['Punkte'] = changeContext.value;
    }

    this._changeFilters();
    this.filterHasChanged.emit(keepFilters);
  }

  /**
   * Triggers the change of options, and
   * the filtering of the results.
   */
  private _changeOptions(): void {
    this._setFilter();
    this._changeFilters();
  }

  /**
   * Triggers the change of filters, and
   * the filtering of the results.
   */
  private _changeFilters(): void {
    if (this.filter['Punkte'] !== this._accountBalance) {
      this.orderableTravels = false;
    }

    this._setDefaultFilter();
  }

  /**
   * Sets the default filter.
   */
  private _setDefaultFilter(): void {
    if (Object.keys(this.options).length === 0)
      return;

    let defaultFilter = (this.filter['Punkte'] === this.rangeSliderOptions.ceil);

    defaultFilter = defaultFilter && this._isNoneSelected(this.options['Kategorien']);
    defaultFilter = defaultFilter && this._isNoneSelected(this.options['Anbieter']);

    this.defaultFilter = defaultFilter;
  }

  /**
   * Sets all options of the given
   * array to not active.
   */
  selectNone(options: Array<any>, evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    options.forEach((option: any) => {
      option['Active'] = false;
    });

    if (typeof evt !== 'undefined') {
      this._changeOptions();
      this.filterHasChanged.emit(true);
    }
  }

  /**
   * Sets all options of the given
   * array to active.
   */
  selectAll(options: Array<any>, evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    options.forEach((option: any) => {
      option['Active'] = true;
    });

    if (typeof evt !== 'undefined') {
      this._changeOptions();
      this.filterHasChanged.emit(true);
    }
  }

  /**
   * Checks whether or not an option
   * is selectable
   */
  private _isNoneSelected(options: Array<any>): boolean {
    const foundActive = options.find((option: any) => {
      return option['Active'] === true;
    });

    return typeof foundActive === 'undefined' ? true : false;
  }

  /**
   * Filters the offers by orderable.
   */
  onlyOrderableTravels(): void {
    if (this.orderableTravels && typeof this.rangeSliderOptions.ceil !== 'undefined') {
      this.filter['Punkte'] = Math.min(this._accountBalance, this.rangeSliderOptions.ceil);
    } else if (this.filter['Punkte'] === this._accountBalance) {
      this.filter['Punkte'] = this.rangeSliderOptions.ceil;
    }

    this.filterPoints = this.filter['Punkte'];
    this._changeFilters();
    this.filterHasChanged.emit(false);
  }

  /**
   * Sets the filter.
   */
  private _setFilter(): void {
    if (Object.keys(this.options).length === 0)
      return;

    this._filterService.updateRangeSliderOptions(this, this._accountBalance);
    this.filter['Punkte'] = this.rangeSliderOptions.ceil;
    this.filterPoints = this.filter['Punkte'];

    this.filter['Kategorien'] = this.options['Kategorien'].filter((cat: any) => {
      return cat['Active'] === true;
    }).map((cat: any) => {
      return cat['CarGroup'];
      });

    this.filter['Anbieter'] = this.options['Anbieter'].filter((cat: any) => {
      return cat['Active'] === true;
    }).map((cat: any) => {
      return cat['Bezeichnung'];
    });

    this._setDefaultFilter();
  }

  /**
   * Resets the filter.
   */
  resetFilter(): void {
    if (Object.keys(this.options).length === 0)
      return;

    this._filterService.updateRangeSliderOptions(this, this._accountBalance);
    this.filter['Punkte'] = this.rangeSliderOptions.ceil;
    this.filterPoints = this.filter['Punkte'];

    this.selectNone(this.options['Kategorien']);
    this.selectNone(this.options['Anbieter']);

    this.orderableTravels = false;

    this._changeOptions();
    this.filterHasChanged.emit(true);
  }

}
