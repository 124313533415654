import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { ApiService } from './../../../../../services/api.service';
import { MpMessagingService } from './../../../../../services/mp-messaging.service';
import { FroalaConfigService } from './../../../../../services/froala-config.service';

/**
 * This class provides the functionalities for
 * the theme stage editor button element.
 */
@Component({
  selector: 'mp-core-theme-stage-editor-button',
  templateUrl: './theme-stage-editor-button.component.html',
  styleUrls: ['./theme-stage-editor-button.component.scss']
})
export class ThemeStageEditorButtonComponent implements OnInit, OnDestroy {

  @ViewChildren('fileUpload') fileUpload: any;

  @Input() public buttonTypes: Array<any> = [];
  @Input() public button: { [key: string]: any } = {};
  @Input() public tbsList: Array<any> = [];
  @Input() public content: { [key: string]: any } = {};
  @Input() public buttonIndex: number = 0;
  @Input() public errorLabel: any;

  @Output() contentChange = new EventEmitter<{ [key: string]: any }>();
  @Output() buttonChange = new EventEmitter<{ [key: string]: any }>();

  public froalaOptions: { [key: string]: any } = {};
  public froalaOptionsLoaded: boolean = false;
  public buttonType: { [key: string]: any } = {};
  public buttonTypeSet: boolean = false;
  public filteredButtonTypes: Array<any> = [];
  public file: File | undefined;
  public uploadSuccessfully: boolean = false;

  private _language: string = 'de';
  private _token: string | null = sessionStorage.getItem('token');
  private _froalaButton2: any;
  private _froalaEditor: any;
  private _getLanguageSubscription: Subscription | undefined;
  private _getFroalaOptionsSubscription: Subscription | undefined;
  private _fileUploadSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _froalaConfig: FroalaConfigService
  ) { }

  /**
   * Gets the language and triggers the
   * loading of the froala editor
   * options.
   */
  ngOnInit(): void {
    this._getLanguageSubscription = this._apiService.getRequest('/api/Language/getLanguage').subscribe((data: any) => {
      this._language = data.Records[0];
      this._getFroalaOptions();
      this._setFilteredButtonTypes();

      if (this.buttonTypes.length > 0) {
        this.buttonType = this.buttonTypes.find((buttonType: any) => {
          return buttonType['ButtonTypId'] === this.button['TypId'];
        });

        if (typeof this.buttonType !== 'undefined') {
          this.buttonTypeSet = true;
        } else {
          this.buttonTypeSet = false;
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLanguageSubscription !== 'undefined') {
      this._getLanguageSubscription.unsubscribe();
    }

    if (typeof this._getFroalaOptionsSubscription !== 'undefined') {
      this._getFroalaOptionsSubscription.unsubscribe();
    }

    if (typeof this._fileUploadSubscription !== 'undefined') {
      this._fileUploadSubscription.unsubscribe();
    }
  }

  /**
   * Watches for changes of content and
   * button, and triggers some functions to
   * handle those changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['content'] !== 'undefined') {
      if (typeof changes['content'].previousValue === 'undefined' || typeof changes['content'].previousValue !== 'undefined' && changes['content'].previousValue !== changes['content'].currentValue) {
        this._setFilteredButtonTypes();
      }
    }

    if (typeof changes['button'] !== 'undefined') {
      if (typeof changes['button'].previousValue === 'undefined' || typeof changes['button'].previousValue !== 'undefined' && changes['button'].previousValue.TypId !== changes['button'].currentValue.TypId) {
        if (this.buttonTypes.length > 0) {
          this.buttonType = this.buttonTypes.find((buttonType: any) => {
            return buttonType['ButtonTypId'] === this.button['TypId'];
          });

          if (typeof this.buttonType !== 'undefined') {
            this.buttonTypeSet = true;
          } else {
            this.buttonTypeSet = false;
          }
        }
      }
    }
  }

  /*
   * Gets the froala editor options from
   * the backend.
   */
  private _getFroalaOptions(): void {
    this._getFroalaOptionsSubscription = this._apiService.getRequest('/api/Froala/GetFroalaConfig?module=button2').subscribe((data: any) => {
      const froalaConfig = data.Records[0];
      const requestVerificationTokenInput: HTMLInputElement | null = document.querySelector('input[name="__RequestVerificationToken"]');
      const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

      this.froalaOptions = Object.assign(this._froalaConfig.getConfig(), {
        language: this._language,
        toolbarButtons: froalaConfig.ToolbarButtons,
        colorsBackground: froalaConfig.BackgroundColours,
        colorsStep: froalaConfig.ColourColumnCount,
        colorsText: froalaConfig.TextColours,
        charCounterMax: froalaConfig.CharacterCount,
        height: froalaConfig.Height,
        heightMin: froalaConfig.HeightMin,
        heightMax: froalaConfig.HeightMax,
        linkEditButtons: ['linkOpen', 'linkEdit'],
        requestHeaders: {
          'X-XSRF-TOKEN': xsrfToken || '',
          'Authorization': 'Bearer ' + this._token
        },
        events: Object.assign(this._froalaConfig.getEvents(), {
          'initialized': (editor: any) => {
            this._froalaEditor = editor;
          },
          'blur': (editor: any) => {
            const currentTarget = editor.currentTarget;

            if (currentTarget.innerHTML === '<p><br></p>' || currentTarget.innerText === '') {
              currentTarget.innerHTML = '';
            }
          }
        })
      });

      this.froalaOptionsLoaded = true;
      this._initFroala();
    });
  }

  /**
 * Retrieves a cookie value by name
 **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Initializes the froala editor.
   */
  private _initFroala(): void {
    if (this._froalaButton2 && Object.keys(this.froalaOptions).length > 0) {
      this._froalaButton2.destroy();
      this._froalaButton2.initialize(this.froalaOptions);
    }
  }

  /**
   * Gets the froala controls from the
   * froala init function.
   */
  getFroalaControlsButton2(froala: any): void {
    this._froalaButton2 = froala;
    this._froalaEditor = froala.getEditor();
    this._initFroala();
  }

  /**
   * Updates the button by the froala
   * editor.
   */
  changedButtonByFroale(evt: any): void {
    this.content['Text'] = evt;
    this.contentChange.emit(this.content);
  }

  /**
   * Sets the button types.
   */
  private _setFilteredButtonTypes(): void {
    this.filteredButtonTypes = this.buttonTypes.filter((buttonType: any) => {
      const noDetailText = buttonType.ButtonTypId !== 1;
      const reusable = !buttonType.NurEinmalVerwendbar;

      const isSelectedWithinThisButton = (this.buttonIndex === 1 && this.content['Button1'] && this.content['Button1'].TypId === buttonType.ButtonTypId)
        || (this.buttonIndex === 2 && this.content['Button2'] && this.content['Button2'].TypId === buttonType.ButtonTypId);

      const isNotSelectedWithinAnotherButton = (this.buttonIndex === 2 && !this.content['Button1'])
        || (this.buttonIndex === 1 && !this.content['Button2'])
        || (this.buttonIndex === 2 && this.content['Button1'].TypId !== buttonType.ButtonTypId)
        || (this.buttonIndex === 1 && this.content['Button2'].TypId !== buttonType.ButtonTypId);

      const returnValue = noDetailText && (reusable || isSelectedWithinThisButton || isNotSelectedWithinAnotherButton);

      return returnValue;
    });
  }

  /**
   * Handles the change of the theme
   * stage text-snippets.
   */
  tbsChange(text: { [key: string]: any }, index: number): void {
    if (text['selectedTBS'] && text['selectedTBS'].Value) {
      if (typeof this._froalaEditor !== 'undefined' && this._froalaEditor !== null) {
        // @ts-ignore
        this._froalaEditor._editor.html.insert(text['selectedTBS'].Value, true);
        text['selectedTBS'] = null;
      }
    }
  }

  /**
   * Handles the file upload.
   */
  uploadFiles(evt: any): void {
    if (this.buttonType['ButtonTypId'] !== 7)
      return;

    const files = evt.target.files;
    this.uploadSuccessfully = false;
    this.file = files[0];

    if (this.file) {
      this._fileUploadSubscription = this._apiService.postFileUploadRequest('/api/Upload/uploadBuehnenFile', this.file).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.uploadSuccessfully = true;
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DateiWurdeErfolgreichHochgeladen);
          this.button['LinkWert'] = data.Records[0];
        } else {
          this.uploadSuccessfully = false;
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this.uploadSuccessfully = false;
        this._mpMessaging.openWarningPanel(error.Message);
      });
    }
  }

  /**
   * Fetches changes of the button
   * object, and emits them to the
   * button change event.
   */
  buttonChangeEvent(key: string, value: string): void {
    this.button[key] = value;
    this.buttonChange.emit(this.button);
  }

  /**
   * Handles the change of the button
   * type id.
   */
  buttonTypeIdChanged(typeId: number): void {
    this.button['TypId'] = typeId;

    if (this.buttonTypes.length > 0) {
      this.buttonType = this.buttonTypes.find((buttonType: any) => {
        return buttonType['ButtonTypId'] === this.button['TypId'];
      });

      if (typeof this.buttonType !== 'undefined') {
        this.buttonTypeSet = true;
      } else {
        this.buttonTypeSet = false;
      }
    }
  }
}
