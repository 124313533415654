<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table title=""
                 *ngIf="loaderName !== ''"
                 fieldsAction="/api/Statistik/getVersteuerungsgrundlageChildFields"
                 listAction="/api/Statistik/getVersteuerungsgrundlageChild"
                 loaderName="{{loaderName}}"
                 [paging]="false"
                 [footer]="false"
                 [startEmpty]="true"
                 defaultSorting="BestellDatum DESC">
  </mp-core-data-table>
</div>
