import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/it.js';
import 'froala-editor/js/languages/pt_pt.js';
import 'froala-editor/js/languages/ru.js';

import { CommonInternComponentsLoaderModule } from './../common-intern-components-loader.module';

import { AdminComponent } from './admin.component';

import { HomeComponent } from './pages/home/home.component';
import { GitlogComponent } from './pages/gitlog/gitlog.component';
import { ImportCampaignOrdersComponent } from './pages/import-campaign-orders/import-campaign-orders.component';
import { InactiveParticipantsComponent } from './pages/inactive-participants/inactive-participants.component';
import { AccountStatisticComponent } from './pages/account-statistic/account-statistic.component';
import { ManagerManagementComponent } from './pages/manager-management/manager-management.component';
import { CreateParticipantComponent } from './pages/create-participant/create-participant.component';

@NgModule({
  declarations: [
    AdminComponent,
    HomeComponent,
    GitlogComponent,
    ImportCampaignOrdersComponent,
    InactiveParticipantsComponent,
    AccountStatisticComponent,
    ManagerManagementComponent,
    CreateParticipantComponent
  ],
  exports: [
    AdminComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonInternComponentsLoaderModule,
    CarouselModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ]
})
export class AdminModule { }
