import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Event, NavigationStart } from '@angular/router';

import { MpSidebarService } from './../../components/sidebar/mp-sidebar.service';
import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';
import { MpMetaService } from './../../services/mp-meta.service';
import { MpTitleService } from './../../services/mp-title.service';
import { ApiService } from './../../services/api.service';
import { MpMessagingService } from './../../services/mp-messaging.service';
import { MatomoService } from './../../services/matomo.service';

/**
 * This class provides data and
 * functions for the main manager
 * component.
 */
@Component({
  selector: 'mp-core-manager',
  templateUrl: './manager.component.html',
  styleUrls: [
    './manager.component.scss',
    './../../components/input/styles/input.scss',
    './../../components/sidebar/styles/sidebar.scss',
    './../../components/sidebar/styles/sidebar-extend.scss',
    './../../components/card/styles/card.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ManagerComponent implements OnInit, OnDestroy {

  public hamburgerOpen: boolean = false;
  public mainMenuItems: Array<any> = [];
  public userMenuItems: Array<any> = [];
  public userMenuOpen: boolean = false;
  public navbarIsSticky: boolean = false;

  private _managerData: { [key: string]: any } = {};
  private _apiManagerSubscription: Subscription | undefined;
  private _settingsSubscription: Subscription | undefined;
  private _routeChangeSubscription: Subscription | undefined;
  private _locsSubscription: Subscription | undefined;
  private _apiMenuSubscription: Subscription | undefined;

  constructor(
    public mpSidebar: MpSidebarService,
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpMetaService: MpMetaService,
    private _titleService: MpTitleService,
    private _apiService: ApiService,
    private _router: Router,
    private _mpMessaging: MpMessagingService,
    private _matomoService: MatomoService
  ) { }

  /**
   * Triggers the functions to set meta tags,
   * and some other 'head'-tags, gets menus.
   */
  ngOnInit(): void {
    this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== null) {
          this._titleService.setTitle(this.mpSettings.settings['NetcentiveCoreSettings'].Shopname);
        } else {
          this._titleService.setTitleFromLoc('Manager');
        }

        if (this.mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
          this._matomoService.setMatomo();
        }

        if (typeof this._settingsSubscription !== 'undefined') {
          this._settingsSubscription.unsubscribe();
        }
      }
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this.ls.getLocalization();
    }

    this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this._apiMenuSubscription = this._apiService.getRequest('/api/Menu/getMenu?context=Manager').subscribe((data: any) => {
          if (data.Result === "OK") {
            const mainMenuItemsRaw = data.Records[0];
            this.mainMenuItems = this._replaceUrlStructure(mainMenuItemsRaw);
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
          }
        },
        (error: any) => {
          this._mpMessaging.openWarningPanel(error.Message);
        });

        if (typeof this._locsSubscription !== 'undefined') {
          this._locsSubscription.unsubscribe();
        }
      }
    });

    this._apiManagerSubscription = this._apiService.postRequest('/api/Teilnehmer/getTN', {}).subscribe((data: any) => {
      this._managerData = data.Records[0];
      this._setUserMenuItems();
    },
    (error: any) => {
      console.log(error.message);
    });

    this._mpMetaService.setBasicTags();
    this._mpMetaService.setFavicon();
    this._mpMetaService.setThemeColorTag();
    this._mpMetaService.setLinkTag({
      href: '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css',
      rel: 'stylesheet',
      type: 'text/css'
    });

    this._routeChangeSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.hamburgerOpen = false;
        this.navbarIsSticky = false;
      }
    });

    window.addEventListener('scroll', this._handleNav.bind(this), false);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._settingsSubscription !== 'undefined') {
      this._settingsSubscription.unsubscribe();
    }

    if (typeof this._apiManagerSubscription !== 'undefined') {
      this._apiManagerSubscription.unsubscribe();
    }

    if (typeof this._routeChangeSubscription !== 'undefined') {
      this._routeChangeSubscription.unsubscribe();
    }

    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }

    if (typeof this._apiMenuSubscription !== 'undefined') {
      this._apiMenuSubscription.unsubscribe();
    }
  }

  /**
   * Replaces the old (AnuglarJS) url structure
   * by the new (Angular) url structure.
   */
  private _replaceUrlStructure(mainMenuItemsRaw: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
    mainMenuItemsRaw.forEach((menuItem: { [key: string]: any }) => {
      if (typeof menuItem['href'] !== 'undefined' && menuItem['href'] !== '' && menuItem['href'] !== null) {
        menuItem['href'] = menuItem['href'].replace('#/', '#/manager/');
      }

      if (typeof menuItem['children'] !== 'undefined' && menuItem['children'].length > 0) {
        menuItem = this._replaceUrlStructure(menuItem['children']);
      }
    });

    return mainMenuItemsRaw;
  }

  /**
   * Opens the hambuerger menu.
   */
  openHamburger(): void {
    this.hamburgerOpen = true;
  }

  /**
   * Closes the hamburger and user menu.
   */
  closeHamburger(): void {
    this.hamburgerOpen = false;
    this.userMenuOpen = false;
  }

  /**
   * Sets the data for the user menu.
   */
  private _setUserMenuItems(): void {
    if (Object.keys(this._managerData).length > 0) {
      this.userMenuItems = [
        {
          text:(this._managerData['Vorname'] || this._managerData['Name'])
            ? `${this._managerData['Vorname']} ${this._managerData['Name']}`
            : this._managerData['Benutzer'],
          hasLogout: true,
          children: []
        }
      ];
    }
  }

  /**
   * Handles the stick / visiblity behavior of the
   * navbar, when the user scrolls the page up / down.
   */
  private _handleNav(): void {
    const scrollTop = window.scrollY;
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');
    const headerHeight = header !== null ? header.getBoundingClientRect().height : 0;

    if (headerHeight < scrollTop && navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv === null) {
        const divElem = document.createElement('div');
        divElem.style.height = navbar.getBoundingClientRect().height + 'px';
        divElem.id = 'sticky-ghost-div';
        navbar.insertAdjacentElement('afterend', divElem);
      }

      if (!navbar.classList.contains('fix')) {
        navbar.classList.add('fix');
      }

      if (!navbar.classList.contains('hl-sticky')) {
        navbar.classList.add('hl-sticky');
      }

      this.navbarIsSticky = true;
    } else if (navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv !== null) {
        ghostDiv.remove();
      }

      if (navbar.classList.contains('fix')) {
        navbar.classList.remove('fix');
      }

      if (navbar.classList.contains('hl-sticky')) {
        navbar.classList.remove('hl-sticky');
      }

      this.navbarIsSticky = false;
    }

    const filterNavBaseline = document.querySelector('.filter-nav-baseline');
    const filterNav = document.querySelector('.filter-nav');

    if (filterNav !== null) {
      if (filterNav.classList.contains('no-header')) {
        if (headerHeight < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      } else {
        const mainNav = document.querySelector('.mainNav');
        const mainNavHeight = mainNav !== null ? mainNav.getBoundingClientRect().height : 0;

        if (filterNavBaseline !== null && filterNavBaseline.getBoundingClientRect().top - mainNavHeight - filterNav.getBoundingClientRect().height < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      }
    }
  }

}
