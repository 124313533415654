<div *ngIf="(ls.locsLoaded | async)">
  <div class="showbox" [hidden]="!loading">
    <div class="loader">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
  <div class="details-header header padding-top-16 floating-container padding-bottom-0">
    <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Close }}" class="float-right">
      <a class="mp-button display-flex-important flex-align-items-center flex-justify-content-center" (click)="onClick($event, item)">
        <i class="svg-icon __clr-default __size-18 __clear"></i>
      </a>
    </mp-core-tooltip>
    <div class="title display-block padding-0 font-size-m font-weight-400 float-left text-overflow-ellipsis max-width-full"><span>{{item.Betreff}}</span></div>
  </div>
  <div class="animate-show" [ngClass]="{open: !loading}">
    <div class="rich-media text-align-center">
      <img class="max-width-full z-index-1 width-full" *ngIf="item.SubItems && item.SubItems[0] && item.SubItems[0].Image" [src]="item.SubItems[0].Image+'?h=427&w=1280&mode=crop'" alt="{{item.Title}}" />
    </div>
    <div class="header padding-top-bottom-16 floating-container border-top">
      <div class="row-inner">
        <div class="grid col-small-6">
          <div class="inbox-item-title">
            <i class="svg-icon __clr-primary __size-18 margin-right-16" [ngClass]="item.Type"></i>
            <div class="icon-text text-overflow-ellipsis">
              <span *ngIf="item.Titel" [innerText]="item.Titel"></span>
              <span *ngIf="item.Titel">&nbsp;</span>
              <span *ngIf="item.Betreff" [innerText]="item.Betreff"></span>
            </div>
          </div>
        </div>
        <div class="grid col-small-6">
          <div>{{item.ChangeDate}}</div>
          <div class="subtitle float-right display-block font-size-general">
            <span>{{item.Datum}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="supporting-text" [innerHTML]="item.Text"></div>
  </div>
</div>
