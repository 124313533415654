<div class="tap-wrapper" *ngIf="(ls.locsLoaded | async)">>
  <div class="flex-grid margin-0">
    <div class="flex-col-12 text-transform-uppercase padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 pointer-hover active bg-color-brand-primary">{{ ls.locs['loc'].Details }}</div>
  </div>
  <div class="rk-tab-content bg-color-white">
    <div class="rk-flug-details">
      <div class="rk-flight-details">
        <div class="flex-grid margin-bottom-16 flight-steps-headline">
          <span class="flex-col-2 flex-col-2-s flex-col-4-xs font-weight-500 text-color-info">
            {{ ls.locs['locReisekonfigurator'].Hinflug}}:
          </span>
          <span class="flex-col-8 flex-col-7-s flex-col-8-xs font-weight-500 text-color-info">
            <i class="svg-icon __clr-default __size-22 __top--2 __calendar margin-right-8 default-cursor-hover"></i>
            {{flight['SelectedHinflug'].AbflugdatumDetailsDisplay}}
          </span>
          <span class="show-on-small-mobile flex-col-4-xs"></span>
          <span class="flex-col-2 flex-col-3-s flex-col-8-xs flex-justify-cont-end font-weight-500 flight-duration text-color-info">
            <i class="svg-icon __clr-default __size-22 __top--2 __time-rk margin-right-8 default-cursor-hover"></i>
            {{flight['SelectedHinflug'].FlugdauerDisplay}}
          </span>
        </div>
        <div class="flex-grid flight-steps">
          <div *ngFor="let section of flight['SelectedHinflug'].Abschnitte; trackBy: trackByIndex; let last = last; let first = first" class="flex-grid">
            <div class="flex-col-12 margin-bottom-16">
              <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                <span class="font-weight-500 text-color-info" [innerText]="section.AbfluguhrzeitDisplay"></span>
                <span class="font-size-xs text-color-grey-info" [innerText]="section.AbflugdatumDisplay"></span>
              </div>
              <div class="position-relative flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="first ? 'flight-point-filled' : 'flight-point-circle'">
                <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="first ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
              </div>
              <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                <span class="flex-col-12 padding-left-right-0" [innerText]="section.Abflughafen.Stadt+' ('+section.Abflughafen.Kuerzel+')'"></span>
                <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="section.Abflughafen.Bezeichnung"></span>
                <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300">{{section.Airline.Name}} ({{section.Airline.IATACode}} {{section.Flugnummer}}{{section.Airline.AdditionalInfo && ', '+section.Airline.AdditionalInfo}}), {{flight['SelectedHinflug'].Klasse}}{{section.Flugzeug ? ', '+section.Flugzeug.Name : ''}}</span>
                <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="section.FlugdauerDisplay"></span>
              </div>
              <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                <span [innerText]="section.FlugdauerDisplay"></span>
              </div>
            </div>
            <div class="flex-col-12 margin-bottom-16">
              <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                <span class="font-weight-500 text-color-info" [innerText]="section.AnkunftuhrzeitDisplay"></span>
                <span class="font-size-xs text-color-grey-info" [innerText]="section.AnkunftdatumDisplay"></span>
              </div>
              <div class="position-relative flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="last ? 'flight-point-filled' : 'flight-point-circle'">
                <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="last ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
              </div>
              <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                <span class="flex-col-12 padding-left-right-0" [innerText]="section.Ankunftflughafen.Stadt+' ('+section.Ankunftflughafen.Kuerzel+')'"></span>
                <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="section.Ankunftflughafen.Bezeichnung"></span>
                <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300" [hidden]="last">
                  {{ ls.locs['locReisekonfigurator'].UmstiegIn | format:[section.Ankunftflughafen.Stadt] }}
                  <span *ngIf="section.Nachtaufenthalt" class="text-color-brand-primary">&nbsp;{{ ls.locs['locReisekonfigurator'].MitNachtaufenthalt }}</span>
                </span>
                <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="section.AufenthaltsdauerDisplay"></span>
              </div>
              <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                <span [innerText]="section.AufenthaltsdauerDisplay"></span>
              </div>
            </div>
          </div>
        </div>
        <hr class="margin-bottom-0" />
        <div class="flex-grid flight-marker">
          <div class="flex-col-1 flex-col-2-s flex-col-4-xs"></div>
          <div class="flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center pin-icon">
            <i class="svg-icon __clr-info __size-32 __top-0 __place margin-top-4 margin-bottom-4 default-cursor-hover"></i>
          </div>
          <div class="flex-col-10 flex-col-9-s flex-col-7-xs flex-align-items-center">{{ ls.locs['locReisekonfigurator'].XInY | format:[(ls.locs['locReisekonfigurator'].NNaechte | format:[flight['Dauer']]),flight['SelectedHinflug'].Ankunftflughafen.Stadt] }}</div>
        </div>
        <hr class="margin-top-0" [ngClass]="flight['SelectedRueckflug'] && flight['SelectedRueckflug'].Abschnitte.length > 0 ? 'margin-bottom-32' : '' " />
        <div class="flex-grid margin-bottom-16 flight-steps-headline" *ngIf="flight['SelectedRueckflug'] && flight['SelectedRueckflug'].Abschnitte.length > 0">
          <span class="flex-col-2 flex-col-2-s flex-col-4-xs font-weight-500 text-color-info">{{ ls.locs['locReisekonfigurator'].Rueckflug }}</span>
          <span class="flex-col-8 flex-col-7-s flex-col-8-xs font-weight-500 text-color-info">
            <i class="svg-icon __clr-default __size-22 __top--2 __calendar margin-right-8 default-cursor-hover"></i>
            {{flight['SelectedRueckflug'].AbflugdatumDetailsDisplay}}
          </span>
          <span class="show-on-small-mobile flex-col-4-xs"></span>
          <span class="flex-col-2 flex-col-3-s flex-col-8-xs flex-justify-cont-end font-weight-500 flight-duration text-color-info">
            <i class="svg-icon __clr-default __size-22 __top--2 __time-rk margin-right-8 default-cursor-hover"></i>
            {{flight['SelectedRueckflug].FlugdauerDisplay}}
          </span>
        </div>
        <div class="flex-grid flight-steps" *ngIf="flight['SelectedRueckflug'] && flight['SelectedRueckflug'].Abschnitte.length > 0">
          <div *ngFor="let section of flight['SelectedRueckflug'].Abschnitte; trackBy: trackByIndex; let last = last; let first = first" class="flex-grid">
            <div class="flex-col-12 margin-bottom-16">
              <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                <span class="font-weight-500 text-color-info" [innerText]="section.AbfluguhrzeitDisplay"></span>
                <span class="font-size-xs text-color-grey-info" [innerText]="section.AbflugdatumDisplay"></span>
              </div>
              <div class="position-relative flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="first ? 'flight-point-filled' : 'flight-point-circle'">
                <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="first ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
              </div>
              <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                <span class="flex-col-12 padding-left-right-0" [innerText]="section.Abflughafen.Stadt+' ('+section.Abflughafen.Kuerzel+')'"></span>
                <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="section.Abflughafen.Bezeichnung"></span>
                <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300 padding-left-0">{{section.Airline.Name}} ({{section.Airline.IATACode}} {{section.Flugnummer}}{{section.Airline.AdditionalInfo && ', '+section.Airline.AdditionalInfo}}), {{flight['SelectedRueckflug'].Klasse}}</span>
                <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="section.FlugdauerDisplay"></span>
              </div>
              <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                <span [innerText]="section.FlugdauerDisplay"></span>
              </div>
            </div>
            <div class="flex-col-12 margin-bottom-16">
              <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                <span class="font-weight-500 text-color-info" [innerText]="section.AnkunftuhrzeitDisplay"></span>
                <span class="font-size-xs text-color-grey-info" [innerText]="section.AnkunftdatumDisplay"></span>
              </div>
              <div class="position-relative flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="last ? 'flight-point-filled' : 'flight-point-circle'">
                <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="last ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
              </div>
              <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                <span class="flex-col-12 padding-left-right-0" [innerText]="section.Ankunftflughafen.Stadt+' ('+section.Ankunftflughafen.Kuerzel+')'"></span>
                <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="section.Ankunftflughafen.Bezeichnung"></span>
                <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300" [hidden]="last">
                  {{ ls.locs['locReisekonfigurator'].UmstiegIn | format:[section.Ankunftflughafen.Stadt] }}
                  <span *ngIf="section.Nachtaufenthalt">&nbsp;{{ ls.locs['locReisekonfigurator'].MitNachtaufenthalt }}</span>
                </span>
                <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="section.AufenthaltsdauerDisplay"></span>
              </div>
              <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                <span [innerText]="section.AufenthaltsdauerDisplay"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
