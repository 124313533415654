import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mp-gfc-root',
  templateUrl: './gfc.component.html',
  styleUrls: [
    './gfc.component.scss',
    './modules/style/gamification-main.scss',
    './modules/style/fruehjahrsaktion-autohaus/fruehjahrsaktion-autohaus.scss',
    './modules/style/racing/racing.scss',
    './modules/style/winterspiele2021/winterspiele.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class GfcComponent { }
