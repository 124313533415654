import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';

/**
 * This class provides the data
 * and functions for the adm
 * home page.
 */
@Component({
  selector: 'mp-leas-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {

  public text: SafeHtml = '';
  public title: SafeHtml = '';

  private _getTextAndTitleSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public sanitizer: DomSanitizer,
    private _apiService: ApiService,
  ) { }

  /**
   * Gets the home text and title.
   */
  ngOnInit(): void {
    this._getTextAndTitleSubscription = this._apiService.getRequest('/api/Texte/getTextAndTitle', false, { params: { key: 'adm-home-text' } }).subscribe((data: any) => {
      this.text = this._trustAsHtml(data.Records[0].PreparedText);
      this.title = this._trustAsHtml(data.Records[0].Title);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTextAndTitleSubscription !== 'undefined') {
      this._getTextAndTitleSubscription.unsubscribe();
    }
  }

  /*
   * Sanitizer for HTML.
   */
  private _trustAsHtml(str: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }

}
