import { LOCALE_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';

import { CoreModule } from '@core/core.module';
import { RkModule } from '@rk/rk.module';
import { RkzzModule } from '@rkzz/rkzz.module';
import { ZzModule } from '@zz/zz.module';
import { WkzzModule } from '@wkzz/wkzz.module';
import { GfcModule } from '@gfc/gfc.module';
import { GkModule } from '@gk/gk.module';
import { TawModule } from '@taw/taw.module';

import { AdmModule } from './modules/adm/adm.module';
import { CharityModule } from './modules/charity/charity.module';

import { LeasRoutingModule } from './leas-routing.module';
import { LeasComponent } from './leas.component';

import { LanguageService } from '@core/services/language.service';
import { ComplaintManagementComponent } from './modules/participant/pages/complaint-management/complaint-management.component';
import { CharityCurrentlyInfosComponent } from './modules/participant/pages/charity-currently-infos/charity-currently-infos.component';
import { CharityDescriptionComponent } from './modules/participant/pages/charity-description/charity-description.component';
import { CharityDonateComponent } from './modules/participant/pages/charity-donate/charity-donate.component';
import { RedeemVoucherComponent } from './modules/participant/pages/redeem-voucher/redeem-voucher.component';
import { CampaignsHistoryComponent } from './modules/manager/pages/campaigns-history/campaigns-history.component';
import { CampaignsHistoryDetailsComponent } from './modules/manager/pages/campaigns-history-details/campaigns-history-details.component';
import { CharityStatisticComponent } from './modules/manager/pages/charity-statistic/charity-statistic.component';
import { CompaniesRankingComponent } from './modules/manager/pages/companies-ranking/companies-ranking.component';
import { VoucherStatisticComponent } from './modules/manager/pages/voucher-statistic/voucher-statistic.component';
import { AppreciationOverviewComponent } from './modules/manager/pages/appreciation-overview/appreciation-overview.component';
import { ProductStatisticComponent } from './modules/manager/pages/product-statistic/product-statistic.component';
import { PointsSharingOverviewComponent } from './modules/manager/pages/points-sharing-overview/points-sharing-overview.component';
import { PointsDecayComponent } from './modules/manager/pages/points-decay/points-decay.component';
import { PointsDecayReportingComponent } from './modules/manager/pages/points-decay-reporting/points-decay-reporting.component';
import { QuickboardingStatisticComponent } from './modules/manager/pages/quickboarding-statistic/quickboarding-statistic.component';
import { SpecialArrangementAgencyAgreementComponent } from './modules/manager/pages/special-arrangement-agency-agreement/special-arrangement-agency-agreement.component';
import { ParticipantEvaluationComponent } from './modules/manager/pages/participant-evaluation/participant-evaluation.component';
import { ParticipantSurveyStatisticComponent } from './modules/manager/pages/participant-survey-statistic/participant-survey-statistic.component';
import { TestuserManagementComponent } from './modules/manager/pages/testuser-management/testuser-management.component';
import { PrtcpntAdvertisePrtcpntStatisticComponent } from './modules/manager/pages/prtcpnt-advertise-prtcpnt-statistic/prtcpnt-advertise-prtcpnt-statistic.component';
import { ExpactedPointsReleaseComponent } from './modules/manager/pages/expacted-points-release/expacted-points-release.component';
import { ImportCampaignOrdersLeasComponent } from './modules/admin/pages/import-campaign-orders-leas/import-campaign-orders-leas.component';
import { CreditProposalComponent } from './modules/admin/pages/credit-proposal/credit-proposal.component';
import { SchoolingStartLeasComponent } from './modules/participant/pages/schooling-start-leas/schooling-start-leas.component';
import { SchoolingPageLeasComponent } from './modules/participant/pages/schooling-page-leas/schooling-page-leas.component';
import { SchoolingStatisticLeasComponent } from './modules/manager/pages/schooling-statistic-leas/schooling-statistic-leas.component';

registerLocaleData(localeDE, 'de_DE');

@NgModule({
  declarations: [
    LeasComponent,
    ComplaintManagementComponent,
    CharityCurrentlyInfosComponent,
    CharityDescriptionComponent,
    CharityDonateComponent,
    RedeemVoucherComponent,
    CampaignsHistoryComponent,
    CampaignsHistoryDetailsComponent,
    CharityStatisticComponent,
    CompaniesRankingComponent,
    VoucherStatisticComponent,
    AppreciationOverviewComponent,
    ProductStatisticComponent,
    PointsSharingOverviewComponent,
    PointsDecayComponent,
    PointsDecayReportingComponent,
    QuickboardingStatisticComponent,
    SpecialArrangementAgencyAgreementComponent,
    ParticipantEvaluationComponent,
    ParticipantSurveyStatisticComponent,
    TestuserManagementComponent,
    PrtcpntAdvertisePrtcpntStatisticComponent,
    ExpactedPointsReleaseComponent,
    ImportCampaignOrdersLeasComponent,
    CreditProposalComponent,
    SchoolingStartLeasComponent,
    SchoolingPageLeasComponent,
    SchoolingStatisticLeasComponent,
    SchoolingStatisticLeasComponent
  ],
  imports: [
    BrowserModule,
    CarouselModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    CommonInternComponentsLoaderModule,
    CoreModule,
    RkModule,
    ZzModule,
    WkzzModule,
    RkzzModule,
    GfcModule,
    GkModule,
    TawModule,
    AdmModule,
    CharityModule,
    LeasRoutingModule
  ],
  providers: [
    LanguageService,
    {
      provide: APP_INITIALIZER,
      deps: [
        LanguageService
      ],
      useFactory: (languageService: LanguageService) => languageService.setLocale(),
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [
        LanguageService
      ],
      useFactory: (languageService: LanguageService) => languageService.getLocale()
    }
  ],
  bootstrap: [
    LeasComponent
  ]
})
export class LeasModule { }
