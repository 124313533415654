import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './mp-localization.service';
import { MpSettingsService } from './mp-settings.service';

/**
 * This class provides functions for
 * setting the docs title.
 */
@Injectable({
  providedIn: 'root'
})
export class MpTitleService {

  private _locsSubscription: Subscription | undefined;
  private _settingsSubscription: Subscription | undefined;

  constructor(
    private _ls: MpLocalizationService,
    private _titleService: Title,
    private _mpSettings: MpSettingsService
  ) { }

  /**
   * Sets the title by given string.
   */
  setTitle(title: string): void {
    if (Object.keys(this._mpSettings.settings).length > 0) {
      if (typeof this._settingsSubscription !== 'undefined') {
        this._settingsSubscription.unsubscribe();
      }

      title = this._mpSettings.settings['NetcentiveCoreSettings'].Shopname + (title !== '' ? ' - ' + title : '');
      this._titleService.setTitle(title);
    } else {
      this._settingsSubscription = this._mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          title = this._mpSettings.settings['NetcentiveCoreSettings'].Shopname + (title !== '' ? ' - ' + title : '');
          this._titleService.setTitle(title);

          if (typeof this._settingsSubscription !== 'undefined') {
            this._settingsSubscription.unsubscribe();
          }
        }
      });

      this._mpSettings.getSettings();
    }
  }

  /**
   * Gets the title string from the
   * localizations.
   */
  private _getTitleFromLoc(key: string, locName?: string): string {
    let loc = '';

    if (typeof locName !== 'undefined') {
      if (typeof this._ls.locs[locName][key] !== 'undefined') {
        loc = this._ls.locs[locName][key];
      }
    } else {
      if (typeof this._ls.locs['loc'][key] !== 'undefined') {
        loc = this._ls.locs['loc'][key];
      }
    }

    return loc;
  }

  /**
   * Sets the title by given key, and
   * (optional) locName, so the title string
   * can be fetched from localizations.
   */
  setTitleFromLoc(key: string, locName?: string): void {
    if (Object.keys(this._ls.locs).length > 0) {
      if (typeof locName === 'undefined') {
        this.setTitle(this._getTitleFromLoc(key));
      } else {
        this.setTitle(this._getTitleFromLoc(key, locName));
      }
    } else {
      this._ls.getLocalization();

      this._locsSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof locName === 'undefined') {
            this.setTitle(this._getTitleFromLoc(key));
          } else {
            this.setTitle(this._getTitleFromLoc(key, locName));
          }

          if (typeof this._locsSubscription !== 'undefined') {
            this._locsSubscription.unsubscribe();
          }
        }
      });
    }
  }

  /**
   * Sets the title with given string, and
   * appends string fetched from localizations
   * to it.
   */
  setTitleFromStringAndLoc(string: string, key: string, locName?: string): void {
    if (Object.keys(this._ls.locs).length > 0) {
      if (typeof locName === 'undefined') {
        this.setTitle(`${string} - ${this._getTitleFromLoc(key)}`);
      } else {
        this.setTitle(`${string} - ${this._getTitleFromLoc(key, locName)}`);
      }
    } else {
      this._ls.getLocalization();

      this._locsSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof locName === 'undefined') {
            this.setTitle(`${string} - ${this._getTitleFromLoc(key)}`);
          } else {
            this.setTitle(`${string} - ${this._getTitleFromLoc(key, locName)}`);
          }

          if (typeof this._locsSubscription !== 'undefined') {
            this._locsSubscription.unsubscribe();
          }
        }
      });
    }
  }

  /**
   * Returns the currently set title.
   */
  getTitle(): string {
    return this._titleService.getTitle();
  }
}
