import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { AdminComponent } from '@core/modules/admin/admin.component';
import { TravelConfiguratorComponent } from './modules/participant/pages/travel-configurator/travel-configurator.component';
import { HotelChoiceComponent } from './modules/participant/pages/hotel-choice/hotel-choice.component';
import { HotelCheckComponent } from './modules/participant/pages/hotel-check/hotel-check.component';
import { HotelConfirmationComponent } from './modules/participant/pages/hotel-confirmation/hotel-confirmation.component';
import { FlightChoiceComponent } from './modules/participant/pages/flight-choice/flight-choice.component';
import { FlightCheckComponent } from './modules/participant/pages/flight-check/flight-check.component';
import { FlightConfirmationComponent } from './modules/participant/pages/flight-confirmation/flight-confirmation.component';
import { RentalChoiceComponent } from './modules/participant/pages/rental-choice/rental-choice.component';
import { RentalCheckComponent } from './modules/participant/pages/rental-check/rental-check.component';
import { RentalConfirmationComponent } from './modules/participant/pages/rental-confirmation/rental-confirmation.component';
import { TravelActivitiesEditorComponent } from './modules/admin/pages/travel-activities-editor/travel-activities-editor.component';
import { TravelActivitiesOverviewComponent } from './modules/admin/pages/travel-activities-overview/travel-activities-overview.component';
import { TravelogueComponent } from './modules/participant/pages/travelogue/travelogue.component';
import { TraveloguesComponent } from './modules/participant/pages/travelogues/travelogues.component';
import { TravelActivityRedirectComponent } from './modules/participant/pages/travel-activity-redirect/travel-activity-redirect.component';

import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'FlugAuswahl',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: FlightChoiceComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'FlugBestaetigung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: FlightConfirmationComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'FlugPruefung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: FlightCheckComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'HotelBestaetigung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: HotelConfirmationComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'HotelPruefung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: HotelCheckComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'HotelAuswahl',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: HotelChoiceComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'MietwagenAuswahl',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RentalChoiceComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'MietwagenBestaetigung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RentalConfirmationComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'MietwagenPruefung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RentalCheckComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'ReiseaktionRedirect',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: TravelActivityRedirectComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'Reisebericht',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: TravelogueComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'Reiseberichte',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: TraveloguesComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'Reisekonfigurator',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: TravelConfiguratorComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'ReiseaktionenEditor',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: TravelActivitiesEditorComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }
        ]
      },
      {
        path: 'ReiseaktionenUebersicht',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: TravelActivitiesOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class RkRoutingModule { }
