import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';

import { WkzzRoutingModule } from './wkzz-routing.module';
import { WkzzComponent } from './wkzz.component';
import { SbAdditionalPaymentPayplaceComponent } from './components/sb-additional-payment-payplace/sb-additional-payment-payplace.component';
import { SbAdditionalPaymentPayplaceCreditcardComponent } from './components/sb-additional-payment-payplace/sb-additional-payment-payplace-creditcard/sb-additional-payment-payplace-creditcard.component';
import { SbAdditionalPaymentPayplaceDebitcardComponent } from './components/sb-additional-payment-payplace/sb-additional-payment-payplace-debitcard/sb-additional-payment-payplace-debitcard.component';
import { SbAdditionalPaymentPayplacePaypalComponent } from './components/sb-additional-payment-payplace/sb-additional-payment-payplace-paypal/sb-additional-payment-payplace-paypal.component';
import { PaymentComponent } from './modules/participant/pages/payment/payment.component';
import { SbAdditionalPaymentSliderDirective } from './directives/sb-additional-payment-slider.directive';
import { PaymentFailedComponent } from './modules/participant/pages/payment-failed/payment-failed.component';

@NgModule({
  declarations: [
    WkzzComponent,
    SbAdditionalPaymentPayplaceComponent,
    SbAdditionalPaymentPayplaceCreditcardComponent,
    SbAdditionalPaymentPayplaceDebitcardComponent,
    SbAdditionalPaymentPayplacePaypalComponent,
    PaymentComponent,
    SbAdditionalPaymentSliderDirective,
    PaymentFailedComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    WkzzRoutingModule,
    NgxSliderModule
  ],
  exports: [
    WkzzComponent
  ],
  providers: [],
  bootstrap: [
    WkzzComponent
  ]
})
export class WkzzModule { }

@NgModule({})
export class WkzzSharedModule {
  static forRoot(): ModuleWithProviders<WkzzModule> {
    return {
      ngModule: WkzzModule,
      providers: []
    }
  }
}
