<div class="layout-container" *ngIf="(ls.locsLoaded | async)" id="communication-management">
  <div id="nav-breadcrumb" class="display-flex flex-align-items-center margin-bottom-8 margin-left-4">
    <div id="breadcrumb">
      <a class="mp-button text-color-grey-info" (click)="goBack($event)">{{ ls.locs['loc'].Zurueck }}</a>
      <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
      <a class="mp-button text-color-grey-info font-weight-regular" href="">{{ ls.locs['loc'].Benachrichtigungen }}</a>
      <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
      <a class="mp-button text-color-grey-info font-weight-regular" href="">{{ ls.locs['loc'].KommunikationsVerwaltung }}</a>
</div>
  </div>
  <mp-core-infotext [sitename]="'kommunikationsverwaltung'"></mp-core-infotext>
  <div class="display-flex flex-direction-row flex-wrap flex-justify-cont-end flex-align-items-end">
    <a class="mp-button _prim" href="#/{{role}}/TeilnehmerAuswahl?id=0" style="margin-bottom: -10px" [innerHTML]="ls.locs['loc'].KommunikationAnlegen">
    </a>
  </div>
  <h1 class="mobile-only">{{ ls.locs['loc'].KommunikationsVerwaltung }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].KommunikationsVerwaltung }}"
                 infotextShorten="teilnehmerverwaltung"
                 fieldsAction="/api/KommunikationProzess/getKommunikationsverwaltungFields"
                 listAction="/api/KommunikationProzess/getKommunikationsverwaltung"
                 loaderName="communicationManagementLoader"
                 [paging]="true"
                 [disableCache]="true"
                 defaultSorting="AnlageDatum DESC"
                 [recLoadedFunc]="recLoadedFunc"
                 [execClickFunctions]="execClickFunctions">
  </mp-core-data-table>
</div>
