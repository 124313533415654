<div id="shopping-basket" class="layout-container order-process" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div *ngIf="shoppingBasketLoaded && sBCount > 0">
    <div id="wk-headlines" class="margin-bottom-40 padding-bottom-20">
      <h1 class="display-inline-block margin-bottom-0">{{ ls.locs['loc'].MeinWarenkorb }}</h1>
      <h2 class="display-inline-block margin-bottom-0">&nbsp;({{sBCount | integer}} {{sBCount != 1 ? ls.locs['loc'].Praemien : ls.locs['loc'].Praemie}})</h2>
    </div>
    <div class="bestellung-nav-row wk-top-buttons floating-container">
      <a href="#/{{role}}/Shop/0" class="mp-button _grey">{{ ls.locs['loc'].WeiterEinkaufen }}</a>
      <a class="mp-button _prim float-right" *ngIf="sBCount" (click)="validateBasket($event)" [ngClass]="{disabled: sbInvalid}">{{ ls.locs['loc'].WarenkorbWeiter }}</a>
    </div>
    <div>
      <div *ngFor="let wkItems of groupedBasket" class="margin-top-24 wk-group">
        <div [innerText]="wkItems[0].Versandzeit" class="shipping-status margin-bottom-16 padding-top-bottom-8 padding-left-right-16 display-flex flex-align-items-center flex-justify-cont-center"></div>
        <div *ngFor="let item of wkItems" class="card-flat wk-card margin-top-8 padding-0 display-flex flex-grid flex-direction-column margin-right-8 margin-left-8" [ngClass]="{'warning': item.ShownValidationMessages && item.ShownValidationMessages.length > 0, 'danger': item.HasBlockingValidationMessages}">
          <div class="floating-container wk-item flex-grid">
            <div class="flex-col-1 flex-col-3-s flex-col-3-xs flex-justify-cont-center flex-align-items-center wk-item-img-container">

              <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}" [hidden]="!item.ShowDetailSeite">
                <img class="wk-item-img" src="{{item.Bild}}?width=300" alt="{{item.ArtNr+'-'+item.PIN}}" />
              </a>

              <img class="wk-item-img" src="{{item.Bild}}?width=300" alt="{{item.ArtNr+'-'+item.PIN}}" [hidden]="item.ShowDetailSeite" />

            </div>
            <div class="flex-col-3 flex-col-9-s flex-col-9-xs flex-justify-cont-start flex-align-items-center wk-item-description-container">

              <a href="#/{{role}}/PraemienDetails?art={{item.ArtNr}}&pin={{item.PIN}}" [hidden]="!item.ShowDetailSeite">
                <span class="wk-company">{{item.Hersteller}}</span>
                <strong class="wk-item-label overflow-hidden display-inline-block">{{item.ArtBez}} {{item.ArtBez2}}</strong>
              </a>

              <div [hidden]="item.ShowDetailSeite">
                <span class="wk-company">{{item.Hersteller}}</span>
                <strong class="wk-item-label overflow-hidden display-inline-block">{{item.ArtBez}} {{item.ArtBez2}}</strong>
              </div>

            </div>
            <div class="flex-col-1 flex-col-2-s flex-justify-cont-center flex-align-items-start flex-direction-column wk-icon-buttons">
              <div *ngIf="mpSettings.settings['DisplaySettings'].ShowWunschlisteButton && wlLoaded" class="fav-heart">
                <mp-core-tooltip tooltipTitle="{{ isInWishlist(item.ArtNr) ? ls.locs['loc'].AusWunschlisteEntfernen : ls.locs['loc'].WunschlisteHinzufuegen }}"
                            alignment="right">
                  <button class="mp-button padding-left-right-8"
                          (click)="toggleWishlist(item.ArtNr, item.CurrentAuspraegung.PIN, item.Key)">
                    <i class="svg-icon __size-18 __top-0 float-none-important vertical-align-middle"
                       [ngClass]="isInWishlist(item.ArtNr) ? '__clr-primary __favorite-filled' : '__clr-default __favorite'"></i>
                  </button>
                </mp-core-tooltip>
              </div>
              <div>
                <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].WKentfernen }}"
                            alignment="right">
                  <button class="mp-button padding-left-right-8 display-block wk-clear-btn"
                          (click)="deleteItem(item.Key)">
                    <i class="svg-icon __clr-default __size-20 __top-0 __clear float-none-important vertical-align-middle"></i>
                  </button>
                </mp-core-tooltip>
              </div>
            </div>
            <div class="flex-col-1 flex-col-2-s flex-justify-cont-end flex-align-items-center wk-item-count flex-wrap">
              <mp-core-select label="{{ ls.locs['loc'].Stueckzahl }}" [model]="item.Anzahl" [elements]="item.Range" [mpRequired]="true" (modelChange)="item.Anzahl = $event; updatePos(item)" *ngIf="!item.HasZusatzabfrage && !item.DisableChange" class="wk-select _min-width-100"></mp-core-select>
              <mp-core-input label="{{ ls.locs['loc'].Stueckzahl }}" [(model)]="item.Anzahl" *ngIf="item.HasZusatzabfrage || item.DisableChange" [mpDisabled]="true"></mp-core-input>

              <div *ngIf="mpSettings.settings['DisplaySettings'].ShowVerfuegbareMenge"
                   class="__availability"
                   [ngClass]="item.VerfuegbareMenge > 0 ? '__available' : '__not-available'">
                <span class="font-size-s">
                  {{ ls.locs['loc'].von }} {{item.VerfuegbareMenge | integer}}
                </span>
              </div>
            </div>
            <div class="flex-col-4 flex-col-5-s flex-justify-cont-center flex-align-items-center padding-left-64 wk-item-characteristic">
              <div [hidden]="item.ArtNr === 'VersandTN'">
                <div *ngIf="!(item.HasZusatzabfrage || item.DisableChange) && item.CurrentAuspraegung && item.Auspraegungen.length > 1">
                  <mp-core-select label="{{ ls.locs['loc'].Auspraegung }}" [model]="item.CurrentAuspraegung" [mpRequired]="true" [elements]="item.Auspraegungen" textProperty="Auspraegung" (modelChange)="item.CurrentAuspraegung = $event; updatePos(item)" class="magma-select"></mp-core-select>
                </div>
                <span *ngIf="item.HasZusatzabfrage || item.DisableChange || !item.CurrentAuspraegung || item.Auspraegungen.length < 2">{{item.Auspraegung}}</span>
              </div>
            </div>
            <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte" class="flex-col-2 flex-col-3-s flex-justify-cont-center flex-align-items-end flex-direction-column wk-points padding-right-16">

              <span *ngIf="!mpSettings.settings['BestellprozessSettings'].HideVersandkosten">{{item.BasisPunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
              <span *ngIf="mpSettings.settings['BestellprozessSettings'].HideVersandkosten">{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
            </div>
            <div class="wk-mobile-wrapper">
              <div class="wk-item-count">
                <span class="padding-right-8">{{ ls.locs['loc'].Stueckzahl }}:</span>
                <mp-core-select label="{{ ls.locs['loc'].Stueckzahl }}" [model]="item.Anzahl" [elements]="item.Range" (modelChange)="item.Anzahl = $event; updatePos(item)" *ngIf="!item.HasZusatzabfrage && !item.DisableChange" class="wk-select _min-width-100"></mp-core-select>
                <mp-core-input label="{{ ls.locs['loc'].Stueckzahl }}" [(model)]="item.Anzahl" *ngIf="item.HasZusatzabfrage || item.DisableChange" [mpDisabled]="true"></mp-core-input>
                <div *ngIf="mpSettings.settings['DisplaySettings'].ShowVerfuegbareMenge"
                     class="__availability"
                     [ngClass]="item.VerfuegbareMenge > 0 ? '__available' : '__not-available'">
                  <span class="font-size-s">
                    {{ ls.locs['loc'].von }} {{item.VerfuegbareMenge | integer}}
                  </span>
                </div>
              </div>
              <div class="padding-left-64 wk-item-characteristic" *ngIf="item.Auspraegung.length > 0">
                <span class="padding-right-8">{{ ls.locs['loc'].Auspraegung }}:</span>
                <div [hidden]="item.ArtNr === 'VersandTN'">
                  <div *ngIf="!(item.HasZusatzabfrage || item.DisableChange) && item.CurrentAuspraegung && item.Auspraegungen.length > 1">
                    <mp-core-select label="{{ ls.locs['loc'].Auspraegung }}" [model]="item.CurrentAuspraegung" [elements]="item.Auspraegungen" textProperty="Auspraegung" (modelChange)="item.CurrentAuspraegung = $event; updatePos(item)" class="wk-select"></mp-core-select>
                  </div>
                </div>
                <span *ngIf="item.HasZusatzabfrage || item.DisableChange || !item.CurrentAuspraegung || item.Auspraegungen.length < 2" class="wk-characteristic-text">{{item.Auspraegung}}</span>
              </div>

              <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte"
                   class="padding-right-48">
                <div>
                  <span class="padding-right-4">{{ ls.locs['loc'].Praemienwert }}:</span>
                  <span *ngIf="!mpSettings.settings['BestellprozessSettings'].HideVersandkosten">{{item.BasisPunkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                  <span *ngIf="mpSettings.settings['BestellprozessSettings'].HideVersandkosten">{{item.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
                </div>
              </div>
            </div>
            <mp-core-info-notification *ngFor="let message of item.ShownValidationMessages" iconPosition="left" infoState="{{message.Blocking ? 'danger' : 'warning'}}" headline="{{ ls.locs['loc'].PraemieUeberpruefen }}" copyText="{{message.Message}}"></mp-core-info-notification>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grid margin-top-32">
      <div class="flex-col-5 flex-grid flex-wrap wk-infos">
        <mp-core-micro-copy *ngFor="let notice of notices"
                            infoState="inactive"
                            copyText="{{ notice }}"
                            class="flex-col-12 padding-bottom-8">
        </mp-core-micro-copy>
      </div>
      <div class="flex-col-7 flex-direction-column wk-sum">
        <div *ngIf="!mpSettings.settings['BestellprozessSettings'].HideVersandkosten && mpSettings.settings['DisplaySettings'].ShowPunkte"
             class="items-shipment flex-grid"
             [hidden]="(sBItems | sumOfItems:'AuslandsversandPunkte') <= 0">
          <span class="flex-col-7 flex-col-7-xs flex-justify-cont-end wk-shipping-service-lkz">{{ ls.locs['loc'].Lieferkosten }}</span>
          <span class="flex-col-5 flex-col-5-xs flex-justify-cont-end padding-right-48">{{sBItems | sumOfItems:'AuslandsversandPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        </div>
        <div class="items-shipment flex-grid"
             *ngIf="!mpSettings.settings['BestellprozessSettings'].HideVersandkosten && mpSettings.settings['DisplaySettings'].ShowPunkte"
             [hidden]="(sBItems | sumOfItems:'ExpressServicezuschlag') <= 0">
          <span class="flex-col-7 flex-col-7-xs flex-justify-cont-end wk-shipping-service-lkz">{{ ls.locs['loc'].Express }}</span>
          <span class="flex-col-5 flex-col-5-xs flex-justify-cont-end padding-right-48">{{sBItems | sumOfItems:'ExpressServicezuschlag' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        </div>

        <div *ngIf="!mpSettings.settings['BestellprozessSettings'].HideZusatzkosten && mpSettings.settings['DisplaySettings'].ShowPunkte"
             class="items-shipment flex-grid"
             [hidden]="(sBItems | sumOfItems:'ZusatzPunkte') <= 0">
          <span class="flex-col-7 flex-col-7-xs flex-justify-cont-end wk-shipping-service-lkz">{{ ls.locs['loc'].ZusatzkostenInfo }}</span>
          <span class="flex-col-5 flex-col-5-xs flex-justify-cont-end padding-right-48">{{sBItems | sumOfItems:'ZusatzPunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        </div>

        <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte" class="items-total-cost flex-grid">
          <span class="flex-col-7 flex-col-7-xs flex-justify-cont-end">
            {{ ls.locs['loc'].Gesamtkosten }}
          </span>
          <span class="flex-col-5 flex-col-5-xs flex-justify-cont-end padding-right-48">{{sBSum | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        </div>
      </div>
    </div>
    <div class="bestellung-nav-row wk-bottom-buttons floating-container hide-on-mobile">
      <a class="mp-button _prim float-right" *ngIf="sBCount" (click)="validateBasket($event)" [ngClass]="{disabled: sbInvalid}">{{ ls.locs['loc'].WarenkorbWeiter }}</a>
    </div>
    <div stickyBottom class="bestellung-nav-row floating-container show-on-mobile bg-color-white padding-bottom-8 padding-top-16 width-full text-align-center wk-bottom-buttons">
      <a class="mp-button _prim" id="to-payment-button" *ngIf="sBCount" (click)="validateBasket($event)" [ngClass]="{disabled: sbInvalid}">{{ ls.locs['loc'].WarenkorbWeiter }}</a>
      <a href="#/{{role}}/Shop/0" class="mp-button" id="back-to-shop-button">{{ ls.locs['loc'].WeiterEinkaufen }}</a>
    </div>
  </div>
  <div *ngIf="shoppingBasketLoaded && sBCount === 0" class="flex-grid" id="error-404">
    <aside class="flex-col-12 flex-align-items-center flex-direction-column">
      <i class="svg-icon __clr-info __size-62 __error-outline"></i>
      <div class="text-align-center margin-top-16">
        <h1>{{ ls.locs['loc'].WarenkorbLeer }}</h1>
        <p>{{ ls.locs['loc'].WarenkorbLeerHinweis }}</p>
      </div>
    </aside>
    <article class="flex-col-12 flex-justify-cont-center margin-top-56">
      <a id="error-page-button" href="/#/{{role}}/Shop/0" class="mp-button _grey">{{ ls.locs['loc'].WeiterEinkaufen }}</a>
    </article>
    <article class="flex-col-12 flex-justify-cont-center margin-top-88 error-slider text-align-left display-block-important padding-left-right-0">
      <mp-core-newest-awards [count]="8"></mp-core-newest-awards>
    </article>
    <article class="flex-col-12 flex-justify-cont-center error-slider text-align-left display-block-important padding-left-right-0">
      <mp-core-topseller-awards [count]="8"></mp-core-topseller-awards>
    </article>
  </div>
  <mp-core-modal [show]="showDeleteDialog" modalClass="size-warning" id="wk-modal" [buttons]="[{text: ls.locs['loc'].Ja, action: deleteItemFunc, actionParam: addToWlKey, class: '_prim'}, {text: ls.locs['loc'].Nein, action: hideDeleteDialog, class: '_sec'}]">
    <div [innerHTML]="ls.locs['loc'].PraemieAusWarenkorbLoeschen"></div>
    <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important" (click)="closeWkModal()"></i>
  </mp-core-modal>
</div>
