import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../../services/mp-settings.service';
import { MpStatusService } from './../../../../../services/mp-status.service';
import { MpPermissionsService } from './../../../../../services/mp-permissions.service';
import { ParticipantCockpitService } from './../participant-cockpit.service';
import { ApiService } from './../../../../../services/api.service';
import { MpMessagingService } from './../../../../../services/mp-messaging.service';
import { MpErrorHandlerService } from './../../../../../services/mp-error-handler.service';

/**
 * This class provides the data and the
 * functions for the master data of the
 * participant.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-master-data',
  templateUrl: './ptcpnt-cockpit-master-data.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitMasterDataComponent implements OnInit, OnDestroy {

  public hasStatus: boolean = false;
  public admin: boolean = false;
  public notAdmin: boolean = !this.admin;
  public canEdit: boolean = false;
  public cantEdit: boolean = !this.canEdit;
  public canSendLoginData: boolean = false;
  public data: { [key: string]: any } = {};
  public filteredSalutationList: Array<any> = [];
  public errors: { [key: string]: any } = {};

  private _cockpitData: { [key: string]: any } = {};
  private _eligibleUpTohere: boolean = false;
  private _getStatusSubscription: Subscription | undefined;
  private _getPermissionsSubscription: Subscription | undefined;
  private _getParticipantCockpitDataSubscription: Subscription | undefined;
  private _getParticipantMasterDataSubscription: Subscription | undefined;
  private _sendPwdForgotMailSubscription: Subscription | undefined;
  private _sendUsernameMailSubscription: Subscription | undefined;
  private _sendLoginDataSmsSubscription: Subscription | undefined;
  private _anonymizeSubscription: Subscription | undefined;
  private _saveMasterDataSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpStatus: MpStatusService,
    private _mpPermissions: MpPermissionsService,
    private _participantCockpitService: ParticipantCockpitService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpErrorHandler: MpErrorHandlerService
  ) { }

  /**
   * Gets the status, the permissions and
   * the participant cockpit data.
   */
  ngOnInit(): void {
    if (typeof this._mpStatus.currentHasStatus === 'undefined') {
      this._getStatusSubscription = this._mpStatus.status.subscribe((hasStatus: boolean) => {
        this.hasStatus = hasStatus;

        if (typeof this._getStatusSubscription !== 'undefined') {
          this._getStatusSubscription.unsubscribe();
        }
      });

      this._mpStatus.hasStatus();
    } else {
      this.hasStatus = this._mpStatus.currentHasStatus;

      if (typeof this._getStatusSubscription !== 'undefined') {
        this._getStatusSubscription.unsubscribe();
      }
    }

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.admin = this._mpPermissions.permissions.IsAdmin;
        this.notAdmin = !this.admin;
        this.canEdit = this._mpPermissions.permissions.CanCurrentUserEdit;
        this.cantEdit = !this.canEdit;
        this.canSendLoginData = this._mpPermissions.permissions.CanZugangsdatenSenden
      }
    });

    this._mpPermissions.getPermissions();

    this._getParticipantCockpitDataSubscription = this._participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
      this._showMasterData(data['tnId']);
      this._cockpitData = data;
    });

    this._participantCockpitService.saveFuncs['Stammdaten'] = this._save.bind(this);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getStatusSubscription !== 'undefined') {
      this._getStatusSubscription.unsubscribe();
    }

    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getParticipantCockpitDataSubscription !== 'undefined') {
      this._getParticipantCockpitDataSubscription.unsubscribe();
    }

    if (typeof this._getParticipantMasterDataSubscription !== 'undefined') {
      this._getParticipantMasterDataSubscription.unsubscribe();
    }

    if (typeof this._sendPwdForgotMailSubscription !== 'undefined') {
      this._sendPwdForgotMailSubscription.unsubscribe();
    }

    if (typeof this._sendUsernameMailSubscription !== 'undefined') {
      this._sendUsernameMailSubscription.unsubscribe();
    }

    if (typeof this._sendLoginDataSmsSubscription !== 'undefined') {
      this._sendLoginDataSmsSubscription.unsubscribe();
    }

    if (typeof this._anonymizeSubscription !== 'undefined') {
      this._anonymizeSubscription.unsubscribe();
    }

    if (typeof this._saveMasterDataSubscription !== 'undefined') {
      this._saveMasterDataSubscription.unsubscribe();
    }
  }

  /**
   * Loads and shows the master data of
   * the participant.
   */
  private _showMasterData(ptcpntId: number): void {
    if (typeof ptcpntId === 'undefined' || ptcpntId === -1) {
      return;
    }

    this._getParticipantMasterDataSubscription = this._apiService.postRequest('/api/Teilnehmer/getStammdaten/', { TnID: ptcpntId }).subscribe((data: any) => {
      this.data = data.Records[0];
      this.data['PWDGueltigBis'] = moment(this.data['PwdValid2DateOriginalDbString']).format('DD.MM.YYYY HH:mm:ss');
      this._eligibleUpTohere = this.data['Teilnahmeberechtigt'];
      this.updateSalutationList();
    });
  }

  /**
   * Updates the salutation list for the
   * salutation select.
   */
  updateSalutationList(): void {
    this.filteredSalutationList = Object.keys(this.data).length > 0 && typeof this.data['Land'] !== 'undefined' ? this.data['Anredenliste'].filter((salutation: {[key: string]: any }) => {
      return salutation['Land'] === this.data['Land'].Kuerzel;
    }) : [];
  }

  /**
   * Sends the forgot password mail to
   * the participant.
   */
  sendPwdForgotMail(): void {
    const eMail = this.data['EmailStd'] === 'G' || !this.data['EmailStd'] ? this.data['Email_G'] : this.data['Email_P'];

    this._mpMessaging.openConfirm({
        text: this.ls.locs['loc'].PasswortMailSendenConfirm.replace('[EMail]', eMail),
        submitText: this.ls.locs['loc'].Ok,
        cancelText: this.ls.locs['loc'].Abbrechen
      },
      () => {
        this._sendPwdForgotMailSubscription = this._apiService
          .getRequest(`/api/Com/SendPwdVergessenLinkCockpit?tnId=${this._cockpitData['tnId']}`).subscribe(
            (data: any) => {
              this._mpMessaging.closeOverlay();
              if (data.Result === 'OK')
                this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].PasswortMailSendenSuccess);
              else
                this._mpMessaging.openDangerPanel(data.Message);
            },
            (error: any) => {
              this._mpMessaging.closeOverlay();
              this._mpMessaging.openDangerPanel(error.Message);
            });
      },
      () => {
        this._mpMessaging.closeOverlay();
      });
  }

  /**
   * Sends the username via mail to
   * the participant.
   */
  sendUsernameMail(): void {
    const eMail = this.data['EmailStd'] === 'G' || !this.data['EmailStd'] ? this.data['Email_G'] : this.data['Email_P'];

    this._mpMessaging.openConfirm({
      text: this.ls.locs['loc'].BenutzernameMailSendenConfirm.replace('[EMail]', eMail),
      submitText: this.ls.locs['loc'].Ok,
      cancelText: this.ls.locs['loc'].Abbrechen
    }, () => {
      this._sendUsernameMailSubscription = this._apiService.getRequest(`/api/Com/SendBenutzernameCockpit?tnId=${this._cockpitData['tnId']}`).subscribe((data: any) => {
        this._mpMessaging.closeOverlay();

        if (data.Result === 'OK') {
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].BenutzernameMailSendenSuccess);
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
        (error: any) => {
          this._mpMessaging.closeOverlay();
          this._mpMessaging.openDangerPanel(error.Message);
        });
    }, () => {
      this._mpMessaging.closeOverlay();
    });
  }

  /**
   * Sends the login data via sms to
   * the participant.
   */
  sendLoginDataSms(): void {
    const phoneNumber = this.data['Telefon_G'];

    if (!phoneNumber) {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].KeineMobilnummerVorhanden);
    }

    this._mpMessaging.openConfirm({
      text: this.ls.locs['loc'].ZugangsdatenSenden.replace('[EMail]', phoneNumber),
      submitText: this.ls.locs['loc'].Ok,
      cancelText: this.ls.locs['loc'].Abbrechen
    }, () => {
      this._sendLoginDataSmsSubscription = this._apiService.postRequest('/api/Com/SendZugangsdatenSms', this.data).subscribe((data: any) => {
        this._mpMessaging.closeOverlay();
        this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].ZugangsdatenVersendet);
      },
        (error: any) => {
          this._mpMessaging.closeOverlay();
          this._mpMessaging.openDangerPanel(error.Message);
        });
    }, () => {
      this._mpMessaging.closeOverlay();
    });
  }

  /**
   * Sets the standard email address.
   */
  setEmailStd(event: MouseEvent): void {
    // @ts-ignore
    this.data['EmailStd'] = event.target.dataset.val;
  }

  /**
   * Sets the standard phone number.
   */
  setTelephoneStd(event: MouseEvent): void {
    // @ts-ignore
    this.data['TelefonStd'] = event.target.dataset.val;
  }

  /**
   * Anonymizes the participant data.
   */
  anonymize(): void {
    this._mpMessaging.openConfirm({
      text: this.ls.locs['loc'].WirklichAnonymisieren,
      submitText: this.ls.locs['loc'].Ja,
      cancelText: this.ls.locs['loc'].Nein
    }, () => {
      this._anonymizeSubscription = this._apiService.getRequest(`/api/Teilnehmer/TnAnonymisieren?tnId=${this._cockpitData['tnId']}`).subscribe((data: any) => {
        this._mpMessaging.closeOverlay();

        if (data.Result === 'OK') {
          this._mpMessaging.openSuccessPanel(data.Message);
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
        (error: any) => {
          this._mpMessaging.closeOverlay();
          this._mpMessaging.openErrorPanel(error);
        });
    }, () => {
      this._mpMessaging.closeOverlay();
    });
  }

  /**
   * Saves the chaned participant data.
   */
  private _save(): void {
    this.errors = {};
    this.data['GruppenID'] = this.data['GruppenObjekt'].GruppenID;
    this.data['StatusID'] = this.data['StatusObjekt'].StatusID;
    this.data['AnredenID'] = this.data['AnredenObjekt'].ID;
    this.data['LKZ'] = this.data['Land'].Kuerzel;
    this.data['Geschlecht'] = this.data['AnredenObjekt'].Geschlecht;

    if (this._eligibleUpTohere !== this.data['Teilnahmeberechtigt']) {
      if (this.data['Teilnahmeberechtigt'])
        this.data['PWDGueltigBis'] = new Date(2050, 12, 31, 23, 59, 0, 0)
    }

    this._saveMasterDataSubscription = this._apiService.postRequest('/api/Teilnehmer/saveStammdaten/', this.data).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.data['errMessages'] = '';
        const inputErrors = document.querySelectorAll('.inputError');

        if (inputErrors.length > 0) {
          inputErrors.forEach((inputError: any) => {
            inputError.classList.remove('inputError');
          });
        }

        this._mpMessaging.openSuccessPanel(data.Message);
        this._participantCockpitService.reload();
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      const asErrMessages = this._mpErrorHandler.handleResponse(error);
      this.data['errMessages'] = asErrMessages;
      this.errors = error.ModelState;

      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].FehlerBeimSpeichern +
        (error.Message === undefined ? '' : ('<br/>' + error.Message)) +
        (error.ExceptionMessage === undefined ? '' : ('<br/>' + error.ExceptionMessage)));
    });
  }

}
