<div class="magma-select flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8 position-relative" (clickOutside)="hide()" *ngIf="(selectedCategory && selectedCategory.Bezeichnung && shownCategories && shownCategories.length > 0)" click-outside-except="#kategorieOpenClose, #filterActive, .filterContent">
  <div class="select-container display-flex flex-align-items-center padding-left-8 padding-right-32" (click)="toggle()">
    <div class="text-overflow-ellipsis">{{selectedCategory.Bezeichnung}}</div>
    <i class="svg-icon __clr-default __size-24 __top-8 __sidebar-arrow-down right-4 position-absolute"></i>
  </div>
  <div class="mobile-shadow" [hidden]="!showDropdown" (click)="closeDropdown()"></div>
  <ul [hidden]="!showDropdown" class="select-dropdown">
    <li *ngFor="let child of shownCategories">
      <ng-container *ngTemplateOutlet="categoryTreeTemplate; context: { child: child }"></ng-container>
    </li>
  </ul>
</div>

<ng-template #categoryTreeTemplate let-child="child">
  <a [routerLink]="'/' + role + '/Shop'" [queryParams]="{ k: child.KategorieID.toString() }" class="selection system-icon-text" ng-class="{active: child.Selected}" (click)="hide()">
    <span class="text-label">{{child.Bezeichnung}} {{child.Anzahl > 0 ? "("+child.Anzahl+")" : ""}}</span>
  </a>
  <ul class="margin-left-16" *ngIf="shownCategories.length > 0">
    <li *ngFor="let subChild of child.Children">
      <ng-container *ngTemplateOutlet="categoryTreeTemplate; context: { child: subChild }"></ng-container>
    </li>
  </ul>
</ng-template>
