import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Matomo } from 'angulartics2';

import { CoreRoutingModule } from './core-routing.module';
import { FirstLoginModule } from './modules/first-login/first-login.module';
import { ExternModule } from './modules/extern/extern.module';
import { ExternFixedModule } from './modules/extern-fixed/extern-fixed.module';
import { ParticipantModule } from './modules/participant/participant.module';
import { ManagerModule } from './modules/manager/manager.module';
import { AdminModule } from './modules/admin/admin.module';
import { ErrorModule } from './modules/error/error.module';

import { CoreComponent } from './core.component';

import { authInterceptorProviders } from './helpers/auth.interceptor';

@NgModule({
  declarations: [
    CoreComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    CoreRoutingModule,
    ExternModule,
    ExternFixedModule,
    ParticipantModule,
    LoadingBarModule,
    ManagerModule,
    AdminModule,
    ErrorModule,
    FirstLoginModule,
    Angulartics2Module.forRoot()
  ],
  exports: [
    CoreComponent
  ],
  providers: [
    authInterceptorProviders,
    Meta
  ],
  bootstrap: [
    CoreComponent
  ]
})
export class CoreModule { }

@NgModule({})
export class CoreSharedModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    }
  }
}
