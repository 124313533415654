<div id="reiseberichte" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" class="layout-container bg-color-white padding-bottom-48">
  <mp-core-breadcrumbs></mp-core-breadcrumbs>
  <h1 class="flex-col-12">{{ls.locs['locReisekonfigurator'].Reiseberichte}}</h1>

  <div class="floating-container flex-grid">
    <mp-rk-travelogue-card *ngFor="let travelogue of travelogues"
                           class="flex-col-6"
                           [travelogue]="travelogue">
    </mp-rk-travelogue-card>
  </div>

  <mp-rk-tc-support class="hide-on-medium"></mp-rk-tc-support>
  <div *ngIf="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length > 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer !== null) && !mpSettings.settings['DisplaySettings'].HideHotline" id="rk-bottom-buttons" class="show-on-medium position-fixed bottom-0 width-full left-0-important" stickyBottom>
    <a class="mp-button _light-grey width-100-pcnt display-flex-important flex-justfiy-cont-center flex-align-items-center flex-direction-column padding-top-bottom-8" href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">
      <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
      <span class="display-block">{{ls.locs['locReisekonfigurator'].Reisebuero}}</span>
    </a>
  </div>
</div>
