import { Component, OnInit } from '@angular/core';
import { MpLocalizationService } from '../../services/mp-localization.service';

import { MpSettingsService } from './../../services/mp-settings.service';

/**
 * This class provides the view for the dashboard.
 */
@Component({
  selector: 'mp-core-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService
  ) { }

  ngOnInit(): void {
  }

}
