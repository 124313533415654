import { Directive, Input, SimpleChanges, ElementRef } from '@angular/core';

/**
 * This class provides a directive to set the
 * focus on a given element, when a given
 * boolean is true.
 */
@Directive({
  selector: '[mpCoreMpFocus]'
})
export class MpFocusDirective {

  @Input() public mpFocus: boolean = false;

  private _focusElement: EventListener | undefined;
  private _nativeElement: HTMLElement | undefined;

  constructor(private _element: ElementRef) {
    this._focusElement = this.focusElement.bind(this);
    this._nativeElement = this._element.nativeElement;
  }

  /**
   * Angulars change function, that creates a
   * new custom event for touchstart on the
   * directives' element and triggers it to set
   * the focus on it.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['mpFocus']) {
      if (this.mpFocus && this._nativeElement && this._focusElement) { 
        this._nativeElement.removeEventListener('touchstart', this._focusElement);
        this._nativeElement.addEventListener('touchstart', this._focusElement);

        setTimeout(() => {
          if (this._nativeElement) {
            this._nativeElement.dispatchEvent(new CustomEvent('touchstart'));
          }
        }, 0);
      }
    }
  }

  /**
   * Sets the focus to the given target element.
   */
  focusElement(): void {
    if (this._nativeElement) {
      this._nativeElement.focus();
    }
  }
}
