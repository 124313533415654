import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { ApiService } from './../../services/api.service';
import { AuthService } from './../../services/auth.service';

/**
 * This class provides logout functions.
 */
@Component({
  selector: 'mp-core-logout',
  templateUrl: './logout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(public ls: MpLocalizationService, private _apiService: ApiService, private _authService: AuthService) { }

  ngOnInit(): void {
  }

  /**
   * Calls the backend logout and after that it
   * logout the user in the frontend.
   */
  logout(): void {
    this._apiService.getRequest('/api/Auth/Logout').subscribe((redirectUrl: any) => {
      this._authService.setUserLoggedOut();

      if (redirectUrl && (redirectUrl.indexOf('http://') === 0 || redirectUrl.indexOf('https://') === 0)) {
        window.location = redirectUrl;
      } else {
        location.href = "/";
      }
    },
    (error: any) => {
      console.log(error.message);
    });
  }

}
