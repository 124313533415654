import { Pipe, PipeTransform } from '@angular/core';

/**
 * This class provides a pipe to
 * get the sum of the given items.
 */
@Pipe({
  name: 'sumOfItems'
})
export class SumOfItemsPipe implements PipeTransform {

  transform(items: any, key: string): number {
    if (!items || typeof (key) === 'undefined') {
      return 0;
    }

    let sum = 0;

    for (let i = 0; i < items.length; i++) {
      sum = sum + items[i][key];
    }

    return sum;
  }

}
