import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';

/**
 * This class provides the functions
 * and the date for the support lug.
 */
@Component({
  selector: 'mp-core-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupportComponent implements OnInit {

  @Input() public subline: string = '';

  public openSupport: boolean = false;
  public closeSupport = this._closeSupport.bind(this);

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService
  ) { }

  ngOnInit(): void { }

  /**
   * Closes the support lug.
   */
  private _closeSupport(): void {
    if (this.openSupport === true) {
      this.openSupport = false;
    }
  };

}
