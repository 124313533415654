import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpCoreService } from '@core/services/mp-core.service';

/**
 * This class provides the data and functions
 * for the tip and win editor.
 */
@Component({
  selector: 'mp-taw-tip-and-win-editor',
  templateUrl: './tip-and-win-editor.component.html',
  styleUrls: ['./tip-and-win-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinEditorComponent implements OnInit, OnDestroy {

  public campaigns: Array<any> = [];
  public allGroups: Array<any> = [];
  public questions: Array<any> = [];
  public languages: Array<any> = [];
  public language: string = '';
  public campaign: string = '';
  public errors: { [key: string]: any } = {};

  private _questionsDeleted: Array<any> = [];
  private _invocationCounter: number = 0;
  private _getLocsSubscription: Subscription | undefined;
  private _getCampaignsSubscription: Subscription | undefined;
  private _getGroupsSubscription: Subscription | undefined;
  private _getLanguagesSubscription: Subscription | undefined;
  private _getDayQuizSubscription: Subscription | undefined;
  private _updateGroupSubscription: Subscription | undefined;
  private _updateDayQuizSubscription: Subscription | undefined;
  private _deleteDayQuizSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpCoreService: MpCoreService
  ) { }

  /**
   * Gets the locs, the groupa and the
   * languages.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._getCampaigns();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._getCampaigns();
        }
      });

      this.ls.getLocalization();
    }

    this._getGroupsSubscription = this._apiService.getRequest('/api/TipAndWin/GetGruppen4Editor').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.allGroups = data.Records;
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
    this._getLanguagesSubscription = this._apiService.getRequest('/api/Language/GetLanguagesForDropdown').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length === 1 && data.Records[0].Languages) {
        this.languages = data.Records[0].Languages;
        this.language = this.languages[0].Kuerzel;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getCampaignsSubscription !== 'undefined') {
      this._getCampaignsSubscription.unsubscribe();
    }

    if (typeof this._getGroupsSubscription !== 'undefined') {
      this._getGroupsSubscription.unsubscribe();
    }

    if (typeof this._getLanguagesSubscription !== 'undefined') {
      this._getLanguagesSubscription.unsubscribe();
    }

    if (typeof this._getDayQuizSubscription !== 'undefined') {
      this._getDayQuizSubscription.unsubscribe();
    }

    if (typeof this._updateGroupSubscription !== 'undefined') {
      this._updateGroupSubscription.unsubscribe();
    }

    if (typeof this._updateDayQuizSubscription !== 'undefined') {
      this._updateDayQuizSubscription.unsubscribe();
    }

    if (typeof this._deleteDayQuizSubscription !== 'undefined') {
      this._deleteDayQuizSubscription.unsubscribe();
    }
  }

  /**
   * Returns the question id of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByQuestionId(index: number, item: any): number {
    return item['questionId'];
  }

  /**
   * Gets the campaigns from the backend.
   */
  private _getCampaigns(): void {
    this._getCampaignsSubscription = this._apiService.getRequest('/api/TipAndWin/GetAktionen').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.campaigns = data.Records;

        if (this.campaigns.length > 0) {
          this.campaign = this.campaigns[0].Name;
          this.getDayQuiz();
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Gets the 'day quiz' for the selected
   * campaign.
   */
  getDayQuiz(): void {
    if (this.campaign !== '') {
      this._getDayQuizSubscription = this._apiService.getRequest(`/api/TipAndWin/GetTagesQuiz4Editor?aktion=${this.campaign}`).subscribe((data: any) => {
        this.questions = [];
        this._questionsDeleted = [];

        if (data.Result === 'OK') {
          this.questions = data.Records.map((quiz: { [key: string]: any }) => {
            return {
              questionId: quiz['TagesQuizId'],
              question: quiz['Frage'],
              answerA: quiz['AntwortA'],
              answerB: quiz['AntwortB'],
              answerC: quiz['AntwortC'],
              answerD: quiz['AntwortD'],
              isCorrectLabel: quiz['IsKorrektLabel'],
              groups: quiz['Gruppen'],
              dateParams: {
                Headline: this.ls.locs['locTipAndWin'].Gueltigkeitstag,
                date: moment(quiz['Datum']),
                maxYearDif: 1,
                minYearDif: -31
              },
              offen: true
            };
          });
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });
    }
  }

  /**
   * Updates the group for the given
   * question.
   */
  private _updateGroup(question: { [key: string]: any }): void {
    const quiz = {
      Aktion: this.campaign,
      TagesQuizId: question['questionId'],
      Gruppen: question['groups']
    };

    ++this._invocationCounter;

    this._updateGroupSubscription = this._apiService.postRequest('/api/TipAndWin/SaveTagesQuizGruppen4Editor', quiz).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (--this._invocationCounter <= 0) {
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].SaveMessageOK1);
          this.getDayQuiz();
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Updates the 'day quiz' with the
   * given question.
   */
  private _updateDayQuiz(question: { [key: string]: any }): void {
    const quiz = {
      Aktion: this.campaign,
      TagesQuizId: question['questionId'],
      Datum: question['dateParams'].date,
      Frage: question['question'],
      AntwortA: question['answerA'],
      AntwortB: question['answerB'],
      AntwortC: question['answerC'],
      AntwortD: question['answerD'],
      IsKorrektLabel: question['isCorrectLabel']
    };

    ++this._invocationCounter;

    this._updateDayQuizSubscription = this._apiService.postRequest('/api/TipAndWin/SaveTagesQuiz4Editor', quiz).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (--this._invocationCounter <= 0) {
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].SaveMessageOK1);
          this.getDayQuiz();
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Deletes the given question from
   * the 'day quiz'.
   */
  private _deleteDayQuiz(question: { [key: string]: any }): void {
    const quiz = {
      Aktion: this.campaign,
      TagesQuizId: question['questionId']
    };

    ++this._invocationCounter;

    this._deleteDayQuizSubscription = this._apiService.postRequest('/api/TipAndWin/DeleteTagesQuiz4Editor', quiz).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (--this._invocationCounter <= 0) {
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].SaveMessageOK1);
          this.getDayQuiz();
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Adds a new question to the quiz.
   */
  addQuestion(): void {
    const maxQuestionId = (!this.questions || this.questions.length === 0) ? 0 : Math.max(...this.questions.map((question: { [key: string]: any }) => {
      return question['questionId'];
    }));

    this.questions.push({
      questionId: 1 + maxQuestionId,
      question: '',
      answerA: '',
      answerB: '',
      answerC: '',
      answerD: '',
      isCorrectLabel: '',
      groups: [],
      dateParams: {
        Headline: this.ls.locs['locTipAndWin'].Gueltigkeitstag,
        date: moment(),
        maxYearDif: 1,
        minYearDif: -31
      },
      offen: true
    });
  }

  /**
   * Marks the given question as deleted.
   */
  deleteQuestion(question: { [key: string]: any }): void {
    const index = this.questions.indexOf(question);
    this.questions.splice(index, 1);
    this._questionsDeleted.push(question);
  }

  /**
   * Checks whether or not the user can
   * save.
   */
  canSave(): boolean {
    return true;
  }

  /**
   * Saves the questions.
   */
  saveQuestions(): void {
    this._invocationCounter = 0;

    this.questions.forEach((question: { [key: string]: any }) => {
      this._updateGroup(question);
      this._updateDayQuiz(question);
    });

    this._questionsDeleted.forEach((question: { [key: string]: any }) => {
      this._deleteDayQuiz(question);
    });

    this.errors = {};
  }

  /**
   * Resets the editor.
   */
  reset(): void {
    if (this.campaigns.length > 0) {
      this.campaign = this.campaigns[0].Name;
      this.getDayQuiz();
    } else {
      this.campaign = '';
      this.questions = [];
    }
  }

  /**
   * Goes back to the previous site.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

}
