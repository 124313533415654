import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * This class provides the error message, that
 * is shown under a form element, if an error
 * occures.
 */
@Component({
  selector: 'mp-core-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  public isErrString: boolean = false;
  public errorProp: Array<string> = [];
  public errorString: string = '';
  @Input() public model: { [key: string]: any } | string | undefined;

  constructor() { }

  /**
   * Angulars init function of the class. It sets
   * checks whether or not the given error is a
   * model or a string.
   */
  ngOnInit(): void {
    if (typeof this.model !== 'undefined') {
      if (typeof this.model === 'string') {
        this.isErrString = true;
        this.errorString = this.model;
      } else {
        this.isErrString = false;
        const errKeys = Object.keys(this.model);

        errKeys.forEach(key => {
          if (typeof this.model !== 'undefined') {
            // @ts-ignore
            this.errorProp.push(this.model[key]);
          }
        });
      }
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
