<div class="layout-container">
  <div class="buehnedetails">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <mp-core-detail-page-content *ngIf="errorMessage === '' && stageLoaded"
                                 imgSrc="{{stage['ImagePath'] + '?h=320&w=1280&mode=crop&anchor=middleright' }}"
                                 [title]="headline"
                                 [text]="(text === '' ? teaser : text)"></mp-core-detail-page-content>
    <div class="card" *ngIf="errorMessage !== ''">
      <div class="error" [innerHTML]="errorMessage"></div>
      <div class="bg-color-light-grey padding-top-32 padding-bottom-32"></div>
    </div>
  </div>
</div>
