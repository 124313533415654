import { Component, OnInit, ViewEncapsulation, Injector, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ApiService } from './../../../../../services/api.service';

import { ParentTableRow, ParentTableRowIndex } from './../../../../../components/data-table/table-injectors';

/**
 * This class provides the data for the
 * email iframe.
 */
@Component({
  selector: 'mp-core-email',
  templateUrl: './email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EmailComponent implements OnInit, OnDestroy {

  public text: any;

  private _getMailDataSubscription: Subscription | undefined;

  public trustedHtml: any;

  constructor(
    private _apiService: ApiService,
    public sanitizer: DomSanitizer,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  ngOnInit(): void {
    if (this._parentTableRow !== null) {
      // @ts-ignore
      this._getMailDataSubscription = this._apiService.getRequest(`/api/EMailProtokoll/getMailBody/${this._parentTableRow['MailId']}`).subscribe((data: any) => {
        this.text = data.Records[0];
        this.text = this.sanitizer.bypassSecurityTrustHtml(data.Records[0]);
      });
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getMailDataSubscription !== 'undefined') {
      this._getMailDataSubscription.unsubscribe();
    }
  }
}
