import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

import { Permissions } from './interfaces/permissions';

/**
 * This class provides the functions to fetch the
 * permissions from the backend api.
 */
@Injectable({
  providedIn: 'root'
})
export class MpPermissionsService {

  private _permissionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public permissions: Permissions = {
    CanCurrentUserEdit: false,
    CanDownloadStatistics: false,
    CanZugangsdatenSenden: false,
    IsAdmin: false,
    IsAuthenticated: false,
    IsManager: false,
    IsAd: false
  };
  public permissionsLoaded: Observable<boolean> = this._permissionsLoaded.asObservable();

  constructor(private _apiService: ApiService) { }

  /**
   * Calls the api to get the settings data.
   */
  getPermissions(): void {
    this._apiService.getRequest('/api/Permissions/GetPermissions/').subscribe((data: any) => {
      this.permissions = data.Records[0];
      this._permissionsLoaded.next(true);
    },
    (error: any) => {
      console.log(error.message);
    });
  }
}
