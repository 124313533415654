import { Component, OnInit } from '@angular/core';

import { ApiService } from './../../services/api.service';
import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';
import { CookieService } from './../../services/cookie.service';
import { LoginService } from './login.service';

/**
 * This class provides the login functions.
 */
@Component({
  selector: 'mp-core-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isMaintenanceWork: boolean = false;
  public showPWDForgotten: boolean = false;
  public loading: boolean = false;
  public user: any = '';
  public pwd: string = '';

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _cookieService: CookieService,
    private _loginService: LoginService
  ) { }

  /**
   * Checks if user is already set in cookies,
   * and whether or not there is a maintenance
   * work at the moment.
   */
  ngOnInit(): void {
    if (this._cookieService.checkIfCookieSet('CurrentLogin')) {
      this.user = this._cookieService.getCookieValue('CurrentLogin');
    }

    this._apiService.getRequest('/api/Helper/IsWartungsarbeit').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.isMaintenanceWork = data.Records[0];
      } else {
        this.isMaintenanceWork = true;
      }
    },
    (error: any) => {
      console.log(error.message);
    });
  }

  /**
   * Starts login functions.
   */
  login(): void {
    this.loading = true;
    this._loginService.login(this.user, this.pwd);
    this._loginService.isLoggedIn.subscribe(() => { }, () => { }, () => {
      this._loginService.setUserLoggedIn();
      this.loading = false;
    });
  }

}
