import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';

/**
 * This class provides the functions for
 * the inbox item preview.
 */
@Component({
  selector: 'mp-core-inbox-preview',
  templateUrl: './inbox-preview.component.html'
})
export class InboxPreviewComponent implements OnInit, OnDestroy {

  @Input() public count: number | null = null;
  @Input() public type: string = '';
  @Input() public header: string = '';
  @Input() public hideIfEmpty: boolean = false;

  public loaded: boolean = false;
  public inboxItems: Array<any> = [];

  private _getInboxSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the inbox data.
   */
  ngOnInit(): void {
    this._getInboxSubscription = this._apiService.postRequest('/api/Postfach/GetPostfach', {
      Key: this.type,
      Suchtext: '',
      Startindex: 0,
      Pagesize: this.count === null ? 2 : this.count
    }).subscribe((data: any) => {
      this.inboxItems = data.Records;
      this.loaded = true;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getInboxSubscription !== 'undefined') {
      this._getInboxSubscription.unsubscribe();
    }
  }

}
