<section class="dashboard-chart-section">
  <div class="flex-grid">
    <div class="flex-col-12 __section-header flex-justify-cont-between margin-bottom-16">
      <div class="flex-col-6 flex-wrap padding-left-0">
        <h2 [innerHTML]="sectionHeadline" class="display-block width-full margin-bottom-8"></h2>
        <p [innerHTML]="sectionSubline" class="margin-bottom-0 display-block width-full bold"></p>
      </div>
      <div class="flex-col-6 padding-right-0 flex-wrap flex-justify-cont-end">
        <ng-content></ng-content>
      </div>
    </div>
    <div *ngFor="let chart of charts; let chartIndex = index" class="flex-col-12 __chart flex-wrap">
      <div class="flex-col-12 flex-wrap padding-0 bg-color-white">
        <div (click)="toggleChart(chartIndex)" class="flex-col-12 padding-16 __chart-headline flex-justify-cont-between" [ngClass]="{ 'pointer-hover': charts.length > 1 }">
          <p class="margin-bottom-0 display-flex flex-align-items-center flex-wrap">
            <i class="svg-icon __clr-primary __size-20 __top-0 __trending-up margin-right-12"></i>
            <span [innerText]="chart.chartHeadline"></span>
          </p>
          <i class="svg-icon __size-28 __top-0 __clr-inactive" *ngIf="charts.length > 1" [ngClass]="!openCharts[chartIndex] ? '__add' : '__remove'"></i>
        </div>
        <div [ngSwitch]="chart.chartType" [hidden]="!openCharts[chartIndex]" class="flex-col-12 padding-16">
          <div *ngSwitchCase="'bar-plot'" class="__bar-plot width-full display-flex flex-align-items-center flex-wrap" [ngClass]="{ 'scrollable-chart' : chart.scrollable }">
            <mp-core-d3-bar-plot id="{{chart.mpId}}"
                         mpid="{{chart.mpId}}"
                         [width]="chart.width"
                         [height]="chart.height"
                         textxachse="{{chart.textXAchse}}"
                         textyachse="{{chart.textYAchse}}"
                         [animationms]="chart.animationMs"
                         dataurl="{{chart.dataUrl}}"
                         [loader]="chart.loader"
                         [showaverage]="chart.showAverage"
                         class="box-sizing-border-box padding-16 position-relative">
            </mp-core-d3-bar-plot>
            <div class="chart-legend box-sizing-border-box display-inline-flex flex-wrap flex-align-items-center"></div>
          </div>
          <div *ngSwitchCase="'line-chart'" class="__line-chart width-full display-flex flex-align-items-center flex-wrap" [ngClass]="{ 'scrollable-chart' : chart.scrollable }">
            <mp-core-d3-line-chart  id="{{chart.mpId}}"
                            mpid="{{chart.mpId}}"
                            [width]="chart.width"
                            [height]="chart.height"
                            textxachse="{{chart.textXAchse}}"
                            textyachse="{{chart.textYAchse}}"
                            [animationms]="chart.animationMs"
                            dataurl="{{chart.dataUrl}}"
                            [loader]="chart.loader"
                            class="box-sizing-border-box padding-16 position-relative">
            </mp-core-d3-line-chart>
            <div class="chart-legend box-sizing-border-box display-inline-flex flex-wrap flex-align-items-center"></div>
          </div>
          <div *ngSwitchCase="'pie-chart'" class="__pie-chart width-full display-flex flex-align-items-center flex-wrap">
            <mp-core-d3-pie-chart id="{{chart.mpId}}"
                          [isDonut]="chart.isDonut"
                          mpid="{{chart.mpId}}"
                          [width]="chart.width"
                          [height]="chart.height"
                          dataurl="{{chart.dataUrl}}"
                          [loader]="chart.loader"
                          class="box-sizing-border-box padding-16 position-relative text-align-center">
            </mp-core-d3-pie-chart>
            <div class="chart-legend box-sizing-border-box display-inline-flex flex-wrap flex-align-items-center"></div>
          </div>
          <div *ngSwitchCase="'circular-bar-plot'" class="__circular-bar-plot width-full display-flex flex-align-items-center flex-wrap">
            <mp-core-d3-circular-bar-plot id="{{chart.mpId}}"
                                  mpid="{{chart.mpId}}"
                                  [width]="chart.width"
                                  [height]="chart.height"
                                  [animationms]="chart.animationMs"
                                  dataurl="{{chart.dataUrl}}"
                                  [loader]="chart.loader"
                                  class="box-sizing-border-box padding-16 position-relative text-align-center">
            </mp-core-d3-circular-bar-plot>
            <div class="chart-legend box-sizing-border-box display-inline-flex flex-wrap flex-align-items-center"></div>
          </div>
          <div *ngSwitchDefault></div>
        </div>
      </div>
    </div>
    <div [hidden]="!hasTable" class="flex-col-12 margin-top-8">
      <mp-core-accordion class="position-relative fallback-acc width-full"
                         id="{{mpId}}-accordion"
                         [accToggle]="true"
                         [accDataExpression]="tableAccDataFunc"
                         [hasTranscluded]="true"
                         [sidebarAcc]="false"
                         [accOpenOnLoad]="true">
        <ng-container hasNoDataModel>
          <mp-core-data-table title="{{tableTitle}}"
                              infotextShorten="{{tableInfotextShorten}}"
                              fieldsAction="{{tableFieldsAction}}"
                              listAction="{{tableListAction}}"
                              loaderName="{{tableLoader}}"
                              [paging]="tablePaging"
                              [footer]="tableFooter"
                              defaultSorting="{{tableDefaultSorting}}"
                              [disableCache]="tableDisableCache">
          </mp-core-data-table>
          <div class="display-flex width-full flex-justify-cont-end padding-left-right-0 padding-bottom-16" *ngIf="exportDataLink && (ls.locsLoaded | async)">
            <a href="{{exportDataLink}}" class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</a>
          </div>
          <div>
            <span>{{tableText}}</span>
          </div>
        </ng-container>
      </mp-core-accordion>
    </div>
  </div>
</section>
