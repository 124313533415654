import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/helpers/auth.guard';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { ManagerComponent } from '@core/modules/manager/manager.component';
import { SchoolingStatisticComponent } from './modules/manager/pages/schooling-statistic/schooling-statistic.component';
import { SchoolingPageComponent } from './modules/participant/pages/schooling-page/schooling-page.component'
import { SchoolingStartComponent } from './modules/participant/pages/schooling-start/schooling-start.component'

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'SchulungSeite',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: SchoolingPageComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'SchulungStart',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: SchoolingStartComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'SchulungStatistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: SchoolingStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class SchoolingRoutingModule { }
