<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].Aktionshistorie }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].Aktionshistorie }}"
                      infotextShorten="Aktionshistorie"
                      fieldsAction="/api/Aktionshistorie/getAktionshistorieFields"
                      listAction="/api/Aktionshistorie/getAktionshistorie"
                      loaderName="leasCampaignsHistoryLoader"
                      [execClickFunctions]="execClickFunctions"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [footer]="false"
                      defaultSorting="AnzeigeVon DESC"></mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams"  class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
