import { OnDestroy, SecurityContext, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, Observable, Subject } from 'rxjs';

/**
 * This class provides the functions to
 * fetch the content of a svg for the
 * game parts.
 */
@Injectable({
  providedIn: 'root'
})
export class GameSvgFetcherService implements OnDestroy {

  private _fetchedSvgs: { [key: string]: SafeHtml } = {};
  private _getSvgContentSubscription: Subscription | undefined;

  constructor(
    private _httpClient: HttpClient,
    private _sanitizer: DomSanitizer
  ) { }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getSvgContentSubscription !== 'undefined') {
      this._getSvgContentSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the svg and returns its
   * content.
   */
  fetchSvg(svgNameAndPath: string): Observable<SafeHtml> {
    const svgContentSubject = new Subject<SafeHtml>();

    if (typeof this._fetchedSvgs[svgNameAndPath] !== 'undefined') {
      setTimeout(() => {
        svgContentSubject.next(this._fetchedSvgs[svgNameAndPath]);
      }, 150);
    } else {
      setTimeout(() => {
        this._getSvgContentSubscription = this._httpClient.get(`/assets/${svgNameAndPath}.svg`, { responseType: 'text' }).subscribe((svgContent: any) => {
          this._fetchedSvgs[svgNameAndPath] = this._sanitizer.bypassSecurityTrustHtml(svgContent);
          svgContentSubject.next(this._sanitizer.bypassSecurityTrustHtml(svgContent));
        });
      }, 150);
    }

    return svgContentSubject.asObservable();
  }
}
