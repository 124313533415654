<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async) && wlLoaded" class="layout-container">
  <div *ngIf="mpSettings.settings['DisplaySettings'].ShowBreadcrumbs" class="breadcrumb">
    <a class="breadcrumb-item" href="#/{{role}}/Home">{{ ls.locs['loc'].Home }}</a>
    <i class="breadcrumb-splitter"></i>
    <a class="breadcrumb-item" href="#/{{role}}/Wunschliste">{{ ls.locs['loc'].Wunschliste }}</a>
  </div>
  <h1>{{ ls.locs['loc'].Wunschliste }}</h1>
  <p class="teaser">{{ ls.locs['loc'].Wunschlistentext1 }}</p>
  <div class="no-items" [hidden]="data && data['length'] > 0">{{ ls.locs['loc'].WunschlisteLeer }}</div>
  <div #shopGrid ng-model="data" id="shop-grid-outer" [hidden]="!data || (data && data['length'] <= 0)">
    <div #shopGridCards id="shop-cards" class="css-grid _shop">
      <mp-core-wishlist-card [addToShoppingbasketFunc]="addToShoppingbasket.bind(this)" [deleteItemFunc]="deleteItem.bind(this)" class="grid-card-s" *ngFor="let e of data" [wishCard]="e"></mp-core-wishlist-card>
    </div>
  </div>
</div>
