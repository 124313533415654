import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[isLast]'
})

/**
 * This class provides a directive to handle event
 * after render of html element.
 */
export class MpAfterRenderItemDirective {

  @Input() isLast: boolean = false;
  @Output() afterRenderItem: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    if (this.isLast) {
      this.afterRenderItem.emit(true);
    }
  }

}
