import { Component, OnInit, Input, ElementRef, ViewEncapsulation, SecurityContext } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
* This class provides the accordion data
* and functions.
*/
@Component({
  selector: 'mp-core-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent implements OnInit {
  @Input() public accToggle!: boolean;
  @Input() public accAlwaysOpen: boolean = false;
  @Input() public accDataExpression!: Function;
  @Input() public accDataModel: any;
  @Input() public sidebarAcc: boolean = false;
  @Input() public accOpenOnLoad: boolean = false;
  @Input() public hidePaddingBottom: boolean = false;
  @Input() public dataScrollArea: any;
  @Input() public dataScrollItemHeight: number = 0;
  @Input() public hasTranscluded: boolean = false;

  public dataModelExists: boolean = false;
  public openAcc: Array<any> = [];
  public intervCnt: number = 0;
  public expressionCheck: any;
  public buttonFuncTriggered: boolean = false;
  public accDataLoaded: boolean = false;
  public trustedHtml: any;
  public isIframe: boolean = true;
  public iframeStr: string = '';
  public safeSrc: SafeResourceUrl | undefined;

  constructor(
    private _elementRef: ElementRef,
    private sanitizer: DomSanitizer
  ) { }

  /**
  * Gets the data for accordions
  * and handles the datamodel.
  */
  ngOnInit(): void {
    this.dataScrollArea = this._elementRef.nativeElement.querySelectorAll('data-scroll-area');
    this.dataScrollItemHeight = this._elementRef.nativeElement.querySelectorAll('data-scroll-item-height');

    if (typeof this.accDataModel !== 'undefined') {
      if (this.accDataModel.length > 0) {
        this.dataModelExists = true;
        this.accDataModel.forEach((element: any) => {
          
          if (element.Text.startsWith('<iframe')) {
            let srcStr = '';
            let attributes = element.Text.match(/[\w-]+="[^"]*"/g);
            attributes.forEach((attr: string) => {
              if (attr.startsWith('src')) {
                srcStr = attr;
                srcStr = srcStr.replace('\\', '');
                srcStr = srcStr.substring(srcStr.indexOf("\"") + 1, srcStr.lastIndexOf("\""));
                this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(srcStr);
              }
            });
            this.isIframe = true;
          }
        });
      }
    }

    if (!this.dataModelExists) {
      this.expressionCheck = setInterval(() => {
        if (this.intervCnt === 20) {
          this.stopExpressionCheck();
        }

        if (typeof this.accDataExpression() !== 'undefined') {
          if (this.accDataExpression().length > 0) {
            this.stopExpressionCheck();
            this.checkIfAccsOpen(false);
            this.accDataLoaded = true;
          }
        }
        this.intervCnt++;
      }, 50);
    }
  }

  /**
  * Clears interval
  */
  stopExpressionCheck() {
    clearInterval(this.expressionCheck);
  }

  /**
   * Runs headline function
   */
  executeHeadlineFunc(callback?: Function, funcParameter?: any) {
    if (typeof callback !== 'undefined') {
      this.buttonFuncTriggered = true;

      if (typeof funcParameter !== 'undefined') {
        callback(funcParameter);
      } else {
        callback();
      }
    }
  }

  /**
   * Collapses accordion
   */
  toggleAccordion(accId: any): void {
    if (this.accAlwaysOpen) {
      return;
    }

    if (this.buttonFuncTriggered) {
      this.buttonFuncTriggered = false;
      return;
    }

    if (this.accToggle && typeof (this.accToggle) !== 'undefined') {
      this.openAcc.forEach((value: any, openId: any) => {
        if (openId !== accId) {
          this.openAcc[openId] = false;
        }
      });

      Object.keys(this.openAcc).forEach((openId: any) => {
        if (openId !== accId) {
          this.openAcc[openId] = false;
        }
      });
    }

    this.openAcc[accId] = !this.openAcc[accId];
  }

  /*
  * Sanitizer for HTML.
  */
  trustAsHtml(str: string): string {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Checks if accordion is open
   */
  checkIfAccsOpen(withDataModel: any) {
    if (!withDataModel) {
      this.accDataExpression().forEach((acc: any) => {
        if (this.accAlwaysOpen || acc.accOpenOnLoad === true) {
          this.openAcc[acc.accID] = true;
        } else {
          this.openAcc[acc.accID] = false;
        }
      });
    } else if (withDataModel) {
      this.accDataModel.forEach((acc: any, index: any) => {
        if (this.accAlwaysOpen || acc.accOpenOnLoad === true) {
          this.openAcc[index] = true;
        } else {
          if (acc.offen) {
            this.openAcc[index] = true;
          } else {
            this.openAcc[index] = false;
          }
        }
      });
    }
  }

}
