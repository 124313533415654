import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';

/**
 * This class provides the functions for
 * for the account.
 */
@Component({
  selector: 'mp-core-account',
  templateUrl: './account.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit {

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
  ) { }


  ngOnInit(): void { }

}
