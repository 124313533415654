import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';

@Component({
  selector: 'mp-core-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  private _key: string = '';
  public message: string = '';

  constructor(
    public ls: MpLocalizationService,
    public mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    this._route.queryParams.subscribe((params: any) => {
      this._key = params['key'];

      var newsletterKey = { Key: this._key };

      this._apiService.postRequest('/api/Newsletter/SetDoubleOptIn', newsletterKey).subscribe((data: any) => {
          this.message = data.Message;
          if (data.Result === 'OK')
            this.mpMessaging.openSuccessPanel(data.Message);
          else
            this.mpMessaging.openWarningPanel(data.Message);
          this._router.navigate(['/']);
        },
        (error: any) => {
          this.mpMessaging.openWarningPanel(error.Message);
          this._router.navigate(['/']);
        });
    });
  }

}
