import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides a sidebar with a
 * calendar to select a date and a time.
 */
@Component({
  selector: 'mp-core-sidebar-date-time',
  templateUrl: './sidebar-date-time.component.html',
  styleUrls: ['./sidebar-date-time.component.scss']
})
export class SidebarDateTimeComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {
    date: null
  };
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public yearRange: any[] = [];
  public timeHours: any[] = [];
  public timeMinutes: any[] = [];

  private _now: moment.Moment | undefined;
  private _baseDate: moment.Moment | undefined;
  private _minYearDif: number = 0;
  private _maxYearDif: number = 100;
  private _defaultYearDif: number = 0;
  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(public ls: MpLocalizationService, public mpSidebar: MpSidebarService) { }

  /**
   * Watches for sidebar params changes
   * and triggers updating year and year range.
   * Sets the current date and time. Sets
   * the time (hours and minutes).
   */
  ngOnInit(): void {
    this._now = moment();
    this._baseDate = moment.utc([1900, 0, 1]);

    for (let loopCount = 0; loopCount <= 24; loopCount++) {
      this.timeHours.push({
        text: this._baseDate.clone().local().hour(loopCount).format('HH'),
        value: loopCount
      });
    }

    for (let loopCount = 0; loopCount <= 60; loopCount++) {
      this.timeMinutes.push({
        text: this._baseDate.clone().local().minute(loopCount).format('mm'),
        value: loopCount
      });
    }

    this._setYearRange();
    this.yearChange();

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this._setYearRange();
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Calculates and sets the range of the
   * slectable years.
   */
  private _setYearRange(): void {
    if (typeof this.mpSidebar.params['minYearDif'] !== 'undefined') {
      this._minYearDif = this.mpSidebar.params['minYearDif'];
    }

    if (typeof this.mpSidebar.params['maxYearDif'] !== 'undefined') {
      this._maxYearDif = this.mpSidebar.params['maxYearDif'];
    }

    if (typeof this.mpSidebar.params['defaultYearDif'] !== 'undefined') {
      this._defaultYearDif = this.mpSidebar.params['defaultYearDif'];
    }

    if (this._defaultYearDif > this._maxYearDif) {
      this._defaultYearDif = this._maxYearDif;
    }

    if (this._defaultYearDif < this._minYearDif) {
      this._defaultYearDif = this._minYearDif;
    }

    if (typeof this._now === 'undefined') {
      this._now = moment();
    }

    const minYear = this._now.year() - this._maxYearDif;
    const maxYear = this._now.year() - this._minYearDif;
    this.yearRange = [];

    for (let loopCnt = minYear; loopCnt <= maxYear; loopCnt++) {
      this.yearRange.push(moment().set('year', loopCnt).year());
    }

    const date = this.selection['date'];
    const hours = (date === 'undefined' ? 0 : (this.selection['timeHours'] ? date.local().hours() : 0));
    const minutes = (date === 'undefined' ? 0 : (this.selection['timeMinutes'] ? date.local().minutes() : 0));

    this.selection = Object.assign(this.selection, {
      year: typeof this.mpSidebar.params['date'] !== 'undefined' ? this.mpSidebar.params['date'].year() : this._now.year() - (typeof this.mpSidebar.params['age'] !== 'undefined' ? this.mpSidebar.params['age'] : this._defaultYearDif),
      date: typeof this.mpSidebar.params['date'] !== 'undefined' ? this.mpSidebar.params['date'] : date,
      timeHours: typeof this.mpSidebar.params['date'] !== 'undefined' ? this.mpSidebar.params['date'].local().hours() : hours,
      timeMinutes: typeof this.mpSidebar.params['date'] !== 'undefined' ? this.mpSidebar.params['date'].local().minutes() : minutes,
      initDate: typeof this.mpSidebar.params['initDate'] !== 'undefined' ? this.mpSidebar.params['initDate'] : null
    });

    if (this.selection['initDate'] && this.selection['date'].isSame(moment.utc([1900, 0, 1]))) {
      this.selection['date'] = this.selection['initDate'];
      this.selection['year'] = this.selection['initDate'].year();
    }
  }

  /**
   * Triggered, when year selection is changed.
   * Sets the new min and max dates and changes
   * the selected day, if year is not leap year.
   */
  yearChange(): void {
    this.minDate = moment([this.selection['year'], 0, 1]);
    this.maxDate = moment([this.selection['year'], 11, 31, 23, 59]);

    if (typeof this._now === 'undefined') {
      this._now = moment();
    }

    if (this.selection['year'] == this._now.year() && typeof this.mpSidebar.params['maxDate'] !== 'undefined') {
      this.maxDate = this.mpSidebar.params['maxDate'];
    }

    if (this.selection['year'] == this._now.year()) {
      this.minDate = moment();
    }

    const newYear = moment(this.selection['year'] + '-01-01');
    const isLeapYear = newYear.isLeapYear;

    if (typeof this.selection['date'] !== 'undefined' && this.selection['date'] !== null) {
      const month = this.selection['date'].month();
      const day = this.selection['date'].date();

      if (!isLeapYear && month === 1 && day === 29) {
        this.selection['date'].date(28);
      }

      this.selection['date'].year(this.selection['year']);
    }
  }

  /**
   * Delets the selected date and time,
   * and updates the sidebar params.
   */
  delete(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['date'] = moment.utc([1900, 0, 1]);
    this.mpSidebar.params['timeHours'] = 0;
    this.mpSidebar.params['timeMinutes'] = 0;
    this.mpSidebar.params['text'] = this.mpSidebar.params['noDateText'];

    this.selection = {
      date: null
    };

    this.mpSidebar.changeParams(this.mpSidebar.params);
    this.mpSidebar.close();
  }

  /**
   * Merges the selection with the sidebar
   * params and triggers the save function,
   * if exists.
   */
  save(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['date'] = this.selection['date'].clone();
    this.mpSidebar.params['date'].local().hours(this.selection['timeHours']);
    this.mpSidebar.params['date'].local().minutes(this.selection['timeMinutes']);
    this.mpSidebar.params['setByInit'] = false;
    this.mpSidebar.params['timeHours'] = this.selection['timeHours'];
    this.mpSidebar.params['timeMinutes'] = this.selection['timeMinutes'];
    this.mpSidebar.params['text'] = this.mpSidebar.params['date'].isBefore(moment()) && this.mpSidebar.params['setPastAsSofort']
      ? this.ls.locs['loc'].Sofort
      : this.mpSidebar.params['date'].local().format('DD.MM.YYYY HH:mm');

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

  /**
   * Sets date and time to the currently
   * moment.
   */
  setDirectly(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params['date'] = moment();
    this.mpSidebar.params['timeHours'] = this.mpSidebar.params['date'].local().hours();
    this.mpSidebar.params['timeMinutes'] = this.mpSidebar.params['date'].local().minutes();
    this.mpSidebar.params['text'] = this.mpSidebar.params['setPastAsSofort'] ? this.ls.locs['loc'].Sofort : this.mpSidebar.params['date'].local().format('DD.MM.YYYY HH:mm');

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);
    this.mpSidebar.close();
  }

}
