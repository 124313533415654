import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { FormatPipe } from '@core/pipes/format.pipe'; 
import { IntegerPipe } from '@core/pipes/integer.pipe';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';
import { RkModule } from '@rk/rk.module';

import { RkzzRoutingModule } from './rkzz-routing.module';
import { RkzzComponent } from './rkzz.component';
import { TcAdditionalPaymentPayplaceComponent } from './components/tc-additional-payment-payplace/tc-additional-payment-payplace.component';
import { TcAdditionalPaymentPayplacePaypalComponent } from './components/tc-additional-payment-payplace/tc-additional-payment-payplace-paypal/tc-additional-payment-payplace-paypal.component';
import { TcAdditionalPaymentPayplaceDebitcardComponent } from './components/tc-additional-payment-payplace/tc-additional-payment-payplace-debitcard/tc-additional-payment-payplace-debitcard.component';
import { TcAdditionalPaymentPayplaceCreditcardComponent } from './components/tc-additional-payment-payplace/tc-additional-payment-payplace-creditcard/tc-additional-payment-payplace-creditcard.component';
import { HotelCheckAdditionalPaymentDirective } from './modules/participant/pages/hotel-check-additional-payment/hotel-check-additional-payment.directive';
import { TcAdditionalPaymentSliderDirective } from './directives/tc-additional-payment-slider.directive';
import { HotelCheckAdditionalPaymentComponent } from './modules/participant/pages/hotel-check-additional-payment/hotel-check-additional-payment.component';
import { HotelConfirmationAdditionalPaymentComponent } from './modules/participant/pages/hotel-confirmation-additional-payment/hotel-confirmation-additional-payment.component';
import { FlightConfirmationAdditionalPaymentComponent } from './modules/participant/pages/flight-confirmation-additional-payment/flight-confirmation-additional-payment.component';
import { FlightCheckAdditionalPaymentComponent } from './modules/participant/pages/flight-check-additional-payment/flight-check-additional-payment.component';
import { FlightCheckAdditionalPaymentDirective } from './modules/participant/pages/flight-check-additional-payment/flight-check-additional-payment.directive';
import { RentalConfirmationAdditionalPaymentComponent } from './modules/participant/pages/rental-confirmation-additional-payment/rental-confirmation-additional-payment.component';
import { RentalCheckAdditionalPaymentComponent } from './modules/participant/pages/rental-check-additional-payment/rental-check-additional-payment.component';
import { RentalCheckAdditionalPaymentDirective } from './modules/participant/pages/rental-check-additional-payment/rental-check-additional-payment.directive';

@NgModule({
  declarations: [
    RkzzComponent,
    TcAdditionalPaymentPayplaceComponent,
    TcAdditionalPaymentPayplacePaypalComponent,
    TcAdditionalPaymentPayplaceDebitcardComponent,
    TcAdditionalPaymentPayplaceCreditcardComponent,
    HotelCheckAdditionalPaymentDirective,
    TcAdditionalPaymentSliderDirective,
    HotelCheckAdditionalPaymentComponent,
    HotelConfirmationAdditionalPaymentComponent,
    FlightConfirmationAdditionalPaymentComponent,
    FlightCheckAdditionalPaymentComponent,
    FlightCheckAdditionalPaymentDirective,
    RentalConfirmationAdditionalPaymentComponent,
    RentalCheckAdditionalPaymentComponent,
    RentalCheckAdditionalPaymentDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    RkzzRoutingModule,
    FormsModule,
    CarouselModule,
    NgxSliderModule,
    RkModule
  ],
  exports: [
    RkzzComponent
  ],
  providers: [
    FormatPipe,
    IntegerPipe
  ],
  bootstrap: [
    RkzzComponent
  ]
})
export class RkzzModule { }

@NgModule({})
export class RkzzSharedModule {
  static forRoot(): ModuleWithProviders<RkzzModule> {
    return {
      ngModule: RkzzModule,
      providers: []
    }
  }
}
