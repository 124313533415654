<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].DrohenderPunkteverfallManager }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].DrohenderPunkteverfallManager }}"
                 infotextShorten="DrohenderPunkteverfall"
                 fieldsAction="/api/Statistik/getDrohenderPunkteverfallFields"
                 listAction="/api/Statistik/getDrohenderPunkteverfall"
                 loaderName="imminentPointsDecayLoader"
                 [paging]="true"
                 [footer]="true"
                 defaultSorting="Verfallspunkte DESC"
                 [recLoadedFunc]="recLoadedFunc"
                 [showDatePicker]="true"
                 [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
