import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Event, NavigationStart } from '@angular/router';

import { MpSidebarService } from './../../components/sidebar/mp-sidebar.service';
import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpSettingsService } from './../../services/mp-settings.service';
import { MpMetaService } from './../../services/mp-meta.service';
import { MpTitleService } from './../../services/mp-title.service';
import { ApiService } from './../..//services/api.service';
import { MatomoService } from './../../services/matomo.service';
import { CookieService } from '@core/services/cookie.service';

/**
 * This class provides data and
 * functions for the first login
 * component.
 */
@Component({
  selector: 'mp-core-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: [
    './first-login.component.scss',
    './../../components/input/styles/input.scss',
    './../../components/sidebar/styles/sidebar.scss',
    './../../components/sidebar/styles/sidebar-extend.scss',
    './../../components/card/styles/card.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FirstLoginComponent implements OnInit, OnDestroy {

  public hamburgerOpen: boolean = false;
  public userMenuItems: Array<any> = [];
  public rootLanguageData: { [key: string]: any } = [];
  public showUserMenu: boolean = false;
  public userMenuOpen: boolean = false;
  public navbarIsSticky: boolean = false;

  private _participantData: { [key: string]: any } = [];
  private _lastScrollTop: number = 0;
  private _scrollDelta: number = 5;
  private _sticked: boolean = false;
  private _creditsInitialHeight: string = '';
  private _settingsSubscription: Subscription | undefined;
  private _locsSubscription: Subscription | undefined;
  private _apiParticipantSubscription: Subscription | undefined;
  private _apiLanguageSubscription: Subscription | undefined;
  private _routeChangeSubscription: Subscription | undefined;

  constructor(
    public mpSidebar: MpSidebarService,
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public cookieService: CookieService,
    private _mpMetaService: MpMetaService,
    private _titleService: MpTitleService,
    private _apiService: ApiService,
    private _router: Router,
    private _matomoService: MatomoService
  ) { }

  /**
   * Triggers the functions to set meta tags,
   * and some other 'head'-tags, gets menus
   * and languages.
   */
  ngOnInit(): void {
    this._apiParticipantSubscription = this._apiService.postRequest('/api/Teilnehmer/getTN', {}).subscribe((data: any) => {
      this._participantData = data.Records[0];
      this._setUserMenuItems();
    },
      (error: any) => {
        console.log(error.message);
      });

    this._apiLanguageSubscription = this._apiService.getRequest('/api/Language/GetLanguagesForDropdown').subscribe((data: any) => {
      this.rootLanguageData = data.Records[0];
    },
      (error: any) => {
        console.log(error.message);
      });

    this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags !== null) {
          this._mpMetaService.setTagsBySettings(this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags);
        }

        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== null) {
          this._titleService.setTitleFromStringAndLoc(this.mpSettings.settings['NetcentiveCoreSettings'].Shopname, 'MeinPostfach');
        } else {
          this._titleService.setTitleFromLoc('MeinPostfach');
        }

        if (this.mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
          this._matomoService.setMatomo();
        }

        if (typeof this._settingsSubscription !== 'undefined') {
          this._settingsSubscription.unsubscribe();
        }
      }
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this.ls.getLocalization();
    }

    this._mpMetaService.setBasicTags();
    this._mpMetaService.setFavicon();
    this._mpMetaService.setThemeColorTag();

    this._routeChangeSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.hamburgerOpen = false;
        this.navbarIsSticky = false;
      }
    });

    window.addEventListener('scroll', this._handleNavAndCredits.bind(this), false);
  }

  /**
   * Checks whether or not there are subscriptions,
   * and unsubscribes them.
   */
  ngOnDestroy(): void {
    if (typeof this._settingsSubscription !== 'undefined') {
      this._settingsSubscription.unsubscribe();
    }

    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }

    if (typeof this._apiParticipantSubscription !== 'undefined') {
      this._apiParticipantSubscription.unsubscribe();
    }

    if (typeof this._apiLanguageSubscription !== 'undefined') {
      this._apiLanguageSubscription.unsubscribe();
    }

    if (typeof this._routeChangeSubscription !== 'undefined') {
      this._routeChangeSubscription.unsubscribe();
    }
  }

  /**
   * Replaces the old (AnuglarJS) url structure
   * by the new (Angular) url structure.
   */
  private _replaceUrlStructure(mainMenuItemsRaw: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
    mainMenuItemsRaw.forEach((menuItem: { [key: string]: any }) => {
      if (typeof menuItem['href'] !== 'undefined' && menuItem['href'] !== '' && menuItem['href'] !== null) {
        menuItem['href'] = menuItem['href'].replace('#/', '#/tn/');
      }

      if (typeof menuItem['children'] !== 'undefined' && menuItem['children'].length > 0) {
        menuItem = this._replaceUrlStructure(menuItem['children']);
      }
    });

    return mainMenuItemsRaw;
  }

  /**
   * Sets the data for the user menu.
   */
  private _setUserMenuItems(): void {
    if (Object.keys(this._participantData).length > 0 && Object.keys(this.ls.locs).length > 0) {
      this.userMenuItems = [
        {
          hasStatusIcon: true,
          icon: '#',
          statusId: this._participantData['StatusID'],
          text: this._participantData['Vorname'] + ' ' + this._participantData['Name'],
          hasLogout: true,
          ShowOnMobile: true,
          children: [
          ]
        }
      ];

    }
  }

  /**
   * Shows and hides the user menu.
   */
  toggleUserMenu(): void {
    this.showUserMenu = !this.showUserMenu;
  }

  /**
   * Opens the hambuerger menu.
   */
  openHamburger(): void {
    this.hamburgerOpen = true;
  }

  /**
   * Closes the hamburger and user menu.
   */
  closeHamburger(): void {
    this.hamburgerOpen = false;
    this.userMenuOpen = false;
  }

  /**
   * Handles the stick / visiblity behavior of the
   * navbar and the credits bar, when the user
   * scrolls the page up / down.
   */
  private _handleNavAndCredits(): void {
    const scrollTop = window.scrollY;
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');
    const headerHeight = header !== null ? header.getBoundingClientRect().height : 0;

    if (headerHeight < scrollTop && navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv === null) {
        const divElem = document.createElement('div');
        divElem.style.height = navbar.getBoundingClientRect().height + 'px';
        divElem.id = 'sticky-ghost-div';
        navbar.insertAdjacentElement('afterend', divElem);
      }

      if (!navbar.classList.contains('fix')) {
        navbar.classList.add('fix');
      }

      if (!navbar.classList.contains('hl-sticky')) {
        navbar.classList.add('hl-sticky');
      }

      this.navbarIsSticky = true;
    } else if (navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv !== null) {
        ghostDiv.remove();
      }

      if (navbar.classList.contains('fix')) {
        navbar.classList.remove('fix');
      }

      if (navbar.classList.contains('hl-sticky')) {
        navbar.classList.remove('hl-sticky');
      }

      this.navbarIsSticky = false;
    }

    const filterNavBaseline = document.querySelector('.filter-nav-baseline');
    const filterNav = document.querySelector('.filter-nav');

    if (filterNav !== null) {
      if (filterNav.classList.contains('no-header')) {
        if (headerHeight < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      } else {
        const mainNav = document.querySelector('.mainNav');
        const mainNavHeight = mainNav !== null ? mainNav.getBoundingClientRect().height : 0;

        if (filterNavBaseline !== null && filterNavBaseline.getBoundingClientRect().top - mainNavHeight - filterNav.getBoundingClientRect().height < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      }
    }

    const credits = document.getElementById('credits');
    // @ts-ignore
    const userProfileNav = document.getElementById('user-profile__navbar');
    const currScrollTop = window.scrollY;

    if (Math.abs(this._lastScrollTop - currScrollTop) <= this._scrollDelta) {
      return;
    }

    if (navbar !== null) {
      const navbarHeight = window.getComputedStyle(navbar).getPropertyValue('height');

      if (typeof navbarHeight !== 'undefined' && navbarHeight !== null) {
        if (currScrollTop > this._lastScrollTop && currScrollTop > parseInt(navbarHeight.replace('px', ''))) {
          const placeholderStickyDivs = document.querySelectorAll('#placeholder-sticky-div');

          // @ts-ignore
          if (this._sticked && placeholderStickyDivs.lenght > 0 && header !== null && header.classList.contains('stickyNavbar')) {
            placeholderStickyDivs.forEach(placeholderStickyDiv => {
              placeholderStickyDiv.remove();
            });
          }

          if (credits !== null) {
            if (!credits.classList.contains('credits-hidden')) {
              if (userProfileNav !== null) {
                if (userProfileNav.classList.contains('credits-shown')) {
                  userProfileNav.classList.remove('credits-shown');
                }
              }

              if (header !== null) {
                if (!header.classList.contains('stickyNavbar') && !this._sticked) {
                  const stickyElem = document.querySelector('.hl-sticky');

                  if (stickyElem !== null) {
                    stickyElem.insertAdjacentHTML('afterend', `<div id="placeholder-sticky-div" style="height:0;clear:both;top:-${(parseInt(navbarHeight.replace('px', '')) + 8)}px;position:relative;"></div>`);
                    this._sticked = true;
                  }
                }
              }

              credits.classList.add('credits-hidden');
            }
          }
        } else {
          if (credits !== null) {
            if (credits.classList.contains('credits-hidden')) {
              if (userProfileNav !== null) {
                if (userProfileNav.classList.contains('is-sticky')) {
                  if (!userProfileNav.classList.contains('credits-shown')) {
                    userProfileNav.classList.add('credits-shown');
                  }
                } else {
                  if (userProfileNav.classList.contains('credits-shown')) {
                    userProfileNav.classList.remove('credits-shown');
                    this._sticked = false;
                  }
                }
              }

              credits.classList.remove('credits-hidden');
            }
          }
        }
      }

      this._lastScrollTop = currScrollTop;
    }
  }
}
