import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './../services/auth.service';

/**
 * This class provides the AuthInterceptor, that
 * modifies the request header.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _authService: AuthService) { }

  /**
   * Adds the authorization header, if
   * token is set. Sets some cach related
   * headers, if users role is "Teilnehmer".
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authRequest = request;
    const token = this._authService.getToken();

    if (token !== '') {
      authRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });

      if (this._authService.getRole() === 'Teilnehmer') {
        authRequest.headers.set('Cache-Control', 'no-cache');
        authRequest.headers.set('Pragma', 'no-cache');
        authRequest.headers.set('If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT');
      }
    }

    return next.handle(authRequest);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
