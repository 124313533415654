import { Directive, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, Injector, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Options } from '@angular-slider/ngx-slider';

import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpDebounceService } from '@core/services/mp-debounce.service';
import { TcAdditionalPaymentService } from './../../../../services/tc-additional-payment.service';

import { FormatPipe } from '@core/pipes/format.pipe';
import { Provider } from './../../../../services/interfaces/rk-provider';

/**
 * This class provides the additional payment
 * functions for the flight check page.
 */
@Directive({
  selector: '[mpRkzzFlightCheckAdditionalPayment]',
  exportAs: 'mpRkzzFlightCheckAdditionalPayment'
})
export class FlightCheckAdditionalPaymentDirective implements OnDestroy, OnChanges {

  @Input() public protectionKey: string = '';
  @Input() public checkingParametersWishes: string = '';
  @Input() public checkingParameters: { [key: string]: any } = {};
  @Input() public travelProtectionOptions: { [key: string]: any } = {};
  @Input() public offer: { [key: string]: any } = {};
  @Input() public pointWorth: number = 0;
  @Input() public routeParams: { [key: string]: any } = {};
  @Input() public totalPoints: number = 0;
  @Input() public bookFunc: any;
  @Input() public isValid: boolean = true;
  @Input() public validate: any;
  @Input() public travelProtectionPoints: number = 0;

  @Output() protectionKeyChange = new EventEmitter<string>();
  @Output() checkingParametersChange = new EventEmitter<{ [key: string]: any }>();
  @Output() offerChange = new EventEmitter<{ [key: string]: any }>();
  @Output() totalPointsChange = new EventEmitter<number>();
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() travelProtectionPointsChange = new EventEmitter<number>();

  public provider: Array<Provider> = [];
  public showAdditionalPayment: boolean = false;
  public amountValue: number = 0;
  public additionalPaymentRequired: boolean = false;
  public injector: Injector | any;
  public lockButton: boolean = false;
  public getAmount: any;
  public getAmountFunc: any;
  public orderNotPossibleText: string = '';
  public updateAdditionalPaymentgValues = this._updateAdditionalPaymentgValues.bind(this);
  public redemptionlimitWarning: string = '';

  private _baseMaxRedeemedPoints: number = 0;
  private _missingPoints: number = 0;
  private _updateAmount: any;
  private _lastRequestedAmount: number = 0;
  private _selectedProvider: Provider | undefined;
  private _getMenudataSubscription: Subscription | undefined;
  private _getAmountSubscription: Subscription | undefined;
  private _providerSubscription: Subscription | undefined;

  /**
   * Creates the get and update amount
   * functions, and gets the provider.
   */
  constructor(
    private _ls: MpLocalizationService,
    private _mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpSidebar: MpSidebarService,
    private _mpMessaging: MpMessagingService,
    private _injector: Injector,
    public tcAdditionalPaymentService: TcAdditionalPaymentService,
    private _mpDebounce: MpDebounceService,
    private _router: Router,
    private _formatPipe: FormatPipe
  ) {
    this.getAmount = this._mpDebounce.debounce(() => {
      if (this.offer['EingeloestePunkte'] !== parseInt(this.offer['EingeloestePunkteText'])) {
        this.offer['EingeloestePunkte'] = parseInt(this.offer['EingeloestePunkteText']);
      }

      const requestedAmount = this.totalPoints - this.offer['EingeloestePunkte'];
      this._lastRequestedAmount = requestedAmount;

      this._getAmountSubscription = this._apiService.getRequest('/api/RkZuzahlung/GetBetragNurFlug', false, {
        params: {
          searchId: this.routeParams['sid'],
          outboundId: this.routeParams['out'],
          returnId: this.routeParams['ret'],
          reiseversicherung: this.checkingParameters['Reiseschutz'],
          zuzahlungPunkte: requestedAmount,
          Gesamtpunkte: this.totalPoints
        }
      }).subscribe((data: any) => {
        if (data.Result === 'OK' && requestedAmount === this._lastRequestedAmount) {
          this._updateAdditionalPaymentgValues(data);
        }

        if (typeof this._getAmountSubscription !== 'undefined') {
          this._getAmountSubscription.unsubscribe();
        }
      });
    }, 750);

    this._updateAmount = this._mpDebounce.debounce(() => {
      this.showAdditionalPayment = this.offer && (this.offer['MaxEingeloestePunkte'] <= this.totalPoints || this._mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung) && this.offer['CanZuzahlen'];

      if (this.totalPoints !== 0)
        this.lockButton = !this.showAdditionalPayment;

      if (this.showAdditionalPayment) {
        if (typeof this.getAmount !== 'undefined') {
          this.getAmount();
        }
      }
    }, 300);

    this._providerSubscription = this.tcAdditionalPaymentService.providerChange.subscribe((changed: boolean) => {
      if (changed) {
        this.provider = this.tcAdditionalPaymentService.getProvider();
        this.setType(this.provider && this.provider[0].Key);
        this._checkMinPayment();
      }
    });

    this.tcAdditionalPaymentService.refreshProvider();
    this.getAmountFunc = this.getAmount.bind(this);
    this.orderNotPossibleText = this._ls.locs['locReisekonfigurator'].BestellungNichtMoeglichText.replace(/\{0\}/g, this._mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getMenudataSubscription !== 'undefined') {
      this._getMenudataSubscription.unsubscribe();
    }

    if (typeof this._getAmountSubscription !== 'undefined') {
      this._getAmountSubscription.unsubscribe();
    }

    if (typeof this._providerSubscription !== 'undefined') {
      this._providerSubscription.unsubscribe();
    }
  }

  /**
   * Watches for changes of the input
   * paramaters.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['pointWorth'] !== 'undefined') {
      if (changes['pointWorth'].isFirstChange() || typeof changes['pointWorth'].previousValue !== 'undefined' && typeof changes['pointWorth'].currentValue !== 'undefined' && changes['pointWorth'].previousValue !== changes['pointWorth'].currentValue) {
        this._updatePoints();

        if (typeof this._updateAmount !== 'undefined') {
          this._updateAmount();
        }
      }
    }

    if (typeof changes['offer'] !== 'undefined') {
      if (changes['offer'].isFirstChange() || typeof changes['offer'].previousValue !== 'undefined' && typeof changes['offer'].currentValue !== 'undefined' && changes['offer'].previousValue !== changes['offer'].currentValue) {
        this._baseMaxRedeemedPoints = this.offer['MaxEingeloestePunkte'];
        this.showAdditionalPayment = this.offer && (this.offer['MaxEingeloestePunkte'] <= this.totalPoints || this._mpSettings.settings['RkZuzahlungSettings'].ManuelleZuzahlung) && this.offer['CanZuzahlen'];
      }
    }

    if (typeof changes['totalPoints'] !== 'undefined') {
      if (changes['totalPoints'].isFirstChange() || typeof changes['totalPoints'].previousValue !== 'undefined' && typeof changes['totalPoints'].currentValue !== 'undefined' && changes['totalPoints'].previousValue !== changes['totalPoints'].currentValue) {
        if (typeof this._updateAmount !== 'undefined') {
          this._updateAmount();
        }
      }
    }
  }

  /**
   * Sets the travel protection.
   */
  setTravelProtection(val: any): void {
    this.checkingParameters['Reiseschutz'] = val;
    this.checkingParametersChange.emit(this.checkingParameters);
    this._updatePoints();
  }

  /**
   * Sets the additional payment type.
   */
  setType(key: string): void {
    this.tcAdditionalPaymentService.paymentType = key;
  }

  /**
   * Shows the info sidebar.
   */
  showInfoSidebar(): void {
    this._mpSidebar.open('FaqSidebar', {
      choice: {
        ThemaID: this._mpSettings.settings['ZuzahlungSettings'].BezahlungFaqThemenId
      },
      showWithoutFilter: false,
      hideSearchbox: false,
      hideFilter: true,
      title: this._ls.locs['locZuzahlung'].InfoSidebarTitle
    });
  }

  /**
   * Creates the injector for the
   * dynamically loaded (sub-)components.
   */
  private _createInjector() {
    this.injector = Injector.create({
      providers: [
        { provide: 'tcAdditionalPaymentProvider', useValue: this._selectedProvider },
        { provide: 'tcAdditionalCheckingParameter', useValue: this.checkingParameters },
        { provide: 'tcAdditionalPaymentMissingPoints', useValue: this._missingPoints },
        { provide: "tcAdditionalPaymentTotalPoints", useValue: this.totalPoints }
      ],
      parent: this._injector
    });
  }

  /**
   * Stes and returns the injector.
   */
  getInjector(provider: Provider): Injector {
    this._selectedProvider = provider;
    this._missingPoints = this.totalPoints - this.offer['EingeloestePunkte'];
    this._createInjector();
    return this.injector;
  }

  /**
   * Updates the additional payment values.
   */
  private _updateAdditionalPaymentgValues(data: any): void {
    this.offer['ZuzahlungEuro'] = data.Records[0].Betrag;
    this.offer['EingeloestePunkte'] = this.totalPoints - data.Records[0].ZuzahlungPunkte;
    this.offer['EingeloestePunkteText'] = this.offer['EingeloestePunkte'] + '';
    this.amountValue = data.Records[0].BetragValue;
    this.offerChange.emit(this.offer);
    this._checkMinPayment();
  }

  /**
   * Checks the minimum value of the payment.
   */
  private _checkMinPayment(): void {
    if (this.tcAdditionalPaymentService.paymentType === '') {
      this.tcAdditionalPaymentService.paymentType = this.provider[0] && this.provider[0].Key;
    }

    if (this.provider.length === 0)
      return;

    this._selectedProvider = this.provider.find((prvdr: any) => {
      return prvdr['Key'] === this.tcAdditionalPaymentService.paymentType;
    });

    if (Object.keys(this.offer).length === 0 || this.offer['EingeloestePunkte'] === this.totalPoints)
      return;

    this.provider.forEach((p: any) => {
      p['possible'] = p['MinPaymentAmount'] <= this.amountValue && (p['MaxPaymentAmount'] < 0 || this.amountValue <= p['MaxPaymentAmount']);
    });

    if (this._selectedProvider && !this._selectedProvider['possible']) {
      const possibleProvider = this.provider.find((prvdr: any) => {
        return prvdr['possible'] === true;
      });

      this.tcAdditionalPaymentService.paymentType = typeof possibleProvider === 'undefined' ? '' : possibleProvider['Key'];
    } else if (this.tcAdditionalPaymentService.paymentType === '') {
      const possibleProvider = this.provider[0];
      this.tcAdditionalPaymentService.paymentType = typeof possibleProvider === 'undefined' ? '' : possibleProvider['Key'];
    }
  }

  /**
   * Updates the points.
   */
  private _updatePoints(): void {
    this.protectionKey = '';

    Object.keys(this.travelProtectionOptions).forEach((key: string) => {
      if (this.travelProtectionOptions[key] === this.checkingParameters['Reiseschutz']) {
        this.protectionKey = key;
      }
    });

    this.protectionKeyChange.emit(this.protectionKey)

    if (Object.keys(this.offer).length > 0) {
      let price = this.offer['Punkte'];

      if (this._baseMaxRedeemedPoints === 0) {
        this._baseMaxRedeemedPoints = this.offer['MaxEingeloestePunkte'];
      }

      this.travelProtectionPoints = this.offer['Preise'][this.protectionKey] || 0;
      price += this.travelProtectionPoints;
      const maxRedeemedPoints = this._baseMaxRedeemedPoints + this.travelProtectionPoints;
      const redeemedPoints = this.offer['EingeloestePunkte'] + this.travelProtectionPoints;

      if ((maxRedeemedPoints <= (this.pointWorth || 0) &&
        this._baseMaxRedeemedPoints === this.offer['Punkte']) || this._baseMaxRedeemedPoints === maxRedeemedPoints) {
        this.offer['MaxEingeloestePunkte'] = maxRedeemedPoints;
      }

      if (((redeemedPoints <= this.offer['MaxEingeloestePunkte']) &&
        this._baseMaxRedeemedPoints === this.offer['Punkte']) ||
        (this.offer['EingeloestePunkte'] >= this.offer['Punkte'] && !this.additionalPaymentRequired)) {
        this.offer['EingeloestePunkte'] = this.pointWorth >= price ? price : redeemedPoints;
      }

      if (this.offer['EingeloestePunkte'] > this.offer['MaxEingeloestePunkte'] &&
        !(price < this.pointWorth && this.offer['EingeloestePunkte'] === price)) {
        this.offer['EingeloestePunkte'] = this.offer['MaxEingeloestePunkte'];
      }

      this.additionalPaymentRequired = !(this.offer['CanBePaidWithPoints'] as boolean);

      this.redemptionlimitWarning = this._formatPipe.transform(this._ls.locs['validate'].RedemptionlimitWarning, [this._mpSettings.settings['BestellprozessSettings'].RedemptionlimitWarning]);

      if (this.offer['sliderOptions']) {
        const newSliderOptions: Options = Object.assign({}, (this.offer['sliderOptions'] || {}));
        // @ts-ignore
        newSliderOptions.stepsArray = this.offer.getStepArray(this._mpSettings.settings['ZuzahlungSettings'].ManuelleZuzahlungStep, this.offer, Math.min(price, this.pointWorth), !this.additionalPaymentRequired);
        this.offer['sliderOptions'] = newSliderOptions;
      }

      this.offer['EingeloestePunkteText'] = this.offer['EingeloestePunkte'] + '';
      this.totalPoints = price;
      this.totalPointsChange.emit(this.totalPoints);
      this.travelProtectionPointsChange.emit(this.travelProtectionPoints);
      this.offerChange.emit(this.offer);
    }
  }

  /**
   * Triggers the booking process.
   */
  book(): void {
    this.checkingParameters['Wuensche'] = this.checkingParametersWishes;

    if (this.offer['EingeloestePunkte'] === this.totalPoints) {
      if (typeof this.bookFunc !== 'undefined') {
        this.bookFunc();
      }
    } else {
      if (typeof this.validate !== 'undefined') {
        if (!this.validate()) {
          this.isValid = false;
          this.isValidChange.emit(this.isValid);
          return;
        }
      }

      this.tcAdditionalPaymentService.call(this.tcAdditionalPaymentService.paymentType, this._bookingSuccess.bind(this), this._bookingError.bind(this))
    }
  }

  /**
   * Handles the success callback of the
   * booking function.
   */
  private _bookingSuccess(data: any): void {
    if (data.Message === 'redirect') {
      window.location.href = data.Records[0];
    } else {
      if (data.Result === 'OK') {
        this._mpMessaging.openSuccessPanel(data.Message);
        this._router.navigateByUrl('/');
        this.lockButton = false;
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
        this.lockButton = false;
      }
    }
  }

  /**
   * Handles the error callback of the
   * booking function.
   */
  private _bookingError(error: any): void {
    this.lockButton = true;
  }

}
