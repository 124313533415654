<div id="order-confirmation" class="layout-container order-process" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="border-bottom flex-grid margin-0 flex-align-items-center">
    <div class="flex-col-10 flex-col-8-s flex-col-8-m padding-left-0">
      <i class="svg-icon __clr-info __size-40 __top-4 __done-all margin-bottom-8 margin-right-8 default-cursor-hover float-none-important"></i>
      <h2 class="margin-bottom-0 display-flex flex-align-items-center">{{ ls.locs['loc'].BestellBestaetigungTitel }}</h2>
    </div>
    <div class="flex-col-2 flex-col-4-s flex-col-4-m flex-justify-cont-end padding-right-0 hide-on-small-mobile">
      <a href="#/{{role}}/Home" class="mp-button">{{ ls.locs['loc'].ZurStartseite }}</a>
    </div>
  </div>
  <div id="order-confirmation-header-text" class="margin-top-32 bold" [innerText]="ls.locs['loc'].BestellungHeadertext | format:[orderNo]"></div>
  <mp-core-order-shopping-basket-panel [shoppingCart]="shoppingCart" [cartItems]="cartItems" class="margin-bottom-64"></mp-core-order-shopping-basket-panel>
  <mp-core-info-notification iconPosition="top" infoState="info" class="margin-0 padding-bottom-32">
    <span [innerHTML]="ls.locs['loc'].BestellBestaetigungInfo | format:[role,orderNo]"></span>
  </mp-core-info-notification>
  <div class="bg-color-dark flex-grid margin-0 flex-col-12 flex-justify-cont-center flex-align-items-center padding-top-8 padding-bottom-8 margin-bottom-4 margin-top-24">
    {{ ls.locs['loc'].IhreBestellnummer }}:
    <span class="bold margin-left-8">{{orderNo}}</span>
  </div>
  <div *ngIf="!mpSettings.settings['DisplaySettings'].HideHotline"
       id="order-confirmation-info-block"
       class="bg-color-dark flex-grid margin-0 flex-col-12 flex-justify-cont-center flex-align-items-center padding-top-32 padding-bottom-32 flex-direction-column">
    <p class="text-transform-uppercase font-size-px-24 margin-bottom-4 bold">{{ ls.locs['loc'].FragenZurBestellung }}</p>
    <p>{{ ls.locs['loc'].ServiceZurVerfuegung }}</p>
    <div class="flex-grid margin-left-0 margin-right-0 margin-top-16" id="order-confirmation-info-contacts">
      <div id="order-confirmation-contact" class="flex-col-6 flex-justify-cont-center text-align-center">
        <span class="font-size-px-24" [innerHTML]="ls.locs['loc'].KontaktformularBestellung | format:[role,orderNo]"></span>
      </div>
      <div id="order-confirmation-hotline" class="flex-col-6 flex-align-items-center flex-direction-column">
        <div class="font-size-px-24">{{ ls.locs['loc'].RufenSieUnsAn }}</div>
        <span class="text-color-info font-size-px-24">{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}</span>
        <div class="kontaktServiceTimes">
          <span>{{ ls.locs['loc'].Supporttool_Zeiten }}</span>
        </div>
        <div class="kontaktServiceTimes">
          <span>{{ ls.locs['loc'].SupportKosten }}</span>
        </div>
      </div>
    </div>
  </div>
  <mp-core-micro-copy *ngIf="!mpSettings.settings['DisplaySettings'].HideHotline"
                      infoState="inactive"
                      copyText="{{ ls.locs['loc'].BestellnummerBeiKontaktAngeben }}"
                      class="margin-top-8">
  </mp-core-micro-copy>
  <div class="flex-grid margin-left-0 margin-right-0 flex-align-items-center margin-top-40 hide-on-small-mobile">
    <div class="flex-col-10 flex-col-8-s flex-col-8-m padding-left-0"></div>
    <div class="flex-col-2 flex-col-4-s flex-col-4-m padding-right-0 flex-justify-cont-end">
      <a href="#/{{role}}/Home" class="mp-button">{{ ls.locs['loc'].ZurStartseite }}</a>
    </div>
  </div>
  <div stickyBottom class="bestellung-nav-row floating-container show-on-small-mobile bg-color-white width-full wk-bottom-buttons">
    <a href="#/{{role}}/Home" class="mp-button">{{ ls.locs['loc'].ZurStartseite }}</a>
  </div>
</div>
