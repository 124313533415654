<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].GutscheinStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].GutscheinStatistik }}"
                      infotextShorten="GutscheinStatistik"
                      fieldsAction="/api/Gutschein/getGutscheinStatistikFields"
                      listAction="/api/Gutschein/getGutscheinStatistik"
                      loaderName="leasVoucherStatisticLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [execClickFunctions]="execClickFunctions"
                      [paging]="true"
                      [footer]="false"
                      defaultSorting="EinloeseDatum DESC"
                      [disableCache]="true">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
  <mp-core-modal [show]="editModalOpen" title="{{ ls.locs['locLeas'].KommentarBearbeiten }}" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: closeEditModal}, {text: ls.locs['loc'].Speichern, action: saveComment}]">
    <mp-core-textarea label="{{ ls.locs['loc'].Kommentar }}" mpId="kommentar-modal" [(model)]="voucher['KommentarKomplett']" [hasError]="voucher['errors'] && voucher['errors'].length > 0 ? true : false"></mp-core-textarea>
    <mp-core-form-error *ngIf="voucher['errors'] && voucher['errors'].length > 0" [model]="voucher['errors']"></mp-core-form-error>
  </mp-core-modal>
</div>
