import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the points decay  
 * reporting table.
 */
@Component({
  selector: 'mp-leas-points-decay-reporting',
  templateUrl: './points-decay-reporting.component.html'
})
export class PointsDecayReportingComponent implements OnInit, OnDestroy {

  public quartersLoaded: boolean = false;
  public excelService: string = '/api/Punkteverfall/getPunkteverfallReporting2Excel';
  public loader: MpLoader | null = null;
  public quarter: { [key: string]: any } = {};
  public quarterList: Array<any> = [];
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _leasPointsDecayReportingLoader: MpLoader = {
    name: 'leasPointsDecayReportingLoader',
    params: {}
  };
  private _getQuartersSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader and gets the
   * quarters.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._getQuartersSubscription = this._apiService.getRequest('api/Punkteverfall/getQuartalePunkteverfalReporting').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.quarterList = data.Records;
        this.quarter = data.Records[0];

        this._leasPointsDecayReportingLoader.params = {
          StartDate: this.quarter['Beginn'],
          EndDate: this.quarter['Ende']
        };

        this._mpLoaderService.registerLoader(this._leasPointsDecayReportingLoader);

        this._mpLoaderService.extendLoader('leasPointsDecayReportingLoader', {
          isReady: true
        });

        this.quartersLoaded = true;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQuartersSubscription !== 'undefined') {
      this._getQuartersSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasPointsDecayReportingLoader');
  }

  /**
   * Triggerst the reload of the date
   * for the selected quarter.
   */
  changeQuarter(): void {
    if (this.loader !== null) {
      this.loader.params['StartDate'] = this.quarter['Beginn'];
      this.loader.params['EndDate'] = this.quarter['Ende'];

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

}
