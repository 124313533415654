import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

/**
  * This class provides the info notification, that
  * can show the users infos and errors (colored
  * box with icon).
 */
@Component({
  selector: 'mp-core-info-notification',
  templateUrl: './info-notification.component.html',
  styleUrls: ['./info-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoNotificationComponent implements OnInit {
  @Input() public iconPosition: string = '';
  @Input() public infoState: string = '';
  @Input() public headline: string = '';
  @Input() public copyText: string = '';
  @Input() public noFullWidth: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
