<div mpCoreMpContentFullHeight>
  <p>showroom works!</p>
  <!--<mp-core-date-picker label="Test"
                       mpId="date-picker-von"
                       class="form-control suchKontainerDPDatum date-picker"
                       mpType="text">
  </mp-core-date-picker>-->
  <mp-core-data-table title="Mein Punktestand"
                      fieldsAction="/api/Konto/getKontoFields"
                      listAction="/api/Konto/getKonto"
                      loaderName="showroomPunktestand"
                      [showDatePicker]="true"
                      [paging]="true"
                      [footer]="true"
                      [disableCache]="true"
                      defaultSorting="Datum DESC">
  </mp-core-data-table>
  <!--<mp-core-date-range-picker-with-presets class="mp-data-table-date-range-picker"
                                          [von]="loader.picker['Von']"
                                          [bis]="loader.picker['Bis']"
                                          [minDate]="loader['minDate']"
                                          [maxDate]="loader['maxDate']">
  </mp-core-date-range-picker-with-presets>-->
  <!--<daterangepicker [options]="daterangepickerOptions"
                   (rangeSelected)="rangeSelected($event)">
  </daterangepicker>-->

</div>
