import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../../services/mp-loader.service';
import { ParticipantCockpitService } from './../../participant-cockpit/participant-cockpit.service';
import { DataTableChildsService } from './../../../../../components/data-table/data-table-childs.service';

import { MpLoader } from './../../../../../services/interfaces/mp-loader';

import { PtcpntCockpitOrdersChildComponent } from './../ptcpnt-cockpit-orders-child/ptcpnt-cockpit-orders-child.component';

/**
 * This class provides the data and
 * functions for the orders table.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-orders',
  templateUrl: './ptcpnt-cockpit-orders.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitOrdersComponent implements OnInit, OnDestroy {

  public loader: MpLoader | null = null;
  public tnIdAvailable: boolean = false;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _cockpitData: { [key: string]: any } = {};
  private _ptcpntCockpitOrdersLoader: MpLoader = {
    name: 'ptcpntCockpitOrdersLoader',
    params: {}
  };
  private _getParticipantCockpitDataSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _participantCockpitService: ParticipantCockpitService,
    private _dataTableChilds: DataTableChildsService
  ) { }

  /**
   * Registers and extends the loader,
   * gets the participant cockpit data.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('/Views/ng-templates/TNCockpit-BestellungenChild', PtcpntCockpitOrdersChildComponent);
    this._mpLoaderService.registerLoader(this._ptcpntCockpitOrdersLoader);
    this.loader = this._mpLoaderService.getLoader('ptcpntCockpitOrdersLoader');

    this._getParticipantCockpitDataSubscription = this._participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
      if (data['tnId'] === -1) {
        this.tnIdAvailable = false;
        return;
      }

      if (typeof this._cockpitData['tnId'] === 'undefined' || this._cockpitData['tnId'] !== data['tnId'] || typeof this._cockpitData['reloadCounter'] === 'undefined' || this._cockpitData['reloadCounter'] !== data['reloadCounter']) {
        this._cockpitData = data;

        this._mpLoaderService.extendLoader('ptcpntCockpitOrdersLoader', {
          isReady: true,
          params: Object.assign(this._ptcpntCockpitOrdersLoader.params, {
            tnid: this._cockpitData['tnId']
          })
        });

        if (this.loader !== null) {
          if (typeof this.loader.load !== 'undefined') {
            this.loader.load();
          }
        }

        this.tnIdAvailable = this._cockpitData['tnId'] && this._cockpitData['tnId'] !== -1;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getParticipantCockpitDataSubscription !== 'undefined') {
      this._getParticipantCockpitDataSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('ptcpntCockpitOrdersLoader');
  }

}
