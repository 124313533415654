import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpTitleService } from './../../services/mp-title.service';
import { UserProfileNavbarService } from './user-profile-navbar.service';
import { MpLocalizationService } from './../../services/mp-localization.service';
import { InboxUnreadService } from './../inbox/inbox-unread.service';
import { MpBreadcrumbService } from './../../services/mp-breadcrumb.service';

/**
 * This class provides the functions for
 * the user profile navbar.
 */
@Component({
  selector: 'mp-core-user-profile-navbar',
  templateUrl: './user-profile-navbar.component.html',
  styleUrls: ['./user-profile-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileNavbarComponent implements OnInit, OnDestroy {

  public activeTab: number = 1;
  public inboxUnread: number = 0;
  public locsSubscription: Subscription | undefined;
  public queryParamsSubscription: Subscription | undefined;
  public inboxUnreadSubscription: Subscription | undefined;
  public activeTabSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public breadcrumbService: MpBreadcrumbService,
    public titleService: MpTitleService,
    public route: ActivatedRoute,
    public userProfileNavbarService: UserProfileNavbarService,
    public inboxUnredService: InboxUnreadService
  ) { }

  /**
   * Gets inbox items, and changes tab, if
   * there are parameters set in the url.
   */
  ngOnInit(): void {
    window.addEventListener('scroll', this.stickyOnScroll.bind(this));
    window.addEventListener('resize', this.stickyOnScroll.bind(this));

    this.inboxUnreadSubscription = this.inboxUnredService.unread.subscribe((unread: number) => {
      this.inboxUnread = unread;
    });

    this.inboxUnredService.getUnread();

    this.activeTabSubscription = this.userProfileNavbarService.activeTabObserver.subscribe((tab: number) => {
      if (tab && tab > 0) {
        this.changeTab(tab);
      }
    });

    this.setBreadcrumb('Dashboard');

    this.queryParamsSubscription = this.route.queryParams.subscribe((params: any) => {
      if (typeof params['tab'] !== 'undefined') {
        let tab = params['tab'];

        switch (tab.toLowerCase()) {
          case 'dashboard':
            tab = '1';
            break;
          case 'konto':
            tab = '3';
            break;
          case 'postfach':
            tab = '4';
            break;
        }

        this.changeTab(parseInt(tab));
      }

      if (typeof params['type'] !== 'undefined' && typeof params['key'] !== 'undefined') {
        this.userProfileNavbarService.setParams('PostfachType', params['type']);
        this.userProfileNavbarService.setParams('PostfachKey', params['key']);
      }
    });
  }

  /**
   * Unsubscribes set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.locsSubscription !== 'undefined') {
      this.locsSubscription.unsubscribe();
    }

    if (typeof this.queryParamsSubscription !== 'undefined') {
      this.queryParamsSubscription.unsubscribe();
    }

    if (typeof this.inboxUnreadSubscription !== 'undefined') {
      this.inboxUnreadSubscription.unsubscribe();
    }

    if (typeof this.activeTabSubscription !== 'undefined') {
      this.activeTabSubscription.unsubscribe();
    }

    window.removeEventListener('scroll', this.stickyOnScroll.bind(this));
    window.removeEventListener('resize', this.stickyOnScroll.bind(this));
  }

  /**
   * Changes the tab to the given id.
   */
  changeTab(tabId: number, evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    let tab = 'Dashboard';

    switch (tabId) {
      case 1:
        this.titleService.setTitleFromLoc('Dashboard');
        break;
      case 3:
        tab = 'Konto';
        this.titleService.setTitleFromLoc('Konto');
        break;
      case 4:
        tab = 'Postfach';
        this.titleService.setTitleFromLoc('Postfach');
        break;
    }

    this.setBreadcrumb(tab);
    this.userProfileNavbarService.changeTab(tabId);
    this.activeTab = this.userProfileNavbarService.getActiveTab();
  }

  /**
   * Sets the text for the breadcrumb.
   */     
  setBreadcrumb(tab: string): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this.breadcrumbService.setDashboardTab(
        {
          Path: tab,
          Loc: 'Mein ' + this.ls.locs['loc'][tab]
        });
    } else {
      this.ls.getLocalization();

      this.locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.breadcrumbService.setDashboardTab(
            {
              Path: tab,
              Loc: 'Mein ' + this.ls.locs['loc'][tab]
            });
        }
      });
    }
  }

  /*
   * Sets the profile navbar as fixed on
   * scroll.
   */
  stickyOnScroll(): void {
    const profileNavbar = document.getElementById('user-profile__navbar');
    const navbar = document.getElementById('navbar');
    const stage = document.querySelector('.stage');
    const credits = document.getElementById('credits');
    let creditsHeight = 0;

    if (credits !== null) {
      if (!credits.classList.contains('credits-hidden') && window.innerWidth < 1400) {
        creditsHeight = credits.getBoundingClientRect().height;
      }
    }

    if (profileNavbar !== null && navbar !== null && stage !== null) {
      const stageCBR = stage.getBoundingClientRect();

      if (stageCBR.top + stageCBR.height <= navbar.getBoundingClientRect().height) {
        const placeholderNavbarFromDom = document.getElementById('user-profile__navbar-sticky-placeholder');

        if (placeholderNavbarFromDom === null) {
          const placeholderNavbar = document.createElement('div');
          placeholderNavbar.id = 'user-profile__navbar-sticky-placeholder';
          placeholderNavbar.style.width = '100%';
          placeholderNavbar.style.height = `${profileNavbar.getBoundingClientRect().height}px`;
          profileNavbar.insertAdjacentElement('beforebegin', placeholderNavbar);
        } else {
          placeholderNavbarFromDom.style.height = `${profileNavbar.getBoundingClientRect().height}px`;
        }

        profileNavbar.style.width = '100%';
        profileNavbar.style.position = 'fixed';
        profileNavbar.style.left = '0';
        profileNavbar.style.zIndex = '9';
        profileNavbar.style.marginTop = '0';
        profileNavbar.style.top = `${(navbar.getBoundingClientRect().height + creditsHeight)}px`;
      } else {
        const placeholderNavbarFromDom = document.getElementById('user-profile__navbar-sticky-placeholder');

        if (placeholderNavbarFromDom !== null) {
          placeholderNavbarFromDom.remove();
        }

        profileNavbar.removeAttribute('style');
      }
    }
  }

}
