import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import clone from 'clone';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { ApiService } from '@core/services/api.service';
import { MpEnumsService } from '@core/services/mp-enums.service';

/**
 * This class provides the functionalities
 * for the travel destinations hotel sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-travel-destinations-hotel',
  templateUrl: './sidebar-travel-destinations-hotel.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarTravelDestinationsHotelComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public selectionLoaded: boolean = false;
  public focusOnInit: boolean = false;
  public noResults: boolean = false;
  public noSelection: boolean = false;
  public areaType: { [key: string]: any } = {};
  public searchfieldPlaceholder: string = '';
  public selectedTyp: number = 0;
  public countries: Array<any> = [];
  public regions: Array<any> = [];
  public cities: Array<any> = [];
  public hotels: Array<any> = [];

  private _lastSearchSuggestRequestId: number = Math.ceil(Math.random() * 1000);
  private _sidebarParamsSubscription: Subscription | undefined;
  private _getEnumsSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;
  private _getGeoFilterSubscription: Subscription | undefined;
  private _getGeoFilterSuggestionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _apiService: ApiService,
    private _mpEnums: MpEnumsService
  ) { }

  /**
   * Gets the enums and the sidebar
   * params.
   */
  ngOnInit(): void {
    if (Object.keys(this._mpEnums.enums).length > 0) {
      this.areaType = this._mpEnums.enums['BereichType'];
      this._getLocs();
    } else {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.areaType = this._mpEnums.enums['BereichType'];
          this._getLocs();
        }
      });

      this._mpEnums.getEnums();
    }

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        Suchbegriff: this.selection['Suchbegriff'] || this.mpSidebar.params['Suchbegriff'],
        ziele: this.selection['ziele'] || clone(this.mpSidebar.params['ziele']) || []
      });

      if (this.selection['ziele'] && this.selection['ziele'].length > 0) {
        this.selectedTyp = this.selection['ziele'][0].Typ;
      }

      this._setSearchfieldPlaceholder();

      this.noResults = this.mpSidebar.params['noResults'];
      this.noSelection = this.mpSidebar.params['noSelection'];
      this.focusOnInit = false;

      setTimeout(() => {
        this.focusOnInit = true;
      }, 0);

      this._getGeoFilter();
      this.selectionLoaded = true;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getGeoFilterSubscription !== 'undefined') {
      this._getGeoFilterSubscription.unsubscribe();
    }

    if (typeof this._getGeoFilterSuggestionsSubscription !== 'undefined') {
      this._getGeoFilterSuggestionsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the localization data.
   */
  private _getLocs(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this._setSearchfieldPlaceholder();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setSearchfieldPlaceholder();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Sets the placeholder for the search field.
   */
  private _setSearchfieldPlaceholder(): void {
    if (this.ls.locs['locReisekonfigurator']) {
      switch (this.selectedTyp) {
        case 1:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesHotel;
          break;
        case 2:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmterOrt;
          break;
        case 3:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesZielgebiet;
          break;
        case 4:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesLand;
          break;
        default:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesReiseziel;
          break;
      }
    }
  }

  /**
   * Gets the geo filter, so the airports
   * can be filtered.
   */
  private _getGeoFilter(): void {
    if (this.selection['Suchbegriff']) {
      this.noResults = false;
      this.noSelection = false;
    }

    if ((this.selection['Suchbegriff'] && this.selection['Suchbegriff'].length > 2)) {
      const geoFilterData = {
        Suchbegriff: this.selection['Suchbegriff'],
        RequestId: ++this._lastSearchSuggestRequestId
      };

      this._getGeoFilterSubscription = this._apiService.postRequest('/api/RkHotelUndFlug/GeoFilter/', geoFilterData).subscribe((data: any) => {
        if (this._lastSearchSuggestRequestId === data.footer[0]) {
          const lowerCaseSearchTerm = geoFilterData['Suchbegriff'].toLowerCase();

          this.countries = [...new Map(data.Records.map((item: any) => [item['LandCode'], item])).values()].filter((country: any) => {
            country['active'] = this.selection['ziele'] && typeof this.selection['ziele'].find((target: any) => {
              return target['Code'] === country['LandCode'];
            }) !== 'undefined';

            return country['Land'] && country['Land'].toLowerCase().indexOf(lowerCaseSearchTerm) > -1;
          }).concat().sort(this._sortBy('Land'));

          this.regions = [...new Map(data.Records.map((item: any) => [item['LandCode'] + '-' + item['Zielgebietscode'], item])).values()].filter((region: any) => {
            region['active'] = this.selection['ziele'] && typeof this.selection['ziele'].find((target: any) => {
              return target['Code'] === region['Zielgebietscode'];
            }) !== 'undefined';

            return region['Zielgebiet'] && region['Zielgebiet'].toLowerCase().indexOf(lowerCaseSearchTerm) > -1;
          }).concat().sort(this._sortBy('Zielgebiet'));

          this.cities = [...new Map(data.Records.map((item: any) => [item['LandCode'] + '-' + item['Zielgebietscode'] + '-' + item['StadtCode'], item])).values()].filter((city: any) => {
            city['active'] = this.selection['ziele'] && typeof this.selection['ziele'].find((target: any) => {
              return target['Code'] === city['StadtCode'];
            }) !== 'undefined';

            return city['Stadt'] && city['Stadt'].toLowerCase().indexOf(lowerCaseSearchTerm) > -1;
          }).concat().sort(this._sortBy('Stadt'));

          this.hotels = [...new Map(data.Records.map((item: any) => [item['LandCode'] + '-' + item['Zielgebietscode'] + '-' + item['StadtCode'] + '-' + item['HotelCode'], item])).values()].filter((hotel: any) => {
            hotel['active'] = this.selection['ziele'] && typeof this.selection['ziele'].find((target: any) => {
              return target['Code'] === hotel['HotelCode'];
            }) !== 'undefined';

            return hotel['Hotel'] && hotel['Hotel'].toLowerCase().indexOf(lowerCaseSearchTerm) > -1;
          }).concat().sort(this._sortBy('Hotel'));
        }
      });
    } else {
      this._getGeoFilterSuggestionsSubscription = this._apiService.getRequest('/api/RkHotelUndFlug/GeoFilterVorschlaege/', false, { params: { requestId: ++this._lastSearchSuggestRequestId } }).subscribe((data: any) => {
        if (this._lastSearchSuggestRequestId === data.footer[0]) {
          const result = data.Records[0];

          result['Land'].forEach((country: any) => {
            const old = this.countries.length > 0 ? this.countries.find((oldCountry: any) => {
              return oldCountry['LandCode'] === country['LandCode'];
            }) : null;

            country['active'] = old !== undefined && old !== null && old['active'];
          });

          this.countries = result['Land'];

          result['Zielgebiet'].forEach((region: any) => {
            const old = this.regions.length > 0 ? this.regions.find((oldRegion: any) => {
              return oldRegion['Zielgebietscode'] === region['Zielgebietscode'];
            }) : null;

            region['active'] = old !== undefined && old !== null && old['active'];
          });

          this.regions = result['Zielgebiet'];

          result['Stadt'].forEach((city: any) => {
            const old = this.regions.length > 0 ? this.regions.find((oldCity: any) => {
              return oldCity['StadtCode'] === city['StadtCode'];
            }) : null;

            city['active'] = old !== undefined && old !== null && old['active'];
          });

          this.cities = result['Stadt'];

          result['Hotel'].forEach((hotel: any) => {
            const old = this.regions.length > 0 ? this.regions.find((oldHotel: any) => {
              return oldHotel['HotelCode'] === hotel['HotelCode'];
            }) : null;

            hotel['active'] = old !== undefined && old !== null && old['active'];
          });

          this.hotels = result['Hotel'];
        }
      });
    }
  }

  /**
   * Sets the geo filter by the given
   * parameters.
   */
  setGeoFilter(code: string, text: string, type: number, object: { [key: string]: any }): void {
    const existing = this.selection['ziele'].find((target: any) => {
      return target['Code'] === code && target['Typ'] === type;
    });

    if (typeof existing !== 'undefined') {
      const index = this.selection['ziele'].indexOf(existing);
      this.selection['ziele'].splice(index, 1);

      let objFound = false;

      switch (type) {
        case 1:
          object = this.hotels.find((hotel: any) => {
            return hotel['HotelCode'] === code && hotel['active'] === true;
          });

          objFound = typeof object !== 'undefined';
          break;
        case 2:
          object = this.cities.find((city: any) => {
            return city['StadtCode'] === code && city['active'] === true;
          });

          objFound = typeof object !== 'undefined';
          break;
        case 3:
          object = this.regions.find((region: any) => {
            return region['Zielgebietscode'] === code && region['active'] === true;
          });

          objFound = typeof object !== 'undefined';
          break;
        case 4:
          object = this.countries.find((country: any) => {
            return country['LandCode'] === code && country['active'] === true;
          });

          objFound = typeof object !== 'undefined';
          break;
        default:
          break;
      }

      if (objFound) {
        object['active'] = false;
      }

      if (this.selection['ziele'].length === 0) {
        this.selectedTyp = 0;
      }
    } else {
      this.selection['ziele'].push({ Code: code, Typ: type, Text: text });
      this.selectedTyp = type;
      object['active'] = true;
    }

    this._setSearchfieldPlaceholder();

    setTimeout(() => {
      this.selection['Suchbegriff'] = '';
    }, 10);

    this.noResults = false;
  }

  /**
   * Sorts the array by the given object
   * key.
   */
  private _sortBy(key: any): any {
    return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
  };

  /**
   * Triggers the geo filter.
   */
  searchTermChange(): void {
    this._getGeoFilter();
  }

  /**
   * Clears the search field.
   */
  clearSearch(evt: MouseEvent): void {
    this.selection['Suchbegriff'] = '';
    // @ts-ignore
    evt.currentTarget.parentElement.parentElement.querySelector('input').focus();
  }

  /**
   * Saves the selected destinations.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpSidebar.params['ziele'] = this.selection['ziele'];
    this.mpSidebar.params['noSelection'] = this.noSelection;
    this.mpSidebar.params['noResults'] = this.noResults;

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

}
