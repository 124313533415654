<div id="sidebar-calendar-rental" class="rk-sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="texst-align-left">{{ ls.locs['locReisekonfigurator'].AnUndAbreiseSidebarHeader }}</div>
    <div class="divider margin-bottom-8"></div>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex"
         id="calendar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeStart'] }"
           (click)="setCurrentSelection('start')">
        <div>
          <span class="text-transform-uppercase font-size-xs">{{ ls.locs['locReisekonfigurator'].Anreise }}</span>
          <div *ngIf="selection['rangeStart']">
            <span class="font-size-xs"
                  [innerText]="selection['rangeStart'].format('dddd')"></span>
            <span [innerText]="selection['rangeStart'].format('L')"></span>
          </div>
        </div>
      </div>
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeEnd'] && selection['rangeEnd'] != selection['rangeStart'] }"
           (click)="setCurrentSelection('end')">
        <div>
          <span class="text-transform-uppercase font-size-xs">
            {{ ls.locs['locReisekonfigurator'].Abreise }}</span>
          <div *ngIf="selection['rangeEnd']">
            <span class="font-size-xs"
                  [innerText]="selection['rangeEnd'].format('dddd')"></span>
            <span [innerText]="selection['rangeEnd'].format('L')"></span>
          </div>
        </div>
      </div>
    </div>
    <div id="mietwagen-time-selects" *ngIf="times.length > 0" class="display-flex margin-left-16 margin-right-16">
      <mp-core-select mpId="rkReisedauer"
                 [(model)]="selection['UhrzeitAbholung']"
                 [elements]="times"
                 valueProperty="Value"
                 textProperty="Text"
                 class="margin-0"></mp-core-select>
      <mp-core-select mpId="rkReisedauer"
                 [(model)]="selection['UhrzeitRueckgabe']"
                 [elements]="times"
                 valueProperty="Value"
                 textProperty="Text"
                 class="margin-0"></mp-core-select>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#flugzeiten-scroll-area"
                   scrollDirection="up"
                   desktopItemHeight=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="flugzeiten-scroll-area-container">
      <div id="flugzeiten-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="mpSidebar.params['minDate']"
                    [(rangeStart)]="selection['rangeStart']"
                    [(rangeEnd)]="selection['rangeEnd']"
                    [isRange]="true"
                    [minRange]="0"
                    [(maxRange)]="maxRange"
                    [defaultRange]="0"
                    [(currentSelection)]="selection['currentSelection']">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#flugzeiten-scroll-area"
                   scrollDirection="down"
                   desktopItemHeight=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" [ngClass]="{disabled: !(selection['rangeStart'] && selection['rangeEnd'])}" class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}</a>
</div>
  </div>
</div>
