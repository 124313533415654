import { Component, OnInit, Input, ElementRef, ViewEncapsulation, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import numeral from 'numeral';
//@ts-ignore
import ColorThief from 'colorthief';

import { MpLocalizationService } from './../../services/mp-localization.service';

/**
* This class provides the data and
* functions for the action card.
*/
@Component({
  selector: 'mp-core-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionCardComponent implements OnInit {
  @Input() public action: any;

  public bgImg: string = '';
  public currentTheme: any | undefined;
  public timeLeftText: string = '';
  public bgColor: any;

  public secTotal: number = 0;     
  public hoursTotal: number = 0;
  public daysTotal: number = 0;
  public secLeft: number = 0;
  public hoursLeft: number = 0;
  public daysLeft: number = 0;
  public hideTimeline: boolean = false;
  public detailLink: string = '';
  public detailInNewTab: boolean = false;

  private _locsSubscription: Subscription | undefined;

  constructor(
    public sanitizer: DomSanitizer,
    public ls: MpLocalizationService,
    private _elementRef: ElementRef
    ) { }

  /**
  * Loads the action card
  */
  ngOnInit(): void {

    this.ls.getLocalization();
    this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.timeLeftText = this.ls.locs['loc'].NochXTage.replace('{0}', numeral(this.daysLeft).format('0'));
      }
    });

    this.currentTheme = "default";

    this.setBgImg();

    const img = this._elementRef.nativeElement.querySelector('img.bg-img');

    const colorThief = new ColorThief();
   
    img.addEventListener('load', () => {
      if (this.action.Key !== 'LeereAktion')
        this.bgColor = this._rgbToHex(colorThief.getColor(img)[0],
          colorThief.getColor(img)[1],
          colorThief.getColor(img)[2]);
      else
        this.bgColor = '#BBC0C6';
        //this.bgColor = this._rgbToHex(colorThief.getColor(img)[0],
        //  colorThief.getColor(img)[0],
        //  colorThief.getColor(img)[0]);
    });

    let bis = new Date(this.action.GueltigBis);
    if (bis < new Date(2050, 11, 31)) {
      let von = new Date(this.action.GueltigVon);
      let now = new Date();

      this.secTotal = (bis.getTime() - von.getTime()) / 1000;
      this.hoursTotal = this.secTotal / 3600;
      this.daysTotal = this.hoursTotal / 24;

      this.secLeft = (bis.getTime() - now.getTime()) / 1000;
      this.hoursLeft = this.secLeft / 3600;
      this.daysLeft = this.hoursLeft / 24;

      this.hideTimeline = false;
    } else {
      this.hideTimeline = true;
    }

    this.detailLink = '#/AktionDetails?key=' + this.action.Key;
    if (this.action.Link) {
      this.detailLink = this.action.Link;
      this.detailInNewTab = (this.action.Link.includes('http') || this.action.Link.includes('www.'));
    }
  }

  /*
  * Prepares conversion RGB value in HEX
  */
  _componentToHex(c: number) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  /*
  * Converts RGB value in HEX
  */
  _rgbToHex(r: number, g: number, b: number) {
    return "#" + this._componentToHex(r) + this._componentToHex(g) + this._componentToHex(b);
}

  /*
  * Sanitizer for HTML.
  */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Sets background color of the action card image
   */
  setBgImg() {
    this.bgImg = '/themes/' + this.currentTheme + '/media/aktionen/uebersicht/' + this.action.Key + '.jpg?h=410&w=630&mode=crop';
  }
}
