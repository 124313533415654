<div class="iframe-overlay-container">
  <ng-content></ng-content>
  <div class="iframe-overlay" *ngIf="!showContent">
    <div>
      {{ ls.locs['loc'].ContentBlockedBecauseOfCookies }}
      <a class="mp-button _prim margin-16" (click)="acceptCookie()" style="text-transform: uppercase;">
        {{ ls.locs['loc'].Bestaetigen }}
      </a>
    </div>
    <div>
      <a class="mp-button margin-top-24" (click)="cookieService.openCookieConsentBanner()" style="text-transform: uppercase;">
        {{ ls.locs['loc'].CookieSettings }}
      </a>
    </div>
  </div>
</div>

