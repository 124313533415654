<div id="praemien-details" *ngIf="(ls.locsLoaded | async)">
  <div>
    <div id="praemien-details-top">

      <div class="layout-container">
        <div class="margin-top-48 margin-right-16" style="float: left;">
          <div>
            <img [src]="schoolingData['BildPfad'] + schoolingData['Id'] + '/SchulungStart.jpg?mode=crop'" />
          </div>
        </div>
        <div class="margin-top-48">
          <!--<h2>{{ ls.locs['locSchulung'].Pflichtschulung }}</h2>-->
          <h1 [innerText]="schoolingData['Name']"></h1>
        </div>
        <div class="margin-top-48">
          <a href="#/{{role}}/SchulungSeite?schoolingId={{schoolingData['Id']}}">
            <button class="mp-button _prim margin-top-bottom-24">
              {{ ls.locs['locSchulung'].SchulungStarten }}
            </button>
          </a>
          <div [hidden]="!isTrainingCompleted" [innerText]="trainingCompletedText" class="margin-top-16"></div>
        </div>

      </div>
    </div>
    <div id="praemien-details-bottom">
      <div class="layout-container">
        <div class="row">
          <div class="grid col-large-6">
            <!--<h3>{{ schoolingData['Titel'] }}</h3>-->
            <div [innerHTML]="schoolingData['Beschreibung']">
              <!--<p>{{ schoolingData['Beschreibung'] }}</p>-->
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</div>
