<div id="sidebar-calendar-birthday" class="rk-sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="texst-align-left">{{ ls.locs['locReisekonfigurator'].GeburtsdatumSidebarHeader }}</div>
    <div class="divider margin-bottom-8 margin-left-16"></div>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex" id="calendar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start active-tab">
        <span class="text-transform-uppercase font-size-xs" *ngIf="!mpSidebar.params['IsKind']">{{ls.locs['locReisekonfigurator'].NterErwachsener.replace('{0}', mpSidebar.params['Id'])}}</span>
        <span class="text-transform-uppercase font-size-xs" *ngIf="mpSidebar.params['IsKind']">{{ls.locs['locReisekonfigurator'].NtesKind.replace('{0}', mpSidebar.params['Id'])}}</span>
        <div>
          <span class="font-size-xs" [innerText]="mpSidebar.params['Vorname']+' '+mpSidebar.params['Name']"></span>
          <span [innerText]="selection['Geburtsdatum'].format('L')"></span>
        </div>
      </div>
    </div>
    <div>
      <mp-core-select label="" mpId="rkGeburtsjahr" [model]="selection['Geburtsjahr']" (modelChange)="selection['Geburtsjahr'] = $event; changeBirthYear();" [elements]="yearsRange" class="display-block padding-left-right-16 margin-left-0 box-sizing-border-box margin-top-32"></mp-core-select>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="false" scrollItemHeight="80" desktopItemHeight=".calendar > div" scrollArea="#geburtsdatum-scroll-area" scrollDirection="up" scrollFactor="1" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container" id="geburtsdatum-scroll-area-container">
      <div id="geburtsdatum-scroll-area" class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="minDate" [(maxDate)]="maxDate" [model]="selection['Geburtsdatum']" (modelChange)="selection['Geburtsdatum'] = $event; changeBirthday();"></mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="false" scrollItemHeight="80" desktopItemHeight=".calendar > div" scrollArea="#geburtsdatum-scroll-area" scrollDirection="down" scrollFactor="1" class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].AenderungenUebernehmen }}</a>
    </div>
  </div>
</div>
