import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';


/**
 * This class provides the data and
 * functions for the appreciation overview
 * table.
 */
@Component({
  selector: 'mp-leas-appreciation-overview',
  templateUrl: './appreciation-overview.component.html'
})
export class AppreciationOverviewComponent implements OnInit, OnDestroy {

  public editModalOpen: boolean = false;
  public excelService: string = '/api/Honorierung/Honorierungen2Excel';
  public loader: MpLoader | null = null;
  public optionsFrom: { [key: string]: any } = { format: 'dd.mm.yyyy' };
  public optionsTo: { [key: string]: any } = { format: 'dd.mm.yyyy' };
  public appreciation: { [key: string]: any } = {
    VonText: undefined,
    BisText: undefined
  };
  public honDateFrom: Date | undefined;
  public honDateTo: Date | undefined;
  public saveAppreciation = this._saveAppreciation.bind(this);
  public closeEditModal = this._closeEditModal.bind(this);
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'editHonorierung',
      func: this.editAppreciation.bind(this)
    }
  ];

  private _loading: boolean = false;
  private _leasAppreciationOverviewLoader: MpLoader = {
    name: 'leasAppreciationOverviewLoader',
    params: {}
  };
  private _saveAppreciationSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._leasAppreciationOverviewLoader);

    this._mpLoaderService.extendLoader('leasAppreciationOverviewLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._saveAppreciationSubscription !== 'undefined') {
      this._saveAppreciationSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasAppreciationOverviewLoader');
  }

  /**
   * Saves the appreciation.
   */
  private _saveAppreciation(): void {
    if (this._loading) {
      return;
    }

    if (this._validate()) {
      this._loading = true;

      const sendData = {
        Id: this.appreciation['Id'],
        Punkte: this.appreciation['PunkteWert'],
        Von: this.appreciation['VonText'],
        Bis: this.appreciation['BisText']
      };

      this._saveAppreciationSubscription = this._apiService.postRequest('/api/Honorierung/saveHonorierung', sendData).subscribe((data: any) => {
        this._loading = false;

        if (data.Result === 'ERROR') {
          this.appreciation['errors'].push(data.Message);
          this._mpMessaging.openDangerPanel(data.Message);
        } else {
          this.editModalOpen = false;
          this._mpMessaging.openSuccessPanel(data.Message);

          if (this.loader !== null) {
            if (typeof this.loader.load !== 'undefined') {
              this.loader.load();
            } 
          }
        }
      },
      (error: any) => {
        this._loading = false;
      });
    }
  }

  /**
   * Closes the edit modal.
   */
  private _closeEditModal(): void {
    this.editModalOpen = false;
  }

  /**
   * Openes the edit appreciation modal.
   */
  editAppreciation(row: any): void {
    this.editModalOpen = true;
    this.appreciation = row;

    if (this.appreciation) {
      this.honDateFrom = moment(this.appreciation['Von'], 'DD.MM.YYYY').toDate();
      this.honDateTo = moment(this.appreciation['Bis'], 'DD.MM.YYYY').toDate();
    }
  }

  /**
   * Validates the appreciation to save.
   */
  private _validate(): boolean {
    if (!this.appreciation || Object.keys(this.appreciation).length === 0) {
      return false;
    }

    this.appreciation['errors'] = [];

    if (this.appreciation['PunkteWert'] < 0)
      this.appreciation['errors'].push(this.ls.locs['locLeas'].HonorierungWenigerAlsNullPunkte);

    return this.appreciation['errors'].length === 0;
  }

}
