import { Directive, ElementRef, HostListener, Input, Renderer2, Inject } from '@angular/core';

/**
 * This class provides a directive scroll event
 * on list of cards
 */
@Directive({
  selector: '[stickyOnScroll]'
})
export class StickyOnScrollDirective {

  public elem: any;
  public parent: any;
  public isStickElement: boolean = false;
  public elemBCR: number = 0;
  public stickElemHeight: number = 0;
  public stickElemPos: number = 0;
  public elemWidth: string = '';
  public elemHeight: number = 0;
  public creditsHeight: number = 0;
  public credits: any;
  public stickyElementWidth: any;

  public _stickElem: any;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.elem = this._elementRef.nativeElement;

    const tempEl = this.elem.getAttribute('data-stick-element');

    if (tempEl) {
      this._stickElem = document.querySelector('#' + tempEl);
    }
  }

  /**
  * Decorator with call of the onScroll function
  */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.stickyElementWidth = document.getElementById('sticky-element-width');
    this.elemWidth = window.getComputedStyle(this.stickyElementWidth).getPropertyValue('width').replace('px', '');

    if (this.elem.style.height.replace('px', '') !== this.elemWidth) {
      this._renderer.setAttribute(this.elem, 'style', 'width: ' + this.elemWidth + 'px');
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this._scrollHandlerFnc();
  }

  /**
  * Handles scroll operation
  */
  _scrollHandlerFnc(): void {
    this.credits = document.getElementById('credits');
 
    if (document.querySelector('[stickyOnScroll]')) {
      this.elemBCR = this.elem.getBoundingClientRect().top;
      if (typeof this._stickElem !== 'undefined') {
        this.stickElemHeight = parseInt(window.getComputedStyle(this._stickElem).getPropertyValue('height').replace('px', ''));
        this.stickElemPos = this._stickElem.offsetTop;
      }
      this.stickyElementWidth = document.getElementById('sticky-element-width');
      this.elemWidth = window.getComputedStyle(this.stickyElementWidth).getPropertyValue('width').replace('px', '');
      this.elemHeight = parseInt(window.getComputedStyle(this.elem).getPropertyValue('height').replace('px', ''));

      this.creditsHeight = typeof this.credits !== 'undefined' ? !this.credits.classList.contains('credits-hidden') ? typeof this.credits.style.height !== 'undefined' ? parseInt(this.credits.style.height.replace('px', '')) : 0 : 0 : 0;
      if (window.innerWidth >= 992) {
        this.creditsHeight = 0;
      }

      this.parent = this.elem.parentElement;

      if (this.elemBCR <= (this.stickElemHeight + this.stickElemPos) && this.isStickElement === false) {
        this._renderer.setStyle(this.elem, 'position', 'fixed');
        this._renderer.setStyle(this.elem, 'top', (this.stickElemHeight + this.creditsHeight) + 'px');
        this._renderer.setStyle(this.elem, 'width', this.elemWidth + 'px');
        this._renderer.setStyle(this.elem, 'zIndex', 3);

        this._renderer.setStyle(this.parent, 'padding-top', this.elemHeight + 'px');

        this.isStickElement = true;
      } else if (this.parent.getBoundingClientRect().top > (this.stickElemHeight + this.stickElemPos + this.elemHeight) && this.isStickElement) {
        this._renderer.removeAttribute(this.elem, 'style');
        this._renderer.removeAttribute(this.parent, 'style');
        this.isStickElement = false;
      } else if (this.isStickElement && window.innerWidth < 992) {
        this._renderer.setStyle(this.elem, 'top', (this.stickElemHeight + this.creditsHeight) + 'px');
      }
    } else {
      window.removeEventListener('scroll', this._scrollHandlerFnc);
    }
  }

}
