import { Injectable } from '@angular/core';

import { LoadingBarService } from '@ngx-loading-bar/core';

import { MpLoadingBar } from './interfaces/mp-loading-bar';

/**
 * This class provides the functions
 * to start and stop loading bars.
 */
@Injectable({
  providedIn: 'root'
})
export class MpLoadingBarService {

  private _loadingBars: Array<MpLoadingBar> = [];
  private _loader: any;

  constructor(
    private _loadingBar: LoadingBarService
  ) {
    this._loader = this._loadingBar.useRef();
  }

  /**
   * Starts (or queues) a loading
   * bar.
   */
  start(name: string): void {
    const loadingBarInArr = this._loadingBars.length > 0 ? this._loadingBars.find((loadingBar: MpLoadingBar) => {
      return loadingBar.name === name;
    }) : undefined;

    if (typeof loadingBarInArr === 'undefined') {
      if (this._loadingBars.length === 0) {
        this._loader.start();
      }

      this._loadingBars.push({
        name: name,
        running: true
      });
    }
  }

  /**
   * Completes a loading bar, and
   * starts the next queued loading
   * bar.
   */
  complete(name: string): void {
    const loadingBarInArr = this._loadingBars.length > 0 ? this._loadingBars.find((loadingBar: MpLoadingBar) => {
      return loadingBar.name === name;
    }) : undefined;

    if (typeof loadingBarInArr !== 'undefined') {
      const loadingBarInArrIndex = this._loadingBars.findIndex((loadingBar: MpLoadingBar) => {
        return loadingBar.name === name;
      });

      this._loadingBars.splice(loadingBarInArrIndex, 1);
    }

    if (this._loadingBars.length === 0) {
      this._loader.complete();
    }
  }
}
