<div [hidden]="awards.length <= 0" *ngIf="(ls.locsLoaded | async)">
  <div class="floating-container awards-list margin-bottom-32"
       id="new-benefits">
    <h2 [innerText]="header"
        [ngClass]="{'display-inline-block': categoryId}"></h2>
    <a href="#/{{role}}/Shop/{{categoryId}}"
       class="margin-left-16 font-size-xs all-benefits-link"
       [hidden]="categoryId === -1">
      {{ ls.locs['loc'].AllePraemienAnzeigen }}
    </a>
    <owl-carousel-o *ngIf="awards.length > 0" id="owl-carousel" class="owl-theme" [options]="awardsOwlOptions">
      <ng-container *ngFor="let item of awards">
        <ng-template carouselSlide>
          <div class="item padding-top-16">
            <mp-core-shop-card class="grid-card-s display-block" [tile]="item" [tileWidth]="1" [tileHeight]="1"></mp-core-shop-card>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
