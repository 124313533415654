import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';

/**
 * This class provides the functions
 * for the flight details.
 */
@Component({
  selector: 'mp-rk-flight-details',
  templateUrl: './flight-details.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FlightDetailsComponent implements OnInit {

  @Input() public flight: { [key: string]: any } = {};

  constructor(
    public ls: MpLocalizationService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
