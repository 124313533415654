<div id="sidebar-calendar-hotel" *ngIf="(ls.locsLoaded | async)"
     class="rk-sidebar-form">
  <div class="sidebar-grid" *ngIf="selectionLoaded">
    <div id="overlay-headline"
         class="texst-align-left">
      {{ ls.locs['locReisekonfigurator'].AnUndAbreiseSidebarHeader }}
    </div>
    <div class="divider margin-bottom-8 margin-left-16"></div>
    <mp-core-select label="{{ ls.locs['locReisekonfigurator'].Hotelnaechte }}"
                    mpId="rkReisedauer"
                    [mpRequired]="true"
                    [model]="selection['Reisedauer']"
                    (modelChange)="selection['Reisedauer'] = $event;"
                    [elements]="mpSidebar.params['reisedauerOptions']"
                    textProperty="Text"
                    class="padding-left-right-16 margin-left-0 box-sizing-border-box">
    </mp-core-select>
    <div *ngIf="sliderOptions && selection['Reisedauer'].id === 3"
         class="margin-left-16 margin-right-16 margin-top-32 text-color-info">
      <span [hidden]="selection['MinTage'] === selection['MaxTage']">{{ls.locs['locReisekonfigurator'].VonBisNaechte.replace('{0}', selection['MinTage']).replace('{1}', selection['MaxTage'])}}</span>
      <span [hidden]="selection['MinTage'] !== selection['MaxTage'] || selection['MinTage'] === 1">{{ls.locs['locReisekonfigurator'].XNaechte.replace('{0}', selection['MaxTage'])}}</span>
      <span [hidden]="selection['MinTage'] !== selection['MaxTage'] || selection['MinTage'] !== 1">{{ ls.locs['locReisekonfigurator'].EineNacht }}</span>
    </div>
    <ngx-slider *ngIf="sliderOptions && selection['Reisedauer'].id === 3 && sliderOptions['ceil'] !== sliderOptions['floor']"
                [(value)]="selection['MinTage']"
                [options]="sliderOptions"
                [(highValue)]="selection['MaxTage']"
                class="mp-ngx-slider margin-left-16 margin-right-16 margin-top-16 margin-bottom-0">
    </ngx-slider>
    <div class="margin-left-16 margin-right-16 margin-top-32 display-flex"
         id="calendar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeStart']  }"
           (click)="setCurrentSelection('start')">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['locReisekonfigurator'].FruehesteAnreise }}</span>
        <div *ngIf="selection['rangeStart']">
          <span class="font-size-xs"
                [innerText]="selection['rangeStart'].format('dddd')">
          </span>
          <span [innerText]="selection['rangeStart'].format('L')"></span>
        </div>
      </div>
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeEnd'] && selection['rangeEnd'] != selection['rangeStart'] }"
           (click)="setCurrentSelection('end')">
        <span class="text-transform-uppercase font-size-xs">
          {{ ls.locs['locReisekonfigurator'].SpaetesteAbreise }}
        </span>
        <div *ngIf="selection['rangeEnd']">
          <span class="font-size-xs"
                [innerText]="selection['rangeEnd'].format('dddd')">
          </span>
          <span [innerText]="selection['rangeEnd'].format('L')"></span>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                           [ifNeeded]="false"
                           scrollItemHeight="80"
                           desktopItemHeight=".calendar > div"
                           scrollArea="#reisedauer-scroll-area"
                           scrollDirection="up"
                           scrollFactor="1"
                           class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="reisedauer-scroll-area-container">
      <div id="reisedauer-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="mpSidebar.params['minDate']"
                            [rangeStart]="selection['rangeStart']"
                            [rangeEnd]="selection['rangeEnd']"
                            (rangeStartChange)="selection['rangeStart'] = $event"
                            (rangeEndChange)="selection['rangeEnd'] = $event"
                            [isRange]="mpSidebar.params['isRange']"
                            [minRange]="1"
                            [defaultRange]="selection['MinTage']"
                            [(currentSelection)]="selection['currentSelection']">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                           [ifNeeded]="false"
                           scrollItemHeight="80"
                           desktopItemHeight=".calendar > div"
                           scrollArea="#reisedauer-scroll-area"
                           scrollDirection="down"
                           scrollFactor="1"
                           class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         [ngClass]="{disabled: !(selection['rangeStart'] && selection['rangeEnd'])}"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}</a>
</div>
  </div>
</div>
