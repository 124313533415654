<div *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)" class="layout-container manager-text-editor" id="tip-and-win-editor">
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb" class="display-flex flex-align-items-center margin-bottom-8 padding-left-4 flex-col-12">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info" (click)="goBack($event)">{{ ls.locs['loc'].Zurueck }}</a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['locTipAndWin'].TipAndWin }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['locTipAndWin'].TaeglichesQuiz }}</a>
      </div>
    </div>
    <section id="mngr-text-editor-content" class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-24">
      <div class="width-full bg-color-white padding-16 box-sizing-border-box">
        <ul class="magma-accordion editor-acc">
          <li *ngFor="let question of questions; let index = index; trackBy: trackByQuestionId" id="acc-data-id-{{question['questionId']}}" class="padding-left-right-0 padding-bottom-16 fallback-acc" [ngClass]="{'accOpened': question['offen']}">
            <div class="display-flex margin-left-right-0 elig-requ-acc-flex-container">
              <button class="mp-button _prim padding-left-right-4 margin-right-8 elig-requ-delete-button" (click)="deleteQuestion(question)">
                <i class="svg-icon __top-0 __clr-white __clear __size-16"></i>
              </button>
              <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2 margin-left-right-0 acc-headline-open-trigger" (click)="question['offen'] = !question['offen']">
                <p class="margin-right-16">
                  <a [innerText]="question['question'] + ' [' + question['dateParams'].date.format('L') + ']'"></a>
                </p>
                <i class="svg-icon __size-28 __top-0" [ngClass]="!question['offen'] ? '__clr-inactive __add' : '__clr-white __remove'"></i>
              </div>
            </div>
            <div [hidden]="!question['offen']" id="editor-Container" class="bg-color-white display-block padding-top-bottom-32 padding-left-right-16 overflow-hidden margin-top-8 margin-bottom-0">
              <div class="margin-bottom-40 flex-grid" id="taw-editor-sidebars">
                <div class="flex-col-6 flex-col-12-m flex-grid margin-left-right-0">
                  <mp-core-select class="multiselect mp-data-table-multiselect width-full"  [multiple]="true"
                             placeholder="{{ ls.locs['loc'].Gruppe }}"
                             [keepPlaceholderText]="true"
                             [(model)]="question['groups']"
                             textProperty="Gruppe"
                             [elements]="allGroups" [allowHtml]="true"></mp-core-select>
                </div>
                <div class="flex-col-6 flex-col-12-m">
                  <mp-core-sidebar-input class="flex-col-12 padding-left-right-0"
                                 mpPlaceholder="{{ ls.locs['locTipAndWin'].Veroeffentlichungsdatum }}"
                                 text="{{question['dateParams'].date ? question['dateParams'].date.format('LL') : ''}}"
                                 sidebarComponent="SidebarDate"
                                 [sidebarParams]="question['dateParams']">
                  </mp-core-sidebar-input>
                </div>
              </div>
              <span class="margin-bottom-24 display-block">
                <mp-core-input mpId="acc-editor-title" [(model)]="question['question']" mpPlaceholder="{{ ls.locs['locTipAndWin'].FrageVerfassen }}" [mpRequired]="true" [hasError]="errors['questions['+index+'].question'] ? true : false"></mp-core-input>
                <mp-core-form-error *ngIf="errors['questions['+index+'].question']" [model]="errors['questions['+index+'].question']"></mp-core-form-error>
              </span>
              <div class="flex-grid">
                <div class="flex-col-6 flex-col-12-m margin-bottom-16 taw-answer-input">
                  <div>
                    <mp-core-input mpId="taw-answer-a" [ngClass]="question['answerA'] !== '' ? 'taw-not-empty' : ''" [(model)]="question['answerA']" dataPreText="A:" mpPlaceholder="{{ ls.locs['locTipAndWin'].AntwortAVerfassen }}" [mpRequired]="true" [hasError]="errors['questions['+index+'].answerA'] ? true : false"></mp-core-input>
                    <mp-core-form-error *ngIf="errors['questions['+index+'].answerA']" [model]="errors['questions['+index+'].answerA']"></mp-core-form-error>
                  </div>
                  <mp-core-radio-button name="correctAnswer-{{index}}" mpId="taw-answer-{{index}}-a-checkbox" [(model)]="question['isCorrectLabel']" value="A" [ngClass]="question['answerA'] === '' ? 'disabled pointer-events-none' : question['isCorrectLabel']==='A' ? 'taw-active-answer' : ''" mpGroup="correctAnswer-{{index}}"></mp-core-radio-button>
                </div>
                <div class="flex-col-6 flex-col-12-m margin-bottom-16 taw-answer-input">
                  <div>
                    <mp-core-input mpId="taw-answer-b" [ngClass]="question['answerB'] !== '' ? 'taw-not-empty' : ''" [(model)]="question['answerB']" dataPreText="B:" mpPlaceholder="{{ ls.locs['locTipAndWin'].AntwortBVerfassen }}" [mpRequired]="true" [hasError]="errors['questions['+index+'].answerB'] ? true : false"></mp-core-input>
                    <mp-core-form-error *ngIf="errors['questions['+index+'].answerB']" [model]="errors['questions['+index+'].answerB']"></mp-core-form-error>
                  </div>
                  <mp-core-radio-button name="correctAnswer-{{index}}" mpId="taw-answer-{{index}}-b-checkbox" [(model)]="question['isCorrectLabel']" value="B" [ngClass]="question['answerB'] === '' ? 'disabled pointer-events-none' : question['isCorrectLabel']==='B' ? 'taw-active-answer' : ''" mpGroup="correctAnswer-{{index}}"></mp-core-radio-button>
                </div>
                <div class="flex-col-6 flex-col-12-m margin-bottom-16 taw-answer-input">
                  <div>
                    <mp-core-input mpId="taw-answer-c" [ngClass]="question['answerC'] !== '' ? 'taw-not-empty' : ''" [(model)]="question['answerC']" dataPreText="C:" mpPlaceholder="{{ ls.locs['locTipAndWin'].AntwortCVerfassen }}" [mpRequired]="true" [hasError]="errors['questions['+index+'].answerC'] ? true : false"></mp-core-input>
                    <mp-core-form-error *ngIf="errors['questions['+index+'].answerC']" [model]="errors['questions['+index+'].answerC']"></mp-core-form-error>
                  </div>
                  <mp-core-radio-button name="correctAnswer-{{index}}" mpId="taw-answer-{{index}}-c-checkbox" [(model)]="question['isCorrectLabel']" value="C" [ngClass]="question['answerC'] === '' ? 'disabled pointer-events-none' : question['isCorrectLabel']==='C' ? 'taw-active-answer' : ''" mpGroup="correctAnswer-{{index}}"></mp-core-radio-button>
                </div>
                <div class="flex-col-6 flex-col-12-m taw-answer-input">
                  <div>
                    <mp-core-input mpId="taw-answer-d" [ngClass]="question['answerD'] !== '' ? 'taw-not-empty' : ''" [(model)]="question['answerD']" dataPreText="D:" mpPlaceholder="{{ ls.locs['locTipAndWin'].AntwortDVerfassen }}" [mpRequired]="true" [hasError]="errors['questions['+index+'].answerD'] ? true : false"></mp-core-input>
                    <mp-core-form-error *ngIf="errors['questions['+index+'].answerD']" [model]="errors['questions['+index+'].answerD']"></mp-core-form-error>
                  </div>
                  <mp-core-radio-button name="correctAnswer-{{index}}" mpId="taw-answer-{{index}}-d-checkbox" [(model)]="question['isCorrectLabel']" value="D" [ngClass]="question['answerD'] === '' ? 'disabled pointer-events-none' : question['isCorrectLabel']==='D' ? 'taw-active-answer' : ''" mpGroup="correctAnswer-{{index}}"></mp-core-radio-button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div (click)="addQuestion()" class="dashed-border-button margin-top-16">
        <p class="margin-0" [innerHTML]="ls.locs['locTipAndWin'].FrageHinzuefuegen"></p>
      </div>
    </section>
    <section id="mngr-text-editor-datapanel" class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-24">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full">

        <mp-core-select class="margin-bottom-24 width-full" label="{{ ls.locs['loc'].Aktion }}" mpId="aktionId" [elements]="campaigns" textProperty="Name" valueProperty="Name" [model]="campaign" (modelChange)="campaign = $event; getDayQuiz()" [mpRequired]="true">
        </mp-core-select>

        <mp-core-select *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages" class="width-full margin-bottom-16" mpId="mngr-text-editor-type" [(model)]="language" [elements]="languages" textProperty="Name" valueProperty="Kuerzel" [mpRequired]="true" label="{{ ls.locs['loc'].Sprache }}"></mp-core-select>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages" class="width-full float-right margin-top-40">
          <button class="mp-button ng-scope float-right" (click)="reset()">
            <i class="svg-icon __size-24 __clr-default __top-0 __refresh" id="mngr-text-editor-refresh-icon"></i>
          </button>
        </div>

        <div *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages" class="width-full float-right margin-top-0">
          <button class="mp-button ng-scope float-right" (click)="reset()">
            <i class="svg-icon __size-24 __clr-default __top-0 __refresh" id="mngr-text-editor-refresh-icon"></i>
          </button>
        </div>

        <button class="mp-button _prim margin-top-16 width-full" [attr.disabled]="!canSave() ? '' : null" [ngClass]="{disabled: !canSave()}" (click)="saveQuestions()">{{ ls.locs['loc'].SeiteVeroeffentlichen }}</button>
      </div>
    </section>
  </div>
</div>
