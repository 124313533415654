<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table title=""
                      *ngIf="loaderName !== ''"
                      fieldsAction="/api/Statistik/getBestelluebersichtChildFields"
                      listAction="/api/Statistik/getBestelluebersichtChild"
                      loaderName="{{loaderName}}"
                      [paging]="false"
                      [footer]="false"
                      [startEmpty]="true"
                      defaultSorting="Datum DESC, BestellNr DESC">
  </mp-core-data-table>
</div>
