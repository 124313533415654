import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import clone from 'clone';

import { MpAddressesService } from '../../../services/mp-addresses.service';
import { MpSidebarService } from './../../../components/sidebar/mp-sidebar.service';
import { MpLocalizationService } from './../../../services/mp-localization.service';



@Component({
  selector: 'mp-core-address-edit-area',
  templateUrl: './address-edit-area.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./address-edit-area.component.scss']
})

/**
 * This class provides the functions and data
 * for the edit area of addresses.
 */
export class AddressEditAreaComponent implements OnInit, OnChanges {

  @Input() public adr: any = null;
  @Input() public showSaveAnyway: boolean = false;
  @Input() public hasUnusedCorrections: boolean = false;

  private _paramsSubscription: Subscription | undefined;

  public editAddressCloneSet: boolean = false;

  public editAddress: any;
  public editAddressClone: any;

  constructor(
    public ls: MpLocalizationService,
    private _mpAddressesService: MpAddressesService,
    private _mpSidebarService: MpSidebarService
  ) {
    if (this.adr === null) {
      this.adr = this._mpAddressesService.mpAddresses;
    }
  }

  /**
   * Angulars init function of the class.
   */
  ngOnInit(): void {
    const params = this._mpSidebarService.params;
    this.editAddress = params['item'];
    this.editAddressClone = clone(this.editAddress);
    this.editAddressCloneSet = true;
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._paramsSubscription !== 'undefined') {
      this._paramsSubscription.unsubscribe();
    }
  }

  /**
   * A lifecycle hook that is called when a data-bound property of a directive changes.
   * Since the changes of class properties like hasUnusedCorrections, ddlData, Anreden, Anreden and land are processed
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (typeof this.adr.hasUnusedCorrections != 'undefined') {
      if (changes['adr.hasUnusedCorrections'].currentValue !== changes['adr.hasUnusedCorrections'].previousValue) {
        this.hasUnusedCorrections = this.adr.hasUnusedCorrections;
      }
    }
    if (typeof this.editAddress !== 'undefined') {
      if (changes['adr.ddlData'].currentValue !== changes['adr.ddlData'].previousValue) {
        this._mpAddressesService.validate(this.editAddressClone, {}, this.validationFail);
      }
    }
    if (typeof this.adr.ddlData.Anreden != 'undefined') {
      if (changes['adr.ddlData.Anreden'].currentValue !== changes['adr.ddlData.Anreden'].previousValue) {
        this.setSalutations();
      }
    }
    if (typeof this.editAddress.AdressArtID != 'undefined') {
      if (changes['adr.ddlData.Anreden'].currentValue !== changes['adr.ddlData.AdressArtID'].previousValue) {
        this.resetErrors();
      }
    }
    if (typeof this.editAddress != 'undefined') {
      if (changes['adr.ddlData'].currentValue !== changes['adr.ddlData'].previousValue) {
        this.resetErrors();
      }
    }
    if (typeof this.editAddress.Land != 'undefined') {
      if (changes['adr.ddlData.Land'].currentValue !== changes['adr.ddlData.Land'].previousValue) {
        (neu: any, alt: any) => {
          if (!neu || !alt)
            return;

          if (neu !== 'DE') {
            this.editAddress.editAddress.AdressArtID = 1;
          }
        }
      }
    }
  }

  /**
   * Checks address for correctness:
   * - if AdresArtId 1 is sent to the check and after addresses service
   * - otherwise validationSuccess method is called
   */
  validate() {
    this.editAddress = this.editAddressClone;
    
    if (this.editAddress.AdressArtID === 1) {
      this._mpAddressesService.validate(this.editAddress, this.validationSuccess.bind(this), this.validationFail.bind(this));
    }
    else {
      this.validationSuccess();
    };
  };

  /**
   * Saves address
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    if (!this._mpAddressesService.mpAddresses.hasUnusedCorrections) {
      this.validate();
    }
  };

  /**
   * Saves address without checks
   */
  saveWithoutValidation(evt: MouseEvent) {
    evt.preventDefault();
    this.validationSuccess();
  };

  /**
   * If validation failed, method is called from addresses service
   */
  validationFail() {
    this._mpAddressesService.checkCorrectedAddress();
    this.showSaveAnyway = true;
  };

  /**
   * After successful check, is saved in database via address service
   */
  validationSuccess() {
    this._mpAddressesService.mpAddresses.correctedAddress = {};
    this.showSaveAnyway = false;

    this.editAddress = this.editAddressClone;
    const hasCallback = this._mpSidebarService.params && (this._mpSidebarService.params['callback'] && typeof this._mpSidebarService.params['callback'] === 'function');

    this._mpAddressesService.save(this.editAddress, hasCallback ? true : false)
      .subscribe((data: any) => {
        if (hasCallback) {
          if (data !== false && typeof data.AdressID !== 'undefined') {
            this._mpSidebarService.params['callback'](data);
            this.editAddress = {};
            this._mpAddressesService.mpAddresses.correctedAddress = this.adr.correctedAddress;
            this._mpSidebarService.close();
          }
        } else {
          this._mpSidebarService.close();
        }
      });
  };

  /**
   * Updated salutation field in address data
   */
  updateSalutations() {
    this.editAddressClone.Anrede = this.editAddressClone.AnredeObjekt.Anrede;
  };

  /**
   * Sets salutation field in address data
   */
  setSalutations() {
    this._mpAddressesService.setSalutationsForLand(this.editAddressClone);
  };

  /**
   * Resets errors
   */
  resetErrors() {
    this.adr.errors = {};
  }

}
