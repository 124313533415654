import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { ApiService } from './../../../../services/api.service';
import { AuthService } from './../../../../services/auth.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the functions,
 * and the data for the communication
 * management table.
 */
@Component({
  selector: 'mp-core-communication-management',
  templateUrl: './communication-management.component.html',
  styleUrls: ['./communication-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommunicationManagementComponent implements OnInit, OnDestroy {

  public excelService = '/api/KommunikationProzess/Kommunikationsverwaltung2Excel';
  public communicationManagementLoader: MpLoader = {
    name: 'communicationManagementLoader',
    params: {}
  };
  public execClickFunctions: Array<any> = [
    {
      backendName: 'edit',
      func: this.edit.bind(this)
    },
    {
      backendName: 'loeschen',
      func: this.delete.bind(this)
    }
  ];
  public canDownloadStatistics: boolean = false;
  public role: string = '';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _getPermissionsSubscription: Subscription | undefined;
  private _deleteSubscription: Subscription | undefined;

  constructor(
    private _mpLoader: MpLoaderService,
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _apiService: ApiService,
    private _authService: AuthService,
    private _router: Router,
    private _mpMessaging: MpMessagingService,
    private _mpCoreService: MpCoreService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Registers the loader, loads permissions
   * and role, sets the title and the menu
   * highlighting.
   */
  ngOnInit(): void {
    this._mpLoader.registerLoader(this.communicationManagementLoader);
    this._mpLoader.extendLoader('communicationManagementLoader', {
      isReady: true
    });

    this._titleService.setTitleFromLoc('KommunikationProzess');
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
    this.role = this._authService.getRole();

    if (typeof this.role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(this.role);
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._deleteSubscription !== 'undefined') {
      this._deleteSubscription.unsubscribe();
    }
  }

  /**
   * Starts edit mode of the clicked
   * row.
   */
  edit(row: any): void {
    this._router.navigateByUrl(`/${this.role}/TeilnehmerAuswahl?id=${row['CommunicationId']}`);
  }

  /**
   * Delets the given row.
   */
  delete(row: any): void {
    this._deleteSubscription = this._apiService.getRequest(`/api/KommunikationProzess/DeleteKommunikation?communicationId=${row['CommunicationId']}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        const loader = this._mpLoader.getLoader('communicationManagementLoader');

        if (typeof loader !== 'undefined' && typeof loader.load !== 'undefined') {
          loader.load();
        }
      } else {
        this._mpMessaging.openErrorPanel(data, data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Goes back to the previous site.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('communicationManagementLoader');
  }

}
