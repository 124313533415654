<div mpCoreMpContentFullHeight *ngIf="(ls.locsLoaded | async)"
     class="layout-container manager-text-editor communication-process __check-communication position-relative">
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb"
         class="display-flex flex-col-12 flex-align-items-center margin-bottom-8 padding-left-4">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info"
           href="#/{{role}}/Home">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Kommunikationsprozess }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].KommunikationPruefenBreadcrumb }}</a>
      </div>
    </div>
    <mp-core-communication-process-stepper></mp-core-communication-process-stepper>

    <section class="flex-col-12 box-sizing-border-box padding-left-right-8 margin-bottom-16">
      <div class="bg-color-white width-full padding-left-right-16 padding-top-bottom-24">
        <h3>{{ ls.locs['loc'].KommunikationTitelHeadline }}</h3>
        <div class="width-full margin-top-8">
          <mp-core-input [(model)]="title"
                         label="{{ ls.locs['loc'].BenachrichtigungsTitel }}"
                         [mpRequired]="true"
                         mpPlaceholder="{{ ls.locs['loc'].Titel }} {{ ls.locs['loc'].VerfassenKlein }}">
          </mp-core-input>
        </div>
      </div>
    </section>

    <section class="flex-col-12 box-sizing-border-box padding-left-right-8 margin-bottom-16 margin-top-16">
      <div class="bg-color-white width-full padding-left-right-16 padding-top-bottom-24">
        {{ ls.locs['loc'].BenachrichtigungPruefenPt1Text }}
      </div>
    </section>

    <section id="mngr-text-editor-content" class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-16">
      <mp-core-accordion class="fallback-acc width-full"
                         [accToggle]="false"
                         [accDataExpression]="accData"
                         [sidebarAcc]="false"
                         [hidePaddingBottom]="true">
      </mp-core-accordion>
    </section>
    <section id="mngr-text-editor-datapanel"
             class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-16">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full padding-top-20">
        <div class="width-full float-right margin-top-40">
          <button class="mp-button ng-scope float-right"
                  (click)="cpShowResetConfirm()">
            <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
               id="mngr-text-editor-refresh-icon">
            </i>
          </button>
        </div>
        <a class="mp-button _sec margin-top-16 width-full"
           (click)="saveCommunicationProcess($event)">
          {{ ls.locs['loc'].Speichern }}
        </a>
        <a class="mp-button _prim margin-top-16 width-full"
           (click)="openSaveCpConfirm($event)">
          {{ ls.locs['loc'].BelohnungseditorVeroeffentlichenButton }}
        </a>
      </div>
    </section>
    <div class="flex-grid width-full-important hide-on-mobile position-absolute bottom-48 left-0">
      <div class="flex-col-9 flex-col-12-l flex-col-12-m padding-left-right-16 display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           (click)="cpShowClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
        <div>
          <a class="mp-button margin-right-16"
             *ngIf="cp !== null && cp.currentStep !== null"
             (click)="goToPreviousStep($event)">
            {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
          </a>
          <a class="mp-button _prim float-right"
             (click)="openSaveCpConfirm($event)">
            {{ ls.locs['loc'].BelohnungseditorVeroeffentlichenButton }}
          </a>
        </div>
      </div>
    </div>
    <div stickyBottom
         class="bestellung-nav-row floating-container show-on-mobile bg-color-white padding-top-bottom-0 width-full text-align-center wk-bottom-buttons">
      <a class="mp-button _prim padding-top-bottom-8 width-full display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
         (click)="saveCommunicationProcess($event, false)">
        <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
        <span class="display-block">{{ ls.locs['loc'].BelohnungseditorVeroeffentlichenButton }}</span>
      </a>
      <div class="width-full padding-left-right-8 box-sizing-border-box display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           *ngIf="cp !== null && cp.currentStep !== null"
           (click)="goToPreviousStep($event)">
          {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
        </a>
        <a class="mp-button"
           (click)="cpShowClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
      </div>
    </div>
  </div>

  <mp-core-modal [show]="showSaveCpConfirm"
            title="{{ ls.locs['loc'].KommunikationsprozessSaveConfirmTitle }}"
            [buttons]="[{text: ls.locs['loc'].Nein, action: setShowSaveCpConfirmFalse}, {text: ls.locs['loc'].Ja, action: saveCommunicationProcessApproved, class: '_prim'}]">
    {{ ls.locs['loc'].KommunikationsprozessSaveConfirmText }}
  </mp-core-modal>

  <mp-core-modal [show]="showTestMailModal"
            title="{{ ls.locs['loc'].TestMailVersenden }}"
            [buttons]="[{text: ls.locs['loc'].Abbrechen, action: setShowTestMailModalFalse}, {text: ls.locs['loc'].Senden, action: sendTestEMail, class: '_prim'}]">
    <mp-core-input type="text"
              mpPlaceholder="{{ ls.locs['loc'].EMailAdressedesTestempfaengers }}"
              class="margin-top-8 width-full"
              [(model)]="testMailData['Address']">
    </mp-core-input>
  </mp-core-modal>
</div>
