import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { DataTableChildsService } from '@core/components/data-table/data-table-childs.service';

import { WinnersOverviewComponent } from './../winners-overview/winners-overview.component';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the prize celendar
 * evalutation details table.
 */
@Component({
  selector: 'mp-gk-prize-calendar-evaluation-details',
  templateUrl: './prize-calendar-evaluation-details.component.html'
})
export class PrizeCalendarEvaluationDetailsComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Gewinnkalender/GewinnkalenderAuswertungDetails2Excel';
  public loader: MpLoader | null = null;
  public key: string = '';
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _prizeCalendarEvaluationDetailsLoader: MpLoader = {
    name: 'prizeCalendarEvaluationDetailsLoader',
    params: {}
  };
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoader: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _route: ActivatedRoute,
    private _dataTableChilds: DataTableChildsService
  ) { }

  /**
   * Sets the loader for the table data.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('/Views/manager/GewinnerUebersicht', WinnersOverviewComponent);
    this._mpLoader.registerLoader(this._prizeCalendarEvaluationDetailsLoader);
    
    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this.key = params['Key'];

      this._mpLoader.extendLoader('prizeCalendarEvaluationDetailsLoader', {
        isReady: true,
        params: {
          Key: params['Key']
        }
      });
      this.loader = this._mpLoader.getLoader('prizeCalendarEvaluationDetailsLoader');
    });

    this._titleService.setTitleFromLoc('GewinnkalenderAuswertung', 'locGewinnkalender');
    this._mpMenuHighlight.setMenuHighlight('statistiken');
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('prizeCalendarEvaluationDetailsLoader');
  }

}
