import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpPermissionsService } from '@core/services/mp-permissions.service';
import { ApiService } from '@core/services/api.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { TipAndWinService } from './../../services/tip-and-win.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the participant ranking 
 * table.
 */
@Component({
  selector: 'mp-taw-tip-and-win-performance',
  templateUrl: './tip-and-win-performance.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinPerformanceComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/TipAndWin/TeilnehmerPerformance2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = true;


  private _tipAndWinGamePerformanceLoader: MpLoader = {
    name: 'tipAndWinGamePerformanceLoader',
    params: {
      Aktion: ''
    }
  };
  //private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _tipAndWinService: TipAndWinService,
    //private _mpPermissions: MpPermissionsService,
    public mpSettings: MpSettingsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._tipAndWinGamePerformanceLoader);

    this._mpLoaderService.extendLoader('tipAndWinGamePerformanceLoader', {
      isReady: true,
      params: {
        Aktion: this._tipAndWinService.campaign
      }
    });

    //this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
    //  if (loaded) {
    //    this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
    //  }
    //});

    //this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    //if (typeof this._getPermissionsSubscription !== 'undefined') {
    //  this._getPermissionsSubscription.unsubscribe();
    //}
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('tipAndWinGamePerformanceLoader');
  }



}
