import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { Options, ChangeContext } from '@angular-slider/ngx-slider';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { TcFilter, TcFilterService } from '../../services/tc-filter.service';

/**
 * This class provides the functionalities
 * for the travel configurator filter.
 */
@Component({
  selector: 'mp-rk-travel-configurator-only-flight-filter',
  templateUrl: './travel-configurator-only-flight-filter.component.html',
  styleUrls: ['./../travel-configurator-filter/travel-configurator-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TravelConfiguratorOnlyFlightFilterComponent implements OnInit, OnDestroy, TcFilter {

  @Input() public filter: { [key: string]: any } = {};
  @Input() public options: { [key: string]: any } = {};
  @Input() public step: number = -1;
  @Input() public filterId: string = '';

  @Output() filterChange = new EventEmitter<{ [key: string]: any }>();
  @Output() filterHasChanged = new EventEmitter<boolean>();

  public rangeSliderOptions: Options = {
    stepsArray: [
      {
        value: 0
      },
      {
        value: 1
      }
    ],
    ceil: 1,
    showSelectionBar: true,
    showTicks: true,
    hideLimitLabels: true,
    hidePointerLabels: true
  };
  public rangeSliderOptionsOutboundFlight: Options = {
    step: 0.5,
    floor: 0,
    ceil: 24,
    showSelectionBar: true,
    showTicks: true,
    hideLimitLabels: true,
    hidePointerLabels: true
  };
  public rangeSliderOptionsInwardFlight: Options = {
    step: 0.5,
    floor: 0,
    ceil: 24,
    showSelectionBar: true,
    showTicks: true,
    hideLimitLabels: true,
    hidePointerLabels: true
  };
  public rangeSliderConfigDone: boolean = false;
  public defaultFilter: boolean = true;
  public orderableTravels: boolean = false;
  public showAirlines: boolean = false;
  public showAirplanes: boolean = false;
  public showExtras: boolean = false;
  public tempFilter: { [key: string]: any } = {};
  public outboundFlightSliderValue = 0;
  public outboundFlightSliderHeightValue = 24;
  public inwardFlightSliderValue = 0;
  public inwardFlightSliderHeightValue = 24;
  public filterPoints: number = 0;

  private _accountBalance: number = -1;
  private _getMenuDataSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _filterService: TcFilterService
  ) { }

  /**
   * Gets the account balance from the
   * menu data.
   */
  ngOnInit(): void {
    this._getMenuDataSubscription = this._apiService.getRequest('/api/Teilnehmer/getMenuData').subscribe((data: any) => {
      this._accountBalance = data.Records[0].Kontostand;
      this._filterService.updateRangeSliderOptions(this, this._accountBalance);
      let oldOptions = JSON.parse(JSON.stringify(this.filter));
      this._changeOptions();

      if (!(Object.keys(oldOptions).length === 1 && oldOptions.Punkte === this.filter['Punkte'])) {
        this.filterHasChanged.emit();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getMenuDataSubscription !== 'undefined') {
      this._getMenuDataSubscription.unsubscribe();
    }
  }

  /**
   * Handles clicks on the options.
   */
  optionClicked(): void {
    this._changeOptions();
    this.filterHasChanged.emit(false);
  }

  /**
   * Handles clicks on the filters.
   */
  filterClicked(keepFilters: boolean, changeContext?: ChangeContext): void {
    if (typeof changeContext !== 'undefined') {
      this.filter['Punkte'] = changeContext.value;
    }

    this._changeFilters();
    this.filterHasChanged.emit(keepFilters);
  }

  /**
   * Resets the filter.
   */
  resetFilter(): void {
    if (Object.keys(this.options).length === 0)
      return;

    this._filterService.updateRangeSliderOptions(this, this._accountBalance);
    this.filter['Stops'] = null

    this.tempFilter['anzahlStops'] = this._getDefaultStopsCount();

    this.orderableTravels = false;

    this.outboundFlightSliderValue = 0;
    this.outboundFlightSliderHeightValue = 24;
    this.inwardFlightSliderValue = 0;
    this.inwardFlightSliderHeightValue = 24;

    this.selectNoAirlines();
    this.selectNoAirplanes();

    this.options['Klassen'].forEach((a: any) => {
      a['Active'] = false;
    });

    this._changeOptions();
    this.filterHasChanged.emit(true);
  }

  /**
   * Sets all options of the airlines
   * array to not active.
   */
  selectNoAirlines(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    this.options['Airlines'].forEach((option: any) => {
      option['Active'] = false;
    });

    this._changeOptions();
    this.filterHasChanged.emit(false);
  }

  /**
   * Sets all options of the airlines
   * array to active.
   */
  selectAllAirlines(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    this.options['Airlines'].forEach((option: any) => {
      option['Active'] = true;
    });

    this._changeOptions();
    this.filterHasChanged.emit(false);
  }

  /**
   * Sets all options of the airplanes
   * array to not active.
   */
  selectNoAirplanes(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    this.options['Airplanes'].forEach((option: any) => {
      option['Active'] = false;
    });

    this._changeOptions();
    this.filterHasChanged.emit(false);
  }

  /**
   * Sets all options of the airplanes
   * array to active.
   */
  selectAllAirplanes(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    this.options['Airplanes'].forEach((option: any) => {
      option['Active'] = true;
    });

    this._changeOptions();
    this.filterHasChanged.emit(false);
  }

  /**
   * Checks whether or not an option
   * is selectable
   */
  private _isNoneSelected(options: Array<any>): boolean {
    const foundActive = options.find((option: any) => {
      return option['Active'] === true;
    });

    return typeof foundActive === 'undefined' ? true : false;
  }

  /**
   * Filters the offers by orderable.
   */
  onlyOrderableTravels(): void {
    if (this.orderableTravels && typeof this.rangeSliderOptions.ceil !== 'undefined') {
      this.filter['Punkte'] = Math.min(this._accountBalance, this.rangeSliderOptions.ceil);
    } else if (this.filter['Punkte'] === this._accountBalance) {
      this.filter['Punkte'] = this.rangeSliderOptions.ceil;
    }

    this.filterPoints = this.filter['Punkte'];
    this._changeFilters();
    this.filterHasChanged.emit(false);
  }

  /**
   * Triggers the change of options, and
   * the filtering of the results.
   */
  private _changeOptions(): void {
    this._setFilter();
    this._changeFilters();
  }

  /**
   * Triggers the change of filters, and
   * the filtering of the results.
   */
  private _changeFilters(): void {
    if (this.filter['Punkte'] !== this._accountBalance) {
      this.orderableTravels = false;
    }

    this._setDefaultFilter();
  }

  /**
   * Sets the default filter.
   */
  private _setDefaultFilter(): void {
    if (Object.keys(this.options).length === 0)
      return;

    let defaultFilter = (this.filter['Punkte'] === this.rangeSliderOptions.ceil);
    defaultFilter = defaultFilter && JSON.stringify(this.tempFilter['anzahlStops']) === JSON.stringify(this._getDefaultStopsCount());
    defaultFilter = defaultFilter && (this.outboundFlightSliderValue === 0);
    defaultFilter = defaultFilter && (this.outboundFlightSliderHeightValue === 24);
    defaultFilter = defaultFilter && (this.inwardFlightSliderValue === 0);
    defaultFilter = defaultFilter && (this.inwardFlightSliderHeightValue === 24);
    defaultFilter = defaultFilter && this._isNoneSelected(this.options['Airlines']);
    defaultFilter = defaultFilter && this._isNoneSelected(this.options['Klassen']);

    this.defaultFilter = defaultFilter;
  }

  /**
   * Gets the count of stops.
   */
  private _getDefaultStopsCount(): { [key: string]: any } {
    if (Object.keys(this.options).length === 0) {
      return {};
    }

    const count =  Math.max(...this.options['Stops'].map((option: any) => {
      return option['Value'];
    }));

    const foundCount = this.options['Stops'].find((stops: any) => {
      return stops['Value'] === count;
    });

    return typeof foundCount !== 'undefined' ? foundCount : {};
  }

  /**
   * Sets the filter.
   */
  private _setFilter(): void {
    if (Object.keys(this.options).length === 0)
      return;

    this._filterService.updateRangeSliderOptions(this, this._accountBalance);
    this.filter['Punkte'] = this.rangeSliderOptions.ceil;
    this.filterPoints = this.filter['Punkte'];
    this.filter['HinflugRange'] = [this.outboundFlightSliderValue, this.outboundFlightSliderHeightValue];
    this.filter['RueckflugRange'] = [this.inwardFlightSliderValue, this.inwardFlightSliderHeightValue];
    this.filter['Zwischenstopps'] = Object.keys(this.tempFilter).length > 0 && this.tempFilter['anzahlStops'] && this.tempFilter['anzahlStops'].Value;

    this.filter['Airlines'] = Object.keys(this.options).length > 0 && this.options['Airlines'].filter((airline: any) => {
      return airline['Active'] === true;
    }).map((activeAirline: any) => {
      return activeAirline['Value'];
      });

    this.filter['Klassen'] = Object.keys(this.options).length > 0 && this.options['Klassen'].filter((classes: any) => {
      return classes['Active'] === true;
    }).map((activeClasses: any) => {
      return activeClasses['Value'];
      });

    this.filter['Airplanes'] = Object.keys(this.options).length > 0 && this.options['Airplanes'].filter((airplane: any) => {
      return airplane['Active'] === true;
    }).map((activeAirplane: any) => {
      return activeAirplane['Value'];
    });

    this._setDefaultFilter();
  }

}
