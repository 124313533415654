<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async) && configLoaded">
  <iframe *ngIf="config['HasExternalLink'] && config['ExternalLinkIsDocument']" src="{{config['ExternalLink']}}" width="100%" height="700px"></iframe>
  <section *ngIf="!config['HasExternalLink'] && dataLoaded" id="teilnahmebedingungen" class="layout-container bg-color-white padding-bottom-48">
    <div class="white-content-container">
      <mp-core-breadcrumbs></mp-core-breadcrumbs>
      <h1>{{ ls.locs['loc'].Teilnahmebedingungen }}</h1>
      <div>
        <div class="accordion">
          <ul class="accordion-list">
            <li id="{{tbThema.ThemenNr}}" *ngFor="let tbThema of data; let last = last" [isLast]="last" (afterRenderItem)="scrollToHash()" class="margin-bottom-16">
              <h3 [innerHTML]="tbThema.ThemenNr + '. '+tbThema.Thema"></h3>
              <ul class="accordionitem">
                <li *ngFor="let text of tbThema.Texte" [innerHTML]="text"></li>
              </ul>
              <br />
            </li>
          </ul>
        </div>
        <div *ngIf="mpSettings.settings['DisplaySettings'].ShowDisableTrackingSwitch && mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik">
          <mp-core-checkbox class="margin-bottom-24" mpId="datenschutzCheckbox" [model]="trackingOn" (modelChange)="trackingOn = $event; setCookie()" label="{{ ls.locs['loc'].DisableCookieText }}"></mp-core-checkbox>
        </div>
      </div>
    </div>
  </section>

</div>
