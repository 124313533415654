<div id="card-category" class="margin-bottom-32" *ngIf="(ls.locsLoaded | async)">
  <h2>{{ ls.locs['loc'].IhreVorteileBeiUns }}</h2>

  <owl-carousel-o *ngIf="features.length > 0" id="feature-carousel" class="owl-theme" [options]="owlOptions">
    <ng-container *ngFor="let feature of features">
      <ng-template carouselSlide>
        <div class="item">
          <mp-core-feature-card [feature]="feature"></mp-core-feature-card>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>

</div>
