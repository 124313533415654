<section *ngIf="(ls.locsLoaded | async)" class="layout-container flex-grid" id="error-404" mpCoreErrorPage #ePage="mpCoreErrorPage">
  <aside class="flex-col-12 flex-align-items-center flex-direction-column">
    <i class="svg-icon __clr-info __size-62 __error-outline"></i>
    <div class="text-align-center margin-top-16">
      <h1>{{ ls.locs['loc'].KeinZugriffAufDieseSeite }}</h1>
      <p *ngIf="ePage.isParticipant">{{ ls.locs['loc'].PraemienEntdecken }}</p>
    </div>
  </aside>
  <div *ngIf="ePage.isParticipantInfoAvailable && ePage.isParticipant" class="flex-col-12 flex-grid flex-justify-cont-center margin-top-56 padding-left-right-0">
    <article class="flex-col-12 flex-justify-cont-center margin-top-56">
      <a id="error-page-button" href="/#/{{role}}/Shop" class="mp-button _grey">{{ ls.locs['loc'].WeiterEinkaufen }}</a>
    </article>
    <div *ngif="mpSettings.settings['NetcentiveCoreSettings'].HasNeuheitenKategorie">
      <article class="flex-col-12 flex-justify-cont-center margin-top-88 error-slider display-block-important padding-left-right-0">
        <mp-core-newest-awards [count]="8"></mp-core-newest-awards>
      </article>
    </div>
    <div *ngif="mpSettings.settings['NetcentiveCoreSettings'].HasTopsellerKategorie">
      <article class="flex-col-12 flex-justify-cont-center error-slider text-align-left display-block-important padding-left-right-0">
        <mp-core-topseller-awards [count]="8"></mp-core-topseller-awards>
      </article>
    </div>
  </div>
  <article *ngIf="ePage.isParticipantInfoAvailable && !ePage.isParticipant" class="flex-col-12 flex-justify-cont-center margin-top-56">
    <a id="error-page-button" href="/" class="mp-button _grey">
      {{ ls.locs['loc'].ZurueckZurStartseite }}
    </a>
  </article>
</section>>
