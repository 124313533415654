import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpCoreService } from '@core/services/mp-core.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { TcSearchParamsService } from './../../../../services/tc-search-params.service';

/**
 * This class provides the data and functions
 * for the rental check page.
 */
@Component({
  selector: 'mp-rk-rental-check',
  templateUrl: './rental-check.component.html',
  styleUrls: [
    './../../../styles/elements/rk-select.scss',
    './../../../styles/sites/rk-main-page-styles.scss',
    './../../../styles/sites/rk-two-column-pages.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../styles/sites/rk-browser-hacks.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RentalCheckComponent implements OnInit, OnDestroy {

  public showAnnotations: boolean = false;
  public countries: Array<any> = [];
  public pointWorth: number = 0;
  public checkingParameters: { [key: string]: any } = {};
  public offer: { [key: string]: any } = {};
  public totalPoints: number = 0;
  public salutations: Array<any> = [];
  public loading: Array<boolean> = [];
  public conditionsInvalid: boolean = false;
  public role: string = '';
  public offerLoaded: boolean = false;
  public checkingParametersWishes: string = '';
  public gearBox: { [key: string]: any } = {};
  public isValid: boolean = true;
  public bookFunc = this.book.bind(this);
  public validateFunc = this.validate.bind(this);
  public routeParams: { [key: string]: any } = {};
  public getEnumsSubscription: Subscription | undefined;
  public getMenudataSubscription: Subscription | undefined;
  public routeParamsSubscription: Subscription | undefined;
  public getFirstAdultParticipantSubscription: Subscription | undefined;
  public getOfferSubscription: Subscription | undefined;
  public getSalutationsSubscription: Subscription | undefined;
  public getLanguagesSubscription: Subscription | undefined;
  public bookingSubscription: Subscription | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    public mpCoreService: MpCoreService,
    public svgLoader: SvgLoaderService,
    public mpEnums: MpEnumsService,
    public mpMenuRefresh: MpMenuRefreshService,
    public apiService: ApiService,
    public mpMessaging: MpMessagingService,
    public tcSearchParamsService: TcSearchParamsService,
    public route: ActivatedRoute,
    public roleMapping: RoleMappingService,
    public authService: AuthService,
    public router: Router
  ) { }

  /**
   * Gets the enums from the backend,
   * gets the route params, and gets
   * the role
   */
  ngOnInit(): void {
    this.svgLoader.startLoading(2);
    this.loading.push(true);
    this.loading.push(true);

    const role = this.authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this.roleMapping.getReverseMappedRole(role);
    }

    this.routeParamsSubscription = this.route.queryParams.subscribe((params: any) => {
      this.routeParams = params;

      if (typeof this.routeParamsSubscription !== 'undefined') {
        this.routeParamsSubscription.unsubscribe();
      }

      if (Object.keys(this.mpEnums.enums).length > 0) {
        this.doInitialFunctions();
      } else {
        this.getEnumsSubscription = this.mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            if (Object.keys(this.mpEnums.enums).length > 0) {
              if (typeof this.getEnumsSubscription !== 'undefined') {
                this.getEnumsSubscription.unsubscribe();
              }
            }

            this.doInitialFunctions();
          }
        });

        this.mpEnums.getEnums();
      }
    });
  }

  /**
   * Gets the needed data for the
   * checking page.
   */
  doInitialFunctions(): void {
    if (this.mpMenuRefresh.menuData !== null) {
      this.pointWorth = this.mpMenuRefresh.menuData['Kontostand'];
    } else {
      this.getMenudataSubscription = this.mpMenuRefresh.loadMenuData(false).subscribe((loaded: boolean) => {
        if (loaded) {
          this.pointWorth = this.mpMenuRefresh.menuData['Kontostand'];
        }
      });
    }

    this.gearBox = this.mpEnums.enums['Gangschaltung'];

    this.checkingParameters = {
      Erwachsene: [this.getPerson(0)],
      Wuensche: '',
      BedingungenAkzeptiert: false,
      AngebotsId: this.routeParams['ref']
    };

    this.checkingParametersWishes = this.checkingParameters['Wuensche'];

    if (sessionStorage['pruefungsParameter'] && typeof sessionStorage['pruefungsParameter'] !== 'undefined') {
      const savedPp = JSON.parse(sessionStorage['pruefungsParameter']);
      let noPersonsSet = true;

      if (savedPp['Erwachsene'] && savedPp['Erwachsene'].length) {
        for (let i = 0;
          i < Math.min(savedPp['Erwachsene'].length, this.checkingParameters['Erwachsene'].length);
          i++) {
          this.updatePerson(this.checkingParameters['Erwachsene'][i], savedPp['Erwachsene'][i]);
        }
        noPersonsSet = false;
      }

      if (noPersonsSet)
        this.loadParticipantData();

    } else {
      this.loadParticipantData();
    }

    this.checkingParameters['Erwachsene'].forEach((person: any) => {
      if (person['Geburtsdatum']) {
        person['Geburtsdatum'] = moment(person['Geburtsdatum']);
      }
    });

    this.getOfferSubscription = this.apiService.getRequest('/api/RkMietwagen/GetSingleMietwagen', false, {
      params: {
        bookingKey: this.routeParams['ref']
      }
    }).subscribe((data: any) => {
      this.svgLoader.finishLoading();
      this.loading.pop();

      if (data.Result === 'OK' && data.Records[0]) {
        this.offer = data.Records[0];
        this.updatePoints();
        this.offerLoaded = true;
      } else {
        window.history.back();
      }
    },
    (error: any) => {
      this.svgLoader.finishLoading();
      this.loading.pop();
      window.history.back();
    });

    this.getSalutationsSubscription = this.apiService.getRequest('/api/Teilnehmer/GetAnreden').subscribe((data: any) => {
      this.getLanguagesSubscription = this.apiService.getRequest('/api/Language/GetLanguages').subscribe((langData: any) => {
        const languages = langData.Records[0];
        const language = languages['Language'] || 'de';

        const salutationsFiltered = data.Records.filter((salutation: any) => {
          return salutation['Language'] === language;
        });

        const salutationsReduced = salutationsFiltered.reduce((r: any, v: any, i: number, a: any[], k: any = v['Anrede']) => ((r[k] || (r[k] = [])).push(v), r), {});

        this.salutations = [];

        Object.keys(salutationsReduced).forEach((key: string) => {
          this.salutations.push(salutationsReduced[key][0]);
        });

        this.svgLoader.finishLoading();
        this.loading.pop();
      });
    },
    (error: any) => {
      this.svgLoader.finishLoading();
      this.loading.pop();
    });

    this.onCheckingParametersrChanged(this.checkingParameters);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.getEnumsSubscription !== 'undefined') {
      this.getEnumsSubscription.unsubscribe();
    }

    if (typeof this.getMenudataSubscription !== 'undefined') {
      this.getMenudataSubscription.unsubscribe();
    }

    if (typeof this.routeParamsSubscription !== 'undefined') {
      this.routeParamsSubscription.unsubscribe();
    }

    if (typeof this.getFirstAdultParticipantSubscription !== 'undefined') {
      this.getFirstAdultParticipantSubscription.unsubscribe();
    }

    if (typeof this.getOfferSubscription !== 'undefined') {
      this.getOfferSubscription.unsubscribe();
    }

    if (typeof this.getSalutationsSubscription !== 'undefined') {
      this.getSalutationsSubscription.unsubscribe();
    }

    if (typeof this.getLanguagesSubscription !== 'undefined') {
      this.getLanguagesSubscription.unsubscribe();
    }

    if (typeof this.bookingSubscription !== 'undefined') {
      this.bookingSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the updating of the check params.
   */
  checkParamChanged(): void {
    this.onCheckingParametersrChanged(this.checkingParameters);
  }

  /**
   * Triggers the updating of the check params.
   */
  checkParamsChangedSidebar(): void {
    this.onCheckingParametersrChanged(this.checkingParameters);
  }

  /**
   * Triggers the global goBack function.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpCoreService.goBack();
  }

  /**
   * Updates the params in session storage.
   */
  onCheckingParametersrChanged(newVal: { [key: string]: any }): void {
    sessionStorage['pruefungsParameter'] = JSON.stringify(newVal);
  }

  /**
   * Updates the points.
   */
  updatePoints(): void {
    if (Object.keys(this.offer).length > 0) {
      let price = this.offer['Punkte'];
      this.totalPoints = price;
    }
  }

  /**
   * Gets the data for one person.
   */
  getPerson(id: number, age?: number): { [key: string]: any } {
    return {
      Id: id + 1,
      AnredeId: 0,
      Name: '',
      Vorname: '',
      Alter: age,
      Geburtsdatum: '',
      IsKind: !!age || age === 0,
      Nationalitaet: '',
      Wohnsitz: '',
      NationalitaetRequired: false,
      WohnsitzRequired: false
    }
  }

  /**
   * Updates the given person.
   */
  updatePerson(target: { [key: string]: any }, src: { [key: string]: any }): void {
    if (!src || typeof src === 'undefined') {
      return;
    }

    target['AnredeId'] = src['AnredeId'];
    target['Name'] = src['Name'];
    target['Vorname'] = src['Vorname'];
    target['Alter'] = src['Alter'];
    target['Geburtsdatum'] = src['Geburtsdatum'] && src['Geburtsdatum'] !== '1900-01-01T00:00:00' ? moment(src['Geburtsdatum']) : '';
    target['IsKind'] = src['IsKind'];
    target['Nationalitaet'] = src['Nationalitaet'];
    target['Wohnsitz'] = src['Wohnsitz'];
  }

  /**
   * Loads the participant data.
   */
  loadParticipantData(): void {
    this.getFirstAdultParticipantSubscription = this.apiService.getRequest('/api/RkCommon/getErsterErwachsener').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.updatePerson(this.checkingParameters['Erwachsene'][0], data.Records[0]);
      }
    });
  }

  /**
   * Checks whether the given person
   * is valid.
   */
  isPersonValid(p: { [key: string]: any }): boolean {
    let isValid = true;

    p['AnredeRequired'] = false;
    p['VornameRequired'] = false;
    p['NameRequired'] = false;
    p['GeburtsdatumRequired'] = false;

    if (!p['AnredeId']) {
      p['AnredeRequired'] = true;
      isValid = false;
    }

    if (!p['Vorname']) {
      p['VornameRequired'] = true;
      isValid = false;
    }

    if (!p['Name']) {
      p['NameRequired'] = true;
      isValid = false;
    }

    if (!p['Geburtsdatum']) {
      p['GeburtsdatumRequired'] = true;
      isValid = false;
    }

    return isValid;
  }

  /**
   * Validates the data.
   */
  validate(): boolean {
    let isValid = true;
    this.conditionsInvalid = false;

    this.checkingParameters['Erwachsene'].forEach((e: any) => {
      if (!this.isPersonValid(e)) {
        isValid = false;
      }
    });

    if (!this.checkingParameters['BedingungenAkzeptiert']) {
      this.conditionsInvalid = true;
      isValid = false;
    }

    if (this.totalPoints > this.pointWorth) {
      isValid = false;
    }

    return isValid;
  }

  /**
   * Does the booking process.
   */
  book(): void {
    this.isValid = true;
    this.checkingParameters['Wuensche'] = this.checkingParametersWishes;

    if (this.validate()) {
      this.svgLoader.startLoading();
      this.loading.push(true);

      this.bookingSubscription = this.apiService.postRequest('/api/RkMietwagen/Buchen', this.checkingParameters).subscribe((data: any) => {
        if (data.Result === 'OK') {
          const orderNo = data.Records[0];
          this.router.navigateByUrl(`/${this.role}/MietwagenBestaetigung?bestellNr=${orderNo}`);
        } else {
          this.mpMessaging.openPanelFromResultResponse(data);
        }

        this.svgLoader.finishLoading();
        this.loading.pop();
      },
      (error: any) => {
        this.isValid = false;
        this.svgLoader.finishLoading();
        this.loading.pop();
      });
    } else {
      this.isValid = false;
    }
  }

}
