import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';
import { ParticipantCockpitService } from './../../../manager/pages/participant-cockpit/participant-cockpit.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the account statistic 
 * table.
 */
@Component({
  selector: 'mp-core-account-statistic',
  templateUrl: './account-statistic.component.html'
})
export class AccountStatisticComponent implements OnInit, OnDestroy {
  
  public excelService: string = '/api/Statistik/KontoStatistik2Excel';
  public loader: MpLoader | null = null;
  public deleteId: number = 0;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;
  public confirmDelete: boolean = false;
  public toggleDeleteAccount = this._toggleDeleteAccount.bind(this);
  public deleteAccount = this._deleteAccount.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'openCockpit',
      func: this.openCockpit.bind(this)
    },
    {
      backendName: 'toggleDeleteKonto',
      func: this.toggleDeleteAccount
    }
  ];

  private _adminAccountStatisticLoader: MpLoader = {
    name: 'adminAccountStatisticLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;
  private _deleteAccountSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _participantCockpitService: ParticipantCockpitService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('KontoStatistik');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._adminAccountStatisticLoader);

    this._mpLoaderService.extendLoader('adminAccountStatisticLoader', {
      isReady: true
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._deleteAccountSubscription !== 'undefined') {
      this._deleteAccountSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('adminAccountStatisticLoader');
  }

  /**
   * Openes the given particpant within
   * the particpant cockpit.
   */
  openCockpit(participant: { [key: string]: any }): void {
    this._participantCockpitService.showParticipantCockpit(participant['TnId']);
  }

  /**
   * Toggles the delete account modal.
   */
  private _toggleDeleteAccount(row: { [key: string]: any }): void {
    this.confirmDelete = !this.confirmDelete;
    this.deleteId = row['Id'];
  }

  /**
   * Deletes the selected account.
   */
  private _deleteAccount(): void {
    this._deleteAccountSubscription = this._apiService.getRequest(`/api/Statistik/DeleteKonto?id=${this.deleteId}`).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this.confirmDelete = false;

      if (this.loader !== null) {
        if (typeof this.loader.load !== 'undefined') {
          this.loader.load();
        }
      }
    });
  }

}
