import { Component, OnInit, Input } from '@angular/core';

import { MpSettingsService } from './../../../../../services/mp-settings.service';
import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { AuthService } from './../../../../../services/auth.service';
import { RoleMappingService } from './../../../../../services/role-mapping.service';

import { ShoppingBasketItem } from './../shopping-basket-item';

/**
 * This class provides the shopping
 * basket item for the order process.
 */
@Component({
  selector: 'mp-core-sb-item',
  templateUrl: './sb-item.component.html'
})
export class SbItemComponent implements OnInit {

  // @ts-ignore
  @Input() public item: ShoppingBasketItem;

  public role: string = '';
  public itemCount: string = '';

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Converts the items count from
   * number into string.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this.itemCount = this.item.Anzahl.toString();
  }

}
