import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSettingsService } from './../../../services/mp-settings.service';
import { MpStatusService } from './../../../services/mp-status.service';
import { AuthService } from './../../../services/auth.service';
import { RoleMappingService } from './../../../services/role-mapping.service';

import { Tile } from '../shop-card/tile';

/**
 * This class provides the tile of a
 * wishlist card, that can be used within
 * the wishlist cards grid.
 */
@Component({
  selector: 'mp-core-wishlist-card',
  templateUrl: './wishlist-card.component.html'
})
export class WishlistCardComponent implements OnInit, OnDestroy {

  public hasStatus: boolean = false;
  public showIconLegend: boolean = false;
  public role: string = '';

  @Input() public wishCard: Tile | undefined;
  @Input() public addToShoppingbasketFunc: Function | undefined;
  @Input() public deleteItemFunc: Function | undefined;
  @Input() public mpClick: Function | undefined;

  private _statusSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpStatus: MpStatusService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Checks whether or not the user can
   * see status information.
   */
  ngOnInit(): void {
    if (typeof this._mpStatus.currentHasStatus !== 'undefined') {
      this.hasStatus = this._mpStatus.currentHasStatus;
    } else {
      this._statusSubscription = this._mpStatus.status.subscribe((status: boolean) => {
        this.hasStatus = status;
      });

      this._mpStatus.hasStatus();
    }

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._statusSubscription !== 'undefined') {
      this._statusSubscription.unsubscribe();
    }
  }

  /**
   * Deletes the given item from
   * the wishlist.
   */
  deleteItem(vlID: number | undefined, evt: MouseEvent): void {
    if (typeof this.deleteItemFunc !== 'undefined' && typeof vlID !== 'undefined') {
      this.deleteItemFunc(vlID, evt);
    }
  }

  /**
   * Adds the given wishlist item
   * to the shopping basket.
   */
  addToShoppingbasket(wishlistItem: Tile): void {
    if (typeof this.addToShoppingbasketFunc !== 'undefined') {
      this.addToShoppingbasketFunc(wishlistItem);
    }
  }

  /**
   * Shows the icon legend on mouseover.
   */
  showIconLegendFnc(): void {
    // @ts-ignore
    if (!document.documentElement.classList.contains('touch')) {
      this.showIconLegend = true;
    } else {
      this.showIconLegend = false;
    }
  }

  /**
   * Triggers the click event of the
   * components @Input, when the user
   * clicks on the tile.
   */
  onClick(evt: MouseEvent): void {
    this.mpClick && this.mpClick(evt, this.wishCard);
  }

}
