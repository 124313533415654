import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import moment from 'moment';
import dayjs from "dayjs";
import * as localeDe from 'dayjs/locale/de';
import * as localeEn from 'dayjs/locale/en';
import * as localeEs from 'dayjs/locale/es';
import * as localeFr from 'dayjs/locale/fr';
import * as localeIt from 'dayjs/locale/it';
import * as localePt from 'dayjs/locale/pt';
import * as localeRu from 'dayjs/locale/ru';

import { ApiService } from './api.service';

/**
 * This class provides the data and functions
 * for the localization.
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _locale: string = '';
  private _getLanguagesSubscription: Subscription | undefined;

  constructor(
    @Inject(DOCUMENT) private _dom: any,
    private _apiService: ApiService
  ) { }

  /**
   * Triggers the getting and setting
   * of the locale.
   */
  setLocale(): any {
    return () => {
      return this._setLocale().then((locale: string) => {
        this._locale = locale;
      });
    }
  }

  /**
   * Sets the locale.
   */
  private _setLocale(): Promise<string> {
    return new Promise(async (resolve: any, reject: any) => {
      this._getLanguagesSubscription = this._apiService.getRequest('/api/Language/GetLanguages').subscribe((data: any) => {
        const languages = data.Records[0];
        const lang = languages['Language'] || 'de';
        const locale = languages['LanguageRessourceKuerzel'] || 'de_DE';
        moment.locale(lang);

        switch (lang) {
          case 'de':
            dayjs.locale(localeDe);
            break;
          case 'en':
            dayjs.locale(localeEn);
            break;
          case 'es':
            dayjs.locale(localeEs);
            break;
          case 'fr':
            dayjs.locale(localeFr);
            break;
          case 'it':
            dayjs.locale(localeIt);
            break;
          case 'pt':
            dayjs.locale(localePt);
            break;
          case 'ru':
            dayjs.locale(localeRu);
            break;
          default:
            dayjs.locale(localeDe);
            break;
        }

        this._dom.documentElement.lang = lang;
        resolve(locale);

        if (typeof this._getLanguagesSubscription !== 'undefined') {
          this._getLanguagesSubscription.unsubscribe();
        }
      },
      (errror: any) => {
        reject('de-DE');
      });
    });
  }

  /**
   * Gets the set locale.
   */
  getLocale(): string {
    return this._locale;
  }
}
