import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the special arrangement
 * agency agreement table.
 */
@Component({
  selector: 'mp-leas-special-arrangement-agency-agreement',
  templateUrl: './special-arrangement-agency-agreement.component.html'
})
export class SpecialArrangementAgencyAgreementComponent implements OnInit, OnDestroy {

  public showReactivation: boolean = false;
  public showConfirmReactivation: boolean = false;
  public excelService: string = '/api/Teilnehmer/SonderregelungAgenturvertrag2Excel';
  public loader: MpLoader | null = null;
  public reactivationConfirmText: string = '';
  public today: Date = new Date();
  public reactivation: { [key: string]: any } = {};
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public confirmReactivation = this._confirmReactivation.bind(this);
  public okConfirmReactivation = this._okConfirmReactivation.bind(this);
  public cancelReactivation = this._cancelReactivation.bind(this);
  public cancelReactivationConfirm = this._cancelReactivationConfirm.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'Reaktivieren',
      func: this.reactivate.bind(this)
    }
  ];

  private _specialArrangementAgencyAgreementLoader: MpLoader = {
    name: 'specialArrangementAgencyAgreementLoader',
    params: {}
  };
  private _saveReactivationSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._specialArrangementAgencyAgreementLoader);

    this._mpLoaderService.extendLoader('specialArrangementAgencyAgreementLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._saveReactivationSubscription !== 'undefined') {
      this._saveReactivationSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('specialArrangementAgencyAgreementLoader');
  }

  /**
   * Opens the confirmation dialog for
   * the reactivation.
   */
  private _confirmReactivation(): void {
    this.reactivationConfirmText = this.ls.locs['locLeas'].ReaktivierungBestaetigen
      .replace('[Benutzer]', this.reactivation['Benutzer'])
      .replace('[Enddatum]', this.reactivation['ReaktivierungString']);

    this.showConfirmReactivation = true;
  }

  /**
   * Processes the reactivation.
   */
  private _okConfirmReactivation(): void {
    this._saveReactivationSubscription = this._apiService.postRequest('/api/Teilnehmer/reaktivieren', this.reactivation).subscribe((data: any) => {
      if (data.Result === 'ERROR') {
        this._mpMessaging.openDangerPanel(data.Message);
      } else {
        this._mpMessaging.openSuccessPanel(data.Message);
        this._cancelReactivation();
        this._cancelReactivationConfirm();

        if (this.loader !== null) {
          if (typeof this.loader.load !== 'undefined') {
            this.loader.load();
          }
        }
      }
    });
  }

  /**
   * Cancels the reactivation.
   */
  private _cancelReactivation(): void {
    this.reactivation = {};
    this.showReactivation = false;
  }

  /**
   * Canels the reactivation confirm.
   */
  private _cancelReactivationConfirm(): void {
    this.showConfirmReactivation = false;
    this.reactivationConfirmText = '';
  }

  /**
   * Openes the reactivation modal.
   */
  reactivate(row: any): void {
    if (row && row['TnId']) {
      if (row['Aktiviert']) {
        this._mpMessaging.openWarningPanel(this.ls.locs['locLeas'].DieserTeilnehmerIstBereitsReaktiviert);
      } else {
        this.reactivation = {
          TnId: row['TnId'],
          Reaktivierung: row['ReaktivierungString'],
          Benutzer: row['Benutzer']
        };

        this.showReactivation = true;
      }
    }
  }

}
