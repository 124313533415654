import { SidebarForgotLoginDataComponent } from './sidebar-forgot-login-data/sidebar-forgot-login-data.component';
import { SidebarInboxComponent } from './sidebar-inbox/sidebar-inbox.component';
import { SidebarCalendarComponent } from './sidebar-calendar/sidebar-calendar.component';
import { SidebarDateComponent } from './sidebar-date/sidebar-date.component';
import { SidebarDateTimeComponent } from './sidebar-date-time/sidebar-date-time.component';
import { SidebarPeriodComponent } from './sidebar-period/sidebar-period.component';
import { SidebarPeriodTimeComponent } from './sidebar-period-time/sidebar-period-time.component';
import { SidebarRewardPeriodComponent } from './sidebar-reward-period/sidebar-reward-period.component';
import { SidebarMultiselectComponent } from './sidebar-multiselect/sidebar-multiselect.component';
import { SbOverviewComponent } from './../../modules/participant/pages/shopping-basket/sb-overview/sb-overview.component';
import { ExpressEditComponent } from './../../modules/participant/pages/shipping/express-edit/express-edit.component';
import { AddressEditAreaComponent } from './../../components/addresses/address-edit-area/address-edit-area.component';
import { FaqSidebarComponent } from './../../components/faq/faq-sidebar/faq-sidebar.component';
import { SidebarHelpComponent } from './sidebar-help/sidebar-help.component';

export class CoreSidebars {
  public sidebarComponents: { [key: string]: any } = {
    'SidebarForgotLoginData': SidebarForgotLoginDataComponent,
    'SidebarInbox': SidebarInboxComponent,
    'SidebarCalendar': SidebarCalendarComponent,
    'SidebarDate': SidebarDateComponent,
    'SidebarDateTime': SidebarDateTimeComponent,
    'SidebarPeriod': SidebarPeriodComponent,
    'SidebarPeriodTime': SidebarPeriodTimeComponent,
    'SidebarRewardPeriod': SidebarRewardPeriodComponent,
    'SidebarMultiselect': SidebarMultiselectComponent,
    'sbOverview': SbOverviewComponent,
    'expressEdit': ExpressEditComponent,
    'addressEditArea': AddressEditAreaComponent,
    'FaqSidebar': FaqSidebarComponent,
    'HelpSidebar': SidebarHelpComponent
  };
}
