<article *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" class="margin-top-8 margin-bottom-8 offer-details" id="flugDetails{{flight['Hinfluege'][0].Id}}" [ngClass]="{detailsOpen: flight['open']}">
  <div class="rk-booking bg-color-white">
    <div class="rk-booking-details">
      <div class="rk-booking-details-start" *ngFor="let key of objectKeys(flight['filteredHinfluege']); let index = index">
        <mp-core-popover content="{{ ls.locs['locReisekonfigurator'].FlugzeitenOrtszeiten }}" alignment="left" popoverTitle="{{ ls.locs['locReisekonfigurator'].Flugzeiten }}" [hidden]="index > 0">
          <i class="svg-icon __clr-info __size-22 __top-0 __info"></i>
        </mp-core-popover>
        <div class="flex-grid">
          <div class="flex-col-12 flex-col-5-xs">
            <i class="svg-icon __clr-default __size-22 __top-0 __flight-takeoff margin-right-8 default-cursor-hover"></i>
            <span class="bold">
              {{ ls.locs['locReisekonfigurator'].Hinflug }}</span>
          </div>
          <div class="flex-col-7-xs text-align-right flex-direction-column show-on-small-mobile">
            <div class="text-color-info font-size-s">
              {{flight['filteredHinfluege'][key][0].Airline.Name}}
            </div>
            <div class="text-color-info font-size-xs" [hidden]="!flight['filteredHinfluege'][key][0].Airline.AdditionalInfo">{{flight['filteredHinfluege'][key][0].Airline.AdditionalInfo}}</div>
            <div class="text-color-info font-size-s">{{flight['filteredHinfluege'][key][0].Klasse}}</div>
          </div>
        </div>
        <div class="flex-grid flex-direction-row">
          <div class="flex-col-2 flex-col-3-l flex-direction-column hide-on-small-mobile">
            <div class="text-color-info font-size-s">{{flight['filteredHinfluege'][key][0].Airline.Name}}</div>
            <div class="text-color-info font-size-xs" [hidden]="!flight['filteredHinfluege'][key][0].Airline.AdditionalInfo">{{flight['filteredHinfluege'][key][0].Airline.AdditionalInfo}}</div>
            <div class="text-color-info font-size-s">{{flight['filteredHinfluege'][key][0].Klasse}}</div>
          </div>
          <div class="flex-col-10 flex-col-9-l flex-col-12-xs flex-direction-column padding-0 rk-nurFlug-flights">
            <div class="display-flex" *ngFor="let outboundFlight of flight['filteredHinfluege'][key].slice(0, flight['HinflugAnzahl'] || 2)">
              <div class="flex-col-1 flex-col-2-l flex-col-1-m flex-col-2-s flex-col-1-xs">
                <div class="radio-button-container" (click)="flight['SelectedHinflug'] = outboundFlight">
                  <input class="flex-col-1 flex-col-1-xs" type="radio" id="outboundFlight{{outboundFlight.Id}}"  name="outbound-radios-{{flight['Id']}}" [(ngModel)]="flight['SelectedHinflug'].Id" [value]="outboundFlight.Id" (change)="calculateDuration(flight)"/>
                  <label class="radio float-left" for="outboundFlight{{outboundFlight.Id}}">
                    <svg version="1.1" class="radiobutton_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                    <g>
                    <path class="radiobutton_svg__outer" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z" />
	                              </g>
                    <circle class="radiobutton_svg__inner" cx="8" cy="8" r="4" />
                              </svg>
                  </label>
                </div>
              </div>
              <div class="flex-col-11 flex-col-10-l flex-col-11-m flex-col-10-s flex-col-11-xs flex-wrap padding-0 rk-flight-summary">
                <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                  <div>{{outboundFlight.AbflugdatumDisplay}}</div>
                </div>
                <div class="flex-col-3 flex-col-8-l flex-col-8-xs font-size-s">
                  <div>{{outboundFlight.FlugzeitenDisplay}}</div>
                </div>
                <div class="flex-col-3 flex-col-4-l flex-col-4-xs font-size-s">
                  <div>{{outboundFlight.FlughafenDisplay}}</div>
                </div>
                <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                  <div>{{outboundFlight.FlugdauerDisplay}}</div>
                </div>
                <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s padding-left-0">
                  <div>{{outboundFlight.AnzahlStops + (outboundFlight.AnzahlStops === 1 ? ls.locs['locReisekonfigurator'].Stopp : ls.locs['locReisekonfigurator'].Stopps)}}</div>
                </div>
              </div>
            </div>
            <div class="display-flex padding-left-8" [hidden]="!flight['filteredHinfluege'][key] || flight['filteredHinfluege'][key].length <= (flight['HinflugAnzahl'] || 2) ">
              <span class="pointer-hover text-color-brand-primary" (click)="flight['HinflugAnzahl'] = flight['HinflugAnzahl'] === flight['filteredHinfluege'][key].length ? 2 : flight['filteredHinfluege'][key].length">
                {{ ls.locs['locReisekonfigurator'].WeitereHinfluegeZumPreis }}
                <i class="svg-icon __clr-default __size-18 __top-4 __arrow-dropdown margin-left-8 float-none-important"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr [hidden]="!flight['Rueckfluege'] || flight['Rueckfluege'].length > 0 " />
      <div class="rk-booking-details-end" *ngFor="let key of objectKeys(flight['filteredRueckfluege'])">
        <div class="flex-grid">
          <div class="flex-col-12 flex-col-5-xs">
            <i class="svg-icon __clr-default __size-22 __top-0 __flight-takeoff margin-right-8 flip-horizontal default-cursor-hover"></i>
            <span class="bold">
              {{ ls.locs['locReisekonfigurator'].Rueckflug }}</span>
          </div>
          <div class="flex-col-7-xs text-align-right flex-direction-column show-on-small-mobile">
            <div class="text-color-info font-size-s">
              {{flight['filteredRueckfluege'][key][0].Airline.Name}}
            </div>
            <div class="text-color-info font-size-xs" [hidden]="!flight['filteredRueckfluege'][key][0].Airline.AdditionalInfo">{{flight['filteredRueckfluege'][key][0].Airline.AdditionalInfo}}</div>
            <div class="text-color-info font-size-s">{{flight['filteredRueckfluege'][key][0].Klasse}}</div>
          </div>
        </div>
        <div class="flex-grid flex-direction-row">
          <div class="flex-col-2 flex-col-3-l flex-direction-column hide-on-small-mobile">
            <div class="text-color-info font-size-s">{{flight['filteredRueckfluege'][key][0].Airline.Name}}</div>
            <div class="text-color-info font-size-xs" [hidden]="!flight['filteredRueckfluege'][key][0].Airline.AdditionalInfo">{{flight['filteredRueckfluege'][key][0].Airline.AdditionalInfo}}</div>
            <div class="text-color-info font-size-s">{{flight['filteredRueckfluege'][key][0].Klasse}}</div>
          </div>
          <div class="flex-col-10 flex-col-9-l flex-col-12-xs flex-direction-column padding-0 rk-nurFlug-flights">
            <div class="display-flex" *ngFor="let inwardFlight of flight['filteredRueckfluege'][key].slice(0, flight['RueckflugAnzahl'] || 2)">
              <div class="flex-col-1 flex-col-2-l flex-col-1-m flex-col-2-s flex-col-1-xs">
                <div class="radio-button-container" (click)="flight['SelectedRueckflug'] = inwardFlight">
                  <input class="flex-col-1 flex-col-1-xs" type="radio" id="inwardFlight{{inwardFlight.Id}}" [(ngModel)]="flight['SelectedRueckflug'].Id" [value]="inwardFlight.Id" name="inward-radios-{{flight['Id']}}" />
                  <label class="radio float-left" for="inwardFlight{{inwardFlight.Id}}">
                    <svg version="1.1" class="radiobutton_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                    <g>
                    <path class="radiobutton_svg__outer" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z" />
	                              </g>
                    <circle class="radiobutton_svg__inner" cx="8" cy="8" r="4" />
                              </svg>
                  </label>
                </div>
              </div>
              <div class="flex-col-11 flex-col-10-l flex-col-11-m flex-col-10-s flex-col-11-xs flex-wrap padding-0 rk-flight-summary">
                <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                  <div>{{inwardFlight.AbflugdatumDisplay}}</div>
                </div>
                <div class="flex-col-3 flex-col-8-l flex-col-8-xs font-size-s">
                  <div>{{inwardFlight.FlugzeitenDisplay}}</div>
                </div>
                <div class="flex-col-3 flex-col-4-l flex-col-4-xs font-size-s">
                  <div>{{inwardFlight.FlughafenDisplay}}</div>
                </div>
                <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                  <div>{{inwardFlight.FlugdauerDisplay}}</div>
                </div>
                <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s padding-left-0">
                  <div>{{inwardFlight.AnzahlStops + (inwardFlight.AnzahlStops === 1 ? ls.locs['locReisekonfigurator'].Stopp : ls.locs['locReisekonfigurator'].Stopps)}}</div>
                </div>
              </div>
            </div>
            <div class="display-flex padding-left-8" [hidden]="!flight['filteredRueckfluege'][key] || flight['filteredRueckfluege'][key].length <= (flight['RueckflugAnzahl'] || 2) ">
              <span class="pointer-hover text-color-brand-primary" (click)="flight['RueckflugAnzahl'] = flight['RueckflugAnzahl'] === flight['filteredRueckfluege'][key].length ? 2 : flight['filteredRueckfluege'][key].length">
                {{ ls.locs['locReisekonfigurator'].WeitereRueckfluegeZumPreis }}<i class="svg-icon __clr-default __size-18 __top-4 __arrow-dropdown margin-left-8 float-none-important"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rk-booking-actions display-flex flex-direction-column flex-align-items-end flex-justify-cont-center padding-16">
      <div *ngIf="!mpSettings.settings['RkZuzahlungSettings'].HideZuzahlungHinweis" class="rk-offer-zuzahlung-hinweis display-flex flex-align-items-center flex-justify-cont-center" [hidden]="!flight['CanZuzahlen']">
        <i class="svg-icon __clr-inactive __size-16 __top-0 __info margin-right-4 default-cursor-hover"></i>
        <span class="font-size-xs">{{ ls.locs['locRkZuzahlung'].ZuzahlungMoeglich }}</span>
      </div>
      <div *ngIf="mpSettings.settings['RkZuzahlungSettings'].HideZuzahlungHinweis"></div>
      <div class="text-color-info margin-bottom-16 action-price">
        <span class="text-transform-uppercase font-weight-light show-on-small-mobile">ab </span>
        <span class="font-size-xl">
          {{flight['Punkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </span>
      </div>
      <div *ngIf="!permissions['CanUseReisekonfigurator']"><span class="font-size-xs">{{ ls.locs['locReisekonfigurator'].RkNichtErlaubt }}</span></div>
      <a *ngIf="permissions['CanUseReisekonfigurator']" class="mp-button _prim" href="#/{{role}}/FlugPruefung?out={{flight['SelectedHinflug']?.Id}}&ret={{flight['SelectedRueckflug']?.Id}}&sid={{searchId}}">{{ ls.locs['locReisekonfigurator'].ZurBuchung }}</a>
      <button *ngIf="permissions['CanUseReisekonfigurator']" class="mp-button _sec margin-top-8" [ngClass]="{detailsOpen: flight['open'] === true, _dark: showDetails}" [hidden]="(!flight['SelectedHinflug']?.Abschnitte || flight['SelectedHinflug']?.Abschnitte.length === 0) || (!flight['SelectedRueckflug']?.Abschnitte || flight['SelectedRueckflug']?.Abschnitte.length === 0)" (click)="showDetails = !showDetails; flight['open'] = !flight['open']; setOpenedOffers();">
        {{flight['open'] ? ls.locs['locReisekonfigurator'].DetailsAusblenden : ls.locs['locReisekonfigurator'].DetailsAnsehen}}
      </button>

    </div>
  </div>
</article>
