import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { DataTableChildsService } from '@core/components/data-table/data-table-childs.service';
import { MpTitleService } from '@core/services/mp-title.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

import { RankingLightStatisticChildComponent } from './ranking-light-statistic-child/ranking-light-statistic-child.component';

/**
 * This class provides the data and
 * functions for the ranking light
 * statistic table.
 */
@Component({
  selector: 'mp-gfc-ranking-light-statistic',
  templateUrl: './ranking-light-statistic.component.html'
})
export class RankingLightStatisticComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Gamification/GetRankingLightStatistik2Exce';
  public loader: MpLoader | null = null;
  public nameParamSet: boolean = false;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _rankingLightStatisticLoader: MpLoader = {
    name: 'rankingLightStatisticLoader',
    params: {}
  };
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _dataTableChilds: DataTableChildsService,
    private _titleService: MpTitleService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('/Views/manager/RankingLightStatistikChild', RankingLightStatisticChildComponent);
    this._dataTableChilds.registerTemplate('/Views/Manager/RankingLightStatistikChild', RankingLightStatisticChildComponent);
    this._mpMenuHighlight.setMenuHighlight('statistiken');
    this._titleService.setTitleFromLoc('RankingLightStatistik');

    this._mpLoaderService.registerLoader(this._rankingLightStatisticLoader);

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._mpLoaderService.extendLoader('rankingLightStatisticLoader', {
        params: {
          PlayName: params['Name'] || params['name']
        },
        isReady: true
      });

      this.nameParamSet = true;
    });

    
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('rankingLightStatisticLoader');
  }

}
