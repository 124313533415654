import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the
 * functions for the admin
 * home page.
 */
@Component({
  selector: 'mp-core-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public participant: string = '';
  public role: string = '';

  private _getParticipantIdByName: Subscription | undefined;

  public searchParticipantByNameFunc = this.searchParticipantByName.bind(this);

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _router: Router,
    public _mpMessaging: MpMessagingService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets some basic informations to the
   * role of user
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }
  }

  /**
   * Searches participant by name
   */
  searchParticipantByName() {
    this._getParticipantIdByName = this._apiService.postRequest('/api/Teilnehmer/getTnIdByBenutzer', { Benutzer: this.participant }).subscribe((data: any) => {
        if (data.Records[0] > 0)
          this._router.navigateByUrl(`/${this.role}/TNCockpit/` + data.Records[0]);
        else
          this._router.navigateByUrl(`/${this.role}/Teilnehmerverwaltung?search=` + this.participant);
      },
      (error: any) => {
        this._mpMessaging.openPanelFromResultResponse(error);
      });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getParticipantIdByName !== 'undefined') {
      this._getParticipantIdByName.unsubscribe();
    }
  }
}
