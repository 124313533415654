import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { AdditionalPaymentService } from './../../services/additional-payment.service';

/**
 * This class provides the functions for
 * prepayment in the additional paymnt.
 */
@Component({
  selector: 'mp-zz-additional-payment-prepayment',
  templateUrl: './additional-payment-prepayment.component.html'
})
export class AdditionalPaymentPrepaymentComponent implements OnInit, OnDestroy {

  public package: { [key: string]: any } = {};
  public participant: { [key: string]: any } = {};
  public errorName: boolean  = false;
  public errorFirstname: boolean  = false;
  public errorStreet: boolean  = false;
  public errorAdresszusatz: boolean  = false;
  public errorLKZ: boolean  = false;
  public errorZip: boolean  = false;
  public errorCity: boolean  = false;
  public errorEMail: boolean = false;
  public errorCountry: boolean = false;
  public CountriesList: Array<any> = [];

  private _getCountriesSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _additionalPaymentService: AdditionalPaymentService,
    private _injector: Injector
  ) { }

  /**
   * Gets the package and registers the
   * function.
   */
  ngOnInit(): void {
    this.package = this._injector.get('package');
    this.participant = this._injector.get('participant');
    this._additionalPaymentService.register('vorkasse', this.createInvoice.bind(this));

    this._getCountriesSubscription = this._apiService.getRequest('api/Teilnehmer/getDdlData').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.CountriesList = data.Records[0].Laenderliste;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getCountriesSubscription !== 'undefined') {
      this._getCountriesSubscription.unsubscribe();
    }
  }

  /**
   * Creates the invoice for the prepayment
   * method in additional payment.
   */
  createInvoice(successCallback: Function, errorCallback: Function): void {
    if (this._validatePrepayment()) {
      const data = {
        Name: this.participant['Name'],
        Vorname: this.participant['Vorname'],
        Strasse: this.participant['Strasse'],
        Adresszusatz: this.participant['Adresszusatz'],
        Land: this.participant['Land'],
        PLZ: this.participant['PLZ'],
        Ort: this.participant['Ort'],
        EMail: this.participant['EMail'],
        Punkte: this.package['Punkte'] || 0,
        Provider: 'vorkasse'
      };

      this._apiService.postRequest('/api/Zuzahlung/zuzahlen', data).subscribe((data: any) => {
        if (data.Result === 'OK')
          this._mpMessaging.openSuccessPanel(data.Message);
        else
          this._mpMessaging.openWarningPanel(data.Message);

        successCallback(data);
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.ExceptionMessage);
        errorCallback(error);
      });
    }
  }

  /**
   * Validates the data of the prepayment.
   */
  private _validatePrepayment(): boolean {
    this.errorName = false;
    this.errorFirstname = false;
    this.errorStreet = false;
    this.errorAdresszusatz = false;
    this.errorLKZ = false;
    this.errorZip = false;
    this.errorCity = false;
    this.errorEMail = false;
    this.errorCountry = false;

    if (!this.participant['Name']) {
      this.errorName = true;
      return false;
    }

    if (!this.participant['Vorname']) {
      this.errorFirstname = true;
      return false;
    }

    if (!this.participant['Strasse']) {
      this.errorStreet = true;
      return false;
    }

    if (!this.participant['LKZ']) {
      this.errorLKZ = true;
      return false;
    }

    if (!this.participant['PLZ']) {
      this.errorZip = true;
      return false;
    }

    if (!this.participant['Ort']) {
      this.errorCity = true;
      return false;
    }

    if (!this.participant['EMail']) {
      this.errorEMail = true;
      return false;
    }

    return true;
  }

}
