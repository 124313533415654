import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpSettingsService } from '@core/services/mp-settings.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

@Component({
  selector: 'mp-schooling-schooling-statistic',
  templateUrl: './schooling-statistic.component.html',
  styleUrls: ['./schooling-statistic.component.scss']
})
export class SchoolingStatisticComponent implements OnInit {

  public loader: MpLoader | null = null;
  public searchTexts: { [key: string]: any } = {};
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'SetBentzerName',
      func: this.setUserName.bind(this)
    }
  ];

  private _schoolingStatisticLoader: MpLoader = {
    name: 'schoolingStatisticLoader',
    params: {
      Handelskette: null
    }
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    public mpSettings: MpSettingsService
  ) { }

  /**
  * Registers the loader.
  */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._schoolingStatisticLoader);
    this._doData();
  }

  /**
 * Loads the data for the table.
 */
  private _doData(): void {
    this._mpLoaderService.extendLoader('schoolingStatisticLoader', {
      isReady: true
    });

    if (this.loader !== null) {
      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('schoolingStatisticLoader');
  }

  /**
   * Sets the user filter.
   */
  setUserName(row: any): void {
    this.searchTexts['Benuzername'] = row['Benuzername'];
    this._doData();
  }
}
