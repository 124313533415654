import { Directive, ElementRef } from '@angular/core';

import { ScrollButtonService } from './scroll-button.service';

/**
 * This class updates the scroll position
 * and the scroll buttons of a sidebar, when
 * the user deletes an element from it.
 */
@Directive({
  selector: '[mpCoreScrollButtonDeleteItem]'
})
export class ScrollButtonDeleteItemDirective {

  private _deleteButtonNative: HTMLElement;

  constructor(private _deleteItemButton: ElementRef, private _scrollButtonService: ScrollButtonService) {
    this._deleteButtonNative = this._deleteItemButton.nativeElement;

    this._deleteButtonNative.addEventListener('click', this._deleteButtonListenerFunc.bind(this), false);
  }

  /**
   * Updates scroll position and scroll
   * buttons (show / hide).
   */
  private _deleteButtonListenerFunc(): void {
    const dataScrollAreaAttr = this._deleteButtonNative.getAttribute('data-scroll-area');
    const scrollArea = dataScrollAreaAttr !== null ? document.querySelector(dataScrollAreaAttr): null;

    if (scrollArea !== null) {
      let scrollPos = 0;
      const scrollAreaParent = scrollArea.parentElement;

      if (scrollAreaParent !== null) {
        const scrollAreaParentCssHeight = scrollAreaParent.getBoundingClientRect().height;
        const scrollDownButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonDownClass}`);
        const scrollUpButton = document.querySelector(`[scrollArea="#${scrollArea.id}"].${this._scrollButtonService.scrollButtonUpClass}`);
        let scrollItemCssHeight = 100;
        const scrollItem = scrollArea.querySelector('.scroll-item');

        if (scrollItem !== null) {
          scrollItemCssHeight = parseInt(window.getComputedStyle(scrollItem).getPropertyValue('height').replace('px', ''));
        }

        const scrollItemsShownCnt = scrollAreaParentCssHeight / scrollItemCssHeight;
        const scrollItemIndex = this._deleteButtonNative.getAttribute('itemIndex');

        scrollPos = scrollAreaParent.scrollTop;

        if (scrollItemIndex !== null && parseInt(scrollItemIndex) <= scrollItemsShownCnt) {
          if (typeof scrollAreaParent.scroll !== 'undefined') {
            scrollAreaParent.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          } else {
            scrollAreaParent.scrollTop = 0;
          }

          if (scrollUpButton !== null && scrollDownButton !== null) {
            if (!scrollUpButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
              scrollUpButton.classList.add(this._scrollButtonService.inactiveButtonClass);
            }

            if (scrollDownButton.classList.contains(this._scrollButtonService.inactiveButtonClass)) {
              scrollDownButton.classList.remove(this._scrollButtonService.inactiveButtonClass);
            }
          }
        } else {
          if (typeof scrollAreaParent.scroll !== 'undefined') {
            scrollAreaParent.scroll({
              top: scrollPos + scrollItemCssHeight,
              left: 0,
              behavior: 'smooth'
            })
          } else {
            scrollAreaParent.scrollTop = scrollPos + scrollItemCssHeight;
          }
        }
      }
    }
  }

}
