import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './../../services/api.service';
import { MpShoppingBasketService } from './../../modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';

/**
 * This class provides some functions,
 * that are needed within the orderr
 * process.
 */
@Injectable({
  providedIn: 'root'
})
export class MpOrderProcessService {

  public paths: Array<string> = [];
  public currentStep: { [key: string]: any } | null = null;
  public paymentType: string = '';

  private _currentStepObserve: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentStepObserve: Observable<any> = this._currentStepObserve.asObservable();

  private _updateSteps: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public steps: Observable<any> = this._updateSteps.asObservable();

  private _steps: { [key: string]: any } = {};
  private _role: string = '';
  private _currentKey: string = '';
  private _goToFirstPage: boolean = false;
  private _validateStep: string = '';
  private _getStepsSubscription: Subscription | undefined;

  /**
   * Gets the available steps of the
   * order process from the backend.
   */
  constructor(
    private _apiService: ApiService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    const currentStepFromSession = sessionStorage.getItem('currentStep');
    this.currentStep = currentStepFromSession !== null ? JSON.parse(currentStepFromSession) : null;
    this._currentStepObserve.next(this.currentStep);

    this._getStepsSubscription = this._apiService.getRequest('/api/Bestellprozess/Steps').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._steps = data.Records[0];
        this._updateSteps.next(this._steps);

        this.paths = Object.keys(this._steps).map((key: string) => {
          return `/${this._role}/${this._steps[key].Url}`;
        });

        if (this._goToFirstPage) {
          this.goToFirstPage();
          this._goToFirstPage = false;
        } else if (this._currentKey !== '') {
          this._setCurrentStep(this._currentKey);
        } else if (this._validateStep !== '') {
          this.isValidStep(this._validateStep);
        }

        if (typeof this._getStepsSubscription !== 'undefined') {
          this._getStepsSubscription.unsubscribe();
        }
      }
    });
  }

  /**
   * Goes to the current set step
   * in order process.
   */
  goToCurrentStep(): void {
    if (this.currentStep && this.currentStep['Url']) {
      this._router.navigateByUrl(`/${this._role}/${this.currentStep['Url']}`);
    } else {
      this.goToFirstPage();
    }
  }

  /**
   * Goes to the first page of
   * the order process.
   */
  goToFirstPage(): void {
    if (Object.keys(this._steps).length > 0) {
      const stepsValues = Object.values(this._steps);

      const sortedStepsValues = stepsValues.concat().sort((): any => {
        const key = 'Index';
        return (valOne: any, valTwo: any) => (valOne[key] > valTwo[key]) ? 1 : ((valTwo[key] > valOne[key]) ? -1 : 0);
      });

      this._router.navigateByUrl(`/${this._role}/${sortedStepsValues[0].Url}`);
    } else {
      this._goToFirstPage = true;
    }
  }

  /**
   * Goes to the given step, if
   * it exists in the order process.
   */
  goToStep(step: any): void {
    if (this.currentStep !== null && step['Index'] < this.currentStep['Index']) {
      this._setCurrentStep(step.Key);
      this.goToCurrentStep();
    }
  }

  /**
   * Sets the current step of the
   * order process.
   */
  private _setCurrentStep(pageKey: string): void {
    this._currentKey = pageKey;
    this.currentStep = this._steps[this._currentKey];
    this._currentStepObserve.next(this.currentStep);

    if (this.currentStep !== null) {
      sessionStorage.setItem('currentStep', (this.currentStep !== null ? JSON.stringify(this.currentStep) : ''));
    }
  }

  /**
   * Validates the given step, to see
   * whether or not it is a valid step
   * of the order process.
   */
  isValidStep(key: string): boolean {
    if (!this._steps || !this._steps[key]) {
      this._validateStep = key;
      return false;
    }

    if ((this.currentStep && this._steps[key].Index <= this.currentStep['Index']) || this._steps[key].Index < 2) {
      this._setCurrentStep(key);
      return true;
    }

    return false;
  }

  /**
   * Goes to the next step of the
   * order process.
   */
  next(pageKey: string, params?: string): void {
    this._mpShoppingBasket.validate(pageKey).subscribe((valid: boolean) => {
      if (valid) {
        const currentStep = this._steps[pageKey];
        this._currentStepObserve.next(this.currentStep);

        if (typeof params !== 'undefined') {
          this._router.navigateByUrl(`/${this._role}/${currentStep.NextUrl}${params}`);
        } else {
          this._router.navigateByUrl(`/${this._role}/${currentStep.NextUrl}`);
        }

        this._setCurrentStep(this._steps[pageKey].NextKey);
      }
    });
  }

  /**
   * Goes to the previous step of the
   * order process.
   */
  previous(pageKey: string, params?: string): void {
    if (typeof params !== 'undefined') {
      this._router.navigateByUrl(`/${this._role}/${this._steps[pageKey].PreviousUrl}${params}`);
    } else {
      this._router.navigateByUrl(`/${this._role}/${this._steps[pageKey].PreviousUrl}`);
    }

    this._setCurrentStep(this._steps[pageKey].PreviousKey);
  }
}
