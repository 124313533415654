import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';

/**
 * This class provides the data for
 * the gitlog page.
 */
@Component({
  selector: 'mp-core-gitlog',
  templateUrl: './gitlog.component.html'
})
export class GitlogComponent implements OnInit, OnDestroy {

  public content: string = '';

  private _getGitlogSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public sanitizer: DomSanitizer,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the gitlog from the backend.
   */
  ngOnInit(): void {
    this._getGitlogSubscription = this._apiService.getRequest('/api/GitLog/GetGitLog').subscribe((data: any) => {
      this.content = this._trustAsHtml(data.Message);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getGitlogSubscription !== 'undefined') {
      this._getGitlogSubscription.unsubscribe();
    }
  }

  /**
   * Sanitizer for HTML.
   */
  private _trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
