import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { Angulartics2Matomo } from 'angulartics2';

import { MpMetaService } from './mp-meta.service';
import { MpSettingsService } from './mp-settings.service';
import { ApiService } from './api.service';
import { CookieService } from './cookie.service';

/**
 * This class adds Matomo to the
 * app.
 */
@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  public group: string = '';
  public participant: number = -1;

  private _siteId: number = 0;
  private _trackingBaseUrl: string = 'https://webanalytics.praemienshop.net/';
  private _matomoTrackingApiUrlPHP: string = 'piwik.php';
  private _matomoTrackingApiUrl: string = '';
  private _getParticipantAndGroupSubscription: Subscription | undefined;

  constructor(
    private _matomo: Angulartics2Matomo,
    private _mpMetaService: MpMetaService,
    private _mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _cookieService: CookieService,
    @Inject(DOCUMENT) private _dom: any
  ) { }

  /**
   * Gets and sets some base information
   * for Matomo, and triggers the process
   * to include Matomo.
   */
  setMatomo(): void {
    this._siteId = this._mpSettings.settings['NetcentiveCoreSettings'].PiwikSiteId;

    if (this._siteId !== 0) {
      this._cookieService
        .canSetCookie("matomo-id", "matomo-reference", "matomo-session")
        .then((consentGiven: boolean) => {
          if (consentGiven) {
            this._startTracking();
          }
        });
    }
  }

  private _startTracking() {
    this._trackingBaseUrl = this._mpSettings.settings['NetcentiveCoreSettings'].PiwikUrl || this._trackingBaseUrl;
    this._matomoTrackingApiUrl = `${this._trackingBaseUrl}${this._matomoTrackingApiUrlPHP}`;

    if (this.group === '' || this.participant === -1) {
      this._getParticipantAndGroupSubscription = this._apiService.postRequest('/api/Teilnehmer/getTN', {}).subscribe((data: any) => {
        const partiPnt = data.Records[0];

        if (!partiPnt) {
          return;
        }

        this.participant = this.participant !== -1 ? this.participant : partiPnt['TnID'];

        const group = partiPnt['Gruppenliste'].find((groupFromList: { [key: string]: any; }) => {
          return groupFromList['GruppenID'] === partiPnt['GruppenID'];
        });

        this.group = this.group !== '' ? this.group : typeof group === 'undefined' ? '' : group['Gruppe'];
        this._setMatomoScriptsAndStart();

        if (typeof this._getParticipantAndGroupSubscription !== 'undefined') {
          this._getParticipantAndGroupSubscription.unsubscribe();
        }
      });
    }
  }

  /**
   * Checks whether or not the script tag
   * is a Matomo script tag.
   */
  private _isMatomocriptTag(tag: any): boolean {
    return tag.src.indexOf("piwik.js") > -1;
  }

  /**
   * Sets the matomo scripts, and starts
   * the tracking.
   */
  private _setMatomoScriptsAndStart(): void {
    const matomoConfigScript = this._dom.createElement('script');
    const pushParticipant = this.participant !== -1 ? "_paq.push(['setCustomDimension', 1, '" + this.participant + "']);" : '';
    const pushGroup = this.group !== '' ? "_paq.push(['setCustomDimension', 2, '" + this.group + "']);" : '';

    matomoConfigScript.text = `
      var _paq = _paq || [];
      _paq.push(['setTrackerUrl', '${this._matomoTrackingApiUrl}']);
      _paq.push(['setSiteId', ${this._siteId}]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push(['setSecureCookie', true]);
      ${pushParticipant}
      ${pushGroup}
    `;

    this._dom.body.appendChild(matomoConfigScript);
    const scriptTags = this._dom.getElementsByTagName('script');
    let matomoScriptExists = false;

    if (scriptTags.length > 0) {
      matomoScriptExists = typeof Array.from(scriptTags).find(this._isMatomocriptTag) !== 'undefined' ? true : false;
    }

    if (!matomoScriptExists) {
      this._mpMetaService.setScriptTag(`${this._trackingBaseUrl}piwik.js`, true, 'text/javascript', true, true, () => {
        this._matomo.startTracking();
      });
    }
  }
}
