import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { InitialLoginService } from './../../../../services/initial-login.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';
import { AuthService } from './../../../../services/auth.service';

/**
 * This class provides the functionalities
 * for the home page of the firstlogin
 * module.
 */
@Component({
  selector: 'mp-core-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {

  public firstLoginSettingsLoaded: boolean = false;
  public firstLoginSettings: { [key: string]: any } = {};
  public statusLoaded: boolean = false;
  public status: { [key: string]: any } = {};
  public hasConditionsOfParticipation: boolean = false;
  public hasSecurityQuestion: boolean = false;
  public hasMajorData: boolean = false;
  public hasNewsletter: boolean = false;
  public hasPrtcpntAdvertisePrtcpnt: boolean = false;
  public hasSetPrtcpntGroup: boolean = false;
  public hasQuarterSurbey: boolean = false;

  private _nextClicked: boolean = false;
  private _role: string = '';
  private _getStatusSubscription: Subscription | undefined;
  private _getLeasStatusSubscription: Subscription | undefined;
  private _getSettingsSubscription: Subscription | undefined;
  private _getRedirectSubscription: Subscription | undefined;
  private _validSubscription: Subscription | undefined;
  private _successSubscription: Subscription | undefined;
  private _savePrtcpntAdvertisePrtcpntSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    public initialLoginService: InitialLoginService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _router: Router
  ) { }

  /**
   * Gets the role, the status and the
   * settings. Subscribes to the success and
   * validate observables of service.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this._getLeasStatusSubscription = this._apiService.getRequest('/api/Erstanmeldung/GetLeasStatus').subscribe((data: any) => {
      this.status = data.Records[0];
      this.statusLoaded = true;
      this._setNecessarySteps();
    });

    this._getSettingsSubscription = this._apiService.getRequest('/api/Erstanmeldung/GetSettings').subscribe((data: any) => {
      this.firstLoginSettings = data.Records[0];
      this.firstLoginSettingsLoaded = true;
      this._setNecessarySteps();
    });

    this._validSubscription = this.initialLoginService.validObservable.subscribe((valid: boolean) => {
      if (valid) {
        this.initialLoginService.next();
      }
    });

    this._successSubscription = this.initialLoginService.successObservable.subscribe((success: boolean) => {
      if (success && this._nextClicked) {
        this._nextClicked = false;

        if (this.initialLoginService.step < 2 && this.hasSecurityQuestion) {
          this.initialLoginService.step = 2;
          this.initialLoginService.stepSubject.next(this.initialLoginService.step);
        } else if (this.initialLoginService.step < 3 && this.hasMajorData && this.firstLoginSettings['ShowTeilnahmebedingungDatenschutzInExtraView']) {
          this.initialLoginService.step = 3;
          this.initialLoginService.stepSubject.next(this.initialLoginService.step);
        } else if (this.initialLoginService.step < 4 && this.hasNewsletter) {
          this.initialLoginService.step = 4;
          this.initialLoginService.stepSubject.next(this.initialLoginService.step);
        } else if (this.initialLoginService.step < 5 && this.hasPrtcpntAdvertisePrtcpnt) {
          this.initialLoginService.step = 5;
          this.initialLoginService.stepSubject.next(this.initialLoginService.step);
        } else if (this.initialLoginService.step <= 5 && this.hasSetPrtcpntGroup) {
          this.initialLoginService.step = 6;
          this.initialLoginService.stepSubject.next(this.initialLoginService.step);
        } else if (this.initialLoginService.step <= 6 && this.hasQuarterSurbey) {
          this.initialLoginService.step = 7;
          this.initialLoginService.stepSubject.next(this.initialLoginService.step);
        } else {
          this._redirectUser();
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getStatusSubscription !== 'undefined') {
      this._getStatusSubscription.unsubscribe();
    }

    if (typeof this._getLeasStatusSubscription !== 'undefined') {
      this._getLeasStatusSubscription.unsubscribe();
    }

    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }

    if (typeof this._getRedirectSubscription !== 'undefined') {
      this._getRedirectSubscription.unsubscribe();
    }

    if (typeof this._validSubscription !== 'undefined') {
      this._validSubscription.unsubscribe();
    }

    if (typeof this._successSubscription !== 'undefined') {
      this._successSubscription.unsubscribe();
    }

    if (typeof this._savePrtcpntAdvertisePrtcpntSubscription !== 'undefined') {
      this._savePrtcpntAdvertisePrtcpntSubscription.unsubscribe();
    }
  }

  /**
   * Sets the necessary steps.
   */
  private _setNecessarySteps(): void {
    if (Object.keys(this.status).length > 0 && Object.keys(this.firstLoginSettings).length > 0) {
      this.hasConditionsOfParticipation = this.firstLoginSettings['TeilnahmebedingungenBestaetigen'] && !this.status['TeilnahmebdingungenBestaetigt'];
      this.hasSecurityQuestion = this.firstLoginSettings['SicherheitsfragenAusfuellen'] && !this.status['SicherheitsfragenBeantwortet'];
      this.hasMajorData = this.firstLoginSettings['StammdatenErgaenzen'] && !this.status['StammdatenKomplett'];
      this.hasNewsletter = this.firstLoginSettings['NewsletterOptIn'] && !this.status['NewsletterOptInKomplett'];
      this.hasPrtcpntAdvertisePrtcpnt = !this.status['HasTnWerbenTnBeantwortet'];
      this.hasSetPrtcpntGroup = !this.status['HasTnGruppeBeantwortet'];
      this.hasQuarterSurbey = this.status['ShowQuartalsbefragung'] && !this.status['HasQuartalsbefragungBeantwortet'];

      if (!this.hasConditionsOfParticipation &&
        !this.hasSecurityQuestion &&
        !this.hasMajorData &&
        !this.hasNewsletter &&
        !this.hasSetPrtcpntGroup &&
        !this.hasQuarterSurbey) {
        this._redirectUser();
      }

      if (this.hasConditionsOfParticipation || this.hasMajorData && !this.firstLoginSettings['ShowTeilnahmebedingungDatenschutzInExtraView']) {
        this.initialLoginService.step = 1;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else if (this.hasSecurityQuestion) {
        this.initialLoginService.step = 2;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else if (this.hasMajorData && this.firstLoginSettings['ShowTeilnahmebedingungDatenschutzInExtraView']) {
        this.initialLoginService.step = 3;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else if (this.hasNewsletter) {
        this.initialLoginService.step = 4;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else if (this.hasPrtcpntAdvertisePrtcpnt) {
        this.initialLoginService.step = 5;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else if (this.hasSetPrtcpntGroup) {
        this.initialLoginService.step = 6;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else if (this.hasQuarterSurbey) {
        this.initialLoginService.step = 7;
        this.initialLoginService.stepSubject.next(this.initialLoginService.step);
      } else {
        this._redirectUser();
      }
    }
  }

  /**
   * Redirects the user, if first login
   * process is done / not needed.
   */
  private _redirectUser(): void {
    this._router.navigateByUrl(`/${this._role}/Home`);
  }

  /**
   * Goes to the next step of the
   * process.
   */
  next(): void {
    this._nextClicked = true;
    this.initialLoginService.validate();
  }

  /**
   * Skips the step of the first
   * login.
   */
  skip(): void {
    if (this.initialLoginService.step === 4) {
      this._savePrtcpntAdvertisePrtcpntSubscription = this._apiService.postRequest('/api/TnWerbenTn/set', {
        Entscheidung: '',
        Werber: ''
      }).subscribe((data: any) => {});
    }

    this._nextClicked = true;
    this.initialLoginService.nextSuccess();
  }

}
