<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'verkaeuferUebersicht'"></mp-core-infotext>
  <h1 class="mobile-only">{{ ls.locs['loc'].Teilnehmerverwaltung }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Teilnehmerverwaltung }}"
                 infotextShorten="teilnehmerverwaltung"
                 fieldsAction="/api/Teilnehmer/getTeilnehmerverwaltungFields"
                 listAction="/api/Teilnehmer/getTeilnehmerverwaltung"
                 loaderName="participantManagementTableLoader"
                 [paging]="true"
                 defaultSorting="Benutzer"
                 [recLoadedFunc]="recLoadedFunc"
                 [alwaysShowSearch]="true"
                 [execClickFunctions]="execClickFunctions"
                 [searchTexts]="searchTexts">
  </mp-core-data-table>
    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a class="mp-button _prim margin-top-24 float-right" href="{{excelService}}?{{loader.getExcelParams()}}">
        {{ ls.locs['loc'].Export }}
      </a>
    </div>
</div>
