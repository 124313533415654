import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { ApiService } from '@core/services/api.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpSettingsService } from '@core/services/mp-settings.service';

/**
 * This class provides the data and
 * the functions for the schooling
 * page.
 */
@Component({
  selector: 'mp-schooling-schooling-page',
  templateUrl: './schooling-page.component.html',
  styleUrls: ['./../../../../../../mp.Core/app/modules/participant/pages/award-details/award-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchoolingPageComponent implements OnInit, OnDestroy {

  public role: string = '';
  public isTrainingCompleted: boolean = false;
  public trainingCompletedDate: string = '';
  public maxPageCount: number = 0;
  public pageIndex: number = 1;

  public schoolingData: any = {
    Id: 1,
    Name: 'Demo-Schulung',
    Pages: 12
  };

  public message: string = '';

  private _getLocsSubscription: Subscription | undefined;
  private _getSchoolingDataSubscription: Subscription | undefined;
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute,
  ) { }

  /**
   * Gets the role. Gets the locs.
   */
  ngOnInit(): void {
    this.maxPageCount = this.schoolingData.Pages;
    const role = this._authService.getRole();

    this.message = this.ls.locs['locSchulung'].SchulungErfolgreichAbgeschlossenMessage;

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._getSchoolingData();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._getSchoolingData();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getSchoolingDataSubscription !== 'undefined') {
      this._getSchoolingDataSubscription.unsubscribe();
    }

    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
 * Gets the schooling data.
 */
  private _getSchoolingData(): void {
    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['trainingCompleted'] !== 'undefined' && params['trainingCompleted'] !== '') {
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        // @ts-ignore
        today = dd + '.' + mm + '.' + yyyy;
        this.trainingCompletedDate = this.ls.locs['locCam'].ErfolgreichAbsolviertAm + today;
        this.isTrainingCompleted = true;
        this._mpMessaging.openSuccessPanel(this.message, 15);
      }
    });
  }

  /**
  * Openes the details of the schooling
  * page.
  */
  startDetails(href: string, evt: MouseEvent): void {
    evt.preventDefault();
    window.open(href, '_blank');
  }

  /**
 * Goes to the next page.
 */
  indexIncrement(evt: MouseEvent): void {
    evt.preventDefault();

    if (this.pageIndex < this.maxPageCount) {
      this.pageIndex++;
    }
  }

  /**
   * Goes to the previous page.
   */
  indexDecrement(evt: MouseEvent): void {
    evt.preventDefault();

    if (this.pageIndex >= 2) {
      this.pageIndex--;
    }
  }

  saveClosingSchooling(): void {
    this._mpMessaging.openSuccessPanel(this.message, 15);
  }

}
