import { Directive, ElementRef, Injector, QueryList, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { CarouselService } from 'ngx-owl-carousel-o/lib/services/carousel.service';

import { MpSettingsService } from './../services/mp-settings.service';

/**
 * This class provides a guideline for handling animation of themed stage
 */
@Directive({
  selector: '[themesStagesAnimations]',
  exportAs: 'themesStagesAnimationsRef'
})
export class MpThemesStagesAnimationsDirective {

  public disable: boolean = true;
  public displaySettings: any;
  public autoplayTimeout: number = 0;
  public smartSpeed: number = 0;
  public owlProperties: OwlOptions = {};
  public injector: Injector | any;
  public owlPropertiesSet: boolean = false;

  public _settingsSubscription: Subscription | undefined;

  private _carouselService: any;
  private _owlElement: any;

  constructor(
    public mpSettings: MpSettingsService,
    private _injector: Injector,
    private _elementRef: ElementRef) {}

  ngOnInit(): void {
    
    this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.displaySettings = this.mpSettings.settings['DisplaySettings'];
        this.autoplayTimeout = this.displaySettings.BuehnenAnzeigeZeitMs;
        this.smartSpeed = this.displaySettings.BuehnenAnimationsZeitMs;

        if (!this.autoplayTimeout || isNaN(this.autoplayTimeout)) {
          this.autoplayTimeout = 10000;
        }
        if (!this.smartSpeed || isNaN(this.smartSpeed)) {
          this.smartSpeed = 10000;
        }
        this.owlProperties = {
          loop: true,
          nav: true,
          margin: 16,
          items: 1,
          dots: true,
          animateIn: this.displaySettings.BuehnenAnimationsKlasseIn || false,
          animateOut: this.displaySettings.BuehnenAnimationsKlasseOut || false,
          mouseDrag: false,
          touchDrag: false,
          autoplayHoverPause: this.displaySettings.BuehnenStopOnHover,
          autoplayMouseleaveTimeout: this.autoplayTimeout,
          responsive: {
            0: {
              items: 1,
              nav: true,
              loop: true,
              autoplay: true,
              autoplayTimeout: this.autoplayTimeout,
              smartSpeed: this.smartSpeed
            },
            768: {
              items: 1,
              nav: false,
              loop: true,
              autoplay: true,
              autoplayTimeout: this.autoplayTimeout,
              smartSpeed: this.smartSpeed
            },
            1024: {
              items: 1,
              nav: false,
              loop: true,
              autoplay: true,
              autoplayTimeout: this.autoplayTimeout,
              smartSpeed: this.smartSpeed
            }
          },
        };
        this.disable = false;
        this.owlPropertiesSet = true;
      }
    });
  }

  /**
  * A callback method that is invoked immediately after the default change
  * detector has completed one change-check cycle for a component's view
  */
  ngAfterViewChecked() {
    let htmlElem = document.getElementsByTagName("html")[0];

    let owlItem = this._elementRef.nativeElement.querySelectorAll('#home-carousel .owl-item');

    if (!htmlElem.classList.contains('no-fullsiz-stage')) {
      if (htmlElem.classList.contains('IE-10') || htmlElem.classList.contains('IE-11') || htmlElem.classList.contains('Edge')) {
        owlItem.querySelector('img').forEach(() => {
          const divElem = document.createElement("div");
          divElem.classList.add('ie-img');
          let thisParent = this._elementRef.nativeElement.parentNode;
          let thisSrc = document.querySelector('src');
          let thisParHeight = window.getComputedStyle(thisParent).getPropertyValue('height');

          let homeCarouselAndOwlStageOuter = this._elementRef.nativeElement.querySelector('#home-carousel .owl-stage-outer');
          homeCarouselAndOwlStageOuter.style.height = thisParHeight;

          this._elementRef.nativeElement.remove();

          thisParent.prepend(divElem);

          divElem.style.height = thisParHeight;
          divElem.style.backgroundImage = 'url(' + thisSrc + ')';
        });
      }
    }

    if (owlItem !== null) {
      
      owlItem.forEach((itm: HTMLElement) => {
        
        let item = itm;

        item.addEventListener('cssClassChanged', (event: any) => {
          event.preventDefault();

          let homeCarousel = this._elementRef.nativeElement.querySelector('#home-carousel');

          if (this._elementRef.nativeElement.classList.contains(this.displaySettings.BuehnenAnimationsKlasseIn)
            && !homeCarousel.classList.contains('slider-animation-running')) {
            homeCarousel.classList.add('slider-animation-running');
            this._sliderJSAnimations(this._elementRef.nativeElement, this.displaySettings, this.smartSpeed);
          }
        });
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // @ts-ignore
    this._carouselService.onResize(event.target.innerWidth);
  }

  /**
  *  Fires after the carousel gets initialized and exposes the object
  */
  onInitialized(owlElem: any) {
    this._owlElement = owlElem;
    this._carouselService = this._owlElement.carouselService as CarouselService;

    const element = (<HTMLElement>this._elementRef.nativeElement).querySelectorAll('.cloned');
    if (!element)
      return;

    element.forEach(elem => {
      
      let elemClasses = '';
      
        Array.prototype.slice.call(elem.classList).forEach((elemClass: any) => {
          elemClasses += '.' + elemClass;
        });

      let origElement = (<HTMLElement>this._elementRef.nativeElement).querySelector('.owl-item:not(.cloned) ' + elemClasses);

      this.injector = Injector.create({
        providers: [
          { provide: 'elem', useValue: elem },
          { provide: 'origElement', useValue: origElement }
        ],
        parent: this._injector
      });
    });
  }

  /**
  * Fires after the carousel finishes translating and exposes the object
  */
  onTranslated() {
    document.querySelectorAll('.slider-img-double').forEach(() => {
      this._elementRef.nativeElement.remove();
    });

    document.querySelectorAll('.slider-caption-double').forEach(() => {
      this._elementRef.nativeElement.remove();
    });

    document.querySelectorAll('.owl-item.animated.owl-animated-in').forEach(() => {
      this._elementRef.nativeElement.removeAttribute('class');
      this._elementRef.nativeElement.classList.add('owl-item');
      this._elementRef.nativeElement.classList.add('active');
    });

    let elem = document.querySelector('#home-carousel');
    if (elem !== null) {
      elem.classList.remove('slider-animation-running');
    }
  }

  /**
  * Controls animation from slider
  */
  _sliderJSAnimations(item: any, displaySettings: any, smartSpeed: any) {
    let slotsSlider = false;
    let parallaxSlider = false;
    let slotAnimationProps: any;
    let parallaxAnimationProps: any;

    if (displaySettings.BuehnenAnimationsKlasseIn === 'slider-slots-in' && displaySettings.BuehnenAnimationsKlasseOut === 'slider-slots-out') {
      slotsSlider = true;
    }

    if (displaySettings.BuehnenAnimationsKlasseIn === 'slider-parallax-in' && displaySettings.BuehnenAnimationsKlasseOut === 'slider-parallax-out') {
      parallaxSlider = true;
    }

    if (slotsSlider === true || parallaxSlider === true) {
      let outItem = this._elementRef.nativeElement.querySelector('.' + displaySettings.BuehnenAnimationsKlasseOut);
      let imgIn = item.getElementsByTagName('img')[0];
      let imgOut = outItem.getElementsByTagName('img')[0];
      let imgCopy: any;
      let direction = 'right';

      if (imgIn.length === 0) {
        imgIn = item.querySelector('.ie-img');
      }

      if (imgOut.length === 0) {
        imgOut = outItem.querySelector('.ie-img');
      }

      if (slotsSlider === true) {
        slotAnimationProps = {
          inOutItem: {
            properties: {
              left: "+=10%",
              width: "25%"
            },
            options: {
              duration: (smartSpeed * 0.5),
              specialEasing: {
                left: 'easeInOutCirc',
                width: 'easeInOutCubic'
              }
            }
          }
        };
      } else if (parallaxSlider === true) {
        parallaxAnimationProps = {
          full: {
            inItem: {
              properties: {
                left: {
                  left: "+=100%"
                },
                right: {
                  left: "0"
                }
              },
              options: {
                duration: (smartSpeed * 0.5),
                specialEasing: {
                  left: 'easeInOutCubic'
                }
              },
              startingPoint: {
                right: {
                  left: '100%'
                }
              }
            },
            inCaption: {
              properties: {
                left: {
                  left: "+=100%"
                },
                right: {
                  left: "50%"
                }
              },
              options: {
                duration: (smartSpeed * 0.5),
                specialEasing: {
                  left: 'easeInOutCubic'
                }
              },
              startingPoint: {
                right: {
                  left: '150%'
                }
              }
            },
            outItem: {
              properties: {
                left: {
                  left: "+=30%"
                },
                right: {
                  left: "-=30%"
                }
              },
              options: {
                duration: (smartSpeed * 0.5),
                specialEasing: {
                  left: 'easeInOutCirc'
                }
              }
            },
            outCaption: {
              properties: {
                left: {
                  left: "+=30%"
                },
                right: {
                  left: "-=30%"
                }
              },
              options: {
                duration: (smartSpeed * 0.5),
                specialEasing: {
                  left: 'easeInOutCirc'
                }
              }
            }
          },
          px1280: {
            inCaption: {
              properties: {
                left: {
                  left: "+=100%"
                },
                right: {
                  left: "0%"
                }
              },
              startingPoint: {
                right: {
                  left: "100%"
                }
              }
            }
          }
        }
      }

      let viewportWidth = window.innerWidth;

      if (slotsSlider === true) {
        for (let a = 0; a < 4; a++) {
          imgCopy = { ...imgIn };
          imgCopy.classList.add('slider-img-double');
          item.querySelectorAll().append(imgCopy);

          imgCopy = { ...imgOut };
          imgCopy.classList.add('slider-img-double');
          outItem.querySelectorAll().append(imgCopy);
        }

        item.querySelector('.slider-img-double').forEach(() => {
          if (viewportWidth < 768) {
            this._elementRef.nativeElement.style.height = getComputedStyle(imgIn).getPropertyValue('height');
          }

          this._elementRef.nativeElement.animate(slotAnimationProps.inOutItem.properties, slotAnimationProps.inOutItem.options);
        });

        outItem.querySelector('.slider-img-double').forEach(() => {
          if (viewportWidth < 768) {
            this._elementRef.nativeElement.style.height = getComputedStyle(imgOut).getPropertyValue('height');
          }

          this._elementRef.nativeElement.animate(slotAnimationProps.inOutItem.properties, slotAnimationProps.inOutItem.options);
        });
      } else if (parallaxSlider) {
        imgCopy = { ...imgIn };
        imgCopy.classList.add('slider-img-double');
        item.querySelectorAll().append(imgCopy);

        let caption = item.querySelector('.caption');
        caption.classList.add('slider-caption-double');
        item.querySelectorAll().append(caption);

        let outInd = 0;
        var outChildren = item.querySelector('.slider-parallax-out').parentNode.children;

        while (outChildren[outInd] !== item.querySelector('.slider-parallax-out')) outInd++;
        outInd++;
        let inInd = 0;
        var inChildren = item.querySelector('.slider-parallax-in').parentNode.children;
        while (inChildren[inInd] !== item.querySelector('.slider-parallax-in')) inInd++;
        inInd++;
        if (outInd > inInd) {
          direction = 'left';
        }

        imgCopy = { ...imgOut };
        imgCopy.classList.add('slider-img-double');
        outItem.querySelectorAll().append(imgCopy);

        caption = { ...outItem.querySelector('.caption') };
        caption.classList.add('slider-caption-double');
        outItem.querySelectorAll().append(caption);

        item.querySelector('.slider-img-double').forEach(() => {
          if (direction === 'right') {
            this._elementRef.nativeElement.style.left =
              parallaxAnimationProps.full.inItem.startingPoint[direction].left;
          }
          this._elementRef.nativeElement.animate(parallaxAnimationProps.full.inItem.properties[direction], parallaxAnimationProps.full.inItem.options);
        });

        item.querySelector('.slider-caption-double').forEach(() => {
          if (direction === 'right') {
            if (viewportWidth < 1280) {
              this._elementRef.nativeElement.style.left =
                parallaxAnimationProps.px1280.inCaption.startingPoint[direction].left;
            } else {
              this._elementRef.nativeElement.style.left =
                parallaxAnimationProps.full.inCaption.startingPoint[direction].left;
            }
          }

          if (viewportWidth < 768) {
            let imgHeight = item.querySelector('.slider-img-double').style.height;
            let containerHeight = item.querySelector('.owl-inner').style.height;;

            let newCaptiionHeight = parseInt(containerHeight.replace('px', '')) + parseInt(imgHeight.replace('px', ''));

            this._elementRef.nativeElement.style.top = imgHeight;
            this._elementRef.nativeElement.style.height = newCaptiionHeight;
          }

          if (viewportWidth < 1280) {
            this._elementRef.nativeElement.animate(parallaxAnimationProps.px1280.inCaption.properties[direction], parallaxAnimationProps.full.inCaption.options);
          } else {
            this._elementRef.nativeElement.animate(parallaxAnimationProps.full.inCaption.properties[direction], parallaxAnimationProps.full.inCaption.options);
          }
        });

        outItem.querySelector('.slider-img-double').forEach(() => {
          this._elementRef.nativeElement.animate(parallaxAnimationProps.full.outItem.properties[direction], parallaxAnimationProps.full.outItem.options);
        });

        outItem.querySelector('.slider-caption-double').forEach(() => {
          if (viewportWidth < 768) {
            this._elementRef.nativeElement.style.top = outItem.querySelector('.slider-img-double').style.height;
          }

          this._elementRef.nativeElement.animate(parallaxAnimationProps.full.outCaption.properties[direction], parallaxAnimationProps.full.outCaption.options);
        });
      }
    } else {
      return;
    }
  }
}
