import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpSidebarService } from './../../../components/sidebar/mp-sidebar.service';

/**
 * This class provides the functions for
 * the faq sidebar.
 */
@Component({
  selector: 'mp-core-faq-sidebar',
  templateUrl: './faq-sidebar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FaqSidebarComponent implements OnInit, OnDestroy {

  public params: any;

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    private _mpSidebar: MpSidebarService
  ) { }

  /**
   * Gets the sidebar parameters.
   */
  ngOnInit(): void {
    this.params = this._mpSidebar.params;

    this._sidebarParamsSubscription = this._mpSidebar.paramsChange.subscribe((params: any) => {
      if (Object.keys(params).length > 0 && this.params !== params) {
        this.params = params;
      }

    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

}
