<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].Honorierungen }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].Honorierungen }}"
                      infotextShorten="Honorierung"
                      fieldsAction="/api/Honorierung/getHonorierungenFields"
                      listAction="/api/Honorierung/getHonorierungen"
                      loaderName="leasAppreciationOverviewLoader"
                      [paging]="true"
                      [footer]="false"
                      [recLoadedFunc]="recLoadedFunc"
                      [execClickFunctions]="execClickFunctions"
                      defaultSorting="Marke"
                      [disableCache]="true"></mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
  <mp-core-modal [show]="editModalOpen" title="{{ ls.locs['locLeas'].HonorierungBearbeiten }}" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: closeEditModal}, {text: ls.locs['loc'].Speichern, action: saveAppreciation}]">
    <mp-core-input label="{{ ls.locs['locLeas'].Marke }}" mpId="marke-modal" [(model)]="appreciation['Marke']" [mpDisabled]="true"></mp-core-input>
    <mp-core-input label="{{ ls.locs['locLeas'].Produkt }}" mpId="produkt-modal" [(model)]="appreciation['Produkt']" [mpDisabled]="true"></mp-core-input>
    <mp-core-input label="{{ ls.locs['loc'].Punkte }}" is-int mpId="punkte-modal" [(model)]="appreciation['PunkteWert']" [mpRequired]="true"></mp-core-input>
    <mp-core-date-picker label="{{ ls.locs['locLeas'].GueltigVon }}"
                         class="hon-date-from"
                         mpId="gueltig-von-modal"
                         rangeType="from"
                         [model]="honDateFrom"
                         (modelChange)="honDateFrom = $event;"
                         mpType="text">
    </mp-core-date-picker>
    <mp-core-date-picker label="{{ ls.locs['locLeas'].GueltigBis }}"
                         class="hon-date-to"
                         mpId="gueltig-bis-modal"
                         rangeType="to"
                         [model]="honDateTo"
                         (modelChange)="honDateTo = $event;"
                         mpType="text">
    </mp-core-date-picker>
    <p class="inputError" *ngFor="let e of appreciation['errors']" [innerText]="e"></p>
  </mp-core-modal>
</div>
