import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';

/**
* This class provides a input for search, that
* can be configured with different input attributes.
*/
@Component({
  selector: 'mp-core-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() public model: string = '';
  @Input() public mpId: string = '';
  @Input() public action!: Function;
  @Input() public focusOn: boolean = false;
  @Input() public mpPlaceholder: string = '';
  @Input() public noTrim: boolean = false;
  @Input() public mpEnter: Function | undefined;
  @Input() public mpFocus: Function | undefined;

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputBlur: EventEmitter<object> = new EventEmitter<object>();
  @Output() inputChange: EventEmitter<object> = new EventEmitter<object>();
  @Output() inputFocus: EventEmitter<object> = new EventEmitter<object>();


  constructor(
    private _elementRef: ElementRef,
    public ls: MpLocalizationService) { }

  ngOnInit(): void {
  }

  /**
  * Is submitted when type "search" was found
  */
  executeAction() {
    if (this.action) {
      const searchInputs = document.querySelectorAll('[type="search"]');
      // @ts-ignore
      if (searchInputs.length > 0) {
        // @ts-ignore
        searchInputs[0].blur();
      }
      this.action();
    }

  }

  /**
   * Detects a change on model
   */
  modelChanged(event: string) {
    this.modelChange.emit(event);
  }
}
