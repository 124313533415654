import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpMenuHighlightService } from './mp-menu-highlight.service';
import { MpMenuService } from './mp-menu.service';
import { ApiService } from './../../services/api.service';
import { MpPermissionsService } from './../../services/mp-permissions.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';

/**
 * This class provides the functions and data
 * for menus.
 */
@Component({
  selector: 'mp-core-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnChanges {

  @Input() public mpId: string = '';
  @Input() public mpClass: string = '';

  @Input() public items: Array<any> = [];
  @Input() public anyOpen: any = {};
  @Input() public showLogout: boolean = false;
  @Input() public firstItemType: string = '';
  @Input() public sameMenuSelector: string = '';

  public hasStatus: boolean = false;
  public menuItems: Array<any> = [];
  public currentMenuItem: string = '';
  public locationPath: string = '';

  private _routeChangeSubscription: Subscription | undefined;
  private _menuHighlightChangeSubscription: Subscription | undefined;
  private _getStatusSubscription: Subscription | undefined;
  private _role: string = '';

  @Output("closeHamburger") closeHamburger: EventEmitter<any> = new EventEmitter();
  @Output('anyOpenChange') anyOpenChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private _mpMenuHighlightService: MpMenuHighlightService,
    private _mpMenuService: MpMenuService,
    private _apiService: ApiService,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
  * This function changes the menu items.
  */
  itemsChange(): void {
    if (typeof this.items === 'undefined') {
      return;
    }
    this.updateMenuItems();
  }

  /**
   * Watches for changes of the menu items.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.items.length > 0 && this.items !== null && typeof changes['items'] !== 'undefined') {
      if (changes['items'].currentValue !== changes['items'].previousValue) {
        this.items = changes['items'].currentValue;
        this.updateMenuItems();
      }
    }
  }

  /**
   * Triggers the functions to set status, to update menu
   * and to determine location path.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this.updateMenuItems();
    this.currentMenuItem = this._mpMenuHighlightService.getMenuHighlight();

    this._getStatusSubscription = this._apiService.getRequest('/api/Status/HasStatus').subscribe((data: any) => {
      this.hasStatus = data.Records[0].HasStatus;

      if (typeof this._getStatusSubscription !== 'undefined') {
        this._getStatusSubscription.unsubscribe();
      }
    },
    (error: any) => {
      console.log(error.message);
    });

    this._routeChangeSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.locationPath = '#' + this._router.url;
        this.menuItems.forEach((item: any) => this.updateHighlighting(item));
      }
    });

    this._menuHighlightChangeSubscription = this._mpMenuHighlightService.menuHighlighChange.subscribe((highlight: any) => {
      this.currentMenuItem = highlight;
    });
  }

  /**
  * Checks whether or not there are subscriptions,
  * and unsubscribes them.
  */
  ngOnDestroy(): void {
    if (typeof this._routeChangeSubscription !== 'undefined') {
      this._routeChangeSubscription.unsubscribe();
    }

    if (typeof this._menuHighlightChangeSubscription !== 'undefined') {
      this._menuHighlightChangeSubscription.unsubscribe();
    }

    if (typeof this._getStatusSubscription !== 'undefined') {
      this._getStatusSubscription.unsubscribe();
    }
  }

  /**
  * Returns the index of item in
  * ngFor. Is used for trackBy in ngFor.
  */
  trackByIndex(index: number, item: any): number {
    return index;
  }
  
  /**
   * Updates the data for the user menu.
   */
  updateMenuItems() {
    this.items.forEach((item: any) => {
      item['subChildren'] = false;
      if (typeof item.children !== 'undefined') {
        if (item.children.length > 0) {
          item.children.forEach((subItem: any) => {
            if (subItem && typeof subItem.children !== 'undefined') {
              if (subItem.children.length > 0) {
                item.subChildren = true;
              }
            }
          });
        }
      }
    });

    this.menuItems = this.items;
    this.locationPath = '#' + this._router.url;
    this.menuItems.forEach((item: any) => this.updateHighlighting(item));
  }

  /**
   * This function sets the active menuitem.
   */
  toggle(item: any) {
    if (this._mpMenuService.activeMenuItem !== undefined && this._mpMenuService.activeMenuItem !== item) {
        if (this._mpMenuService.activeMenuItem.open === true) {
          this._mpMenuService.activeMenuItem.open = false;
        }
      }

      let isOpen = item.open;
      for (let i = 0; i < this.menuItems.length; i++) {
        this.menuItems[i].open = false;
      }
      item.open = !isOpen;
      this.anyOpen = item.open; //!item.open;
      this.anyOpenChange.emit(this.anyOpen);

      this._mpMenuService.setActiveMenuItem(item);
  };

  /**
   * This function sets the active menuitem at hover.
   */
  toggleHover(mpId: string, item: any, open: boolean, evt: MouseEvent, href?: any){
    if (mpId !== 'main-menu') {
      return false;
    }
    if (open) {
      item.open = true;
    } else {
      item.open = false;
    }

    href = href || false;

    if (href !== false) {
      this.closeParent(href, evt);
    }
  };

  /**
  * This function closes menuitem.
  */
  close(item: any): void {
    if (item.open) {
      this.toggle(item);
    }
  };

  /**
  * This function closes menuitem at parent component.
  */
  closeParent(href: any, evt: MouseEvent, item?: any): void{
    evt.preventDefault();
    if (href) {
      this.closeHamburger.emit();

      if (evt.type === 'click' || evt.type === 'touch') {
        if (typeof item !== 'undefined') {
          if (item.target !== null && typeof item.target !== 'undefined' && item.target === '_blank') {
            window.open(href, '_blank');
          } else {
            window.location.href = href;
          }
        }

      }
    }
  }

  /**
  * This function updates highlighting from menu.
  */
  updateHighlighting(menuItem: any) {
    if (!this.locationPath)
      this.locationPath = '#' + this._router.url;

    if (menuItem) {
      menuItem.currentRoot = false;

      let pattern = '^' + (menuItem.highlightPattern || menuItem.href) + '[/?]?.*';
      let hrefRegExp = new RegExp(pattern, 'g');

      let locationMatch = this.locationPath.match(hrefRegExp);

      if (locationMatch && locationMatch.length || this.locationPath === menuItem.href) {
        menuItem.currentRoot = true;

      } else if (menuItem.children && menuItem.children.length) {
        menuItem.children.forEach(this.updateHighlighting.bind(this));

        menuItem.currentRoot = menuItem.children.some( (item: any) => item.currentRoot);
      }

      return menuItem.currentRoot;

    } else if (this.menuItems && this.menuItems.length) {
      this.menuItems.forEach(this.updateChildrenHighlighting.bind(this));
    }
  }

  /**
  * This function updates highlighting from children of menu.
  */
  updateChildrenHighlighting(item: any) {
    let highlight = this.updateHighlighting(item);
    item.currentRoot = highlight;
    return highlight;
  }
}
