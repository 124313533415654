import { Component, OnInit, Input } from '@angular/core';

/**
 * This class provides the html
 * video.
 */
@Component({
  selector: 'mp-core-html-video',
  templateUrl: './html-video.component.html'
})
export class HtmlVideoComponent implements OnInit {

  @Input() public width: string = '';
  @Input() public height: string = '';
  @Input() public poster: string = '';
  @Input() public autoplay: string = '';
  @Input() public loop: string = '';
  @Input() public muted: string = '';
  @Input() public videoSources: { [key: string]: any } = {};

  public sources: Array<{ [key: string]: any }> = [];

  constructor() { }

  /**
   * Creates the sources array from
   * the sources object.
   */
  ngOnInit(): void {
    Object.keys(this.videoSources).forEach((key: string) => {
      this.sources.push({
        src: this.videoSources[key],
        type: key
      });
    });
  }

}
