import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpWishlistService } from './mp-wishlist.service';
import { MpShoppingBasketService } from './../shopping-basket/mp-shopping-basket.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

import { Tile } from './../../../../components/card/shop-card/tile';

/**
 * This class provides the functions
 * for the wishlist.
 */
@Component({
  selector: 'mp-core-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./../shop/shop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WishlistComponent implements OnInit, OnDestroy {

  public wlLoaded: boolean = false;
  public data: Array<Tile> | undefined = [];
  public role: string = '';

  private _wishlistSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _titleService: MpTitleService,
    private _mpWishlist: MpWishlistService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the wíshlist data, and
   * sets the title and menu highlight.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._mpWishlist.getWishlist();

    if (this._mpWishlist.loaded) {
      this.data = this._mpWishlist.wishlist;
      this.wlLoaded = true;
    }

    this._wishlistSubscription = this._mpWishlist.wishlistLoaded.subscribe(() => {
      this.data = this._mpWishlist.wishlist;
      this.wlLoaded = true;
    });

    this._titleService.setTitleFromLoc('Wunschliste');
    //mpMenuHighlight.setMenuHighlight('wunschliste');
  }

  /**
   * Deletes the given item from
   * the wishlist.
   */
  deleteItem(vlID: number, evt: MouseEvent): void {
    evt.preventDefault();
    this._mpWishlist.deleteItem(vlID);
  }

  /**
   * Adds the given wishlist item
   * to the shopping basket.
   */
  addToShoppingbasket(wishlistItem: Tile): void {
    if (wishlistItem.CanOrder) {
      // @ts-ignore
      this._mpShoppingBasket.addItem(wishlistItem.ArtNr, wishlistItem.PIN, false, 1, wishlistItem.HasZusatz);
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._wishlistSubscription !== 'undefined') {
      this._wishlistSubscription.unsubscribe();
    }
  }

}
