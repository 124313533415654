<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].Punkteverteilungen }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].Punkteverteilungen }}"
                      infotextShorten="Punkteverteilung"
                      fieldsAction="/api/Punktebeteiligung/getPunktebeteiligungFields"
                      listAction="/api/Punktebeteiligung/getPunktebeteiligungen"
                      loaderName="leasPointsSharingOverviewLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [execClickFunctions]="execClickFunctions"
                      [paging]="false"
                      [footer]="false"
                      defaultSorting="FirmenId"
                      [disableCache]="true">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
  <mp-core-modal [show]="editModalOpen" title="{{ ls.locs['locLeas'].PunkteverteilungBearbeiten }}" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: closeEditModal}, {text: ls.locs['loc'].Speichern, action: savePointsSharing}]">
    <mp-core-input label="{{ ls.locs['locLeas'].DealerId }}" mpId="dealer-id-modal" [(model)]="pointsSharing['FirmenId']" [mpDisabled]="true"></mp-core-input>
    <mp-core-input label="{{ ls.locs['locLeas'].Geschaeftsfuehrer }}" mpId="gf-prozent-modal" [mpRequired]="true" [model]="pointsSharing['GfProzentWert']" (modelChange)="pointsSharing['GfProzentWert'] = $event; calculateVkPercentWorth()"></mp-core-input>
    <mp-core-input label="{{ ls.locs['locLeas'].Verkaufsleiter }}" mpId="vl-prozent-modal" [mpRequired]="true" [model]="pointsSharing['VlProzentWert']" (modelChange)="pointsSharing['VlProzentWert'] = $event; calculateVkPercentWorth()"></mp-core-input>
    <mp-core-input label="{{ ls.locs['locLeas'].Verkaeufer }}" mpId="vk-prozent-modal" [(model)]="pointsSharing['VkProzentWertBerechnet']" [mpDisabled]="true"></mp-core-input>
    <p class="inputError" *ngFor="let e of pointsSharing['errors']" [innerText]="e"></p>
  </mp-core-modal>
</div>
