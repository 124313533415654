import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpShoppingBasketService } from './../shopping-basket/mp-shopping-basket.service';
import { MpOrderProcessService } from './../../../../components/order-process/mp-order-process.service';
import { ApiService } from './../../../../services/api.service';
import { MpSidebarService } from './../../../../components/sidebar/mp-sidebar.service';
import { MpAddressesService } from './../../../../services/mp-addresses.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

import { ShoppingBasketItem } from './../shopping-basket/shopping-basket-item';

@Component({
  selector: 'mp-core-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: [
    './shipping.component.scss',
    './../../../../components/order-process/styles/order-process-pooled.scss',
    './../../../../components/order-process/styles/order-process-shipping.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ShippingComponent implements OnInit, OnDestroy {

  public currentStep: { [key: string]: any } = {};
  public shoppingBasket: Array<ShoppingBasketItem> = [];
  public groupedShoppingBasket: Array<any> = [];
  public expressPoints: number | null = null;
  public selAddress: { [key: string]: any } = {};
  public validateShoppingBasketBound: Function = () => { };
  public cancelBound: Function = () => { };
  public role: string = '';
  public updateExpressService = this._updateExpressService.bind(this);

  private _expressPointsSubscription: Subscription | undefined;
  private _stepsSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _currentSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpOrderProcess: MpOrderProcessService,
    private _apiService: ApiService,
    private _mpSidebar: MpSidebarService,
    private _mpAdresses: MpAddressesService,
    private _mpMessaging: MpMessagingService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets express points and checks
   * whether or not order process step
   * is valid.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this.validateShoppingBasketBound = this.validateShoppingBasket.bind(this);
    this.cancelBound = this.cancel.bind(this);

    this._expressPointsSubscription = this._apiService.getRequest('/api/Artikel/GetExpressPunkte').subscribe((data: any) => {
      this.expressPoints = data.Records[0];
    });

    this._stepsSubscription = this._mpOrderProcess.steps.subscribe((data: any) => {
      if (!this._mpOrderProcess.isValidStep('versand')) {
        this._mpOrderProcess.goToCurrentStep();
      }
    });

    this._currentSubscription = this._mpOrderProcess.currentStepObserve.subscribe((currentStep: any) => {
      this.currentStep = currentStep;
    });

    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((shoppingBasket: Array<ShoppingBasketItem>) => {
      this.shoppingBasket = shoppingBasket;
      const groupedBasketKeys = Object.keys(this._mpShoppingBasket.groupedBasket);

      if (groupedBasketKeys.length > 0) {
        this.groupedShoppingBasket = [];

        groupedBasketKeys.forEach((key: string) => {

          for (let i = 0; i < this._mpShoppingBasket.groupedBasket[key].length; ++i) {
            var item = this._mpShoppingBasket.groupedBasket[key][i];
            var bild = item.Bild;
            if (bild !== null) {
              for (const [k] of Object.entries(item)) {
                if (k === 'Bild') {
                  // <imgPath>\<ArtNr>_<PIN><separator><nrExtension> ==> <impPath>\<ArtNr>_<item.PIN><separator><nrExtension> (https://cdn.netcentive.de/v5/1800x1800\P22571_01-01.jpg)
                  var pin = bild.slice(-9, -7);
                  var underscore = bild.slice(-10, -9);
                  var idx = bild.indexOf(underscore) + 1;
                  var nrExtension = bild.slice(-7);
                  var pathArtNr = bild.slice(0, idx);
                  if (pin === '00') {
                    item.Bild = pathArtNr + '00' + nrExtension;
                  } else {
                    item.Bild = pathArtNr + item.PIN + nrExtension;
                  }
                }
              }
            }
          }
          this._mpShoppingBasket.groupedBasket[key].sort((a: any, b: any) => {
            return (Date.parse(b.Anlagedatum) - Date.parse(a.Anlagedatum));
          });
          this.groupedShoppingBasket.push(this._mpShoppingBasket.groupedBasket[key]);
        });
      }
      this.groupedShoppingBasket = this.groupedShoppingBasket.slice().reverse();
    });

    if (this._mpShoppingBasket.shoppingBasket.length > 0) {
      this.shoppingBasket = this._mpShoppingBasket.shoppingBasket;
    } else {
      this._mpShoppingBasket.refreshShoppingBasket();
    }
  }

  /**
   * Unsubscribes set subsriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._expressPointsSubscription !== 'undefined') {
      this._expressPointsSubscription.unsubscribe();
    }

    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._currentSubscription !== 'undefined') {
      this._currentSubscription.unsubscribe();
    }
  }

  /**
   * Fail callback for shipping
   * address validation.
   */
  private _validationFail(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this.ls.getLocalization();
    }

    if (this._mpAdresses.mpAddresses.selectedAddressTyp === 1) {
      this._mpMessaging.openWarningPanel(this.ls.locs['loc'].ValidationErrorFirmenAdresse, 20);
      this._validationSuccess();
    } else {
      this._mpMessaging.openConfirm(
        {
          text: this.ls.locs['loc'].AdresseNichtValidiert,
          submitText: this.ls.locs['loc'].VersandadresseAendern,
          cancelText: this.ls.locs['loc'].OhneAenderungFortfahren
        }, () => {
          this._mpMessaging.closeOverlay();

          console.log(this._mpAdresses.addresses);
          console.log(this._mpAdresses.mpAddresses);

          const selectedAddress = this._mpAdresses.addresses.find((address: any) => {
            return address.AdressID === this._mpAdresses.mpAddresses.selectedAddressId && address.adressTyp === this._mpAdresses.mpAddresses.selectedAddressTyp;
          });

          if (selectedAddress[0]) {
            setTimeout(() => {
              this._mpAdresses.edit(selectedAddress[0]);
            }, 200);
          }
        },
        () => {
          this._validationSuccess();
          this._mpMessaging.closeOverlay();
        }
      );
    }
  }

  /**
   * Success callback for shipping
   * address validation.
   */
  private _validationSuccess(): void {
    this._mpOrderProcess.next('versand');
  }

  /**
   * Validates the selcted shipping
   * address.
   */
  private _validate(): void {
    this._mpAdresses.validateWkAdress(this._validationSuccess.bind(this), this._validationFail.bind(this));
  }

  /**
   * Triggers the validation of the
   * selected shipping address.
   */
  validateShoppingBasket(evt: MouseEvent): void {
    evt.preventDefault();
    this._validate();
  }

  /**
   * Cancels the order process.
   */
  cancel(): void {
    window.location.href = '#/' + this.role + '/';
  }

  /**
   * Updates the express state of the
   * given item.
   */
  updateItem(item: ShoppingBasketItem, isExpress: boolean): void {
    item.Express = isExpress;

    if (item.Express && item.Anzahl > item.MaxExpress) {
      item.Express = false;
      this._mpSidebar.open('expressEdit', { item: item });
    } else {
      this._mpShoppingBasket.updateItem(item.Key, item.ArtNr, item.PIN, item.Express, item.Anzahl);
    }
  }

  private _updateExpressService(selAddress: { [key: string]: any }): void {
    if (selAddress['LKZ'] === 'DE')
      return;

    setTimeout(() => //setTimeout because otherwise the change is not done yet
      this.shoppingBasket.forEach(item => {
        item.Express = false;
      }), 10)
  }

}
