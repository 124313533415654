<div *ngIf="(ls.locsLoaded | async) && phaseTipsSet" class="bg-color-white box-sizing-border-box width-full padding-left-right-24 padding-bottom-0 padding-top-24" id="taw-day-tips-container">
  <div *ngIf="showTippsCommingSoon" class="padding-bottom-80 padding-top-56">
    <span [innerHTML]="textTippsCommingSoon"></span>
  </div>
  <div *ngIf="!showTippsCommingSoon">
    <div id="taw-dt-save" class="display-flex flex-wrap flex-align-items-center margin-bottom-24">
      <div class="taw-phase-tips-navigation bg-color-light-grey display-flex flex-justify-cont-between flex-align-items-center padding-left-right-8 padding-top-bottom-6 margin-right-8">
        <i class="svg-icon __size-28 __top-0 __nav-prev" (click)="showTips(tipIndex - 1)" [ngClass]="tipIndex === 0 ? '__clr-inactive pointer-event-none' : '__clr-default pointer'"></i>
        <span class="font-size-s">{{phaseTips['phase']}}</span>
        <i class="svg-icon __size-28 __top-0 __nav-next" (click)="showTips(tipIndex + 1)" [ngClass]="tipIndex === allTips.length - 1 ? '__clr-inactive pointer-event-none' : '__clr-default pointer'"></i>
      </div>
      <button *ngIf="hasTipsToFill" class="mp-button _prim taw-save-btn" type="button" (click)="clickedSaveButton()">{{ ls.locs['locTipAndWin'].SpieltagSpeichern }}</button>
      <button *ngIf="!hasTipsToFill" class="mp-button disabled taw-save-btn" type="button">{{ ls.locs['locTipAndWin'].SpieltagSpeichern }}</button>
    </div>
    <div class="padding-bottom-32 taw-phase-tips" *ngFor="let day of phaseTips['days']; trackBy: trackByIndex">
      <h3 class="bold margin-bottom-0">{{formatMatchDate(day['date'])}}</h3>
      <div class="flex-grid margin-left-right-0 font-size-xs hide-on-mobile">
        <div class="flex-col-1 flex-align-items-center">{{ ls.locs['locTipAndWin'].Uhrzeit }}</div>
        <div class="flex-col-3 flex-align-items-center">{{ ls.locs['locTipAndWin'].Ort }}</div>
        <div class="flex-col-4 flex-align-items-center">{{ ls.locs['locTipAndWin'].Spiel }}</div>
        <div class="flex-col-1 flex-align-items-center">{{ ls.locs['locTipAndWin'].Ergebnis }}</div>
        <div class="flex-col-2 flex-align-items-center">{{ ls.locs['locTipAndWin'].Tipp }}</div>
        <div class="flex-col-1 flex-align-items-center">{{ ls.locs['locTipAndWin'].Punkte }}</div>
      </div>
      <div class="flex-grid margin-left-right-0 font-size-s taw-match-data" *ngFor="let match of day['matches']; trackBy: trackByIndex">
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Uhrzeit }}</div>
        <div class="flex-col-1 flex-col-6-s flex-col-7-xs flex-align-items-center">{{match['time']}}</div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Ort }}</div>
        <div class="flex-col-3 flex-col-6-s flex-col-7-xs flex-align-items-center">
          <span class="">{{match['place']}}</span>
        </div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Spiel }}</div>
        <div class="flex-col-4 flex-col-6-s flex-col-7-xs flex-align-items-center taw-match-results">
          <div class="display-flex flex-wrap">
            <span>{{match['teamOne'].name}}&nbsp;:&nbsp;</span>
            <span>{{match['teamTwo'].name}}</span>
          </div>
        </div>

        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Ergebnis }}</div>
        <div class="flex-col-1 flex-col-6-s flex-col-7-xs flex-align-items-center">
          <span *ngIf="match['teamOne'].result !== null">{{match['teamOne'].result}}</span>
          <span *ngIf="match['teamOne'].result === null">&ndash;</span>
          <span class="margin-left-right-8">&colon;</span>
          <span *ngIf="match['teamTwo'].result !== null">{{match['teamTwo'].result}}</span>
          <span *ngIf="match['teamTwo'].result === null">&ndash;</span>
        </div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Tipp }}</div>
        <div class="flex-col-2 flex-col-6-s flex-col-7-xs flex-align-items-center">
          <span class="width-40" *ngIf="checkLastTipTimeReached(match) && match['teamOne'].tip === null">-</span>
          <span class="width-40" *ngIf="checkLastTipTimeReached(match) && match['teamOne'].tip !== null">{{match['teamOne'].tip}}</span>
          <mp-core-input-integer mpType="text" *ngIf="!checkLastTipTimeReached(match)" [model]="match['teamOne'].tip" (modelChange)="match['teamOne'].tip = $event; setTip(match)" [onlyInt]="true"></mp-core-input-integer>
          <span class="margin-left-right-8">:</span>
          <mp-core-input-integer mpType="text" *ngIf="!checkLastTipTimeReached(match)" [model]="match['teamTwo'].tip" (modelChange)="match['teamTwo'].tip = $event; setTip(match)" [onlyInt]="true"></mp-core-input-integer>
          <span class="width-40" *ngIf="checkLastTipTimeReached(match) && match['teamTwo'].tip !== null">{{match['teamTwo'].tip}}</span>
          <span class="width-40" *ngIf="checkLastTipTimeReached(match) && match['teamTwo'].tip === null">-</span>
        </div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Punkte }}</div>
        <div class="flex-col-1 flex-col-6-s flex-col-7-xs flex-align-items-center">
          <span *ngIf="match['points'] !== null" [ngClass]="match['points'] > 0 ? 'text-success' : 'text-danger'">{{match['points']}}</span>
          <span *ngIf="match['points'] === null">-</span>
        </div>

      </div>
    </div>
  </div>
</div>
