import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';
import { PaymentOfTaxBaseChildComponent } from './payment-of-tax-base-child/payment-of-tax-base-child.component';
import { DataTableChildsService } from '../../../../components/data-table/data-table-childs.service';

/**
 * This class provides the data and
 * functions for the payment of tax 
 * base table.
 */
@Component({
  selector: 'mp-core-payment-of-tax-base',
  templateUrl: './payment-of-tax-base.component.html'
})
export class PaymentOfTaxBaseComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Statistik/GetVersteuerungsgrundlage2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;

  public changeYearAndReload = this._changeYearAndReload.bind(this);
  public changeMonthAndReload = this._changeMonthAndReload.bind(this);

  public yearList: Array<number> = [];
  public year: number = new Date().getFullYear();

  public monthList: Array<any> = []
  public month: number = 0;

  private _paymentOfTaxBaseLoader: MpLoader = {
    name: 'paymentOfTaxBaseLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _dataTableChilds: DataTableChildsService,
    public mpSettings: MpSettingsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('payment-of-tax-base-child', PaymentOfTaxBaseChildComponent);
    this._titleService.setTitleFromLoc('Versteuerungsgrundlage');
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._paymentOfTaxBaseLoader);

    this.ls.locsLoaded.subscribe((loaded) => {
      if (loaded) {
        this.yearList = Array.from({ length: this.year - 2009 }, (v, k) => k + 2010); // get array of years since 2010
        this.monthList = [{ month: 0, text: this.ls.locs['loc'].Alle }];
        for (let i = 0; i < 12; i++) {
          var date = new Date(this.year, i);

          this.monthList.push({
            month: i + 1,
            text: date.toLocaleString('default', { month: 'long' })
          })
        }

        this._mpLoaderService.extendLoader('paymentOfTaxBaseLoader', {
          params: {
            Monat: this.month,
            Jahr: this.year
          },
          isReady: true
        });
      }
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('paymentOfTaxBaseLoader');
  }

  private _changeYearAndReload(): void {
    if (this.loader?.params) {
      this.loader.params.Jahr = this.year;
      this.loader.load?.();
    }
  }

  private _changeMonthAndReload(): void {
    if (this.loader?.params) {
      this.loader.params.Monat = this.month;
      if (this.loader.params.Jahr) {
        this.loader.load?.();
      }
    }
  }

}
