<div *ngIf="(ls.locsLoaded | async)" class="layout-container __limited-width bg-color-white padding-bottom-48">
  <div class="white-content-container" *ngIf="ddlLoaded && groupsLoaded">
    <div class="margin-bottom-32" [innerHTML]="ls.locs['loc'].TeilnehmerAnlegenHeader"></div>
    <div id="tnAnlegen"
         [hidden]="ptcpntCreated">
      <div class="flex-grid _m-w-small-none">
        <div class="flex-col-6 flex-col-12-m display-block-important margin-bottom-32">
          <h3>
            {{ ls.locs['loc'].Profil }}
          </h3>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input [hasError]="errors['data.Benutzer'] ? true : false"
                      label="{{ ls.locs['loc'].BenutzerMitManagerhinweis }}"
                      [(model)]="data['Benutzer']"
                      mpId="benutzer"
                      [mpRequired]="true"
                      [mpDisabled]="false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Benutzer']"
                           [model]="errors['data.Benutzer']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-select label="{{ ls.locs['loc'].Anrede }}"
                       mpId="anrede"
                       [elements]="filteredSalutationsList"
                       textProperty="Anrede"
                       [(model)]="data['AnredeObjekt']"
                       [mpRequired]="true"
                       [mpDisabled]="false"
                            class="width-full"
                       [hasError]="errors['data.AnredeObjekt'] ? true : false">
            </mp-core-select>
            <mp-core-form-error *ngIf="errors['data.AnredeObjekt']"
                           [model]="errors['data.AnredeObjekt']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input label="{{ ls.locs['loc'].Name }}"
                      [(model)]="data['Name']"
                      mpId="name"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Name'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Name']"
                           [model]="errors['data.Name']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input label="{{ ls.locs['loc'].Vorname }}"
                      [(model)]="data['Vorname']"
                      mpId="vorname"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Vorname'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Vorname']"
                           [model]="errors['data.Vorname']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap">
            <mp-core-input label="{{ ls.locs['loc'].Firma }}"
                      [(model)]="data['Firma']"
                      mpId="firma"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Firma'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Firma']"
                           [model]="errors['data.Firma']">
            </mp-core-form-error>
          </div>
        </div>
        <div class="flex-col-6 flex-col-12-m display-block-important margin-bottom-32">
          <h3>{{ ls.locs['loc'].Adresse }}</h3>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input label="{{ ls.locs['loc'].Strasse_Nr }}"
                      [(model)]="data['Strasse']"
                      mpId="strasse"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Strasse'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Strasse']"
                           [model]="errors['data.Strasse']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-select label="{{ ls.locs['loc'].LKZ }}"
                       mpId="land"
                            class="width-full"
                       [elements]="countriesList"
                       textProperty="Land"
                       valueProperty="Kuerzel"
                       [model]="data['Kuerzel']"
                       (modelChange)="data['Kuerzel'] = $event; updateSalutationsList()"
                       [mpRequired]="true"
                       [mpDisabled]="false"
                       [hasError]="errors['data.Kuerzel'] ? true : false">
            </mp-core-select>
            <mp-core-form-error *ngIf="errors['data.Kuerzel']"
                           [model]="errors['data.Kuerzel']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input label="{{ ls.locs['loc'].PLZ }}"
                      [(model)]="data['PLZ']"
                      mpId="plz"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.PLZ'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.PLZ']"
                           [model]="errors['data.PLZ']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap">
            <mp-core-input label="{{ ls.locs['loc'].Ort }}"
                      [(model)]="data['Ort']"
                      mpId="ort"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Ort'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Ort']"
                           [model]="errors['data.Ort']">
            </mp-core-form-error>
          </div>
        </div>
      </div>
      <div class="flex-grid _m-w-small-none">
        <div class="flex-col-6 flex-col-12-m display-block-important margin-bottom-32">
          <h3>{{ ls.locs['loc'].TN_Anlegen_ALS }}</h3>
          <mp-core-checkbox class="margin-bottom-16"
                       mpId="chkTestuseranlegen"
                       [(model)]="data['Testuseranlegen']"
                       label="{{ ls.locs['loc'].Teilnehmer }}">
          </mp-core-checkbox>
          <mp-core-checkbox class="margin-bottom-16"
                       mpId="chkManagerAnlegen"
                       [(model)]="data['Manageranlegen']"
                       label="{{ ls.locs['loc'].Manager }}">
          </mp-core-checkbox>
          <h3 class="margin-top-32">{{ ls.locs['loc'].Sonstiges }}</h3>
          <mp-core-checkbox [hidden]="!data['Testuseranlegen']"
                       class="margin-bottom-16"
                       mpId="chkDemobuchungen"
                       [(model)]="data['DemoBuchungen']"
                       label="{{ ls.locs['loc'].TestdatensaetzeAnlegen }}">
          </mp-core-checkbox>
          <mp-core-checkbox class="margin-bottom-16"
                       mpId="chkTestuser"
                       [(model)]="data['Testuser']"
                       label="{{ ls.locs['loc'].Testuser }}">
          </mp-core-checkbox>
          <mp-core-select class="margin-bottom-16"
                     label="{{ ls.locs['loc'].Gruppe }}"
                     mpId="gruppe"
                     [elements]="groupsList"
                     textProperty="Gruppe"
                     [(model)]="data['Gruppe']"
                     [mpRequired]="true"
                     [mpDisabled]="false">
          </mp-core-select>
          <div class="display-flex flex-direction-row flex-wrap">

            <mp-core-sidebar-input class="width-full"
                           text="{{data['PWDGueltigBisParams'].date ? data['PWDGueltigBisParams'].date.format('L') : ''}}"
                           label="{{ ls.locs['loc'].PWDGueltigBis }}"
                           [sidebarParams]="data['PWDGueltigBisParams']"
                           sidebarComponent="SidebarDate"
                           [hasError]="errors['data.PWDGueltigBis'] ? true : false">
            </mp-core-sidebar-input>
            <mp-core-form-error *ngIf="errors['data.PWDGueltigBis']"
                           [model]="errors['data.PWDGueltigBis']">
            </mp-core-form-error>

          </div>
        </div>
        <div class="flex-col-6 flex-col-12-m display-block-important margin-bottom-32">
          <h3>{{ ls.locs['loc'].Kontaktdaten }}</h3>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input label="{{ ls.locs['loc'].Telefon }}"
                      [(model)]="data['Telefon_G']"
                      mpId="telefon"
                      [mpRequired]="false"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Telefon_G'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Telefon_G']"
                           [model]="errors['data.Telefon_G']">
            </mp-core-form-error>
          </div>
          <div class="display-flex flex-direction-row flex-wrap margin-bottom-16">
            <mp-core-input label="{{ ls.locs['loc'].EMail }}"
                      [(model)]="data['Email_G']"
                      mpId="email"
                      [mpRequired]="true"
                      [mpDisabled]="false"
                      [hasError]="errors['data.Email_G'] ? true : false">
            </mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Email_G']"
                           [model]="errors['data.Email_G']">
            </mp-core-form-error>
          </div>
        </div>
      </div>
      <button id="btnSpeichern"
              [hidden]="!data['Testuseranlegen'] && !data['Manageranlegen']"
              class="mp-button _prim"
              (click)="save(data)">
        {{ ls.locs['loc'].Speichern }}
      </button>
    </div>
    <div id="tnanlageresult"
         *ngIf="ptcpntCreated && ptcpntCreatedResult.length > 0">
      <h1>{{ ls.locs['loc'].TN_Daten }}</h1>
      <div class="flex-grid _m-w-small-none">
        <div class="flex-col-6 flex-col-12-m display-block-important margin-bottom-32"
             *ngFor="let result of ptcpntCreatedResult">
          <mp-core-input class="margin-bottom-16"
                    label="{{ ls.locs['loc'].Benutzername }}"
                    [model]="result['Benutzer']"
                    [mpDisabled]="true">
          </mp-core-input>
          <mp-core-input class="margin-bottom-16"
                    label="{{ ls.locs['loc'].Passwort }}"
                    [(model)]="result['Passwort']"
                    [mpDisabled]="true">
          </mp-core-input>
        </div>
      </div>
      <button id="btnTNNeuAnlegen"
              class="mp-button"
              (click)="reload()">
        {{ ls.locs['loc'].Neuen_TN_Anlegen }}
      </button>
    </div>
  </div>
</div>
<div class="clearfix"></div>
