import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { ApiService } from './../../../../services/api.service';
import { DataTableChildsService } from './../../../../components/data-table/data-table-childs.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

import { AwardManagerChildComponent } from './award-manager-child/award-manager-child.component';

/**
 * This class provides the functions and
 * data for the award manager.
 */
@Component({
  selector: 'mp-core-award-manager',
  templateUrl: './award-manager.component.html'
})
export class AwardManagerComponent implements OnInit, OnDestroy {

  public categoriesLoaded: boolean = false;
  public categories: Array<any> = [];
  public category: { [key: string]: any } = {
    id: 0
  };
  public excelService: string = '/api/Statistik/Praemienmanager2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;

  private _managerAwardManagerLoader: MpLoader = {
    name: 'managerAwardManagerLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;
  private _getCategoriesSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _apiService: ApiService,
    private _dataTableChilds: DataTableChildsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader, after the categories
   * are loaded. Gets the permissions.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('/Views/ng-templates/PraemienManagerChild', AwardManagerChildComponent);
    this._titleService.setTitleFromLoc('Praemienmanager');
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._managerAwardManagerLoader);

    this._getCategoriesSubscription = this._apiService.getRequest('/api/Kategorien/GetKategorienForPraemienmanager').subscribe((data: any) => {
      this.categories = data.Records;

      this._mpLoaderService.extendLoader('managerAwardManagerLoader', {
        isReady: true,
        params: {
          KategorieId: this.category['id']
        }
      });

      this.categoriesLoaded = true;
    },
    (error: any) => {
      this._mpLoaderService.extendLoader('managerAwardManagerLoader', {
        isReady: true,
        params: {
          KategorieId: this.category['id']
        }
      });

      this.categoriesLoaded = true;
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getCategoriesSubscription !== 'undefined') {
      this._getCategoriesSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('managerAwardManagerLoader');
  }

  /**
   * Reloads the data for the selected
   * category.
   */
  changeCategory(): void {
    if (this.loader !== null) {
      this._mpLoaderService.extendLoader('managerAwardManagerLoader', {
        params: {
          KategorieId: this.category['id']
        }
      });

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

}
