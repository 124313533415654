import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';
import { MpAddressesService } from '../../../services/mp-addresses.service';

@Component({
  selector: 'mp-core-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 * This class provides the functions and data
 * for the simple card of addresses.
 */
export class AddressCardComponent implements OnInit {

  @Input() public model: any;
  @Input() public showEdit: boolean = false;
  @Input() public showDelete: boolean = false;
  @Input() public editable: boolean = false;
  @Input() public ddlData: any;
  @Input() public hideStd: string = '';
  @Input() public stdSelectable: boolean = false;
  @Input() public onCardClick: Function | undefined;
  @Input() public selected: boolean = false;

  public canChangeAdresse: boolean = false;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpAddressesService: MpAddressesService
  ) { }

  /**
   * Loads the address cards
   */
  ngOnInit(): void {
    this.canChangeAdresse = true;

    this._apiService.getRequest('/api/Teilnehmer/CanChangeAdresse').subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.canChangeAdresse = data.Records[0];
        }
      },
      (error: any) => {
        console.log(error.Message);
      });
  }

  /**
   * Calls delete method of address from the service
   */
  delete(event: Event) {
    event.preventDefault();
    this._mpAddressesService.delete(this.model);
    event.stopPropagation();
  };

  /**
   * Calls edit method of address from the service
   */
  edit(event: Event) {
    event.preventDefault();
    this._mpAddressesService.edit(this.model);
    event.stopPropagation();
  };

  /**
   * Calls set method of standard address from the service
   */
  setStdAdresse(event: Event) {
    if (this.stdSelectable) {
      event.preventDefault();
      this._mpAddressesService.setStdAdresse(this.model);
      event.stopPropagation();
    }
  };

  /**
   * Triggers the given on-click-function of
   * the parent component.
   */
  cardClick(model: any): void {
    if (typeof this.onCardClick !== 'undefined') {
      this.onCardClick(model);
    }
  }
}
