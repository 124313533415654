import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { CalendarService } from '@core/components/calendar/calendar.service';

/**
 * This class provides the functionalities
 * for the rental time sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-calendar-rental',
  templateUrl: './sidebar-calendar-rental.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarCalendarRentalComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public maxRange: number = 50;
  public times: Array<any> = [];

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _calendarService: CalendarService
  ) { }

  /**
   * Gets the sidebar params, and sets
   * the times for the times selects.
   */
  ngOnInit(): void {
    const baseDate = moment([1900, 0, 1]);

    this.times = Array.from({ length: 25 }, (_, i) => i).map((time: number) => {
      return {
        Text: baseDate.clone().hour(time).format('HH:mm'),
        Value: time
      }
    });

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        rangeStart: this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'],
        rangeEnd: this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd'],
        UhrzeitAbholung: this.selection['UhrzeitAbholung'] || this.mpSidebar.params['UhrzeitAbholung'] || 12,
        UhrzeitRueckgabe: this.selection['UhrzeitRueckgabe'] || this.mpSidebar.params['UhrzeitRueckgabe'] || 12
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Saves the selected rental time.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();

    if (!(this.selection['rangeStart'] && this.selection['rangeEnd'])) {
      return;
    }

    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

  /**
   * Changes the current selection to
   * 'start' or 'end'.
   */
  setCurrentSelection(selection: string): void {
    this._calendarService.changeCalendarSelection({ selection: selection });
  }

}
