import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';

@Component({
  selector: 'mp-core-email-opt-out',
  templateUrl: './email-opt-out.component.html',
  styleUrls: ['./email-opt-out.component.scss']
})
export class EmailOptOutComponent implements OnInit {

  private _guid: string = '';
  private _typeId: string = '';
  public message: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe((params: any) => {
      this._guid = params['guid'];
      this._typeId = params['typeId'];

      this._apiService.getRequest('/api/Extern/EmailOptOut?guid=' + this._guid + '&typeId=' + this._typeId).subscribe((data: any) => {
          this.message = data.Message;
          this._router.navigate(['/']);
        },
        (error: any) => {
          this._router.navigate(['/']);
        });
    });

  }

}
