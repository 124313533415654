import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';
import { TcSearchParamsService } from './../../../services/tc-search-params.service';

/**
 * This class provides the functionalities
 * for the search area 'rental'.
 */
@Component({
  selector: 'mp-rk-search-area-rental',
  templateUrl: './search-area-rental.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SearchAreaRentalComponent implements OnInit, OnDestroy {

  @Input() public params: { [key: string]: any } = {
    dateRangeMietwagen: {},
    standorteMietwagen: {}
  };
  @Input() public travelType: number = 0;
  @Input() public hideButton: boolean = false;

  @Output() paramsChange = new EventEmitter<{ [key: string]: any }>();

  public travelTypeEnum: { [key: string]: any } = {};
  public paramsSet: boolean = false;

  private _role: string = '';
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _mpSidebar: MpSidebarService,
    private _router: Router,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    private _customEventService: CustomEventService
  ) { }

  /**
   * Gets the localization data, and gets
   * the rental data from the session storage,
   * if they are in the session storage.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    if (sessionStorage['mietwagenParams']) {
      this.params = Object.assign(this.params || {}, JSON.parse(sessionStorage['mietwagenParams']));

      if (this.params['dateRangeMietwagen'].rangeStart) {
        this.params['dateRangeMietwagen'].rangeStart = moment(this.params['dateRangeMietwagen'].rangeStart);
      }

      if (this.params['dateRangeMietwagen'].rangeEnd) {
        this.params['dateRangeMietwagen'].rangeEnd = moment(this.params['dateRangeMietwagen'].rangeEnd);
      }
    }

    if (Object.keys(this.ls.locs).length > 0) {
      this._setParams();

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setParams();

          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Sets this params for the search.
   */
  private _setParams(): void {
    if (this.paramsSet) {
      return;
    }

    this.params['dateRangeMietwagen'] = Object.assign(this.params['dateRangeMietwagen'] || {}, {
      minDate: moment().add(4, 'days').hour(0).minute(0).second(0).millisecond(0),
      save: this._dateRangeRentalSave.bind(this)
    });

    this.params['dateRangeMietwagen'].save(true);
    
    this.params['standorteMietwagen'] = Object.assign(this.params['standorteMietwagen'] || {}, {
      RueckgabeOptions: [
        {
          Text: this.ls.locs['locReisekonfigurator'].RueckgabeAmAbholort,
          Value: false
        },
        {
          Text: this.ls.locs['locReisekonfigurator'].AndererRueckgabeort,
          Value: true
        }
      ],
      save: this._locationsRentalSave.bind(this)
    });

    this.params['standorteMietwagen'].save(true);


    this.params = this._tcSearchParamsService.getParamsForSearchArea(this.params, this.travelType);
    this.paramsSet = true;
  }

  /**
   * Save function for dateRangeMietwagen
   * sidebar param.
   */
  private _dateRangeRentalSave(initialSave?: boolean): void {
    if (this.params['dateRangeMietwagen'].rangeStart && this.params['dateRangeMietwagen'].rangeEnd) {
      this.params['dateRangeMietwagenText'] = this.params['dateRangeMietwagen'].rangeStart.format('L') +
        ' - ' +
        this.params['dateRangeMietwagen'].rangeEnd.format('L');
    } else {
      this.params['dateRangeMietwagenText'] = '';
    }

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for standorteMietwagen
   * sidebar param.
   */
  private _locationsRentalSave(initialSave?: boolean): void {
    if (this.params['standorteMietwagen'].StandorteAbholung) {
      this.params['abholungTextMietwagen'] = this.params['standorteMietwagen'].StandorteAbholung.map((location: any) => {
        return location['Name'];
      }).reduce((a: string, b: string) => {
        return a + ', ' + b;
      });
    } else {
      this.params['abholungTextMietwagen'] = '';
    }

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Validates the chosen data for the
   * rental.
   */
  private _validate(params: { [key: string]: any }): boolean {
    this.params['dateRangeMietwagen'].noSelection = false;
    this.params['standorteMietwagen'].noSelection = false;

    if (!params['dateRangeMietwagen'].rangeStart || !params['dateRangeMietwagen'].rangeEnd) {
      this.params['dateRangeMietwagen'].noSelection = true;
      this._mpSidebar.open('sidebarCalendarRental', this.params['dateRangeMietwagen']);
      return false;
    }

    if (!(params['standorteMietwagen'].StandorteAbholung && params['standorteMietwagen'].StandorteAbholung.length) ||
      (params['standorteMietwagen'].AndereRueckgabe && !(params['standorteMietwagen'].StandorteRueckgabe && params['standorteMietwagen'].StandorteRueckgabe.length))) {
      this.params['standorteMietwagen'].noSelection = true;
      this._mpSidebar.open('sidebarLocationsRental', this.params['standorteMietwagen']);
      return false;
    }

    return true;
  }

  /**
   * Triggers the search.
   */
  search(evt: MouseEvent): void {
    evt.preventDefault();

    const rentalParams = {
      dateRangeMietwagen: this.params['dateRangeMietwagen'],
      standorteMietwagen: this.params['standorteMietwagen']
    }

    if (this._validate(rentalParams)) {
      sessionStorage['mietwagenParams'] = JSON.stringify(rentalParams);
      this._router.navigateByUrl(`/${this._role}/MietwagenAuswahl`);
    }
  }

}
