import { Injectable } from '@angular/core';

/**
 * This class provides the functions to handle
 * the positons of the slider items.
 */
@Injectable({
  providedIn: 'root'
})
export class CarouselSliderSlideService {

  public mouseIsDown: boolean = false;

  constructor() { }

  /**
   * Handles a "slide" per button click.
   */
  slidePerButton(slideDirection: string): void {
    const slider = document.getElementById('winCalenderSlider');

    if (slider !== null) {
      const sliderUl = slider.querySelector('ul');

      if (sliderUl !== null) {
        let sliderItemWidth = 0;
        const sliderItemPosition = 0;
        const sliderUlLi = sliderUl.querySelector('li');

        if (sliderUlLi !== null) {
          sliderItemWidth = (sliderUl.getBoundingClientRect().width + (parseInt(getComputedStyle(sliderUlLi).getPropertyValue('margin-top').replace('px', '')) * 2));
          this.slideItems(false, sliderUl, sliderItemWidth, sliderItemPosition, slideDirection);
        }        
      }
    }
  }

  /**
   * Moves the items in the slider,
   * while sliding.
   */
  movingItems(handler: string, mouseDownPositionX: number, sliderUl: HTMLUListElement, sliderUlLi: HTMLLIElement, sliderItemPosition: number, element: HTMLElement): void {
    let slideDirection = '';
    let sliderItemWidth = 0;
    let swipePositionX = 0;

    const moveItemsEventListener = (evt: any) => {
      if (this.mouseIsDown) {
        sliderItemWidth = (sliderUlLi.getBoundingClientRect().width + (parseInt(getComputedStyle(sliderUlLi).getPropertyValue('margin-top').replace('px', ''), 0) * 2));
        swipePositionX = evt['pageX'] - mouseDownPositionX;

        if (handler === 'touch') {
          swipePositionX = evt['touches'][0].screenX - mouseDownPositionX;
        }

        if (swipePositionX >= 0 && swipePositionX > (sliderItemWidth * 0.75)) {
          this.mouseIsDown = false;
          element.removeEventListener(handler + 'move', moveItemsEventListener.bind(this));
          slideDirection = 'left';
          this.slideItems(false, sliderUl, sliderItemWidth, sliderItemPosition, slideDirection);
        } else if (swipePositionX < 0 && swipePositionX < (-(sliderItemWidth * 0.75))) {
          this.mouseIsDown = false;
          element.removeEventListener(handler + 'move', moveItemsEventListener.bind(this));
          slideDirection = 'right';
          this.slideItems(false, sliderUl, sliderItemWidth, sliderItemPosition, slideDirection);
        }
      }
    };

    element.addEventListener(handler + 'move', moveItemsEventListener.bind(this))    
  }

  /**
   * Slides the items into position.
   */
  slideItems(initial: boolean, sliderUl: HTMLUListElement, sliderItemWidth: number, sliderItemPosition: number, slideDirection: string): void {
    if (!initial) {
      sliderUl.querySelectorAll('li').forEach((li: any, index: number) => {
        if (index === 0 && slideDirection === 'right') {
          sliderUl.insertAdjacentElement('beforeend', li);
        } else if (index === (sliderUl.children.length - 1) && slideDirection === 'left') {
          sliderUl.insertAdjacentElement('afterbegin', li);
        }
      });

      sliderUl.querySelectorAll('li').forEach((li: any, index: number) => {
        sliderItemWidth = (li.getBoundingClientRect().width + (parseInt(getComputedStyle(li).getPropertyValue('margin-top').replace('px', '')) * 2));
        sliderItemPosition = (sliderItemWidth * index);

        if (li.classList.contains('center-left-slide-item')) {
          li.classList.remove('center-left-slide-item');
        }

        if (li.classList.contains('center-slide-item')) {
          li.classList.remove('center-slide-item');
        }

        if (li.classList.contains('center-right-slide-item')) {
          li.classList.remove('center-right-slide-item');
        }

        li.style.left = sliderItemPosition + 'px';

        if (sliderItemPosition == sliderItemWidth) {
          if (!li.classList.contains('center-left-slide-item')) {
            li.classList.add('center-left-slide-item');
          }
        }

        if (sliderItemPosition == (sliderItemWidth * 2)) {
          if (!li.classList.contains('center-slide-item')) {
            li.classList.add('center-slide-item');
          }
        }

        if (sliderItemPosition == (sliderItemWidth * 3)) {
          if (!li.classList.contains('center-right-slide-item')) {
            li.classList.add('center-right-slide-item');
          }
        }
      });
    } else {
      sliderUl.querySelectorAll('li').forEach((li: any, index: number) => {
        sliderItemWidth = (li.getBoundingClientRect().width + (parseInt(getComputedStyle(li).getPropertyValue('margin-top').replace('px', '')) * 2));
        sliderItemPosition = (sliderItemWidth * index);

        li.style.left = sliderItemPosition + 'px';

        if (sliderItemPosition == sliderItemWidth) {
          if (!li.classList.contains('center-left-slide-item')) {
            li.classList.add('center-left-slide-item');
          }
        }

        if (sliderItemPosition == (sliderItemWidth * 2)) {
          if (!li.classList.contains('center-slide-item')) {
            li.classList.add('center-slide-item');
          }
        }

        if (sliderItemPosition == (sliderItemWidth * 3)) {
          if (!li.classList.contains('center-right-slide-item')) {
            li.classList.add('center-right-slide-item');
          }
        }
      });
    }
  }

}
