<div id="first-login" *ngIf="(ls.locsLoaded | async) && firstLoginSettingsLoaded && statusLoaded" class="layout-container">

  <div *ngIf="firstLoginSettings['ShowTeilnahmebedingungDatenschutzInExtraView'] && firstLoginSettings['TeilnahmebedingungenBestaetigen'] && !status['TeilnahmebdingungenBestaetigt']" [hidden]="initialLoginService.step !== 1">
    <mp-core-first-login-conditions-of-participation></mp-core-first-login-conditions-of-participation>
  </div>

  <div *ngIf="!firstLoginSettings['ShowTeilnahmebedingungDatenschutzInExtraView'] && (firstLoginSettings['TeilnahmebedingungenBestaetigen'] && !status['TeilnahmebdingungenBestaetigt'] || firstLoginSettings['StammdatenErgaenzen'] && !status['StammdatenKomplett'])" [hidden]="initialLoginService.step !== 1">
    <mp-core-first-login-major-data-conditions-of-participation [firstLoginSettings]="firstLoginSettings" [hasMajorData]="hasMajorData" [hasConditionsOfParticipation]="hasConditionsOfParticipation"></mp-core-first-login-major-data-conditions-of-participation>
  </div>

  <!--<div *ngIf="firstLoginSettings['SicherheitsfragenAusfuellen'] && !status['SicherheitsfragenBeantwortet']" [hidden]="initialLoginService.step !== 2">
    erstanmeldung-sicherheitsfragen => No directive found in AngularJS
  </div>-->

  <div *ngIf="firstLoginSettings['ShowTeilnahmebedingungDatenschutzInExtraView'] && firstLoginSettings['StammdatenErgaenzen'] && !status['StammdatenKomplett']" [hidden]="initialLoginService.step !== 3">
    <mp-core-first-login-major-data [firstLoginSettings]="firstLoginSettings"></mp-core-first-login-major-data>
  </div>

  <div *ngIf="firstLoginSettings['NewsletterOptIn'] && !status['NewsletterOptInKomplett']" [hidden]="initialLoginService.step !== 4">
    <mp-core-first-login-newsletter></mp-core-first-login-newsletter>
  </div>

  <div [hidden]="initialLoginService.step !== 5">
    <mp-leas-first-login-prtcpnt-advertise-prtcpnt></mp-leas-first-login-prtcpnt-advertise-prtcpnt>
  </div>

  <div [hidden]="initialLoginService.step !== 6">
    <mp-leas-first-login-set-group></mp-leas-first-login-set-group>
  </div>

  <div [hidden]="initialLoginService.step !== 7">
    <mp-leas-first-login-quarter-survey></mp-leas-first-login-quarter-survey>
  </div>

  <div class="erstanmeldung-buttons margin-top-24">
    <button type="button" class="mp-button _prim" (click)="next()">{{ ls.locs['loc'].Weiter }}</button>
    <button type="button" class="mp-button _prim margin-left-16" [hidden]="initialLoginService.step !== 5" (click)="skip()">{{ ls.locs['locLeas'].Ueberspringen }}</button>
  </div>
</div>
