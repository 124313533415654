import { Injectable } from '@angular/core';

import { MpLoader } from './interfaces/mp-loader';

/**
 * This class provides functionalities
 * for sharing and extending loader
 * objects across different component
 * hierarchies.
 */
@Injectable({
  providedIn: 'root'
})
export class MpLoaderService {

  private _registeredLoaders: { [key: string]: MpLoader } = {};

  constructor() { }

  /**
   * Check is loader is registered.
   */
  loaderExists(loaderName: string): boolean {
    const keys = Object.keys(this._registeredLoaders);

    if (keys.indexOf(loaderName) !== -1) {
      return true;
    }

    return false;
  }

  /**
   * Returns a registered loader.
   */
  getLoader(loaderName: string): MpLoader {
    return this._registeredLoaders[loaderName];
  }

  /**
   * Extends a registered loader.
   */
  extendLoader(loaderName: string, extend: any): void {
    this._registeredLoaders[loaderName] = Object.assign(this._registeredLoaders[loaderName], extend);
  }

  /**
   * Registers a given loader.
   */
  registerLoader(loader: MpLoader): void {
    if (!this.loaderExists(loader.name)) {
      this._registeredLoaders = Object.assign(this._registeredLoaders, { [loader.name]: loader });
    }
  }

  /**
   * Removes a registered loader.
   */
  unregisterLoader(loaderName: string): void {
    if (this.loaderExists(loaderName)) {
      delete this._registeredLoaders[loaderName];
    }
  }

}
