
  <div layout-admin id="main" class="position-relative layout-manager" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
    <div id="wrapper-top">
      <header id="header" class="position-relative">
        <div class="layout-inner position-container">
          <div id="shop-logo" [hidden]="!mpSettings.settings['DisplaySettings']">
            <a href="#/admin/Home">
              <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoSecondary}}" alt="Pr&auml;emienshop Logo">
            </a>
          </div>
          <div id="company-logo" [hidden]="!mpSettings.settings['DisplaySettings']">
            <a href="#/admin/Home">
              <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoPrimary}}" alt="Firmenlogo">
            </a>
          </div>
          <mp-core-menu [items]="userMenuItems" mpClass="user-menu" [showLogout]="true" [(anyOpen)]="userMenuOpen" mpId="user-menu" sameMenuSelector="#mobile-user-menu, #navbar-user-menu"></mp-core-menu>
        </div>
      </header>
      <nav id="navbar" [ngClass]="{'is-sticky': navbarIsSticky}">
        <div class="floating-container">
          <div id="hamburger-menu-button"
               class="float-left pointer-hover"
               (click)="openHamburger()">
            <a class="display-block">
              <i class="svg-icon __clr-navbar __wide __menu"></i>
            </a>
          </div>
          <div id="navbar-title"
               class="system-icon-text float-left">
            {{ ls.locs['loc'].Menu }}
          </div>
          <mp-core-menu *ngIf="mainMenuItems.length > 0"
                        mpId="main-menu"
                        [items]="mainMenuItems"
                        sameMenuSelector="#mobile-main-menu, #navbar-main-menu"
                        [showLogout]="true">
          </mp-core-menu>
        </div>
      </nav>
    </div>
    <!--<mp-slider></mp-slider>-->
    <div id="main-content" class="overflow-hidden">
      <router-outlet name="mainContentAdmin"></router-outlet>
    </div>
    <footer id="footer">
      <div class="layout-inner position-container">
        <div id="company-address" [innerHTML]="ls.locs['loc'].FooterText"></div>
        <div id="imprint">
          <a *ngIf="!mpSettings.settings['DisplaySettings'].ImpressumLink" href="#/admin/Impressum">{{ ls.locs['loc'].Impressum }}</a>
          <a *ngIf="mpSettings.settings['DisplaySettings'].ImpressumLink" href="{{mpSettings.settings['DisplaySettings'].ImpressumLink}}" target="_blank">{{ ls.locs['loc'].Impressum }}</a>
        </div>
      </div>
    </footer>
    <div id="shadow" [ngClass]="{open: hamburgerOpen || mpSidebar.visible}"></div>
    <div id="sb-overlay-shadow" [ngClass]="{open: mpSidebar.visible}"></div>
    <div id="mobile-menu" [ngClass]="{open: hamburgerOpen}" (clickOutside)="closeHamburger()" click-outside-except="#hamburger-menu-button">
      <div id="mobile-menu-inner-scroll">
        <mp-core-menu *ngIf="userMenuItems.length > 0"
                      mpId="mobile-user-menu"
                      [items]="userMenuItems"
                      sameMenuSelector="#user-menu, #navbar-user-menu"
                      [(anyOpen)]="userMenuOpen">
        </mp-core-menu>
        <mp-core-menu *ngIf="mainMenuItems.length > 0"
                      mpId="mobile-main-menu"
                      [items]="mainMenuItems"
                      sameMenuSelector="#main-menu, #navbar-user-menu"
                      [hidden]="userMenuOpen">
        </mp-core-menu>
      </div>
      <div id="mobile-menu-inner-fixed">
        <mp-core-logout></mp-core-logout>
      </div>
      <div id="menu-close" class="floating-container">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="right">
          <button class="mp-button padding-left-right-8 float-left" (click)="closeHamburger()">
            <i class="svg-icon __clr-navbar __size-18 __top-0 __clear"></i>
          </button>
        </mp-core-tooltip>
      </div>
    </div>
    <div mpCoreSidebar #sidebar="mpCoreSidebar" id="sidebar" class="sb-overlay sb-overview-sidebar" [ngClass]="{open: sidebar.mpSidebar.visible}">
      <div id="sidebar-inner-scroll">
        <div id="sidebar-content">
          <ng-container *ngComponentOutlet="sidebar.contentComponent"></ng-container>
        </div>
      </div>
      <div id="sidebar-close" class="floating-container">
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="left">
          <button class="mp-button padding-left-right-8 float-right" (click)="sidebar.mpSidebar.close()">
            <i class="svg-icon __clr-default __size-18 __top-0 __clear"></i>
          </button>
        </mp-core-tooltip>
      </div>
    </div>
    <div mpCoreMpAntiForgery></div>
  </div>
