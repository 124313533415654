<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Versteuerungsgrundlage }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Versteuerungsgrundlage }}"
                      infotextShorten="Versteuerungsgrundlage"
                      fieldsAction="/api/Teilnehmer/getVersteuerungsgrundlageTnFields"
                      listAction="/api/Teilnehmer/getVersteuerungsgrundlageTn"
                      loaderName="paymentOfTaxBaseParticipantLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [showDatePicker]="true"
                      [alwaysShowFilter]="true">
    <!--mpSettings.settings['DisplaySettings'].ShowFilterAlways-->
  </mp-core-data-table>

  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}" class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</a>
  </div>
</div>
