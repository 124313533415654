import { Injectable } from '@angular/core';

/**
 * This class provides some auth functions, like
 * functions for setting roles and mark user as logged
 * in.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userLoggedIn: boolean = false;
  private _token: string = '';
  private _role: string = '';

  constructor() { }

  /**
   * Gets the token, if it is set,
   * otherwise trys to get the token
   * from the session storage.
   */
  getToken(): string {
    if (this._token === '') {
      const token = sessionStorage.getItem('token');

      if (token !== null) {
        this._token = token;
      }
    }

    return this._token;
  }

  /**
   * Sets the given token.
   */
  setToken(token: string): void {
    this._token = token;
  }

  /**
   * Sets the role by given token.
   */
  setRoleByToken(token: string): void {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    this._role = decodedToken.role;
  }

  /**
   * Sets the role by given string.
   */
  setRole(role: string): void {
    this._role = role;
  }

  /**
   * Returns the role.
   */
  getRole(): string {
    return this._role;
  }

  /**
   * Returns the users logged in state.
   */
  getUserLoggedIn(): boolean {
    return this.userLoggedIn;
  }

  /**
   * Sets the user as logged in.
   */
  setUserLoggedIn(): void {
    this.userLoggedIn = true;
  }

  /**
   * Sets the user as logged out
   * and resets token and role.
   */
  setUserLoggedOut(): void {
    this.userLoggedIn = false;
    this._token = '';
    this._role = '';
    sessionStorage.clear();
  }
}
