import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

/**
 * This class provides the data and functions
 * for the credit proposal page.
 */
@Component({
  selector: 'mp-leas-credit-proposal',
  templateUrl: './credit-proposal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CreditProposalComponent implements OnInit, OnDestroy {

  public proposal: string = '';
  public proposalInfo: { [key: string]: any } = {};
  public points: string = '0';
  public blockButton: boolean = false;
  public user: string = '';
  public statusPoints: string = '0';

  private _getProposalInfoSubscription: Subscription | undefined;
  private _setProposalSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Sets teh menu highlighting.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getProposalInfoSubscription !== 'undefined') {
      this._getProposalInfoSubscription.unsubscribe();
    }

    if (typeof this._setProposalSubscription !== 'undefined') {
      this._setProposalSubscription.unsubscribe();
    }
  }

  /**
   * Resets the form-
   */
  private _reset(): void {
    this.proposal = '';
    this.proposalInfo = {};
    this.points = '0';
    this.user = '';
    this.statusPoints = '0';
    this.blockButton = false;
  }

  /**
   * Gets the proposal information.
   */
  getProposalInfo(): void {
    this.blockButton = false;

    this._getProposalInfoSubscription = this._apiService.postRequest('api/Proposal/GetProposalInfo', {
      Proposal: this.proposal,
      Benutzer: this.user
    }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.proposalInfo = data.Records[0];

        if (this.proposalInfo['ErrorMessage']) {
          this._mpMessaging.openWarningPanel(this.proposalInfo['ErrorMessage']);
          this.blockButton = true;
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openWarningPanel(error.Message);
    });
  }

  /**
   * Saves the proposal.
   */
  saveProposal(): void {
    if (!this.proposal
      || !this.user
      || !this.proposalInfo['PunktefaktorZumDatum']
      || this.proposalInfo['PunktefaktorZumDatum'] === 0
      || this.points === '0')
      return;

    const data = {
      Proposal: this.proposal,
      Benutzer: this.user,
      Punkte: this.points,
      StatusPunkte: this.statusPoints
    };

    this._setProposalSubscription = this._apiService.postRequest('api/Proposal/SetProposal', data).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);

      if (data.Result === 'OK') {
        this._reset();
      }
    },
    (error: any) => {
      this._mpMessaging.openWarningPanel(error.Message);
    });
  }

}
