<section id="fruehjahrsaktion-autohaus" *ngIf="(ls.locsLoaded | async)">
  <div id="fj-auto-fade-animation" class="width-full height-full position-absolute"></div>
  <div class="width-full height-full position-absolute">
    <div id="fj-auto-background" [innerHTML]="backgroundSvg"></div>
    <div id="fj-auto-autohaus" [innerHTML]="dealerSvg"></div>
    <div id="fj-auto-shild" [innerHTML]="signSvg"></div>
  </div>
  <div id="fj-auto-game-information" class="width-full height-full position-absolute">
    <div [ngClass]="{ 'gami-gami-info-no-doors': !showDoors }" class="gami-infos-container display-flex flex-align-items-center flex-justify-cont-end box-sizing-border-box">
      <div class="gami-game-info gami-game-info-keys flex-grid">
        <mp-core-tooltip class="flex-col-6 flex-col-6-xs flex-align-items-center flex-justify-cont-center cursor-pointer" tooltipTitle="{{ ls.locs['locGamification'].OffeneSchluessel }}" alignment="left">
          <div class="flex-grid gami-game-info-keys-available">
            <div class="flex-col-6 flex-col-6-xs flex-align-items-center flex-justify-cont-center">
              <svg version="1.1" id="Layer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="39.3px" height="16.6px" viewBox="0 0 39.3 16.6" style="enable-background:new 0 0 39.3 16.6;" xml:space="preserve"><g id="schluessel"><path class="st-fj-key-0" d="M31,0c-4,0-7.3,2.8-8.1,6.5c0,0,0,0,0,0l-21.1,0c-1,0-1.8,1-1.7,1.9c0.1,1,0.9,1.7,1.9,1.7h1.6l0,2.7c0,0.7,0.7,1.3,1.3,1.2c0.7-0.1,1.2-0.6,1.2-1.3l0-2.6h1.8l0,1.6c0,0.7,0.7,1.3,1.3,1.2c0.7-0.1,1.2-0.6,1.2-1.3v-1.5l12.4,0c0.9,3.7,4.1,6.4,8.1,6.4c4.6,0,8.3-3.7,8.3-8.3S35.5,0,31,0z M31,13.1c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C35.8,11,33.6,13.1,31,13.1z" /></g></svg>
            </div>
            <div class="flex-col-6 flex-col-6-xs text-align-center flex-align-items-center flex-justify-cont-end">
              <span [innerText]="positionOld['SchluesselVerfuegbar']"></span>
            </div>
          </div>
        </mp-core-tooltip>
        <span class="padding-left-right-4 gami-game-info-keys-separator">|</span>
        <mp-core-tooltip class="flex-col-6 flex-col-6-xs flex-align-items-center flex-justify-cont-center" tooltipTitle="{{ ls.locs['locGamification'].EingeloesteSchluessel }}" alignment="left">
          <div class="flex-grid gami-game-info-keys-used">
            <div class="flex-col-6 flex-col-6-xs text-align-center flex-align-items-center flex-justify-cont-start">
              <span [innerText]="positionOld['Schluessel']"></span>
            </div>
            <div class="flex-col-6 flex-col-6-xs flex-align-items-center flex-justify-cont-center">
              <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 43.9 30.7" width="43.9px" height="30.7px" style="enable-background:new 0 0 43.9 30.7;" xml:space="preserve"><radialGradient id="schluessel_gesamt_schluesselloch_1_" cx="6.6694" cy="17.5969" r="10.37" gradientUnits="userSpaceOnUse"><stop offset="0.5744" style="stop-color:#161412" /><stop offset="0.7977" style="stop-color:#161412" /><stop offset="1" style="stop-color:#161412" /></radialGradient><path id="schluessel_gesamt_schluesselloch" class="schluessel_gesamt_st0" d="M13.3,11.2c0-3.7-3-6.7-6.7-6.7S0,7.5,0,11.2c0,2.8,1.7,5.2,4.2,6.2v10.8c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V17.4C11.6,16.4,13.3,14,13.3,11.2z" /><path id="schluessel_gesamt_schluessel" class="schluessel_gesamt_st1" d="M32.8,0c-5.3,0-9.8,3.8-10.9,8.8c0,0,0,0,0,0H6.7v4.9h15.3c1.2,4.9,5.6,8.6,10.8,8.6c6.1,0,11.1-5,11.1-11.1C43.9,5,38.9,0,32.8,0z M32.8,17.6c-3.6,0-6.5-2.9-6.5-6.5c0-3.6,2.9-6.5,6.5-6.5c3.6,0,6.5,2.9,6.5,6.5C39.3,14.7,36.3,17.6,32.8,17.6z" /></svg>
            </div>
          </div>
        </mp-core-tooltip>
      </div>
      <div *ngIf="showDoors" class="gami-game-info flex-grid margin-left-24">
        <div class="flex-col-6 flex-col-6-xs flex-align-items-center flex-justify-cont-center">
          <svg version="1.1" id="Layer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32.8px" height="42.4px" viewBox="0 0 32.8 42.4" style="enable-background:new 0 0 32.8 42.4;" xml:space="preserve"><g id="tuer_1_"><g><polygon class="st-fj-doors-0" points="28.9,0 26.6,2.6 6.1,2.6 6.1,40.4 8.5,40.4 8.5,10.2 28.6,10.2 28.6,40.4 31,40.4 31,2.6 		" /><g><polygon class="st-fj-doors-1" points="28.9,0 28.9,40.9 26.4,40.9 26.4,7.6 6.4,7.6 6.4,40.9 3.9,40.9 3.9,0 			" /></g><rect y="40.9" class="st-fj-doors-2" width="32.8" height="1.5" /></g><rect x="25.5" y="7.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 51.9702 48.5287)" class="st-fj-doors-3" width="0.9" height="33.3" /><rect x="6.4" y="7.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 13.8108 48.5287)" class="st-fj-doors-3" width="0.9" height="33.3" /></g></svg>
        </div>
        <div class="flex-col-6 flex-col-6-xs flex-align-items-center flex-justify-cont-center">
          <span [innerText]="positionOld['Tueren']"></span>
        </div>
      </div>
    </div>
    <div id="gamification-positions-list">
      <h2 class="text-uppercase">{{ ls.locs['locGamification'].Rangliste }}</h2>
      <div *ngFor="let rang of ranking; trackBy: trackByIndex" class="padding-top-bottom-8" [ngClass]="{'gami-post-list-current-tn': rang.TnId === positionOld['TnId'], 'gami-post-list-click-area': rang.TnId === 0, 'gami-gami-info-no-doors': !showDoors}">
        <span *ngIf="rang.TnId !== 0" class="margin-right-4">{{rang.RangNr}}</span>
        <span *ngIf="rang.TnId !== 0" class="margin-right-4">{{rang.Name}}</span>
        <span *ngIf="rang.TnId !== 0">{{rang.Schluessel}} {{ ls.locs['locGamification'].FruehjahrsaktionSchluessel }}</span>
        <span *ngIf="rang.TnId !== 0 && showDoors">{{rang.Tueren}} {{ ls.locs['locGamification'].FruehjahrsaktionTuerenKurz }}</span>
        <span *ngIf="rang.TnId === 0" (click)="toggleRanking()" class="pointer display-flex flex-align-items-center flex-justify-cont-center">
          {{ ls.locs['locGamification'].RanglisteOeffnen }}
        </span>
      </div>
    </div>
    <button class="mp-button _prim" [ngClass]="{disabled: !positionOld['SchluesselVerfuegbar'] || positionOld['SchluesselVerfuegbar'] === 0}" (click)="openDoor()">{{ ls.locs['locGamification'].FruehjahrsaktionSchluesselNutzen }}</button>
  </div>
  <mp-core-modal id="gamification-modal" [show]="showRanking" title="{{ ls.locs['locGamification'].Rangliste }}" [buttons]="[{text: ls.locs['loc'].Schliessen, action: toggleRankingFunc}]">
    <mp-core-select class="margin-bottom-16" label="{{ ls.locs['loc'].Gruppe }}" [elements]="rankingGroups['Gruppen']" textProperty="label" [model]="rankingGroups['Gruppe']" (modelChange)="rankingGroups['Gruppe'] = $event; filterGroups()"></mp-core-select>
    <div id="gamification-modal-list">
      <div *ngFor="let rang of filteredRanking; trackBy: trackByIndex" class="padding-top-bottom-8" [ngClass]="{'gami-post-list-current-tn': rang.TnId === positionOld['TnId'], 'gami-gami-info-no-doors': !showDoors}">
        <span class="margin-right-4">{{rang.RangNr}}</span>
        <span class="margin-right-4">{{rang.Name}}</span>
        <span *ngIf="rang.TnId !== 0">{{rang.Schluessel}} {{ ls.locs['locGamification'].FruehjahrsaktionSchluessel }}</span>
        <span *ngIf="rang.TnId !== 0 && showDoors">{{rang.Tueren}} {{ ls.locs['locGamification'].FruehjahrsaktionTuerenKurz }}</span>
      </div>
    </div>
  </mp-core-modal>
</section>
