<div id="charity-spenden" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="stage">
    <owl-carousel-o id="charity-carousel" class="owl-theme" [options]="charitySliderProperties">
      <ng-container *ngFor="let item of [{ID:1}]">
        <ng-template carouselSlide>
          <div class="item">
            <img src="/themes/default/media/buehnen/seiten/charity.jpg?width={{ mpSettings.settings['DisplaySettings'].BuehnenBildBreite }}&height={{ mpSettings.settings['DisplaySettings'].BuehnenBildHoehe }}&mode=crop" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="layout-container">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <h1>{{ ls.locs['locLeas'].BubblesSpenden }}</h1>
    <div>
      <p [innerHTML]="ls.locs['locLeas'].BubblesSpendenTextOben"></p>
    </div>
    <div>
      <p [innerHTML]="accountBalanceText"></p>
      <div class="inputRequired" [hidden]="errorBubbles === ''" [innerText]="errorBubbles"></div>
      <div class="row">
        <div class="grid col-small-6 col-medium-3">
          <mp-core-input-integer class="fallback-input" label="{{ ls.locs['loc'].Punkte }}" [mpRequired]="true" mpId="bubbles-spenden-anzahl" mpType="text" [(model)]="bubbles"></mp-core-input-integer>
        </div>
      </div>
      <div class="margin-top-24">
        <button type="button" class="mp-button _prim" (click)="donate()" [attr.disabled]="loading ? '' : null">{{ ls.locs['locLeas'].BubblesSpenden }}</button>
      </div>
    </div>
    <div>
      <p [innerHTML]="ls.locs['locLeas'].BubblesSpendenTextUnten | format:[role]"></p>
    </div>
  </div>
</div>
