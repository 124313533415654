import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLoaderService } from './../../../../services/mp-loader.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';


/**
 * This class provides the data and functions
 * for the account table.
 */
@Directive({
  selector: '[mpCoreAccount]',
  exportAs: 'mpCoreAccount'
})
export class AccountDirective implements OnInit, OnDestroy {

  @Input() searchTerm: string = '';

  public loaderRegistered: boolean = false;
  public threatenedPointsPreview: number = 0;
  public threatenedPoints: number = 0;
  public threatenedStatusPoints: number = 0;
  public nextDecayDate: string = '';
  public scrollToStatusAccount = this._scrollToStatusAccount.bind(this);

  private _accountTableLoader: MpLoader = {
    name: 'accountTableLoader',
    params: {}
  };
  private _selection: Array<any> = [];
  private _getAccountTypesSubscription: Subscription | undefined;
  private _getPointsDecaySubscription: Subscription | undefined;
  private _getThreatenedStatusPointsSubscription: Subscription | undefined;

  constructor(
    private _mpLoaderService: MpLoaderService,
    private _apiService: ApiService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Gets tht account types and the points
   * decay information.
   */
  ngOnInit(): void {
    this._mpLoaderService.registerLoader(this._accountTableLoader);
    this.loaderRegistered = true;

    this._getAccountTypesSubscription = this._apiService.getRequest('/api/Konto/getKontoarten').subscribe((data: any) => {
      this._selection = data.Records;

      this._mpLoaderService.extendLoader(this._accountTableLoader.name, {
        params: {
          Array: this._selection.map((item: any) => { return item['Status']; })
        },
        isReady: true
      });
    });

    this._getPointsDecaySubscription = this._apiService.getRequest('/api/Konto/GetPunkteverfall').subscribe((data: any) => {
      const pv = data.Records[0];
      this.threatenedPointsPreview = pv['Verfallspunkte'];
      this.threatenedPoints = pv['Verfallspunktevorschau'];
      this.nextDecayDate = pv['Verfallsdatum'];
    });

    this._mpMenuHighlight.setMenuHighlight('konto');

    if (this._route.snapshot.pathFromRoot[2].routeConfig !== null && this._route.snapshot.pathFromRoot[2].routeConfig.path === 'Konto') {
      this._titleService.setTitleFromLoc('Konto');
    }

    this._getThreatenedStatusPointsSubscription = this._apiService.getRequest('/api/Konto/GetStatusPunkteverfall').subscribe((data: any) => {
      this.threatenedStatusPoints = data.Records[0];
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getAccountTypesSubscription !== 'undefined') {
      this._getAccountTypesSubscription.unsubscribe();
    }

    if (typeof this._getPointsDecaySubscription !== 'undefined') {
      this._getPointsDecaySubscription.unsubscribe();
    }

    if (typeof this._getThreatenedStatusPointsSubscription !== 'undefined') {
      this._getThreatenedStatusPointsSubscription.unsubscribe();
    }
  }

  /**
   * Scrolls to status account table, if
   * hash is set.
   */
  private _scrollToStatusAccount(): void {
    setTimeout(() => {
      if (this._route.snapshot.fragment !== null) {
        const anchor = document.getElementById(this._route.snapshot.fragment);

        if (anchor !== null) {
          anchor.scrollIntoView();
        }
      }
    }, 1500);
  }

  /**
   * Gets the text for the multiselect.
   */
  getMultiDropDownText(): string {
    const text = this._selection.reduce((memo: any, item: any) => {
      return memo ? memo + ', ' + item['Art'] : item['Art'];
    }, '');

    if (text)
      return text.length > 30 ? text.substring(0, 30) + '...' : text;

    return text;
  }

  /**
   * Triggers a search.
   */
  search(): void {
    const loader = this._mpLoaderService.getLoader(this._accountTableLoader.name);

    this._mpLoaderService.extendLoader(this._accountTableLoader.name, {
      params: Object.assign(loader.params || {}, {
        SuchtextOutside: this.searchTerm
      })
    });

    if (typeof loader.load !== 'undefined') {
      loader.load();
    }
  }

}
