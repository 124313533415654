<div class="layout-container"
     *ngIf="(ls.locsLoaded | async)"
     id="theme-stage-management">
  <mp-core-infotext [sitename]="'themenbuehnenVerwalten'"></mp-core-infotext>
  <button class="mp-button"
          (click)="loadActive()"
          [ngClass]="{'_prim': active}">
    {{ ls.locs['loc'].Aktiviert }}
  </button>
  <button class="mp-button"
          (click)="loadArchived()"
          [ngClass]="{'_prim': archived}">
    {{ ls.locs['loc'].Archiviert }}
  </button>
  <a class="mp-button float-right"
     href="#/manager/ThemenbuehnenEditor"
     [ngClass]="{'_prim': active}"
     [innerHTML]="ls.locs['loc'].ThemenbuehneAnlegen">
  </a>
  <h1 class="mobile-only">{{ ls.locs['loc'].ThemenbuehnenVerwalten }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].ThemenbuehnenVerwalten }}"
                      fieldsAction="/api/Themenbuehnen/GetThemenbuehnenFields"
                      listAction="/api/Themenbuehnen/GetThemenbuehnenForVerwaltung"
                      loaderName="getThemeStagesLoader"
                      [paging]="true"
                      [disableCache]="true"
                      [execClickFunctions]="execClickFunctions"
                      defaultSorting="Anlagedatum DESC">
  </mp-core-data-table>
</div>

<mp-core-modal [show]="confirmDelete"
          title="{{ ls.locs['loc'].ThemenbuehnemodulConfirmDeleteTitle }}"
          [buttons]="[{text: ls.locs['loc'].Nein, action: toggleDeleteThemeStageFunc}, {text: ls.locs['loc'].Ja, action: deleteThemeStageFunc, class: '_prim'}]">
  <span>{{ ls.locs['loc'].ThemenbuehnemodulConfirmDeleteText }}</span>
</mp-core-modal>
<mp-core-modal [show]="showDontApprove"
          title="{{ ls.locs['loc'].ThemenbuehneNichtVeroeffentlichenHinweisTitel }}"
          [buttons]="[{text: ls.locs['loc'].Nein, action: toggleShowDontApproveFunc}, {text: ls.locs['loc'].Ja, action: removeApprovalFunc, class: '_prim'}]">
  {{ ls.locs['loc'].ThemenbuehneNichtVeroeffentlichenHinweisText }}
</mp-core-modal>

<mp-core-modal [show]="showDuplicate"
          title="{{ ls.locs['loc'].ThemenbuehneDuplizierenHinweisTitel }}"
          [buttons]="[{text: ls.locs['loc'].Nein, action: toggleShowDuplicateFunc}, {text: ls.locs['loc'].Ja, action: duplicateFunc, class: '_prim'}]">
  {{ ls.locs['loc'].ThemenbuehneDuplizierenHinweisText }}
</mp-core-modal>
