<div class="magma-input flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8 position-relative {{errClass}}">
  <label class="flex-col-12 font-weight-700 font-size-xxs padding-left-right-0"
         for="{{mpId}}"
         [innerHTML]="label">
  </label>
  <daterangepicker class="flex-col-12 font-size-general padding-left-right-8"
                   id="{{mpId}}"
                   (keyup)="executeMpEnter($event)"
                   (rangeSelected)="rangeSelected($event)"
                   #pickerElem
                   (click)="calculatePosition()"
                   [options]="daterangepickerOptions">
  </daterangepicker>
</div>
