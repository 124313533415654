<div class="layout-container bg-color-white padding-bottom-48" *ngIf="(ls.locsLoaded | async)">
  <div id="register" class="PWDVergessenContainer">
    <h1>{{ ls.locs['loc'].PWDVergessenTitle }}</h1>
    <p [innerHTML]="ls.locs['loc'].PWDVergessenTitleDescription"></p>
    <div>
      <div class="margin-left-32"> {{ls.locs['loc'].PWDVergessenLength}} </div>
      <div *ngIf="mpSettings.settings['PasswortSettings'].NeedsLowerCase" class="margin-left-32"> {{ls.locs['loc'].PWDVergessenSmallLetters}} </div>
      <div *ngIf="mpSettings.settings['PasswortSettings'].NeedsUpperCase" class="margin-left-32"> {{ls.locs['loc'].PWDVergessenCapitalLetters}} </div>
      <div *ngIf="mpSettings.settings['PasswortSettings'].NeedsNumbers" class="margin-left-32"> {{ls.locs['loc'].PWDVergessenNumbers}} </div>
      <div *ngIf="mpSettings.settings['PasswortSettings'].NeedsSymbols" class="margin-left-32"> {{ls.locs['loc'].PWDVergessenSpecialChars | format:[specialChars]}} </div>
    </div>
    <div class="margin-top-32">
      <div [hidden]="!data['errMessages']" class="MessageBox bg-danger" [innerHTML]="data['errMessages']"></div>
      <mp-core-input label="{{ ls.locs['loc'].NeuesPasswortWaehlen }}" [(model)]="data['PWD']" mpId="passwort" mpType="password" [mpRequired]="true"></mp-core-input>
      <mp-core-form-error *ngIf="errors['PWD']" [model]="errors['PWD']" class="margin-bottom-32"></mp-core-form-error>
      <mp-core-input label="{{ ls.locs['loc'].PasswortWiederholen }}" [(model)]="data['PWDRepeat']" mpId="passwort2" mpType="password" [mpRequired]="true"></mp-core-input>
      <mp-core-form-error *ngIf="errors['PWDRepeat']" [model]="errors['PWDRepeat']" class="margin-bottom-32"></mp-core-form-error>
      <button class="mp-button _prim margin-top-16" (click)="confirm()">
        {{ ls.locs['loc'].Bestaetigen }}
      </button>
    </div>
  </div>
</div>
