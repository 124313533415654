<section id="summer-race-pit" *ngIf="(ls.locsLoaded | async)">
  <div id="summer-race-pit-container" class="position-relative flex-grid flex-align-items-start flex-justify-cont-center">
    <div id="summer-race-pit-repair" [innerHTML]="pitRepairSvg" class="flex-col-8 flex-align-items-center flex-justify-cont-center"></div>
    <div id="summer-race-pit-bollard" [innerHTML]="bollardSvg" class="position-absolute"></div>
    <div id="summer-race-pit-settings" class="position-absolute">
      <div id="summer-race-pit-set-cars">
        <h2>{{ ls.locs['locGamification'].RennwagenWaehlen }}</h2>
        <div class="flex-grid flex-align-items-center flex-justify-cont-start flex-wrap">
          <div class="pointer flex-col-4" *ngFor="let car of availableCars" [innerHTML]="carsSvgs[car]" (click)="setSelectedCar(car)"></div>
        </div>
      </div>
      <div id="summer-race-pit-set-colors">
        <h2>{{ ls.locs['locGamification'].FarbeWaehlen }}</h2>
        <div class="flex-grid flex-align-items-center flex-justify-cont-start flex-wrap">
          <div class="pointer flex-col-2" *ngFor="let color of availableColors" [ngClass]="color" (click)="setSelectedColor(color)"></div>
        </div>
      </div>
    </div>
    <div id="summer-race-pit-track" [innerHTML]="trackSvg" class="flex-col-4 flex-align-items-center flex-justify-cont-start"></div>
    <div id="summer-race-pit-start" class="position-absolute">
      <a href="" class="mp-button _sec" (click)="saveRacer($event)">{{ ls.locs['locGamification'].Starten }}</a>
    </div>
    <div id="summer-race-pit-tyres" [innerHTML]="wheelSvg" class="position-absolute"></div>
    <div id="summer-race-pit-car" [ngClass]="selectedColor" [innerHTML]="carsSvgs[selectedCar]" class="position-absolute summer-race-car"></div>
  </div>
</section>
