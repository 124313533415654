import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

import { TcAdditionalPaymentPayplaceCreditcardComponent } from './../components/tc-additional-payment-payplace/tc-additional-payment-payplace-creditcard/tc-additional-payment-payplace-creditcard.component';
import { TcAdditionalPaymentPayplacePaypalComponent } from './../components/tc-additional-payment-payplace/tc-additional-payment-payplace-paypal/tc-additional-payment-payplace-paypal.component';
import { TcAdditionalPaymentPayplaceDebitcardComponent } from './../components/tc-additional-payment-payplace/tc-additional-payment-payplace-debitcard/tc-additional-payment-payplace-debitcard.component'

import { Provider } from './../services/interfaces/rk-provider';

import { ApiService } from '@core/services/api.service';

/**
 * This class provides the functions for
 * the additional payment.
 */
@Injectable({
  providedIn: 'root'
})
export class TcAdditionalPaymentService {

  public paymentType: string = '';

  private _functions: { [key: string]: Function } = {};
  private _provider: Array<Provider> = [];
  private _getProviderSubscription: Subscription | undefined;

  private _providerChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public providerChange: Observable<boolean> = this._providerChange.asObservable();

  constructor(
    private _apiService: ApiService
  ) { }

  /**
   * Registers a function within the
   * additional payment service.
   */
  register(key: string, func: Function): void {
    this._functions[key] = func;
  }

  /**
   * Calls a registered function.
   */
  call(key: string, successCallback: Function, errorCallback: Function): void {
    if (typeof this._functions[key] !== 'undefined') {
      this._functions[key](successCallback, errorCallback);
    }
  }

  /**
   * Loads the available providers.
   */
  refreshProvider(): void {
    this._getProviderSubscription = this._apiService.getRequest('/api/RkZuzahlung/GetProvider').subscribe((data: any) => {
      this._provider = data.Records;
      this._providerChange.next(true);

      if (typeof this._getProviderSubscription !== 'undefined') {
        this._getProviderSubscription.unsubscribe();
      }
    });
  }

  /**
   * Returns the available providers.
   */
  getProvider(): Array<Provider> {
    return this._provider;
  }

  /**
   * Maps the child template path
   * from backendto the child template
   * in Angular.
   */
  getChildComponent(childTemplate: string): any {
    switch (childTemplate) {
      case '/Views/ng-templates/rk-zuzahlung-payplace-debitcard.cshtml':
        return TcAdditionalPaymentPayplaceDebitcardComponent;
      case '/Views/ng-templates/rk-zuzahlung-payplace-paypal.cshtml':
        return TcAdditionalPaymentPayplacePaypalComponent;
      case '/Views/ng-templates/rk-zuzahlung-payplace-creditcard.cshtml':
        return TcAdditionalPaymentPayplaceCreditcardComponent;
    }
  }
}
