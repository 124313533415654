import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MpPageLoadService } from './mp-page-load.service';
import { SvgLoaderService } from './../components/svg-loader/svg-loader.service';

/**
 * This function provides the functions
 * to load the articles.
 */
@Injectable({
  providedIn: 'root'
})
export class MpLoadArticlesService {

  private _scope: any;
  private _targetProperty: any;
  private _scopeChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public scopeChange: Observable<boolean> = this._scopeChange.asObservable();
  private _routeParams: { [key: string]: any } = {};
  private _routeParamsSubscr: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public routeParams: Observable<any> = this._routeParamsSubscr.asObservable();
  private _last: any = { kategorieId: '', suchbegriff: '', markenname: '' };

  constructor(
    private _mpPageLoad: MpPageLoadService,
    private _svgLoader: SvgLoaderService
  ) { }

  /**
   * Resets the loaded data (if reset = true)
   * and gets the data (articles) for the next
   * page.
   */
  load(reset: boolean, callback: Function, filterOrSortingHasChanged : boolean = false): void {
    var k = this._last.kategorieId;
    var s = this._last.suchbegriff;
    var m = this._last.markenname;

    if (typeof this._routeParams['id'] !== 'undefined') {
      this._last.kategorieId = this._routeParams['id'];
      this._last.suchbegriff = '';
      this._last.markenname = '';
    } else {
      this._last.kategorieId = typeof this._routeParams['k'] !== 'undefined' ? this._routeParams['k'] : 0;
      this._last.suchbegriff = typeof this._routeParams['s'] !== 'undefined' ? this._routeParams['s'] : '';
      this._last.markenname = typeof this._routeParams['m'] !== 'undefined' ? this._routeParams['m'] : '';
    }

    var ksmHasChanged = k !== this._last.kategorieId || s !== this._last.suchbegriff || m !== this._last.markenname;

    // only load the page, if something has been changed, or no articleData were there (BACK-button in browser causes this)
    if (filterOrSortingHasChanged || ksmHasChanged || this._scope.articleData === undefined) {

      if (typeof this._scope !== 'undefined' && typeof this._targetProperty !== 'undefined' && reset) {
        this._mpPageLoad.reset(this._scope, this._targetProperty);
      }

      this._scope.articleData = [];
      this._svgLoader.startLoading();

      this._mpPageLoad.scopeObserve.subscribe(() => {
        this._scope = this._mpPageLoad.scope;
        this._scopeChange.next(true);
      });

      this._mpPageLoad.loadNext(
        this._scope,
        this._targetProperty,
        '/api/Artikel/getFilteredArtikel',
        {
          KategorieID: this._last.kategorieId,
          Suchbegriff: this._last.suchbegriff,
          Markenname: this._last.markenname
        },
        (data: any) => {
          this._svgLoader.finishLoading();
          callback(data);
        }
      );
    }
  }

  /**
   * Sets the scope object and the
   * target property to load the articles.
   */
  setScope(newScope: any, newTargetProperty: string) {
    this._scope = newScope;
    this._targetProperty = newTargetProperty;
    this._scopeChange.next(true);
  }

  /**
   * Returns ths scope object.
   */
  getScope(): any {
    return this._scope;
  }

  /**
   * Sets the rout params by the
   * component.
   */
  setRouteParams(params: any) {
    this._routeParams = params;
    this._routeParamsSubscr.next(params);
  }
}
