<div id="impressumloader">
  <section id="impressum" *ngIf="(ls.locsLoaded | async)" class="layout-container bg-color-white padding-bottom-48">
    <div class="white-content-container">
      <mp-core-breadcrumbs></mp-core-breadcrumbs>
      <h1>{{ ls.locs['loc'].Impressum }}</h1>
      <div>
        <div [innerHTML]="data"></div>
      </div>
      <a href="/">
        <button class="mp-button _prim margin-top-32">{{ ls.locs['loc'].Zurueck }}</button>
      </a>
    </div>
  </section>
</div>
