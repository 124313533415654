import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the anonymization
 * overview table.
 */
@Component({
  selector: 'mp-core-anonymization-overview',
  templateUrl: './anonymization-overview.component.html'
})
export class AnonymizationOverviewComponent implements OnInit, OnDestroy {
  
  public excelService: string = '/api/Teilnehmer/AnonymisierungsUebersicht2Excel';
  public loader: MpLoader | null = null;
  public enddate: Date = new Date();
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;

  private _anonymizationOverviewLoader: MpLoader = {
    name: 'anonymizationOverviewLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('AnonymisierungsUebersicht');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._anonymizationOverviewLoader);

    this._mpLoaderService.extendLoader('anonymizationOverviewLoader', {
      isReady: true
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('anonymizationOverviewLoader');
  }

}
