import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';

/**
 * This class provides the functionalities
 * for the target airport sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-target-airport-filter-flight',
  templateUrl: './sidebar-target-airport-filter-flight.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarTargetAirportFilterFlightComponent implements OnInit, OnDestroy {

  public targets: Array<any> = [];

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _customEventService: CustomEventService
  ) { }

  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.targets = JSON.parse(JSON.stringify(this.mpSidebar.params['ziele']));
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Saves the selected airports.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpSidebar.params['ziele'] = this.targets;
    this.mpSidebar.changeParams(this.mpSidebar.params);
    this.mpSidebar.close();
    this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, 'filterChanged'));
  }

}
