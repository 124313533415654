<div id="praemien-details" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div *ngIf="artNr !== '' && wlLoaded">
    <div id="praemien-details-top">
      <div class="layout-container">
        <mp-core-breadcrumbs></mp-core-breadcrumbs>
        <div class="row">
          <div class="grid col-large-6">
            <mp-core-image-video-carousel sourceUrl="/api/Artikel/getVisuals/{{artNr}}"></mp-core-image-video-carousel>
          </div>
          <div class="grid col-large-6">
            <div class="margin-top-48">
              <div class="margin-top-bottom-16 card-uvp-diff"
                   [hidden]="!percentageDiscount || percentageDiscount && percentageDiscount < 5">
                <span class="card-uvp-diff-badge text-uppercase">{{ ls.locs['loc'].ProzentRabatt | format:[percentageDiscount] }}</span>
              </div>
              <div [hidden]="brandImagePath === ''"
                   class="margin-bottom-16">
                <img [src]="brandImagePath+'?maxwidth=150&maxheight=70&s.grayscale='+!mpSettings.settings['DisplaySettings'].UseColoredHerstellerLogos"
                     mpCoreErrSrc errSrc="/themes/default/media/images/trans.png" />
              </div>
              <h2>{{artlabel1}}</h2>
              <h1>{{artlabel2}}</h1>
              <div class="margin-top-bottom-24"
                   [ngClass]="{selectContainer: dropDown.values.length > 1}"
                   *ngFor="let dropDown of dropDownItems">
                <mp-core-select class="max-width-256"
                           *ngIf="dropDown.values.length > 1"
                           (modelChange)="selectedCharacteristic[dropDown.dropDownIndex] = $event; setDropDownSelectedValue(dropDown.dropDownIndex)"
                           [model]="selectedCharacteristic[dropDown.dropDownIndex]"
                           [elements]="dropDown.values">
                </mp-core-select>
                <span *ngIf="dropDown.values.length <= 1">{{selectedCharacteristic[dropDown.dropDownIndex]}}</span>
              </div>
            </div>
            <div class="row">
              <div class="grid col-large-12">
                <div *ngIf="availability && lightGreenText && lightYellowText">
                  <i class="svg-icon  __top-4 __size-18  default-cursor-hover"
                     [ngClass]="{'__clr-success': availability === 1, '__clr-warning': availability === 2, '__clr-danger __oneway': availability === 3, '__check-circle': availability < 3}">
                  </i>
                  <span *ngIf="availability === 1"
                        class="icon-text margin-left-8">
                    {{ lightGreenText }}
                  </span>
                  <span *ngIf="availability === 2 && !showDiliveryNotice"
                        class="icon-text margin-left-8">
                    {{ lightYellowText }}
                  </span>
                  <span *ngIf="availability === 3 && !showDiliveryNotice"
                        class="icon-text margin-left-8">
                    {{ ls.locs['loc'].AmpelRot }}
                  </span>
                  <span *ngIf="availability > 1 && showDiliveryNotice"
                        class="icon-text margin-left-8">
                    {{diliveryNotice}}
                  </span>
                </div>
                <div [hidden]="!limitedAmount">
                  <span>
                    <i class="svg-icon __clr-warning __top-4 __size-18 __limited default-cursor-hover"></i><span class="icon-text margin-left-8">{{ ls.locs['loc'].SolangeDerVorratReicht }}</span>
                  </span>
                </div>
                <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasExpress" [hidden]="!showExpress">

                  <mp-core-popover content="{{ ls.locs['loc'].ExpressTooltip }}"
                              popoverTitle="{{ ls.locs['loc'].Express }}"
                              class="pointer no-word-wrap hide-on-medium"
                              alignment="right">
                    <span>
                      <i class="svg-icon __clr-specific-4 __top-5 __size-18 __express default-cursor-hover"></i>
                    </span><span class="hinweis icon-text margin-left-8">{{ ls.locs['loc'].ExpressMoeglich }}</span><i class="svg-icon __clr-default __top-4 __size-18 __info margin-left-8 float-none-important"></i>
                  </mp-core-popover>
                </div>

                <div *ngIf="hasStatus && mpSettings.settings['DisplaySettings'].ShowPraemienStatus===true && statusID" class="display-flex flex-align-items-center margin-top-2">
                  <img *ngIf="mpSettings.settings['DisplaySettings'].StatusIconsAsImages"
                       class="float-left icon status-icon-img margin-right-16"
                       src="/themes/default/media/icons/status-icon-{{ statusID }}.png" />

                  <span *ngIf="!mpSettings.settings['DisplaySettings'].StatusIconsAsImages">
                    <i class="svg-icon __clr-status-{{ statusID }} __size-18 __top-0 __state-round"></i>
                  </span>
                  <div [ngSwitch]="statusID">
                    <span *ngSwitchCase="1"
                          class="margin-left-8">
                      <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusBronzeVerfuegbar }}</span>
                    </span>
                    <span *ngSwitchCase="2"
                          class="margin-left-8">
                      <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusSilberVerfuegbar }}</span>
                    </span>
                    <span *ngSwitchCase="3"
                          class="margin-left-8">
                      <span class="icon-legend-text">{{ ls.locs['loc'].AbStatusGoldVerfuegbar }}</span>
                    </span>
                  </div>

                </div>
              </div>
              <div class="action grid col-large-12 margin-top-56">
                <div>
                  <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte">
                    <span *ngIf="comparePoints && percentageDiscount"
                          id="details-view-uvp"
                          class="position-relative margin-right-4">
                      {{comparePoints | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
                    </span>
                  </div>
                  <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPunkte && points" class="margin-bottom-16">
                    <span class="font-size-xl bold"
                          [attr.data-tooltip]="getTooltipText()">
                      {{points | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
                    </span>
                  </div>

                  <div *ngIf="mpSettings.settings['DisplaySettings'].ShowVerfuegbareMenge && mpSettings.settings['DisplaySettings'].HideBestellenOnVerfuegbarkeit0"
                       [hidden]="availableAmount === 0">
                    <div class="aticle-count margin-bottom-16"
                         [hidden]="!canOrder">
                      <span class="font-size-xs bolder">
                        {{ ls.locs['loc'].Stueckzahl }}
                      </span>
                      <mp-core-select [(model)]="count.value"
                                 *ngIf="availableAmount > 0" class="_min-width-100"
                                 [elements]="range">
                      </mp-core-select>

                      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowVerfuegbareMenge && availableAmount > 0"
                           class="__availability"
                           [ngClass]="availableAmount > 0 ? '__available' : '__not-available'">
                        <span class="font-size-s">
                          {{ ls.locs['loc'].von }} {{availableAmount | integer}}
                        </span>
                      </div>
                    </div>

                    <div class="display-flex flex-wrap flex-align-items-center">
                      <button [hidden]="!pin"
                              class="mp-button _prim margin-bottom-8"
                              [ngClass]="canOrder ? '' : 'disabled'"
                              (click)="addToShoppingbasket()">
                        <!--<i class="svg-icon __top--1 margin-right-4 __size-18 __basket float-none-important margin-right-8" [ngClass]="canOrder ? '__clr-navbar' : '__clr-inactive'"></i>-->
                        <span class="icon-text">{{ ls.locs['loc'].WarenkorbHinzufuegen }}</span>
                      </button>
                      <mp-core-tooltip *ngIf="mpSettings.settings['DisplaySettings'].ShowWunschlisteButton" [hidden]="isInWishlist()" class="display-inline-block margin-left-8" tooltipTitle="{{ ls.locs['loc'].WunschlisteHinzufuegen }}">
                        <a href (click)="toggleWishlist($event)" class="display-inline-block">
                          <i class="svg-icon __top--0 __size-24 __clr-default __favorite"></i>
                        </a>
                      </mp-core-tooltip>
                      <mp-core-tooltip [hidden]="!isInWishlist()" class="display-inline-block margin-left-8" tooltipTitle="{{ ls.locs['loc'].WLentfernen }}">
                        <a href (click)="toggleWishlist($event)" class="display-inline-block">
                          <i class="svg-icon __top--0 __size-24 __clr-primary __favorite-filled"></i>
                        </a>
                      </mp-core-tooltip>
                    </div>
                  </div>

                  <div *ngIf="!mpSettings.settings['DisplaySettings'].ShowVerfuegbareMenge || !mpSettings.settings['DisplaySettings'].HideBestellenOnVerfuegbarkeit0">
                    <div class="aticle-count margin-bottom-16"
                         [hidden]="!canOrder">
                      <span class="font-size-xs bolder">{{ ls.locs['loc'].Stueckzahl }}</span>
                      <mp-core-select [model]="count.value"
                                 [elements]="range"
                                 (ngChange)="count.value = $event"
                                 class="_min-width-100">
                      </mp-core-select>
                      <div *ngIf="mpSettings.settings['DisplaySettings'].ShowVerfuegbareMenge && availableAmount > -1" class="__availability" [ngClass]="availableAmount > 0 ? '__available' : '__not-available'">
                        <span class="font-size-s">
                          {{ ls.locs['loc'].von }} {{availableAmount | integer}}
                        </span>
                      </div>
                    </div>
                    <div class="display-flex flex-wrap flex-align-items-center">
                      <button [hidden]="!pin"
                              class="mp-button _prim margin-bottom-8"
                              [ngClass]="canOrder ? '' : 'disabled'"
                              (click)="addToShoppingbasket()">
                        <!--<i class="svg-icon __top--1 margin-right-4 __size-18 __basket float-none-important margin-right-8" [ngClass]="canOrder ? '__clr-navbar' : '__clr-inactive'"></i>-->
                        <span class="icon-text">{{ ls.locs['loc'].WarenkorbHinzufuegen }}</span>
                      </button>
                      <mp-core-tooltip *ngIf="mpSettings.settings['DisplaySettings'].ShowWunschlisteButton" [hidden]="isInWishlist()" class="display-inline-block margin-left-8" tooltipTitle="{{ ls.locs['loc'].WunschlisteHinzufuegen }}">
                        <a href (click)="toggleWishlist($event)" class="display-inline-block">
                          <i class="svg-icon __top--0 __size-24 __clr-default __favorite"></i>
                        </a>
                      </mp-core-tooltip>
                      <mp-core-tooltip *ngIf="mpSettings.settings['DisplaySettings'].ShowWunschlisteButton" [hidden]="!isInWishlist()" class="display-inline-block margin-left-8" tooltipTitle="{{ ls.locs['loc'].WLentfernen }}">
                        <a href (click)="toggleWishlist($event)" class="display-inline-block">
                          <i class="svg-icon __top--0 __size-24 __clr-primary __favorite-filled"></i>
                        </a>
                      </mp-core-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="mpSettings.settings['DisplaySettings'].ShowSachpraemienhinweisEu && land && land.ShowHinweis" class="grid col-large-12 margin-bottom-32 padding-left-12">
                <mp-core-info-notification iconPosition="left"
                                   [noFullWidth]="true"
                                   *ngIf="showNonMonetaryBonusNotice"
                                   infoState="warning"
                                   copyText="{{ ls.locs['loc'].SachpraemienhinweisEu }}">
                </mp-core-info-notification>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="praemien-details-bottom">
      <div class="layout-container">
        <div class="row">
          <div class="grid col-large-6">
            <h3>{{ ls.locs['loc'].ProduktdetailBeschreibung }}</h3>
            <div [innerHTML]="teaser"
                 [hidden]="!teaser">
            </div>
            <div [innerHTML]="description"></div>
          </div>
          <div class="grid col-large-6">
            <h3>{{ ls.locs['loc'].Eigenschaften }}</h3>
            <ul [innerHTML]="properties"
                *ngIf="!showPropertiesList">
            </ul>
            <table class="table simple"
                   *ngIf="showPropertiesList">
              <tr class="eigenschaftenLine"
                  *ngFor="let eig of propertiesList">
                <td class="eigenschaftKey">{{eig.Item}}</td>
                <td class="eigenschaftValue"
                    [innerHTML]="eig.Value">
                </td>
              </tr>
            </table>
            <div class="margin-top-bottom-24 font-style-italic">{{ ls.locs['loc'].AenderungenUndIrrtuermer }}</div>
            <div *ngIf="mpSettings.settings['DisplaySettings'].ShowArtNrLieferant" class="text-align-right">{{ ls.locs['loc'].ArtNr }} {{artNrSupplier}}</div>
            <div *ngIf="!mpSettings.settings['DisplaySettings'].ShowArtNrLieferant" class="text-align-right">{{ ls.locs['loc'].ArtNr }} {{artNr}}</div>
          </div>
        </div>
        <div class="divider"></div>
        <div [hidden]="!showLinks">
          <h2>{{ ls.locs['loc'].Links }}</h2>
          <div [innerHTML]="links"></div>
        </div>
        <div class="row">
          <div class="grid col-medium-12">
            <section id="passendePraemien"
                     *ngIf="showCompatibleArticles"
                     class="margin-top-24">
              <h3 class="passendePraemienHeaderTitel">{{ ls.locs['loc'].PassendeArtikel }}</h3>

              <owl-carousel-o id="praemieneinzel-carousel"
                               class="owl-theme"
                               [options]="compatibleArticlesOwlProperties">
                <ng-container class="item"
                     *ngFor="let item of compatibleArticles">
                  <ng-template carouselSlide [id]="item.ArtNr" [width]="owlWidth" style="width: auto;">
                    <mp-core-shop-card class="grid-card-s display-block" [tile]="item" [tileWidth]="1" [tileHeight]="1"></mp-core-shop-card>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </section>
            <section id="weiterePraemien"
                     *ngIf="showAdditionalArticles"
                     class="margin-top-24">
              <h3 [hidden]="!showAdditionalArticles">{{ ls.locs['loc'].WeiterePraemienVon }} {{producer}}</h3>
              <owl-carousel-o id="praemiendetails-carousel"
                               class="owl-theme"
                               [options]="additionalArticlesOwlProperties">
                <ng-container class="item"
                     *ngFor="let item of additionalArticles">
                  <ng-template carouselSlide [id]="item.ArtNr" [width]="owlWidth" style="width: auto;">
                    <mp-core-shop-card class="grid-card-s display-block" [tile]="item" [tileWidth]="1" [tileHeight]="1"></mp-core-shop-card>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
