import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Provides the available custom
 * event types.
 */
export enum CustomEventType {
  SendTimeFromSidebarPeriodTime = 'SEND_TIME_FROM_SIDEBAR_PERIOD_TIME',
  SendGroupingBool = 'SEND_GROUPING_BOOL',
  SendSelectedBool = 'SEND_SELECTED_BOOL',
  HideSvgLoader = 'HIDE_SVG_LOADER',
  ShowSvgLoader = 'SHOW_SVG_LOADER',
  TcSearchParamsUpdated = 'TC_SEARCH_PARAMS_UPDATED',
  ShowHideScrollButtons = 'SHOW_HIDE_SCROLL_BUTTONS',
  SetNotifyCookie = 'SET_NOTIFY_COOKIE',
  Logout = 'USER_LOGOUT',
  LoaderRegistered = 'LOADER_REGISTERED'
}

/**
 * Provides the custom event
 * with the payload.
 */
export class CustomEvent<T> {
  constructor(
    public type: CustomEventType,
    public payload: T,
  ) { }
}

/**
 * This class provides the
 * functionalitites to detech
 * and listen to custom events.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomEventService {

  private eventBrocker = new Subject<CustomEvent<any>>();

  constructor() { }

  /**
   * Listener function for custom event.
   */
  on(eventType: CustomEventType): Observable<CustomEvent<any>> {
    return this.eventBrocker.pipe(filter((event: CustomEvent<any>) => event.type === eventType));
  }

  /**
   * Dispatch function for custom event.
   */
  dispatch<T>(event: CustomEvent<T>): void {
    this.eventBrocker.next(event);
  }
}
