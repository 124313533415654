import { Directive, HostListener } from '@angular/core';

import { UserProfileNavbarService } from './../components/user-profile-navbar/user-profile-navbar.service';
import { MpDebounceService } from './../services/mp-debounce.service';

/**
 * This class provides the function, that
 * should be triggered, when the stage
 * image is loaded.
 */
@Directive({
  selector: '[mpCoreStageImageLoaded]'
})
export class StageImageLoadedDirective {

  constructor(
    private _userProfileNavbar: UserProfileNavbarService,
    private _mpDebounce: MpDebounceService
  ) { }

  /**
   * Triggers the scroll function of
   * UserProfileNavbarService.
   */
  @HostListener('load')
  onload() {
    this._mpDebounce.debounce(() => {
      this._userProfileNavbar.scroll();
    }, 1000)();
  }
}
