<div class="mp-info-noti-container">
  <div class="mp-info-noti mp-info-noti__{{infoState}} flex-grid flex-direction-row margin-top-16"
       *ngIf="iconPosition === 'left'"
       [ngClass]="{'_small-version': noFullWidth}">
    <div class="flex-col-1 mp-info-noti-icon flex-align-items-center flex-justify-cont-start">
      <i class="svg-icon __size-40 __top-0 __clr-{{infoState}} default-cursor-hover"
         [ngClass]="infoState === 'info' ? '__info' : '__error-outline'">
      </i>
    </div>
    <div class="flex-col-11 flex-direction-column flex-justify-cont-center mp-info-noti-text-container padding-left-32 padding-top-bottom-8">
      <span class="mp-info-noti-headline"
            [innerHtml]="headline"
            *ngIf="headline">
      </span>
      <span class="mp-info-noti-text"
            [innerHtml]="copyText">
      </span>
    </div>
  </div>
  <div class="mp-info-noti-top mp-info-noti-top__{{infoState}} bg-color-info text-color-white margin-0 flex-grid flex-direction-column flex-align-items-center padding-bottom-32 text-align-center"
       *ngIf="iconPosition === 'top'">
    <i class="svg-icon __clr-info __size-48 __top-0 __info default-cursor-hover"></i>
    <span>
      <ng-content></ng-content>
    </span>
  </div>
</div>
