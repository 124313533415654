<div layout-erstanmeldung id="main" class="position-relative" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <header id="header" class="position-relative" [ngClass]="{stickyNavbar: navbarIsSticky}">
    <div class="layout-inner position-container">
      <div id="shop-logo">
        <a href="#/tn/Home">
          <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoSecondary}}" alt="Pr&auml;emienshop Logo">
        </a>
      </div>
      <div id="company-logo">
        <a href="#/tn/Home">
          <img src="../../themes/default/media/logos/{{mpSettings.settings['DisplaySettings'].LogoPrimary}}" alt="Firmenlogo">
        </a>
      </div>
      <mp-core-menu *ngIf="userMenuItems.length > 0"
                    mpId="user-menu"
                    [items]="userMenuItems"
                    mpClass="user-menu"
                    sameMenuSelector="#mobile-user-menu, #navbar-user-menu"
                    [showLogout]="true"
                    [(anyOpen)]="userMenuOpen">
      </mp-core-menu>
    </div>
  </header>
  <div id="main-content" class="overflow-hidden">
    <router-outlet name="mainContentFirstLogin"></router-outlet>
  </div>
  <footer id="footer">
    <div class="layout-inner position-container">
      <div id="company-address" [innerHTML]="ls.locs['loc'].FooterText"></div>
      <div id="imprint">
        <a *ngIf="!mpSettings.settings['DisplaySettings'].ImpressumLink" href="#/firstlogin/Impressum">{{ ls.locs['loc'].Impressum }}</a>
      </div>
    </div>
  </footer>
  <div id="shadow" [ngClass]="{open: hamburgerOpen || mpSidebar.visible}"></div>
  <div id="sb-overlay-shadow" [ngClass]="{open: mpSidebar.visible}"></div>
  <div id="mobile-menu" [ngClass]="{open: hamburgerOpen}" (clickOutside)="closeHamburger()" click-outside-except="#hamburger-menu-button">
    <div id="mobile-menu-inner-scroll">
      <mp-core-menu *ngIf="userMenuItems.length > 0"
                    mpId="mobile-user-menu"
                    [items]="userMenuItems"
                    sameMenuSelector="#user-menu, #navbar-user-menu"
                    [(anyOpen)]="userMenuOpen">
      </mp-core-menu>
    </div>
    <div id="mobile-menu-inner-fixed" [hidden]="!hamburgerOpen">
      <mp-core-logout></mp-core-logout>
    </div>
    <div id="menu-close" class="floating-container">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="right">
        <button class="mp-button padding-left-right-8 float-left" (click)="closeHamburger()">
          <i class="svg-icon __clr-navbar __size-18 __top-0 __clear"></i>
        </button>
      </mp-core-tooltip>
    </div>
  </div>
  <div mpCoreSidebar #sidebar="mpCoreSidebar" id="sidebar" class="sb-overlay sb-overview-sidebar" [ngClass]="{open: sidebar.mpSidebar.visible}">
    <div id="sidebar-inner-scroll">
      <div id="sidebar-content">
        <ng-container *ngComponentOutlet="sidebar.contentComponent"></ng-container>
      </div>
    </div>
    <div id="sidebar-close" class="floating-container">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="left">
        <button class="mp-button padding-left-right-8 float-right" (click)="sidebar.mpSidebar.close()">
          <i class="svg-icon __clr-default __size-18 __top-0 __clear"></i>
        </button>
      </mp-core-tooltip>
    </div>
  </div>
  <mp-core-browser-support-modal></mp-core-browser-support-modal>
</div>
<div mpCoreMpAntiForgery></div>
<mp-core-cookie-consent></mp-core-cookie-consent>
