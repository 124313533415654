<div id="praemien-details" *ngIf="(ls.locsLoaded | async)">
  <div>
    <div id="praemien-details-top">

      <div class="layout-container">
        <div class="margin-top-48 margin-right-16" style="float: left;">
          <div>
            <img [src]="ls.locs['locSchulung'].SchulungFolienPfad + '/SchulungStart.jpg?mode=crop'" />
          </div>
        </div>
        <div class="margin-top-48">
          <h2>{{ ls.locs['locSchulung'].Demoschulung }}</h2>
          <h1 [innerText]="ls.locs['locSchulung'].Demoschulung"></h1>
        </div>
        <div class="margin-top-48">
          <a href="#/{{role}}/SchulungSeite">
            <button [hidden]="isTrainingCompleted" class="mp-button _prim margin-top-bottom-24">
              {{ ls.locs['locSchulung'].SchulungStarten }}
            </button>
          </a>
          <div [hidden]="!isTrainingCompleted" [innerText]="trainingCompletedDate" class="margin-top-16">
          </div>
        </div>

      </div>
    </div>
    <div id="praemien-details-bottom">
      <div class="layout-container">
        <div class="row">
          <div class="grid col-large-6">
            <h3>{{ ls.locs['locSchulung'].SchulungBeschreibungTitel }}</h3>
            <div>
              <p>{{ ls.locs['locSchulung'].SchulungBeschreibung }}</p>
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</div>

