<div *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async) && textLoaded">
  <div class="taw-rules-yields-headline padding-top-bottom-8 padding-left-right-16 text-transform-uppercase bg-color-brand-primary text-color-brand-secondary display-flex flex-align-items-center font-size-s">{{ ls.locs['locTipAndWin'].Spielregeln }}</div>
  <div class="bg-color-white box-sizing-border-box width-full padding-24 taw-rules-yields-section">
    <h1 [innerText]="text['Title']"></h1>
    <div *ngIf="text['Text']" [innerHTML]="trustAsHtml(text['Text'])"></div>
    <div *ngIf="!text['Text']">
      <a *ngIf="!mpSettings.settings['TipAndWinSettings'].HideSpielregelnDownloadButton" href="/themes/default/media/tipAndWin/Spielregeln.pdf" target="_blank" class="mp-button _prim margin-bottom-16">{{ ls.locs['locTipAndWin'].PdfDownload }}</a>
      <iframe src="/themes/default/media/tipAndWin/Spielregeln.pdf#toolbar=0" width="100%" height="700px"></iframe>
    </div>
  </div>
</div>
