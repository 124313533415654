import { Directive, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from './../../../services/api.service';
import { MpSidebarService } from '../mp-sidebar.service';
import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';


/**
 * This function provides the data for the
 * help sidebar and provides the trigger to
 * open the sidebar.
 */
@Directive({
  selector: '[mpCoreSidebarHelpIcon]',
  exportAs: 'mpCoreSidebarHelpIcon'
})
export class SidebarHelpIconDirective implements OnInit, OnDestroy {

  public showHelpSidebar: boolean = false;

  private _helpSidebarParams: Array<any> = [];
  private _getHelpSidebarContent: Subscription | undefined;

  constructor(
    private _apiService: ApiService,
    private _mpSidebar: MpSidebarService,
    private _authService: AuthService,
    private _router: Router
  ) { }

  /**
   * Gets the content for the help sidebar.
   */
  ngOnInit(): void {
    let path = this._getUrlWithoutParams();
    path = path.replace(/\//g, '');
    let role = this._authService.getRole();
    role = role.toLowerCase();
    path = path.replace(role, '');

    this._getHelpSidebarContent = this._apiService.getRequest('/api/Texte/GetHelpTexte', false, {
      params: {
        site: path
      }
    }).subscribe((data: any) => {
      if (typeof data.Result !== 'undefined') {
        if (data.Result === 'ERROR') {
          this._helpSidebarParams = [];
          this.showHelpSidebar = false;
          console.error(data.Message);
        }
      }

      if (data.Records[0] !== null && data.Records.length > 0) {
        this._helpSidebarParams = data.Records[0];
        this.showHelpSidebar = true;
      } else {
        this._helpSidebarParams = [];
        this.showHelpSidebar = false;
      }
    },
    (error: any) => {
      this._helpSidebarParams = [];
      this.showHelpSidebar = false;
      console.error(error);
    });
  }

  /**
   * Gets current route without params
   */
  _getUrlWithoutParams() {
    let urlTree = this._router.parseUrl(this._router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getHelpSidebarContent !== 'undefined') {
      this._getHelpSidebarContent.unsubscribe();
    }
  }

  /**
   * Opens the help sidebar.
   */
  openHelpSidebar(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    this._mpSidebar.open('HelpSidebar', this._helpSidebarParams);
  }

}
