import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';

/**
 * This class provides the data and
 * the functions for a single travelogues.
 */
@Component({
  selector: 'mp-rk-travelogue',
  templateUrl: './travelogue.component.html',
  styleUrls: ['./../travel-configurator/travel-configurator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TravelogueComponent implements OnInit, OnDestroy {

  public travelogue: { [key: string]: any } = {};

  private _routeParams: { [key: string]: any } = {};
  private _getTravelogueSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;
  private _routeParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    public _breadcrumbService: MpBreadcrumbService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Gets the locs, and sets the
   * breadcrumbs.
   */
  ngOnInit(): void {
    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._routeParams = params;

      if (Object.keys(this.ls.locs).length > 0) {
        this._breadcrumbService.setCurrentSiteWithText('Reisebericht?key=' + this._routeParams['key'], this.ls.locs['locReisekonfigurator'].Reisebericht);
        this._getTravelogue();

        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }
      } else {
        this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
          this._breadcrumbService.setCurrentSiteWithText('Reisebericht?keyy=' + this._routeParams['key'], this.ls.locs['locReisekonfigurator'].Reisebericht);
          this._getTravelogue();

          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        });

        this.ls.getLocalization();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTravelogueSubscription !== 'undefined') {
      this._getTravelogueSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the travelogue.
   */
  private _getTravelogue(): void {
    this._getTravelogueSubscription = this._apiService.getRequest(`/api/Reiseberichte/getReisebericht?key=${this._routeParams['key']}`).subscribe((data: any) => {
      if (data.Result === "OK") {
        this.travelogue = data.Records[0];
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }

    },
    (error: any) => {
      this._mpMessaging.openDangerPanel(error);
    });
  }

}
