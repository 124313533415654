import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';
import { InitialLoginService } from './../../../services/initial-login.service';
import { ApiService } from './../../../services/api.service';
import { MpSettingsService } from './../../../services/mp-settings.service';
import { MpBreadcrumbService } from './../../../services/mp-breadcrumb.service';
import { MpMenuHighlightService } from './../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../services/mp-title.service';
import { MpCoreService } from './../../../services/mp-core.service';

import { ConditionsOfParticipationComponent as ConditionsOfParticipation } from './../../../modules/participant/pages/conditions-of-participation/conditions-of-participation.component';
import { CookieService } from '@core/services/cookie.service';

/**
 * This class provides the functions and
 * data for the step "conditions of
 * participation" of the first login module.
 */
@Component({
  selector: 'mp-core-first-login-conditions-of-participation',
  templateUrl: './conditions-of-participation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ConditionsOfParticipationComponent extends ConditionsOfParticipation implements OnInit, OnDestroy {

  public accepted: boolean = false;
  public voluntary: boolean = false;
  public superior: boolean = false;
  public approved: boolean = false;
  public error: string = '';
  public configLoaded: boolean = false;

  private _getConfigSubscription: Subscription | undefined;
  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _saveConditionsOfParticipationSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public apiService: ApiService,
    public mpBreadcrumbService: MpBreadcrumbService,
    public mpMenuHighlight: MpMenuHighlightService,
    public titleService: MpTitleService,
    public mpCoreService: MpCoreService,
    public route: ActivatedRoute,
    public mpMessaging: MpMessagingService,
    public initialLoginService: InitialLoginService,
    public cookieService: CookieService
  ) {
    super(ls, mpSettings, apiService, mpBreadcrumbService, mpMenuHighlight, titleService, mpCoreService, route, cookieService);
  }

  /**
   * Gets the configuration. Subscribes to
   * the validate step and next step observabbles
   * of the InitialLoginService. Saves the
   * conditions of participation.
   */
  ngOnInit(): void {
    super.ngOnInit();

    this._getConfigSubscription = this.apiService.getRequest('/api/TermsAndConditions/GetTermsAndConditionsConfiguration').subscribe((data: any) => {
      const termsAndConditions = data.Records[0];
      this.configLoaded = true;

      if (termsAndConditions['HasExternalLink'] && !termsAndConditions['ExternalLinkIsDocument'])
        location.href = this.config['ExternalLink'];
    });

    this._validateStepSubscription = this.initialLoginService.validateStep(1).subscribe((validate: boolean) => {
      if (validate) {
        this.initialLoginService.validateCalled.next(false);
        const isValid = this._validateConditionsOfParticipation();
        this.initialLoginService.validBool = isValid;
        this.initialLoginService.valid.next(isValid);
      }
    });

    this._nextStepSubscription = this.initialLoginService.nextCalledStep(1).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this.initialLoginService.validBool) {
          this._saveConditionsOfParticipationSubscription = this.apiService.getRequest('/api/Erstanmeldung/setTeilnahmebedingungenBestaetigt?herkunft=Erstanmeldung').subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.initialLoginService.nextSuccess();
            } else {
              this.mpMessaging.openWarningPanel(data.Message);
              this.initialLoginService.nextFailure();
            }
          },
          (error: any) => {
            this.mpMessaging.openWarningPanel(error.Message);
            this.initialLoginService.nextFailure();
          });
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (typeof this._getConfigSubscription !== 'undefined') {
      this._getConfigSubscription.unsubscribe();
    }

    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._nextStepSubscription !== 'undefined') {
      this._nextStepSubscription.unsubscribe();
    }

    if (typeof this._saveConditionsOfParticipationSubscription !== 'undefined') {
      this._saveConditionsOfParticipationSubscription.unsubscribe();
    }
  }

  /**
   * Validates the conditions of participation.
   */
  private _validateConditionsOfParticipation(): boolean {
    this.error = '';

    if (!this.voluntary)
      this.error = this.ls.locs['locLeas'].ErrorTeilnahemFreiwillig;

    if (!this.superior)
      this.error = this.ls.locs['locLeas'].ErrorVorgesetzterEinverstanden;

    if (!this.accepted)
      this.error = this.ls.locs['loc'].DieTeilnahmebedingungenMuessenAkzeptiertWerden;

    return this.accepted && this.voluntary && this.superior;
  }

}
