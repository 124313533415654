<div class="magma-modal-background" [ngClass]="{open: show, '_wide-modal': setModalWideClass}">
  <div class="magma-modal floating-container box-shadow-dialog padding-16 {{modalClass}}" [ngClass]="{noTitle: !title}">
    <div class="magma-modal-title" *ngIf="title !== ''">
      <h3 class="magma-modal-title-text margin-bottom-0 text-overflow-ellipsis" [innerText]="title"></h3>
    </div>
    <hr *ngIf="title !== ''" />
    <div class="magma-modal-copy">
      <ng-content></ng-content>
    </div>
    <hr [hidden]="!actionsTemplate && !buttons || !actionsTemplate && buttons.length === 0" />
    <div class="magma-modal-actions flex-grid" [ngClass]="{ 'flex-justify-cont-center': centerButtons }">
      <div class="flex-col-12-xs" 
           *ngFor="let button of buttons; let index = index" 
           [ngClass]="{ 'flex-justify-cont-center': buttons.length === 1, 'flex-justify-cont-end': centerButtons && buttons.length > 1 && !(index % 2), 'flex-justify-cont-start': centerButtons && buttons.length > 1 && index % 2, 'flex-col-6 flex-col-6-s': buttons.length > 1, 'flex-col-12 flex-col-12-s': buttons.length === 1 }">
        <button class="mp-button {{button.class}}" (click)="executeClick(button)" [innerText]="button.text"></button>
      </div>
      <ng-container *ngIf="actionsTemplate">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
</div>
