import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

/**
 * This class provides the functions and
 * data for the racing overview page.
 */
@Component({
  selector: 'mp-gfc-racing-overview',
  templateUrl: './racing-overview.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RacingOverviewComponent implements OnInit, OnDestroy {

  public isRacer: boolean = false;
  public summerRaceTitle: string = '';

  private _player: { [key: string]: any } = {};
  private _role: string = '';
  private _getQueryParamsSubscription: Subscription | undefined;
  private _getTitleSubscription: Subscription | undefined;
  private _getIsRacerSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the race key, the information
   * whether the participant is racer,
   * the title and the role.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._player = {
        AktionenKey: params['key'],
        TnId: 0
      }

      this._getTitleSubscription = this._apiService.postRequest('/api/Gamification/GetTitel', this._player).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.summerRaceTitle = data.Records[0];
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });

      this._getIsRacerSubscription = this._apiService.postRequest('/api/Gamification/IsRennfahrer', this._player).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.isRacer = data.Records[0];
        } else {
          this._mpMessaging.openDangerPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._getTitleSubscription !== 'undefined') {
      this._getTitleSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the participation.
   */
  participate(evt: MouseEvent): void {
    evt.preventDefault();

    if (this.isRacer) {
      this.toRaceTrack();
    } else {
      this.toPit();
    }
  }

  /**
   * Routes to pit.
   */
  toPit(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    this._router.navigateByUrl(`/${this._role}/RacingBoxengasse`);
  }

  /**
   * Routes to race track.
   */
  toRaceTrack(): void {
    this._router.navigateByUrl(`/${this._role}/RacingRennstrecke`);
  }

}
