import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { TcAdditionalPaymentService } from './../../services/tc-additional-payment.service';

/**
 * This class provides the functions
 * for the additional payment payplace.
 */
@Component({
  selector: 'mp-rkzz-tc-additional-payment-payplace',
  templateUrl: './tc-additional-payment-payplace.component.html'
})
export class TcAdditionalPaymentPayplaceComponent implements OnInit, OnDestroy {

  public options: Array<any> = [];
  public bookingData: { [key: string]: any } = {};
  public missingPoints: number = 0;
  public provider: { [key: string]: any } = {};
  public paymentMethod: string = '';

  private _locsLoadedSubscription: Subscription | undefined;

  constructor(
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _tcAdditionalPaymentService: TcAdditionalPaymentService,
    public ls: MpLocalizationService,
    private _injector: Injector
  ) { }

  /**
   * Gets injected data, and locs.
   */
  ngOnInit(): void {
    this.bookingData = this._injector.get('tcAdditionalPaymentBookingData');
    this.provider = this._injector.get('tcAdditionalPaymentProvider');
    this.missingPoints = this._injector.get('tcAdditionalPaymentMissingPoints');

    if (Object.keys(this.ls.locs).length > 0) {
      this._setInititalData();
    } else {
      this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setInititalData();
        }
      })

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }
  }

  /**
   * Sets initial data, after localizations are
   * loaded.
   */
  private _setInititalData(): void {
    this.options = [
      {
        text:
          '<div class="payplace-select-img display-inline-block float-left"><img class="padding-right-8" class="margin-right-12" src="https://cdn.netcentive.de/logos/visa.png" height="24"/></div>' +
          '<div class="payplace-select-img display-inline-block float-left"><img img class="padding-right-16" src="https://cdn.netcentive.de/logos/mastercard.png" height="24"/></div> ' +
          ((this.ls.locs['locZuzahlung'] && this.ls.locs['locZuzahlung'].Kreditkarte) || ''),
        value: 'creditcard'
      },
      {
        text:
          '<div class="payplace-select-img display-inline-block float-left"><img class="padding-right-16" src="https://cdn.netcentive.de/logos/eckarte.png" height="24" /></div> ' +
          ((this.ls.locs['locZuzahlung'] && this.ls.locs['locZuzahlung'].Lastschrift) || ''),
        value: 'directdebit'
      }
    ];

    this._tcAdditionalPaymentService.register('rk-payplace-express', this.setPayplaceExpress.bind(this));
  }

  /**
   * Sets Payplace as method for the
   * additional payment.
   */
  setPayplaceExpress(successCallback: Function, errorCallback: Function): void {
    if (Object.keys(this.provider).length === 0 || !this.provider['paymentMethod']) {
      this._mpMessaging.openDangerPanel(this.ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
    } else {
      const data = {
        PunkteList: [this.missingPoints || 0],
        BuchungsData: this.bookingData,
        Provider: 'rk-payplace-express',
        PaymentMethod: this.provider['paymentMethod'].value
      };

      this._apiService.postRequest('/api/RkZuzahlung/zuzahlen', data).subscribe((responseData: any) => {
        if (responseData.Result !== 'OK') {
          this._mpMessaging.openWarningPanel(responseData.Message);
        } else {
          this._mpMessaging.openWarningPanel(responseData.Message);
        }

        successCallback(responseData);
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.ExceptionMessage);
        errorCallback(error);
      });
    }
  }

}
