import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpOrderProcessService } from './../mp-order-process.service';

/**
 * This class provides the functions for the
 * order process stepper navigation.
 */
@Component({
  selector: 'mp-core-order-process-stepper',
  templateUrl: './order-process-stepper.component.html',
  styleUrls: ['./order-process-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderProcessStepperComponent implements OnInit, OnDestroy {

  public steps: Array<any> = [];
  public currentStepIndex: number = 0;

  private _stepsSubscription: Subscription | undefined;

  constructor(
    private _elementRef: ElementRef,
    private _mpOrderProcess: MpOrderProcessService
  ) { }

  /**
   * Sets event listeners for resize,
   * and sets watcher for steps.
   */
  ngOnInit(): void {
    this._stepsSubscription = this._mpOrderProcess.steps.subscribe((steps: any) => {
      this.steps = [];

      Object.keys(steps).forEach((key: string) => {
        if (steps[key].Hide === false) {
          this.steps.push(steps[key]);
        }
      });

      this.currentStepIndex = this._mpOrderProcess.currentStep !== null ? this._mpOrderProcess.currentStep['Index'] : 0;
    });

    window.removeEventListener('resize', this._finishedRendering.bind(this));
    window.addEventListener('resize', this._finishedRendering.bind(this));
  }

  /**
   * Unsubscribes set subscribers.
   */
  ngOnDestroy(): void {
    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }
  }

  /**
   * Triggers function to set the
   * styling classes.
   */
  ngAfterViewChecked(): void {
    this._finishedRendering();
  }

  /**
   * Sets classes for the styling of
   * the steps.
   */
  private _finishedRendering(): void {
    const steps = this._elementRef.nativeElement.querySelectorAll('.single-step');

    if (steps.length > 0) {
      const firstStep = steps[0];

      steps.forEach((step: any, index: number) => {
        const firstStepTopPos = firstStep.getBoundingClientRect().top;
        const stepTopPos = step.getBoundingClientRect().top;
        step.style.zIndex = '';

        if (window.innerWidth > 767) {
          if (stepTopPos > firstStepTopPos) {
            for (var indexCnt = index; indexCnt >= 3; indexCnt--) {
              if (steps[indexCnt].classList.contains('minimized-step') === false) {
                steps[indexCnt].classList.add('minimized-step');
              }
            }

            for (var indexCnt = 0; indexCnt < 2; indexCnt++) {
              if (steps[indexCnt].classList.contains('minimized-prev-step') === false) {
                steps[indexCnt].classList.add('minimized-prev-step');
              }
            }
          }

          step.style.zIndex = steps.length - index;
        }

        if (step.classList.contains('steps-rendered') === false) {
          step.classList.add('steps-rendered');
        }
      });
    }
  }

  /**
   * Goes to the given step.
   */
  goToStep(step: any, evt: MouseEvent): void {
    evt.preventDefault();
    this._mpOrderProcess.goToStep(step);
  }

}
