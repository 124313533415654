import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ImageVideoCarouselModule } from '@core/components/image-video-carousel/image-video-carousel.module';

import { CharityComponent } from './charity.component';

import { HomeComponent } from './pages/home/home.component';
import { AwardDetailsComponent } from './pages/award-details/award-details.component';
import { AccountComponent } from './pages/account/account.component';

import { AccountDirective } from './pages/account/account.directive';

@NgModule({
  declarations: [
    HomeComponent,
    AwardDetailsComponent,
    AccountComponent,
    AccountDirective,
    CharityComponent
  ],
  exports: [
    CharityComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonInternComponentsLoaderModule,
    NgxPaginationModule,
    CarouselModule,
    ImageVideoCarouselModule
  ]
})
export class CharityModule { }
