<div id="sidebar-period" class="sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="texst-align-left">
      {{ ls.locs['loc'].ZeitraumAuswaehlen }}
    </div>
    <div class="divider margin-bottom-8"></div>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex"
         id="calendar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start"
           [ngClass]="{ 'active-tab': selection['rangeStart'] }">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['loc'].Von }}</span>
        <div>
          <span class="font-size-xs"
                *ngIf="selection['rangeStart']"
                [innerText]="selection['rangeStart'].format('dddd')">
          </span>
          <span *ngIf="selection['rangeStart']" [innerText]="selection['rangeStart'].format('L')"></span>
        </div>
      </div>
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start"
           [ngClass]="{ 'active-tab': selection['rangeEnd'] && selection['rangeEnd'] !== selection['rangeStart'] }">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['loc'].Bis }}</span>
        <div>
          <span *ngIf="selection['rangeEnd'] && selection['rangeStart'] && selection['rangeEnd'] !== null && selection['rangeEnd'] !== selection['rangeStart']"
                class="font-size-xs"
                [innerText]="selection['rangeEnd'].format('dddd')">
          </span>
          <span [innerText]="(selection['rangeEnd'] && selection['rangeEnd'] !== null && selection['rangeEnd'] !== selection['rangeStart']) ? selection['rangeEnd'].format('L') : ls.locs['loc'].Unbegrenzt"></span>
        </div>
      </div>
    </div>
    <div class="padding-left-right-8">
      <mp-core-select label=""
                      mpId="jahre-range"
                      [(model)]="selection['year']"
                      (ngChange)="selection['year'] = $event; yearChange();"
                      [elements]="yearRange"
                      class="padding-left-right-16 margin-left-0 margin-top-32 box-sizing-border-box display-block">
      </mp-core-select>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#reward-range-scroll-area"
                   scrollDirection="up"
                   desktopItemHeight=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="reward-range-scroll-area-container">
      <div id="reward-range-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="minDate"
                            [(maxDate)]="maxDate"
                            [(rangeStart)]="selection['rangeStart']"
                            [(rangeEnd)]="selection['rangeEnd']"
                            [isRange]="true"
                            [(minRange)]="minRange"
                            [defaultRange]="0"
                            [(currentSelection)]="selection['currentSelection']"
                            [endDatesLimitedToLastDayOfQuarter]="endDatesLimitedToLastDayOfQuarter">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#reward-range-scroll-area"
                   scrollDirection="down"
                   desktopItemHeight=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         [ngClass]="{disabled: !selection['rangeStart']}"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}
      </a>
    </div>
  </div>
</div>
