<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'gewinnkalenderAuswertung'"></mp-core-infotext>
  <h1 class="mobile-only">{{ ls.locs['locGewinnkalender'].GewinnkalenderAuswertung }}</h1>
  <mp-core-data-table title="{{ ls.locs['locGewinnkalender'].GewinnkalenderAuswertung }}" [recLoadedFunc]="recLoadedFunc" infotextShorten="gewinnkalenderAuswertung" fieldsAction="/api/Gewinnkalender/getGewinnkalenderAuswertungFields" listAction="/api/Gewinnkalender/getGewinnkalenderAuswertung" loaderName="prizeCalendarEvaluationLoader" defaultSorting="Von DESC"></mp-core-data-table>
  <div class="floating-container" *ngIf="loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
