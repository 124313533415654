import { Directive, OnInit, ElementRef, HostListener } from '@angular/core';

/**
 * This class provides the functions to
 * set the content to the full viewport
 * height.
 */
@Directive({
  selector: '[mpCoreMpContentFullHeight]'
})
export class MpContentFullHeightDirective implements OnInit {

  private _navbar: HTMLElement | null = null;
  private _header: HTMLElement | null = null;
  private _footer: HTMLElement | null = null;

  constructor(
    private _elementRef: ElementRef
  ) { }

  /**
   * Gets the relevant elements for
   * min height calculation, and sets
   * the initial min height.
   */
  ngOnInit(): void {
    this._navbar = document.querySelector('nav#navbar');
    this._header = document.querySelector('header#header');
    this._footer = document.querySelector('footer#footer');
    this._elementRef.nativeElement.style.minHeight = `${this._calculateMinHeight()}px`;
  }

  /**
   * Sets the min content height on
   * window scroll.
   */
  @HostListener('window:scroll')
  onscroll() {
    this._elementRef.nativeElement.style.minHeight = `${this._calculateMinHeight()}px`;
  }

  /**
   * Sets the min content height on
   * window resize.
   */
  @HostListener('window:resize')
  onresize() {
    this._elementRef.nativeElement.style.minHeight = `${this._calculateMinHeight()}px`;
  }

  /**
   * Gets the navbar height.
   */
  private _getNavbarHeight(): number {
    return this._navbar !== null ? this._navbar.getBoundingClientRect().height : 0;
  }

  /**
   * Gets the header height.
   */
  private _getHeaderHeight(): number {
    return this._header !== null ? this._header.getBoundingClientRect().height : 0;
  }

  /**
   * Gets the footer height.
   */
  private _getFooterHeight(): number {
    return this._footer !== null ? this._footer.getBoundingClientRect().height : 0;
  }

  /**
   * Calculates the min height of the
   * content.
   */
  private _calculateMinHeight(): number {
    const elemComputedStyles = window.getComputedStyle(this._elementRef.nativeElement);
    const computedPaddingTop = parseInt(elemComputedStyles.getPropertyValue('padding-top').replace('px', '').replace('rem', ''));
    const computedPaddingBottom = parseInt(elemComputedStyles.getPropertyValue('padding-bottom').replace('px', '').replace('rem', ''));

    if (this._navbar && this._navbar.classList.contains('is-sticky') !== false) {
      return window.innerHeight - this._getNavbarHeight() - this._getFooterHeight() - computedPaddingTop - computedPaddingBottom;
    } else {
      return window.innerHeight - this._getNavbarHeight() - this._getHeaderHeight() - this._getFooterHeight() - computedPaddingTop - computedPaddingBottom;
    }
  }

}
