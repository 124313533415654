import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';

@Component({
  selector: 'mp-rk-sidebar-travel-destinations-filter-hotel',
  templateUrl: './sidebar-travel-destinations-filter-hotel.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarTravelDestinationsFilterHotelComponent implements OnInit, OnDestroy {

  public searchTerm: string = '';
  public searchfieldPlaceholder: string = '';
  public targets: Array<any> = [];
  public activeItems: { [key: string]: any } = {};
  public focusOnInit: boolean = false;

  private _targets: Array<any> = [];
  private _sidebarParamsSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _customEventService: CustomEventService
  ) { }

  /**
   * Gets the sidebar params and the
   * localizations.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      if (typeof this.mpSidebar.params['ziele'] === 'undefined') {
        return;
      }

      this._targets = JSON.parse(JSON.stringify(this.mpSidebar.params['ziele']));
      this._updateTargetsAndActiveItems();
      this._setSearchfieldPlaceholder();
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this._setSearchfieldPlaceholder();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setSearchfieldPlaceholder();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the placeholder for the search field.
   */
  private _setSearchfieldPlaceholder(): void {
    if (this.ls.locs['locReisekonfigurator'] && this._targets && this._targets.length) {
      switch (this._targets[0].Typ) {
        case 1:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesHotel;
          break;
        case 2:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmterOrt;
          break;
        case 3:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesZielgebiet;
          break;
        case 4:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesLand;
          break;
        default:
          this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtesReiseziel;
          break;
      }
    }
  }

  /**
   * Updates the targets and the active items.
   */
  private _updateTargetsAndActiveItems(): void {
    let targetList = this._targets;
    this.targets = [];

    if (this.searchTerm !== '') {
      targetList = targetList.filter((target: any) => {
        return target['Bezeichnung'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    }

    // @ts-ignore
    const targetsObj = targetList.reduce((r, v, i, a, k = v.ParentId) => ((r[k] || (r[k] = [])).push(v), r), {});

    Object.keys(targetsObj).forEach((key: any) => {
      this.targets.push({
        parentId: key,
        targetList: targetsObj[key]
      });
    });

    this.activeItems = this.targets.map((target: any) => { return target['targetList'].every((childTarget: any) => { return childTarget['Active']; }); });
  }

  /**
   * Updates the child targets.
   */
  updateChildTargets(parentId: any): void {
    this.targets.forEach((target: any) => {
      if (target['parentId'] === parentId) {
        target['targetList'].forEach((childTarget: any) => {
          childTarget['Active'] = this.activeItems[parentId];
        });
      }
    });
  }

  /**
   * Triggers the update of the targets
   * and active items.
   */
  searchTermChange(): void {
    this._updateTargetsAndActiveItems();
  }

  /**
   * Clears the search field.
   */
  clearSearch(evt: MouseEvent): void {
    this.searchTerm = '';
    // @ts-ignore
    evt.currentTarget.parentElement.parentElement.querySelector('input').focus();
    this._updateTargetsAndActiveItems();
  }

  /**
   * Changes the active state of a
   * location target.
   */
  changeTargetState(evt: any, parentIndex: any, index: any): void {
    // @ts-ignore
    this.targets[parentIndex]['targetList'][index]['Active'] = evt.target.checked;
  }

  /**
   * Saves the selected targets.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    const mergeArrays: Array<any> = [];

    this.targets.forEach((target: any) => {
      mergeArrays.push(target['targetList']);
    });

    const flattenTargets = mergeArrays.reduce((a: any, b: any) => a.concat(b), []);

    this._targets.forEach((target: any) => {
      const filteredTarget = flattenTargets.find((flattenTarget: any) => {
        return flattenTarget['Code'] === target['Code'];
      });

      target['Active'] = typeof filteredTarget !== 'undefined' ? filteredTarget['Active'] : false;
    });

    this.mpSidebar.params['ziele'] = this._targets;
    this.mpSidebar.changeParams(this.mpSidebar.params);
    this.mpSidebar.close();
    this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, 'filterChanged'));
  }

}
