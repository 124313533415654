import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

/**
 * This class provides a simple micro-copy, that
 * can be configured with different input attributes.
 */
@Component({
  selector: 'mp-core-micro-copy',
  templateUrl: './micro-copy.component.html',
  styleUrls: ['./micro-copy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MicroCopyComponent implements OnInit {

  @Input() public infoState: any;
  @Input() public copyText: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
