import { Injectable } from '@angular/core';

/**
 * This class proivdes mapping functions for the mapping
 * of backend and frontend user roles.
 */
@Injectable({
  providedIn: 'root'
})
export class RoleMappingService {
  private _roleMapping: any = {
    tn: 'Teilnehmer',
    manager: 'Manager',
    admin: 'Admin',
    vl: 'vl',
    adm: 'adm',
    charity: 'Charity',
    firstlogin: [
      'Teilnehmer',
      'Charity'
    ]
  };

  constructor() { }

  /**
   * Returns the role name from the
   * mapping object.
   */
  getMappedRole(role: string): string {
    return this._roleMapping[role] ? this._roleMapping[role] : '';
  }

  /**
   * Returns the role key from the
   * mapping object.
   */
  getReverseMappedRole(role: string): string {
    let roleKey = '';

    Object.keys(this._roleMapping).forEach((key: string) => {
      if (this._roleMapping[key] === role) {
        roleKey = key;
      }
    });

    return roleKey;
  }

  /**
   * Returns the available role
   * keys as an array.
   */
  getRoleKeys(): Array<string> {
    return Object.keys(this._roleMapping);
  }

}
