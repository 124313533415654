import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from '@core/services/mp-permissions.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the tip and win game 
 * acceptance table.
 */
@Component({
  selector: 'mp-taw-tip-and-win-game-acceptance',
  templateUrl: './tip-and-win-game-acceptance.component.html'
})
export class TipAndWinGameAcceptanceComponent implements OnInit, OnDestroy {

  public campaigns: Array<any> = [];
  public selectedCampaignId: number = 0;
  public campaignsLoaded: boolean = false;
  public excelService: string = '/api/TipAndWin/GetSpielakzeptanz2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;

  private _tipAndWinGameAcceptanceLoader: MpLoader = {
    name: 'tipAndWinGameAcceptanceLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;
  private _getCampsaignsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('TipAndWinSpielakzeptanz');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._getCampsaignsSubscription = this._apiService.getRequest('/api/TipAndWin/GetAktionen').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.campaigns = data.Records;

        if (this.campaigns.length > 0) {
          this.selectedCampaignId = this.campaigns[0].AktionId;
          this._tipAndWinGameAcceptanceLoader.params = {
            AktionId: this.selectedCampaignId
          };
        }

        this._mpLoaderService.registerLoader(this._tipAndWinGameAcceptanceLoader);

        this._mpLoaderService.extendLoader('tipAndWinGameAcceptanceLoader', {
          isReady: true
        });

        this.campaignsLoaded = true;
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getCampsaignsSubscription !== 'undefined') {
      this._getCampsaignsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('tipAndWinGameAcceptanceLoader');
  }

  /**
   * Loads the data for the selected
   * campaign id.
   */
  changeCampaign(): void {
    if (this.loader !== null) {
      this.loader.params['AktionId'] = this.selectedCampaignId;

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

}
