import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { CommonInternComponentsLoaderModule } from '@core/modules/common-intern-components-loader.module';
import { ParticipantModule } from '@core/modules/participant/participant.module';
import { AdminModule } from '@core/modules/admin/admin.module';

import { RkRoutingModule } from './rk-routing.module';
import { RkComponent } from './rk.component';
import { TravelConfiguratorComponent } from './modules/participant/pages/travel-configurator/travel-configurator.component';
import { SidebarAirportComponent } from './components/sidebar/sidebar-airport/sidebar-airport.component';
import { SidebarTravelDestinationsHotelComponent } from './components/sidebar/sidebar-travel-destinations-hotel/sidebar-travel-destinations-hotel.component';
import { SidebarGuestsHotelComponent } from './components/sidebar/sidebar-guests-hotel/sidebar-guests-hotel.component';
import { SidebarCalendarHotelComponent } from './components/sidebar/sidebar-calendar-hotel/sidebar-calendar-hotel.component';
import { SidebarCalendarFlightComponent } from './components/sidebar/sidebar-calendar-flight/sidebar-calendar-flight.component';
import { SidebarCalendarBirthdayComponent } from './components/sidebar/sidebar-calendar-birthday/sidebar-calendar-birthday.component';
import { SidebarCalendarRentalComponent } from './components/sidebar/sidebar-calendar-rental/sidebar-calendar-rental.component';
import { SidebarPassengersFlightComponent } from './components/sidebar/sidebar-passengers-flight/sidebar-passengers-flight.component';
import { SidebarTravelDestinationsFilterHotelComponent } from './components/sidebar/sidebar-travel-destinations-filter-hotel/sidebar-travel-destinations-filter-hotel.component';
import { SidebarLocationsRentalComponent } from './components/sidebar/sidebar-locations-rental/sidebar-locations-rental.component';
import { SidebarTargetAirportFilterFlightComponent } from './components/sidebar/sidebar-target-airport-filter-flight/sidebar-target-airport-filter-flight.component';
import { SearchAreaHotelAndFlightComponent } from './components/search-area/search-area-hotel-and-flight/search-area-hotel-and-flight.component';
import { SearchAreaOnlyFlightComponent } from './components/search-area/search-area-only-flight/search-area-only-flight.component';
import { SearchAreaRentalComponent } from './components/search-area/search-area-rental/search-area-rental.component';
import { TcSupportComponent } from './components/tc-support/tc-support.component';
import { HotelChoiceComponent } from './modules/participant/pages/hotel-choice/hotel-choice.component';
import { HotelCardComponent } from './components/hotel-card/hotel-card.component';
import { HotelDetailsComponent } from './components/hotel-details/hotel-details.component';
import { TravelConfiguratorFilterComponent } from './components/travel-configurator-filter/travel-configurator-filter.component';
import { TcMobileSearchFilterComponent } from './components/tc-mobile-search-filter/tc-mobile-search-filter.component';
import { HotelCheckComponent } from './modules/participant/pages/hotel-check/hotel-check.component';
import { HotelConfirmationComponent } from './modules/participant/pages/hotel-confirmation/hotel-confirmation.component';
import { FlightDetailsComponent } from './components/flight-details/flight-details.component';
import { FlightCardComponent } from './components/flight-card/flight-card.component';
import { FlightChoiceComponent } from './modules/participant/pages/flight-choice/flight-choice.component';
import { TravelConfiguratorOnlyFlightFilterComponent } from './components/travel-configurator-only-flight-filter/travel-configurator-only-flight-filter.component';
import { FlightCheckComponent } from './modules/participant/pages/flight-check/flight-check.component';
import { FlightConfirmationComponent } from './modules/participant/pages/flight-confirmation/flight-confirmation.component';
import { RentalDetailsComponent } from './components/rental-details/rental-details.component';
import { RentalCardComponent } from './components/rental-card/rental-card.component';
import { RentalChoiceComponent } from './modules/participant/pages/rental-choice/rental-choice.component';
import { RentalFilterComponent } from './components/rental-filter/rental-filter.component';
import { RentalCheckComponent } from './modules/participant/pages/rental-check/rental-check.component';
import { RentalConfirmationComponent } from './modules/participant/pages/rental-confirmation/rental-confirmation.component';
import { TravelActivitiesEditorComponent } from './modules/admin/pages/travel-activities-editor/travel-activities-editor.component';
import { TravelActivitiesOverviewComponent } from './modules/admin/pages/travel-activities-overview/travel-activities-overview.component';
import { TravelogueCardComponent } from './components/travelogue-card/travelogue-card.component';
import { TraveloguesComponent } from './modules/participant/pages/travelogues/travelogues.component';
import { TravelogueComponent } from './modules/participant/pages/travelogue/travelogue.component';
import { TravelActivityRedirectComponent } from './modules/participant/pages/travel-activity-redirect/travel-activity-redirect.component';
import { TravelogueCardListCardComponent } from './components/travelogue-card-list-card/travelogue-card-list-card.component';
import { TravelogueCardListComponent } from './components/travelogue-card-list/travelogue-card-list.component';

@NgModule({
  declarations: [
    RkComponent,
    TravelConfiguratorComponent,
    SidebarAirportComponent,
    SidebarTravelDestinationsHotelComponent,
    SidebarGuestsHotelComponent,
    SidebarCalendarHotelComponent,
    SidebarCalendarFlightComponent,
    SidebarCalendarBirthdayComponent,
    SidebarCalendarRentalComponent,
    SidebarPassengersFlightComponent,
    SidebarTravelDestinationsFilterHotelComponent,
    SidebarLocationsRentalComponent,
    SidebarTargetAirportFilterFlightComponent,
    SearchAreaHotelAndFlightComponent,
    SearchAreaOnlyFlightComponent,
    SearchAreaRentalComponent,
    TcSupportComponent,
    HotelChoiceComponent,
    HotelCardComponent,
    HotelDetailsComponent,
    TravelConfiguratorFilterComponent,
    TcMobileSearchFilterComponent,
    HotelCheckComponent,
    HotelConfirmationComponent,
    FlightDetailsComponent,
    FlightCardComponent,
    FlightChoiceComponent,
    TravelConfiguratorOnlyFlightFilterComponent,
    FlightCheckComponent,
    FlightConfirmationComponent,
    RentalDetailsComponent,
    RentalCardComponent,
    RentalChoiceComponent,
    RentalFilterComponent,
    RentalCheckComponent,
    RentalConfirmationComponent,
    TravelActivitiesEditorComponent,
    TravelActivitiesOverviewComponent,
    TravelogueCardComponent,
    TraveloguesComponent,
    TravelogueComponent,
    TravelActivityRedirectComponent,
    TravelogueCardListCardComponent,
    TravelogueCardListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonInternComponentsLoaderModule,
    ParticipantModule,
    AdminModule,
    RkRoutingModule,
    NgxSliderModule,
    FormsModule,
    CarouselModule
  ],
  exports: [
    RkComponent,
    TcSupportComponent
  ],
  providers: [],
  bootstrap: [
    RkComponent
  ]
})
export class RkModule { }

@NgModule({})
export class RkSharedModule {
  static forRoot(): ModuleWithProviders<RkModule> {
    return {
      ngModule: RkModule,
      providers: []
    }
  }
}
