<div class="inbox-item-card" *ngIf="(ls.locsLoaded | async)" [ngClass]="{highlight: item.showChild || hoverItem, 'inbox-item-card--hovered': hoverItem}" (mouseenter)="hoverItem = true" (mouseleave)="hoverItem = false">
  <div class="card" id="{{item.Key}}" [ngClass]="{gelesen: item.Gelesen, ungelesen: !item.Gelesen}">
    <div class="flex-grid" [hidden]="item.showChild">
      <div [ngClass]="isEMailType ? 'flex-col-12' : 'flex-col-4 flex-col-12-m'">
        <div class="inbox-item-title header padding-top-bottom-16" [ngClass]="{'display-flex _is-email-type width-full': isEMailType}">
          <i class="svg-icon __clr-primary __size-18 __top-2 margin-right-16" [ngClass]="item.Type">
          <i *ngIf="item.HasAttachment && isEMailType" class="inbox-item__attachment-badge svg-icon ng-scope __attach_file __size-14 position-absolute"></i>
        </i>
          <div *ngIf="!isEMailType" class="icon-text text-overflow-ellipsis">
            <span *ngIf="item.Titel" [innerText]="item.Titel"></span>
            <span *ngIf="item.Titel">:&nbsp;</span>
            <span *ngIf="item.Betreff" [innerHTML]="item.Betreff"></span>
          </div>
          <div *ngIf="isEMailType" [hidden]="!item.Teaser" class="inbox-item-card--headline display-flex flex-justify-cont-between" (click)="onClick(item)" [ngClass]="{'pointer-hover': isEMailType && !item.showChild}">
            <span [innerText]="cuttedTeaser" class="padding-left-right-8 box-sizing-border-box"></span>
            <span class="inbox-item-card__date">{{item.Datum}}</span>
        </div>
          <mp-core-tooltip *ngIf="isEMailType" tooltipTitle="{{ ls.locs['loc'].Loeschen }}" class="inbox-item-card__delete-btn position-absolute right-24 top-8">
            <button class="mp-button display-flex flex-align-items-center flex-justify-cont-center padding-left-right-8" [attr.aria-label]="ls.locs['loc'].Loeschen" (click)="onItemDelete(item)">
              <i class="svg-icon __clr-default __size-22 __delete"></i>
            </button>
          </mp-core-tooltip> 
      </div>
    </div>
      <div *ngIf="!isEMailType" class="flex-col-8 flex-col-12-m">
      <div class="supporting-text first width-full box-sizing-border-box">
        <div id="inb-it-{{item.Key}}--teaser" [hidden]="!item.Teaser" class="text-overflow-ellipsis pointer" mpCoreMpShave [shaveHeight]="50" (click)="onClick(item)">
          <span [innerHTML]="trustAsHtml(item.Teaser)"></span>
        </div>
        <owl-carousel-o *ngIf="item.SubItems && item.SubItems.length > 0" id="inbox-carousel" class="owl-theme" [options]="{loop: false, nav: true, margin:16, items:1, dots:false, navText: ['<', '>']  }">
          <ng-container class="item"
                        *ngFor="let sItem of item.SubItems">
            <ng-template carouselSlide [width]="0" style="width: auto;" [id]="'inbox-item-key-'+item.Key">
              <div (click)="onClick(item)">
                <div class="card inbox-item-content-inner row-inner">
                  <div class="grid col-small-4">
                    <div class="rich-media overflow-hidden text-align-center">
                      <img class="z-index-1 height-full" [src]="sItem.Image+'?width=220&height=127&margin=5'" />
                    </div>
                  </div>
                  <div class="grid col-small-8">
                    <div class="primary-title">
                      <div class="title display-block font-size-general bold text-overflow-ellipsis" [innerHTML]="sItem.Titel"></div>
                      <div class="subtitle display-block font-size-general text-overflow-ellipsis" [ngClass]="sItem.OrderState" [innerHTML]="sItem.Text"></div>
                    </div>
                    <div class="supplemental-actions padding-8">
                      <button class="mp-button margin-left-0">{{ ls.locs['loc'].Details }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div *ngIf="!isEMailType" class="flex-col-1 flex-col-12-m"></div>
  </div>
  <div *ngIf="isEMailType" [hidden]="item.showChild" class="inbox-text-teaser _is-email-type" (click)="onClick(item)" [ngClass]="{'pointer-hover': isEMailType && !item.showChild}">
    <div class="padding-bottom-32 padding-left-right-24 padding-top-0">
        <hr class="margin-bottom-16 margin-top-0" />
      <div class="display-block">
        <span [innerHTML]="cuttedText"></span>
      </div>
      <button class="mp-button _sec margin-top-16">{{ ls.locs['loc'].NachrichtLesen }}</button>
    </div>
  </div>
  <div *ngIf="!isPreview" class="inbox-preview" [ngClass]="{open: item.showChild}">
    <div class="inbox-details" [ngClass]="{active: item.showChild}" *ngIf="item.showChild && injector">
      <ng-container *ngComponentOutlet="getChildComponent(item.ChildTemplate); injector: injector;"></ng-container>
    </div>
  </div>
</div>
</div>
