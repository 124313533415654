import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpShoppingBasketService } from './../shopping-basket/mp-shopping-basket.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the functions for
 * the additional questions page.
 */
@Component({
  selector: 'mp-core-additional-questions',
  templateUrl: './additional-questions.component.html',
  styleUrls: ['./../../../../components/order-process/styles/order-process-pooled.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalQuestionsComponent implements OnInit, OnDestroy {

  public additionalQuestions: Array<{ [key: string]: any }> = [];
  public isPreview: boolean = false;
  public hidden: { [key: string]: any } = {};
  public sbItem: { [key: string]: any } = {};
  public sbItemLoaded: boolean = false;
  public vkResultTotal: number = 0;
  public buttonsDisabled: boolean = false;
  public validationErrors: { [key: string]: any } = {};

  private _role: string = '';
  private _getRouteParamsSubscription: Subscription | undefined;
  private _getQuestionsControlsSubscription: Subscription | undefined;
  private _validateSubscription: Subscription | undefined;
  private _saveQuesiontsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _titleService: MpTitleService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService
  ) { }

  /**
   * Sets the title. Gets the shopping basket
   * item and the additional questions. Gets
   * the role.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('Zusatzabfragen');

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    this._getRouteParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['artNr'] !== 'undefined' && typeof params['pin'] !== 'undefined') {
        this.sbItem = {
          ArtNr: params['artNr'],
          PIN: params['pin']
        };

        this.isPreview = true;
      } else {
        this.sbItem = this._mpShoppingBasket.retrieveSavedSBItem();
      }

      this.sbItemLoaded = true;

      if (Object.keys(this.sbItem).length > 0 && typeof this.sbItem['ArtNr'] !== 'undefined') {
        if (this.isPreview) {
          // @ts-ignore
          this.additionalQuestions = JSON.parse(localStorage.getItem('zusatzabfragen'));
          this._handleAdditionalQuestions();
        } else {
          this._getQuestionsControlsSubscription = this._apiService.postRequest('/api/Zusatzabfragen/getAbfrageControls', {
            ArtNr: this.sbItem['ArtNr'],
            PIN: this.sbItem['PIN']
          }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.additionalQuestions = data.Records;
              this._handleAdditionalQuestions();
            }
          });
        }
      } else {
        window.history.back();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getRouteParamsSubscription !== 'undefined') {
      this._getRouteParamsSubscription.unsubscribe();
    }

    if (typeof this._getQuestionsControlsSubscription !== 'undefined') {
      this._getQuestionsControlsSubscription.unsubscribe();
    }

    if (typeof this._validateSubscription !== 'undefined') {
      this._validateSubscription.unsubscribe();
    }

    if (typeof this._saveQuesiontsSubscription !== 'undefined') {
      this._saveQuesiontsSubscription.unsubscribe();
    }
  }

  /**
   * Prepares the additional questions.
   */
  private _handleAdditionalQuestions(): void {
    this.additionalQuestions.forEach((item: { [key: string]: any }) => {
      item['Values'] = item['Value'] ? item['Value'].split('|') : [];
      const VKs = item['VK'] ? item['VK'].split('|') : [];

      if (item['Ctrl'] === 'DropDown') {
        item['VKs'] = item['Values'].map((v: any) => {
          const result = parseInt(v) * parseInt(item['VK']);
          return isNaN(result) ? 0 : result;
        });
      } else {
        item['VKs'] = VKs.map((v: any) => {
          const result = parseInt(v);
          return isNaN(result) ? 0 : result;
        });
      }

      item['Captions'] = item['Caption'] ? item['Caption'].split('|') : [];

      if (item['Ctrl'] === 'CheckBox') {
        item['Result'] = item['DefaultValue'] == 'checked';
      } else {
        item['Result'] = item['DefaultValue'] || item['Values'][0];
      }

      item['ShowHides'] = item['ShowHide'].split('|').map((showHide: any) => {
        return this._getFilteredShowHide(showHide);
      });

      if (item['Ctrl'] === 'Datum') {
        item['ShowConditions'] = item['ShowCondition'].split(';');
      }

      if (item['Ctrl'] === 'NameVornameGebDatum') {
        item['MaxValue'] = '0';
      }

      this.doShowHide(item);
      this._updateVK(item);
    });
  }

  /**
   * Filters and returns the showHides of the
   * given item.
   */
  private _getFilteredShowHide(sh: any): Array<any> {
    const fullShowHide = sh.split(';').map((s: any) => {
      const temp = s.replace('div_', '').split('_')[0];
      return parseInt(temp);
    });

    const filteredShowHide = fullShowHide.filter((showHide: any) => {
      return !isNaN(showHide);
    });

    return [...new Set(filteredShowHide)];
  };

  /**
   * Watches for item changes, and
   * triggers update functions.
   */
  itemChanged(item: { [key: string]: any }): void {
    this.doShowHide(item);
    this._updateVK(item);
  }

  /**
   * Handles the show and hide of question items.
   */
  doShowHide(item: { [key: string]: any }): void {
    switch (item['Ctrl']) {
      case 'Datum':
        const hide = item['ShowConditions'].indexOf(item['selectedDay']) < 0;

        item['ShowHides'][0].forEach((ctrlID: number) => {
          this._setHidden(ctrlID, hide);
        });

        break;
      case 'Radio':
        const valIdx = item['Values'].indexOf(item['Result']);
        const flatShowHides = [].concat(...item['ShowHides']);

        flatShowHides.forEach((ctrlID: number) => {
          this._setHidden(ctrlID, true);
        });

        item['ShowHides'][valIdx].forEach((ctrlID: number) => {
          this._setHidden(ctrlID, false);
        });

        break;
      case 'CheckBox':
        item['ShowHides'].forEach((ctrlID: number) => {
          this._setHidden(ctrlID, !item['Result']);
        })

        break;
    }
  }

  /**
   * Sets the given question item to hidden /
   * not hidden.
   */
  private _setHidden(ctrlID: number, hidden: boolean): void {
    const hideShowItem = this.additionalQuestions.find((question: { [key: string]: any }) => {
      return question['CtrlID'] === ctrlID;
    });

    if (typeof hideShowItem !== 'undefined') {
      hideShowItem['hidden'] = hidden;
    }
  }

  /**
   * Updates the total result value.
   */
  private _updateVK(item: { [key: string]: any }): void {
    if (item['VK']) {
      if (item['Ctrl'] === 'CheckBox') {
        item['VKResult'] = item['Result'] ? parseInt(item['VK']) : 0;
      } else {
        const valIdx = item['Values'].indexOf(item['Result']);
        item['VKResult'] = valIdx >= 0 ? item['VKs'][valIdx] : 0;
      }

      const pluckedResults = this.additionalQuestions.map((question: { [key: string]: any }) => {
        return question['VKResult'];
      });

      this.vkResultTotal = pluckedResults.reduce((memo: any, num: any) => {
        return (memo || 0) + (num || 0);
      }, 0);
    }
  }

  /**
   * Saves the data of the additional
   * questions.
   */
  saveData(redirect?: string): void {
    this.buttonsDisabled = true;
    const payload: Array<any> = [];

    this.additionalQuestions.forEach((item: { [key: string]: any }) => {
      switch (item['Ctrl']) {
        case 'Zeitraum':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_start', Value: item['Values'][0], Punkte: '', Pflicht: item['Pflichtfeld'] });
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_end', Value: item['Values'][1], Punkte: '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'Datum':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'], Value: item['Result'], Punkte: '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'Radio':
          for (let j = 0; j < item['Values'].length; j++) {
            const checked = item['Result'] === item['Values'][j];
            payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_' + j, Value: checked, Punkte: checked ? item['VKs'][j] : '', Pflicht: item['Pflichtfeld'] });
          }
          break;
        case 'DropDown':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'], Value: item['Result'], Punkte: item['VKResult'] || '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'CheckBox':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'], Value: item['Result'], Punkte: item['VKResult'] || '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'NameVornameGebDatum':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_name', Value: item['Values'][0], Punkte: '', Pflicht: item['Pflichtfeld'] });
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_vorname', Value: item['Values'][1], Punkte: '', Pflicht: item['Pflichtfeld'] });
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_gebdatum', Value: item['Values'][2], Punkte: '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'Textbox':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'], Value: item['Result'], Punkte: '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'NameVorname':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_name', Value: item['Values'][0], Punkte: '', Pflicht: item['Pflichtfeld'] });
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'] + '_vorname', Value: item['Values'][1], Punkte: '', Pflicht: item['Pflichtfeld'] });
          break;
        case 'Textarea':
          payload.push({ ItemID: 'ctrl_' + item['CtrlID'], Value: item['Result'], Punkte: '', Pflicht: item['Pflichtfeld'] });
          break;
      }
    });

    this._validateSubscription = this._apiService.postRequest('/api/Zusatzabfragen/ValidateAbfrage', payload).subscribe((data: any) => {
      this.validationErrors = {};
      this.buttonsDisabled = true;

      this._mpShoppingBasket.setSavedSBItem((data: any) => {
        if (data.Result === 'OK' && data.footer.length > 0) {
          const additionalItemKey = data.footer[0].Key;

          this._saveQuesiontsSubscription = this._apiService.postRequest('/api/Zusatzabfragen/SaveAbfrage', {
            ArtNr: this.sbItem['ArtNr'],
            PIN: this.sbItem['PIN'],
            Items: payload,
            Key: additionalItemKey
          }).subscribe((result: any) => {
            if (result.Result === 'OK') {
              this._mpShoppingBasket.refreshShoppingBasket(true);
              this._mpShoppingBasket.resetSavedSBItem();

              this.additionalQuestions.forEach((item: { [key: string]: any }) => {
                item['Values'] = item['Value'] ? item['Value'].split('|') : [];
                item['VKResult'] = 0;
              });

              if (typeof redirect !== 'undefined') {
                this._router.navigateByUrl(`/${this._role}/${redirect}`);
              } else {
                this._router.navigateByUrl(`/${this._role}/Warenkorb`);
              }
            } else {
              this._mpShoppingBasket.deleteItem(additionalItemKey);
              this._mpMessaging.openWarningPanel(result.Message, 3000);
            }

            this.buttonsDisabled = false;
          });
        } else {
          this.buttonsDisabled = false;
        }
      });
    },
    (error: any) => {
      if (error.status === 400) {
        this.validationErrors = error.ModelState;
      }

      this.buttonsDisabled = false;
    });
  }

}
