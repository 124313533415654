import { Directive, Input, HostListener } from '@angular/core';

import { MpDebounceService } from './../services/mp-debounce.service';

/**
 * This class provides the functions to
 * trigger a given function on window
 * resize.
 */
@Directive({
  selector: '[mpCoreResizerFn]'
})
export class ResizerFnDirective {

  @Input() public resizeFn: any;

  constructor(
    private _mpDebounce: MpDebounceService
  ) { }

  /**
   * Triggers (with debounce) the given
   * resize function.
   */
  @HostListener('window:resize')
  onresize() {
    if (typeof this.resizeFn !== 'undefined') {
      this._mpDebounce.debounce(() => {
        this.resizeFn();
      }, 200)();
    }
  }

}
