<div id="order-check" mpCoreShoppingBasketCheck class="layout-container padding-bottom-80" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-order-process-stepper key="pruefen"></mp-core-order-process-stepper>
  <div class="row order-process" id="check-order">
    <div class="grid col-medium-9" id="check-order-wk">
      <div>
        <h2 class="border-bottom padding-bottom-24">{{ ls.locs['loc'].BestellungPruefenTitel }}</h2>
        <p class="bold margin-top-24">{{ ls.locs['loc'].IhrePraemien }}</p>
        <mp-core-order-shopping-basket-panel [shoppingCart]="groupedShoppingBasket" [cartItems]="shoppingBasket" [showEdit]="true" id="check-wk-overview"></mp-core-order-shopping-basket-panel>
      </div>

      <div class="flex-grid hide-on-medium">
        <div class="flex-col-12 flex-align-item-center flex-justify-cont-end">
          <mp-core-checkbox mpId="chkTeilnamebedingungen"
                            [(model)]="termsConfirmed"
                            label="{{ ls.locs['loc'].LabelTeilnahmebedingungen | format:[role] }}"
                            [hasError]="showTermsNotification ? true : false">
          </mp-core-checkbox>
        </div>
        <div class="flex-col-12 flex-align-item-center flex-justify-cont-end text-align-right">
          <mp-core-form-errorstring *ngIf="showTermsNotification" errstring="{{ ls.locs['validate'].TbBestaetigtRequired }}"></mp-core-form-errorstring>
        </div>
        <div class="flex-col-12 flex-align-item-center flex-justify-cont-end margin-top-16">
          <mp-core-checkbox mpId="chkDatenschutzBestaetigen"
                            [(model)]="revocationConfirmed"
                            label="{{ ls.locs['loc'].LabelWiderrufsrechtBestaetigt | format:[role] }}"
                            [hasError]="showRevocationNotification ? true : false">
          </mp-core-checkbox>
        </div>
        <div class="flex-col-12 flex-align-item-center flex-justify-cont-end text-align-right">
          <mp-core-form-errorstring *ngIf="showRevocationNotification" errstring="{{ ls.locs['validate'].WiderrufBestaetigenRequired }}"></mp-core-form-errorstring>
        </div>
      </div>

      <div class="bestellung-nav-row floating-container margin-top-16 hide-on-mobile">
        <a href="#/{{role}}/Home" class="mp-button text-color-back-btn float-left hide-on-medium" id="back-to-shop-button">
          {{ ls.locs['loc'].BestellungAbbrechen }}
          <i class="svg-icon __clr-default __size-18 margin-left-8 __restore float-none-important"></i>
        </a>
        <div class="floating-container show-on-medium">
          <a href="#/{{role}}/Home" class="mp-button text-color-back-btn float-left" id="back-to-shop-button">
            {{ ls.locs['loc'].BestellungAbbrechen }}
            <i class="svg-icon __clr-default __size-18 margin-left-8 __restore float-none-important"></i>
          </a>
        </div>
        <div class="show-on-medium margin-top-8 margin-bottom-16">
          <div class="flex-grid">
            <div class="flex-col-12 flex-align-item-center flex-justify-cont-center">
              <mp-core-checkbox mpId="chkTeilnamebedingungen"
                                [(model)]="termsConfirmed"
                                label="{{ ls.locs['loc'].LabelTeilnahmebedingungen | format:[role] }}"
                                [hasError]="showTermsNotification ? true : false">
              </mp-core-checkbox>
            </div>
            <div class="flex-col-12 flex-align-item-center flex-justify-cont-end text-align-right">
              <mp-core-form-errorstring *ngIf="showTermsNotification" errstring="{{ ls.locs['validate'].TbBestaetigtRequired }}"></mp-core-form-errorstring>
            </div>
            <div class="flex-col-12 flex-align-item-center flex-justify-cont-center margin-top-16">
              <mp-core-checkbox mpId="chkDatenschutzBestaetigen"
                                [(model)]="revocationConfirmed"
                                label="{{ ls.locs['loc'].LabelWiderrufsrechtBestaetigt | format:[role] }}"
                                [hasError]="showRevocationNotification ? true : false">
              </mp-core-checkbox>
            </div>
            <div class="flex-col-12 flex-align-item-center flex-justify-cont-end text-align-right">
              <mp-core-form-errorstring *ngIf="showRevocationNotification" errstring="{{ ls.locs['validate'].WiderrufBestaetigenRequired }}"></mp-core-form-errorstring>
            </div>
          </div>
        </div>
        <a class="mp-button _prim float-right" [ngClass]="{'disabled': loading}" id="to-payment-button" (click)="order($event)">{{ ls.locs['loc'].BestellungAbsenden }}</a>
        <a *ngIf="currentStep['PreviousUrl']" href="#/{{role}}/{{currentStep['PreviousUrl']}}" id="go-back-button" class="mp-button text-color-back-btn float-right margin-right-32">
          {{ ls.locs['loc'].Zurueck }}
        </a>
      </div>

      <div stickyBottom class="bestellung-nav-row floating-container show-on-mobile bg-color-white wk-bottom-buttons z-index-3">
        <div class="flex-grid margin-top-bottom-16 padding-left-right-16">
          <div class="flex-col-12 flex-align-item-center flex-justify-cont-center">
            <mp-core-checkbox mpId="chkTeilnamebedingungen"
                              [(model)]="termsConfirmed"
                              label="{{ ls.locs['loc'].LabelTeilnahmebedingungen | format:[role] }}"
                              [hasError]="showTermsNotification ? true : false">
            </mp-core-checkbox>
          </div>
          <div class="flex-col-12 flex-align-item-center flex-justify-cont-end text-align-center">
            <mp-core-form-errorstring *ngIf="showTermsNotification" errstring="{{ ls.locs['validate'].TbBestaetigtRequired }}"></mp-core-form-errorstring>
          </div>
          <div class="flex-col-12 flex-align-item-center flex-justify-cont-center margin-top-16">
            <mp-core-checkbox mpId="chkDatenschutzBestaetigen"
                              [(model)]="revocationConfirmed"
                              label="{{ ls.locs['loc'].LabelWiderrufsrechtBestaetigt | format:[role] }}"
                              [hasError]="showRevocationNotification ? true : false">
            </mp-core-checkbox>
          </div>
          <div class="flex-col-12 flex-align-item-center flex-justify-cont-end text-align-center">
            <mp-core-form-errorstring *ngIf="showRevocationNotification" errstring="{{ ls.locs['validate'].WiderrufBestaetigenRequired }}"></mp-core-form-errorstring>
          </div>
        </div>
        <div class="flex-grid flex-direction-row flex-wrap margin-left-right-0">
          <a *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer" class="mp-button _light-grey padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column margin-top-0" href="tel:{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}">
            <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].Support }}</span>
          </a>
          <a *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer" id="wk-bottom-button-right" class="mp-button _prim padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column" [ngClass]="{'disabled':loading}" (click)="order($event)">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].BestellungAbsenden }}</span>
          </a>

          <a *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer" class="mp-button _prim padding-top-bottom-8 flex-col-12-xs flex-col-12-s flex-col-12-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column" [ngClass]="{'disabled':loading}" (click)="order($event)">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ ls.locs['loc'].BestellungAbsenden }}</span>
          </a>

          <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-between">
            <a class="mp-button" *ngIf="currentStep['PreviousUrl']" href="#/{{role}}/{{currentStep['PreviousUrl']}}">{{ ls.locs['loc'].Zurueck }}</a>
            <a class="mp-button" href="#/{{role}}/Home">{{ ls.locs['loc'].Abbrechen }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid col-medium-3" id="check-order-panel" [ngClass]="{ 'sticky-bottom-has-error': showTermsNotification || showRevocationNotification }">
      <mp-core-order-check-side-panel></mp-core-order-check-side-panel>
    </div>
  </div>
  <mp-core-support class="hide-on-medium"></mp-core-support>

  <mp-core-modal [show]="showRedirectInfo"
            title="{{ ls.locs['loc'].RedirectTitle }}">
    <div><span [innerHTML]="ls.locs['loc'].RedirectText"></span></div>
  </mp-core-modal>
</div>
