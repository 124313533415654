import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SwipeModule } from 'ng-swipe';
import { DatetimerangepickerModule } from 'angular-datetimerangepicker';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { SharedComponentsLoaderModule } from './shared-components-loader.module';

import { CheckboxComponent } from './../components/checkbox/checkbox.component';
import { CookieNotifyComponent } from './../components/cookie-notify/cookie-notify.component';
import { FormErrorComponent } from './../components/error/form-error/form-error.component';
import { FormErrorstringComponent } from './../components/error/form-errorstring/form-errorstring.component';
import { InfoNotificationComponent } from './../components/info-notification/info-notification.component';
import { InputComponent } from './../components/input/input.component';
import { InputDecimalComponent } from './../components/input/input-decimal/input-decimal.component';
import { InputIntegerComponent } from './../components/input/input-integer/input-integer.component';
import { InputRegistrierungsCodeComponent } from './../components/input/input-registrierungs-code/input-registrierungs-code.component';
import { InputWithCorrectionComponent } from './../components/input/input-with-correction/input-with-correction.component';
import { SelectComponent } from './../components/select/select/select.component';
import { SelectInputComponent } from './../components/select/select-input/select-input.component';
import { SidebarInputComponent } from './../components/sidebar/sidebar-input/sidebar-input.component';
import { ScrollButtonComponent } from './../components/scroll-button/scroll-button.component';
import { TooltipComponent } from './../components/tooltip/tooltip.component';
import { ShopCardComponent } from './../components/card/shop-card/shop-card.component';
import { ContactFormComponent } from './../components/contact-form/contact-form.component';
import { FaqComponent } from './../components/faq/faq.component';
import { FaqSidebarComponent } from './../components/faq/faq-sidebar/faq-sidebar.component';
import { DateRangePickerWithPresetsComponent } from './../components/date-picker/date-range-picker-with-presets/date-range-picker-with-presets.component';
import { DateRangePickerComponent } from './../components/date-picker/date-range-picker/date-range-picker.component';
import { MpDatePickerComponent } from './../components/date-picker/date-picker/mp-date-picker.component';
import { ModalComponent } from './../components/modal/modal.component';
import { MaskedInputComponent } from './../components/input/masked-input/masked-input.component';
import { RadioButtonComponent } from './../components/radio-button/radio-button.component';
import { TextareaComponent } from './../components/textarea/textarea.component';
import { MicroCopyComponent } from './../components/micro-copy/micro-copy.component';
import { DividerComponent } from './../components/divider/divider.component';
import { SearchComponent } from './../components/search/search.component';
import { AccordionComponent } from './../components/accordion/accordion.component';
import { HtmlVideoComponent } from './../components/html-video/html-video.component';
import { SearchAwardsComponent } from './../components/search-awards/search-awards.component';
import { BrandsSliderComponent } from './../components/brands-slider/brands-slider.component';
import { NewestAwardsComponent } from './../components/newest-awards/newest-awards.component';
import { TopsellerAwardsComponent } from './../components/topseller-awards/topseller-awards.component';
import { BreadcrumbsComponent } from './../components/breadcrumbs/breadcrumbs.component';
import { ActionCardComponent } from './../components/action-card/action-card.component';
import { ActionCardListComponent } from './../components/action-card-list/action-card-list.component';
import { InfotextComponent } from './../components/infotext/infotext.component';
import { PopoverComponent } from './../components/popover/popover.component';
import { FeatureCardComponent } from './../components/card/feature-card/feature-card.component';
import { CategoryCardComponent } from './../components/card/category-card/category-card.component';
import { ManagerDashboardCardComponent } from './../components/card/manager-dashboard-card/manager-dashboard-card.component';
import { CategoryCardListComponent } from './../components/category-card-list/category-card-list.component';
import { FeatureCardListComponent } from './../components/feature-card-list/feature-card-list.component';
import { BrowserSupportModalComponent } from './../components/browser-support-modal/browser-support-modal.component';
import { CookieConsentComponent } from './../components/cookie-consent/cookie-consent.component';
import { ToggleButtonComponent } from './../components/toggle-button/toggle-button.component';
import { CookieOverlayComponent } from './../components/cookie-overlay/cookie-overlay.component';

import { MpFocusDirective } from './../directives/mp-focus.directive';
import { MpAfterImageLoadDirective } from './../directives/mp-after-image-load.directive';
import { MpAfterRenderItemDirective } from './../directives/mp-after-render-item.directive';
import { ClickOutsideDirective } from './../directives/mp-click-outside.directive';
import { SidebarDirective } from './../components/sidebar/sidebar.directive';
import { ScrollButtonDeleteItemDirective } from './../components/scroll-button/scroll-button-delete-item.directive';
import { ScrollButtonRerendererDirective } from './../components/scroll-button/scroll-button-rerenderer.directive';
import { LoadSyncDirective } from './../directives/load-sync.directive';
import { MpThemesStagesAnimationsDirective } from './../directives/mp-themes-stages-animations.directive';
import { MpStickyBottomDirective } from './../directives/mp-sticky-bottom.directive';
import { ErrSrcDirective } from './../directives/err-src.directive';
import { HighlightDirective } from './../directives/highlight.directive';
import { MpContentFullHeightDirective } from './../directives/mp-content-full-height.directive';
import { ResizerFnDirective } from './../directives/resizer-fn.directive';
import { VoucherInputDirective } from './../directives/voucher-input.directive';
import { MpShaveDirective } from './../directives/mp-shave.directive';
import { OnlyDecimalDirective } from './../directives/only-decimal.directive';
import { IframeSetDimensionsOnLoadDirective } from './../directives/iframe-set-dimensions-on-load.directive';
import { StickyOnScrollDirective } from './../directives/sticky-on-scroll.directive';

import { FormatPipe } from './../pipes/format.pipe';
import { IntegerPipe } from './../pipes/integer.pipe';
import { SumOfItemsPipe } from './../pipes/sum-of-items.pipe';
import { DecimalPipe } from './../pipes/decimal.pipe';
import { OrderByPipe } from './../pipes/order-by.pipe';
import { TimeOfDayPipe } from './../pipes/time-of-day.pipe';
import { AngularjsToAnuglarUrlPipe } from './../pipes/angularjs-to-anuglar-url.pipe';
import { MinPipe } from './../pipes/min.pipe';
import { NoNullItemsPipe } from './../pipes/no-null-items.pipe';
import { FilterPipe } from './../pipes/filter.pipe';

@
NgModule({
  declarations: [
    CookieNotifyComponent,
    CheckboxComponent,
    FormErrorComponent,
    FormErrorstringComponent,
    InfoNotificationComponent,
    InputComponent,
    InputDecimalComponent,
    InputIntegerComponent,
    InputRegistrierungsCodeComponent,
    InputWithCorrectionComponent,
    SelectComponent,
    SelectInputComponent,
    MpFocusDirective,
    ClickOutsideDirective,
    SidebarInputComponent,
    SidebarDirective,
    FormatPipe,
    IntegerPipe,
    SumOfItemsPipe,
    DecimalPipe,
    OrderByPipe,
    TimeOfDayPipe,
    AngularjsToAnuglarUrlPipe,
    MinPipe,
    NoNullItemsPipe,
    FilterPipe,
    ScrollButtonComponent,
    ScrollButtonDeleteItemDirective,
    ScrollButtonRerendererDirective,
    TooltipComponent,
    ShopCardComponent,
    LoadSyncDirective,
    ContactFormComponent,
    FaqComponent,
    FaqSidebarComponent,
    MpAfterImageLoadDirective,
    MpAfterRenderItemDirective,
    DateRangePickerWithPresetsComponent,
    DateRangePickerComponent,
    MpDatePickerComponent,
    ModalComponent,
    MaskedInputComponent,
    MpThemesStagesAnimationsDirective,
    RadioButtonComponent,
    TextareaComponent,
    MicroCopyComponent,
    MpStickyBottomDirective,
    ErrSrcDirective,
    HighlightDirective,
    MpContentFullHeightDirective,
    ResizerFnDirective,
    VoucherInputDirective,
    MpShaveDirective,
    DividerComponent,
    SearchComponent,
    AccordionComponent,
    HtmlVideoComponent,
    SearchAwardsComponent,
    BrandsSliderComponent,
    NewestAwardsComponent,
    TopsellerAwardsComponent,
    BreadcrumbsComponent,
    ActionCardComponent,
    ActionCardListComponent,
    InfotextComponent,
    PopoverComponent,
    OnlyDecimalDirective,
    IframeSetDimensionsOnLoadDirective,
    FeatureCardComponent,
    CategoryCardComponent,
    ManagerDashboardCardComponent,
    CategoryCardListComponent,
    StickyOnScrollDirective,
    FeatureCardListComponent,
    BrowserSupportModalComponent,
    CookieConsentComponent,
    ToggleButtonComponent,
    CookieOverlayComponent
  ],
  exports: [
    SharedComponentsLoaderModule,
    CookieNotifyComponent,
    CheckboxComponent,
    FormErrorComponent,
    FormErrorstringComponent,
    InfoNotificationComponent,
    InputComponent,
    InputDecimalComponent,
    InputIntegerComponent,
    InputRegistrierungsCodeComponent,
    InputWithCorrectionComponent,
    SelectComponent,
    SelectInputComponent,
    MpFocusDirective,
    ClickOutsideDirective,
    SidebarInputComponent,
    SidebarDirective,
    FormatPipe,
    IntegerPipe,
    SumOfItemsPipe,
    DecimalPipe,
    OrderByPipe,
    TimeOfDayPipe,
    AngularjsToAnuglarUrlPipe,
    MinPipe,
    NoNullItemsPipe,
    FilterPipe,
    ScrollButtonComponent,
    ScrollButtonDeleteItemDirective,
    ScrollButtonRerendererDirective,
    TooltipComponent,
    ShopCardComponent,
    LoadSyncDirective,
    ContactFormComponent,
    FaqComponent,
    FaqSidebarComponent,
    MpAfterImageLoadDirective,
    MpAfterRenderItemDirective,
    DateRangePickerWithPresetsComponent,
    DateRangePickerComponent,
    MpDatePickerComponent,
    ModalComponent,
    MaskedInputComponent,
    MpThemesStagesAnimationsDirective,
    RadioButtonComponent,
    TextareaComponent,
    MicroCopyComponent,
    MpStickyBottomDirective,
    ErrSrcDirective,
    HighlightDirective,
    MpContentFullHeightDirective,
    ResizerFnDirective,
    VoucherInputDirective,
    MpShaveDirective,
    DividerComponent,
    SearchComponent,
    AccordionComponent,
    HtmlVideoComponent,
    SearchAwardsComponent,
    BrandsSliderComponent,
    NewestAwardsComponent,
    TopsellerAwardsComponent,
    BreadcrumbsComponent,
    ActionCardComponent,
    ActionCardListComponent,
    InfotextComponent,
    PopoverComponent,
    OnlyDecimalDirective,
    IframeSetDimensionsOnLoadDirective,
    FeatureCardComponent,
    CategoryCardComponent,
    ManagerDashboardCardComponent,
    CategoryCardListComponent,
    StickyOnScrollDirective,
    FeatureCardListComponent,
    BrowserSupportModalComponent,
    CookieConsentComponent,
    ToggleButtonComponent,
    CookieOverlayComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedComponentsLoaderModule,
    SwipeModule,
    DatetimerangepickerModule,
    CarouselModule,
    NgxMaskDirective
  ],
  providers: [
    FormatPipe,
    provideNgxMask()
  ]
})
export class CommonComponentsLoaderModule { }
