<ng-container *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <mp-core-svg-loader *ngIf="loading" svg="LadeanimationenIconsRennwagen" svgAnimation="draw" class="svg-loader summer-race-loader" [time]="1.9" loadingText="{{ ls.locs['locGamification'].LadeanimationBitteWarten }}"></mp-core-svg-loader>
  <div id="summer-race-rrotate-notification">{{ ls.locs['locGamification'].BitteGeraetDrehen }}</div>
  <div id="summer-race-ie-notification">{{ ls.locs['locGamification'].AufloesungInIENichtSupported }}</div>

  <section id="summer-race-track" [hidden]="!animationsLoaded">
    <div class="width-full position-absolute">
      <div id="summer-race-track-svg-blanko" [innerHTML]="trackBlankSvg"></div>
      <div id="summer-race-track-svg" [innerHTML]="trackSvg"></div>
    </div>
    <div class="width-full position-absolute" id="summer-race-track-cars" [hidden]="showTargetPositions">
      <div *ngIf="raceCarsArrayOld !== null && raceCarsArrayOld.length > 0">
        <div *ngFor="let trackCar of raceCarsArrayOld.slice().reverse(); trackBy: trackByIndex; let index = index" [attr.id]="carElementIds[index]">
          <ng-container *ngIf="trackCar !== null">
            <div [innerHTML]="carsSvgs[availableCars[trackCar['Autotyp']]]" class="summer-race-car" [ngClass]="availableColors[trackCar['Farbe']]"></div>

            <div *ngIf="mpSettings.settings['GamificationSettings'].ShowNames" class="small-names"><span class="small-names">{{trackCar['Name']}}</span></div>

            <div *ngIf="!mpSettings.settings['GamificationSettings'].ShowNames"><span>{{ ls.locs['locGamification'].CarPrefix }}{{trackCar['Nr']}}</span></div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="width-full position-absolute" id="summer-race-track-cars-target" [hidden]="!showTargetPositions">
      <div *ngIf="raceCarsArrayNew !== null && raceCarsArrayNew.length > 0">
        <div *ngFor="let trackCar of raceCarsArrayNew.slice().reverse(); trackBy: trackByIndex; let index = index" [attr.id]="carElementIds[index]">
          <ng-container *ngIf="trackCar !== null">
            <div [innerHTML]="carsSvgs[availableCars[trackCar['Autotyp']]]" class="summer-race-car" [ngClass]="availableColors[trackCar['Farbe']]"></div>

            <div *ngIf="mpSettings.settings['GamificationSettings'].ShowNames" class="small-names"><span class="small-names">{{trackCar['Name']}}</span></div>

            <div *ngIf="!mpSettings.settings['GamificationSettings'].ShowNames"><span>{{ ls.locs['locGamification'].CarPrefix }}{{trackCar['Nr']}}</span></div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="width-full position-absolute" id="summer-race-cockpit">
      <div id="summer-race-positions" *ngIf="ranking !== null && ranking.length > 0">
        <h2>{{ ls.locs['locGamification'].Rangliste }}</h2>
        <div *ngFor="let rang of ranking; trackBy: trackByIndex" class="padding-top-bottom-8" [ngClass]="{'summer-race-current-tn': rang.TnId === positionOld['TnId'], 'summer-race-positions-click-area': rang.TnId === 0}">
          <span *ngIf="rang.TnId !== 0" class="margin-right-4">{{rang.RangNr}}</span>

          <span *ngIf="rang.TnId !== 0 && mpSettings.settings['GamificationSettings'].ShowNames" class="margin-right-4">{{rang.Name}}</span>

          <span *ngIf="rang.TnId !== 0 && !mpSettings.settings['GamificationSettings'].ShowNames" class="margin-right-4">{{ ls.locs['locGamification'].CarPrefix }}{{rang.Nr}}</span>

          <span *ngIf="mpSettings.settings['GamificationSettings'].ShowKilometer && rang.TnId !== 0 && rang.PunkteString.length > 0">{{rang.PunkteString}} {{ ls.locs['locGamification'].KilometerKurz }}</span>
          <span *ngIf="mpSettings.settings['GamificationSettings'].ShowKilometer && rang.TnId !== 0 && rang.PunkteString.length === 0">&nbsp;</span>

          <span *ngIf="rang.TnId === 0" (click)="toggleRanking()" class="pointer display-flex flex-align-items-center flex-justify-cont-center">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>

      <div id="summmer-race-infos-container">
        <div id="summer-race-infos" class="display-flex flex-direction-row flex-align-items-center flex-justify-cont-center">
          <div class="padding-top-bottom-0 margin-0">
            <span class="padding-top-bottom-0 margin-0 display-flex flex-align-items-center">{{ ls.locs['locGamification'].Pos }} {{tnPos}}/{{allTn}}</span>
          </div>

          <div *ngIf="mpSettings.settings['GamificationSettings'].ShowKilometer" class="padding-top-bottom-0 padding-left-right-16 margin-top-bottom-0 margin-left-right-16">
            <span class="padding-top-bottom-0 margin-0 display-flex flex-align-items-center">{{points}} {{ ls.locs['locGamification'].KilometerKurz }}</span>
          </div>
          <div class="padding-top-bottom-0 margin-0">
            <span class="padding-top-bottom-0 margin-0 display-flex flex-align-items-center">{{leftTime}}</span>
          </div>
        </div>
      </div>

      <button *ngIf="mpSettings.settings['GamificationSettings'].ShowResetButton && kmToDrive > 0" class="mp-button _prim" [ngClass]="{'disabled': hideRaceButton}" [hidden]="showReset" [attr.disabled]="hideRaceButton ? '' : null" (click)="startRace()">{{ ls.locs['locGamification'].JetztGasGeben }} (+{{kmToDrive}} km)</button>
      <button class="mp-button _prim" [ngClass]="{'disabled': hideRaceButton}" [hidden]="showReset" [attr.disabled]="hideRaceButton ? '' : null" *ngIf="mpSettings.settings['GamificationSettings'].ShowResetButton && kmToDrive<=0">{{ ls.locs['locGamification'].KeineKmVorhanden }}</button>
      <button class="mp-button _prim" *ngIf="mpSettings.settings['GamificationSettings'].ShowResetButton" [hidden]="!showReset" (click)="resetPosition()">{{ ls.locs['locGamification'].PositionZuruecksetzen }}</button>

      <button class="mp-button _prim" [ngClass]="{'disabled': hideRaceButton}" [attr.disabled]="hideRaceButton ? '' : null" *ngIf="!mpSettings.settings['GamificationSettings'].ShowResetButton && kmToDrive>0" (click)="startRace()">{{ ls.locs['locGamification'].JetztGasGeben }} (+{{kmToDrive}} km)</button>
      <button class="mp-button _prim" [ngClass]="{'disabled': hideRaceButton}" [attr.disabled]="hideRaceButton ? '' : null" *ngIf="!mpSettings.settings['GamificationSettings'].ShowResetButton && kmToDrive<=0">{{ ls.locs['locGamification'].KeineKmVorhanden }}</button>
    </div>

    <mp-core-modal [show]="showRanking" title="{{ ls.locs['locGamification'].Rangliste }}" [buttons]="[{text: ls.locs['loc'].Schliessen, action: toggleRankingFunc}]">
      <div id="summer-race-modal" *ngIf="ranking !== null && ranking.length > 0">
        <div *ngFor="let rang of rankingComplete; trackBy: trackByIndex" class="padding-top-bottom-8" [ngClass]="{'summer-race-current-tn': rang.TnId === positionOld['TnId']}">

          <span *ngIf="mpSettings.settings['GamificationSettings'].ShowNames" class="margin-right-4">{{rang.Name}}</span>
          <span *ngIf="!mpSettings.settings['GamificationSettings'].ShowNames" class="margin-right-4">{{ ls.locs['locGamification'].CarPrefix }}{{rang.Nr}}</span>

          <span *ngIf="mpSettings.settings['GamificationSettings'].ShowKilometer"></span>
          <span *ngIf="mpSettings.settings['GamificationSettings'].ShowKilometer && rang.PunkteString.length > 0">{{rang.PunkteString}} {{ ls.locs['locGamification'].KilometerKurz }}</span>
          <span *ngIf="mpSettings.settings['GamificationSettings'].ShowKilometer && rang.PunkteString.length === 0">&nbsp;</span>
        </div>
      </div>
    </mp-core-modal>

    <mp-core-modal *ngIf="mpSettings.settings['GamificationSettings'].ShowWeiterKmSammeln" [show]="showCollectMore" title="{{ ls.locs['locGamification'].SummerRaceWeiterKilometerSammelnTitel }}" [buttons]="[{text: ls.locs['loc'].Schliessen, action: closeCollectMore}]">
      <div>{{ ls.locs['locGamification'].SummerRaceWeiterKilometerSammeln }}</div>
    </mp-core-modal>
  </section>

</ng-container>
