<div>
  <a href="#/{{role}}/Shop?k={{category.KategorieID}}">
    <div class="card">
      <div class="row-inner">
        <div class="grid col-small-4 col-medium-5">
          <div class="rich-media overflow-hidden text-align-center">
            <img class="float-left z-index-1 width-full"
                 [src]="'/themes/default/media/buehnen/kategorien/T_' + category.KategorieID + '.jpg?h=400&w=400&mode=crop'">
            <div class="primary-title z-index-1 text-align-left width-full box-sizing-border-box left-0 position-absolute padding-top-56 hidden-breakpoint-small">
              <div class="subtitle"
                   [hidden]="category.Typ != 1 "
                   [innerText]="category.Typ == 3 ? ls.locs['loc'].Megatrends : category.Typ == 4 ? ls.locs['loc'].AktuelleThemenwelten : ls.locs['loc'].AktuellePraemien">
              </div>
              <div class="title"
                   [innerText]="category.Bezeichnung">
              </div>
            </div>
          </div>
        </div>
        <div class="grid col-small-8 col-medium-7">
          <div class="primary-title visible-breakpoint-small">
            <div class="subtitle"
                 [hidden]="category.Typ == 1"
                 [innerText]="category.Typ == 3 ? ls.locs['loc'].Megatrends : category.Typ == 4 ? ls.locs['loc'].AktuelleThemenwelten : ls.locs['loc'].AktuellePraemien">
            </div>
            <div class="title"
                 [innerText]="category.Bezeichnung">
            </div>
          </div>
          <div class="supplemental-actions padding-top-bottom-8 padding-left-right-0 position-absolute bottom-0 left-0 right-0">
            <div class="row-inner">
              <div class="grid offset-small-4 offset-medium-5 col-small-8 col-medium-7">
                <a class="mp-button details"
                        href="{{ katLink | angularjsToAnuglarUrl}}">
                  {{ ls.locs['loc'].Ansehen }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
