import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';


@Component({
  selector: 'mp-core-opt-out',
  templateUrl: './opt-out.component.html',
  styleUrls: ['./opt-out.component.scss']
})
export class OptOutComponent implements OnInit {

  private _key: string = '';
  public message: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mpMessaging: MpMessagingService
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe((params: any) => {
      this._key = params['key'];

      this._apiService.postRequest('/api/Newsletter/OptOut', { params: { Key: this._key } }).subscribe((data: any) => {
        this.message = data.Message;
        this._router.navigate(['/']);
        },
        (error: any) => {
          this._router.navigate(['/']);
        });
    });
  }

}
