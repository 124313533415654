<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Managerverwaltung }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Managerverwaltung }}"
                      infotextShorten="managerverwaltung"
                      fieldsAction="/api/Managerverwaltung/getManagerverwaltungFields"
                      listAction="/api/Managerverwaltung/getManagerverwaltung"
                      loaderName="managerManagementTableLoader"
                      [paging]="true"
                      [recLoadedFunc]="recLoadedFunc"
                      [execClickFunctions]="execClickFunctions"
                      defaultSorting="Benutzer"></mp-core-data-table>
  <div class="floating-container">
    <a *ngIf="loader !== null && loader.getExcelParams" href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">
        {{ ls.locs['loc'].Export }}
      </button>
    </a>
  </div>
</div>
