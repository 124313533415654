<div class="layout-container" schulungstatistik mp-settings *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locSchulung'].Schulungsstatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['locSchulung'].Schulungsstatistik }}"
                      infotextShorten="schulungsstatistik"
                      fieldsAction="/api/Schulung/GetSchulungStatistikFields"
                      listAction="/api/Schulung/GetSchulungStatistik"
                      showDatepicker="true"
                      loaderName="schoolingStatisticLoader"
                      [paging]="true"
                      defaultSorting="Benutzer"
                      [recLoadedFunc]="recLoadedFunc"
                      [disableCache]="true"
                      [alwaysShowSearch]="true"
                      [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways"
                      [searchTexts]="searchTexts">
  </mp-core-data-table>
  <div class="ui-helper-clearfix"></div>
</div>
