import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeOfDay'
})
export class TimeOfDayPipe implements PipeTransform {

  transform(inputNumber: number): string {
    if (('' + inputNumber).indexOf('.') !== -1) {
      return ('00' + ('' + inputNumber).split('.')[0]).substr(-2, 2) + ':' + ('00' + parseFloat('0.' + ('' + inputNumber).split('.')[1]) * 60).substr(-2, 2);
    }

    return ('00' + inputNumber).substr(-2, 2) + ':' + ('00' + (inputNumber - inputNumber) * 60).substr(-2, 2);
  }

}
