import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';

/**
 * This class provides the data and
 * the functions for the travelogues.
 */
@Component({
  selector: 'mp-rk-travelogues',
  templateUrl: './travelogues.component.html',
  styleUrls: ['./../travel-configurator/travel-configurator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TraveloguesComponent implements OnInit, OnDestroy {

  public travelogues: Array<any> = [];

  private _getTraveloguesSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the locs, and sets the
   * breadcrumbs.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this._breadcrumbService.setCurrentSite(
      {
        Path: 'Reiseberichte',
        Loc: this.ls.locs['locReisekonfigurator'].Reiseberichte
      });

      this._getTravelogues();

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        this._breadcrumbService.setCurrentSite(
        {
          Path: 'Reiseberichte',
          Loc: this.ls.locs['locReisekonfigurator'].Reiseberichte
        });

        this._getTravelogues();

        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Loads the travelogues from the
   * backend.
   */
  private _getTravelogues(): void {
    this._getTraveloguesSubscription = this._apiService.getRequest('/api/Reiseberichte/getReiseberichteUebersicht').subscribe((data: any) => {
      if (data.Result === "OK") {
        this.travelogues = data.Records;
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }

    },
    (error: any) => {
      this._mpMessaging.openDangerPanel(error, this.ls.locs['loc'].Fehler);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTraveloguesSubscription !== 'undefined') {
      this._getTraveloguesSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

}
