import { Component, OnInit, EventEmitter, Input, Output, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import clone from 'clone';
import { Subscription } from 'rxjs';

import { CalendarService } from './calendar.service';
import { MpSidebarService } from './../sidebar/mp-sidebar.service';

/**
 * This function provides a calendar (e.g.
 * for date select sidebars).
 */
@Component({
  selector: 'mp-core-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy {

  @Input() public minDate: moment.Moment | undefined;
  @Input() public maxDate: moment.Moment | undefined;
  @Input() public minRange: number | undefined;
  @Input() public maxRange: number | undefined;
  @Input() public rangeStart: moment.Moment | null | undefined;
  @Input() public rangeEnd: moment.Moment | null | undefined;
  @Input() public isRange: boolean = false;
  @Input() public hasRange: boolean = false;
  @Input() public defaultRange: number | undefined;
  @Input() public model: moment.Moment | undefined;
  @Input() public currentSelection: string = '';
  @Input() public endDatesLimitedToLastDayOfQuarter: boolean = false;

  @Output() minDateChange = new EventEmitter<moment.Moment>();
  @Output() maxDateChange = new EventEmitter<moment.Moment>();
  @Output() minRangeChange = new EventEmitter<number>();
  @Output() maxRangeChange = new EventEmitter<number>();
  @Output() rangeStartChange = new EventEmitter<moment.Moment | null>();
  @Output() rangeEndChange = new EventEmitter<moment.Moment | null>();
  @Output() modelChange = new EventEmitter<moment.Moment>();
  @Output() currentSelectionChange = new EventEmitter<string>();

  private _firstActiveDaySet: boolean = false;
  private _firstActiveDay: any = null;
  private _now: moment.Moment | undefined;
  private _calendarCurrentSelectionChanged: Subscription | undefined;
  private _toggleSidebar: Subscription | undefined;

  public months: any[] | undefined;

  constructor(private _calendarService: CalendarService, private _mpSidebar: MpSidebarService) { }

  /**
   * Sets the currently date, or sets it
   * to the minDate (if given). Subscribes
   * to CalenderService and MpSidebarService
   * observables to watch changes of current
   * selection and sidebar visibility.
   */
  ngOnInit(): void {
    const minDate = typeof this.minDate !== 'undefined' ? clone(this.minDate) : moment();
    this._now = minDate.utcOffset(0).startOf('month');

    if (typeof this.minRange !== 'undefined' && typeof this.maxRange !== 'undefined') {
      if (this.minRange > this.maxRange) {
        throw new Error('min-range > max-range');
      }
    }

    if (typeof this.currentSelection === 'undefined' || this.currentSelection == '') {
      this.currentSelection = 'start';
    }


    this._calendarCurrentSelectionChanged = this._calendarService.calendarCurrentSelectionChanged.subscribe((data: any) => {
      this.currentSelection = data.selection;
      this.currentSelectionChange.emit(this.currentSelection);

      if (this.currentSelection === 'start') {
        this.rangeStart = null;
        this.rangeStartChange.emit(this.rangeStart);
      } else if (this.currentSelection === 'end') {
        this.rangeEnd = null;
        this.rangeEndChange.emit(this.rangeEnd);
      }

      this._updateDays();
    });

    this._toggleSidebar = this._mpSidebar.toggleSidebar.subscribe(() => {
      if (!this._mpSidebar.visible) {
        this._firstActiveDaySet = false;
        this._firstActiveDay = null
      }
    });

    this._updateMonths(this._now);
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._calendarCurrentSelectionChanged !== 'undefined') {
      this._calendarCurrentSelectionChanged.unsubscribe();
    }

    if (typeof this._toggleSidebar !== 'undefined') {
      this._toggleSidebar.unsubscribe();
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Updates the day object (setting correct
   * values for active, disabled, start, end, ...).
   */
  private _updateDayObject(day: any): any {
    if (day) {
      day.active = this.rangeStart && this.rangeEnd && day.day.isBetween(this.rangeStart, this.rangeEnd, null, []);
      day.disabled = (this.minDate && day.day.isBefore(this.minDate.startOf('day'))) || (this.maxDate && day.day.isAfter(this.maxDate));
      day.start = day.day.isSame(this.rangeStart, 'day') && (typeof (this.rangeEnd) === 'undefined' || !day.day.isSame(this.rangeEnd, 'day'));
      day.end = day.day.isSame(this.rangeEnd, 'day') && (typeof (this.rangeStart) === 'undefined' || !day.day.isSame(this.rangeStart, 'day'));

      if (this.endDatesLimitedToLastDayOfQuarter && this.rangeStart) {
        day.disabled = day.day.isBefore(this.rangeStart) || !day.day.isSame(day.day.clone().endOf('quarter'), 'day');
      }

      if (this._firstActiveDay !== null) {
        if (day.day.format('D-MM-YYYY') === this._firstActiveDay.day.format('D-MM-YYYY')) {
          day.firstActiveDay = true;
        }
      }

      if (this._firstActiveDaySet === false) {
        if (day.active === true) {
          day.firstActiveDay = true;
          this._firstActiveDaySet = true;
          this._firstActiveDay = day;
        }
      }
    }

    return day;
  }

  /**
   * Gets and returns the weeks of the given
   * month.
   */
  private _getWeeks(month: moment.Moment): any[] {
    const weeks: any[] = [[], [], [], [], [], []];
    const firstWeek = month.clone().startOf('week');

    for (let i = 0; i < month.daysInMonth(); i++) {
      const day = month.clone().add(i, 'days');

      let dayObject = {
        day: day,
        active: false,
        disabled: false,
        start: false,
        end: false,
        empty: !day
      };

      dayObject = this._updateDayObject(dayObject);

      weeks[day.diff(firstWeek, 'weeks')][day.weekday()] = dayObject;
    }

    if (typeof weeks[weeks.length - 1][0] == 'undefined') {
      weeks.pop();
    }

    if (7 - weeks[weeks.length - 1].length !== 0) {
      var diff = 7 - weeks[weeks.length - 1].length;

      for (var a = diff; a > 0; a--) {
        weeks[weeks.length - 1].push(undefined);
      }
    }

    return weeks;
  }

  /**
   * Update the months array by given
   * fist month.
   */
  private _updateMonths(firstMonth: moment.Moment): void {
    const months: any[] = [];

    firstMonth = firstMonth.add(firstMonth.utcOffset(), 'minutes');
    firstMonth = firstMonth.utc();
    months.push(firstMonth);

    for (var i = 1; i < 24; i++) {
      var newMonth = firstMonth.clone().add(i, 'months');
      if (!this.maxDate || this.maxDate > newMonth) {
        months.push(newMonth);
      }
    }

    this.months = months.map(m => { return { month: m, weeks: this._getWeeks(m.clone()), weekdays: this._getWeekdays(m) }; });
  }

  /**
   * Gets and returns the weekdays of
   * the given month.
   */
  private _getWeekdays(month: moment.Moment): any[] {
    return [0, 1, 2, 3, 4, 5, 6].map((i: number) => {
      return month.clone().startOf('week').add(i, 'days').format('ddd');
    });
  }

  /**
   * Adds one month to current date.
   */
  private _addMonth(): void {
    if (typeof this._now !== 'undefined') {
      this._now.add(1, 'month');
      this._updateMonths(this._now);
    }
  }

  /**
   * Substracts one month from current date.
   */
  private _reduceMonth(): void {
    if (typeof this._now !== 'undefined') {
      this._now.subtract(1, 'month');
      this._updateMonths(this._now);
    }
  }

  /**
   * Calculates the range by a given day, and
   * sets the current selection to start or end.
   */
  private _calculateRange(day: moment.Moment, keepRangeEnd?: boolean): void {
    if (this.minDate && day.isBefore(this.minDate.startOf('day')))
      return;

    if (this.maxDate && day.isAfter(this.maxDate))
      return;

    if (!this.isRange) {
      this.rangeStart = day;
      this.rangeEnd = day;
      this.model = day;
      this.rangeStartChange.emit(this.rangeStart);
      this.rangeEndChange.emit(this.rangeEnd);
      this.modelChange.emit(this.model);

      return;
    }

    const forceSetStart = this.endDatesLimitedToLastDayOfQuarter && !day.isSame(day.clone().endOf('quarter'), 'day');

    if ((!this.rangeStart || (this.rangeEnd && this.currentSelection !== 'end') || this.rangeStart.isAfter(day)) || forceSetStart || this.currentSelection !== 'end') {
      // --> rangeStart
      //if (day.isSame(this.rangeStart, 'day'))
      //  return;

      this.rangeStart = day.clone();
      this.rangeEnd = !this.endDatesLimitedToLastDayOfQuarter ? (typeof keepRangeEnd !== 'undefined' && keepRangeEnd ? this.rangeEnd : null) : day.clone().endOf('quarter');
      this.currentSelection = 'end';
      this.rangeStartChange.emit(this.rangeStart);
      this.rangeEndChange.emit(this.rangeEnd);
      this.currentSelectionChange.emit(this.currentSelection);
    } else {
      // --> rangeEnd
      //if (day.isSame(this.rangeEnd, 'day'))
      //  return;

      if (this.rangeStart.clone().add(this.minRange, 'days') > day)
        return;

      if (this.maxRange && this.rangeStart.clone().add(this.maxRange, 'days') < day)
        return;

      this.rangeEnd = day.clone();
      this.currentSelection = 'start';
      this.rangeEndChange.emit(this.rangeEnd);
      this.currentSelectionChange.emit(this.currentSelection);
    }
  }

  /**
   * Updates the days for each week
   * in each month.
   */
  private _updateDays(): void {
    if (typeof this.months !== 'undefined') {
      this.months.forEach(month => {
        month.weeks.forEach((week: any) => {
          week.forEach((day: any) => {
            day = this._updateDayObject(day);
          });
        });
      });
    }
  }

  /**
   * Sets the range on click.
   */
  setRange(day: moment.Moment, keepRangeEnd?: boolean): void {
    this._calculateRange(day, keepRangeEnd);
    this._updateDays();
  }

  /**
   * Watches for changes of some variables
   * and triggers calculation of ranges,
   * select of dates and so on, if variables
   * changed their values.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.maxDate !== 'undefined' && typeof changes['maxDate'] !== 'undefined' && changes['maxDate'] !== null) {
      if (changes['maxDate'].currentValue !== changes['maxDate'].previousValue) {
        const tempMaxDate = moment(changes['maxDate'].currentValue);

        if (tempMaxDate.hour() !== 23 || tempMaxDate.minute() !== 59) {
          this.maxDate = tempMaxDate.hour(23).minute(59).second(59);
          this.maxDateChange.emit(this.maxDate);
        }

        this.minDate = moment(this.minDate);
        this.minDateChange.emit(this.minDate);
      }
    }

    if (typeof this.minDate !== 'undefined' && typeof changes['minDate'] !== 'undefined' && changes['minDate'] !== null) {
      if (changes['minDate'].currentValue !== changes['minDate'].previousValue) {
        let firstMonth = moment(changes['minDate'].currentValue);
        firstMonth.subtract(firstMonth.date() - 1, 'days');
        this._updateMonths(firstMonth);
      }
    }

    if (typeof this.maxRange !== 'undefined' && typeof changes['maxRange'] !== 'undefined' && changes['maxRange'] !== null) {
      if (this.maxRange && this.rangeStart && this.rangeEnd) {
        const range = this.rangeEnd.diff(this.rangeStart, 'days');

        if (range > this.maxRange || range < 0) {
          this.rangeEnd = this.rangeStart.clone().add(this.maxRange, 'days');
          this.rangeEndChange.emit(this.rangeEnd);
        }
      }
    }

    if (typeof this.model !== 'undefined' && typeof changes['model'] !== 'undefined' && changes['model'] !== null) {
      if (!this.rangeStart && this.model) {
        this.rangeStart = this.model.clone().startOf('day');
        this.rangeStartChange.emit(this.rangeStart);
      }

      if (!this.isRange && !this.rangeEnd && this.model) {
        this.rangeEnd = this.model.clone().endOf('day');
        this.rangeEndChange.emit(this.rangeEnd);
      }

      if (this.model === null) {
        this.rangeStart = null;
        this.rangeEnd = null;
        this.rangeStartChange.emit(this.rangeStart);
        this.rangeEndChange.emit(this.rangeEnd);
      }

      this._updateDays();
    }

    if (typeof this.isRange !== 'undefined' && typeof changes['isRange'] !== 'undefined' && changes['isRange'] !== null) {
      if (!changes['isRange'].currentValue && changes['isRange'].previousValue && typeof this.rangeStart !== 'undefined' && this.rangeStart !== null) {
        const day: moment.Moment = this.rangeStart;
        this.rangeStart = null;
        this.rangeEnd = null;
        this.rangeStartChange.emit(this.rangeStart);
        this.rangeEndChange.emit(this.rangeEnd);
        this.setRange(day);
      } else if (changes['isRange'].currentValue && !changes['isRange'].previousValue && typeof this.rangeStart !== 'undefined' && this.rangeStart !== null) {
        this.setRange(this.rangeStart.clone(), (typeof this.rangeEnd !== 'undefined' && this.rangeEnd !== null));

        if (typeof this.rangeEnd !== 'undefined' && this.rangeEnd !== null) {
          this.setRange(this.rangeEnd.clone());
        }
      }
    }
  }

}
