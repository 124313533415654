import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noNullItems'
})
export class NoNullItemsPipe implements PipeTransform {

  transform(data: Array<any>): Array<any> {
    const filtered = data.filter((dataObj: any) => {
      return dataObj === null;
    });

    return filtered;
  }

}
