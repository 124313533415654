import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/**
 * The class describes highlighting of menu items
 */
export class MpMenuHighlightService {

  public menuHighlight: any;

  private _menuHighlighChange: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public menuHighlighChange: Observable<any> = this._menuHighlighChange.asObservable();

  constructor() { }

  /**
   *  Gets menu highlight
   */
  getMenuHighlight(): any {
    return this.menuHighlight;
  }

  /**
   *  Sets menu highlight
   */
  setMenuHighlight(highlight: any): void {
    this.menuHighlight = highlight;
    this._menuHighlighChange.next(highlight);
  }
}
