import { Component, OnInit, OnDestroy, ViewEncapsulation, Injector, InjectionToken } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpOrderProcessService } from '@core/components/order-process/mp-order-process.service';
import { MpShoppingBasketService } from '@core/modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { SbAdditionalPaymentService } from '@wkzz/services/sb-additional-payment.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { Provider } from '@wkzz/services/interfaces/provider';
import { ShoppingBasketItem } from '@core/modules/participant/pages/shopping-basket/shopping-basket-item';

/**
 * This class provides the data for
 * the check order side panel.
 */
@Component({
  selector: 'mp-core-order-check-side-panel',
  templateUrl: './order-check-side-panel.component.html',
  styleUrls: ['./order-check-side-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderCheckSidePanelComponent implements OnInit, OnDestroy {

  public address: { [key: string]: any } = {};
  public steps: { [key: string]: any } = {};
  public addressLoaded: boolean = false;
  public additionalPaymentProvider: Array<Provider> = [];
  public additionalPaymentAmount: number = 0;
  public injector: Injector | any;
  public role: string = '';

  private _selectedProvider: Provider | undefined;
  private _addressesSubscription: Subscription | undefined;
  private _stepsSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _getAdditionalPaymentProvidersSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpOrderProcess: MpOrderProcessService,
    private _shoppingBasket: MpShoppingBasketService,
    public sbAdditionalPaymentService: SbAdditionalPaymentService,
    private _injector: Injector,
    private _apiService: ApiService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the steps from shopping basket,
   * and gets the address data. Sets the
   * payment type. Gets the additional
   * paxment providers, and the additional
   * payment amount.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if ('sessionStorage' in window && this.mpOrderProcess.paymentType === '') {
      this.mpOrderProcess.paymentType = sessionStorage['bestellProzessZuzahlungType'];
    }

    this._shoppingBasketSubscription = this._shoppingBasket.shoppingBasketObserver.subscribe((sBItems: Array<ShoppingBasketItem>) => {
      this.additionalPaymentAmount = sBItems.reduce((a: any, b: any) => {
        return a + b['ZuzahlungEuro'];
      }, 0);
    });

    this._getAdditionalPaymentProvidersSubscription = this._apiService.getRequest('/api/WkZuzahlung/GetProvider').subscribe((data: any) => {
      this.additionalPaymentProvider = data.Records;
    });

    this._addressesSubscription = this._shoppingBasket.addressObserver.subscribe((address: { [key: string]: any }) => {
      this.address = address;
      this.addressLoaded = true;
    });

    this._stepsSubscription = this.mpOrderProcess.steps.subscribe((steps: any) => {
      this.steps = steps;
    });
  }

  /**
   * Unsubscribes set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._addressesSubscription !== 'undefined') {
      this._addressesSubscription.unsubscribe();
    }

    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._getAdditionalPaymentProvidersSubscription !== 'undefined') {
      this._getAdditionalPaymentProvidersSubscription.unsubscribe();
    }
  }

  /**
   * Creates the injector for the
   * dynamically loaded (sub-)components.
   */
  private _createInjector() {
    this.injector = Injector.create({
      providers: [
        { provide: 'sbAdditionalPaymentProvider', useValue: this._selectedProvider },
        { provide: 'sbAdditionalPaymentShoppingCart', useValue: this._shoppingBasket.shoppingBasket }
      ],
      parent: this._injector
    });
  }

  /**
   * Stes and returns the injector.
   */
  getInjector(provider: Provider): Injector {
    this._selectedProvider = provider;
    this._createInjector();
    return this.injector;
  }

}
