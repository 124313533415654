import { Component, OnInit, Injector } from '@angular/core';

import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { AdditionalPaymentService } from './../../services/additional-payment.service';

/**
 * This class provides the functions for
 * PayPal in the additional paymnt.
 */
@Component({
  selector: 'mp-zz-additional-payment-paypal',
  templateUrl: './additional-payment-paypal.component.html'
})
export class AdditionalPaymentPaypalComponent implements OnInit {

  public package: { [key: string]: any } = {};

  constructor(
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _additionalPaymentService: AdditionalPaymentService,
    private _injector: Injector
  ) { }

  /**
   * Gets the package and registers the
   * function.
   */
  ngOnInit(): void {
    this.package = this._injector.get('package');
    this._additionalPaymentService.register('paypal', this.setPayPal.bind(this));
  }

  /**
   * Sets PayPal as method for the
   * additional payment.
   */
  setPayPal(successCallback: Function, errorCallback: Function): void {
    const data = {
      PaketGuid: this.package['Guid'],
      Punkte: this.package['Punkte'] || 0,
      Provider: 'paypal'
    };

    this._apiService.postRequest('/api/Zuzahlung/zuzahlen', data).subscribe((data: any) => {
      if (data.Result === 'OK')
        this._mpMessaging.openSuccessPanel(data.Message);
      else
        this._mpMessaging.openWarningPanel(data.Message);

      successCallback(data);
    },
    (error: any) => {
      this._mpMessaging.openWarningPanel(error.ExceptionMessage);
      errorCallback(error);
    });
  }

}
