import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { TipAndWinService } from './../../services/tip-and-win.service';
import { MpSettingsService } from '@core/services/mp-settings.service';


/**
 * This class provides the data and
 * the functions for the match tips.
 */
@Component({
  selector: 'mp-taw-tip-and-win-match-tips',
  templateUrl: './tip-and-win-match-tips.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinMatchTipsComponent implements OnInit, OnDestroy {

  public allTips: Array<any> = [];
  public hasTipsToFill: boolean = false;
  public tipIndex: number = 0;
  public phaseTips: { [key: string]: any } = {};
  public phaseTipsSet: boolean = false;
  public showTippsCommingSoon: boolean = false;
  public textTippsCommingSoon: any = '';

  private _days: Array<string> = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  ];
  private _months: Array<string> = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

  private _getMatchTipsSubscription: Subscription | undefined;
  private _saveTipSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _tipAndWinService: TipAndWinService,
    private _svgLoader: SvgLoaderService
  ) { }

  ngOnInit(): void {
    this._svgLoader.startLoading();

    var hideSpieltippsBis = new Date(this.mpSettings.settings['TipAndWinSettings'].HideSpieltippsBis);
    if (this.mpSettings.settings['TipAndWinSettings'].HideSpieltipps &&
      hideSpieltippsBis > new Date() &&
      hideSpieltippsBis < new Date('2050-12-31')) {
      this.showTippsCommingSoon = true;
      this.textTippsCommingSoon = this.ls.locs['locTipAndWin'].DieTippphaseBeginntAb.replace('{0}', hideSpieltippsBis.toLocaleDateString());
    }

    this._getMatchTipsSubscription = this._apiService.getRequest(`/api/TipAndWin/GetSpieltipps?aktion=${this._tipAndWinService.campaign}`).subscribe((data: any) => {
      this.allTips = [];
      this._svgLoader.finishLoading();

      if (data.Result === 'OK') {
        if (data.Records.length > 0) {
          this.allTips = data.Records;
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      this._setLastTipTimeForMatches();
      this._updateOpenTips();
      this.showTips(this._getIndexOfCurrentTip());
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getMatchTipsSubscription !== 'undefined') {
      this._getMatchTipsSubscription.unsubscribe();
    }

    if (typeof this._saveTipSubscription !== 'undefined') {
      this._saveTipSubscription.unsubscribe();
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Shows a message notification, when
   * save button is clicked.
   */
  clickedSaveButton(): void {
    this._mpMessaging.openSuccessPanel(this.ls.locs['locTipAndWin'].EingabeWurdeGespeichert);
  }

  /**
   * Gets the index of the current tip.
   */
  private _getIndexOfCurrentTip(): number {
    let index = 0;

    if (this.allTips.length > 0) {
      let found = false;
      const now = new Date().toISOString();

      while (index < this.allTips.length && !found) {
        const week = this.allTips[index];

        for (var j = 0; j < week.days.length && !found; ++j) {
          if (week.days[j].date >= now) {
            found = true;
          }
        }

        if (!found) {
          ++index;
        }
      }

      if (index >= this.allTips.length) {
        --index;
      }

      if (index < 0) {
        index = 0;
      }
    }

    return index;
  }

  /**
   * Updates the open tips.
   */
  private _updateOpenTips(): void {
    this._tipAndWinService.openTips = 0;

    this.allTips.forEach((phase: { [key: string]: any }) => {
      phase['days'].forEach((day: { [key: string]: any }) => {
        day['matches'].forEach((match: { [key: string]: any }) => {
          if (Date.now() < match['lastTipTime']) {
            if (match['teamOne'].tip === null || match['teamOne'].tip === '' ||
              match['teamTwo'].tip === null || match['teamTwo'].tip === '') {
              this._tipAndWinService.openTips++;
            }
          }
        });
      });
    });
  }

  /**
   * Sets the last tip time for the
   * matches.
   */
  private _setLastTipTimeForMatches(): void {
    this.allTips.forEach((phase: { [key: string]: any }) => {
      phase['days'].forEach((day: { [key: string]: any }) => {
        const date = day['date'];

        day['matches'].forEach((match: { [key: string]: any }) => {
          match['lastTipTime'] = Date.parse(date + 'T' + match['time']) - 300000;
        });
      });
    });
  }

  /**
   * Shows the tips for the given index.
   */
  showTips(tipIndex: number): void {
    this.phaseTips = this.allTips[tipIndex];
    this.tipIndex = tipIndex;
    this.hasTipsToFill = false;

    if (this.phaseTips['days'].length > 0) {
      for (let index = 0; index < this.phaseTips['days'].length; index++) {
        this.phaseTips['days'][index].matches.forEach((match: { [key: string]: any }) => {
          if (Date.now() < match['lastTipTime']) {
            this.hasTipsToFill = true;
          }
        });
      }
    }

    this.phaseTipsSet = true;
  }

  /**
   * Formats the match date.
   */
  formatMatchDate(date: string): string {
    const dateToFormat = new Date(date);
    const day = dateToFormat.getDate() < 10 ? '0' + dateToFormat.getDate() : dateToFormat.getDate();

    return this._days[dateToFormat.getDay()] + ', ' + day + '. ' + this._months[dateToFormat.getMonth()] + ' ' + dateToFormat.getFullYear();
  }

  /**
   * Checks whether or not the last tip
   * time is reached.
   */
  checkLastTipTimeReached(match: { [key: string]: any }): boolean {
    if (Date.now() >= match['lastTipTime']) {
      this._updateOpenTips();

      return true;
    } else {
      return false;
    }
  }

  /**
   * Saves the set tip in the databse.
   */
  setTip(match: { [key: string]: any }): void {
    if (match['teamOne'].tip !== undefined && match['teamOne'].tip !== '' &&
      match['teamTwo'].tip !== undefined && match['teamTwo'].tip !== '') {
      const reply = {
        Aktion: this._tipAndWinService.campaign,
        FixtureId: match['fixtureId'],
        TnTeamATore: match['teamOne'].tip,
        TnTeamBTore: match['teamTwo'].tip
      };

      this._saveTipSubscription = this._apiService.postRequest('/api/TipAndWin/SaveSpieltipp', reply).subscribe((data: any) => {
        if (data.Result !== 'OK') {
          this._mpMessaging.openWarningPanel(data.Message);
        }

        this._updateOpenTips();
      },
      (error: any) => {
        this._mpMessaging.openErrorPanel(error);
      });
    }
  }

}
