import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';

/**
 * This class provides a simple input-registrierungs-code, that
 * can be configured with different input attributes
 * (e.g. for required fields, for optional fields, ...).
 */
@Component({
  selector: 'mp-core-input-registrierungs-code',
  templateUrl: './input-registrierungs-code.component.html',
  styleUrls: ['./input-registrierungs-code.component.scss']
})
export class InputRegistrierungsCodeComponent implements OnInit {
  @Input() public label: string = '';
  @Input() public mpId: string = '';
  @Input() public mpType: string = '';
  @Input() public mpEnter: Function | undefined;
  @Input() public mpRequired: boolean = false;
  @Input() public mpDisabled: boolean = false;
  @Input() public keyup: Function | undefined;
  @Input() public focusOn: boolean = false;
  @Input() public mpPlaceholder: string = '';
  @Input() public mpOptional: boolean = false;
  @Input() public mpSup: string = '';
  @Input() public hasError: boolean = false;
  @Input() public minVal: boolean = false;
  @Input() public maxVal: boolean = false;
  @Input() public onlyInt: boolean = false;
  @Input() public mpClass: string = '';
  @Input() public model: string = '';

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputBlur: EventEmitter<object> = new EventEmitter<object>();
  @Output() inputChange: EventEmitter<object> = new EventEmitter<object>();
  @Output() inputFocus: EventEmitter<object> = new EventEmitter<object>();

  public mpIdHash: string = '';
  public labelText: string = '';

  public regExp1: any;
  public regExp2: any;
  public regExp3: any;
  public regExp4: any;

  constructor(public ls: MpLocalizationService) { }

  /**
  * Angulars init function of the class. It sets
  * an id hash to have an unique id on each input
  * element in DOM and it assignes the given
  * label to another variable.
  */
  ngOnInit(): void {
    this.mpIdHash = `${this.mpId}-${(Math.floor((Math.random() + 0.9) * 1000000000))}`;
    this.labelText = this.label;

    this.regExp1 = new RegExp('[0-9A-Z]{0,16}');
    this.regExp2 = new RegExp('[0-9A-Z]{4}-[0-9A-Z]{0,12}');
    this.regExp3 = new RegExp('[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{0,8}');
    this.regExp4 = new RegExp('[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{0,4}');
  }

  correctInput() {
    if (this.model) {
      let input = this.model.toUpperCase();
      let inputCorrected = this.regExp4.exec(input);
      inputCorrected = inputCorrected ? inputCorrected : this.regExp3.exec(input);
      inputCorrected = inputCorrected ? inputCorrected : this.regExp2.exec(input);
      inputCorrected = inputCorrected ? inputCorrected : this.regExp1.exec(input);


      if (!inputCorrected || inputCorrected.length === 0) {
        this.model = '';
      } else {
        if (inputCorrected[0].length > 4 && inputCorrected[0][4] !== '-')
          inputCorrected[0] = inputCorrected[0].substr(0, 4) + '-' + inputCorrected[0].substr(4);

        if (inputCorrected[0].length > 9 && inputCorrected[0][9] !== '-')
          inputCorrected[0] = inputCorrected[0].substr(0, 9) + '-' + inputCorrected[0].substr(9);

        if (inputCorrected[0].length > 14 && inputCorrected[0][14] !== '-')
          inputCorrected[0] = inputCorrected[0].substr(0, 14) + '-' + inputCorrected[0].substr(14);

        this.model = inputCorrected[0];
      }
    }
  }

  /**
   * Listens to keyup event and executes the given
   * functions for mpEnter and / or keyup.
   */
  executeKeyup(event: any): void {
    this.correctInput();
    if (event.keyCode === 13 && this.mpEnter) {
      this.mpEnter();
    }

    if (this.keyup) {
      this.keyup();
    }
  }

  /**
   * Listens to blur, change and focus events and
   * emits those events back to the parent
   * component.
   */
  executeEvent(event: object, eventType: string): void {
    switch (eventType) {
    case 'focus':
      this.inputFocus.emit(event);
      break;
    case 'change':
      this.inputChange.emit(event);
      break;
    case 'blur':
      this.inputBlur.emit(event);
      break;
    default:
    }
  }
}
