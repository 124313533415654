import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpPermissionsService } from './../../services/mp-permissions.service';
import { MpBreadcrumbService } from './../../services/mp-breadcrumb.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { MpCoreService } from './../../services/mp-core.service';

/**
 * This class provides the data and
 * functions for the breadcrumbs.
 */
@Component({
  selector: 'mp-core-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public dashboardTab: any = {};
  public currentSite: any;
  public currentSteps: any;
  public isAuthenticated: boolean = false;

  private _role: string = '';
  private _getPermissionsSubscription: Subscription | undefined;
  private _getDashboardTabSubscription: Subscription | undefined;
  private _getCurrentSiteSubscription: Subscription | undefined;
  private _getCurrentStepsSubscription: Subscription | undefined;


  constructor(
    public ls: MpLocalizationService,
    public _permissionsService: MpPermissionsService,
    public _breadcrumbService: MpBreadcrumbService,
    private _router: Router,
    private _mpCore: MpCoreService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the subscriptions from permissions and breadcrunb services.
   */
  ngOnInit(): void {
    this._permissionsService.getPermissions();
    this._getPermissionsSubscription = this._permissionsService.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.isAuthenticated = this._permissionsService.permissions.IsAuthenticated;
      }
    });
    this._getDashboardTabSubscription = this._breadcrumbService.dashboardTab.subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.dashboardTab = this._breadcrumbService.getDashboardTab();
      } else {
        this.dashboardTab = {};
      }

    });
    this._getCurrentSiteSubscription = this._breadcrumbService.currentSite.subscribe((data: any) => {
      this.currentSite = data;

    });
    this._getCurrentStepsSubscription = this._breadcrumbService.currentSteps.subscribe((data: any) => {

        this.currentSteps = data;

    });

    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }
  }

  /**
   * Unsubscribes set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getDashboardTabSubscription !== 'undefined') {
      this._getDashboardTabSubscription.unsubscribe();
    }

    if (typeof this._getCurrentSiteSubscription !== 'undefined') {
      this._getCurrentSiteSubscription.unsubscribe();
    }

    if (typeof this._getCurrentStepsSubscription !== 'undefined') {
      this._getCurrentStepsSubscription.unsubscribe();
    }
  }

  /**
   * Is triggered when clicking on breadcrumb for dashboard
   */
  breadcrumbClickDashboard(val: any, extern?: boolean) {
    this._mpCore.goTo(val, extern);
  }

  /**
  * Is triggered when clicking on breadcrumb for site
  */
  breadcrumbClickSite(val: any) {
    const hasQueryParams = val.indexOf('?');
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';

    if (!(val.indexOf(this._role + '/') === 1 || val.indexOf('/' + this._role + '/') === 1)) {
      val = this._role + '/' + val;
    }

    if (hasQueryParams !== -1) {
      this._router.navigateByUrl(val);
    } else {
      this._router.navigate([val]);
    }
  }

  /*
  * Calls goTo method from core service
  */
  goToFromCore(val?: any, extern?: boolean) {
    if (!this.isAuthenticated) {
      this._mpCore.goTo(val, true);
    } else {
      this._mpCore.goTo(val);
    }
  }

  /*
  * Calls goBack method from core service
  */
  goBackFromCore() {
    this._mpCore.goBack();
  }
}
