import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSettingsService } from './../../../services/mp-settings.service';
import { MpOrderProcessService } from './../../../components/order-process/mp-order-process.service';
import { AuthService } from './../../../services/auth.service';
import { RoleMappingService } from './../../../services/role-mapping.service';
import { ApiService } from './../../../services/api.service';

/**
 * This class provides the data
 * for the shopping basket panel
 * within the order process.
 */
@Component({
  selector: 'mp-core-order-shopping-basket-panel',
  templateUrl: './order-shopping-basket-panel.component.html',
  styleUrls: ['./order-shopping-basket-panel.component.scss']
})
export class OrderShoppingBasketPanelComponent implements OnInit, OnDestroy {

  public tnIsOnPaymentBlacklist: boolean = true;
  private _blacklistSubscription: Subscription | undefined;

  @Input() public shoppingCart: Array<any> = [];
  @Input() public cartItems: any = null;
  @Input() public showEdit: boolean = false;

  public steps: { [key: string]: any } = {};
  public role: string = '';

  private _stepsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpOrderProcess: MpOrderProcessService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the steps and provides them
   * to the view.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._stepsSubscription = this._mpOrderProcess.steps.subscribe((steps: any) => {
      this.steps = steps;
    });

    this._blacklistSubscription = this._apiService.getRequest("/api/WkZuzahlung/IsTnOnBlacklist").subscribe((result: any) => {
      this.tnIsOnPaymentBlacklist = result.Result === 'OK' && result.Records[0];
    });
  }

  /**
   * Unsubscribes set subscribers.
   */
  ngOnDestroy(): void {
    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }

    if (this._blacklistSubscription) {
      this._blacklistSubscription.unsubscribe();
    }
  }

}
