import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpOrderProcessService } from './../../../../components/order-process/mp-order-process.service';
import { MpShoppingBasketService } from './mp-shopping-basket.service';

import { ShoppingBasketItem } from './shopping-basket-item';

/**
 * This class provides the functions
 * for the shopping basket check.
 */
@Directive({
  selector: '[mpCoreShoppingBasketCheck]'
})
export class ShoppingBasketCheckDirective implements OnDestroy {

  private _shoppingBasketSubscription: Subscription | undefined;
  private _currentSteopSubscription: Subscription | undefined;

  /**
   * Gets the items of the shopping
   * basket and the current step from
   * the order process.
   */
  constructor(
    private _mpOrderProcess: MpOrderProcessService,
    private _mpShoppingBasket: MpShoppingBasketService
  ) {
    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((shoppingBasket: Array<ShoppingBasketItem>) => {
      if (this._mpShoppingBasket.shoppingBasket.length > 0) {
        this._updateValidationMessages();
      } else {
        this._mpOrderProcess.goToFirstPage();
      }
    });

    if (this._mpShoppingBasket.shoppingBasket.length === 0) {
      this._mpShoppingBasket.refreshShoppingBasket();
    }

    this._currentSteopSubscription = this._mpOrderProcess.currentStepObserve.subscribe((currentStep: any) => {
      this._updateValidationMessages();
    });
  }

  /**
   * Unsubscribes set subscriptions
   */
  ngOnDestroy(): void {
    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._currentSteopSubscription !== 'undefined') {
      this._currentSteopSubscription.unsubscribe();
    }
  }

  /**
   * Updates the validation messages.
   */
  private _updateValidationMessages(): void {
    if (this._mpOrderProcess.currentStep !== null && this._mpShoppingBasket.shoppingBasket.length > 0) {
      const groupedBasketKeys = Object.keys(this._mpShoppingBasket.groupedBasket);

      if (groupedBasketKeys.length > 0) {
        groupedBasketKeys.forEach((key: string) => {
          const group = this._mpShoppingBasket.groupedBasket[key];
          const currentStepObj = this._mpOrderProcess.currentStep;
          const currentStepKey = currentStepObj !== null ? currentStepObj['Key'] : null;

          if (group.length > 0 && currentStepObj !== null && typeof currentStepKey !== 'undefined') {
            group.forEach((item: any) => {
              item.ShownValidationMessages = item.ValidationMessages.filter((m: any) => {
                return m.ShowInSteps.indexOf(currentStepKey) !== -1;
              });

              item.HasBlockingValidationMessages = typeof item.BlockingValidationMessages.find((m: any) => {
                return m.ShowInSteps.indexOf(currentStepKey) !== -1;
              }) !== 'undefined' ? true : false;
            });
          }
        });
      }
    }
  }

}
