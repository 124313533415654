import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpLoadArticlesService } from './../../../../services/mp-load-articles.service';
import { MpCategoriesService } from './../../../../services/mp-categories.service';
import { MpEnumsService } from './../../../../services/mp-enums.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpStatusService } from './../../../../services/mp-status.service';
import { MpThemesService } from './../../../../services/mp-themes.service';
import { SvgLoaderService } from './../../../../components/svg-loader/svg-loader.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { MpErrorHandlerService } from './../../../../services/mp-error-handler.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';
import { MpShoppingBasketService } from './../shopping-basket/mp-shopping-basket.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpDebounceService } from '../../../../services/mp-debounce.service';

import { Tile } from './../../../../components/card/shop-card/tile';

@Component({
  selector: 'mp-core-shop',
  templateUrl: './shop.component.html',
  styleUrls: [
    './shop.component.scss',
    './../../../error/styles/error-page.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ShopComponent implements OnInit, OnDestroy {

  public articleData: any;
  public dataSorting: { [key: string]: any } = {};
  public hasStage: boolean = false;
  public stages: Array<any> = [];
  public visualTitel: string = '';
  public visualText: string = '';
  public showFilter: boolean = false;
  public showCategories: boolean = false;
  public data: { [key: string]: any } = {};
  public categoryId: number = 0;
  public currentCatId: { [key: string]: any } = {};
  public currentTheme: string = 'default';
  public totalrecordcount: number = -1;
  public searchTerm: string = '';
  public pageCount: number = 1;
  public currentPage: number = 1;
  public count: any;
  public hasStatus: boolean = false;
  public renderSize: number = 40;
  public loading: boolean = true;
  public errMessages: Array<string> = [];
  public saveCurrentPositionFunc: Function = this.saveCurrentPosition.bind(this);
  public filterForCheck: any;
  public initialLoad: boolean = true;
  public role: string = '';

  private _oldSorting: any;
  private _initialSorting: any;
  private _initialLoad: boolean = true;
  private _scope: any;
  private _ignoreScrollOnce: boolean = true;
  private _routeParams: any = [];
  private _codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
  private _firstTimeCategoryLoad: boolean = true;
  private _statusSubscription: Subscription | undefined;
  private _categorySubscription: Subscription | undefined;
  private _enumSubscription: Subscription | undefined;
  private _routeSubscription: Subscription | undefined;
  private _scopeChangeSubscription: Subscription | undefined;
  private _getSortingSubscription: Subscription | undefined;
  private _setSortingSubscription: Subscription | undefined;
  private _oderableSubscription: Subscription | undefined;
  private _expressServiceSubscription: Subscription | undefined;
  private _newFilterSubscription: Subscription | undefined;
  private _availableSubscription: Subscription | undefined;
  private _resetPointsSubscription: Subscription | undefined;
  private _setStatusSubscription: Subscription | undefined;
  private _resetStatusSubscription: Subscription | undefined;
  private _loadFilterSubscription: Subscription | undefined;
  private _resetFilterSubscription: Subscription | undefined;
  private _themeSubscription: Subscription | undefined;
  private _setPointFilterSubscription: Subscription | undefined;
  private _loaderSubscription: Subscription | undefined;
  private _getShopHeaderSubscription: Subscription | undefined;
  private _getFiltereCountSubscription: Subscription | undefined;
  private _routerEventSubscription: Subscription | undefined;
  private _routeUrlPipeSubscription: Subscription | undefined;
  private _lastFilter: any = { bestellbar: '', express: '', neuheiten: '', verfuegbarkeit: '', status: '', punkteIntervallId: '' };
  private _sortingLoaded: boolean = false;
  private _filtersLoaded: boolean = false;
  private _getFilterCount: Function;
    private _loadFilter: Function;


  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    public mpSettings: MpSettingsService,
    private _mpLoadArticles: MpLoadArticlesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mpCategories: MpCategoriesService,
    private _mpEnums: MpEnumsService,
    private _apiService: ApiService,
    private _titleService: MpTitleService,
    private _mpStatus: MpStatusService,
    private _mpThemes: MpThemesService,
    private _svgLoader: SvgLoaderService,
    private _mpCoreService: MpCoreService,
    private _mpErrorHandler: MpErrorHandlerService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpDebounce: MpDebounceService

  ) {
    this._routerEventSubscription = this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this._mpCategories.getCategoryId();
        this._mpCategories.loadCategoryTree(true);
        this.initialLoad = true;
        this._reloadDataWithParams();
      }
    });

    this._getFilterCount = this._mpDebounce.debounce(this._getFilterCountCall, 100, false, this);
    this._loadFilter = this._mpDebounce.debounce(this._loadFilterCall, 100, false, this);
  }

  /**
   * Angulars init function. Loads categories,
   * enums, and articles. Sets the subscribers
   * for detecting changes (like route param
   * changes).
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._mpThemes.getCurrentTheme();

    this._loaderSubscription = this._svgLoader.svgLoaderState.subscribe((count: number) => {
      if (count > 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });

    this._themeSubscription = this._mpThemes.theme.subscribe((theme: string) => {
      this.currentTheme = theme;
    });

    if (typeof this._mpStatus.currentHasStatus !== 'undefined') {
      this.hasStatus = this._mpStatus.currentHasStatus;
    } else {
      this._statusSubscription = this._mpStatus.status.subscribe((status: boolean) => {
        this.hasStatus = status;
      });

      this._mpStatus.hasStatus();
    }

    this._mpCategories.getCategoryId();
    this._mpCategories.loadCategoryTree();

    this._enumSubscription = this._mpEnums.enumsLoaded.subscribe(() => {
      if (typeof this._mpEnums.enums['AktuelleKategorienId'] !== 'undefined') {
        this.currentCatId = this._mpEnums.enums['AktuelleKategorienId'];
      }
    });

    this._mpLoadArticles.setScope({
      startindex: 0,
      pagesize: 90000,
      renderSize: 40,
      loadingMore: false,
      totalrecordcount: this.totalrecordcount,
      data: [],
      stages: [],
      searchTerm: ''
    }, 'articleData');

    this._scopeChangeSubscription = this._mpLoadArticles.scopeChange.subscribe((change: boolean) => {
      if (change) {
        this._scope = this._mpLoadArticles.getScope();
        this.articleData = typeof this._scope !== 'undefined' ? typeof this._scope['articleData'] !== 'undefined' ? this._scope['articleData'] : [] : [];
        this.totalrecordcount = typeof this._scope !== 'undefined' ? typeof this._scope['totalrecordcount'] !== 'undefined' ? this._scope['totalrecordcount'] : -1 : -1;
        this._shopGrid();
      }
    });

    this._reloadDataWithParams();
    this._mpMenuHighlight.setMenuHighlight("praemien");
  }

  private _applyRouteValues(routeValues: any) {
    const params = routeValues[1].params;
    const qParams = routeValues[2].params;

    if (typeof params['id'] !== 'undefined' && params['id'] !== null) {
      this._mpLoadArticles.setRouteParams({ id: params['id'] });
      this._routeParams['k'] = params['id'];
    } else {
      this._mpLoadArticles.setRouteParams(qParams);
      this._routeParams = qParams;
    }

    if (typeof qParams['s'] !== 'undefined') {
      if (this.searchTerm !== qParams['s']) {
        this._initialSorting = -1;
      }

      this.searchTerm = qParams['s'];
    } else {
      this.searchTerm = '';
    }

    let lastCategoryId = localStorage.getItem('categoryId');
    let lastSearchText = localStorage.getItem('searchText');

    this._categorySubscription = this._mpCategories.categoryId.subscribe((catId: string) => {
      this.categoryId = parseInt(catId);
      localStorage.setItem('categoryId', this.categoryId.toString());
      localStorage.setItem('searchText', this.searchTerm);

      if (lastCategoryId && parseInt(lastCategoryId) !== this.categoryId || lastSearchText && lastSearchText != this.searchTerm) {
        this.resetAllFilter();
      } else {
        if (!this._firstTimeCategoryLoad) {
          this._update();
        }
      }

      if (this._firstTimeCategoryLoad) {
        this._firstTimeCategoryLoad = false;
      }
    });

    this._getShopHeaderSubscription = this._apiService.getRequest(`/api/Kategorien/getShopHeader/${(typeof params['id'] !== 'undefined' ? params['id'] : typeof qParams['k'] !== 'undefined' ? qParams['k'] : '0')}?suchbegriff=${(typeof qParams['s'] !== 'undefined' ? this._codec.encodeValue(qParams['s']) : '')}&markenname=${(typeof qParams['m'] !== 'undefined' ? this._codec.encodeValue(qParams['m']) : '')}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        const dataHeader = data.Records[0];
        let counter = 0;

        if (dataHeader.Buehne !== null) {
          this.stages = dataHeader.Buehne.map((stage: any) => {
            this.hasStage = this.hasStage || true;

            return {
              title: dataHeader.Titel,
              text: dataHeader.KategorieText,
              source: stage,
              stageText: dataHeader.BuehnenText,
              link: dataHeader.Link,
              linkText: dataHeader.LinkText,
              id: counter++
            }
          });
        }

        this.visualTitel = dataHeader.Titel;
        this.visualText = dataHeader.KategorieText;
        this._titleService.setTitle(dataHeader.Titel);

        const url = location.hash.slice(1);
        this._breadcrumbService.setCurrentSiteWithText(url, this.visualTitel);
      }
    },
      (error: any) => {
        console.log(error.message);
      });

    this._getSortingSubscription = this._apiService.getRequest('/api/Filter/getSortierung').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._oldSorting = data.Records[0].Sortierung;
        this.dataSorting = data.Records[0];
        this._setSorting(data.Records[0].Sortierung);
        this._initialSorting = data.Records[0].Sortierung;
      }
    },
      (error: any) => {
        console.log(error.message);
      });

    this._update();
  }

  /**
   * Initially loads the date and does
   * a reload of the data on route
   * params change.
   */
  private _reloadDataWithParams(): void {
    let debouncedCallback = this._mpDebounce.debounce(this._applyRouteValues, 100, false, this);

    this._routeUrlPipeSubscription = this._route.url.pipe(
      withLatestFrom(this._route.paramMap, this._route.queryParamMap)
    ).subscribe((routeValues: any) => {
      debouncedCallback(routeValues)
    });
  }

  private loadItems(sortingOrCategoryChanged: boolean): void {
    this._mpLoadArticles.load(true, () => { }, sortingOrCategoryChanged);
  }

  /**
   * Unsubscribes all subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._statusSubscription !== 'undefined') {
      this._statusSubscription.unsubscribe();
    }

    if (typeof this._categorySubscription !== 'undefined') {
      this._categorySubscription.unsubscribe();
    }

    if (typeof this._enumSubscription !== 'undefined') {
      this._enumSubscription.unsubscribe();
    }

    if (typeof this._routeSubscription !== 'undefined') {
      this._routeSubscription.unsubscribe();
    }

    if (typeof this._scopeChangeSubscription !== 'undefined') {
      this._scopeChangeSubscription.unsubscribe();
    }

    if (typeof this._getSortingSubscription !== 'undefined') {
      this._getSortingSubscription.unsubscribe();
    }

    if (typeof this._setSortingSubscription !== 'undefined') {
      this._setSortingSubscription.unsubscribe();
    }

    if (typeof this._oderableSubscription !== 'undefined') {
      this._oderableSubscription.unsubscribe();
    }

    if (typeof this._expressServiceSubscription !== 'undefined') {
      this._expressServiceSubscription.unsubscribe();
    }

    if (typeof this._newFilterSubscription !== 'undefined') {
      this._newFilterSubscription.unsubscribe();
    }

    if (typeof this._availableSubscription !== 'undefined') {
      this._availableSubscription.unsubscribe();
    }

    if (typeof this._resetPointsSubscription !== 'undefined') {
      this._resetPointsSubscription.unsubscribe();
    }

    if (typeof this._setStatusSubscription !== 'undefined') {
      this._setStatusSubscription.unsubscribe();
    }

    if (typeof this._resetStatusSubscription !== 'undefined') {
      this._resetStatusSubscription.unsubscribe();
    }

    if (typeof this._loadFilterSubscription !== 'undefined') {
      this._loadFilterSubscription.unsubscribe();
    }

    if (typeof this._resetFilterSubscription !== 'undefined') {
      this._resetFilterSubscription.unsubscribe();
    }

    if (typeof this._themeSubscription !== 'undefined') {
      this._themeSubscription.unsubscribe();
    }

    if (typeof this._setPointFilterSubscription !== 'undefined') {
      this._setPointFilterSubscription.unsubscribe();
    }

    if (typeof this._loaderSubscription !== 'undefined') {
      this._loaderSubscription.unsubscribe();
    }

    if (typeof this._getShopHeaderSubscription !== 'undefined') {
      this._getShopHeaderSubscription.unsubscribe();
    }

    if (typeof this._getFiltereCountSubscription !== 'undefined') {
      this._getFiltereCountSubscription.unsubscribe();
    }

    if (typeof this._routerEventSubscription !== 'undefined') {
      this._routerEventSubscription.unsubscribe();
    }

    if (typeof this._routeUrlPipeSubscription !== 'undefined') {
      this._routeUrlPipeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the sorting in backend.
   */
  private _setSorting(id: any, calledFromReset?: boolean): void {
    if (typeof this._initialSorting !== 'undefined') {
      if (this._initialSorting === id) {
        return;
      }
    }

    if (this.initialLoad && typeof this._initialSorting === 'undefined' && calledFromReset) {
      return;
    }

    this._setSortingSubscription = this._apiService.postRequest('/api/Filter/setSortierung', { sortierelement: id }).subscribe(() => {
      const sortingHasChanged = true;
      this._sortingLoaded = true;
      this.loadItems(sortingHasChanged);
    },
      (error: any) => {
        console.log(error.message);
      });
  }

  /**
   * Triggers a change of the sorting.
   * Event listener for change events
   * on radio buttons.
   */
  changeSorting(evt: Event): void {
    //const target = evt.target as HTMLInputElement;
    this._initialSorting = -1;

    if (evt !== this._oldSorting) {

      this._setSorting(evt);
      this._oldSorting = evt;
    }
  }

  /**
   * Creates the shop grid, that means it
   * adds the info which card should be
   * shown on wich page of the shop.
   */
  private _shopGrid(): void {
    if (typeof this.articleData === 'undefined' || (typeof this.articleData !== 'undefined' && this.articleData.length === 0)) {
      if (this.totalrecordcount > -1) {
        this.initialLoad = false;
      }

      return;
    }

    this.initialLoad = false;

    const winWidth = window.innerWidth;

    if (winWidth < 1300 && winWidth >= 975) {
      this._scope['renderSize'] = 30;
      this.renderSize = 30;
    }

    let cardDimensions = 1;
    let pageCards = 0;
    let page = 1;
    let childTmp;
    let currentCard;
    let isCardM;
    let renderSize = this._scope['renderSize'];
    let isLastPage = false;

    this.articleData.forEach((child: any, index: number) => {
      cardDimensions = parseInt(child.CellWidth) * parseInt(child.CellHeight);
      pageCards = pageCards + cardDimensions;

      isCardM = cardDimensions === 4 ? true : false;
      isLastPage = this.articleData.length - index <= 5;

      if (pageCards > renderSize && !isCardM && !isLastPage) {
        page++;
        pageCards = 1;
        child.page = page;
      } else if (pageCards > renderSize && isCardM && !isLastPage ||
        pageCards === renderSize && isCardM && !isLastPage ||
        pageCards === renderSize - 1 && isCardM && !isLastPage) {
        currentCard = index;
        pageCards = pageCards - cardDimensions;

        for (let a = currentCard; a < this.articleData.length; a++) {
          if (this.articleData[a].CellWidth == 1 && pageCards < renderSize) {
            childTmp = this.articleData[currentCard];
            this.articleData[currentCard] = this.articleData[a];
            this.articleData[currentCard].page = page;

            this.articleData[a] = childTmp;
            currentCard = a;

            pageCards++;
          } else if (pageCards === renderSize) {
            page++;
            pageCards = 0;

            return false;
          }
        }
      } else if (typeof child.page === 'undefined') {
        child.page = page;
      } else {
        pageCards = pageCards - cardDimensions;
      }

      this.pageCount = page;
    });

    this._ignoreScrollOnce = false;
  }

  /**
   * Handles the scroll on a page
   * change.
   */
  onPageChange(evt: any): void {
    this.currentPage = evt;

    if (!this._ignoreScrollOnce) {
      window.scrollTo(0, 0);
    }

    this._ignoreScrollOnce = false;
  }

  /**
   * Scrolls to the last positon (= last
   * card in view / last clicked card).
   */
  scrollToLastPosition(): void {
    setTimeout(() => {
      if (sessionStorage['lastShopPage']) {
        if (this._mpCoreService.usedBack) {
          this.currentPage = parseInt(sessionStorage['lastShopPage']);
        }

        sessionStorage.removeItem('lastShopPage');
      }

      if (sessionStorage['scrollPosition']) {
        if (this._mpCoreService.usedBack) {
          window.scrollTo(0, parseInt(sessionStorage['scrollPosition']));

          if (this.currentPage != 1) {
            this._ignoreScrollOnce = true;
          }
        }

        sessionStorage.removeItem('scrollPosition');
      }
    }, 1000);
  }

  //scope.getTileById = function (tileId) {
  //  var kachel = _.findWhere(_.flatten(scope.grid), { RecNo: parseInt(tileId) });
  //  return kachel;
  //}

  /**
   * Saves the current positon of the
   * viewport to scroll back to it
   * later (if needed).
   */
  saveCurrentPosition(): void {
    sessionStorage['lastShopPage'] = this.currentPage;
    sessionStorage['scrollPosition'] = window.scrollY;
  }

  /**
   * Triggers the opening / closing
   * of the filter.
   */
  toggleFilter(): void {
    if (this.showFilter) {
      this.closeFilter();
    } else {
      this._openFilter();

      const filterElem = document.getElementById('filter');

      if (filterElem !== null) {
        let scrollDistance = filterElem.offsetTop;
        const navbar = document.getElementById('navbar');
        scrollDistance -= navbar !== null ? navbar.getBoundingClientRect().height : 0;

        window.scrollTo({
          top: scrollDistance + 1,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  /**
   * Closes the filter.
   */
  closeFilter(): void {
    if (this.showFilter) {
      this.showFilter = false;
    }
  }

  /**
   * Opens the filter.
   */
  private _openFilter(): void {
    this.showFilter = true;
    this.showCategories = false;
  }

  /**
   * Triggers availability filter
   * on checkbox state change.
   */
  changeOderable(evt: any): void {
    this.setOderableFilter();
  }

  /**
   * Sets the filter for oderable
   * awards.
   */
  setOderableFilter(): void {
    let service = 'setBestellbarFilter';

    if (!this.data['Bestellbar']) {
      service = 're' + service;
    }

    this._oderableSubscription = this._apiService.postRequest('/api/Filter/' + service, {}).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  };

  /**
   * Sets the filter for express
   * service.
   */
  setExpressServiceFilter(): void {
    let service = 'setExpressServiceFilter';

    if (!this.data['Express']) {
      service = 're' + service;
    }

    this._expressServiceSubscription = this._apiService.postRequest('/api/Filter/' + service, {}).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  };

  /**
   * Sets the filter for new awrds.
   */
  setNewFilter(): void {
    let service = 'setNeuheitenFilter';

    if (!this.data['Neuheiten']) {
      service = 're' + service;
    }

    this._newFilterSubscription = this._apiService.postRequest('/api/Filter/' + service, {}).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  };

  /**
   * Sets the availability filter.
   */
  setAvailableFilter(): void {
    let service = 'setVerfuegbareArtikelFilter';

    if (!this.data['Verfuegbarkeit']) {
      service = 're' + service;
    }

    this._availableSubscription = this._apiService.postRequest('/api/Filter/' + service, {}).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  };

  /**
   * Resets the point worth filter.
   */
  resetPointWorthFilter(): void {
    this._resetPointsSubscription = this._apiService.postRequest('/api/Filter/resetPunktwertFilter', {}).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  }

  /**
   * Checks whether or not the filter
   * is active.
   */
  isFilterActive(): boolean {
    return this.data['Express']
      || this.data['Neuheiten']
      || (this.data['SelectedIntervall'] && this.data['SelectedIntervall'].Von > 0)
      || (this.data['SelectedIntervall'] && this.data['SelectedIntervall'].Bis > 0)
      || this.data['Status'] < 3
      || this.data['Verfuegbarkeit'];
  };

  /**
   * Gets the count of the points
   * worth filter.
   */
  getPointWorthFilterCount(from: any, to: any): string {
    if (this.count) {
      const searchProps = { Von: from, Bis: to };
      // @ts-ignore
      return '(' + this.count['Punktefilter'].find((item: any) => item['Von'] === searchProps['Von'] && item['Bis'] === searchProps['Bis']).Anzahl + ')';
    }

    return '';
  }

  /**
   * Sets the status filter.
   */
  setStatusFilter(vlStatusID: any, evt: MouseEvent): void {
    evt.preventDefault();
    this.data['Status'] = vlStatusID;

    this._setStatusSubscription = this._apiService.postRequest('/api/Filter/setStatusFilter', { status: vlStatusID }).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  }

  /**
   * Resets the status filter.
   */
  resetStatusFilter(): void {
    this._resetStatusSubscription = this._apiService.postRequest('/api/Filter/resetStatusFilter', {}).subscribe(() => {
      this._update();
    },
      (error: any) => {
        console.log(error.message);
      });
  }

  /**
   * Gets the count of the status filter.
   */
  getStatusFilterCount(StatusID: any): string {
    if (this.count && this.count['Statusfilter'][StatusID]) {
      return '(' + this.count['Statusfilter'][StatusID] + ')';
    }

    return '';
  }

  /**
   * Updates the filters.
   */
  private _update(): void {
    this._getFilterCount();
    this._loadFilter();
  }

  /**
   * Gets the results count for the
   * filter.
   */
  private _getFilterCountCall(): void {
    this._getFiltereCountSubscription = this._apiService.getRequest('/api/Filter/getFilterCount/' + (this._routeParams['k'] || "0") + '?Suchbegriff=' + (typeof this._routeParams['s'] !== 'undefined' && this._codec.encodeValue(this._routeParams['s']) || '') + '&Markenname=' + (typeof this._routeParams['m'] !== 'undefined' && this._codec.encodeValue(this._routeParams['m']) || '')).subscribe((data: any) => {
      this.count = data.Records[0];
    },
      (error: any) => {
        console.log(error.message);
      });
  }


  /**
   * Loads the filters from the backend.
   */
  private _loadFilterCall(): void {

    var bestellbar = this._lastFilter.bestellbar;
    var express = this._lastFilter.express;
    var neuheiten = this._lastFilter.neuheiten;
    var verfuegbarkeit = this._lastFilter.verfuegbarkeit;
    var status = this._lastFilter.status;
    var punkteIntervallId = this._lastFilter.punkteIntervallId;

    this._loadFilterSubscription = this._apiService.getRequest('/api/Filter/getFilter?temp=' + (Math.floor(Math.random() * 100) + '')).subscribe((data: any) => {
      const rec = data.Records[0];

      if (!!rec) {
        this.data = rec;

        this.data['SelectedIntervallValue'] = this.data['PunktwertManuell'].Von + '-' + this.data['PunktwertManuell'].Bis;

        this.data['Punktwerte'].forEach((item: any) => {
          if (item['VonDisplay'] === this.data['PunktwertManuell'].VonDisplay && item['BisDisplay'] === this.data['PunktwertManuell'].BisDisplay) {
            Object.assign(item, { filterChecked: true });
          } else {
            Object.assign(item, { filterChecked: false });
          }
        });

        if (this.data['PunktwertManuell'].Von === -1) {
          this.data['PunktwertManuell'].Von = '';
        }

        if (this.data['PunktwertManuell'].Bis === -1) {
          this.data['PunktwertManuell'].Bis = '';
        }
      }

      if (typeof this.articleData === 'undefined' || (typeof this.articleData !== 'undefined' && this.articleData.length === 0) && this._initialLoad) {
        this._initialLoad = false;
        return;
      }

      this._lastFilter.bestellbar = this.data['Bestellbar'];
      this._lastFilter.express = this.data['Express'];
      this._lastFilter.neuheiten = this.data['Neuheiten'];
      this._lastFilter.verfuegbarkeit = this.data['Verfuegbarkeit'];
      this._lastFilter.status = this.data['Status'];
      this._lastFilter.punkteIntervallId = this.data['SelectedIntervallId'];
      var filterHasChanged = this._lastFilter.bestellbar !== bestellbar ||
        this._lastFilter.express !== express ||
        this._lastFilter.neuheiten !== neuheiten ||
        this._lastFilter.verfuegbarkeit !== verfuegbarkeit ||
        this._lastFilter.status !== status ||
        this._lastFilter.punkteIntervallId !== punkteIntervallId;

      this._filtersLoaded = true;
      this.loadItems(filterHasChanged);
    },
      (error: any) => {
        console.log(error.message);
      });
  }

  /**
   * Resets all filters.
   */
  resetAllFilter(): void {
    this.data['Status'] = 0;

    if (!this.data['PunktwertManuell']) {
      this.data['PunktwertManuell'] = {
        Von: '',
        Bis: ''
      };
    }

    this.data['PunktwertManuell'].Von = '';
    this.data['PunktwertManuell'].Bis = '';
    this.data['SelectedIntervall'] = null;

    this._resetFilterSubscription = this._apiService.postRequest('/api/Filter/resetAllFilter', {}).subscribe(() => {
      this._update();

      this._setSorting(this._initialSorting, true);
    },
      (error: any) => {
        console.log(error.message);
      });
  }

  /**
   * Triggers express service filter
   * on checkbox state change.
   */
  changeExpress(evt: any): void {
    this.setExpressServiceFilter();
  }

  /**
   * Triggers new awards filter
   * on checkbox state change.
   */
  changeNewAwards(evt: any): void {
    this.setNewFilter();
  }

  /**
   * Triggers availability filter
   * on checkbox state change.
   */
  changeAvailability(evt: any): void {
    this.setAvailableFilter();
  }

  /**
   * Sets the point worth filter.
   */
  setPointWorthFilter(from: any, to: any): void {
    this._setPointFilterSubscription = this._apiService.postRequest('/api/Filter/setPunktwertFilter', { Von: from, Bis: to }).subscribe(() => {
      this.filterForCheck = from + '-' + to;
      this._update();
    },
      (error: any) => {
        const asErrMessages = this._mpErrorHandler.handleResponse(error);
        this._updateErrMessage(asErrMessages);
      });
  }

  private _updateErrMessage(asErrMessages: Array<string>): void {
    this.errMessages = asErrMessages;
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
  * Adds the given wishlist item
  * to the shopping basket.
  */
  addToShoppingbasket(item: Tile): void {
    //if (item.CanOrder) {
    // @ts-ignore
    if (typeof (item.PIN) === 'undefined' || item.PIN === null) {
      item.PIN = '01';
      this._mpShoppingBasket.addItem(item.ArtNr, item.PIN, false, 1, item.HasZusatz);
    }
      
    //}
  }
}
