import { Injectable } from '@angular/core';

/**
 * This class provides some basic
 * config for froala.
 */
@Injectable({
  providedIn: 'root'
})
export class FroalaConfigService {

  constructor() { }

  /**
   * Returns the basic config.
   *
   * Key expires after 12 months. Usage
   * will be still possible, but no updates.
   * If Froala should be updated, a new
   * key must be bought. The currently key
   * was bought on 2022/12/09.
   */
  getConfig(): { [key: string]: any } {
    return {
      imageUploadURL: '/api/Image/Upload',
      key: 'CTD5xF3G3G2G1A8A2B3pZGCTRSAPJWTLPLZHTQQe1JGZxC4B3A3E2B5B1A1E4G1B4==',
      placeholderText: ''
    };
  }

  /**
   * Returns basic events.
   */
  getEvents(): { [key: string]: any } {
    return {
      'image.resizeEnd': (img: any) => {
        this._froalaAddAttributes(img.get(0));
      },
      'image.inserted': (img: any, response: any) => {
        this._froalaAddAttributes(img.get(0));
      },
      'html.beforeGet': () => {
        const tables = document.querySelectorAll('.fr-view table');

        if (tables.length > 0) {
          tables.forEach((table: any) => {
            const tbody = table.querySelectorAll('tbody');
            const thead = table.querySelectorAll('thead');
            const tr = table.querySelectorAll('tr');
            const th = table.querySelectorAll('th');
            const td = table.querySelectorAll('td');

            if (tbody.length > 0) {
              tbody.forEach((elem: any) => {
                this._froalaAddAttributes(elem);
              });
            }

            if (thead.length > 0) {
              thead.forEach((elem: any) => {
                this._froalaAddAttributes(elem);
              });
            }

            if (tr.length > 0) {
              tr.forEach((elem: any) => {
                this._froalaAddAttributes(elem);
              });
            }

            if (th.length > 0) {
              th.forEach((elem: any) => {
                this._froalaAddAttributes(elem);
              });
            }

            if (td.length > 0) {
              td.forEach((elem: any) => {
                this._froalaAddAttributes(elem);
              });
            }
          });
        }
      }
    };
  }

  /**
   * Adds attributes to the froala editor.
   */
  private _froalaAddAttributes(element: any): void {
    const width = window.getComputedStyle(element).getPropertyValue('width');
    const height = window.getComputedStyle(element).getPropertyValue('height');

    if (typeof width !== 'undefined' && typeof height !== 'undefined') {
      element.setAttribute('width', width.replace('px', '').replace('%', ''));
      element.setAttribute('height', height.replace('px', '').replace('%', ''));
    }
  }
}
