import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides a sidebar input, that is
 * used to open sidebars and to show the selected
 * data from the sidebar.
 */
@Component({
  selector: 'mp-core-sidebar-input',
  templateUrl: './sidebar-input.component.html',
  styleUrls: ['./sidebar-input.component.scss']
})
export class SidebarInputComponent implements OnInit, OnDestroy {

  @Input() public text: string = '';
  @Input() public icon: string = '';
  @Input() public sidebarComponent: string = '';
  @Input() public sidebarParams: any;
  @Input() public label: string = '';
  @Input() public hasError: boolean = false;
  @Input() public mpRequired: boolean = false;
  @Input() public mpDisabled: boolean = false;
  @Input() public mpPlaceholder: string = '';
  @Input() public mpOptional: boolean = false;

  @Output() sidebarParamsChange: EventEmitter<any> = new EventEmitter<any>();

  private _paramsChangeSubscription: Subscription | undefined;
  private _toggleSidebarSubscription: Subscription | undefined;

  constructor(public ls: MpLocalizationService, private _mpSidebar: MpSidebarService) { }

  ngOnInit(): void { }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._paramsChangeSubscription !== 'undefined') {
      this._paramsChangeSubscription.unsubscribe();
    }

    if (typeof this._toggleSidebarSubscription !== 'undefined') {
      this._toggleSidebarSubscription.unsubscribe();
    }
  }

  /**
   * Click event listener that opens the
   * given sidebar with the given parameters.
   * Listens to params changes in sidebar, and
   * emits change event for params.
   */
  openSidebar(): void {
    if (this.mpDisabled) {
      return;
    }

    this._paramsChangeSubscription = this._mpSidebar.paramsChange.subscribe((params: any) => {
      this.sidebarParamsChange.emit(params);
    });

    this._toggleSidebarSubscription = this._mpSidebar.toggleSidebar.subscribe((opened: boolean) => {
      if (!opened) {
        if (typeof this._paramsChangeSubscription !== 'undefined') {
          this._paramsChangeSubscription.unsubscribe();
        }

        if (typeof this._toggleSidebarSubscription !== 'undefined') {
          this._toggleSidebarSubscription.unsubscribe();
        }
      }
    });

    this._mpSidebar.open(this.sidebarComponent, this.sidebarParams);
  }

}
