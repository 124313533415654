<div *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)" id="dlgTNCockpit" class="layout-container">
  <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].ShowTnCockpitHeader" class="grid col-large-2">
    <mp-core-input label="{{ ls.locs['loc'].Benutzer }}" [(model)]="cockpitData['Benutzer']" mpId="cockpitheader-bonus-id" [mpRequired]="false" [mpDisabled]="true"></mp-core-input>
  </div>
  <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].ShowTnCockpitHeader" class="grid col-large-2">
    <mp-core-input label="{{ ls.locs['loc'].Name }}" [(model)]="cockpitData['Name']" mpId="cockpitheader-name" [mpRequired]="false" [mpDisabled]="true"></mp-core-input>
  </div>
  <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].ShowTnCockpitHeader" class="grid col-large-2">
    <mp-core-input label="{{ ls.locs['loc'].Vorname }}" [(model)]="cockpitData['Vorname']" mpId="cockpitheader-vorname" [mpRequired]="false" [mpDisabled]="true"></mp-core-input>
  </div>
  <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].ShowTnCockpitHeader" class="clearfix"></div>
  <div id="tabs">
    <div class="tab" [ngClass]="{active: tabVisible == 'Stammdaten' || !tabVisible}" (click)="setTab('Stammdaten')">{{ ls.locs['loc'].Stammdaten }}</div>
    <div class="tab" [ngClass]="{active: tabVisible == 'Adresse' || !tabVisible}" (click)="setTab('Adresse')">{{ ls.locs['loc'].Adresse }}</div>
    <div class="tab" [ngClass]="{active: tabVisible == 'Praemienkonto' || !tabVisible}" (click)="setTab('Praemienkonto')">{{ ls.locs['loc'].Praemienkonto }}</div>
    <div *ngIf="hasStatus" class="tab" [ngClass]="{active: tabVisible == 'Statuskonto' || !tabVisible}" (click)="setTab('Statuskonto')">{{ ls.locs['loc'].Statuskonto }}</div>
    <div class="tab" [ngClass]="{active: tabVisible == 'Bestellungen' || !tabVisible}" (click)="setTab('Bestellungen')">{{ ls.locs['loc'].Bestellungen }}</div>
    <div *ngIf="isAdmin" class="tab" [ngClass]="{active: tabVisible == 'ManuelleBestellung' || !tabVisible}" (click)="setTab('ManuelleBestellung')">{{ ls.locs['loc'].ManuelleBestellung }}</div>
    <div class="margin-top-24">
      <div id="tabStammdaten" [hidden]="tabVisible !== 'Stammdaten' && tabVisible !== ''">
        <mp-core-ptcpnt-cockpit-master-data></mp-core-ptcpnt-cockpit-master-data>
        <div class="clear"></div>
      </div>
      <div id="tabAdressen" [hidden]="tabVisible !== 'Adresse'">
        <mp-core-ptcpnt-cockpit-addresses></mp-core-ptcpnt-cockpit-addresses>
        <div class="clear"></div>
      </div>
      <div id="tabKonto" [hidden]="tabVisible !== 'Praemienkonto'">
        <mp-core-ptcpnt-cockpit-account></mp-core-ptcpnt-cockpit-account>
        <div class="clear"></div>
      </div>
      <div *ngIf="hasStatus" id="tabStatusKonto" [hidden]="!hasStatus || tabVisible !== 'Statuskonto'">
        <mp-core-ptcpnt-cockpit-status-account></mp-core-ptcpnt-cockpit-status-account>
        <div class="clear"></div>
      </div>
      <div id="tabBestellungen" [hidden]="tabVisible !== 'Bestellungen'">
        <mp-core-ptcpnt-cockpit-orders></mp-core-ptcpnt-cockpit-orders>
        <div class="clear"></div>
      </div>
      <div id="tabStatusKonto" *ngIf="isAdmin" [hidden]="isAdmin && tabVisible !== 'ManuelleBestellung'">
        <mp-core-ptcpnt-cockpit-manually-order></mp-core-ptcpnt-cockpit-manually-order>
        <div class="clear"></div>
      </div>
      <div class="margin-top-24">
        <button class="mp-button" (click)="close()">{{ ls.locs['loc'].Zurueck }}</button>
        <button *ngIf="canCurrentUserEdit" class="mp-button _prim margin-left-8" [hidden]="!participantCockpitService.saveFuncs[tabVisible]" (click)="participantCockpitService.saveFuncs[tabVisible]()">{{ ls.locs['loc'].Speichern }}</button>
      </div>
    </div>
  </div>
</div>
