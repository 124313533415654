import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

interface SettingsData extends Record<string, any> {
  [key: string]: any
}

/**
 * This class provides the functions to fetch the
 * settings from the backend api.
 */
@Injectable({
  providedIn: 'root'
})
export class MpSettingsService {

  private _settingsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public settings: SettingsData = {};
  public settingsLoaded: Observable<boolean> = this._settingsLoaded.asObservable();

  constructor(private _apiService: ApiService) { }

  /**
   * Calls the api to get the settings data.
   */
  getSettings(): void {
    this._apiService.getRequest('/api/Settings/GetFrontendSettings/').subscribe((data: any) => {
      data.Records.forEach((record: any) => {
        this.settings = Object.assign(this.settings, record);
      });

      this._settingsLoaded.next(true);
    },
    (error: any) => {
      console.log(error.message);
    });
  }
}
