import { Component, OnInit, Injector } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';
import { ParentTableRow, ParentTableRowIndex } from '@core/components/data-table/table-injectors';

/**
 * This class provides the data and
 * functions for the winners
 * overview table.
 */
@Component({
  selector: 'mp-gk-winners-overview',
  templateUrl: './winners-overview.component.html'
})
export class WinnersOverviewComponent implements OnInit {

  public loader: MpLoader | null = null;
  public loaderName = '';

  private _winnersOverviewStatisticLoader: MpLoader = {
    name: 'winnersOverviewStatisticLoader',
    isFirst: false,
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoader: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  /**
   * Sets the loader for the table data,
   * and gets the key from the parent data
   * table.
   */
  ngOnInit(): void {
    this._winnersOverviewStatisticLoader.name = `${this._winnersOverviewStatisticLoader.name}--${this._parentTableRowIndex}`;
    this._mpLoader.registerLoader(this._winnersOverviewStatisticLoader);
    let params = {};

    if (this._parentTableRow !== null) {
      params = {
        // @ts-ignore
        key: this._parentTableRow['ExpandableKey']
      };
    }

    this._mpLoader.extendLoader(this._winnersOverviewStatisticLoader.name, {
      isReady: true,
      params: params
    });

    this.loaderName = this._winnersOverviewStatisticLoader.name;
    this.loader = this._mpLoader.getLoader(this._winnersOverviewStatisticLoader.name);    

    setTimeout(() => {
      if (this.loader !== null && typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }, 400);

    this._titleService.setTitleFromLoc('GewinnkalenderGewinnerStatistik', 'locGewinnkalender');
    this._mpMenuHighlight.setMenuHighlight('statistiken');
  }

}
