import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTcPermissionsService } from './../../services/mp-tc-permissions.service';

import { TcPermissions } from './../../services/interfaces/tc-permissions';

/**
 * This class provides the functions
 * for the rental card.
 */
@Component({
  selector: 'mp-rk-rental-card',
  templateUrl: './rental-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RentalCardComponent implements OnInit {

  @Input() public rental: { [key: string]: any } = {};
  @Input() public gearBox: { [key: string]: any } = {};
  @Input() public filteredRentals: Array<any> = [];
  @Input() public locations: Array<any> = [];
  @Input() public grouping: { [key: string]: any } = {};
  @Input() public setOpenedOffersFunc: any;
  @Input() public goToCheckFunc: any;
  @Input() public rentalIndex: number = 0;
  @Input() public otherDropOffLocation: boolean = false;

  public permissions: TcPermissions = {
    CanUseReisekonfigurator: false
  };

  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _permissionService: MpTcPermissionsService
  ) { }

  /**
   * Gets the travel configurator permissions.
   */
  ngOnInit(): void {
    if (typeof this.rental['open'] === 'undefined' || this.rental['open'] === null) {
      this.rental['open'] = false;
    }

    if (this._permissionService.permissionsReadyLoaded) {
      this.permissions = this._permissionService.permissions;

      if (typeof this._getPermissionsSubscription !== 'undefined') {
        this._getPermissionsSubscription.unsubscribe();
      }
    } else {
      this._getPermissionsSubscription = this._permissionService.permissionsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.permissions = this._permissionService.permissions;

          if (typeof this._getPermissionsSubscription !== 'undefined') {
            this._getPermissionsSubscription.unsubscribe();
          }
        }
      });

      this._permissionService.getPermissions();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the opened offers.
   */
  setOpenedOffers(): void {
    if (typeof this.setOpenedOffersFunc !== 'undefined') {
      this.setOpenedOffersFunc();
    }
  }

  /**
   * Triggers the goToCheck of the
   * parent.
   */
  goToCheck(rental: any): void {
    if (typeof this.goToCheckFunc !== 'undefined') {
      this.goToCheckFunc(rental);
    }
  }

}
