import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { OwlOptions } from 'ngx-owl-carousel-o';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';
import { MpWishlistService } from './../../../../modules/participant/pages/wishlist/mp-wishlist.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpStatusService } from './../../../../services/mp-status.service';
import { ImageCarouselService } from './../../../../components/image-video-carousel/image-carousel.service';
import { MpShoppingBasketService } from './../shopping-basket/mp-shopping-basket.service';
import { MpAnalyticsService } from './../../../../services/mp-analytics.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';
import { MpCoreService } from './../../../../services/mp-core.service';

import { Tile } from './../../../../components/card/shop-card/tile';

@Component({
  selector: 'mp-core-award-details',
  templateUrl: './award-details.component.html',
  styleUrls: ['./award-details.component.scss']
})
export class AwardDetailsComponent implements OnInit, OnDestroy {

  public artNr: string = '';
  public pin: string = '';
  public wlClicked: boolean = false;
  public wlLoaded: boolean = false;
  public lightGreenText: string | undefined;
  public lightYellowText: string | undefined;
  public land: any | undefined;
  public canOrder: boolean = false;
  public availableAmount: number = -1;
  public artlabel1: string = '';
  public artlabel2: string = '';
  public producer: string = '';
  public brandImagePath: string = '';
  public description: string = '';
  public showNonMonetaryBonusNotice: boolean = false;
  public links: string = '';
  public downloadLink: string = '';
  public showLinks: boolean = false;
  public showPropertiesList: boolean = false;
  public propertiesList: Array<any> = [];
  public properties: string = '';
  public showTeaser: boolean = false;
  public teaser: string = '';
  public percentageDiscount: number | undefined;
  public range: Array<any> = [];
  public characteristics: any;
  public dropDownItems: Array<any> = [];
  public selectedCharacteristic: Array<any> = [];
  public showExpress: boolean = false;
  public express: boolean = false;
  public points: number | undefined;
  public comparePoints: number | undefined;
  public showDiliveryNotice: boolean = false;
  public diliveryNotice: string = '';
  public availability: number | undefined;
  public limitedAmount: boolean = false;
  public artNrSupplier: string = '';
  public statusID: number | undefined;
  public tooltipText: string = '';
  public showCompatibleArticles: boolean = false;
  public hasStatus: boolean = false;
  public compatibleArticlesOwlProperties: OwlOptions = {};
  public compatibleArticles: Array<any> = [];
  public showAdditionalArticles: boolean = false;
  public additionalArticlesOwlProperties: OwlOptions = {};
  public additionalArticles: Array<any> = [];
  public owlWidth: number = 308;

  public count = {
    value: 1
  };

  private _loadSubscription: Subscription | undefined;
  private _loadCompatibleArticlesSubscription: Subscription | undefined;
  private _loadAdditionalArticlesSubscription: Subscription | undefined;
  private _loadCharacteristicSubscription: Subscription | undefined;
  private _queryParamsSubscription: Subscription | undefined;
  private _wishlistSubscription: Subscription | undefined;
  private _landSubscription: Subscription | undefined;
  private _lightTextsSubscription: Subscription | undefined;
  private _shopHeaderSubscription: Subscription | undefined;
  private _expressPointsSubscription: Subscription | undefined;
  private _pointsAfterOrderSubscription: Subscription | undefined;
  private _pinSubscription: Subscription | undefined;
  private _statusSubscription: Subscription | undefined;
  private _wl: Array<Tile> | undefined;
  private _searchTerm: string = '';
  private _codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
  private _expressPoints: number = 0;
  private _hasAdditional: boolean = false;
  private _dropDownTemplateName: string = 'AuspraegungDropDown';
  private _getLocsSubscription: Subscription | undefined;

  private _routeParams = {
    k: '',
    s: '',
    m: ''
  }

  private _owlProperties: OwlOptions = {
    nav: true,
    margin: 16,
    dots: false,
    navText: ['<', '>'],
    responsive: {
      0: {
        autoWidth: false,
        items: 1,
        nav: true,
        loop: true
      },
      551: {
        autoWidth: false,
        items: 2,
        nav: true,
        loop: true
      },
      943: {
        autoWidth: false,
        items: 3,
        nav: true,
        loop: false
      },
      1260: {
        autoWidth: true,
        items: 4,
        nav: true,
        loop: false
      }
    }
  };

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpWishlist: MpWishlistService,
    private _titleService: MpTitleService,
    private _mpStatus: MpStatusService,
    private _imageCarouselService: ImageCarouselService,
    private _route: ActivatedRoute,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpAnalytics: MpAnalyticsService,
    private _mpCoreService: MpCoreService
  ) { }

  /**
   * Angulars init function. Subscribes to
   * different requests, to get the needed
   * data for the award details.
   * Subscribes to change of parameters
   * and triggers functions to get
   * additional data for the details page.
   */
  ngOnInit(): void {
    //mpMenuHighlight.setMenuHighlight("praemien");

    this._mpWishlist.getWishlist();

    if (this._mpWishlist.loaded) {
      this._wl = this._mpWishlist.wishlist;
      this.wlLoaded = true;
    } else {
      this._wishlistSubscription = this._mpWishlist.wishlistLoaded.subscribe(() => {
        this._wl = this._mpWishlist.wishlist;
        this.wlLoaded = true;
      });
    }

    if (typeof this._mpStatus.currentHasStatus !== 'undefined') {
      this.hasStatus = this._mpStatus.currentHasStatus;
    } else {
      this._statusSubscription = this._mpStatus.status.subscribe((status: boolean) => {
        this.hasStatus = status;
      });

      this._mpStatus.hasStatus();
    }

    this._landSubscription = this._apiService.getRequest('/api/Teilnehmer/GetTnLand').subscribe((data: any) => {
      this.land = data.Records[0];
    });

    this._lightTextsSubscription = this._apiService.getRequest('/api/Artikel/GetAmpelTexte').subscribe((data: any) => {
      const lightTexts = data.Records[0];
      this.lightGreenText = lightTexts['AmpelGruenText'];
      this.lightYellowText = lightTexts['AmpelGelbText'];
    });

    this._pinSubscription = this._imageCarouselService.pinObserver.subscribe((pin: string) => {
      if (typeof this.pin !== 'undefined' && this.pin !== '' && this.pin !== pin) {
        this.pin = pin;
        this._selectCharacteristic(`${this.artNr} - ${pin}`);
      } else if (typeof this.pin !== 'undefined') {
        this.pin = pin;
      }
    });

    this._queryParamsSubscription = this._route.queryParamMap.subscribe((params: any) => {
      if (this.artNr !== '') {
        this._mpCoreService.reloadComponent();
      }

      this.pin = '';
      this._imageCarouselService.pin = '';
      this.artNr = params.get('art');
      const paramPin = params.get('pin');
      const paramK = params.get('k');
      const paramS = params.get('s');
      const paramM = params.get('m');

      if (paramPin !== null && paramPin !== '') {
        this.pin = paramPin;
      }

      if (paramK !== null && paramK !== '') {
        this._routeParams.k = paramK;
      }

      if (paramS !== null && paramS !== '') {
        this._routeParams.s = paramS;
      }

      if (paramM !== null && paramM !== '') {
        this._routeParams.m = paramM;
      }

      if (Object.keys(this.ls.locs).length > 0) {
        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }

        this._getShopHeaderAndBreadcrumb();
      } else {
        this.ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            if (typeof this._getLocsSubscription !== 'undefined') {
              this._getLocsSubscription.unsubscribe();
            }

            this._getShopHeaderAndBreadcrumb();
          }
        });

        this.ls.getLocalization();
      }

      this._load();
    });
  }

  /**
   * Unsubscribes all set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._loadSubscription !== 'undefined') {
      this._loadSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._loadCompatibleArticlesSubscription !== 'undefined') {
      this._loadCompatibleArticlesSubscription.unsubscribe();
    }

    if (typeof this._loadAdditionalArticlesSubscription !== 'undefined') {
      this._loadAdditionalArticlesSubscription.unsubscribe();
    }

    if (typeof this._loadCharacteristicSubscription !== 'undefined') {
      this._loadCharacteristicSubscription.unsubscribe();
    }

    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._wishlistSubscription !== 'undefined') {
      this._wishlistSubscription.unsubscribe();
    }

    if (typeof this._landSubscription !== 'undefined') {
      this._landSubscription.unsubscribe();
    }

    if (typeof this._lightTextsSubscription !== 'undefined') {
      this._lightTextsSubscription.unsubscribe();
    }

    if (typeof this._shopHeaderSubscription !== 'undefined') {
      this._shopHeaderSubscription.unsubscribe();
    }

    if (typeof this._expressPointsSubscription !== 'undefined') {
      this._expressPointsSubscription.unsubscribe();
    }

    if (typeof this._pointsAfterOrderSubscription !== 'undefined') {
      this._pointsAfterOrderSubscription.unsubscribe();
    }

    if (typeof this._pinSubscription !== 'undefined') {
      this._pinSubscription.unsubscribe();
    }

    if (typeof this._statusSubscription !== 'undefined') {
      this._statusSubscription.unsubscribe();
    }
  }

  /**
   * Gets the shop header data and creates
   * gets all needed data to set the
   * breadcrumbs.
   */
  private _getShopHeaderAndBreadcrumb() {
    const site = {
      Path: 'PraemienDetails?art=' + this.artNr + '&k=' + this._routeParams.k + '&s=' + this._routeParams.s + '&m=' + this._routeParams.m,
      Loc: this.ls.locs['loc'].Praemiendetails
    };

    if ((this._routeParams.k && this._routeParams.k !== '0') || this._routeParams.s || this._routeParams.m) {
      this._shopHeaderSubscription = this._apiService.getRequest('/api/Kategorien/getShopHeader/' + (this._routeParams.k || '0') + '?suchbegriff=' + this._codec.encodeValue(this._routeParams.s) + '&markenname=' + this._codec.encodeValue(this._routeParams.m)).subscribe((data: any) => {
        if (data && data.Records.length == 1 && data.Records[0]) {
          const header = data.Records[0];

          var steps = [{
            Path: 'Shop' + this._getLinkparameter(this._routeParams.k, this._routeParams.s, this._routeParams.m),
            Loc: header.Titel
          }];

          this._setBreadcrumb(site, steps);
        } else {
          this._setBreadcrumb(site);
        }
      },
      (error: any) => {
        this._setBreadcrumb(site);
      });
    } else {
      this._setBreadcrumb(site);
    }
  }

  /**
   * Creates the link parameter by the
   * given parameter parts.
   */
  private _getLinkparameter(k: string, s: string, m: string): string {
    var params = '';
    if (k && k !== '0') {
      params += '/' + k;
    }
    if (s) {
      params += '?s=' + this._codec.encodeValue(s);
    } else if (m) {
      params += '?m=' + this._codec.encodeValue(m);
    }
    return params;
  }

  /**
   * Loads the articles data and the
   * points for express service.
   */
  private _load(): void {
    this._loadSubscription = this._apiService.getRequest(`/api/Artikel/getArtikel/${this.artNr}`).subscribe((data: any) => {
      if (data && data.Records.length == 1 && data.Records[0]) {
        var article = data.Records[0];

        this.artlabel1 = article.ArtBez1;
        this.artlabel2 = article.ArtBez2;
        this.producer = article.HerstellerName;
        this.brandImagePath = article.HerstellerLogoPath;
        this.description = article.Beschreibung;
        this.showNonMonetaryBonusNotice = article.ShowSachpraemienHinweis;

        this._titleService.setTitle(`${this.artlabel1} ${this.artlabel2}`);

        this.links = '<a href="' + article.Links + '">' + article.Links + '</a>';
        this.downloadLink = article.Links;
        this.showLinks = (article.Links && article.Links != '' && article.Links != undefined);

        if (article.EigenschaftenList && article.EigenschaftenList.length > 0) {
          this.showPropertiesList = true;
          this.propertiesList = article.EigenschaftenList;
        } else {
          this.showPropertiesList = false;
          this.properties = article.Eigenschaften;
        }

        if (article.Teaser) {
          this.showTeaser = true;
          this.teaser = article.Teaser;
        }

        this._loadCompatibleArticles();
        this._loadAdditionalArticles();
        this._loadCharacteristic();
      }
    },
    (error: any) => {
      console.log(error.message);
    });

    this._expressPointsSubscription = this._apiService.getRequest('/api/Artikel/getExpressPunkte').subscribe((data: any) => {
      if (data && data.Records.length == 1) {
        this._expressPoints = parseInt(data.Records[0]);
      }
    });
  }

  /**
   * Loads compatible articles for the
   * slider at the bottom of the details
   * page.
   */
  private _loadCompatibleArticles(): void {
    this._loadCompatibleArticlesSubscription = this._apiService.getRequest(`/api/Artikel/getPassendeArtikel/${this.artNr}`).subscribe((data: any) => {
      if (data) {
        this.showCompatibleArticles = data.Records.length > 0;
        this.compatibleArticlesOwlProperties = this._owlProperties;

        if (typeof this.compatibleArticlesOwlProperties.responsive !== 'undefined') {
          this.compatibleArticlesOwlProperties.responsive[551].loop = (data.Records.length > 1 ? true : false);
        }

        this.compatibleArticles = data.Records;
      }
    });
  }

  /**
   * Loads additional articles for the
   * slider at the bottom of the details
   * page.
   */
  private _loadAdditionalArticles(): void {
    this._loadAdditionalArticlesSubscription = this._apiService.getRequest(`/api/Artikel/getWeitereArtikel/${this.artNr}`).subscribe((data: any) => {
      if (data) {
        this.showAdditionalArticles = data.Records.length > 0;
        this.additionalArticlesOwlProperties = this._owlProperties;

        if (typeof this.additionalArticlesOwlProperties.responsive !== 'undefined') {
          this.additionalArticlesOwlProperties.responsive[551].loop = (data.Records.length > 1 ? true : false);
        }

        this.additionalArticles = data.Records;
      }
    });
  }

  /**
   * Loads all characteristics of the
   * aricle, and provides the information
   * to the view.
   */
  private _loadCharacteristic(): void {
    this._loadCharacteristicSubscription = this._apiService.getRequest(`/api/Artikel/getAuspraegungen/${this.artNr}`).subscribe((data: any) => {
      if (data && data.Records[0]) {
        this.pin = data.Records[0].PIN;
        
        if (typeof this.pin !== 'undefined' || this.pin !== '') {
          const characteristic = data.Records.find((characteristicItem: any) => {
            return characteristicItem.PIN === this.pin;
          });

          this.canOrder = characteristic.CanOrder;
          this._hasAdditional = characteristic.HasZusatz;
          this.percentageDiscount = characteristic.ProzentRabatt;
          this.range = characteristic.Range;
          this.availableAmount = characteristic.VerfuegbareMenge;
        }
      }

      if (data && typeof this.pin !== 'undefined') {
        this.characteristics = data.Records;

        const selectedCharacteristic = this.characteristics.find((characteristic: any) => {
          return characteristic.ArtNr === this.artNr.toUpperCase() && (typeof this.pin === 'undefined' || typeof this.pin !== 'undefined' && characteristic.PIN === this.pin);
        });

        let characteristicsArrays: Array<any> = [];
        const characteristicsMapped = this.characteristics.map((charac: any) => { return charac.Eigenschaften; });
        let caracsLength = 0;

        characteristicsMapped.forEach((carac: any) => {
          if (carac.length > caracsLength) {
            caracsLength = carac.length;
          }
        });

        for (let idx = 0; idx < caracsLength; idx++) {
          characteristicsArrays[idx] = characteristicsMapped.map((charac: any) => {
            if (typeof charac[idx] === 'undefined') {
              return false;
            }

            if (charac[idx].charAt(0) === ' ') {
              charac[idx].replace(' ', '');
            }

            return charac[idx];
          });
        }

        if (this.characteristics[0]) {
          this.dropDownItems = characteristicsArrays.map((array: Array<any>, index: number) => {
            let dropDown = {
              name: `${this._dropDownTemplateName}${index.toString()}`,
              dropDownIndex: index,
              values: [...new Set(characteristicsArrays[index])]
            };

            if (typeof selectedCharacteristic !== 'undefined') {
              if (selectedCharacteristic !== null) {
                dropDown = Object.assign(dropDown, { selectedValue: selectedCharacteristic.Eigenschaften[index] });
              }
            }

            return dropDown;
          });
        }

        if (sessionStorage.getItem('artNrPIN'))
          this._selectCharacteristic(sessionStorage.getItem('artNrPIN'));
        else
          this._selectCharacteristic(selectedCharacteristic.ArtNrPIN);
      }
    });
  }

  /**
   * Handles selection of a characteristic.
   */
  private _selectCharacteristic(artNrPin: string | null): void {
    if (artNrPin === null || typeof this.characteristics === 'undefined') {
      return;
    }

    let characteristic = this.characteristics.find((characteristic: any) => {
      return characteristic.ArtNrPIN === artNrPin;
    });

    if (typeof characteristic === 'undefined' || typeof characteristic !== 'undefined' && characteristic === null) {
      characteristic = this.characteristics[0];
    }

    this.selectedCharacteristic = characteristic.Eigenschaften.map((charac: any) => { return charac; });
    this.showExpress = characteristic.CanExpress;

    if (this.express && !this.showExpress) {
      this.express = false;
    }

    this.points = characteristic.Punkte;
    this.comparePoints = characteristic.Vergleichspreis;
    this.showDiliveryNotice = characteristic.ShowLieferhinweis;
    this.diliveryNotice = characteristic.Lieferhinweis;
    this.availability = characteristic.VerfuegbarkeitID; 
    this.limitedAmount = characteristic.BegrenzteMenge;
    this.pin = characteristic.PIN;
    this.canOrder = characteristic.CanOrder;
    this._hasAdditional = characteristic.HasZusatz;
    this.percentageDiscount = characteristic.ProzentRabatt;
    this.artNrSupplier = characteristic.ArtNrLieferant;
    this.statusID = characteristic.StatusID;

    this.characteristics.forEach((charac: any) => {
      charac.selected = false;
    });

    characteristic.selected = true;

    this._pointsAfterOrderSubscription = this._apiService.getRequest(`/api/Artikel/getPunktestandAfterBestellung/${this._getPoints().toString()}`).subscribe((data: any) => {
      if (data && data.Records.length == 1 && data.Records[0]) {
        this.tooltipText = data.Records[0];
      }
    });

    this._imageCarouselService.setPin(characteristic.PIN);

    this.count.value = 1;
  }

  /**
   * Calculates points plus express
   * points.
   */
  private _getPoints(): number {
    let result = this.points;

    if (this.express && result)
      result = result + this._expressPoints;

    // @ts-ignore
    return result;
  }

  /**
   * Returns the tooltip text.
   */
  getTooltipText(): string {
    if (this.tooltipText !== '') {
      return this.tooltipText;
    }

    return '';
  }

  /**
   * Triggers the setting of the
   * breacrumbs.
   */
  private _setBreadcrumb(site: any, steps?: Array<object>): void {
    this._breadcrumbService.setCurrentSite(site, steps);
  }

  /**
   * Checks whether or not the article
   * of the tile is in the wish list.
   */
  isInWishlist(): boolean {
    if (typeof this._wl !== 'undefined') {
      const inWl = this._wl.find(wlItem => {
        // @ts-ignore
        return wlItem.ArtNr === this.artNr;
      });

      if (typeof inWl !== 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Gets the item from the wish list.
   */
  private _getWlItem(): any {
    // @ts-ignore
    return this._wl.find(wlItem => {
      // @ts-ignore
      return wlItem.ArtNr === this.artNr;
    });
  }

  /**
   * Adds / removes the item to / from
   * the wish list.
   */

  toggleWishlist(evt: MouseEvent): void {
    evt.preventDefault();

    this.wlClicked = true;

    setTimeout(() => {
      this.wlClicked = false;
    }, 650);

    if (this.isInWishlist()) {
      const wlItem = this._getWlItem();
      this._mpWishlist.deleteItem(wlItem.ID, (wl: Array<Tile>) => {
        this._wl = wl;
      });
    } else {
      this._mpWishlist.addItem(this.artNr, (typeof this.pin !== 'undefined' ? this.pin : ''), (wl: Array<Tile>) => {
        this._wl = wl;
      });
    }
  }

  /**
   * Adds the article to the shopping
   * basket.
   */
  addToShoppingbasket(): void {
    if (this.canOrder) {
      // @ts-ignore
      this._mpShoppingBasket.addItem(this.artNr, this.pin, this.express, this.count.value, this._hasAdditional);
    }
  }

  /**
   * Sets the values for the selected
   * item within the characteristics
   * dropdown.
   */
  setDropDownSelectedValue(index: number): void {
    let selectedCharacteristic = this.characteristics.find((characteristic: any) => {
      return JSON.stringify(characteristic.Eigenschaften) === JSON.stringify(this.selectedCharacteristic);
    });

    if (typeof selectedCharacteristic === 'undefined' || typeof selectedCharacteristic !== 'undefined' && selectedCharacteristic === null) {
      selectedCharacteristic = this.characteristics.find((characteristic: any) => {
        return characteristic.Eigenschaften[index] === this.selectedCharacteristic[index];
      });
    }

    if (typeof selectedCharacteristic !== 'undefined') {
      if (selectedCharacteristic !== null) {
        this._selectCharacteristic(selectedCharacteristic.ArtNrPIN);
        sessionStorage.setItem('artNrPIN', selectedCharacteristic.ArtNrPIN);
      }
    }
  }

  /**
   * Fires the track event of the analytics
   * service.
   */
  trackEvent(category: string, action: string, name: string, value?: string | number): void {
    this._mpAnalytics.trackEvent(category, action, name, value);
  }

}
