import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This class provides a service to open and
 * close sidebars.
 */
@Injectable({
  providedIn: 'root'
})
export class MpSidebarService {

  public visible: boolean = false;
  public contentComponent: string = '';
  private _closeable: boolean = false;
  public params: { [key: string]: any } = {};
  private _paramsChange: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public paramsChange: Observable<any> = this._paramsChange.asObservable();
  private _toggleSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toggleSidebar: Observable<boolean> = this._toggleSidebar.asObservable();

  constructor() { }

  /**
   * Closes the sidebar and resets the
   * classes variables.
   */
  close(): void {
    if (this._closeable) {
      this.visible = false;
      const sidebarGrid: HTMLDivElement | null = document.querySelector('[mpcoresidebar] .sidebar-grid');

      if (sidebarGrid !== null) {
        sidebarGrid.style.height = '';
      }

      this.contentComponent = '';
      this.params = {};
      this._paramsChange.next(this.params);
      this._closeable = false;

      const sidebarElement = document.getElementById('sidebar');

      if (sidebarElement && sidebarElement.classList.contains('_postfach-sidebar')) {
        sidebarElement.classList.remove('_postfach-sidebar');
      }

      const body = document.body;

      if (body !== null) {
        if (body.classList.contains('openedSidebar')) {
          body.classList.remove('openedSidebar');
        }
      }

      this._toggleSidebar.next(false);
    }
  }

  /**
   * Opens the sidebar.
   */
  private _show(): void {
    this._toggleSidebar.next(true);
    this.visible = true;

    setTimeout(() => {
      this._closeable = true;
      let sidebarGrid: HTMLDivElement | null = document.querySelector('[mpcoresidebar] .sidebar-grid');
      let overlayButtons = sidebarGrid !== null ? sidebarGrid.nextElementSibling : null;

      if (sidebarGrid !== null && overlayButtons !== null) {
        sidebarGrid.style.height = `calc(100% - ${overlayButtons.getBoundingClientRect().height}px)`;
      } else {
        let intvalCnt = 0;

        const getElemsInterval = setInterval(() => {
          sidebarGrid = document.querySelector('[mpcoresidebar] .sidebar-grid');
          overlayButtons = sidebarGrid !== null ? sidebarGrid.nextElementSibling : null;
          intvalCnt++;

          if (sidebarGrid !== null && overlayButtons !== null) {
            sidebarGrid.style.height = `calc(100% - ${overlayButtons.getBoundingClientRect().height}px)`;
          }

          if (sidebarGrid !== null && overlayButtons !== null || intvalCnt >= 10) {
            clearInterval(getElemsInterval);
          }
        }, 50);
      }

      const body = document.body;

      if (body !== null) {
        if (!body.classList.contains('openedSidebar')) {
          body.classList.add('openedSidebar');
        }
      }
    }, 200);
  }

  /**
   * Triggeers the openeing of the sidebar
   * and sets the component to include
   * (e.g. for password reset sidebar).
   */
  open(contentComponent: string, params: object): void {
    this.contentComponent = contentComponent;
    this.params = params;
    this._show();
  }

  /**
   * Triggers the change of sidebar params.
   */
  changeParams(params: any) {
    this._paramsChange.next(params);
  }
}
