import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, LOCALE_ID, Inject, ViewChild, ElementRef } from '@angular/core';
import dayjs from "dayjs";
import { Options } from 'angular-datetimerangepicker';

/**
* This class provides a datepicker with a
* calendar to select a date.
*/
@Component({
  selector: 'mp-core-date-picker',
  templateUrl: './mp-date-picker.component.html',
  styleUrls: ['./mp-date-picker.component.scss']
})

export class MpDatePickerComponent implements OnInit {

  public daterangepickerOptions: Options = {
    addTouchSupport: true,
    showRanges: false,
    hideControls: true,
    singleCalendar: true,
    disableWeekEnds: false,
    disabledDays: [],
    disabledDates: [],
    format: 'DD.MM.YYYY',
    minDate: dayjs('01.01.1970')
  };

  @Input() public model: any;
  @Input() public rangeType: string = '';
  @Input() public label: string = '';
  @Input() public mpId: string = '';
  @Input() public mpType: string = '';
  @Input() public mpEnter: any;
  @Input() public minDate: Date | undefined;
  @Input() public maxDate: Date | undefined;
  @Input() public hasError: boolean = false;
  @Input() public initialModel: any = undefined;

  @Output() enterEvent = new EventEmitter<any>();
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pickerElem') pickerElem: ElementRef<any> | undefined;

  public errClass: string | undefined;

  private _flyout: HTMLDivElement | null = null;
  private _pickerElemNative: any = null;
  private _modalElem: HTMLDivElement | null = null;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  /**
  * Initializes date one year ago.
  * Sets locale and options of datepicker.
  */
  ngOnInit(): void {

    let initDate = dayjs();

    if (this.rangeType !== '') {
      if (this.rangeType === 'from') {
        initDate = dayjs().subtract(1, 'year');
      }
    }

    switch (this.locale) {
    case 'de_DE':
      this.daterangepickerOptions.displayFormat = 'DD.MM.YYYY';
        break;
    case 'en_GB':
      this.daterangepickerOptions.displayFormat = 'DD/MM/YYYY';
        break;
    case 'ee_ES':
      this.daterangepickerOptions.displayFormat = 'DD/MM/YYYY';
        break;
    case 'fr_FR':
      this.daterangepickerOptions.displayFormat = 'DD/MM/YYYY';
        break;
    case 'it_IT':
      this.daterangepickerOptions.displayFormat = 'DD/MM/YYYY';
        break;
    case 'pt_PT':
      this.daterangepickerOptions.displayFormat = 'DD-MM-YYYY';
        break;
    case 'ru_RU':
      this.daterangepickerOptions.displayFormat = 'DD.MM.YYYY';
      break;
    default:
        this.daterangepickerOptions.displayFormat = 'DD.MM.YYYY';
      break;
    }

    this.daterangepickerOptions = {
      ...this.daterangepickerOptions,
      startDate: initDate
    };

    if (typeof this.minDate !== 'undefined') {
      this.daterangepickerOptions = {
        ...this.daterangepickerOptions,
        minDate: dayjs(this.minDate)
      };
    }

    if (typeof this.maxDate !== 'undefined') {
      this.daterangepickerOptions = {
        ...this.daterangepickerOptions,
        maxDate: dayjs(this.maxDate)
      };
    }

    if (this.rangeType === 'to') {
      this.modelChange.emit(initDate.hour(0).minute(0).add(23, 'hour').add(59, 'minute'));
    } else {
      this.modelChange.emit(initDate);
    }

    this.errClass = '';

    if (this.hasError) {
      this.errClass = 'invalid-error';
    } else {
      this.errClass = '';
    }

    if (typeof this.initialModel !== 'undefined') {
      initDate = dayjs(this.initialModel);
      this.modelChange.emit(initDate);
    }

  }

  /**
   * Passes start date and end date to parent component.
   */
  rangeSelected(evt: any): void {

    if (this.rangeType === 'to') {
      this.modelChange.emit(new Date(evt.start.$d.setHours(23, 59)));
    } else {
      this.modelChange.emit(evt.start.$d);
    }
  }

  /**
  * A lifecycle hook that is called when a data-bound property of a hasError and model.
  */
  ngOnChanges(changes: SimpleChanges): void {

    if (typeof this.hasError !== 'undefined' && typeof changes['hasError'] !== 'undefined') {
      if (changes['hasError'].currentValue !== changes['hasError'].previousValue) {
        if (this.hasError) {
          this.errClass = 'invalid-error';
        } else {
          this.errClass = '';
        }

      }
    }

    if (typeof this.model !== 'undefined' && typeof changes['model'] !== 'undefined') {
      if (changes['model'].currentValue !== changes['model'].previousValue) {
        this.daterangepickerOptions = {
          ...this.daterangepickerOptions,
          startDate: dayjs(this.model)
        };
      }
    }
  }
  /**
   * Executes tap from enter key.
   */
  executeMpEnter(event: any) {
    if (event.keyCode === 13 && this.mpEnter) {
      this.enterEvent.emit(this.mpEnter);
    }
  };

  /**
   * Calculates the picker position, if the
   * picker is within a modal.
   */
  calculatePosition(): void {
    if (this._flyout !== null && typeof this._pickerElemNative !== 'undefined' && this._pickerElemNative !== null && this._modalElem !== null) {
      this._doCalculationOfPickerPost();
    } else if (typeof this.pickerElem !== 'undefined') {
      // @ts-ignore
      this._pickerElemNative = this.pickerElem.elem.nativeElement;
      this._flyout = this._pickerElemNative.querySelector('.drp-flyout');

      if (this._flyout !== null) {
        let hasModal = false;
        this._modalElem = null;
        let pickerElemNativeParent = this._pickerElemNative.parentElement;

        while (!hasModal && pickerElemNativeParent !== document.body) {
          pickerElemNativeParent = pickerElemNativeParent.parentElement;

          if (pickerElemNativeParent.classList.contains('magma-modal')) {
            hasModal = true;
            this._modalElem = pickerElemNativeParent;
          }
        }

        if (hasModal) {
          this._doCalculationOfPickerPost();
          window.addEventListener('scroll', this.calculatePosition.bind(this));
          window.addEventListener('resize', this.calculatePosition.bind(this));
        }
      }
    }
  }

  /**
   * Does the calculation of the
   * picker position.
   */
  private _doCalculationOfPickerPost(): void {
    if (this._flyout !== null && typeof this._pickerElemNative !== 'undefined' && this._pickerElemNative !== null && this._modalElem !== null) {
      this._flyout.style.position = 'fixed';
      this._flyout.style.left = '16px';
      // @ts-ignore
      this._flyout.style.top = this._flyout.previousElementSibling.getBoundingClientRect().top + this._flyout.previousElementSibling.getBoundingClientRect().height + 10 - (this._modalElem.getBoundingClientRect().top) + 'px';
    }
  }

}
