import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides the inbox filter sidebar.
 */
@Component({
  selector: 'mp-core-sidebar-inbox',
  templateUrl: './sidebar-inbox.component.html',
  styleUrls: ['./sidebar-inbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarInboxComponent implements OnInit {

  public sidebarData: { [key: string]: any } = {};
  public sidebarCategories: any[] = [];
  public sidebarFilterFunction: Function = () => { };
  public sidebarCloseFunction: Function = () => { };
  public sidebarSearchFunction: Function = () => { };

  constructor(
    public ls: MpLocalizationService,
    private _sidebar: MpSidebarService
  ) { }

  /**
   * Assigns the given sidebar parameters
   * to component variables.
   */
  ngOnInit(): void {
    this.sidebarData = this._sidebar.params['sidebarData'];
    this.sidebarCategories = this._sidebar.params['sidebarCategories'];
    this.sidebarFilterFunction = this._sidebar.params['sidebarFilterFunction'];
    this.sidebarCloseFunction = this._sidebar.params['sidebarCloseFunction'];
    this.sidebarSearchFunction = this._sidebar.params['sidebarSearchFunction'];
  }

  /**
   * Clears the search of the sidebar and
   * resets the filter.
   */
  clearSearch(event: any): void {
    this.sidebarData['searchTerm'] = '';
    event.currentTarget.parentElement.parentElement.querySelector('input').focus();
    this.sidebarFilterFunction();
  }

}
