<div class="floating-container flex-grid" *ngIf="(ls.locsLoaded | async) && addressesLoaded">
  <mp-core-address-card
    class="flex-col-4 flex-col-6-m flex-col-12-s padding-8 repeat-address"
    *ngFor="let adr of shownTnAddresses; trackBy: trackByIndex"
    [selected]="selectable && adr['AdressID'] === selAddress['AdressID'] && adr['AdressTyp'] === selAddress['AdressTyp']"
    [model]="adr"
    [showDelete]="adr['Editierbar']"
    [showEdit]="adr['Editierbar']"
    hideStd=""
    [stdSelectable]="true"
    [onCardClick]="setSelAddress"
    [ddlData]="ddlData">
  </mp-core-address-card>
  <div *ngIf ="canAddAddress" class="AdresskachelItemWrapper bg-color-transparent flex-col-4 flex-col-6-m flex-col-12-s padding-8">
    <div class="AdresskachelItem font-weight-light padding-top-bottom-8 padding-left-right-16 width-full display-table overflow-visible position-relative">
      <div class="flex-grid flex-direction-column padding-left-8 padding-right-8 flex-justify-cont-center flex-align-items-center new-shipping-address default-cursor-hover height-full">
        <h3 class="bold">{{ ls.locs['loc'].NeueLieferadresse }}</h3>
        <p>
          {{ ls.locs['loc'].AddNeueAdresse }}
        </p>
        <span class="mp-button _sec" (click)="addAddress($event)">{{ ls.locs['loc'].Hinzufuegen }}</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="addresses.length > 0 && shownTnAddresses.length > 0 && addressesLoaded" class="flex-grid padding-left-right-8">
  <div class="text-transform-uppercase full-width ng-scope bg-color-white padding-8 margin-top-8 flex-col-12 flex-justify-cont-center flex-align-items-center pointer-hover" id="load-more" *ngIf="shownTnAddresses.length < addresses.length" (click)="loadMoreAddresses()">
    {{ ls.locs['loc'].WeitereAdressenLaden }}
  </div>
</div>
