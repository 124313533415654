<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locGamification'].Winterspiele2021 }}</h1>
  <mp-core-data-table title="{{ ls.locs['locGamification'].Winterspiele2021 }}"
                 infotextShorten="Winterspiele2021Statistik"
                 fieldsAction="/api/Statistik/GetWinterspiele2021StatistikFields"
                 listAction="/api/Statistik/GetWinterspiele2021Statistik"
                 loaderName="wintergamesTwentyTwentyoneStatisticLoader"
                 [recLoadedFunc]="recLoadedFunc"
                 [paging]="true"
                 defaultSorting="RangNr ASC">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>

