<div *ngIf="(ls.locsLoaded | async)">
  <mp-core-data-table title=""
                 *ngIf="loaderName !== ''"
                 fieldsAction="/api/TipAndWin/GetSpielstatistikChildFields"
                 listAction="/api/TipAndWin/GetSpielstatistikChild" 
                 loaderName="{{loaderName}}" 
                 [paging]="false" 
                 [footer]="false" 
                 [startEmpty]="true" 
                 defaultSorting="Datum DESC">
  </mp-core-data-table>
</div>
