import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { CalendarService } from '@core/components/calendar/calendar.service';
import { MpSettingsService } from '@core/services/mp-settings.service';

/**
 * This class provides the functionalities
 * for the flilght date / time sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-calendar-flight',
  templateUrl: './sidebar-calendar-flight.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarCalendarFlightComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public maxRange: number = 300;

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public mpSidebar: MpSidebarService,
    private _calendarService: CalendarService
  ) { }

  /**
   * Gets the sidebar parameters.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        rangeStart: this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'],
        rangeEnd: this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd'],
        Flugart: this.selection['Flugart'] || this.mpSidebar.params['Flugart'] || this.mpSidebar.params['flugOptions'][0],
        VarianzHin: this.selection['VarianzHin'] || this.mpSidebar.params['VarianzHin'] || this.mpSidebar.params['reisedauerOptionsHin'][0],
        VarianzRueck: this.selection['VarianzRueck'] || this.mpSidebar.params['VarianzRueck'] || this.mpSidebar.params['reisedauerOptionsZurueck'][0],
        MinTage: this.selection['MinTage'] || this.mpSidebar.params['MinTage'],
        MaxTage: this.selection['MaxTage'] || this.mpSidebar.params['MaxTage']
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }


  /**
   * Saves the selected travel time.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();

    if (!(this.selection['rangeStart'] && this.selection['rangeEnd'])) {
      return;
    }

    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

  /**
   * Changes the current selection to
   * 'start' or 'end'.
   */
  setCurrentSelection(selection: string): void {
    this._calendarService.changeCalendarSelection({ selection: selection });
  }

}
