<div id="{{mpId}}"
     class="{{mpClass}}">
  <div class="menu-item"
       [ngClass]="{'active': item.open && (item.children.length > 0 || (item.hasLogout && showLogout)), 'currently': item.currentRoot, 'hide-on-mobile': !item.ShowOnMobile, 'hide-on-desktop': !item.ShowOnDesktop, 'is-manager-menu-item': item.isManagerMenuItem}"
       *ngFor="let item of menuItems; let Index = index"
       (mouseenter)="toggleHover(mpId, item, true, $event, item.href)"
       (mouseleave)="toggleHover(mpId, item, false, $event)"
       (clickOutside)="close(item)"
       [attr.click-outside-except]="sameMenuSelector">
    <a [href]="item.href || undefined"
       (click)="toggle(item);closeParent(item.href, $event, item);">
      <div class="floating-container display-flex flex-align-items-center flex-justify-content-center">
        <div class="status-icon-background"
             *ngIf="hasStatus && item.hasStatusIcon"
             [hidden]="firstItemType !== '' && firstItemType === 'icon'">
        </div>
        <i class="svg-icon __clr-status-{{item.statusId}} __size-22 __state-round margin-right-4"
           *ngIf="hasStatus && item.hasStatusIcon"
           [hidden]="firstItemType !== '' && firstItemType === 'icon'">
        </i>
        <i class="svg-icon __wide menu-icon"
           [ngClass]="firstItemType === 'icon' ? 'userMenuIcon __clr-specific-3 __person' : '__clr-default ' + item.icon"
           [hidden]="firstItemType !== '' && firstItemType !== 'icon'">
        </i>
        <div class="icon_nav-text menu-item-text"
             [hidden]="firstItemType !== '' && firstItemType !== 'text'"
             [innerHTML]="item.text">
        </div>
        <img class="menu-image"
             [src]="item.image"
             [hidden]="!item.image && (firstItemType || firstItemType !== 'image')" />
        <i class="svg-icon __clr-specific-3 __size-18 __arrow-dropdown"
           *ngIf="(item.children.length > 0 || item.hasLogout) && mpId !== 'main-menu'"
           [hidden]="firstItemType || firstItemType === 'icon'">
        </i>
      </div>
    </a>
    <div *ngIf="(Index === 0)">
      <ng-content></ng-content>
    </div>
    <div class="sub-menu"
         *ngIf="item.subChildren">
      <div class="floating-container">
        <div class="sub-menu-section"
             [hidden]="subItem.children.length <= 0"
             *ngFor="let subItem of item.children">
          <span class="sub-menu-header">{{subItem.text}}</span>
          <a href="#"
             class="sub-menu-item wrap"
             (click)="close(item);closeParent(subSubItem.href, $event, item)"
             [href]="subSubItem.href"
             [innerHTML]="subSubItem.text"
             *ngFor="let subSubItem of subItem.children">
          </a>
        </div>
      </div>
    </div>
    <div class="sub-menu"
         *ngIf="(item.children.length > 0 || (item.hasLogout && showLogout)) && !item.subChildren && mpId !== 'main-menu'">
      <a class="sub-menu-item"
         [href]="subItem.href"
         (click)="close(item);closeParent(subItem.href, $event, item)"
         *ngFor="let subItem of item.children">
        <span *ngIf="!subItem.icon"
              [innerHTML]="subItem.text">
        </span>
        <div *ngIf="subItem.icon"
             class="floating-container">
          <i class="svg-icon __wide menu-icon __clr-default {{subItem.icon}}"></i>
          <div class="menu-item-text"
               [innerHTML]="subItem.text">
          </div>
        </div>
      </a>
      <div *ngIf="(item.children.length > 0 && (item.hasLogout && showLogout))"
           class="menu-divider"
           role="separator">
      </div>
      <mp-core-logout *ngIf="item.hasLogout && showLogout"></mp-core-logout>
    </div>
    <div class="sub-menu"
         *ngIf="item.children.length > 0 && !item.subChildren && mpId === 'main-menu'">
      <div class="floating-container">
        <div class="sub-menu-section">
          <a class="sub-menu-item"
             [href]="subItem.href"
             (click)="close(item);closeParent(subItem.href, $event, item)"
             *ngFor="let subItem of item.children">
            <span *ngIf="!subItem.icon"
                  [innerHTML]="subItem.text">
            </span>
            <div *ngIf="subItem.icon"
                 class="floating-container">
              <i class="svg-icon __wide menu-icon __clr-default {{subItem.icon}}"></i>
              <div class="menu-item-text"
                   [innerHTML]="subItem.text">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
