<div *ngIf="(ls.locsLoaded | async)" id="order-check-side-panel" class="margin-top-72">
  <p class="bold">{{ ls.locs['loc'].IhreLieferadresse }}</p>
  <div class="card AdresskachelItem font-weight-light padding-16 pointer-hover overlay-yellow-visible" *ngIf="addressLoaded">
    <div [ngSwitch]="address['LKZ']" class="AdresskachelItemContent font-weight-regular padding-top-16">
      <div *ngSwitchCase="'RU'">
        <p class="bold">{{address['Anrede']}} {{address['Titel']}} {{address['Vorname']}} {{address['Name']}}</p>
        {{address['Strasse']}}
        <br />
        {{address['Ort']}}
        <br />
        {{address['PLZ']}}
        <br />
        {{address['Land'].Land}}
      </div>
      <div *ngSwitchDefault>
        <p class="bold">{{address['Anrede']}} {{address['Titel']}} {{address['Vorname']}} {{address['Name']}}</p>
        <p [hidden]="!address['Firma']">{{address['Firma']}}</p>
        <p [hidden]="!address['Adresszusatz']">
          <span *ngIf="address['AdressArtID'] == 3 || address['AdressArtID'] == 2">{{ ls.locs['loc'].PostNummer }} </span>
          {{address['Adresszusatz']}}
        </p>
        <span *ngIf="address['AdressArtID'] == 3">{{ ls.locs['loc'].Postfiliale }} </span>
        <span *ngIf="address['AdressArtID'] == 2">{{ ls.locs['loc'].Packstation }} </span>
        {{address['Strasse']}}
        <br />
        {{address['PLZ']}} {{address['Ort']}}
        <br />
        {{address['Land'].Land}}
      </div>
    </div>
    <a href="#/{{role}}/{{steps['versand'].Url}}" class="edit-before-order bold text-transform-uppercase text-color-brand-primary font-size-xs position-absolute left-16 bottom-16">{{ ls.locs['loc'].Bearbeiten }}</a>
  </div>
  <div *ngIf="mpOrderProcess.paymentType !== '' && additionalPaymentAmount > 0">
    <p class="bold margin-top-40">{{ ls.locs['locZuzahlung'].Zahlungsmethode }}</p>
    <div class="card padding-16" id="additional-pay-summary">
      <p>{{ ls.locs['locZuzahlung'].ZahlungsmethodeText }}</p>
      <ng-container *ngFor="let p of additionalPaymentProvider">
        <div *ngIf="p.Key === mpOrderProcess.paymentType" [innerHTML]="p.Bezeichnung"></div>
        <div *ngIf="p.Key === mpOrderProcess.paymentType">
          <ng-container *ngComponentOutlet="sbAdditionalPaymentService.getChildComponent(p.TemplateUrl); injector: getInjector(p);"></ng-container>
        </div>
      </ng-container>
      <a *ngIf="additionalPaymentProvider.length > 1" href="#/{{role}}/{{steps['zuzahlung'].Url}}" class="edit-before-order bold text-transform-uppercase text-color-brand-primary font-size-xs position-absolute left-16 bottom-16">{{ ls.locs['loc'].Bearbeiten }}</a>
    </div>
  </div>
</div>
