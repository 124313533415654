import { Directive } from '@angular/core';

import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';

import { CoreSidebars } from '@core/components/sidebar/core-sidebars';
import { RkSidebars } from '@rk/components/sidebar/rk-sidebars';

@Directive({
  selector: '[mpCoreSidebar]',
  exportAs: 'mpCoreSidebar'
})
export class SidebarDirective {

  public contentComponent: any;
  private _sidebarComponents: { [key: string]: any } = Object.assign(new CoreSidebars().sidebarComponents, new RkSidebars().sidebarComponents);

  constructor(public mpSidebar: MpSidebarService) {
    this.contentComponent = this._sidebarComponents[this.mpSidebar.contentComponent];

    this.mpSidebar.toggleSidebar.subscribe(() => {
      this.contentComponent = this._sidebarComponents[this.mpSidebar.contentComponent];
    });
  }

}
