<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].AnonymisierungsUebersicht }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].AnonymisierungsUebersicht }}"
                 [minimizedStyle]="true"
                 infotextShorten="AnonymisierungsUebersicht"
                 fieldsAction="/api/Teilnehmer/getAnonymisierungsUebersichtFields"
                 listAction="/api/Teilnehmer/getAnonymisierungsUebersicht"
                 loaderName="anonymizationOverviewLoader"
                 [recLoadedFunc]="recLoadedFunc"
                 [paging]="true"
                 defaultSorting="AnonymisierungAm">
  </mp-core-data-table>
  <div class="floating-container" *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
