import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';

/**
 * This class provides a simple checkbox, that
 * can be configured with different input attributes
 * (e.g. for required fields, for optional fields, ...).
 */
@Component({
  selector: 'mp-core-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent implements OnInit {
  @Input() public label: string = '';
  @Input() public mpId: string = '';
  @Input() public mpDisabled: boolean = false;
  @Input() public ngDisabled: boolean = false;
  @Input() public hasError: boolean = false;
  @Input() public mpClick: Function | void | undefined;
  @Input() public model: any;
  @Input() public tristate: boolean = false;

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public ls: MpLocalizationService) { }

  ngOnInit(): void {
  }

  /**
   * Triggers the given click function.
   */
  onClick() {
    if (this.mpClick) {
      this.mpClick();
    }
  }

  /**
  * Triggers model change event.
  */
  modelChanged(): void {
    this.modelChange.emit(this.model);
  }

}
