import { Directive, OnInit, HostListener } from '@angular/core';

import { AdjustCalendarService } from './../services/adjust-calendar.service';
import { SizeCheckService } from './../services/size-check.service';

/**
 * This class provides the functions to move
 * the doors within the calendar.
 */
@Directive({
  selector: '[mpGkDoorMovement]',
  exportAs: 'mpGkDoorMovement'
})
export class DoorMovementDirective implements OnInit {

  private _mouseIsDown: boolean = false;
  private _swipeX: boolean = false;
  private _swipeY: boolean = false;
  private _mouseDownPositionX: number = 0;
  private _mouseDownPositionY: number = 0;
  private _swipePositionX: number = 0;
  private _swipePositionY: number = 0;
  private _newPositionX: number = 0;
  private _newPositionY: number = 0;
  private _sectionToMove: HTMLElement | null = null;
  private _sectionToMoveWidth: number = 0;
  private _sectionToMoveHeight: number = 0;
  private _sectionToMoveCurrentMarginLeft: number = 0;
  private _sectionToMoveCurrentMarginTop: number = 0;
  private _benefitDoor: HTMLDivElement | null = null;
  private _sectionToMoveItemsWidth: number = 0;
  private _sectionToMoveItemsHeight: number = 0;

  constructor(
    private _adjustCalendar: AdjustCalendarService,
    private _sizeCheck: SizeCheckService
  ) { }
  
  ngOnInit(): void { }

  /**
   * Adjusts the calendar on resize.
   */
  @HostListener('window:resize')
  onresize() {
    this._adjustCalendar.adjustCalendar();
  }

  /**
   * Sets the functionalities for the
   * movement, when all doors are
   * rendered.
   */
  ngForFinished(): void {
    if (this._sectionToMove !== null) {
      return;
    }

    this._adjustCalendar.adjustCalendar();
    this._mouseIsDown = false;
    this._swipeX = false;
    this._swipeY = false;
    this._mouseDownPositionX = 0;
    this._mouseDownPositionY = 0;
    this._swipePositionX = 0;
    this._swipePositionY = 0;
    this._newPositionX = 0;
    this._newPositionY = 0;
    this._sectionToMove = document.getElementById('calender');

    if (this._sectionToMove !== null) {
      this._sectionToMoveWidth = this._sectionToMove.getBoundingClientRect().width;
      this._sectionToMoveHeight = this._sectionToMove.getBoundingClientRect().height;
      this._sectionToMoveCurrentMarginLeft = parseInt(getComputedStyle(this._sectionToMove).getPropertyValue('margin-left').replace('px', ''));
      this._sectionToMoveCurrentMarginTop = parseInt(getComputedStyle(this._sectionToMove).getPropertyValue('margin-top').replace('px', ''));
      this._benefitDoor = document.querySelector('.benefit-door');

      if (this._benefitDoor !== null) {
        this._sectionToMoveItemsWidth = this._benefitDoor.getBoundingClientRect().width + (parseInt(getComputedStyle(this._benefitDoor).getPropertyValue('margin').replace('px', '')) * 2);
        this._sectionToMoveItemsHeight = this._benefitDoor.getBoundingClientRect().height + (parseInt(getComputedStyle(this._benefitDoor).getPropertyValue('margin').replace('px', '')) * 2);
      }
    }
  }

  /**
   * Sets / removes special styles and classes,
   * based on the orientation of the device.
   */
  @HostListener('orientationchange')
  onorientationchange() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth > screenHeight) {
      if (this._sectionToMove !== null) {
        this._sectionToMove.removeAttribute('style');
      }

      if (document.body.classList.contains('win-calender-body')) {
        document.body.classList.remove('win-calender-body');
      }
    } else {
      if (!document.body.classList.contains('win-calender-body')) {
        document.body.classList.add('win-calender-body');
      }
    }
  }

  /**
   * Starts the calendar movement.
   */
  @HostListener('mousedown', ['$event'])
  onmousedown(e: any) {
    if (!this._sizeCheck.sizeCheck()) {
      return;
    }

    if (this._sectionToMove !== null && this._mouseIsDown === false) {
      this._mouseIsDown = true;
      this._sectionToMoveCurrentMarginLeft = parseInt(getComputedStyle(this._sectionToMove).getPropertyValue('margin-left').replace('px', ''));
      this._sectionToMoveCurrentMarginTop = parseInt(getComputedStyle(this._sectionToMove).getPropertyValue('margin-top').replace('px', ''));
      this._swipeX = false;
      this._swipeY = false;
      this._mouseDownPositionX = e['pageX'];
      this._mouseDownPositionY = e['pageY'];
    }
  }

  /**
   * Starts the calendar movement.
   */
  @HostListener('touchstart', ['$event'])
  ontouchstart(e: any) {
    if (!this._sizeCheck.sizeCheck()) {
      return;
    }

    if (this._sectionToMove !== null && this._mouseIsDown === false) {
      this._mouseIsDown = true;
      this._sectionToMoveCurrentMarginLeft = parseInt(getComputedStyle(this._sectionToMove).getPropertyValue('margin-left').replace('px', ''));
      this._sectionToMoveCurrentMarginTop = parseInt(getComputedStyle(this._sectionToMove).getPropertyValue('margin-top').replace('px', ''));
      this._swipeX = false;
      this._swipeY = false;
      this._mouseDownPositionX = e['touches'][0].screenX;
      this._mouseDownPositionY = e['touches'][0].screenY;
    }
  }

  /**
   * Stops the calendar movement.
   */
  @HostListener('window:mouseup')
  onmouseup() {
    if (!this._sizeCheck.sizeCheck()) {
      return;
    }

    this._endCalendarMovement();
  }

  /**
   * Stops the calendar movement.
   */
  @HostListener('window:touchend')
  touchend() {
    if (!this._sizeCheck.sizeCheck()) {
      return;
    }

    this._endCalendarMovement();
  }

  /**
   * Stops the calendar movement.
   */
  private _endCalendarMovement(): void {
    if (this._sectionToMove !== null) {
      this._mouseIsDown = false;

      if (this._sectionToMoveCurrentMarginLeft + this._swipePositionX <= (-(this._sectionToMoveItemsWidth * this._sectionToMoveWidth / this._sectionToMoveItemsWidth)) + this._sectionToMoveItemsWidth || this._sectionToMoveCurrentMarginLeft + this._swipePositionX >= (this._sectionToMoveItemsWidth * 0.5) ||
        this._sectionToMoveCurrentMarginTop + this._swipePositionY <= (-(this._sectionToMoveItemsHeight * this._sectionToMoveHeight / this._sectionToMoveItemsHeight)) + this._sectionToMoveItemsWidth || this._sectionToMoveCurrentMarginTop + this._swipePositionY >= (this._sectionToMoveItemsHeight * 0.5)) {
        this._newPositionX = this._sectionToMoveCurrentMarginLeft;
        this._newPositionY = this._sectionToMoveCurrentMarginTop;
      } else if (this._swipeX) {
        if (this._sectionToMoveCurrentMarginLeft > (this._sectionToMoveCurrentMarginLeft + this._swipePositionX)) {
          this._newPositionX = this._sectionToMoveCurrentMarginLeft - this._sectionToMoveItemsWidth;
        } else {
          this._newPositionX = this._sectionToMoveCurrentMarginLeft + this._sectionToMoveItemsWidth;
        }
      } else if (this._swipeY) {
        if (this._sectionToMoveCurrentMarginTop > (this._sectionToMoveCurrentMarginTop + this._swipePositionY)) {
          this._newPositionY = this._sectionToMoveCurrentMarginTop - this._sectionToMoveItemsHeight;
        } else {
          this._newPositionY = this._sectionToMoveCurrentMarginTop + this._sectionToMoveItemsHeight;
        }
      } else {
        this._newPositionX = this._sectionToMoveCurrentMarginLeft;
        this._newPositionY = this._sectionToMoveCurrentMarginTop;
      }

      this._sectionToMove.style.marginTop = `${this._newPositionY}px`;
      this._sectionToMove.style.marginLeft = `${this._newPositionX}px`;
    }
  }

  /**
   * Does the movement.
   */
  @HostListener('mousemove', ['$event'])
  onmousemove(e: any) {
    if (this._mouseIsDown) {
      this._swipePositionX = e['pageX'] - this._mouseDownPositionX;
      this._swipePositionY = e['pageY'] - this._mouseDownPositionY;
      this._doMove();
    }
  }

  /**
   * Does the movement.
   */
  @HostListener('touchmove', ['$event'])
  touchmove(e: any) {
    if (this._mouseIsDown) {
      this._swipePositionX = e['touches'][0].screenX - this._mouseDownPositionX;
      this._swipePositionY = e['touches'][0].screenY - this._mouseDownPositionY;
      this._doMove();
    }
  }

  /**
   * Does the movement.
   */
  private _doMove(): void {
    if (this._sectionToMove !== null) {
      this._sectionToMove.style.marginTop = `${(this._sectionToMoveCurrentMarginTop + this._swipePositionY)}px`;
      this._sectionToMove.style.marginLeft = `${(this._sectionToMoveCurrentMarginLeft + this._swipePositionX)}px`;
    }

    if (this._swipePositionX > (this._sectionToMoveItemsWidth * 0.5) || this._swipePositionX < (-(this._sectionToMoveItemsWidth * 0.5))) {
      this._swipeX = true;
      this._swipeY = false;
    } else if (this._swipePositionY > (this._sectionToMoveItemsHeight * 0.5) || this._swipePositionY < (-(this._sectionToMoveItemsHeight * 0.5))) {
      this._swipeY = true;
      this._swipeX = false;
    }

    if (this._swipeX || this._swipeY) {
      this._endCalendarMovement();
    }
  }

}
