import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the points decay 
 * table.
 */
@Component({
  selector: 'mp-leas-points-decay',
  templateUrl: './points-decay.component.html'
})
export class PointsDecayComponent implements OnInit {

  public excelService: string = '/api/Statistik/getPunkteverfall2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _leasPointsDecayLoader: MpLoader = {
    name: 'leasPointsDecayLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._leasPointsDecayLoader);

    this._mpLoaderService.extendLoader('leasPointsDecayLoader', {
      isReady: true
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasPointsDecayLoader');
  }

}
