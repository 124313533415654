import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';
import { TcSearchParamsService } from './../../../services/tc-search-params.service';

/**
 * This class provides the functionalities
 * for the search area 'hotel and flight'.
 */
@Component({
  selector: 'mp-rk-search-area-hotel-and-flight',
  templateUrl: './search-area-hotel-and-flight.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SearchAreaHotelAndFlightComponent implements OnInit, OnDestroy {

  @Input() public params: { [key: string]: any } = {
    dateRangeHotel: {},
    gaesteHotel: {},
    reisezieleHotel: {},
    flugHotelUndFlug: {}
  };
  @Input() public travelType: number = 0;
  @Input() public hideButton: boolean = false;
  @Input() public searchFunction: Function | undefined;

  @Output() paramsChange = new EventEmitter<{ [key: string]: any }>();

  public travelTypeEnum: { [key: string]: any } = {};
  public paramsSet: boolean = false;

  private _getLocsSubscription: Subscription | undefined;
  private _getEnumsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpEnums: MpEnumsService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _customEventService: CustomEventService
  ) { }

  /**
   * Gets the enums for the
   * travel types.
   */
  ngOnInit(): void {
    this.params['dateRangeHotel'] = this.params['dateRangeHotel'] || {};
    this.params['gaesteHotel'] = this.params['gaesteHotel'] || {};
    this.params['reisezieleHotel'] = this.params['reisezieleHotel'] || {};
    this.params['flugHotelUndFlug'] = this.params['flugHotelUndFlug'] || {};

    if (Object.keys(this._mpEnums.enums).length > 0) {
      this.travelTypeEnum = this._mpEnums.enums['Reisetyp'];
      this._getLocs();
    } else {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (Object.keys(this.ls.locs).length > 0) {
            if (typeof this._getLocsSubscription !== 'undefined') {
              this._getLocsSubscription.unsubscribe();
            }

            if (typeof this._getEnumsSubscription !== 'undefined') {
              this._getEnumsSubscription.unsubscribe();
            }
          }

          this.travelTypeEnum = this._mpEnums.enums['Reisetyp'];
          this._getLocs();
        }
      });

      this._mpEnums.getEnums();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the localization data.
   */
  private _getLocs(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this._setParams();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setParams();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Sets this params for the search.
   */
  private _setParams(): void {
    if (this.paramsSet) {
      return;
    }

    this.params['dateRangeHotel'] = Object.assign(this.params['dateRangeHotel'] || {}, {
      minDate: moment().add(4, 'days').hour(0).minute(0).second(0).millisecond(0),
      isRange: true,
      save: this._dateRangeHotelSave.bind(this),
      reisedauerOptions: [
        { Text: this.ls.locs['locReisekonfigurator'].Beliebig, id: 1 },
        { Text: this.ls.locs['locReisekonfigurator'].ExaktWieAngegeben, id: 2 },
        { Text: this.ls.locs['locReisekonfigurator'].ZeitraumFreiDefinieren, id: 3 }
      ]
    });

    this.params['dateRangeHotel'].Reisedauer = this.params['dateRangeHotel'].Reisedauer || this.params['dateRangeHotel'].reisedauerOptions[1];
    this.params['dateRangeHotel'].MinTage = this.params['dateRangeHotel'].MinTage || 1;
    this.params['dateRangeHotel'].MaxTage = this.params['dateRangeHotel'].MaxTage || 1;
    this.params['dateRangeHotel'].save(true);

    this.params['gaesteHotel'] = Object.assign(this.params['gaesteHotel'] || {}, {
      save: this._guestsHotelSave.bind(this)
    });

    this.params['gaesteHotel'].save(true);

    this.params['reisezieleHotel'] = Object.assign(this.params['reisezieleHotel'] || {}, {
      save: this._travelDestinationsHotelSave.bind(this)
    });

    this.params['reisezieleHotel'].save(true);

    this.params['flugHotelUndFlug'] = Object.assign(this.params['flugHotelUndFlug'] || {}, {
      save: this._flightHotelAndFlightSave.bind(this)
    });

    this.params['flugHotelUndFlug'].save(true);

    this.params = this._tcSearchParamsService.getParamsForSearchArea(this.params, this.travelType);
    this.paramsSet = true;
  }

  /**
   * Triggers the search.
   */
  search(evt: MouseEvent): void {
    evt.preventDefault();

    if (typeof this.searchFunction !== 'undefined') {
      this.searchFunction(evt);
    }
  }

  /**
   * Save function for dateRangeHotel
   * sidebar param.
   */
  private _dateRangeHotelSave(initialSave?: boolean): void {
    if (this.params['dateRangeHotel'].rangeStart && this.params['dateRangeHotel'].rangeEnd) {
      let reisedauerText;

      if (this.params['dateRangeHotel'].Reisedauer.id === this.params['dateRangeHotel'].reisedauerOptions[2].id) {
        reisedauerText = this.ls.locs['locReisekonfigurator'].NNaechte
          .replace('{0}',
            this.params['dateRangeHotel'].MinTage + ' - ' + this.params['dateRangeHotel'].MaxTage);
      } else {
        reisedauerText = this.params['dateRangeHotel'].Reisedauer.Text
      }

      this.params['zeitraumTextHotel'] = this.ls.locs['locReisekonfigurator'].ZeitraumText
        .replace('{0}', this.params['dateRangeHotel'].rangeStart.format('L'))
        .replace('{1}', this.params['dateRangeHotel'].rangeEnd.format('L'))
        .replace('{2}', reisedauerText);
    } else {
      this.params['zeitraumTextHotel'] = undefined;
    }

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for gaesteHotel
   * sidebar param.
   */
  private _guestsHotelSave(initialSave?: boolean): void {
    if (this.params['gaesteHotel'].AnzahlKinder > 0 || this.params['gaesteHotel'].AnzahlErwachsene > 0) {
      this.params['gaesteTextHotel'] = this.ls.locs['locReisekonfigurator'].GaesteText
        .replace('{0}', this.params['gaesteHotel'].AnzahlErwachsene || '0')
        .replace('{1}', this.params['gaesteHotel'].AnzahlKinder || '0');
    } else {
      this.params['gaesteTextHotel'] = undefined;
    }

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for reisezieleHotel
   * sidebar param.
   */
  private _travelDestinationsHotelSave(initialSave?: boolean): void {
    if (this.params['reisezieleHotel'].ziele && this.params['reisezieleHotel'].ziele.length > 0) {
      this.params['reisezielTextHotel'] = this.params['reisezieleHotel'].ziele.map((destination: any) => {
        return destination['Text'];
      }).reduce((a: string, b: string) => {
        return a + ', ' + b;
      });
    } else {
      this.params['reisezielTextHotel'] = undefined;
    }

    this.params['reisezieleHotel'].noResults = false;

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }

  /**
   * Save function for flugHotelUndFlug
   * sidebar param.
   */
  private _flightHotelAndFlightSave(initialSave?: boolean): void {
    if (this.params['flugHotelUndFlug'].flughaefen &&
      this.params['flugHotelUndFlug'].flughaefen.length > 0) {
      this.params['abflughafenTextHotelUndFlug'] = this.params['flugHotelUndFlug'].flughaefen.map((hotelAndFlight: any) => {
        return hotelAndFlight['Bezeichnung'];
      }).reduce((a: string, b: string) => {
        return a + ', ' + b;
      });
    } else {
      this.params['abflughafenTextHotelUndFlug'] = undefined;
    }

    this.params['flugHotelUndFlug'].header = this.ls.locs['locReisekonfigurator'].AbflughafenSidebarHeader;
    this.params['flugHotelUndFlug'].noResults = false;
    this.params['flugHotelUndFlug'].noSelection = false;

    if (typeof initialSave === 'undefined' || initialSave === false) {
      this._customEventService.dispatch(new CustomEvent(CustomEventType.TcSearchParamsUpdated, this.params));
    }
  }
}
