import { Component, OnInit, ViewEncapsulation, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

/**
 * This class porvides the data for the
 * imprint page.
 */
@Component({
  selector: 'mp-core-imprint',
  templateUrl: './imprint.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ImprintComponent implements OnInit, OnDestroy {

  public data: string = '';

  private _getContentSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _sanitizer: DomSanitizer,
    private _titleService: MpTitleService,
    private _mpBreadcrumbService: MpBreadcrumbService
  ) { }

  /**
   * Sets the title, the menu highlight and the
   * breadcrumbgs. Loads the content.
   */
  ngOnInit(): void {
    this._mpBreadcrumbService.setCurrentSiteWithLoc('Impressum', 'loc', 'Impressum');
    this._titleService.setTitleFromLoc('Impressum');
    this._mpMenuHighlight.setMenuHighlight('support');

    this._getContentSubscription = this._apiService.getRequest('/api/Texte/getText', false, {
      params: {
        key: "tn-impressum"
      }
    }).subscribe((data: any) => {
      this.data = this._trustAsHtml(data.Records[0]);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getContentSubscription !== 'undefined') {
      this._getContentSubscription.unsubscribe();
    }
  }

  /**
   * Sanitizer for HTML.
   */
  private _trustAsHtml(str: string): string {
    return this._sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
