import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { PaymentComponent } from './modules/participant/pages/payment/payment.component';
import { PaymentFailedComponent } from './modules/participant/pages/payment-failed/payment-failed.component';

import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Bezahlung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'ZuzahlungFailed',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PaymentFailedComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class WkzzRoutingModule { }
