<div *ngIf="(ls.locsLoaded | async)" [ngClass]="{'invalid-error': hasError}" class="magma-input flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8 correction-input">
  <label class="flex-col-12 font-weight-700 font-size-xxs padding-left-right-0 display-block-important"
         for="{{mpIdHash}}">
    <span class="display-inline font-weight-700 font-size-xxs"
          [ngClass]="(!mpRequired && !mpDisabled) && labelText ? 'margin-right-4' : ''">
      {{labelText}}
    </span>
    <span *ngIf="(!mpRequired && !mpDisabled) && labelText && (ls.locsLoaded | async)"
          class="font-size-xxs text-color-grey-info display-inline">
      {{ ls.locs['loc'].OptionalField }}
    </span>
  </label>
  <input class="flex-col-12 font-size-general padding-left-right-8"
         type="{{mpType}}"
         [(ngModel)]="model"
         id="{{mpIdHash}}"
         (ngModelChange)="modelChange.emit($event)"
         (focus)="executeEvent($event, 'focus')"
         (change)="executeEvent($event, 'change')"
         (blur)="executeEvent($event, 'blur')"
         [ngClass]="{active: (model !== undefined && model !== null && model !== '') || mpDisabled}"
         [required]="mpRequired"
         [disabled]="mpDisabled"
         mpCoreMpFocus
         [mpFocus]="focusOn"
         placeholder="{{mpPlaceholder}}">
  <div [hidden]="!mpCorrection"
       class="display-flex flex-direction-column flex-align-items-start flex-justify-content-center padding-left-right-8 padding-top-8 position-relative">
    <span class="font-size-xs font-weight-500">
      {{mpCorrectionLabel !== '' ? mpCorrectionLabel : ls.locs['loc'].MeintenSie}}
    </span>
    <span (click)="applyCorrection()"
          class="margin-top-4">
      {{mpCorrection}}
    </span>
  </div>
</div>
