import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpUrlEncodingCodec } from '@angular/common/http';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSettingsService } from './../../../services/mp-settings.service';
import { MpStatusService } from './../../../services/mp-status.service';
import { MpWishlistService } from './../../../modules/participant/pages/wishlist/mp-wishlist.service';
import { AuthService } from './../../../services/auth.service';
import { RoleMappingService } from './../../../services/role-mapping.service';

import { Tile } from './tile';

/**
 * This class provides the tile of a
 * shop card, that can be used within
 * the shop cards grid.
 */
@Component({
  selector: 'mp-core-shop-card',
  templateUrl: './shop-card.component.html'
})
export class ShopCardComponent implements OnInit, OnDestroy {

  public categoryId: string = '';
  public searchTerm: string = '';
  public encodedSsearchTerm: string = '';
  public brand: string = '';
  public hasStatus: boolean = false;
  public showIconLegend: boolean = false;
  public wlLoaded: boolean = false;
  public role: string = '';

  @Input() public tile: Tile = {
    ArtNr: '',
    clicked: false,
    PIN: '',
    CellWidth: 1,
    CellHeight: 1,
    Teaser: '',
    ProzentRabatt: 0
  };
  @Input() public mpClick: Function | undefined;
  @Input() public tileWidth: number | undefined;
  @Input() public tileHeight: number | undefined;
  @Input() public addToShoppingbasketFunc: Function | undefined;

  private _statusSubscription: Subscription | undefined;
  private _wishlistSubscription: Subscription | undefined;
  private _wl: Array<Tile> | undefined;
  private _codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _route: ActivatedRoute,
    private _mpStatus: MpStatusService,
    public mpWishlist: MpWishlistService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets some basic informations to the
   * tile (card), reads optional set params
   * from the url and provides them to the view,
   * and trggers the loading of the wish list.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }
    
    if (typeof this._mpStatus.currentHasStatus !== 'undefined') {
      this.hasStatus = this._mpStatus.currentHasStatus;
    } else {
      this._statusSubscription = this._mpStatus.status.subscribe((status: boolean) => {
        this.hasStatus = status;
      });

      this._mpStatus.hasStatus();
    }

    if (typeof this.tileWidth !== 'undefined') {
      this.tile.CellWidth = this.tileWidth;
    }

    if (typeof this.tileHeight !== 'undefined') {
      this.tile.CellHeight = this.tileHeight;
    }

    if (this.tile.CellWidth === 4) {
      this.tile.filteredVisuals = this.tile.MoodBildPath;
    } else {
      this.tile.filteredVisuals = this.tile.Image;
    }

    this.tile.Groesse = this.tile.CellWidth === (1 || 2) ? this.tile.CellWidth : 3;

    this.mpWishlist.getWishlist();

    this._wishlistSubscription = this.mpWishlist.wishlistLoaded.subscribe(() => {
      this._wl = this.mpWishlist.wishlist;
      this.wlLoaded = true;
    });

    this._route.queryParams.subscribe((params: any) => {
      if (typeof params['k'] !== 'undefined') {
        this.categoryId = params['k'];
      }

      if (typeof params['s'] !== 'undefined') {
        this.searchTerm = params['s'];
        this.encodedSsearchTerm = this._codec.encodeValue(params['s']);
      }

      if (typeof params['m'] !== 'undefined') {
        this.brand = params['m'];
      }
    });

    this._route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.categoryId = params['id'];
      }
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._statusSubscription !== 'undefined') {
      this._statusSubscription.unsubscribe();
    }

    if (typeof this._wishlistSubscription !== 'undefined') {
      this._wishlistSubscription.unsubscribe();
    }
  }

  /**
   * Checks whether or not the article
   * of the tile is in the wish list.
   */
  isInWishlist(): boolean {
    if (typeof this._wl !== 'undefined') {
      const inWl = this._wl.find(wlItem => {
        // @ts-ignore
        return wlItem.ArtNr === this.tile.ArtNr;
      });

      if (typeof inWl !== 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Gets the item from the wish list.
   */
  private _getWlItem(): any {
    // @ts-ignore
    return this._wl.find(wlItem => {
      // @ts-ignore
      return wlItem.ArtNr === this.tile.ArtNr;
    });
  }

  /**
   * Adds / removes the item to / from
   * the wish list.
   */
  toggleWishlist(evt: MouseEvent): void {
    evt.preventDefault();
    this.tile.clicked = true;

    setTimeout(() => {
      this.tile.clicked = false;
    }, 650);

    if (this.isInWishlist()) {
      const wlItem = this._getWlItem();
      this.mpWishlist.deleteItem(wlItem.ID, (wl: Array<Tile>) => {
        this._wl = wl;
      });
    } else {
      this.mpWishlist.addItem(this.tile.ArtNr, this.tile.PIN, (wl: Array<Tile>) => {
        this._wl = wl;
      });
    }
  }

  /**
   * Gets the image path for the mood
   * image, if set, otherwise, gets the
   * normal image path.
   */
  getMoodImage(): string {
    if (typeof this.tile.MoodBildPath !== 'undefined') {
      return this.tile.MoodBildPath;
    } else if (typeof this.tile.Image !== 'undefined') {
      return this.tile.Image;
    }

    return '';
  }

  /**
   * Gets the image path of the award
   * image.
   */
  getAwardImage(): string {
    if (typeof this.tile.Image !== 'undefined') {
      return this.tile.Image;
    }

    return '';
  }

  /**
   * Shows the icon legend on mouseover.
   */
  showIconLegendFnc(): void {
    // @ts-ignore
    if (!document.documentElement.classList.contains('touch')) {
      this.showIconLegend = true;
    } else {
      this.showIconLegend = false;
    }
  }

  /**
   * Triggers the click event of the
   * components @Input, when the user
   * clicks on the tile.
   */
  onClick(evt: MouseEvent): void {
    this.mpClick && this.mpClick(evt, this.tile);
  }

  /**
  * Adds the given wishlist item
  * to the shopping basket.
  */
  addToShoppingbasket(wishlistItem: Tile): void {
    if (typeof this.addToShoppingbasketFunc !== 'undefined') {
      this.addToShoppingbasketFunc(wishlistItem);
    }
  }

}
