import { Component, Injectable, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpCategoriesService } from './../../services/mp-categories.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';

/**
 * This class provides the category
 * dropdown for the filter in shops.
 */
@Component({
  selector: 'mp-core-category-dropdown',
  templateUrl: './category-dropdown.component.html',
  styleUrls: ['./category-dropdown.component.scss']
})
export class CategoryDropdownComponent implements OnInit, OnDestroy {

  public shownCategories: any;
  public selectedCategory: any;
  public role: string = '';

  @Input() public showFilter: boolean = false;
  @Input() public showDropdown: boolean = false;

  @Output() showFilterChange = new EventEmitter<boolean>();

  private _categories: any;
  private _currentCategory: string = '';
  private _categoryTreeSubscription: Subscription | undefined;
  private _categoryIdSubscription: Subscription | undefined;
  private _catListSubscription: Subscription | undefined;

  constructor(
    private _mpCategories: MpCategoriesService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Angulars init function. Loads the
   * category tree and the currently
   * selected category.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._categoryTreeSubscription = this._mpCategories.categoryTree.subscribe((tree: any) => {
      if (tree !== null) {
        this._categories = tree['Children'];
      }

      this._updateShownCategories();
    });

    this._categoryIdSubscription = this._mpCategories.categoryId.subscribe((catId: string) => {
      this._currentCategory = catId;
      this._updateShownCategories();
    });

    this._mpCategories.loadCategoryTree();
    this._mpCategories.getCategoryId();
  }

  /**
   * Unsubscribes set subscribers.
   */
  ngOnDestroy(): void {
    if (typeof this._categoryTreeSubscription !== 'undefined') {
      this._categoryTreeSubscription.unsubscribe();
    }

    if (typeof this._categoryIdSubscription !== 'undefined') {
      this._categoryIdSubscription.unsubscribe();
    }

    if (typeof this._catListSubscription !== 'undefined') {
      this._catListSubscription.unsubscribe();
    }
  }

  /**
   * Updates the categories in the
   * category tree / dropdown.
   */
  private _updateShownCategories(): void {
    if (this._categories && this._categories.length > 0) {
      if (this._currentCategory !== '') {
        let catString = this._currentCategory;

        this.shownCategories = this._categories.filter((cat: any) => {
          return (catString.substr(0, 1) !== '9' ||
            catString === cat['KategorieID'].toString()) &&
            cat['KategorieID'].toString() === catString.substr(0, 2);
        });

        this._catListSubscription = this._mpCategories.catList.subscribe((catList: any) => {
          const selectedCategory = catList.find((cat: any) => cat['KategorieID'] === parseInt(this._currentCategory));
          if (typeof selectedCategory !== 'undefined') {
            this.selectedCategory = selectedCategory;
          }
        });
      } else {
        this.shownCategories = this._categories.map((cat: any) => {
          const catParsed = JSON.parse(JSON.stringify(cat));
          catParsed.Children = [];
          return catParsed;
        });
      }
    }
  }

  /**
   * Toggles the dropdown.
   */
  toggle(): void {
    this.showDropdown = !this.showDropdown;

    if (this.showFilter) {
      this.showFilter = false;
      this.showFilterChange.emit(false);
    }
  }

  /**
   * Closes the dropdown.
   */
  closeDropdown(): void {
    this.showDropdown = false;
  }

  /**
   * Closes the dropdown and the filter.
   */
  hide(): void {
    this.showDropdown = false;
    this.showFilter = false;
    this.showFilterChange.emit(false);
  }

}
