import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { FroalaConfigService } from './../../../../services/froala-config.service';

/**
 * This class provides the functionalities
 * to edit email text templates.
 */
@Component({
  selector: 'mp-core-email-text-editor',
  templateUrl: './email-text-editor.component.html'
})
export class EmailTextEditorComponent implements OnInit, OnDestroy {

  public froalaOptions: { [key: string]: any } = {};
  public froalaOptionsLoaded: boolean = false;
  public hints: { [key: string]: any } = {
    showRevertChanges: false,
    showSaveEmailTexts: false
  };
  public errors: { [key: string]: any } = {};
  public tbsList: Array<any> = [];
  public languages: Array<any> = [];
  public language: string = 'de';
  public visibleEmailTexts: Array<any> = [];
  public saveFunc = this._save.bind(this);
  public loadDataFunc = this._loadData.bind(this);
  public setShowSaveEmailTextsFalse = this._setShowSaveEmailTextsFalse.bind(this);
  public setShowRevertChangeFalse = this._setShowRevertChangeFalse.bind(this);

  private _token: string | null = sessionStorage.getItem('token');
  private _froalaEditors: Array<any> = [];
  private _emailTexts: Array<any> = [];
  private _getTextSnippetsSubscription: Subscription | undefined;
  private _getLanguagesSubscription: Subscription | undefined;
  private _loadDataSubscription: Subscription | undefined;
  private _saveSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    private _mpCoreService: MpCoreService,
    private _froalaConfig: FroalaConfigService
  ) { }

  /**
   * Sets the froala options, and the
   * menu highlight. Gets languages and
   * text snippets.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('kommunikation');
    const requestVerificationTokenInput: HTMLInputElement | null = document.querySelector('input[name="__RequestVerificationToken"]');
    const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

    this.froalaOptions = Object.assign(this._froalaConfig.getConfig(), {
      language: this.language,
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'fontSize', 'fontFamily', '|', 'color', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'insertTable', 'undo', 'redo', 'html'],
      requestHeaders: {
        'X-XSRF-TOKEN': xsrfToken || '',
        'Authorization': 'Bearer ' + this._token
      },
      events: Object.assign(this._froalaConfig.getEvents(), {
        'initialized': (editor: any) => {
          this._froalaEditors.push(editor);
        },
        'blur': (editor: any) => {
          const currentTarget = editor.currentTarget;

          if (currentTarget.innerHTML === '<p><br></p>' || currentTarget.innerText === '') {
            currentTarget.innerHTML = '';
          }
        }
      })
    });

    this.froalaOptionsLoaded = true;

    this._getTextSnippetsSubscription = this._apiService.getRequest('/api/Texte/GetTextbausteine').subscribe((data: any) => {
      this.tbsList = data.Records;
    });

    this._getLanguagesSubscription = this._apiService.getRequest('/api/Language/GetLanguagesForDropdown').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length === 1 && data.Records[0].Languages) {
        this.languages = data.Records[0].Languages;
        this.language = this.languages[0].Kuerzel;
        this.froalaOptions['language'] = this.language;
      }
    });

    this._loadData();
  }

  /**
   * Retrieves a cookie value by name
   **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Handles the change of the email
   * text editor text-snippets.
   */
  tbsChange(text: { [key: string]: any }, index: number): void {
    if (text['selectedTBS'] && text['selectedTBS'].Value) {
      if (this._froalaEditors.length > 0) {
        // @ts-ignore
        this._froalaEditors[index]._editor.html.insert(text['selectedTBS'].Value, true);
        text['selectedTBS'] = null;
      }
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTextSnippetsSubscription !== 'undefined') {
      this._getTextSnippetsSubscription.unsubscribe();
    }

    if (typeof this._getLanguagesSubscription !== 'undefined') {
      this._getLanguagesSubscription.unsubscribe();
    }

    if (typeof this._loadDataSubscription !== 'undefined') {
      this._loadDataSubscription.unsubscribe();
    }

    if (typeof this._saveSubscription !== 'undefined') {
      this._saveSubscription.unsubscribe();
    }
  }

  /**
   * Saves the email texts.
   */
  private _save(): void {
    this._saveSubscription = this._apiService.postRequest('/api/TextEditor/SaveEMailTexte', this._emailTexts).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this.hints['showSaveEmailTexts'] = false;
    },
    (error: any) => {
      if (typeof error['ModelState'] !== 'undefined') {
        this.errors = error['ModelState'];
      }

      this._mpMessaging.openErrorPanel(error);
      this.hints['showSaveEmailTexts'] = false;
    });
  }

  /**
   * Loads the data for the email
   * text editor.
   */
  private _loadData(): void {
    this._loadDataSubscription = this._apiService.getRequest('/api/TextEditor/GetEMailTexte').subscribe((data: any) => {
      this._emailTexts = data.Records;
      this.hints['showRevertChanges'] = false;
      this.setFilteredMailTexts();
    },
    (error: any) => {
      this.hints['showRevertChanges'] = false;
    });
  }

  /**
   * Filters the mail texts for the
   * selected language.
   */
  setFilteredMailTexts(): void {
    if (this._emailTexts.length > 0) {
      this.visibleEmailTexts = this._emailTexts.filter((mailText: any) => {
        return mailText['Language'] === this.language;
      });
    }
  }

  /**
   * Sets the showSaveEmailTexts hint to
   * false
   */
  private _setShowSaveEmailTextsFalse(): void {
    this.hints['showSaveEmailTexts'] = false;
  }

  /**
   * Sets the showRevertChanges hint to
   * false.
   */
  private _setShowRevertChangeFalse(): void {
    this.hints['showRevertChanges'] = false;
  }

  /**
   * Triggers the goBack function of
   * the breadcrumb.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

}
