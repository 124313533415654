<div *ngIf="(ls.locsLoaded | async) && model !== null"
     class="AdresskachelItemWrapper bg-color-transparent width-full card"
     [ngClass]="{active: selected}">
  <div class="AdresskachelItem font-weight-light padding-top-bottom-8 padding-left-right-16 width-full display-table overflow-visible position-relative" (click)="cardClick(model)">
    <div [ngSwitch]="model.LKZ" class="AdresskachelItemContent font-weight-regular padding-top-32 padding-bottom-32 text-align-center width-full height-full display-table-cell">
      <div *ngSwitchCase="model.LKZ['RU']" class="text-align-left position-relative">
        <span class="bold">{{model.Anrede}} {{model.Titel}} {{model.Vorname}} {{model.Name}}</span>
        <br />
        {{model.Strasse}}
        <br />
        {{model.Ort}}
        <br />
        {{model.PLZ}}
        <br />
        {{model.Land.Land}}
      </div>
      <div *ngSwitchDefault class="text-align-left position-relative">
        <span class="bold">{{model.Anrede}} {{model.Titel}} {{model.Vorname}} {{model.Name}}</span>
        <br />
        <p class="margin-bottom-0" [hidden]="!model.Firma">{{model.Firma}}</p>
        <p class="margin-bottom-0" [hidden]="!model.Adresszusatz">
          <span *ngIf="model.AdressArtID == 3 || model.AdressArtID == 2">{{ ls.locs['loc'].PostNummer }}</span>
          {{model.Adresszusatz}}
        </p>
        <span *ngIf="model.AdressArtID == 3">{{ ls.locs['loc'].Postfiliale }}</span>
        <span *ngIf="model.AdressArtID == 2">{{ ls.locs['loc'].Packstation }}</span>
        {{model.Strasse}}
        <br />
        {{model.PLZ}} {{model.Ort}}
        <br />
        {{model.Land.Land}}
      </div>
    </div>
    <div [hidden]="!canChangeAdresse" class="address-icon-buttons hide-on-mobile position-absolute bottom-8 right-8 display-flex">
      <span [ngClass]="{'display-none': hideStd && !model.IsStdAdresse, 'active padding-bottom-16': model.IsStdAdresse, 'padding-bottom-0': !model.IsStdAdresse}" class="setDefault adresskachelButtonStd font-size-xs position-relative hide-on-mobile pointer display-inline-flex flex-align-items-center padding-left-right-8" (click)="setStdAdresse($event)">
        <span *ngIf="model.IsStdAdresse">{{ ls.locs['loc'].Standardadresse }}</span>
        <span *ngIf="!model.IsStdAdresse">{{ ls.locs['loc'].AlsStandardadresseDefinieren }}</span>
        <i class="svg-icon __size-18 __check-circle float-right-important margin-left-8" [ngClass]="model.IsStdAdresse ? '__clr-success' : '__clr-default'"></i>
      </span>
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AdresseAendern }}" alignment="right" class="adresskachelButtonEdit font-size-xl hide-on-mobile position-relative">
        <button class="mp-button padding-left-right-8" [ngClass]="{active: model.selected}" href="" [hidden]="!showEdit || !model.Editierbar" (click)="edit($event)">
          <i class="svg-icon __clr-default __size-18 __top--1 __edit"></i>
        </button>
      </mp-core-tooltip>
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AdresseEntfernen }}" alignment="right" class="adresskachelButtonDelete font-size-xl hide-on-mobile position-relative">
        <button class="mp-button padding-left-right-8" [ngClass]="{editable:showEdit && model.Editierbar}" href="" [hidden]="!showDelete || model.IsStdAdresse || !model.Editierbar" (click)="delete($event)">
          <i class="svg-icon __clr-default __size-18 __top--1 __clear"></i>
        </button>
      </mp-core-tooltip>
    </div>
    <div class="address-icon-buttons show-on-mobile">
      <div class="display-flex flex-align-items-center">
        <span [ngClass]="{'display-none': hideStd && !model.IsStdAdresse, 'active padding-bottom-16': model.IsStdAdresse, 'padding-bottom-8': !model.IsStdAdresse}" class="setDefault adresskachelButtonStd font-size-xs position-relative show-on-mobile pointer display-inline-flex flex-align-items-center padding-left-right-8 top-2" (click)="setStdAdresse($event)">
          <span *ngIf="model.IsStdAdresse">{{ ls.locs['loc'].Standardadresse }}</span>
          <span *ngIf="!model.IsStdAdresse">{{ ls.locs['loc'].AlsStandardadresseDefinieren }}</span>
          <i class="svg-icon __size-18 __check-circle float-right-important margin-left-8" [ngClass]="model.IsStdAdresse ? '__clr-success' : '__clr-default'"></i>
        </span>
      </div>
      <div>
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AdresseAendern }}" alignment="left">
          <button class="mp-button padding-left-right-8" [ngClass]="{active: model.selected}" href="" [hidden]="!showEdit || !model.Editierbar" (click)="edit($event)">
            <i class="svg-icon __clr-default __size-18 __top--1 __edit"></i>
          </button>
        </mp-core-tooltip>
      </div>
      <div>
        <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].AdresseEntfernen }}" alignment="left">
          <button class="mp-button padding-left-right-8" [ngClass]="{editable:showEdit && model.Editierbar}" href="" [hidden]="!showDelete || model.IsStdAdresse || !model.Editierbar" (click)="delete($event)">
            <i class="svg-icon __clr-default __size-18 __top--1 __clear"></i>
          </button>
        </mp-core-tooltip>
      </div>
    </div>
  </div>
</div>
