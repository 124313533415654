import { Component, OnInit, OnDestroy, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Event, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpMetaService } from '@core/services/mp-meta.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { ApiService } from '@core/services/api.service';
import { MpStatusService } from '@core/services/mp-status.service';
import { MpWishlistService } from '@core/modules/participant/pages/wishlist/mp-wishlist.service';
import { MpShoppingBasketService } from '@core/modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { MpOrderProcessService } from '@core/components/order-process/mp-order-process.service';
import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { MatomoService } from '@core/services/matomo.service';
import { CookieService } from '@core/services/cookie.service';

import { SbOverviewComponent } from '@core/modules/participant/pages/shopping-basket/sb-overview/sb-overview.component';

import { ShoppingBasketItem } from '@core/modules/participant/pages/shopping-basket/shopping-basket-item';

/**
 * This class provides data and
 * functions for the main charity
 * component.
 */
@Component({
  selector: 'mp-leas-charity',
  templateUrl: './charity.component.html',
  styleUrls: [
    './charity.component.scss',
    './../../../../mp.Core/app/components/input/styles/input.scss',
    './../../../../mp.Core/app/components/sidebar/styles/sidebar.scss',
    './../../../../mp.Core/app/components/sidebar/styles/sidebar-extend.scss',
    './../../../../mp.Core/app/components/card/styles/card.scss',
    './../../../../mp.Core/app/components/order-process/styles/order-process.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CharityComponent implements OnInit, OnDestroy {

  public sbOverviewComponent = SbOverviewComponent;
  public hamburgerOpen: boolean = false;
  public searchActive: boolean = false;
  public mainMenuItems: Array<any> = [];
  public userMenuItems: Array<any> = [];
  public showUserMenu: boolean = false;
  public userMenuOpen: boolean = false;
  public navbarIsSticky: boolean = false;
  public pointValue: number = 0;
  public pointValueState: number = 0;
  public pointValuePreview: number = 0;
  public statusID: number = 0;
  public hasStatus: boolean = false;
  public wLCount: number = 0;
  public wlLoaded: boolean = false;
  public basketCount: number = 0;
  public showWkOverlay: boolean = false;

  private _participantData: { [key: string]: any } = [];
  private _lastScrollTop: number = 0;
  private _scrollDelta: number = 5;
  private _sticked: boolean = false;
  private _creditsInitialHeight: string = '';
  private _settingsSubscription: Subscription | undefined;
  private _locsSubscription: Subscription | undefined;
  private _apiParticipantSubscription: Subscription | undefined;
  private _routeChangeSubscription: Subscription | undefined;
  private _statusSubscription: Subscription | undefined;
  private _wishlistSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _menuRefreshSubscription: Subscription | undefined;

  constructor(
    public mpSidebar: MpSidebarService,
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public cookieService: CookieService,
    private _mpMetaService: MpMetaService,
    private _titleService: MpTitleService,
    private _apiService: ApiService,
    private _router: Router,
    private _mpStatus: MpStatusService,
    private _mpWishlist: MpWishlistService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpOrderProcess: MpOrderProcessService,
    private _location: Location,
    private _mpMenuRefreshService: MpMenuRefreshService,
    private _matomoService: MatomoService
  ) { }

  /**
   * Triggers the functions to set meta tags,
   * and some other 'head'-tags, gets menus
   * and languages.
   */
  ngOnInit(): void {
    this._mpWishlist.getWishlist();

    if (this._mpWishlist.loaded) {
      this.wLCount = typeof this._mpWishlist.wishlist !== 'undefined' ? this._mpWishlist.wishlist.length : 0;
      this.wlLoaded = true;
    } else {
      this._wishlistSubscription = this._mpWishlist.wishlistLoaded.subscribe(() => {
        this.wLCount = typeof this._mpWishlist.wishlist !== 'undefined' ? this._mpWishlist.wishlist.length : 0;
        this.wlLoaded = true;
      });
    }

    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((sBItems: Array<ShoppingBasketItem>) => {
      if (sBItems['length'] > 0) {
        this.basketCount = sBItems.map((sBItem: ShoppingBasketItem) => {
          return sBItem.Anzahl;
        }).reduce((a: number, b: number) => {
          return a + b;
        });
      } else {
        this.basketCount = 0;
      }
    });

    this._mpShoppingBasket.refreshShoppingBasket();

    this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags !== null) {
          this._mpMetaService.setTagsBySettings(this.mpSettings.settings['NetcentiveCoreSettings'].MetaTags);
        }

        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== null) {
          this._titleService.setTitleFromStringAndLoc(this.mpSettings.settings['NetcentiveCoreSettings'].Shopname, 'MeinPostfach');
        } else {
          this._titleService.setTitleFromLoc('MeinPostfach');
        }

        if (this.mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
          this._matomoService.setMatomo();
        }

        if (typeof this._settingsSubscription !== 'undefined') {
          this._settingsSubscription.unsubscribe();
        }
      }
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this.ls.getLocalization();
    }

    this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.mainMenuItems = [
          {
            text: this.ls.locs['loc'].Home,
            href: '#/charity/Home',
            icon: '\uE88A',
            children: [],
            ShowOnMobile: true,
            ShowOnDesktop: true
          },
          {
            text: this.ls.locs['loc'].Praemien,
            icon: '\uE84F',
            href: '#/charity/Shop/9086',
            children: [],
            ShowOnMobile: true,
            ShowOnDesktop: true
          },
          {
            text: this.ls.locs['loc'].Konto,
            href: '#/charity/Konto',
            icon: '\uE870',
            children: [],
            ShowOnMobile: true,
            ShowOnDesktop: true
          }
        ]

        if (typeof this._locsSubscription !== 'undefined') {
          this._locsSubscription.unsubscribe();
        }
      }
    });

    this._statusSubscription = this._mpStatus.status.subscribe((status: boolean) => {
      this.hasStatus = status;
    });

    if (typeof this._mpStatus.currentHasStatus !== 'undefined') {
      this.hasStatus = this._mpStatus.currentHasStatus;
    }

    this._apiParticipantSubscription = this._apiService.postRequest('/api/Teilnehmer/getTN', {}).subscribe((data: any) => {
      this._participantData = data.Records[0];
      this._setUserMenuItems();
    });

    this._mpMetaService.setBasicTags();
    this._mpMetaService.setFavicon();
    this._mpMetaService.setThemeColorTag();
    this._mpMetaService.setScriptTag('https://mycliplister.com/merge?cliplister=1.9&clviewer=1.33&videostage=1.22&innercontrols=1.11&clickablevideo=1.5&playbutton=1.2&previewimage=1.4&bufferingspinner=1.6');
    this._mpMetaService.setLinkTag({
      href: '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css',
      rel: 'stylesheet',
      type: 'text/css'
    });

    this._routeChangeSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.hamburgerOpen = false;
        this.navbarIsSticky = false;
      }
    });

    window.addEventListener('scroll', this._handleNavAndCredits.bind(this), false);
    let credits = document.getElementById('credits');
    let creditsIntvalCounter = 0;

    if (credits === null) {
      const creditsLoadedIntval = setInterval(() => {
        credits = document.getElementById('credits');

        if (credits !== null) {
          clearInterval(creditsLoadedIntval);
          this._creditsInitialHeight = window.getComputedStyle(credits).getPropertyValue('height');
        } else if (creditsIntvalCounter > 30) {
          clearInterval(creditsLoadedIntval);
        }

        creditsIntvalCounter++;
      }, 50);
    } else {
      this._creditsInitialHeight = window.getComputedStyle(credits).getPropertyValue('height');
    }

    this._menuRefreshSubscription = this._mpMenuRefreshService.loadMenuData(true).subscribe((data: any) => {
      this.updatePunkte(data);
    });
  }

  /**
   * Watches for changes, when the points have been changed
   */
  ngOnChange(changes: SimpleChanges): void {
    if (typeof changes['_menuRefreshSubscription'] !== 'undefined') {
      if (changes['_menuRefreshSubscription'].previousValue !== changes['_menuRefreshSubscription'].currentValue) {
        this.updatePunkte(changes['_menuRefreshSubscription'].currentValue);
      }
    }
  }

  /**
   * Checks whether or not there are subscriptions,
   * and unsubscribes them.
   */
  ngOnDestroy(): void {
    if (typeof this._settingsSubscription !== 'undefined') {
      this._settingsSubscription.unsubscribe();
    }

    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }

    if (typeof this._apiParticipantSubscription !== 'undefined') {
      this._apiParticipantSubscription.unsubscribe();
    }

    if (typeof this._routeChangeSubscription !== 'undefined') {
      this._routeChangeSubscription.unsubscribe();
    }

    if (typeof this._statusSubscription !== 'undefined') {
      this._statusSubscription.unsubscribe();
    }

    if (typeof this._wishlistSubscription !== 'undefined') {
      this._wishlistSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }
  }

  /**
   * Replaces the old (AnuglarJS) url structure
   * by the new (Angular) url structure.
   */
  private _replaceUrlStructure(mainMenuItemsRaw: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
    mainMenuItemsRaw.forEach((menuItem: { [key: string]: any }) => {
      if (typeof menuItem['href'] !== 'undefined' && menuItem['href'] !== '' && menuItem['href'] !== null) {
        menuItem['href'] = menuItem['href'].replace('#/', '#/tn/');
      }

      if (typeof menuItem['children'] !== 'undefined' && menuItem['children'].length > 0) {
        menuItem = this._replaceUrlStructure(menuItem['children']);
      }
    });

    return mainMenuItemsRaw;
  }

  /**
   * Sets the data for the user menu.
   */
  private _setUserMenuItems(): void {
    if (Object.keys(this._participantData).length > 0 && Object.keys(this.ls.locs).length > 0) {
      this.userMenuItems = [
        {
          hasStatusIcon: true,
          icon: '#',
          statusId: this._participantData['StatusID'],
          text: this._participantData['Vorname'] + ' ' + this._participantData['Name'],
          hasLogout: true,
          ShowOnMobile: true,
          children: [
            {
              text: this.ls.locs['loc'].MeinKonto,
              href: '#/charity/Konto',
              ShowOnMobile: true,
              icon: '__compare'
            },
            {
              text: this.ls.locs['loc'].Einstellungen,
              href: '#/charity/Einstellungen',
              ShowOnMobile: true,
              icon: '__settings'
            }
          ]
        }
      ];

    }
  }

  /**
   * Shows and hides the user menu.
   */
  toggleUserMenu(): void {
    this.showUserMenu = !this.showUserMenu;
  }

  /**
   * Opens the hambuerger menu.
   */
  openHamburger(): void {
    this.hamburgerOpen = true;
  }

  /**
   * Closes the hamburger and user menu.
   */
  closeHamburger(): void {
    this.hamburgerOpen = false;
    this.userMenuOpen = false;
  }

  /**
   * Enables / disables click possibility for
   * the "wk" icon.
   */
  disableWKIconClick(): boolean {
    // @ts-ignore
    const overlay = document.querySelector('.sb-overlay');

    if (overlay !== null) {
      const visibilty = overlay.getAttribute('hidden');

      // @ts-ignore
      if (!document.documentElement.classList.contains('touch') &&
        (visibilty === '' || visibilty === null) &&
        this.isShoppingbasketOverlayEnabled()) {
        if (window.innerWidth < 544) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Checks and returns whether or not the
   * overlay for the basket is enabled.
   */
  isShoppingbasketOverlayEnabled(): boolean {
    // @ts-ignore
    return ((this._mpOrderProcess.paths.length > 0 && this._mpOrderProcess.paths.indexOf(this._location.path()) === -1) && !document.documentElement.classList.contains('touch'));
  }

  /**
   * Handles the stick / visiblity behavior of the
   * navbar and the credits bar, when the user
   * scrolls the page up / down.
   */
  private _handleNavAndCredits(): void {
    const scrollTop = window.scrollY;
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');
    const headerHeight = header !== null ? header.getBoundingClientRect().height : 0;

    if (headerHeight < scrollTop && navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv === null) {
        const divElem = document.createElement('div');
        divElem.style.height = navbar.getBoundingClientRect().height + 'px';
        divElem.id = 'sticky-ghost-div';
        navbar.insertAdjacentElement('afterend', divElem);
      }

      if (!navbar.classList.contains('fix')) {
        navbar.classList.add('fix');
      }

      if (!navbar.classList.contains('hl-sticky')) {
        navbar.classList.add('hl-sticky');
      }

      this.navbarIsSticky = true;
    } else if (navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv !== null) {
        ghostDiv.remove();
      }

      if (navbar.classList.contains('fix')) {
        navbar.classList.remove('fix');
      }

      if (navbar.classList.contains('hl-sticky')) {
        navbar.classList.remove('hl-sticky');
      }

      this.navbarIsSticky = false;
    }

    const filterNavBaseline = document.querySelector('.filter-nav-baseline');
    const filterNav = document.querySelector('.filter-nav');

    if (filterNav !== null) {
      if (filterNav.classList.contains('no-header')) {
        if (headerHeight < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      } else {
        const mainNav = document.querySelector('.mainNav');
        const mainNavHeight = mainNav !== null ? mainNav.getBoundingClientRect().height : 0;

        if (filterNavBaseline !== null && filterNavBaseline.getBoundingClientRect().top - mainNavHeight - filterNav.getBoundingClientRect().height < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      }
    }

    const credits = document.getElementById('credits');
    // @ts-ignore
    const userProfileNav = document.getElementById('user-profile__navbar');
    const currScrollTop = window.scrollY;

    if (Math.abs(this._lastScrollTop - currScrollTop) <= this._scrollDelta) {
      return;
    }

    if (navbar !== null) {
      const navbarHeight = window.getComputedStyle(navbar).getPropertyValue('height');

      if (typeof navbarHeight !== 'undefined' && navbarHeight !== null) {
        if (currScrollTop > this._lastScrollTop && currScrollTop > parseInt(navbarHeight.replace('px', ''))) {
          const placeholderStickyDivs = document.querySelectorAll('#placeholder-sticky-div');

          // @ts-ignore
          if (this._sticked && placeholderStickyDivs.lenght > 0 && header !== null && header.classList.contains('stickyNavbar')) {
            placeholderStickyDivs.forEach(placeholderStickyDiv => {
              placeholderStickyDiv.remove();
            });
          }

          if (credits !== null) {
            if (!credits.classList.contains('credits-hidden')) {
              if (userProfileNav !== null) {
                if (userProfileNav.classList.contains('credits-shown')) {
                  userProfileNav.classList.remove('credits-shown');
                }
              }

              if (header !== null) {
                if (!header.classList.contains('stickyNavbar') && !this._sticked) {
                  const stickyElem = document.querySelector('.hl-sticky');

                  if (stickyElem !== null) {
                    stickyElem.insertAdjacentHTML('afterend', `<div id="placeholder-sticky-div" style="height:0;clear:both;top:-${(parseInt(navbarHeight.replace('px', '')) + 8)}px;position:relative;"></div>`);
                    this._sticked = true;
                  }
                }
              }

              credits.classList.add('credits-hidden');
            }
          }
        } else {
          if (credits !== null) {
            if (credits.classList.contains('credits-hidden')) {
              if (userProfileNav !== null) {
                if (userProfileNav.classList.contains('is-sticky')) {
                  if (!userProfileNav.classList.contains('credits-shown')) {
                    userProfileNav.classList.add('credits-shown');
                  }
                } else {
                  if (userProfileNav.classList.contains('credits-shown')) {
                    userProfileNav.classList.remove('credits-shown');
                    this._sticked = false;
                  }
                }
              }

              credits.classList.remove('credits-hidden');
            }
          }
        }
      }

      this._lastScrollTop = currScrollTop;
    }
  }

  /**
   * Updates the points
   */
  updatePunkte(data: any) {
    if (data !== null) {
      this.pointValue = data.Kontostand;
      this.pointValueState = data.StatusKontostand;
      this.pointValuePreview = data.KontostandVorschau;
      this.statusID = data.StatusID;
    }
  }

}
