<div id="sticky-element-width" *ngIf="(ls.locsLoaded | async)">
  <div [hidden]="!rental['showHeader']" class="rk-offers-headline" stickyOnScroll data-stick-element="navbar" id="rk-sticky-headlines">
    <h2 *ngIf="!(grouping && grouping['GruppierFeld'])">{{ls.locs['locReisekonfigurator'].MietwagenAngebote.replace('{0}', filteredRentals.length)}}</h2>
    <h2 *ngIf="grouping['GruppierFeld'] && grouping['GruppierFeld'] === 'Anbieter' ">{{ls.locs['locReisekonfigurator'].MietwagenAngeboteBeiAnbieter.replace('{0}', filteredRentals.length).replace('{1}', rental['Anbieter'])}}</h2>
  </div>
  <article class="margin-top-8 rk-offers-section mietwagen-kachel" id="hotelDetails{{rentalIndex}}" [ngClass]="{detailsOpen: rental['open'] === true}" [hidden]="rental['hidden']">
    <div class="rk-offer-imgs overflow-hidden">
      <div class="owl-carousel owl-loaded">
        <div class="owl-item active">
          <div class="item">
            <div class="blur">
              <img src="{{rental['Image']}}" />
            </div>
            <div class="item-img">
              <img class="position-relative" src="{{rental['Image']}}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rk-offer-summary padding-16">
      <div class="rk-offer-breadcrumb text-color-grey-info">
        <div class="display-inline">
          <span class="icon-text font-size-s">{{rental['Fahrzeugtyp']}}, {{rental['Anbieter']}}</span>
        </div>
      </div>
      <div class="rk-offer-title padding-top-8">
        <h3 class="margin-bottom-8">{{rental['Titel']}}</h3>
      </div>
      <div class="rk-offer-categories display-flex">
        <mp-core-popover *ngIf="rental['AnzahlPersonen']" content="Personen" alignment="left" class="margin-right-8">
            <span>
              <svg version="1.1" id="Ebene_1" width="18px" height="36px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 19.7 37.4" style="enable-background:new 0 0 19.7 37.4;" xml:space="preserve">
              <style type="text/css">
                .st0_personen {
                  fill: #3996D2;
                }

                .st1_personen {
                  fill: #FFFFFF;
                }

                .st2_personen {
                  font-family: 'Roboto-Regular';
                }

                .st3_personen {
                  font-size: 14.9717px;
                }
  </style>
              <title>personen_svg</title>
              <g id="a2e4c653-4423-4959-a264-4d4b0c5894fb">
              <g id="a985a983-1737-478f-b244-319d57ffe943">
              <g>
              <g id="a62a8208-9178-4b37-805c-c1359376da83">
              <circle class="st0_personen" cx="9.9" cy="27.6" r="9.9" style="fill:#3996D2;" />
              <text transform="matrix(1.0038 0 0 1 5.648 32.6817)" class="st1_personen st2_personen st3_personen" style="fill:#fff;font-family:'Roboto-Regular';font-size:14.9717px;">{{rental['AnzahlPersonen'] | integer}}</text>
                      </g>
              <g>
              <circle class="st0_personen" cx="9.9" cy="4.8" r="4.8" style="fill:#3996D2;" />
              <path class="st0_personen" d="M9.9,16.7c2.6,0,5,0.9,7,2.6h2.6v-2.4c0-3.2-6.4-4.8-9.6-4.8s-9.6,1.6-9.6,4.8v2.4h2.6
                          C4.8,17.6,7.3,16.7,9.9,16.7z" style="fill:#3996D2;" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </mp-core-popover>
          <mp-core-popover *ngIf="rental['AnzahlTueren']" content="Türen" alignment="left" class="margin-right-8">
            <span>
              <svg version="1.1" id="Ebene_1" width="18px" height="36px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 20.7 39.2" style="enable-background:new 0 0 20.7 39.2;" xml:space="preserve">
              <style type="text/css">
                .st0_tueren {
                  fill: #3996D2;
                }

                .st1_tueren {
                  fill: #FFFFFF;
                }

                .st2_tueren {
                  font-family: 'Roboto-Regular';
                }

                .st3_tueren {
                  font-size: 14.9717px;
                }
  </style>
              <title>tueren_svg</title>
              <g id="b39f9472-5024-458e-9465-02518d765010">
              <g id="af04142e-d01e-4ba4-baae-88a94282a1e2">
              <g>
              <g id="b3abaa60-9668-4f19-9305-24b1a88525cc">
              <circle class="st0_tueren" cx="9.9" cy="29.3" r="9.9" style="fill:#3996D2;" />
              <text transform="matrix(1.0038 0 0 1 5.6491 34.447)" class="st1_tueren st2_tueren st3_tueren" style="fill:#fff;font-family:'Roboto-Regular';font-size:14.9717px;">{{rental['AnzahlTueren'] | integer}}</text>
                      </g>
              <path class="st0_tueren" d="M1.5,22.4c2.7-3.3,7.2-4.7,11.3-3.5c1.5-3.1,4.4-5.3,7.9-5.7V2.5c0-1.4-1.1-2.5-2.5-2.5H8.3L0.8,7.5l0,14.9
                        c0,0,0,0,0,0H1.5z M18.5,10h-5V8.7h5L18.5,10z M9.2,2.1h8.3c0.6,0,1,0.5,1,1v3.7h-14L9.2,2.1z" style="fill:#3996D2;" />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </mp-core-popover>
          <mp-core-popover *ngIf="rental['HasKlimaanlage']" content="Koffer" alignment="left" class="margin-right-8">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 19.25691 19.25691">
                <g id="fad7c83d-6875-4079-9c47-cfa0b10deae5" data-name="Ebene 2">
                  <g id="fca5050a-a70a-40ed-ae95-7a8bfd699672" data-name="Ebene 1">
                    <path d="M19.25691,8.66561H15.24185L18.36146,5.546,17.00385,4.17875,12.517,8.66561H10.5913V6.73992l4.48686-4.48686L13.71092.89545,10.5913,4.01507V0H8.66561V4.01507L5.546.89545,4.17875,2.25306,8.66561,6.73992V8.66561H6.73992L2.25306,4.17875.89545,5.546,4.01507,8.66561H0V10.5913H4.01507L.89545,13.71092l1.35761,1.36724L6.73992,10.5913H8.66561V12.517L4.17875,17.00385,5.546,18.36146l3.11962-3.11961v4.01506H10.5913V15.24185l3.11962,3.11961,1.36724-1.35761L10.5913,12.517V10.5913H12.517l4.48686,4.48686,1.35761-1.36724L15.24185,10.5913h4.01506Z" style="fill: #3996d2" />
                  </g>
                </g>
              </svg>
            </span>
          </mp-core-popover>
          <mp-core-popover *ngIf="rental['AnzahlKoffer']" content="Koffer" alignment="left" class="margin-right-8">
            <span>
              <svg version="1.1" id="Ebene_1" width="18px" height="36px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 21.4 38" style="enable-background:new 0 0 21.4 38;" xml:space="preserve">
              <style type="text/css">
                .st0_koffer {
                  fill: #3996D2;
                }

                .st1_koffer {
                  fill: #FFFFFF;
                }

                .st2_koffer {
                  font-family: 'Roboto-Regular';
                }

                .st3_koffer {
                  font-size: 14.9717px;
                }
  </style>
              <title>koffer_svg</title>
              <g id="a98286e4-f5f6-48b1-94c3-dd160e682277">
              <g id="ab4ee40e-63f6-43c9-be02-23228d2f08ce">
              <g>
              <g id="f3e86441-8f38-4b1d-8e7d-c3d59c0ffb81">
              <circle class="st0_koffer" cx="10.7" cy="28.1" r="9.9" style="fill:#3996D2;" />
              <text transform="matrix(1.0038 0 0 1 6.2662 33.3493)" class="st1_koffer st2_koffer st3_koffer" style="fill:#fff;font-family:'Roboto-Regular';font-size:14.9717px;">{{rental['AnzahlKoffer'] | integer}}</text>
                      </g>
              <path class="st0_koffer" d="M2.1,20.3h1c4.2-4.1,11-4.1,15.2,0h1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0,0V6.4c0-1.2-0.9-2.1-2.1-2.1
                        c0,0,0,0,0,0H15V2.1C15,1,14,0,12.9,0c0,0,0,0,0,0H8.6C7.4,0,6.4,0.9,6.4,2.1c0,0,0,0,0,0v2.1H2.1C1,4.3,0,5.2,0,6.4c0,0,0,0,0,0
                        l0,11.8C0,19.4,0.9,20.3,2.1,20.3C2.1,20.3,2.1,20.3,2.1,20.3z M8.6,2.1h4.3v2.1H8.6V2.1z" style="fill:#3996D2;" />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </mp-core-popover>
          <mp-core-popover *ngIf="rental['Gangschaltung'] === gearBox['Automatik']" content="Getriebe: Automatik" alignment="left" class="margin-right-8">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 16.7274 19.01256">
                <g id="a249dea6-f163-4aed-b08d-52abf3336344" data-name="Ebene 2">
                  <g id="ab35782d-cafe-490b-83c0-c1642c24f8d3" data-name="Ebene 1">
                    <path d="M12.33988,14.05049H4.37446l-1.789,4.96207H0L7.26029,0H9.45405L16.7274,19.01256H14.155ZM5.13183,11.98732h6.46374L8.35717,3.09476Z" style="fill: #3996d2" />
                  </g>
                </g>
              </svg>
            </span>
          </mp-core-popover>
          <mp-core-popover *ngIf="rental['Gangschaltung'] === gearBox['Manuell']" content="Getriebe: Manuell" alignment="left" class="margin-right-8">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 20.62232 20.34329">
                <g id="a19c2a45-86e0-41b7-aeac-9c446f87cb6a" data-name="Ebene 2">
                  <g id="e0f3accb-896a-48f8-8d1a-c24a18f725e0" data-name="Ebene 1">
                    <path d="M17.84824,0a2.77495,2.77495,0,0,0-.9247,5.391V9.247H11.23586V5.391a2.77409,2.77409,0,1,0-1.84939,0V9.247H3.69878V5.391a2.77409,2.77409,0,1,0-1.84939,0V14.9523a2.77409,2.77409,0,1,0,1.84939,0v-3.856H9.38647v3.856a2.77409,2.77409,0,1,0,1.84939,0v-3.856h7.53707V5.391A2.77495,2.77495,0,0,0,17.84824,0Z" style="fill: #3996d2" />
                  </g>
                </g>
              </svg>
            </span>
          </mp-core-popover>
      </div>
      <div class="rk-offer-price text-color-info">
        <span class="font-size-xl">{{rental['Punkte'] | integer}}</span>
        <span class="font-size-xl">{{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div *ngIf="!permissions['CanUseReisekonfigurator']"><span class="font-size-xs">{{ ls.locs['locReisekonfigurator'].RkNichtErlaubt }}</span></div>
      <div *ngIf="permissions['CanUseReisekonfigurator']" class="rk-offer-details-btn">
        <button class="mp-button _prim" (click)="goToCheck(rental)">{{ ls.locs['locReisekonfigurator'].ZurBuchung }}</button>
        <button [ngClass]="rental['open'] ? '_dark' : ''"
                (click)="rental['open'] = !rental['open']; setOpenedOffers();"
                class="mp-button _sec margin-top-8">
          {{rental['open'] ? ls.locs['locReisekonfigurator'].DetailsAusblenden : ls.locs['locReisekonfigurator'].DetailsAnsehen}}
        </button>
      </div>
    </div>
  </article>
  <mp-rk-rental-details [otherDropOffLocation]="otherDropOffLocation" [rental]="rental" [show]="rental['open']" [locations]="locations" class="offer-details bg-color-white display-block margin-top-8"></mp-rk-rental-details>
</div>
