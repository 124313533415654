<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)" class="layout-container reiseaktionen-editor-width ng-scope box-padding position-relative" id="#travel-activities-editor">
  <a mpCoreSidebarHelpIcon
     #helpIcon="mpCoreSidebarHelpIcon"
     id="help-sidebar-icon"
     class="position-absolute right-4 top-4"
     [hidden]="!helpIcon.showHelpSidebar"
     (click)="helpIcon.openHelpSidebar($event)">
    <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
      <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
    </mp-core-tooltip>
  </a>
  <div id="nav-breadcrumb"
       class="display-flex flex-align-items-center margin-bottom-8">
    <div id="breadcrumb">
      <a class="mp-button text-color-grey-info"
         (click)="goBack($event)">
        {{ ls.locs['loc'].Zurueck }}
      </a>
      <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
      <a class="mp-button text-color-grey-info font-weight-regular"
         href="/">
        {{ ls.locs['loc'].Home }}
      </a>
      <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
      <a class="mp-button text-color-grey-info font-weight-regular position-relative top-2"
         [hidden]="stage['Id']" [innerHTML]="ls.locs['locReisekonfigurator'].ReiseaktionAnlegenBreadcrumb">
      </a>
    </div>
  </div>

  <div class="reiseaktionen-body margin-top-16">
    <div class="margin-bottom-8 display-flex flex-direction-column">
      <mp-core-input class="fallback-input" label="{{ ls.locs['loc'].TitelMenu }}" [mpRequired]="true" mpId="txtAktionsKey" mpType="text" [(model)]="title" [hasError]="errors['activity.Titel'] ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errors['activity.Titel']" [model]="errors['activity.Titel']"></mp-core-form-error>
    </div>
  </div>
  <div class="reiseaktionen-body margin-top-16">
    <div class="margin-bottom-24 display-flex flex-direction-column">
      <mp-core-input class="fallback-input" label="{{ ls.locs['loc'].SboVerrechnungshinweis }}" [mpRequired]="true" mpId="txtVerrechnungshinweis" mpType="text" [(model)]="offsettingHint" [hasError]="errors['activity.Aktionscode'] ? true : false"></mp-core-input>
      <mp-core-form-error *ngIf="errors['activity.Aktionscode']" [model]="errors['activity.Aktionscode']"></mp-core-form-error>
    </div>
    <div class="margin-bottom-8 display-flex flex-direction-column">
      <mp-core-select class="fallback-select" label="{{ ls.locs['locReisekonfigurator'].ThemenbuehneWaehlen }}" [mpRequired]="true" mpId="txtBuehne" [(model)]="stage" [elements]="stages" textProperty="TitelFuerMenu" valueProperty="Id" [hasError]="errors['activity.BuehnenKey'] ? true : false"></mp-core-select>
      <mp-core-form-error *ngIf="errors['activity.BuehnenKey']" [model]="errors['activity.BuehnenKey']"></mp-core-form-error>
    </div>
  </div>
  <div id="rk-search-wrapper" *ngIf="travelTypeLoaded && showTcFilter" class="display-flex flex-justify-cont-center flex-align-items-center z-index-2">
    <div id="rk-search" class="padding-16 width-100-pcnt">
      <div id="tab-wrapper" class="display-flex flex-wrap">
        <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowHotel" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['NurHotel'])" [ngClass]="tab === 0 || tab === travelType['NurHotel'] ? 'active' : ''">
          <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].Hotel}}</span>
          <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].HotelSubHeader | format:[minHotelCategory]}}</span>
        </div>
        <article class="search-elements-wrapper">
          <div class="rk-such-bereich display-flex" [hidden]="tab !== 0 && tab !== travelType['NurHotel']">
            <mp-rk-search-area-hotel-and-flight [travelType]="travelType['NurHotel']" [(params)]="params" [hideButton]="true"></mp-rk-search-area-hotel-and-flight>
          </div>
        </article>
        <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowHotelUndFlug" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['HotelUndFlug'])" [ngClass]="tab === travelType['HotelUndFlug'] ? 'active' : ''">
          <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].FlugUndHotel}}</span>
          <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].FlugUndHotelSubHeader}}</span>
        </div>
        <article class="search-elements-wrapper">
          <div class="rk-such-bereich display-flex" [hidden]="tab !== travelType['HotelUndFlug']">
            <mp-rk-search-area-hotel-and-flight [travelType]="travelType['HotelUndFlug']" [(params)]="params" [hideButton]="true"></mp-rk-search-area-hotel-and-flight>
          </div>
        </article>
        <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowNurFlug" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['NurFlug'])" [ngClass]="tab === travelType['NurFlug'] ? 'active' : ''">
          <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].NurFlug}}</span>
          <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].NurFlugSubHeader}}</span>
        </div>
        <article class="search-elements-wrapper">
          <div class="rk-such-bereich display-flex" [hidden]="tab !== travelType['NurFlug']">
            <mp-rk-search-area-only-flight [travelType]="travelType['NurFlug']" [(params)]="params" [hideButton]="true"></mp-rk-search-area-only-flight>
          </div>
        </article>
        <div *ngIf="mpSettings.settings['ReisekonfiguratorSettings'].ShowMietwagen" class="rk-tab-header pointer-hover text-align-left padding-8" (click)="setTab(travelType['Mietwagen'])" [ngClass]="tab === travelType['Mietwagen'] ? 'active' : ''">
          <span class="rk-tab-header-main text-transform-uppercase display-block">{{ls.locs['locReisekonfigurator'].Mietwagen}}</span>
          <span class="rk-tab-header-sub display-block">{{ls.locs['locReisekonfigurator'].MietwagenSubHeader}}</span>
        </div>
        <article class="search-elements-wrapper">
          <div class="rk-such-bereich display-flex" [hidden]="tab !== travelType['Mietwagen']">
            <mp-rk-search-area-rental [travelType]="travelType['Mietwagen']" [(params)]="params" [hideButton]="true"></mp-rk-search-area-rental>
          </div>
        </article>
      </div>
    </div>
  </div>
  <div class="margin-top-24">
    <button type="button" class="mp-button _prim" (click)="save()">{{ ls.locs['loc'].Speichern }}</button>
  </div>
  <div [hidden]="!success">
    {{ ls.locs['loc'].SaveMessageOK1 }}
  </div>
</div>
