import { Component, OnInit, OnDestroy, ViewChildren, OnChanges, SimpleChanges, ViewEncapsulation, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CarouselService } from 'ngx-owl-carousel-o/lib/services/carousel.service';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { CustomEventService, CustomEventType, CustomEvent } from './../../../../services/custom-event.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { MpCommunicationProcessService } from './../../../../services/mp-communication-process.service';
import { FroalaConfigService } from './../../../../services/froala-config.service';

import { Stage } from './stage';
import { ThemeStageTemplate } from './theme-stage-template';

/**
 * This class provides the data and the
 * functionalities for the theme stage
 * editor.
 */
@Component({
  selector: 'mp-core-theme-stage-editor',
  templateUrl: './theme-stage-editor.component.html',
  styleUrls: ['./theme-stage-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeStageEditorComponent implements OnInit, OnDestroy {

  @ViewChildren('owlElement') owlElement: any;
  @ViewChildren('fileUpload') fileUpload: any;

  public admin: boolean = false;
  public selectedContent: { [key: string]: any } = {};
  public teaserOpen: boolean = false;
  public button1Open: boolean = false;
  public button2Open: boolean = false;
  public stage: Stage | undefined;
  public themeStage: { [key: string]: any } = {};
  public communicationProcess: boolean = false;
  public role: string = 'manager';
  public themeStageTemplate: number | undefined;
  public themeStageTemplates: Array<ThemeStageTemplate> = [];
  public defaultImagePath: string = '/themes/default/media/buehnen/home/VorlageLeer.jpg';
  public hints: { [key: string]: any } = {
    showRevertChanges: false,
    showSaveThemeStage: false,
    showNotApprove: false,
    showApprove: false,
    showDeleteTeaser: false,
    showDeleteButton1: false,
    showDeleteButton2: false,
    showDeleteContent: false,
    showChangeLanguage: false,
    showAddLanguage: false
  };  
  public selectableLanguages: Array<any> = [];
  public typeSidebarStageAreaParams: { [key: string]: any } = {
    defaultText: '',
    text: '',
    title: '',
    limit: 1,
    save: () => { }
  };  
  public groupSidebarParams: { [key: string]: any } = {
    defaultText: '',
    text: '',
    title: '',
    save: () => { }
  };
  public groupingSidebarParams: { [key: string]: any } = {
    defaultText: '',
    text: '',
    title: '',
    save: () => { }
  };
  public isGrouping: boolean = false;
  public countriesSidebarParams: { [key: string]: any } = {
    defaultText: '',
    text: '',
    title: '',
    save: () => { }
  };
  public tbsList: Array<any> = [];
  public isRecivedGrouping: boolean = false;
  public buttonTypes: Array<any> = [];
  public stageDate: { [key: string]: any } = {
    text: '',
    save: () => { },
    minDate: moment()
  };
  public errors: { [key: string]: any } = {};
  public languageCarouselProperties: OwlOptions = {
    loop: false,
    nav: true,
    navText: ['<i class="svg-icon __size-32 __top-0 __clr-default __nav-prev">', '<i class="svg-icon __size-32 __top-0 __clr-default __nav-next">'],
    dots: false,
    autoWidth: true,
    margin: 4,
    items: 3,
    responsive: {
      0: {
        items: 1
      },
      275: {
        items: 1
      },
      475: {
        items: 2
      },
      678: {
        items: 3
      }
    }
  };
  public tsNameTextObj: { [key: string]: any } = {
    headlinePart: '',
    datePart: ''
  };
  public uploadSuccessfully: boolean = false;
  public file: File | undefined;
  public errorUpload: string = '';
  public languages: { [key: string]: any } = {};
  public dateFixedByCp: boolean = false;
  public froalaOptionsTeaser: { [key: string]: any } = {};
  public froalaOptionsTeaserLoaded: boolean = false;
  public showLanguageCarousel: boolean = false;
  public hideHint = this._hideHint.bind(this);
  public saveFunc = this.save.bind(this);
  public loadDataFunc = this.loadData.bind(this);
  public deleteContentFunc = this.deleteContent.bind(this);
  public deleteTeaserFunc = this.deleteTeaser.bind(this);
  public deleteButton1Func = this.deleteButton1.bind(this);
  public deleteButton2Func = this.deleteButton2.bind(this);
  public addLanguageFunc = this.addLanguage.bind(this);
  public changeLanguageFunc = this.changeLanguage.bind(this);
  public newStageFunc = this.newStage.bind(this);

  private _carouselService: any;
  private _token: string | null = sessionStorage.getItem('token');
  private _date1900: moment.Moment = moment.utc([1900, 0, 1]);
  private _date2050: moment.Moment = moment.utc([2050, 0, 1]);
  private _language: string = '';
  private _languages: Array<any> = [];
  private _lang: { [key: string]: any } = {};
  private _froalaTeaser: any;
  private _permissionsLoaded: boolean = false;
  private _cpKey: string = 'themenbuehnen-editor';
  private _showCpSkipStepConfirm: boolean = false;
  private _isTitleChanged: boolean = false;
  private _isRecivedDate: boolean = false;
  private _froalaEditor: any;
  private _recivedDate: string = '';
  private _contentToDelete: { [key: string]: any } = {};
  private _contentToChange: { [key: string]: any } = {};
  private _timeInTSName: string = ` von ${moment().format('DD.MM.YYYY HH:MM')}`;
  private _themeStageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _themeStageLoadedObservable: Observable<boolean> = this._themeStageLoaded.asObservable();
  private _stageChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _stageChangeObservable: Observable<boolean> = this._stageChange.asObservable();
  private _getLanguageSubscription: Subscription | undefined;
  private _getFroalaOptionsSubscription: Subscription | undefined;
  private _getPermissionsSubscription: Subscription | undefined;
  private _locsLoadedSubscription: Subscription | undefined;
  private _getThemeStageSubscription: Subscription | undefined;
  private _getTypeSidebarStageAreaParamsSubscription: Subscription | undefined;
  private _getGroupSidebarParamsSubscription: Subscription | undefined;
  private _getGroupingSidebarParamsSubscription: Subscription | undefined;
  private _getCountriesSidebarParamsSubscription: Subscription | undefined;
  private _tbsListSubscription: Subscription | undefined;
  private _deleteThemeStageSubscription: Subscription | undefined;
  private _getButtonTypesSubscription: Subscription | undefined;
  private _getThemeStageTemplatesSubscription: Subscription | undefined;
  private _themeStageLoadedSubscription: Subscription | undefined;
  private _saveThemeStageSubscription: Subscription | undefined;
  private _locsLoadedForEmptyStageSubscription: Subscription | undefined;
  private _sentTimeFromSidebarEventSubscription: Subscription | undefined;
  private _sendGroupingBoolEventSubscription: Subscription | undefined;
  private _fileUploadSubscription: Subscription | undefined;
  private _getLanguagesForDropdownSubscription: Subscription | undefined;
  private _stageChangedSubscription: Subscription | undefined;
  private _loadDataQueryParamsSubscription: Subscription | undefined;
  private _queryParamsSubscription: Subscription | undefined;
  private _cpStepsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _permissions: MpPermissionsService,
    public sanitizer: DomSanitizer,
    private _router: Router,
    private _route: ActivatedRoute,
    private _mpMessaging: MpMessagingService,
    private _customEventService: CustomEventService,
    private _mpCoreService: MpCoreService,
    public cp: MpCommunicationProcessService,
    private _froalaConfig: FroalaConfigService
  ) { }

  /**
   * Gets some basic data, and triggers
   * functions to initially load
   * additional data (like groups, counries,
   * and so on).
   */
  ngOnInit(): void {
    this._getLanguageSubscription = this._apiService.getRequest('/api/Language/getLanguage').subscribe((data: any) => {
      this._language = data.Records[0];
      this._getFroalaOptions();
    });

    this._getPermissionsSubscription = this._permissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.admin = this._permissions.permissions.IsAdmin;
        this._permissionsLoaded = true;

        if (this.admin) {
          this.role = 'admin';
        }
      }
    });

    this._permissions.getPermissions();

    if (Object.keys(this.ls.locs).length > 0) {
      this._initialDataLoads();
    } else {
      this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._initialDataLoads();
        }
      });
    }

    this._tbsListSubscription = this._apiService.getRequest('/api/Texte/GetTextbausteine').subscribe((data: any) => {
      this.tbsList = data.Records;
    });

    this._themeStageLoadedSubscription = this._themeStageLoadedObservable.subscribe((loaded: boolean) => {
      if (loaded) {
        if (typeof this.themeStageTemplate !== 'undefined' && typeof this.stage !== 'undefined' && typeof this.stage.Id === 'undefined') {
          this.loadData(this.themeStageTemplate, true);
        }
      }

      this.loadData();
    });

    this._sentTimeFromSidebarEventSubscription = this._customEventService.on(CustomEventType.SendTimeFromSidebarPeriodTime).subscribe((event: CustomEvent<any>) => {
      this._recivedDate = event.payload;

      if (!this._isTitleChanged) {
        if (!this._isRecivedDate) {
          if (typeof this.stage !== 'undefined') {
            this.tsNameTextObj['datePart'] = this._recivedDate;
            this.stage.Titel = this.tsNameTextObj['headlinePart'] + ' ' + this.tsNameTextObj['datePart'];
            this._stageChange.next(true);
          }
        } else {
          if (typeof this.stage !== 'undefined') {
            this.tsNameTextObj['datePart'] = this._timeInTSName;
            this.stage.Titel = this.tsNameTextObj['headlinePart'] + ' ' + this.tsNameTextObj['datePart'];
            this._isRecivedDate = true;
            this._stageChange.next(true);
          }
        }
      }
    });

    this._sendGroupingBoolEventSubscription = this._customEventService.on(CustomEventType.SendGroupingBool).subscribe((event: CustomEvent<any>) => {
      this.isRecivedGrouping = event.payload;
    });

    this._getLanguagesForDropdownSubscription = this._apiService.getRequest('/api/Language/GetLanguagesForDropdown').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length === 1 && data.Records[0].Languages) {
        this._languages = data.Records[0].Languages;
        this._lang = this._languages[0];

        if (typeof this.stage !== 'undefined' && typeof this.stage.Contents[0] !== 'undefined') {
          if (!this.stage.Contents[0].Language) {
            this.stage.Contents[0].Language = this._lang['Kuerzel'];
            this._stageChange.next(true);
          }
        }

        const reducedLanguages = this._languages.reduce((r: any, v: any, i: number, a: any[], k: any = v.length) => ((r[k] || (r[k] = [])).push(v), r), {});

        Object.keys(reducedLanguages).map((key: string) => {
          if (typeof this.owlElement !== 'undefined') {
            if (typeof this._carouselService === 'undefined' && typeof this.owlElement.first !== 'undefined') {
              this._carouselService = this.owlElement.first.carouselService as CarouselService;
            }
          }
          for (let i = 0; i < reducedLanguages[key].length; i++) {
            this.languages[reducedLanguages[key][i]['Kuerzel']] = reducedLanguages[key][i];
          }
          
        });

        this._updateSelectableLanguages();
        this.showLanguageCarousel = true;
      }
    });

    this._stageChangedSubscription = this._stageChangeObservable.subscribe((changed: boolean) => {
      if (changed) {
        this.updatePreview();
      }
    });

    this._queryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['p'] !== 'undefined' && params['p'] === '1') {
        this.communicationProcess = true;
        this.dateFixedByCp = false;
        const rewardData = this.cp.loadStep('belohnungs-editor');

        if (rewardData && rewardData.BelohnungsartObject && rewardData.BelohnungsartObject.FixedDateForEmail) {
          this.dateFixedByCp = true;
        }

        if (this.cp.checkStepIsSkipped(this._cpKey))
          this._showCpSkipStepConfirm = true;

        this.cp.isValidStep(this._cpKey);

        this._cpStepsSubscription = this.cp.stepsObserver.subscribe((steps: { [key: string]: any }) => {
          if (Object.keys(this.cp.steps).length > 0) {
            this.cp.steps[this._cpKey].Save = this.saveStep;
          }
        });
      }
    });
  }

  /**
   * Triggered, when headline changed.
   * Sets the theme stages name and
   * some other texts for the stage.
   */
  onChangeHeadline(): void {
    if (!this._isTitleChanged) {
      let tsNameText = '';

      if (this.selectedContent['Headline'] && this._recivedDate !== '') {
        this.tsNameTextObj['headlinePart'] = this.selectedContent['Headline'];
        this.tsNameTextObj['datePart'] = this._recivedDate;
        this._isRecivedDate = false;
      } else if (this.selectedContent['Headline'] && this._recivedDate === '') {
        this.tsNameTextObj['headlinePart'] = this.selectedContent['Headline'];
        this.tsNameTextObj['datePart'] = this._timeInTSName;
        this._isRecivedDate = false;
      } else if (!this.selectedContent['Headline'] && this._recivedDate !== '') {
        this.tsNameTextObj['headlinePart'] = '';
        this.tsNameTextObj['datePart'] = this._recivedDate;
        this._isRecivedDate = false;
      } else if (!this.selectedContent['Headline'] && this._recivedDate === '') {
        this.tsNameTextObj['headlinePart'] = '';
        this.tsNameTextObj['datePart'] = this._timeInTSName;
        this._isRecivedDate = false;
      }
      tsNameText = this.tsNameTextObj['headlinePart'] + ' ' + this.tsNameTextObj['datePart'];
      if (typeof this.stage !== 'undefined') {
        this.stage.Titel = tsNameText;
        this._stageChange.next(true);
      }
    }
  }

  /**
   * Assigns the CarouselService, when owl element
   * is available.
   */
  ngAfterViewInit(): void {
    if (typeof this._carouselService === 'undefined' && typeof this.owlElement !== 'undefined' && typeof this.owlElement.first !== 'undefined') {
      this._carouselService = this.owlElement.first.carouselService as CarouselService;
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLanguageSubscription !== 'undefined') {
      this._getLanguageSubscription.unsubscribe();
    }

    if (typeof this._getFroalaOptionsSubscription !== 'undefined') {
      this._getFroalaOptionsSubscription.unsubscribe();
    }

    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }

    if (typeof this._getThemeStageSubscription !== 'undefined') {
      this._getThemeStageSubscription.unsubscribe();
    }

    if (typeof this._getTypeSidebarStageAreaParamsSubscription !== 'undefined') {
      this._getTypeSidebarStageAreaParamsSubscription.unsubscribe();
    }

    if (typeof this._getGroupSidebarParamsSubscription !== 'undefined') {
      this._getGroupSidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getGroupingSidebarParamsSubscription !== 'undefined') {
      this._getGroupingSidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getCountriesSidebarParamsSubscription !== 'undefined') {
      this._getCountriesSidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._tbsListSubscription !== 'undefined') {
      this._tbsListSubscription.unsubscribe();
    }

    if (typeof this._deleteThemeStageSubscription !== 'undefined') {
      this._deleteThemeStageSubscription.unsubscribe();
    }

    if (typeof this._getButtonTypesSubscription !== 'undefined') {
      this._getButtonTypesSubscription.unsubscribe();
    }

    if (typeof this._getThemeStageTemplatesSubscription !== 'undefined') {
      this._getThemeStageTemplatesSubscription.unsubscribe();
    }

    if (typeof this._themeStageLoadedSubscription !== 'undefined') {
      this._themeStageLoadedSubscription.unsubscribe();
    }

    if (typeof this._saveThemeStageSubscription !== 'undefined') {
      this._saveThemeStageSubscription.unsubscribe();
    }

    if (typeof this._locsLoadedForEmptyStageSubscription !== 'undefined') {
      this._locsLoadedForEmptyStageSubscription.unsubscribe();
    }

    if (typeof this._sentTimeFromSidebarEventSubscription !== 'undefined') {
      this._sentTimeFromSidebarEventSubscription.unsubscribe();
    }

    if (typeof this._sendGroupingBoolEventSubscription !== 'undefined') {
      this._sendGroupingBoolEventSubscription.unsubscribe();
    }

    if (typeof this._fileUploadSubscription !== 'undefined') {
      this._fileUploadSubscription.unsubscribe();
    }

    if (typeof this._getLanguagesForDropdownSubscription !== 'undefined') {
      this._getLanguagesForDropdownSubscription.unsubscribe();
    }

    if (typeof this._stageChangedSubscription !== 'undefined') {
      this._stageChangedSubscription.unsubscribe();
    }

    if (typeof this._loadDataQueryParamsSubscription !== 'undefined') {
      this._loadDataQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._cpStepsSubscription !== 'undefined') {
      this._cpStepsSubscription.unsubscribe();
    }
  }

  /**
   * Loads and provides data on init.
   */
  private _initialDataLoads(): void {

    const shopLanguage: { [key: string]: any } = {
      key: this._language !== '' ? this._language : 'de'
    }

    this._getTypeSidebarStageAreaParamsSubscription = this._apiService
      .getRequest('/api/Themenbuehnen/GetThemeStageAreas', false, shopLanguage['key']).subscribe((data: any) => {
        this.typeSidebarStageAreaParams['list'] = data.Records;
        this._updateLoadedThemeStages();
    });

    this.typeSidebarStageAreaParams = {
      defaultText: this.ls.locs['loc'].SelectThemeStageArea,
      text: this.ls.locs['loc'].SelectThemeStageArea,
      title: this.ls.locs['loc'].SelectThemeStageArea,
      limit: 1,
      save: () => {
        if (typeof this.typeSidebarStageAreaParams['selectedList'] !== 'undefined' && this.typeSidebarStageAreaParams['selectedList'].length > 0) {
          this.typeSidebarStageAreaParams['text'] = this.typeSidebarStageAreaParams['selectedList'].map((list: any) => {
            return list['DisplaytextKurz'];
          });
        } else {
          this.typeSidebarStageAreaParams['text'] = this.typeSidebarStageAreaParams['defaultText'];
        }

        if (typeof this.stage !== 'undefined') {

          this.stage.IsPublicAreaSelected = this._isPublicArea();
          this.stage.ThemeStageAreasSelected = this.typeSidebarStageAreaParams['selectedList'];

          if (this.stage.IsPublicAreaSelected) {
            this.selectedContent['Button1'] = undefined;
            this.selectedContent['Button2'] = undefined;
            this.selectedContent['Detailtext'] = undefined;
            this.selectedContent['Text'] = undefined;
          }

          if (!this.selectedContent['Teaser'] || this.selectedContent['Teaser'] === '') {
            this.stage.IsTeaserComponentAdded = false;
          }
          else {
            this.stage.IsTeaserComponentAdded = true;
          }
          
          this._updateSelectableElements(this.selectedContent);
          this._stageChange.next(true);
        }
      }
    }

    this._getButtonTypesSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetButtonTypen').subscribe((data: any) => {
      this.buttonTypes = data.Records;
    });

    this._getGroupSidebarParamsSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetGruppen', false).subscribe((data: any) => {
      this.groupSidebarParams['list'] = data.Records;
      this._setSelectedGroups();
    });

    this.groupSidebarParams = {
      defaultText: this.ls.locs['loc'].EmpfaengergruppeAuswaehlen,
      text: this.ls.locs['loc'].EmpfaengergruppeAuswaehlen,
      title: this.ls.locs['loc'].EmpfaengergruppeAuswaehlen,
      save: () => {
        if (typeof this.groupSidebarParams['selectedList'] !== 'undefined' && this.groupSidebarParams['selectedList'].length > 0) {
          this.groupSidebarParams['text'] = this.groupSidebarParams['selectedList'].map((list: any) => {
            return list['DisplaytextKurz'];
          }).reduce((a: string, b: string) => {
            return a + ', ' + b;
          });
        } else {
          this.groupSidebarParams['text'] = this.groupSidebarParams['defaultText'];
        }

        if (typeof this.stage !== 'undefined' && typeof this.groupSidebarParams['selectedList'] !== 'undefined' && this.groupSidebarParams['selectedList'].length > 0) {
          this.stage.Gruppen = this.groupSidebarParams['selectedList'];
          this._stageChange.next(true);
        }        
      }
    };   

    this.groupingSidebarParams = {
      defaultText: this.ls.locs['loc'].ThemenbuehnenGruppierungenAuswaehlen,
      text: this.ls.locs['loc'].ThemenbuehnenGruppierungenAuswaehlen,
      title: this.ls.locs['loc'].ThemenbuehnenGruppierungenAuswaehlen,
      save: () => {
        if (typeof this.groupingSidebarParams['selectedList'] !== 'undefined' && this.groupingSidebarParams['selectedList'].length > 0) {
          this.groupingSidebarParams['text'] = this.groupingSidebarParams['selectedList'].map((list: any) => {
            return list['DisplaytextKurz'];
          }).reduce((a: string, b: string) => {
            return a + ', ' + b;
          });
        } else {
          this.groupingSidebarParams['text'] = this.groupingSidebarParams['defaultText'];
        }

        if (typeof this.stage !== 'undefined') {
          this.stage.Gruppierungen = this.groupingSidebarParams['selectedList'];
          this._stageChange.next(true);
        }
      }
    };

    this._getGroupingSidebarParamsSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetGruppierungen').subscribe((data: any) => {
      this.groupingSidebarParams['list'] = data.Records;
      this._setSelectedGrouping();
    });

    this.countriesSidebarParams = {
      defaultText: this.ls.locs['loc'].ThemenbuehnenLaenderAuswaehlen,
      text: this.ls.locs['loc'].ThemenbuehnenLaenderAuswaehlen,
      title: this.ls.locs['loc'].ThemenbuehnenLaenderAuswaehlen,
      save: () => {
        if (typeof this.countriesSidebarParams['selectedList'] !== 'undefined' && this.countriesSidebarParams['selectedList'].length > 0) {
          this.countriesSidebarParams['text'] = this.countriesSidebarParams['selectedList'].map((list: any) => {
            return list['DisplaytextKurz'];
          }).reduce((a: string, b: string) => {
            return a + ', ' + b;
          });
        } else {
          this.countriesSidebarParams['text'] = this.countriesSidebarParams['defaultText'];
        }

        if (typeof this.stage !== 'undefined') {
          this.stage.Laender = this.countriesSidebarParams['selectedList'];
          this._stageChange.next(true);
        }
      }
    };

    this._getCountriesSidebarParamsSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetLaender').subscribe((data: any) => {
      this.countriesSidebarParams['list'] = data.Records;
      this._setSelectedCountries();
    });

    this._getThemeStageTemplatesSubscription = this._apiService.getRequest('/api/Themenbuehnen/GetThemenbuehnenTemplates').subscribe((data: any) => {
      this.themeStageTemplates = data.Records;

      if (data.Records[0]) {
        this.themeStageTemplate = data.Records[0].Identifier;
      }

      this._themeStageLoaded.next(true);

      if (typeof this.stage !== 'undefined' && typeof this.stage.Id === 'undefined') {
        this.loadTemplate();
      }
    });

    this.stageDate = {
      text: this.ls.locs['loc'].BitteWaehlen,
      save: () => {
        if (this.stageDate['rangeStart']) {
          if (!this.stageDate['rangeEnd'] || this.stageDate['rangeStart'] === this.stageDate['rangeEnd']) {
            if (this.stageDate['UhrzeitVonStunden'] && this.stageDate['UhrzeitVonMinuten'])
              this.stageDate['text'] = this.ls.locs['loc'].ZeitraumOffenText
                .replace('{0}',
                this.stageDate['rangeStart']
                    .clone()
                  .hour(this.stageDate['UhrzeitVonStunden'])
                  .minute(this.stageDate['UhrzeitVonMinuten'])
                    .local().format('DD.MM.YYYY HH:mm'));
            else
              this.stageDate['text'] = this.ls.locs['loc'].ZeitraumOffenText.replace('{0}',
                this.stageDate['rangeStart'].local().format('DD.MM.YYYY HH:mm'));
          } else {
            this.stageDate['text'] = this.ls.locs['loc'].ZeitraumText
              .replace('{0}', this.stageDate['rangeStart'].local().format('DD.MM.YYYY HH:mm'))
              .replace('{1}', this.stageDate['rangeEnd'].local().format('L'));
          }

        } else {
          this.stageDate['text'] = this.ls.locs['loc'].Zeitraum;
        }

        if (typeof this.stage !== 'undefined') {
          this.stage.Von = (this.stageDate['rangeStart'] !== undefined ? this.stageDate['rangeStart'] : moment());
          this.stage.Bis = (this.stageDate['rangeEnd'] !== undefined ? this.stageDate['rangeEnd'] : this._date2050);
          if (this.stageDate['UhrzeitVonStunden'] !== undefined) this.stage.UhrzeitVonStunden = this.stageDate['UhrzeitVonStunden'];
          if (this.stageDate['UhrzeitVonMinuten'] !== undefined) this.stage.UhrzeitVonMinuten = this.stageDate['UhrzeitVonMinuten'];
          if (this.stageDate['UhrzeitBisStunden'] !== undefined) this.stage.UhrzeitBisStunden = this.stageDate['UhrzeitBisStunden'];
          if (this.stageDate['UhrzeitBisMinuten'] !== undefined) this.stage.UhrzeitBisMinuten = this.stageDate['UhrzeitBisMinuten'];
          this._stageChange.next(true);
        }
      },
      minDate: moment()
    };
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /*
   * Gets the froala editor options from
   * the backend.
   */
  private _getFroalaOptions(): void {
    this._getFroalaOptionsSubscription = this._apiService.getRequest('/api/Froala/GetFroalaConfig?module=teaser').subscribe((data: any) => {
      const froalaConfig = data.Records[0];
      const requestVerificationTokenInput: HTMLInputElement | null = document.querySelector('input[name="__RequestVerificationToken"]');
      const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

      this.froalaOptionsTeaser = Object.assign(this._froalaConfig.getConfig(), {
        language: this._language !== '' ? this._language : 'de',
        toolbarButtons: froalaConfig.ToolbarButtons,
        colorsBackground: froalaConfig.BackgroundColours,
        colorsStep: froalaConfig.ColourColumnCount,
        colorsText: froalaConfig.TextColours,
        charCounterMax: froalaConfig.CharacterCount,
        height: froalaConfig.Height,
        heightMin: froalaConfig.HeightMin,
        heightMax: froalaConfig.HeightMax,
        linkEditButtons: ['linkOpen', 'linkEdit'],
        requestHeaders: {
          'X-XSRF-TOKEN': xsrfToken || '',
          'Authorization': 'Bearer ' + this._token
        },
        events: Object.assign(this._froalaConfig.getEvents(), {
          'initialized': (editor: any) => {
            this._froalaEditor = editor;
          },
          'blur': (editor: any) => {
            const currentTarget = editor.currentTarget;

            if (currentTarget.innerHTML === '<p><br></p>' || currentTarget.innerText === '') {
              currentTarget.innerHTML = '';
            }
          }
        })
      });

      this.froalaOptionsTeaserLoaded = true;
      this._initFroala();
    });
  }

  /**
   * Retrieves a cookie value by name
   **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Initializes the froala editor.
   */
  private _initFroala(): void {
    if (this._froalaTeaser && Object.keys(this.froalaOptionsTeaser).length > 0) {
      this._froalaTeaser.destroy();
      this._froalaTeaser.initialize(this.froalaOptionsTeaser);
    }
  }

  /**
   * Gets the froala controls from the
   * froala init function.
   */
  getFroalaControlsTeaser(froala: any): void {
    this._froalaTeaser = froala;
    this._froalaEditor = froala.getEditor();
    this._initFroala();
  }

  /**
   * Updates the teaser by the froala
   * editor.
   */
  changedTeaserByFroale(evt: any): void {
    this.selectedContent['Teaser'] = evt;
    this.updatePreview();
  }

  /**
   * Sets the selected content.
   */
  setSelectedContent(content: { [key: string]: any }): void {
    this.selectedContent = content;
    this.teaserOpen = false;
    this.button1Open = false;
    this.button2Open = false;

    if (typeof this.stage !== 'undefined' && this.stage.Contents[0]) {
      if (this.stage.Contents[0]) {
        const stageContents = this.stage.Contents[0];

        if (stageContents.Teaser) {
          this.teaserOpen = true;
          this.stage!.IsTeaserComponentAdded = true;
        }
        if (stageContents.Button1)
          this.button1Open = true;
        if (stageContents.Button2)
          this.button2Open = true;
      }
    }

    this.updatePreview();
  }

  /**
   * Updates the preview of the theme stage.
   */
  updatePreview(): void {
    if (this.communicationProcess && typeof this.stage !== 'undefined') {
      this.stage.Titel = this.cp.loadStep('titel');
    }

    if (typeof this.stage !== 'undefined' && this.stage.Contents && this.stage.Contents.length > 0) {
      const currentContent = Object.keys(this.selectedContent).length > 0 ? this.selectedContent : this.stage.Contents.find((content: any) => {
        // @ts-ignore
        return content['Language'] === this.stage.Contents[0].Language;
      });

      const themeStage = {
        Titel: currentContent.Headline,
        CssClass: currentContent.CssClass,
        Teaser: currentContent.Teaser,
        Text: currentContent.Text,
        ImagePath: this.stage.ImagePath,
        Button1Bezeichnung: currentContent.Button1 ? currentContent.Button1.Bezeichnung : '',
        Button2Bezeichnung: currentContent.Button2 ? currentContent.Button2.Bezeichnung : '',
        Button1LinkURL: currentContent.Button1 ? currentContent.Button1.LinkURL : '',
        Button2LinkURL: currentContent.Button2 ? currentContent.Button2.LinkURL : '',
        Button1InNeuemTab: currentContent.Button1 ? currentContent.Button1.InNeuemTab : false,
        Button2InNeuemTab: currentContent.Button2 ? currentContent.Button2.InNeuemTab : false,
        Button1TypId: currentContent.Button1 ? currentContent.Button1.TypId : 0,
        Button2TypId: currentContent.Button2 ? currentContent.Button2.TypId : 0
      };

      this.themeStage = themeStage;
    }
  }

  /**
   * Shows the reset confirm dialog.
   */
  cpShowResetConfirm(action: Function): void {
    this.cp.showResetConfirm(action);
  }

  /**
   * Shows the clear confirm dialog.
   */
  cpShowClearConfirm(evt: MouseEvent): void {
    evt.preventDefault();
    this.cp.showClearConfirm();
  }

  /**
   * Redirects the user to create
   * a new stage.
   */
  newStage(): void {
    if (!this._permissionsLoaded) {
      this._getPermissionsSubscription = this._permissions.permissionsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.admin = this._permissions.permissions.IsAdmin;
          this._permissionsLoaded = true;

          if (this.admin) {
            this.role = 'admin';
          }

          this._mpCoreService.reloadComponent();
        }
      });

      this._permissions.getPermissions();
    } else {
      this._mpCoreService.reloadComponent();
    }
  }

  /**
   * Loads the stage template.
   */
  loadTemplate(): void {
    if (typeof this.themeStageTemplate !== 'undefined') {
      this.loadData(this.themeStageTemplate, true);
    }
  }

  /**
   * Loads the data of the given stage
   * id or an empty stage.
   */
  loadData(id?: number, isTemplate?: boolean): void {
    this._loadDataQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined' || typeof id !== 'undefined') {
        if (typeof params['id'] !== 'undefined') {
          isTemplate = false;
          id = parseInt(params['id']);
        }

        this._getThemeStageSubscription = this._apiService.getRequest(`api/Themenbuehnen/GetThemenbuehne?id=${id}`, true).subscribe((data: any) => {
          if (data.Result === 'OK' && data.Records[0]) {
            this.stage = data.Records[0];

            if (typeof this.stage !== 'undefined'
              && this.mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea) {

              if (this.typeSidebarStageAreaParams === null || (typeof this.typeSidebarStageAreaParams['list'] === 'undefined' || this.typeSidebarStageAreaParams['list'].length === 0)) {
                const shopLanguage: { [key: string]: any } = {
                  key: this._language !== '' ? this._language : 'de'
                }

                this._getTypeSidebarStageAreaParamsSubscription = this._apiService
                  .getRequest('/api/Themenbuehnen/GetThemeStageAreas', true, shopLanguage['key']).subscribe((data: any) => {
                    this.typeSidebarStageAreaParams['list'] = data.Records;              
                  });
              }
              this._updateLoadedThemeStages();
            }

            if (typeof isTemplate !== 'undefined' && isTemplate && typeof this.stage !== 'undefined' && this.stage.Contents.length === 0) {
              this._initEmptyStage();
            } else {
              if (typeof isTemplate !== 'undefined') {
                this._setLoadedData(isTemplate);
              } else {
                this._setLoadedData();
              }
            }

            this._stageChange.next(true);
          } else {
            this._initEmptyStage();
          }

          this._updateSelectableLanguages();
          this._setSelectedGroups();
          this._setSelectedGrouping();
          this._setSelectedCountries();
          this._updateLoadedThemeStages();
        });
      } else if (typeof params['p'] !== 'undefined' && params['p'] === '1') {
        const data = this.cp.loadStep(this._cpKey);

        if (this.cp.checkStepIsSkipped(this._cpKey)) {
          this._showCpSkipStepConfirm = true;
        }

        if (data) {
          this.stage = data;
          this._stageChange.next(true);
          this._setLoadedData();
        } else {
          this._initEmptyStage();
        }
      } else {
        this._initEmptyStage();
      }

      this._updateSelectableLanguages();
      this.hints['showRevertChanges'] = false;
    });
  }

  /**
   * Inits an empty stage.
   */
  private _initEmptyStage(locsAvailable?: boolean) {
    if (typeof locsAvailable === 'undefined') {
      if (Object.keys(this.ls.locs).length > 0) {
        this._initEmptyStage(true);
      } else {
        this._locsLoadedForEmptyStageSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            this._initEmptyStage(true);
          }
        });
      }
    } else if (typeof locsAvailable !== 'undefined' && locsAvailable) {
      const now = moment();
      const rangeBegin = now;
      const rangeEnd = undefined;
      const rangeBeginHour = rangeBegin.local().hours();
      const rangeBeginMinute = rangeBegin.local().minutes();
      const rangeEndHour = 23;
      const rangeEndMinute = 59;

      this.stage = {
        Contents: [
          {
            Language: this._lang && this._lang['Kuerzel'],
            Headline: ''
          }
        ],
        Titel: '',
        ImagePath: this.defaultImagePath,
        Von: rangeBegin,
        Bis: rangeEnd,
        UhrzeitVonStunden: rangeBeginHour,
        UhrzeitVonMinuten: rangeBeginMinute,
        UhrzeitBisStunden: rangeEndHour,
        UhrzeitBisMinuten: rangeEndMinute,
        ThemeStageAreasTypeContents: [
          {
            Id: -1,
            ThemeStageId: -1,
            ThemeStageAreaTypeId: -1
          }
        ],
        IsPublicAreaSelected: this._isPublicArea(),
        IsTeaserComponentAdded: false
      }

      if (this.communicationProcess && typeof this.stage !== 'undefined') {
        this.stage.Titel = this.cp.loadStep('title');
      }

      this.setSelectedContent(this.stage.Contents[0]);
      this._updateSelectableElements(this.stage.Contents[0]);
      this._updateSelectableLanguages();
      this._updateLoadedThemeStages();
      this.stageDate['text'] = this.ls.locs['loc'].AbSofort;
      this.stageDate['rangeStart'] = rangeBegin;
      this.stageDate['rangeEnd'] = rangeEnd;
      this._stageChange.next(true);
    }
  }

  /**
   * Sets the loaded data for the template
   * or an existing stage.
   */
  private _setLoadedData(isTemplate?: boolean) {
    if (typeof isTemplate !== 'undefined' && isTemplate) {
      const now = moment();
      const rangeBegin = now;
      const rangeEnd = undefined;
      const rangeBeginHour = rangeBegin.local().hours();
      const rangeBeginMinute = rangeBegin.local().minutes();
      const rangeEndHour = 23;
      const rangeEndMinute = 59;

      this.stageDate['rangeStart'] = rangeBegin;
      this.stageDate['UhrzeitVonStunden'] = rangeBeginHour;
      this.stageDate['UhrzeitVonMinuten'] = rangeBeginMinute;

      this.stageDate['rangeEnd'] = rangeEnd;
      this.stageDate['UhrzeitBisStunden'] = rangeEndHour;
      this.stageDate['UhrzeitBisMinuten'] = rangeEndMinute;

      this.stageDate['text'] = this.ls.locs['loc'].AbSofort;

      if (typeof this.stage !== 'undefined') {
        this.stage.Id = 0;
      }
    } else if (typeof this.stage !== 'undefined' && this.stage.Von) {
      const from = moment(this.stage.Von);
      this.stageDate['rangeStart'] = from.isBefore(this._date1900) ? this._date1900 : from;
      this.stageDate['UhrzeitVonStunden'] = this.stage.UhrzeitVonStunden;
      this.stageDate['UhrzeitVonMinuten'] = this.stage.UhrzeitVonMinuten;

      const to = moment(this.stage.Bis);
      this.stageDate['rangeEnd'] = to > this._date2050 ? undefined : to;
      this.stageDate['UhrzeitBisStunden'] = this.stage.UhrzeitBisStunden ? this.stage.UhrzeitBisStunden : undefined;
      this.stageDate['UhrzeitBisMinuten'] = this.stage.UhrzeitBisMinuten ? this.stage.UhrzeitBisMinuten : undefined;

      // @ts-ignore
      this.stageDate.save();
    }

    if (typeof this.stage !== 'undefined') {
      this.setSelectedContent(this.stage.Contents[0]);

      this.stage.Contents.forEach((content: any) => {
        this._updateSelectableElements(content);
      });
    }

    this._stageChange.next(true);
  }

  /**
   * Updates the selectable languages.
   */
  private _updateSelectableLanguages(): void {
    if (typeof this.stage === 'undefined') {
      this.selectableLanguages = this._languages;
    } else if (typeof this.stage !== 'undefined') {
      this.selectableLanguages = this._languages.filter((lang: any) => {
        // @ts-ignore
        const langFoundInContents = this.stage.Contents.find((content: any) => {
          return content['Language'] === lang['Kuerzel'];
        });

        if (typeof langFoundInContents === 'undefined') {
          return true;
        } else {
          return false;
        }
      });

      this.stage.Sprache = this.selectableLanguages[0] && this.selectableLanguages[0].Kuerzel;
      this._stageChange.next(true);
    }
  }

  /**
   * Updates the editable theme stage due to theme-stage-management.
   */
  private _updateLoadedThemeStages(): void {
    if (typeof this.stage !== 'undefined' && this.stage !== null && typeof this.typeSidebarStageAreaParams['list'] !== 'undefined' && this.stage.ThemeStageAreasTypeContents !== null) {
      
      const contents = this.stage.ThemeStageAreasTypeContents;
      this.typeSidebarStageAreaParams['selectedList'] = this.typeSidebarStageAreaParams['list'].filter((item: any) => {
        // @ts-ignore
        return contents.some(area => {
          return area['ThemeStageAreaTypeId'] === item['Identifier'];
        });
      });

      this.stage.ThemeStageAreasSelected = this.typeSidebarStageAreaParams['selectedList'];
      
      // @ts-ignore
      this.typeSidebarStageAreaParams.save();
    }
  }

  /**
   * Sets the selected theme stage areas.
   */
  private _setSelectedThemeStageAreas(): void {
    if (typeof this.stage !== 'undefined' && typeof this.typeSidebarStageAreaParams['list'] !== 'undefined') {
      this.typeSidebarStageAreaParams['selectedList'] = this.typeSidebarStageAreaParams['list'].filter((item: any) => {
        // @ts-ignore
        const foundAreaForStage = typeof this.stage.ThemeStageAreasSelected !== 'undefined' ? this.stage.ThemeStageAreasSelected.find((area: any) => {
          return area['Identifier'] = item['Identifier'];
        }) : null;

        if (typeof foundAreaForStage !== 'undefined' && foundAreaForStage !== null) {
          return true;
        } else {
          return false;
        }
      });

      // @ts-ignore
      this.typeSidebarStageAreaParams.save();
    }    
  }

  /**
   * Sets the selected groups.
   */
  private _setSelectedGroups(): void {
    if (typeof this.stage !== 'undefined' && this.stage !== null && typeof this.groupSidebarParams['list'] !== 'undefined') {

      const contents = this.stage.Gruppen;
      if (typeof contents !== 'undefined') {
        this.groupSidebarParams['selectedList'] = this.groupSidebarParams['list'].filter((item: any) => {
          // @ts-ignore
          return contents.some(group => {
            return group['Identifier'] == item['Identifier'];
          });
        });
      } else {
        this.groupSidebarParams['selectedList'] = [];
      }

      // @ts-ignore
      this.groupSidebarParams.save();
    }
  }

  /**
   * Sets the selected grouping.
   */
  private _setSelectedGrouping(): void {
    if (typeof this.stage !== 'undefined' && typeof this.groupingSidebarParams['list'] !== 'undefined') {
      this.isGrouping = true;

      this.groupingSidebarParams['selectedList'] = this.groupingSidebarParams['list'].filter((item: any) => {
        // @ts-ignore
        const foundGroupingInStage = typeof this.stage.Gruppierungen !== 'undefined' ? this.stage.Gruppierungen.find((group: any) => {
          return group['Identifier'] == item['Identifier'];
        }) : null;

        if (typeof foundGroupingInStage !== 'undefined' && foundGroupingInStage !== null) {
          return true;
        } else {
          return false;
        }
      });

      // @ts-ignore
      this.groupingSidebarParams.save();
    }
  }

  /**
   * Sets the selected countries.
   */
  private _setSelectedCountries(): void {
    if (typeof this.stage !== 'undefined' && typeof this.stage.Laender !== 'undefined' && typeof this.countriesSidebarParams['list'] !== 'undefined') {
      this.countriesSidebarParams['selectedList'] = this.countriesSidebarParams['list'].filter((item: any) => {
        // @ts-ignore
        const foundCountryInStage = this.stage.Laender.find((country: any) => {
          return country['Identifier'] == item['Identifier'];
        });

        if (typeof foundCountryInStage !== 'undefined') {
          return true;
        } else {
          return false;
        }
      });

      // @ts-ignore
      this.countriesSidebarParams.save();
    } else if (typeof this.stage !== 'undefined' && typeof this.countriesSidebarParams['list'] !== 'undefined') {
      this.countriesSidebarParams['selectedList'] = [];

      // @ts-ignore
      this.countriesSidebarParams.save();
    }
  }

  /**
   * Updates the given hint.
   */
  updateHints(hint: string, val: boolean): void {
    this.hints[hint] = val;
    this._isTitleChanged = false;
    this._isRecivedDate = false;
    this._recivedDate = '';
    this.isRecivedGrouping = false;
  }

  /**
   * Deletes the given theme stage.
   */
  deleteThemeStage(themeStageId: number | undefined): void {
    if (typeof themeStageId !== 'undefined') {
      this._deleteThemeStageSubscription = this._apiService.getRequest(`/api/Themenbuehnen/DeleteThemenbuehne?themenbuehnenId=${themeStageId}`).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DeleteThemenbuehneSuccess);
          this._router.navigateByUrl(`/${this.role}/ThemenbuehnenVerwalten`);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      });
    }
  }

  /**
   * Saves the theme stage.
   */
  save(approval?: boolean): void {
    if (typeof this.stage !== 'undefined') {
      if (typeof approval !== 'undefined') {
        this.stage.Freigegeben = approval;
      }

      this._saveThemeStageSubscription = this._apiService.postRequest('/api/Themenbuehnen/SaveThemenbuehne', this.stage).subscribe((data: any) => {
        if (data.Result === 'OK') {
          if (typeof this.stage !== 'undefined') {
            if (typeof approval !== 'undefined' && this.stage.Freigegeben === approval) {
              if (approval) {
                this._router.navigateByUrl(`/${this.role}/ThemenbuehnenSliderVerwaltung`);
                this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].VeroeffentlichenThemenbuehneSuccsess);
              } else if (!approval) {
                this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].NichtVeroeffentlichenThemenbuehneSuccsess);
              }
            } else {
              this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].SaveThemenbuehneSuccsess);
            }

            const newId = data.Records[0];

            if (newId !== this.stage.Id) {
                this._router.navigateByUrl(`/${this.role}/ThemenbuehnenSliderVerwaltung?id=${newId}`);
            }
          }
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }

        this.hints['showSaveThemeStage'] = false;
        this.hints['showApprove'] = false;
        this.hints['showNotApprove'] = false;
      },
      (error: any) => {
        if (typeof this.stage !== 'undefined') {
          if (typeof approval !== 'undefined' && this.stage.Freigegeben === approval) {
            this._mpMessaging.openDangerPanel(this.ls.locs['loc'].VeroeffentlichenThemenbuehneError);
          }

          this._mpMessaging.openDangerPanel(this.ls.locs['loc'].SaveThemenbuehneError);

          if (error['ModelState']) {
            this.errors = error['ModelState'];
          }

          if (typeof approval !== 'undefined') {
            this.stage.Freigegeben = !approval;
          }
        }
        
        this.hints['showSaveThemeStage'] = false;
        this.hints['showApprove'] = false;
        this.hints['showNotApprove'] = false;
      });

      this._stageChange.next(true);
    }
  }

  /**
   * Updates the elements, this.stageDate are
   * selectable (to add) within the
   * editor.
   */
  private _updateSelectableElements(content: any): void {
    content['themenbuehnenElementTypes'] = [];

    if (Object.keys(this.ls.locs).length === 0) {
      setTimeout(() => {
        this._updateSelectableElements(content);
      }, 10);

      return;
    }

    if (this.stage && this.stage.IsPublicAreaSelected) {
      
      this.selectedContent['themenbuehnenElementTypes'] = this.selectedContent['themenbuehnenElementTypes'].filter(function (e: any) {
        return e.Typ == 'Teaser';
      });

      if ((!content['Teaser'] && content['Teaser'] !== '') || !content['themenbuehnenElementTypes']) {
        content['themenbuehnenElementTypes'].push({ Typ: 'Teaser', Label: this.ls.locs['loc'].ThemenbuehneTeaserHinzufuegen });
      }

      return;
    }

    if (!content['Button1'] || !content['Button2']) {
      content['themenbuehnenElementTypes'].push({ Typ: 'Button', Label: this.ls.locs['loc'].ThemenbuehneButtonHinzufuegen });
    }

    var teaserExists = content['themenbuehnenElementTypes'].some((item: any) => {
      return item.Typ === 'Teaser' && item.Label !== '';
    });

    if ((this.stage
      && !this.stage.IsPublicAreaSelected
      && content['themenbuehnenElementTypes']
      && !teaserExists) || !content['Teaser'] || content['Teaser'] !== '') {
      content['themenbuehnenElementTypes'].push({
        Typ: 'Teaser',
        Label: this.ls.locs['loc'].ThemenbuehneTeaserHinzufuegen
      });         
    }

    if (!content['Button1'] || !content['Button2']) {
      if (!content['Text'] && content['Text'] !== '') {
        content['themenbuehnenElementTypes'].push({ Typ: 'Detailtext', Label: this.ls.locs['loc'].ThemenbuehneDetailtextHinzufuegen });
      }
    }
  }

  /**
   * Changes the styles of the owl
   * carousel.
   */
  handleOwlNavStyles(): void {
    const nextBtn = document.querySelector('#language-carousel .owl-next');
    const prevBtn = document.querySelector('#language-carousel .owl-prev');
    const owlStageOuter = document.querySelector('#language-carousel .owl-stage-outer');

    if (nextBtn !== null && owlStageOuter !== null) {
      if (nextBtn.classList.contains('disabled') === false) {
        if (owlStageOuter.classList.contains('next-shown') === false) {
          owlStageOuter.classList.add('next-shown');
        }
      } else {
        if (owlStageOuter.classList.contains('next-shown') !== false) {
          owlStageOuter.classList.remove('next-shown');
        }
      }
    }

    if (prevBtn !== null && owlStageOuter !== null) {
      if (prevBtn.classList.contains('disabled') === false) {
        if (owlStageOuter.classList.contains('prev-shown') === false) {
          owlStageOuter.classList.add('prev-shown');
        }
      } else {
        if (owlStageOuter.classList.contains('prev-shown') !== false) {
          owlStageOuter.classList.remove('prev-shown');
        }
      }
    }
  }

  /**
   * Triggered, when title changed.
   * Sets isTitleChanged to true.
   */
  onChangeTitle(): void {
    this._isTitleChanged = true;
  }

  /**
   * Adds an element to the
   * theme stage.
   */
  addElement(content: any): void {
    if (content['elementTyp'] === 'Button') {
      let typ = 'Button1';

      if (typeof content['Button1'] !== 'undefined') {
        typ = 'Button2';
      }

      content[typ] = {
        Id: 0,
        Language: content['Language'],
        Bezeichnung: '',
        LinkURL: '',
        LinkWert: '',
        InNeuemTab: false,
        Open: true
      }

      if (typ === 'Button1')
        this.button1Open = true;
      else
        this.button2Open = true;
    } else if (content['elementTyp'] === 'Teaser') {
      content['Teaser'] = content['Teaser'] || '';
      if (typeof this.stage !== 'undefined') {
        this.stage.IsTeaserComponentAdded = true;
      }
      this.teaserOpen = true;
    } else if (content['elementTyp'] === 'Detailtext') {
      content['Text'] = content['Text'] || '';

      let typ = 'Button1';

      if (typeof content['Button1'] !== 'undefined') {
        typ = 'Button2';
      }
      content[typ] = {
        Id: 0,
        Language: content['Language'],
        Bezeichnung: '',
        LinkURL: '',
        LinkWert: '',
        InNeuemTab: false,
        Open: true,
        TypId: 1
      }

      if (typ === 'Button1')
        this.button1Open = true;
      else
        this.button2Open = true;
    }

    this._updateSelectableElements(content);
    this.selectedContent['elementTyp'] = null;
  }

  /**
   * Shows the delete teaser hint.
   */
  initDeleteTeaser(content: any): void {
    this._contentToDelete = content;
    this.hints['showDeleteTeaser'] = true;
  }

  /**
   * Deletes the teaser.
   */
  deleteTeaser(): void {
    const content = this._contentToDelete;
    content['Teaser'] = undefined;
    this.teaserOpen = false;
    
    this._updateSelectableElements(content);
    this.hints['showDeleteTeaser'] = false;

    if (typeof this.stage !== 'undefined') {
      this.stage.IsTeaserComponentAdded = false;
      this._stageChange.next(true);
    }
  }

  /**
   * Shows the delete button 1 hint.
   */
  initDeleteButton1(content: any): void {
    this._contentToDelete = content;
    this.hints['showDeleteButton1'] = true;
  }

  /**
   * Deletes button 1.
   */
  deleteButton1(): void {
    const content = this._contentToDelete;

    if (content['Button1'].TypId === 1) {
      content['Text'] = undefined;
    }

    content['Button1'] = undefined;
    this.button1Open = false;

    if (content['Button2']) {
      content['Button1'] = content['Button2'];
      content['Button2'] = undefined;
    }

    this._updateSelectableElements(content);
    this.hints['showDeleteButton1'] = false;

    if (typeof this.stage !== 'undefined') {
      this._stageChange.next(true);
    }
  }

  /**
   * Shows the delete button 2 hint.
   */
  initDeleteButton2(content: any): void {
    this._contentToDelete = content;
    this.hints['showDeleteButton2'] = true;
  }

  /**
   * Deletes the button 2.
   */
  deleteButton2(): void {
    const content = this._contentToDelete;

    if (content['Button2'].TypId === 1) {
      content['Text'] = undefined;
    }

    content['Button2'] = undefined;
    this.button2Open = false;
    this._updateSelectableElements(content);
    this.hints['showDeleteButton2'] = false;

    if (typeof this.stage !== 'undefined') {
      this._stageChange.next(true);
    }
  }

  /**
   * Shows the delete content hint.
   */
  initDeleteContent(content: any): void {
    this._contentToDelete = content;
    this.hints['showDeleteContent'] = true;
  }

  /**
   * Deletes the content.
   */
  deleteContent(): void {
    if (typeof this.stage !== 'undefined') {
      if (this.stage.Contents.length <= 1) {
        return;
      }

      const content = this._contentToDelete;
      const idx = this.stage.Contents.indexOf(content);
      this.stage.Contents.splice(idx, 1);
      this.setSelectedContent(this.stage.Contents[0]);
      this._updateSelectableLanguages();
      this._contentToDelete = {};
      this._stageChange.next(true);
    }

    this.hints['showDeleteContent'] = false;

    setTimeout(() => {
      this.handleOwlNavStyles();

      // @ts-ignore
      this._carouselService.to(this.stage.Contents.length - 1, 250);
    }, 500);
  }

  /**
   * Shows the change language hint.
   */
  initChangeLanguage(content: any): void {
    this._contentToChange = content;
    this.hints['showChangeLanguage'] = true;
  }

  /**
   * Changes the language.
   */
  changeLanguage(): void {
    if (typeof this.stage !== 'undefined') {
      const content = this._contentToChange;
      content['Language'] = this.stage.Sprache;
      this._updateSelectableLanguages();
      this._contentToChange = {};
      this.hints['showChangeLanguage'] = false;
      this._stageChange.next(true);
    }
  }

  /**
   * Handles the file upload.
   */
  uploadFiles(evt: any): void {
    const files = evt.target.files;

    if (typeof this.stage !== 'undefined') {
      if (!this.stage.Titel && !this.communicationProcess) {
        return;
      }
    }

    this.uploadSuccessfully = false;
    this.file = files[0];

    if (this.file) {
      this._fileUploadSubscription = this._apiService.postFileUploadRequest('/api/Upload/uploadNeueThemenbuehne', this.file, this.file.name.split('.').slice(0, -1).join('')).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.uploadSuccessfully = true;
          this.errorUpload = '';
          this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DateiWurdeErfolgreichHochgeladen);

          if (typeof this.stage !== 'undefined') {
            this.stage.ImagePath = data.Records[0];
            this._stageChange.next(true);
          }
        } else {
          this.uploadSuccessfully = false;
          this._mpMessaging.openWarningPanel(data.Message);
          this.errorUpload = data.Message;
        }
      },
      (error: any) => {
        this.uploadSuccessfully = false;
        this._mpMessaging.openWarningPanel(error.Message);
        this.errorUpload = error.Message;
      });
    }
  }

  /**
   * Adds a language to the theme stage.
   */
  addLanguage(): void {
    if (typeof this.stage !== 'undefined') {
      if (!this.stage.Sprache) {
        return;
      }

      const newContent = {
        Language: this.stage.Sprache
      };

      this.stage.Contents.push(newContent);
      this._updateSelectableLanguages();
      this._updateSelectableElements(newContent);
      this.hints['showAddLanguage'] = false;
      this.setSelectedContent(newContent);

      if (typeof this.owlElement !== 'undefined') {
        if (typeof this._carouselService === 'undefined' && typeof this.owlElement.first !== 'undefined') {
          this._carouselService = this.owlElement.first.carouselService as CarouselService;
        }

        setTimeout(() => {
          this.handleOwlNavStyles();

          if (typeof this._carouselService !== 'undefined' && typeof this.stage !== 'undefined') {
            this._carouselService.to(this.stage.Contents.length - 1, 250);
          }
        }, 500);
      }

      //this._stageChange.next(true);
    }
  }

  /**
   * Skips the cp step.
   */
  skipStep(): void {
    this.cp.skipStep(this._cpKey);
    this._showCpSkipStepConfirm = false;
  };

  /**
   * Does the cp step.
   */
  doStep(): void {
    this.cp.confirmStep(this._cpKey);
    this._showCpSkipStepConfirm = false;
  };

  /**
   * Saves the cp step.
   */
  saveStep(): void {
    if (typeof this.stage !== 'undefined') {
      if (this.stageDate['rangeStart'] !== undefined) this.stage.Von = this.stageDate['rangeStart'];
      if (this.stageDate['rangeEnd'] !== undefined) this.stage.Bis = this.stageDate['rangeEnd'];
      if (this.stageDate['UhrzeitVonStunden'] !== undefined) this.stage.UhrzeitVonStunden = this.stageDate['UhrzeitVonStunden'];
      if (this.stageDate['UhrzeitVonMinuten'] !== undefined) this.stage.UhrzeitVonMinuten = this.stageDate['UhrzeitVonMinuten'];
      if (this.stageDate['UhrzeitBisStunden'] !== undefined) this.stage.UhrzeitBisStunden = this.stageDate['UhrzeitBisStunden'];
      if (this.stageDate['UhrzeitBisMinuten'] !== undefined) this.stage.UhrzeitBisMinuten = this.stageDate['UhrzeitBisMinuten'];

      const data = this.stage;
      data.Freigegeben = false;
      this.cp.saveStep(this._cpKey, data);
    }    
  }

  /**
   * Saves the communication process.
   */
  saveCommunicationProcess(): void {
    this.saveStep();
    this.cp.save();
  }

  /**
   * Validates the cp step.
   */
  validateStep(): boolean | any {
    if (typeof this.stage === 'undefined') {
      return;
    } else {
      const tbEditorData = this.stage;

      if (!tbEditorData.Contents || !tbEditorData.Contents[0] || !tbEditorData.Contents[0].Headline || tbEditorData.Contents[0].Headline === '') {
        return false;
      }

      if (!tbEditorData.ImagePath || tbEditorData.ImagePath === '' || tbEditorData.ImagePath.includes('VorlageLeer')) {
        return false;
      }

      if (!tbEditorData.Titel || tbEditorData.Titel === '') {
        return false;
      }

      if (this.stageDate['text'] === '' || !this.stageDate['rangeStart'])
        return false;

      return true;
    }
  }

  /**
   * Goes to the next cp step.
   */
  goToNextStep(evt: MouseEvent): void {
    evt.preventDefault();

    if (!this.validateStep())
      return;

    this.saveStep();
    this.cp.next();
  };

  /**
   * Goes to the previous cp step.
   */
  goToPreviousStep(evt: MouseEvent): void {
    evt.preventDefault();

    this.saveStep();
    this.cp.previous();
  };

  /**
   * Triggers "go back" in the browser.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Hides the given hint modal.
   */
  private _hideHint(modal: string) {
    this.hints[modal] = false;
  }

  /**
   * Determines whether the public theme stage area is currently selected or not.
   * return: True if public area selected, otherwise false.
   */
  private _isPublicArea(): boolean {
    let result = false;

    if (this.mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea === true
      && typeof this.stage !== 'undefined'
      && typeof this.typeSidebarStageAreaParams !== 'undefined'
      && typeof this.typeSidebarStageAreaParams['selectedList'] !== 'undefined') {

      var selList = this.typeSidebarStageAreaParams['selectedList'];
      result = Object.keys(selList).some((key: any) => {
        return selList[key].Identifier === '2';
      });      
    } 
   
    return result;  
  }
}
