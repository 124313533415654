import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';

/**
 * This class provides a simple textarea, that
 * can be configured with different input attributes.
 */
@Component({
  selector: 'mp-core-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @Input() public model: string = '';
  @Input() public label: string = '';
  @Input() public mpId: string = '';
  @Input() public mpRequired: boolean = true;
  @Input() public focusMe: boolean = true;
  @Input() public mpOptional: boolean = true;
  @Input() public mpDisabled: boolean = true;
  @Input() public hasError: boolean = false;
  @Input() public fieldHeight?: string = '';
  @Input() public mpPlaceholder: string = '';
  @Input() public height: string = '';
  @Input() public maxlength: number | undefined = undefined;

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('textarea', { static: false })
  textarea!: ElementRef;

  constructor(
    public ls: MpLocalizationService,
  ) {}

  ngOnInit(): void {

  }

  /**
   * Triggers model change event.
   */
  textChanged(): void {
    this.modelChange.emit(this.model);
  }

}
