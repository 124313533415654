<div themesStagesAnimations #themesStagesAnimationsRef="themesStagesAnimationsRef" class="stage-container">
  <div class="stage"
       *ngIf="themesStagesAnimationsRef.owlPropertiesSet">
    <owl-carousel-o #owlElem id="home-carousel"
                    class="owl-theme"
                    owl-items="homeStages"
                    [options]="themesStagesAnimationsRef.owlProperties"
                    (swipeMove)="swipeLeft()"
                    (swipeEnd)="swipeRight()"
                    (translated)="themesStagesAnimationsRef.onTranslated()"
                    (initialized)="themesStagesAnimationsRef.onInitialized(owlElem)">
      <ng-container class="item" *ngFor="let item of homeStages">
        <ng-template carouselSlide [width]="0" style="width: auto;" [id]="'stage-item-id-'+item.Id">
          <div class="owl-inner buehne-{{item.Id}}">
            <img mpCoreStageImageLoaded
                 id="{{item.Id}}"
                 [src]="item.ImagePath + '?width=' + imgWidth + '&height=' + imgHeight + '&mode=crop'"
                 alt="" />
            <div class="caption"
                 [ngClass]="[item.CssClass, !item.Teaser && !item.Button1Bezeichnung && !item.Button2Bezeichnung ? '_only-title' : '']"
                 *ngIf="item.Titel">
              <div>
                <h1 [innerHTML]="trustAsHtml(item.Titel)"></h1>
              </div>
              <span [hidden]="!item.Teaser" [innerHTML]="item.Teaser ? trustAsHtml(item.Teaser) : ''" [ngClass]="[!item.Button1Bezeichnung  && !item.Button2Bezeichnung? '_no-buttons-following' : '']"></span>
              <div class="read-more-buttons">
                <a class="mp-button _prim button-large read-more"
                   [hidden]="!item.Button1Bezeichnung"
                   (click)="trackButton(item.Id, item.Titel, item.Button1Bezeichnung)"
                   href="{{item.Button1LinkURL | angularjsToAnuglarUrl}}"
                   target="{{item.Button1InNeuemTab ? '_blank' : '_self'}}">
                  {{item.Button1Bezeichnung}}
                </a>
                <a class="mp-button _prim button-large read-more"
                   [hidden]="!item.Button2Bezeichnung"
                   (click)="trackButton(item.Id, item.Titel, item.Button2Bezeichnung)"
                   href="{{item.Button2LinkURL | angularjsToAnuglarUrl}}"
                   target="{{item.Button2InNeuemTab ? '_blank' : '_self'}}">
                  {{item.Button2Bezeichnung}}
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="clear"></div>
</div>
<mp-core-user-profile-navbar></mp-core-user-profile-navbar>
