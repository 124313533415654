import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from "d3";

import { MpD3StylesService } from './../mp-d3-styles.service';
import { MpDebounceService } from './../../../services/mp-debounce.service';
import { ApiService } from './../../../services/api.service';
import { MpLoaderService } from './../../../services/mp-loader.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';

import { MpLoader } from './../../../services/interfaces/mp-loader';

/**
 * This class provides a d3 circular bar plot.
 */
@Component({
  selector: 'mp-core-d3-circular-bar-plot',
  templateUrl: './d3-circular-bar-plot.component.html',
  styleUrls: ['./d3-circular-bar-plot.component.scss']
})
export class D3CircularBarPlotComponent implements OnInit {

  @Input() public mpid: string = '';
  @Input() public width: number = 600;
  @Input() public height: number = 400;
  @Input() public dataurl: string = '';
  @Input() public textxachse: string = '';
  @Input() public textyachse: string = '';
  @Input() public animationms: number = 800;
  @Input() public loader: string | undefined;

  private _fillcolorRange: Array<string> = [];
  private _linecolor: string = '';

  constructor(
    private _mpD3StylesService: MpD3StylesService,
    private _mpDebounce: MpDebounceService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService,
    private _chartElem: ElementRef,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Loads the colors and the data
   * for the chart.
   */
  ngOnInit(): void {
    this._fillcolorRange = this._mpD3StylesService.mpD3Styles().d3CircularBarPlotStyles.fillcolorRange;
    this._linecolor = this._mpD3StylesService.mpD3Styles().d3CircularBarPlotStyles.linecolor;

    let parentLoader: MpLoader;

    const load = this._mpDebounce.debounce(() => {
      const p = (typeof (this.loader) === 'undefined' ? {} : parentLoader.params);

      this._apiService.postRequest(this.dataurl, p).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._drawChart(data.Records);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
      });
    }, 200);

    if (typeof this.loader !== 'undefined') {
      if (this._mpLoaderService.loaderExists(this.loader)) {
        parentLoader = this._mpLoaderService.getLoader(this.loader);

        this._mpLoaderService.extendLoader(parentLoader.name, {
          load: load
        });
      }
    }

    load();
  }

  /**
   * Draws the d3 chart.
   */
  private _drawChart(inputData: any) {
    if (typeof inputData === 'undefined' || typeof inputData !== 'undefined' && typeof inputData[0] === 'undefined')
      return;

    const scaleRadial = () => {
      let domain = [0, 1],
        range = [0, 1];

      const scale = (x: any) => {
        var r0 = range[0] * range[0], r1 = range[1] * range[1];
        return Math.sqrt((x - domain[0]) / (domain[1] - domain[0]) * (r1 - r0) + r0);
      }

      scale.domain = (_: any) => {
        return arguments.length ? (domain = [+_[0], +_[1]], scale) : domain.slice();
      };

      scale.range = (_: any) => {
        return arguments.length ? (range = [+_[0], +_[1]], scale) : range.slice();
      };

      scale.ticks = (count: any) => {
        return d3.scaleLinear().domain(domain).ticks(count);
      };

      scale.tickFormat = (count: any, specifier: any) => {
        return d3.scaleLinear().domain(domain).tickFormat(count, specifier);
      };

      return scale;
    };

    inputData.map(
      (d: any) => {
        if (d.xDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.xDate);
        if (d.yDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.yDate);
      });

    const widthChart = this.width;
    const heightChart = this.height;
    const outerRadius = Math.min(widthChart, heightChart) / 2;
    const innerRadius = outerRadius / 4;
    const xData = (d: any) => { return d.xDate ? d.xDate : d.xInt ? d.xInt : d.xDecimal ? d.xDecimal : d.xString ? d.xString : ''; };
    const yData = (d: any) => { return d.yDate ? d.yDate : d.yInt ? d.yInt : d.yDecimal ? d.yDecimal : d.yString ? d.yString : 0; };
    const colorGroup = (d: any) => { return d.xString; };
    const colorGroupNames: Array<any> = [];

    inputData.forEach((d: any) => {
      var g = colorGroup(d);
      if (colorGroupNames.indexOf(g) < 0) colorGroupNames.push(g);

      d.tooltip = typeof d.tooltip === 'undefined' || d.tooltip === null ? yData(d) : d.tooltip;
    });

    d3.select('#' + this.mpid)
      .selectAll('*')
      .remove();

    const svg = d3.select('#' + this.mpid)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewBox', '0, 0, ' + this.width + ', ' + this.height)
      .append('g')
      .attr('transform', 'translate(' + (widthChart / 2) + ',' + (heightChart / 2) + ')');

    const tooltip = d3.select('#' + this.mpid)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '8px')
      .style('padding', '5px');

    const mouseover = (d: any) => {
      tooltip
        .style('opacity', 0.9);
    };

    const mousemove = (evt: any, d: any) => {
      tooltip
        .html(d.tooltip)
        .style('left', (d3.pointer(evt)[0] + 20).toString() + 'px')
        .style('top', (d3.pointer(evt)[1]).toString() + 'px');
    };

    const mouseleave = (d: any) => {
      tooltip
        .style('opacity', 0);
    };

    const color = d3.scaleOrdinal()
      .domain(inputData.map(xData))
      .range(this._fillcolorRange);

    const x = d3.scaleBand()
      .domain(inputData.map(xData))
      .range([0, 2 * Math.PI])
      .align(0);

    const y = scaleRadial()
      .range([innerRadius, outerRadius])
      // @ts-ignore
      .domain([0, Math.max(d3.max(inputData, yData), 1)]);

    const bar = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius((d: any) => { return y(yData(d)); })
      // @ts-ignore
      .startAngle((d: any) => { return x(xData(d)); })
      // @ts-ignore
      .endAngle((d: any) => { return x(xData(d)) + x.bandwidth(); })
      .padAngle(0.01)
      .padRadius(innerRadius);

    svg.append("g")
      .selectAll("path")
      .data(inputData)
      .enter()
      .append("path")
      // @ts-ignore
      .attr("d", bar)
      // @ts-ignore
      .style("fill", (d: any) => { return color(xData(d)); })
      .style("stroke-width", "2px")
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    if (colorGroupNames.length > 1) {
      const legend = this._chartElem.nativeElement.parentElement.querySelector('.chart-legend');

      while (legend.hasChildNodes()) {
        // @ts-ignore
        legend.removeChild(list.firstChild);
      }

      colorGroupNames.forEach((colorName: any, index: number) => {
        const legendElem = document.createElement('div');
        const legendDotElem = document.createElement('span');
        const legendTextElem = document.createElement('span');

        // @ts-ignore
        legendDotElem.style.backgroundColor = color(colorName);
        legendTextElem.innerHTML = colorName;
        legendElem.appendChild(legendDotElem);
        legendElem.appendChild(legendTextElem);

        legend.appendChild(legendElem);
      });
    }
  }
}
