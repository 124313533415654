<ngx-slick-carousel #imageVideoCarousel id="imageVideoCarousel" *ngIf="visuals.length > 0 && slickConfig" [config]="slickConfig" (afterChange)="slickAfterChange($event)" (beforeChange)="slickBeforeChange($event)">
  <div ngxSlickItem *ngFor="let visual of visuals; trackBy: trackById">
    <div *ngIf="visual.Typ == 1">
      <lib-ngx-image-zoom id="image{{visual.ID}}" class="sliderMedia width-100-pcnt" [thumbImage]="visual.Source+'?width=992&height=992&mode=crop'" [fullImage]="visual.Source" zoomMode="hover" [enableLens]="true" [circularLens]="true" [lensWidth]="350" [lensHeight]="350"></lib-ngx-image-zoom>
      <img class="sliderMediaMobile width-100-pcnt" id="image{{visual.ID}}" [src]="visual.Source+'?width=630&height=630&mode=crop'" alt="" />
    </div>
    <div *ngIf="visual.Typ == 2" class="videogular-container">
      <video class="sliderMedia video-js vjs-default-skin" id="video{{visual.ID}}" controls width="1000" height="500">
        <source [src]="visual.Source" type="{{visual.SourceType}}" />
      </video>
    </div>
    <div *ngIf="visual.Typ == 3" class="center-block image-360">
      <div id="threesixty-container">
        <div id="threesixty{{visual.ID}}" class="threesixty car">
          <div class="spinner">
            <span>0%</span>
          </div>
          <ol class="threesixty_images"></ol>
        </div>
      </div>
    </div>
    <div *ngIf="visual.Typ == 4" class="cl-video-container">
      <div class="sliderMedia clVideo" id="clVideo{{visual.ID}}" [attr.ean]="visual.Source"></div>
    </div>
    <div *ngIf="visual.Typ == 5">
      <a class="highlightPraemienLink" href="/#/{{role}}/PraemienDetails?art={{visual.ArtNr + (visual.PIN == '*' ? '' : '&pin=' + visual.PIN)}}">
        <img class="sliderMedia highlightPraemienImg" [src]="visual.Bild" alt="{{visual.ArtBez}}" style="float: left" />
        <div class="highlightText">
          <h2 [innerText]="visual.ArtBez"></h2>
          <div [innerHTML]="trustAsHtml(visual.Teaser)"></div>
        </div>
      </a>
    </div>
  </div>
</ngx-slick-carousel>
<div class="praemiendetails-thumnbails-nav">
  <ngx-slick-carousel #imageVideoCarouselNav id="imageVideoCarouselNav" *ngIf="visuals.length > 0 && slickThumbsConfig" class="display-flex flex-align-items-center" [config]="slickThumbsConfig">
    <div ngxSlickItem *ngFor="let visual of visuals; trackBy: trackById" (click)="navSlideChanged($event)">
      <img [src]="visual.Thumbnail" height="80" width="80" alt="" />
    </div>
  </ngx-slick-carousel>
</div>
