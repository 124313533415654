import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides a sidebar with a
 * calendar in it.
 */
@Component({
  selector: 'mp-core-sidebar-calendar',
  templateUrl: './sidebar-calendar.component.html',
  styleUrls: ['./sidebar-calendar.component.scss']
})
export class SidebarCalendarComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public maxRange: number = 50;
  public sliderOptions: any = false;

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(public ls: MpLocalizationService, public mpSidebar: MpSidebarService) { }

  /**
   * Watches for sidebar params changes of
   * range start and range end.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        rangeStart: this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'],
        rangeEnd: this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd']
      });
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Merges the selection with the sidebar
   * params and triggers the save function,
   * if exists.
   */
  save(event: MouseEvent): void {
    event.preventDefault();
    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }
    
    this.mpSidebar.close();
  }
}
