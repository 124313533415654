<div class="layout-container manager-text-editor" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb" class="display-flex flex-align-items-center margin-bottom-8 padding-left-8">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info" (click)="goBack($event)">{{ ls.locs['loc'].Zurueck }}</a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Seiten }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Teilnahmebedingungen }}</a>
      </div>
    </div>
    <div class="margin-top-16 flex-col-12 flex-direction-column">
      <div class="flex-col-12 bg-color-white padding-16 flex-direction-column">
        {{ ls.locs['loc'].Teilnahmebedingungen }}
      </div>
    </div>
    <section id="elig-requ-editor-content" class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-24">
      <div class="width-full bg-color-white padding-16 box-sizing-border-box">
        <ul class="magma-accordion editor-acc">
          <li *ngFor="let thema of filteredThemes; let parentIndex = index" id="acc-data-id-{{thema['ThemaId']}}" class="padding-left-right-0 padding-bottom-16 fallback-acc" [ngClass]="{'accOpened': thema['offen']}">
            <div class="display-flex margin-left-right-0 elig-requ-acc-flex-container">
              <button class="mp-button _prim padding-left-right-4 margin-right-8 elig-requ-delete-button" (click)="deleteTheme(thema)">
                <i class="svg-icon __top-0 __clr-white __clear __size-16"></i>
              </button>
              <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2 margin-left-right-0 acc-headline-open-trigger" (click)="thema['offen'] = !thema['offen']">
                <p class="margin-right-16">
                  <a [innerText]="thema['Thema'] || thema['NeuerParagraphText']"></a>
                </p>
                <i class="svg-icon __size-28 __top-0" [ngClass]="!thema['offen'] ? '__clr-inactive __add' : '__clr-white __remove'"></i>
              </div>
            </div>
            <div *ngIf="thema['offen']" id="editor-Container" class="bg-color-white display-block padding-top-bottom-32 padding-left-right-16 overflow-hidden margin-top-8 margin-bottom-0">
              <span class="margin-bottom-16 display-block">
                <mp-core-input mpId="acc-editor-title" [(model)]="thema['Thema']" mpPlaceholder="{{ ls.locs['loc'].ThemaVerfassen }}" [mpRequired]="true" [hasError]="errors['editorHeadline'] ? true : false"></mp-core-input>
                <mp-core-form-error *ngIf="errors['teilnahmebedingungen['+parentIndex+'].Thema']" [model]="errors['teilnahmebedingungen['+parentIndex+'].Thema']"></mp-core-form-error>
              </span>
              <div *ngFor="let text of thema['Texte']; let index = index" class="margin-top-16 display-flex elig-requ-acc-flex-container-full">
                <button class="mp-button _prim padding-left-right-4 margin-right-8 elig-requ-delete-button" (click)="deleteText(text, thema)">
                  <i class="svg-icon __top-0 __clr-white __clear __size-16"></i>
                </button>
                <div>
                  <textarea *ngIf="froalaOptionsLoaded" id="editor-{{text['TextId']}}" [froalaEditor]="froalaOptions" [(froalaModel)]="text['TbText']" class="form-control" name="editor-{{text['TextId']}}"></textarea>
                  <mp-core-form-error *ngIf="errors['teilnahmebedingungen['+parentIndex+'].Texte['+index+'].TbText']" [model]="errors['teilnahmebedingungen['+parentIndex+'].Texte['+index+'].TbText']"></mp-core-form-error>
                </div>
              </div>
              <div (click)="addText(thema)" class="dashed-border-button margin-top-16 margin-left-32">
                <p class="margin-0" [innerHTML]="ls.locs['loc'].UnterpunktHinzufuegen"></p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div (click)="addTheme()" class="dashed-border-button margin-top-16">
        <p class="margin-0" [innerHTML]="ls.locs['loc'].ParagraphHinzufuegen"></p>
      </div>
    </section>
    <section id="elig-requ-editor-datapanel" class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-24">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full">
          <mp-core-select *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages" class="width-full margin-bottom-16" mpId="elig-requ-type" [model]="selLanguage" (modelChange)="selLanguage = $event; setFilteredThemes()" [elements]="languages" textProperty="Name" valueProperty="Kuerzel" [mpRequired]="true" label="{{ ls.locs['loc'].Sprache }}"></mp-core-select>
          <mp-core-select *ngIf="mpSettings.settings['NetcentiveCoreSettings'].ShowGruppenInEditoren" class="width-full margin-bottom-16" mpId="select-gruppe" [model]="group" (modelChange)="group = $event; setFilteredThemes()" [elements]="groups" textProperty="Gruppe" [mpRequired]="true" label="{{ ls.locs['loc'].Gruppe }}"></mp-core-select>
          <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].ShowGruppenInEditoren || mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages" class="width-full margin-top-40">
            <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}" alignment="left" class="float-right-important">
              <button class="mp-button" (click)="hints['showRevertChanges'] = true">
                <i class="svg-icon __size-24 __clr-default __top-0 __refresh" id="elig-requ-refresh-icon"></i>
              </button>
            </mp-core-tooltip>
          </div>
          <div *ngIf="!(mpSettings.settings['NetcentiveCoreSettings'].ShowGruppenInEditoren || mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages)" class="width-full">
            <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}" alignment="left" class="float-right-important">
              <button class="mp-button" (click)="hints['showRevertChanges'] = true">
                <i class="svg-icon __size-24 __clr-default __top-0 __refresh" id="elig-requ-refresh-icon"></i>
              </button>
            </mp-core-tooltip>
          </div>
        <mp-core-checkbox class="margin-top-bottom-24" mpId="chkTnBestaetigungNoetigBezeichnung" [(model)]="ptcpntConfirmationRequired" label="{{ ls.locs['loc'].TnBestaetigungNoetigBezeichnung }}"></mp-core-checkbox>
        <button class="mp-button _prim margin-top-16 width-full" (click)="hints['showSaveConditions'] = true">{{ ls.locs['loc'].SeiteVeroeffentlichen }}</button>
      </div>
    </section>
  </div>

  <mp-core-modal [show]="hints['showSaveConditions']" title="{{ ls.locs['loc'].TeilnahmebedingungenSpeichernHinweisTitel }}" [buttons]="[{text: ls.locs['loc'].Nein, action: setShowSaveConditionsFalse}, {text: ls.locs['loc'].Ja, action: saveFunc, class: '_prim'}]">
    {{ ls.locs['loc'].TeilnahmebedingungenSpeichernHinweisText }}
  </mp-core-modal>
  <mp-core-modal [show]="hints['showRevertChanges']" title="{{ ls.locs['loc'].AenderungenRueckgaengigHinweisTitel }}" [buttons]="[{text: ls.locs['loc'].Nein, action: setShowRevertChangeFalse}, {text: ls.locs['loc'].Ja, action: loadDataFunc, class: '_prim'}]">
    {{ ls.locs['loc'].AenderungenRueckgaengigHinweisText }}
  </mp-core-modal>
</div>
