import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';

import { RoleMappingService } from '@core/services/role-mapping.service';

/**
 * This class provides the data and
 * the functions for the schooling
 * start page.
 */
@Component({
  selector: 'mp-schooling-schooling-start',
  templateUrl: './schooling-start.component.html',
  styleUrls: ['./../../../../../../mp.Core/app/modules/participant/pages/award-details/award-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchoolingStartComponent implements OnInit, OnDestroy {

  public schoolingName: string = '';
  public role: string = '';
  public isTrainingCompleted: boolean = false;
  public trainingCompletedDate: string = '';
  public schoolingDataLoaded: boolean = false;

  public index: number = 1;
  public maxAnzahlSeiten: number = 0;
 

  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
  ) { }

  /**
   * Gets the role. Gets the locs.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

}
