import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpErrorHandlerService } from './../../../../services/mp-error-handler.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpSettingsService } from  './../../../../services/mp-settings.service';


/**
 * This class provides the functions to
 * set a new password via the password reset
 * page.
 */
@Component({
  selector: 'mp-core-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public data: { [key: string]: any } = {
    PWD: '',
    PWDRepeat: ''
  };
  public errors: { [key: string]: any } = {
    PWD: '',
    PWDRepeat: ''
  };
  public specialChars: string = '';
  private _id: string = '';

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mpErrorHandler: MpErrorHandlerService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Checks whether or not the given id
   * (given via request parameter) is vlaid.
   */
  ngOnInit(): void {
    this.mpSettings.settings['PasswortSettings'].AllowedSymbols.forEach(
      (x:string) => this.specialChars += (this.specialChars === '' ? '' : ', ') + x);

    this._route.queryParams.subscribe((params: any) => {
      this._id = params['id'];

      this._apiService.getRequest('/api/PWDVergessen/getLinkResult', false, { params: { id: this._id } }).subscribe((data: any) => {
        if (data.Result === 'ERROR') {
          this._mpMessaging.openWarningPanel(data.Message);
          this._router.navigate(['/']);
        }
      },
      (error: any) => {
        if (error.status === 400)
          this.errors = error.ModelState;

        this._mpMessaging.openWarningPanel(error.Message);
        this._router.navigate(['/']);
      });
    });
  }

  /**
   * Sends the new password to the backend, so
   * it can be saved in database.
   */
  confirm(): void {
    this.data['ID'] = this._id;
    this.resetErrors();

    this._apiService.postRequest('/api/PWDVergessen/savePWD', this.data).subscribe(() => {
      this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].PwdChangeSuccess, 5);
      //console.log(this.ls.locs['loc'].PwdChangeSuccess);
      this._router.navigate(['/']);
    },
    (error: any) => {
      if (error.status === 400)
        this.errors = error.ModelState;

      const asErrMessages = this._mpErrorHandler.handleResponse(error);
      this._mpMessaging.openWarningPanel(asErrMessages);
    });
  }

  resetErrors(): void {
    this.errors['PWD'] = '';
    this.errors['PWDRepeat'] = '';
  }

}
