import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import clone from 'clone';

import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';

/**
 * This class provides the functionalities
 * for the hotel guests sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-guests-hotel',
  templateUrl: './sidebar-guests-hotel.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarGuestsHotelComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public selectionLoaded: boolean = false;
  public childrenOldOptions: Array<any> = [
    { Text: '<1', Value: 0 },
    { Text: '1', Value: 1 },
    { Text: '2', Value: 2 },
    { Text: '3', Value: 3 },
    { Text: '4', Value: 4 },
    { Text: '5', Value: 5 },
    { Text: '6', Value: 6 },
    { Text: '7', Value: 7 },
    { Text: '8', Value: 8 },
    { Text: '9', Value: 9 },
    { Text: '10', Value: 10 },
    { Text: '11', Value: 11 },
    { Text: '12', Value: 12 },
    { Text: '13', Value: 13 },
    { Text: '14', Value: 14 },
    { Text: '15', Value: 15 },
    { Text: '16', Value: 16 },
    { Text: '17', Value: 17 }
  ];

  private _maxChildren: number = 0;
  private _maxAdults: number = 0;
  private _sidebarParamsSubscription: Subscription | undefined;
  private _getSettingsSubscription: Subscription | undefined;

  constructor(
    public mpSettings: MpSettingsService,
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService
  ) { }

  /**
   * Gets the sidebar params and the settings.
   * Sets the max values for children and adults.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        AnzahlErwachsene: this.selection['AnzahlErwachsene'] || this.mpSidebar.params['AnzahlErwachsene'] || 1,
        AnzahlKinder: this.selection['AnzahlKinder'] || clone(this.mpSidebar.params['AnzahlKinder']) || (this.selection['Kinder'] && this.selection['Kinder'].length) || (this.mpSidebar.params['Kinder'] && this.mpSidebar.params['Kinder'].length) || 0,
        Kinder: this.selection['Kinder'] || clone(this.mpSidebar.params['Kinder']) || []
      });

      this.selectionLoaded = true;
    });

    if (Object.keys(this.mpSettings.settings).length > 0) {
      this._maxChildren = this.mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlKinder;
      this._maxAdults = this.mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlErwachsene;
    } else {
      this._getSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._maxChildren = this.mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlKinder;
          this._maxAdults = this.mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlErwachsene;
        }
      });

      this.mpSettings.getSettings();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }
  }

  /**
   * Saves the selected airports.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Adds an adult.
   */
  addAdult(): void {
    if (this.selection['AnzahlErwachsene'] < this._maxAdults) {
      this.selection['AnzahlErwachsene']++;
    }
  }

  /**
   * Removes an adult.
   */
  removeAdult(): void {
    if (this.selection['AnzahlErwachsene'] > 1) {
      this.selection['AnzahlErwachsene']--;
    }
  }

  /**
   * Adds a child
   */
  addChild(): void {
    if (this.selection['AnzahlKinder'] < this._maxChildren && this.selection['AnzahlErwachsene']) {
      this.selection['AnzahlKinder']++;
      this.selection['Kinder'].push(this.childrenOldOptions[0]);
    }
  }

  /**
   * Removes a child.
   */
  removeChild(): void {
    if (this.selection['AnzahlKinder'] > 0) {
      this.selection['AnzahlKinder']--;
      this.selection['Kinder'].pop();
    }
  }

}
