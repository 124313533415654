<div [hidden]="!show || !hotel['angebote'] " class="bg-color-white hotel-details tabbed-details" *ngIf="(ls.locsLoaded | async)">
  <div class="tap-wrapper">
    <div [ngClass]="'visible-tabs-'+tabCounter" class="flex-grid margin-0">
      <div class="flex-col-6 flex-col-6-xs text-transform-uppercase padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 pointer-hover" (click)="tabIndex = 1" [ngClass]="[tabIndex == 1 ? 'active bg-color-brand-primary' : 'not-active']" counter="tabCounter">{{ ls.locs['loc'].Details }}</div>
      <div class="flex-col-6 flex-col-6-xs text-transform-uppercase padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 pointer-hover" (click)="tabIndex = 2" [ngClass]="[tabIndex == 2 ? 'active bg-color-brand-primary' : 'not-active']" counter="tabCounter">{{ ls.locs['locReisekonfigurator'].ReiseBuchen }}</div>
    </div>
    <div class="rk-tab-content">
      <div [hidden]="tabIndex !== 1" class="rk-hotel-details">
        <div class="bold margin-bottom-16">{{ ls.locs['locReisekonfigurator'].HotelDetails }}</div>
        <div [innerHTML]="hotel['DetailText']"></div>
      </div>
      <div *ngIf="tabIndex == 2">
        <div *ngFor="let angebot of pagedAndFilteredOffers" class="rk-booking">
          <div class="rk-booking-details">
            <div class="rk-booking-details-range">
              <div class="bold">{{angebot.Display}}</div>
              <div class="margin-0 flex-grid flex-justify-cont-between flex-align-items-start">
                <div>
                  <span>{{angebot.StartTerminDisplay}} - {{angebot.EndTerminDisplay}}</span>
                </div>
              </div>
              <div class="margin-0 flex-grid flex-justify-cont-between flex-align-items-start">
                <div>
                  <span [innerHTML]="ls.locs['locReisekonfigurator'].VeranstaltetVon | format:[getOfferHtml(angebot)]"></span>
                  <mp-core-popover *ngIf="angebot.Veranstalter" content="{{angebot.Veranstalter.Name}}<br />{{angebot.Veranstalter.Strasse}}<br />{{angebot.Veranstalter.Plz}} {{angebot.Veranstalter.Ort}}<br /><br />{{angebot.Veranstalter.Telefon}} {{angebot.Veranstalter.Erreichbarkeit}}" popoverTitle="{{ ls.locs['loc'].Veranstalter }}">
                    <i class="svg-icon __clr-info __size-18 __help-outline"></i>
                  </mp-core-popover>
                </div>
              </div>
            </div>
            <div class="rk-booking-details-services">
              <div class="display-flex">
                <i class="svg-icon __clr-default __size-22 __top-0 __hotel margin-right-8 default-cursor-hover"></i>
                <span *ngFor="let zk of angebot.Zimmerkuerzel">
                  <span>{{zk.Beschreibung}}</span>
                  <span *ngIf="angebot.Zimmerkuerzel[angebot.Zimmerkuerzel.length - 1] != zk">/</span>
                </span>
              </div>
              <div class="display-flex" *ngIf="angebot.VerpflegungDisplay">
                <i class="svg-icon __clr-default __size-22 __top-0 __restaurant margin-right-8 default-cursor-hover"></i>
                <span>{{angebot.VerpflegungDisplay}}</span>
              </div>
              <div class="display-flex" *ngIf="angebot.TransferText && angebot.Transfer && ((angebot.AbflughafenHinDisplay && angebot.AnkunftflughafenHinDisplay) || (angebot.AbflughafenRueckDisplay && angebot.AnkunftflughafenRueckDisplay))">
                <i class="svg-icon __clr-default __size-22 __top-0 margin-right-8 default-cursor-hover" [ngClass]="angebot.Transfer === transfer['Mietwagen'] ? '__car' : angebot.transfer === transfer['Bustransfer'] ? '__bus' : '__cancel'"></i>
                <span>{{angebot.TransferText}}</span>
              </div>
            </div>
            <div *ngIf="angebot.AnkunftflughafenHin !== null" class="rk-booking-details-start" [hidden]="!angebot.AbflughafenHinDisplay || !angebot.AnkunftflughafenHinDisplay ">
              <mp-core-popover content="{{ ls.locs['locReisekonfigurator'].FlugzeitenOrtszeiten }}" alignment="left" popoverTitle="{{ ls.locs['locReisekonfigurator'].Flugzeiten }}">
                <i class="svg-icon __clr-info __size-22 __top-0 __info"></i>
              </mp-core-popover>
              <div class="flex-grid">
                <div class="flex-col-12 flex-col-5-xs">
                  <i class="svg-icon __clr-default __size-22 __top-0 __flight-takeoff margin-right-8 default-cursor-hover"></i>
                  <span class="bold">{{ ls.locs['locReisekonfigurator'].Hinflug }}</span>
                </div>
                <div class="flex-col-7-xs text-align-right flex-direction-column show-on-small-mobile">
                  <div class="text-color-info font-size-s">{{angebot.AirlineHin.Name}}</div>
                  <div class="text-color-info font-size-s">{{angebot.FlugklasseHin}}</div>
                </div>
              </div>
              <div class="flex-grid flex-direction-row">
                <div class="flex-col-2 flex-col-4-l flex-direction-column hide-on-small-mobile">
                  <div class="text-color-info font-size-s">{{angebot.AirlineHin.Name}}</div>
                  <div class="text-color-info font-size-s">{{angebot.FlugklasseHin}}</div>
                </div>
                <div class="flex-col-10 flex-col-8-l flex-col-12-xs flex-wrap padding-0 rk-flight-summary">
                  <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.AbflugzeitHinDisplayKurz}}</div>
                  </div>
                  <div class="flex-col-3 flex-col-8-l flex-col-8-xs font-size-s">
                    <div>{{angebot.FlugzeitenHinDisplay}}</div>
                  </div>
                  <div class="flex-col-3 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.AbflughafenHin.Kuerzel}}-{{angebot.AnkunftflughafenHin.Kuerzel}}</div>
                  </div>
                  <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.FlugdauerHinDisplay}}</div>
                  </div>
                  <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.AnzahlStopsHinDisplay}}</div>
                  </div>
                </div>
              </div>
            </div>
            <hr *ngIf="angebot.AbflughafenRueck !== null" [hidden]="!angebot.AbflughafenRueckDisplay || !angebot.AnkunftflughafenRueckDisplay " />
            <div *ngIf="angebot.AbflughafenRueck !== null" class="rk-booking-details-end" [hidden]="!angebot.AbflughafenRueckDisplay || !angebot.AnkunftflughafenRueckDisplay ">
              <div class="flex-grid">
                <div class="flex-col-12 flex-col-5-xs">
                  <i class="svg-icon __clr-default __size-22 __top-0 __flight-takeoff margin-right-8 flip-horizontal default-cursor-hover"></i>
                  <span class="bold">{{ ls.locs['locReisekonfigurator'].Rueckflug }}</span>
                </div>
                <div class="flex-col-7-xs text-align-right flex-direction-column show-on-small-mobile">
                  <div class="text-color-info font-size-s">{{angebot.AirlineRueck.Name}}</div>
                  <div class="text-color-info font-size-s">{{angebot.FlugklasseRueck}}</div>
                </div>
              </div>
              <div class="flex-grid flex-direction-row">
                <div class="flex-col-2 flex-col-4-l flex-direction-column hide-on-small-mobile">
                  <div class="text-color-info font-size-s">{{angebot.AirlineRueck.Name}}</div>
                  <div class="text-color-info font-size-s">{{angebot.FlugklasseRueck}}</div>
                </div>
                <div class="flex-col-10 flex-col-8-l flex-col-12-xs flex-wrap padding-0 rk-flight-summary">
                  <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.AbflugzeitRueckDisplayKurz}}</div>
                  </div>
                  <div class="flex-col-3 flex-col-8-l flex-col-8-xs font-size-s">
                    <div>{{angebot.FlugzeitenRueckDisplay}}</div>
                  </div>
                  <div class="flex-col-3 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.AbflughafenRueck.Kuerzel}}-{{angebot.AnkunftflughafenRueck.Kuerzel}}</div>
                  </div>
                  <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.FlugdauerRueckDisplay}}</div>
                  </div>
                  <div class="flex-col-2 flex-col-4-l flex-col-4-xs font-size-s">
                    <div>{{angebot.AnzahlStopsRueckDisplay}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rk-booking-actions display-flex flex-direction-column flex-align-items-end flex-justify-cont-center padding-left-right-16">
            <div class="text-color-info font-size-xl margin-bottom-8">
              {{angebot.Punkte | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</div>
            <a *ngIf="searchId !== ''" class="mp-button _prim" href="#/{{role}}/HotelPruefung?id={{angebot.ID}}&code={{hotel['HotelCode']}}&sid={{searchId}}">{{ ls.locs['locReisekonfigurator'].ZurBuchung }}</a>
            <button class="mp-button _sec margin-top-8" [ngClass]="angebot.showDetails ? '_dark' : ''" [hidden]="(!angebot.FlugabschnitteHin || angebot.FlugabschnitteHin.length === 0) && (!angebot.FlugabschnitteRueck || angebot.FlugabschnitteRueck.length === 0)" (click)="angebot.showDetails = !angebot.showDetails">{{angebot.showDetails ? ls.locs['locReisekonfigurator'].FlugDetailsAusblenden : ls.locs['locReisekonfigurator'].FlugDetails}}</button>
            </div>
          <div class="rk-flight-details" [hidden]="!angebot.showDetails">
            <div class="flex-grid margin-bottom-16 flight-steps-headline">
              <span class="flex-col-2 flex-col-2-s flex-col-4-xs font-weight-500 text-color-info">{{ ls.locs['locReisekonfigurator'].Hinflug }}:</span>
              <span class="flex-col-8 flex-col-7-s flex-col-8-xs font-weight-500 text-color-info">
                <i class="svg-icon __clr-default __size-22 __top--2 __calendar margin-right-8 default-cursor-hover"></i> 
                {{angebot.AbflugdatumHinDetailsDisplay}}
              </span>
              <span class="show-on-small-mobile flex-col-4-xs"></span>
              <span class="flex-col-2 flex-col-3-s flex-col-8-xs flex-justify-cont-end font-weight-500 flight-duration text-color-info">
                <i class="svg-icon __clr-default __size-22 __top--2 __time-rk margin-right-8 default-cursor-hover"></i> 
                {{angebot.FlugdauerHinDisplay}}
              </span>
            </div>
            <div class="flex-grid flight-steps">
              <div *ngFor="let abschnitt of angebot.FlugabschnitteHin; trackBy: trackByIndex; let last = last; let first = first" class="flex-grid">
                <div class="flex-col-12 margin-bottom-16">
                  <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                    <span class="font-weight-500 text-color-info" [innerText]="abschnitt.AbfluguhrzeitDisplay"></span>
                    <span class="font-size-xs text-color-grey-info" [innerText]="abschnitt.AbflugdatumDisplay"></span>
                  </div>
                  <div class="flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="first ? 'flight-point-filled' : 'flight-point-circle'">
                    <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="first ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
                  </div>
                  <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                    <span class="flex-col-12 padding-left-right-0" [innerText]="abschnitt.Abflughafen.Stadt+' ('+abschnitt.Abflughafen.Kuerzel+')'"></span>
                    <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="abschnitt.Abflughafen.Bezeichnung"></span>
                    <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300 padding-left-0">{{abschnitt.Airline.Name}} ({{abschnitt.Flugnummer}}), {{abschnitt.Flugklasse || angebot.FlugklasseHin}}</span>
                    <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="abschnitt.FlugdauerDisplay"></span>
                  </div>
                  <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                    <span [innerText]="abschnitt.FlugdauerDisplay"></span>
                  </div>
                </div>
                <div class="flex-col-12 margin-bottom-16">
                  <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                    <span class="font-weight-500 text-color-info" [innerText]="abschnitt.AnkunftuhrzeitDisplay"></span>
                    <span class="font-size-xs text-color-grey-info" [innerText]="abschnitt.AnkunftdatumDisplay"></span>
                  </div>
                  <div class="flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="last ? 'flight-point-filled' : 'flight-point-circle'">
                    <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="last ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
                  </div>
                  <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                    <span class="flex-col-12 padding-left-right-0" [innerText]="abschnitt.Ankunftflughafen.Stadt+' ('+abschnitt.Ankunftflughafen.Kuerzel+')'"></span>
                    <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="abschnitt.Ankunftflughafen.Bezeichnung"></span>
                    <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300" [hidden]="last">
                      {{ ls.locs['locReisekonfigurator'].UmstiegIn | format:[abschnitt.Ankunftflughafen.Stadt] }}
                      <span *ngIf="abschnitt.Nachtaufenthalt" class="text-color-brand-primary">&nbsp;mit Nachtaufenthalt</span>
                    </span>
                    <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="abschnitt.AufenthaltsdauerDisplay"></span>
                  </div>
                  <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                    <span [innerText]="abschnitt.AufenthaltsdauerDisplay"></span>
                  </div>
                </div>
              </div>
            </div>
            <hr class="margin-bottom-0" />
            <div class="flex-grid flight-marker">
              <div class="flex-col-1 flex-col-2-s flex-col-4-xs"></div>
              <div class="flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center pin-icon">
                <i class="svg-icon __clr-info __size-32 __top-0 __place margin-top-4 margin-bottom-4 default-cursor-hover"></i>
              </div>
              <div class="flex-col-10 flex-col-9-s flex-col-7-xs flex-align-items-center">{{ ls.locs['locReisekonfigurator'].XInY | format:[(ls.locs['locReisekonfigurator'].NNaechte | format:[angebot.DauerInNaechten]), hotel['Name']] }}</div>
            </div>
            <hr class="margin-top-0 margin-bottom-32" />
            <div class="flex-grid margin-bottom-16 flight-steps-headline">
              <span class="flex-col-2 flex-col-2-s flex-col-4-xs font-weight-500 text-color-info">{{ ls.locs['locReisekonfigurator'].Rueckflug }}:</span>
              <span class="flex-col-8 flex-col-7-s flex-col-8-xs font-weight-500 text-color-info">
                <i class="svg-icon __clr-default __size-22 __top--2 __calendar margin-right-8 default-cursor-hover"></i> 
                {{angebot.AbflugdatumRueckDetailsDisplay}}
              </span>
              <span class="show-on-small-mobile flex-col-4-xs"></span>
              <span class="flex-col-2 flex-col-3-s flex-col-8-xs flex-justify-cont-end font-weight-500 flight-duration text-color-info">
                <i class="svg-icon __clr-default __size-22 __top--2 __time-rk margin-right-8 default-cursor-hover"></i> 
                {{angebot.FlugdauerRueckDisplay}}
              </span>
            </div>
            <div class="flex-grid flight-steps">
              <div *ngFor="let abschnitt of angebot.FlugabschnitteRueck; trackBy: trackByIndex; let last = last; let first = first" class="flex-grid">
                <div class="flex-col-12 margin-bottom-16">
                  <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                    <span class="font-weight-500 text-color-info" [innerText]="abschnitt.AbfluguhrzeitDisplay"></span>
                    <span class="font-size-xs text-color-grey-info" [innerText]="abschnitt.AbflugdatumDisplay"></span>
                  </div>
                  <div class="flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="first ? 'flight-point-filled' : 'flight-point-circle'">
                    <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="first ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
                  </div>
                  <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                    <span class="flex-col-12 padding-left-right-0" [innerText]="abschnitt.Abflughafen.Stadt+' ('+abschnitt.Abflughafen.Kuerzel+')'"></span>
                    <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="abschnitt.Abflughafen.Bezeichnung"></span>
                    <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300 padding-left-0">{{abschnitt.Airline.Name}} ({{abschnitt.Flugnummer}}), {{abschnitt.Flugklasse || angebot.FlugklasseRueck}}</span>
                    <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="abschnitt.FlugdauerDisplay"></span>
                  </div>
                  <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                    <span [innerText]="abschnitt.FlugdauerDisplay"></span>
                  </div>
                </div>
                <div class="flex-col-12 margin-bottom-16">
                  <div class="flex-col-1 flex-col-2-s flex-col-4-xs flex-direction-column">
                    <span class="font-weight-500 text-color-info" [innerText]="abschnitt.AnkunftuhrzeitDisplay"></span>
                    <span class="font-size-xs text-color-grey-info" [innerText]="abschnitt.AnkunftdatumDisplay"></span>
                  </div>
                  <div class="flex-col-1 flex-col-1-s flex-col-1-xs flex-justify-cont-center padding-top-4 flight-point" [ngClass]="last ? 'flight-point-filled' : 'flight-point-circle'">
                    <i class="system-icon text-align-center pointer fa font-size-l" [ngClass]="last ? 'fa-circle text-color-brand-primary' : 'fa-circle-thin'"></i>
                  </div>
                  <div class="flex-col-8 flex-col-7-s flex-col-7-xs flex-wrap">
                    <span class="flex-col-12 padding-left-right-0" [innerText]="abschnitt.Ankunftflughafen.Stadt+' ('+abschnitt.Ankunftflughafen.Kuerzel+')'"></span>
                    <span class="flex-col-12 padding-left-right-0 font-size-s" [innerText]="abschnitt.Ankunftflughafen.Bezeichnung"></span>
                    <span class="flex-col-12 margin-top-8 padding-right-0 font-size-s text-color-grey-info font-weight-300" [hidden]="last">
                      {{ ls.locs['locReisekonfigurator'].UmstiegIn | format:[abschnitt.Ankunftflughafen.Stadt] }}
                      <span *ngIf="abschnitt.Nachtaufenthalt">&nbsp;mit Nachtaufenthalt</span>
                    </span>
                    <span class="show-on-small-mobile flex-col-12 padding-right-0 font-size-s text-color-grey-info font-weight-300" [innerText]="abschnitt.AufenthaltsdauerDisplay"></span>
                  </div>
                  <div class="flex-col-2 flex-col-2-s flex-justify-cont-end flex-align-items-end font-size-s text-color-grey-info font-weight-300 hide-on-small-mobile">
                    <span [innerText]="abschnitt.AufenthaltsdauerDisplay"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rk-load-more-offers display-flex flex-align-items-center flex-justify-cont-center">
          <div class="display-inline-flex flex-align-items-center flex-justify-cont-center padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 text-transform-uppercase" [hidden]="startindex + pagesize >= filteredOffers.length" (click)="loadAdditionalOffers()">{{ ls.locs['locReisekonfigurator'].WeitereErgebnisseAnzeigen }}</div>
          <div class="display-inline-flex flex-align-items-center flex-justify-cont-center padding-top-8 padding-bottom-8 padding-left-16 padding-right-16 text-transform-uppercase" [ngClass]="{noBorder: startindex + pagesize >= filteredOffers.length}" [hidden]="startindex <= 0 " (click)="hideOffers()">{{ ls.locs['locReisekonfigurator'].WeitereErgebnisseAusblenden }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
