import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

import { ShoppingBasketItem } from './../shopping-basket/shopping-basket-item';

/**
 * This class provides the data for
 * the order confirmation page.
 */
@Component({
  selector: 'mp-core-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderConfirmationComponent implements OnInit, OnDestroy {

  public orderNo: string = '';
  public cartItems: Array<ShoppingBasketItem> = [];
  public shoppingCart: Array<any> = [];
  public role: string = '';

  private _queryParamsSubscription: Subscription | undefined;
  private _orderDetailsSubscription: Subscription | undefined;

  constructor(
    private _route: ActivatedRoute,
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the data of the order, so
   * the user can see the order
   * confirmation.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    localStorage.removeItem('selectedBestellAdresse');

    this._queryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['bestellNr'] !== 'undefined' || typeof params['bestellnr'] !== 'undefined') {
        this.orderNo = typeof params['bestellNr'] !== 'undefined' ? params['bestellNr'] : params['bestellnr'];

        this._orderDetailsSubscription = this._apiService.getRequest(`/api/Bestellung/getBestellDetailsForBestellbestaetigung/${this.orderNo}`).subscribe((data: any) => {
          if (data.Result === 'OK') {
            const record = data.Records[0];

            if (record) {
              this.cartItems = record.Items;
              const groupedBasketKeys = Object.keys(record.GroupedItems);

              if (groupedBasketKeys.length > 0) {
                groupedBasketKeys.forEach((key: string) => {
                  this.shoppingCart.push(record.GroupedItems[key]);
                });
              }

            }
          }
        });
      }
    });
  }

  /**
   * Unsubscribes set subscriptions-
   */
  ngOnDestroy(): void {
    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._orderDetailsSubscription !== 'undefined') {
      this._orderDetailsSubscription.unsubscribe();
    }
  }

}
