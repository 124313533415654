import { Component, OnInit, OnDestroy, Injector, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { AdditionalPaymentService } from './../../../../services/additional-payment.service';

import { AdditionalPaymentPaypalComponent } from './../../../../components/additional-payment-paypal/additional-payment-paypal.component';
import { AdditionalPaymentPayplaceComponent } from './../../../../components/additional-payment-payplace/additional-payment-payplace.component';
import { AdditionalPaymentPrepaymentComponent } from './../../../../components/additional-payment-prepayment/additional-payment-prepayment.component';

/**
 * This class provides the functions for
 * buiyng points with additional payment.
 */
@Component({
  selector: 'mp-zz-additional-payment',
  templateUrl: './additional-payment.component.html'
})
export class AdditionalPaymentComponent implements OnInit, OnDestroy {

  public participant: { [key: string]: any } = {};
  public package: { [key: string]: any } = {
    Punkte: 0
  };
  public packages: Array<any> = [];
  public additionalPaymentProvider: Array<any> = [];
  public confirmationStep: string = '';
  public additionalPaymentDetailsStep: string = '';
  public type: string = '';
  public pointsRange: string = '';
  public additionalPaymentType: string = '';
  public injector: Injector | any;
  public lockButton: boolean = false;
  public errors: Array<string> = [];
  public viewAmount: string = '';
  public errorPoints: boolean = false;

  private _minimum: number = 0;
  private _maximum: number = 0;
  private _getSettingsSubscription: Subscription | undefined;
  private _getProviderDataSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;
  private _getCountriesListSubscription: Subscription | undefined;
  private _getPackagesSubscription: Subscription | undefined;
  private _getAmountSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _additionalPaymentService: AdditionalPaymentService,
    private _injector: Injector,
    private _router: Router
  ) { }

  /**
   * Sets the title and menu highlighting. Gets
   * countries for selection, and gets the
   * available packages.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('Zuzahlung');
    this._mpMenuHighlight.setMenuHighlight('konto');

    if (Object.keys(this.mpSettings.settings).length > 0) {
      this._getProviderData();
    } else {
      this._getSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._getProviderData();
        }
      });

      this.mpSettings.getSettings();
    }

    this._getCountriesListSubscription = this._apiService.getRequest('/api/Config/Laender/').subscribe((data: any) => {
      if (data.Result === "OK") {
        this.participant['LKZ'] = data.Records[0];
      }
    });

    this._getPackagesSubscription = this._apiService.getRequest('/api/Zuzahlung/getpakete').subscribe((data: any) => {
      this.packages = data.Records;

      if (this.packages && this.packages.length > 0) {
        this.package = data.Records[0];
        this._minimum = data.Records[0].Punkte;
        this._maximum = data.Records[data.Records.length - 1].Punkte;
        this.viewAmount = this._minimum + ' €';

        this.getAmount();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }

    if (typeof this._getProviderDataSubscription !== 'undefined') {
      this._getProviderDataSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getCountriesListSubscription !== 'undefined') {
      this._getCountriesListSubscription.unsubscribe();
    }

    if (typeof this._getPackagesSubscription !== 'undefined') {
      this._getPackagesSubscription.unsubscribe();
    }

    if (typeof this._getAmountSubscription !== 'undefined') {
      this._getAmountSubscription.unsubscribe();
    }
  }

  /**
   * Gets the amount of the selected
   * packages.
   */
  getAmount(): void {
    if (Object.keys(this.package).length > 0) {
      this._getAmountSubscription = this._apiService.getRequest(`/api/Zuzahlung/getBetrag/${this.package['Punkte']}`).subscribe((data: any) => {
        if (data.Result === "OK") {
          this.viewAmount = data.Records[0].Betrag + ' €';
        }
      });
    }
  }

  /**
   * Sets the package.
   */
  setPackage(pack: { [key: string]: any }): void {
    this.package = pack;
    this.getAmount();
  }

  /**
   * Gets the additional payment providers.
   */
  private _getProviderData(): void {
    this._getProviderDataSubscription = this._apiService.getRequest('/api/Zuzahlung/GetProviderData').subscribe((data: any) => {
      this.additionalPaymentProvider = data.Records;

      if (Object.keys(this.ls.locs).length > 0) {
        this._setDataByProviders();
      } else {
        this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            this._setDataByProviders();
          }
        });

        this.ls.getLocalization();
      }
    });
  }

  /**
   * Sets additional data by the previous
   * fetched additional payment providers.
   */
  private _setDataByProviders(): void {
    this.confirmationStep = this.additionalPaymentProvider.length === 1 ? this.ls.locs['locZuzahlung'].ZuzahlungSchritt3 : this.ls.locs['locZuzahlung'].ZuzahlungSchritt4;
    this.additionalPaymentDetailsStep = this.additionalPaymentProvider.length === 1 ? this.ls.locs['locZuzahlung'].ZuzahlungSchritt2 : this.ls.locs['locZuzahlung'].ZuzahlungSchritt3;
    this.type = (this.additionalPaymentProvider.length == 1) ? this.additionalPaymentProvider[0].Key : '';
    const minimum = this.mpSettings.settings['ZuzahlungSettings'].ZuzahlungPakete[0];
    const maximum = this.mpSettings.settings['ZuzahlungSettings'].ZuzahlungPakete[this.mpSettings.settings['ZuzahlungSettings'].ZuzahlungPakete.Length - 1];
    this.pointsRange = this.ls.locs['locZuzahlung'].PunkteBetragRequired;
    const args = [minimum, maximum, this.ls.locs['loc'].Punkten];

    for (let i = 0; i < args.length; i++) {
      this.pointsRange = this.pointsRange.replace(new RegExp("\\{" + i + "\\}", "g"), args[i]);
    }
  }

  /**
   * Maps the child template path
   * from backendto the child template
   * in Angular.
   */
  getChildComponent(childTemplate: string): any {
    switch (childTemplate) {
      case '/Views/ng-templates/zuzahlung-paypal':
        return AdditionalPaymentPaypalComponent;
      case '/Views/ng-templates/zuzahlung-payplace':
        return AdditionalPaymentPayplaceComponent;
      case '/Views/ng-templates/zuzahlung-vorkasse':
        return AdditionalPaymentPrepaymentComponent;
    }
  }

  /**
   * Creates the injector for the
   * dynamically loaded (sub-)components.
   */
  private _createInjector() {
    this.injector = Injector.create({
      providers: [
        { provide: 'package', useValue: this.package },
        { provide: 'psrticipant', useValue: this.participant }
      ],
      parent: this._injector
    });
  }

  /**
   * Checks whether or not the confirm
   * button should be shown.
   */
  showButton(count: number): boolean {
    return this.additionalPaymentType !== '' || count === 1;
  }

  /**
   * Sets the additional payment type.
   */
  setType(key: string): void {
    this.additionalPaymentType = key;
    this.getAmount();
    this._createInjector();
  }

  /**
   * Confirms the additional payment.
   */
  confirm(key: string): void {
    this.lockButton = true;

    if (this._validate()) {
      const type = key ? key : this.additionalPaymentType;

      this._additionalPaymentService.call(type, this._confirmSuccess.bind(this), this._confirmError.bind(this));
    } else {
      this.lockButton = false;
    }
  }

  /**
   * Handles the success callback of the
   * confirm function.
   */
  private _confirmSuccess(data: any): void {
    if (data.Message === 'redirect')
      window.location.href = (data.Records[0]);
    else if (data.Message === 'redirect-newtab')
      window.open(data.Records[0], '_blank');
    else {
      this._mpMessaging.openSuccessPanel(data.Message);
      this._router.navigateByUrl('/tn/Home');
      this.lockButton = false;
    }
  }

  /**
   * Handles the error callback of the
   * confirm function.
   */
  private _confirmError(error: any): void {
    this.errors = error.ModelState.ERROR;
    this.lockButton = false;
  }

  /**
   * Validates the additional payment.
   */
  private _validate(): boolean {
    this.errorPoints = false;

    if (!this.package['Punkte'] || this.package['Punkte'] < this._minimum) {
      this.package['Punkte'] = this._minimum;
      this.errorPoints = true;
      return false;
    }

    if (this.package['Punkte'] > this._maximum) {
      this.package['Punkte'] = this._maximum;
      this.errorPoints = true;
      return false;
    }

    return true;
  }

}
