import { Component, OnInit, OnDestroy, Injector, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ApiService } from './../../../services/api.service';
import { MpLocalizationService } from './../../../services/mp-localization.service';
import { InboxService } from './../inbox.service';

/**
 * This class provides the data and
 * the functions for the details of
 * a message item in the inbox.
 */
@Component({
  selector: 'mp-core-inbox-details-message',
  templateUrl: './inbox-details-message.component.html'
})
export class InboxDetailsMessageComponent implements OnInit, OnDestroy {

  public loading: boolean = true;
  public item: any;
  public trustedHtml: any;

  private _attachmentsSubscription: Subscription | undefined;

  constructor(
    public sanitizer: DomSanitizer,
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _injector: Injector,
    private _inboxService: InboxService
  ) { }

  /**
   * Gets the attachments of the item.
   */
  ngOnInit(): void {
    this.item = this._injector.get('iboxItem');

    if (typeof this.item !== 'undefined' && this.item !== null) {
      this._attachmentsSubscription = this._apiService.getRequest(`/api/Postfach/GetMailAttachments/${this.item.Key}`).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.item.attachments = data.Records;
        }

        this.loading = false;
      },
      (error: any) => {
          this.loading = false;
      });
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._attachmentsSubscription !== 'undefined') {
      this._attachmentsSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the delete function of
   * the parent component.
   */
  onItemDelete(item: any): void {
    this._inboxService.triggerParentClickFunction(item, 'onItemDelete');
  }

  /**
   * Triggers the on click function of
   * the parent component.
   */
  onClick(evt: MouseEvent, item: any): void {
    evt.preventDefault();

    this._inboxService.triggerParentClickFunction(item, 'onClick');
  }

  /*
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
