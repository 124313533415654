import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

/**
 * This class provides the functions for
 * the toggle button.
 */
@Component({
  selector: 'mp-core-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleButtonComponent implements OnInit {

  @Input() onLabel: string = '';
  @Input() offLabel: string = '';
  @Input() label: string = '';
  @Input() active: boolean = false;
  @Output() activeChange = new EventEmitter<boolean>();

  public uuid: string = '';

  constructor() { }

  /**
   * Generates a uuid for the form
   * elements.
   */
  ngOnInit(): void {
    this.uuid = uuidv4();
  }

  /**
   * Toggles the active state.
   */
  toggleActiveState(): void {
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }

}
