<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].AktuellerPunktestand }}</h1>
  <mp-core-date-picker label="{{ ls.locs['loc'].PunktestandAm }}"
                       mpId="date-picker-von"
                       class="form-control suchKontainerDPDatum date-picker fallback-input"
                       [maxDate]="enddate"
                       (modelChange)="enddate = $event; updateEnddate();"
                       [model]="enddate"
                       mpType="text">
  </mp-core-date-picker>
  <mp-core-data-table title="{{ ls.locs['loc'].AktuellerPunktestand }}" [minimizedStyle]="true" infotextShorten="AktuellerPunktestand" fieldsAction="/api/Statistik/getAktuellerPunktestandFields" listAction="/api/Statistik/getAktuellerPunktestand" [execClickFunctions]="execClickFunctions" [recLoadedFunc]="recLoadedFunc" loaderName="currentScoreTableLoader" [paging]="true" [footer]="true" defaultSorting="Punkte DESC"></mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
