import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subject, Subscription, startWith } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { CookieType } from './interfaces/cookie-type';
import { CookieInfo } from './interfaces/cookie-info';
import { MpSettingsService } from './mp-settings.service';

const cookieConsentCookieName: string = 'cookieConsent';

/**
 * This class provides some functions to handle
 * cookies.
 */
@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private _cookieTypes: CookieType[] | null = null;
  private _cookieTypesLoading: boolean = false;

  private _consentBannerOpenedSubject: Subject<boolean> = new Subject<boolean>();

  public consentBannerOpened: Observable<boolean> = this._consentBannerOpenedSubject.asObservable();

  private _cookiesUpdatedSubject: Subject<boolean> = new Subject<boolean>();

  public cookiesUpdated: Observable<boolean> = this._cookiesUpdatedSubject.asObservable();

  constructor(
    private _apiService: ApiService,
    private _mpSettings: MpSettingsService
  ) { }

  /**
   * Checks whether or not a cookie is set for
   * the given cookie name.
   */
  checkIfCookieSet(cookieToCheck: string, getValue?: boolean): boolean | string {
    const cookies = document.cookie.split(';');
    let cookieSet = false;
    let cookieValue = '';

    for (let encodedCookie of cookies) {
      let cookie = decodeURIComponent(encodedCookie);
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1);
      }

      if (cookie.indexOf(cookieToCheck + '=') !== -1) {
        cookieSet = true;
        cookieValue = cookie.split('=')[1];
        break;
      }
    }

    if (getValue) {
      return cookieValue;
    }

    return cookieSet;
  }

  /**
   * Sets the given string as a browser cookie,
   * if that cookie not already exists.
   */
  setCookie(cookie: string, options?: any) {
    let cookieName = cookie.split('=')[0];

    this.canSetCookie(cookieName).then((consentGiven: boolean) => {
      if (!consentGiven)
        return;

      cookie = this.setCookieWithoutCheck(cookie, options);
    });
  }

  private _getSetCookieName(cookieName: string): string {
    const cookies = document.cookie.split(';');
    let actualCookieName = '';

    for (let encodedCookie of cookies) {
      let cookie = decodeURIComponent(encodedCookie);
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1);
      }

      var regex = new RegExp(cookieName.replaceAll('*', '.+') + '=', 'g');

      if (cookie.match(regex)?.length ?? 0 > 0) {
        let value = cookie.split('=')[1];
        if (value) {
          actualCookieName = cookie.split('=')[0];
          break;
        }
      }
    }

    return actualCookieName;
  }

  /**
   * Sets the Cookie without checking for Consent.
   * Only to be used in manager or admin area!
   * 
   * @param cookie
   * @param options can have three fields "secure:boolean", "expires:number" (expiry in days), "path:string"
   * @returns
   */
  public setCookieWithoutCheck(cookie: string, options: any) {
    if (options) {
      if (options.secure) {
        cookie += ';secure';
      }

      if (options.expires) {
        cookie += `;expires=${((new Date(Date.now() + 86400 * 1000 * options.expires)).toUTCString())}`;
      }

      if (options.path) {
        cookie += `;path=${options.path}`;
      }
    }

    if (!this.checkIfCookieSet(cookie)) {
      document.cookie = cookie;
    }
    return cookie;
  }

  /**
   * Gets the value of a cookie, if it is set in
   * the users browser.
   */
  getCookieValue(cookie: string): string {
    return (this.checkIfCookieSet(cookie, true) || '').toString();
  }

  openCookieConsentBanner() {
    this._consentBannerOpenedSubject.next(true);
  }

  private _updateConsentFromCookie(cookies: CookieInfo[], defaultValue: boolean | null): boolean | null {
    var cookie = this.getCookieValue(cookieConsentCookieName);
    if (!cookie)
      return defaultValue;

    var cookieConsent = JSON.parse(cookie.toString());
    if (!cookieConsent.consent)
      return defaultValue;

    let trueCount = 0, falseCount = 0;
    cookies.forEach((cookie: CookieInfo) => {
      cookieConsent.consent.forEach((consent: any) => {
        if (consent.type !== 'cookie')
          return;

        if (consent.identifier === cookie.Identifier && cookie.HasConsent === null) {
          cookie.HasConsent = consent.hasConsent ?? false;
          return;
        }
      });

      if (cookie.HasConsent) {
        trueCount++;
      } else {
        falseCount++;
      }
    });

    // fill tristate checkbox
    return falseCount == 0 ? true : trueCount == 0 ? false : null;
  }

  public getCookieTypes(force: boolean = false): Promise<CookieType[]> {
    return new Promise<CookieType[]>((resolve, reject) => {
      if (this._cookieTypes && !force) {
        resolve(this._cookieTypes);
      } else if (!this._cookieTypesLoading) {
        this._cookieTypesLoading = true;
        let getCookiesSubscription = this._apiService.getRequest("/api/CookieConsent/GetCookieTypes?includeCookies=true").subscribe((data) => {
          this._cookieTypes = data.Records;

          this._cookiesUpdatedSubject.next(data.Result === 'OK');
          getCookiesSubscription.unsubscribe();

          this._cookieTypesLoading = false;

          this._cookieTypes?.forEach((type: CookieType) => {
            type.HasConsent = this._updateConsentFromCookie(type.Cookies, type.HasConsent)
          });

          resolve(this._cookieTypes ?? []);
        }, () => this._cookieTypesLoading = false);
      } else {
        reject([]);
      }
    });
  }

  public isCookieSet(): boolean {
    if (this.checkIfCookieSet(cookieConsentCookieName)) {
      return true;
    }

    // Consent was set before for this user
    if (this._cookieTypes) {
      for (let type of this._cookieTypes) {
        for (let cookie of type.Cookies) {
          if (cookie.ConsentSet) {
            // Set the Cookie
            this.saveCookieConsent(this._cookieTypes);
            return true;
          }
        }
      }
    }

    return false;
  }

  public canSetCookie(...identifier: string[]): Promise<boolean> {
    let cookieSettablePromise = new Promise<boolean>((resolve, reject) => {
      if (this._cookieTypes) {
        resolve(this._canSetCookie(...identifier));
      } else {
        this.getCookieTypes().then(() => {
          resolve(this._canSetCookie(...identifier));
        })
      }
    });

    return cookieSettablePromise;
  }

  public acceptCookie(cookieNameOrIdentifier: string): Promise<boolean | string> {
    for (let type of this._cookieTypes || []) {
      for (let cookie of type.Cookies) {
        if (cookie.Name == cookieNameOrIdentifier || cookie.Identifier == cookieNameOrIdentifier) {
          cookie.HasConsent = true;
          this.setTypeConsent(type);
        }
      }
    }

    return this.saveCookieConsent(this._cookieTypes ?? []);
  }

  public setTypeConsent(type: CookieType) {
    let hasTrue = false, hasFalse = false;
    for (let cookie of type.Cookies) {
      if (cookie.HasConsent) {
        hasTrue = true;
      } else {
        hasFalse = true;
      }
      if (hasTrue && hasFalse)
        break;
    }

    type.HasConsent = !hasFalse ? true : !hasTrue ? false : null;
  }

  public saveCookieConsent(cookieTypes: CookieType[]): Promise<boolean | string> {
    // User is most likely not logged in. Because of that, cookie consent is stored in a cookie.
    let cookieConsentCookieContent = this._getCookieConsentCookieContent(cookieTypes);

    for (let newC of cookieConsentCookieContent.consent) {
      if (newC.type == 'cookie'
        && !newC.hasConsent) {
        this.deleteCookie(newC.identifier);
      }
    }

    let encoded = encodeURIComponent(JSON.stringify(cookieConsentCookieContent));
    this.setCookieWithoutCheck(`${cookieConsentCookieName}=${encoded}`, { secure: true, expires: 365 });

    let result = new Promise<boolean | string>((resolve, reject) => {

      // API is still called, so the request can be stored and later updated
      let saveSubscription = this._apiService.postRequest('/api/CookieConsent/Save', cookieConsentCookieContent).subscribe((data: any) => {
        saveSubscription.unsubscribe();

        // refresh cookie data
        this.getCookieTypes(true).then(() => {
          resolve(data.Result === 'OK' || data.Message);
          this._cookiesUpdatedSubject.next(data.Result === 'OK');
        })

      });
    });

    return result;
  }

  deleteCookie(identifier: string) {
    this.getCookieTypes().then((c: CookieType[]) => {
      for (let type of c) {
        for (let cookie of type.Cookies) {
          if (identifier === cookie.Identifier) {
            let actualCookieName = this._getSetCookieName(cookie.Name)
            while (actualCookieName) {
              this.setCookieWithoutCheck(actualCookieName + "=", { expires: -1, path: '/' });
              actualCookieName = this._getSetCookieName(cookie.Name)
            }
          }
        }
      }
    })
  }

  private _getCookieConsentCookieContent(cookieTypes: CookieType[]): any {
    let cookieConsentContent: any[] = [];
    cookieTypes.forEach((type: CookieType) => {
      cookieConsentContent.push({
        type: 'type',
        identifier: type.Identifier,
        hasConsent: type.HasConsent
      });

      type.Cookies.forEach((cookie: CookieInfo) => {
        cookieConsentContent.push({
          type: 'cookie',
          identifier: cookie.Identifier,
          hasConsent: cookie.HasConsent
        });
      })
    });

    return {
      timestamp: new Date().toISOString(),
      token: uuidv4(),
      userAgent: window.navigator.userAgent,
      uri: window.location.href,
      consent: cookieConsentContent
    };
  }

  private _canSetCookie(...identifiers: string[]): boolean {
    var hasFullConsent: boolean = true;
    var identifierConsentExists: number = 0;

    if (this._mpSettings.settings && !this._mpSettings.settings['CookieConsentSettings'].ConsentNecessary)
      return true;

    if (!this._cookieTypes)
      return false;

    for (let type of this._cookieTypes) {
      for (let cookie of type.Cookies) {
        for (let identifier of identifiers) {
          if (cookie.Identifier == identifier || cookie.Name == identifier) {
            hasFullConsent = hasFullConsent && (cookie.HasConsent ?? false);
            identifierConsentExists++;
            if (!hasFullConsent)
              return false;
          }
        }
      }
    }

    return (identifierConsentExists == identifiers.length) && hasFullConsent;
  }
}

