import { Component, OnInit, OnDestroy, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpDebounceService } from './../../../../services/mp-debounce.service';

/**
 * This class provides the functions and the
 * data for the create grouping page.
 */
@Component({
  selector: 'mp-core-create-grouping',
  templateUrl: './create-grouping.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CreateGroupingComponent implements OnInit, OnDestroy {

  @ViewChildren('fileUpload') fileUpload: any;

  public error: string = '';
  public grouping: { [key: string]: any } = {
    Name: '',
    Filename: '',
    Ziel: '',
    ArtDerKommunikation: ''
  };
  public uploadSuccessfully: boolean = false;
  public file: File | undefined;
  public nameValid: boolean = false;
  public nameChanged: any = null;

  private _fileUploadSubscription: Subscription | undefined;
  private _validateNameSubscription: Subscription | undefined;
  private _saveGroupingSusbcription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpDebounce: MpDebounceService
  ) { }

  /**
   * Sets the menu highlighting. Creates the
   * nameChanged function for name validation.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this.nameChanged = this._mpDebounce.debounce(() => {
      if (typeof this.grouping['Name'] !== 'undefined') {

        this._validateNameSubscription = this._apiService.getRequest(`api/Gruppierung/isNameValid?name=${this.grouping['Name']}`).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this.nameValid = data.Records[0];
            this.error = '';
          } else {
            this.nameValid = false;
          }
        });
      }
    }, 700);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._fileUploadSubscription !== 'undefined') {
      this._fileUploadSubscription.unsubscribe();
    }

    if (typeof this._validateNameSubscription !== 'undefined') {
      this._validateNameSubscription.unsubscribe();
    }

    if (typeof this._saveGroupingSusbcription !== 'undefined') {
      this._saveGroupingSusbcription.unsubscribe();
    }
  }

  /**
   * Handles the file upload.
   */
  public uploadFiles(evt: any): void {
    if (typeof this.grouping['Name'] === 'undefined' || !this.nameValid) {
      this.error = this.ls.locs['loc'].ErrorGruppierungName;
      return;
    }

    this.uploadSuccessfully = false;
    const files = evt.target.files;
    this.file = files[0];

    if (this.file) {
      this._fileUploadSubscription = this._apiService.postFileUploadRequest('/api/Upload/uploadGruppierung', this.file, this.grouping['Name']).subscribe((data: any) => {
        if (data) {
          if (data.Result === 'OK') {
            this.uploadSuccessfully = true;
            this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DateiWurdeErfolgreichHochgeladen);
            this.grouping['Filename'] = data.Records[0];
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
            this.uploadSuccessfully = false;
          }
        } else {
          this.uploadSuccessfully = false;
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
        this.uploadSuccessfully = false;
      });
    }
  }

  /**
   * Validates the form data.
   */
  private _validate(): boolean {
    this.error = '';

    if (!this.nameValid) {
      this.error = this.ls.locs['loc'].ErrorGruppierungName;
      return false;
    }
    if (this.grouping['Ziel'].length > 200) {
      this.error = this.ls.locs['locLeas'].ErrorGruppierungZiel;
      return false;
    }

    if (this.grouping['ArtDerKommunikation'].length > 100) {
      this.error = this.ls.locs['locLeas'].ErrorGruppierungArt;
      return false;
    }

    return true;
  }

  /**
   * Saves the grouping-
   */
  saveGrouping(): void {
    if (!this.nameValid || !this._validate()) {
      return;
    }

    this._saveGroupingSusbcription = this._apiService.postRequest('api/Gruppierung/saveGrouping', this.grouping).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data, 10);
    },
    (error: any) => {
      this._mpMessaging.openWarningPanel(error.Message);
    });
  }

}
