import { Injectable } from '@angular/core';

import * as notie from 'notie';

@Injectable({
  providedIn: 'root'
})
export class MpMessagingService {

  public overlayScope: any =  {
    overlayMessage: '',
    title: '',
    visible: false,
    buttons: undefined
  }

  public overlayData: any = this.overlayScope;

  constructor() { }

  /**
   *  Opens panel with alert
   */
  openPanel(type: any, text: string, stay?: any, time?: any, position?: any): void {
    if (text) {
      notie.alert({ type: type, text: text, stay: stay, time: time ? time : 3, position: position });
    }
  }

  /**
 *  Opens panel with force
 */
  openForcePanel(type: any, text: string, buttonText?: string, position?: string, callback?: Function): void {
    if (text) {
      notie.force(({
        type: type, text: text, buttontext: buttonText, position: position }) as any);
    }
  }

  /**
   * Opens panel from response with result
   */
  openPanelFromResultResponse(data: any, timeInSeconds?: number) {
    if (data.Message) {
      if (data.Result === "OK") {
        this.openSuccessPanel(data.Message,  timeInSeconds);
      } else {
        this.openDangerPanel(data.Message, timeInSeconds);
      }
    }
  }

  /**
   *  Opens panel with success alert
   */
  openSuccessPanel(message: any, timeInSeconds?: number): void {
    this.openPanel(1, message, false, timeInSeconds || !message || typeof (message) === 'undefined' ? 6 : (message.length / 15) + 2, 'top');
  }

  /**
  *  Opens panel with error alert
  */
  openDangerPanel(message: any, timeInSeconds?: number): void {
    this.openPanel(3, message, false, timeInSeconds ? timeInSeconds : 4, 'top');
  }

  /**
 *  Opens panel with error alert
 */
  openDangerForcePanel(message: any, timeInSeconds?: number): void {
    this.openForcePanel(3, message, 'top');
  }

  /**
  *  Opens panel with error alert
  */
  openErrorPanel(response: any, text?: string, timeInSeconds?: number): void {

    let value_1 = text === undefined ? '' : text;
    let value_2 = response['Message'] === undefined ? '' : '<br/>' + response['Message'];
    let value_3 = response['ExceptionMessage'] === undefined ? '' : '<br/>' + response['ExceptionMessage'];

    this.openPanel(3, value_1 + value_2 + value_3, false, timeInSeconds ? timeInSeconds : 4, 'top');
  }

  /**
  *  Opens panel with info alert
  */
  openInfoPanel(message: any, timeInSeconds: number): void {
    this.openPanel(4, message, false, timeInSeconds || 6, 'top');
  }

  /**
  *  Opens panel with warning alert
  */
  openWarningPanel(message: any, timeInSeconds?: number): void {
    this.openPanel(2, message, false, timeInSeconds || 6, 'top');
  }

  /**
  *  Opens panel alert with confirm
  */
  openConfirm(message: any, yesButton: any, noButton: any): void {
    notie.confirm(message, yesButton, noButton);
  }

  /**
  *  Opens overlay
  */
  openOverlay(title: any, message: any, buttons: any) {
    if (this.overlayScope) {
      this.overlayScope.overlayMessage = message;
      this.overlayScope.title = title;
      this.overlayScope.visible = true;
      if (buttons) {
        this.overlayScope.buttons = buttons;
      } else {
        this.overlayScope.buttons = [
          {
            text: "OK",
            click: this.closeOverlay
          }
        ];
      }
    }
  }

  /**
  *  Closes overlay
  */
  closeOverlay(): void {
    this.overlayScope.visible = false;
    this.overlayScope.overlayMessage = "";
    this.overlayScope.buttons = [];
  }
}
