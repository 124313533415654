<ul class="magma-accordion">
  <div class="data-model" *ngIf="!dataModelExists">
    <li *ngFor="let accData of accDataExpression(); let i = index"
        id="acc-data-id-{{accData.accID}}"
        class="padding-left-right-16 padding-bottom-16"
        [ngClass]="{'accOpened': openAcc[accData.accID]}">
      <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2"
           (click)="toggleAccordion(accData.accID)"
           *ngIf="sidebarAcc"
           mpCoreScrollButtonRerenderer
           data-scroll-area="dataScrollArea"
           data-scroll-item-height="dataScrollItemHeight">
        <p class="margin-right-16">
          <a href (click)="$event.preventDefault()"
             [innerHTML]="accData.accHeadline">
          </a>
        </p>
        <i class="svg-icon __size-28 __top-0"
           [ngClass]="!openAcc[accData.accID] ? '__clr-inactive __add' : '__clr-white __remove'">
        </i>
      </div>
      <div class="pointer-hover display-flex flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2"
           (click)="toggleAccordion(accData.accID)"
           [ngClass]="accData.accSubline ? 'flex-align-items-start' : 'flex-align-items-center'"
           *ngIf="!sidebarAcc">
        <p class="margin-right-16">
          <a href (click)="$event.preventDefault()"
             [innerHTML]="accData.accHeadline">
          </a>
          <span *ngIf="accData.accSubline"
                class="display-block acc-subline font-size-s"
                [innerHTML]="accData.accSubline">
          </span>
        </p>
        <span *ngIf="accData.accButtonOne || accData.accButtonTwo"
              [ngClass]="accData.accSubline ? 'margin-top-4' : ''"
              class="acc-header-links">
          <a href
             *ngIf="!accData.accButtonOne.funcParameter && !accData.accButtonOne.useSwotch"
             (click)="$event.preventDefault(); executeHeadlineFunc(accData.accButtonOne.func)">
            {{accData.accButtonOne.label}}
          </a>
          <a href
             *ngIf="accData.accButtonOne.funcParameter && !accData.accButtonOne.useSwotch"
             (click)="$event.preventDefault(); executeHeadlineFunc(accData.accButtonOne.func, accData.accButtonOne.funcParameter)">
            {{accData.accButtonOne.label}}
          </a>
          <mp-core-toggle-button *ngIf="!accData.accButtonOne.funcParameter && accData.accButtonOne.useSwotch"
                                 [active]="accData.accButtonOne.switchActive"
                                 label="{{ accData.accButtonOne.label }}"
                                 (activeChange)="executeHeadlineFunc(accData.accButtonOne.func)">
          </mp-core-toggle-button>
          <mp-core-toggle-button *ngIf="accData.accButtonOne.funcParameter && accData.accButtonOne.useSwotch"
                                 [active]="accData.accButtonOne.switchActive"
                                 label="{{ accData.accButtonOne.label }}"
                                 (activeChange)="executeHeadlineFunc(accData.accButtonOne.func, accData.accButtonOne.funcParameter)">
          </mp-core-toggle-button>
          <span *ngIf="accData.accButtonTwo !== undefined && accData.accButtonTwo !== null">|</span>
          <a href
             *ngIf="accData.accButtonTwo !== undefined && accData.accButtonTwo !== null && !accData.accButtonTwo.funcParameter && !accData.accButtonTwo.useSwotch"
             (click)="$event.preventDefault(); executeHeadlineFunc(accData.accButtonTwo.func)">
            {{accData.accButtonTwo.label}}
          </a>
          <a href
             *ngIf="accData.accButtonTwo !== undefined && accData.accButtonTwo !== null && accData.accButtonTwo.funcParameter && !accData.accButtonTwo.useSwotch"
             (click)="$event.preventDefault(); executeHeadlineFunc(accData.accButtonTwo.func, accData.accButtonTwo.funcParameter)">
            {{accData.accButtonTwo.label}}
          </a>
          <mp-core-toggle-button *ngIf="accData.accButtonTwo !== undefined && accData.accButtonTwo !== null && !accData.accButtonTwo.funcParameter && accData.accButtonTwo.useSwotch"
                                 [active]="accData.accButtonTwo.switchActive"
                                 label="{{ accData.accButtonTwo.label }}"
                                 (activeChange)="executeHeadlineFunc(accData.accButtonTwo.func)">
          </mp-core-toggle-button>
          <mp-core-toggle-button *ngIf="accData.accButtonTwo !== undefined && accData.accButtonTwo !== null && accData.accButtonTwo.funcParameter && accData.accButtonTwo.useSwotch"
                                 [active]="accData.accButtonTwo.switchActive"
                                 label="{{ accData.accButtonTwo.label }}"
                                 (activeChange)="executeHeadlineFunc(accData.accButtonTwo.func, accData.accButtonTwo.funcParameter)">
          </mp-core-toggle-button>
        </span>
        <i class="svg-icon __size-28 __top-0"
           [ngClass]="!openAcc[accData.accID] ? '__clr-inactive __add' : '__clr-white __remove'">
        </i>
      </div>
      <p [hidden]="!openAcc[accData.accID]"
         *ngIf="accData.accText"
         [innerHTML]="trustAsHtml(accData.accText)"
         class="overflow-hidden margin-top-24 padding-bottom-4 margin-bottom-12">
      </p>
      <p [hidden]="!openAcc[accData.accID]"
         *ngIf="hasTranscluded && !hidePaddingBottom"
         class="padding-bottom-4 margin-bottom-12">
        <ng-content select="[hasNoDataModel]"></ng-content>
      </p>
      <p [hidden]="!openAcc[accData.accID]"
         *ngIf="accData.accTemplates"
         class="padding-bottom-4 margin-bottom-12">
        <ng-container *ngFor="let accTemplate of accData.accTemplates">
          <ng-container *ngTemplateOutlet="accTemplate"></ng-container>
        </ng-container>
      </p>
    </li>
  </div>
  <div *ngIf="dataModelExists">
  <li *ngFor="let accData of accDataModel; let index = index;"
      id="acc-data-id-index"
      class="padding-left-right-16 padding-bottom-16"
      [ngClass]="{'accOpened': openAcc[index]}">
    <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2"
         (click)="toggleAccordion(index)"
         *ngIf="sidebarAcc"
         mpCoreScrollButtonRerenderer
         data-scroll-area="dataScrollArea"
         data-scroll-item-height="dataScrollItemHeight">
      <p class="margin-right-16">
        <a href (click)="$event.preventDefault()"
           [innerHTML]="accData.Title">
        </a>
      </p>
      <i class="svg-icon __size-28 __top-0"
         [ngClass]="!openAcc[index] ? '__clr-inactive __add' : '__clr-white __remove'">
      </i>
    </div>
    <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2"
         (click)="toggleAccordion(index)"
         *ngIf="!sidebarAcc">
      <p class="margin-right-16">
        <a href (click)="$event.preventDefault()"
           [innerHTML]="accData.Title">
        </a>
      </p>
      <i class="svg-icon __size-28 __top-0"
         [ngClass]="!openAcc[index] ? '__clr-inactive __add' : '__clr-white __remove'">
      </i>
    </div>
    <div *ngIf="!accData.Text.startsWith('<iframe');then not_iframe else iframe">here is ignored</div>
    <ng-template #not_iframe>
      <p [hidden]="!openAcc[index]"
         [innerHTML]="trustAsHtml(accData.Text)"
         class="overflow-hidden margin-top-8 padding-bottom-4 margin-bottom-12">
      </p>
    </ng-template>
    <ng-template #iframe>
      <p [hidden]="!openAcc[index]"
         class="overflow-hidden margin-top-8 padding-bottom-4 margin-bottom-12">
        <iframe [src]="safeSrc" width="100%" height="950"></iframe>
      </p>
    </ng-template>
    <p [hidden]="!openAcc[index]"
       *ngIf="hasTranscluded"
       class="padding-bottom-4 margin-bottom-12">
      <ng-content select="[hasDataModel]"></ng-content>
    </p>
  </li>
  </div>
</ul>
