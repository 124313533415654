import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ApiService } from './../services/api.service';
import { RoleMappingService } from './../services/role-mapping.service';
import { AuthService } from './../services/auth.service';
import { CookieService } from '../services/cookie.service';
import { MpSettingsService } from '../services/mp-settings.service';

/**
 * This class provides the auth checks and
 * the redirects for not authenticated users.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private _authService: AuthService,
    private _cookieService: CookieService,
    private _router: Router,
    private _apiService: ApiService,
    private _roleMappingService: RoleMappingService,
    private _mpSettings: MpSettingsService
  ) { }

  /**
   * Checks whether or not the user can
   * access the route and redirects to
   * the requested route or the login.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      let settingsSubscription = new Subscription();
      let settingsLoaded = false;

      this._mpSettings.settingsLoaded.subscribe(loaded => {
        settingsLoaded = loaded;
        if (!loaded)
          return;

        settingsSubscription.unsubscribe();

        if (this._mpSettings.settings && this._mpSettings.settings['CookieConsentSettings'].ConsentDisabled) {
          this._checkAuthAndRedirect(state, resolve);
          return;
        }

        this._cookieService.getCookieTypes().then(_ => {
          if (!this._cookieService.isCookieSet()) {
            this._blockRequest(resolve);
            return;
          }

          this._checkAuthAndRedirect(state, resolve);
        });
      });

      // If the subscription above is resolved synchronously the variable has not yet been initialized when the above "unsubscribe" is called, so it has to be called again.
      if (settingsLoaded && !settingsSubscription.closed)
        settingsSubscription.unsubscribe();
    });
  }

  private _checkAuthAndRedirect(state: RouterStateSnapshot, resolve: (value: boolean | UrlTree | PromiseLike<boolean | UrlTree>) => void) {

    let roleFromUrl = '';
    let currentLocation = '/#' + state.url;
    const roleFromRoute = state.url.split('/')[1];
    const redirectUri = state.url.replace(`/${roleFromRoute}`, '');
    let role: string | Array<string> = [];

    currentLocation = this._trimTrailingSlash(currentLocation);

    if (!this._authService.getUserLoggedIn() || this._authService.getRole() === '') {
      this._apiService.getRequest('/api/Auth/GetRedirect', false, { params: { redirectUri: redirectUri } }).subscribe((data: any) => {
        ({ roleFromUrl, role } = this._redirectSuccessCallback(data, roleFromUrl, role, currentLocation, roleFromRoute, resolve));
      },
        (error: any) => {
          console.log(error.message);
          this._blockRequest(resolve);
        });
    } else {
      if (currentLocation.indexOf('#') === 1) {
        roleFromUrl = currentLocation.split('/')[2];
        role = this._roleMappingService.getMappedRole(roleFromUrl);
      }

      if (typeof this._authService.getRole() === 'object') {
        if (typeof role === 'object') {
          let roleMatch = false;

          role.forEach((roleName: string) => {
            if (this._authService.getRole().indexOf(roleName) !== -1) {
              roleMatch = true;
            }
          });

          if (!roleMatch) {
            this._blockRequest(resolve);
          }
        } else {
          if (this._authService.getRole().indexOf(role) === -1) {
            this._blockRequest(resolve);
          }
        }
      } else {
        if (typeof role === 'object') {
          let roleMatch = false;

          role.forEach((roleName: string) => {
            if (this._authService.getRole() === roleName) {
              roleMatch = true;
            }
          });

          if (!roleMatch) {
            this._blockRequest(resolve);
          }
        } else {
          if (this._authService.getRole() !== role) {
            this._blockRequest(resolve);
          }
        }
      }

      resolve(true);
    }
    return { roleFromUrl, role };
  }

  private _blockRequest(resolve: (value: boolean | UrlTree | PromiseLike<boolean | UrlTree>) => void) {
    this._authService.setRole('');
    this._router.navigate(['/']);
    resolve(false);
  }

  private _redirectSuccessCallback(data: any, roleFromUrl: string, role: string | string[], currentLocation: string, roleFromRoute: string, resolve: (value: boolean | UrlTree | PromiseLike<boolean | UrlTree>) => void) {
    const newLocation = data.Records[0];

    if (newLocation.indexOf('#') === 1) {
      roleFromUrl = newLocation.split('/')[2];
      role = this._roleMappingService.getMappedRole(roleFromUrl);
    }

    if (currentLocation !== newLocation || role !== this._roleMappingService.getMappedRole(roleFromRoute)) {
      this._authService.setRole('');
      this._router.navigate([newLocation.replace('/#/', '')]);
      resolve(false);
    } else if (currentLocation === newLocation && role === this._roleMappingService.getMappedRole(roleFromRoute)) {
      if (this._authService.getRole() !== role) {
        this._authService.setRole(role);
      }

      this._authService.setUserLoggedIn();
      resolve(true);
    }
    return { roleFromUrl, role };
  }

  private _trimTrailingSlash(currentLocation: string) {
    if (currentLocation !== '/') {
      if (currentLocation.lastIndexOf('/') === currentLocation.length - 1) {
        currentLocation = currentLocation.substr(0, currentLocation.length - 1);
      }
    }
    return currentLocation;
  }

  /**
   * Checks whether or not the user can
   * access the child route and redirects to
   * the requested route or the login.
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

}
