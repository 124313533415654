<div id="gutschein-einloesen" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="stage">
    <owl-carousel-o id="charity-carousel" class="owl-theme" [options]="redeemVoucherSliderProperties">
      <ng-container *ngFor="let item of [{ID:1}]">
        <ng-template carouselSlide>
          <div class="item">
            <img src="/themes/default/media/buehnen/seiten/gutschein-einloesen.jpg?width={{ mpSettings.settings['DisplaySettings'].BuehnenBildBreite }}&height={{ mpSettings.settings['DisplaySettings'].BuehnenBildHoehe }}&mode=crop" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="layout-container">
    <h1>{{ ls.locs['locLeas'].GutscheineEinloesen }}</h1>
    <div>
      <p>{{ ls.locs['locLeas'].GutscheinCodeText }}</p>
    </div>
    <div>
      <mp-core-masked-input label="{{ ls.locs['locLeas'].GutscheinCode }}" mpId="gutschein-code" [(model)]="code" mask="****-****-****" [hasError]="errorCode !== '' || errorInvalid ? true : false" class="fallback-input"></mp-core-masked-input>
      <mp-core-form-error *ngIf="errorInvalid" [model]="ls.locs['locLeas'].DerGutscheinCodeIstNichtKorrekt"></mp-core-form-error>
      <mp-core-form-error *ngIf="errorCode" [model]="errorCode"></mp-core-form-error>
    </div>
    <div class="margin-top-24">
      <button type="button" class="mp-button _prim" (click)="redeem()" [attr.disabled]="loading ? '' : null">{{ ls.locs['locLeas'].Senden }}</button>
    </div>
  </div>
</div>
