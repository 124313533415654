<div id="sidebar-date" class="sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="texst-align-left">
      {{mpSidebar.params['Headline']}}
    </div>
    <div class="divider margin-bottom-8 margin-left-16"></div>
    <mp-core-select label=""
                    mpId="sidebar-datum"
                    [(model)]="selection['year']"
                    (ngChange)="selection['year'] = $event; yearChange();"
                    [elements]="yearRange"
                    class="padding-left-right-16 margin-left-0 box-sizing-border-box margin-top-16">
      </mp-core-select>
      <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 width-100-pcnt bg-color-white z-index-3">
      <div id="overlay-buttons">
        <a href=""
           (click)="resetDate($event)"
           class="mp-button _sec display-block">
          {{ ls.locs['loc'].KeineAngabe }}
        </a>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   desktopItemHeight=".calendar > div"
                   scrollArea="#datum-scroll-area"
                   scrollDirection="up"
                   scrollFactor="1"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="datum-scroll-area-container">
      <div id="datum-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="minDate"
                    [(maxDate)]="maxDate"
                    [model]="selection['date']"
                    (modelChange)="selection['date'] = $event; dateChange();">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   desktopItemHeight=".calendar > div"
                   scrollArea="#datum-scroll-area"
                   scrollDirection="down"
                   scrollFactor="1"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].EingabenSpeichern }}
      </a>
    </div>
  </div>
</div>
