<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'gewinnkalenderAuswertungDetails'"></mp-core-infotext>
  <h1 class="mobile-only">{{ ls.locs['locGewinnkalender'].GewinnkalenderAuswertung }}: {{key}}</h1>
  <mp-core-data-table title="{{ ls.locs['locGewinnkalender'].GewinnkalenderAuswertung }}: {{key}}" [recLoadedFunc]="recLoadedFunc" infotextShorten="gewinnkalenderAuswertungDetails" fieldsAction="/api/Gewinnkalender/getGewinnkalenderAuswertungDetailsFields" listAction="/api/Gewinnkalender/getGewinnkalenderAuswertungDetails" loaderName="prizeCalendarEvaluationDetailsLoader" defaultSorting="Tag"></mp-core-data-table>
  <div class="floating-container" *ngIf="loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
