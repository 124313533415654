<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Rechnungskontrolle }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Rechnungskontrolle }}" 
                 infotextShorten="Rechnungskontrolle" 
                 fieldsAction="/api/Statistik/getRechnungskontrolleFields" 
                 listAction="/api/Statistik/getRechnungskontrolle" 
                 loaderName="invoiceControlLoader"
                 [recLoadedFunc]="recLoadedFunc"
                 [paging]="true" 
                 [footer]="false" 
                 defaultSorting="RgDatum DESC" 
                 [showDatePicker]="true">
  </mp-core-data-table>

    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a href="{{excelService}}?{{loader.getExcelParams()}}">
        <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
      </a>
    </div>
</div>
