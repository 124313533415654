<div #communicationStepper *ngIf="(ls.locsLoaded | async) && cp.currentStep" class="border-top border-bottom flex-grid flex-direction-row flex-justify-cont-center flex-align-items-center padding-bottom-16 padding-top-16 margin-top-bottom-16">
  <div *ngFor="let step of steps; let last = last"
       [ngClass]="{twoStepsDone: step['Index'] === 0 && cp.currentStep['Index'] > 1, done: step['Index'] < cp.currentStep['Index'], current: step['Index'] === cp.currentStep['Index'], following: step['Index'] > cp.currentStep['Index']}"
       class="display-flex flex-direction-row margin-right-40 single-step repeat-communication-steps">
    <a (click)="cp.goToStep(step, $event)"
       class="flex-col-12 flex-direction-row flex-justify-cont-center flex-align-items-center">
      <mp-core-tooltip class="order-process-step-cnt margin-right-16 display-flex flex-justify-cont-center flex-align-items-center" tooltipTitle="{{step['Title']}}">
        <i class="svg-icon __clr-process-stepper __size-20 __top-0 text-align-center"
           [ngClass]="step['Index'] < cp.currentStep['Index'] ? step['Skipped'] === true ? '__clear' : '__done' : ''">
          {{step['Index'] < cp.currentStep['Index'] ? '' : (step['Index'] + 1)}}{{last ? finishedRendering() : ''}}
        </i>
      </mp-core-tooltip>
      <span [innerText]="step['Title']" class="step-text text-overflow-ellipsis"></span>
    </a>
  </div>
</div>

<mp-core-modal class="actions-modal" [show]="cp && cp.skipPopUpData && cp.skipPopUpData['Show']"
          [buttons]="[{text: cp.skipPopUpData['Button1Text'], action: cpSkipStep, actionParam: cp.skipPopUpData['Key'], class: '_sec'}, {text: cp.skipPopUpData['Button2Text'], action: cpConfirmStep, actionParam: cp.skipPopUpData['Key'], class: '_prim'}]">
  <span [innerText]="cp.skipPopUpData['Text']"></span>
  <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important"
     (click)="cp.closeSkipPopUp()"></i>
</mp-core-modal>

<mp-core-modal class="actions-modal" [show]="cp && cp.resetPopUpData && cp.resetPopUpData['Show']"
          [buttons]="[{text: ls.locs['loc'].Nein, action: cpHideResetConfirm, class: '_sec'}, {text: ls.locs['loc'].Ja, action: cpReset, class: '_prim'}]">
  <span [innerText]="cp.resetPopUpData['Text']"></span>
  <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important"
     (click)="cp.hideResetConfirm()"></i>
</mp-core-modal>

<mp-core-modal class="actions-modal" [show]="cp && cp.clearPopUpData && cp.clearPopUpData['Show']"
          [buttons]="[{text: ls.locs['loc'].Nein, action: cpHideClearConfirm, class: '_sec'}, {text: ls.locs['loc'].Ja, action: cpClear, class: '_prim'}]">
  <span [innerText]="cp.clearPopUpData['Text']"></span>
  <i class="svg-icon close-icon __clr-inactive __size-24 __clear float-none-important"
     (click)="cp.hideClearConfirm()"></i>
</mp-core-modal>
