import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { ApiService } from './../../../../../services/api.service';
import { ParticipantCockpitService } from './../../participant-cockpit/participant-cockpit.service';
import { MpMessagingService } from './../../../../../services/mp-messaging.service';
import { MpSettingsService } from '../../../../../services/mp-settings.service';

/**
 * This class provides the data and functions
 * for the manually order.
 */
@Component({
  selector: 'mp-core-ptcpnt-cockpit-manually-order',
  templateUrl: './ptcpnt-cockpit-manually-order.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PtcpntCockpitManuallyOrderComponent implements OnInit, OnDestroy {

  public order: Array<any> = [];
  public participantAddresses: Array<any> = [];
  public participantAddress: { [key: string]: any } = {};
  public salutationObject: { [key: string]: any } = {};
  public phone: string = '';
  public email: string = '';
  public ddlData: { [key: string]: any } = {};
  public errorSalutation = '';
  public errorName: string = '';
  public errorPrename: string = '';
  public errorStreet: string = '';
  public errorLKZ: string = '';
  public errorZIP: string = '';
  public errorCity: string = '';
  public errorArtNo: string = '';
  public loading: boolean = false;
  public position: { [key: string]: any } = { Anzahl: 1, ID: 0, Preise: { Punkte: '' } };
  public salutationDisabled: boolean = true;
  public selectableSalutation: Array<any> = [];
  public artMessage: string = '';
  public getArticleInfoFunc = this.getArticleInfo.bind(this);
  public redemptionLimitOrderSum: number = 0;
  public redemptionLimitActive: boolean = false;
  public redemptionLimit: number = 0;
  public redemptionLimitWarning: number = 0;

  private _positionID: number = 0;
  private _position: { [key: string]: any } = { ArtNr: '' };
  private _cockpitData: { [key: string]: any } = {};
  private _getParticipantAddressesSubscription: Subscription | undefined;
  private _getParticipantCockpitDataSubscription: Subscription | undefined;
  private _getParticipantContactDataSubscription: Subscription | undefined;
  private _getArticleInformationSubscription: Subscription | undefined;
  private _sendManuallyOrderSubscription: Subscription | undefined;
  private _getParticipantRedemptionLimitDataSubscription: Subscription | undefined;
  private _mpSettingsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _participantCockpitService: ParticipantCockpitService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the participant cockpit data,
   * the addresses and the contact
   * data.
   */
  ngOnInit(): void {
    this._getParticipantCockpitDataSubscription = this._participantCockpitService.getData().subscribe((data: { [key: string]: any }) => {
      let tnId = parseInt(this._cockpitData['tnId'])
      if (tnId < 1)
        return;
      
      if (isNaN(tnId)) {
        tnId = parseInt(data['tnId'])
        if (isNaN(tnId) || tnId < 1)
          return;
      }

      if (typeof this._cockpitData['tnId'] === 'undefined' || this._cockpitData['tnId'] !== data['tnId'] || typeof this._cockpitData['reloadCounter'] === 'undefined' || this._cockpitData['reloadCounter'] !== data['reloadCounter']) {
        this._cockpitData = data;

        this._getParticipantAddressesSubscription = this._apiService.getRequest(`/api/Teilnehmer/getTNAdressen/${this._cockpitData['tnId']}`).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this.participantAddresses = data.Records;
            this.ddlData = data.footer[0];
            this._updateSalutations();
          }
        });

        this._getParticipantContactDataSubscription = this._apiService.getRequest(`/api/Com/getKontaktData/${this._cockpitData['tnId']}`).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this.email = data.Records[0].EMail;
            this.phone = data.Records[0].Telefon;
          }
        });

        this._mpSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
          if (!loaded || !this.mpSettings.settings['BestellprozessSettings'].RedemptionlimitActive)
            return;

          this.redemptionLimitActive = this.mpSettings.settings['BestellprozessSettings'].RedemptionlimitActive;
          this.redemptionLimit = this.mpSettings.settings['BestellprozessSettings'].Redemptionlimit;
          this.redemptionLimitWarning = this.mpSettings.settings['BestellprozessSettings'].RedemptionlimitWarning;

          this._getParticipantRedemptionLimitDataSubscription = this._apiService.getRequest(`/api/RedemptionLimit/GetBestellungSummeEuro/${this._cockpitData['tnId']}`).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.redemptionLimitOrderSum = data.Records[0];
            }
          });
        });
      }
    });

    this._participantCockpitService.triggerObservable();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getParticipantAddressesSubscription !== 'undefined') {
      this._getParticipantAddressesSubscription.unsubscribe();
    }

    if (typeof this._getParticipantCockpitDataSubscription !== 'undefined') {
      this._getParticipantCockpitDataSubscription.unsubscribe();
    }

    if (typeof this._getParticipantContactDataSubscription !== 'undefined') {
      this._getParticipantContactDataSubscription.unsubscribe();
    }

    if (typeof this._getArticleInformationSubscription !== 'undefined') {
      this._getArticleInformationSubscription.unsubscribe();
    }

    if (typeof this._sendManuallyOrderSubscription !== 'undefined') {
      this._sendManuallyOrderSubscription.unsubscribe();
    }

    if (typeof this._getParticipantRedemptionLimitDataSubscription !== 'undefined') {
      this._getParticipantRedemptionLimitDataSubscription.unsubscribe();
    }

    if (typeof this._mpSettingsSubscription !== 'undefined') {
      this._mpSettingsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the article data.
   */
  getArticleInfo(): void {
    if (this._verify()) {
      this.loading = true;

      const article = {
        ArtNr: this.position['ArtNr'],
        TnID: this._cockpitData['tnId'],
        Strasse: this.participantAddress['Strasse'],
        Adresszusatz: this.participantAddress['Adresszusatz'],
        PLZ: this.participantAddress['PLZ'],
        LKZ: this.participantAddress['LKZ'],
        Ort: this.participantAddress['Ort'],
        Firma: this.participantAddress['Firma']
      }

      this._getArticleInformationSubscription = this._apiService.postRequest('/api/Artikel/getCompleteArtikelinfoByArtnr/', article).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.position['Preise'] = data.Records[0].Preise === null ? { Punkte: '' } : data.Records[0].Preise;
          this.position['ArtBez'] = data.Records[0].ArtBez;
          this.position['ArtNr'] = data.Records[0].ArtNr + '-' + data.Records[0].PIN;
          this._position['ArtNr'] = this.position['ArtNr'];
          this.artMessage = data.Records[0].Message;
        }

        this.loading = false;
      });
    }
  }

  /**
   * Sends the order to the backend.
   */
  sendOrder(): void {
    if (this._verify()) {
      this.loading = true;

      const manuallyOrder = {
        Adresse: this.participantAddress,
        Warenkorb: this.order,
        TnID: this._cockpitData['tnId'],
        Kontakt: {
          EMail: this.email,
          Telefon: this.phone
        }
      };

      this._sendManuallyOrderSubscription = this._apiService.postRequest('/api/Bestellung/setManuelleBestellung/', manuallyOrder).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.loading = false;
          this.position = { Anzahl: 1, ID: 0, Preise: { Punkte: '' } };
          this.order = [];
          this._positionID = 0;
          this._mpMessaging.openSuccessPanel(data.Message);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      });
    }
  }

  /**
   * Verifies the form data.
   */
  private _verify(): boolean {
    this.errorSalutation = '';
    this.errorName = '';
    this.errorPrename = '';
    this.errorStreet = '';
    this.errorLKZ = '';
    this.errorZIP = '';
    this.errorCity = '';
    this.errorArtNo = '';

    if (!this.participantAddress['AnredenID']) {
      this.errorSalutation = 'Bitte eine Anrede auswählen.';
      return false;
    }

    if (!this.participantAddress['Name']) {
      this.errorName = 'Bitte einen Namen eingeben.';
      return false;
    }

    if (!this.participantAddress['Vorname']) {
      this.errorPrename = 'Bitte einen Vornamen eingeben.';
      return false;
    }

    if (!this.participantAddress['Strasse']) {
      this.errorStreet = 'Bitte eine Strasse eingeben.';
      return false;
    }

    if (!this.participantAddress['LKZ']) {
      this.errorLKZ = 'Bitte ein Land auswählen.';
      return false;
    }

    if (!this.participantAddress['PLZ'] || this.participantAddress['PLZ'].length !== 5) {
      this.errorZIP = 'Bitte eine PLZ eingeben.';
      return false;
    }

    if (!this.participantAddress['Ort']) {
      this.errorCity = 'Bitte einen Ort eingeben.';
      return false;
    }

    if (!this.position['ArtNr'] || !this.position['ArtNr'].split('-')[1]) {
      this.errorArtNo = 'Bitte einen Artikelnummer mit PIN eingeben.';
      return false;
    }

    return true;
  }

  /**
   * Updates the selectable salutations.
   */
  private _updateSalutations(): void {
    if (Object.keys(this.participantAddress).length === 0 || !this.participantAddress['LKZ']) {
      this.salutationDisabled = true;
      this.selectableSalutation = [];
    } else {
      this.salutationDisabled = false;

      this.selectableSalutation = this.ddlData['Anreden'].filter((salutation: { [key: string]: any }) => {
        return salutation['Land'] === this.participantAddress['LKZ'];
      });

      if (Object.keys(this.salutationObject).length > 0) {
        this.salutationObject = this.selectableSalutation.find((salutation: { [key: string]: any }) => {
          return salutation['Geschlecht'] === this.salutationObject['Geschlecht'] || salutation['ID'] === this.salutationObject['AnredenID'];
        });

        if (Object.keys(this.salutationObject).length > 0) {
          this.salutationUpdate();
        }
      }
    }
  }

  /**
   * Updates the selected salutation.
   */
  salutationUpdate(): void {
    this.participantAddress['AnredenID'] = this.salutationObject['AnredenID'];
  }

  /**
   * Updates the address.
   */
  addressUpdate(): void {
    this.salutationObject['AnredenID'] = this.participantAddress['AnredenID'];
    this.participantAddress['Telefon'] = this.phone;
    this.participantAddress['EMail'] = this.email;
    this.countryUpdate();
  }

  /**
   * Updates the country.
   */
  countryUpdate(): void {
    this.participantAddress['LKZ'] = this.participantAddress['Land'].Kuerzel;
    this._updateSalutations();
  }

  /**
   * Adds an order position (article)
   * to the order.
   */
  addPosition(): void {
    this.order.push(this.position);
    this._positionID++;
    this.position = { Anzahl: 1, ID: this._positionID, Preise: { Punkte: '' } };
    this.position['ArtNr'] = this._position['ArtNr'];
  }

  /**
   * Removes the given position from
   * the order.
   */
  removePosition(id: any): void {
    this.order = this.order.filter((position: { [key: string]: any }) => {
      return position['ID'] !== id;
    });
  }

}
