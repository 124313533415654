<div confirm-email>
  <div id="registration">
    <div class="loginbackground" [hidden]="!message">
      <div id="registration-success">
        <div [innerHTML]="message"></div>
        <a href="/" class="mp-button display-block margin-top-40">{{ ls.locs['loc'].ZurStartseite }}</a>
      </div>
    </div>
  </div>
</div>
