import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpShoppingBasketService } from '@core/modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { MpOrderProcessService } from '@core/components/order-process/mp-order-process.service';
import { ApiService } from '@core/services/api.service';
import { MpWishlistService } from '@core/modules/participant/pages/wishlist/mp-wishlist.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { SbAdditionalPaymentService } from '@wkzz/services/sb-additional-payment.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { ShoppingBasketItem } from '@core/modules/participant/pages/shopping-basket/shopping-basket-item';

/**
 * This class provides the data and the
 * functionalities for the order check
 * page within the order process.
 */
@Component({
  selector: 'mp-core-order-check',
  templateUrl: './order-check.component.html',
  styleUrls: [
    './order-check.component.scss',
    './../../../../components/order-process/styles/order-process-pooled.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class OrderCheckComponent implements OnInit, OnDestroy {

  public currentStep: { [key: string]: any } = {};
  public shoppingBasket: Array<ShoppingBasketItem> = [];
  public groupedShoppingBasket: Array<any> = [];
  public termsConfirmed: boolean = false;
  public revocationConfirmed: boolean = false;
  public showTermsNotification: boolean = false;
  public showRevocationNotification: boolean = false;
  public role: string = '';
  public showRedirectInfo: boolean = false;
  public loading: boolean = false;

  private _currentSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _stepsSubscription: Subscription | undefined;
  private _orderSubscription: Subscription | undefined;
  private _confirmTermSubscription: Subscription | undefined;
  private _locsLoadedSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpOrderProcess: MpOrderProcessService,
    private _apiService: ApiService,
    private _mpWishlist: MpWishlistService,
    private _mpMessaging: MpMessagingService,
    private _sbAdditionalPaymentService: SbAdditionalPaymentService,
    private _mpMenuRefreshService: MpMenuRefreshService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the step, and the shopping basket.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._stepsSubscription = this._mpOrderProcess.steps.subscribe((data: any) => {
      if (!this._mpOrderProcess.isValidStep('pruefen')) {
        this._mpOrderProcess.goToCurrentStep();
      }
    });

    this._currentSubscription = this._mpOrderProcess.currentStepObserve.subscribe((currentStep: any) => {
      this.currentStep = currentStep;
    });

    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((shoppingBasket: Array<ShoppingBasketItem>) => {
      this.groupedShoppingBasket = [];
      this.shoppingBasket = shoppingBasket;
      const groupedBasketKeys = Object.keys(this._mpShoppingBasket.groupedBasket);

      if (groupedBasketKeys.length > 0) {
        groupedBasketKeys.forEach((key: string) => {
          this.groupedShoppingBasket.push(this._mpShoppingBasket.groupedBasket[key]);
        });
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._currentSubscription !== 'undefined') {
      this._currentSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }

    if (typeof this._orderSubscription !== 'undefined') {
      this._orderSubscription.unsubscribe();
    }

    if (typeof this._confirmTermSubscription !== 'undefined') {
      this._confirmTermSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the order of the articles
   * in the users shopping basket, without
   * additional payment.
   */
  private _orderBase(evt: MouseEvent): void {
    if (this.loading) // prevent duplicate order
      return;

    this.showTermsNotification = false;
    this.showRevocationNotification = false;

    if (!this.termsConfirmed || !this.revocationConfirmed) {
      if (!this.termsConfirmed) {
        this.showTermsNotification = true;
      }

      if (!this.revocationConfirmed) {
        this.showRevocationNotification = true;
      }
    } else {
      this.loading = true;
      this._orderSubscription = this._apiService.postRequest('/api/Bestellung/setBestellung', { }).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._mpOrderProcess.next('pruefen', `?bestellnr=${data.Records[0]}`);
          this._confirmTermSubscription = this._apiService.getRequest('/api/Erstanmeldung/setTeilnahmebedingungenBestaetigt?herkunft=' + data.Records[0]).subscribe(() => { });
          localStorage.removeItem('selectedBestellAdresse');
          this._mpWishlist.getWishlist(true);
          this._mpMenuRefreshService.loadMenuData(true).subscribe(() => { });
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }

        this._mpShoppingBasket.refreshShoppingBasket(true);
        this.loading = false;
      },
      (error: any) => {
        this._mpMessaging.openDangerPanel(error.ExceptionMessage);
        this.loading = false;
      });
    }
  }

  /**
   * Triggers the order of the articles
   * in the users shopping basket.
   */
  order(evt: MouseEvent): void {
    if (this.loading) // prevent duplicate order
      return;

    this.showTermsNotification = false;
    this.showRevocationNotification = false;

    if (!this.termsConfirmed || !this.revocationConfirmed) {
      if (!this.termsConfirmed) {
        this.showTermsNotification = true;
      }

      if (!this.revocationConfirmed) {
        this.showRevocationNotification = true;
      }
    } else {
      const points = this._mpShoppingBasket.shoppingBasket.reduce((a: any, b: any) => {
        return a + b['Punkte'] - b['EingeloestePunkte'];
      }, 0) || 0;

      if (points <= 0) {
        this._orderBase(evt);
      } else {
        if (this._mpOrderProcess.paymentType === '') {
          if (Object.keys(this.ls.locs).length > 0) {
            this._mpMessaging.openDangerPanel(this.ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
          } else {
            this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
              if (loaded) {
                this._mpMessaging.openDangerPanel(this.ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
              }
            })

            this.ls.getLocalization();
          }
        } else {
          this.loading = true;
          this._sbAdditionalPaymentService.call(this._mpOrderProcess.paymentType, this._orderSuccess.bind(this), this._orderError.bind(this))
        }
      }
    }
  }

  /**
   * Handles the success callback of the
   * order function.
   */
  private _orderSuccess(data: any): void {
    this._confirmTermSubscription = this._apiService.getRequest('/api/Erstanmeldung/setTeilnahmebedingungenBestaetigt?herkunft=BestellungMitZuzahlung').subscribe(() => {
      if (data.Message === 'redirect') {
        for (let i = 0; i < this._mpShoppingBasket.shoppingBasket.length; ++i) {
          this.showRedirectInfo = this._mpShoppingBasket.shoppingBasket[i].ZuzahlungEuro > 0;
        }

        window.location.href = data.Records[0];
      } else {
        this._mpOrderProcess.next('pruefen', `?bestellnr=${data.Records[0]}`);
        this.loading = false;
      }
    });
  }

  /**
   * Handles the error callback of the
   * order function.
   */
  private _orderError(error: any): void {
    this._mpMessaging.openDangerPanel(error.ModelState && error.ModelState.ERROR);
    this.loading = false;
  }

}
