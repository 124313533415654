<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-infotext [sitename]="'gewinnkalenderStatistik'"></mp-core-infotext>
  <h1 class="mobile-only">{{ ls.locs['loc'].GewinnkalenderStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].GewinnkalenderStatistik }}" [recLoadedFunc]="recLoadedFunc" [execClickFunctions]="execClickFunctions" infotextShorten="gewinnkalenderStatistik" fieldsAction="/api/GewinnkalenderStatistik/GetGewinnkalenderStatistikFields" listAction="/api/GewinnkalenderStatistik/GetGewinnkalenderStatistik" loaderName="prizeCalendarStatisticLoader" [disableCache]="true" [paging]="true"></mp-core-data-table>
  <div class="floating-container" *ngIf="loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
