import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { ApiService } from './../../../services/api.service';
import { InitialLoginService } from './../../../services/initial-login.service';

/**
 * This class provides the functions and
 * data for the step "newsletter" of the
 * first login module.
 */
@Component({
  selector: 'mp-core-first-login-newsletter',
  templateUrl: './newsletter.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NewsletterComponent implements OnInit, OnDestroy {

  public newsletter: { [key: string]: any } = {
    OptIn: false,
    sentBefore: false
  };

  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _saveNewsletterSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _initialLoginService: InitialLoginService
  ) { }

  /**
   * Subscribes to the validate step and
   * next step observabbles of the
   * InitialLoginService.
   */
  ngOnInit(): void {
    this._validateStepSubscription = this._initialLoginService.validateStep(4).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLoginService.validateCalled.next(false);
        this._initialLoginService.validBool = true;
        this._initialLoginService.valid.next(true);
      }
    });

    this._nextStepSubscription = this._initialLoginService.nextCalledStep(4).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this._initialLoginService.validBool) {
          this._saveNewsletter();
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptione.
   */
  ngOnDestroy(): void {
    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._nextStepSubscription !== 'undefined') {
      this._nextStepSubscription.unsubscribe();
    }

    if (typeof this._saveNewsletterSubscription !== 'undefined') {
      this._saveNewsletterSubscription.unsubscribe();
    }
  }

  /**
   * Saves the newsletter option state.
   */
  private _saveNewsletter(): void {
    if (!this.newsletter['sentBefore']) {
      this.newsletter['sentBefore'] = true;

      this._saveNewsletterSubscription = this._apiService.postRequest('/api/Newsletter/setOptIn', this.newsletter)
        .subscribe((data: any) => {
            this._initialLoginService.nextSuccess();
          },
          (error: any) => {
            this.newsletter['sentBefore'] = false;
            this._initialLoginService.nextFailure();
          });
    } else {
      this._initialLoginService.nextSuccess();
    }

  }

}
