<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Bestelluebersicht }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Bestelluebersicht }}"
                 infotextShorten="Bestelluebersicht"
                 fieldsAction="/api/Statistik/getBestelluebersichtParentFields"
                 listAction="/api/Statistik/getBestelluebersicht"
                 loaderName="managerOrderOverviewTableLoader"
                 [paging]="true"
                 [footer]="true"
                 defaultSorting="Datum DESC, BestellNr DESC"
                 [execClickFunctions]="execClickFunctions"
                 [recLoadedFunc]="recLoadedFunc"
                 [showDatePicker]="true"
                 [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways">
  </mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  

  <mp-core-modal *ngIf="modalDataLoaded" [show]="showEditOverlay"
            title="{{ ls.locs['loc'].AdresseAendern }}"
            [buttons]="[{text: ls.locs['loc'].Abbrechen, action: cancel}, {text: ls.locs['loc'].Bestaetigen, action: confirm, class: '_prim'}]">
    <mp-core-select label="{{ ls.locs['loc'].Auswahl }}"
               [elements]="ptcnptAddresses"
               textProperty="Strasse"
               [(model)]="selection['tnAdresse']">
    </mp-core-select>
    <br />
    <br />
    <h3>{{ ls.locs['loc'].Adresse }}</h3>
    <div class="inputRequired"
         [hidden]="!best['InSbo']"
         [innerText]="best['InSboError']">
    </div>
    <mp-core-input [(model)]="selection['tnAdresse'].Firma"
              label="{{ ls.locs['loc'].Firma }}">
    </mp-core-input>
    <mp-core-select label="{{ ls.locs['loc'].Anrede }}"
               [elements]="ddlData['Anreden']"
               textProperty="Anrede"
               [(model)]="selection['tnAdresse'].AnredeEntityObjekt"
               [hasError]="errorSalutation !== '' ? true : false">
    </mp-core-select>
    <mp-core-form-error *ngIf="errorSalutation !== ''"
                   [model]="errorSalutation">
    </mp-core-form-error>
    <mp-core-input [(model)]="selection['tnAdresse'].Titel"
              label="{{ ls.locs['loc'].Titel }}">
    </mp-core-input>
    <mp-core-input [(model)]="selection['tnAdresse'].Name"
              label="{{ ls.locs['loc'].Name }}"
              [hasError]="errorName !== '' ? true : false">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorName"
                   [model]="errorName">
    </mp-core-form-error>
    <mp-core-input [(model)]="selection['tnAdresse'].Vorname"
              label="{{ ls.locs['loc'].Vorname }}"
              [hasError]="errorPrename !== '' ? true : false">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorPrename !== ''"
                   [model]="errorPrename">
    </mp-core-form-error>
    <mp-core-input [(model)]="selection['tnAdresse'].Strasse"
              label="{{ ls.locs['loc'].Strasse }}"
              [hasError]="errorStreet !== '' ? true : false">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorStreet !== ''"
                   [model]="errorStreet">
    </mp-core-form-error>
    <mp-core-input [(model)]="selection['tnAdresse'].Adresszusatz"
              label="{{ ls.locs['loc'].Adresszusatz }}">
    </mp-core-input>
    <mp-core-select label="{{ ls.locs['loc'].LKZ }}"
               [elements]="ddlData['Laenderliste']"
               textProperty="Land"
               [(model)]="selection['tnAdresse'].Land"
               [hasError]="errorCountry !== '' ? true : false">
    </mp-core-select>
    <mp-core-form-error *ngIf="errorCountry !== ''"
                   [model]="errorCountry">
    </mp-core-form-error>
    <mp-core-input [(model)]="selection['tnAdresse'].PLZ"
              label="{{ ls.locs['loc'].PLZ }}"
              [hasError]="errorZIP !== '' ? true : false">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorZIP !== ''"
                   [model]="errorZIP">
    </mp-core-form-error>
    <mp-core-input [(model)]="selection['tnAdresse'].Ort"
              label="{{ ls.locs['loc'].Ort }}"
              [hasError]="errorCity !== '' ? true : false">
    </mp-core-input>
    <mp-core-form-error *ngIf="errorCity !== ''"
                   [model]="errorCity">
    </mp-core-form-error>
  </mp-core-modal>
</div>
