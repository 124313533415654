import { Injectable } from '@angular/core';

/**
 * This class provides the colors
 * for the d3 charts.
 */
@Injectable({
  providedIn: 'root'
})
export class MpD3StylesService {

  constructor() { }

  /**
   * Returns the object with the
   * colors for each chart variant.
   */
  mpD3Styles(): any {
    return {
      d3BarPlotStyles: {
        linecolor: '#69b3a2',
        fillcolorRange: [
          '#ea5153',
          '#ee7375',
          '#f29697',
          '#f6b9ba',
          '#fadcdc',
          '#ffffff'
        ]
      },
      d3CircularBarPlotStyles: {
        linecolor: '#ff0000',
        fillcolorRange: [
          '#ea5153',
          '#ee7375',
          '#f29697',
          '#f6b9ba',
          '#fadcdc',
          '#ffffff'
        ]
      },
      d3PieChartStyles: {
        linecolor: '#ff0000',
        fillcolorRange: [
          '#ea5153',
          '#ee7375',
          '#f29697',
          '#f6b9ba',
          '#fadcdc',
          '#ffffff'
        ]
      },
      d3HistogramStyles: {
        fillcolor: '#69b3a2',
        linecolor: '#69b3a2'
      },
      d3LineChartStyles: {
        linecolor: '#ff0000',
        fillcolorRange: [
          '#ea5153',
          '#ee7375',
          '#f29697',
          '#f6b9ba',
          '#fadcdc',
          '#ffffff'
        ]
      },
      d3LollipopChartStyles: {
        fillcolor: '#69b3a2',
        linecolor: '#69b3a2'
      },
      d3ChartAreaStyles: {
        fillcolor: '#cce5df',
        linecolor: '#69b3a2'
      }
    };
  }
}
