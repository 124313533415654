<div id="theme-stage-slider-management" class="layout-container manager-text-editor" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="flex-grid position-relative">
    <div id="nav-breadcrumb" class="display-flex flex-align-items-center margin-bottom-8 margin-left-4">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info" (click)="goBack($event)">{{ ls.locs['loc'].Zurueck }}</a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular" href="">{{ ls.locs['loc'].Themenbuehnen }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular" href="">{{ ls.locs['loc'].ThemenbuehnensliderVerwalten }}</a>
      </div>
    </div>
    <div class="margin-top-16 flex-col-12 flex-direction-column margin-left-8">
      <ul class="tab-flex flex-grid">
        <li class="flex-col-3 flex-col-6-m padding-0">
          <a class="mp-button _prim active">{{ ls.locs['loc'].ReihenfolgeDerBuehnen }}</a>
        </li>
      </ul>
    </div>
    <section id="elig-requ-editor-content"
             class="flex-col-9 flex-col-9-l flex-col-12-m flex-col-12-s display-block-important margin-top-4">
      <div class="width-full bg-color-white padding-top-bottom-16 padding-left-right-8 box-sizing-border-box" id="stage-order-overview-table">
        <div class="flex-grid hide-on-medium" id="stage-order-overview-table-headlines">
          <div class="flex-col-2">{{ ls.locs['loc'].Position }}</div>
          <div class="flex-col-3">{{ ls.locs['loc'].Titel }}</div>
          <div class="flex-col-1">{{ ls.locs['loc'].ThemeStageArea }}</div>
          <div class="flex-col-2">{{ ls.locs['loc'].Anlagedatum }}</div>
          <div class="flex-col-1">{{ ls.locs['loc'].Von }}</div>
          <div class="flex-col-1">{{ ls.locs['loc'].Bis }}</div>
          <div class="flex-col-1">{{ ls.locs['loc'].Gruppe }}</div>
          <div class="flex-col-1" *ngIf="mpSettings.settings['DisplaySettings'].ShowGruppierungenInEditoren">{{ ls.locs['loc'].Gruppierung }}</div>
        </div>
        <ng-container *ngIf="groupsLoaded">
          <div *ngFor="let buehne of visibleStages; let index = index;" class="flex-grid">
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium flex-align-items-center">
              {{ ls.locs['loc'].Position }}
            </div>
            <div class="flex-col-2 flex-col-8-m flex-col-8-s flex-col-7-xs flex-justify-cont-between stage-sort-buttons">
              <div>
                {{index + 1}}
              </div>
              <div>
                <button class="mp-button padding-8 stage-sort-button"
                        (click)="pushDown(index)" [ngClass]="index === visibleStages.length -1 ? 'disabled' : '_prim'">
                  <i class="svg-icon __arrow-down __size-18" [ngClass]="index === visibleStages.length -1 ? '__clr-inactive' : '__clr-white'"></i>
                </button>
              </div>
              <div>
                <button class="mp-button padding-8 stage-sort-button"
                        (click)="pushUp(index)" [ngClass]="index === 0 ? 'disabled' : '_prim'">
                  <i class="svg-icon __arrow-up __size-18" [ngClass]="index === 0 ? '__clr-inactive' : '__clr-white'"></i>
                </button>
              </div>
            </div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium">
              <span>{{ ls.locs['loc'].Titel }}</span>
            </div>
            <div class="flex-col-3 flex-col-8-m flex-col-8-s flex-col-7-xs flex-direction-column">
              <div class="break-word" [innerHTML]="buehne.Titel"></div>
              <a href="#/manager/ThemenbuehnenEditor?id={{buehne.Id}}" class="text-link">{{ ls.locs['loc'].Bearbeiten }}</a>
            </div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium">{{ ls.locs['loc'].ThemeStageArea }}</div>
            <div class="flex-col-1 flex-col-8-m flex-col-8-s flex-col-7-xs">{{buehne.ThemeStageArea.substr(0,10)}}</div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium">{{ ls.locs['loc'].Anlagedatum }}</div>
            <div class="flex-col-2 flex-col-8-m flex-col-8-s flex-col-7-xs">{{buehne.Anlagedatum.substr(0,10)}}</div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium">{{ ls.locs['loc'].Von }}</div>
            <div class="flex-col-1 flex-col-8-m flex-col-8-s flex-col-7-xs">{{buehne.Von.substr(0,10)}}</div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium">{{ ls.locs['loc'].Bis }}</div>
            <div class="flex-col-1 flex-col-8-m flex-col-8-s flex-col-7-xs">{{buehne.Bis.substr(0,10)}}</div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium ">{{ ls.locs['loc'].Gruppe }}</div>
            <div class="flex-col-1 flex-col-8-m flex-col-8-s flex-col-7-xs" [innerHTML]="buehne.Gruppe"></div>
            <div class="flex-col-4-m flex-col-4-s flex-col-5-xs show-on-medium " *ngIf="mpSettings.settings['DisplaySettings'].ShowGruppierungenInEditoren">{{ ls.locs['loc'].Gruppierung }}</div>
            <div class="flex-col-1 flex-col-8-m flex-col-8-s flex-col-7-xs" *ngIf="mpSettings.settings['DisplaySettings'].ShowGruppierungenInEditoren" [innerHTML]="buehne.Gruppierung"></div>
          </div>
        </ng-container>
      </div>
    </section>
    <section id="reward-editor-datapanel"
             class="flex-col-3 flex-col-3-l flex-col-12-m flex-col-12-s display-block-important margin-top-4">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full">
        <div [hidden]="!mpSettings.settings['NetcentiveCoreSettings'].ShowGruppenInEditoren || !mpSettings.settings['BuehnenEditorSettings'].CanSelectUserGroups">
          <mp-core-checkbox class="width-full margin-top-24 margin-bottom-16" [model]="orderByUserGroups" (modelChange)="orderByUserGroups = $event; loadData()" mpId="chkOrderByUserGroups" label="{{ ls.locs['loc'].ReihenfolgeNachGruppen }}"></mp-core-checkbox>
          <div [hidden]="!orderByUserGroups">
            <mp-core-select class="width-full margin-bottom-24" mpId="select-gruppe" [(model)]="group" (ngChange)="groupChanged()" [elements]="groups" textProperty="Gruppe" [mpRequired]="true" label="{{ ls.locs['loc'].Gruppe }}"></mp-core-select>
          </div>
        </div>
        <div [hidden]="!mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea">
          <mp-core-select class="width-full margin-bottom-24"
                          mpId="select-themestagearea"
                          [(model)]="themestagearea"
                          (ngChange)="themestageareaChanged()"
                          [elements]="typeStageAreaParams"
                          textProperty="Displaytext"
                          [mpRequired]="true"
                          label="{{ ls.locs['loc'].ThemeStageArea }}"></mp-core-select>
        </div>
        <div class="width-full margin-top-24">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}"
                      alignment="left"
                      class="float-right-important">
            <button class="mp-button"
                    (click)="hints['showRevertChanges'] = true">
              <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
                 id="elig-requ-refresh-icon"></i>
            </button>
          </mp-core-tooltip>
        </div>
        <button class="mp-button _prim margin-top-16 width-full"
                (click)="hints['showSaveThemesStage'] = true">
          {{ ls.locs['loc'].AenderungenUebernehmen }}
        </button>
      </div>
    </section>
  </div>
  <mp-core-modal [show]="hints['showSaveThemesStage']" title="{{ ls.locs['loc'].ThemenbuehneSpeichernHinweisTitel }}" [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showSaveThemesStage'}, {text: ls.locs['loc'].Ja, action: saveFunc, class: '_prim'}]">
    {{ ls.locs['loc'].ThemenbuehneSpeichernHinweisText }}
  </mp-core-modal>
  <mp-core-modal [show]="hints['showRevertChanges']" title="{{ ls.locs['loc'].AenderungenRueckgaengigHinweisTitel }}" [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showRevertChanges'}, {text: ls.locs['loc'].Ja, action: loadDataFunc, class: '_prim'}]">
    {{ ls.locs['loc'].AenderungenRueckgaengigHinweisText }}
  </mp-core-modal>
</div>
