import { Injectable } from '@angular/core';

/**
 * This class provides the functions for
 * the additional payment.
 */
@Injectable({
  providedIn: 'root'
})
export class AdditionalPaymentService {

  private _functions: { [key: string]: Function } = {};

  constructor() { }

  /**
   * Registers a function within the
   * additional payment service.
   */
  register(key: string, func: Function): void {
    this._functions[key] = func;
  }

  /**
   * Calls a registered function.
   */
  call(key: string, successCallback: Function, errorCallback: Function): void {
    if (typeof this._functions[key] !== 'undefined') {
      this._functions[key](successCallback, errorCallback);
    }
  }
}
