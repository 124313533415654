import { Component, OnInit, Input } from '@angular/core';
import { MpLocalizationService } from '../../services/mp-localization.service';

import { ApiService } from './../../services/api.service';

/**
 * This class provides the data and
 * functions for the infotext.
 */
@Component({
  selector: 'mp-core-infotext',
  templateUrl: './infotext.component.html',
  styleUrls: ['./infotext.component.scss']
})
export class InfotextComponent implements OnInit {

  @Input() public sitename: string = '';
  @Input() public placement: string = '';
  @Input() public alignment: string = '';
  @Input() public text: string = '';
  @Input() public hasInfoText: boolean = false;

  constructor(
    private _apiService: ApiService,
    public ls: MpLocalizationService
  ) { }

  /**
   * Gets the data of the infotexts from the database
   */
  ngOnInit(): void {
    if (typeof this.alignment === 'undefined' || this.alignment === null || this.alignment === '') {
      this.alignment = 'bottom';
    }

    this._apiService.getRequest('/api/Texte/getText', false, { params: { key: 'infotext-' + this.sitename } }).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.text = data.Records[0];
          this.placement = typeof this.placement !== 'undefined' ? this.placement : 'left';

          if (this.text)
            this.hasInfoText = true;
        }
      },
      (error: any) => {
        console.log(error.Message);
      });
  }

}
