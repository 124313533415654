import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Provides observable to watch for selection
 * changes in calendar.
 */
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private _calendarCurrentSelectionChanged: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public calendarCurrentSelectionChanged: Observable<any> = this._calendarCurrentSelectionChanged.asObservable();

  constructor() { }

  /**
   * Changes the selection in a calendar.
   */
  changeCalendarSelection(data: any): void {
    this._calendarCurrentSelectionChanged.next(data);
  }
}
