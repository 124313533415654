import { Component, OnInit, Input, Inject, SimpleChanges, ElementRef, ViewEncapsulation, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * This class provides the functionalities
 * for a popover.
 */
@Component({
  selector: 'mp-core-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PopoverComponent implements OnInit {

  @Input() public content: string = '';
  @Input() public popoverTitle: string = '';
  @Input() public alignment: string = '';

  public showAsPopup: boolean = false;
  public showPopover: boolean = false;

  public window: Window | null;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  /**
   * Calls the check for showAsPopup
   * and registers an event listener for
   * window resize to set showAsPopup.
   */
  ngOnInit(): void {
    this.handleShowAsPopup();
  }

  /**
   * Checks if the popover is shown as pop-up depending on the window size.
   */
  handleShowAsPopup(): void {
    this.showAsPopup = (this.window?.innerWidth || 0) < 992;
  }

  /**
   * Opens the popover.
   */
  openPopover(): void {
    if (this.showAsPopup && !this.showPopover) {
      this.showPopover = true;
    }
  }

  /**
   * Closes the popover.
   */
  closePopover(): void {
    if (this.showPopover && this.showAsPopup) {
      this.showPopover = false;
    }
  }
}
