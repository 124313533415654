import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';

import { MpLocalizationService } from './../../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../../services/mp-loader.service';

import { MpLoader } from './../../../../../services/interfaces/mp-loader';
import { ParentTableRow, ParentTableRowIndex } from './../../../../../components/data-table/table-injectors';

/**
 * This class provides the data for the
 * award statistic table child.
 */
@Component({
  selector: 'mp-core-award-statistic-child',
  templateUrl: './award-statistic-child.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AwardStatisticChildComponent implements OnInit {

  public loaderName = '';

  private _managerAwardStatisticChildLoader: MpLoader = {
    name: 'managerAwardStatisticChildLoader',
    isFirst: false,
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  /**
   * Sets the loader for the table data,
   * and gets the key from the parent data
   * table.
   */
  ngOnInit(): void {
    this._managerAwardStatisticChildLoader.name = `${this._managerAwardStatisticChildLoader.name}--${this._parentTableRowIndex}`;
    this._mpLoaderService.registerLoader(this._managerAwardStatisticChildLoader);
    const parentLoader = this._mpLoaderService.getLoader('managerAwardStatisticLoader');
    let params = {};

    if (this._parentTableRow !== null) {
      params = {
        // @ts-ignore
        ArtNr: this._parentTableRow['ArtNr'],
        StartDate: parentLoader.params.StartDate,
        EndDate: parentLoader.params.EndDate
      };
    }

    this._mpLoaderService.extendLoader(this._managerAwardStatisticChildLoader.name, {
      isReady: true,
      params: params
    });

    this.loaderName = this._managerAwardStatisticChildLoader.name;
    const loader = this._mpLoaderService.getLoader(this._managerAwardStatisticChildLoader.name);

    setTimeout(() => {
      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    }, 400);
  }

}
