import { Directive, Input, OnChanges, SimpleChanges, OnDestroy, LOCALE_ID, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';
import { Subscription } from 'rxjs';

import { ChangeContext, Options } from '@angular-slider/ngx-slider';

import { ApiService } from '@core/services/api.service';

/**
 * This class provides the data and functions
 * for the additional payment slider.
 */
@Directive({
  selector: '[mpRkzzTcAdditionalPaymentSlider]',
  exportAs: 'mpRkzzTcAdditionalPaymentSlider'
})
export class TcAdditionalPaymentSliderDirective implements OnChanges, OnDestroy {

  @Input() public item: { [key: string]: any } = {};
  @Input() public step: number = 0;
  @Input() public enforceStep: boolean = false;
  @Input() public getAmountFunc: any;
  @Input() public routeParams: { [key: string]: any } = {};
  @Input() public totalPoints: number = 0;
  @Input() public checkingParameters: { [key: string]: any } = {};
  @Input() public updateAdditionalPaymentgValues: any;

  private _maxValue: number = 0;
  private _lastRequestedAmount: number | string = 0;
  private _setAmountSubscription: Subscription | undefined;

  /**
   * Creates the functions for updating euro
   * and points.
   */
  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    @Inject(LOCALE_ID) private _locale: string
  ) { }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._setAmountSubscription !== 'undefined') {
      this._setAmountSubscription.unsubscribe();
    }
  }

  /**
   * Watches for changes of the input
   * parameters.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['item'] !== 'undefined') {
      if (changes['item'].isFirstChange() || typeof changes['item'].previousValue !== 'undefined' && typeof changes['item'].currentValue !== 'undefined' && changes['item'].previousValue !== changes['item'].currentValue) {
        if (Object.keys(this.item).length === 0) {
          return;
        }

        if (typeof this.item['EingeloestePunkte'] === 'undefined') {
          this.item['EingeloestePunkte'] = 0;
        }

        this.item['getStepArray'] = (step: number, item: { [key: string]: any }, maxRedemption: number, canBePaidWithPoints: boolean) => {
          let stepArray = [];

          for (var i = item['MinEingeloestePunkte']; i < item['MaxEingeloestePunkte']; i += step) {
            stepArray.push(i);
          }

          stepArray.push(item['MaxEingeloestePunkte']);

          if (canBePaidWithPoints && item['MaxEingeloestePunkte'] !== maxRedemption) {
            stepArray.push(maxRedemption);
          }

          if (item['CanBePaidWithPoints']) {
            stepArray.push(item['Punkte']);
          }

          if (stepArray.indexOf(item['EingeloestePunkte']) === -1) {
            stepArray.push(item['EingeloestePunkte']);
          }

          stepArray = [...new Set(stepArray)];

          const sortedStepArray = stepArray.sort((a: number, b: number) => {
            return a - b;
          });

          const sortedStepsObjectArray: Array<any> = [];

          sortedStepArray.forEach((st: any, index: number) => {
            sortedStepsObjectArray.push({
              value: st
            });
          });

          return sortedStepsObjectArray;
        };

        this._maxValue = this.item['MaxEingeloestePunkte'];
        const newSliderOptions: Options = Object.assign({}, (this.item['sliderOptions'] || {}));
        newSliderOptions.ceil = this._maxValue;
        // @ts-ignore
        newSliderOptions.stepsArray = this.item.getStepArray(this.step, this.item, this.item['Punkte'], this.item['CanBePaidWithPoints']);
        newSliderOptions.enforceStep = this.enforceStep || false;
        newSliderOptions.showSelectionBar = true;

        this.item['sliderOptions'] = newSliderOptions;

        if (typeof changes['item'].previousValue !== 'undefined' && typeof changes['item'].currentValue !== 'undefined' && changes['item'].currentValue['EingeloestePunkteText'] !== changes['item'].previousValue['EingeloestePunkteText']) {
          if (typeof this.updateOfferPoints !== 'undefined') {
            this.updateOfferPoints(changes['item'].currentValue['EingeloestePunkteText'], changes['item'].previousValue['EingeloestePunkteText']);
          }
        }

        if (changes['item'].isFirstChange() || changes['item'].currentValue['EingeloestePunkte'] !== changes['item'].previousValue['EingeloestePunkte']) {
          this.item['EingeloestePunkteText'] = this.item['EingeloestePunkte'] + '';
        }
      }
    }
  }

  /**
   * Fetches th end of changes of the slider,
   * triggered by user interaction.
   */
  onSliderChangeEnd(changeContext: ChangeContext): void {
    this.item['EingeloestePunkte'] = changeContext.value;
    this.item['EingeloestePunkteText'] = this.item['EingeloestePunkte'] + '';

    if (typeof this.getAmountFunc !== 'undefined') {
      this.getAmountFunc();
    }
  }

  /**
   * Fetches the start of changes of the slider,
   * triggered by user interaction.
   */
  onSliderChangeStart(changeContext: ChangeContext): void {
    this.item['ZuzahlungEuro'] = '-';
  }

  /**
   * Triggers the update of the offers
   * euro worth.
   */
  updateOfferEuro(newVal: any, oldVal: any): void {
    if ((oldVal || oldVal === 0) && oldVal !== newVal && newVal !== '-') {
      this._lastRequestedAmount = newVal;

      this._setAmountSubscription = this._apiService.postRequest('/api/RkZuzahlung/SetBetrag', {
        SearchId: this.routeParams['sid'],
        AngebotsId: this.item['ID'],
        OutboundId: this.routeParams['out'],
        ReturnId: this.routeParams['ret'],
        Reiseversicherung: this.checkingParameters['Reiseschutz'],
        Gesamtpunkte: this.totalPoints,
        ZuzahlungEuro: formatNumber(this.item['ZuzahlungEuro'], this._locale),
        // @ts-ignore
        Path: this._route.snapshot.pathFromRoot[2].routeConfig.path
      }).subscribe((data: any) => {
        if (data.Result === 'OK' && newVal === this._lastRequestedAmount) {
          this.item['ZuzahlungEuro'] = data.Records[0].Betrag;
          this.item['EingeloestePunkte'] = this.totalPoints - data.Records[0].ZuzahlungPunkte;

          if (typeof this.updateAdditionalPaymentgValues !== 'undefined') {
            this.updateAdditionalPaymentgValues(data);
          }
        }

        if (typeof this._setAmountSubscription !== 'undefined') {
          this._setAmountSubscription.unsubscribe();
        }
      });
    }
  }

  /**
   * Triggers the update of the offers
   * points worth.
   */
  updateOfferPoints(newVal: any, oldVal: any): void {
    const redeemedPoints = parseInt(newVal);

    if (isNaN(redeemedPoints)) {
      this.item['EingeloestePunkteText'] = oldVal;
      return;
    }

    this.item['EingeloestePunkte'] = redeemedPoints;

    if (typeof this.getAmountFunc !== 'undefined') {
      this.getAmountFunc();
    }
  }

}
