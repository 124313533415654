import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { CookieService } from './../../services/cookie.service';
import { AuthService } from './../../services/auth.service';
import { RoleMappingService } from './../../services/role-mapping.service';
import { CustomEventService, CustomEventType, CustomEvent } from './../../services/custom-event.service';

/**
 * This class handles the notification of used
 * cookies, that is shown to the user (if cookies
 * are not already set).
 */
@Component({
  selector: 'mp-core-cookie-notify',
  templateUrl: './cookie-notify.component.html',
  styleUrls: ['./cookie-notify.component.scss']
})
export class CookieNotifyComponent implements OnInit, OnDestroy {
  @Input() public notifyCookieSettings: any;
  @Input() public mainContent: string = '';
  @Input() public internExtern: string = '';

  @ViewChild('cookieContainer') cookieContainer: ElementRef<any> | undefined;

  public notifyCookieSet: boolean = false;
  public role: string = '';

  private _notifyCookieSetTriggers: NodeListOf<Node> | null = null;
  private _cookieContainerHeight: number = 0;
  private _setNotifyCookie = this.setNotifyCookie.bind(this);
  private _customEventSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _cookieService: CookieService,
    private _customEventService: CustomEventService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * The init function, that checks wether or not
   * the notify cookie is set.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (this.checkNotifyCookieSet()) {
      this.notifyCookieSet = true;
    }

    this._customEventSubscription = this._customEventService.on(CustomEventType.SetNotifyCookie).subscribe((event: CustomEvent<any>) => {
      this.setNotifyCookie();
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._customEventSubscription !== 'undefined') {
      this._customEventSubscription.unsubscribe();
    }

    if (this.notifyCookieSettings.hideOnScroll) {
      window.removeEventListener('scroll', this.setNotifyCookieOnScroll.bind(this), false);
    }
  }

  /**
   * Angulars after view init function. Shows the
   * cookie layer (if notify cookie not set) and
   * sets event listeners for setting the notify
   * cookie.
   */
  ngAfterViewInit(): void {
    if (!this.notifyCookieSet) {
      this._notifyCookieSetTriggers = document.body.querySelectorAll('.owl-dot, a[href], .mp-button, button');

      if (this.notifyCookieSettings.hideOnScroll) {
        window.addEventListener('scroll', this.setNotifyCookieOnScroll.bind(this), false);
      }

      if (this.notifyCookieSettings.hideOnLink) {
        if (this.mainContent === '') {
          console.log('Please insert mainContent to user hideOnLink');
        } else {
          if (this._notifyCookieSetTriggers !== null) {
            this._notifyCookieSetTriggers.forEach(notifyCookieSetTrigger => {
              notifyCookieSetTrigger.addEventListener('click', this._setNotifyCookie, false);
              notifyCookieSetTrigger.addEventListener('touchstart', this._setNotifyCookie, false);
            });
          }

          if (this.cookieContainer) {
            const layoutExternElement: HTMLElement | null = document.querySelector('[layout-extern]');
            const cookieContainerComputedStyles: any = window.getComputedStyle(this.cookieContainer.nativeElement);
            this._cookieContainerHeight = parseInt(cookieContainerComputedStyles.getPropertyValue('height').replace('px', '')) + parseInt(cookieContainerComputedStyles.getPropertyValue('padding').replace('px', '')) * 2;

            if (layoutExternElement !== null) {
              layoutExternElement.style.minHeight = `calc(100% - ${this._cookieContainerHeight}px)`;
            }
          }
        }
      }
    }
  }

  /**
   * Checks whether or not the notify cookie is
   * already set in the users browser.
   */
  checkNotifyCookieSet(): boolean|string {
    return this._cookieService.checkIfCookieSet('mpHideCookieNotify');
  }

  /**
   * Triggers the function to set the notifiy cookie,
   * if user scrolls more than 0 px.
   */
  setNotifyCookieOnScroll(): void {
    if (window.scrollY > 0) {
      this.setNotifyCookie();
    }
  }

  /**
   * Sets the notify cookie and removes all registered
   * event listeners. Hides the cookie layer.
   */
  setNotifyCookie(): void {
    if (this.notifyCookieSettings.hideOnScroll) {
      window.removeEventListener('scroll', this.setNotifyCookieOnScroll.bind(this), false);
    }

    if (this.notifyCookieSettings.hideOnLink) {
      if (this._notifyCookieSetTriggers !== null) {
        this._notifyCookieSetTriggers.forEach(notifyCookieSetTrigger => {
          notifyCookieSetTrigger.removeEventListener('click', this._setNotifyCookie, false);
          notifyCookieSetTrigger.removeEventListener('touchstart', this._setNotifyCookie, false);
        });
      }
    }

    this._cookieService.setCookie('mpHideCookieNotify=true', { secure: true });

    if (this.cookieContainer) {
      const layoutExternElement: HTMLElement | null = document.querySelector('[layout-extern]');
      const cookieContainerNativeElement = this.cookieContainer.nativeElement;
      const cookieContainerComputedStyles: any = window.getComputedStyle(cookieContainerNativeElement);
      this._cookieContainerHeight = parseInt(cookieContainerComputedStyles.getPropertyValue('height').replace('px', '')) + parseInt(cookieContainerComputedStyles.getPropertyValue('padding').replace('px', '')) * 2;

      if (!cookieContainerNativeElement.classList.contains('hide-cookie-notify')) {
        cookieContainerNativeElement.classList.add('hide-cookie-notify');
      }

      cookieContainerNativeElement.style.marginTop = `-${this._cookieContainerHeight}px`;
      cookieContainerNativeElement.style.display = 'none';

      if (layoutExternElement !== null) {
        layoutExternElement.removeAttribute('style');
      }
    }

    this.notifyCookieSet = false;
  }
}
