<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].EMailProtokoll }}</h1>
  <div class="MailStatusFilter">
    
  </div>
  <div class="clear"></div>
  <mp-core-data-table [headerSelectTransclude]="true" title="{{ ls.locs['loc'].EMailProtokoll }}" infotextShorten="EMailProtokoll" fieldsAction="/api/EMailProtokoll/getEMailProtokollFields" listAction="/api/EMailProtokoll/getEMailProtokoll" loaderName="getEmailProtocolLoader" [paging]="true" defaultSorting="AnlageDatum DESC" [showDatePicker]="true">
    <mp-core-select [multiple]="true" label="{{ ls.locs['loc'].Nachrichtenstatus }}" [mpRequired]="true" mpId="e-mail-modul-filter" [(model)]="activeFilters" textProperty="Text" [elements]="filters" (ngChange)="doData(activeFilters)"></mp-core-select>
  </mp-core-data-table>
  <div class="floating-container" *ngIf="loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
