import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, BehaviorSubject } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpCommunicationProcessService } from './../../../../services/mp-communication-process.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';
import { CookieService } from './../../../../services/cookie.service';

/**
 * This class provides the data and
 * functions for the check notiication
 * page of the communication process.
 */
@Component({
  selector: 'mp-core-check-notification',
  templateUrl: './check-notification.component.html',
  styleUrls: ['./../theme-stage-editor/theme-stage-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckNotificationComponent implements OnInit, OnDestroy {
  
  public showSaveCpConfirm: boolean = false;
  public title: string = '';
  public testMailData: { [key: string]: any } = {
    Address: ''
  };
  public showTestMailModal: boolean = false;
  public testMailAddress: string = '';
  public role: string = '';
  public setShowTestMailModalFalse = this._setShowTestMailModalFalse.bind(this);
  public setShowSaveCpConfirmFalse = this._setShowSaveCpConfirmFalse.bind(this);
  public sendTestEMail = this._sendTestEMail.bind(this);
  public saveCommunicationProcessApproved = this._saveCommunicationProcessApproved.bind(this);
  public accData = this._accData.bind(this);

  private _hastStageEditor: boolean = false;
  private _hasEmailModule: boolean = false;
  private _hasRewardEditor: boolean = false;
  private _mailText: { [key: string]: any } = {};
  private _data: Array<any> = [];
  private _mailTemplate: string = '';
  private _getLocsSubscription: Subscription | undefined;
  private _getMailTextSubscription: Subscription | undefined;
  private _sendTestMailSubscription: Subscription | undefined;
  private _locationChangeSubscription: Subscription | undefined;

  private accDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    public cp: MpCommunicationProcessService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
    private _mpMessaging: MpMessagingService,
    private _cookieService: CookieService,
    private _router: Router,
    private _authService: AuthService,
    private _sanitizer: DomSanitizer,
    private _roleMapping: RoleMappingService
  ) { }

  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('kommunikation');
    this._titleService.setTitleFromLoc('BenachrichtigungPruefen');
    const confirmedSteps = this.cp.getConfirmedSteps();
    this._hasEmailModule = confirmedSteps.indexOf('e-mail-modul') !== -1;
    this._hastStageEditor = confirmedSteps.indexOf('themenbuehnen-editor') !== -1;
    this._hasRewardEditor = confirmedSteps.indexOf('belohnungs-editor') !== -1;
    this.cp.isValidStep('benachrichtigung-pruefen');
    this.testMailAddress = this._cookieService.getCookieValue('TestEMail');
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      this._getParticipantMailText();
      this.title = this.cp.loadStep('titel');
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._getParticipantMailText();
          this.title = this.cp.loadStep('titel');
        }
      });

      this.ls.getLocalization();
    }

    this._locationChangeSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.cp.saveStep('titel', this.title);
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getMailTextSubscription !== 'undefined') {
      this._getMailTextSubscription.unsubscribe();
    }

    if (typeof this._sendTestMailSubscription !== 'undefined') {
      this._sendTestMailSubscription.unsubscribe();
    }

    if (typeof this._locationChangeSubscription !== 'undefined') {
      this._locationChangeSubscription.unsubscribe();
    }
  }

  /**
   * Gets the mail text for the
   * chosen participant.
   */
  private _getParticipantMailText(): void {
    const participantChoiceData = this.cp.loadStep('teilnehmer-auswahl');
    const participantId = participantChoiceData['Teilnehmer'] && participantChoiceData['Teilnehmer'].length == 1
      ? participantChoiceData['Teilnehmer'][0].Identifier
      : 0;
    this._getMailTextSubscription = this._apiService.getRequest(`/api/Belohnung/GetTnMailText?tnId=${participantId}`).subscribe((data: any) => {
      this._mailText = data.Records[0];
      this._setAccData();
      this.accDataLoaded.next(true);
    });
  }

  /**
   * Sets data, that should be shown
   * in the accordions at the frontend.
   */
  private _setAccData(): void {
    this._data = [];
    if (Object.keys(this.cp.steps).length > 0) {
      const participantChoiceData = this.cp.loadStep('teilnehmer-auswahl');
      var participantChoiceText = `<strong>${this.ls.locs['loc'].Empfaenger}</strong><br>`;

      if (participantChoiceData) {
        const hasGroups = participantChoiceData['Gruppen'] && participantChoiceData['Gruppen'].length > 0;
        const groupsText = !hasGroups
          ? ''
          : (participantChoiceData['GruppenText']
            ? participantChoiceData['GruppenText']
            : (participantChoiceData['AlleGruppen']
              ? this.ls.locs['loc'].Alle
              : ''));
        const participantText = participantChoiceData['TeilnehmerText']
          ? participantChoiceData['TeilnehmerText']
          : (participantChoiceData['AlleTeilnehmer']
            ? this.ls.locs['loc'].Alle
            : (participantChoiceData['Teilnehmer']
              ? participantChoiceData['Teilnehmer'].length
              : '0'));

        participantChoiceText += hasGroups ? `<br>${this.ls.locs['loc'].Gruppe} (${groupsText})` : '';
        participantChoiceText += `<br> ${this.ls.locs['loc'].Teilnehmer } (${participantText})`;
      }

      this._data.push({
        accID: 1,
        accHeadline: this.ls.locs['loc'].Empfaenger,
        accText: participantChoiceText,
        accOpenOnLoad: true,
        accButtonOne: {
          label: this.ls.locs['loc'].KommunikationsEmpfaenger,
          func: this._downloadCommunicationRecipients.bind(this)
        },
        accButtonTwo: {
          label: this.ls.locs['loc'].Bearbeiten,
          func: this._editParticipantChoice.bind(this)
        }
      });

      if (this._hasRewardEditor) {
        const rewardData = this.cp.loadStep('belohnungs-editor');
        let rewardText = '';
        const rewardDate = moment(rewardData['StartDatum']);
        rewardText += '<strong>' + this.ls.locs['loc'].WasWirdBelohnt + '?: </strong>' + rewardData['WasWirdBelohnt'];

        if (rewardData && rewardData['Belohnungsart'] === 'geburtstag') {
          rewardText += '<hr /><strong>' +
            this.ls.locs['loc'].WannWirdBelohnt +
            '?: </strong>' +
            this.ls.locs['loc'].BeiGeburtstag;
        } else if (rewardData && rewardData['Belohnungsart'] === 'jubilaeum') {
          rewardText += '<hr /><strong>' +
            this.ls.locs['loc'].WannWirdBelohnt +
            '?: </strong>' +
            this.ls.locs['loc'].BeiFirmeneintritt;
        }
        else if (!rewardData ||
          !rewardData['BelohnungsartObject'] ||
          !rewardData['BelohnungsartObject'].FixedDateForEmail) {
          rewardText += '<hr /><strong>' +
            this.ls.locs['loc'].WannWirdBelohnt +
            '?: </strong>' +
            (rewardDate.isBefore(moment()) ? this.ls.locs['loc'].BeiFreigabe : rewardDate.local().format('DD.MM.YYYY'));
        }
        
        rewardText += '<hr /><strong>' + this.ls.locs['loc'].WieWirdBelohnt + '?: </strong><ul class="list">';

        if (rewardData['Belohnungsart'] === 'geburtstag') {
          rewardText += '<li>' +
            this.ls.locs['loc'].BelohnungInPunktenGeburtstag +
            ': ' +
            rewardData['BelohnungsHoehe'] +
            '</li>';
          rewardText += '<li>' +
            this.ls.locs['loc'].BelohnungInEuroGeburtstag + ' €: ' +
            rewardData['BelohnungsHoeheEuro'].toFixed(2) + ' €</li>';
          rewardText += '<li>' +
            this.ls.locs['loc'].BelohnungInPunktenHalbrund +
            ': ' +
            rewardData['PunkteHalbrund'] +
            '</li>';
          rewardText += '<li>' + this.ls.locs['loc'].BelohnungInEuroHalbrund + ': ' + rewardData['EuroHalbrund'].toFixed(2) + ' €</li>';
          rewardText += '<li>' + this.ls.locs['loc'].BelohnungInPunktenRund + ': ' + rewardData['PunkteRund'] + '</li>';
          rewardText += '<li>' + this.ls.locs['loc'].BelohnungInEuroRund + ': ' + rewardData['EuroRund'].toFixed(2) + ' €</li>';
        }

        if (rewardData['Belohnungsart'] !== 'geburtstag') {
          rewardText += '<li>' + this.ls.locs['loc'].BelohnungInPunkten + ': ' + rewardData['BelohnungsHoehe'] + '</li>';
          rewardText += '<li>' + this.ls.locs['loc'].BelohnungInEuro + ': ' + rewardData['BelohnungsHoeheEuro'].toFixed(2) + ' €</li>';
        }

        rewardText += '</ul>';

        this._data.push({
          accID: 2,
          accHeadline: this.ls.locs['loc'].Belohnung,
          accText: rewardText,
          accOpenOnLoad: true,
          accButtonOne: {
            label: this.ls.locs['loc'].Bearbeiten,
            func: this._editRewardEditor.bind(this)
          }
        });
      }

      if (this._hasEmailModule) {
        const emailModuleData = this.cp.loadStep('e-mail-modul');
        let emailModuleText = '';
        const date = moment(emailModuleData['AusfuehrungDatum']);
        const dateText = date.isSame(moment.utc([1900, 0, 1])) ? this.ls.locs['loc'].Sofort : date.local().format('DD.MM.YYYY HH:mm');

        if (emailModuleData && emailModuleData['BelohnungsartObject'] && emailModuleData['BelohnungsartObject'].FixedDateForEmail)
          emailModuleText += '<strong>' + this.ls.locs['loc'].VersendenAm + ':</strong> Gutschrifttag';
        else
          emailModuleText += '<strong>' + this.ls.locs['loc'].VersendenAm + ':</strong> ' + dateText;

        if (emailModuleData['Subject']) {
          emailModuleText += '<hr /><strong>' + this.ls.locs['loc'].Betreff + ':</strong> ';
          emailModuleText += emailModuleData['Subject'] + '<br>';
        }

        if (emailModuleData.Attachments && emailModuleData['Attachments'].length > 0) {
          emailModuleText += '<hr /><strong>' + this.ls.locs['loc'].Dateianheange + ':</strong> ';
          emailModuleData['Attachments'].forEach(
            (elem: any) => {
              let i = elem.lastIndexOf('\\');
              let j = elem.lastIndexOf('/');
              emailModuleText += i < 0 && j < 0
                ? elem + '<br>'
                : i > j
                  ? elem.substr(i + 1) + '<br>'
                  : elem.substr(j + 1) + '<br>';
            });
        }; // ==> Entfernen in mp.Demo

        if (emailModuleData['Body'] && this._mailTemplate !== '') {
          emailModuleText += '<hr /><p>' + this._mailTemplate.replace('[EMailBody]', emailModuleData['Body']) + '</p>';
        }

        if (emailModuleData['Body'] && this._mailTemplate === '') {
          emailModuleText += '<hr /><p>' + emailModuleData['Body'] + '</p>';
        }

        this._data.push({
          accID: 3,
          accHeadline: this.ls.locs['loc'].Nachricht,
          accText: emailModuleText,
          accOpenOnLoad: true,
          accButtonOne: {
            label: this.ls.locs['locButtons'].TestNachrichtSenden,
            func: this._openTestMailModal.bind(this)
          },
          accButtonTwo: {
            label: this.ls.locs['loc'].Bearbeiten,
            func: this._editEmailModul.bind(this)
          }
        });
      }

      if (this._hastStageEditor) {
        const stageEditorData = this.cp.loadStep('themenbuehnen-editor');
        localStorage.removeItem("themeStage");
        const currentContent = stageEditorData['Contents'][0];

        const themeStage: any = {
          Titel: currentContent['Headline'],
          CssClass: currentContent['CssClass'],
          Teaser: currentContent['Teaser'],
          ImagePath: stageEditorData['ImagePath'],
          Button1Bezeichnung: currentContent['Button1'] ? currentContent['Button1'].Bezeichnung : '',
          Button2Bezeichnung: currentContent['Button2'] ? currentContent['Button2'].Bezeichnung : '',
          Button1LinkURL: currentContent['Button1'] ? currentContent['Button1'].LinkURL : '',
          Button2LinkURL: currentContent['Button2'] ? currentContent['Button2'].LinkURL : '',
          Button1InNeuemTab: currentContent['Button1'] ? currentContent['Button1'].InNeuemTab : '',
          Button2InNeuemTab: currentContent['Button2'] ? currentContent['Button2'].InNeuemTab : ''
        };

        localStorage.setItem("themenbuehne", JSON.stringify(themeStage));

        let tsEditorText = '';

        if (stageEditorData && stageEditorData['BelohnungsartObject'] && stageEditorData['BelohnungsartObject'].FixedDateForTb)
          tsEditorText += '<strong>' + this.ls.locs['loc'].Zeitraum + ':</strong> Ab Gutschrifttag für ' + stageEditorData['BelohnungsartObject'].ShowTbForDays + ' Tage';
        else if (stageEditorData['Von']) {
          tsEditorText += '<strong>' + this.ls.locs['loc'].Zeitraum + ':</strong> ';

          const von = moment(stageEditorData['Von']);
          const vonText = von.local().format('DD.MM.YYYY HH:mm');

          if (stageEditorData.Bis) {
            const bis = moment.utc(stageEditorData['Bis']);
            const bisText = bis.local().format('DD.MM.YYYY HH:mm');

            tsEditorText += vonText + ' - ' + bisText + '<br>';
          } else {
            tsEditorText += this.ls.locs['loc'].ab + ' ' + vonText + '<br>';
          }
        }

        tsEditorText += `
                   <section id="stage-preview" class="stage-container margin-top-16 flex-col-12 flex-direction-column bg-color-transparent">
                      <div class="stage box-sizing-border-box overflow-hidden">
                        <div class="owl-theme">
                          <div class="owl-carousel owl-loaded">
                            <div class="owl-stage-outer">
                              <div class="owl-stage">
                                <div class="owl-item active width-full box-sizing-border-box overflow-hidden">
                                  <div class="item">
                                    <div class="owl-inner">
                                      <img src="[ImagePath]" alt="">
                                      <div class="caption">
                                        <div>
                                          <h1>[Titel]</h1>
                                        </div>
                                        <span>[Teaser]</span>
                                        [Buttons]
                                      </div>
                                    </div>
                                  </div>
                                  <span id="_preview-notice" class="position-absolute bottom-0 box-sizing-border-box display-flex flex-align-items-center flex-justify-cont-center text-transform-uppercase padding-bottom-8 padding-left-right-40">
                                   [Vorschau]
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>`;

        tsEditorText = tsEditorText.replace('[ImagePath]', themeStage['ImagePath']);
        tsEditorText = tsEditorText.replace('[Titel]', themeStage['Titel']);
        tsEditorText = tsEditorText.replace('[Teaser]', themeStage['Teaser']);
        tsEditorText = tsEditorText.replace('[Vorschau]', this.ls.locs['loc'].Vorschau);

        let buttons = `
                    <div class="read-more-buttons">
                      [Button1]
                      [Button2]
                    </div>`;

        let button1 = '';

        if (themeStage['Button1Bezeichnung'].length > 0 &&
          themeStage['Button1TypId'] !== 2 &&
          themeStage['Button1TypId'] !== 7) {
          button1 =
            `<div class="mp-button _prim button-large read-more">
                     [ButtonBezeichnung]
                   </div>`
        }

        if (themeStage.Button1Bezeichnung.length > 0 &&
          (themeStage['Button1TypId'] === 2 || themeStage['Button1TypId'] === 7)) {
          let target = themeStage['Button1InNeuemTab'] ? '_blank' : '_self';

          button1 =
            `<a class="mp-button _prim button-large read-more"
                      href="[LinkUrl]"
                      target="[Target]">
                     [ButtonBezeichnung]
                   </a>`

          button1 = button1.replace('[Target]', target);
          button1 = button1.replace('[LinkUrl]', themeStage['Button1LinkURL']);
        }

        button1 = button1.replace('[ButtonBezeichnung]', themeStage['Button1Bezeichnung']);
        let button2 = '';

        if (themeStage['Button2Bezeichnung'].length > 0 &&
          themeStage['Button2TypId'] !== 2 &&
          themeStage['Button2TypId'] !== 7) {
          button2 =
            `<div class="mp-button _prim button-large read-more">
                     [ButtonBezeichnung]
                   </div>`
        }

        if (themeStage['Button2Bezeichnung'].length > 0 &&
          (themeStage['Button2TypId'] === 2 || themeStage['Button2TypId'] === 7)) {
          let target = themeStage['Button2InNeuemTab'] ? '_blank' : '_self';

          button2 =
            `<a class="mp-button _prim button-large read-more"
                      href="[LinkUrl]"
                      target="[Target]">
                     [ButtonBezeichnung]
                   </a>`

          button2 = button2.replace('[Target]', target);
          button2 = button2.replace('[LinkUrl]', themeStage['Button2LinkURL']);
        }

        button2 = button2.replace('[ButtonBezeichnung]', themeStage['Button2Bezeichnung']);
        buttons = buttons.replace('[Button1]', button1);
        buttons = buttons.replace('[Button2]', button2);
        tsEditorText = tsEditorText.replace('[Buttons]', buttons);

        this._data.push({
          accID: 4,
          accHeadline: this.ls.locs['loc'].Themenbuehne,
          accText: tsEditorText,
          accOpenOnLoad: true,
          accButtonOne: {
            label: this.ls.locs['loc'].Bearbeiten,
            func: this._editThemeStageEditor.bind(this)
          }
        });
      }
    }
  }

  /**
   * Opens the test mail modal.
   */
  private _openTestMailModal(): void {
    this.showTestMailModal = true;
  }

  /**
   * Closes the test mail modal.
   */
  private _setShowTestMailModalFalse(): void {
    this.showTestMailModal = false;
  }

  /**
   * Closes the save cp confirm modal.
   */
  private _setShowSaveCpConfirmFalse(): void {
    this.showSaveCpConfirm = false;
  }

  /**
   * Redirects to the communication procss
   * step for editting the theme stage.
   */
  private _editThemeStageEditor(): void {
    this.cp.goToStepKey('themenbuehnen-editor');
  }

  /**
   * Redirects to the communication procss
   * step for editting the email.
   */
  private _editEmailModul(): void {
    this.cp.goToStepKey('e-mail-modul');
  }

  /**
   * Redirects to excel download of the
   * recipients.
   */
  private _downloadCommunicationRecipients(): void {
    // @ts-ignore
    document.location.href = '/api/KommunikationProzess/KommunikationsEmpfaenger2Excel';
  }

  /**
   * Redirects to the communication procss
   * step for choosing participants.
   */
  private _editParticipantChoice(): void {
    this.cp.goToStepKey('teilnehmer-auswahl');
  }

  /**
   * Redirects to the communication procss
   * step for the rewards.
   */
  private _editRewardEditor(): void {
    this.cp.goToStepKey('belohnungs-editor');
  }

  /**
   * Returns the set data.
   */
  _accData(): Array<any> {
    return this._data;
  }

  /**
   * Triggers the showing of the
   * clear confirm modal of the
   * communication process.
   */
  cpShowClearConfirm(evt: MouseEvent): void {
    evt.preventDefault();
    this.cp.showClearConfirm();
  }

  /**
   * Shows the save cp confirm modal.
   */
  openSaveCpConfirm(evt: MouseEvent): void {
    this.showSaveCpConfirm = true;
  }

  /**
   * Triggers the showing of the
   * reset confirm modal of the
   * communication process.
   */
  cpShowResetConfirm(): void {
    this.cp.showResetConfirm();
  }

  /**
   * Validates the participant choice.
   */
  private _validateParticipantChoice(): boolean {
    const participantChoiceData = this.cp.loadStep('teilnehmer-auswahl');

    if (participantChoiceData['Teilnehmer'].length > 0 || participantChoiceData['AlleTeilnehmer']) {
      return true;
    }

    return false;
  }

  /**
   * Validates the reward.
   */
  private _validateReward(): boolean {
    const rewardEditorData = this.cp.loadStep('belohnungs-editor');

    if (rewardEditorData['Belohnungsart'] === 'sonstige' && !rewardEditorData['Begruendung']) {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].BelohnungsartBenoetigtBegruendung);
      return false;
    }

    return true;
  }

  /**
   * Validates the email.
   */
  private _validateEmail(test: boolean): boolean {
    const emailModuleData = this.cp.loadStep('e-mail-modul');

    if (test) {
      if (!this.testMailData['Address'] || this.testMailData['Address'] === '') {
        this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorTestEmpfaenger);
        return false;
      }
    }

    if (emailModuleData['CommunicationTypes'].length > 0 && emailModuleData['CommunicationTypes'][0].Identifier != 'sms' && (!emailModuleData['Subject'] || emailModuleData['Subject'] === '')) {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorEmailSubject);
      return false;
    }

    if (!emailModuleData['Body'] || emailModuleData['Body'] === '') {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorEmailBody);
      return false;
    }

    return true;
  }

  /**
   * Validates the theme stage.
   */
  private _validateThemeStage(): boolean {
    const tsEditorData = this.cp.loadStep('themenbuehnen-editor');

    if (!tsEditorData['Contents'] || !tsEditorData['Contents'][0] || !tsEditorData['Contents'][0].Headline || tsEditorData['Contents'][0].Headline === '') {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorHeadline);
      return false;
    }

    if (!tsEditorData['ImagePath'] || tsEditorData['ImagePath'] === '') {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorThemenbuehnenbild);
      return false;
    }

    if (!tsEditorData['Titel'] || tsEditorData['Titel'] === '') {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorThemenbuehneTitle);
      return false;
    }

    return true;
  }

  /**
   * Saves the test mail address in the
   * cookies.
   */
  saveTestMail(): void {
    this._cookieService.setCookieWithoutCheck('TestEMail=' + this.testMailAddress, { expires: 365 });
  }

  /**
   * SAves the communication process.
   */
  saveCommunicationProcess(evt: MouseEvent | null, approval?: boolean) {
    if (evt !== null) {
      evt.preventDefault();
    }

    if (approval) {
      this.showSaveCpConfirm = false;

      if (!this._validateParticipantChoice())
        return;

      if (this._hasRewardEditor && !this._validateReward())
        return;

      if (this._hasEmailModule && !this._validateEmail(false))
        return;

      if (this._hastStageEditor && !this._validateThemeStage())
        return;
    }

    if (!this.title) {
      const dateText = moment().local().format('DD.MM.YYYY HH:mm');
      this.cp.saveStep('titel', this.ls.locs['loc'].BenachrichtigungVom + ' ' + dateText);
    } else {
      this.cp.saveStep('titel', this.title);
    }

    if (approval)
      this.cp.save(true, this._saveCallback.bind(this));
    else
      this.cp.save(false);
  }

  /**
   * Callback function for the save function
   * of the communication process.
   */
  private _saveCallback(): void {
    this.cp.locationChangeExpected = true;
    this.cp.clear();
    this._router.navigateByUrl(`/${this.role}/KommunikationsVerwaltung`);
  }

  /**
   * Sends the test mail.
   */
  private _sendTestEMail(): void {
    if (this._validateEmail(true)) {
      this._mpMessaging.openConfirm({
        text: this.ls.locs['loc'].EMailModul_FrageTestEMailSenden,
        submitText: this.ls.locs['loc'].Ja,
        cancelText: this.ls.locs['loc'].Nein
      }, () => {
        const data: any = this.cp.loadStep('e-mail-modul');
        data['to'] = this.testMailData['Address'];
        data['Freigegeben'] = true;

        this._sendTestMailSubscription = this._apiService.postRequest('/api/EMailModul/sendTestEMail', data).subscribe((data: any) => {
          this._mpMessaging.openPanelFromResultResponse(data);
          this.showTestMailModal = false;
        });
        
        this._mpMessaging.closeOverlay();
      }, () => {
        this._mpMessaging.closeOverlay();
      });
    }
  }

  /**
   * Triggers the (approved) save process
   * of the communication process.
   */
  private _saveCommunicationProcessApproved(): void {
    this.saveCommunicationProcess(null, true);
  }

  /**
   * Goes to the previous step of the
   * communication process.
   */
  goToPreviousStep(evt: MouseEvent): void {
    evt.preventDefault();
    this.cp.previous();
  }

}
