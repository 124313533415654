<div class="position-container search-input">
  <form action="."
        (ngSubmit)="$event.preventDefault(); executeAction()">
    <mp-core-input label="{{ ls.locs['loc'].Suchen }}"
                   mpType="search"
                   mpId="{{mpId}}"
                   [model]="model"
                   (modelChange)="modelChanged($event)"
                   [mpRequired]="true"
                   [mpFocus]="focusOn"
                   mpCoreMpFocus
                   mpPlaceholder="{{mpPlaceholder}}"
                   [noTrim]="noTrim">
    </mp-core-input>
    <span (click)="executeAction()" class="transclude">
      <ng-content></ng-content>
    </span>
  </form>
</div>
