import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { ApiService } from '@core/services/api.service';
import { InitialLoginService } from '@core/services/initial-login.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

/**
 * This class provides the functions and
 * data for the step "participant advertise
 * participant" of the first login module.
 */
@Component({
  selector: 'mp-leas-first-login-prtcpnt-advertise-prtcpnt',
  templateUrl: './prtcpnt-advertise-prtcpnt.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PrtcpntAdvertisePrtcpntComponent implements OnInit, OnDestroy {

  public adWays: Array<any> = [];
  public adWay: string = '';
  public user: string = '';

  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _savePrtcpntAdvertisePrtcpntSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _initialLogonService: InitialLoginService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Subscribes to the validate step
   * and next step observabbles
   * of the InitialLoginService. Gets
   * the locs, and sets the adWays.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._setAdWays();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._setAdWays();
        }
      });

      this.ls.getLocalization();
    }

    this._validateStepSubscription = this._initialLogonService.validateStep(5).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLogonService.validateCalled.next(false);
        const isValid = this._validatePrtcpntAdvertisePrtcpnt();
        this._initialLogonService.validBool = isValid;
        this._initialLogonService.valid.next(isValid);
      }
    });

    this._nextStepSubscription = this._initialLogonService.nextCalledStep(5).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this._initialLogonService.validBool) {
          this._savePrtcpntAdvertisePrtcpntSubscription = this._apiService.postRequest('/api/TnWerbenTn/set', {
            Entscheidung: this.adWay,
            Werber: this.user
          }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this._initialLogonService.nextSuccess();
            } else {
              this._mpMessaging.openWarningPanel(data.Message);
              this._initialLogonService.nextFailure();
            }
          },
          (error: any) => {
            this._mpMessaging.openWarningPanel(error.Message);
            this._initialLogonService.nextFailure();
          });
        }
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._validateStepSubscription !== 'undefined') {
      this._validateStepSubscription.unsubscribe();
    }

    if (typeof this._nextStepSubscription !== 'undefined') {
      this._nextStepSubscription.unsubscribe();
    }

    if (typeof this._savePrtcpntAdvertisePrtcpntSubscription !== 'undefined') {
      this._savePrtcpntAdvertisePrtcpntSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the ad ways.
   */
  private _setAdWays(): void {
    this.adWays = [
      { text: this.ls.locs['locLeas'].VerkaeuferAusMeinemAutohaus, value: 'a' },
      { text: this.ls.locs['locLeas'].VerkaeuferAusEinemAnderenAutohaus, value: 'b' },
      { text: this.ls.locs['locLeas'].EMailNewsletterVonLeasePlanGo, value: 'c' }
    ];
  }

  /**
   * Validates the 'participant advertise
   * participant' step of the first login.
   */
  private _validatePrtcpntAdvertisePrtcpnt(): boolean {
    let errorAdway = false;
    let errorUser = false;

    if (!this.adWay || this.adWay === '') {
      errorAdway = true;
    }

    if (this.adWay === 'a' || this.adWay === 'b' || this.adWay === 'c') {
      if (!this.user || this.user === '') {
        errorUser = true;
      }
    }

    return !errorAdway && !errorUser;
  }

}
