<div class="magma-input flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8 {{errClass}}">
  <label class="flex-col-12 font-weight-700 font-size-xxs padding-left-right-0 display-block-important"
         for="{{mpId}}">
    <span class="display-inline font-weight-700 font-size-xxs"
          [ngClass]="((!mpRequired && mpDisabled != true) || (mpRequired == false && mpDisabled != true) || (!mpRequired && !mpDisabled) || (mpRequired == false && !mpDisabled)) && label ? 'margin-right-4' : ''">{{label}}</span>
    <span *ngIf="((!mpRequired && mpDisabled != true) || (mpRequired == false && mpDisabled != true) || (!mpRequired && !mpDisabled) || (mpRequired == false && !mpDisabled)) && labelText && (ls.locsLoaded | async)"
          class="font-size-xxs text-color-grey-info display-inline">
      {{ ls.locs['loc'].OptionalField }}
    </span>
  </label>
  <input class="flex-col-12 font-size-general padding-left-right-8"
         type="{{mpType}}"
         [ngModel]="model"
         (ngModelChange)="model = $event; modelChanged()"
         (blur)="executeEvent(inputBlur)"
         id="{{mpId}}"
         (keyup)="executeKeyup($event)"
         [required]="mpRequired"
         [disabled]="mpDisabled"
         [mpFocus]="focusOn"
         mpCoreMpFocus
         (focus)="executeEvent(inputFocus)"
         (change)="executeEvent(inputChange)"
         mask="{{mask}}"
         placeholder="{{mpPlaceholder}}">
</div>
