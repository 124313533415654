<div [ngClass]="{'invalid-error': hasError, 'mp-disabled': mpDisabled}" [attr.data-pre-text]="dataPreText !== '' ? dataPreText : null" class="magma-input flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8">
  <label class="flex-col-12 font-weight-700 font-size-xxs padding-left-right-0 display-block-important"
         for="{{mpIdHash}}">
    <span class="display-inline font-weight-700 font-size-xxs"
          [ngClass]="(!mpRequired && !mpDisabled) && labelText ? 'margin-right-4' : ''">
      {{labelText}}
    </span>
    <span *ngIf="(!mpRequired && !mpDisabled) && labelText && (ls.locsLoaded | async)"
          class="font-size-xxs text-color-grey-info display-inline">
      {{ ls.locs['loc'].OptionalField }}
    </span>
    <sup class="margin-left-4 text-color-grey-info"
         *ngIf="mpSup && labelText">
      {{mpSup}}
    </sup>
  </label>
  <input class="flex-col-12 font-size-general padding-left-right-8"
         type="{{mpType === '' ? 'text' : mpType}}"
         [(ngModel)]="model"
         (ngModelChange)="modelChange.emit($event)"
         id="{{mpIdHash}}"
         maxlength="{{(maxLength && maxLength > 0) ? maxLength : ''}}"
         (keyup)="executeKeyup($event)"
         [ngClass]="{active: (model !== undefined && model !== null && model !== '') || mpDisabled}"
         [required]="mpRequired"
         [disabled]="mpDisabled"
         min="{{mpMin && mpMin > 0 ? mpMin : -999999}}"
         placeholder="{{mpPlaceholder}}"
         (focus)="executeEvent($event, 'focus')"
         (change)="executeEvent($event, 'change')"
         (blur)="executeEvent($event, 'blur')"
         mpCoreMpFocus
         [mpFocus]="focusOn"
         [attr.size]="mpSize ? mpSize : null">
  <span [hidden]="!maxLength || maxLength && maxLength <= 0"
        class="max-length-counter"
        [ngClass]="{'text-danger': (model !== undefined && model !== null && model !== '') && model.length >= maxLength}">
    <span [textContent]="(model !== undefined && model !== null && model !== '') ? (maxLength - model.length) : maxLength"></span>
  </span>
</div>

<!--ng-trim="{{!noTrim}}" => @TODO: Clearify whether or not it is still needed. -->
