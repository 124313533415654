import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';
import { ParentTableRow, ParentTableRowIndex } from '@core/components/data-table/table-injectors';

/**
 * This class provides the data for the
 * ranking light statistic table child.
 */
@Component({
  selector: 'mp-gfc-ranking-light-statistic-child',
  templateUrl: './ranking-light-statistic-child.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RankingLightStatisticChildComponent implements OnInit {

  public loaderName = '';

  private _rankingLightStatisticChildLoader: MpLoader = {
    name: 'rankingLightStatisticChildLoader',
    isFirst: false,
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  /**
   * Sets the loader for the table data,
   * and gets the key from the parent data
   * table.
   */
  ngOnInit(): void {
    this._rankingLightStatisticChildLoader.name = `${this._rankingLightStatisticChildLoader.name}--${this._parentTableRowIndex}`;
    this._mpLoaderService.registerLoader(this._rankingLightStatisticChildLoader);
    let params = {};

    if (this._parentTableRow !== null) {
      params = {
        // @ts-ignore
        TnId: this._parentTableRow['TnId']
      };
    }

    this._mpLoaderService.extendLoader(this._rankingLightStatisticChildLoader.name, {
      isReady: true,
      params: params
    });

    this.loaderName = this._rankingLightStatisticChildLoader.name;
    const loader = this._mpLoaderService.getLoader(this._rankingLightStatisticChildLoader.name);

    setTimeout(() => {
      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    }, 400);
  }

}
