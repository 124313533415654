import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { TipAndWinService } from './../../services/tip-and-win.service';

/**
 * This class provides the main functions
 * for the tips.
 */
@Component({
  selector: 'mp-taw-tip-and-win-tips',
  templateUrl: './tip-and-win-tips.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinTipsComponent implements OnInit {

  public tabVisible: string = '';
  public showTipps: boolean = false;
  public showTippsCommingSoon: boolean = false;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public tipAndWinService: TipAndWinService
  ) { }

  ngOnInit(): void {
    var hideSpieltippsBis = new Date(this.mpSettings.settings['TipAndWinSettings'].HideSpieltippsBis);

    this.showTipps = !this.mpSettings.settings['TipAndWinSettings'].HideSpieltipps ||
      hideSpieltippsBis < new Date();

    this.showTippsCommingSoon = this.mpSettings.settings['TipAndWinSettings'].HideSpieltipps &&
        hideSpieltippsBis > new Date() &&
        hideSpieltippsBis < new Date('2050-12-31');

    this.tabVisible = this.showTipps || this.showTippsCommingSoon
      ? 'tagestipps'
      : !this.mpSettings.settings['TipAndWinSettings'].HideTaeglichesQuiz
      ? 'taeglichesQuiz'
      : !this.mpSettings.settings['TipAndWinSettings'].HideZusatzfragen
      ? 'zusatzfragen'
      : !this.mpSettings.settings['TipAndWinSettings'].HidePerformance
      ? 'performance'
      : 'tagestipps';

  } 

  /**
   * Sets the active tab.
   */
  setTab(tab: string): void {
    this.tabVisible = tab;

    if (tab === 'taeglichesQuiz') {
      this.tipAndWinService.setTabHasFocus(tab, true);
    }
  }

}
