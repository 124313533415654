import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the participant  
 * evaluation table.
 */
@Component({
  selector: 'mp-leas-participant-evaluation',
  templateUrl: './participant-evaluation.component.html'
})
export class ParticipantEvaluationComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Teilnehmer/Teilnehmerauswertung2Excel';
  public loader: MpLoader | null = null;
  public filter: string = '-1';
  public filterList: Array<any> = [];
  public brand: string = '';
  public brandList: Array<any> = [];
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _leasParticpantEvaluationLoader: MpLoader = {
    name: 'leasParticpantEvaluationLoader',
    refillExtParamsFunc: (params: any) => {
      if (params['FilterId'] && this.filterList.length > 0) {
        this.filter = this.filterList.find((filter: { [key: string]: any }) => {
          return filter['Value'] === params['FilterId'];
        });
      }

      if (params['Marke']) {
        this.brand = params['Marke'];
      }
    },
    params: {
      FilterId: this.filter,
      Marke: this.brand
    }
  };
  private _getFilterListSubscription: Subscription | undefined;
  private _getBrandListSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader and gets the
   * locs.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');
    this._mpLoaderService.registerLoader(this._leasParticpantEvaluationLoader);

    this._mpLoaderService.extendLoader('leasParticpantEvaluationLoader', {
      isReady: true
    });

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._getFilterLists();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._getFilterLists();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getFilterListSubscription !== 'undefined') {
      this._getFilterListSubscription.unsubscribe();
    }

    if (typeof this._getBrandListSubscription !== 'undefined') {
      this._getBrandListSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the filters and the brands.
   */
  private _getFilterLists(): void {
    this._getFilterListSubscription = this._apiService.getRequest('/api/Teilnehmer/getFilterForTeilnehmerauswertung').subscribe((data: any) => {
      this.filterList = data.Records;
      this.filterList.unshift({
        Text: this.ls.locs['loc'].WaehleEinenFilter,
        Value: '-1'
      });
    });

    this._getBrandListSubscription = this._apiService.getRequest('/api/Teilnehmer/getMarken').subscribe((data: any) => {
      this.brandList = data.Records;
      this.brandList.unshift({
        Text: this.ls.locs['loc'].WaehleEinenFilter,
        Value: ''
      });
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasParticpantEvaluationLoader');
  }

  /**
   * Triggerst the reload of the date
   * for the selected filter.
   */
  changeFilter(): void {
    if (this.loader !== null) {
      this.loader.params['FilterId'] = this.filter;

      if (this.filter !== '-1') {
        this.brand = '';
        this.loader.params['Marke'] = this.brand;
      }

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Triggerst the reload of the date
   * for the selected brand.
   */
  changeBrand(): void {
    if (this.loader !== null) {
      this.loader.params['Marke'] = this.brand;

      if (this.brand !== '') {
        this.filter = '-1';
        this.loader.params['FilterId'] = this.filter;
      }

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

}
