<mp-core-search [action]="setSearchFilterFunc"
                mpId="mpId"
                (keyup)="getSearchSuggests()"
                [(model)]="data.searchTerm"
                [mpPlaceholder]="ls.locs['loc'].Suchen"
                [noTrim]="true">
  <i class="position-absolute-top-right svg-icon __clr-default __size-24 __top-8 margin-right-12 __search"></i>
</mp-core-search>
<div class="overlaySuggest" (clickOutside)="clearSearchSuggests()" *ngIf="showSuggests">
  <div class="scrolling-container">
    <ul>
      <li *ngFor="let suggest of searchTextSuggests">
        <a href=""
           mpCoreHighlight
           #highlightDirective="mpCoreHighlight"
           highlight="{{ data.searchTerm }}"
           originalText="{{ suggest['ArtBez'] + (suggest['HerstellerName']? ' - '+suggest['HerstellerName'] : '') }}"
           [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : suggest['ArtBez'] + (suggest['HerstellerName']? ' - '+suggest['HerstellerName'] : '')"
           (click)="setSearchSuggest(suggest.ArtBez, $event)">
        </a>
      </li>
    </ul>
  </div>
</div>
