import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ApiService } from './../../../services/api.service';
import { MpLocalizationService } from './../../../services/mp-localization.service';

/**
 * This class provides data and
 * functions for the main date-range-picker
 * component.
 */
@Component({
  selector: 'mp-core-date-range-picker-with-presets',
  templateUrl: './date-range-picker-with-presets.component.html',
  styleUrls: ['./date-range-picker-with-presets.component.scss']
})
export class DateRangePickerWithPresetsComponent implements OnInit{

  @Input() public von?: Date;
  @Input() public bis?: Date;
  @Input() public minDate?: Date;
  @Input() public maxDate?: Date;
  @Input() public model: string = '';

  @Output() vonChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() bisChange: EventEmitter<Date> = new EventEmitter<Date>();

  public presetList: Array<any>[] = [];
  public selectedPreset: any;
  private vonParam: any;
  private bisParam: any;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
  ) { }

  /**
   * Calls refresh function.
   */
  ngOnInit(): void {
    this.presetList = [];

    // save initial values of 'von' and 'bis'
    this.vonParam = this.von;
    this.bisParam = this.bis;

    this.refresh(true);
  }

  /**
  * Restores initial value of 'von' (if set)
  */
  restoreVon() {
    if (typeof this.vonParam !== 'undefined' && this.vonParam !== null) {
      var val =  new Date(this.vonParam); 
      this.vonParam = null; // restore value only 1x
      this.von = val;
    }
  }

  /**
   * Restores initial value of 'bis' (if set)
   */
  restoreBis() {
    if (typeof this.bisParam !== 'undefined' && this.bisParam !== null) {
      var val =  new Date(this.bisParam); 
      this.bisParam = null; // restore value only 1x
      this.bis = val;
    }
  }

  /**
  * Passes start date and end date to parent component.
  */
  onSelectedPresetChange() {
    this.von = new Date(this.selectedPreset.Von);
    this.bis = new Date(this.selectedPreset.Bis);
    this.vonChange.emit(this.von);
    this.bisChange.emit(this.bis);
  }

  /**
  * Passes start date to parent component.
  */
  vonChanged(): void {
    this.restoreVon();
    this.vonChange.emit(this.von);
    this.selectedPreset = "";
  }

  /**
  * Passes end date to parent component.
  */
  bisChanged(): void {
    this.restoreBis();
    this.bisChange.emit(this.bis);
    this.selectedPreset = "";
  }

  /**
   * Loads the fillters of dates for presets.
   */
  refresh(force?: boolean) {
    this.presetList = [];
    if (force || !this.presetList) {
      
      this._apiService.getRequest('/api/Config/getDateFilterPresets').subscribe((data: any) => {
        if (data.Records.length > 0)
          this.presetList = data.Records;
          //this.selectedPreset = this.presetList[0];
        },
        (error: any) => {
          console.log(error.message);
        });
    }
  }
}

