import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';
import { ParentTableRow, ParentTableRowIndex } from '@core/components/data-table/table-injectors';

/**
 * This class provides the data for the
 * tip and win game statistic table child.
 */
@Component({
  selector: 'mp-taw-tip-and-win-game-statistic-child',
  templateUrl: './tip-and-win-game-statistic-child.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinGameStatisticChildComponent implements OnInit {

  public loaderName = '';

  private _tipAndWinGameStatisticChildLoader: MpLoader = {
    name: 'tipAndWinGameStatisticChildLoader',
    isFirst: false,
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _parentTableRow: ParentTableRow,
    private _parentTableRowIndex: ParentTableRowIndex
  ) { }

  /**
   * Sets the loader for the table data,
   * and gets the key from the parent data
   * table.
   */
  ngOnInit(): void {
    this._tipAndWinGameStatisticChildLoader.name = `${this._tipAndWinGameStatisticChildLoader.name}--${this._parentTableRowIndex}`;
    this._mpLoaderService.registerLoader(this._tipAndWinGameStatisticChildLoader);
    let params = {};

    if (this._parentTableRow !== null) {
      params = {
        // @ts-ignore
        AktionId: this._parentTableRow['AktionId'],
        // @ts-ignore
        TnId: this._parentTableRow['TnId'],
      };
    }

    this._mpLoaderService.extendLoader(this._tipAndWinGameStatisticChildLoader.name, {
      isReady: true,
      params: params
    });

    this.loaderName = this._tipAndWinGameStatisticChildLoader.name;
    const loader = this._mpLoaderService.getLoader(this._tipAndWinGameStatisticChildLoader.name);

    setTimeout(() => {
      if (typeof loader.load !== 'undefined') {
        loader.load();
      }
    }, 400);
  }

}
