import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ManagerComponent } from '@core/modules/manager/manager.component';
import { AdminComponent } from '@core/modules/admin/admin.component';
import { ParticipantComponent } from '@core/modules/participant/participant.component';

import { AdditionalPaymentOverviewComponent } from './modules/manager/pages/additional-payment-overview/additional-payment-overview.component';
import { AdditionalPaymentComponent } from './modules/participant/pages/additional-payment/additional-payment.component';

import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Zuzahlungsuebersicht',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: AdditionalPaymentOverviewComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Zuzahlungsuebersicht',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: AdditionalPaymentOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Zuzahlung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: AdditionalPaymentComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class ZzRoutingModule { }
