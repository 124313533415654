import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../services/mp-localization.service';
import { MpCommunicationProcessService } from './../../services/mp-communication-process.service';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

/**
 * This class provides the functionalities
 * of the communicaion process stepper.
 */
@Component({
  selector: 'mp-core-communication-process-stepper',
  templateUrl: './communication-process-stepper.component.html',
  styleUrls: ['./communication-process-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommunicationProcessStepperComponent implements OnInit, OnDestroy {

  @ViewChild('communicationStepper') communicationStepper: ElementRef<any> | undefined;

  public steps: Array<any> = [];
  public cpHideClearConfirm = this._cpHideClearConfirm.bind(this);
  public cpHideResetConfirm = this._cpHideResetConfirm.bind(this);
  public cpSkipStep = this._cpSkipStep.bind(this);
  public cpConfirmStep = this._cpConfirmStep.bind(this);
  public cpReset = this._cpReset.bind(this);
  public cpClear = this._cpClear.bind(this);

  private _finishRenderingFunc = this.finishedRendering.bind(this);
  private _clearConfirmedOnRouteChange: boolean = false;
  private _watchStepsSubscription: Subscription | undefined;
  private _locationChangeSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public cp: MpCommunicationProcessService,
    private _router: Router
  ) { }

  /**
   * Sets watchers for step, viewport,
   * and location changes.
   */
  ngOnInit(): void {
    if (this.cp.locationChangeExpected) {
      this.cp.locationChangeExpected = false;
    }

    this._watchStepsSubscription = this.cp.stepsObserver.subscribe((steps: { [key: string]: any }) => {
      this.steps = [];

      Object.keys(steps).forEach((key: string) => {
        if (!steps[key].Hide) {
          this.steps.push(steps[key]);
        }
      });

      this._locationChangeSubscription = this._router.events.subscribe((event: Event) => {
        this._handleLocationChange(event);
      });
    });

    window.removeEventListener('resize', this._finishRenderingFunc);
    window.addEventListener('resize', this._finishRenderingFunc);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._watchStepsSubscription !== 'undefined') {
      this._watchStepsSubscription.unsubscribe();
      this._clearConfirmedOnRouteChange = true;
    }

    if (typeof this._locationChangeSubscription !== 'undefined') {
      this._locationChangeSubscription.unsubscribe();
    }
  }

  /**
   * Shows a confirm dialog of the browser,
   * if the location change was not expected.
   */
  private _handleLocationChange(event?: Event): void {
    if (typeof event !== 'undefined' && event instanceof NavigationStart) {

      //if (this.cp.locationChangeExpected) {
      //  this.cp.locationChangeExpected = false;
      //}
      let next = event.url;
      const requestUrl = next.substring(next.lastIndexOf('/') + 1);

      const isCommunicationProcess = typeof Object.keys(this.cp.steps).find((key: any) => {
        return this.cp.steps[key].Url.split('?')[0] === requestUrl.split('?')[0];
      }) !== 'undefined';

      if (!isCommunicationProcess && !this._clearConfirmedOnRouteChange && !this.cp.locationChangeExpected) {
        if (!confirm(this.ls.locs['loc'].ConfirmPageLeave)) {
          this._clearConfirmedOnRouteChange = true;
          this._router.navigateByUrl(this._router.url);
        } else {
          this._clearConfirmedOnRouteChange = true;
          this.cp.clear(null, requestUrl);
        }
      }
    }

    //if (typeof event !== 'undefined' && event instanceof NavigationEnd) {
    //  this._clearConfirmedOnRouteChange = false;

    //  if (this.cp.locationChangeExpected) {
    //    this.cp.locationChangeExpected = false;
    //  }
    //}
  }

  /**
   * Sets / removes some styles for the
   * process steps within the communication
   * process stepper.
   */
  finishedRendering(): void {
    if (typeof this.communicationStepper !== 'undefined') {
      // @ts-ignore
      const steps = this.communicationStepper.nativeElement.querySelectorAll('.single-step');

      if (steps.length > 0) {
        const firstStep = steps[0];

        steps.forEach((step: any, index: number) => {
          var firstStepTopPos = firstStep.getBoundingClientRect().top;
          var stepTopPos = step.getBoundingClientRect().top;
          step.style.zIndex = '';

          if (window.innerWidth > 767) {
            if (stepTopPos > firstStepTopPos) {
              for (let indexCnt = index; indexCnt >= 3; indexCnt--) {
                if (steps[indexCnt].classList.contains('minimized-step') === false) {
                  steps[indexCnt].classList.add('minimized-step');
                }
              }

              for (let indexCnt = 0; indexCnt < 2; indexCnt++) {
                if (steps[indexCnt].classList.contains('minimized-prev-step') === false) {
                  steps[indexCnt].classList.add('minimized-prev-step');
                }
              }
            }

            step.style.zIndex = steps.length - index;
          }

          if (step.classList.contains('steps-rendered') === false) {
            step.classList.add('steps-rendered');
          }
        });
      }
    }
  }

  /**
   * Triggers the hideClearConfirm function of
   * the communication process.
   */
  private _cpHideClearConfirm(): void {
    this.cp.hideClearConfirm();
  }

  /**
   * Triggers the hideResetConfirm function of
   * the communication process.
   */
  private _cpHideResetConfirm(): void {
    this.cp.hideResetConfirm();
  }

  /**
   * Triggers the skipStep function of
   * the communication process.
   */
  private _cpSkipStep(key: string): void {
    this.cp.skipStep(key);
  }

  /**
   * Triggers the confirmStep function of
   * the communication process.
   */
  private _cpConfirmStep(key: string): void {
    this.cp.confirmStep(key);
  }

  /**
   * Triggers the reset function of
   * the communication process.
   */
  private _cpReset(): void {
    this.cp.reset();
  }

  /**
   * Triggers the clear function of
   * the communication process.
   */
  private _cpClear(): void {
    this.cp.clear();
  }

}
