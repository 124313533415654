import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';

/**
 * This class provides the functionalities
 * for the birthday sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-calendar-birthday',
  templateUrl: './sidebar-calendar-birthday.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarCalendarBirthdayComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public yearsRange: Array<any> = [];
  public minDate: moment.Moment = moment([moment().year(), 0, 1]);
  public maxDate: moment.Moment = moment([moment().year(), 11, 31, 23, 59]);

  private _now = moment();
  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
  ) { }

  /**
   * Gets the sidebar params, and sets
   * the year range for the birthday year
   * select.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.yearsRange = this.mpSidebar.params['IsKind'] ? Array.from({ length: 20 }, (_, i) => i + (this._now.year() - 18)).reverse() : Array.from({ length: 85 }, (_, i) => i + (this._now.year() - 100)).reverse();

      this.selection = Object.assign(this.selection, {
        Geburtsjahr: this.mpSidebar.params['Geburtsdatum'] ? this.mpSidebar.params['Geburtsdatum'].year() : this._now.year() - (this.mpSidebar.params['Alter'] || this.mpSidebar.params['Alter'] === 0 ? this.mpSidebar.params['Alter'] : 30),
        Geburtsdatum: this.mpSidebar.params['Geburtsdatum'] ? this.mpSidebar.params['Geburtsdatum'] : this._now
      });

      this.changeBirthYear();
      this.changeBirthday();
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Triggered, when the user changes the
   * year of the birthday.
   */
  changeBirthYear(): void {
    this.minDate = moment([this.selection['Geburtsjahr'], 0, 1]);
    this.maxDate = moment([this.selection['Geburtsjahr'], 11, 31, 23, 59]);
  }

  /**
   * Triggered, when the user changes the
   * birthday.
   */
  changeBirthday(): void {
    this.selection['Alter'] = this._now.diff(this.selection['Geburtsdatum'], 'year');
  }

  /**
   * Saves the selected birthday.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpSidebar.params['Geburtsdatum'] = this.selection['Geburtsdatum'];
    this.mpSidebar.params['Alter'] = this.selection['Alter'];
    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    this.mpSidebar.close();
  }

}
