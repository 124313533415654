import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpCommunicationProcessService } from './../../../../services/mp-communication-process.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';
import { ApiService } from './../../../../services/api.service';
import { AuthService } from './../../../../services/auth.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';
import { FroalaConfigService } from './../../../../services/froala-config.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';
import { MpDebounceService } from '../../../../services/mp-debounce.service';
import { CookieService } from '../../../../services/cookie.service';

/**
 * This class provides the functions for
 * the email editor.
 */
@Component({
  selector: 'mp-core-email-module',
  templateUrl: './email-module.component.html',
  styleUrls: ['./email-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailModuleComponent implements OnInit, OnDestroy {

  @ViewChildren('fileUpload') fileUpload: any;

  public communicationProcess: boolean = false;
  public mailBody: string = '';
  public role: string = '';
  public isAdmin: boolean = false;
  public showNewsflash: boolean = false;
  public confirmSave: boolean = false;
  public file: File | undefined;
  public selectedTBS: { [key: string]: any } | null = null;
  public emailModuleEditorLoader: MpLoader = {
    name: 'emailModuleEditorLoader',
    params: {}
  };
  public executionDate: { [key: string]: any } = {};
  public communicationTypeParams: { [key: string]: any } = {};
  public countryParams: { [key: string]: any } = {};
  public languageParams: { [key: string]: any } = {};
  public attachments: Array<any> = [];
  public inputData: { [key: string]: any } = {
    subject: '',
    mailBody: '',
    preheader: '',
    isNewsletter: false,
    approved: false,
    showCountryLanguage: false
  };
  public recipientChoice: string = 'Filter';
  public recipientChoiceList: Array<any> = [];
  public chosenRecipients: Array<any> = [];
  public tbsList: Array<any> = [];
  public filterList: Array<any> = [];
  public emailModulTemplates: Array<any> = [];
  public emailTypeId: number = 0;
  public selectedFilter: { [key: string]: any } = {};
  public sendWithDelay: boolean = false;
  public froalaOptions: { [key: string]: any } = {};
  public froalaOptionsLoaded: boolean = false;
  public emailTypes: Array<any> = [];
  public loadedEMail: { [key: string]: any } = {};
  public testInboxData: { [key: string]: any } = {
    Benutzer: ''
  };
  public testSmsData: { [key: string]: any } = {
    Benutzer: ''
  };
  public errorTestInboxMessage: { [key: string]: any } = {
    hasError: false,
    errMessage: ''
  };
  public errorTestSmsMessage: { [key: string]: any } = {
    hasError: false,
    errMessage: ''
  };
  public execChangeFunctions: Array<any> = [
    {
      backendName: 'toggleAuswahl',
      func: this.toggleChoice.bind(this)
    }
  ];
  public confirmDelete: boolean = false;
  public errorRecipient: boolean = false;
  public errorTestRecipient: boolean = false;
  public errorSubject: boolean = false;
  public errorBody: boolean = false;
  public errorDate: boolean = false;
  public hideExecutionDate: boolean = false;
  public testMailAddress: string = '';
  public confirmOpenNew: boolean = false;
  public confirmSend: boolean = false;
  public confirmRemoveApproval: boolean = false;
  public dateFixedByCp: boolean = false;
  public toggleShowNewsflash = this._toggleShowNewsflash.bind(this);
  public loadStep = this._loadStep.bind(this);
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public deleteEmail = this._deleteEmail.bind(this);
  public toggleDeleteEmail = this._toggleDeleteEmail.bind(this);
  public toggleSaveEmail = this._toggleSaveEmail.bind(this);
  public toggleOpenNew = this._toggleOpenNew.bind(this);
  public toggleSendEmail = this._toggleSendEmail.bind(this);
  public toggleRemoveApproval = this._toggleRemoveApproval.bind(this);
  public removeApproval = this._removeApproval.bind(this);
  public openNew = this._openNew.bind(this);
  public saveEmail = this._saveEmail.bind(this);
  public sendEMail = this._sendEMail.bind(this);

  private _token: string | null = sessionStorage.getItem('token');
  private _cpKey: string = 'e-mail-modul';
  private _attachmentsServerSide: Array<any> = [];
  private _selectedCountryLanguage: { [key: string]: any } = {};
  private _selectedCountries: Array<any> = [];
  private _selectedLanguages: Array<any> = [];
  private _maxUploadFileLength: number = 0;
  private _loaded: boolean = false;
  private _language: string = 'de';
  private _froalaEditor: any;
  private _testInboxAddress: string = '';
  private _testSmsAddress: string = '';
  private _showCpSkipStepConfirm: boolean = false;
  private _getPermissionsSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;
  private _getSavedEmailSubscription: Subscription | undefined;
  private _queryParamsSubscription: Subscription | undefined;
  private _getEmailModuleDataSubscription: Subscription | undefined;
  private _getSettingsSubscription: Subscription | undefined;
  private _getLanguageSubscription: Subscription | undefined;
  private _getFroalaOptionsSubscription: Subscription | undefined;
  private _loadChosenRecipientsSubscription: Subscription | undefined;
  private _deleteSavedMailSubscription: Subscription | undefined;
  private _removeApprovalSubsription: Subscription | undefined;
  private _setSavedMailSubscription: Subscription | undefined;
  private _sendTestMailSubscription: Subscription | undefined;
  private _sendTestInboxMailSubscription: Subscription | undefined;
  private _fileUploadSubscription: Subscription | undefined;
  private _queryParamsCPSubscription: Subscription | undefined;
  private _cpStepsSubscription: Subscription | undefined;
  private _sendTestSmsSubscription: Subscription | undefined;
  private _editor: any;
  private _loadOptions: Function | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _titleService: MpTitleService,
    private _menuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    public cp: MpCommunicationProcessService,
    private _mpPermissions: MpPermissionsService,
    private _apiService: ApiService,
    private _authService: AuthService,
    private _mpLoader: MpLoaderService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mpCoreService: MpCoreService,
    private _roleMapping: RoleMappingService,
    private _froalaConfig: FroalaConfigService,
    private _mpDebounce: MpDebounceService,
    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {
    const role = this._authService.getRole();
    this._testInboxAddress = this._cookieService.getCookieValue('TestPostfach');
    this._testSmsAddress = this._cookieService.getCookieValue('TestSms');

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._titleService.setTitleFromLoc('EMailModul');
    this._menuHighlight.setMenuHighlight('kommunikation');

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.isAdmin = this._mpPermissions.permissions.IsAdmin;
      }
    });

    this._mpPermissions.getPermissions();
    this._mpLoader.registerLoader(this.emailModuleEditorLoader);

    this._mpLoader.extendLoader('emailModuleEditorLoader', {
      isReady: true
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this.reset();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.reset();
        }
      });

      this.ls.getLocalization();
    }

    if (Object.keys(this.mpSettings.settings).length > 0) {
      this._maxUploadFileLength = this.mpSettings.settings['NetcentiveCoreSettings'].MaxUploadFileLength;
    } else {
      this._getSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._maxUploadFileLength = this.mpSettings.settings['NetcentiveCoreSettings'].MaxUploadFileLength;
        }
      });

      this.mpSettings.getSettings();
    }

    this._getLanguageSubscription = this._apiService.getRequest('/api/Language/getLanguage').subscribe((data: any) => {
      this._language = data.Records[0];
      this._sidebarSave();
    });

    this._queryParamsCPSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['p'] !== 'undefined') {
        this.communicationProcess = true;
        this.dateFixedByCp = false;
        const rewardData = this.cp.loadStep('belohnungs-editor');

        if (rewardData && rewardData.BelohnungsartObject && rewardData.BelohnungsartObject.FixedDateForEmail) {
          this.dateFixedByCp = true;
        }

        this.cp.isValidStep(this._cpKey);

        this._cpStepsSubscription = this.cp.stepsObserver.subscribe((steps: { [key: string]: any }) => {
          if (Object.keys(this.cp.steps).length > 0) {
            this.cp.steps[this._cpKey].Save = this.saveStep.bind(this);
          }
        });
      }
    });

    this._loadOptions = this._mpDebounce.debounce(() => {
      this._getFroalaOptionsSubscription = this._apiService.getRequest(`/api/Froala/GetFroalaConfig?module=${this.communicationTypeParams['selectedList'][0].Identifier}`).subscribe((data: any) => {
        const requestVerificationTokenInput: HTMLInputElement | null = document.querySelector('input[name="__RequestVerificationToken"]');
        const froalaConfig = data.Records[0];
        const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

        this.froalaOptions = Object.assign(this._froalaConfig.getConfig(), {
          language: this._language,
          toolbarButtons: froalaConfig['ToolbarButtons'],
          colorsBackground: froalaConfig['BackgroundColours'],
          colorsStep: froalaConfig['ColourColumnCount'],
          colorsText: froalaConfig['TextColours'],
          charCounterMax: froalaConfig['CharacterCount'],
          height: froalaConfig['Height'],
          heightMin: froalaConfig['HeightMin'],
          heightMax: froalaConfig['HeightMax'],
          requestHeaders: {
            'X-XSRF-TOKEN': xsrfToken || '',
            'Authorization': 'Bearer ' + this._token
          },
          events: Object.assign(this._froalaConfig.getEvents(), {
            'blur': (editor: any) => {
              //this._editor.selection.save();

              const currentTarget = editor.currentTarget;

              if (currentTarget.innerHTML === '<p><br></p>' || currentTarget.innerText === '') {
                currentTarget.innerHTML = '';
                this.inputData['mailBody'] = '';
              }

            },
            'initialized': () => {
              this._editor = this._froalaEditor.getEditor();
            }
          })
        });

        this.froalaOptionsLoaded = true;
        this._initFroala();
      });
      }, 200);
  }

  /**
  * Retrieves a cookie value by name
  **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getSavedEmailSubscription !== 'undefined') {
      this._getSavedEmailSubscription.unsubscribe();
    }

    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._getEmailModuleDataSubscription !== 'undefined') {
      this._getEmailModuleDataSubscription.unsubscribe();
    }

    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }

    if (typeof this._getLanguageSubscription !== 'undefined') {
      this._getLanguageSubscription.unsubscribe();
    }

    if (typeof this._getFroalaOptionsSubscription !== 'undefined') {
      this._getFroalaOptionsSubscription.unsubscribe();
    }

    if (typeof this._loadChosenRecipientsSubscription !== 'undefined') {
      this._loadChosenRecipientsSubscription.unsubscribe();
    }

    if (typeof this._deleteSavedMailSubscription !== 'undefined') {
      this._deleteSavedMailSubscription.unsubscribe();
    }

    if (typeof this._removeApprovalSubsription !== 'undefined') {
      this._removeApprovalSubsription.unsubscribe();
    }

    if (typeof this._setSavedMailSubscription !== 'undefined') {
      this._setSavedMailSubscription.unsubscribe();
    }

    if (typeof this._sendTestMailSubscription !== 'undefined') {
      this._sendTestMailSubscription.unsubscribe();
    }

    if (typeof this._sendTestInboxMailSubscription !== 'undefined') {
      this._sendTestInboxMailSubscription.unsubscribe();
    }

    if (typeof this._fileUploadSubscription !== 'undefined') {
      this._fileUploadSubscription.unsubscribe();
    }

    if (typeof this._queryParamsCPSubscription !== 'undefined') {
      this._queryParamsCPSubscription.unsubscribe();
    }

    if (typeof this._cpStepsSubscription !== 'undefined') {
      this._cpStepsSubscription.unsubscribe();
    }

    if (typeof this._sendTestSmsSubscription !== 'undefined') {
      this._sendTestSmsSubscription.unsubscribe();
    }
  }

  /**
   * Toggles the showNewsflash modal.
   */
  private _toggleShowNewsflash(): void {
    this.showNewsflash = !this.showNewsflash;
  }

  /**
   * Resets the email module.
   */
  reset(): void {
    this.executionDate = {
      Headline: this.ls.locs['loc'].VersendenAm,
      text: this.ls.locs['loc'].Sofort,
      noDateText: this.ls.locs['loc'].BitteWaehlen,
      date: moment.utc([1900, 0, 1]),
      initDate: moment(),
      maxYearDif: 0,
      minYearDif: -5,
      setPastAsSofort: true
    };

    this.communicationTypeParams = {
      selectedList: [],
      list: [],
      filtered: [],
      text: this.ls.locs['loc'].BitteWaehlen,
      defaultText: this.ls.locs['loc'].BitteWaehlen,
      searchTerm: '',
      title: this.ls.locs['loc'].NachrichtentypAuswaehlen,
      all: false,
      limit: 1,
      save: this._sidebarSave.bind(this)
    };

    this.attachments = [];
    this._attachmentsServerSide = [];
    this.inputData['subject'] = '';
    this.inputData['mailBody'] = '';
    this.inputData['preheader'] = '';
    this.recipientChoice = 'Filter';
    this._selectedCountryLanguage = {};
    this.chosenRecipients = [];
    this.inputData['isNewsletter'] = false;
    this.emailTypeId = 0;

    this.selectedFilter = {
      Value: '-1'
    };

    this._selectedCountries = [];

    this.countryParams = {
      selectedList: this._selectedCountries,
      list: [],
      filtered: [],
      text: this.ls.locs['loc'].Alle,
      searchTerm: '',
      title: this.ls.locs['loc'].LandWaehlen
    };

    this._selectedLanguages = [];

    this.languageParams = {
      selectedList: this._selectedLanguages,
      list: [],
      filtered: [],
      text: this.ls.locs['loc'].Alle,
      searchTerm: '',
      title: this.ls.locs['loc'].SpracheWaehlen
    };

    this._getEmailModuleDataSubscription = this._apiService.getRequest('/api/EMailModul/GetEmailModulData').subscribe((data: any) => {
      const headerData = data.Records[0];

      if (headerData) {
        this.filterList = headerData['Filter'];
        this.tbsList = headerData['TBS'];
        this.recipientChoiceList = headerData['EmpfaengerwahlList'];
        this.sendWithDelay = headerData['VerzoegertSenden'];
        this.emailModulTemplates = headerData['EmailModulTemplates'];
        this.communicationTypeParams['list'] = headerData['CommunicationTypes'];
        this.communicationTypeParams['filtered'] = headerData['CommunicationTypes'];

        if (headerData['CommunicationTypes'] && headerData['CommunicationTypes'].length === 1) {
          this.communicationTypeParams['selectedList'].push(headerData['CommunicationTypes'][0]);
          this._getFroalaOptions();
        }

        this._setSidebarText(this.communicationTypeParams);
        this.countryParams['list'] = headerData['Laenderliste'];
        this.countryParams['filtered'] = headerData['Laenderliste'];
        this.languageParams['list'] = headerData['Sprachliste'];
        this.languageParams['filtered'] = headerData['Sprachliste'];
        this.emailTypes = headerData.EmailTypes;

        if (this.emailTypes && this.emailTypes.length >= 1)
          this.emailTypeId = this.emailTypes[0].Id;

        this._getSavedEmail();

        if (this.communicationProcess) {
          this._loaded = true;
          this._loadStep();
        }
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Sets the sidebar text.
   */
  private _setSidebarText(sidebar: any): void {
    if (typeof sidebar['selectedList'] === 'undefined') {
      sidebar['selectedList'] = [];
    }

    sidebar['all'] = sidebar['selectedList'].length === sidebar['list'].length;

    if (sidebar['all']) {
      sidebar['text'] = this.ls.locs['loc'].Alle;
    } else {
      if (sidebar['selectedList'].length === 0) {
        sidebar['text'] = sidebar['defaultText'];
      } else {
        const pluckedSelectedList = sidebar['selectedList'].map((selected: any) => {
          return selected['DisplaytextKurz'];
        });

        sidebar['text'] = pluckedSelectedList.reduce((a: any, b: any) => {
          return a + ', ' + b;
        });
      }
    }
  }

  /**
   * Gets the options for the froala
   * editor.
   */
  private _getFroalaOptions(): void {
    if (this.communicationTypeParams['selectedList'] && this.communicationTypeParams['selectedList'].length > 0 && this.communicationTypeParams['selectedList'][0].Identifier === 'sms') {
      if (this._froalaEditor) {
        this._froalaEditor.destroy();
      }

      this.mailBody = this._stripHtml((this.mailBody !== '' ? this.mailBody : this.inputData['mailBody']));
      return;
    }

    if (this.froalaOptionsLoaded) {
      return;
    }

    if (this._loadOptions) {
      this._loadOptions();
    }
  }

  

  /**
   * Initializes the froala editor.
   */
  private _initFroala(): void {
    if (this._froalaEditor) {
      this._froalaEditor.destroy();

      if (Object.keys(this.froalaOptions).length > 0) {
        this._froalaEditor.initialize(this.froalaOptions);
      }
    }
  }

  /**
   * Gets the froala controls from the
   * froala init function.
   */
  getFroalaControls(froala: any): void {
    this._froalaEditor = froala;
    this._initFroala();
  }

  /**
   * Stores the last selection of the froala editor
   * is called on pointerdown (mouse or touch) on tbs selector
   */
  storeEditorSelection(): void {
    if (this._editor.core.hasFocus()) {
      this._editor.selection.save();
    }
  }

  /**
   * Handles the change of the mail
   * text-snippets.
   */
  tbsChange(selectedTBS: { [key: string]: any } | null): void {
    this.selectedTBS = selectedTBS;

    if (this.selectedTBS && this.selectedTBS['Value']) {
      if (this.communicationTypeParams['selectedList'] && this.communicationTypeParams['selectedList'].length > 0 && this.communicationTypeParams['selectedList'][0].Identifier === 'sms') {
        this.mailBody += this._stripHtml(this.selectedTBS['Value']);
      } else {
        if (typeof this._editor !== 'undefined' && this._editor !== null) {
          // @ts-ignore
          this._editor.selection.restore();
          this._editor.html.insert(this.selectedTBS['Value'], true);
          this.selectedTBS = null;
        }
      }
    }
  }

  /**
   * Strips html from the given string.
   */
  private _stripHtml(text: string): string {
    return text ? text.replace(/<\/p>/gm, "\n\n").replace(/<br\s*\/?>/gm, "\n").replace(/(<[^>]+>)|(\&nbsp\;)/gm, '').trim() : '';
  }

  /**
   * Saves the sidebar.
   */
  private _sidebarSave(): void {
    if (typeof this.communicationTypeParams !== 'undefined' && typeof this.communicationTypeParams['selectedList'] !== 'undefined' && this.communicationTypeParams['selectedList'].length > 0) {
      this._setTemplate();
      this._getFroalaOptions();
    }
  }

  /**
   * Sets the saved data.
   */
  private _setSavedData(data: any): void {
    this.inputData['subject'] = data['Subject'];
    this.inputData['mailBody'] = data['Body'];
    this.inputData['preheader'] = data['Preheader'];
    this.inputData['isNewsletter'] = data['IsNewsletter'];
    this.inputData['approved'] = data['Freigegeben'];
    this.inputData['showCountryLanguage'] = data['HasLandSpracheFilter'];
    this.countryParams['selectedList'] = data['Laender'];
    this.languageParams['selectedList'] = data['Sprachen'];
    this.emailTypeId = data['EmailTypeId'];

    if (data['CommunicationTypes'] && data['CommunicationTypes'].length > 0) {
      this.communicationTypeParams['selectedList'] = data['CommunicationTypes'];
    } else if (this.communicationTypeParams['list'] && this.communicationTypeParams['list'].length > 0) {
      this.communicationTypeParams['selectedList'].push(this.communicationTypeParams['list'][0]);
      this._getFroalaOptions();
    }

    this._setSidebarText(this.communicationTypeParams);
    this._calculateCountriesText();
    this._calculateLanguagesText();
    this.loadedEMail = data;

    if (data['Attachments'] && data['Attachments'][0]) {
      this.attachments = JSON.parse(JSON.stringify(data['Attachments']));
      this._attachmentsServerSide = JSON.parse(JSON.stringify(data['Attachments']));
    }

    if (data['AusfuehrungDatum']) {
      this.executionDate['date'] = moment(data['AusfuehrungDatum']);
      this.executionDate['timeHours'] = data['AusfuehrungStunde'];
      this.executionDate['timeMinutes'] = data['AusfuehrungMinute'];

      const date = this.executionDate['date'].clone();
      date.hour(data['AusfuehrungStunde']);
      date.minute(data['AusfuehrungMinute']);

      const now = moment();
      this.executionDate['text'] = (date.isBefore(now) || date.isSame(now)) ? this.ls.locs['loc'].Sofort : date.local().format('DD.MM.YYYY HH:mm');
    } else {
      this.executionDate['text'] = this.ls.locs['loc'].EmailModulKeinDatum;
    }

    if (data['EMailModulFilterId']) {
      this.recipientChoice = 'Filter';
      this.selectedFilter = this.filterList.find((item: any) => {
        return item['Value'] === data['EMailModulFilterId'] + '';
      });
    } else {
      this.recipientChoice = 'Auswahl';

      if (typeof data['Id'] !== 'undefined') {
        this._loadChosenParticipants(data['Id']);
      }
    }

    this._sidebarSave();
  }

  /**
   * Gets the data of a saved email.
   */
  private _getSavedEmail(): void {
    this._queryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this._getSavedEmailSubscription = this._apiService.getRequest(`/api/EMailModul/GetSavedEmail?id=${params['id']}`).subscribe((data: any) => {
          this._setSavedData(data.Records[0]);
        });
      }
    });
  }

  /**
   * Loads the chosen recipients for
   * the given data id.
   */
  private _loadChosenParticipants(id: string): void {
    const loader = this._mpLoader.getLoader('emailModuleEditorLoader');

    if (typeof loader !== 'undefined' && typeof loader.load !== 'undefined') {
      this._loadChosenRecipientsSubscription = this._apiService.getRequest(`/api/EMailModul/getAusgewaehlteTn?id=${id}`).subscribe((data: any) => {
        this.chosenRecipients = data.Records;
        // @ts-ignore
        loader.load();
      });
    }
  }

  /**
   * Loads the data for the communication
   * process step.
   */
  private _loadStep(): void {
    if (!this.communicationProcess) {
      return;
    }

    const data = this.cp.loadStep(this._cpKey);

    if (this.cp.checkStepIsSkipped(this._cpKey))
      this._showCpSkipStepConfirm = true;

    if (!data && !this._loaded) {
      this.reset();
      return;
    }

    if (data) {
      this._setSavedData(data);
    }
  }

  /**
   * Sets the selected template for
   * the email.
   */
  private _setTemplate(): void {
    let step: any = null;

    if (this.communicationProcess) {
      step = this.cp.loadStep('teilnehmer-auswahl');
    }

    let template: any = {};

    if (typeof this.communicationTypeParams['selectedList'] !== 'undefined') {
      template = this.emailModulTemplates.find((templ: any) => {
        return templ['Key'] === this.communicationTypeParams['selectedList'][0].Identifier;
      });
    }

    if ((!template || Object.keys(template).length === 0) && step !== null && step['CommunicationGroup']) {
      template = this.emailModulTemplates.find((templ: any) => {
        return templ['Key'] === step['CommunicationGroup'];
      });
    }

    if (template && Object.keys(template).length > 0 && (typeof this.inputData['mailBody'] === 'undefined' || typeof this.inputData['mailBody'] !== 'undefined' && (this.inputData['mailBody'] === template['Body'] || this.inputData['mailBody'] === ''))) {
      this.inputData['subject'] = template['Subject'];
      this.inputData['mailBody'] = template['Body'];
    }
  }

  /**
   * Triggered after data of the
   * data table were loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    rows.forEach((row: any) => {
      row['Auswahl'] = this.chosenRecipients.some((chosen: any) => {
        return chosen['ID'] === row['ID'];
      });
    });
  }

  /**
   * Toggles the choice of a
   * participant as a recipient.
   */
  toggleChoice(user: any): void {
    if (user['Auswahl'] && typeof this.chosenRecipients.find((recipient: any) => { return recipient['ID'] === user['ID']; }) === 'undefined') {
      this.chosenRecipients.push(user);
    }

    if (!user['Auswahl']) {
      this.chosenRecipients = this.chosenRecipients.filter((recipient: any) => {
        return recipient['ID'] !== user['ID'];
      });
    }
  }

  /**
   * Creates the text for the country
   * selection.
   */
  private _calculateCountriesText(): void {
    if (typeof this.countryParams['selectedList'] !== 'undefined' && this.countryParams['selectedList'] !== null && this.countryParams['selectedList'].length > 0) {
      const pluckedDisplaytextShort = this.countryParams['selectedList'].map((item: any) => {
        return item['DisplaytextKurz'];
      });

      this.countryParams['text'] = pluckedDisplaytextShort.reduce((a: any, b: any) => {
        return a + ', ' + b;
      });
    } else {
      this.countryParams['text'] = this.ls.locs['loc'].Alle;
    }
  }

  /**
   * Creates the text for the language
   * selection.
   */
  private _calculateLanguagesText(): void {
    if (typeof this.languageParams['selectedList'] !== 'undefined' && this.languageParams['selectedList'] !== null && this.languageParams['selectedList'].length > 0) {
      const pluckedDisplaytextShort = this.languageParams['selectedList'].map((item: any) => {
        return item['DisplaytextKurz'];
      });

      this.languageParams['text'] = pluckedDisplaytextShort.reduce((a: any, b: any) => {
        return a + ', ' + b;
      });
    } else {
      this.languageParams['text'] = this.ls.locs['loc'].Alle;
    }
  }

  /**
   * Gets the currently data, and
   * provides them for saving
   * processes.
   */
  private _getData(): { [key: string]: any } | boolean {
    if (typeof this.communicationTypeParams === 'undefined' || Object.keys(this.communicationTypeParams).length === 0) {
      return false;
    }

    const date = this.executionDate['date'].clone();
    date.hour(this.executionDate['timeHours']);
    date.minute(this.executionDate['timeMinutes']);

    const mailBody = (this.communicationTypeParams['selectedList'] && this.communicationTypeParams['selectedList'].length > 0 && this.communicationTypeParams['selectedList'][0].Identifier === 'sms') ?
      this.mailBody :
      this.inputData['mailBody'];

    const data: { [key: string]: any } = {
      Subject: this.inputData['subject'],
      Body: mailBody,
      Preheader: this.inputData['preheader'],
      Typ: this.recipientChoice,
      AusfuehrungDatum: date,
      AusfuehrungStunde: this.executionDate['timeHours'],
      AusfuehrungMinute: this.executionDate['timeMinutes'],
      Attachments: this._attachmentsServerSide,
      IsNewsletter: this.inputData['isNewsletter'],
      Sprachen: [],
      Laender: [],
      HasLandSpracheFilter: this.inputData['showCountryLanguage'],
      EmailTypeId: this.emailTypeId,
      CommunicationTypes: this.communicationTypeParams['selectedList']
    };

    if (data['CommunicationTypes'].length > 0 && data['CommunicationTypes'][0].Identifier === 'sms') {
      data['Body'] = this._stripHtml(data['Body']);
    }

    if (Object.keys(this.loadedEMail).length > 0) {
      data['Id'] = this.loadedEMail['Id'];
    }

    if (typeof this.languageParams['selectedList'] === 'undefined' || this.languageParams['selectedList'] === null || this.languageParams['selectedList'].length === 0) {
      this.languageParams['selectedList'] = this.languageParams['list'];
    }
      
    if (typeof this.countryParams['selectedList'] === 'undefined' || this.countryParams['selectedList'] === null || this.countryParams['selectedList'].length === 0) {
      this.countryParams['selectedList'] = this.countryParams['list'];
    }

    if (this.inputData['showCountryLanguage']) {
      data['Laender'] = this.countryParams['selectedList'];
      data['Sprachen'] = this.languageParams['selectedList'];
    }

    switch (this.recipientChoice) {
      case 'Auswahl':
        if (typeof this.chosenRecipients !== 'undefined' && this.chosenRecipients['length'] > 0) {
          data['to'] = this.chosenRecipients.reduce((memo: any, empf: any) => {
            return memo + empf['ID'] + ';';
          }, '');
        }

        break;
      case 'Filter':
        if (Object.keys(this.selectedFilter).length > 0) {
          data['to'] = this.selectedFilter['Value'];
        }

        break;
    }

    return data;
  }

  /**
   * Validates the email data.
   */
  validate(test: boolean, approval: boolean, setErrors?: boolean): boolean {
    let result = true;

    if (setErrors) {
      this.errorRecipient = false;
      this.errorTestRecipient = false;
      this.errorSubject = false;
      this.errorBody = false;
      this.errorDate = false;
    }

    this.hideExecutionDate = typeof this.executionDate !== 'undefined' && typeof this.executionDate['date'] !== 'undefined' && this.executionDate['date'].isSame(moment.utc([1900, 0, 1]));

    if (approval) {
      if (!test) {
        switch (this.recipientChoice) {
          case 'Auswahl':
            if (typeof this.chosenRecipients === 'undefined' || this.chosenRecipients.length === 0) {
              if (setErrors) {
                this.errorRecipient = true;
              }

              result = false;
            }

            break;
          case 'Filter':
            if (typeof this.selectedFilter === 'undefined' || parseInt(this.selectedFilter['Value']) <= 0) {
              if (setErrors) {
                this.errorRecipient = true;
              }

              result = false;
            }

            break;
          case 'LandSprache':
            if (typeof this._selectedCountryLanguage === 'undefined' ||
              typeof this._selectedCountryLanguage['Land'] === 'undefined' ||
              typeof this._selectedCountryLanguage['Sprachen'] === 'undefined') {
              if (setErrors) {
                this.errorRecipient = true;
              }

              result = false;
            }

            break;
        }
      } else {
        if (typeof this.testMailAddress === 'undefined' || this.testMailAddress === '') {
          if (setErrors) {
            this.errorTestRecipient = true;
          }

          result = false;
        }
      }
    }

    if (typeof this.inputData['subject'] === 'undefined' || this.inputData['subject'] === '') {
      if (setErrors) {
        this.errorSubject = true;
      }

      result = false;
    }

    if (typeof this.communicationTypeParams === 'undefined' || this.communicationTypeParams['selectedList'].length === 0) {
      result = false;
    }

    if (typeof this.inputData['mailBody'] === 'undefined' || this.inputData['mailBody'] === '') {
      if (setErrors) {
        this.errorBody = true;
      }

      result = false;
    }

    return result;
  }

  /**
   * Deletes the given email.
   */
  private _deleteEmail(row: any): void {
    this._deleteSavedMailSubscription = this._apiService.getRequest(`/api/EMailModul/DeleteEmail?id=${this.loadedEMail['Id']}`).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data.Message);
      this.confirmDelete = false;
      this._router.navigateByUrl(`/${this.role}/EmailScheduledVerwaltung`);
    });
  }

  /**
   * Sets the error messages from
   * a response from the api.
   */
  private _setErrorsFromResponse(response: any): void {
    if (response['status'] === 400) {
      this._mpMessaging.openWarningPanel(response.Message);
    }
  }

  /**
   * Toggles the confirm delete
   * modal.
   */
  private _toggleDeleteEmail(): void {
    if (this.validate(false, true, true)) {
      this.confirmDelete = !this.confirmDelete;
    }
  }

  /**
   * Toggles the save email modal.
   */
  private _toggleSaveEmail(): void {
    if (this.validate(false, false, true)) {
      this.confirmSave = !this.confirmSave;
    }
  }

  /**
   * Toggles the open new modal.
   */
  private _toggleOpenNew(): void {
    this.confirmOpenNew = !this.confirmOpenNew;
  }

  /**
   * Toggles the send email
   * modal.
   */
  private _toggleSendEmail(): void {
    if (this.validate(false, true, true)) {
      this.confirmSend = !this.confirmSend;
    }
  }

  /**
   * Toggles the remove approval
   * modal.
   */
  private _toggleRemoveApproval(): void {
    if (this.validate(false, true, true)) {
      this.confirmRemoveApproval = !this.confirmRemoveApproval;
    }
  }

  /**
   * Removes the approval.
   */
  private _removeApproval(): void {
    this._removeApprovalSubsription = this._apiService.getRequest(`/api/EMailModul/FreigabeEntziehen?id=${this.loadedEMail['Id']}`).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data.Message);
      this.inputData['approved'] = false;
      this.confirmRemoveApproval = false;
    });
  }

  /**
   * Openes a new email.
   */
  private _openNew(): void {
    this.confirmOpenNew = false;
    this._router.navigateByUrl(`/${this.role}/EMailModul`);
  }

  /**
   * Saves the email in the backend.
   */
  private _setEmail(data: any): void {
    this._setSavedMailSubscription = this._apiService.postRequest('/api/EMailModul/SetSavedMail', data).subscribe((response: any) => {
      this.loadedEMail = data;
      this.loadedEMail['Id'] = response.Records[0];
      this._mpMessaging.openSuccessPanel(data['Freigegeben'] ? this.ls.locs['loc'].EmailErfolgreichFreigegeben : this.ls.locs['loc'].EmailErfolgreichGespeichert);

      if (data['Freigegeben']) {
        this._router.navigateByUrl(`/${this.role}/EmailScheduledVerwaltung`);
      }
    },
    (error: any) => {
      this._setErrorsFromResponse(error);
    });
  }

  /**
   * Triggers the save process
   * for the eamil.
   */
  private _saveEmail(): void {
    const data: any = this._getData();
    data['Freigegeben'] = false;
    this.confirmSave = false;
    this._setEmail(data);
  }

  /**
   * Triggers the send email
   * process for the email.
   */
  private _sendEMail(): void {
    const data: any = this._getData();
    data['Freigegeben'] = true;
    this.inputData['approved'] = true;
    this.confirmSend = false;
    this._setEmail(data);
  }

  /**
   * Sends a test mail.
   */
  sendTestEMail(): void {
    if (this.validate(true, true, true)) {
      this._mpMessaging.openConfirm({
        text: this.ls.locs['loc'].EMailModul_FrageTestEMailSenden,
        submitText: this.ls.locs['loc'].Ja,
        cancelText: this.ls.locs['loc'].Nein
      }, () => {
        const data: any = this._getData();
        data['to'] = this.testMailAddress;
        data['Freigegeben'] = true;

        this._sendTestMailSubscription = this._apiService.postRequest('/api/EMailModul/sendTestEMail', data).subscribe((data: any) => {
          this._mpMessaging.openSuccessPanel(data.Message);
        },
        (error: any) => {
          this._setErrorsFromResponse(error);
        });

        this._mpMessaging.closeOverlay();
      }, () => {
        this._mpMessaging.closeOverlay();
      });
    }
  }

  /**
   * Saves the test inbox address
   * in the cookies.
   */
  saveTestInbox(): void {
    this._cookieService.setCookieWithoutCheck('TestPostfach=' + this._testInboxAddress, { expires: 365 });
  }

  /**
   * Saves the test sms address
   * in the cookies.
   */
  saveTestSms(): void {
    this._cookieService.setCookieWithoutCheck('TestSms=' + this._testSmsAddress, { expires: 365 });
  }

  /**
   * Sends a test message to the
   * inbox.
   */
  sendTestInboxMessage(): void {
    this.errorTestInboxMessage = {
      hasError: false,
      errMessage: ''
    };

    if (this._validateInbox(true)) {
      this._mpMessaging.openConfirm({
        text: this.ls.locs['loc'].EMailModul_FrageTestPostfachnachrichtSenden,
        submitText: this.ls.locs['loc'].Ja,
        cancelText: this.ls.locs['loc'].Nein
      }, () => {
        const data: any = this._getData();
        data['to'] = this.testInboxData['Benutzer'];

        this._sendTestInboxMailSubscription = this._apiService.postRequest('/api/EMailModul/SendTestPostfachnachricht', data).subscribe((response: any) => {
          this._mpMessaging.openPanelFromResultResponse(response);

          if (response.Result === 'ERROR') {
            this.errorTestInboxMessage = {
              hasError: true,
              errMessage: response.Message
            };
          }
        },
        (error: any) => {
          this._mpMessaging.openWarningPanel(error.Message, 3);
        });

        this._mpMessaging.closeOverlay();
      }, () => {
        this._mpMessaging.closeOverlay();
      });
    }
  }

  /**
   * Sends a test sms to the
   * inbox.
   */
  sendTestSmsMessage(): void {
    this.errorTestSmsMessage = {
      hasError: false,
      errMessage: ''
    };

    if (this._validateSms(true)) {
      this._mpMessaging.openConfirm({
        text: this.ls.locs['loc'].EMailModul_FrageTestSmsSenden,
        submitText: this.ls.locs['loc'].Ja,
        cancelText: this.ls.locs['loc'].Nein
      }, () => {
        const data: any = this._getData();
        data['to'] = this.testSmsData['Benutzer'];

        this._sendTestSmsSubscription = this._apiService.postRequest('/api/EMailModul/SendTestSms', data).subscribe((response: any) => {
          this._mpMessaging.openPanelFromResultResponse(response);

          if (response.Result === 'ERROR') {
            this.errorTestSmsMessage = {
              hasError: true,
              errMessage: response.Message
            };
          }
        },
          (error: any) => {
            this._mpMessaging.openWarningPanel(error.Message, 3);
          });

        this._mpMessaging.closeOverlay();
      }, () => {
        this._mpMessaging.closeOverlay();
      });
    } 
  }

  /**
   * Validates the data for inbox mail test.
   */
  private _validateInbox(test: boolean): boolean {
    const emailModulData: any = this._getData();

    if (test) {
      if (typeof this.testInboxData['Benutzer'] === 'undefined' || this.testInboxData['Benutzer'] === '') {
        this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorTestEmpfaenger);
        return false;
      }
    }

    if (emailModulData['CommunicationTypes'].length > 0 && emailModulData['CommunicationTypes'][0].Identifier !== 'sms' && (typeof emailModulData['Subject'] === 'undefined' || emailModulData['Subject'] === '')) {
      this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorEmailSubject);
      return false;
    }

    if (typeof emailModulData['Body'] === 'undefined' || emailModulData['Body'] === '') {
      this._mpMessaging.openDangerPanel(this.ls.locs['validate'].BodyRequired);
      return false;
    }

    return true;
  }

  /**
   * Validates the sms test.
   */
  private _validateSms(test: boolean): boolean {
    const emailModulData: any = this._getData();

    if (test) {
      if (typeof this.testSmsData['Benutzer'] === 'undefined' || this.testSmsData['Benutzer'] === '') {
        this._mpMessaging.openDangerPanel(this.ls.locs['loc'].ErrorTestEmpfaenger);
        return false;
      }
    }

    if (typeof emailModulData['Body'] === 'undefined' || emailModulData['Body'] === '') {
      this._mpMessaging.openDangerPanel(this.ls.locs['validate'].BodyRequired);
      return false;
    }

    return true;
  }

  /**
   * Handles the file upload.
   */
  public addAttachment(evt: any): void {
    const files = evt.target.files;
    this.file = files[0];

    if (this.file) {
      if (
        this.file.type !== "application/pdf"
        && this.file.type !== 'image/jpeg'
        && this.file.type !== 'image/jpg'
        && this.file.type !== 'image/png'
        && this.file.type !== 'application/vnd.ms-excel'
        && this.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        && this.file.type !== 'application/msword'
        && this.file.type !== 'application/zip'
        && this.file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this._mpMessaging.openWarningPanel(this.ls.locs['error'].FalschesDateiFormat);
      } else if (this.file.size > 0 + this._maxUploadFileLength * 1024 * 1024) {
        this._mpMessaging.openWarningPanel(this.ls.locs['error'].DateiZuGross.replace('{0}', this._maxUploadFileLength.toString()));
      } else {
        this._fileUploadSubscription = this._apiService.postFileUploadRequest('/api/Upload/uploadMailAttachment', this.file).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this._attachmentsServerSide.push(data.Records[0]);
            // @ts-ignore
            this.attachments.push(this.file.name);
            this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DateiWurdeErfolgreichHochgeladen);
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
          }
        },
        (error: any) => {
          this._mpMessaging.openWarningPanel(error.Message);
        });
      }
    }
  }

  /**
   * Deletes an attachment.
   */
  public deleteAttachment(index: number): void {
    this.attachments.splice(index, 1);
    this._attachmentsServerSide.splice(index, 1);
  }

  /**
   * Saves the cp step.
   */
  saveStep(): void {
    const data: any = this._getData();
    data['Freigegeben'] = false;
    this.cp.saveStep(this._cpKey, data);
  }

  /**
   * Validates the cp step.
   */
  validateStep(): boolean {
    if (typeof this.executionDate === 'undefined' || Object.keys(this.executionDate).length === 0)
      return false;

    const emailModulData: any = this._getData();

    if (this.communicationTypeParams['selectedList'].length === 0) {
      return false;
    }

    if (this.communicationTypeParams['selectedList'][0].Identifier !== 'sms' && (typeof emailModulData['Subject'] === 'undefined' || emailModulData['Subject'] === '')) {
      return false;
    }

    if (typeof emailModulData['Body'] === 'undefined' || emailModulData['Body'] === '') {
      return false;
    }

    return true;
  };

  /**
   * Skips the cp step.
   */
  skipStep(): void {
    this.cp.skipStep(this._cpKey);
    this._showCpSkipStepConfirm = false;
  };

  /**
   * Does the cp step.
   */
  doStep(): void {
    this.cp.confirmStep(this._cpKey);
    this._showCpSkipStepConfirm = false;
  };

  /**
   * Saves the communication process.
   */
  saveCommunicationProcess(): void {
    this.saveStep();
    this.cp.save();
  }

  /**
   * Goes to the next cp step.
   */
  goToNextStep(evt: MouseEvent): void {
    evt.preventDefault();

    if (!this.validateStep())
      return;

    this.saveStep();
    this.cp.next();
  };

  /**
   * Goes to the previous cp step.
   */
  goToPreviousStep(evt: MouseEvent): void {
    evt.preventDefault();

    this.saveStep();
    this.cp.previous();
  };

  /**
   * Shows the cp clearConfirm.
   */
  showClearConfirm(evt: MouseEvent): void {
    evt.preventDefault();
    this.cp.showClearConfirm();
  }

  /**
   * Triggers the goBack function
   * of the mpCoreService.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

}
