import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpSettingsService } from '@core/services/mp-settings.service';


import { RoleMappingService } from '@core/services/role-mapping.service';

@Component({
  selector: 'mp-leas-schooling-page-leas',
  templateUrl: './schooling-page-leas.component.html',
  styleUrls: ['./schooling-page-leas.component.scss']
})
export class SchoolingPageLeasComponent implements OnInit {

  public schoolingData: { [key: string]: any } = {};
  public schoolingId: number = 0;
  public role: string = '';
  public isTrainingCompleted: boolean = false;
  public pageIndex: number = 1;
  public message: string = '';

  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _roleMapping: RoleMappingService,
    private _mpMessaging: MpMessagingService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }

    this._route.queryParams.subscribe((params: any) => {
      this.schoolingId = typeof params['schoolingId'] !== 'undefined' ? params['schoolingId'] : 0;
      if (this.schoolingId > 0) {
        this._apiService.postRequest('/api/Schulung/GetSchulung', { Id: this.schoolingId }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.schoolingData = data.Records[0];
            }
          },
          (error: any) => {
            console.log(error.Message);
          });
        this._apiService.postRequest('/api/Schulung/CheckSchulungCompletedExists', { Id: this.schoolingId }).subscribe((data: any) => {
            if (data.Result === 'OK') {
              this.isTrainingCompleted = data.Records[0];
            }
          },
          (error: any) => {
            console.log(error.Message);
          });
      }
    });
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
* Openes the details of the schooling
* page.
*/
  startDetails(href: string, evt: MouseEvent): void {
    evt.preventDefault();
    window.open(href, '_blank');
  }

  /**
 * Goes to the next page.
 */
  indexIncrement(evt: MouseEvent): void {
    evt.preventDefault();

    if (this.pageIndex < this.schoolingData['Seiten']) {
      this.pageIndex++;
    }
  }

  /**
   * Goes to the previous page.
   */
  indexDecrement(evt: MouseEvent): void {
    evt.preventDefault();

    if (this.pageIndex >= 2) {
      this.pageIndex--;
    }
  }

  saveClosingSchooling(): void {
    
    this._apiService.postRequest('/api/Schulung/SaveClosingSchulung', { SchulungsId: this.schoolingId }).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._mpMessaging.openSuccessPanel(this.ls.locs['locSchulung'].SchulungAbgeschlossen);
        }
      },
      (error: any) => {
        console.log(error.Message);
      });

  }

}
