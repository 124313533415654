<div id="card-action" [hidden]="actions !== undefined && actions.length === 0 || actions === undefined || actions === null" *ngIf="(ls.locsLoaded | async)" class="margin-bottom-32">
  <h2 [innerText]="header"></h2>
  <owl-carousel-o *ngIf="actions !== undefined && actions !== null" id="aktionen-carousel" class="owl-theme" [options]="actioncardListProperties">
    <ng-container *ngFor="let item of actions">
      <ng-template carouselSlide [width]="632" style="width: auto;">
        <mp-core-action-card class="item" [action]="item"></mp-core-action-card>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
