import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSettingsService } from './../../../services/mp-settings.service';
import { MpOrderProcessService } from './../../../components/order-process/mp-order-process.service';
import { MpShoppingBasketService } from './../../../modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { MpMenuRefreshService } from './../../../components/menu/mp-menu-refresh.service';
import { AuthService } from './../../../services/auth.service';
import { RoleMappingService } from './../../../services/role-mapping.service';
import { ApiService } from './../../../services/api.service';

import { ShoppingBasketItem } from './../../../modules/participant/pages/shopping-basket/shopping-basket-item';

/**
 * This class provides the data and
 * functions for the order overview.
 */
@Component({
  selector: 'mp-core-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderOverviewComponent implements OnInit, OnDestroy {

  @Input() public nextText: string = '';
  @Input() public buttonDisabled: boolean = false;
  @Input() public buttonFunction: Function | null = null;

  public currentStepNextUrl: string = '';
  public sBItems: Array<ShoppingBasketItem> = [];
  public sBCount: number = 0;
  public role: string = '';
  public pointWorth: number = 0;

  private _currentStepSubscription: Subscription | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;

  public tnIsOnPaymentBlacklist: boolean = true;
  private _blacklistSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpOrderProcess: MpOrderProcessService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpMenuRefreshService: MpMenuRefreshService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the shopping basket items and
   * count. Also gets current step, and
   * its next url.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((sBItems: Array<ShoppingBasketItem>) => {
      if (sBItems['length'] > 0) {
        this.sBItems = sBItems;

        this.sBCount = sBItems.map((sBItem: ShoppingBasketItem) => {
          return sBItem.Anzahl;
        }).reduce((a: number, b: number) => {
          return a + b;
        });
      } else {
        this.sBItems = [];
        this.sBCount = 0;
      }
    });

    this._currentStepSubscription = this._mpOrderProcess.currentStepObserve.subscribe((currentStep: any) => {
      if (currentStep !== null) {
        this.currentStepNextUrl = currentStep.NextUrl;
      }
    });

    this._mpMenuRefreshService.loadMenuData(true).subscribe((data: any) => {
      if (data !== null) {
        this.pointWorth = data.Kontostand;
      }
    });

    this._blacklistSubscription = this._apiService.getRequest("/api/WkZuzahlung/IsTnOnBlacklist").subscribe((result: any) => {
      this.tnIsOnPaymentBlacklist = result.Result === 'OK' && result.Records[0];
    });
  }

  /**
   * Unsubscribes set subscribers.
   */
  ngOnDestroy(): void {
    if (typeof this._currentStepSubscription !== 'undefined') {
      this._currentStepSubscription.unsubscribe();
    }

    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (this._blacklistSubscription) {
      this._blacklistSubscription.unsubscribe();
    }
  }

  /**
   * Gets the remain points.
   */
  getRemainPoints(key: string): number {
    const wkSumme = this.sBItems.reduce((a: any, b: any) => { return a + b[key]; }, 0);
    return Math.max(this.pointWorth - wkSumme, 0);
  }

}
