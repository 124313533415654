import { Component, OnInit, ViewEncapsulation, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

/**
 * This class provides the data for the
 * picture credit page.
 */
@Component({
  selector: 'mp-core-picture-credit',
  templateUrl: './picture-credit.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PictureCreditComponent implements OnInit, OnDestroy {

  public data: string = '';

  private _getDataSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
    public sanitizer: DomSanitizer
  ) { }

  /**
   * Gets the content, sets the breadcrumb,
   * the menu highlight, and the title.
   */
  ngOnInit(): void {
    this._breadcrumbService.setCurrentSiteWithLoc('Bildernachweis', 'loc', 'Bildernachweis');

    this._getDataSubscription = this._apiService.getRequest('/api/Texte/getText', false, { params: { key: 'tn-bildnachweis' } }).subscribe((data: any) => {
      this.data = data.Records[0] === null ? '' : this._trustAsHtml(data.Records[0]);
    });

    this._titleService.setTitleFromLoc('Bildernachweis');
    this._mpMenuHighlight.setMenuHighlight('support');
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getDataSubscription !== 'undefined') {
      this._getDataSubscription.unsubscribe();
    }
  }

  /*
   * Sanitizer for HTML.
  */
  private _trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
