import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpCoreService } from './../../../../services/mp-core.service';
import { FroalaConfigService } from './../../../../services/froala-config.service';

/**
 * This class provides the functionalities
 * to edit the conditions of participation.
 */
@Component({
  selector: 'mp-core-conditions-of-participation-editor',
  templateUrl: './conditions-of-participation-editor.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ConditionsOfParticipationEditorComponent implements OnInit, OnDestroy {

  public ptcpntConfirmationRequired: boolean = true;
  public froalaOptions: { [key: string]: any } = {};
  public froalaOptionsLoaded: boolean = false;
  public hints: { [key: string]: any } = {
    showRevertChanges: false,
    showSaveConditions: false
  };
  public errors: { [key: string]: any } = {};
  public languages: Array<any> = [];
  public selLanguage: string = 'de';
  public groups: Array<any> = [];
  public group: { [key: string]: any } = {};
  public filteredThemes: Array<any> = [];
  public saveFunc = this._save.bind(this);
  public loadDataFunc = this._loadData.bind(this);
  public setShowSaveConditionsFalse = this._setShowSaveConditionsFalse.bind(this);
  public setShowRevertChangeFalse = this._setShowRevertChangeFalse.bind(this);

  private _token: string | null = sessionStorage.getItem('token');
  private _initialLoadTriggered: boolean = false;
  private _themesLoaded: boolean = false;
  private _froalaEditors: Array<any> = [];
  private _themes: Array<any> = [];
  private _paragraphNo: number = 0;
  private _getLanguagesSubscription: Subscription | undefined;
  private _loadDataSubscription: Subscription | undefined;
  private _saveSubscription: Subscription | undefined;
  private _getGroupsSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    private _mpCoreService: MpCoreService,
    private _froalaConfig: FroalaConfigService
  ) { }

  /**
   * Sets the froala options, and the
   * menu highlight. Gets languages and
   * text snippets.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('kommunikation');
    const requestVerificationTokenInput: HTMLInputElement | null = document.querySelector('input[name="__RequestVerificationToken"]');
    const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

    this.froalaOptions = Object.assign(this._froalaConfig.getConfig(), {
      language: 'de',
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'color', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'insertTable', 'undo', 'redo', 'html'],
      requestHeaders: {
        'X-XSRF-TOKEN': xsrfToken || '',
        'Authorization': 'Bearer ' + this._token
      },
      events: Object.assign(this._froalaConfig.getEvents(), {
        'initialized': (editor: any) => {
          this._froalaEditors.push(editor);
        },
        'blur': (editor: any) => {
          const currentTarget = editor.currentTarget;

          if (currentTarget.innerHTML === '<p><br></p>' || currentTarget.innerText === '') {
            currentTarget.innerHTML = '';
          }
        }
      })
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this.froalaOptions['placeholderText'] = this.ls.locs['loc'].UnterpunktVerfassen;
      this.froalaOptionsLoaded = true;

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.froalaOptions['placeholderText'] = this.ls.locs['loc'].UnterpunktVerfassen;
          this.froalaOptionsLoaded = true;

          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }

    this._getLanguagesSubscription = this._apiService.getRequest('/api/Language/GetLanguagesForDropdown').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length === 1 && data.Records[0].Languages) {
        this.languages = data.Records[0].Languages;
        this.selLanguage = this.languages[0].Kuerzel;
        this.froalaOptions['language'] = this.selLanguage;
      }

      this._loadData(true);
    });

    this._getGroupsSubscription = this._apiService.getRequest('/api/TextEditor/GetGruppen').subscribe((data: any) => {
      this.groups = data.Records;
      this.group = this.groups[0];
      this._loadData(true);
    });

    this._loadData(true);
  }

  /**
 * Retrieves a cookie value by name
 **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getLanguagesSubscription !== 'undefined') {
      this._getLanguagesSubscription.unsubscribe();
    }

    if (typeof this._loadDataSubscription !== 'undefined') {
      this._loadDataSubscription.unsubscribe();
    }

    if (typeof this._saveSubscription !== 'undefined') {
      this._saveSubscription.unsubscribe();
    }

    if (typeof this._getGroupsSubscription !== 'undefined') {
      this._getGroupsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the showSaveConditions hint to
   * false
   */
  private _setShowSaveConditionsFalse(): void {
    this.hints['showSaveConditions'] = false;
  }

  /**
   * Sets the showRevertChanges hint to
   * false.
   */
  private _setShowRevertChangeFalse(): void {
    this.hints['showRevertChanges'] = false;
  }

  /**
   * Triggers the goBack function of
   * the breadcrumb.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Loads the data for the conditions
   * of participation editor.
   */
  private _loadData(initial?: boolean): void {
    if (typeof initial !== 'undefined' && initial) {
      if (this._initialLoadTriggered) {
        if (this._themesLoaded) {
          this.setFilteredThemes();
        }

        return;
      }

      if (!this._initialLoadTriggered) {
        this._initialLoadTriggered = true;
      }
    }

    this._loadDataSubscription = this._apiService.getRequest('/api/TextEditor/GetTeilnahmebedingungen').subscribe((data: any) => {
      this._themes = data.Records;
      this._themesLoaded = true;
      this.hints['showRevertChanges'] = false;
      this.setFilteredThemes();
    },
    (error: any) => {
      this.hints['showRevertChanges'] = false;
    });
  }

  /**
   * Filters the conditions themes for the
   * selected language.
   */
  setFilteredThemes(): void {
    if (this._themes.length > 0 && this.selLanguage && Object.keys(this.group).length > 0) {
      this.filteredThemes = this._themes.filter((conditionsText: any) => {
        let topic;

        if (this.group['GruppenID'] === 0 && conditionsText['Language'] === this.selLanguage) {
          topic = conditionsText;
        }

        if (this.group['GruppenID'] === conditionsText['GruppenId'] && conditionsText['Language'] === this.selLanguage) {
          topic = conditionsText;
        }

        return topic;
      });
    }
  }

  /**
   * Saves the conditions of participation.
   */
  private _save(): void {
    this.errors = {};

    const data = {
      themen: this._themes,
      updateVersion: this.ptcpntConfirmationRequired
    };

    this._saveSubscription = this._apiService.postRequest('/api/TextEditor/SaveTeilnahmebedingungen', data).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this.hints['showSaveConditions'] = false;
    },
    (error: any) => {
      if (typeof error['ModelState'] !== 'undefined') {
        this.errors = error['ModelState'];
      }

      this.hints['showSaveConditions'] = false;
    });
  }

  /**
   * Adds a text to the given theme.
   */
  addText(themeForText: { [key: string]: any }): void {
    const allTexts = this._themes.map((theme: { [key: string]: any }) => {
      return theme['Texte'];
    }).reduce((a: any, b: any) => a.concat(b), []);

    let textId = 0;

    if (allTexts && allTexts.length > 0) {
      const textIds = allTexts.map((text: { [key: string]: any }) => text['TextId']);
      textId = Math.max(...textIds);
    }

    textId++;
    let sortorder = 0;

    if (themeForText['Texte'] && themeForText['Texte'].length > 0) {
      sortorder = Math.max(...themeForText['Texte'].map((text: { [key: string]: any }) => text['Sortorder']));
    }

    sortorder++;

    themeForText['Texte'].push({
      GruppenId: themeForText['GruppenId'],
      Language: themeForText['Language'],
      TextId: textId,
      Sortorder: sortorder,
      TbText: ''
    });
  }

  /**
   * Adds a new theme.
   */
  addTheme(): void {
    let themeId = 0;
    let sortorder = 0;

    if (this._themes.length > 0) {
      const themeIds = this._themes.map((theme: { [key: string]: any }) => theme['ThemaId']);
      themeId = Math.max(...themeIds);
      const sortorders = this._themes.map((theme: { [key: string]: any }) => theme['ThemaSortorder']);
      sortorder = Math.max(...sortorders);
    }

    themeId++;
    sortorder++;
    this._paragraphNo++;

    const newTheme = {
      GruppenId: this.group['GruppenID'],
      Language: this.selLanguage,
      Texte: [],
      Thema: '',
      ThemaId: themeId,
      ThemaSortorder: sortorder,
      offen: true,
      NeuerParagraphText: 'Neuer Paragraph '  
    };

    if (this._paragraphNo !== 1) {
      newTheme['NeuerParagraphText'] = 'Neuer Paragraph (' + this._paragraphNo + ')';
    }

    this._themes.push(newTheme);
    this.addText(newTheme);
    this.setFilteredThemes();
  }

  /**
   * Deletes the given theme.
   */
  deleteTheme(theme: { [key: string]: any }): void {
    const index = this._themes.indexOf(theme);
    this._themes.splice(index, 1);
    this.setFilteredThemes();
  }

  /**
   * Deletes the given text.
   */
  deleteText(text: { [key: string]: any }, theme: { [key: string]: any }): void {
    const index = theme['Texte'].indexOf(text);
    theme['Texte'].splice(index, 1);
  }

}
