<section *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <mp-core-svg-loader [hidden]="!loading" svg="LadeanimationenIconsFlugzeug" animation="draw" class="svg-loader" svgAnimation="draw" [time]="1.9" loadingText="{{ls.locs['locReisekonfigurator'].LadeanimationBitteWarten}}"></mp-core-svg-loader>
  <span *ngIf="mpSettings.settings['DisplaySettings'].HasThemesSwitch" id="display-settings-hast-theme-switch"></span>
  <div class="Hotelauswahl padding-top-64 padding-bottom-64 rk-two-columns-layout" [ngClass]="{openFilter: openFilter === true}">
    <div reisekonfigurator-background class="rk-background" style="background-image: url('/themes/default/media/images/rk-video.png')"></div>
    <div id="rk-search-wrapper" *ngIf="hotels" class="choice-site-search-wrapper display-flex flex-justify-cont-center flex-align-items-center" [ngClass]="{'search-error': hotels.length == 0 && message !== '' && !keepFilters || showInnerError}">
      <div id="rk-search" class="padding-top-16 padding-left-16 padding-right-16 padding-bottom-8 width-100-pcnt display-flex flex-nowrap flex-justify-cont-between">
        <div id="tab-wrapper" class="display-inline-flex flex-wrap">
          <article class="search-elements-wrapper">
            <div class="rk-such-bereich display-flex">
              <mp-rk-search-area-hotel-and-flight [travelType]="travelType" [params]="searchParams"></mp-rk-search-area-hotel-and-flight>
            </div>
          </article>
        </div>
        <div id="rk-back-btn" class="display-inline-flex flex-justify-cont-end __top-0 padding-8 position-relative">
          <a class="mp-button display-flex flex-align-items-center display-flex" href="#/{{role}}/Reisekonfigurator">
            <span class="display-inline-block position-relative top-2">{{ls.locs['locReisekonfigurator'].ZurueckZuReisekonfigurator}}</span>
          </a>
        </div>
      </div>
    </div>
    <div id="rk-main-section" class="rk-hotel-auswahl padding-bottom-16" *ngIf="hotels" [ngClass]="{noHotelsMessage: hotels.length == 0 || filteredHotels.length === 0}">
      <div [hidden]="hotels.length === 0 && !keepFilters">
        <mp-rk-travel-configurator-filter filterId="" *ngIf="filterOptionsSet" [options]="filterOptions" [(filter)]="filter" (filterHasChanged)="filterChanged($event)" [step]="mpSettings.settings['ReisekonfiguratorSettings'].PunktesliderStepCount"></mp-rk-travel-configurator-filter>
      </div>
      <div [hidden]="hotels.length === 0 && !keepFilters">
        <div class="Regionauswahl hide-on-medium margin-bottom-8">
          <div id="rk-head-filters" class="display-flex flex-justify-cont-between flex-align-items-end padding-8">
            <mp-core-sidebar-input [hidden]="!targetFilter['ziele'] || targetFilter['ziele'].length <= 1 " [ngClass]="{sidebarInptShown: targetFilter['ziele'] && targetFilter['ziele'].length > 1}" text="{{targetFilter['text']}}" icon="" sidebarComponent="sidebarTravelDestinationsFilterHotel" [sidebarParams]="targetFilter"></mp-core-sidebar-input>
            <mp-core-select [hidden]="!groupings || groupings.length <= 1 " [ngClass]="{rkSelcetShown: groupings && groupings.length > 1}" [mpRequired]="true" mpId="rk-gruppierung" label="{{ls.locs['loc'].GruppierenNach}}" [(model)]="grouping" [elements]="groupings" textProperty="Bezeichnung" (ngChange)="updateFiltered()"></mp-core-select>
            <mp-core-select mpId="rk-sortierung" label="{{ls.locs['loc'].SortierenNach}}" [mpRequired]="true" [(model)]="sorting" [elements]="sortings" textProperty="Bezeichnung" (ngChange)="updateFiltered()"></mp-core-select>
          </div>
        </div>
        <div id="rk-offers" [ngClass]="{opacityOffers: openedOffers}" #rkOffersContainer [hidden]="hotels.length === 0">
          <mp-rk-hotel-card *ngFor="let hotel of visibleHotels; trackBy: trackByHotelCode; let index = index" [isLast]="index === pagesize - 1" (afterRenderItem)="setInitialLoadDone()" [setOpenedOffersFunc]="setOpenedOffers" [travelType]="travelType" [filter]="filter" [sorting]="sorting" [selectedCategory]="selectedCategory" [getParamsForGetHotels]="getParamsForGetHotels" [hotel]="hotel" [searchParams]="searchParams" [grouping]="grouping" [filteredHotels]="filteredHotels" [(filterOptionsMaxPrice)]="filterOptions['MaxPreis']"></mp-rk-hotel-card>
        </div>
        <mp-core-info-notification [hidden]="!showInnerError" iconPosition="left" infoState="info" copyText="{{(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length === 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer === null) && mpSettings.settings['DisplaySettings'].HideHotline ? ls.locs['locReisekonfigurator'].KeineErgebnisseHideHotline : ls.locs['locReisekonfigurator'].KeineErgebnisse | format:[mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer]}}" class="showInnerError margin-top-8 padding-left-right-8"></mp-core-info-notification>
      </div>
      <mp-core-info-notification [hidden]="hotels.length > 0 || message === '' || keepFilters" iconPosition="left" infoState="info" copyText="{{message}}" class="margin-top-8 margin-left-0 margin-right-0 padding-left-4 padding-right-8"></mp-core-info-notification>
    </div>
    <mp-rk-tc-support class="hide-on-medium"></mp-rk-tc-support>
    <mp-rk-tc-mobile-search-filter *ngIf="hotels" [filteredHotels]="filteredHotels" [openFilter]="openFilter" [toggleSearchFilter]="toggleSearchFilterFunc" [travelStyle]="travelType" filterButtonText="{{travelType === travelTypeEnums['HotelUndFlug'] ? ls.locs['locReisekonfigurator'].HotelMitFlug : ls.locs['locReisekonfigurator'].Hotel}}">
      <div id="rk-search-wrapper" *ngIf="hotels" class="choice-site-search-wrapper display-flex flex-justify-cont-center flex-align-items-center">
        <div id="rk-search" class="padding-top-16 padding-left-16 padding-right-16 padding-bottom-8 width-100-pcnt display-flex flex-nowrap flex-justify-cont-between">
          <div id="tab-wrapper" class="display-inline-flex flex-wrap">
            <article class="search-elements-wrapper">
              <div class="rk-such-bereich display-flex">
                <mp-rk-search-area-hotel-and-flight [travelType]="travelType" [(params)]="searchParams"></mp-rk-search-area-hotel-and-flight>
              </div>
            </article>
          </div>
          <div id="rk-back-btn" class="display-inline-flex flex-justify-cont-end __top-0 padding-8 position-relative">
            <a class="mp-button display-flex flex-align-items-center display-flex" href="#/{{role}}/Reisekonfigurator">
              <span class="display-inline-block position-relative top-2">{{ls.locs['locReisekonfigurator'].ZurueckZuReisekonfigurator}}</span>
            </a>
          </div>
        </div>
      </div>
      <div id="rk-head-filters" class="display-flex flex-justify-cont-between flex-align-items-end padding-8">
        <mp-core-sidebar-input [hidden]="!targetFilter['ziele'] || targetFilter['ziele'].length <= 1 " [ngClass]="{sidebarInptShown: targetFilter['ziele'] && targetFilter['ziele'].length > 1}" text="{{targetFilter['text']}}" icon="" sidebarComponent="sidebarTravelDestinationsFilterHotel" [sidebarParams]="targetFilter"></mp-core-sidebar-input>
        <mp-core-select [hidden]="!groupings || groupings.length <= 1 " [ngClass]="{rkSelcetShown: groupings && groupings.length > 1}" mpId="rk-gruppierung-mobile" label="{{ls.locs['loc'].GruppierenNach}}" [(model)]="grouping" [elements]="groupings" textProperty="Bezeichnung" (ngChange)="updateFiltered()"></mp-core-select>
        <mp-core-select mpId="rk-sortierung-mobile" label="{{ls.locs['loc'].SortierenNach}}" [(model)]="sorting" [elements]="sortings" textProperty="Bezeichnung" (ngChange)="updateFiltered()"></mp-core-select>
      </div>
      <mp-rk-travel-configurator-filter filterId="Mobile" *ngIf="filterOptionsSet" [options]="filterOptions" [(filter)]="filter" (filterHasChanged)="filterChanged($event)" [step]="mpSettings.settings['ReisekonfiguratorSettings'].PunktesliderStepCount"></mp-rk-travel-configurator-filter>

    </mp-rk-tc-mobile-search-filter>
  </div>
  <div id="rk-bottom-buttons" class="show-on-medium position-fixed bottom-0 width-full" [ngClass]="{openFilter: openFilter === true}" stickyBottom>
    <div class="flex-grid flex-direction-row flex-wrap">

      <a *ngIf="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length > 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer !== null) && !mpSettings.settings['DisplaySettings'].HideHotline" class="mp-button _light-grey padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column margin-top-0" href="tel:{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}">
        <i class="svg-icon __clr-primary __size-24 __top--2 __phone float-none-important margin-bottom-4"></i>
        <span class="display-block">{{ls.locs['locReisekonfigurator'].Reisebuero}}</span>
      </a>
      <a *ngIf="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length > 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer !== null) && !mpSettings.settings['DisplaySettings'].HideHotline" class="mp-button _prim padding-top-bottom-8 flex-col-6-xs flex-col-6-s flex-col-6-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column" (click)="toggleSearchFilter($event)">
        <i class="svg-icon __clr-navbar __size-24 __top--2 __tune float-none-important margin-bottom-4"></i>
        <span class="display-block">{{ls.locs['loc'].SucheUndFilter}}</span>
      </a>
      <a *ngIf="(mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer.length === 0 || mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer === null) && mpSettings.settings['DisplaySettings'].HideHotline" class="mp-button _prim padding-top-bottom-8 flex-col-12-xs flex-col-12-s flex-col-12-m display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column" (click)="toggleSearchFilter($event)">
        <i class="svg-icon __clr-navbar __size-24 __top--2 __tune float-none-important margin-bottom-4"></i>
        <span class="display-block">
          {{ls.locs['loc'].SucheUndFilter}}
        </span>
      </a>
      <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button" (click)="goBack($event)">{{ls.locs['loc'].Zurueck}}</a>
        <a class="mp-button" href="#/{{role}}/Reisekonfigurator">{{ls.locs['loc'].Abbrechen}}</a>
      </div>
    </div>
  </div>
</section>
