import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { ApiService } from './api.service';
import { MpLocalizationService } from './mp-localization.service';
import { MpMessagingService } from './mp-messaging.service';
import { MpCoreService } from './mp-core.service';
import { MpSidebarService } from '../components/sidebar/mp-sidebar.service';
import { MpLoaderService } from './../services/mp-loader.service';

/**
 * This class provides functions and data
 * for the details to the subscriber addresses
 * in components where they are needed.
 */
@Injectable({
  providedIn: 'root'
})
export class MpAddressesService {
  public mpAddresses: any = {
    selectedAddressId: 0,
    selectedAddressTyp: 0,
    correctedAddress: {},
    hasUnusedCorrections: false,
    editAddress: null,
    errors: {}
  };

  public addresses: Array<any> = [];

  private _addresses: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

  private _editAddressChange: Subject<any> = new Subject<any>();
  public editAddressChange: Observable<any> = this._editAddressChange.asObservable();
  private _hasUnusedCorrectionsChange: Subject<boolean> = new Subject<boolean>();
  public hasUnusedCorrectionsChange: Observable<boolean> = this._hasUnusedCorrectionsChange.asObservable();

  constructor(
    private _apiService: ApiService,
    public ls: MpLocalizationService,
    public _mpMessaging: MpMessagingService,
    public _mpSidebar: MpSidebarService,
    private _mpLoaderService: MpLoaderService,
    public _mpCore: MpCoreService
  ) {}

  /**
   * Sidebar is opened for address editing
   */
  showSidebar(item: any, callback?: Function) {
    if (typeof callback !== 'undefined') {
      this._mpSidebar.open('addressEditArea', { item: item, callback: callback });
    } else {
      this._mpSidebar.open('addressEditArea', { item: item });
    }
  }

  /**
   * Deletes address
   */
  delete(addresse: any) {
    this._apiService.postRequest('/api/Teilnehmer/deleteTNAdresse', addresse).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.loadAddresses();
        }
      },
      (error: any) => {
        console.log(error.Message);
      });
  }

  /**
   * Checks address for correctness
   */
  checkCorrectedAddress() {
    let filtered = this.filterCorrectedAddress(this.mpAddresses.correctedAddress);
    this.mpAddresses.hasUnusedCorrections = filtered.length > 0;
    this._hasUnusedCorrectionsChange.next(filtered.length > 0);
  };

  /**
   * Deletes correction of address
   */
  deleteCorrection(val: any) {
    let keyToDelete = Object.keys(this.mpAddresses.correctedAddress).find(key => { return this.mpAddresses.correctedAddress[key] === val });
    const omit = (keys: any, obj: {}) =>
      Object.fromEntries(
        Object.entries(obj)
        .filter(([k]) => !keys[k])
      );
    this.mpAddresses.correctedAddress = omit(keyToDelete, this.mpAddresses.correctedAddress);
  };

  /**
  * Filters corrected address
  */
  filterCorrectedAddress(record: any) {
    let values = Object.values(record).filter((v: any) => typeof v == 'string');

    let filtered = values.filter((v: any) => { return v; });

    return filtered;
  };

  /**
   * Handle validation result
   */
  handleValidationResult(data: any, successFunction: any, failFunction: any) {
    let correctedAddress = data.Records[0];
    let filtered = this.filterCorrectedAddress(correctedAddress);
    if (filtered.length === 0 && !data.Records[0].GlobalError) {
      if (successFunction) {
        if (typeof successFunction === 'function') {
          successFunction();
        }
      }
    } else {

      this.mpAddresses.correctedAddress = correctedAddress;
      this.mpAddresses.errors = data.Records[0].Errors;

      failFunction();

      if (data.Records[0].GlobalError) {
        if (this.ls.locsLoaded) {
          ((data: any) => {
            this.mpAddresses.openWarningPanel(data.loc.AdresseGlobalError, 15);
          });
        }
      }
    }
  };

  /**
   * Checks standard address for correctness
   */
  validateStdAdress(successFunction: any, failFunction: any) {

    this._apiService.getRequest('/api/Teilnehmer/ValidateStdAdress').subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.handleValidationResult(data, successFunction, failFunction);
        }
      },
      (error: any) => {
        console.log(error.Message);
      });
  };

  /**
   * Checks shopping cart address for correctness
   */
  validateWkAdress(successFunction: any, failFunction: any) {

    this._apiService.getRequest('/api/Teilnehmer/ValidateWkAdress').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.handleValidationResult(data, successFunction, failFunction);
        }
      });
  };

  /**
   * Checks address for correctness
   */
  validate(addressData: any, successFunction: any, failFunction: any) {
    let address = {
      Name: addressData.Name,
      Vorname: addressData.Vorname,
      Strasse: addressData.Strasse,
      //Hausnummer: adressData.Hausnummer,
      Plz: addressData.PLZ,
      Ort: addressData.Ort,
      Lkz: (addressData.Land && addressData.Land.Kuerzel) ? addressData.Land.Kuerzel : addressData.LKZ
    };
    this._apiService.postRequest('/api/Teilnehmer/ValidateAdress', address).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.handleValidationResult(data, successFunction, failFunction);
        }
      });
  };

  /**
   * Stores address in database
   */
  save(editAddressPar: any, loadAdressesInCallback?: boolean): Observable<any> {
    
    let editAddress = editAddressPar;
    const resultOk: Subject<boolean> = new Subject<boolean>();

    if (editAddress && Object.keys(editAddress).length > 0) {
      if (editAddress.Land) {
        editAddress.LKZ = editAddress.Land.Kuerzel;
      }

      if (editAddress.AnredeObjekt) {
        editAddress.AnredenID = editAddress.AnredeObjekt.ID;
      }

      editAddress.AnredeEntityObjekt = editAddress.AnredeObjekt;

      this._apiService.postRequest("/api/Teilnehmer/saveTNAdresse", editAddress).subscribe((data: any) => {
        this.mpAddresses.errors = {};
        this.mpAddresses.correctedAddress = {};
        this.mpAddresses.editAddress = {};
        this._editAddressChange.next({});

        if (data.Result === "OK") {
          if (typeof loadAdressesInCallback === 'undefined' || loadAdressesInCallback === false) {
            this.loadAddresses();
          }

          if (data.Message) {
            this._mpMessaging.openSuccessPanel(data.Message);
          }

          resultOk.next(data.Records[0]);
        } else {
          if (data.Message) {
            this._mpMessaging.openWarningPanel(data.Message);
          }

          resultOk.next(false);
        }
      },
      (error: any) => {
        if (error.status === 400) {
          this.mpAddresses.errors = error.ModelState;
        } else {
          this.mpAddresses.errors = {};
          this.mpAddresses.correctedAddress = {};

          if (error.Message) {
            this._mpMessaging.openWarningPanel(error.Message);
          }

          resultOk.error(error.Message);
        }
      });
    }
    return resultOk.asObservable();
  }

  /**
   * Get addresses from database
   */
  loadAddresses(): Observable<any> {
    this._apiService.getRequest('/api/Teilnehmer/getTNAdressen').subscribe((data: any) => {
      this.addresses = data.Records;

      this.mpAddresses.ddlData = data.footer[0];
      this.setSalutationsForLand();
      this._addresses.next(data.Records);
    },
      (error: any) => {
        console.log(error.message);
      });
    return this._addresses.asObservable();
  }

  /**
   * Sets salutations for land
   */
  setSalutationsForLand(addressPar?: any) {
    let address = addressPar;
    if (!address) {
      address = this.mpAddresses.editAddress;
    }
    let values = this.mpAddresses.ddlData && this.mpAddresses.ddlData.Anreden;
    let lkz = address && address.Land && address.Land.Kuerzel;
    if (lkz && values) {
      this.mpAddresses.anreden = values.filter((a: any) => { return a.Land === lkz || !a.Land });

      address.AnredeObjekt = this.mpAddresses.anreden.find((obj: any) => obj.Anrede === address.Anrede);
      let geschlecht = address.AnredeObjekt && address.AnredeObjekt.Geschlecht;

      if (!address.AnredeObjekt && geschlecht) {
        address.AnredeObjekt = this.mpAddresses.anreden.find((obj: any) => obj.Geschlecht === geschlecht);
      }
    } else {
      this.mpAddresses.anreden = values;
    }
  }

  /**
   * Changes address
   */
  edit(address: any, callback?: Function) {
    this.mpAddresses.editAddress = address;
    this._editAddressChange.next(address);

    if (this.mpAddresses.editAddress.LKZ && this.mpAddresses.ddlData) {
      address.AnredeObjekt = this.mpAddresses.ddlData.Laenderliste.find((obj: any) => obj.Kuerzel === this.mpAddresses.editAddress.LKZ);
    }
    this.setSalutationsForLand();
    this.mpAddresses.errors = {}
    this.mpAddresses.correctedAddress = {};

    if (typeof callback !== 'undefined') {
      this.showSidebar(this.mpAddresses.editAddress, callback);
    } else {
      this.showSidebar(this.mpAddresses.editAddress);
    }
  }

  /**
   * Sets standard address
   */
  setStdAdresse(address: any) {
    return this._apiService
      .postRequest('/api/Teilnehmer/setStdAdressID', { AdressID: address.AdressID, AdressTyp: address.AdressTyp })
      .subscribe((data: any) => {
        this.loadAddresses();
      });
  }
}
