import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import { MpLoadingBarService } from './mp-loading-bar.service';

/**
 * This class provides all functions to communicate with
 * the backend api.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _http: HttpClient,
    private _loadingBar: MpLoadingBarService
  ) { }

  /**
   * Gets the X-XSRF-Token from the hidden input
   * in DOM and sets it as request header for the
   * following request.
   */
  private _setXXSRFToken(): HttpHeaders {
    const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

    return new HttpHeaders()
      .set('X-XSRF-Token', xsrfToken || '');
  }

  /**
   * Retrieves a cookie value by name
   **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Sends a get request to the server and
   * returns the response data or an error, if
   * the request was not sucessfully.
   */
  getRequest(uri: string, withoutToken?: boolean, params?: object, responseType?: string): Subject<any> {
    const uuid = this._create_UUID();
    this._loadingBar.start(uuid);
    let subject = new Subject();
    params = params || {};
    withoutToken = withoutToken || false;

    if (responseType) {
      params = Object.assign(params, { responseType: responseType });
    }

    if (!withoutToken) {
      params = Object.assign(params, { 'headers': this._setXXSRFToken() });
    }

    this._http.get<any>(uri, params).subscribe(
      (response: any) => {
        this._loadingBar.complete(uuid);
        subject.next(response);
      },
      (error: any) => {
        this._loadingBar.complete(uuid);
        subject.error(error);
      }
    );

    return subject;
  }

  /**
   * Semnds a post request to the server and
   * returns the response data or an error, if
   * the request was not sucessfully.
   */
  postRequest(uri: string, params: object | null = null): Subject<any> {
    const uuid = this._create_UUID();
    this._loadingBar.start(uuid);
    let subject = new Subject();

    this._http.post<any>(uri, params, { 'headers': this._setXXSRFToken() }).subscribe(
      (response: any) => {
        this._loadingBar.complete(uuid);
        subject.next(response);
      },
      (error: any) => {
        this._loadingBar.complete(uuid);
        error.error = Object.assign(error.error, {
          status: error.status
        });
        subject.error(error.error);
      }
    );

    return subject;
  }

  /**
   * Semnds a post request with int param to the server and
   * returns the response data or an error, if
   * the request was not sucessfully.
   */
  postRequestWithInt(uri: string, params: number): Subject<any> {
    const uuid = this._create_UUID();
    this._loadingBar.start(uuid);
    let subject = new Subject();

    this._http.post<any>(uri, params, { 'headers': this._setXXSRFToken() }).subscribe(
      (response: any) => {
        this._loadingBar.complete(uuid);
        subject.next(response);
      },
      (error: any) => {
        this._loadingBar.complete(uuid);
        error.error = Object.assign(error.error, {
          status: error.status
        });
        subject.error(error.error);
      }
    );

    return subject;
  }

  /**
   * Semnds a post request with boolean param to the server and
   * returns the response data or an error, if
   * the request was not sucessfully.
   */
  postRequestWithBoolean(uri: string, params: boolean): Subject<any> {
    const uuid = this._create_UUID();
    this._loadingBar.start(uuid);
    let subject = new Subject();

    this._http.post<any>(uri, params, { 'headers': this._setXXSRFToken() }).subscribe(
      (response: any) => {
        this._loadingBar.complete(uuid);
        subject.next(response);
      },
      (error: any) => {
        this._loadingBar.complete(uuid);
        error.error = Object.assign(error.error, {
          status: error.status
        });
        subject.error(error.error);
      }
    );

    return subject;
  }

  /**
   * Semnds a post request for files to the server
   * and returns the response data or an error, if
   * the request was not sucessfully.
   */
  postFileUploadRequest(uri: string, file: File, fileUploadObj?: string, additionalDataObj?: { [key: string]: any }): Subject<any> {
    const uuid = this._create_UUID();
    this._loadingBar.start(uuid);
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileUploadObj', (typeof fileUploadObj !== 'undefined' ? fileUploadObj : file.name));

    if (typeof additionalDataObj !== 'undefined') {
      Object.keys(additionalDataObj).forEach((objKey: string) => {
        formData.append(objKey, additionalDataObj[objKey]);
      });
    }

    let subject = new Subject();

    this._http.post<any>(uri, formData, { 'headers': this._setXXSRFToken() }).subscribe(
      (response: any) => {
        this._loadingBar.complete(uuid);
        subject.next(response);
      },
      (error: any) => {
        this._loadingBar.complete(uuid);
        subject.error(error.error);
      }
    );

    return subject;
  }

  /**
   * Creates a uuid.
   */
  private _create_UUID(): string {
    let dtime = new Date().getTime();

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char: string) => {
      var random = (dtime + Math.random() * 16) % 16 | 0;
      dtime = Math.floor(dtime / 16);
      return (char == 'x' ? random : (random & 0x3 | 0x8)).toString(16);
    });

    return uuid;
  }

  /**
   * Sends a get request to the server and
   * returns the response data or an error, if
   * the request was not sucessfully.
   */
  deleteRequest(uri: string, withoutToken?: boolean, params?: object, responseType?: string): Subject<any> {
    const uuid = this._create_UUID();
    this._loadingBar.start(uuid);
    let subject = new Subject();
    params = params || {};
    withoutToken = withoutToken || false;

    if (responseType) {
      params = Object.assign(params, { responseType: responseType });
    }

    if (!withoutToken) {
      params = Object.assign(params, { 'headers': this._setXXSRFToken() });
    }

    this._http.delete<any>(uri, params).subscribe(
      (response: any) => {
        this._loadingBar.complete(uuid);
        subject.next(response);
      },
      (error: any) => {
        this._loadingBar.complete(uuid);
        subject.error(error);
      }
    );

    return subject;
  }

}
