import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functionalities for the theme stage
 * management table.
 */
@Component({
  selector: 'mp-core-theme-stage-management',
  templateUrl: './theme-stage-management.component.html',
  styleUrls: ['./theme-stage-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeStageManagementComponent implements OnInit, OnDestroy {

  public active: boolean = true;
  public archived: boolean = false;
  public confirmDelete: boolean = false;
  public showDuplicate: boolean = false;
  public showDontApprove: boolean = false;
  public execClickFunctions: Array<any> = [
    {
      backendName: 'toggleDeleteThemenbuehne',
      func: this.toggleDeleteThemeStage.bind(this)
    },
    {
      backendName: 'toggleShowDuplizieren',
      func: this.toggleShowDuplicate.bind(this)
    },
    {
      backendName: 'toggleShowNichtFreigeben',
      func: this.toggleShowDontApprove.bind(this)
    }
  ];
  public toggleDeleteThemeStageFunc = this.toggleDeleteThemeStage.bind(this);
  public deleteThemeStageFunc = this.deleteThemeStage.bind(this);
  public toggleShowDontApproveFunc = this.toggleShowDontApprove.bind(this);
  public removeApprovalFunc = this.removeApproval.bind(this);
  public toggleShowDuplicateFunc = this.toggleShowDuplicate.bind(this);
  public duplicateFunc = this.duplicate.bind(this);

  private _getThemeStagesLoader: MpLoader = {
    name: 'getThemeStagesLoader',
    params: {}
  };
  private _deleteId: number = 0;
  private _tbId: number = 0;
  private _locsLoadedSubscription: Subscription | undefined;
  private _deleteStageSubscription: Subscription | undefined;
  private _duplicateSubscription: Subscription | undefined;
  private _approvalRemoveSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    public _breadcrumbService: MpBreadcrumbService,
    private _titleService: MpTitleService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the title and menu highlight.
   * Registers the loader and sets
   * the breadcrumbs.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('ThemenebuehnenVerwalten', 'loc');
    this._mpMenuHighlight.setMenuHighlight('Themenbuehnen');
    this._mpLoaderService.registerLoader(this._getThemeStagesLoader);

    if (Object.keys(this.ls.locs).length > 0) {
      this._breadcrumbService.setCurrentSiteWithLoc('ThemenbuehnenVerwalten', 'loc', 'ThemenbuehnenVerwalten');
    } else {
      this._locsLoadedSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._breadcrumbService.setCurrentSiteWithLoc('ThemenbuehnenVerwalten', 'loc', 'ThemenbuehnenVerwalten');
        }
      });
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }

    if (typeof this._deleteStageSubscription !== 'undefined') {
      this._deleteStageSubscription.unsubscribe();
    }

    if (typeof this._duplicateSubscription !== 'undefined') {
      this._duplicateSubscription.unsubscribe();
    }

    if (typeof this._approvalRemoveSubscription !== 'undefined') {
      this._approvalRemoveSubscription.unsubscribe();
    }
  }

  /**
   * Loads active theme stages.
   */
  loadActive(): void {
    this._mpLoaderService.extendLoader(this._getThemeStagesLoader.name, {
      params: this._getThemeStagesLoader.params
    });

    const themeStageLoader = this._mpLoaderService.getLoader(this._getThemeStagesLoader.name);

    if (typeof themeStageLoader.params['StatusID'] !== 'undefined') {
      delete themeStageLoader.params['StatusID'];
    }

    this.active = true;
    this.archived = false;

    if (typeof themeStageLoader.load !== 'undefined') {
      themeStageLoader.load();
    }
  }

  /**
   * Loads archived theme stages.
   */
  loadArchived(): void {
    this._mpLoaderService.extendLoader(this._getThemeStagesLoader.name, {
      params: this._getThemeStagesLoader.params
    });

    const themeStageLoader = this._mpLoaderService.getLoader(this._getThemeStagesLoader.name);
    themeStageLoader.params['StatusID'] = 4;
    this.active = false;
    this.archived = true;

    if (typeof themeStageLoader.load !== 'undefined') {
      themeStageLoader.load();
    }
  }

  /**
   * Toggles the delete theme stage
   * modal.
   */
  toggleDeleteThemeStage(row?: any, column?: any): void {
    if (row) {
      this._deleteId = row.Id;
    }

    this.confirmDelete = !this.confirmDelete;
  }

  /**
   * Toggles the duplicate theme stage
   * modal.
   */
  toggleShowDuplicate(row?: any, column?: any): void {
    if (row) {
      this._tbId = row.Id;
    }

    this.showDuplicate = !this.showDuplicate;
  }

  /**
   * Toggles the don't approve theme stage
   * modal.
   */
  toggleShowDontApprove(row?: any, column?: any): void {
    if (row) {
      this._tbId = row.Id;
    }

    this.showDontApprove = !this.showDontApprove;
  }

  /**
   * Deletes the given theme stage.
   */
  deleteThemeStage(): void {
    this._deleteStageSubscription = this._apiService.getRequest(`/api/Themenbuehnen/DeleteThemenbuehne?themenbuehnenId=${this._deleteId}`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.confirmDelete = false;
        const themeStageLoader = this._mpLoaderService.getLoader(this._getThemeStagesLoader.name);

        if (typeof themeStageLoader.load !== 'undefined') {
          themeStageLoader.load();
        }

        this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DeleteThemenbuehneSuccess);
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    });
  }

  /**
   * Duplicates the given theme stage.
   */
  duplicate(): void {
    this._duplicateSubscription = this._apiService.getRequest(`/api/Themenbuehnen/Duplizieren?id=${this._tbId}`).subscribe((data: any) => {
      this._mpMessaging.openSuccessPanel(data.Records[0]);
      const themeStageLoader = this._mpLoaderService.getLoader(this._getThemeStagesLoader.name);

      if (typeof themeStageLoader.load !== 'undefined') {
        themeStageLoader.load();
      }

      this.showDuplicate = false;
    });
  }

  /**
   * Removes the approval of the given
   * theme stage.
   */
  removeApproval(): void {
    this._approvalRemoveSubscription = this._apiService.postRequest(`/api/Themenbuehnen/FreigabeEntziehen?id=${this._tbId}`, {}).subscribe((data: any) => {
      this._mpMessaging.openSuccessPanel(data.Records[0]);
      const themeStageLoader = this._mpLoaderService.getLoader(this._getThemeStagesLoader.name);

      if (typeof themeStageLoader.load !== 'undefined') {
        themeStageLoader.load();
      }

      this.showDontApprove = false;
    });
  }

}
