import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject, Observable, Subject } from 'rxjs';

import animateScrollTo from 'animated-scroll-to';

import { MpStageIdService } from './../../services/mp-stage-id.service';

/**
 * This class provides service functions
 * for the user profile navbar.
 */
@Injectable({
  providedIn: 'root'
})
export class UserProfileNavbarService {

  private _paramsObserver: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [key: string]: any }>({});
  public paramsObserver: Observable<{ [key: string]: any }> = this._paramsObserver.asObservable();
  private _activeTabObserver: Subject<number> = new Subject<number>();
  public activeTabObserver: Observable<number> = this._activeTabObserver.asObservable();
  public scroll = this._scroll.bind(this);

  private _activeTab: number = 1;
  private _activeTabName: string = '';
  private _params: { [key: string]: any } = {};
  private _queryParamsSubscription: Subscription | undefined;

  constructor(
    private _route: ActivatedRoute,
    private _mpStageIdService: MpStageIdService
  ) {
    this._mpStageIdService.setScrolled(false);
  }

  /**
   * Changes the tab to the given
   * id.
   */
  changeTab(tabId: number, triggerObserver?: boolean): void {
    this._activeTab = tabId;
    this._mpStageIdService.setScrolled(false);
    this._scroll();

    if (triggerObserver) {
      this._activeTabObserver.next(this._activeTab);
    }
  }

  /**
   * Scrolls to the start of the
   * profile navbar, after user
   * changes the tab.
   */
  private _scroll(): void {
    const buehnenIds = this._mpStageIdService.getIds();
    const scrolled = this._mpStageIdService.getScrolled();
    let imageLoadingDone = true;

    if (buehnenIds && buehnenIds.length > 0) {
      // @ts-ignore
      imageLoadingDone = typeof buehnenIds.find((e: any) => { return document.getElementById(e) !== null ? document.getElementById(e).complete : false; }) !== 'undefined' ? true : false;
    }

    if (!scrolled && imageLoadingDone && this._activeTab !== 1) {
      this._mpStageIdService.setScrolled(true);

      this._queryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
        if (typeof params['key'] !== 'undefined') {
          setTimeout(() => {
            const key = params['key'];
            const target = document.getElementById(key);
            const profileNavbar = document.getElementById('user-profile__navbar');
            const navbar = document.getElementById('navbar');

            if (target !== null && profileNavbar !== null && navbar !== null) {
                const scrollDistance = target.offsetTop - profileNavbar.getBoundingClientRect().height - navbar.getBoundingClientRect().height;
                animateScrollTo(scrollDistance, { maxDuration: scrollDistance, easing: (t) => { return 1 - (--t) * t * t * t } });
            }
          }, 1000);
        } else {
          const stage = document.querySelector('.stage');
          const navbar = document.getElementById('navbar');

          if (stage !== null && navbar !== null) {
            // @ts-ignore
            const scrollDistance = stage.offsetTop + stage.getBoundingClientRect().height - navbar.getBoundingClientRect().height +1;
            animateScrollTo(scrollDistance, { maxDuration: scrollDistance, easing: (t) => { return 1 - (--t) * t * t * t } });
          }
        }


        if (typeof this._queryParamsSubscription !== 'undefined') {
          this._queryParamsSubscription.unsubscribe();
        }
      });
    }
  }

  /**
   * Sets the given parameters.
   */
  setParams(param: string, value: any): void {
    this._params[param] = value;
    this._paramsObserver.next(this._params);
  }

  // Gets the set parameters.
  getParams(): { [key: string]: any } {
    return this._params;
  }

  /**
   * Returns the active tab id.
   */
  getActiveTab(): number {
    return this._activeTab;
  }

  /**
   * Sets the name of the active
   * tab.
   */
  setTabName(name: string): void {
    this._activeTabName = name;
  }

  /**
   * Returns the name of the active
   * tab.
   */
  getTabname(): string {
    return this._activeTabName;
  }

}
