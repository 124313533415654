<div mpGkPrizeCalendar #pC="mpGkPrizeCalendar" id="win-calender" *ngIf="(ls.locsLoaded | async)">
  <div *ngIf="pC.calendarLoaded" id="calender-background" style="background-image: url('{{pC.calendar['BackgroundImage']}}')"></div>
  <div id="snowflakes">
    <i *ngFor="let flake of pC.snowflakes; trackBy: pC.trackByIndex"></i>
  </div>
  <h1 *ngIf="pC.calendarLoaded" [innerText]="pC.calendar['Title']"></h1>
  <p *ngIf="pC.calendarLoaded" class="calender-subline" [innerHTML]="pC.calendar['Teaser']"></p>
  <div *ngIf="pC.calendarLoaded && pC.currentTheme !== ''" id="swipe-container" mpGkDoorMovement #doorMovement="mpGkDoorMovement" class="noselect">
    <section id="calender" class="noselect">
      <div id="door-{{tag.Nummer}}" class="benefit-door noselect" [ngClass]="{opened: tag.openDoors && (tag.IsToday || tag.dayPast), today: tag.IsToday, 'day-past': tag.dayPast || tag.HasBeenOpened}" *ngFor="let tag of pC.calendar['Tage']; let index = index; let last = last" (click)="pC.checkAbleToOpen(tag, index);">
        <div class="door door-left noselect" [ngClass]="tag.openDoors ? tag.IsToday ? 'today door-opened' : 'door-opened' : ''">
          <img draggable="false" src="/themes/{{pC.currentTheme}}/media/gewinnkalender/{{tag.GewinnkalenderKey}}/{{tag.Nummer}}.svg" alt="{{tag.Nummer}}" />
        </div>
        <div class="door door-right noselect" [ngClass]="tag.openDoors ? tag.IsToday ? 'today door-opened' : 'door-opened' : ''">
          <img draggable="false" src="/themes/{{pC.currentTheme}}/media/gewinnkalender/{{tag.GewinnkalenderKey}}/{{tag.Nummer}}.svg" alt="{{tag.Nummer}}" />
          {{ last ? doorMovement.ngForFinished() : ''}}
        </div>
      </div>
    </section>
  </div>
  <mp-gk-prize-calendar-modal *ngIf="pC.showOpenDoorResult" [show]="!pC.openDoorResult['IsMessage']">
    <div class="col-large-6 grid open-door-image-grid position-relative">
      <img class="open-door-benefit-image" *ngIf="pC.openDoorResult['IsGewinn'] && !pC.openDoorResult['ProzentRabatt'] || pC.openDoorResult['IsGewinn'] && pC.openDoorResult['ProzentRabatt'] === 0" src="/themes/{{pC.currentTheme}}/media/gewinnkalender/{{pC.openDoorGewinnkalenderKey}}/Gedicht.jpg" alt="{{pC.openDoorDay}}" />
      <img class="open-door-benefit-image" *ngIf="!pC.openDoorResult['IsGewinn'] && (!pC.openDoorResult['ProzentRabatt'] || pC.openDoorResult['ProzentRabatt'] === 0)" src="/themes/{{pC.currentTheme}}/media/gewinnkalender/{{pC.openDoorGewinnkalenderKey}}/Gedicht.jpg" alt="{{pC.openDoorDay}}" />
      <img class="open-door-benefit-image margin-bottom-0" *ngIf="pC.openDoorResult['ProzentRabatt'] && pC.openDoorResult['ProzentRabatt'] > 0" src="/themes/{{pC.currentTheme}}/media/gewinnkalender/{{pC.openDoorGewinnkalenderKey}}/Gedicht.jpg" alt="{{pC.openDoorDay}}" />
      <div id="open-door-img-overlay" *ngIf="pC.openDoorResult['ProzentRabatt'] && pC.openDoorResult['ProzentRabatt'] > 0" class="position-absolute width-full height-full top-0 left-0"></div>
      <div class="open-door-day position-absolute top-32 left-32">{{pC.openDoorDay}}</div>
      <div class="open-door-benefit position-absolute top-0" *ngIf="pC.openDoorResult['IsGewinn']" [ngClass]="pC.openDoorResult['Punkte'] > 0 && !pC.openDoorResult['ArtNr'] ? 'point-benefit' : ''">
        <div class="open-door-benefit-points-headline" *ngIf="pC.openDoorResult['Punkte'] > 0 && !pC.openDoorResult['ArtNr']">{{ ls.locs['locGewinnkalender'].GewinnPunkte }}</div>
        <div class="open-door-benefit-points" *ngIf="pC.openDoorResult['Punkte'] > 0 && !pC.openDoorResult['ArtNr']">{{pC.openDoorResult['Punkte']}}</div>
        <div class="open-door-benefit-article-img display-flex flex-direction-row flex-wrap flex-align-items-center flex-justify-cont-center" *ngIf="pC.noPoints">
          <img src="{{pC.openDoorResult['DisplayImage']}}?trim.threshold=80&width=300&height=300" alt="{{pC.openDoorResult['DisplaySubtitle']}}" class="margin-bottom-0" />
        </div>
        <img class="open-door-benefit-image-banner position-absolute left-0 width-full" *ngIf="!pC.openDoorResult['ProzentRabatt'] || pC.openDoorResult['ProzentRabatt'] === 0" src="/themes/{{pC.currentTheme}}/media/gewinnkalender/{{pC.openDoorGewinnkalenderKey}}/Gewinn_Banner.svg" alt="{{pC.openDoorDay}}" />
        <div class="open-door-benefit-percent-rebate position-absolute top-32 padding-top-bottom-8 padding-left-right-16 right-0 text-align-right font-weight-400" *ngIf="pC.openDoorResult['ProzentRabatt'] && pC.openDoorResult['ProzentRabatt'] > 0">{{ls.locs['loc'].ProzentRabatt | format:pC.openDoorResult['ProzentRabatt']}}</div>
      </div>
    </div>
    <div class="col-large-6 grid open-door-text-grid padding-32">
      <div class="mp-button _prim close-benefit-modal padding-left-right-8 position-absolute" (click)="pC.closeBenefitModal(pC.openDoorIndex, 'click');">
        <i class="icon icon-font">x</i>
      </div>
      <h1 class="open-door-title" [innerHTML]="pC.openDoorResult['DisplayTitle']"></h1>
      <h2 class="open-door-subtitle margin-top-8 margin-bottom-4" [innerHTML]="pC.openDoorResult['DisplaySubtitle']"></h2>
      <h3 *ngIf="pC.openDoorResult['DisplaySubsubtitle']" [innerHTML]="pC.openDoorResult['DisplaySubsubtitle']"></h3>
      <div class="open-door-text margin-top-48" [innerHTML]="pC.openDoorResult['DisplayText']"></div>
      <div class="open-door-text-hinweis margin-top-8 font-weight-500" *ngIf="pC.openDoorResult['HinweisText']" [innerHTML]="pC.openDoorResult['HinweisText']"></div>
      <a href="{{pC.openDoorResult['ButtonLink'].replace('#', '#/' + role)}}" *ngIf="pC.openDoorResult['ButtonLink'] && pC.openDoorResult['ButtonText']" class="mp-button _prim margin-top-8 open-door-button">{{pC.openDoorResult['ButtonText']}}</a>
      <a *ngIf="!pC.openDoorResult['ButtonLink'] && pC.openDoorResult['ButtonText']" class="mp-button _prim margin-top-8 open-door-button disabled">{{pC.openDoorResult['ButtonText']}}</a>
      <div class="position-absolute right-32 bottom-32 text-align-right" id="open-door-savings" *ngIf="pC.openDoorResult['ProzentRabatt'] && pC.openDoorResult['ProzentRabatt'] > 0">
        <span class="text-align-right font-size-xl margin-bottom-4 position-relative display-inline-block">{{pC.openDoorResult['Vergleichspunkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
        <span class="text-align-right font-size-xxl">{{pC.openDoorResult['Punkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
    </div>
  </mp-gk-prize-calendar-modal>
  <mp-gk-prize-calendar-modal *ngIf="pC.showOpenDoorResult" [show]="pC.openDoorResult['IsMessage']" class="just-opened-door">
    <div class="col-large-12 grid open-door-text-grid paddding-32">
      <div class="mp-button _prim close-benefit-modal padding-left-right-8 position-absolute display-flex flex-align-items-center flex-justify-cont-center" (click)="pC.closeBenefitModal(pC.openDoorIndex, 'click');">
        <i class="svg-icon __size-28 __top-0 __clr-specific-5 __clear"></i>
      </div>
      <div class="open-door-text" [innerHTML]="pC.openDoorResult['MessageText']"></div>
    </div>
  </mp-gk-prize-calendar-modal>
</div>
