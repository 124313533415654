<div class="row inbox floating-container" *ngIf="(ls.locsLoaded | async)">
  <div class="grid floating-container" [ngClass]="hideCategories ? 'col-large-12' : 'col-large-3'">
    <div class="inbox-sidebar margin-bottom-16" [ngClass]="{open: openInboxFilter}">
      <div class="floating-container hidden-breakpoint-large">
        <button class="mp-button float-right" ng-click="openInboxFilter = false">
          <i class="svg-icon __clr-default __wide __clear"></i>
        </button>
      </div>
      <mp-core-search
        [(model)]="searchTerm"
        mpId="inbox-suche"
        [action]="loadFunc"
        [mpPlaceholder]="ls.locs['loc'].PostfachSuchePlaceholder">
        <i class="position-absolute-top-right svg-icon __clr-default __size-24 __top-4 __search"></i>
      </mp-core-search>
      <div class="menu-item" [ngClass]="{active: active === 'all'}" [hidden]="hideCategories">
        <a (click)="filterCategory($event)">
          <div class="floating-container">
            <i class="svg-icon __wide __wide-18 __inbox" [ngClass]="active === 'all' ? '__clr-primary' : '__clr-default'"></i>
            <div class="icon_nav-text menu-item-text">{{ ls.locs['loc'].AlleNachrichten }}</div>
          </div>
        </a>
      </div>
      <div class="menu-item" *ngFor="let cat of categories" [ngClass]="{active: active === cat.class}" [hidden]="hideCategories">
        <a (click)="filterCategory($event, cat)">
          <div class="floating-container">
            <i class="svg-icon __wide __wide-18 {{cat.class}}" [ngClass]="active === cat.class ? '__clr-primary' : '__clr-default'"></i>
            <div class="icon_nav-text menu-item-text">{{cat.text}}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div id="inbox-items-container" *ngIf="inboxItemsLoaded" class="grid" [ngClass]="hideCategories ? 'col-large-12' : 'col-large-9'">
    <div class="floating-container margin-top-16 hidden-breakpoint-large width-full">
      <button id="filter-toggle-button" class="mp-button floating-container float-right margin-right-0" (click)="openSidebar()">
        <span class="filterText icon-text">{{ ls.locs['loc'].Filter }}</span>
        <i class="svg-icon __clr-default __size-18 __top--1 margin-left-4 __filter float-none-important"></i>
      </button>
    </div>
    <div *ngIf="totalrecordcount === 0" class="margin-top-24 alert alert-info" [innerHTML]="ls.locs['loc'].KeinePostfacheintraegeGefunden"></div>
    <div id="today-header" *ngIf="elementsToday && elementsToday.length > 0" class="padding-top-16 inbox-section">
      <div mpCoreInboxStickHeadline class="inbox-header today">{{ ls.locs['loc'].DatumHeute }}</div>
      <mp-core-inbox-item *ngFor="let item of elementsToday" [item]="item" (deleteItem)="toggleDeleteItemModal($event)"></mp-core-inbox-item>
    </div>
    <div id="yesterday-header" *ngIf="elementsYesterday && elementsYesterday.length > 0" class="padding-top-16 inbox-section">
      <div mpCoreInboxStickHeadline class="inbox-header yesterday">{{ ls.locs['loc'].DatumGestern }}</div>
      <mp-core-inbox-item *ngFor="let item of elementsYesterday" [item]="item" (deleteItem)="toggleDeleteItemModal($event)"></mp-core-inbox-item>
    </div>
    <div id="lastweek-header" *ngIf="elementsLastWeek && elementsLastWeek.length > 0" class="padding-top-16 inbox-section">
      <div mpCoreInboxStickHeadline class="inbox-header lastweek">{{ ls.locs['loc'].Letzte7Tage }}</div>
      <mp-core-inbox-item *ngFor="let item of elementsLastWeek" [item]="item" (deleteItem)="toggleDeleteItemModal($event)"></mp-core-inbox-item>
    </div>
    <div id="lastmonth-header" *ngIf="elementsLastMonth && elementsLastMonth.length > 0" class="padding-top-16 inbox-section">
      <div mpCoreInboxStickHeadline class="inbox-header lastmonth">{{ ls.locs['loc'].Letzte30Tage }}</div>
      <mp-core-inbox-item *ngFor="let item of elementsLastMonth" [item]="item" (deleteItem)="toggleDeleteItemModal($event)"></mp-core-inbox-item>
    </div>
    <div id="lastyear-header" *ngIf="elementsLastYear && elementsLastYear.length > 0" class="padding-top-16 inbox-section">
      <div mpCoreInboxStickHeadline class="inbox-header lastyear">{{ ls.locs['loc'].Letzte12Monate }}</div>
      <mp-core-inbox-item *ngFor="let item of elementsLastYear" [item]="item" (deleteItem)="toggleDeleteItemModal($event)"></mp-core-inbox-item>
    </div>
    <div id="earlier-header" *ngIf="elementsBefore && elementsBefore.length > 0" class="padding-top-16 inbox-section">
      <div mpCoreInboxStickHeadline class="inbox-header earlier">{{ ls.locs['loc'].Frueher }}</div>
      <mp-core-inbox-item *ngFor="let item of elementsBefore" [item]="item" (deleteItem)="toggleDeleteItemModal($event)"></mp-core-inbox-item>
    </div>
    <a class="mp-button margin-top-48 margin-bottom-32" *ngIf="totalrecordcount > startindex" (click)="load(false, $event)">{{ ls.locs['loc'].WeitereLaden }}</a>
  </div>
  <mp-core-modal [show]="showDeleteItemModal" title="{{ ls.locs['loc'].NachrichtLoeschen }}" [buttons]="[{text: ls.locs['loc'].Nein, action: toggleDeleteItemModalFunc}, {text: ls.locs['loc'].Ja, action: deleteItemFunc, actionParam: itemToDelete, class: '_prim'}]">
    <p class="margin-bottom-0">
      {{ ls.locs['loc'].NachrichtWirklichInPapierkorb }}
    </p>
  </mp-core-modal>
</div>
