import { Component, OnInit } from '@angular/core';

import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the functions for
 * the additional payment overview.
 */
@Component({
  selector: 'mp-zz-additional-payment-overview',
  templateUrl: './additional-payment-overview.component.html',
  styleUrls: ['./additional-payment-overview.component.scss']
})
export class AdditionalPaymentOverviewComponent implements OnInit {

  public excelService = '/api/Zuzahlung/getZuzahlungsuebersicht2Excel';
  public additionalPaymentOverviewLoader: MpLoader = {
    name: 'additionalPaymentOverviewLoader',
    params: {},
    isReady: true
  };
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  constructor(
    public ls: MpLocalizationService,
    private _mpLoader: MpLoaderService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the title and menu highlighting,
   * registers the loader.
   */
  ngOnInit(): void {
    this._mpLoader.registerLoader(this.additionalPaymentOverviewLoader);
    this._titleService.setTitleFromLoc('Zuzahlungsuebersicht');
    this._mpMenuHighlight.setMenuHighlight('statistik');
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('additionalPaymentOverviewLoader');
  }

}
