<div class="layout-container manager-text-editor"
     id="theme-stage-editor"
     *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)"
     [ngClass]="{'communication-process position-relative': communicationProcess}"
     mpCoreMpContentFullHeight>
  <section class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb"
         class="display-flex flex-col-12 flex-align-items-center margin-bottom-8 padding-left-4">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info"
           [hidden]="communicationProcess"
           (click)="goBack($event)">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <a class="mp-button text-color-grey-info"
           [hidden]="!communicationProcess"
           href="#/{{role}}/Home">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Kommunikation }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular position-relative"
           [hidden]="communicationProcess"
           [innerHTML]="ls.locs['loc'].ThemenbuehneAnlegen">
        </a>
        <a class="mp-button text-color-grey-info font-weight-regular position-relative top-2"
           [hidden]="!communicationProcess"
           [innerHTML]="ls.locs['loc'].KommunikationAnlegenBreadcrumb">
        </a>
      </div>
    </div>

    <mp-core-communication-process-stepper *ngIf="communicationProcess"></mp-core-communication-process-stepper>

    <!--Select area-->
    <section class="flex-grid flex-col-12">
      <section id="themenbuehnen-bereich"
               class="flex-col-12 flex-col-4-l flex-col-5-m flex-col-12-s margin-top-24">
        <div class="flex-col-12 bg-color-white padding-16 flex-direction-column"
             [hidden]="communicationProcess"
             *ngIf="stage && mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea">
          <h3>{{ ls.locs['loc'].ThemenbuehnenBereichAnlegen }}</h3>
          <span class="display-block">
            <mp-core-sidebar-input class="width-full margin-top-24"
                                   text="{{ typeSidebarStageAreaParams['text'] }}"
                                   label="{{ ls.locs['loc'].ThemeStageArea }}"
                                   sidebarComponent="SidebarMultiselect"
                                   [sidebarParams]="typeSidebarStageAreaParams"
                                   [mpRequired]="true">
            </mp-core-sidebar-input>
          </span>
        </div>
      </section>
      </section>
      <section id="stage-with-extern"
               class="flex-grid flex-col-12"
               [hidden]="!communicationProcess
             && mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea
             && (!typeSidebarStageAreaParams['selectedList'] || typeSidebarStageAreaParams['selectedList'].length === 0)">

        <div class="margin-top-16 flex-col-12 flex-direction-column"
             [hidden]="communicationProcess"
             *ngIf="stage">
          <div id="editor-Container-headline" class="flex-col-12 bg-color-white padding-16 flex-direction-column">
            <h3>{{ ls.locs['loc'].ThemenbuehnenAnlegen }}</h3>
            <span>
              <mp-core-input [(model)]="stage.Titel"
                             mpPlaceholder="{{ ls.locs['loc'].ThemenbuehnenNamen }}"
                             [mpRequired]="true"
                             label="{{ ls.locs['loc'].ThemenbuehnenNamen }}"
                             (modelChange)="onChangeTitle()">
              </mp-core-input>
              <mp-core-form-error *ngIf="errors['stage.Titel']"
                                  [model]="errors['stage.Titel']">
              </mp-core-form-error>
            </span>
          </div>
        </div>

        <div *ngIf="themeStageTemplate" class="width-full bg-color-white margin-left-right-8 padding-left-right-16 padding-top-16 box-sizing-border-box padding-bottom-40"
             [hidden]="!themeStageTemplates || themeStageTemplates && themeStageTemplates.length === 0 ">
          <h3 class="width-full margin-bottom-16">{{ ls.locs['loc'].ThemenbuehnenVorlageTitel }}</h3>
          <div class="width-full margin-top-8">
            <mp-core-select label="{{ ls.locs['loc'].ThemenbuehnenTemplate }}"
                            [(model)]="themeStageTemplate"
                            [elements]="themeStageTemplates"
                            textProperty="Displaytext"
                            (ngChange)="loadTemplate()"
                            placeholder="{{ ls.locs['loc'].BitteWaehlen }}"
                            valueProperty="Identifier"
                            [mpRequired]="true"
                            class="fallback-select">
            </mp-core-select>
          </div>
        </div>

        <section id="stage-preview"
                 class="stage-container margin-top-16 flex-col-12 flex-direction-column bg-color-transparent">
          <div class="stage box-sizing-border-box overflow-hidden">
            <div class="owl-theme">
              <div class="owl-carousel owl-loaded">
                <div class="owl-stage-outer">
                  <div class="owl-stage">
                    <div class="owl-item active width-full box-sizing-border-box overflow-hidden">
                      <div class="item">
                        <div class="owl-inner">
                          <img [src]="themeStage['ImagePath']" alt="">
                          <div class="caption">
                            <div>
                              <h1 *ngIf="themeStage['Titel']" [innerHTML]="trustAsHtml(themeStage['Titel'])"></h1>
                            </div>
                            <span *ngIf="themeStage['Teaser']" [innerHTML]="trustAsHtml(themeStage['Teaser'])"></span>
                            <div class="read-more-buttons" *ngIf="themeStage">
                              <!--Buttons f�r Typ 1,3,4,5,6 (Detailtext Interner Lini, Pr�mie, Kategorie und Mark) - diese haben keinen Effekt-->
                              <div class="mp-button _prim button-large read-more"
                                   [hidden]="!themeStage['Button1Bezeichnung'] || themeStage['Button1Bezeichnung'] && themeStage['Button1Bezeichnung'].length === 0 || themeStage['Button1Bezeichnung'] && themeStage['Button1Bezeichnung'].length > 0 && (themeStage['Button1TypId'] === 2 || themeStage['Button1TypId'] === 7)">
                                {{themeStage['Button1Bezeichnung']}}
                              </div>
                              <div class="mp-button _prim button-large read-more"
                                   [hidden]="!themeStage['Button2Bezeichnung'] || themeStage['Button2Bezeichnung'] && themeStage['Button2Bezeichnung'].length === 0 || themeStage['Button2Bezeichnung'] && themeStage['Button2Bezeichnung'].length > 0 && (themeStage['Button2TypId'] === 2 || themeStage['Button2TypId'] === 7)">
                                {{themeStage['Button2Bezeichnung']}}
                              </div>
                              <!--Buttons f�r Typ 2 und 7 (Externer Link, Detailtext) - diese fungieren normal als Link-->
                              <a class="mp-button _prim button-large read-more"
                                 [hidden]="!themeStage['Button1Bezeichnung'] || themeStage['Button1Bezeichnung'] && themeStage['Button1Bezeichnung'].length === 0 || themeStage['Button1Bezeichnung'] && themeStage['Button1Bezeichnung'].length > 0 && themeStage['Button1TypId'] !== 2 && themeStage['Button1TypId'] !== 7"
                                 href="{{themeStage['Button1LinkURL'] | angularjsToAnuglarUrl}}"
                                 target="{{themeStage['Button1InNeuemTab'] ? '_blank' : '_self'}}">
                                {{themeStage['Button1Bezeichnung']}}
                              </a>
                              <a class="mp-button _prim button-large read-more"
                                 [hidden]="!themeStage['Button2Bezeichnung'] || themeStage['Button2Bezeichnung'] && themeStage['Button2Bezeichnung'].length === 0 || themeStage['Button2Bezeichnung'] && themeStage['Button2Bezeichnung'].length > 0 && themeStage['Button2TypId'] !== 2 && themeStage['Button2TypId'] !== 7"
                                 href="{{themeStage['Button2LinkURL'] | angularjsToAnuglarUrl}}"
                                 target="{{themeStage['Button2InNeuemTab'] ? '_blank' : '_self'}}">
                                {{themeStage['Button2Bezeichnung']}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span id="_preview-notice"
                            class="position-absolute bottom-0 box-sizing-border-box display-flex flex-align-items-center flex-justify-cont-center text-transform-uppercase padding-bottom-8 padding-left-right-40">
                        {{ ls.locs['loc'].Vorschau }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="elig-requ-editor-content"
                 class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-24">
          <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages && stage && languages" id="tb-language-select-area">
            <owl-carousel-o *ngIf="(mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea && typeSidebarStageAreaParams['selectedList'] && typeSidebarStageAreaParams['selectedList'].length !== 0 || !mpSettings.settings['BuehnenEditorSettings'].CanSelectThemedStagePublicArea) && stage.Contents.length > 0 && showLanguageCarousel" #owlElement id="language-carousel" class="owl-theme" [options]="languageCarouselProperties" (translated)="handleOwlNavStyles()" (initialized)="handleOwlNavStyles()">
              <ng-container *ngFor="let content of stage.Contents; trackBy: trackByIndex">
                <ng-template carouselSlide [width]="190" [id]="'lang-item-id-'+content.Language">
                  <div class="display-flex-important item item-{{content.Language}}" (click)="setSelectedContent(content)">
                    <span class="mp-button language-carousel-button language-carousel-button--label"
                          [ngClass]="content === selectedContent ? '_prim' : 'bg-color-white'"
                          *ngIf="languages[content.Language]"
                          [innerText]="languages[content.Language].Name">
                    </span>
                    <span class="mp-button padding-4"
                          [ngClass]="content === selectedContent ? '_prim' : 'bg-color-white'"
                          [hidden]="!stage.Contents || stage.Contents && stage.Contents.length <= 1">
                      <i class="svg-icon __size-18 __clear"
                         [ngClass]="content === selectedContent ? '__clr-white' : '__clr-default'"
                         (click)="initDeleteContent(content)">
                      </i>
                    </span>
                    <span class="mp-button padding-4"
                          [ngClass]="{'bg-color-white': content !== selectedContent, '_prim': content === selectedContent, '__clr-inactive': !selectableLanguages || selectableLanguages && selectableLanguages.length === 0}"
                          [attr.disabled]="!selectableLanguages || selectableLanguages && selectableLanguages.length === 0 ? 'disabled' : null">
                      <i class="svg-icon __size-18 __arrow-dropdown"
                         [ngClass]="content === selectedContent ? '__clr-white' : '__clr-default'"
                         (click)="initChangeLanguage(content)">
                      </i>
                    </span>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
            <a class="mp-button _prim"
               [ngClass]="{'disabled' : selectableLanguages && selectableLanguages.length < 1}"
               (click)="$event.preventDefault(); selectableLanguages && selectableLanguages.length >= 1 ? hints['showAddLanguage'] = true : hints['showAddLanguage'] = false">
              {{ ls.locs['loc'].ThemenbuehnenSpracheHinzufuegen }}
            </a>
          </div>
          <div>
            <div class="width-full bg-color-white padding-top-bottom-16 padding-left-right-16 padding-bottom-8 box-sizing-border-box">
              <ul class="magma-accordion editor-acc">
                <li id="acc-data-id-headline"
                    class="padding-left-right-0 padding-bottom-24 fallback-acc accOpened">
                  <div id="editor-Container-headline"
                       class="bg-color-white display-block padding-left-right-16 margin-bottom-0">
                    <div>
                      <h3>{{ ls.locs['loc'].ThemenbuehnenInhalteAnlegen }}</h3>
                      <span class="display-block">
                        <mp-core-input class="margin-top-16"
                                       mpId="acc-editor-headlinetext"
                                       [mpRequired]="true"
                                       [(model)]="selectedContent['Headline']"
                                       label="{{ ls.locs['loc'].ThemenbuehnenUeberschrift }}"
                                       mpPlaceholder="{{ ls.locs['loc'].ThemenbuehnenHeadline }}"
                                       (modelChange)="onChangeHeadline()">
                        </mp-core-input>
                        <mp-core-form-error *ngIf="stage && errors['stage.Contents['+stage.Contents.indexOf(selectedContent)+'].Headline']"
                                            [model]="errors['stage.Contents['+stage.Contents.indexOf(selectedContent)+'].Headline']">
                        </mp-core-form-error>
                      </span>
                    </div>
                  </div>
                </li>
                <li id="acc-data-id-teaser"
                    class="padding-left-right-0 padding-bottom-24 fallback-acc"
                    [ngClass]="{'accOpened': teaserOpen}"
                    [hidden]="stage && !stage.IsTeaserComponentAdded">
                  <div class="display-flex margin-left-right-0 elig-requ-acc-flex-container">
                    <button class="mp-button _prim padding-left-right-4 margin-right-8 elig-requ-delete-button"
                            (click)="initDeleteTeaser(selectedContent)">
                      <i class="svg-icon __top-0 __clr-white __clear __size-16"></i>
                    </button>
                    <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2 margin-left-right-0 acc-headline-open-trigger"
                         (click)="teaserOpen = !teaserOpen">
                      <p class="margin-right-16">
                        <a href>{{ ls.locs['loc'].ThemenbuehnenTeaser }}</a>
                      </p>
                      <i class="svg-icon __size-28 __top-0"
                         [ngClass]="!teaserOpen ? '__clr-inactive __add' : '__clr-white __remove'">
                      </i>
                    </div>
                  </div>
                  <div *ngIf="teaserOpen"
                       id="editor-Container"
                       class="editor-froala bg-color-white display-block padding-top-8 padding-bottom-0 padding-left-right-16 margin-top-8 margin-bottom-0">
                    <div>
                      <span class="display-block" *ngIf="froalaOptionsTeaserLoaded">
                        <textarea id="acc-editor-teasertext"
                                  [froalaEditor]="froalaOptionsTeaser"
                                  (froalaInit)="getFroalaControlsTeaser($event)"
                                  [froalaModel]="selectedContent['Teaser']"
                                  (froalaModelChange)="changedTeaserByFroale($event)"
                                  class="form-control margin-top-16"
                                  name="acc-editor-teasertext"
                                  required
                                  [attr.placeholder]="ls.locs['loc'].Teaserinhalt"></textarea>
                      </span>
                    </div>
                  </div>
                </li>
                <li id="acc-data-id-button1"
                    class="padding-left-right-0 padding-bottom-24 fallback-acc"
                    *ngIf="selectedContent['Button1']"
                    [ngClass]="{'accOpened': button1Open}"
                    [hidden]="!selectedContent['Button1']">
                  <div class="display-flex margin-left-right-0 elig-requ-acc-flex-container">
                    <button class="mp-button _prim padding-left-right-4 margin-right-8 elig-requ-delete-button"
                            (click)="initDeleteButton1(selectedContent)">
                      <i class="svg-icon __top-0 __clr-white __clear __size-16"></i>
                    </button>
                    <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2 margin-left-right-0 acc-headline-open-trigger"
                         (click)="button1Open = !button1Open">
                      <p class="margin-right-16">
                        <a [hidden]="selectedContent['Button1'].TypId === 1 ">{{ ls.locs['loc'].Button1 }}</a>
                        <a [hidden]="selectedContent['Button1'].TypId !== 1 ">{{ ls.locs['loc'].ThemenbuehnenDetailtext }}</a>
                      </p>
                      <i class="svg-icon __size-28 __top-0"
                         [ngClass]="!button1Open ? '__clr-inactive __add' : '__clr-white __remove'">
                      </i>
                    </div>
                  </div>
                  <div *ngIf="button1Open"
                       id="editor-Container"
                       class="bg-color-white display-block padding-top-8 padding-bottom-0 padding-left-right-16 margin-top-8 margin-bottom-0">
                    <div>
                      <mp-core-theme-stage-editor-button [button]="selectedContent['Button1']"
                                                         (buttonChange)="updatePreview()"
                                                         [(content)]="selectedContent"
                                                         [buttonTypes]="buttonTypes"
                                                         [tbsList]="tbsList"
                                                         [buttonIndex]="1"
                                                         [errorLabel]="stage && errors['stage.Contents['+stage.Contents.indexOf(selectedContent)+'].Button1.Bezeichnung']">
                      </mp-core-theme-stage-editor-button>

                    </div>
                  </div>
                </li>
                <li id="acc-data-id-button2"
                    class="padding-left-right-0 padding-bottom-24 fallback-acc"
                    [ngClass]="{'accOpened': button2Open}"
                    *ngIf="selectedContent['Button2']"
                    [hidden]="!selectedContent['Button2']">
                  <div class="display-flex margin-left-right-0 elig-requ-acc-flex-container">
                    <button class="mp-button _prim padding-left-right-4 margin-right-8 elig-requ-delete-button"
                            (click)="initDeleteButton2(selectedContent)">
                      <i class="svg-icon __top-0 __clr-white __clear __size-16"></i>
                    </button>
                    <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-16 padding-left-8 padding-right-2 margin-left-right-0 acc-headline-open-trigger"
                         (click)="button2Open = !button2Open">
                      <p class="margin-right-16">
                        <a [hidden]="selectedContent['Button2'].TypId === 1 ">{{ ls.locs['loc'].Button2 }}</a>
                        <a [hidden]="selectedContent['Button2'].TypId !== 1 ">{{ ls.locs['loc'].ThemenbuehnenDetailtext }}</a>
                      </p>
                      <i class="svg-icon __size-28 __top-0"
                         [ngClass]="!button2Open ? '__clr-inactive __add' : '__clr-white __remove'">
                      </i>
                    </div>
                  </div>
                  <div *ngIf="button2Open"
                       id="editor-Container"
                       class="bg-color-white display-block padding-top-8 padding-bottom-0 padding-left-right-16 margin-top-8 margin-bottom-0">
                    <div>
                      <mp-core-theme-stage-editor-button [button]="selectedContent['Button2']"
                                                         (buttonChange)="updatePreview()"
                                                         [(content)]="selectedContent"
                                                         [buttonTypes]="buttonTypes"
                                                         [tbsList]="tbsList"
                                                         [buttonIndex]="2"
                                                         [errorLabel]="stage && errors['stage.Contents['+stage.Contents.indexOf(selectedContent)+'].Button2.Bezeichnung']">
                      </mp-core-theme-stage-editor-button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="dashed-border-button margin-top-16"
                 id="add-additional-question"
                 [hidden]="!selectedContent['themenbuehnenElementTypes'] || selectedContent['themenbuehnenElementTypes'] && selectedContent['themenbuehnenElementTypes'].length === 0 || stage && stage.IsPublicAreaSelected && stage.IsTeaserComponentAdded">
              <mp-core-select class="margin-0 fallback-select"
                              [(model)]="selectedContent['elementTyp']"
                              (ngChange)="addElement(selectedContent)"
                              [elements]="selectedContent['themenbuehnenElementTypes']"
                              textProperty="Label"
                              valueProperty="Typ"
                              placeholder="{{ ls.locs['loc'].ThemenbuehneElementHinzufuegen }}">
              </mp-core-select>
            </div>
          </div>
        </section>
        <section id="reward-editor-datapanel"
                 class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-24">
          <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full">
            <span class="width-full margin-top-24"
                  [hidden]="!dateFixedByCp">
              {{ ls.locs['loc'].ThemenbuehnenHinweis }}
            </span>
            <!--Zeitraum-->
            <mp-core-sidebar-input class="width-full margin-top-24"
                                   [hasError]="errors['stageDate'] ? true : false"
                                   text="{{stageDate['text']}}"
                                   label="{{ ls.locs['loc'].Zeitraum }}"
                                   [hidden]="dateFixedByCp"
                                   sidebarComponent="SidebarPeriodTime"
                                   [sidebarParams]="stageDate"
                                   [mpRequired]="true">
            </mp-core-sidebar-input>
            <div class="width-full margin-top-0"
                 [hidden]="communicationProcess">

              <!--Gruppen-->
              <div *ngIf="stage && mpSettings.settings['NetcentiveCoreSettings'].ShowGruppenInEditoren
                      && mpSettings.settings['BuehnenEditorSettings'].CanSelectUserGroups"
                   class="width-full margin-top-24">
                <mp-core-sidebar-input [hidden]="stage.HasQuery || stage.IsPublicAreaSelected"
                                       text="{{groupSidebarParams['text']}}"
                                       label="{{ ls.locs['loc'].Empfaengergruppe }}"
                                       sidebarComponent="SidebarMultiselect"
                                       [sidebarParams]="groupSidebarParams"
                                       [mpRequired]="true">
                </mp-core-sidebar-input>
              </div>
              <!--Gruppierungen-->
              <div *ngIf="stage && mpSettings.settings['NetcentiveCoreSettings'].HasSecondaryGrouping
                      && mpSettings.settings['BuehnenEditorSettings'].CanSelectSecondaryGrouping"
                   class="width-full margin-top-24">
                <mp-core-sidebar-input [hidden]="stage.HasQuery || stage.IsPublicAreaSelected"
                                       text="{{groupingSidebarParams['text']}}"
                                       label="{{ ls.locs['loc'].Gruppierung }}"
                                       sidebarComponent="SidebarMultiselect"
                                       [sidebarParams]="groupingSidebarParams"
                                       [mpRequired]="true">
                </mp-core-sidebar-input>
              </div>
              <!--Laender-->
              <div *ngIf="stage && mpSettings.settings['NetcentiveCoreSettings'].Laender.length > 1 && mpSettings.settings['BuehnenEditorSettings'].CanSelectCountries"
                   class="width-full margin-top-24">
                <mp-core-sidebar-input [hidden]="stage.HasQuery || stage.IsPublicAreaSelected"
                                       text="{{countriesSidebarParams['text']}}"
                                       label="{{ ls.locs['loc'].Laender }}"
                                       sidebarComponent="SidebarMultiselect"
                                       [sidebarParams]="countriesSidebarParams"
                                       [mpRequired]="true">
                </mp-core-sidebar-input>
              </div>
              <span *ngIf="stage" [hidden]="!stage.HasQuery" class="width-full margin-top-24">
                {{ ls.locs['loc'].ThemenbuehneHasQueryInfo }}
              </span>
            </div>

            <div class="upload-buehne margin-top-24 width-full" *ngIf="stage">
              <button type="button"
                      id="btn-upload-buehne"
                      class="mp-button _prim nonefloat distanceforButton width-full"
                      (click)="fileUpload.click()"
                      [disabled]="!stage.Titel && !communicationProcess"
                      [ngClass]="{disabled: !stage.Titel && !communicationProcess}">
                {{ ls.locs['loc'].BildHochladen }}
              </button>
              <input #fileUpload name="fileUpload" type="file" class="display-none" accept="image/jpeg" (change)="uploadFiles($event)">
              <mp-core-form-error *ngIf="errors['stage.ImagePath']"
                                  [model]="errors['stage.ImagePath']">
              </mp-core-form-error>
              <div [hidden]="stage.ImagePath"
                   class="margin-top-16 display-flex">
                <span>{{ ls.locs['loc'].Format }}: {{mpSettings.settings['BuehnenEditorSettings'].BuehnenBildUploadBreite}} x {{mpSettings.settings['BuehnenEditorSettings'].BuehnenBildUploadHoehe}} px</span>
              </div>
              <div [hidden]="!file && !stage.ImagePath || !file && stage.ImagePath"
                   class="margin-top-16 display-flex">
                <span class="svg-icon __clr-danger __top-0 __size-18 __oneway default-cursor-hover float-none-important margin-right-4"
                      [hidden]="!file || uploadSuccessfully">
                </span>
                <span class="inputRequired"
                      [hidden]="errorUpload === ''">
                  {{errorUpload}}
                </span>
              </div>
              <div [ngClass]="{disabled: !stage.ImagePath || stage.ImagePath === '/themes/default/media/buehnen/home/VorlageLeer.jpg'}"
                   [hidden]="!stage.ImagePath || stage.ImagePath === '/themes/default/media/buehnen/home/VorlageLeer.jpg' || errors['stage.ImagePath']"
                   class="margin-top-16">
                <a href="{{stage.ImagePath}}"
                   target="_blank">
                  {{ ls.locs['loc'].BildAnsehen }}
                </a>
                <span class="svg-icon __clr-success __top-4 __size-18 __check-circle default-cursor-hover float-none-important"></span>
              </div>
            </div>
            <div *ngIf="communicationProcess"
                 class="width-full">
              <div class="width-full margin-top-40">
                <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}"
                                 alignment="left"
                                 class="float-right-important">
                  <button class="mp-button"
                          (click)="cpShowResetConfirm(loadData)">
                    <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
                       id="elig-requ-refresh-icon">
                    </i>
                  </button>
                </mp-core-tooltip>
              </div>
              <button class="mp-button _sec margin-top-16 width-full"
                      (click)="saveCommunicationProcess()">
                {{ ls.locs['loc'].Speichern }}
              </button>
              <a *ngIf="cp.currentStep" class="mp-button _prim margin-top-16 width-full"
                 (click)="goToNextStep($event)"
                 [ngClass]="{disabled: !validateStep()}">
                {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
              </a>
            </div>
            <div [hidden]="communicationProcess"
                 class="width-full">
              <div class="width-full margin-top-40">
                <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}"
                                 alignment="left"
                                 class="float-right-important">
                  <button class="mp-button"
                          (click)="updateHints('showRevertChanges', true)">
                    <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
                       id="elig-requ-refresh-icon">
                    </i>
                  </button>
                </mp-core-tooltip>
              </div>
              <button class="mp-button _sec margin-top-16 width-full"
                      (click)="newStage()">
                {{ ls.locs['loc'].NeueThemenbuehneAnlegen }}
              </button>
              <button class="mp-button _sec margin-top-16 width-full"
                      *ngIf="stage"
                      [attr.disabled]="!(stage.Titel && selectedContent['Headline'] && stage.ImagePath) ? '' : null"
                      (click)="updateHints('showSaveThemeStage', true)"
                      [ngClass]="{disabled: !(stage.Titel && selectedContent['Headline'] && stage.ImagePath)}">
                {{ ls.locs['loc'].ThemenbuehneSpeichern }}
              </button>
              <button class="mp-button _prim margin-top-16 width-full"
                      *ngIf="stage"
                      [attr.disabled]="!(stage.Titel && selectedContent['Headline'] && stage.ImagePath && stage.ImagePath !== defaultImagePath && (isRecivedGrouping || isGrouping)) ? '' : null"
                      [hidden]="stage.Freigegeben"
                      (click)="updateHints('showApprove', true)"
                      [ngClass]="{disabled: !(stage.Titel && selectedContent['Headline'] && stage.ImagePath && stage.ImagePath !== defaultImagePath && (isRecivedGrouping || isGrouping))}">
                {{ ls.locs['loc'].ThemenbuehneVeroeffentlichen }}
              </button>
              <button class="mp-button _prim margin-top-16 width-full"
                      *ngIf="stage"
                      [hidden]="!stage.Freigegeben"
                      (click)="updateHints('showNotApprove', true)">
                {{ ls.locs['loc'].ThemenbuehneNichtVeroeffentlichen }}
              </button>
              <button *ngIf="admin && stage" class="mp-button _prim margin-top-16 width-full"
                      (click)="deleteThemeStage(stage.Id)">
                {{ ls.locs['loc'].ThemenbuehneLoeschen }}
              </button>
            </div>
          </div>
        </section>
        <div *ngIf="communicationProcess && cp.currentStep"
             class="flex-grid width-full-important hide-on-mobile position-absolute bottom-48 left-0">
          <div class="flex-col-9 flex-col-12-l flex-col-12-m padding-left-right-16 display-flex flex-direction-row flex-justify-cont-between">
            <a class="mp-button"
               (click)="cpShowClearConfirm($event)">
              {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
            </a>
            <div>
              <a class="mp-button margin-right-16"
                 (click)="goToPreviousStep($event)">
                {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
              </a>
              <a class="mp-button _prim float-right"
                 (click)="goToNextStep($event)"
                 [ngClass]="{disabled: !validateStep()}">
                {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
              </a>
            </div>
          </div>
        </div>
        <div stickyBottom
             *ngIf="communicationProcess && cp.currentStep"
             class="bestellung-nav-row floating-container show-on-mobile bg-color-white padding-top-bottom-0 width-full text-align-center wk-bottom-buttons">
          <a class="mp-button _prim padding-top-bottom-8 width-full display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
             (click)="goToNextStep($event)"
             [ngClass]="{disabled: !validateStep()}">
            <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
            <span class="display-block">{{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}</span>
          </a>
          <div class="width-full padding-left-right-8 box-sizing-border-box display-flex flex-direction-row flex-justify-cont-between">
            <a class="mp-button"
               (click)="goToPreviousStep($event)">
              {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
            </a>
            <a class="mp-button"
               (click)="cpShowClearConfirm($event)">
              {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
            </a>
          </div>
        </div>
      </section>

      <mp-core-modal [show]="hints['showSaveThemeStage']"
                     title="{{ ls.locs['loc'].ThemenbuehneSpeichernHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showSaveThemeStage'}, {text: ls.locs['loc'].Ja, action: saveFunc, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneSpeichernHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showApprove']"
                     title="{{ ls.locs['loc'].ThemenbuehneVeroeffentlichenHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showApprove'}, {text: ls.locs['loc'].Ja, action: saveFunc, actionParam: true, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneVeroeffentlichenHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showNotApprove']"
                     title="{{ ls.locs['loc'].ThemenbuehneNichtVeroeffentlichenHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showNotApprove'}, {text: ls.locs['loc'].Ja, action: saveFunc, actionParam: false, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneNichtVeroeffentlichenHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showRevertChanges']"
                     title="{{ ls.locs['loc'].AenderungenRueckgaengigHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showRevertChanges'}, {text: ls.locs['loc'].Ja, action: loadDataFunc, class: '_prim'}]">
        {{ ls.locs['loc'].AenderungenRueckgaengigHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showDeleteContent']"
                     title="{{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showDeleteContent'}, {text: ls.locs['loc'].Ja, action: deleteContentFunc, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showDeleteTeaser']"
                     title="{{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showDeleteTeaser'}, {text: ls.locs['loc'].Ja, action: deleteTeaserFunc, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showDeleteButton1']"
                     title="{{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showDeleteButton1'}, {text: ls.locs['loc'].Ja, action: deleteButton1Func, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showDeleteButton2']"
                     title="{{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showDeleteButton2'}, {text: ls.locs['loc'].Ja, action: deleteButton2Func, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneContentLoeschenHinweisText }}
      </mp-core-modal>

      <mp-core-modal [show]="hints['showAddLanguage']"
                     title="{{ ls.locs['loc'].ThemenbuehnenSpracheHinzufuegen }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showAddLanguage'}, {text: ls.locs['loc'].Ja, action: addLanguageFunc, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehnenSpracheHinzufuegenText }}
        <mp-core-select class="width-full margin-bottom-16"
                        *ngIf="stage"
                        mpId="reward-type"
                        [(model)]="stage.Sprache"
                        [elements]="selectableLanguages"
                        textProperty="Name"
                        valueProperty="Kuerzel"
                        [mpRequired]="true"
                        label="{{ ls.locs['loc'].Sprache }}">
        </mp-core-select>
      </mp-core-modal>

      <mp-core-modal [show]="hints['showChangeLanguage']"
                     title="{{ ls.locs['loc'].ThemenbuehneChangeLanguageHinweisTitel }}"
                     [buttons]="[{text: ls.locs['loc'].Nein, action: hideHint, actionParam: 'showChangeLanguage'}, {text: ls.locs['loc'].Ja, action: changeLanguageFunc, class: '_prim'}]">
        {{ ls.locs['loc'].ThemenbuehneChangeLanguageHinweisText }}
        <mp-core-select class="width-full margin-bottom-16"
                        *ngIf="stage"
                        mpId="reward-type"
                        [(model)]="stage.Sprache"
                        [elements]="selectableLanguages"
                        textProperty="Name"
                        valueProperty="Kuerzel"
                        [mpRequired]="true"
                        label="{{ ls.locs['loc'].Sprache }}">
        </mp-core-select>
      </mp-core-modal>
    </section>
</div>
