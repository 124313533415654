<div class="layout-container box-padding-16" *ngIf="(ls.locsLoaded | async)">
  <h1 class="display-inline-block">{{ ls.locs['loc'].PasswortAendern }}</h1>
  <mp-core-infotext [sitename]="'PasswortAendern'" class="headline-info-popover"></mp-core-infotext>
  <div class="form">
    <form name="formPasswort"
          #formPassword="ngForm"
          class="margin-top-16"
          novalidate>
      <div
        class="margin-top-16">
        <mp-core-input mpType="password"
                  mpId="oldpw"
                  [(model)]="pwd['OldPassword']"
                  label="{{ ls.locs['loc'].PasswortAlt }}"
                  [mpRequired]="true"
                  [hasError]="errors['model.OldPassword'] ? true : false">
        </mp-core-input>
        <mp-core-form-error *ngIf="errors['model.OldPassword']"
                       [model]="errors['model.OldPassword']">
        </mp-core-form-error>
      </div>
      <div
        class="margin-top-16">
        <mp-core-input mpType="password"
                  mpId="newpw"
                  [(model)]="pwd['NewPassword']"
                  label="{{ ls.locs['loc'].PasswortNeu }}"
                  [mpRequired]="true"
                  [hasError]="errors['model.NewPassword'] ? true : false">
        </mp-core-input>
        <mp-core-form-error *ngIf="errors['model.NewPassword']"
                       [model]="errors['model.NewPassword']">
        </mp-core-form-error>
      </div>
      <div
        class="margin-top-16">
        <mp-core-input mpType="password"
                  mpId="confirmpw"
                  [(model)]="pwd['ConfirmPassword']"
                  label="{{ ls.locs['loc'].PasswortNeuConfirm }}"
                  [mpRequired]="true"
                  [hasError]="errors['model.ConfirmPassword'] ? true : false">
        </mp-core-input>
        <mp-core-form-error *ngIf="errors['model.ConfirmPassword']"
                       [model]="errors['model.ConfirmPassword']">
        </mp-core-form-error>
      </div>
    </form>
    <div class="floating-container margin-top-24">
      <button class="mp-button _prim"
              (click)="savePassword()">
        {{ ls.locs['loc'].Speichern }}
      </button>
    </div>
  </div>
</div>
