import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';
import { ApiService } from '@core/services/api.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * This class provides the data and functions
 * for the charity currently infos page.
 */
@Component({
  selector: 'mp-leas-charity-currently-infos',
  templateUrl: './charity-currently-infos.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CharityCurrentlyInfosComponent implements OnInit, OnDestroy {

  public charitySliderProperties: OwlOptions = {
    loop: true,
    nav: true,
    margin: 16,
    items: 1,
    dots: true
  };
  public articleList: Array<any> = [];
  public role: string = '';

  private _getArticleListSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _apiService: ApiService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets the breadcrumb and gets
   * the articles.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._mpBreadcrumbService.setCurrentSiteWithLoc('CharityAktuelleInfos', 'locLeas', 'AktuelleInfos');

    this._getArticleListSubscription = this._apiService.getRequest('/api/Charity/bisherigeBestellungen').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.articleList = [{ ID: 1 }];
        let id = 1;

        data.Records.forEach((rec: { [key: string]: any }) => {
          id = id + 1;
          this.articleList.push(Object.assign(rec, { ID: id }));
        });
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getArticleListSubscription !== 'undefined') {
      this._getArticleListSubscription.unsubscribe();
    }
  }

  /**
   * Used for trackBy within ngFor.
   */
  trackByArticleId(index: number, item: any): number {
    return item.ID;
  }

}
