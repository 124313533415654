<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locZuzahlung'].Zuzahlungsuebersicht }}</h1>
  <mp-core-data-table title="{{ ls.locs['locZuzahlung'].Zuzahlungsuebersicht }}"
                 infotextShorten="Zuzahlunsuebersicht"
                 fieldsAction="/api/Zuzahlung/getZuzahlungsuebersichtFields"
                 listAction="/api/Zuzahlung/getZuzahlungsuebersicht"
                 loaderName="additionalPaymentOverviewLoader"
                 [paging]="true"
                 [showDatePicker]="true"
                 [recLoadedFunc]="recLoadedFunc"
                 [footer]="true">
  </mp-core-data-table>
  <div class="floating-container" *ngIf="loader !== null && loader.getExcelParams">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
