<div *ngIf="(mpSettings.settingsLoaded | async)" class="padding-top-0 padding-left-right-0">
  <div themesStagesAnimations #themesStagesAnimationsRef="themesStagesAnimationsRef" class="stage-container">
    <div class="stage" *ngIf="themesStagesAnimationsRef.owlPropertiesSet">
      <owl-carousel-o #owlElem id="home-carousel"
                      class="owl-theme"
                      [options]="themesStagesAnimationsRef.owlProperties"
                      (translated)="themesStagesAnimationsRef.onTranslated()"
                      (initialized)="themesStagesAnimationsRef.onInitialized(owlElem)">
        <ng-container class="item"
                      *ngFor="let item of homeStages">
          <ng-template carouselSlide [width]="0" style="width: auto;" [id]="'stage-item-id-'+item.Id">
            <img mpCoreStageImageLoaded id="{{item.Id}}" [src]="item.ImagePath + '?width=' + imgWidth + '&height=' + imgHeight + '&mode=crop'" alt="" />
            <div class="caption"
                  [ngClass]="[item.CssClass, !item.Teaser && !item.Button1Bezeichnung && !item.Button2Bezeichnung ? '_only-title' : '']"
                  *ngIf="item.Titel">
              <div>
                <h1 [innerHTML]="trustAsHtml(item.Titel)"></h1>
              </div>
              <span [hidden]="!item.Teaser" [innerHTML]="trustAsHtml(item.Teaser)" [ngClass]="[!item.Button1Bezeichnung  && !item.Button2Bezeichnung? '_no-buttons-following' : '']"></span>
              <div class="read-more-buttons">
                <a class="mp-button _prim button-large read-more"
                    [hidden]="!item.Button1Bezeichnung"
                    href="{{item.Button1LinkURL | angularjsToAnuglarUrl}}"
                    target="{{item.Button1InNeuemTab ? '_blank' : '_self'}}">
                  {{item.Button1Bezeichnung}}
                </a>
                <a class="mp-button _prim button-large read-more"
                    [hidden]="!item.Button2Bezeichnung"
                    href="{{item.Button2LinkURL | angularjsToAnuglarUrl}}"
                    target="{{item.Button2InNeuemTab ? '_blank' : '_self'}}">
                  {{item.Button2Bezeichnung}}
                </a>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <div class="clear"></div>
  </div>

  <div class="layout-container manager-dashboard-container">
    <h1 [innerHTML]="title"></h1>
    <div [innerHTML]="text"></div>
  </div>
</div>
