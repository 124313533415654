import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the prize celendar
 * winner statistic table.
 */
@Component({
  selector: 'mp-gk-prize-calendar-winner-statistic',
  templateUrl: './prize-calendar-winner-statistic.component.html'
})
export class PrizeCalendarWinnerStatisticComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/GewinnkalenderStatistik/GewinnkalenderGewinnerStatistik2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _prizeCalendarWinnerStatisticLoader: MpLoader = {
    name: 'prizeCalendarWinnerStatisticLoader',
    params: {}
  };
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoader: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Sets the loader for the table data.
   */
  ngOnInit(): void {
    this._mpLoader.registerLoader(this._prizeCalendarWinnerStatisticLoader);

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._mpLoader.extendLoader('prizeCalendarWinnerStatisticLoader', {
        isReady: true,
        params: {
          Key: params['key'],
          Nr: params['nr']
        }
      });
      this.loader = this._mpLoader.getLoader('prizeCalendarWinnerStatisticLoader');
    });

    this._titleService.setTitleFromLoc('GewinnkalenderGewinnerStatistik', 'locGewinnkalender');
    this._mpMenuHighlight.setMenuHighlight('statistiken');
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoader.getLoader('prizeCalendarWinnerStatisticLoader');
  }

}
