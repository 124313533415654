import { Pipe, PipeTransform, LOCALE_ID, Inject  } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  transform(inputNumber: any): unknown {
    return formatNumber(inputNumber, this.locale, '1.0-2');
  }

}
