import { Injectable } from '@angular/core';

/**
 * This class handles child templates
 * for the data table.
 */
@Injectable({
  providedIn: 'root'
})
export class DataTableChildsService {

  private _templates: { [key: string]: any } = {};

  constructor() { }

  /**
   * Registers a child template.
   */
  registerTemplate(key: string, template: any): void {
    this._templates[key] = template;
  }

  /**
   * Gets a child template.
   */
  getTemplate(key: string): any {
    return this._templates[key];
  }

}
