import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from '../mp-sidebar.service';
import { ScrollButtonService } from './../../scroll-button/scroll-button.service';
import { CustomEventService, CustomEvent, CustomEventType } from './../../../services/custom-event.service';

/**
 * This class provides the functions and date for
 * the help sidebar.
 */
@Component({
  selector: 'mp-core-sidebar-help',
  templateUrl: './sidebar-help.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarHelpComponent implements OnInit, OnDestroy {

  public params: any;
  public searchTerm: string = '';
  public filtered: Array<any> = [];
  public helpCount: number = 0;
  public filteredHelpFunc = this._filteredHelp.bind(this);

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpSidebar: MpSidebarService,
    private _scrollButtonService: ScrollButtonService,
    private _customEventService: CustomEventService
  ) { }

  /**
   * Gets the sidebar params for the help
   * sidebar.
   */
  ngOnInit(): void {
    this.params = this._mpSidebar.params;

    this._sidebarParamsSubscription = this._mpSidebar.paramsChange.subscribe((params: any) => {
      if (Object.keys(params).length > 0 && this.params !== params) {
        this.params = params;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Escapes the given string.
   */
  private _htmlEscape(str: string): string {
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/ä/g, '&auml;')
      .replace(/ö/g, '&ouml;')
      .replace(/ü/g, '&uuml;')
      .replace(/Ä/g, '&Auml;')
      .replace(/Ö/g, '&Ouml;')
      .replace(/Ü/g, '&Uuml;')
      .replace(/ß/g, '&szlig;');
  }

  /**
   * Filters the help results for the
   * sidebar.
   */
  private _filteredHelp(): Array<any> {
    if (typeof this.params['content'] !== 'undefined') {
      const filtered = this.params['content'].filter((content: any) => {
        const searchTermEscaped = this._htmlEscape(this.searchTerm);
        return !this.searchTerm ||
          content['accHeadline'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
          content['accText'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
          content['accHeadline'].toLowerCase().indexOf(searchTermEscaped.toLowerCase()) > -1 ||
          content['accText'].toLowerCase().indexOf(searchTermEscaped.toLowerCase()) > -1;
      });

      if (!this._scrollButtonService.scrollBtnClicked) {
        const accordionContainer = document.getElementById('help-sidebar-scroll-accordion-container');

        if (accordionContainer !== null) {
          accordionContainer.style.top = '0px';
          this._customEventService.dispatch(new CustomEvent(CustomEventType.ShowHideScrollButtons, accordionContainer));
        }
      }

      this.helpCount = filtered.length;
      const filteredUniq: Array<any> = [];
      const mappedUniq: Array<any> = [];

      const mapped = filtered.map((content: any) => {
        return content['accHeadline'] + content['accText'];
      });

      mapped.forEach((mappedContent: string, index: number) => {
        if (mappedUniq.indexOf(mappedContent) === -1) {
          mappedUniq.push(mappedContent);
          filteredUniq.push(filtered[index]);
        }
      });

      return filteredUniq;
    } else {
      return [];
    }
  }

}
