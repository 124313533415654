import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

/**
 * This class provides the functions to get the
 * theme from the backend api.
 */
@Injectable({
  providedIn: 'root'
})
export class MpThemesService {

  private _theme: BehaviorSubject<string> = new BehaviorSubject<string>('default');
  public theme: Observable<string> = this._theme.asObservable();

  constructor(private _apiService: ApiService) { }

  /**
   * Calls the api to get the theme data.
   */
  getCurrentTheme(): void {
    this._apiService.getRequest('/api/Themes/GetCurrentTheme/').subscribe((data: any) => {
      this._theme.next(data.Records[0]);
    },
    (error: any) => {
      console.log(error.message);
    });
  }
}
