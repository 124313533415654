import { Component, OnInit, ViewEncapsulation, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpThemesService } from './../../../../services/mp-themes.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

/**
 * This class provides the functions for
 * the campaign details page.
 */
@Component({
  selector: 'mp-core-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./../../../../components/card/styles/card.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignDetailsComponent implements OnInit, OnDestroy {

  public currentTheme = 'default';
  public themeLoaded: boolean = false;
  public campaignLoaded: boolean = false;
  public errorMessage: string = '';
  public campaign: { [key: string]: any } = {};
  public daysTotal: number = 0;
  public daysLeft: number = 0;
  public hideTimeline: boolean = true;

  private _getCurrentThemeSubscription: Subscription | undefined;
  private _getCampaignSubscription: Subscription | undefined;
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    private _mpThemes: MpThemesService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private _titleService: MpTitleService
  ) { }

  /**
   * Gets the campaign data and the current
   * theme.
   */
  ngOnInit(): void {
    this._getCurrentThemeSubscription = this._mpThemes.theme.subscribe((currentTheme: string) => {
      this.currentTheme = currentTheme;
      this.themeLoaded = true;
    });

    this._mpThemes.getCurrentTheme();

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      const key = typeof params['key'] !== 'undefined' ? params['key'] : '-';

      this._getCampaignSubscription = this._apiService.getRequest(`/api/Aktionen/GetAktion/${key}`).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.campaign = data.Records[0];
          const to = new Date(this.campaign['GueltigBis']);

          if (to < new Date(2050, 11, 31)) {
            const from = new Date(this.campaign['GueltigVon']);
            const now = new Date();

            const secTotal = (to.getTime() - from.getTime()) / 1000;
            const hoursTotal = secTotal / 3600;
            this.daysTotal = hoursTotal / 24;

            // @ts-ignore
            const secLeft = (to.getTime() - now) / 1000;
            const hoursLeft = secLeft / 3600;
            this.daysLeft = hoursLeft / 24;

            this.hideTimeline = false;
          } else {
            this.hideTimeline = true;
          }

          this._titleService.setTitle(this.campaign['Title']);
          this._breadcrumbService.setCurrentSiteWithText('AktionDetails?key=' + key, this.campaign['Title']);
          this.campaignLoaded = true;
        } else {
          this.errorMessage = data.Message;
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getCurrentThemeSubscription !== 'undefined') {
      this._getCurrentThemeSubscription.unsubscribe();
    }

    if (typeof this._getCampaignSubscription !== 'undefined') {
      this._getCampaignSubscription.unsubscribe();
    }

    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
