<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async) && nameParamSet">
  <h1 class="mobile-only">{{ ls.locs['locGamification'].RankingLightStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['locGamification'].RankingLightStatistik }}"
                 infotextShorten="StatistikRangliste"
                 fieldsAction="/api/Gamification/GetRankingLightStatistikFields"
                 listAction="/api/Gamification/GetRankingLightStatistik"
                 loaderName="rankingLightStatisticLoader"
                 [recLoadedFunc]="recLoadedFunc"
                 [paging]="true"
                 [footer]="false"
                 defaultSorting="RangNr ASC"
                 [showDatePicker]="true"
                 [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways">
  </mp-core-data-table>

  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
