import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

/**
 * This class provides the functions for
 * the prize calendar two.
 */
@Component({
  selector: 'mp-gk-prize-calendar-two',
  templateUrl: './prize-calendar-two.component.html',
  styleUrls: ['./../styles/prize-calendar.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrizeCalendarTwoComponent implements OnInit {

  public role: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the current role.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }
  }

}
