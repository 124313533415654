import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { ManagerComponent } from '@core/modules/manager/manager.component';
import { AdminComponent } from '@core/modules/admin/admin.component';

import { RacingOverviewComponent } from './modules/participant/pages/racing-overview/racing-overview.component';
import { RacingPitComponent } from './modules/participant/pages/racing-pit/racing-pit.component';
import { RacingRaceTrackComponent } from './modules/participant/pages/racing-race-track/racing-race-track.component';
import { SpringCampaignComponent } from './modules/participant/pages/spring-campaign/spring-campaign.component';
import { WintergamesTwentyTwentyoneStatisticComponent } from './modules/manager/pages/wintergames-twenty-twentyone-statistic/wintergames-twenty-twentyone-statistic.component';
import { RankingLightStatisticComponent } from './modules/manager/pages/ranking-light-statistic/ranking-light-statistic.component';
import { RankingLightPlayComponent } from './modules/participant/pages/ranking-light-play/ranking-light-play.component';

import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Fruehjahrsaktion',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: SpringCampaignComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'RacingBoxengasse',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RacingPitComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'RacingRennstrecke',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RacingRaceTrackComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'RacingUebersicht',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RacingOverviewComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'RankingLightPlay',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RankingLightPlayComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Winterspiele2021Statistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: WintergamesTwentyTwentyoneStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      },
      {
        path: 'RankingLightStatistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RankingLightStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'RankingLightStatistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: RankingLightStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class GfcRoutingModule { }
