import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';
import clone from 'clone';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';
import { ApiService } from './../../../../services/api.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';
import { CookieService } from '../../../../services/cookie.service';

/**
 * This class provides the functions and data
 * for the settings page.
 */
@Component({
  selector: 'mp-core-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit, OnDestroy {

  @ViewChild('formProfile') formProfile: any;
  @ViewChild('formKontakt') formKontakt: any;
  @ViewChild('formPasswort') formPasswort: any;

  public profile: { [key: string]: any } = {
    Geburtsdatum: undefined
  };
  public showSetTelStandardDialog: boolean = false;
  public showSetEmailStandardDialog: boolean = false;
  public sQuestions: Array<any> = [];
  public errors: { [key: string]: any } = {};
  public kontakt: { [key: string]: any } = {};
  public pwd: { [key: string]: any } = {
    OldPassword: '',
    NewPassword: '',
    ConfirmPassword: ''
  };
  public closeTelModal = this._closeTelModal.bind(this);
  public closeEmailModal = this._closeEmailModal.bind(this);
  public setTelefonStd = this._setTelefonStd.bind(this);
  public setEmailStd = this._setEmailStd.bind(this);
  public emailSubscriptions: Array<any> = [];
  public newsletter: { [key: string]: any } = {};
  public newsletterLoaded: boolean = false;
  public profilLoaded: boolean = false;
  public contactLoaded: boolean = false;

  private _forms: { [key: string]: any } = {};
  private _backup: { [key: string]: any } = {};
  private _sQuestion: { [key: string]: any } = {
    FragenID: 1
  };
  private _newAdresseOpen: boolean = false;
  private _participantAddressError: Array<any> = [];
  private _eventTelStandard: any = null;
  private _eventEmailStandard: any = null;
  private _contactChanged: boolean = false;
  private _profileHasChanged: boolean = false;
  private _loader: MpLoader = {
    name: 'participantSettingsLoader',
    params: {},
    isReady: false
  };
  private _getSecurityQuestionSubscription: Subscription | undefined;
  private _getContactDataSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;
  private _getProfileDataSubscription: Subscription | undefined;
  private _saveProfileSubscription: Subscription | undefined;
  private _saveContactSubscription: Subscription | undefined;
  private _saveSecurityQuestionSubscription: Subscription | undefined;
  private _savePasswordSubscription: Subscription | undefined;
  private _getEmailSubscriptionsSubscription: Subscription | undefined;
  private _saveEmailSubscriptionsSubscription: Subscription | undefined;
  private _getNewsletterSubscription: Subscription | undefined;
  private _saveNewsletterSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public cookieService: CookieService,
    private _mpMessaging: MpMessagingService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _titleService: MpTitleService,
    private _mpBreadcrumbService: MpBreadcrumbService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService
  ) { }

  /**
   * Sets the title, the breadcrumbs and the menu
   * highlight. Gets some data for the profile settings,
   * and the localizations.
   */
  ngOnInit(): void {
    this._forms = {
      formProfile: this.formProfile,
      formKontakt: this.formKontakt,
      formPasswort: this.formPasswort
    };

    this._mpLoaderService.registerLoader(this._loader);
    this._titleService.setTitleFromLoc('Einstellungen');
    this._mpBreadcrumbService.setCurrentSiteWithLoc('Einstellungen', 'loc', 'Einstellungen');
    this._mpMenuHighlight.setMenuHighlight('einstellungen');

    this._getSecurityQuestionSubscription = this._apiService.getRequest('/api/Teilnehmer/getSicherheitsfragen').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.sQuestions = data.Records;
      }
    },
    (error: any) => {
      if (error.status === 400) {
        this.errors = error.ModelState;
      }
    });

    this._getContactDataSubscription = this._apiService.getRequest('/api/Teilnehmer/getKontakt/').subscribe((data: any) => {
      this.kontakt = data.Records[0];
      this._contactChanged = false;
      this._updateBackup('kontakt');
      this.contactLoaded = true;
      this._checkLoaderState();
    });

    this._getEmailSubscriptionsSubscription = this._apiService.getRequest('/api/Teilnehmer/GetEmailTypeSubscriptions').subscribe((data: any) => {
      this.emailSubscriptions = data.Records;
    });

    this._getNewsletter();

    if (Object.keys(this.ls.locs).length > 0) {
      this._loadProfileData();

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._loadProfileData();

          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getSecurityQuestionSubscription !== 'undefined') {
      this._getSecurityQuestionSubscription.unsubscribe();
    }

    if (typeof this._getContactDataSubscription !== 'undefined') {
      this._getContactDataSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }

    if (typeof this._getProfileDataSubscription !== 'undefined') {
      this._getProfileDataSubscription.unsubscribe();
    }

    if (typeof this._saveProfileSubscription !== 'undefined') {
      this._saveProfileSubscription.unsubscribe();
    }

    if (typeof this._saveContactSubscription !== 'undefined') {
      this._saveContactSubscription.unsubscribe();
    }

    if (typeof this._saveSecurityQuestionSubscription !== 'undefined') {
      this._saveSecurityQuestionSubscription.unsubscribe();
    }

    if (typeof this._savePasswordSubscription !== 'undefined') {
      this._savePasswordSubscription.unsubscribe();
    }

    if (typeof this._getEmailSubscriptionsSubscription !== 'undefined') {
      this._getEmailSubscriptionsSubscription.unsubscribe();
    }

    if (typeof this._saveEmailSubscriptionsSubscription !== 'undefined') {
      this._saveEmailSubscriptionsSubscription.unsubscribe();
    }

    if (typeof this._getNewsletterSubscription !== 'undefined') {
      this._getNewsletterSubscription.unsubscribe();
    }

    if (typeof this._saveNewsletterSubscription !== 'undefined') {
      this._saveNewsletterSubscription.unsubscribe();
    }
  }

  /**
   * Loads the profile data.
   */
  private _loadProfileData(): void {
    this._getProfileDataSubscription = this._apiService.getRequest('/api/Teilnehmer/getProfil/').subscribe((data: any) => {
      this.profile = data.Records[0];

      this.profile['GeburtsdatumParams'] = {
        Headline: this.ls.locs['loc'].Geburtsdatum,
        date: moment(data.Records[0].Geburtsdatum)
      };

      this._profileHasChanged = false;
      this._updateBackup('profile', 'formProfile');
      this.profilLoaded = true;
      this._checkLoaderState();
    });
  }

  /**
   * Checks the loader state, and sets
   * it to ready, if all data loaded.
   */
  private _checkLoaderState(): void {
    this._mpLoaderService.extendLoader(this._loader.name, {
      isReady: (this.profilLoaded === true && this.contactLoaded === true)
    });
  }

  /**
   * Reverts profile changes.
   */
  undoProfile(): void {
    this._restoreBackup('profile', 'formProfile');
    this.errors = {};
  }

  /**
   * Saves the profile.
   */
  saveProfile(): void {
    this.errors = {};
    this.profile['GeburtsdatumString'] = this.profile['GeburtsdatumParams'].date.toDate();

    this._saveProfileSubscription = this._apiService.postRequest('/api/Teilnehmer/setProfil', this.profile).subscribe((data: any) => {
      this._updateBackup('profile', 'formProfile');
      this._mpMessaging.openPanelFromResultResponse(data);
    },
    (error: any) => {
      if (error.status === 400) {
        this.errors = error.ModelState;
        this.formProfile.control.markAsDirty();
      }
    });

    if (sessionStorage['pruefungsParameter']) {
      const savedPp = JSON.parse(sessionStorage['pruefungsParameter']);
      savedPp['Erwachsene'][0].Geburtsdatum = this.profile['GeburtsdatumParams'].date.toDate();
      sessionStorage['pruefungsParameter'] = JSON.stringify(savedPp);
    }
  }

  /**
   * Openes the telephone modal.
   */
  openTelModal(event: MouseEvent): void {
    this.showSetTelStandardDialog = true;
    // @ts-ignore
    this._eventTelStandard = event.target.dataset.val;
  }

  /**
   * Openes the email modal.
   */
  openEmailModal(event: MouseEvent): void {
    this.showSetEmailStandardDialog = true;
    // @ts-ignore
    this._eventEmailStandard = event.target.dataset.val;
  }

  /**
   * Closes the telephone modal.
   */
  private _closeTelModal(): void {
    this.showSetTelStandardDialog = false;
    this._eventTelStandard = null;
  }

  /**
   * Closes the email modal.
   */
  private _closeEmailModal(): void {
    this.showSetEmailStandardDialog = false;
    this._eventEmailStandard = null;
  }

  /**
   * Sets the standard telephone.
   */
  private _setTelefonStd(): void {
    this._contactChanged = true;
    this.kontakt['TelefonStd'] = this._eventTelStandard;
    this.showSetTelStandardDialog = false;
    this._eventTelStandard = null;
  }

  /**
   * Sets the standard email.
   */
  private _setEmailStd(): void {
    this._contactChanged = true;
    this.kontakt['EmailStd'] = this._eventEmailStandard;
    this.showSetEmailStandardDialog = false;
    this._eventEmailStandard = null;
  }

  /**
   * Saves the contact data.
   */
  saveContact(): void {
    this.errors = {};

    if ((this.kontakt['TelefonStd'] === 'MG' && !this.kontakt['Mobil_G'])
      || (this.kontakt['TelefonStd'] === 'MP' && !this.kontakt['Mobil_P']
      || (this.kontakt['TelefonStd'] === 'TP' && !this.kontakt['Telefon_P'])))
      this.kontakt['TelefonStd'] = 'TG';

    this._saveContactSubscription = this._apiService.postRequest('/api/Teilnehmer/setKontakt', this.kontakt).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this._updateBackup('kontakt', 'formKontakt');
      this._contactChanged = false;
    },
    (error: any) => {
      if (error.status === 400) {
        this.errors = error.ModelState;
        this.formKontakt.control.markAsDirty();
      }
    });
  }

  /**
   * Restes the contact changes.
   */
  undoContact(): void {
    this._restoreBackup('kontakt', 'formKontakt');
    this.errors = {};
  }

  /**
   * Restes the security question changes.
   */
  undoSecurityQuestion(): void {
    this._sQuestion = { FragenID: 1 };
    this.errors = {};
    this._setPristine('formSicherheitsfrage');
  }

  /**
   * Saves the security question.
   */
  saveSecurityQuestion(): void {
    this.errors = {};

    this._saveSecurityQuestionSubscription = this._apiService.postRequest('/api/Teilnehmer/setSicherheitsfrage', this._sQuestion).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this._setPristine('formSicherheitsfrage');
    },
    (error: any) => {
      if (error.status === 400) {
        this.errors = error.ModelState;
        this._setDirty('formSicherheitsfrage');
      }
    });
  }

  /**
   * Rests the password changes.
   */
  undoPassword(): void {
    this.pwd = {
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: ''
    };

    this.errors = {};
    this._setPristine('formPasswort');
  }

  /**
   * Saves the password.
   */
  savePassword(): void {
    this._savePasswordSubscription = this._apiService.postRequest('/api/Teilnehmer/ChangePassword', this.pwd).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);
      this.errors = {};
      this._setPristine('formPasswort');
    },
    (error: any) => {
      if (error.status === 400) {
        this.errors = error.ModelState;
        this._setDirty('formPasswort');
      }
    });
  }

  /**
   * Saves the email subscriptions.
   */
  saveEmailSubscriptions(): void {
    this._saveEmailSubscriptionsSubscription = this._apiService.postRequest('/api/Teilnehmer/SetEmailTypeSubscriptions', this.emailSubscriptions).subscribe((data: any) => {
      this._mpMessaging.openSuccessPanel(data.Records[0]);
    },
    (error: any) => {
      this._mpMessaging.openPanelFromResultResponse(error);
    });
  }

  /**
   * Gets the newsletter state.
   */
  private _getNewsletter(): void {
    this._getNewsletterSubscription = this._apiService.getRequest('/api/Newsletter/getNewsletter').subscribe((data: any) => {
      this.newsletter = data.Records[0];
      this.newsletterLoaded = true;
    });
  }

  /**
   * Saves the newsletter state.
   */
  saveNewsletter(): void {
    this._saveNewsletterSubscription = this._apiService.postRequest('/api/Newsletter/setNewsletter', this.newsletter).subscribe((data: any) => {
      this._mpMessaging.openSuccessPanel(data.Records[0]);
      this._getNewsletter();
    },
    (error: any) => {
      this._mpMessaging.openPanelFromResultResponse(error);
    });
  }

  /**
   * Updates the backup object.
   */
  private _updateBackup(key: string, formKey?: string): void {
    // @ts-ignore
    this._backup[key] = clone(this[key]);

    if (typeof formKey !== 'undefined') {
      this._setPristine(formKey);
    }
  }

  /**
   * Restores a backup.
   */
  private _restoreBackup(key: string, formKey?: string): void {
    // @ts-ignore
    this[key] = clone(this._backup[key]);

    if (typeof formKey !== 'undefined') {
      this._setPristine(formKey);
    }
  }

  /**
   * Sets the form for the given
   * key to prestine.
   */
  private _setPristine(key: string): void {
    if (typeof this._forms[key] !== 'undefined') {
      this._forms[key].control.markAsPristine();
    }
  }

  /**
   * Sets the form for the given
   * key to dirty.
   */
  private _setDirty(key: string): void {
    if (typeof this._forms[key] !== 'undefined') {
      this._forms[key].control.markAsDirty();
    }
  }

}
