import { Injectable } from '@angular/core';

/**
 * This class provides some basic variables
 * for scroll button components and directives.
 */
@Injectable({
  providedIn: 'root'
})
export class ScrollButtonService {

  public scrollBtnClicked: boolean = false;
  public scrollButtonDownClass: string = 'scrollbutton-down';
  public scrollButtonUpClass: string = 'scrollbutton-up';
  public inactiveButtonClass: string = 'inactive-scroll-button';

  constructor() { }
}
