import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { MpSidebarService } from './../mp-sidebar.service';

/**
 * This class provides a sidebar with a
 * calendar to select a date range (period).
 */
@Component({
  selector: 'mp-core-sidebar-reward-period',
  templateUrl: './sidebar-reward-period.component.html',
  styleUrls: ['./sidebar-reward-period.component.scss']
})
export class SidebarRewardPeriodComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public minRange: number = 0;
  public yearRange: any[] = [];
  public endDatesLimitedToLastDayOfQuarter: boolean = false;
  public sidebarParamsLoaded: boolean = false;

  private _sidebarParamsSubscription: Subscription | undefined;

  constructor(public ls: MpLocalizationService, public mpSidebar: MpSidebarService) { }

  /**
   * Watches for sidebar params changes of
   * and triggers updating year and year range.
   * Sets the current date.
   */
  ngOnInit(): void {
    this._setYearRange();
    this.yearChange();

    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        rangeStart: this.selection['rangeStart'] || this.mpSidebar.params['rangeStart'],
        rangeEnd: this.selection['rangeEnd'] || this.mpSidebar.params['rangeEnd'],
        repetition: this.selection['repetition'] || this.mpSidebar.params['repetition'] || this.mpSidebar.params['repetitionOptions'][0],
        year: moment().year()
      });

      this._setYearRange();
      this.yearChange();
      this.sidebarParamsLoaded = true;
    });
  }

  /**
   * Unsubscribes subscriptions, if exist.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }
  }

  /**
   * Calculates and sets the range of the
   * slectable years.
   */
  private _setYearRange(): void {
    const firstYear = moment().year();
    const lastYear = moment().year() + 3;
    this.yearRange = [];

    for (let loopCnt = firstYear; loopCnt <= lastYear; loopCnt++) {
      this.yearRange.push(moment().set('year', loopCnt).year());
    }
  }

  /**
   * Triggered, when year selection is changed.
   * Sets the new min and max dates.
   */
  yearChange(): void {
    if (typeof this.selection['year'] === 'undefined') {
      return;
    }

    this.minDate = this.selection['year'] === moment().year() ? moment() : moment([this.selection['year'], 0, 1]);
    this.maxDate = moment([this.selection['year'], 11, 31, 23, 59]);
  }

  /**
   * Triggered, when repetition selection is changed.
   * Reset the selected date range to it's initial value
   */
  changeRepetition(): void {
    this.selection['rangeStart'] = this.mpSidebar.params['rangeStart'];
    this.selection['rangeEnd'] = this.mpSidebar.params['rangeEnd'];
    this.yearChange();
  }

  rewardRangeChanged(): void {
    if (this.selection['rangeStart'] === this.selection['rangeEnd'])
      this.selection['rangeEnd'] = undefined;
  }

  /**
   * Merges the selection with the sidebar
   * params and triggers the save function,
   * if exists.
   */
  save(event: MouseEvent): void {
    event.preventDefault();

    if (typeof this.selection['year'] === 'undefined') {
      return;
    }

    this.mpSidebar.params = Object.assign(this.mpSidebar.params, this.selection);
    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

}
