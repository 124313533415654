<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-breadcrumbs></mp-core-breadcrumbs>
  <h1>{{ ls.locs['loc'].Zusatzabfragen }}</h1>
  <div class="zusatzabfragen bestell-bg">
    <form>
      <div *ngFor="let za of additionalQuestions" class="margin-bottom-24" [ngSwitch]="za['Ctrl']" [hidden]="za['hidden']">
        <div *ngSwitchCase="'Leerzeile'"></div>
        <div *ngSwitchCase="'Linie'"></div>
        <div *ngSwitchCase="'Label'">
          <span [innerHTML]="za['Caption']" class="caption"></span>
        </div>
        <fieldset class="row" *ngSwitchCase="'Textbox'">
          <div class="grid col-large-10">
            <label for="ctrl_{{za['CtrlID']}}" [innerHTML]="za['Caption']"></label>
            <mp-core-input type="text" mpId="ctrl_{{za['CtrlID']}}" [mpRequired]="za['Pflichtfeld']" [model]="za['Result']" (modelChange)="za['Result'] = $event; itemChanged(za)"></mp-core-input>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </fieldset>
        <fieldset class="row" *ngSwitchCase="'Textarea'">
          <div class="grid col-large-10">
            <label for="ctrl_{{za['CtrlID']}}" [innerHTML]="za['Caption']"></label>
            <mp-core-textarea mpId="ctrl_{{za['CtrlID']}}" [mpRequired]="za['Pflichtfeld']" [model]="za['Result']" (modelChange)="za['Result'] = $event; itemChanged(za)"></mp-core-textarea>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </fieldset>
        <div class="row" *ngSwitchCase="'NameVorname'">
          <div class="grid col-large-5">
            <label for="ctrl_{{za['CtrlID']}}_name" [innerHTML]="za['Captions'][0]"></label>
            <mp-core-input type="text" mpId="ctrl_{{za['CtrlID']}}_name" [mpRequired]="za['Pflichtfeld']" [model]="za['Values'][0]" (modelChange)="za['Values'][0] = $event; itemChanged(za)"></mp-core-input>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_name'] && validationErrors['ctrl_'+za['CtrlID']+'_name'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_name']"></span>
          </div>
          <div class="grid col-large-5">
            <label for="ctrl_{{za['CtrlID']}}_vorname" [innerHTML]="za['Captions'][1]"></label>
            <mp-core-input type="text" mpId="ctrl_{{za['CtrlID']}}_vorname" [mpRequired]="za['Pflichtfeld']" [model]="za['Values'][1]" (modelChange)="za['Values'][1] = $event; itemChanged(za)"></mp-core-input>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_vorname'] && validationErrors['ctrl_'+za['CtrlID']+'_vorname'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_vorname']"></span>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </div>
        <div class="row" *ngSwitchCase="'NameVornameGebDatum'">
          <div class="grid col-large-3">
            <label for="ctrl_{{za['CtrlID']}}_name" [innerHTML]="za['Captions'][0]"></label>
            <mp-core-input class="col-sm-6 form-control" type="text" mpId="ctrl_{{za['CtrlID']}}_name" [mpRequired]="za['Pflichtfeld']" [model]="za['Values'][0]" (modelChange)="za['Values'][0] = $event; itemChanged(za)"></mp-core-input>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_name'] && validationErrors['ctrl_'+za['CtrlID']+'_name'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_name']"></span>
          </div>
          <div class="grid col-large-3">
            <label for="ctrl_{{za['CtrlID']}}_vorname" [innerHTML]="za['Captions'][1]"></label>
            <mp-core-input type="text" mpId="ctrl_{{za['CtrlID']}}_vorname" [mpRequired]="za['Pflichtfeld']" [model]="za['Values'][1]" (modelChange)="za['Values'][1] = $event; itemChanged(za)"></mp-core-input>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_vorname'] && validationErrors['ctrl_'+za['CtrlID']+'_vorname'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_vorname']"></span>
          </div>
          <div class="grid col-large-4">
            <label for="ctrl_{{za['CtrlID']}}_gebdatum" [innerHTML]="za['Captions'][2]"></label>
            <div class="input-container">
              <input class="text date-picker" type="text" id="ctrl_{{za['CtrlID']}}_gebdatum" [attr.required]="za['Pflichtfeld']" [ngModel]="za['Values'][2]" (ngModelChange)="za['Values'][2] = $event; itemChanged(za)" datepicker item="za" select-year="100" />
            </div>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_gebdatum'] && validationErrors['ctrl_'+za['CtrlID']+'_gebdatum'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_gebdatum']"></span>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </div>
        <fieldset class="row" *ngSwitchCase="'Datum'" [hidden]="hidden['ctrl_' + za['CtrlID']]">
          <div class="grid col-large-10">
            <label for="ctrl_{{za['CtrlID']}}" [innerHTML]="za['Caption']"></label>
            <div class="input-container">
              <input class="text date-picker" type="text" id="ctrl_{{za['CtrlID']}}" [attr.required]="za['Pflichtfeld']" [ngModel]="za['Result']" (ngModelChange)="za['Result'] = $event; itemChanged(za)" datepicker item="za" date-change="doShowHide" show-hide="za['ShowHides']" show-condition="za['ShowCondition']" />
            </div>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </fieldset>
        <div class="row" *ngSwitchCase="'Zeitraum'">
          <div class="grid col-large-5">
            <label for="ctrl_{{za['CtrlID']}}_start" [innerHTML]="za['Captions'][0]"></label>
            <div class="input-container">
              <input class="text date-picker" type="text" id="ctrl_{{za['CtrlID']}}_start" [attr.required]="za['Pflichtfeld']" [ngModel]="za['Values'][0]" (ngModelChange)="za['Values'][0] = $event; itemChanged(za)" datepicker item="za" />
            </div>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_start'] && validationErrors['ctrl_'+za['CtrlID']+'_start'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_start']"></span>
          </div>
          <div class="grid col-large-5">
            <label for="ctrl_{{za['CtrlID']}}_end" [innerHTML]="za['Captions'][1]"></label>
            <div class="input-container">
              <input class="text date-picker" type="text" id="ctrl_{{za['CtrlID']}}_end" [attr.required]="za['Pflichtfeld']" [ngModel]="za['Values'][1]" (ngModelChange)="za['Values'][1] = $event; itemChanged(za)" datepicker item="za" />
            </div>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']+'_end'] && validationErrors['ctrl_'+za['CtrlID']+'_end'].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']+'_end']"></span>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </div>
        <fieldset class="row" *ngSwitchCase="'DropDown'">
          <div class="grid col-large-10 margin-bottom-16">
            <label for="ctrl_{{za['CtrlID']}}" [innerHTML]="za['Caption']"></label>
            <mp-core-select id="ctrl_{{za['CtrlID']}}" [mpRequired]="za['Pflichtfeld']" [elements]="za['Values']" [model]="za['Result']" (modelChange)="za['Result'] = $event; itemChanged(za)"></mp-core-select>
          </div>
          <div class="grid col-large-2" [hidden]="!za['VKResult']">
            <span class="bold font-size-l" [innerText]="za['VKResult']"></span>
            <span class="bold font-size-l">{{ ls.locs['loc'].Punkte }}</span>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </fieldset>
        <fieldset class="row" *ngSwitchCase="'CheckBox'">
          <div class="grid col-large-10 margin-bottom-16">
            <mp-core-checkbox type="checkbox" mpId="ctrl_{{za['CtrlID']}}" [model]="za['Result']" (modelChange)="za['Result'] = $event; itemChanged(za)" label="{{za['Value']}}"></mp-core-checkbox>
          </div>
          <div class="grid col-large-2" [hidden]="!za['VKResult']">
            <span class="bold font-size-l" [innerText]="za['VKResult']"></span>
            <span class="bold font-size-l">{{ ls.locs['loc'].Punkte }}</span>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </fieldset>
        <fieldset *ngSwitchCase="'Radio'" class="row">
          <div class="grid col-large-10 margin-bottom-16">
            <div *ngFor="let v of za['Values']; let index = index;">
              <mp-core-radio-button name="radio_{{za['CtrlID']}}" label="{{za['Values'][index]}}" type="radio" mpId="ctrl_{{za['CtrlID']}}_{{index}}" value="{{za['Values'][index]}}" [model]="za['Result']" (modelChange)="za['Result'] = $event; itemChanged(za)"></mp-core-radio-button>
            </div>
          </div>
          <div class="grid col-large-2" [hidden]="!za['VKResult']">
            <span class="bold font-size-l" [innerText]="za['VKResult']"></span>
            <span class="bold font-size-l">{{ ls.locs['loc'].Punkte }}</span>
          </div>
          <div class="grid col-large-10" *ngIf="validationErrors['ctrl_'+za['CtrlID']] && validationErrors['ctrl_'+za['CtrlID']].length > 0">
            <span class="text-danger" [innerText]="e" *ngFor="let e of validationErrors['ctrl_'+za['CtrlID']]"></span>
          </div>
        </fieldset>
      </div>
      <div class="row" [hidden]="isPreview">
        <div class="grid col-large-10"></div>
        <div class="grid col-large-2">
          <span class="bold font-size-l" [hidden]="vkResultTotal > 0" [innerText]="vkResultTotal"></span>
          <span class="bold font-size-l">{{ ls.locs['loc'].Punkte }}</span>
        </div>
      </div>
    </form>
    <button *ngIf="sbItemLoaded" [hidden]="isPreview"
            type="button"
            class="mp-button _prim margin-bottom-16 margin-right-8"
            (click)="saveData('PraemienDetails?art='+sbItem['ArtNr'])"
            [attr.disabled]="buttonsDisabled ? '' : null">
      {{ ls.locs['loc'].SpeichernUndZumWarenkorb }}
    </button>
  </div>
</div>
