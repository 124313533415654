import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

/**
 * This class provides the view for the
 * content of a detail page.
 */
@Component({
  selector: 'mp-core-detail-page-content',
  templateUrl: './detail-page-content.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DetailPageContentComponent implements OnInit {

  @Input() public imgSrc: string = '';
  @Input() public title: string = '';
  @Input() public text: string = '';
  @Input() public showProgress: boolean = false;
  @Input() public progressPercent: number = 0;
  @Input() public progressBarTextActive: string = '';
  @Input() public progressBarTextDone: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
