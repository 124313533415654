import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * This class provides a simple radio-button, that
 * can be configured with different input attributes.
 */
@Component({
  selector: 'mp-core-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() public model: any;
  @Input() public label: string = '';   
  @Input() public mpId: string = '';
  @Input() public mpGroup: string = '';
  @Input() public value: any;
  @Input() public mpLabel: string = '';
  @Input() public name: string = '';

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }

  /**
   * Triggers model change event.
   */
  modelChanged(): void {
    this.modelChange.emit(this.model);
  }

}
