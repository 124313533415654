<div *ngIf="(ls.locsLoaded | async)">
	<mp-core-data-table title=""
                     *ngIf="loaderName !== ''"
	                   fieldsAction="/api/Gamification/GetRankingLightStatistikChildFields"
	                   listAction="/api/Gamification/GetRankingLightStatistikChild"
	                   loaderName="{{loaderName}}"
	                   [paging]="false"
	                   [footer]="false"
	                   [startEmpty]="true"
	                   defaultSorting="Anfragedatum DESC, Auszahlungsdatum DESC">
	</mp-core-data-table>
</div>
