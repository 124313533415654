<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Versteuerungsliste }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Versteuerungsliste }}"
                      infotextShorten="Versteuerungsliste"
                      fieldsAction="/api/Statistik/getVersteuerungslisteFields"
                      listAction="/api/Statistik/getVersteuerungsliste"
                      loaderName="paymerntTaxListLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="Gesamtbetrag DESC"
                      [showDatePicker]="true"></mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
