<div id="sidebar-guests-hotel" class="rk-sidebar-form" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="sidebar-grid" *ngIf="selectionLoaded">
    <div id="overlay-headline" class="texst-align-left">{{ls.locs['locReisekonfigurator'].GaesteSidebarHeader}}</div>
    <div class="divider margin-bottom-8 margin-left-16"></div>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex flex-justify-cont-between flex-align-items-center">
      <div>
        <span class="bold font-size-s">{{ ls.locs['locReisekonfigurator'].Erwachsene }}</span>
      </div>
      <div class="display-flex flex-justify-cont-between flex-align-items-center guest-number-select">
        <span>
          <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle" [ngClass]="selection['AnzahlErwachsene'] === 1 ? '__stroke-clr-inactive not-allowed-cursor-hover' : '__stroke-clr-default-dark pointer-hover'" (click)="removeAdult()"></i>
        </span>
        <span [innerText]="selection['AnzahlErwachsene']" class="bold font-size-l"></span>
        <span>
          <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle" [ngClass]="selection['AnzahlErwachsene'] === mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlErwachsene ? '__stroke-clr-inactive not-allowed-cursor-hover' : '__stroke-clr-default-dark pointer-hover'" (click)="addAdult()"></i>
        </span>
      </div>
    </div>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex flex-justify-cont-between flex-align-items-center">
      <div>
        <span class="bold font-size-s">{{ ls.locs['locReisekonfigurator'].Kinder }}</span>
      </div>
      <div class="display-flex flex-justify-cont-between flex-align-items-center guest-number-select">
        <span>
          <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle" [ngClass]="selection['AnzahlKinder'] === 0 ? '__stroke-clr-inactive not-allowed-cursor-hover' : '__stroke-clr-default-dark pointer-hover'" (click)="removeChild()"></i>
        </span>
        <span [innerText]="selection['AnzahlKinder']" class="bold font-size-l"></span>
        <span>
          <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle" [ngClass]="selection['AnzahlKinder'] === mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlKinder ? '__stroke-clr-inactive not-allowed-cursor-hover' : '__stroke-clr-default-dark pointer-hover'" (click)="addChild()"></i>
        </span>
      </div>
    </div>
    <div class="margin-left-16 margin-right-16 margin-top-32 display-flex flex-wrap">
      <mp-core-micro-copy infoState="inactive" copyText="{{ ls.locs['locReisekonfigurator'].GaesteHinweis | format:[mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlErwachsene, mpSettings.settings['ReisekonfiguratorSettings'].MaxAnzahlKinder] }}"></mp-core-micro-copy>
      <div class="divider margin-top-8 margin-bottom-8 margin-left-16"></div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#gaeste-scroll-area" scrollDirection="up" scrollFactor="1" [scopeObject]="selection" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 padding-top-16 padding-bottom-16 scroll-area-container" id="gaeste-scroll-area-container">
      <div id="gaeste-scroll-area" class="sidebar-scroll-area position-relative">
        <div [hidden]="selection['AnzahlKinder'] === 0 ">
          <h3 class="bold font-size-s">{{ ls.locs['locReisekonfigurator'].AlterDerKinder }}</h3>
          <span class="text-color-grey-info">{{ ls.locs['locReisekonfigurator'].AlterDerKinderSubTitle }}</span>
          <div *ngFor="let kind of selection['Kinder']; let index = index; trackBy: trackByIndex" id="children-age">
            <mp-core-select label="{{ls.locs['locReisekonfigurator'].XtesKind.replace('{0}', index+1)}}" class="margin-top-24 display-block" mpId="rkKind{{index}}" [(model)]="selection['Kinder'][index]" [elements]="childrenOldOptions" textProperty="Text"></mp-core-select>
          </div>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#gaeste-scroll-area" scrollDirection="down" scrollFactor="1" [scopeObject]="selection" class="scrollbutton-down margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].AenderungenUebernehmen }}</a>
    </div>
  </div>
</div>
