<div [ngClass]="{'invalid-error': hasError}" class="flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8">
  <span class="sidebar-input-label flex-col-12 font-weight-700 font-size-xxs padding-left-right-0 display-block-important">
    <span class="display-inline" [ngClass]="(!mpRequired && !mpDisabled) && label ? 'margin-right-4' : ''" [innerHtml]="label"></span>
    <span *ngIf="(!mpRequired && !mpDisabled) && label && (ls.locsLoaded | async)" class="font-size-xxs text-color-grey-info display-inline">{{ ls.locs['loc'].OptionalField }}</span>
  </span>
  <div class="sidebar-input sidebar-open" (click)="openSidebar()">
    <span [hidden]="!icon" class="svg-icon __clr-default __size-24 __top--1 {{icon}}"></span>
    <span [textContent]="text" [hidden]="!text"></span>
    <span [textContent]="mpPlaceholder" [hidden]="text || !mpPlaceholder"></span>
    <span class="svg-icon __clr-default __size-24 __top-0 __nav-next"></span>
  </div>
</div>
