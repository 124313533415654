<div class="modal-background" [ngClass]="{open: show}">
  <div class="card modal floating-container box-shadow-dialog">
    <div class="primary-title" [attr.hidden]="true">
      <div class="title last"></div>
    </div>
    <ng-content class="supporting-text first"></ng-content>
    <div class="supplemental-actions display-flex flex-wrap flex-justify-cont-center padding-8">
    </div>
  </div>
</div>
