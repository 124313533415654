<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].GruppenStatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].GruppenStatistik }}"
                      infotextShorten="GruppenStatistik"
                      fieldsAction="/api/Statistik/getGruppenStatistikFields"
                      listAction="/api/Statistik/getGruppenStatistik"
                      [recLoadedFunc]="recLoadedFunc"
                      loaderName="groupStatisticsLoader"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="Punkte DESC"
                      [disableCache]="true">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
