import { Directive, ElementRef, HostListener } from '@angular/core';

import { CarouselSliderSlideService } from './../services/carousel-slider-slide.service';

/**
 * This class provides the functions for the
 * carousel slider of the calendar variant two.
 */
@Directive({
  selector: '[mpGkCarouselSliderCalendar]',
  exportAs: 'mpGkCarouselSliderCalendar'
})
export class CarouselSliderCalendarDirective {

  private _slider: HTMLElement | null = null;
  private _sliderUl: HTMLUListElement | null = null;
  private _sliderUlLi: HTMLLIElement | null = null;
  private _sliderItemWidth: number = 0;
  private _sliderItemPosition: number = 0;
  private _mouseDownPositionX: number = 0;
  private _slideDirection: string = '';
  private _mouseTime: any;

  constructor(
    public carouselSliderSlide: CarouselSliderSlideService,
    private _elementRef: ElementRef
  ) { }

  /**
   * Sets the functionalities for the
   * movement, when all doors are
   * rendered.
   */
  ngForFinished(): void {
    if (this._sliderUlLi !== null) {
      return;
    }

    this._slider = this._elementRef.nativeElement;

    if (this._slider !== null) {
      this._sliderUl = this._slider.querySelector('ul');

      if (this._sliderUl !== null) {
        this.carouselSliderSlide.mouseIsDown = false;
        this.carouselSliderSlide.slideItems(true, this._sliderUl, this._sliderItemWidth, this._sliderItemPosition, this._slideDirection);
        this._sliderUlLi = this._sliderUl.querySelector('li');
      }
    }
  }

  /**
   * Handles the slider mousedonw event.
   */
  @HostListener('mousedown', ['$event'])
  onmousedown(e: MouseEvent) {
    if (this._sliderUlLi === null) {
      return;
    }

    if (typeof this._mouseTime !== 'undefined') {
      clearTimeout(this._mouseTime);
    }

    this._mouseDownPositionX = e.pageX;


    this._mouseTime = setTimeout(() => {
      this.carouselSliderSlide.mouseIsDown = true;

      if (this._sliderUl !== null && this._sliderUlLi !== null && this._slider !== null) {
        this.carouselSliderSlide.movingItems('mouse', this._mouseDownPositionX, this._sliderUl, this._sliderUlLi, this._sliderItemPosition, this._slider);
      }
    }, 200);
  }

  /**
   * Handles the slider mouseup event.
   */
  @HostListener('mouseup', ['$event'])
  onmouseup(e: MouseEvent) {
    if (this._sliderUlLi === null) {
      return;
    }

    this.carouselSliderSlide.mouseIsDown = false;

    if (typeof this._mouseTime !== 'undefined') {
      clearTimeout(this._mouseTime);
    }

    e.preventDefault();

    return;
  }

  /**
   * Handles the slider touchstart event.
   */
  @HostListener('touchstart', ['$event'])
  ontouchstart(e: any): void {
    if (this._sliderUlLi === null) {
      return;
    }

    this.carouselSliderSlide.mouseIsDown = true;
    this._mouseDownPositionX = e['touches'][0].screenX;

    if (this._sliderUl !== null && this._sliderUlLi !== null && this._slider !== null) {
      this.carouselSliderSlide.movingItems('touch', this._mouseDownPositionX, this._sliderUl, this._sliderUlLi, this._sliderItemPosition, this._slider);
    }
  }

  /**
   * Handles the slider touchend event.
   */
  @HostListener('touchend')
  ontouchend(): void {
    if (this._sliderUlLi === null) {
      return;
    }

    this.carouselSliderSlide.mouseIsDown = false;
    return;
  }

}
