import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(sortArr: Array<any>, key: string): Array<any> {
    return sortArr.concat().sort(this._sortBy(key));;
  }

  private _sortBy(key: any): any {
    return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
  };

}
