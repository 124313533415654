import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { DataTableChildsService } from './../../../../components/data-table/data-table-childs.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

import { EmailComponent } from './email/email.component';

/**
 * This class provides the data and
 * functions for the email protocol
 * table.
 */
@Component({
  selector: 'mp-core-email-protocol',
  templateUrl: './email-protocol.component.html'
})
export class EmailProtocolComponent implements OnInit, OnDestroy {

  public activeFilters: Array<any> = [];
  public filters: Array<any> = [];
  public loader: MpLoader | null = null;
  public excelService: string = '/api/EMailProtokoll/EMailProtokoll2Excel';
  public getEmailProtocolLoader: MpLoader = {
    name: 'getEmailProtocolLoader',
    params: {}
  };
  public loadMailFilterSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public apiService: ApiService,
    public titleService: MpTitleService,
    public mpLoaderService: MpLoaderService,
    public mpMenuHighlight: MpMenuHighlightService,
    public dataTableChilds: DataTableChildsService
  ) { }

  /**
   * Sets the title and menu highlighting.
   * Registers and extends the loader. Loads
   * the mail filter.
   */
  ngOnInit(): void {
    this.dataTableChilds.registerTemplate('/Views/ng-templates/Email', EmailComponent);
    this.titleService.setTitleFromLoc('EMailProtokoll');
    this.mpMenuHighlight.setMenuHighlight('kommunikation');
    this.mpLoaderService.registerLoader(this.getEmailProtocolLoader);

    this.mpLoaderService.extendLoader('getEmailProtocolLoader', {
      refillExtParamsFunc: (params: any) => {
        if (params['MailStatus']) {
          this.activeFilters = this.filters.filter((filter: any) => {
            return params['MailStatus'].indexOf(filter['ID'] !== -1);
          });
        }
      },
      params: {
        hasDatePicker: true,
      }
    });

    this.loadMailFilterSubscription = this.apiService.getRequest('/api/EMailProtokoll/getEmailFilter').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length >= 0) {
        this.filters = data.Records;
        this.activeFilters = data.Records;
        this.loader = this.mpLoaderService.getLoader('getEmailProtocolLoader');
        this.loader.isReady = true;
        this.doData(this.activeFilters);
      }
    });
  }

  /**
   * Processes the loaded data.
   */
  doData(activeFilters: Array<any>): void {
    this.activeFilters = activeFilters;

    if (this.activeFilters) {
      this.loader = this.mpLoaderService.getLoader('getEmailProtocolLoader');

      this.loader.params['MailStatus'] = this.activeFilters.map((filter: any) => {
        return filter['ID'];
      });

      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.loadMailFilterSubscription !== 'undefined') {
      this.loadMailFilterSubscription.unsubscribe();
    }
  }

  /**
   * Gets the texts for multiselect
   * dropdowns.
   */
  getMultiDropDownText(): void {
    let text = this.activeFilters.reduce((memo: any, item: any) => {
      return memo ? memo + ', ' + item['Text'] : item['Text'];
    }, '');

    if (text) {
      return text.length > 30 ? text.substring(0, 30) + '...' : text;
    }

    return text;
  }

}
