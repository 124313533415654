<div id="beschwerdemanagement" class="layout-container bg-color-white padding-bottom-48" *ngIf="(ls.locsLoaded | async)">
  <div class="white-content-container">

    <mp-core-breadcrumbs></mp-core-breadcrumbs>

    <h1>{{ ls.locs['locLeas'].BeschwerdemanagementTitle }}</h1>
    <div [innerHTML]="ls.locs['locLeas'].BeschwerdemanagementText"></div>
    <div [innerHTML]="ls.locs['locLeas'].BeschwerdemanagementText2"></div>

    <div class="margin-top-16">
      <mp-core-input mpType="text"
                label="{{ ls.locs['locLeas'].BeschwerdemanagementName }}"
                [(model)]="complaint['Name']"
                [mpRequired]="true"
                mpId="Name"
                [hasError]="errors['data.Name'] ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.Name']"
                     [model]="errors['data.Name']">
      </mp-core-form-error>
    </div>

    <div class="margin-top-16">
      <mp-core-input mpType="text"
                label="{{ ls.locs['locLeas'].BeschwerdemanagementAutohaus }}"
                [(model)]="complaint['Firma']"
                [mpRequired]="true"
                mpId="Firma"
                [hasError]="errors['data.Firma'] ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.Firma']"
                     [model]="errors['data.Firma']">
      </mp-core-form-error>
    </div>

    <div class="margin-top-16">
      <mp-core-input mpType="text"
                label="{{ ls.locs['locLeas'].BeschwerdemanagementAktuelleBenutzerkennung }}"
                [(model)]="complaint['Benutzer']"
                [mpRequired]="true"
                mpId="Benutzer"
                [hasError]="errors['data.Benutzer'] ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.Benutzer']"
                     [model]="errors['data.Benutzer']">
      </mp-core-form-error>
    </div>
    
    <div class="margin-top-40">
      <mp-core-select label="{{ ls.locs['locLeas'].BeschwerdemanagementVertragsart }}"
                 mpId="VetragsartenList"
                 [(model)]="contractType"
                 [mpRequired]="true"
                 [elements]="contractTypesListe"
                 textProperty="Bezeichnung"
                 [hasError]="errors['data.Vertragsart'] ? true : false">
      </mp-core-select>
      <mp-core-form-error *ngIf="errors['data.Vertragsart']"
                     [model]="errors['data.Vertragsart']">
      </mp-core-form-error>
    </div>
    
    <div class="margin-top-16">
      <mp-core-input mpType="text"
                label="{{ ls.locs['locLeas'].BeschwerdemanagementProposal }}"
                [(model)]="complaint['Proposal']"
                [mpRequired]="true"
                mpId="Proposal"
                [hasError]="errors['data.Proposal'] ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.Proposal']"
                     [model]="errors['data.Proposal']">
      </mp-core-form-error>
    </div>
    
    <div class="margin-top-16 sidebar-div-container">
      <mp-core-sidebar-input class="width-full"
                     text="{{activationDateParams['date'] ? activationDateParams['date'].format('L') : ''}}"
                     label="{{ ls.locs['locLeas'].BeschwerdemanagementAktivierungsdatumn }}"
                     [mpRequired]="true"
                     [sidebarParams]="activationDateParams"
                     sidebarComponent="SidebarDate"
                     [hasError]="errors['data.Aktivierungsdatum'] ? true : false">
      </mp-core-sidebar-input>
      <mp-core-form-error *ngIf="errors['data.Aktivierungsdatum']"
                     [model]="errors['data.Aktivierungsdatum']">
      </mp-core-form-error>
    </div>
    
    <div class="margin-top-16">
      <mp-core-input mpType="text"
                label="{{ ls.locs['locLeas'].BeschwerdemanagementBenutzerkennungDealerGoAktivierungsdatum }}"
                [(model)]="complaint['BenutzerAktivierungsdatum']"
                [mpRequired]="true"
                mpId="BenutzerAktivierungsdatum"
                [hasError]="errors['data.BenutzerAktivierungsdatum'] ? true : false">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.BenutzerAktivierungsdatum']"
                     [model]="errors['data.BenutzerAktivierungsdatum']">
      </mp-core-form-error>
    </div>

    <div class="margin-top-40">
      <mp-core-textarea label="{{ ls.locs['locLeas'].BeschwerdemanagementBemerkung }}"
                   [(model)]="complaint['Bemerkung']"
                   mpId="BemerkungA"
                   [hasError]="errors['data.Bemerkung'] ? true : false">
      </mp-core-textarea>
      <mp-core-form-error *ngIf="errors['data.Bemerkung']"
                     [model]="errors['data.Bemerkung']">
      </mp-core-form-error>
    </div>

    <div id="overlay-buttons" class="margin-top-168 flex-grid flex-direction-row flex-wrap flex-align-items-start">
      <div class="flex-col-6 padding-left-8 padding-right-0">
        <a (click)="send($event)" class="mp-button _prim margin-top-bottom-24">{{ ls.locs['loc'].Senden }}</a>
      </div>
    </div>

  </div>
</div>
