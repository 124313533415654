<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div>
    <div *ngFor="let sbItems of shoppingCart"
         class="margin-top-24 wk-group">
      <div [innerText]="sbItems[0].Versandzeit"
           class="shipping-status margin-bottom-16 padding-top-bottom-8 padding-left-right-16 display-flex flex-align-items-center flex-justify-cont-center">
      </div>
      <mp-core-sb-item *ngFor="let item of sbItems"
               [item]="item"
               class="card-flat bg-color-white wk-card margin-top-8 padding-0 display-flex flex-grid flex-direction-column margin-right-8 margin-left-8">
      </mp-core-sb-item>
    </div>
  </div>
  <a class="text-transform-uppercase bold text-color-brand-primary font-size-xs hide-on-medium"
     href="#/{{role}}/{{steps['warenkorb'].Url}}"
     *ngIf="showEdit">
    {{ ls.locs['loc'].Bearbeiten }}
  </a>
  <div class="divider margin-top-24 margin-bottom-16 hide-on-medium"></div>
  <div class="flex-grid margin-bottom-32">
    <div class="flex-col-5 flex-grid"></div>
    <div class="flex-col-7 flex-direction-column wk-sum">
      <div class="items-total-cost flex-grid">
        <span class="flex-col-7 flex-col-7-xs flex-justify-cont-end bold">
          {{ ls.locs['loc'].Gesamtkosten }}
        </span>
        <span class="flex-col-5 flex-col-5-xs flex-justify-cont-end padding-right-24 [innerText]ing">{{cartItems | sumOfItems:'EingeloestePunkte' | integer}} {{ ls.locs['loc'].WaehrungPunkte }}</span>
      </div>
      <div class="items-total-cost-additional flex-grid" *ngIf="!tnIsOnPaymentBlacklist">
        <span class="flex-col-7 flex-col-7-xs flex-justify-cont-end">{{ ls.locs['locWkZuzahlung'].plus }}</span>
        <span class="flex-col-5 flex-col-5-xs flex-justify-cont-end padding-right-24 ng-binding">{{cartItems | sumOfItems:'ZuzahlungEuro' | decimal}} €</span>
      </div>
    </div>
  </div>
  <a class="text-transform-uppercase bold text-color-brand-primary font-size-xs show-on-medium padding-left-16 margin-top-16 display-block"
     href="#/{{role}}/{{steps['warenkorb'].Url}}"
     *ngIf="showEdit">
    {{ ls.locs['loc'].Bearbeiten }}
  </a>
</div>
