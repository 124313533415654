<div id="inbox-details-message" *ngIf="(ls.locsLoaded | async)">
  <div class="showbox" [hidden]="!loading">
    <div class="loader">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
  <div class="inbox-item-title header display-flex _is-email-type width-full box-sizing-border-box padding-top-bottom-16">
    <i class="svg-icon __clr-primary __size-18 __top-2 margin-right-16" [ngClass]="item.Type">
      <i *ngIf="item.attachments && item.attachments.length > 0" class="inbox-item__attachment-badge svg-icon ng-scope __attach_file __size-14 position-absolute"></i>
    </i>
    <div class="inbox-item-card--headline display-flex flex-justify-cont-between">
      <span>{{item.Betreff}}</span>
    </div>
    <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Loeschen }}" class="position-absolute right-64 top-8">
      <button class="mp-button display-flex flex-align-items-center flex-justify-cont-center padding-left-right-8" [attr.aria-label]="ls.locs['loc'].Loeschen" (click)="onItemDelete(item)">
        <i class="svg-icon __clr-default __size-22 __delete"></i>
      </button>
    </mp-core-tooltip>
    <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Close }}" class="position-absolute right-24 top-8">
      <a class="mp-button display-flex flex-align-items-center flex-justify-content-center padding-left-right-8" [attr.aria-label]="ls.locs['loc'].Close" (click)="onClick($event, item)">
        <i class="svg-icon __clr-default __size-22 __clear"></i>
      </a>
    </mp-core-tooltip>
  </div>
  <div class="animate-show" [ngClass]="{open: !loading}">
    <div class="supporting-text padding-bottom-0">
      <hr class="margin-bottom-16 margin-top-0" />
    </div>
    <div class="rich-media text-align-center">
      <img class="z-index-1 width-full" *ngIf="item.SubItems && item.SubItems[0] && item.SubItems[0].Image" [src]="item.SubItems[0].Image+'?h=427&w=1280&mode=crop'" alt="{{item.Title}}" />
    </div>
    <div class="supporting-text" [ngClass]="{'padding-bottom-0': item.attachments && item.attachments.length > 0}">
      <div [innerHTML]="trustAsHtml(item.Text)"></div>
    </div>
    <div class="attachment-list" *ngIf="item.attachments && item.attachments.length > 0">
      <ul class="list branded-bul margin-left-0 margin-top-32 padding-top-32 margin-bottom-24 border-top">
        <li class="_download-li" *ngFor="let attachment of item.attachments; let index = index">
          <a href="/api/Postfach/GetMailAttachment/{{item.Key}}/{{index}}" class="text-color-brand-primary">{{attachment}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
