import { Component, OnInit } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the quickboarding
 * statistic table.
 */
@Component({
  selector: 'mp-leas-quickboarding-statistic',
  templateUrl: './quickboarding-statistic.component.html'
})
export class QuickboardingStatisticComponent implements OnInit {

  public excelService: string = '/api/Statistik/QuickboardingStatistik2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _leasQuickboardingStatisticLoader: MpLoader = {
    name: 'leasQuickboardingStatisticLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('GewinnkalenderStatistik', 'locGewinnkalender');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._leasQuickboardingStatisticLoader);

    this._mpLoaderService.extendLoader('leasQuickboardingStatisticLoader', {
      isReady: true
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasQuickboardingStatisticLoader');
  }

}
