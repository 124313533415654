import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * This class provides the functions for
 * the initial login.
 */
@Injectable({
  providedIn: 'root'
})
export class InitialLoginService implements OnDestroy {

  public step: number = 1;
  public stepSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public stepObservable: Observable<number> = this.stepSubject.asObservable();
  public validBool: boolean = false;
  public validateCalled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validateCalledObservable: Observable<boolean> = this.validateCalled.asObservable();
  public valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validObservable: Observable<boolean> = this.valid.asObservable();
  public nextCalled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public nextCalledObservable: Observable<boolean> = this.nextCalled.asObservable();
  public success: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public successObservable: Observable<boolean> = this.success.asObservable();

  private _validateCalledScubscription: Subscription | undefined;
  private _nextCalledScubscription: Subscription | undefined;

  constructor() { }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._validateCalledScubscription !== 'undefined') {
      this._validateCalledScubscription.unsubscribe();
    }

    if (typeof this._nextCalledScubscription !== 'undefined') {
      this._nextCalledScubscription.unsubscribe();
    }
  }

  /**
   * Sets the step to validate
   * state.
   */
  validate(): void {
    this.validateCalled.next(true);
  }

  /**
   * Sets the next state.
   */
  next(): void {
    this.success.next(false);
    this.nextCalled.next(true);
  }

  /**
   * Success function for next
   * step.
   */
  nextSuccess(): void {
    this.success.next(true);
    this.nextCalled.next(false);
    this.validBool = false;
    this.valid.next(false);
  }

  /**
   * Failure function for next
   * step.
   */
  nextFailure(): void {
    this.success.next(false);
    this.nextCalled.next(false);
    this.validBool = false;
    this.valid.next(false);
  }

  /**
   * Validates the step.
   */
  validateStep(step: number): Observable<boolean> {
    const validateStepSubject = new Subject<boolean>();

    this._validateCalledScubscription = this.validateCalledObservable.subscribe((validateCalled: boolean) => {
      validateStepSubject.next(this.step === step && validateCalled);
    });

    return validateStepSubject.asObservable();
  }

  /**
   * Returns whether or not next
   * step is called.
   */
  nextCalledStep(step: number): Observable<boolean> {
    const nextCalledStepSubject = new Subject<boolean>();

    this._nextCalledScubscription = this.nextCalledObservable.subscribe((nextCalled: boolean) => {
      nextCalledStepSubject.next(this.step === step && nextCalled);
    });

    return nextCalledStepSubject.asObservable();
  }
}
