import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription, Subject, BehaviorSubject, Observable } from 'rxjs';

import { ShoppingBasketItem } from './shopping-basket-item';
import { ApiService } from './../../../../services/api.service';
import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpSidebarService } from './../../../../components/sidebar/mp-sidebar.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { AuthService } from './../../../../services/auth.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';
import { MpSettingsService } from '../../../../services/mp-settings.service';

/*
 * This class provides functions to handle
 * articles, addresses, and so on in the
 * shopping basket.
 */
@Injectable({
  providedIn: 'root'
})
export class MpShoppingBasketService {

  public shoppingBasket: Array<ShoppingBasketItem> = [];
  public address: { [key: string]: any } = {};
  public groupedBasket: { [key: string]: any } = {};
  public blockingValidationMessages: Array<any> = [];

  private _addressObserver: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [key: string]: any }>({});
  public addressObserver: Observable<{ [key: string]: any }> = this._addressObserver.asObservable();
  private _shoppingBasketObserver: BehaviorSubject<Array<ShoppingBasketItem>> = new BehaviorSubject<Array<ShoppingBasketItem>>([]);
  public shoppingBasketObserver: Observable<Array<ShoppingBasketItem>> = this._shoppingBasketObserver.asObservable();

  private _loading: boolean = false;
  private _role: string = '';
  private _savedItem: { [key: string]: any } = {};
  private _deleteItemSubscription: Subscription | undefined;
  private _updateItemSubscription: Subscription | undefined;
  private _validateBasketSubscription: Subscription | undefined;
  private _refreshBasketSubscription: Subscription | undefined;
  private _addItemSubscription: Subscription | undefined;
  private _setAddressSubscription: Subscription | undefined;
  private _isInSubscription: Subscription | undefined;
  private _locsConfirmDialogSubscription: Subscription | undefined;

  constructor(
    private _ls: MpLocalizationService,
    private _apiService: ApiService,
    private _router: Router,
    private _mpSidebar: MpSidebarService,
    private _location: Location,
    private _mpMessaging: MpMessagingService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    private _mpSettings: MpSettingsService
  ) {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }
  }

  /**
   * Deletes an item from the shopping
   * basket.
   */
  deleteItem(key: string): void {
    this._deleteItemSubscription = this._apiService.postRequest('/api/Warenkorb/deleteItem', { Key: key }).subscribe((data: any) => {
      this._setSBFromData(data);

      if (typeof this._deleteItemSubscription !== 'undefined') {
        this._deleteItemSubscription.unsubscribe();
      }
    });
  }

  /**
   * Updates an item of the shopping
   * basket.
   */
  updateItem(vsKey: string, artNr: string, pin: string, hasExpress: boolean, count: number, lastScrollY?: number, callback?: Function): void {
    lastScrollY = lastScrollY || 0;

    this._updateItemSubscription = this._apiService.postRequest('/api/Warenkorb/updateItem', { Key: vsKey, ArtNr: artNr, Pin: pin, Express: hasExpress, Anzahl: count }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._setSBFromData(data);

        setTimeout(function () {
          if (typeof lastScrollY !== 'undefined' && lastScrollY > 0) {
            if (window.scrollY !== lastScrollY) {
              window.scroll(0, lastScrollY);
            }
          }
        }, 50);
      } else {
        this._apiService.getRequest('/api/Warenkorb/get').subscribe((data: any) => {
          this._setSBFromData(data);
        });

        this._mpMessaging.openWarningPanel(data.Message, 10);
      }

      if (typeof callback !== 'undefined') {
        callback();
      }

      if (typeof this._updateItemSubscription !== 'undefined') {
        this._updateItemSubscription.unsubscribe();
      }
    });
  }

  /**
   * Validates the shopping basket.
   */
  validate(step: string): Observable<boolean> {
    const validateSubject = new Subject<boolean>();

    this._validateBasketSubscription = this._apiService.getRequest('/api/Warenkorb/Validate' + (step ? '?step=' + step : '')).subscribe((data: any) => {
      if (data.Result !== "OK") {
        this._mpMessaging.openDangerPanel((data.Records as any[]).map(r => r['Message']).reduce(
          function (a, b) {
            if (!a) return b;
            return a + '<br>' + b;
          }, ''), 30);

        validateSubject.next(false);
      } else if (this._mpSettings.settings['BestellprozessSettings'].RedemptionlimitActive) {
        this._mpMessaging.openWarningPanel((data.Records as any[]).map(r => r['Message']).reduce(
          function (a, b) {
            if (!a) return b;
            return a + '<br>' + b;
          }, ''), 30);
        validateSubject.next(true);
      } else {
        validateSubject.next(true);
      }

      if (typeof this._validateBasketSubscription !== 'undefined') {
        this._validateBasketSubscription.unsubscribe();
      }
    });

    return validateSubject.asObservable();
  }

  /**
   * Sets the shopping basket data and
   * information by the given data object.
   */
  private _setSBFromData(data: any) {
    if (data && data.Result === 'OK') {
      this.shoppingBasket = data.Records[0].Items;
      this.address = data.Records[0].Adresse;
      this._addressObserver.next(this.address);
      this.groupedBasket = data.Records[0].GroupedItems;
      const groupedBasketKeys = Object.keys(this.groupedBasket);

      if (groupedBasketKeys.length > 0) {
        groupedBasketKeys.forEach(key => {
          for (let i = 0; i < this.groupedBasket[key].length; ++i) {
            var item = this.groupedBasket[key][i];

            if (item == null || item.CurrentAuspraegung == null)
              continue;

            var bild = item.CurrentAuspraegung.Bild;
            if (bild !== null) {
              for (const [k] of Object.entries(item)) {
                if (k === 'Bild') {
                  // <imgPath>\<ArtNr>_<PIN><separator><nrExtension> ==> <impPath>\<ArtNr>_<item.PIN><separator><nrExtension> (https://cdn.netcentive.de/v5/1800x1800\P22571_01-01.jpg)
                  var pin = bild.slice(-9, -7);
                  var underscore = bild.slice(-10, -9);
                  var idx = bild.indexOf(underscore) + 1;
                  var nrExtension = bild.slice(-7);
                  var pathArtNr = bild.slice(0, idx);
                  if (pin === '00') {
                    item.Bild = pathArtNr + '00' + nrExtension;
                  } else {
                    item.Bild = pathArtNr + item.PIN + nrExtension;
                  }
                }
              }
            }
          }
        });
      }

      this._shoppingBasketObserver.next(this.shoppingBasket);
    } else if (data && data.Message) {
      if (data.Message == 'NoDefaultAddress') {
        if (Object.keys(this._ls.locs).length > 0) {
          if (typeof this._locsConfirmDialogSubscription !== 'undefined') {
            this._locsConfirmDialogSubscription.unsubscribe();
          }

          this._mpMessaging.openConfirm({
            text: this._ls.locs['loc'].TnOhneStdAdresseConfirm,
            submitText: this._ls.locs['loc'].Ja,
            cancelText: this._ls.locs['loc'].Nein
          }, () => {
            this._router.navigate([`/${this._role}/Einstellungen`]);
            this._mpMessaging.closeOverlay();
          }, () => {
            this._mpMessaging.closeOverlay();
          });
        } else {
          this._locsConfirmDialogSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
            if (typeof this._locsConfirmDialogSubscription !== 'undefined') {
              this._locsConfirmDialogSubscription.unsubscribe();
            }

            this._mpMessaging.openConfirm({
              text: this._ls.locs['loc'].TnOhneStdAdresseConfirm,
              submitText: this._ls.locs['loc'].Ja,
              cancelText: this._ls.locs['loc'].Nein
            }, () => {
              this._router.navigate([`/${this._role}/Einstellungen`]);
              this._mpMessaging.closeOverlay();
            }, () => {
              this._mpMessaging.closeOverlay();
            });
          });

          this._ls.getLocalization();
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    }

    this._loading = false;
  }

  /**
   * Opens the sidber for the shopping
   * basket overview.
   */
  showSidebar(): void {
    this._mpSidebar.open('sbOverview', { sbItemAdded: true });
  }

  /**
   * Refreshes the shopping basket.
   */
  refreshShoppingBasket(force?: boolean): void {
    if ((this.shoppingBasket.length === 0 && !this._loading) || force) {
      this._loading = true;

      this._refreshBasketSubscription = this._apiService.getRequest('/api/Warenkorb/get').subscribe((data: any) => {
        this._setSBFromData(data);

        if (typeof this._refreshBasketSubscription !== 'undefined') {
          this._refreshBasketSubscription.unsubscribe();
        }
      });
    }
  }

  /**
   * Saves given item in shopping
   * basket.
   */
  saveSBItem(artNr: string, pin: string, count: number, hasExpress: boolean): void {
    this._savedItem = {
      ArtNr: artNr,
      PIN: pin,
      Anzahl: count,
      Express: hasExpress
    }
  }

  /**
   * Resets the saved item.
   */
  resetSavedSBItem(): void {
    this._savedItem = {};
  }

  /**
   * Returns the saved item.
   */
  retrieveSavedSBItem(): any {
    return this._savedItem;
  }

  /**
   * Adds the saved item to the basket.
   */
  setSavedSBItem(callback?: Function) {
    if (Object.keys(this._savedItem).length > 0) {
      if (typeof this.addItem !== 'undefined') {
        this.addItem(this._savedItem['ArtNr'], this._savedItem['PIN'], this._savedItem['Express'], this._savedItem['Anzahl'], false, callback);
      }
    }
  }

  /**
   * Sets the given address.
   */
  setAddress(vlAddressID: string): void {
    this._setAddressSubscription = this._apiService.postRequest('/api/Warenkorb/setAdresse', { AdressID: vlAddressID }).subscribe((data: any) => {
      this._setSBFromData(data);

      if (typeof this._setAddressSubscription !== 'undefined') {
        this._setAddressSubscription.unsubscribe();
      }
    });
  }

  /**
   * Checks whether or not the article
   * is in the shopping basket.
   */
  isIn(artNr: string, pin: string): void {
    this._isInSubscription = this._apiService.postRequest('/api/Warenkorb/isIn', { ArtNr: artNr, Pin: pin }).subscribe((data: any) => {
      if (typeof this._isInSubscription !== 'undefined') {
        this._isInSubscription.unsubscribe();
      }

      return data;
    });
  }

  /**
   * Adds the given item to the
   * shopping basket.
   */
  addItem(artNr: string, pin: string, hasExpress: boolean, count?: number, hasAdditional?: boolean, callback?: Function): any {
    if (!count) count = 1;

    if (hasAdditional) {
      this.saveSBItem(artNr, pin, count, hasExpress);

      const prom = new Promise((resolve, reject) => {
        resolve({ Result: "OK", Records: this.shoppingBasket });
      });

      this._router.navigateByUrl(`/${this._role}/Zusatzabfragen`);

      return prom;
    } else {
      this._addItemSubscription = this._apiService.postRequest('/api/Warenkorb/addItem', { ArtNr: artNr, Pin: pin, Express: hasExpress, Anzahl: count }).subscribe((data: any) => {
        if (data.Result === "OK") {
          this._setSBFromData(data);

          if (window.innerHeight >= 620) {
            this.showSidebar();
          } else {
            this._mpMessaging.openPanelFromResultResponse(data);
          }

          callback && callback(data);
        } else {
          this._mpMessaging.openPanelFromResultResponse(data);
        }

        if (typeof this._addItemSubscription !== 'undefined') {
          this._addItemSubscription.unsubscribe();
        }
      });
    }
  }
}
