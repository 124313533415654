<div id="address-edit-area">
  <div class="sidebar-grid" [ngClass]="{'two-sidebar-buttons': showSaveAnyway}" *ngIf="(ls.locsLoaded | async) && editAddress !== null && editAddressCloneSet">
    <div id="overlay-headline">
      <span *ngIf="!editAddressClone.AdressID || editAddressClone.AdressID < 1">{{ ls.locs['loc'].NeueLieferadresseEingeben }}</span>
      <span *ngIf="editAddressClone.AdressID > 0">{{ ls.locs['loc'].LieferadresseBearbeiten }}</span>
    </div>
    <div class="divider margin-top-bottom-0"></div>
    <mp-core-scroll-button id="overlay-scroll-up"
                           [ifNeeded]="true"
                           scrollItemHeight="80"
                           scrollArea="#shipping-scroll-area"
                           scrollDirection="up"
                           scrollFactor="1"
                           scopeObject="editAddressClone"
                           class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 padding-top-16 padding-bottom-16 scroll-area-container overflow-hidden" id="shipping-scroll-area-container">
      <div id="shipping-scroll-area" class="sidebar-scroll-area">
        <mp-core-select [(model)]="editAddressClone.Land"
                        label="{{ ls.locs['loc'].LKZ }}"
                        [elements]="adr.ddlData.Laenderliste"
                        mpId="ddlLKZ"
                        textProperty="Land"
                        [mpRequired]="true"
                        (ngChange)="setSalutations()"
                        [hasError]="adr.errors['data.LKZ'] ? true : false">
        </mp-core-select>
        <mp-core-form-error *ngIf="adr.errors['data.LKZ']" [model]="adr.errors['data.LKZ']"></mp-core-form-error>
        <ul class="tab-flex margin-top-40 flex-grid margin-bottom-24" [hidden]="editAddressClone.Land.Kuerzel !== 'DE'">
          <li *ngFor="let aa of adr.ddlData.AdressArten" class="flex-col-6 padding-0">
            <a href="javascript:void(0)" class="tablinks text-transform-uppercase" (click)="editAddressClone.AdressArtID = aa.ID" [ngClass]="{'active':editAddressClone.AdressArtID === aa.ID}">{{aa.Bezeichnung}}</a>
          </li>
        </ul>
        <div class="flex-grid">
          <div class="flex-col-4 flex-direction-column margin-top-16">
            <mp-core-select [hasError]="adr.errors['data.AnredenID'] ? true : false"
                            label="{{ ls.locs['loc'].Anrede }}"
                            [elements]="adr.anreden"
                            mpId="ddlAnrede"
                            textProperty="Anrede"
                            (ngChange)="updateSalutations()"
                            [(model)]="editAddressClone.AnredeObjekt"
                            [mpRequired]="true">
            </mp-core-select>
            <mp-core-form-error *ngIf="adr.errors['data.AnredenID']" [model]="adr.errors['data.AnredenID']"></mp-core-form-error>
          </div>
          <div class="flex-col-4 flex-direction-column margin-top-16">
            <mp-core-input-with-correction mpType="text"
                                           label="{{ ls.locs['loc'].Vorname }}"
                                           [(model)]="editAddressClone.Vorname"
                                           mpId="Vorname"
                                           [hasError]="adr.errors['data.Vorname'] ? true : false"
                                           [mpRequired]="true"
                                           mpCorrection="{{adr.correctedAddress.Vorname}}">
            </mp-core-input-with-correction>
            <mp-core-form-error *ngIf="adr.errors['data.Vorname']" [model]="adr.errors['data.Vorname']"></mp-core-form-error>
          </div>
          <div class="flex-col-4 flex-direction-column margin-top-16">
            <mp-core-input-with-correction mpType="text"
                                           label="{{ ls.locs['loc'].Name }}"
                                           [(model)]="editAddressClone.Name"
                                           mpId="Name"
                                           [hasError]="adr.errors['data.Name']? true : false"
                                           [mpRequired]="true"
                                           mpCorrection="{{adr.correctedAddress.Name}}">

            </mp-core-input-with-correction>
            <mp-core-form-error *ngIf="adr.errors['data.Name']" [model]="adr.errors['data.Name']"></mp-core-form-error>
          </div>
        </div>
        <div class="flex-grid margin-top-16 padding-left-right-8">
          <mp-core-input mpType="text"
                         label="{{ ls.locs['loc'].FirmaInAdresse }}"
                         [(model)]="editAddressClone.Firma"
                         mpId="Firma"
                         [hasError]="adr.errors['data.Firma'] ? true : false"
                         [mpOptional]="true">
          </mp-core-input>
          <mp-core-form-error *ngIf="adr.errors['data.Firma']" [model]="adr.errors['data.Firma']"></mp-core-form-error>
        </div>

        <div class="detailsProAdresstyp" [ngSwitch]="editAddressClone.Kuerzel">
          <div *ngSwitchCase="editAddressClone.Land.Kuerzel['DE']">
            <div [ngSwitch]="editAddressClone.AdressArtID">

              <!--Packstation-->
              <div *ngSwitchCase="2">
                <div class="flex-grid margin-top-16 padding-left-right-8">
                  <mp-core-input mpType="text" label="{{ ls.locs['loc'].Packstation }}" [(model)]="editAddressClone.Strasse" mpId="Strasse" [hasError]="adr.errors['data.Packstation'] ? true : false" [mpRequired]="true"></mp-core-input>
                  <mp-core-form-error *ngIf="adr.errors['data.Packstation']" [model]="adr.errors['data.Packstation']"></mp-core-form-error>
                </div>
                <div class="flex-grid margin-top-16 padding-left-right-8">
                  <mp-core-input mpType="text" label="{{ ls.locs['loc'].PostNummer }}" [(model)]="editAddressClone.Adresszusatz" mpId="Adresszusatz" [hasError]="adr.errors['data.Adresszusatz'] ? true : false" [mpRequired]="true"></mp-core-input>
                  <mp-core-form-error *ngIf="adr.errors['data.Adresszusatz']" [model]="adr.errors['data.Adresszusatz']"></mp-core-form-error>
                </div>
                <div class="flex-grid">
                  <div class="flex-col-4 flex-direction-column margin-top-16">
                    <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].PLZ }}" [(model)]="editAddressClone.PLZ" mpId="PLZ" [hasError]="adr.errors['data.PLZ'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Plz}}"></mp-core-input-with-correction>
                    <mp-core-form-error *ngIf="adr.errors['data.PLZ']" [model]="adr.errors['data.PLZ']"></mp-core-form-error>
                  </div>
                  <div class="flex-col-8 flex-direction-column margin-top-16">
                    <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].Ort }}" [(model)]="editAddressClone.Ort" mpId="Ort" [hasError]="adr.errors['data.Ort'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Ort}}"></mp-core-input-with-correction>
                    <mp-core-form-error *ngIf="adr.errors['data.Ort']" [model]="adr.errors['data.Ort']"></mp-core-form-error>
                  </div>
                </div>
                <a href="http://standorte.deutschepost.de/Standortsuche?standorttyp=packstationen_paketboxen&pmtype=4" target="_blank" class="mp-button margin-top-16">{{ ls.locs['loc'].PackstationFinden }}</a>
              </div>

              <!--Postfiliale-->
              <div *ngSwitchCase="3">
                <div class="flex-grid margin-top-16 padding-left-right-8">
                  <mp-core-input mpType="text" label="{{ ls.locs['loc'].NummerDerPostfiliale }}" [(model)]="editAddressClone.Strasse" mpId="Strasse" [hasError]="adr.errors['data.PostfilialNr'] ? true : false" [mpRequired]="true"></mp-core-input>
                  <mp-core-form-error *ngIf="adr.errors['data.PostfilialNr']" [model]="adr.errors['data.PostfilialNr']"></mp-core-form-error>
                </div>
                <div class="flex-grid margin-top-16 padding-left-right-8">
                  <mp-core-input mpType="text" label="{{ ls.locs['loc'].PostNummer }}" [(model)]="editAddressClone.Adresszusatz" mpId="Adresszusatz" [hasError]="adr.errors['data.Adresszusatz'] ? true : false" [mpRequired]="true"></mp-core-input>
                  <mp-core-form-error *ngIf="adr.errors['data.Adresszusatz']" [model]="adr.errors['data.Adresszusatz']"></mp-core-form-error>
                </div>
                <div class="flex-grid">
                  <div class="flex-col-4 flex-direction-column margin-top-16">
                    <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].PLZ }}" [(model)]="editAddressClone.PLZ" mpId="PLZ" [hasError]="adr.errors['data.PLZ'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Plz}}"></mp-core-input-with-correction>
                    <mp-core-form-error *ngIf="adr.errors['data.PLZ']" [model]="adr.errors['data.PLZ']"></mp-core-form-error>
                  </div>
                  <div class="flex-col-8 flex-direction-column margin-top-16">
                    <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].Ort }}" [(model)]="editAddressClone.Ort" mpId="Ort" [hasError]="adr.errors['data.Ort'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Ort}}"></mp-core-input-with-correction>
                    <mp-core-form-error *ngIf="adr.errors['data.Ort']" [model]="adr.errors['data.Ort']"></mp-core-form-error>
                  </div>
                </div>
                <a href="http://standorte.deutschepost.de/Standortsuche?standorttyp=filialen_verkaufspunkte" target="_blank" class="mp-button margin-top-16">{{ ls.locs['loc'].PostfilialeFinden }}</a>
              </div>

              <!--Persönliche Lieferadresse-->
              <div *ngSwitchDefault>
                <div class="flex-grid margin-top-16 padding-left-right-8">
                  <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].Strasse_Nr }}" [(model)]="editAddressClone.Strasse" mpId="Strasse" [hasError]="adr.errors['data.Strasse'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Strasse}}"></mp-core-input-with-correction>
                  <mp-core-form-error *ngIf="adr.errors['data.Strasse']" [model]="adr.errors['data.Strasse']"></mp-core-form-error>
                </div>
                <div class="flex-grid margin-top-16 padding-left-right-8">
                  <mp-core-input mpType="text" label="{{ ls.locs['loc'].Adresszusatz }}" [(model)]="editAddressClone.Adresszusatz" mpId="Adresszusatz" [hasError]="adr.errors['data.Adresszusatz'] ? true : false" [mpOptional]="true"></mp-core-input>
                  <mp-core-form-error *ngIf="adr.errors['data.Adresszusatz']" [model]="adr.errors['data.Adresszusatz']"></mp-core-form-error>
                </div>
                <div class="flex-grid">
                  <div class="flex-col-4 flex-direction-column margin-top-16">
                    <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].PLZ }}" [(model)]="editAddressClone.PLZ" mpId="PLZ" [hasError]="adr.errors['data.PLZ'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Plz}}"></mp-core-input-with-correction>
                    <mp-core-form-error *ngIf="adr.errors['data.PLZ']" [model]="adr.errors['data.PLZ']"></mp-core-form-error>
                  </div>
                  <div class="flex-col-8 flex-direction-column margin-top-16">
                    <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].Ort }}" [(model)]="editAddressClone.Ort" mpId="Ort" [hasError]="adr.errors['data.Ort'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Ort}}"></mp-core-input-with-correction>
                    <mp-core-form-error *ngIf="adr.errors['data.Ort']" [model]="adr.errors['data.Ort']"></mp-core-form-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div class="flex-grid margin-top-16 padding-left-right-8">
              <mp-core-input mpType="text" label="{{ ls.locs['loc'].Strasse_Nr }}" [(model)]="editAddressClone.Strasse" mpId="Strasse" [hasError]="adr.errors['data.Strasse'] ? true : false" [mpRequired]="true"></mp-core-input>
              <mp-core-form-error *ngIf="adr.errors['data.Strasse']" [model]="adr.errors['data.Strasse']"></mp-core-form-error>
            </div>
            <div class="flex-grid margin-top-16 padding-left-right-8">
              <mp-core-input mpType="text" label="{{ ls.locs['loc'].Adresszusatz }}" [(model)]="editAddressClone.Adresszusatz" mpId="Adresszusatz" [hasError]="adr.errors['data.Adresszusatz'] ? true : false" [mpOptional]="true"></mp-core-input>
              <mp-core-form-error *ngIf="adr.errors['data.Adresszusatz']" [model]="adr.errors['data.Adresszusatz']"></mp-core-form-error>
            </div>
            <div class="flex-grid">
              <div class="flex-col-4 flex-direction-column margin-top-16">
                <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].PLZ }}" [(model)]="editAddressClone.PLZ" mpId="PLZ" [hasError]="adr.errors['data.PLZ'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Plz}}"></mp-core-input-with-correction>
                <mp-core-form-error *ngIf="adr.errors['data.PLZ']" [model]="adr.errors['data.PLZ']"></mp-core-form-error>
              </div>
              <div class="flex-col-8 flex-direction-column margin-top-16">
                <mp-core-input-with-correction mpType="text" label="{{ ls.locs['loc'].Ort }}" [(model)]="editAddressClone.Ort" mpId="Ort" [hasError]="adr.errors['data.Ort'] ? true : false" [mpRequired]="true" mpCorrection="{{adr.correctedAddress.Ort}}"></mp-core-input-with-correction>
                <mp-core-form-error *ngIf="adr.errors['data.Ort']" [model]="adr.errors['data.Ort']"></mp-core-form-error>
              </div>
            </div>
          </div>
        </div>
        <mp-core-checkbox class="margin-top-24 address-sidebar-checkbox display-flex flex-align-items-start flex-justify-cont-start"
                          mpId="chkStdAdresse['editAddressClone.AdressID']"
                          [(model)]="editAddressClone['WillBeStdAdresse']"
                          label="{{ ls.locs['loc'].SaveAsStdAdresse }}"
                          [ngDisabled]="editAddressClone['IsStdAdresse']">

        </mp-core-checkbox>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                           [ifNeeded]="true"
                           scrollItemHeight="80"
                           scrollArea="#shipping-scroll-area"
                           scrollDirection="down"
                           scrollFactor="1"
                           scopeObject="editAddressClone"
                           class="scrollbutton-down margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href [hidden]="showSaveAnyway" (click)="save($event);" scroll-button-rerenderer data-scroll-item-height="80" data-scroll-top-zero="true" data-scroll-area="#shipping-scroll-area" class="mp-button _prim display-block">{{ ls.locs['loc'].Speichern }}</a>
      <a href [hidden]="!showSaveAnyway" (click)="save($event)" [ngClass]="hasUnusedCorrections ? 'disabled' : ''" scroll-button-rerenderer data-scroll-item-height="80" data-scroll-top-zero="true" data-scroll-area="#shipping-scroll-area" class="mp-button _prim display-block">{{ ls.locs['loc'].NochmalPruefen }}</a>
      <a href [hidden]="!showSaveAnyway" (click)="saveWithoutValidation($event)" scroll-button-rerenderer data-scroll-item-height="80" data-scroll-top-zero="true" data-scroll-area="#shipping-scroll-area" class="mp-button _sec display-block margin-top-8">{{ ls.locs['loc'].TrotzdemSpeichern }}</a>
    </div>
  </div>
</div>
