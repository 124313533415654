<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Praemienmanager }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Praemienmanager }}"
                      *ngIf="categoriesLoaded"
                      infotextShorten="Praemienmanager"
                      fieldsAction="/api/Statistik/getPraemienmanagerParentFields"
                      listAction="/api/Statistik/getPraemienmanager"
                      loaderName="managerAwardManagerLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="ArtNrPIN"
                      [headerSelectTransclude]="true">
    <mp-core-select [hidden]="categories.length === 0" label="{{ ls.locs['loc'].Kategorie }}" mpId="praemien-manager-kategorie" [model]="category['id']" [mpRequired]="true" (modelChange)="category['id'] = $event; changeCategory()" [elements]="categories" textProperty="Text" valueProperty="KategorieID"></mp-core-select>
  </mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button angular-ripple class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
