<div *ngIf="ls.locsLoaded | async"
     class="row"
     startdate="von"
     enddate="bis">
  <div class="grid col-large-8">
    <div class="margin-bottom-16">
      <mp-core-date-range-picker [startdate]="von"
                                 (startdateChange)="von = $event; vonChanged();"
                                 [enddate]="bis"
                                 (enddateChange)="bis = $event; bisChanged();"
                                 [minDate]="minDate"
                                 [maxDate]="maxDate">
      </mp-core-date-range-picker>
    </div>
  </div>
  <div class="grid col-large-4">
    <div class="margin-bottom-16">
      <div class="">
        <div class="selectContainer arrowFix">
          <mp-core-select mpId="zeitraum"
                          [model]="selectedPreset"
                          (modelChange)="selectedPreset = $event; onSelectedPresetChange()"
                          textProperty="Bezeichnung"
                          [mpRequired]="true"
                          [elements]="presetList">
          </mp-core-select>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>
