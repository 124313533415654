import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import moment from 'moment';

import { MpLocalizationService } from './mp-localization.service';
import { ApiService } from './api.service';
import { MpMessagingService } from './mp-messaging.service';
import { AuthService } from './auth.service';
import { RoleMappingService } from './role-mapping.service';

/**
 * This class provides functionalities /
 * services for the communication process.
 */
@Injectable({
  providedIn: 'root'
})
export class MpCommunicationProcessService {

  public paths: Array<string> = [];
  public steps: { [key: string]: any } = {};
  public locationChangeExpected: boolean = false;
  public communicationId: number = 0;
  public skipPopUpData: { [key: string]: any } = {
    Show: false
  };
  public clearPopUpData: { [key: string]: any } = {
    Show: false
  };
  public resetPopUpData: { [key: string]: any } = {
    Show: false
  };
  public currentStep: { [key: string]: any } | null = {};

  private _updateSteps: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [key: string]: any }>({});
  public stepsObserver: Observable<{ [key: string]: any }> = this._updateSteps.asObservable();

  private _savedData: { [key: string]: any } = {};
  private _confirmedSteps: Array<any> = [];
  private _skippedSteps: Array<any> = [];
  private _currentKey: string = '';
  private _goToFirstPage: boolean = false;
  private _validateStep: string = '';
  private _role: string = '';
  private _loadLocsSubscription: Subscription | undefined;
  private _loadStepsSubscription: Subscription | undefined;
  private _loadCommunicationProcessSubscription: Subscription | undefined;
  private _saveSubscription: Subscription | undefined;

  /**
   * Gets the role, the locs, and the
   * steps.
   */
  constructor(
    private _ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _authService: AuthService,
    private _router: Router,
    private _roleMapping: RoleMappingService
  ) {
    this._role = this._authService.getRole();

    if (typeof this._role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(this._role);
    }

    const currentStepFromSession = sessionStorage.getItem('kpCurrentStep');
    this.currentStep = currentStepFromSession !== null ? JSON.parse(currentStepFromSession) : null;

    if (Object.keys(this._ls.locs).length > 0) {
      this._setPopupData();
    } else {
      this._loadLocsSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setPopupData();

          if (typeof this._loadLocsSubscription !== 'undefined') {
            this._loadLocsSubscription.unsubscribe();
          }
        }
      });
    }
  }

  /**
   * Sets the popup data, after locs
   * loaded.
   */
  private _setPopupData(): void {
    this.clearPopUpData = {
      Show: false,
      Title: this._ls.locs['loc'].KommunikationsprozessCancelConfirmTitle,
      Text: this._ls.locs['loc'].KommunikationsprozessCancelConfirmText
    }

    this.resetPopUpData = {
      Show: false,
      Title: this._ls.locs['loc'].AenderungenRueckgaengigHinweisTitel,
      Text: this._ls.locs['loc'].AenderungenRueckgaengigHinweisText
    }

    this._loadSteps();
  }

  /**
   * Loads the steps of the communication
   * process.
   */
  private _loadSteps(): void {
    this._loadStepsSubscription = this._apiService.getRequest('/api/KommunikationProzess/GetSteps').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.steps = data.Records[0];
        const skippedStepsFromStorage = sessionStorage.getItem('kpSkippedSteps');
        const tmpSkippedSteps = skippedStepsFromStorage !== null ? JSON.parse(skippedStepsFromStorage) : null;

        if (tmpSkippedSteps !== null) {
          tmpSkippedSteps.forEach((step: any) => {
            this.steps[step].Skipped = true;
          });
        }

        sessionStorage.setItem('kpSteps', JSON.stringify(this.steps));

        this.paths = Object.keys(this.steps).map((key: string) => {
          return `/${this._role}/${this.steps[key].Url}`;
        });

        this._updateSteps.next(this.steps);

        if (this._goToFirstPage) {
          this._goToFirstPageFunc();
          this._goToFirstPage = false;
        } else if (this._currentKey !== '') {
          this._setCurrentStep(this._currentKey);
        } else if (this._validateStep !== '') {
          this.isValidStep(this._validateStep);
        } else {
          this._setCurrentStep('teilnehmer-auswahl');
        }

        if (typeof this._loadStepsSubscription !== 'undefined') {
          this._loadStepsSubscription.unsubscribe();
        }
      }
    });
  }

  /**
   * Gets and returns the steps.
   */
  private _getSteps(): { [key: string]: any } {
    if (Object.keys(this.steps).length === 0) {
      const stepsFromStorage = sessionStorage.getItem('kpSteps');
      const tmpSteps = stepsFromStorage !== null ? JSON.parse(stepsFromStorage) : null;

      if (tmpSteps !== null) {
        this.steps = tmpSteps
      }
    }

    return this.steps;
  }

  /**
   * Sets the current step.
   */
  private _setCurrentStep(pageKey: string): void {
    this._currentKey = pageKey;
    let currentStep = this._getSteps()[pageKey];

    if (typeof currentStep === 'undefined') {
      currentStep = {};
    }

    if (typeof currentStep !== 'undefined') {
      currentStep['Skipped'] = false;
      this.currentStep = currentStep;
      sessionStorage.setItem('kpCurrentStep', JSON.stringify(currentStep));
    }
  }

  /**
   * Goes to the first page / step of
   * the communication process.
   */
  private _goToFirstPageFunc(): void {
    const sortBy = (key: any) => {
      return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
    };

    const steps = this._getSteps();

    if (Object.keys(steps).length > 0) {
      // @ts-ignore
      const currentStep = Object.values(steps).concat().sort(sortBy('Index'))[0];
      this._router.navigateByUrl(`/${this._role}/${currentStep['Url']}` + (this.communicationId ? '?id=' + this.communicationId : ''));
      this._setCurrentStep(currentStep['Key']);
    } else {
      this._goToFirstPage = true;
    }
  }

  /**
   * Validates the step by the given
   * key.
   */
  isValidStep(key: string): boolean {
    if (Object.keys(this.steps).length === 0) {
      this._validateStep = key;
      return false;
    }

    if (typeof this.steps[key] === 'undefined') {
      this._validateStep = key;
      this.goToCurrentStep();
      return false;
    }

    if ((this.currentStep !== null && Object.keys(this.currentStep).length && this.steps[key].Index >= this.currentStep['Index']) ||
      this.steps[key].Index < Object.keys(this.steps).length) {
      this._setCurrentStep(key);
      return true;
    }

    return false;
  }

  /**
   * Goes to the current step of the
   * communication process.
   */
  goToCurrentStep(): void {
    if (this.currentStep !== null && typeof this.currentStep['Url'] !== 'undefined') {
      this._router.navigateByUrl(`/${this._role}/${this.currentStep['Url']}`);
    } else {
      this._goToFirstPageFunc();
    }
  }

  /**
   * Gets the skipped steps of the
   * communication process.
   */
  private _getSkippedSteps(): Array<any> {
    if (this._skippedSteps.length === 0) {
      const skippedStepsFromStorage = sessionStorage.getItem('kpSkippedSteps');
      const tmpSteps = skippedStepsFromStorage !== null ? JSON.parse(skippedStepsFromStorage) : null;

      if (tmpSteps !== null) {
        this._skippedSteps = tmpSteps
      }
    }

    return this._skippedSteps;
  }

  /**
   * Gets the confirmed steps
   * of the communication process.
   */
  getConfirmedSteps(): Array<any> {
    if (this._confirmedSteps.length === 0) {
      const confirmedStepsFromStorage = sessionStorage.getItem('kpConfirmedSteps');
      const tmpSteps = confirmedStepsFromStorage !== null ? JSON.parse(confirmedStepsFromStorage) : null;

      if (tmpSteps !== null) {
        this._confirmedSteps = tmpSteps
      }
    }

    return this._confirmedSteps;
  }

  /**
   * Gets the saved data
   * of the communication process.
   */
  private _getSavedData(): { [key: string]: any } {
    if (Object.keys(this._savedData).length === 0) {
      const savedDataFromStorage = sessionStorage.getItem('kpSavedData');
      const tmpData = savedDataFromStorage !== null ? JSON.parse(savedDataFromStorage) : null;

      if (tmpData !== null) {
        this._savedData = tmpData
      }
    }

    return this._savedData;
  }

  /**
   * Returns the next step of
   * the communication process.
   */
  private _getNextStep(key: string): { [key: string]: any } {
    return this._getSteps()[this._getSteps()[key].NextKey];
  }

  /**
   * Gets and returns the saved data
   * for the given step.
   */
  loadStep(key: string): any {
    if (Object.keys(this._savedData).length === 0) {
      const savedDataFromStorage = sessionStorage.getItem('kpSavedData');
      const tmpData = savedDataFromStorage !== null ? JSON.parse(savedDataFromStorage) : null;

      if (tmpData !== null) {
        this._savedData = tmpData
      }
    }

    return this._savedData[key];
  }

  /**
   * Loads the data for the communication
   * process.
   */
  load(callback?: Function): void {
    this._loadCommunicationProcessSubscription = this._apiService.getRequest(`/api/KommunikationProzess/LoadKommunikationsprozess?communicationId=${this.communicationId}`).subscribe((data: any) => {
      const cpData = data.Records[0] || {};

      if (this.communicationId) {
        this._savedData = {
          'communication-id': this.communicationId,
          'titel': (cpData == undefined || cpData === null || cpData.Titel == undefined ? '' : cpData.Titel)
        }
      } else {
        this.communicationId = 0;
      }

      this._confirmedSteps = [];
      this._skippedSteps = [];
      this._savedData['teilnehmer-auswahl'] = cpData ? cpData.TeilnehmerAuswahl : [];

      if (this._getSteps()['e-mail-modul']) {
        if (cpData !== null && this.communicationId !== 0 && typeof cpData.Skipped !== 'undefined' && cpData.Skipped.indexOf('e-mail-modul') < 0) {
          this._confirmedSteps.push('e-mail-modul');
          this._savedData['e-mail-modul'] = cpData.EmailModul;
        } else {
          this._skippedSteps.push('e-mail-modul');
        }

        if (this._getSteps()['themenbuehnen-editor']) {
          if (cpData !== null && this.communicationId !== 0 && typeof cpData.Skipped !== 'undefined' && cpData.Skipped.indexOf('themenbuehnen-editor') < 0) {
            this._confirmedSteps.push('themenbuehnen-editor');
            this._savedData['themenbuehnen-editor'] = cpData.ThemenbuehnenEditor;
          } else {
            this._skippedSteps.push('themenbuehnen-editor');
          }
        }
      }

      if (this._getSteps()['belohnungs-editor']) {
        if (cpData !== null && this.communicationId !== 0 && typeof cpData.Skipped !== 'undefined' && cpData.Skipped.indexOf('belohnungs-editor') < 0) {
          this._confirmedSteps.push('belohnungs-editor');
          this._savedData['belohnungs-editor'] = cpData.BelohnungsEditor;
        } else {
          this._skippedSteps.push('belohnungs-editor');
        }
      }

      sessionStorage.setItem('kpSavedData', JSON.stringify(this._savedData));
      sessionStorage.setItem('kpSkippedSteps', JSON.stringify(this._skippedSteps));
      sessionStorage.setItem('kpConfirmedSteps', JSON.stringify(this._confirmedSteps));
      sessionStorage.setItem('kpSteps', JSON.stringify(this.steps));

      this._loadSteps();
      this.setDefaultTitle();

      if (typeof callback !== 'undefined') {
        callback();
      }

      if (typeof this._loadCommunicationProcessSubscription !== 'undefined') {
        this._loadCommunicationProcessSubscription.unsubscribe();
      }
    },
    (error: any) => {
      this._mpMessaging.openPanelFromResultResponse(error);

      if (typeof this._loadCommunicationProcessSubscription !== 'undefined') {
        this._loadCommunicationProcessSubscription.unsubscribe();
      }
    });
  }

  /**
   * Sets the default title for
   * the step.
   */
  setDefaultTitle(): void {
    if (!this.loadStep('titel')) {
      const dateText = moment().format('DD.MM.YYYY HH:mm (ssSSS)');
      this.saveStep('titel', `${this._ls.locs['loc'].BenachrichtigungVom} ${dateText}`);
    }
  }

  /**
   * Saves the given data for the
   * given key of the step.
   */
  saveStep(key: string, data: any): void {
    this._savedData[key] = data;
    sessionStorage.setItem('kpSavedData', JSON.stringify(this._savedData));
  }

  /**
   * Saves the communication, that was
   * created in the communication process.
   */
  save(submit?: boolean, callback?: Function): void {
    const dataToSave = {
      CommunicationId: this.loadStep('communication-id'),
      TeilnehmerAuswahl: this.steps['teilnehmer-auswahl'].Skipped ? {} : this.loadStep('teilnehmer-auswahl'),
      EmailModul: !this.steps['e-mail-modul'] || this.steps['e-mail-modul'].Skipped ? null : this.loadStep('e-mail-modul'),
      ThemenbuehnenEditor: !this.steps['themenbuehnen-editor'] || this.steps['themenbuehnen-editor'].Skipped ? null : this.loadStep('themenbuehnen-editor'),
      BelohnungsEditor: !this.steps['belohnungs-editor'] || this.steps['belohnungs-editor'].Skipped ? null : this.loadStep('belohnungs-editor'),
      Eingereicht: typeof submit !== 'undefined' ? submit : false,
      Skipped: this._getSkippedSteps(),
      Titel: this.loadStep('titel')
    };

    this._saveSubscription = this._apiService.postRequest('/api/KommunikationProzess/SaveKommunikationsprozess', dataToSave).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (data.Eingereicht) {
          this._mpMessaging.openSuccessPanel(this._ls.locs['loc'].KommunikationsprozessEinreichenErfolgreich);
        } else {
          this._mpMessaging.openSuccessPanel(this._ls.locs['loc'].KommunikationsprozessSpeichernErfolgreich);
        }

        this.communicationId = data.Records[0];
        this.saveStep('communication-id', this.communicationId);

        if (typeof callback !== 'undefined') {
          callback();
        }
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }

      if (typeof this._saveSubscription !== 'undefined') {
        this._saveSubscription.unsubscribe();
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error);

      if (typeof this._saveSubscription !== 'undefined') {
        this._saveSubscription.unsubscribe();
      }
    });
  }

  /**
   * Checks whether or not the step
   * is skipped.
   */
  checkStepIsSkipped(key: string): boolean {
    if (this._confirmedSteps.length === 0) {
      const confirmedStepsFromStorage = sessionStorage.getItem('kpConfirmedSteps');
      const tmpSteps = confirmedStepsFromStorage !== null ? JSON.parse(confirmedStepsFromStorage) : null;

      if (tmpSteps !== null) {
        this._confirmedSteps = tmpSteps;
      }
    }

    if (this._confirmedSteps.indexOf(key) !== -1) {
      this._getSteps()[key].Skipped = false;
      return false;
    }

    return typeof this._getSteps()[key] === 'undefined' || typeof this._getSteps()[key].Skipped === 'undefined' ||
      typeof this._getSteps()[key].Skipped !== 'undefined' &&
      (this._getSteps()[key].Skipped === null || this._getSteps()[key].Skipped === true);
  }

  /**
   * Skips the given step.
   */
  skipStep(key: string): void {
    this.steps[key].Skipped = true;
    this._skippedSteps.push(key);
    sessionStorage.setItem('kpSkippedSteps', JSON.stringify(this._skippedSteps));
    sessionStorage.setItem('kpSteps', JSON.stringify(this.steps));

    this.skipPopUpData = {
      Show: false
    };

    this.next(key);
  }

  /**
   * Confirms the given step.
   */
  confirmStep(key: string): void {
    this.steps[key].Skipped = false;
    this._confirmedSteps.push(key);

    this._skippedSteps = this._skippedSteps.filter((step: any) => {
      return step !== key;
    });

    sessionStorage.setItem('kpConfirmedSteps', JSON.stringify(this._confirmedSteps));
    sessionStorage.setItem('kpSkippedSteps', JSON.stringify(this._skippedSteps));
    sessionStorage.setItem('kpSteps', JSON.stringify(this.steps));

    this.skipPopUpData = {
      Show: false
    };

    const step = this.steps[key];
    this._setCurrentStep(step.Key);
    this._router.navigateByUrl(`/${this._role}/${step.Url}`);
  }

  /**
   * Checks whether or not the step
   * is confiremd
   */
  private _checkStepIsConfirmed(key: string): boolean {
    if (this._confirmedSteps.length === 0) {
      const confirmedStepsFromStorage = sessionStorage.getItem('kpConfirmedSteps');
      const tmpSteps = confirmedStepsFromStorage !== null ? JSON.parse(confirmedStepsFromStorage) : null;

      if (tmpSteps !== null) {
        this._confirmedSteps = tmpSteps;
      }
    }

    return this._confirmedSteps.indexOf(key) !== -1;
  }

  /**
   * Goes to the next step in the
   * communication process.
   */
  next(key?: string): void {
    const currentStep = typeof key !== 'undefined' ? this.steps[key] : this.steps[this._currentKey];
    const nextStep = this._getNextStep(currentStep['Key']);

    if (nextStep['Optional'] && !this._checkStepIsConfirmed(nextStep['Key'])) {
      this.skipPopUpData = {
        Title: this._ls.locs['loc'][nextStep['SkpTitleProperty']],
        Text: this._ls.locs['loc'][nextStep['SkipTextProperty']],
        Button1Text: this._ls.locs['loc'][nextStep['SkipButton1Property']],
        Button2Text: this._ls.locs['loc'][nextStep['SkipButton2Property']],
        Key: nextStep['Key'],
        Show: true
      };
    } else {
      this._setCurrentStep(nextStep['Key']);
      this._router.navigateByUrl(`/${this._role}/${nextStep['Url']}`);
    }
  }

  /**
   * Shows the clear confirm popup.
   */
  showClearConfirm(callback?: Function): void {
    if (typeof callback !== 'undefined')
      this.clearPopUpData['Callback'] = callback;

    this.clearPopUpData['Show'] = true;
  }

  /**
   * Hides the clear confirm popup.
   */
  hideClearConfirm(): void {
    this.clearPopUpData['Show'] = false;
  }

  /**
   * Clears the communication process
   * data.
   */
  clear(callback?: Function | null, route?: string): void {
    this.clearPopUpData['Show'] = false;
    this._savedData = {};
    this._currentKey = '';
    this.currentStep = {};
    this._confirmedSteps = [];
    this._skippedSteps = [];
    this._validateStep = '';
    this.communicationId = 0;
    sessionStorage.removeItem('kpCurrentStep');
    sessionStorage.removeItem('kpSavedData');
    sessionStorage.removeItem('kpConfirmedSteps');
    sessionStorage.removeItem('kpSkippedSteps');
    this._loadSteps();

    if (typeof callback !== 'undefined' && callback !== null || typeof this.clearPopUpData['Callback'] !== 'undefined' && this.clearPopUpData['Callback'] !== null) {
      callback = typeof callback !== 'undefined' && callback !== null ? callback : this.clearPopUpData['Callback'];
      this.clearPopUpData['Callback'] = null;
      // @ts-ignore
      callback();
    } else {
      this.locationChangeExpected = true;

      if (typeof route !== 'undefined') {
        this._router.navigateByUrl(`/${this._role}/${route}`);
      } else {
        this._router.navigateByUrl(`/${this._role}/Home`);
      }
    }
  }

  /**
   * Shows the rest confirm popup.
   */
  showResetConfirm(callback?: Function): void {
    if (typeof callback !== 'undefined') {
      this.resetPopUpData['Callback'] = callback;
    }

    this.resetPopUpData['Show'] = true;
  }

  /**
   * Hides the reset confirm popup.
   */
  hideResetConfirm(): void {
    this.resetPopUpData['Show'] = false;
  }

  /**
   * Resets the current step of the
   * communication process.
   */
  reset(): void {
    this.resetPopUpData['Show'] = false;
    const communicationId = parseInt(this._getSavedData()['communication-id']);

    if (communicationId && communicationId > 0) {
      this.communicationId = communicationId;
      let callback = this.resetPopUpData['Callback'];

      if (typeof callback === 'undefined' || typeof callback !== 'undefined' && callback === null) {
        callback = () => {
          window.location.reload();
        };
      }

      this.resetPopUpData['Callback'] = null;
      this.load(callback);
    } else {
      const callback = this.resetPopUpData['Callback'];
      this.resetPopUpData['Callback'] = null;

      if (typeof callback !== 'undefined' && callback === null) {
        callback();
      }

      this.clear(this._goToFirstPageFunc);
    }
  }

  /**
   * Hides the skip popup.
   */
  closeSkipPopUp(): void {
    if (!this.skipPopUpData) {
      return;
    }

    this.skipPopUpData['Show'] = false;
  }

  /**
   * Goes to the previous step of the
   * communication process.
   */
  previous(): void {
    const currentStep = this.steps[this._currentKey];
    this._setCurrentStep(currentStep['PreviousKey']);
    this._router.navigateByUrl(`/${this._role}/${currentStep['PreviousUrl']}`);
  }

  /**
   * Goes to the given step.
   */
  goToStep(step: any, evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    if (this.currentStep !== null && step.Index < this.currentStep['Index']) {
      if (this.steps[this._currentKey] && this.steps[this._currentKey].Save)
        this.steps[this._currentKey].Save();

      if (step.Optional && !this._checkStepIsConfirmed(step.Key)) {
        this.skipPopUpData = {
          Title: this._ls.locs['loc'][step.SkpTitleProperty],
          Text: this._ls.locs['loc'][step.SkipTextProperty],
          Button1Text: this._ls.locs['loc'][step.SkipButton1Property],
          Button2Text: this._ls.locs['loc'][step.SkipButton2Property],
          Key: step.Key,
          Show: true
        };
      } else {
        this._setCurrentStep(step.Key);
        this._router.navigateByUrl(`/${this._role}/${step.Url}`);
      }
    }
  }

  /**
   * Goes to the step of the given
   * key.
   */
  goToStepKey(key: string): void {
    const step = this.steps[key];

    if (this.currentStep !== null && step.Index < this.currentStep['Index']) {
      if (this.steps[this._currentKey] && this.steps[this._currentKey].Save)
        this.steps[this._currentKey].Save();

      this._setCurrentStep(step.Key);
      this.goToCurrentStep();
    }
  }
}
