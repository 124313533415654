import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { InitialLoginService } from './../../../services/initial-login.service';
import { ApiService } from './../../../services/api.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';
import { MpSettingsService } from './../../../services/mp-settings.service';

/**
 * This class provides the functions and
 * data for the step "master data and conditions of
 * participation" of the first login module.
 */
@Component({
  selector: 'mp-core-first-login-major-data-conditions-of-participation',
  templateUrl: './major-data-conditions-of-participation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MajorDataConditionsOfParticipationComponent implements OnInit, OnDestroy {

  @Input() public hasMajorData: boolean = false;
  @Input() public hasConditionsOfParticipation: boolean = false;
  @Input() public firstLoginSettings: { [key: string]: any } = {};

  public tbDs: { [key: string]: any } = {
    teilnahmebedingungen: false,
    datenschutzerklaerung: false,
    newsletter:false,
    emailTracking:true,
    errorTeilnahmebedingungen: false,
    errorDatenschutzerklaerung: false
  };
  public majorData: { [key: string]: any } = {};
  public contact: { [key: string]: any } = {};
  public profile: { [key: string]: any } = {};
  public majorDataLoaded: boolean = false;
  public contactDataLoaded: boolean = false;
  public changeMajorData: boolean = false;
  public salutations: Array<any> = [];
  public salutationsLoaded: boolean = false;
  public errors: { [key: string]: any } = {};

  private _majorDataSaved: boolean = false;
  private _conditionsOfParticipationSaved: boolean = false;
  private _getLocsSubscription: Subscription | undefined;
  private _getProfileSubscription: Subscription | undefined;
  private _getContactSubscription: Subscription | undefined;
  private _getSalutationsSubscription: Subscription | undefined;
  private _validateStepSubscription: Subscription | undefined;
  private _nextStepSubscription: Subscription | undefined;
  private _saveMajorDataSubscription: Subscription | undefined;
  private _saveConditionsOfParticipationSubscription: Subscription | undefined;
  private _getNewsletterSubscription:Subscription | undefined;
  private _saveNewsletterSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _initialLoginService: InitialLoginService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the localization. Subscribes to
   * the validate step and next step observabbles
   * of the InitialLoginService. Gets the contact
   * data and the salutations.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }

      this._getProfileData();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }

          this._getProfileData();
        }
      });

      this.ls.getLocalization();
    }

    this.changeMajorData = this.firstLoginSettings['StammdatenAendern'];

    this._getContactSubscription = this._apiService.getRequest('/api/Teilnehmer/getKontakt').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.contact = data.Records[0];
        this.majorData = Object.assign(this.majorData, data.Records[0]);
        this.contactDataLoaded = true;
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });

    this._getSalutationsSubscription = this._apiService.getRequest('/api/Teilnehmer/getAnreden').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.salutations = data.Records.filter((salutation: { [key: string]: any }) => {
          return salutation['Land'] === 'DE';
        });

        this.salutationsLoaded = true;
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });

    this._getNewsletterSubscription = this._apiService.getRequest('api/Erstanmeldung/GetNewsletter').subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (data.Records[0]) {
          this.tbDs['newsletter'] = data.Records[0].AcceptNewsletter ?? false;
          this.tbDs['emailTracking'] = data.Records[0].AcceptEmailTracking ?? true;
        }
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });

    this._validateStepSubscription = this._initialLoginService.validateStep(1).subscribe((validate: boolean) => {
      if (validate) {
        this._initialLoginService.validateCalled.next(false);
        const isValid = this._validateConditionsOfParticipationAndDataPrivacy();
        this._initialLoginService.validBool = isValid;
        this._initialLoginService.valid.next(isValid);
      }
    });

    this._nextStepSubscription = this._initialLoginService.nextCalledStep(1).subscribe((nextCalled: boolean) => {
      if (nextCalled) {
        if (this._initialLoginService.validBool) {
          this._majorDataSaved = false;
          this._conditionsOfParticipationSaved = false;

          if (this.hasMajorData)
            this._saveMajorData();
          this._saveNewsletter();
          if (this.hasConditionsOfParticipation)
            this._saveConditionsOfParticipation();
        }
      }
    });

  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') this._getLocsSubscription.unsubscribe();
    if (typeof this._getProfileSubscription !== 'undefined') this._getProfileSubscription.unsubscribe();
    if (typeof this._getContactSubscription !== 'undefined') this._getContactSubscription.unsubscribe();
    if (typeof this._getSalutationsSubscription !== 'undefined') this._getSalutationsSubscription.unsubscribe();
    if (typeof this._validateStepSubscription !== 'undefined') this._validateStepSubscription.unsubscribe();
    if (typeof this._nextStepSubscription !== 'undefined') this._nextStepSubscription.unsubscribe();
    if (typeof this._saveMajorDataSubscription !== 'undefined') this._saveMajorDataSubscription.unsubscribe();
    if (typeof this._saveConditionsOfParticipationSubscription !== 'undefined') this._saveConditionsOfParticipationSubscription.unsubscribe();
    if (typeof this._getNewsletterSubscription !== 'undefined') this._getNewsletterSubscription.unsubscribe();
    if (typeof this._saveNewsletterSubscription !== 'undefined') this._saveNewsletterSubscription.unsubscribe();
  }

  /**
   * Gets the profile data from the
   * backend.
   */
  private _getProfileData(): void {
    this._getProfileSubscription = this._apiService.getRequest('/api/Teilnehmer/getProfil').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.profile = data.Records[0];

        this.profile['GeburtsdatumParams'] = {
          Headline: this.ls.locs['loc'].Geburtsdatum,
          date: moment(data.Records[0].Geburtsdatum)
        };

        this.majorData = Object.assign(this.majorData, this.profile);
        this.majorDataLoaded = true;
      } else {
        this._mpMessaging.openPanelFromResultResponse(data);
      }
    });
  }

  /**
   * Validates the conditions of
   * participation and the data
   * privacy policy.
   */
  private _validateConditionsOfParticipationAndDataPrivacy(): boolean {
    this.tbDs['errorTeilnahmebedingungen'] = this.hasConditionsOfParticipation === true && !this.tbDs['teilnahmebedingungen'];
    this.tbDs['errorDatenschutzerklaerung'] = this.hasConditionsOfParticipation === true && !this.tbDs['datenschutzerklaerung'];
    return !this.tbDs['errorTeilnahmebedingungen'] && !this.tbDs['errorDatenschutzerklaerung'];
  }


  /**
   * Saves the major data.
   */
  private _saveMajorData(): void {
    this._majorDataSaved = false;
    this.majorData['Geburtsdatum'] = this.majorData['GeburtsdatumParams'].date.toDate();

    this._saveMajorDataSubscription = this._apiService.postRequest('/api/Erstanmeldung/setStammdaten', this.majorData)
      .subscribe(
        (data: any) => {
          this._majorDataSaved = true;
          this._goToNext();
        },
        (error: any) => {
          if (error.status === 400)
            this.errors = error.ModelState;

          this._initialLoginService.nextFailure();
        });

  }

  /**
   * Saves the conditions of participation.
   */
  private _saveConditionsOfParticipation(): void {
    this._conditionsOfParticipationSaved = false;

    this._saveConditionsOfParticipationSubscription = this._apiService.getRequest('/api/Erstanmeldung/setTeilnahmebedingungenBestaetigt?herkunft=Erstanmeldung').subscribe(
        (data: any) => {
          if (data.Result === 'OK') {
            //this._initialLoginService.nextSuccess();
            this._conditionsOfParticipationSaved = true;
            this._goToNext();
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
            this._initialLoginService.nextFailure();
          }
        },
        (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
        this._initialLoginService.nextFailure();
      });
  }

  private _saveNewsletter(): void {
    var param = {
      AcceptNewsletter : this.tbDs["newsletter"],
      AcceptEmailTracking:this.tbDs["emailTracking"]
    }
    this._saveNewsletterSubscription = this._apiService.postRequest('/api/Erstanmeldung/SetNewsletter', param).subscribe(
      (data: any) => {
        if (data.Result !== 'OK') {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
      });
  }

  /**
   * Goes to the next step.
   */
  private _goToNext(): void {
    if ((this.hasMajorData === false || this._majorDataSaved === true) &&
      (this.hasConditionsOfParticipation === false || this._conditionsOfParticipationSaved === true)) {
      this._initialLoginService.nextSuccess();
    }
  }

}
