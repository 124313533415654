import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpPermissionsService } from './../../../../services/mp-permissions.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the points-euro per 
 * month table.
 */
@Component({
  selector: 'mp-core-points-euro-per-month',
  templateUrl: './points-euro-per-month.component.html'
})
export class PointsEuroPerMonthComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Statistik/PunkteEuroProMonat2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public canDownloadStatistics: boolean = false;

  private _pointsEuroPerMonthLoader: MpLoader = {
    name: 'pointsEuroPerMonthLoader',
    params: {}
  };
  private _getPermissionsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _titleService: MpTitleService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpPermissions: MpPermissionsService
  ) { }

  /**
   * Sets the title and the menu highlighting.
   * Registers the loader and gets the
   * permissions.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('PunkteEuroProMonat');
    this._mpMenuHighlight.setMenuHighlight('statistiken');

    this._mpLoaderService.registerLoader(this._pointsEuroPerMonthLoader);

    this._mpLoaderService.extendLoader('pointsEuroPerMonthLoader', {
      isReady: true
    });

    this._getPermissionsSubscription = this._mpPermissions.permissionsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.canDownloadStatistics = this._mpPermissions.permissions.CanDownloadStatistics;
      }
    });

    this._mpPermissions.getPermissions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getPermissionsSubscription !== 'undefined') {
      this._getPermissionsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('pointsEuroPerMonthLoader');
  }

}
