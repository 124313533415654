import { Directive, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, LOCALE_ID, Inject, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Subscription } from 'rxjs';

import { ChangeContext, Options } from '@angular-slider/ngx-slider';

import { ApiService } from '@core/services/api.service';
import { MpShoppingBasketService } from '@core/modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { MpDebounceService } from '@core/services/mp-debounce.service';

/**
 * This class provides the data and functions
 * for the additional payment slider.
 */
@Directive({
  selector: '[mpWkzzSbAdditionalPaymentSlider]',
  exportAs: 'mpWkzzSbAdditionalPaymentSlider'
})
export class SbAdditionalPaymentSliderDirective implements OnInit, OnChanges, OnDestroy {

  @Input() public item: { [key:string]: any } = {};
  @Input() public step: number = 0;
  @Input() public enforceStep: boolean = false;

  @Output() itemChange = new EventEmitter<{ [key: string]: any }>();

  private _maxValue: number = 0;
  private _minValue: number = 0;
  private _updateShoppingBasketDebounce: any = null;
  private _updateShoppingBasketEuroDebounce: any = null;
  private _shoppingBasketNewVal: number = 0;
  private _shoppingBasketEuroNewVal: number = 0;
  private _updateShoppingBasketSubscription: Subscription | undefined;
  private _updateShoppingBasketEuroSubscription: Subscription | undefined;

  constructor(
    private _apiService: ApiService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpDebounce: MpDebounceService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  /**
   * Sets the slider options.
   */
  ngOnInit(): void {
    this._maxValue = this.item['CanBePaidWithPoints'] ? this.item['Punkte'] : this.item['MaxEingeloestePunkte'];
    this._minValue = this.item['CanBePaidWithPoints'] ? 0 : this.item['MinEingeloestePunkte'];
    this._shoppingBasketNewVal = this.item['EingeloestePunkte'];
    this._shoppingBasketEuroNewVal = this.item['ZuzahlungEuro'];

    this.item['sliderOptions'] = Object.assign((this.item['sliderOptions'] || {}), {
      ceil: this._maxValue,
      floor: this._minValue,
      stepsArray: this._getStepArray(this.step, this.item),
      enforceStep: this.enforceStep || false
    });

    this._updateShoppingBasketEuroDebounce = this._mpDebounce.debounce(() => {
      this._updateShoppingBasketEuroSubscription = this._apiService.postRequest('/api/WkZuzahlung/UpdateWarenkorb', { Key: this.item['Key'], ZuzahlungEuro: formatNumber(this._shoppingBasketEuroNewVal, this.locale) }).subscribe((data: any) => {
        this._mpShoppingBasket.refreshShoppingBasket(true);
      });
    }, 500);

    this._updateShoppingBasketDebounce = this._mpDebounce.debounce(() => {
      this._updateShoppingBasketSubscription = this._apiService.postRequest('/api/WkZuzahlung/UpdateWarenkorb', { Key: this.item['Key'], EingeloestePunkte: this._shoppingBasketNewVal }).subscribe((data: any) => {
        this._mpShoppingBasket.refreshShoppingBasket(true);
      });
    }, 500);
  }

  /**
   * Updates the shopping basket by given
   * points.
   */
  updateShoppingBasket(newVal: number | string, oldVal: number): void {
    if (typeof newVal === 'string') {
      newVal = parseInt(newVal);
    }

    if (oldVal !== newVal) {
      this.item['ZuzahlungEuro'] = '-';

      if (newVal < this.item['MinEingeloestePunkte']) {
        newVal = this.item['MinEingeloestePunkte'];
      }

      if (newVal > this._maxValue) {
        newVal = this._maxValue;
      }

      if (this._updateShoppingBasketDebounce !== null) {
        //@ts-ignore
        this._shoppingBasketNewVal = newVal;
        this._updateShoppingBasketDebounce();
      }
    }
  }

  /**
   * Updates the shopping basket by given
   * Euro.
   */
  updateShoppingBasketEuro(newVal: number | string, oldVal?: number): void {
    oldVal = oldVal || 0;

    if ((oldVal || oldVal === 0) && oldVal !== newVal && newVal !== '-') {
      if (typeof newVal === 'string') {
        newVal = parseFloat(newVal);
      }

      if (this._updateShoppingBasketEuroDebounce !== null) {
        this._shoppingBasketEuroNewVal = newVal;
        this._updateShoppingBasketEuroDebounce();
      }
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._updateShoppingBasketSubscription !== 'undefined') {
      this._updateShoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._updateShoppingBasketEuroSubscription !== 'undefined') {
      this._updateShoppingBasketEuroSubscription.unsubscribe();
    }
  }

  /**
   * Watches for changes of some properties
   * of the item variable.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['item'] !== 'undefined') {
      const currentVal = changes['item'].currentValue;
      this.item['EingeloestePunkteText'] = currentVal['EingeloestePunkte'];
      this._shoppingBasketNewVal = currentVal['EingeloestePunkte'];

      this._maxValue = this.item['CanBePaidWithPoints'] ? this.item['Punkte'] : this.item['MaxEingeloestePunkte'];
      this._minValue = this.item['CanBePaidWithPoints'] ? 0 : this.item['MinEingeloestePunkte'];

      this.item['sliderOptions'] = Object.assign((this.item['sliderOptions'] || {}), {
        ceil: this._maxValue,
        floor: this._minValue,
        stepsArray: this._getStepArray(this.step, this.item),
        enforceStep: this.enforceStep || false
      });
    }
  }

  /**
   * Creates and returns the step
   * array for slider.
   */
  private _getStepArray(step: number, item: { [key: string]: any }): Array<any> {
    let stepArray = [];

    for (let i = this.item['MinEingeloestePunkte']; i < item['MaxEingeloestePunkte']; i += step) {
      stepArray.push({
        legend: `${i}`,
        value: i
      });
    }

    stepArray.push({
      legend: `${item['MaxEingeloestePunkte']}`,
      value: item['MaxEingeloestePunkte']
    });

    if (item['CanBePaidWithPoints'] && item['MaxEingeloestePunkte'] !== item['Punkte']) {
      stepArray.push({
        legend: `${item['Punkte']}`,
        value: item['Punkte']
      });
    }

    if (typeof stepArray.find((step: any) => { return step['value'] === item['EingeloestePunkte'] }) === 'undefined') {
      stepArray.push({
        legend: `${item['EingeloestePunkte']}`,
        value: item['EingeloestePunkte']
      });
    }

    return stepArray.sort((a: any, b: any) => {
      return a['value'] - b['value'];
    });
  }

  /**
   * Fetches th end of changes of the slider,
   * triggered by user interaction.
   */
  onSliderChangeEnd(changeContext: ChangeContext): void {
    if (changeContext.value !== this._shoppingBasketNewVal) {
      this.updateShoppingBasket(changeContext.value, -1);
    }
  }

  /**
   * Fetches the start of changes of the slider,
   * triggered by user interaction.
   */
  onSliderChangeStart(changeContext: ChangeContext): void {
    this.item['ZuzahlungEuro'] = '-';
  }

}
