import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

/**
 * This class provides the prize
 * calendar modal.
 */
@Component({
  selector: 'mp-gk-prize-calendar-modal',
  templateUrl: './prize-calendar-modal.component.html',
  styleUrls: [
    './../../../../mp.Core/app/components/modal/modal.component.scss',
    './prize-calendar-modal.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PrizeCalendarModalComponent implements OnInit {

  @Input() public show: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
