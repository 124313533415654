import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpBreadcrumbService } from '@core/services/mp-breadcrumb.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { MpCoreService } from '@core/services/mp-core.service';
import { CookieService } from '@core/services/cookie.service';

/**
 * This class provides the data and functions
 * for the conditions of participant page.
 */
@Component({
  selector: 'mp-core-conditions-of-participation',
  templateUrl: './conditions-of-participation.component.html',
})
export class ConditionsOfParticipationComponent implements OnInit, OnDestroy {

  public trackingOn: boolean = false;
  public configLoaded: boolean = false;
  public dataLoaded: boolean = false;
  public config: { [key: string]: any } = {};
  public data: Array<any> = [];

  private _getConditionsOfParticipantSubscription: Subscription | undefined;
  private _getConditionsOfParticipantConfigSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public apiService: ApiService,
    public mpBreadcrumbService: MpBreadcrumbService,
    public mpMenuHighlight: MpMenuHighlightService,
    public titleService: MpTitleService,
    public mpCoreService: MpCoreService,
    public route: ActivatedRoute,
    public cookieService: CookieService
  ) { }

  /**
   * Checks the disableTracking cookie, sets
   * the title, the breadcrumbs and the
   * menu highlighting, gets the content for
   * the page.
   */
  ngOnInit(): void {
    this.trackingOn = !(this.cookieService.getCookieValue('disableTracking') !== '');
    this.titleService.setTitleFromLoc('Teilnahmebedingungen');
    this.mpMenuHighlight.setMenuHighlight('support');
    this.mpBreadcrumbService.setCurrentSiteWithLoc('Teilnahmebedingungen', 'loc', 'Teilnahmebedingungen');

    this._getConditionsOfParticipantSubscription = this.apiService.getRequest('/api/Texte/getTeilnahmebedingungen').subscribe((data: any) => {
      this.data = data.Records;
      this.dataLoaded = true;
    });

    this._getConditionsOfParticipantConfigSubscription = this.apiService.getRequest('/api/TermsAndConditions/GetTermsAndConditionsConfiguration').subscribe((data: any) => {
      this.config = data.Records[0];

      if (this.config['HasExternalLink'] && !this.config['ExternalLinkIsDocument']) {
        location.href = this.config['ExternalLink'];
      }

      this.configLoaded = true;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getConditionsOfParticipantSubscription !== 'undefined') {
      this._getConditionsOfParticipantSubscription.unsubscribe();
    }

    if (typeof this._getConditionsOfParticipantConfigSubscription !== 'undefined') {
      this._getConditionsOfParticipantConfigSubscription.unsubscribe();
    }
  }

  /**
   * Handles the change of the cookies
   * checkbox.
   */
  setCookie(): void {
    if (this.trackingOn) {
      this.cookieService.deleteCookie('disableTracking');
    } else {
      this.cookieService.setCookie('disableTracking=1', { secure: true });
    }
  }

  /**
   * Scrolls to the hash, if there is
   * one set.
   */
  scrollToHash(): void {
    setTimeout(() => {
      if (this.route.snapshot.fragment !== null) {
        const anchor = document.getElementById(this.route.snapshot.fragment);

        if (anchor !== null) {
          anchor.scrollIntoView();
        }
      }
    }, 1000);
  }

}
