import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, AfterViewInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { SwipeEvent } from 'ng-swipe';

import { ApiService } from './../../../../services/api.service';
import { MpStageIdService } from './../../../../services/mp-stage-id.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpAnalyticsService } from './../../../../services/mp-analytics.service';

/**
 * This class provides the functionalities
 * for the home page of the participant
 * module.
 */
@Component({
  selector: 'mp-core-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public homeStages: Array<any> = [];

  public owlProperties: any = {};
  public imgWidth: string = '';
  public imgHeight: string = '';
  public homeStagesSubscription: Subscription | undefined;

  public trustedHtml: any;

  @ViewChild('owlElem', { static: false })
    owlCarousel!: ElementRef;

  constructor(
    public apiService: ApiService,
    public mpStageIdService: MpStageIdService,
    public mpMenuHighlight: MpMenuHighlightService,
    public sanitizer: DomSanitizer,
    public titleService: MpTitleService,
    public mpSettings: MpSettingsService,
    public renderer: Renderer2,
    public mpAnalytics: MpAnalyticsService
  ) { }

  /**
   * Gets the home stages, sets
   * the title and the menu highlight.
   */
  ngOnInit(): void {
    this.homeStagesSubscription = this.apiService.getRequest('/api/Home/getHomeBuehne').subscribe((data: any) => {
      this.homeStages = data.Records;
      this.imgHeight = this.mpSettings.settings['DisplaySettings'].BuehnenBildHoehe;
      this.imgWidth = this.mpSettings.settings['DisplaySettings'].BuehnenBildBreite;
      if (this.homeStages.length > 0) {
        this.mpStageIdService.setIds(this.homeStages.map((stage: any) => {
          return stage.Id;
        }));
      }
    });

    this.mpMenuHighlight.setMenuHighlight('home');

    this.titleService.setTitleFromLoc('Home');
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this.homeStagesSubscription !== 'undefined') {
      this.homeStagesSubscription.unsubscribe();
    }
  }

  /**
   * Swipes the stage left.
   */
  swipeLeft(event?: SwipeEvent) {
    this.renderer.setProperty(this.owlCarousel.nativeElement, 'owl.carousel', "next()");
  }

  /**
   * Swipes the stage right.
   */
  swipeRight(event?: SwipeEvent) {
    this.renderer.setProperty(this.owlCarousel.nativeElement, 'owl.carousel', "prev()");
  }

  /**
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

  /**
   * Tracks the click on the stage.
   */
  trackButton(buehnenId: any, buehnenTitel: any, buttonName: any) {
    this.mpAnalytics.trackEvent('Buehnen-Klick', buehnenId + ' - ' + buehnenTitel, buttonName);
  };

}
