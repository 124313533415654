import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpEnumsService } from '@core/services/mp-enums.service';
import { MpGuidService } from '@core/services/mp-guid.service';

/**
 * This class provides the functionalities
 * for the search of the travel configurator.
 */
@Injectable({
  providedIn: 'root'
})
export class TcSearchParamsService implements OnDestroy {

  private _travelType: { [ key: string]: any } = {};
  private _params: { [ key: string]: any } = {};
  private _routeParams: { [key: string]: any } = {};
  private _getEnumsSubscription: Subscription | undefined;
  private _queryParamsSubscription: Subscription | undefined;

  /**
   * Gets the enumarations and the query
   * params.
   */
  constructor(
    private _mpEnums: MpEnumsService,
    private _mpGuid: MpGuidService,
    private _route: ActivatedRoute
  ) {
    if (Object.keys(this._mpEnums.enums).length === 0) {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._travelType = this._mpEnums.enums['Reisetyp'];

          if (typeof this._getEnumsSubscription !== 'undefined') {
            this._getEnumsSubscription.unsubscribe();
          }
        }
      });

      this._mpEnums.getEnums();
    } else {
      this._travelType = this._mpEnums.enums['Reisetyp'];
    }

    this._queryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._routeParams = params;
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Sets the given parameters in the service
   * and the session storage.
   */
  setParams(parameters: { [ key: string]: any }): void {
    this._params = parameters;
    sessionStorage['rkParameters'] = JSON.stringify(parameters);
    sessionStorage['rkParametersLastSet'] = JSON.stringify(moment());
  }

  /**
   * Returns the set search parameters.
   */
  getParams(): { [ key: string]: any } {
    if (!sessionStorage['rkParametersLastSet']) {
      return {};
    }

    let lastSet = JSON.parse(sessionStorage['rkParametersLastSet']);
    lastSet = moment(lastSet);

    const now = moment();

    if (now.year() == lastSet.year() && now.dayOfYear() == lastSet.dayOfYear()) {
      if (Object.keys(this._params).length === 0) {
        this._params = JSON.parse(sessionStorage['rkParameters']);
      }
    }

    return this._params;
  }

  /**
   * Sets the parameters for a search.
   */
  setParamsForSearch(params: { [key: string]: any }, travelType?: number, searchId?: string, travelTypeEnums?: { [key: string]: any }): string {
    const parameters: { [key: string]: any } = {};

    if (Object.keys(this._travelType).length === 0 && typeof travelTypeEnums !== 'undefined') {
      this._travelType = travelTypeEnums;
    }

    if (travelType && (travelType == this._travelType['NurFlug'])) {
      parameters['StartDatum'] =
        params['dateRangeFlug'] && (params['dateRangeFlug'].rangeStart || params['dateRangeFlug'].minDate) || moment().add(4, 'days');
      params['dateRangeFlug'] && (params['dateRangeFlug'].rangeStart || params['dateRangeFlug'].minDate) || new Date();
      parameters['EndDatum'] = (params['dateRangeFlug'] && params['dateRangeFlug'].rangeEnd) || moment().add(4, 'days').add(2, 'weeks');
      parameters['VarianzHin'] =
        (params['dateRangeFlug'] && params['dateRangeFlug'].VarianzHin) || (params['reisedauerOptionsHin'] && params['reisedauerOptionsHin'][0]);
      parameters['VarianzRueck'] =
        (params['dateRangeFlug'] && params['dateRangeFlug'].VarianzRueck) || (params['reisedauerOptionsZurueck'] && params['reisedauerOptionsZurueck'][0]);
      parameters['Flugart'] = (params['dateRangeFlug'] && params['dateRangeFlug'].Flugart);
      parameters['DatumSet'] = !!(params['dateRangeFlug'] && params['dateRangeFlug'].rangeStart && params['dateRangeFlug'].rangeEnd);
      parameters['AnzahlErwachsene'] = (params['passagiereFlug'] && params['passagiereFlug'].AnzahlErwachsene) || 2;
      parameters['Kinder'] = (params['passagiereFlug'] && params['passagiereFlug'].Kinder) || [];
      parameters['PassagiereSet'] = !!(params['passagiereFlug'] && params['passagiereFlug'].AnzahlErwachsene && params['passagiereFlug'].Kinder);
      parameters['Abflughaefen'] = (params['flugAbflughafen'] && params['flugAbflughafen'].flughaefen) || [];

      parameters['AbflughaefenSet'] = !!(params['flugAbflughafen'] && params['flugAbflughafen'].flughaefen &&
        params['flugAbflughafen'].flughaefen.length);

      parameters['Zielflughaefen'] = (params['flugZielflughafen'] && params['flugZielflughafen'].flughaefen) || [];

      parameters['ZielflughaefenSet'] = !!(params['flugZielflughafen'] && params['flugZielflughafen'].flughaefen &&
        params['flugZielflughafen'].flughaefen.length);
    } else {
      let defaultTimeRangeDays = 14;

      parameters['StartDatum'] =
        params['dateRangeHotel'] && (params['dateRangeHotel'].rangeStart || params['dateRangeHotel'].minDate) || moment().add(4, 'days');
      parameters['EndDatum'] = (params['dateRangeHotel'] && params['dateRangeHotel'].rangeEnd) || moment().add(4, 'days').add(defaultTimeRangeDays, 'days');
      params['dateRangeFlug'] && (params['dateRangeFlug'].rangeStart || params['dateRangeFlug'].minDate) || new Date();
      parameters['Reisedauer'] =
        (params['dateRangeHotel'] && params['dateRangeHotel'].Reisedauer) || (params['reisedauerOptions'] && params['reisedauerOptions'][1]);
      parameters['MinTage'] = (params['dateRangeHotel'] && params['dateRangeHotel'].MinTage) || defaultTimeRangeDays;
      parameters['MaxTage'] = (params['dateRangeHotel'] && params['dateRangeHotel'].MaxTage) || defaultTimeRangeDays;
      parameters['DatumSet'] = !!(params['dateRangeHotel'] && params['dateRangeHotel'].rangeStart && params['dateRangeHotel'].rangeEnd);
      parameters['AnzahlErwachsene'] = (params['gaesteHotel'] && params['gaesteHotel'].AnzahlErwachsene) || 2;
      parameters['Kinder'] = (params['gaesteHotel'] && params['gaesteHotel'].Kinder) || [];
      parameters['GaesteSet'] = !!(params['gaesteHotel'] && params['gaesteHotel'].AnzahlErwachsene && params['gaesteHotel'].Kinder);
      parameters['Ziele'] = (params['reisezieleHotel'] && params['reisezieleHotel'].ziele) || [];

      parameters['ZieleSet'] =
        !!(params['reisezieleHotel'] && params['reisezieleHotel'].ziele && params['reisezieleHotel'].ziele.length);
    }

    if (travelType == this._travelType['HotelUndFlug']) {
      parameters['Abflughaefen'] = (params['flugHotelUndFlug'] && params['flugHotelUndFlug'].flughaefen) || [];

      parameters['AbflughaefenSet'] = !!(params['flugHotelUndFlug'] && params['flugHotelUndFlug'].flughaefen &&
        params['flugHotelUndFlug'].flughaefen.length);
    }

    parameters['Reiseart'] = travelType;

    parameters['SearchId'] = searchId || this._mpGuid.newGuid();
    parameters['Aktionscode'] = params['Aktionscode'];
    parameters['Startindex'] = params['Startindex'] || 0;
    parameters['Pagesize'] = params['Pagesize'] || null;

    this.setParams(parameters);

    return parameters['SearchId'];
  }

  /**
   * Transforms the params for the
   * search.
   */
  transformParamsForSearch(searchParams: { [key: string]: any }, travelType?: number, travelTypeEnums?: { [key: string]: any }): { [key: string]: any } {
    let range, oneway, airportOfDepature;

    if (Object.keys(this._travelType).length === 0 && typeof travelTypeEnums !== 'undefined') {
      this._travelType = travelTypeEnums;
    }

    if (travelType == this._travelType['NurFlug']) {
      range = {
        RangeStart: searchParams['dateRangeFlug'].rangeStart,
        RangeEnd: searchParams['dateRangeFlug'].rangeEnd,
        VarianzHin: searchParams['dateRangeFlug'].VarianzHin,
        VarianzRueck: searchParams['dateRangeFlug'].VarianzRueck
      };

      oneway = searchParams['dateRangeFlug'].Flugart && !!searchParams['dateRangeFlug'].Flugart.id;

      airportOfDepature = searchParams['flugAbflughafen'].flughaefen
        ? searchParams['flugAbflughafen'].flughaefen.map((airport: any) => { return airport['Kuerzel'] })
        : [];
    } else {
      range = {
        RangeStart: searchParams['dateRangeHotel'].rangeStart,
        RangeEnd: searchParams['dateRangeHotel'].rangeEnd,
        MinTage: searchParams['dateRangeHotel'].MinTage,
        MaxTage: searchParams['dateRangeHotel'].MaxTage
      };

      var totalDays = Math.ceil(moment(range.RangeEnd).diff(moment(range.RangeStart), "days"));

      switch (searchParams['dateRangeHotel'].Reisedauer?.id)
      {
        case 1:
          range.MinTage = 1;
          range.MaxTage = totalDays;
          break;
        case 2:
          range.MinTage = totalDays;
          range.MaxTage = totalDays;
          break;
      }

      if (travelType == this._travelType['HotelUndFlug']) {
        airportOfDepature = searchParams['flugHotelUndFlug'].flughaefen
          ? searchParams['flugHotelUndFlug'].flughaefen.map((airport: any) => { return airport['Kuerzel'] })
          : [];
      } else {
        airportOfDepature = [];
      }
    }

    const result = {
      DateRange: range,
      Gaeste: {
        AnzahlErwachsene: searchParams['gaesteHotel'].AnzahlErwachsene || 2,
        Kinder: searchParams['gaesteHotel'].Kinder ? searchParams['gaesteHotel'].Kinder.map((child: any) => { return child['Value'] }) : []
      },
      Reiseziele: searchParams['reisezieleHotel'].ziele,
      Flughaefen: airportOfDepature,
      Zielflughaefen: travelType == this._travelType['NurFlug'] && searchParams['flugZielflughafen'].flughaefen
        ? searchParams['flugZielflughafen'].flughaefen.map((airport: any) => { return airport['Kuerzel'] })
        : [],
      OneWay: oneway,
      Reisetyp: travelType,
      SearchId: this._routeParams['sid'] || this._params['SearchId'],
      Aktionscode: searchParams['Aktionscode'],
      Startindex: searchParams['Startindex'] || 0,
      Pagesize: searchParams['Pagesize'] || null
    }

    return result;
  }

  /**
   * Gets and returns the params for the
   * search area.
   */
  getParamsForSearchArea(params: { [key: string]: any }, travelType?: number, travelTypeEnums?: { [key: string]: any }): { [key: string]: any } {
    const parameters = this.getParams() || {};
    params = params || {};

    if (Object.keys(this._travelType).length === 0 && typeof travelTypeEnums !== 'undefined') {
      this._travelType = travelTypeEnums;
    }

    if (travelType == this._travelType['NurFlug']) {
      params['dateRangeFlug'] = Object.assign(params['dateRangeFlug'] || {}, {
        rangeStart: parameters['StartDatum'] && moment(parameters['StartDatum']) || undefined,
        rangeEnd: parameters['EndDatum'] && moment(parameters['EndDatum']) || undefined,
        VarianzHin: parameters['VarianzHin'] || params['dateRangeFlug'] && params['dateRangeFlug'].reisedauerOptionsHin && params['dateRangeFlug'].reisedauerOptionsHin[0],
        VarianzRueck: parameters['VarianzRueck'] || params['dateRangeFlug'] && params['dateRangeFlug'].reisedauerOptionsZurueck && params['dateRangeFlug'].reisedauerOptionsZurueck[0],
        MinTage: parameters['MinTage'] || 7,
        MaxTage: parameters['MaxTage'] || 10,
        Flugart: parameters['Flugart'] || params['dateRangeFlug'] && params['dateRangeFlug'].flugOptions[0]
      });

      params['dateRangeFlug'].save && params['dateRangeFlug'].save(true);

      params['flugAbflughafen'] =
        Object.assign(params['flugAbflughafen'] || {}, {
          flughaefen: parameters['Abflughaefen'] || []
        });

      params['flugAbflughafen'].save && params['flugAbflughafen'].save(true);

      params['flugZielflughafen'] =
        Object.assign(params['flugZielflughafen'] || {}, {
          flughaefen: parameters['Zielflughaefen'] || []
        });

      params['flugZielflughafen'].save && params['flugZielflughafen'].save(true);
    } else {
      params['dateRangeHotel'] = Object.assign(params['dateRangeHotel'] || {}, {
        rangeStart: parameters['StartDatum'] && moment(parameters['StartDatum']) || undefined,
        rangeEnd: parameters['EndDatum'] && moment(parameters['EndDatum']) || undefined,
        Reisedauer: parameters['Reisedauer'] || params['dateRangeHotel'] && params['dateRangeHotel'].reisedauerOptions && params['dateRangeHotel'].reisedauerOptions[1],
        MinTage: parameters['MinTage'] || 1,
        MaxTage: parameters['MaxTage'] || 1
      });

      params['dateRangeHotel'].save && params['dateRangeHotel'].save(true);

      if (travelType == this._travelType['HotelUndFlug']) {
        params['flugHotelUndFlug'] =
          Object.assign(params['flugHotelUndFlug'] || {},
            {
              flughaefen: parameters['Abflughaefen'] || []
            });

        params['flugHotelUndFlug'].save && params['flugHotelUndFlug'].save(true);
      }
    }

    params['gaesteHotel'] = Object.assign(params['gaesteHotel'] || {}, {
      AnzahlErwachsene: parameters['AnzahlErwachsene'] || 0,
      AnzahlKinder: (parameters['Kinder'] && parameters['Kinder'].length) || 0,
      Kinder: parameters['Kinder'] || []
    });

    params['gaesteHotel'].save && params['gaesteHotel'].save(true);

    params['passagiereFlug'] = Object.assign(params['passagiereFlug'] || {}, {
      AnzahlErwachsene: parameters['AnzahlErwachsene'] || 0,
      AnzahlKinder: (parameters['Kinder'] && parameters['Kinder'].length) || 0,
      Kinder: parameters['Kinder'] || []
    });

    params['passagiereFlug'].save && params['passagiereFlug'].save(true);

    params['reisezieleHotel'] =
      Object.assign(params['reisezieleHotel'] || {}, {
      ziele: parameters['Ziele'] || []
      });

    params['reisezieleHotel'].save && params['reisezieleHotel'].save(true);

    params['SearchId'] = parameters['SearchId'];
    params['Aktionscode'] = parameters['Aktionscode'];

    return params;
  }

}
