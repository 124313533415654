<div class="layout-container manager-text-editor communication-process position-relative"
     *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)"
     id="reward-editor"
     mpCoreMpContentFullHeight>
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb"
         class="display-flex flex-col-12 flex-align-items-center margin-bottom-8 padding-left-4">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info"
           (click)="goBack($event)">
          {{ ls.locs['loc'].Zurueck }}
        </a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Kommunikationsprozess }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular position-relative top-2" [innerHTML]="ls.locs['loc'].KommunikationAnlegenBreadcrumb">
        </a>
      </div>
    </div>
    <mp-core-communication-process-stepper></mp-core-communication-process-stepper>

    <div class="margin-top-16 flex-col-12 flex-direction-column"
         [hidden]="communicationProcess">
      <div class="flex-col-12 bg-color-white padding-16 flex-direction-column">
        <mp-core-input mpId="reward-title"
                       mpPlaceholder="ls.locs['loc'].Titel"
                       [(model)]="reward['Titel']"
                       [keyup]="setTitleChangeManuallyFunc"
                       [mpDisabled]="!editable"
                       [mpRequired]="true"
                       [hasError]="errors['rewardTitle'] ? true : false">
        </mp-core-input>
        <mp-core-form-error *ngIf="errors['rewardTitle']"
                            [model]="errors['rewardTitle']">
        </mp-core-form-error>
      </div>
    </div>

    <section id="mngr-text-editor-content"
             class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-16">
      <div class="width-full bg-color-white padding-left-right-16 padding-top-16 padding-bottom-40 box-sizing-border-box">
        <h3 class="display-block width-full margin-bottom-16">{{ ls.locs['loc'].WasWirdBelohnt }}</h3>
        <mp-core-select class="padding-left-8"
                        [mpDisabled]="!editable"
                        mpId="reward-type"
                        [(model)]="rewardType"
                        [elements]="rewardTypes"
                        (ngChange)="rewardTypeChanged()"
                        textProperty="Bezeichnung"
                        [mpRequired]="true"
                        mpPlaceholder="{{ ls.locs['loc'].BitteWaehlen }}"
                        [hasError]="errors['data.belohnungsArt'] ? true : false">
        </mp-core-select>
        <mp-core-form-error *ngIf="errors['belohnungsArt']"
                            [model]="errors['belohnungsArt']">
        </mp-core-form-error>
      </div>
      <div class="flex-col-12 flex-direction-column width-full padding-top-16 padding-left-right-16 padding-bottom-32 margin-top-16 bg-color-white"
           *ngIf="rewardType['NeedsBegruendung']">
        <mp-core-input mpId="reward-reason"
                       label="{{ ls.locs['loc'].Begruendung }}"
                       mpPlaceholder="{{ ls.locs['loc'].Begruendung }} {{ ls.locs['loc'].VerfassenKlein }}"
                       [mpRequired]="true"
                       [(model)]="reward['Begruendung']"
                       [hasError]="errors['rewardReason'] ? true : false">
        </mp-core-input>
        <mp-core-form-error *ngIf="errors['rewardReason']"
                            [model]="errors['rewardReason']">
        </mp-core-form-error>
      </div>
      <div class="flex-col-12 flex-direction-column width-full padding-top-16 padding-left-right-16 padding-bottom-32 margin-top-16 bg-color-white"
           *ngIf="rewardType['CanSelectZeitraum']">
        <h3 class="display-block width-full margin-bottom-16">{{ ls.locs['loc'].WannWirdBelohnt }}</h3>
        <mp-core-sidebar-input [ngClass]="{disabled: !editable}"
                               [mpDisabled]="!editable"
                               class="width-full"
                               [hasError]="errors['rewardDate'] ? true : false"
                               text="{{rewardDate['text']}}"
                               sidebarComponent="SidebarRewardPeriod"
                               [sidebarParams]="rewardDate"
                               [mpRequired]="true">
        </mp-core-sidebar-input>
        <mp-core-form-error *ngIf="errors['rewardDate']"
                            [model]="errors['rewardDate']">
        </mp-core-form-error>
      </div>
      <div class="flex-col-12 flex-direction-column width-full padding-top-16 padding-left-right-16 padding-bottom-32 margin-top-16 bg-color-white"
           *ngIf="rewardType['Key'] == 'jubilaeum'">
        <h3 class="display-block width-full margin-bottom-16">{{ ls.locs['loc'].WannWirdBelohnt }}</h3>
        <mp-core-select [mpDisabled]="!editable"
                        class="width-full"
                        [hasError]="errors['rewardDate'] ? true : false"
                        [elements]="anniversaryElements"
                        [(model)]="reward['Anstellungsdauer']"
                        label="{{ ls.locs['loc'].Betriebszugehoerigkeit }}"
                        [mpRequired]="true">
        </mp-core-select>
        <mp-core-form-error *ngIf="errors['rewardDate']"
                            [model]="errors['rewardDate']">
        </mp-core-form-error>
      </div>
      <div class="flex-col-12 width-full flex-wrap padding-top-16 padding-left-right-16 padding-bottom-32 margin-top-16 bg-color-white"
           id="reward-worth"
           [hidden]="!rewardTypeLoaded">
        <h3 class="display-block width-full margin-bottom-16">{{ ls.locs['loc'].WieWirdBelohnt }}</h3>
        <div class="flex-col-6 flex-col-12-m padding-left-0 flex-direction-column reward-worth-fields flex-align-items-start">
          <div class="display-flex flex-align-items-center">
            <span *ngIf="reward['Belohnungsart'] !== 'geburtstag'"
                  class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">
              {{ ls.locs['loc'].BelohnungInPunkten }}
            </span>
            <span *ngIf="reward['Belohnungsart'] === 'geburtstag'"
                  class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">
              {{ ls.locs['loc'].BelohnungInPunktenGeburtstag }}
            </span>
            <span class="display-inline-block margin-right-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="reduceRewardPoints()">
              </i>
            </span>
            <mp-core-input-integer [hasError]="errors['rewardPunkte'] ? true : false"
                                   mpClass="text-align-right"
                                   [mpDisabled]="!editable || !rewardType['CanChangeBelohnungshoehe']"
                                   [onlyInt]="true"
                                   mpId="reward-woth-points"
                                   [keyup]="setPointsKeyUpFunc"
                                   [model]="rewardHeight['Punkte']"
                                   (modelChange)="rewardHeight['Punkte'] = $event; rewardHeightPointsChange()"
                                   [mpRequired]="true"
                                   mpPlaceholder="{{ mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorPunkteStep }}">
            </mp-core-input-integer>
            <span class="display-inline-block margin-left-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable || !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="raiseRewardPoints()">
              </i>
            </span>
          </div>
          <mp-core-form-error *ngIf="errors['rewardPunkte']"
                              [model]="errors['rewardPunkte']">
          </mp-core-form-error>
        </div>
        <div class="flex-col-6 flex-col-12-m padding-right-0 flex-direction-column reward-worth-fields reward-worth-fields-euro flex-align-items-start">
          <div class="display-flex flex-align-items-center">
            <span *ngIf="rewardType['Belohnungsart'] !== 'geburtstag'"
                  class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-80 reward-worth-label">
              {{ ls.locs['loc'].BelohnungInEuro }}
            </span>
            <span *ngIf="rewardType['Belohnungsart'] === 'geburtstag'"
                  class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">
              {{ ls.locs['loc'].BelohnungInEuroGeburtstag }}
            </span>
            <span class="display-inline-block margin-right-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="reduceRewardEuro()">
              </i>
            </span>
            <mp-core-input-decimal mpClass="text-align-right"
                                   [mpDisabled]="!editable || !rewardType['CanChangeBelohnungshoehe']"
                                   [onlyInt]="true"
                                   mpId="reward-woth-euro"
                                   (blur)="setEuro(rewardHeight['Euro'], rewardHeight)"
                                   [(model)]="rewardHeight['Euro']"
                                   [mpRequired]="true"
                                   mpPlaceholder="1">
            </mp-core-input-decimal>
            <span class="display-inline-block margin-left-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="raiseRewardEuro()">
              </i>
            </span>
          </div>
        </div>
        <div *ngIf="rewardType['Key'] == 'geburtstag'"
             class="flex-col-6 flex-col-12-m padding-left-0 flex-direction-column reward-worth-fields flex-align-items-start margin-top-16">
          <div class="display-flex flex-align-items-center">
            <span class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">
              {{ ls.locs['loc'].BelohnungInPunktenHalbrund }}
            </span>
            <span class="display-inline-block margin-right-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="reduceRewardHalfRoundedPoints()">
              </i>
            </span>
            <mp-core-input-integer mpClass="text-align-right"
                                   [mpDisabled]="!editable|| !rewardType['CanChangeBelohnungshoehe']"
                                   [onlyInt]="true"
                                   mpId="reward-woth-points"
                                   (change)="setPoints(rewardHalfRoundedHeight['Punkte'], rewardHalfRoundedHeight, 'PunkteHalbrund')"
                                   [model]="rewardHalfRoundedHeight['Punkte']"
                                   (modelChange)="rewardHalfRoundedHeight['Punkte'] = $event; rewardHeightHalfPointsChange()"
                                   [mpRequired]="true"
                                   mpPlaceholder="{{ mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorPunkteStep }}">
            </mp-core-input-integer>
            <span class="display-inline-block margin-left-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="raiseRewardHalfRoundedPoints()">
              </i>
            </span>
          </div>
        </div>
        <div *ngIf="rewardType['Key'] == 'geburtstag'"
             class="flex-col-6 flex-col-12-m padding-right-0 flex-direction-column reward-worth-fields reward-worth-fields-euro flex-align-items-start margin-top-16">
          <div class="display-flex flex-align-items-center">
            <span class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">{{ ls.locs['loc'].BelohnungInEuroHalbrund }}</span>
            <span class="display-inline-block margin-right-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="reduceRewardHalfRoundedEuro()">
              </i>
            </span>
            <mp-core-input-decimal mpClass="text-align-right"
                                   [mpDisabled]="!editable || !rewardType['CanChangeBelohnungshoehe']"
                                   [onlyInt]="true"
                                   mpId="reward-woth-euro"
                                   (blur)="setEuro(rewardHalfRoundedHeight['Euro'], rewardHalfRoundedHeight, 'PunkteHalbrund')"
                                   [(model)]="rewardHalfRoundedHeight['Euro']"
                                   [mpRequired]="true"
                                   mpPlaceholder="{{ mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorEuroStep }}">
            </mp-core-input-decimal>
            <span class="display-inline-block margin-left-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="raiseRewardHalfRoundedEuro()">
              </i>
            </span>
          </div>
        </div>
        <div *ngIf="rewardType['Key'] == 'geburtstag'"
             class="flex-col-6 flex-col-12-m padding-left-0 flex-direction-column reward-worth-fields flex-align-items-start margin-top-16">
          <div class="display-flex flex-align-items-center">
            <span class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">{{ ls.locs['loc'].BelohnungInPunktenRund }}</span>
            <span class="display-inline-block margin-right-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="reduceRewardRoundedPoints()">
              </i>
            </span>
            <mp-core-input-integer mpClass="text-align-right"
                                   [mpDisabled]="!editable || !rewardType['CanChangeBelohnungshoehe']"
                                   [onlyInt]="true"
                                   mpId="reward-woth-points"
                                   (change)="setPoints(rewardRoundedHeight['Punkte'], rewardRoundedHeight, 'PunkteRund')"
                                   [model]="rewardRoundedHeight['Punkte']"
                                   (modelChange)="rewardRoundedHeight['Punkte'] = $event; rewardHeightRoundedPointsChange()"
                                   [mpRequired]="true"
                                   mpPlaceholder="{{ mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorPunkteStep }}">
            </mp-core-input-integer>
            <span class="display-inline-block margin-left-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="raiseRewardRoundedPoints()">
              </i>
            </span>
          </div>
        </div>
        <div *ngIf="rewardType['Key'] == 'geburtstag'"
             class="flex-col-6 flex-col-12-m padding-right-0 flex-direction-column reward-worth-fields reward-worth-fields-euro flex-align-items-start margin-top-16">
          <div class="display-flex flex-align-items-center">
            <span class="font-size-xxs font-weight-700 width-full display-inline-flex flex-align-items-center margin-right-40 reward-worth-label">{{ ls.locs['loc'].BelohnungInEuroRund }}</span>
            <span class="display-inline-block margin-right-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __remove-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="reduceRewardRoundedEuro()">
              </i>
            </span>
            <mp-core-input-decimal mpClass="text-align-right"
                                   [mpDisabled]="!editable|| !rewardType['CanChangeBelohnungshoehe']"
                                   [onlyInt]="true"
                                   mpId="reward-woth-euro"
                                   (blur)="setEuro(rewardRoundedHeight['Euro'], rewardRoundedHeight, 'PunkteRund')"
                                   [(model)]="rewardRoundedHeight['Euro']"
                                   [mpRequired]="true"
                                   mpPlaceholder="{{ mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorEuroStep }}">
            </mp-core-input-decimal>
            <span class="display-inline-block margin-left-8">
              <i class="svg-icon __clr-specific-2 __size-40 __top-0 __add-circle __stroke-clr-default-dark"
                 [ngClass]="{disabled: !editable|| !rewardType['CanChangeBelohnungshoehe']}"
                 [hidden]="!rewardType['CanChangeBelohnungshoehe']"
                 (click)="raiseRewardRoundedEuro()">
              </i>
            </span>
          </div>
        </div>
      </div>
    </section>

    <section id="reward-editor-datapanel"
             class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-16">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full">
        <mp-core-select [hidden]="!mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages"
                        [mpDisabled]="!editable"
                        class="width-full"
                        mpId="reward-type"
                        [(model)]="reward['Sprache']"
                        [elements]="languages"
                        textProperty="Name"
                        valueProperty="Kuerzel"
                        [mpRequired]="true"
                        label="{{ ls.locs['loc'].Sprache }}">
        </mp-core-select>
        <div class="divider margin-top-bottom-16" [hidden]="!mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages"></div>

        <div *ngIf="communicationProcess" class="width-full float-right margin-top-40">
          <button class="mp-button ng-scope float-right"
                  (click)="cpShowResetConfirm(loadStep)">
            <i class="svg-icon __size-24 __clr-default __top-0 __refresh"
               id="mngr-text-editor-refresh-icon">
            </i>
          </button>
        </div>
        <button class="mp-button _sec margin-top-16 width-full"
                (click)="saveCommunicationProcess()">
          {{ ls.locs['loc'].Speichern }}
        </button>
        <div *ngIf="communicationProcess" class="width-full float-right">
          <a class="mp-button _prim margin-top-16 width-full"
             (click)="goToNextStep($event)"
             *ngIf="cp !== null && cp.currentStep !== null"
             [ngClass]="{disabled: !validateStep()}">
            {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
          </a>
        </div>
      </div>
    </section>

    <div *ngIf="communicationProcess" class="flex-grid width-full-important hide-on-mobile position-absolute bottom-48 left-0">
      <div class="flex-col-9 flex-col-12-l flex-col-12-m padding-left-right-16 display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           (click)="cpShowClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
        <div>
          <a class="mp-button margin-right-16"
             *ngIf="cp !== null && cp.currentStep !== null"
             (click)="goToPreviousStep($event)">
            {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
          </a>
          <a class="mp-button _prim float-right"
             *ngIf="cp !== null && cp.currentStep !== null"
             (click)="goToNextStep($event)"
             [ngClass]="{disabled: !validateStep()}">
            {{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}
          </a>
        </div>
      </div>
    </div>
    <div stickyBottom *ngIf="communicationProcess"
         class="bestellung-nav-row floating-container show-on-mobile bg-color-white padding-top-bottom-0 width-full text-align-center wk-bottom-buttons">
      <a class="mp-button _prim padding-top-bottom-8 width-full display-flex-important flex-justify-cont-center flex-align-items-center flex-direction-column"
         (click)="goToNextStep($event)"
         [ngClass]="{disabled: !validateStep()}">
        <i class="svg-icon __clr-navbar __size-24 __top--2 __navigate-next float-none-important margin-bottom-4"></i>
        <span *ngIf="cp !== null && cp.currentStep !== null" class="display-block">{{ls.locs['loc'][cp.currentStep['NextButtonProperty']]}}</span>
      </a>
      <div class="width-full padding-left-right-8 box-sizing-border-box display-flex flex-direction-row flex-justify-cont-between">
        <a class="mp-button"
           *ngIf="cp !== null && cp.currentStep !== null"
           (click)="goToPreviousStep($event)">
          {{ls.locs['loc'][cp.currentStep['PreviousButtonProperty']]}}
        </a>
        <a class="mp-button"
           (click)="cpShowClearConfirm($event)">
          {{ ls.locs['loc'].BenachrichtigungAbbrechen }}
        </a>
      </div>
    </div>
  </div>
</div>
