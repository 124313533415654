import { Component, OnInit, OnDestroy, ViewEncapsulation, Injector, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpOrderProcessService } from '@core/components/order-process/mp-order-process.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpShoppingBasketService } from '@core/modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpMenuRefreshService } from '@core/components/menu/mp-menu-refresh.service';
import { MpWishlistService } from '@core/modules/participant/pages/wishlist/mp-wishlist.service';
import { SbAdditionalPaymentService } from './../../../../services/sb-additional-payment.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

import { Provider } from './../../../../services/interfaces/provider';
import { ShoppingBasketItem } from '@core/modules/participant/pages/shopping-basket/shopping-basket-item';
import { Tile } from '@core/components/card/shop-card/tile';

/**
 * This class provides the data and functions
 * for the payment page of the order process.
 */
@Component({
  selector: 'mp-wkzz-payment',
  templateUrl: './payment.component.html',
  styleUrls: [
    './../../../../../../mp.Core/app/components/order-process/styles/order-process-additional-pay.scss',
    './../../../../../../mp.Zuzahlung/app/zz.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit, OnDestroy {

  public currentStep: { [key: string]: any } = {};
  public errors: Array<any> = [];
  public injector: Injector | any;
  public provider: Array<Provider> = [];
  public groupedShoppingBasket: Array<any> = [];
  public sBSum: number = 0;
  public pointWorth: number = 0;
  public canAdditionalPayment: boolean = false;
  public hasEnoughPoints: boolean = false;
  public canOrder: boolean = false;
  public sBItems: Array<ShoppingBasketItem> = [];
  public wlLoaded: boolean = false;
  public addToWlKey: string = '';
  public showDeleteDialog: boolean = false;
  public deleteItemFunc = this.deleteItem.bind(this);
  public hideDeleteDialog = this._hideDeleteDialog.bind(this);
  public goToNextIfCanOrderFunc = this.goToNextIfCanOrder.bind(this);
  public role: string = '';

  private _lodingProcesses: Array<boolean> = [true, true, true, true, true, true];
  private _lodingProcessesChanged: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>([]);
  private _lodingProcessesObserverable: Observable<Array<boolean>> = this._lodingProcessesChanged.asObservable();
  private _wl: Array<Tile> | undefined;
  private _selectedProvider: Provider | undefined;
  private _shoppingBasketSubscription: Subscription | undefined;
  private _stepsSubscription: Subscription | undefined;
  private _currentSubscription: Subscription | undefined;
  private _providerSubscription: Subscription | undefined;
  private _routeParamsSubscription: Subscription | undefined;
  private _menuRefreshSubscription: Subscription | undefined;
  private _getShoppingBasketStateSubscription: Subscription | undefined;
  private _wishlistSubscription: Subscription | undefined;
  private _loadingBarProcessesSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public mpOrderProcess: MpOrderProcessService,
    private _mpShoppingBasket: MpShoppingBasketService,
    public sbAdditionalPaymentService: SbAdditionalPaymentService,
    private _injector: Injector,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpMenuRefreshService: MpMenuRefreshService,
    private _mpSidebar: MpSidebarService,
    private _mpWishlist: MpWishlistService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets some subscribtions to get data
   * of the process step, the shopping basket,
   * the providers, the points worth, etc..
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._stepsSubscription = this.mpOrderProcess.steps.subscribe((data: any) => {
      if (!this.mpOrderProcess.isValidStep('zuzahlung')) {
        this.mpOrderProcess.goToCurrentStep();
      }

      if (this._lodingProcesses.indexOf(true) !== -1) {
        this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
        this._lodingProcessesChanged.next(this._lodingProcesses);
      }
    });

    this._currentSubscription = this.mpOrderProcess.currentStepObserve.subscribe((currentStep: any) => {
      this.currentStep = currentStep;

      if (this._lodingProcesses.indexOf(true) !== -1) {
        this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
        this._lodingProcessesChanged.next(this._lodingProcesses);
      }
    });

    this._shoppingBasketSubscription = this._mpShoppingBasket.shoppingBasketObserver.subscribe((sBItems: Array<ShoppingBasketItem>) => {
      this.groupedShoppingBasket = [];
      const groupedBasketKeys = Object.keys(this._mpShoppingBasket.groupedBasket);

      if (groupedBasketKeys.length > 0) {
        groupedBasketKeys.forEach((key: string) => {
          this.groupedShoppingBasket.push(this._mpShoppingBasket.groupedBasket[key]);
        });
      }

      if (sBItems['length'] > 0) {
        this.sBItems = sBItems;

        this.sBSum = sBItems.map((sBItem: ShoppingBasketItem) => {
          return sBItem.Punkte;
        }).reduce((a: number, b: number) => {
          return a + b;
        });
      } else {
        this.sBItems = [];
        this.sBSum = 0;
        this.mpOrderProcess.goToFirstPage();
      }

      this._reloadAdditionalPayment();
      this._checkMinAdditionalPayment();

      if (this._lodingProcesses.indexOf(true) !== -1) {
        this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
        this._lodingProcessesChanged.next(this._lodingProcesses);
      }
    });

    this._providerSubscription = this.sbAdditionalPaymentService.providerChange.subscribe((changed: boolean) => {
      if (changed) {
        this.provider = this.sbAdditionalPaymentService.getProvider();
        this.setType(this.mpOrderProcess.paymentType !== '' ? this.mpOrderProcess.paymentType : this.provider.length > 0 ? this.provider[0].Key : '');
        this._checkMinAdditionalPayment();
      }

      if (this._lodingProcesses.indexOf(true) !== -1) {
        this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
        this._lodingProcessesChanged.next(this._lodingProcesses);
      }
    });

    this.sbAdditionalPaymentService.refreshProvider();

    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['Message'] !== 'undefined') {
        this._mpMessaging.openWarningPanel(params['Message']);
      }

      if (this._lodingProcesses.indexOf(true) !== -1) {
        this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
        this._lodingProcessesChanged.next(this._lodingProcesses);
      }
    });

    this._menuRefreshSubscription = this._mpMenuRefreshService.loadMenuData(true).subscribe((data: any) => {
      if (data !== null) {
        this.pointWorth = data.Kontostand;
      }
    });

    this._mpWishlist.getWishlist();

    if (this._mpWishlist.loaded) {
      this._wl = this._mpWishlist.wishlist;
      this.wlLoaded = true;

      if (this._lodingProcesses.indexOf(true) !== -1) {
        this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
        this._lodingProcessesChanged.next(this._lodingProcesses);
      }
    } else {
      this._wishlistSubscription = this._mpWishlist.wishlistLoaded.subscribe(() => {
        this._wl = this._mpWishlist.wishlist;
        this.wlLoaded = true;

        if (this._lodingProcesses.indexOf(true) !== -1) {
          this._lodingProcesses[this._lodingProcesses.indexOf(true)] = false;
          this._lodingProcessesChanged.next(this._lodingProcesses);
        }
      });
    }

    this._loadingBarProcessesSubscription = this._lodingProcessesObserverable.subscribe((processes: Array<boolean>) => {
      if (processes.indexOf(true) === -1) {

      }
    });
  }

  /**
   * Unsubscribes set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._shoppingBasketSubscription !== 'undefined') {
      this._shoppingBasketSubscription.unsubscribe();
    }

    if (typeof this._stepsSubscription !== 'undefined') {
      this._stepsSubscription.unsubscribe();
    }

    if (typeof this._currentSubscription !== 'undefined') {
      this._currentSubscription.unsubscribe();
    }

    if (typeof this._providerSubscription !== 'undefined') {
      this._providerSubscription.unsubscribe();
    }

    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }

    if (typeof this._menuRefreshSubscription !== 'undefined') {
      this._menuRefreshSubscription.unsubscribe();
    }

    if (typeof this._getShoppingBasketStateSubscription !== 'undefined') {
      this._getShoppingBasketStateSubscription.unsubscribe();
    }

    if (typeof this._wishlistSubscription !== 'undefined') {
      this._wishlistSubscription.unsubscribe();
    }

    if (typeof this._loadingBarProcessesSubscription !== 'undefined') {
      this._loadingBarProcessesSubscription.unsubscribe();
    }
  }

  /**
   * Creates the injector for the
   * dynamically loaded (sub-)components.
   */
  private _createInjector() {
    this.injector = Injector.create({
      providers: [
        { provide: 'sbAdditionalPaymentProvider', useValue: this._selectedProvider },
        { provide: 'sbAdditionalPaymentShoppingCart', useValue: this._mpShoppingBasket.shoppingBasket }
      ],
      parent: this._injector
    });
  }

  /**
   * Stes and returns the injector.
   */
  getInjector(provider: Provider): Injector {
    this._selectedProvider = provider;
    this._createInjector();
    return this.injector;
  }

  /**
   * Stes the additional payment provider.
   */
  setType(key: string): void {
    this.mpOrderProcess.paymentType = key;

    if ('sessionStorage' in window) {
      sessionStorage['bestellProzessZuzahlungType'] = key;
    }
  }

  /**
   * Checks the minimum worth for the
   * additional payment.
   */
  private _checkMinAdditionalPayment(): void {
    const selectedProvider: any = this.provider.find((prov: any) => {
      return prov['Key'] === this.mpOrderProcess.paymentType;
    });

    const additionalPaymentItems = this.sBItems.filter((item: any) => {
      return item['ZuzahlungEuro'];
    });

    if (!(additionalPaymentItems && additionalPaymentItems.length > 0)) {
      return;
    }

    const additionalPaymentItemsPlucked = additionalPaymentItems.map((item: any) => {
      return item['ZuzahlungEuro'];
    });

    const min = (nums: Array<any>) => {
      if (nums.length) {
        return Math.min(...nums);
      } else {
        return 0;
      }
    }

    const additionalPaymentEuroMinimum = min(additionalPaymentItemsPlucked);

    const additionalPaymentEuroAmount = additionalPaymentItemsPlucked.reduce((a: any, b: any) => {
      return a + b;
    });

    this.provider.forEach((prov: any) => {
      prov['possible'] = prov['MinPaymentAmount'] <= additionalPaymentEuroMinimum && (prov['MaxPaymentAmount'] < 0 || additionalPaymentEuroAmount <= prov['MaxPaymentAmount']);
    });

    if (selectedProvider && !selectedProvider['possible']) {
      const possibleProvider = this.provider.find((prov: any) => { return prov['possible'] === true });
      this.mpOrderProcess.paymentType = typeof possibleProvider !== 'undefined' ? possibleProvider['Key'] : '';
    }
  }

  /**
   * Shows the info sidebar.
   */
  showInfoSidebar(): void {
    this._mpSidebar.open('FaqSidebar', {
      choice: {
        ThemaID: this.mpSettings.settings['ZuzahlungSettings'].BezahlungFaqThemenId
      },
      showWithoutFilter: false,
      hideSearchbox: false,
      hideFilter: true,
      title: this.ls.locs['locZuzahlung'].InfoSidebarTitle
    });
  }

  /**
   * Reloads some additional payment data.
   */
  private _reloadAdditionalPayment(): void {
    this._getShoppingBasketStateSubscription = this._apiService.getRequest('/api/WkZuzahlung/getWarenkorbState').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.hasEnoughPoints = data.Records[0].HasEnoughPunkte;
        this.canAdditionalPayment = data.Records[0].CanZuzahlen;
        this.canOrder = data.Records[0].CanOrder;
      }
    });
  }

  /**
   * Checks whether or not the article
   * of the tile is in the wish list.
   */
  isInWishlist(artNr: string): boolean {
    if (typeof this._wl !== 'undefined') {
      const inWl = this._wl.find(wlItem => {
        // @ts-ignore
        return wlItem.ArtNr === artNr;
      });

      if (typeof inWl !== 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Gets the item from the wish list.
   */
  private _getWlItem(artNr: string): any {
    // @ts-ignore
    return this._wl.find(wlItem => {
      // @ts-ignore
      return wlItem.ArtNr === artNr;
    });
  }

  /**
   * Adds / removes the item to / from
   * the wish list.
   */
  toggleWishlist(artNr: string, pin: string, key: string): void {
    if (this.isInWishlist(artNr)) {
      const wlItem = this._getWlItem(artNr);

      this._mpWishlist.deleteItem(wlItem.ID, (wl: Array<Tile>) => {
        this._wl = wl;
      });
    } else {
      this._mpWishlist.addItem(artNr, pin, (wl: Array<Tile>) => {
        this._wl = wl;
        this.addToWlKey = key;
        this.showDeleteDialog = true;
      });
    }
  }

  /**
   * Deletes an item from the shopping
   * basket.
   */
  deleteItem(key: string): void {
    this.showDeleteDialog = false;
    this._mpShoppingBasket.deleteItem(key);
    this._mpMessaging.openSuccessPanel(this.ls.locs['locWkZuzahlung'].ArtikelEntfernt);
  }

  /**
   * Hides the delete dialog.
   */
  private _hideDeleteDialog(): void {
    this.showDeleteDialog = false;
  }

  /**
   * Closes thie delete dialog.
   */
  closeWkModal(): void {
    this.showDeleteDialog = false;
  }

  /**
   * Checks if a valid payment method
   * is available.
   */
  hasValidPaymentMethod(): boolean {
    return typeof this.provider.find((prov: any) => { return prov['possible']; }) !== 'undefined' ? true : false;
  }

  /**
   * Gets the amount of the additional
   * payment.
   */
  getAdditionalPaymentAmount(): number {
    const pluckedSbItems = this.sBItems.map((sBItem: any) => {
      return sBItem['ZuzahlungEuro'];
    });

    return pluckedSbItems.reduce((a: any, b: any) => { return a + b }, 0);
  }

  /**
   * Goes to the next step of the order
   * process, if the user can order.
   */
  goToNextIfCanOrder(evt?: MouseEvent): void {
    if (typeof evt !== 'undefined') {
      evt.preventDefault();
    }

    if ((this.canOrder && this.hasValidPaymentMethod()) || this.getAdditionalPaymentAmount() === 0) {
      this.mpOrderProcess.next(this.currentStep['Key']);
    }
  }

  /**
  * Returns the index of item in
  * ngFor. Is used for trackBy in ngFor.
  */
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
