import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CommonComponentsLoaderModule } from './../common-components-loader.module';

import { ExternComponent } from './extern.component';
import { LoginComponent } from './../../components/login/login.component';
import { SidebarForgotLoginDataComponent } from './../../components/sidebar/sidebar-forgot-login-data/sidebar-forgot-login-data.component';

import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ImprintComponent } from './pages/imprint/imprint.component';

@NgModule({
  declarations: [
    ExternComponent,
    LoginComponent,
    SidebarForgotLoginDataComponent,
    ForgotPasswordComponent,
    ImprintComponent
  ],
  exports: [
    ExternComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonComponentsLoaderModule
  ]
})
export class ExternModule { }
