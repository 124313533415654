import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { FroalaConfigService } from './../../../../services/froala-config.service';

/**
 * This class provides the functions for
 * the import of campaign orders.
 */
@Component({
  selector: 'mp-core-import-campaign-orders',
  templateUrl: './import-campaign-orders.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ImportCampaignOrdersComponent implements OnInit, OnDestroy {

  @ViewChildren('fileUpload') fileUpload: any;

  public errorSubject: string = '';
  public errorCampaignKey: string = '';
  public errorOffsettingHint: string = '';
  public errorWarehouse: string = '';
  public errorUpload: string = '';
  public errorBody: string = '';
  public froalaOptions: { [key: string]: any } = {};
  public froalaOptionsLoaded: boolean = false;
  public selectedTBS: { [key: string]: any } | null = null;
  public tbsList: Array<any> = [];
  public warehousesList: Array<any> = [];
  public warehouse: string = '';
  public uploadSuccessfully: boolean = false;
  public file: File | undefined;
  public campaignKey: string = '';
  public offsettingHint: string = '';
  public sendCampaignEmail: boolean = false;
  public campaignNameInAccount: boolean = false;
  public sendOrderMail: boolean = false;
  public externalPeople: boolean = false;
  public subject: string = '';
  public body: string = '';
  public messages: string = '';
  public saveInProgress: boolean = false;

  private _froalaEditor: any;
  private _token: string | null = sessionStorage.getItem('token');
  private _language: string = 'de';
  private _fileName: string = '';
  private _getLanguageSubscription: Subscription | undefined;
  private _getTBSSubscription: Subscription | undefined;
  private _getWarehousesSubscription: Subscription | undefined;
  private _fileUploadSubscription: Subscription | undefined;
  private _saveImportSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    private _froalaConfig: FroalaConfigService
  ) { }

  /**
   * Gets the language and triggers the
   * setting of the froala options. Gets
   * the text-snippets and the warehouses.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._getLanguageSubscription = this._apiService.getRequest('/api/Language/getLanguage').subscribe((data: any) => {
      this._language = data.Records[0];
      this._setFroalaOptions();
    });

    this._getTBSSubscription = this._apiService.getRequest('/api/EMailModul/GetEmailModulData').subscribe((data: any) => {
      if (data.Records[0]) {
        this.tbsList = data.Records[0].TBS;
      }
    });

    this._getWarehousesSubscription = this._apiService.getRequest('/api/Artikel/GetLager').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.warehousesList = data.Records;
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error, '');
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLanguageSubscription !== 'undefined') {
      this._getLanguageSubscription.unsubscribe();
    }

    if (typeof this._getTBSSubscription !== 'undefined') {
      this._getTBSSubscription.unsubscribe();
    }

    if (typeof this._getWarehousesSubscription !== 'undefined') {
      this._getWarehousesSubscription.unsubscribe();
    }

    if (typeof this._fileUploadSubscription !== 'undefined') {
      this._fileUploadSubscription.unsubscribe();
    }

    if (typeof this._saveImportSubscription !== 'undefined') {
      this._saveImportSubscription.unsubscribe();
    }
  }

  /**
   * Sets the options for the froala
   * editor.
   */
  private _setFroalaOptions(): void {
    const requestVerificationTokenInput: HTMLInputElement | null = document.querySelector('input[name="__RequestVerificationToken"]');
    const xsrfToken: string | null = this._getCookie('XSRF-TOKEN');

    this.froalaOptions = Object.assign(this._froalaConfig.getConfig(), {
      language: this._language,
      oolbarButtons: [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontSize', '|', 'color', 'align', 'formatOL', 'formatUL',
        'outdent', 'indent', 'quote', '|', 'insertLink', 'insertImage', 'insertTable', 'undo', 'redo', 'html',
        'imageManager'
      ],
      requestHeaders: {
        'X-XSRF-TOKEN': xsrfToken || '',
        'Authorization': 'Bearer ' + this._token
      }
    });

    this.froalaOptionsLoaded = true;
    this._initFroala();
  }

  /**
  * Retrieves a cookie value by name
  **/
  private _getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Initializes the froala editor.
   */
  private _initFroala(): void {
    if (this._froalaEditor) {
      this._froalaEditor.destroy();

      if (Object.keys(this.froalaOptions).length > 0) {
        this._froalaEditor.initialize(this.froalaOptions);
      }
    }
  }

  /**
   * Gets the froala controls from the
   * froala init function.
   */
  getFroalaControls(froala: any): void {
    this._froalaEditor = froala;
    this._initFroala();
  }

  /**
   * Handles the change of the 
   * text-snippets.
   */
  tbsChange(selectedTBS: { [key: string]: any } | null): void {
    this.selectedTBS = selectedTBS;

    if (this.selectedTBS && this.selectedTBS['Value']) {
      if (typeof this._froalaEditor !== 'undefined' && this._froalaEditor !== null) {
        // @ts-ignore
        this._froalaEditor.getEditor().html.insert(this.selectedTBS['Value'], true);
        this.selectedTBS = null;
      }
    }
  }

  /**
   * Handles the file upload.
   */
  public addAttachment(evt: any): void {
    this.uploadSuccessfully = false;
    const files = evt.target.files;
    this.file = files[0];

    if (this.file) {
      this._fileUploadSubscription = this._apiService.postFileUploadRequest('/api/Upload/uploadImportArtikelbestellungenListe', this.file).subscribe((data: any) => {
        if (data) {
          if (data.Result === 'OK') {
            this.uploadSuccessfully = true;
            this.errorUpload = '';
            this._mpMessaging.openSuccessPanel(this.ls.locs['loc'].DateiWurdeErfolgreichHochgeladen);
            this._fileName = data.Records[0];
          } else {
            this._mpMessaging.openWarningPanel(data.Message);
            this.uploadSuccessfully = false;
            this.errorUpload = data.Message;
          }
        } else {
          this.uploadSuccessfully = true;
        }
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.Message);
        this.uploadSuccessfully = false;
        this.errorUpload = error.Message;
      });
    }
  }

  /**
   * Validates the form data.
   */
  private _validate(): boolean {
    this.errorCampaignKey = '';
    this.errorOffsettingHint = '';
    this.errorWarehouse = '';
    this.errorUpload = '';
    this.errorSubject = '';
    this.errorBody = '';

    if (this.campaignKey === '') {
      this.errorCampaignKey = this.ls.locs['loc'].ErrorAktionsbestellungImportAktionsname;
      return false;
    }

    if (this.campaignKey.length > 50) {
      this.errorCampaignKey = this.ls.locs['loc'].ErrorAktionsbestellungImportAktionsnameLaenge;
    }

    if (this.offsettingHint === '') {
      this.errorOffsettingHint = this.ls.locs['loc'].ErrorAktionsbestellungImportVerrechnungshinweis;
    } else if (this.offsettingHint.length > 25) {
      this.errorOffsettingHint = this.ls.locs['loc'].ErrorAktionsbestellungImportVerrechnungshinweisLaenge;
    }

    if (this.warehouse !== '' && this.warehouse.length > 10) {
      this.errorWarehouse = this.ls.locs['loc'].ErrorAktionsbestellungImportLagerLaenge;
    }

    if (!this.uploadSuccessfully) {
      this.errorUpload = this.ls.locs['loc'].ErrorAktionsbestellungImportUpload;
    }

    if (this.sendCampaignEmail) {
      if (this.subject === '') {
        this.errorSubject = this.ls.locs['loc'].ErrorAktionsbestellungImportBetreffAktionsmail;
      }

      if (this.body === '') {
        this.errorBody = this.ls.locs['loc'].ErrorAktionsbestellungImportTextAktionsmail;
      }
    }

    return this.errorCampaignKey === ''
      && this.errorOffsettingHint === ''
      && this.errorWarehouse === ''
      && this.errorUpload === ''
      && this.errorSubject === ''
      && this.errorBody === '';
  }

  /**
   * Imports the orders.
   */
  importOrders(): void {
    if (!this._validate()) {
      return;
    }

    this.messages = '';
    this.saveInProgress = true;

    const data = {
      FileName: this._fileName,
      Key: this.campaignKey,
      Verrechnungshinweis: this.offsettingHint,
      Lager: this.warehouse,
      AktionsnameInKonto: this.campaignNameInAccount,
      BestellMailVersenden: this.sendOrderMail,
      AktionsEmailVersenden: this.sendCampaignEmail,
      EmailSubject: this.subject,
      EmailBody: this.body,
      ExternePersonen: this.externalPeople
    };

    this._saveImportSubscription = this._apiService.postRequest('api/Bestellung/setAktionsbestellungen', data).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.messages = data.Records[0];
        this._mpMessaging.openSuccessPanel('Import ausgeführt. Siehe Protokoll unten.');
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      this.saveInProgress = false;
    },
    (error: any) => {
      this._mpMessaging.openWarningPanel(error.Message);
      this.saveInProgress = false;
    });
  }

}
