<section id="dashboard" *ngIf="(ls.locsLoaded | async)">
  <div>
    <h1>{{ ls.locs['loc'].MeineAktionen }}</h1>

    <div id="aktionContainer">
      <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="load()">
        <ul class="timeline"
            *ngFor="let campaign of campaigns; let last = last;"
            [ngClass]="{aktionKachel:!campaign['IsVorbei'], aktionKachelBeendet: campaign['IsVorbei']}"
            [isLast]="last" (afterRenderItem)="afterRender()">
          <mp-core-action-card class="item" [action]="campaign"></mp-core-action-card>
        </ul>
      </div>
    </div>
  </div>
</section>
