<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <mp-core-breadcrumbs></mp-core-breadcrumbs>
  <mp-core-detail-page-content imgSrc="{{play['ImagePath']}}?h=427&w=1280&mode=crop"
                               [title]="trustAsHtml(play['Titel'])"
                               [text]="trustAsHtml(play['Text'])"
                               [showProgress]="false">
  </mp-core-detail-page-content>

  <div *ngIf="showNewRanking">
    <mp-core-data-table title="{{ ls.locs['locGamification'].RanglisteStandVom }} {{currentAccess}}"
                        infotextShorten="CurrentRangliste"
                        fieldsAction="/api/Gamification/GetRanglisteFields"
                        listAction="/api/Gamification/GetCurrentRangliste"
                        loaderName="gamificationRankingLightPlayNewRankingLoader"
                        [paging]="false"
                        [footer]="false"
                        [disableCache]="true"
                        [hideSearch]="true"
                        [hideFilter]="true"
                        [hideRowNumbers]="true">
    </mp-core-data-table>
    <!--
    <mp-core-data-table title="{{ ls.locs['locGamification'].RanglisteStandVom }} {{currentAccess}}"
                        infotextShorten="CurrentRangliste"
                        fieldsAction="api/Gamification/GetRankingLightStatistikFields"
                        listAction="/api/Gamification/GetRankingLightStatistik"
                        loaderName="gamificationRankingLightPlayNewRankingLoader"
                        [paging]="false"
                        [footer]="false"
                        [disableCache]="true"
                        [hideSearch]="true"
                        [hideFilter]="true"
                        [hideRowNumbers]="true">
    </mp-core-data-table>
    -->
  </div>

  <div *ngIf="!showNewRanking">
    <mp-core-data-table title="{{ ls.locs['locGamification'].RanglisteStandVom }} {{lastAccess}}"
                        infotextShorten="OldRangliste"
                        fieldsAction="/api/Gamification/GetRanglisteFields"
                        listAction="/api/Gamification/GetOldRangliste"
                        loaderName="gamificationRankingLightPlayOldRankingLoader"
                        [paging]="false"
                        [footer]="false"
                        [disableCache]="true"
                        [hideSearch]="true"
                        [hideFilter]="true"
                        [hideRowNumbers]="true">
    </mp-core-data-table>
  </div>


  <button class="mp-button _prim margin-top-24" [ngClass]="!showNewRanking ? '' : 'disabled'" (click)="refresh()">{{ ls.locs['locGamification'].RanglisteAktualisieren }}</button>

</div>
