<div id="sidebar-travel-destination-filter-hotel" *ngIf="(ls.locsLoaded | async)" class="rk-sidebar-form">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="texst-align-left">{{ ls.locs['locReisekonfigurator'].ReisezieleFilterSidebarHeader }}</div>
    <div class="overflow-hidden">
      <mp-core-search mpId="reiseziele-suche" [model]="searchTerm" (modelChange)="searchTerm = $event; searchTermChange()" class="sb-search padding-16 display-block position-container" mpPlaceholder="{{searchfieldPlaceholder}}" [focusOn]="focusOnInit">
      <i class="position-absolute-top-right svg-icon __clr-default __wide __top-0 __search"></i>
      <i [hidden]="searchTerm === ''" class="svg-icon __clr-default __size-18 __top-8 __clear position-absolute-top-right margin-right-48 margin-top-16" (click)="clearSearch($event)"></i>
      </mp-core-search>
    </div>
    <div class="divider margin-bottom-8 margin-left-16"></div>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#reiseziele-filter-scroll-area" scrollDirection="up" scrollFactor="1" [scopeObject]="targets" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container" id="reiseziele-filter-scroll-area-container">
      <div id="reiseziele-filter-scroll-area" class="padding-top-8 sidebar-scroll-area position-relative">
        <div *ngFor="let targetObj of targets, let index = index; let parentIndex = index" class="margin-bottom-8 flex-grid flex-direction-row flex-wrap">
          <mp-core-checkbox mpId="parent{{index}}" [(model)]="activeItems[targetObj.parentId]" class="flex-col-12 margin-bottom-16" (change)="updateChildTargets(targetObj.parentId)" label="{{targetObj['targetList'][0].ParentBezeichnung}}"></mp-core-checkbox>
          <div *ngFor="let target of targetObj.targetList; let index = index;" class="flex-col-6 flex-col-6-xs flex-col-6-s padding-left-24 margin-bottom-8">
            <mp-core-checkbox mpId="ziel_{{targetObj.parentId}}_{{index}}" [(model)]="target.Active" (change)="changeTargetState($event, parentIndex, index)" label="{{target.Bezeichnung}}"></mp-core-checkbox>
          </div>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#reiseziele-filter-scroll-area" scrollDirection="down" scrollFactor="1" [scopeObject]="targets" class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].AenderungenUebernehmen }}</a>
    </div>
  </div>
</div>
