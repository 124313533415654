<div id="sidebar-period-time" class="sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="texst-align-left">
      {{ ls.locs['loc'].ZeitraumAuswaehlen }}
    </div>
    <div class="divider margin-bottom-8"></div>
    <div class="margin-left-16 margin-right-16 margin-top-16 display-flex"
         id="calendar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeStart'] }"
           (click)="setCurrentSelection('start')">
        <div>
          <span class="text-transform-uppercase font-size-xs margin-left-32">{{ ls.locs['loc'].Von }}</span>
        </div>
        <div>
          <span *ngIf="selection['rangeStart'] && selection['rangeStart'] !== null"
                class="font-size-xs"
                [innerText]="selection['rangeStart'].format('dddd')">
          </span>
          <span [innerText]="selection['rangeStart'].format('L')"></span>
        </div>
      </div>
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-start pointer"
           [ngClass]="{ 'active-tab': selection['rangeEnd'] && selection['rangeEnd'] !== selection['rangeStart'] }"
           (click)="setCurrentSelection('end')">
        <div>
          <span class="text-transform-uppercase font-size-xs margin-left-32">{{ ls.locs['loc'].Bis }}</span>
        </div>
        <div>
          <span *ngIf="selection['rangeEnd'] && selection['rangeEnd'] !== null && selection['rangeEnd'] !== selection['rangeStart']"
                class="font-size-xs"
                [innerText]="selection['rangeEnd'].format('dddd')">
          </span>
          <span [innerText]="(selection['rangeEnd'] && selection['rangeEnd'] !== null && (selection['rangeEnd'] !== selection['rangeStart'] || selection['UhrzeitVonStunden'] !== selection['UhrzeitBisStunden'] || selection['UhrzeitVonMinuten'] !== selection['UhrzeitBisMinuten'])) ? selection['rangeEnd'].format('L') : ls.locs['loc'].Unbegrenzt"></span>
        </div>
      </div>
    </div>
    <div id="sidebar-time-selects"
         class="display-flex margin-left-16 margin-right-16 margin-top-12">
      <mp-core-select mpId="uhrzeit-von-stunden"
                      [(model)]="selection['UhrzeitVonStunden']"
                      [elements]="timeHours"
                      valueProperty="value"
                      textProperty="text"
                      class="width-full">
      </mp-core-select>

       <mp-core-select mpId="uhrzeit-bis-stunden"
                    [(model)]="selection['UhrzeitBisStunden']"
                    [elements]="timeHours"
                    valueProperty="value"
                    textProperty="text"
                    class="width-full">
    </mp-core-select>

  </div>
  <div id="sidebar-time-selects"
       class="display-flex margin-left-16 margin-right-16 margin-top-12">
    <mp-core-select mpId="uhrzeit-von-minuten"
                    [(model)]="selection['UhrzeitVonMinuten']"
                    [elements]="timeMinutes"
                    valueProperty="value"
                    textProperty="text"
                    class="width-full">
    </mp-core-select>

    <mp-core-select mpId="uhrzeit-bis-minuten"
                    [(model)]="selection['UhrzeitBisMinuten']"
                    [elements]="timeMinutes"
                    valueProperty="value"
                    textProperty="text"
                    class="width-full">
    </mp-core-select>

    </div>
    <div class="display-flex margin-left-16 margin-right-16 margin-top-12">
      <div class="width-100-pcnt">
        <a href="" (click)="setDirectly($event)" class="mp-button _prim">{{ ls.locs['loc'].AbSofort }}</a>
      </div>
      <div class="width-100-pcnt">
        <a href="" (click)="setForever($event)" class="mp-button _prim">{{ ls.locs['loc'].FuerImmer }}</a>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#zeitraum-uhrzeit-scroll-area"
                   scrollDirection="up"
                   desktopItemHeightt=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="zeitraum-uhrzeit-scroll-area-container">
      <div id="zeitraum-uhrzeit-scroll-area"
           class="sidebar-scroll-area position-relative">
        <div>
          <mp-core-calendar [(minDate)]="mpSidebar.params['minDate']"
                          [(rangeStart)]="selection['rangeStart']"
                          [(rangeEnd)]="selection['rangeEnd']"
                          [isRange]="true"
                          [(minRange)]="minRange"
                          [defaultRange]="0"
                          [(currentSelection)]="selection['currentSelection']">
          </mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="false"
                   scrollItemHeight="80"
                   scrollArea="#zeitraum-uhrzeit-scroll-area"
                   scrollDirection="down"
                   desktopItemHeightt=".calendar > div"
                   scrollFactor="1"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         [ngClass]="{disabled: !selection['rangeStart']}"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}
      </a>
    </div>
  </div>
</div>
