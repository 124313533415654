import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParticipantComponent } from '@core/modules/participant/participant.component';
import { ManagerComponent } from '@core/modules/manager/manager.component';
import { AdminComponent } from '@core/modules/admin/admin.component';

import { AuthGuard } from '@core/helpers/auth.guard';

import { PrizeCalendarOneComponent } from './modules/participant/pages/prize-calendar/prize-calendar-one/prize-calendar-one.component';
import { PrizeCalendarTwoComponent } from './modules/participant/pages/prize-calendar/prize-calendar-two/prize-calendar-two.component';
import { PrizeCalendarStatisticComponent } from './modules/manager/pages/prize-calendar-statistic/prize-calendar-statistic.component';
import { PrizeCalendarWinnerStatisticComponent } from './modules/manager/pages/prize-calendar-winner-statistic/prize-calendar-winner-statistic.component';
import { PrizeCalendarEvaluationComponent } from './modules/manager/pages/prize-calendar-evaluation/prize-calendar-evaluation.component';
import { PrizeCalendarEvaluationDetailsComponent } from './modules/manager/pages/prize-calendar-evaluation-details/prize-calendar-evaluation-details.component';
import { WinnersOverviewComponent } from './modules/manager/pages/winners-overview/winners-overview.component';

const routes: Routes = [
  {
    path: 'tn',
    component: ParticipantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Gewinnkalender',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarOneComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      },
      {
        path: 'GewinnkalenderZwei',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarTwoComponent, outlet: 'mainContentParticipant', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'GewinnerUebersicht',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: WinnersOverviewComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      },
      {
        path: 'GewinnkalenderAuswertung',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarEvaluationComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      },
      {
        path: 'GewinnkalenderAuswertungDetails',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarEvaluationDetailsComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      },
      {
        path: 'GewinnkalenderGewinnerStatistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarWinnerStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      },
      {
        path: 'GewinnkalenderStatistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarStatisticComponent, outlet: 'mainContentManager', pathMatch: 'full' }
        ]
      }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'GewinnkalenderStatistik',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: PrizeCalendarStatisticComponent, outlet: 'mainContentAdmin', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class GkRoutingModule { }
