<div class="hotel-bestaetigung padding-top-64 padding-bottom-64 rk-two-columns-layout" id="hotel-confirmation" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div reisekonfigurator-background class="rk-background" style="background-image: url('/themes/default/media/images/rk-video.png')"></div>
  <div id="rk-booking-error" [hidden]="message === ''" class="padding-left-16 padding-right-16 padding-bottom-16 hide-on-medium">
    <div id="rk-error" class="padding-top-16 padding-bottom-8 width-100-pcnt">
      <div id="rk-back-btn" class="display-inline-flex flex-justify-cont-end __top-0 padding-8 position-relative">
        <a href="/" class="mp-button display-flex">
          <span class="display-inline-block position-relative top-2">{{ls.locs['loc'].ZurueckZurStartseite}}</span>
        </a>
      </div>
    </div>
  </div>
  <div id="rk-main-section" class="noBookingMessage padding-bottom-16" [hidden]="message === ''">
    <mp-core-info-notification iconPosition="left" infoState="info" headline="{{ls.locs['locReisekonfigurator'].BestellBestaetigungFehlerTitel}}" copyText="{{ls.locs['locReisekonfigurator'].BestellungHeaderFehlertext}}<br> {{message}}" class="margin-top-8 margin-left-0 margin-right-0 padding-left-4 padding-right-8 display-block"></mp-core-info-notification>
  </div>
  <div id="rk-booking-confirm" [hidden]="orderNo === ''" class="margin-left-right-16 margin-top-16 bg-color-white padding-left-40 padding-right-40 padding-bottom-16">
    <div class="border-bottom flex-grid margin-0 flex-align-items-center">
      <div class="flex-col-9 flex-col-8-s flex-col-8-m padding-left-0">
        <i class="svg-icon __clr-info __size-40 __top-4 __done-all margin-bottom-8 margin-right-8 default-cursor-hover float-none-important"></i>
        <h2 class="margin-bottom-0 display-flex flex-align-items-center">{{ls.locs['locReisekonfigurator'].BestellBestaetigungTitel}}</h2>
      </div>
      <div class="flex-col-3 flex-col-4-s flex-col-4-m flex-justify-cont-end padding-right-0 hide-on-medium">
        <a href="/" class="mp-button">{{ls.locs['loc'].ZurueckZurStartseite}}</a>
      </div>
    </div>
    <div id="order-confirmation-header-text" class="margin-top-16 bold" [innerText]="ls.locs['locReisekonfigurator'].BestellungHeadertext.replace('{0}', orderNo)"></div>
  </div>
  <div id="rk-main-section" *ngIf="bookingAvailable" class="rk-hotel-bestaetigung">
    <div id="rk-offers" class="margin-top-16 margin-bottom-16">
      <div id="rk-additional-pay">
        <div class="rk-offers-headline">
          <h2>
            {{ls.locs['locReisekonfigurator'].IhreKostenuebersicht}} {{booking['Erwachsene'].length}} {{booking['Erwachsene'].length > 1 ? ls.locs['locReisekonfigurator'].Erwachsene : ls.locs['locReisekonfigurator'].Erwachsener}}
            <span [hidden]="booking['Kinder'].length === 0">, {{booking['Kinder'].length}} {{booking['Kinder'].length > 1 ? ls.locs['locReisekonfigurator'].Kinder : ls.locs['locReisekonfigurator'].Kind}}</span>
          </h2>
        </div>
        <div class="margin-0 padding-top-16 padding-bottom-16 padding-left-40 padding-right-40 flex-grid flex-direction-row flex-wrap flex-align-items-end bg-color-white">
          <div class="flex-col-12 padding-0">{{ls.locs['locReisekonfigurator'].Reisepreis}}</div>
          <div class="flex-col-8 flex-col-8-xs padding-left-0 font-size-s">
            {{booking['Angebot'].Hotel.Name}}
          </div>
          <div class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
            <span>{{booking['Angebot'].Punkte | integer}}</span>
            <span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
          </div>
          <div [hidden]="!booking['Angebot'].Preise.AsList[booking['Reiseschutz']]" class="flex-col-12 padding-0 margin-top-16">
            {{ls.locs['locReisekonfigurator'].Reiseschutz}}
          </div>
          <div [hidden]="!booking['Angebot'].Preise.AsList[booking['Reiseschutz']]" class="flex-col-8 flex-col-8-xs padding-left-0 font-size-s display-inline-block">
            {{ls.locs['locReisekonfigurator'][booking['Reiseschutz']]}}
          </div>
          <div [hidden]="!booking['Angebot'].Preise.AsList[booking['Reiseschutz']]" class="flex-col-4 flex-col-4-xs padding-right-0 flex-justify-cont-end text-color-info">
            <span>{{(booking['Angebot'].Preise.AsList[booking['Reiseschutz']] || 0) | integer}}</span>
            <span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
          </div>
        </div>
        <div id="rk-overview-sum" class="padding-top-16 padding-bottom-16 padding-left-40 padding-right-40 text-color-white bg-color-info font-size-l">
          <div class="flex-grid flex-direction-row flex-wrap flex-align-items-end">
            <div class="flex-col-8 flex-col-8-xs">{{ls.locs['locReisekonfigurator'].Gesamtbetrag}}</div>
            <div class="flex-col-4 flex-col-4-xs flex-justify-cont-end">
              <span>{{booking['Angebot'].Punkte + (booking['Angebot'].Preise.AsList[booking['Reiseschutz']] || 0) | integer}}</span>
              <span class="currency margin-left-4">{{ls.locs['loc'].WaehrungPunkte}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="margin-top-32 order-process rk-bestaetigung">
        <mp-core-info-notification iconPosition="top" infoState="info" class="margin-0 padding-bottom-32 display-block">
          <span [innerHTML]="ls.locs['locReisekonfigurator'].BestellBestaetigungInfo.replace('{0}', role).replace('{1}', orderNo)"></span>
        </mp-core-info-notification>
        <div class="bg-color-dark flex-grid margin-0 flex-col-12 flex-justify-cont-center flex-align-items-center padding-top-8 padding-bottom-8 margin-bottom-4 margin-top-24">
          {{ls.locs['locReisekonfigurator'].IhreBuchungsnummer}}:
          <span class="bold margin-left-8">{{orderNo}}</span>
        </div>
        <div *ngIf="!mpSettings.settings['DisplaySettings'].HideHotline" id="order-confirmation-info-block" class="bg-color-dark flex-grid margin-0 flex-col-12 flex-justify-cont-center flex-align-items-center padding-top-32 padding-bottom-32 flex-direction-column">
          <p class="text-transform-uppercase font-size-px-24 margin-bottom-4 bold">{{ls.locs['locReisekonfigurator'].FragenZurBestellung}}</p>
          <p>{{ls.locs['loc'].ServiceZurVerfuegung}}</p>
          <div class="flex-grid margin-left-0 margin-right-0 margin-top-16" id="order-confirmation-info-contacts">
            <div id="order-confirmation-contact" class="flex-col-6 flex-justify-cont-center text-align-center">
              <span class="font-size-px-24" [innerHTML]="ls.locs['loc'].KontaktformularBestellung.replace('{0}', role).replace('{1}', orderNo)"></span>
            </div>
            <div id="order-confirmation-hotline" class="flex-col-6 flex-align-items-center flex-direction-column">
              <div class="font-size-px-24">{{ls.locs['loc'].RufenSieUnsAn}}</div>
              <span class="text-color-info font-size-px-24">{{mpSettings.settings['ReisekonfiguratorSettings'].ReisenHotlineNummer}}</span>
              <div class="kontaktServiceTimes">
                <span>{{ls.locs['loc'].Supporttool_Zeiten}}</span>
              </div>
              <div class="kontaktServiceTimes">
                <span>{{ls.locs['loc'].SupportKosten}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mp-core-micro-copy infoState="inactive" copyText="{{ls.locs['locReisekonfigurator'].BuchungsnummerBeiKontaktNennen}}" class="margin-top-16 display-block"></mp-core-micro-copy>
      <hr class="show-on-medium margin-top-16 margin-botto-40 full-hr" />
      <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-end hide-on-medium">
        <a class="mp-button" href="/">{{ls.locs['loc'].ZurueckZurStartseite}}</a>
      </div>
    </div>
    <div class="margin-top-16 margin-bottom-16">
      <div id="rk-overview-hotel" class="bg-color-white">
        <div id="rk-overview-img" style="background-image: url('{{booking['Angebot'].Hotel.Bilder[0]}}')"></div>
        <div class="flex-grid flex-direction-column padding-top-16 padding-bottom-16 padding-left-32 padding-right-32">
          <div class="flex-col-12 text-color-grey-info display-block-important">
            <div class="display-inline">
              <span class="icon-text font-size-s">{{booking['Angebot'].Hotel.Land}}</span>
            </div>
            <div class="display-inline">
              <i class="svg-icon __clr-default __size-14 __top-3 __nav-next margin-left-2 margin-right-2 float-none-important default-cursor-hover"></i>
              <span class="icon-text font-size-s">{{booking['Angebot'].Hotel.Zielgebiet}}</span>
            </div>
            <div class="display-inline">
              <i class="svg-icon __clr-default __size-14 __top-3 __nav-next margin-left-2 margin-right-2 float-none-important default-cursor-hover"></i>
              <span class="icon-text font-size-s">{{booking['Angebot'].Hotel.Stadt}}</span>
            </div>
          </div>
          <div class="flex-col-12 padding-top-12">
            <h3 class="margin-bottom-0">{{booking['Angebot'].Hotel.Name}}</h3>
          </div>
          <div class="flex-col-12 flex-align-items-center rk-offer-stars">
            <span [ngClass]="'sterne' + booking['Angebot'].Hotel.Kategorie"></span>
            <span class="font-size-s">-{{ls.locs['locReisekonfigurator'].Hotel}}</span>
          </div>
          <div class="flex-col-12 rk-offer-categories" [hidden]="booking['Angebot'].Hotel.Themen.length === 0">
            <span *ngFor="let t of booking['Angebot'].Hotel.Themen; trackBy: trackByIndex" class="margin-right-4" [ngClass]="t.CssClas"></span>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">{{ls.locs['loc'].Veranstalter}}</div>
            <div class="font-size-s margin-top-4">
              <img class="" src="https://cdn.netcentive.de/logos/dt/{{booking['Angebot'].Anbieter}}.jpg?maxwidth=50&maxheight=30" alt="{{booking['Angebot'].Anbieter}}" />
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">{{ls.locs['locReisekonfigurator'].Reisetermin}}</div>
            <div class="font-size-s margin-top-4">
              <span>{{booking['Angebot'].StartTerminDisplay}} - {{booking['Angebot'].EndTerminDisplay}}</span>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">{{ls.locs['locReisekonfigurator'].Reiseteilnehmer}}</div>
            <div class="font-size-s display-flex flex-direction-column margin-top-4">
              <div class="display-flex" *ngFor="let person of booking['Personen']">
                <span>{{person.Vorname}} {{person.Name}}</span>
              </div>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16" id="rk-overview-services">
            <div class="bold">{{ls.locs['locReisekonfigurator'].Leistungen}}</div>
            <div class="font-size-s display-flex flex-direction-column margin-top-4">
              <div class="display-flex">
                <i class="svg-icon __clr-default __size-18 __top-1 __done default-cursor-hover"></i>
                <span>{{booking['Angebot'].DauerInNaechtenDisplay}}</span>
              </div>
              <div class="display-flex" *ngFor="let zk of booking['Angebot'].Zimmerkuerzel">
                <i class="svg-icon __clr-default __size-18 __top-1 __done default-cursor-hover"></i>
                <span>{{zk.Beschreibung}}</span>
              </div>
              <div class="display-flex">
                <i class="svg-icon __clr-default __size-18 __top-1 __done default-cursor-hover"></i>
                <span>{{booking['Angebot'].VerpflegungDisplay}}</span>
              </div>
            </div>
          </div>
          <div class="flex-col-12 flex-direction-column padding-top-16">
            <div class="bold">{{ls.locs['locReisekonfigurator'].Reiseschutz}}</div>
            <div class="font-size-s display-flex flex-direction-column margin-top-4">
              <div class="display-flex">
                <i class="svg-icon __clr-default __size-18 __top-1 __done default-cursor-hover margin-right-4"></i>
                <span>{{ls.locs['locReisekonfigurator'][booking['Reiseschutz']]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="rk-bottom-buttons" class="show-on-medium bg-color-white position-fixed bottom-0 width-full" stickyBottom>
    <div class="flex-grid flex-direction-row flex-wrap">
      <div class="flex-col-12 display-flex flex-direction-row flex-justify-cont-end">
        <a class="mp-button" href="/">{{ls.locs['loc'].ZurueckZurStartseite}}</a>
      </div>
    </div>
  </div>
</div>
