import { Component, OnInit, ViewEncapsulation, OnDestroy, SecurityContext } from '@angular/core';
import { Subscription } from 'rxjs';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';

/**
 * This class provides the data for
 * the yields.
 */
@Component({
  selector: 'mp-taw-tip-and-win-yields',
  templateUrl: './tip-and-win-yields.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinYieldsComponent implements OnInit, OnDestroy {

  public textLoaded: boolean = false;
  public text: { [key: string]: any } = {};
  public safeSrc: SafeResourceUrl | undefined;
  public trustedHtml: any;

  private _getYieldsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _svgLoader: SvgLoaderService,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Gets the rules of game.
   */
  ngOnInit(): void {
    this._svgLoader.startLoading();

    this._getYieldsSubscription = this._apiService.getRequest('/api/Texte/getTextAndTitle?key=tip-and-win-gewinne').subscribe((data: any) => {
      this._svgLoader.finishLoading();

      if (data.Result === 'OK') {
        this.text = data.Records[0];

        if (!this.text['Text'] || this.text['Text'] === null || this.text['Text'].length === 0) {
          this.text['Text'] = undefined;
        }

        this.textLoaded = true;
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
      (error: any) => {
        this._svgLoader.finishLoading();
        this._mpMessaging.openErrorPanel(error);
      });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getYieldsSubscription !== 'undefined') {
      this._getYieldsSubscription.unsubscribe();
    }
  }

  /*
  * Sanitizer for HTML.
  */
  trustAsHtml(str: string): string {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
