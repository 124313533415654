import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { DataTableChildsService } from '@core/components/data-table/data-table-childs.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';

import { WintergamesTwentyTwentyoneStatisticChildComponent } from './wintergames-twenty-twentyone-statistic-child/wintergames-twenty-twentyone-statistic-child.component';

/**
 * This class provides the data and
 * functions for the wintergmes 2021
 * statistic table.
 */
@Component({
  selector: 'mp-gfc-wintergames-twenty-twentyone-statistic',
  templateUrl: './wintergames-twenty-twentyone-statistic.component.html'
})
export class WintergamesTwentyTwentyoneStatisticComponent implements OnInit {

  public excelService: string = '/api/Statistik/GetWinterspiele2021Statistik2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);

  private _wintergamesTwentyTwentyoneStatisticLoader: MpLoader = {
    name: 'wintergamesTwentyTwentyoneStatisticLoader',
    params: {}
  };

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _dataTableChilds: DataTableChildsService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._dataTableChilds.registerTemplate('/Views/manager/Winterspiele2021StatistikChild', WintergamesTwentyTwentyoneStatisticChildComponent);
    this._mpMenuHighlight.setMenuHighlight('statistik');

    this._mpLoaderService.registerLoader(this._wintergamesTwentyTwentyoneStatisticLoader);

    this._mpLoaderService.extendLoader('wintergamesTwentyTwentyoneStatisticLoader', {
      isReady: true
    });
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('wintergamesTwentyTwentyoneStatisticLoader');
  }

}
