<div id="dlgTNCockpitStammdaten" *ngIf="(ls.locsLoaded | async)" class="layout-container box-padding-16">
  <div class="fsOverlay" [hidden]="!loading">
    <div class="verticalCenter">
    </div>
  </div>
  <div class="form">
    <div *ngIf="redemptionLimitActive" class="margin-bottom-24 font-weight-600">
      <div>{{ls.locs['loc'].Versteuerungsgrenze}}: {{redemptionLimit | number: '1.2-2'}} €</div>
      <div [ngClass]="{'text-color-warning': redemptionLimitOrderSum > redemptionLimitWarning, 'text-color-danger': redemptionLimitOrderSum > redemptionLimit * 0.99}">{{ls.locs['loc'].BisherigeSumme}}: {{redemptionLimitOrderSum | number: '1.2-2'}} €</div>
    </div>
    <div class="cntrTNData">
      <mp-core-select class="margin-bottom-24" label="{{ ls.locs['loc'].Auswahl }}" [elements]="participantAddresses" textProperty="Strasse" [(model)]="participantAddress" (change)="participantAddress = $event; addressUpdate()" [mpRequired]="true"></mp-core-select>
      <h3>{{ ls.locs['loc'].Adresse }}</h3>
      <div class="row">
        <div class="grid col-large-6">
          <mp-core-input label="{{ ls.locs['loc'].FirmaInAdresse }}" [mpRequired]="true" mpId="firma" [(model)]="participantAddress['Firma']"></mp-core-input>
          <mp-core-select class="margin-top-16 display-block" label="{{ ls.locs['loc'].LKZ }}" [mpRequired]="true" [elements]="ddlData['Laenderliste']" textProperty="Land" [model]="participantAddress['Land']" (modelChange)="participantAddress['Land'] = $event; countryUpdate()" [hasError]="errorLKZ !== '' ? true : false"></mp-core-select>
          <mp-core-form-error *ngIf="errorLKZ" [model]="errorLKZ"></mp-core-form-error>
          <mp-core-select class="margin-top-16 display-block" label="{{ ls.locs['loc'].Anrede }}" [mpRequired]="true" [elements]="selectableSalutation" textProperty="Anrede" [model]="salutationObject" (modelChange)="salutationObject = $event; salutationUpdate()" [hasError]="errorSalutation !== '' ? true : false" [mpDisabled]="salutationDisabled" placeholder="{{ salutationDisabled ? ls.locs['loc'].BitteLandAuswaehlen : '' }}"></mp-core-select>
          <mp-core-form-error *ngIf="errorSalutation" [model]="errorSalutation"></mp-core-form-error>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Titel }}" [mpRequired]="true" mpId="titel" [(model)]="participantAddress['Titel']"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Name }}" [mpRequired]="true" mpId="name" [(model)]="participantAddress['Name']" [hasError]="errorName !== '' ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errorName" [model]="errorName"></mp-core-form-error>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Vorname }}" [mpRequired]="true" mpId="vorname" [(model)]="participantAddress['Vorname']" [hasError]="errorPrename !== '' ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errorPrename" [model]="errorPrename"></mp-core-form-error>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Strasse_Nr }}" [mpRequired]="true" mpId="strasse" [(model)]="participantAddress['Strasse']" [hasError]="errorStreet !== '' ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errorStreet" [model]="errorStreet"></mp-core-form-error>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Adresszusatz }}" mpId="adresszusatz" [(model)]="participantAddress['Adresszusatz']"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].PLZ }}" [mpRequired]="true" mpId="plz" [(model)]="participantAddress['PLZ']" [hasError]="errorZIP !== '' ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errorZIP" [model]="errorZIP"></mp-core-form-error>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Ort }}" [mpRequired]="true" mpId="ort" [(model)]="participantAddress['Ort']" [hasError]="errorCity !== '' ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errorCity" [model]="errorCity"></mp-core-form-error>
        </div>
        <div class="grid col-large-6">
          <mp-core-input label="{{ ls.locs['loc'].Telefon }}" mpId="telefon" [(model)]="phone" [mpDisabled]="true"></mp-core-input>
          <mp-core-input class="margin-top-16" label="{{ ls.locs['loc'].EMail }}" [mpRequired]="true" mpId="email" [(model)]="email" [mpDisabled]="true"></mp-core-input>
        </div>
      </div>
      <h3 class="margin-top-16">{{ ls.locs['loc'].Bestellung }}</h3>
      <div class="row">
        <div class="grid col-large-6">
          <table class="table">
            <thead>
              <tr>
                <th>{{ ls.locs['loc'].Artikelnummer }}</th>
                <th>{{ ls.locs['loc'].Bezeichnung }}</th>
                <th>{{ ls.locs['loc'].Anzahl }}</th>
                <th>{{ ls.locs['loc'].Preis }}</th>
                <th>{{ ls.locs['loc'].Punkte }}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of order">
                <td [innerText]="item['ArtNr']"></td>
                <td [innerText]="item['ArtBez']"></td>
                <td [innerText]="item['Anzahl']"></td>
                <td [innerText]="item['Preise'].VKString + ' €'"></td>
                <td [innerText]="item['Preise'].Punkte"></td>
                <td class="btn btn-danger fa fa-trash-o" (click)="removePosition(item['ID'])"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h3>{{ ls.locs['loc'].Artikel }}</h3>
      <div class="row">
        <div class="grid col-large-6">
          <mp-core-input label="{{ ls.locs['loc'].ArtNr }}" [mpRequired]="true" mpId="artnr" [(model)]="position['ArtNr']" (blur)="getArticleInfo()" [mpEnter]="getArticleInfoFunc" [hasError]="errorArtNo !== '' ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errorArtNo !== ''" [model]="errorArtNo"></mp-core-form-error>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].ArtBez }}" [mpRequired]="true" mpId="artbez" [(model)]="position['ArtBez']"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Anzahl }}" [mpRequired]="true" mpId="anzahl" [(model)]="position['Anzahl']"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Punkte }}" [mpRequired]="true" mpId="punkte" [(model)]="position['Preise'].Punkte"></mp-core-input>
        </div>
        <div class="grid col-large-6">
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].EVP }}" mpId="evp" [(model)]="position['Preise'].UVPString"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].VK_Admin }}" [mpRequired]="true" mpId="vkadmin" [(model)]="position['Preise'].VKString"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Versand }}" [mpRequired]="true" mpId="versand" [(model)]="position['Preise'].VersandString"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].Handling }}" [mpRequired]="true" mpId="handling" [(model)]="position['Preise'].HandlingString"></mp-core-input>
          <mp-core-input class="margin-top-16 display-block" label="{{ ls.locs['loc'].EVP }}" [mpRequired]="true" mpId="evp" [(model)]="position['Preise'].UVPString"></mp-core-input>
        </div>
        <p *ngIf="artMessage !== ''" class="inputRequired margin-top-4 width-full margin-bottom-0"><span [innerText]="artMessage" class="display-block margin-top-4"></span></p>
      </div>
      <button class="mp-button _prim"
              *ngIf="position['ArtNr'] || position['ArtBez'] || position['Preise'].Punkte || position['Preise'].VKString || position['Preise'].VersandString || position['Preise'].HandlingString || position['Preise'].UVPString" (click)="addPosition()">
        {{ ls.locs['loc'].PositionHinzufuegen }}
      </button>
      <div class="clearfix"></div>
      <div [hidden]="order.length === 0 ">
        <h3>{{ ls.locs['loc'].Abschliessen }}</h3>
        <button class="mp-button _prim" (click)="sendOrder()">{{ ls.locs['loc'].BestellungAusloesen }}</button>
      </div>
      <div class="clearfix"></div>
      <p></p>
    </div>
  </div>
</div>
