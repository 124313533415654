import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';


/**
 * This class provides the data and
 * functions for the points sharing
 * overview table.
 */
@Component({
  selector: 'mp-leas-points-sharing-overview',
  templateUrl: './points-sharing-overview.component.html'
})
export class PointsSharingOverviewComponent implements OnInit, OnDestroy {

  public pointsSharing: { [key: string]: any } = {};
  public editModalOpen: boolean = false;
  public closeEditModal = this._closeEditModal.bind(this);
  public savePointsSharing = this._savePointsSharing.bind(this);
  public excelService: string = '/api/punktebeteiligung/punktebeteiligungen2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'editPunktebeteiligung',
      func: this.editPointsSharing.bind(this)
    }
  ];

  private _loading: boolean = false;
  private _leasPointsSharingOverviewLoader: MpLoader = {
    name: 'leasPointsSharingOverviewLoader',
    params: {}
  };
  private _savePointsSharingSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._leasPointsSharingOverviewLoader);

    this._mpLoaderService.extendLoader('leasPointsSharingOverviewLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._savePointsSharingSubscription !== 'undefined') {
      this._savePointsSharingSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasPointsSharingOverviewLoader');
  }

  /**
   * Closes the edit modal.
   */
  private _closeEditModal(): void {
    this.editModalOpen = false;
  }

  /**
   * Saves the points sharing.
   */
  private _savePointsSharing(): void {
    if (this._loading) {
      return;
    }

    if (this._validate()) {
      const sendData = {
        Id: this.pointsSharing['Id'],
        GfProzent: this.pointsSharing['GfProzentWert'],
        VlProzent: this.pointsSharing['VlProzentWert']
      };

      this._savePointsSharingSubscription = this._apiService.postRequest('/api/Punktebeteiligung/savePunktebeteiligung', sendData).subscribe((data: any) => {
        this._loading = false;

        if (data.Result === 'ERROR') {
          this.pointsSharing['errors'].push(data.Message);
          this._mpMessaging.openDangerPanel(data.Message);
        } else {
          this.editModalOpen = false;
          this._mpMessaging.openSuccessPanel(data.Message);

          if (this.loader !== null) {
            if (typeof this.loader.load !== 'undefined') {
              this.loader.load();
            }
          }
        }
      },
      (error: any) => {
        this._loading = false;
      });
    }
  }

  /**
   * Openes the edit modal.
   */
  editPointsSharing(row: any): void {
    this.editModalOpen = true;
    this.pointsSharing = row;
    this.calculateVkPercentWorth();
  }

  /**
   * (Re)calculates percent worth of
   * the vk value.
   */
  calculateVkPercentWorth(): void {
    if (!this.pointsSharing || Object.keys(this.pointsSharing).length === 0)
      return;

    this.pointsSharing['VkProzentWertBerechnet'] = 100 - parseInt(this.pointsSharing['GfProzentWert']) - parseInt(this.pointsSharing['VlProzentWert']);
  }

  /**
   * Validates the points sharing
   * values.
   */
  private _validate(): boolean {
    if (!this.pointsSharing || Object.keys(this.pointsSharing).length === 0)
      return false;

    this.pointsSharing['errors'] = [];

    if (this.pointsSharing['GfProzentWert'] < 0 || this.pointsSharing['VlProzentWert'] < 0 || this.pointsSharing['VkProzentWertBerechnet'] < 0)
      this.pointsSharing['errors'].push(this.ls.locs['locLeas'].PunkteverteilungWenigerAlsNullProzent);

    if (this.pointsSharing['VkProzentWertBerechnet'] + parseInt(this.pointsSharing['GfProzentWert']) + parseInt(this.pointsSharing['VlProzentWert']) > 100)
      this.pointsSharing['errors'].push(this.ls.locs['locLeas'].PunkteverteilungMehrAlsHundertProzentVerteilt);

    return this.pointsSharing['errors'].length === 0;
  }

}
