import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { ApiService } from '@core/services/api.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';

/**
 * This class provides the functions
 * for the rental details.
 */
@Component({
  selector: 'mp-rk-rental-details',
  templateUrl: './rental-details.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RentalDetailsComponent implements OnInit, OnDestroy {

  @Input() public show: boolean = false;
  @Input() public rental: { [key: string]: any } = {};
  @Input() public locations: Array<any> = [];
  @Input() public otherDropOffLocation: boolean = false;

  public tabCounter: number = 0;
  public tabIndex: number = 1;

  private _getInfoTextSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _svgLoader: SvgLoaderService,
    private _apiService: ApiService,
    private _customEventServicee: CustomEventService
  ) { }

  /**
   * Gets the info text and the
   * locations.
   */
  ngOnInit(): void {
    this._loadInfotext();
    this._getLocations();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getInfoTextSubscription !== 'undefined') {
      this._getInfoTextSubscription.unsubscribe();
    }
  }

  /**
   * Loads the info text for the rental.
   */
  private _loadInfotext(): void {
    if (!this.rental['infoText'] && this.show) {
      this._svgLoader.startLoading();
      this._customEventServicee.dispatch(new CustomEvent(CustomEventType.ShowSvgLoader, true));

      this._getInfoTextSubscription = this._apiService.postRequest('/api/RkMietwagen/GetInfoText', this.rental).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.rental['infoText'] = data.Records[0];
        }

        this._customEventServicee.dispatch(new CustomEvent(CustomEventType.HideSvgLoader, true));
        this._svgLoader.finishLoading();
      },
      (error: any) => {
        this._customEventServicee.dispatch(new CustomEvent(CustomEventType.HideSvgLoader, true));
        this._svgLoader.finishLoading();
      });
    }
  }

  /**
   * Gets the locations.
   */
  private _getLocations(): void {
    if ((!this.rental['StandortAbholung'] || !this.rental['StandortRueckgabe']) && this.show) {
      const kombi = this.rental['Standorte'][0];

      if (!kombi)
        return;

      this.rental['StandortAbholung'] = this.locations.find((location: any) => { return location['Code'] === kombi['pickUp'].geoZoneCode });
      this.rental['StandortRueckgabe'] = this.locations.find((location: any) => { return location['Code'] === kombi['dropOff'].geoZoneCode });
    }
  }

  /**
   * Watches for changes of show.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['show'] !== 'undefined' && typeof changes['show'].currentValue !== 'undefined' && typeof changes['show'].previousValue !== 'undefined') {
      if (changes['show'].currentValue !== changes['show'].previousValue) {
        this._loadInfotext();
        this._getLocations();
      }
    }
  }

}
