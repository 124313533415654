import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the grouping overview
 * details table.
 */
@Component({
  selector: 'mp-core-grouping-overview-details',
  templateUrl: './grouping-overview-details.component.html'
})
export class GroupingOverviewDetailsComponent implements OnInit, OnDestroy {

  public groupingLoaded: boolean = false;
  public grouping: { [key: string]: any } = {};
  public groupingId: number = -1;
  public showAddOverlay: boolean = false;

  public excelService: string = '/api/Gruppierung/GetGruppierungsuebersichtDetails2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public toggleAddOverlay = this._toggleAddOverlay.bind(this);
  public addMember = this._addMember.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'toggleStatus',
      func: this.toggleStatus.bind(this)
    },
  ];

  private _managerGroupingOverviewDetailsLoader: MpLoader = {
    name: 'managerGroupingOverviewDetailsLoader',
    params: {}
  };
  private _getQueryParamsSubscription: Subscription | undefined;
  private _getGroupingSubscription: Subscription | undefined;
  private _toggleStatusSubscription: Subscription | undefined;
  private _addMemberSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader and gets the
   * id from the query params.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.groupingId = params['id'];
      }

      this._mpLoaderService.registerLoader(this._managerGroupingOverviewDetailsLoader);

      this._mpLoaderService.extendLoader('managerGroupingOverviewDetailsLoader', {
        isReady: true,
        params: {
          Id: this.groupingId
        }
      });

      this._getGroupingSubscription = this._apiService.getRequest(`/api/Gruppierung/getGroupingInfo?id=${this.groupingId}`).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.grouping = data.Records[0];
          this.groupingLoaded = true;
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._getGroupingSubscription !== 'undefined') {
      this._getGroupingSubscription.unsubscribe();
    }

    if (typeof this._toggleStatusSubscription !== 'undefined') {
      this._toggleStatusSubscription.unsubscribe();
    }

    if (typeof this._addMemberSubscription !== 'undefined') {
      this._addMemberSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('managerGroupingOverviewDetailsLoader');
  }

  /**
   * Loads the data for the table.
   */
  private _doData(): void {
    if (this.loader !== null) {
      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Toggles the status of the given
   * grouping.
   */
  toggleStatus(row: { [key: string]: any }): void {
    const data = {
      GroupingId: this.groupingId,
      MemberId: row['Id'],
      CurrentState: row['Aktiviert']
    };

    this._toggleStatusSubscription = this._apiService.postRequest('/api/Gruppierung/toggleGruppierungDetails', data).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openPanelFromResultResponse(data);
        this._doData();
      }
    });
  }

  /**
   * Toggles the add member modal.
   */
  private _toggleAddOverlay(): void {
    this.grouping['MemberId'] = '';
    this.showAddOverlay = !this.showAddOverlay;
  }

  /**
   * Adds the member to the grouping.
   */
  private _addMember(): void {
    const data = {
      MemberIdentifier: this.grouping['MemberId'],
      GroupingId: this.groupingId
    };

    this._addMemberSubscription = this._apiService.postRequest('/api/Gruppierung/addMember', data).subscribe((data: any) => {
      this._mpMessaging.openPanelFromResultResponse(data);

      if (data.Result === 'OK') {
        this._doData();
        this._toggleAddOverlay();
      }
    },
    (error: any) => {
      this._mpMessaging.openWarningPanel(error);
    });
  }

}
