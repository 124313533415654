import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../services/mp-localization.service';
import { AuthService } from './../../../services/auth.service';
import { RoleMappingService } from './../../../services/role-mapping.service';

@Component({
  selector: 'mp-core-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryCardComponent implements OnInit {

  @Input() public category: any;

  public locs: any;
  public katLink: string = '';
  public role: string = '';

  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
    ) { }

  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    if (Object.keys(this.ls.locs).length > 0) {
      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.locs = this.ls.locs;

          if (typeof this._getLocsSubscription !== 'undefined') {
            this._getLocsSubscription.unsubscribe();
          }
        }
      });
      this.ls.getLocalization();
    }

    this.katLink = '#/Shop/' + this.category.KategorieID;
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }
}
