import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { AuthService } from '@core/services/auth.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { TipAndWinService } from './../../services/tip-and-win.service';

/**
 * This class provides the functions and
 * data for the ranking.
 */
@Component({
  selector: 'mp-taw-tip-and-win-ranking',
  templateUrl: './tip-and-win-ranking.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinRankingComponent implements OnInit, OnDestroy {

  public showAllGamblers: boolean = false;
  public role: string = '';
  public wideRanking: boolean = true;
  public gamblers: Array<any> = [];
  public allGamblers: Array<any> = [];
  public setShowAllGamblersFalse = this._setShowAllGamblersFalse.bind(this);

  private _getRankingSubscription: Subscription | undefined;
  private _getFullRankingSubscription: Subscription | undefined;
  private _updateRankingSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _roleMapping: RoleMappingService,
    private _authService: AuthService,
    private _svgLoader: SvgLoaderService,
    private _tipAndWinService: TipAndWinService,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Gets the role. Sets subscription to
   * update the ranking, if needed.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._getRanking();

    this._updateRankingSubscription = this._tipAndWinService.rankingUpdated.subscribe((update: boolean) => {
      if (update) {
        this._getRanking();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getRankingSubscription !== 'undefined') {
      this._getRankingSubscription.unsubscribe();
    }

    if (typeof this._getFullRankingSubscription !== 'undefined') {
      this._getFullRankingSubscription.unsubscribe();
    }

    if (typeof this._updateRankingSubscription !== 'undefined') {
      this._updateRankingSubscription.unsubscribe();
    }
  }

  /**
   * Sets showAllGamblers to false (closes)
   * the modal.
   */
  private _setShowAllGamblersFalse(): void {
    this.showAllGamblers = false;
  }

  /**
   * Openes the details for mobile.
   */
  openMobileRow(gambler: { [key: string]: any }): void {
    if (window.innerWidth < 768) {
      this.allGamblers.forEach((gamblerFromAll: { [key: string]: any }) => {
        if (gambler !== gamblerFromAll) {
          gamblerFromAll['mobileRowOpened'] = false;
        }
      });

      gambler['mobileRowOpened'] = !gambler['mobileRowOpened'];
    }
  }

  /**
   * Gets teh ranking from the database.
   */
  private _getRanking(): void {
    this._svgLoader.startLoading(2);

    this._getRankingSubscription = this._apiService.getRequest(`/api/TipAndWin/GetRangliste?aktion=${this._tipAndWinService.campaign}&linesToShow=11`).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (data.Records.length > 0) {
          this.gamblers = data.Records;
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      this._svgLoader.finishLoading(1);
    },
    (error: any) => {
      this._svgLoader.finishLoading(1);
      this._mpMessaging.openErrorPanel(error);
    });

    this._getFullRankingSubscription = this._apiService.getRequest(`/api/TipAndWin/GetRangliste?aktion=${this._tipAndWinService.campaign}`).subscribe((data: any) => {
      this.allGamblers = [];

      if (data.Result === 'OK') {
        if (data.Records.length > 0) {
          this.allGamblers = data.Records;

          this.allGamblers.forEach((gambler: { [key: string]: any }) => {
            gambler['mobileRowOpened'] = false;
          });
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      this._svgLoader.finishLoading(1);
    },
    (error: any) => {
      this._svgLoader.finishLoading(1);
      this._mpMessaging.openErrorPanel(error);
    });
  }

}
