<div id="main" class="position-relative" layout-extern *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <header id="header"></header>
  <div id="main-content" class="overflow-hidden">
    <router-outlet name="mainContent"></router-outlet>
  </div>
  <footer id="footer">
    <div class="layout-inner position-container">
      <div id="company-address" [innerHtml]="ls.locs['loc'].FooterText"></div>
      <div id="imprint">
        <a *ngIf="mpSettings.settings['DisplaySettings'].ShowSpielregelnInFooter" href="#/Spielregeln">{{ ls.locs['loc'].Spielregeln }}</a>
        <span *ngIf="mpSettings.settings['DisplaySettings'].ShowSpielregelnInFooter"> | </span>

        <a *ngIf="mpSettings.settings['DisplaySettings'].ShowCookiesInFooter" (click)="cookieService.openCookieConsentBanner()">{{ ls.locs['loc'].CookieSettings }}</a>
        <span *ngIf="mpSettings.settings['DisplaySettings'].ShowCookiesInFooter" class="margin-left-right-4"> | </span>

        <a href="https://www.leaseplan.com/de-de/impressum/" target="_blank">{{ ls.locs['loc'].Impressum }}</a>
      </div>
    </div>
  </footer>
  <div id="shadow" [ngClass]="{open: hamburgerOpen || mpSidebar.visible}"></div>
  <div id="sb-overlay-shadow" [ngClass]="{open: mpSidebar.visible}"></div>
  <div mpCoreSidebar #sidebar="mpCoreSidebar" id="sidebar" class="sb-overlay sb-overview-sidebar" [ngClass]="{open: sidebar.mpSidebar.visible}">
    <div id="sidebar-inner-scroll">
      <div id="sidebar-content">
        <ng-container *ngComponentOutlet="sidebar.contentComponent"></ng-container>
      </div>
    </div>
    <div id="sidebar-close" class="floating-container">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].Schliessen }}" alignment="left">
        <button class="mp-button padding-left-right-8 float-right" (click)="sidebar.mpSidebar.close()">
          <i class="svg-icon __clr-default __size-18 __top-0 __clear"></i>
        </button>
      </mp-core-tooltip>
    </div>
  </div>
  <mp-core-browser-support-modal></mp-core-browser-support-modal>
  <div mpCoreMpAntiForgery></div>
</div>
<mp-core-cookie-consent></mp-core-cookie-consent>
