import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Event, NavigationStart } from '@angular/router';

import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpMetaService } from '@core/services/mp-meta.service';
import { MpTitleService } from '@core/services/mp-title.service';
import { ApiService } from '@core/services/api.service';
import { MatomoService } from '@core/services/matomo.service';
import { CookieService } from '@core/services/cookie.service';

/**
 * This class provides data and
 * functions for the main adm
 * component.
 */
@Component({
  selector: 'mp-leas-adm',
  templateUrl: './adm.component.html',
  styleUrls: [
    './adm.component.scss',
    './../../../../mp.Core/app/modules/manager/manager.component.scss',
    './../../../../mp.Core/app/components/input/styles/input.scss',
    './../../../../mp.Core/app/components/sidebar/styles/sidebar.scss',
    './../../../../mp.Core/app/components/sidebar/styles/sidebar-extend.scss',
    './../../../../mp.Core/app/components/card/styles/card.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AdmComponent implements OnInit, OnDestroy {

  public hamburgerOpen: boolean = false;
  public mainMenuItems: Array<any> = [];
  public userMenuItems: Array<any> = [];
  public userMenuOpen: boolean = false;
  public navbarIsSticky: boolean = false;

  private _admData: { [key: string]: any } = {};
  private _apiAdmSubscription: Subscription | undefined;
  private _settingsSubscription: Subscription | undefined;
  private _routeChangeSubscription: Subscription | undefined;
  private _locsSubscription: Subscription | undefined;
  private _apiMenuSubscription: Subscription | undefined;

  constructor(
    public mpSidebar: MpSidebarService,
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    public cookieService: CookieService,
    private _mpMetaService: MpMetaService,
    private _titleService: MpTitleService,
    private _apiService: ApiService,
    private _router: Router,
    private _matomoService: MatomoService
  ) { }

  /**
   * Triggers the functions to set meta tags,
   * and some other 'head'-tags, gets menus.
   */
  ngOnInit(): void {
    this._settingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        if (typeof this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== 'undefined' && this.mpSettings.settings['NetcentiveCoreSettings'].Shopname !== null) {
          this._titleService.setTitle(this.mpSettings.settings['NetcentiveCoreSettings'].Shopname);
        } else {
          this._titleService.setTitleFromLoc('VL');
        }

        if (this.mpSettings.settings['NetcentiveCoreSettings'].EnablePiwik) {
          this._matomoService.setMatomo();
        }

        if (typeof this._settingsSubscription !== 'undefined') {
          this._settingsSubscription.unsubscribe();
        }
      }
    });

    if (this.ls.locs['length'] > 0) {
      this.ls.getLocalization();
    }

    this._locsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.mainMenuItems = [
          {
            text: this.ls.locs['loc'].Home,
            href: '#/adm/Home',
            children: [],
            ShowOnDesktop: true,
            ShowOnMobile: true
          },
          {
            text: this.ls.locs['loc'].Verwaltung,
            subWide: false,
            children: [
              {
                text: this.ls.locs['loc'].Teilnehmerverwaltung,
                href: '#/adm/Teilnehmerverwaltung'
              }
            ],
            ShowOnDesktop: true,
            ShowOnMobile: true
          },
          {
            text: this.ls.locs['loc'].Statistik,
            subWide: false,
            children: [
              {
                text: this.ls.locs['loc'].TeilnehmerRanking,
                href: '#/adm/TeilnehmerRanking'
              },
              {
                text: this.ls.locs['locLeas'].ProduktStatistikAnzahl,
                href: '#/adm/ProduktStatistik'
              }
            ],
            ShowOnDesktop: true,
            ShowOnMobile: true
          }
        ];

        if (typeof this._locsSubscription !== 'undefined') {
          this._locsSubscription.unsubscribe();
        }
      }
    });

    this._apiAdmSubscription = this._apiService.postRequest('/api/Teilnehmer/getTN', {}).subscribe((data: any) => {
      this._admData = data.Records[0];
      this._setUserMenuItems();
    },
    (error: any) => {
      console.log(error.message);
    });

    this._mpMetaService.setBasicTags();
    this._mpMetaService.setFavicon();
    this._mpMetaService.setLinkTag({
      href: '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css',
      rel: 'stylesheet',
      type: 'text/css'
    });

    this._routeChangeSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.hamburgerOpen = false;
        this.navbarIsSticky = false;
      }
    });

    window.addEventListener('scroll', this._handleNav.bind(this), false);
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._settingsSubscription !== 'undefined') {
      this._settingsSubscription.unsubscribe();
    }

    if (typeof this._apiAdmSubscription !== 'undefined') {
      this._apiAdmSubscription.unsubscribe();
    }

    if (typeof this._routeChangeSubscription !== 'undefined') {
      this._routeChangeSubscription.unsubscribe();
    }

    if (typeof this._locsSubscription !== 'undefined') {
      this._locsSubscription.unsubscribe();
    }
  }

  /**
   * Opens the hambuerger menu.
   */
  openHamburger(): void {
    this.hamburgerOpen = true;
  }

  /**
   * Closes the hamburger and user menu.
   */
  closeHamburger(): void {
    this.hamburgerOpen = false;
    this.userMenuOpen = false;
  }

  /**
   * Sets the data for the user menu.
   */
  private _setUserMenuItems(): void {
    if (Object.keys(this._admData).length > 0) {
      this.userMenuItems = [
        {
          text: `${this._admData['Vorname']} ${this._admData['Name']}`,
          hasLogout: true,
          children: []
        }
      ];
    }
  }

  /**
   * Handles the stick / visiblity behavior of the
   * navbar, when the user scrolls the page up / down.
   */
  private _handleNav(): void {
    const scrollTop = window.scrollY;
    const navbar = document.getElementById('navbar');
    const header = document.getElementById('header');
    const headerHeight = header !== null ? header.getBoundingClientRect().height : 0;

    if (headerHeight < scrollTop && navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv === null) {
        const divElem = document.createElement('div');
        divElem.style.height = navbar.getBoundingClientRect().height + 'px';
        divElem.id = 'sticky-ghost-div';
        navbar.insertAdjacentElement('afterend', divElem);
      }

      if (!navbar.classList.contains('fix')) {
        navbar.classList.add('fix');
      }

      if (!navbar.classList.contains('hl-sticky')) {
        navbar.classList.add('hl-sticky');
      }

      this.navbarIsSticky = true;
    } else if (navbar !== null) {
      const ghostDiv = document.getElementById('sticky-ghost-div');

      if (ghostDiv !== null) {
        ghostDiv.remove();
      }

      if (navbar.classList.contains('fix')) {
        navbar.classList.remove('fix');
      }

      if (navbar.classList.contains('hl-sticky')) {
        navbar.classList.remove('hl-sticky');
      }

      this.navbarIsSticky = false;
    }

    const filterNavBaseline = document.querySelector('.filter-nav-baseline');
    const filterNav = document.querySelector('.filter-nav');

    if (filterNav !== null) {
      if (filterNav.classList.contains('no-header')) {
        if (headerHeight < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      } else {
        const mainNav = document.querySelector('.mainNav');
        const mainNavHeight = mainNav !== null ? mainNav.getBoundingClientRect().height : 0;

        if (filterNavBaseline !== null && filterNavBaseline.getBoundingClientRect().top - mainNavHeight - filterNav.getBoundingClientRect().height < scrollTop) {
          if (!filterNav.classList.contains('fix')) {
            filterNav.classList.add('fix');
          }

          if (navbar !== null) {
            if (!navbar.classList.contains('shop')) {
              navbar.classList.add('shop');
            }
          }
        } else {
          if (filterNav.classList.contains('fix')) {
            filterNav.classList.remove('fix');
          }

          if (navbar !== null) {
            if (navbar.classList.contains('shop')) {
              navbar.classList.remove('shop');
            }
          }
        }
      }
    }
  }

}
