<div id="sidebar-calendar" class="sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="texst-align-left">{{mpSidebar.params['Headline']}}</div>
    <div class="divider"></div>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#calender-sb-scroll-area" scrollDirection="up" scrollFactor="1" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container" id="calender-sb-scroll-area-container" [ngClass]="{reducedContainer: sliderOptions && selection['Reisedauer'].id === 3}">
      <div id="calender-sb-scroll-area" class="position-relative">
        <div>
          <mp-core-calendar [(minDate)]="mpSidebar.params['minDate']" [(rangeStart)]="selection['rangeStart']" [(rangeEnd)]="selection['rangeEnd']" [isRange]="mpSidebar.params['isRange']" [(minRange)]="selection['MinDays']" [(maxRange)]="maxRange"></mp-core-calendar>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#calender-sb-scroll-area" scrollDirection="down" scrollFactor="1" class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].AenderungenUebernehmen }}</a>
    </div>
  </div>
</div>
