<div id="sidebar-airport"  class="rk-sidebar-form" [ngClass]="{noResultsMessage: noResults}" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="text-align-left">{{mpSidebar.params['header']}}</div>
    <div class="overflow-hidden">
      <mp-core-search mpId="flughafen-suche" [model]="selection['Suchbegriff']" (modelChange)="selection['Suchbegriff'] = $event; searchTermChange()" class="sb-search padding-16 display-block" mpPlaceholder="{{ ls.locs['locReisekonfigurator'].SucheBestimmterFlughafen }}" [focusOn]="focusOnInit">
        <i class="position-absolute-top-right svg-icon __clr-default __wide __top-0 __search"></i>
        <i [hidden]="!selection['Suchbegriff'] || selection['Suchbegriff'] && selection['Suchbegriff'].length <= 2" mpCoreScrollButtonRerenderer data-scroll-area="#flughafen-scroll-area" class="svg-icon __clr-default __size-18 __top-8 __clear position-absolute-top-right margin-right-48 margin-top-16" (click)="clearSearch($event)"></i>
      </mp-core-search>
    </div>
    <div [hidden]="!selection['flughaefen'] || selection['flughaefen'].length === 0 " class="padding-16" id="selected-airports">
      <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">
        {{ ls.locs['locReisekonfigurator'].IhreAusgewaehltenFlughafen }}
      </h3>
      <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open" *ngFor="let item of selection['flughaefen']">
        <span class="icon-text">{{item.Bezeichnung}} ({{item.Kuerzel}})</span>
        <span class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8" (click)="setAirport(item)"></span>
      </div>
    </div>
    <div class="divider margin-bottom-8 margin-left-16" [hidden]="!selection['flughaefen'] || selection['flughaefen'].length === 0 "></div>
    <mp-core-info-notification [hidden]="!noResults" iconPosition="left" infoState="warning" headline="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenTitel }}" copyText="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenText }}" class="padding-left-right-16"></mp-core-info-notification>
    <mp-core-info-notification [hidden]="!noSelection" iconPosition="left" infoState="warning" headline="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenTitel }}" copyText="{{ ls.locs['locReisekonfigurator'].FlughafenWaehlenText }}" class="padding-left-right-16"></mp-core-info-notification>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#flughafen-scroll-area" scrollDirection="up" scrollFactor="1" [scopeObject]="countries" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container" id="flughafen-scroll-area-container">
      <div id="flughafen-scroll-area" class="sidebar-scroll-area position-relative" [hidden]="(!selection['Suchbegriff'] || selection['Suchbegriff'].length <= 2) && !mpSidebar.params['AbflughaefenOnLoad']">
        <div *ngFor="let country of countries" class="margin-top-24 repeat-countries">
          <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{country.Land}}</h3>
          <span class="display-block display-flex margin-bottom-4" [ngClass]="airport.active ? 'airport-active' : ''" *ngFor="let airport of country.Flughaefen" (click)="setAirport(airport)">
            <span class="svg-icon __clr-info __wide-26 __top--2 __flight-takeoff margin-right-4"></span>
            <span mpCoreHighlight #highlightDirective="mpCoreHighlight" highlight="{{ selection['Suchbegriff'] }}" originalText="{{ airport.Text }}" [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : airport.Text"></span>
          </span>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#flughafen-scroll-area" scrollDirection="down" scrollFactor="1" [scopeObject]="countries" class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">
        {{ ls.locs['loc'].AenderungenUebernehmen }}
      </a>
    </div>
  </div>
</div>
