<section id="contact" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div [hidden]="errorMessages.length === 0" class="alert alert-danger margin-bottom-24">
    <ul>
      <li *ngFor="let message of errorMessages">
        <span>{{message}}</span>
      </li>
    </ul>
  </div>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowFaqThemenInKontaktformular"
         class="margin-bottom-40">
      <h3>{{ ls.locs['loc'].KontaktThema }}</h3>
      <mp-core-faq [choice]="contact['choiceOfTopics']"
           (choiceChange)="contact['choiceOfTopics'] = $event; changeChoice();"
           [ignoreBreadcrumbs]="true"
           [hideSearchbox]="true"
           [showEmpty]="true"
           [showRequired]="true"
           [showPlaceholder]="true"
           [errors]="errors"
           [excludeFAQText]="true"></mp-core-faq>
    </div>
  <div class="margin-bottom-24" *ngIf="dataLoaded">
    <form name="contactForm">
      <div id="contactConcern" class="anliegen margin-bottom-24">
        <h3>{{ ls.locs['loc'].KontaktAnliegen }}</h3>
        <div class="contactForm margin-top-4">
          <div *ngIf="!showWithoutTopicFields">
            <div [hidden]="ddl.length <= 0 || contact['choiceOfTopics'].ThemaID !== 1 && contact['choiceOfTopics'].ThemaID !== 3" class="data form form-inline select-bestellreklamation">
              <div class="margin-bottom-24">

                <mp-core-select label="{{ ls.locs['loc'].Bestellung }}"
                                mpId="bestellung"
                                [(model)]="data['BestellNr']"
                                (ngChange)="data['BestellNr'] = $event; changeOrderData();"
                                [elements]="ddl"
                                valueProperty="BestellNr"
                                textProperty="Text"
                                [hasError]="errors['data.BestellNr'] ? true : false"
                                [mpRequired]="true">
                </mp-core-select>
                <mp-core-form-error *ngIf="errors['data.BestellNr']" [model]="errors['data.BestellNr']"></mp-core-form-error>
              </div>
              <div class="margin-bottom-24">
                <mp-core-select label="{{ ls.locs['loc'].WelchePraemie }}"
                                mpId="praemie"
                                [editable]="true"
                                [elements]="orderPos"
                                valueProperty="BestellTAN"
                                textProperty="Text"
                                [(model)]="orderTAN"
                                (modelChange)="orderTAN = $event; selectOrderTAN();"
                                [hasError]="errors['data.BestellTAN'] ? true : false"
                                [mpRequired]="true">
                </mp-core-select>
                <mp-core-form-error *ngIf="errors['bestellung.BestellTAN']" [model]="errors['bestellung.BestellTAN']"></mp-core-form-error>
              </div>
              <span [hidden]="ddl.length > 0 || contact['choiceOfTopics'].ThemaID !== 1 && contact['choiceOfTopics'].ThemaID !== 3">{{ ls.locs['loc'].KeineBestellungenGefunden }}</span>
            </div>
              <div *ngIf="mpSettings.settings['DisplaySettings'].ShowGeschaeftsnummernInKontakt" [hidden]="contact['choiceOfTopics'].ThemaID !== 2" class="data form-inline">
                <div class="margin-bottom-24">
                  <mp-core-select label="{{ ls.locs['loc'].Geschaeftsnummer }}"
                                  mpId="geschaeftsnummer"
                                  [editable]="true"
                                  [elements]="businessnumbers"
                                  textProperty="data['Geschaeftsnummer']"
                                  valueProperty="data['Geschaeftsnummer']"
                                  [(model)]="data['geschaeftsnummer']"
                                  [hasError]="errors['data.Geschaeftsnummer'] ? true : false">
                  </mp-core-select>
                  <mp-core-form-error *ngIf="errors['data.Geschaeftsnummer']" [model]="errors['data.Geschaeftsnummer']"></mp-core-form-error>
                </div>
              </div>
            <div [hidden]="contact['choiceOfTopics'].ThemaID === null || contact['choiceOfTopics'].ThemaID !== null && ([4,5].indexOf(contact['choiceOfTopics'].ThemaID) === -1)" class="data form-inline">
              <p for="textfeld" [hidden]="contact['choiceOfTopics'].ThemaID === null || contact['choiceOfTopics'].ThemaID !== null && ([4,5].indexOf(contact['choiceOfTopics'].ThemaID) === -1)">{{ ls.locs['loc'].Supporttool_Kontaktform_Artikelnr_angeben }}  </p>
              <div class="margin-bottom-16">
                <mp-core-input [(model)]="data['Artikelnummer']" mpId="textfeld" label="{{ ls.locs['loc'].ArtNr }}" [hasError]="errors['data.Artikelnummer'] ? true : false"></mp-core-input>
                <mp-core-form-error *ngIf="errors['data.Artikelnummer']" [model]="errors['data.Artikelnummer']"></mp-core-form-error>
              </div>
            </div>
          </div>
          <div class="margin-bottom-16">
            <mp-core-input mpType="text" label="{{ ls.locs['loc'].Betreff }}" [(model)]="data['Subject']" mpId="betreff" [hasError]="errors['data.Subject'] ? true : false" [mpRequired]="true"></mp-core-input>
            <mp-core-form-error *ngIf="errors['data.Subject']" [model]="errors['data.Subject']"></mp-core-form-error>
          </div>
          <div class="margin-bottom-24">
            <mp-core-textarea label="{{ ls.locs['loc'].Nachricht }}"
                              mpId="message"
                              [(model)]="data['Body']"
                              [hasError]="errors['data.Body'] ? true : false"
                              [mpRequired]="true">
            </mp-core-textarea>
            <mp-core-form-error *ngIf="errors['data.Body']" [model]="errors['data.Body']"></mp-core-form-error>
          </div>
        </div>
      </div>
      <div id="contactCheck" class="ueberpruefen"></div>
      <h3 class="margin-top-40">{{ ls.locs['loc'].KontaktUeberpruefen }}</h3>
      <div class="contactForm" id="contactData">
        <div class="margin-bottom-16">
          <mp-core-input mpType="text" label="{{ ls.locs['loc'].EMail }}" [(model)]="data['EMail']" mpId="dataEMail" [mpRequired]="true" [hasError]="errors['data.EMail'] ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errors['data.EMail']" [model]="errors['data.EMail']"></mp-core-form-error>
        </div>
        <div class="margin-bottom-16">
          <mp-core-input mpType="text" label="{{ ls.locs['loc'].Telefon }}" [(model)]="data['Telefon']" mpId="dataTelefon" [mpRequired]="true" [hasError]="errors['data.Telefon'] ? true : false"></mp-core-input>
          <mp-core-form-error *ngIf="errors['data.Telefon']" [model]="errors['data.Telefon']"></mp-core-form-error>
        </div>

        <button type="submit" (click)="send()" class="mp-button _prim margin-top-32">{{ ls.locs['locButtons'].AnfrageAbsenden }}</button>
      </div>
    </form>
  </div>

    <h1 *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline">{{ ls.locs['loc'].RufenSieUnsAn }}</h1>
    <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" id="serviceText">
      <span>{{ ls.locs['loc'].Supporttool_telefonisch_erreichbar }}</span>
    </div>

      <h1 *ngIf="mpSettings.settings['DisplaySettings'].ShowKontaktAdresse && (!mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer || mpSettings.settings['DisplaySettings'].HideHotline)">{{ ls.locs['loc'].Anschrift }}</h1>
    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowKontaktAdresse" id="serviceAdresse" class="margin-top-20">
      <span [innerHTML]="ls.locs['loc'].KontaktAdresse"></span>
    </div>


    <span *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" 
          class="bigFont margin-top-12">{{ mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer }}</span>
    <div *ngIf="!showWithoutServiceTextAdditional && mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" [innerHTML]="ls.locs['loc'].ServiceTextZusatz"></div>
    <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline" class="contactServiceTimes">
      <span class="font-size-xxs">{{ ls.locs['loc'].Supporttool_Zeiten }}</span>
    </div>
    <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline"
         class="contactServiceTimes">
      <span class="font-size-xxs">{{ ls.locs['loc'].SupportKosten }}</span>
    </div>
</section>
