<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].TestuserVerwaltung }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].TestuserVerwaltung }}"
                      infotextShorten="TestuserVerwaltung"
                      fieldsAction="/api/EMailModul/getTestuserFields"
                      listAction="/api/EMailModul/getTestuser"
                      loaderName="leasTestuserManagementLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [execClickFunctions]="execClickFunctions"
                      [paging]="false"
                      [footer]="false"
                      defaultSorting="Email"
                      [disableCache]="true">
  </mp-core-data-table>
  <div class="floating-container margin-top-24">
    <button class="mp-button _prim" (click)="loadTestuser()">Testuser hinzufügen</button>
  </div>
  <mp-core-modal [show]="showConfirmOverlay" title="Testuser löschen" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: closeEditModal}, {text: 'Löschen', action: deleteTestuser}]">
    <mp-core-input label="Testuser" mpId="marke-modal" [model]="email" [mpDisabled]="true"></mp-core-input>
  </mp-core-modal>
  <mp-core-modal [show]="showSelectOverlay" modalClass="reactivation" title="Testuser hinzufügen" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: closeEditModal}, {text: 'hinzufügen', action: addTestuser}]">
    <mp-core-select label="Testuser" mpId="testuser-auswahl" [(model)]="changeData['Testuser']" textProperty="Email" [elements]="testuserList" [mpRequired]="true"></mp-core-select>
  </mp-core-modal>
</div>
