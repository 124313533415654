<div *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <ul class="tab pointer">
    <li class="text-transform-uppercase bg-color-brand-primary padding-left-16 padding-right-16">{{ ls.locs['loc'].Filter }}</li>
  </ul>
  <div class="side-filter margin-bottom-8 padding-left-right-24 padding-top-24 padding-bottom-0 display-flex flex-direction-column">
    <div class="flex-grid flex-direction-row">
      <div class="bold flex-col-12">{{ ls.locs['locReisekonfigurator'].Flugkategorie }}</div>
      <div class="flex-col-6 flex-col-6-l" *ngFor="let klasse of options['Klassen']; let index = index">
        <mp-core-checkbox mpId="chkFlugklasse{{filterId}}{{index}}" [model]="klasse.Active" (modelChange)="klasse.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12" for="chkFlugklasse{{filterId}}{{index}}">{{klasse.DisplayText}}</label>
      </div>
    </div>
    <hr class="margin-top-0 margin-bottom-24">
    <div class="flex-grid flex-direction-row flex-wrao">
      <div class="bold flex-col-12">{{ ls.locs['locReisekonfigurator'].Zwischenstopps }}</div>
      <mp-core-select class="display-block margin-left-0" mpId="rk-filter-zwischenstopps{{filterId}}" label="" [elements]="options['Stops']" textProperty="DisplayText" [model]="tempFilter['anzahlStops']" (modelChange)="tempFilter['anzahlStops'] = $event; optionClicked()"></mp-core-select>
    </div>
    <hr class="margin-top-8 margin-bottom-24">
    <div class="flex-grid flex-direction-column">
      <div class="padding-left-8 padding-right-8">
        <div class="bold display-inline-block">{{ ls.locs['locReisekonfigurator'].Gesamtbudget }}</div>
        <div class="text display-inline-block text-color-info">
          {{ ls.locs['loc'].BisMax }} {{filter['Punkte'] | integer}} {{ ls.locs['loc'].WaehrungPunkte }}
        </div>
      </div>
      <div class="form-group padding-left-8 padding-right-8" *ngIf="rangeSliderConfigDone">
        <ngx-slider class="mp-ngx-slider"
                    [(value)]="filterPoints"
                    [options]="rangeSliderOptions"
                    (userChangeEnd)="filterClicked(false, $event)">
        </ngx-slider>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasPunktekonto" class="filter-checkbox display-flex">
          <mp-core-checkbox mpId="chkBestellbareReisen{{filterId}}" [model]="orderableTravels" (modelChange)="orderableTravels = $event; onlyOrderableTravels(); filterClicked(false);"></mp-core-checkbox>
          <label [innerHTML]="ls.locs['locReisekonfigurator'].NurFluegeImPunktebereich" class="font-size-s margin-top-4" for="chkBestellbareReisen{{filterId}}"></label>
        </div>
      </div>
    </div>
    <hr class="margin-top-8 margin-bottom-24">
    <div class="flex-grid flex-direction-column">
      <div class="padding-left-8 padding-right-8">
        <div class="bold display-block">{{ ls.locs['locReisekonfigurator'].Flugzeiten }}</div>
      </div>
      <div class="font-size-s padding-left-8 padding-right-8">
        <div class="display-inline-block">{{ ls.locs['locReisekonfigurator'].Hinflug }}</div>
        <div class="text display-inline-block text-color-info">{{ls.locs['locReisekonfigurator'].UhrzeitRange.replace('{0}', (outboundFlightSliderValue | timeOfDay)).replace('{1}', (outboundFlightSliderHeightValue | timeOfDay))}}</div>
      </div>
      <div class="form-group padding-left-8 padding-right-8">
        <ngx-slider class="mp-ngx-slider"
                    [(value)]="outboundFlightSliderValue"
                    [(highValue)]="outboundFlightSliderHeightValue"
                    [options]="rangeSliderOptionsOutboundFlight"
                    (userChangeEnd)="filterClicked(false)">
        </ngx-slider>
      </div>
      <div class="font-size-s padding-left-8 padding-right-8">
        <div class="display-inline-block">{{ ls.locs['locReisekonfigurator'].Rueckflug }}</div>
        <div class="text display-inline-block text-color-info">{{ls.locs['locReisekonfigurator'].UhrzeitRange.replace('{0}', (inwardFlightSliderValue | timeOfDay)).replace('{1}', (inwardFlightSliderHeightValue | timeOfDay))}}</div>
      </div>
      <div class="form-group padding-left-8 padding-right-8">
        <ngx-slider class="mp-ngx-slider"
                    [(value)]="inwardFlightSliderValue"
                    [(highValue)]="inwardFlightSliderHeightValue"
                    [options]="rangeSliderOptionsInwardFlight"
                    (userChangeEnd)="filterClicked(false)">
        </ngx-slider>
      </div>
    </div>
    <hr class="margin-top-0 margin-bottom-24">
    <div class="flex-grid flex-direction-row flex-wrao">
      <div class="bold flex-col-12 flex-justify-cont-between pointer" (click)="showAirlines = !showAirlines">
        <div>{{ ls.locs['locReisekonfigurator'].Airlines }}</div>
        <div class="svg-icon __clr-default __size-18 __top-0" [ngClass]="showAirlines === true ? '__sidebar-arrow-up' : '__sidebar-arrow-down'"></div>
      </div>
      <div [hidden]="!showAirlines" class="flex-col-12 font-size-s margin-bottom-12">
        <a (click)="selectAllAirlines($event)">{{ ls.locs['loc'].Alle }}</a> /
        <a (click)="selectNoAirlines($event)">{{ ls.locs['loc'].Keine }}</a>
      </div>
      <div [hidden]="!showAirlines" class="flex-col-6 flex-col-6-l flex-col-6-xs" *ngFor="let airline of options['Airlines']; let index = index">
        <mp-core-checkbox mpId="chkAirline{{filterId}}{{index}}" [model]="airline.Active" (modelChange)="airline.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12" for="chkAirline{{filterId}}{{index}}">{{airline.DisplayText}}</label>
      </div>
    </div>
    <hr class="margin-top-8 margin-bottom-24">
    <div class="flex-grid flex-direction-row flex-wrao">
      <div class="bold flex-col-12 flex-justify-cont-between pointer" (click)="showAirplanes = !showAirplanes">
        <div>{{ ls.locs['locReisekonfigurator'].Flugzeuge }}</div>
        <div class="svg-icon __clr-default __size-18 __top-0" [ngClass]="showAirplanes === true ? '__sidebar-arrow-up' : '__sidebar-arrow-down'"></div>
      </div>
      <div [hidden]="!showAirplanes" class="flex-col-12 font-size-s margin-bottom-12">
        <a (click)="selectAllAirplanes($event)">{{ ls.locs['loc'].Alle }}</a> /
        <a (click)="selectNoAirplanes($event)">{{ ls.locs['loc'].Keine }}</a>
      </div>
      <div [hidden]="!showAirplanes" class="flex-col-6 flex-col-6-l flex-col-6-xs" *ngFor="let airplane of options['Airplanes']; let index = index">
        <mp-core-checkbox mpId="chkairplane{{filterId}}{{index}}" [model]="airplane.Active" (modelChange)="airplane.Active = $event; optionClicked()"></mp-core-checkbox>
        <label class="font-size-s margin-top-12" for="chkairplane{{filterId}}{{index}}">{{airplane.DisplayText}}</label>
      </div>
    </div>
    <div id="side-filter-footer" class="display-flex flex-direction-column flex-align-items-center">
      <hr class="margin-top-24" />
      <button class="mp-button display-flex flex-align-items-center margin-bottom-8" [ngClass]="{disabled: defaultFilter}" (click)="resetFilter()">
        {{ ls.locs['loc'].AlleFilterZuruecksetzen }}
        <i class="svg-icon __clr-default __size-22 __top--1 margin-left-4 __restore float-none-important"></i>
      </button>
    </div>
  </div>
</div>
