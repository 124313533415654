<div class="sb-overlay" *ngIf="(ls.locsLoaded | async)">
  <div id="overlay-headline" class="text-align-left">{{ ls.locs['loc'].IhreSuche }}</div>
  <div class="divider"></div>
  <div id="rk-filter-elements">
    <ng-content></ng-content>
  </div>
  <div class="text-align-center">
    <a href="" *ngIf="travelStyle === 1" (click)="toggleMobileSearchFilter($event)" class="mp-button _prim display-block"><span class="margin-right-4 position-relative">{{hotelCnt}} {{buttonText}} {{ ls.locs['locReisekonfigurator'].Anzeigen }}</span></a>
    <a href="" *ngIf="travelStyle === 2" (click)="toggleMobileSearchFilter($event)" class="mp-button _prim display-block"><span class="margin-right-4 position-relative">{{hotelCnt}} {{buttonText}} {{ ls.locs['locReisekonfigurator'].Anzeigen }}</span></a>
    <a href="" *ngIf="travelStyle === 3" (click)="toggleMobileSearchFilter($event)" class="mp-button _prim display-block"><span class="margin-right-4 position-relative">{{flightCnt}} {{buttonText}} {{ ls.locs['locReisekonfigurator'].Anzeigen }}</span></a>
  </div>
</div>
