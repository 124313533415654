import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxImageZoomModule } from 'ngx-image-zoom';

import { ImageVideoCarouselComponent } from './image-video-carousel.component';

@NgModule({
  declarations: [
    ImageVideoCarouselComponent
  ],
  exports: [
    ImageVideoCarouselComponent
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    NgxImageZoomModule
  ]
})
export class ImageVideoCarouselModule { }
