<div id="sidebar-travel-destinations-hotel"
     class="rk-sidebar-form"
     *ngIf="(ls.locsLoaded | async)"
     [ngClass]="{noResultsMessage: noResults}">
  <div class="sidebar-grid" *ngIf="selectionLoaded">
    <div id="overlay-headline"
         class="texst-align-left">
      {{ ls.locs['locReisekonfigurator'].ReisezieleSidebarHeader }}
    </div>
    <div class="overflow-hidden">
      <mp-core-search mpId="reiseziele-suche"
                [model]="selection['Suchbegriff']"
                (modelChange)="selection['Suchbegriff'] = $event; searchTermChange()"
                class="sb-search padding-16 display-block"
                mpPlaceholder="{{searchfieldPlaceholder}}"
                [focusOn]="focusOnInit">
        <i class="position-absolute-top-right svg-icon __clr-default __wide __top-0 __search"></i>
        <i [hidden]="!selection['Suchbegriff'] || selection['Suchbegriff'] && selection['Suchbegriff'].length <= 2"
           mpCoreScrollButtonRerenderer data-scroll-area="#reiseziele-scroll-area"
           class="svg-icon __clr-default __size-18 __top-8 __clear position-absolute-top-right margin-right-48 margin-top-16"
           (click)="clearSearch($event)">
        </i>
      </mp-core-search>
    </div>
    <div [hidden]="!selection['ziele'] || selection['ziele'].length === 0 "
         class="padding-16"
         id="selected-destination">
      <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['locReisekonfigurator'].IhreAusgewaehltenReiseziele }}</h3>
      <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open"
           *ngFor="let item of selection['ziele']">
        <span class="icon-text sidebar-open">{{item.Text}}</span>
        <i class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8"
           (click)="setGeoFilter(item.Code, item.Text, item.Typ, item)">
        </i>
      </div>
    </div>
    <div class="divider margin-bottom-8 margin-left-16"
         [hidden]="!selection['ziele'] || selection['ziele'].length === 0 ">
    </div>
    <mp-core-info-notification [hidden]="!noResults"
                       iconPosition="left"
                       infoState="warning"
                       headline="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenTitel }}"
                       copyText="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenText }}"
                       class="padding-left-right-16">
    </mp-core-info-notification>
    <mp-core-info-notification [hidden]="!noSelection"
                       iconPosition="left"
                       infoState="warning"
                       headline="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenTitel }}"
                       copyText="{{ ls.locs['locReisekonfigurator'].ReisezielUeberpruefenTextKeineEingabe }}"
                       class="padding-left-right-16">
    </mp-core-info-notification>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="true"
                   scrollItemHeight="80"
                   scrollArea="#reiseziele-scroll-area"
                   scrollDirection="up"
                   scrollFactor="1"
                   [scopeObject]="countries"
                   [secondScopeObject]="regions"
                   [thirdScopeObject]="cities"
                   [fourthScopeObject]="hotels"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="reiseziele-scroll-area-container">
      <div id="reiseziele-scroll-area"
           class="sidebar-scroll-area position-relative"
           [hidden]="selection['Suchbegriff'] && selection['Suchbegriff'] !== '' && selection['Suchbegriff'].length <= 2">
        <h3 [hidden]="(selection['ziele'] && selection['ziele'].length)"
            class="margin-top-bottom-16">
          {{ ls.locs['locReisekonfigurator'].EmpfehlungenTitel }}
        </h3>
        <div [hidden]="!countries || countries.length === 0 || (selectedTyp && selectedTyp !== areaType['Land']) "
             class="margin-top-24">
          <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['loc'].Laender }}</h3>
          <span *ngFor="let country of countries | orderBy: 'Land'"
                (click)="setGeoFilter(country.LandCode, country.Land, areaType['Land'], country)"
                class="display-block display-flex margin-bottom-8 pointer repeat-countries"
                [ngClass]="{'destination-active': country.active}">
            <span class="svg-icon __clr-info __wide-28 __top--2 __place"></span>
            <span mpCoreHighlight
                  #highlightDirective="mpCoreHighlight"
                  highlight="{{ selection['Suchbegriff'] }}"
                  originalText="{{ country.Land }}"
                  [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : country.Land">
            </span>
          </span>
        </div>
        <div [hidden]="!regions || regions.length === 0 || (selectedTyp && selectedTyp !== areaType['Zielgebiet']) "
             class="margin-top-24">
          <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['locReisekonfigurator'].Regionen }}</h3>
          <span *ngFor="let region of regions | orderBy: 'Zielgebiet'"
                (click)="setGeoFilter(region.Zielgebietscode, region.Zielgebiet, areaType['Zielgebiet'], region)"
                class="display-block display-flex margin-bottom-8 pointer repeat-regions"
                [ngClass]="region.active ? 'destination-active' : ''">
            <span class="svg-icon __clr-info __wide-28 __top--2 __place"></span>
            <span mpCoreHighlight
                  #highlightDirective="mpCoreHighlight"
                  highlight="{{ selection['Suchbegriff'] }}"
                  originalText="{{ region.Zielgebiet }}"
                  [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : region.Zielgebiet">
            </span>
          </span>
        </div>
        <div [hidden]="!cities || cities.length === 0 || (selectedTyp && selectedTyp !== areaType['Stadt']) "
             class="margin-top-24">
          <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['locReisekonfigurator'].Orte }}</h3>
          <span *ngFor="let city of cities | orderBy: 'Stadt'"
                (click)="setGeoFilter(city.StadtCode, city.Stadt, areaType['Stadt'], city)"
                class="display-block display-flex margin-bottom-8 pointer repeat-cities"
                [ngClass]="city.active ? 'destination-active' : ''">
            <span class="svg-icon __clr-info __wide-28 __top--2 __place"></span>
            <span mpCoreHighlight
                  #highlightDirective="mpCoreHighlight"
                  highlight="{{ selection['Suchbegriff'] }}"
                  originalText="{{ city.Stadt }}"
                  [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : city.Stadt">
            </span>
          </span>
        </div>
        <div [hidden]="!hotels || hotels.length === 0 || (selectedTyp && selectedTyp !== areaType['Hotel']) "
             class="margin-top-24">
          <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['locReisekonfigurator'].Hotels }}</h3>
          <span *ngFor="let hotel of hotels | orderBy: 'Hotel'"
                (click)="setGeoFilter(hotel.HotelCode, hotel.Hotel, areaType['Hotel'], hotel)"
                class="display-block display-flex margin-bottom-8 pointer repeat-hotels"
                [ngClass]="hotel.active ? 'destination-active' : ''">
            <span class="svg-icon __clr-info __size-22 __top--1 __house margin-right-4 margin-left-4"></span>
            <span mpCoreHighlight
                  #highlightDirective="mpCoreHighlight"
                  highlight="{{ selection['Suchbegriff'] }}"
                  originalText="{{ hotel.Hotel }}"
                  [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : hotel.Hotel">
            </span>
          </span>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="true"
                   scrollItemHeight="80"
                   scrollArea="#reiseziele-scroll-area"
                   scrollDirection="down"
                   scrollFactor="1"
                   [scopeObject]="countries"
                   [secondScopeObject]="regions"
                   [thirdScopeObject]="cities"
                   [fourthScopeObject]="hotels"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].EingabeBestaetigen }}
      </a>
    </div>
  </div>
</div>
