import { Component, OnInit, ViewEncapsulation, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpThemesService } from './../../../../services/mp-themes.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpBreadcrumbService } from './../../../../services/mp-breadcrumb.service';

/**
 * This class provides the functions for
 * the feature details page.
 */
@Component({
  selector: 'mp-core-feature-details',
  templateUrl: './feature-details.component.html',
  styleUrls: ['./../../../../components/card/styles/card.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeatureDetailsComponent implements OnInit, OnDestroy {

  public currentTheme = 'default';
  public themeLoaded: boolean = false;
  public featureLoaded: boolean = false;
  public errorMessage: string = '';
  public feature: { [key: string]: any } = {};
  public daysTotal: number = 0;
  public daysLeft: number = 0;
  public hideTimeline: boolean = true;

  private _getCurrentThemeSubscription: Subscription | undefined;
  private _getFeatureSubscription: Subscription | undefined;
  private _getQueryParamsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public _breadcrumbService: MpBreadcrumbService,
    private _mpThemes: MpThemesService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private _titleService: MpTitleService
  ) { }

  /**
   * Gets the feature data and the current
   * theme.
   */
  ngOnInit(): void {
    this._getCurrentThemeSubscription = this._mpThemes.theme.subscribe((currentTheme: string) => {
      this.currentTheme = currentTheme;
      this.themeLoaded = true;
    });

    this._mpThemes.getCurrentTheme();

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      const key = typeof params['key'] !== 'undefined' ? params['key'] : '-';

      this._getFeatureSubscription = this._apiService.getRequest(`/api/Features/GetFeature?key=${key}`).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this.feature = data.Records[0];
          this.hideTimeline = true;

          this._titleService.setTitle(this.feature['Title']);
          this._breadcrumbService.setCurrentSiteWithText('FeatureDetails?key=' + key, this.feature['Title']);
          this.featureLoaded = true;
        } else {
          this.errorMessage = data.Message;
        }
      });
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getCurrentThemeSubscription !== 'undefined') {
      this._getCurrentThemeSubscription.unsubscribe();
    }

    if (typeof this._getFeatureSubscription !== 'undefined') {
      this._getFeatureSubscription.unsubscribe();
    }

    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }
  }

  /**
   * Sanitizer for HTML.
   */
  trustAsHtml(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
