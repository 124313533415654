<section id="zuzahlung" class="layout-container" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div>
    <h1>{{ ls.locs['locZuzahlung'].PunkteErwerben }}</h1>
    <div class="row">
      <div class="grid col-large-6">
        <div class="margin-bottom-24" [innerHTML]="ls.locs['locZuzahlung'].ZuzahlungSeitentext">
        </div>
        <h2>{{ ls.locs['locZuzahlung'].ZuzahlungSchritt1Titel }}</h2>
        <form name="formPunkte" class="margin-bottom-24" novalidate="">
          <div *ngIf="mpSettings.settings['ZuzahlungSettings'].HasFreienZuzahlungsbetrag" class="col-large-2">
            <mp-core-input mpId="betrag" label="{{ ls.locs['loc'].Punkte }}" [(model)]="package['Punkte']" (blur)="getAmount()" class="margin-bottom-24" [hasError]="errorPoints ? true : false"></mp-core-input>
          </div>
          <mp-core-form-error *ngIf="mpSettings.settings['ZuzahlungSettings'].HasFreienZuzahlungsbetrag && errorPoints" [model]="pointsRange"></mp-core-form-error>

          <div *ngIf="!mpSettings.settings['ZuzahlungSettings'].HasFreienZuzahlungsbetrag" class="margin-bottom-24" [innerHTML]="ls.locs['locZuzahlung'].ZuzahlungSchritt1TextPakete">
          </div>
          <div *ngIf="!mpSettings.settings['ZuzahlungSettings'].HasFreienZuzahlungsbetrag">
            <mp-core-radio-button name="set-additional-paymnt-package"
                                  *ngFor="let p of packages"
                                  mpId="radio-{{p.Guid}}"
                                  label="{{p.Punkte}} {{ ls.locs['loc'].Punkte }}"
                                  [model]="package['Guid']"
                                  (modelChange)="package['Guid'] = $event; setPackage(p)"
                                  [value]="p.Punkte" 
                                  mpGroup="zuzahlung-pakete">
            </mp-core-radio-button>
          </div>
        </form>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="grid col-large-6">
        <form name="formPunkte" class="margin-bottom-24" novalidate="">
          <h2 *ngIf="additionalPaymentProvider.length > 1">{{ ls.locs['locZuzahlung'].ZuzahlungSchritt2 }}: {{ ls.locs['locZuzahlung'].ZuzahlungSchrittZahlungsartWaehlenTitel }}</h2>
          <div *ngIf="additionalPaymentProvider.length > 1" class="margin-bottom-24">{{ ls.locs['locZuzahlung'].ZuzahlungSchrittZahlungsartWaehlenText }}</div>
          <div *ngIf="additionalPaymentProvider.length > 1" class="margin-bottom-24">
            <mp-core-radio-button *ngFor="let p of additionalPaymentProvider"
                                  name="set-additional-payment-type"
                                  mpId="radio-{{p.Key}}"
                                  label="{{p.Bezeichnung}}"
                                  [model]="additionalPaymentType"
                                  (modelChange)="additionalPaymentType = $event; setType(p.Key)"
                                  [value]="p.Key"
                                  mpGroup="zuzahlung-typen">
            </mp-core-radio-button>
          </div>

          <div *ngFor="let p of additionalPaymentProvider">
            <h2 *ngIf="additionalPaymentType === p.Key || additionalPaymentProvider.length == 1">{{ additionalPaymentDetailsStep }}: {{ p.TemplateTitel }}</h2>
            <div *ngIf="additionalPaymentType === p.Key || additionalPaymentProvider.length == 1" class="margin-bottom-24" [innerHTML]="p.TemplateText"></div>
            <div *ngIf="additionalPaymentType === p.Key || additionalPaymentProvider.length == 1" class="margin-bottom-24">
              <ng-container *ngComponentOutlet="getChildComponent(p.TemplateUrl); injector: injector;"></ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div [hidden]="!errors" class="grid col-large-6">
        <p class="inputRequired" *ngFor="let e of errors" [innerText]="e"></p>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row">
      <div class="grid col-large-6">
        <h2>{{confirmationStep}}: {{ ls.locs['locZuzahlung'].ZuzahlungSchrittBestaetigenTitel }}</h2>
        <div class="margin-bottom-24">{{ ls.locs['locZuzahlung'].ZuzahlungSchrittBestaetigenText }}</div>
        <mp-core-input mpId="gesamtBetrag" label="{{ ls.locs['locZuzahlung'].GesamterZuzahlungsbetrag }}:" [(model)]="viewAmount" class="margin-bottom-24" [mpDisabled]="true"></mp-core-input>
        <div class="pflichtfeldHinweis">{{ ls.locs['loc'].PflichtfeldHinweis }}</div>
        <div [hidden]="!showButton(additionalPaymentProvider.length)" class="buttons floating-container">
          <button type="button" class="mp-button _prim float-right margin-left-8" [attr.disabled]="lockButton" (click)="confirm(type)">{{ ls.locs['loc'].Bestaetigen }}</button>
        </div>
      </div>
    </div>
  </div>
</section>
