import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { ApiService } from '@core/services/api.service';

import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * Provides the data and functions for
 * the card list (slider) of travelogues.
 */
@Component({
  selector: 'mp-rk-travelogue-card-list',
  templateUrl: './travelogue-card-list.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TravelogueCardListComponent implements OnInit, OnDestroy {

  public travelogues: Array<any> = [];
  public owlOptions: OwlOptions = { loop: false, nav: true, slideBy: 'page', navText: ['<', '>'], margin: 16, items: 3, dots: true, responsive: { 0: { items: 1 }, 768: { items: 2 }, 1024: { items: 3 } } };

  private _getTraveloguesSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService
  ) { }

  /**
   * Loads the locs.
   */
  ngOnInit(): void {
    if (Object.keys(this.ls.locs).length > 0) {
      this._loadTravelogues();

      if (typeof this._getLocsSubscription !== 'undefined') {
        this._getLocsSubscription.unsubscribe();
      }
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        this._loadTravelogues();

        if (typeof this._getLocsSubscription !== 'undefined') {
          this._getLocsSubscription.unsubscribe();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTraveloguesSubscription !== 'undefined') {
      this._getTraveloguesSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
   * Loads the travelogues.
   */
  private _loadTravelogues(): void {
    this._getTraveloguesSubscription = this._apiService.getRequest('/api/Reiseberichte/getReiseberichteUebersicht').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.travelogues = data.Records;
      } else {
        this._mpMessaging.openDangerPanel(data.Message);
      }
    },
    (error: any) => {
      this._mpMessaging.openErrorPanel(error, this.ls.locs['loc'].Fehler);
    });
  }

}
