<div id="card-category" class="margin-bottom-32" *ngIf="(ls.locsLoaded | async)">
  <h2>{{ ls.locs['locReisekonfigurator'].Reiseberichte }}</h2>

  <owl-carousel-o *ngIf="travelogues.length > 0" id="reiseberichte-carousel" class="owl-theme" [options]="owlOptions">
    <ng-container *ngFor="let report of travelogues">
      <ng-template carouselSlide>
        <div class="item">
          <mp-rk-travelogue-card-list-card [travelogue]="report"></mp-rk-travelogue-card-list-card>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>


</div>
