import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';

/**
 * This class provides the data for the
 * details page of campaign history.
 */
@Component({
  selector: 'mp-leas-campaigns-history-details',
  templateUrl: './campaigns-history-details.component.html'
})
export class CampaignsHistoryDetailsComponent implements OnInit, OnDestroy {

  public campaign: { [key: string]: any } = {};
  public campaignLoaded: boolean = false;

  private _getQueryParamsSubscription: Subscription | undefined;
  private _getCampaignDetailsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpMenugHighlight: MpMenuHighlightService,
    private _apiService: ApiService,
    private _route: ActivatedRoute
  ) { }

  /**
   * Gets the id from the query params
   * and loads the details data.
   */
  ngOnInit(): void {
    this._mpMenugHighlight.setMenuHighlight('verwaltung');

    this._getQueryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {

        this._getCampaignDetailsSubscription = this._apiService.getRequest(`/api/Aktionshistorie/getAktionshistorieDetails?id=${params['id']}`).subscribe((data: any) => {
          if (data.Result === 'OK') {
            this.campaign = data.Records[0];
            this.campaignLoaded = true;
          }
        });
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getQueryParamsSubscription !== 'undefined') {
      this._getQueryParamsSubscription.unsubscribe();
    }

    if (typeof this._getCampaignDetailsSubscription !== 'undefined') {
      this._getCampaignDetailsSubscription.unsubscribe();
    }
  }

}
