<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].SonderregelungAgenturvertrag }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].SonderregelungAgenturvertrag }}"
                      infotextShorten="SonderregelungAgenturvertrag"
                      fieldsAction="/api/Teilnehmer/getSonderregelungAgenturvertragFields"
                      listAction="/api/Teilnehmer/getSonderregelungAgenturvertrag"
                      loaderName="specialArrangementAgencyAgreementLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [execClickFunctions]="execClickFunctions"
                      [paging]="true"
                      [footer]="false"
                      defaultSorting="Benutzer"
                      [disableCache]="true">
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <mp-core-modal [show]="showReactivation" title="{{ ls.locs['locLeas'].Reaktivierung }}" modalClass="reactivation" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: cancelReactivation}, {text: ls.locs['loc'].Bestaetigen, action: confirmReactivation}]">
    <!--<mp-date-picker label="{{ ls.locs['locLeas'].ReaktivierungAb }}" ng-model="reactivation['ReaktivierungString']" mp-id="reaktivierung-ab" type="text" pick-a-date-value="today" class="date-picker form-control" min-date="" max-date="today"></mp-date-picker>-->
    <mp-core-date-picker label="{{ ls.locs['locLeas'].ReaktivierungAb }}"
                         class="date-picker form-control" 
                         mpId="reaktivierung-ab"
                         [maxDate]="today"
                         rangeType="to"
                         [model]="reactivation['ReaktivierungString']"
                         (modelChange)="reactivation['ReaktivierungString'] = $event;"
                         mpType="text">
    </mp-core-date-picker>
  </mp-core-modal>
  <mp-core-modal [show]="showConfirmReactivation" title="{{ ls.locs['locLeas'].ReaktivierungBestaetigenTitle }}" [buttons]="[{text: ls.locs['loc'].Abbrechen, action: cancelReactivationConfirm}, {text: ls.locs['loc'].Bestaetigen, action: okConfirmReactivation}]">
    <span [innerText]="reactivationConfirmText"></span>
  </mp-core-modal>
  <div class="ui-helper-clearfix"></div>
</div>
