<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].PunkteEuroProMonat }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].PunkteEuroProMonat }}"
                      infotextShorten="PunkteEuroProMonat"
                      fieldsAction="/api/Statistik/getPunkteEuroProMonatFields"
                      listAction="/api/Statistik/getPunkteEuroProMonat"
                      [recLoadedFunc]="recLoadedFunc"
                      loaderName="pointsEuroPerMonthLoader"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting=""
                      [disableCache]="true"></mp-core-data-table>
    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a href="{{excelService}}?{{loader.getExcelParams()}}">
        <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
      </a>
    </div>
</div>

<div class="ui-helper-clearfix"></div>
