import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/it.js';
import 'froala-editor/js/languages/pt_pt.js';
import 'froala-editor/js/languages/ru.js';

import { CommonInternComponentsLoaderModule } from './../common-intern-components-loader.module';

import { ManagerComponent } from './manager.component';

import { HomeComponent } from './pages/home/home.component';
import { ThemeStageEditorComponent } from './pages/theme-stage-editor/theme-stage-editor.component';
import { ThemeStageEditorButtonComponent } from './pages/theme-stage-editor/theme-stage-editor-button/theme-stage-editor-button.component';
import { ThemeStageManagementComponent } from './pages/theme-stage-management/theme-stage-management.component';
import { ThemeStageSliderManagementComponent } from './pages/theme-stage-slider-management/theme-stage-slider-management.component';
import { CommunicationProcessStepperComponent } from './../../components/communication-process-stepper/communication-process-stepper.component';
import { CommunicationProcessStartComponent } from './pages/communication-process-start/communication-process-start.component';
import { CommunicationDataComponent } from './pages/communication-data/communication-data.component';
import { CommunicationManagementComponent } from './pages/communication-management/communication-management.component';
import { ParticipantChoiceComponent } from './pages/participant-choice/participant-choice.component';
import { EmailScheduledManagementComponent } from './pages/email-scheduled-management/email-scheduled-management.component';
import { EmailProtocolComponent } from './pages/email-protocol/email-protocol.component';
import { EmailTextEditorComponent } from './pages/email-text-editor/email-text-editor.component';
import { EmailModuleComponent } from './pages/email-module/email-module.component';
import { CheckNotificationComponent } from './pages/check-notification/check-notification.component';
import { RewardEditorComponent } from './pages/reward-editor/reward-editor.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { CurrentScoreComponent } from './pages/current-score/current-score.component';
import { AnonymizationOverviewComponent } from './pages/anonymization-overview/anonymization-overview.component';
import { ParticipantManagementComponent } from './pages/participant-management/participant-management.component';
import { ParticipantCockpitComponent } from './pages/participant-cockpit/participant-cockpit.component';
import { PtcpntCockpitMasterDataComponent } from './pages/participant-cockpit/ptcpnt-cockpit-master-data/ptcpnt-cockpit-master-data.component';
import { PtcpntCockpitAddressesComponent } from './pages/participant-cockpit/ptcpnt-cockpit-addresses/ptcpnt-cockpit-addresses.component';
import { PtcpntCockpitAccountComponent } from './pages/participant-cockpit/ptcpnt-cockpit-account/ptcpnt-cockpit-account.component';
import { PtcpntCockpitStatusAccountComponent } from './pages/participant-cockpit/ptcpnt-cockpit-status-account/ptcpnt-cockpit-status-account.component';
import { PtcpntCockpitOrdersComponent } from './pages/participant-cockpit/ptcpnt-cockpit-orders/ptcpnt-cockpit-orders.component';
import { PtcpntCockpitOrdersChildComponent } from './pages/participant-cockpit/ptcpnt-cockpit-orders-child/ptcpnt-cockpit-orders-child.component';
import { PtcpntCockpitManuallyOrderComponent } from './pages/participant-cockpit/ptcpnt-cockpit-manually-order/ptcpnt-cockpit-manually-order.component';
import { OrderStatisticComponent } from './pages/order-statistic/order-statistic.component';
import { OrderOverviewComponent } from './pages/order-overview/order-overview.component';
import { ImminentPointsDecayComponent } from './pages/imminent-points-decay/imminent-points-decay.component';
import { GroupsStatisticComponent } from './pages/groups-statistic/groups-statistic.component';
import { CreateGroupingComponent } from './pages/create-grouping/create-grouping.component';
import { GroupingOverviewComponent } from './pages/grouping-overview/grouping-overview.component';
import { GroupingOverviewDetailsComponent } from './pages/grouping-overview-details/grouping-overview-details.component';
import { LoginStatisticComponent } from './pages/login-statistic/login-statistic.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AwardManagerComponent } from './pages/award-manager/award-manager.component';
import { AwardManagerChildComponent } from './pages/award-manager/award-manager-child/award-manager-child.component';
import { OrderOverviewChildComponent } from './pages/order-overview/order-overview-child/order-overview-child.component';
import { AwardStatisticComponent } from './pages/award-statistic/award-statistic.component';
import { AwardStatisticChildComponent } from './pages/award-statistic/award-statistic-child/award-statistic-child.component';
import { PointsEuroPerMonthComponent } from './pages/points-euro-per-month/points-euro-per-month.component';
import { PointsEuroPerMonthDetailsComponent } from './pages/points-euro-per-month-details/points-euro-per-month-details.component';
import { FurtherTextsEditorComponent } from './pages/further-texts-editor/further-texts-editor.component';
import { PointsDecayEuroPerMonthComponent } from './pages/points-decay-euro-per-month/points-decay-euro-per-month.component';
import { PointsDecayEuroPerMonthDetailsComponent } from './pages/points-decay-euro-per-month-details/points-decay-euro-per-month-details.component';
import { InvoiceControlComponent } from './pages/invoice-control/invoice-control.component';
import { ConditionsOfParticipationEditorComponent } from './pages/conditions-of-participation-editor/conditions-of-participation-editor.component';
import { ParticipantRankingComponent } from './pages/participant-ranking/participant-ranking.component';
import { TaxReportingComponent } from './pages/tax-reporting/tax-reporting.component';
import { PaymentOfTaxBaseComponent } from './pages/payment-of-tax-base/payment-of-tax-base.component';
import { PaymentOfTaxListComponent } from './pages/payment-of-tax-list/payment-of-tax-list.component';
import { EmailComponent } from './pages/email-protocol/email/email.component';
import { ConfirmAccountChangeComponent } from './pages/confirm-account-change/confirm-account-change.component';
import { ThemeStagePreviewComponent } from './pages/theme-stage-preview/theme-stage-preview.component';
import { PaymentOfTaxBaseChildComponent } from './pages/payment-of-tax-base/payment-of-tax-base-child/payment-of-tax-base-child.component';


@NgModule({
  declarations: [
    ManagerComponent,
    HomeComponent,
    ThemeStageEditorComponent,
    ThemeStageEditorButtonComponent,
    ThemeStageManagementComponent,
    ThemeStageSliderManagementComponent,
    CommunicationProcessStepperComponent,
    CommunicationProcessStartComponent,
    CommunicationDataComponent,
    CommunicationManagementComponent,
    ParticipantChoiceComponent,
    EmailScheduledManagementComponent,
    EmailProtocolComponent,
    EmailTextEditorComponent,
    EmailModuleComponent,
    CheckNotificationComponent,
    RewardEditorComponent,
    ImprintComponent,
    CurrentScoreComponent,
    AnonymizationOverviewComponent,
    ParticipantManagementComponent,
    ParticipantCockpitComponent,
    PtcpntCockpitMasterDataComponent,
    PtcpntCockpitAddressesComponent,
    PtcpntCockpitAccountComponent,
    PtcpntCockpitStatusAccountComponent,
    PtcpntCockpitOrdersComponent,
    PtcpntCockpitOrdersChildComponent,
    PtcpntCockpitManuallyOrderComponent,
    OrderStatisticComponent,
    OrderOverviewComponent,
    ImminentPointsDecayComponent,
    GroupsStatisticComponent,
    CreateGroupingComponent,
    GroupingOverviewComponent,
    GroupingOverviewDetailsComponent,
    LoginStatisticComponent,
    ChangePasswordComponent,
    AwardManagerComponent,
    AwardManagerChildComponent,
    OrderOverviewChildComponent,
    AwardStatisticComponent,
    AwardStatisticChildComponent,
    PointsEuroPerMonthComponent,
    PointsEuroPerMonthDetailsComponent,
    FurtherTextsEditorComponent,
    PointsDecayEuroPerMonthComponent,
    PointsDecayEuroPerMonthDetailsComponent,
    InvoiceControlComponent,
    ConditionsOfParticipationEditorComponent,
    ParticipantRankingComponent,
    PaymentOfTaxBaseComponent,
    PaymentOfTaxBaseChildComponent,
    PaymentOfTaxListComponent,
    EmailComponent,
    ConfirmAccountChangeComponent,
    ThemeStagePreviewComponent,
    TaxReportingComponent,
  ],
  exports: [
    ManagerComponent,
    PtcpntCockpitMasterDataComponent,
    PtcpntCockpitAddressesComponent,
    PtcpntCockpitAccountComponent,
    PtcpntCockpitStatusAccountComponent,
    PtcpntCockpitOrdersComponent,
    PtcpntCockpitOrdersChildComponent,
    PtcpntCockpitManuallyOrderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonInternComponentsLoaderModule,
    CarouselModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ]
})
export class ManagerModule { }
