import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from "d3";

import { MpD3StylesService } from './../mp-d3-styles.service';
import { MpDebounceService } from './../../../services/mp-debounce.service';
import { ApiService } from './../../../services/api.service';
import { MpLoaderService } from './../../../services/mp-loader.service';
import { MpMessagingService } from './../../../services/mp-messaging.service';

import { MpLoader } from './../../../services/interfaces/mp-loader';

/**
 * This class provides a d3 bar plot.
 */
@Component({
  selector: 'mp-core-d3-lollipop-chart',
  templateUrl: './d3-lollipop-chart.component.html',
  styleUrls: ['./d3-lollipop-chart.component.scss']
})
export class D3LollipopChartComponent implements OnInit {

  @Input() public mpid: string = '';
  @Input() public width: number = 600;
  @Input() public height: number = 400;
  @Input() public dataurl: string = '';
  @Input() public textxachse: string = '';
  @Input() public textyachse: string = '';
  @Input() public animationms: number = 800;
  @Input() public loader: string | undefined;

  private _fillcolor: string = '';
  private _linecolor: string = '';

  constructor(
    private _mpD3StylesService: MpD3StylesService,
    private _mpDebounce: MpDebounceService,
    private _apiService: ApiService,
    private _mpLoaderService: MpLoaderService,
    private _chartElem: ElementRef,
    private _mpMessaging: MpMessagingService
  ) { }

  /**
   * Loads the colors and the data
   * for the chart.
   */
  ngOnInit(): void {
    this._fillcolor = this._mpD3StylesService.mpD3Styles().d3LollipopChartStyles.fillcolor;
    this._linecolor = this._mpD3StylesService.mpD3Styles().d3LollipopChartStyles.linecolor;

    let parentLoader: MpLoader;

    const load = this._mpDebounce.debounce(() => {
      const p = (typeof (this.loader) === 'undefined' ? {} : parentLoader.params);

      this._apiService.postRequest(this.dataurl, p).subscribe((data: any) => {
        if (data.Result === 'OK') {
          this._drawChart(data.Records);
        } else {
          this._mpMessaging.openWarningPanel(data.Message);
        }
      },
        (error: any) => {
          this._mpMessaging.openWarningPanel(error.Message);
        });
    }, 200);

    if (typeof this.loader !== 'undefined') {
      if (this._mpLoaderService.loaderExists(this.loader)) {
        parentLoader = this._mpLoaderService.getLoader(this.loader);

        this._mpLoaderService.extendLoader(parentLoader.name, {
          load: load
        });
      }
    }

    load();
  }

  /**
   * Draws the d3 chart.
   */
  private _drawChart(inputData: any) {
    if (typeof inputData === 'undefined' || typeof inputData !== 'undefined' && typeof inputData[0] === 'undefined')
      return;

    inputData.map(
      (d: any) => {
        if (d.xDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.xDate);
        if (d.yDate) d.xDate = d3.utcParse('%Y-%m-%dT%H:%M:%S')(d.yDate);
      });

    const margin = { top: 10, right: 10, bottom: 50, left: 50 };
    const widthChart = this.width - margin.left - margin.right;
    const heightChart = this.height - margin.top - margin.bottom;
    const xData = (d: any) => { return d.xDate ? d.xDate : d.xInt ? d.xInt : d.xDecimal ? d.xDecimal : d.xString ? d.xString : ''; };
    const yData = (d: any) => { return d.yDate ? d.yDate : d.yInt ? d.yInt : d.yDecimal ? d.yDecimal : d.yString ? d.yString : 0; };

    d3.select('#' + this.mpid)
      .selectAll('*')
      .remove();

    const svg = d3.select('#' + this.mpid)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g');

    svg.append('g').attr('class', 'lines');
    svg.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const x = d3.scaleBand()
      .domain(inputData.map(xData))
      .range([0, widthChart])
      .padding(0.2);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + heightChart + ')')
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end');

    if (this.textxachse !== '') {
      svg.append('text')
        .attr('transform', 'translate(' + (widthChart) + ' ,' + (heightChart + margin.top + 20) + ')')
        .style('text-anchor', 'start')
        .text(this.textxachse);
    }

    const yScale = inputData[0].yDate ? d3.scaleTime() : d3.scaleLinear();

    // @ts-ignore
    const y = yScale
      // @ts-ignore
      .domain([0, Math.max(d3.max(inputData, yData), 1)])
      // @ts-ignore
      .range([heightChart, 0]);

    svg
      .append('g')
      .call(d3.axisLeft(y));

    if (this.textyachse !== '') {
      svg.append('text')
        .attr('y', 0 - margin.top)
        .attr('x', 0 - margin.left)
        .attr('dy', '1em')
        .style('text-anchor', 'start')
        .text(this.textyachse);
    }

    svg.select(".lines").selectAll('line')
      .data(inputData)
      .enter()
      .append('line')
      // @ts-ignore
      .attr('x1', (d: any) => { return x(xData(d)); })
      // @ts-ignore
      .attr('x2', (d: any) => { return x(xData(d)); })
      // @ts-ignore
      .attr('y1', (d: any) => { return y(0); })
      // @ts-ignore
      .attr('y2', (d: any) => { return y(0); })
      .attr('stroke', this._linecolor);

    svg.select(".lines").selectAll('circle')
      .data(inputData)
      .enter()
      .append('circle')
      // @ts-ignore
      .attr('cx', (d: any) => { return x(xData(d)); })
      // @ts-ignore
      .attr('cy', (d: any) => { return y(0); })
      .attr('r', '4')
      .style('fill', this._fillcolor)
      .attr('stroke', this._linecolor);

    svg.select('.lines').selectAll('line')
      .transition()
      .duration(this.animationms)
      .attr('y1', (d: any) => { return y(yData(d)); });

    svg.select('.lines').selectAll('circle')
      .transition()
      .duration(this.animationms)
      .attr('cy', (d: any) => { return y(yData(d)); });
  }

}
