import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { TcAdditionalPaymentService } from './../../../services/tc-additional-payment.service';

/**
 * This class provides ther functions for
 * the payment method Paypal.
 */
@Component({
  selector: 'mp-rkzz-tc-additional-payment-payplace-paypal',
  templateUrl: './tc-additional-payment-payplace-paypal.component.html'
})
export class TcAdditionalPaymentPayplacePaypalComponent implements OnInit, OnDestroy {

  public provider: { [key: string]: any } = {};
  public checkingParameter: { [key: string]: any } = {};
  public missingPoints: number = 0;
  public totalPoints: number = -1;

  private _locsLoadedSubscription: Subscription | undefined;

  constructor(
    private _ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _tcAdditionalPaymentService: TcAdditionalPaymentService,
    private _injector: Injector
  ) { }

  /**
   * Gets injected data and registers the
   * payment methods function.
   */
  ngOnInit(): void {
    this.provider = this._injector.get('tcAdditionalPaymentProvider');
    this.checkingParameter = this._injector.get('tcAdditionalCheckingParameter');
    this.missingPoints = this._injector.get('tcAdditionalPaymentMissingPoints');
    this.totalPoints = this._injector.get('tcAdditionalPaymentTotalPoints');
    this._tcAdditionalPaymentService.register('rk-payplace-express-paypal', this.setPayplaceExpressPaypal.bind(this));
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }
  }

  /**
   * Sets Paypal as method for the
   * additional payment.
   */
  setPayplaceExpressPaypal(successCallback: Function, errorCallback: Function): void {
    this.checkingParameter = Object.assign(this.checkingParameter, {
      PunkteGesamt: this.totalPoints
    });

    if (this._tcAdditionalPaymentService.paymentType === '') {
      if (Object.keys(this._ls.locs).length > 0) {
        this._mpMessaging.openDangerPanel(this._ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
      } else {
        this._locsLoadedSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            this._mpMessaging.openDangerPanel(this._ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
          }
        })

        this._ls.getLocalization();
      }
    } else {
      const data = {
        Punkte: this.missingPoints || 0,
        BuchungsData: this.checkingParameter,
        Provider: 'rk-payplace-express-paypal',
        PaymentMethod: this._tcAdditionalPaymentService.paymentType
      };

      this._apiService.postRequest('/api/RkZuzahlung/zuzahlen', data).subscribe((responseData: any) => {
        if (responseData.Result !== 'OK') {
          this._mpMessaging.openWarningPanel(responseData.Message);
        }

        successCallback(responseData);
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.ExceptionMessage);
        errorCallback(error);
      });
    }
  }

}
