import { Pipe, PipeTransform } from '@angular/core';
import clone from 'clone';

@Pipe({
  name: 'min'
})
export class MinPipe implements PipeTransform {

  transform(data: Array<any>, key: string): number {
    if (typeof data === 'undefined' || data.length === 0) {
      return 0;
    }

    let dataCopy = clone(data);

    if (typeof key !== 'undefined') {
      dataCopy = dataCopy.map((dataObj: any) => {
        return dataObj[key];
      });
    }

    if (dataCopy.length) {
      return Math.min(...dataCopy);
    } else {
      return 0;
    }
  }

}
