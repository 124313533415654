import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import clone from 'clone';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { ApiService } from '@core/services/api.service';

/**
 * This class provides the functionalities
 * for the rental locations sidebar.
 */
@Component({
  selector: 'mp-rk-sidebar-locations-rental',
  templateUrl: './sidebar-locations-rental.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarLocationsRentalComponent implements OnInit, OnDestroy {

  public selection: { [key: string]: any } = {};
  public focusOnInit: boolean = false;
  public noResults: boolean = false;
  public noSelection: boolean = false;
  public searchfieldPlaceholder: string = '';
  public locations: Array<any> = [];
  public showAllSelectedLocationsCollection: boolean = false;
  public showAllSelectedLocationsReturn: boolean = false;

  private _lastSearchSuggestRequestId: number = Math.ceil(Math.random() * 1000);
  private _sidebarParamsSubscription: Subscription | undefined;
  private _getGeoFilterSubscription: Subscription | undefined;
  private _getLocsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSidebar: MpSidebarService,
    private _apiService: ApiService
  ) { }

  /**
   * Gets the sidebar params and the locs.
   */
  ngOnInit(): void {
    this._sidebarParamsSubscription = this.mpSidebar.paramsChange.subscribe(() => {
      this.selection = Object.assign(this.selection, {
        Suchbegriff: this.selection['Suchbegriff'] || this.mpSidebar.params['Suchbegriff'],
        standorte: this.selection['standorte'] || clone(this.mpSidebar.params['standorte']) || [],
        Abholung: typeof this.selection['Abholung'] === 'undefined' || this.selection['Abholung'],
        RueckgabeOptions: this.selection['RueckgabeOptions'] || this.mpSidebar.params['RueckgabeOptions'],
        AndereRueckgabe: typeof this.mpSidebar.params['AndereRueckgabe'] === 'undefined' ? false : this.mpSidebar.params['AndereRueckgabe'],
        standorteAbholung: this.mpSidebar.params['StandorteAbholung'] || [],
        standorteRueckgabe: this.mpSidebar.params['StandorteRueckgabe'] || [],
        noSelection: this.mpSidebar.params['noSelection']
      });

      this.noResults = this.mpSidebar.params['noResults'];
      this.noSelection = this.mpSidebar.params['noSelection'];

      if (this.noSelection &&
        this.selection['AndereRueckgabe'] &&
        this.selection['standorteRueckgabe'].length === 0) {
        this.selection['Abholung'] = false;
      }

      this._checkDuplicates(this.selection['standorteAbholung']);
      this._checkDuplicates(this.selection['standorteRueckgabe']);

      this.focusOnInit = false;

      setTimeout(() => {
        this.focusOnInit = true;
      }, 0);
    });

    if (Object.keys(this.ls.locs).length > 0) {
      this._setSearchfieldPlaceholder();
    } else {
      this._getLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._setSearchfieldPlaceholder();
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._sidebarParamsSubscription !== 'undefined') {
      this._sidebarParamsSubscription.unsubscribe();
    }

    if (typeof this._getGeoFilterSubscription !== 'undefined') {
      this._getGeoFilterSubscription.unsubscribe();
    }

    if (typeof this._getLocsSubscription !== 'undefined') {
      this._getLocsSubscription.unsubscribe();
    }
  }

  /**
  * Sets the placeholder for the search field.
  */
  private _setSearchfieldPlaceholder(): void {
    if (this.selection['AndereRueckgabe']) {
      if (this.selection['Abholung']) {
        this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtenAbholort;
      } else {
        this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtenRueckgabeort;
      }
    } else {
      this.searchfieldPlaceholder = this.ls.locs['locReisekonfigurator'].SucheBestimmtenAbholRueckgabeort;
    }
  }

  /**
   * Checks for dupplicated names, and marks
   * them as duplicated.
   */
  private _checkDuplicates(list: Array<any>): void {
    [...new Set(list.map((item: any) => {
      return item['Name'];
    }))].forEach((name: string) => {
      const sameName = list.filter((item: any) => {
        return item['name'] === name;
      });

      sameName.forEach((same: any) => {
        same['IsDuplicate'] = sameName.length > 1;
      });
    });
  }

  /**
   * Gets the geo filter, so the airports
   * can be filtered.
   */
  private _getGeoFilter(): void {
    if (this.selection['Suchbegriff']) {
      this.noResults = false;
      this.noSelection = false;
    }

    if ((this.selection['Suchbegriff'] && this.selection['Suchbegriff'].length > 2)) {
      const geoFilterData = {
        filter: this.selection['Suchbegriff'],
        requestId: ++this._lastSearchSuggestRequestId
      };

      this._getGeoFilterSubscription = this._apiService.getRequest('/api/RkMietwagen/GetStandorte/', false, { params: geoFilterData }).subscribe((data: any) => {
        if (this._lastSearchSuggestRequestId === data.footer[0]) {
          this.locations = data.Records;
        }
      });
    } else {
      this.locations = [];
    }
  }

  /**
   * Sets the geo filter by the given
   * parameters.
   */
  setGeoFilter(station: { [key: string]: any }, list: Array<any>): void {
    const existing = list.find((location: any) => {
      return location['Name'] === station['Name'] && location['Type'] === station['Type'];
    });

    if (existing) {
      const idx = list.indexOf(existing);
      list.splice(idx, 1);
      existing['active'] = false;
      station['active'] = false;
    } else {
      station['active'] = true;
      list.push(station);
    }

    this._checkDuplicates(list);
  }

  /**
   * Triggers the geo filter.
   */
  searchTermChange(): void {
    this._getGeoFilter();
  }

  /**
   * Changes the type of the return.
   */
  changeOtherReturn(): void {
    if (!this.selection['AndereRueckgabe']) {
      this.selection['Abholung'] = true;
    }

    this._setSearchfieldPlaceholder();
  }

  /**
   * Clears the search field.
   */
  clearSearch(evt: MouseEvent): void {
    this.selection['Suchbegriff'] = '';
    // @ts-ignore
    evt.currentTarget.parentElement.parentElement.querySelector('input').focus();
  }

  /**
   * Handles the change of the tabs.
   */
  changeTab(targetValue: boolean): void {
    if (this.selection['Abholung'] != targetValue) {
      this.selection['Abholung'] = targetValue;
      this.selection['Suchbegriff'] = '';
      this.showAllSelectedLocationsCollection = false;
      this.showAllSelectedLocationsReturn = false;
      this.locations = [];
    }

    this._setSearchfieldPlaceholder();
  }

  /**
   * Toggles the showAllSelectedLocationsCollection.
   */
  toggleShowAllSelectedLocastionsCollection(): void {
    this.showAllSelectedLocationsCollection = !this.showAllSelectedLocationsCollection;
  }

  /**
   * Toggles the showAllSelectedLocationsReturn.
   */
  toggleShowAllSelectedLocastionsReturn(): void {
    this.showAllSelectedLocationsReturn = !this.showAllSelectedLocationsReturn;
  }

  /**
   * Saves the selected airports.
   */
  save(evt: MouseEvent): void {
    evt.preventDefault();
    this.mpSidebar.params = Object.assign(this.mpSidebar.params, {
      StandorteAbholung: this.selection['standorteAbholung'] || [],
      StandorteRueckgabe: this.selection['standorteRueckgabe'] || [],
      AndereRueckgabe: this.selection['AndereRueckgabe']
    });

    this.selection = {};
    this.mpSidebar.changeParams(this.mpSidebar.params);

    if (typeof this.mpSidebar.params['save'] !== 'undefined') {
      this.mpSidebar.params['save']();
    }

    this.mpSidebar.close();
  }

}
