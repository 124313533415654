<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].Versteuerungsgrundlage }}</h1>
  <mp-core-data-table title="{{ ls.locs['loc'].Versteuerungsgrundlage }}"
                      infotextShorten="Versteuerungsgrundlage"
                      fieldsAction="/api/Statistik/getVersteuerungsgrundlageFields"
                      listAction="/api/Statistik/getVersteuerungsgrundlage"
                      loaderName="paymentOfTaxBaseLoader"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="RechnungsbetragVkNetto DESC"
                      [recLoadedFunc]="recLoadedFunc"
                      [alwaysShowFilter]="true"
                      [headerSelectTransclude]="true"
                      [showDatePicker]="false">
    <mp-core-select label="{{ ls.locs['loc'].Monat }}"
                    mpId="payment-of-tax-month-select"
                    [elements]="monthList"
                    [(model)]="month"
                    valueProperty="month"
                    textProperty="text"
                    (ngChange)="changeMonthAndReload()"
                    [mpRequired]="true">
    </mp-core-select>
    <mp-core-select label="{{ ls.locs['loc'].Jahr }}"
                    mpId="payment-of-tax-year-select"
                    [elements]="yearList"
                    [(model)]="year"
                    (ngChange)="changeYearAndReload()"
                    [mpRequired]="true">
    </mp-core-select>
  </mp-core-data-table>

  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}" class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</a>
  </div>
</div>
