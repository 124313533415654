import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';

/**
 * Provides the data and functions for the
 * travelogue card.
 */
@Component({
  selector: 'mp-rk-travelogue-card',
  templateUrl: './travelogue-card.component.html',
  styleUrls: [
    './../../../../mp.Core/app/components/card/styles/card.scss',
    './../../../../mp.Core/app/components/addresses/address-card/address-card.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TravelogueCardComponent implements OnInit {

  @Input() public travelogue: { [key: string]: any } = {};

  public isTravelogueHover: boolean = false;

  private _role: string = '';

  constructor(
    public ls: MpLocalizationService,
    private _router: Router,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Gets the role.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this._role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this._role = this._roleMapping.getReverseMappedRole(role);
    }

    if (this._role === 'Reiseberichte') {
      this._role = '';
    }
  }

  /**
   * Redirects to the travlogue.
   */
  showTravelogue(): void {
    if (this._role === '') {
      this._router.navigateByUrl(`/Reisebericht?key=${this.travelogue['Key']}`);
    } else {
      this._router.navigateByUrl(`/${this._role}/Reisebericht?key=${this.travelogue['Key']}`);
    }
  }

}
