<div class="layout-container" *ngIf="(ls.locsLoaded | async) && nameChanged !== null">
  <h1>{{ ls.locs['loc'].GruppierungAnlegen }}</h1>
  <mp-core-input class="margin-top-16 fallback-input" label="{{ ls.locs['loc'].NameDerGruppierung }}" [mpRequired]="true" mpId="gruppierung-name" mpType="text" [(model)]="grouping['Name']" (change)="nameChanged()"></mp-core-input>
  <div class="upload-gruppierung-liste margin-top-16">
    <button type="button"
            id="btn-gruppierung-liste"
            class="mp-button _prim nonefloat distanceforButton"
            [attr.disabled]="!grouping['Name'] ? '' : null"
            (click)="fileUpload.click()">
      {{ ls.locs['loc'].UploaderMemberList }}
    </button>
    <input #fileUpload
           name="fileUpload"
           type="file"
           class="display-none"
           accept="application/vnd.ms-excel"
           (change)="uploadFiles($event)">
    <div *ngIf="file"
         class="margin-top-16">
      <span>{{file.name}}</span>
      <span class="icon icon-Icons_Check ampelGelb"
            [hidden]="uploadSuccessfully">
      </span>
      <span class="icon icon-Icons_Check ampelGruen"
            [hidden]="!uploadSuccessfully">
      </span>
    </div>
    <mp-core-input class="margin-top-16 fallback-input" label="{{ ls.locs['loc'].ZielDerGruppierung }}" [mpRequired]="true" mpId="gruppierung-ziel" mpType="text" [(model)]="grouping['Ziel']" (change)="nameChanged()"></mp-core-input>
    <mp-core-input class="margin-top-16 fallback-input" label="{{ ls.locs['loc'].ArtGruppierungKommunikation }}" [mpRequired]="true" mpId="gruppierung-art-kommunikation" mpType="text" [(model)]="grouping['ArtDerKommunikation']" (change)="nameChanged()"></mp-core-input>
    <span [hidden]="error === ''" [innerText]="error" class="margin-top-16 inputRequired"></span>
    <div class="margin-top-24" [hidden]="!nameValid">
      <button type="button" class="mp-button _prim" (click)="saveGrouping()">{{ ls.locs['loc'].Anlegen }}</button>
    </div>
  </div>
</div>
