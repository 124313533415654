import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';

/**
 * This class provides the functions
 * and the date for the tc support lug.
 */
@Component({
  selector: 'mp-rk-tc-support',
  templateUrl: './tc-support.component.html',
  styleUrls: [
    './tc-support.component.scss',
    './../../../../mp.Core/app/components/support/support.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TcSupportComponent implements OnInit {

  public openSupport: boolean = false;
  public closeSupport = this._closeSupport.bind(this);

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService
  ) { }

  ngOnInit(): void { }

  /**
   * Closes the support lug.
   */
  private _closeSupport(): void {
    if (this.openSupport === true) {
      this.openSupport = false;
    }
  };

}
