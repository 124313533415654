import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpLoaderService } from '@core/services/mp-loader.service';
import { MpMenuHighlightService } from '@core/components/menu/mp-menu-highlight.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpTitleService } from '@core/services/mp-title.service';

import { MpLoader } from '@core/services/interfaces/mp-loader';


/**
 * This class provides the data and
 * functions for the testuser management
 * table.
 */
@Component({
  selector: 'mp-leas-testuser-management',
  templateUrl: './testuser-management.component.html'
})
export class TestuserManagementComponent implements OnInit, OnDestroy {

  public testuserList: Array<any> = [];
  public email: string = '';
  public showConfirmOverlay: boolean = false;
  public showSelectOverlay: boolean = false;
  public changeData: { [key: string]: any } = {
    Testuser: {}
  }
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'Testuserloeschen',
      func: this.testuserDelete.bind(this)
    }
  ];
  public closeEditModal = this._closeEditModal.bind(this);
  public deleteTestuser = this._deleteTestuser.bind(this);
  public addTestuser = this._addTestuser.bind(this);

  private _testuserToDelete: { [key: string]: any } = {};
  private _leasTestuserManagementLoader: MpLoader = {
    name: 'leasTestuserManagementLoader',
    params: {}
  };
  private _getTestuserSubscription: Subscription | undefined;
  private _addTestuserSubscription: Subscription | undefined;
  private _deleteTestuserSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _titleService: MpTitleService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._titleService.setTitleFromLoc('TestuserVerwaltung');
    this._mpMenuHighlight.setMenuHighlight('kommunikation');

    this._mpLoaderService.registerLoader(this._leasTestuserManagementLoader);

    this._mpLoaderService.extendLoader('leasTestuserManagementLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getTestuserSubscription !== 'undefined') {
      this._getTestuserSubscription.unsubscribe();
    }

    if (typeof this._addTestuserSubscription !== 'undefined') {
      this._addTestuserSubscription.unsubscribe();
    }

    if (typeof this._deleteTestuserSubscription !== 'undefined') {
      this._deleteTestuserSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('leasTestuserManagementLoader');
  }

  /**
   * Closes the edit user modal.
   */
  private _closeEditModal(): void {
    this.showConfirmOverlay = false;
    this.showSelectOverlay = false;
  }

  /**
   * Deletes the testuser.
   */
  private _deleteTestuser(): void {
    this._deleteTestuserSubscription = this._apiService.postRequest('/api/EMailModul/deleteTestuser', {
      tnid: this._testuserToDelete['TnId']
    }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openSuccessPanel('Der Testuser wurde aus dem Filter entfernt!');
        this.showConfirmOverlay = false;

        if (this.loader !== null) {
          if (typeof this.loader.load !== 'undefined') {
            this.loader.load();
          }
        }
      } else {
        this._mpMessaging.openWarningPanel('Bitte in der IT melden.');
        this.showConfirmOverlay = false;
      }
    });
  }

  /**
   * Adds the given testuser. 
   */
  private _addTestuser(): void {
    this._addTestuserSubscription = this._apiService.postRequest('/api/EMailModul/addTestuser', this.changeData['Testuser']).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openSuccessPanel('Der Testuser wurde dem Filter hinzugefügt!');
        this.showSelectOverlay = false;

        if (this.loader !== null) {
          if (typeof this.loader.load !== 'undefined') {
            this.loader.load();
          }
        }
      } else {
        this._mpMessaging.openWarningPanel('Bitte in der IT melden.');
        this.showSelectOverlay = false;
      }
    });
  }

  /**
   * Loads the testusers for the
   * add testuser modal.
   */
  loadTestuser(): void {
    this._getTestuserSubscription = this._apiService.getRequest('/api/EMailModul/getTestuserForFilte').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.testuserList = data.Records;
        this.showSelectOverlay = true;
      } else {
        this._mpMessaging.openWarningPanel('Bitte in der IT melden.');
        this.showSelectOverlay = false;
      }
    });
  }

  /**
   * Opens the delete modal for the
   * given testuser.
   */
  testuserDelete(row: any): void {
    this._testuserToDelete = row;
    this.email = this._testuserToDelete['Email'];
    this.showConfirmOverlay = true;
  }

}
