<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locLeas'].Teilnehmerauswertung }}</h1>
  <mp-core-data-table title="{{ ls.locs['locLeas'].Teilnehmerauswertung }}"
                      infotextShorten="Teilnehmerauswertung"
                      fieldsAction="/api/Teilnehmer/getTeilnehmerauswertungFields"
                      listAction="/api/Teilnehmer/getTeilnehmerauswertung"
                      loaderName="leasParticpantEvaluationLoader"
                      [paging]="true"
                      [recLoadedFunc]="recLoadedFunc"
                      [footer]="false"
                      defaultSorting="Name"
                      [headerSelectTransclude]="true">
    <mp-core-select label="{{ ls.locs['locLeas'].Teilnehmergruppe }}" mpId="mail-filter" [model]="filter" [mpRequired]="true" [elements]="filterList" textProperty="Text" (modelChange)="filter = $event; changeFilter()" valueProperty="Value"></mp-core-select>
    <mp-core-select class="margin-top-8" label="{{ ls.locs['locLeas'].Fahrzeughersteller }}" mpId="marke-filter" [model]="brand" [mpRequired]="true" [elements]="brandList" textProperty="Text" (modelChange)="brand = $event; changeBrand()" valueProperty="Value"></mp-core-select>
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
