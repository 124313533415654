import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { MpLocalizationService } from '../../services/mp-localization.service';
import { MpMessagingService } from '../../services/mp-messaging.service';

/**
 * This class provides the feature
 * dropdown for the filter in shops.
 */
@Component({
  selector: 'mp-core-feature-card-list',
  templateUrl: './feature-card-list.component.html'
})
export class FeatureCardListComponent implements OnInit, OnDestroy {

  public features: Array<any> = [];

  public owlOptions: any = {
    loop: false,
    nav: true,
    slideBy: 'page',
    navText: ['<', '>'],
    margin: 16,
    items: 4,
    dots: true,
    responsive:
    {
      0: { items: 1 },
      768: { items: 2 },
      1024: { items: 4 }
    }
  }

  private _getActionsSusbcription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private msg: MpMessagingService,
    private _apiService: ApiService) { }

  /**
   * Angulars init function. Loads the
   * categories to show and filters by the
   * defined parameters
   */
  ngOnInit(): void {
    this._getActionsSusbcription = this._apiService.getRequest('/api/Features/GetFeatures')
      .subscribe((data: any) => {
        if (data.Result === "OK") {
          this.features = data.Records;
        } else {
          this.msg.openDangerPanel(data.Message);
        }
      },
        (error: any) => {
          this.msg.openErrorPanel(error, this.ls.locs['loc'].Fehler);
        });
  }

  /**
  * Unsubscribes the set subscriptions.
  */
  ngOnDestroy(): void {
    if (typeof this._getActionsSusbcription !== 'undefined') {
      this._getActionsSusbcription.unsubscribe();
    }
  }

}
