import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';

/**
 * This class provides the data for
 * the rules of game.
 */
@Component({
  selector: 'mp-taw-tip-and-win-rules-of-the-game',
  templateUrl: './tip-and-win-rules-of-the-game.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinRulesOfTheGameComponent implements OnInit, OnDestroy {

  public textLoaded: boolean = false;
  public text: { [key: string]: any } = {};
  public trustedHtml: any;
  public safeSrc: SafeResourceUrl | undefined;

  private _getRulesOfGameSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _svgLoader: SvgLoaderService,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Gets the rules of game.
   */
  ngOnInit(): void {
    this._svgLoader.startLoading();

    this._getRulesOfGameSubscription = this._apiService.getRequest('/api/Texte/getTextAndTitle?key=tip-and-win-spielregeln').subscribe((data: any) => {
      this._svgLoader.finishLoading();

      if (data.Result === 'OK') {
        this.text = data.Records[0];

        if (!this.text['Text'] || this.text['Text'] === null || this.text['Text'].length === 0) {
          this.text['Text'] = undefined;
        }

        this.textLoaded = true;
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getRulesOfGameSubscription !== 'undefined') {
      this._getRulesOfGameSubscription.unsubscribe();
    }
  }

  /*
  * Sanitizer for HTML.
  */
  trustAsHtml(str: string): string {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(str);
    return this.trustedHtml;
    //return this.sanitizer.sanitize(SecurityContext.HTML, str) || '';
  }

}
