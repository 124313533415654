import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpShoppingBasketService } from '@core/modules/participant/pages/shopping-basket/mp-shopping-basket.service';
import { MpOrderProcessService } from '@core/components/order-process/mp-order-process.service';
import { SbAdditionalPaymentService } from './../../../services/sb-additional-payment.service';

/**
 * This class provides ther functions for
 * the payment method debitcard.
 */
@Component({
  selector: 'mp-wkzz-sb-additional-payment-payplace-debitcard',
  templateUrl: './sb-additional-payment-payplace-debitcard.component.html'
})
export class SbAdditionalPaymentPayplaceDebitcardComponent implements OnInit, OnDestroy {

  public provider: { [key: string]: any } = {};

  private _locsLoadedSubscription: Subscription | undefined;

  constructor(
    private _ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpShoppingBasket: MpShoppingBasketService,
    private _mpOrderProcess: MpOrderProcessService,
    private _sbAdditionalPaymentService: SbAdditionalPaymentService,
    private _injector: Injector
  ) { }

  /**
   * Gets injected data and registers the
   * payment methods function.
   */
  ngOnInit(): void {
    this.provider = this._injector.get('sbAdditionalPaymentProvider');
    this._sbAdditionalPaymentService.register('payplace-express-debitcard', this.setPayplaceExpressDebitCard.bind(this));
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadedSubscription !== 'undefined') {
      this._locsLoadedSubscription.unsubscribe();
    }
  }

  /**
   * Sets debitcard as method for the
   * additional payment.
   */
  setPayplaceExpressDebitCard(successCallback: Function, errorCallback: Function): void {
    if (this._mpOrderProcess.paymentType === '') {
      if (Object.keys(this._ls.locs).length > 0) {
        this._mpMessaging.openDangerPanel(this._ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
      } else {
        this._locsLoadedSubscription = this._ls.locsLoaded.subscribe((loaded: boolean) => {
          if (loaded) {
            this._mpMessaging.openDangerPanel(this._ls.locs['locZuzahlung'].BitteWaehlenSieIhrZahlungsmittel);
          }
        })

        this._ls.getLocalization();
      }
    } else {
      const memo = [0];

      this._mpShoppingBasket.shoppingBasket.forEach((cart: any) => {
        memo.push(cart['Punkte'] - cart['EingeloestePunkte']);
      });

      const data = {
        PunkteList: memo,
        Cart: this._mpShoppingBasket.shoppingBasket,
        Provider: 'payplace-express-debitcard',
        PaymentMethod: this._mpOrderProcess.paymentType
      };

      this._apiService.postRequest('/api/WkZuzahlung/zuzahlen', data).subscribe((responseData: any) => {
        if (responseData.Result !== 'OK') {
          this._mpMessaging.openWarningPanel(responseData.Message);
        }

        successCallback(responseData);
      },
      (error: any) => {
        this._mpMessaging.openWarningPanel(error.ExceptionMessage);
        errorCallback(error);
      });
    }
  }

}
