<div id="sidebar-forgot-login-data" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)"
     class="rk-sidebar-form">
  <div class="sidebar-grid">
    <div id="overlay-headline"
         class="texst-align-left">
      {{ ls.locs['loc'].ZugangsdatenVergessenTitle }}
    </div>
    <div class="divider margin-left-0 margin-bottom-8"></div>
    <div class="margin-left-16 margin-right-16 margin-top-8 display-flex"
         id="sidebar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-center pointer"
           [ngClass]="{ 'active-tab': activeTab === 'password' }"
           (click)="changeTab('password')">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['loc'].Passwort }}</span>
      </div>
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-center pointer"
           [ngClass]="{ 'active-tab': activeTab === 'username' }"
           (click)="changeTab('username')">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['loc'].Benutzername }}</span>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-up"
                   [ifNeeded]="true"
                   scrollItemHeight="80"
                   scrollArea="#login-data-scroll-area"
                   scrollDirection="up"
                   scrollFactor="1"
                   class="scrollbutton-up inactive-scroll-button margin-top-16">
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container"
         id="login-data-scroll-area-container">
      <div id="login-data-scroll-area"
           class="padding-top-16 sidebar-scroll-area position-relative">
        <div [hidden]="activeTab !== 'username'">
          <span>{{ ls.locs['loc'].ZugangsdatenVergessenBenutzernameTabText }}</span>
          <div class="flex-col-12 padding-left-0 flex-direction-column">
            <div class="padding-left-right-0 col-small-12 margin-top-24">
              <mp-core-input mpType="text"
                        mpPlaceholder="{{ ls.locs['loc'].EMail }}"
                        mpId="email"
                        [(model)]="email"
                        (modelChange)="validateEmail()"
                        [mpRequired]="true"
                        [hasError]="errors['data.Email'] ? true : false">
              </mp-core-input>
            </div>
            <mp-core-form-error *ngIf="errors['data.Email']"
                           [model]="errors['data.Email']">
            </mp-core-form-error>
          </div>
        </div>
        <div [hidden]="activeTab !== 'password'">
          <span>
            {{ ls.locs['loc'].ZugangsdatenVergessenPasswortTabText }}
          </span>
          <div class="flex-col-12 padding-left-0 flex-direction-column">
            <div class="padding-left-right-0 col-small-12 margin-top-24">
              <mp-core-input mpType="text"
                        mpPlaceholder="{{ ls.locs['loc'].Benutzername }}"
                        mpId="username"
                        [(model)]="username"
                        (modelChange)="validateUsername()"
                        [mpRequired]="true"
                        [hasError]="errors['data.Username'] ? true : false">
              </mp-core-input>
            </div>
            <mp-core-form-error *ngIf="errors['data.Username']"
                           [model]="errors['data.Username']">
            </mp-core-form-error>
          </div>
        </div>
        <div class="divider margin-top-16"></div>
        <div *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer && !mpSettings.settings['DisplaySettings'].HideHotline"
             class="margin-top-16 display-flex flex-wrap">
          <i class="svg-icon __clr-inactive __size-18 __top-2 __info margin-right-8 default-cursor-hover"></i>
          <span class="text-color-grey-info" [innerHtml]="ls.locs['loc'].ZugangsdatenVergessenSupportHinweisHotline | format:['<br><a href=&quot;tel:' + mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer + '&quot;>' + mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer + '</a>'] "></span>
        </div>
        <div *ngIf="!mpSettings.settings['NetcentiveCoreSettings'].HotlineNummer || mpSettings.settings['DisplaySettings'].HideHotline"
             class="margin-top-16 display-flex flex-wrap">
          <i class="svg-icon __clr-inactive __size-18 __top-2 __info margin-right-8 default-cursor-hover"></i>
          <span class="text-color-grey-info" [innerHtml]="ls.locs['loc'].ZugangsdatenVergessenSupportHinweisKeineHotline | format:['<br><a href=&quot;mailto:' + mpSettings.settings['NetcentiveCoreSettings'].KontaktEMailAddress + '&quot;>' + mpSettings.settings['NetcentiveCoreSettings'].KontaktEMailAddress + '</a>'] "></span>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down"
                   [ifNeeded]="true"
                   scrollItemHeight="80"
                   scrollArea="#login-data-scroll-area"
                   scrollDirection="down"
                   scrollFactor="1"
                   class="scrollbutton-down inactive-scroll-button margin-top-16">
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white">
    <div id="overlay-buttons">
      <a href=""
         (click)="save($event)"
         class="mp-button _prim display-block">
        {{ ls.locs['loc'].JetztZusenden }}
      </a>
    </div>
  </div>
</div>
