import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { AuthService } from './../../../../services/auth.service';
import { ApiService } from './../../../../services/api.service';
import { MpTitleService } from './../../../../services/mp-title.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpCommunicationProcessService } from './../../../../services/mp-communication-process.service';
import { RoleMappingService } from './../../../../services/role-mapping.service';

/**
 * This class provides the data and
 * functions for the participant
 * choice of the communication process.
 */
@Component({
  selector: 'mp-core-participant-choice',
  templateUrl: './participant-choice.component.html'
})
export class ParticipantChoiceComponent implements OnInit, OnDestroy {

  public participantChoiceData: { [key: string]: any } = {};
  public excelServiceCommunicationRecipient: string = '/api/KommunikationProzess/KommunikationsEmpfaenger2Excel';
  public communicationGroup: string = '';
  public loadStepFunc = this._loadStep.bind(this);
  public groupsParams: { [key: string]: any } = {};
  public participantParams: { [key: string]: any } = {};
  public role: string = '';
  public dataLoaded: boolean = false;

  private _cpKey: string = 'teilnehmer-auswahl';
  private _locsLoadSubscription: Subscription | undefined;
  private _getParamsSubscription: Subscription | undefined;
  private _getParticipantChoiceDataSubscription: Subscription | undefined;
  private _watchCpStepsSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _titleService: MpTitleService,
    private _mpMenuHighlight: MpMenuHighlightService,
    public cp: MpCommunicationProcessService,
    private _route: ActivatedRoute,
    private _roleMapping: RoleMappingService
  ) { }

  /**
   * Sets title and menu highlighting. Loads
   * locs. Sets watcher for cp step changes.
   */
  ngOnInit(): void {
    this.role = this._authService.getRole();

    if (typeof this.role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(this.role);
    }

    this._titleService.setTitleFromLoc('BenachrichtigungAnlegen');
    this._mpMenuHighlight.setMenuHighlight('kommunikation');

    if (Object.keys(this.ls.locs).length > 0) {
      this._checkRouteParams();
    } else {
      this._locsLoadSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._checkRouteParams();
        }
      });

      this.ls.getLocalization();
    }

    this._watchCpStepsSubscription = this.cp.stepsObserver.subscribe((steps: { [key: string]: any }) => {
      if (!this.cp.isValidStep(this._cpKey)) {
        this.cp.goToCurrentStep();
      }

      if (this.cp.steps && Object.keys(this.cp.steps).length > 0) {
        this.cp.steps[this._cpKey].save = this._saveStep;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._locsLoadSubscription !== 'undefined') {
      this._locsLoadSubscription.unsubscribe();
    }

    if (typeof this._getParamsSubscription !== 'undefined') {
      this._getParamsSubscription.unsubscribe();
    }

    if (typeof this._getParticipantChoiceDataSubscription !== 'undefined') {
      this._getParticipantChoiceDataSubscription.unsubscribe();
    }

    if (typeof this._watchCpStepsSubscription !== 'undefined') {
      this._watchCpStepsSubscription.unsubscribe();
    }
  }

  /**
   * Gets the route params, and
   * starts the loading data
   * process.
   */
  private _checkRouteParams(): void {
    this._getParamsSubscription = this._route.queryParamMap.subscribe((params: any) => {
      this.cp.communicationId = isNaN(parseInt(params.get('id'))) ? 0 : parseInt(params.get('id'));
      this._loadData();
    });
  }

  /**
   * Loads the data for the
   * participant choice.
   */
  private _loadData(): void {
    this._getParticipantChoiceDataSubscription = this._apiService.getRequest('/api/KommunikationProzess/GetTeilnehmerAuswahlData').subscribe((data: any) => {
      this.participantChoiceData = data.Records[0];
      this._resetSidebarData();

      if (this.cp.communicationId) {
        this.cp.load(this.loadStepFunc);
      } else {
        this._loadStep();
      }
    });
  }

  /**
   * Resets the sidebar data.
   */
  private _resetSidebarData(): void {
    this.groupsParams = {
      selectedList: [],
      list: this.participantChoiceData['Gruppen'],
      filtered: this.participantChoiceData['Gruppen'],
      text: this.ls.locs['loc'].BitteWaehlen,
      defaultText: this.ls.locs['loc'].BitteWaehlen,
      searchTerm: '',
      title: this.ls.locs['loc'].Gruppe,
      all: false,
      save: this._filterForGroup.bind(this)
    };

    this.participantParams = {
      selectedList: [],
      list: this.participantChoiceData['Teilnehmer'],
      filtered: this.participantChoiceData['Teilnehmer'],
      text: this.ls.locs['loc'].BitteWaehlen,
      defaultText: this.ls.locs['loc'].BitteWaehlen,
      searchTerm: '',
      title: this.ls.locs['loc'].Teilnehmer,
      all: false,
      save: this._setSidebarTexts.bind(this)
    };
  }

  /**
   * Loads the step from the
   * communication process.
   */
  private _loadStep(): void {
    const data = this.cp.loadStep(this._cpKey);
    this.dataLoaded = true;

    if (typeof data === 'undefined' || typeof data !== 'undefined' && data === null) {
      this._resetSidebarData();
      this.communicationGroup = '';
      return;
    }

    this._setSavedData(data);
    this._setSidebarTexts();
    this.cp.setDefaultTitle();
  }

  /**
   * Sets the saved data for
   * the step, if there are
   * saved data available.
   */
  private _setSavedData(data: any): void {
    this.groupsParams['selectedList'] = data['Gruppen'] || [];
    this.groupsParams['all'] = data['AlleGruppen'];
    this.participantParams['selectedList'] = data['Teilnehmer'] || [];
    this.participantParams['all'] = data['AlleTeilnehmer'];
  }

  /**
   * Sets the texts of the sidebars.
   */
  private _setSidebarTexts(): void {
    this._setSidebarText(this.groupsParams, 'groupsParams');
    this._setSidebarText(this.participantParams, 'participantParams');
  }

  /**
   * Sets the sidebar texts for the
   * given sidebar.
   */
  private _setSidebarText(sidebar: any, sidebarName: string): void {
    if (typeof sidebar['selectedList'] === 'undefined') {
      sidebar['selectedList'] = [];
    }

    if (sidebar['all']) {
      if (sidebarName === 'participantParams' && (this.groupsParams['selectedList'].length > 0 && !this.groupsParams['all'])) {
        sidebar['text'] = this.ls.locs['loc'].AlleDerObigenAuswahl.replace('{0}', this.ls.locs['loc'].Teilnehmer);
      } else {
        sidebar['text'] = this.ls.locs['loc'].Alle;
      }
    } else {
      if (sidebar['selectedList'].length === 0) {
        sidebar['text'] = sidebar['defaultText'];
      } else {
        const pluckedSelectedList = sidebar['selectedList'].map((selected: any) => {
          return selected['DisplaytextKurz'];
        });

        sidebar['text'] = pluckedSelectedList.reduce((a: any, b: any) => {
          return a + ', ' + b;
        });
      }
    }
  }

  /**
   * Filter function for the group
   * sidebar.
   */
  private _filterForGroup(): void {
    if (this.groupsParams['selectedList'].length > 0) {
      this.participantParams['list'] = this.participantChoiceData['Teilnehmer'].filter((participant: any) => {
        return participant['GruppierungsIds'].length > 0 && [participant['GruppierungsIds'], this.groupsParams['selectedList'].map((selected: any) => {
          return selected['Identifier'];
        })].reduce((a: any, b: any) => {
          return a.filter((c: any) => b.includes(c));
        });
      });

      this.participantParams['selectedList'] = this.participantParams['selectedList'].filter((participant: any) => {
        return participant['GruppierungsIds'].length > 0 && [participant['GruppierungsIds'], this.groupsParams['selectedList'].map((selected: any) => {
          return selected['Identifier'];
        })].reduce((a: any, b: any) => {
          return a.filter((c: any) => b.includes(c));
        });
      });

      if (this.participantParams['selectedList'].length === 0)
        this.participantParams['all'] = true;
    } else {
      this.participantParams['list'] = this.participantParams['list'];
      this.participantParams['selectedList'] = [];
      this.participantParams['all'] = false;
    }

    this._setSidebarTexts();
  }

  /**
   * Gets and returns the saved
   * data.
   */
  private _getSaveData(): { [key: string]: any } {
    return {
      Gruppen: this.groupsParams['all'] ? [] : this.groupsParams['selectedList'],
      AlleGruppen: this.groupsParams['all'],
      Teilnehmer: this.participantParams['all'] ? [] : this.participantParams['selectedList'],
      AlleTeilnehmer: this.participantParams['all'],
      GruppenText: this.groupsParams['text'],
      TeilnehmerText: this.participantParams['text']
    };
  };

  /**
   * Saves the communication process.
   */
  saveCommunicationProcess(evt: MouseEvent): void {
    evt.preventDefault();
    this._saveStep();
    this.cp.save();
  }

  /**
   * Saves the step of the communication
   * process.
   */
  private _saveStep(): void {
    const data = this._getSaveData();
    this.cp.setDefaultTitle();
    this.cp.saveStep(this._cpKey, data);
    sessionStorage.removeItem('teilnehmer');

    if (data['Teilnehmer'] && data['Teilnehmer'].length === 1)
      this.cp.saveStep('teilnehmer', data['Teilnehmer'][0])
  }

  /**
   * Validates the current step of
   * the communication process.
   */
  validateStep(): boolean {
    if (typeof this.participantParams['selectedList'] !== 'undefined' &&
      (this.participantParams['selectedList'].length > 0
        || typeof this.participantParams['all'] !== 'undefined'))
      return true;

    return false;
  }

  /**
   * Goes to the next step of the
   * communication process.
   */
  goToNextStep(evt: MouseEvent): void {
    evt.preventDefault();

    if (!this.validateStep())
      return;

    this._saveStep();
    this.cp.next();
  }

  /**
   * Triggers the opening of the
   * clear confirm modal.
   */
  cpShowClearConfirm(evt: MouseEvent): void {
    evt.preventDefault();
    this.cp.showClearConfirm();
  }

}
