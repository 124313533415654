<div id="jtTNCockpitBestellung" *ngIf="(ls.locsLoaded | async) && tnIdAvailable">
  <mp-core-data-table fieldsAction="/api/Bestellung/getBestellungenCockpitFields"
                      title="{{ ls.locs['loc'].Bestellungen }}"
                      listAction="/api/Bestellung/getBestellungenCockpit"
                      loaderName="ptcpntCockpitOrdersLoader"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="Datum DESC"
                      [disableCache]="true"
                      [recLoadedFunc]="recLoadedFunc"></mp-core-data-table>
</div>
