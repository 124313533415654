<div id="cookie-consent-layer" *ngIf="cookiesLoaded">
  <!--<mp-core-modal [show]="true"
                 title="Test"
                 [ngClass]="{ 'show-individual-settings': showIndividualSettings }"
                 [actionsTemplate]="actionsTemplate">
    <p>locsLoaded# {{locsLoaded}}</p>
    <p>cookiesLoaded# {{cookiesLoaded}}</p>
    <p>showCookieConsentModal# {{showCookieConsentModal}}</p>
  </mp-core-modal>-->
  <mp-core-modal [show]="showCookieConsentModal"
                 title="{{ ls.locs['loc'].WirVerwendenCookies }}"
                 [ngClass]="{ 'show-individual-settings': showIndividualSettings }"
                 [actionsTemplate]="actionsTemplate">

    <p class="text-align-center" [hidden]="showIndividualSettings">{{ ls.locs['loc'].WirVerwendenCookiesText }}</p>
    <p class="text-align-center" [hidden]="!showIndividualSettings">{{ ls.locs['loc'].IndividuelleCookieEinstellungenText }}</p>
    <div id="cookie-consent-layer__categories" class="flex-grid flex-justify-cont-center" [hidden]="true">
      <div class="cookie-consent-layer__categories--category flex-col-5 flex-col-12-s flex-justify-cont-center" *ngFor="let cookieType of cookieTypes">
        <ng-template #singleCookies>
          <table class="table">
            <thead>
            <tr>
              <th>{{ ls.locs['loc'].CookieName }}</th>
              <th>{{ ls.locs['loc'].CookieProvider }}</th>
              <th>{{ ls.locs['loc'].CookieReason }}</th>
              <th>{{ ls.locs['loc'].CookiePersistence }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cookie of cookieType.Cookies">
              <td [attr.data-label]="ls.locs['loc'].CookieName">{{ cookie.Name }}</td>
              <td [attr.data-label]="ls.locs['loc'].CookieProvider">{{ cookie.Provider }}</td>
              <td [attr.data-label]="ls.locs['loc'].CookieReason">{{ cookie.Reason }}</td>
              <td [attr.data-label]="ls.locs['loc'].CookiePersistence">{{ cookie.DurationString }}</td>
              <td class="cookie-consent-layer__single-cookie-toggle">
                <mp-core-toggle-button *ngIf="!cookieType.Mandatory"
                                       [active]="cookie.HasConsent !== null ? cookie.HasConsent : cookie.DefaultAcceptance"
                                       label="{{ (cookie.HasConsent !== null && cookie.HasConsent) || cookie.DefaultAcceptance ? this.ls.locs['loc'].An : this.ls.locs['loc'].Aus }}"
                                       (activeChange)="cookie.HasConsent = $event; cookieService.setTypeConsent(cookieType)">
                </mp-core-toggle-button>
                <span *ngIf="cookieType.Mandatory" class="text-color-info font-size-s cookie-consent-layer__single-cookie-toggle__always_active">{{ ls.locs['loc'].CookieAlwaysActive }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </div>
    </div>
    <div id="cookie-consent-layer__individual-settings" [hidden]="!showIndividualSettings">
      <p class="bold">{{ls.locs['loc'].EinwilligungspraeferenzVerwalten}}</p>
      <mp-core-accordion [accDataExpression]="accCookieDataFunc"></mp-core-accordion>
    </div>
    <div id="cookie-consent-layer__buttons" class="flex-grid flex-justify-cont-center padding-left-right-12 padding-top-8" [hidden]="showIndividualSettings">
      <div class="flex-col-6 flex-col-12-s">
        <button class="mp-button _prim width-full" (click)="acceptAll()">{{ ls.locs['loc'].AlleAkzeptieren }}</button>
      </div>
      <div *ngIf="!showIndividualSettings" class="flex-col-6 flex-col-12-s">
        <button class="mp-button _sec width-full" (click)="acceptEssential()">{{ ls.locs['loc'].NotwendigeCookiesAkzeptieren }}</button>
      </div>
      <div class="flex-col-12 flex-justify-cont-center padding-top-16">
        <button class="mp-button text-color-brand-primary" (click)="toggleIndividualSettings()">
          <span>{{ ls.locs['loc'].IndividuelleCookieEinstellungen }}</span>
        </button>
      </div>
    </div>
    <ng-template #actionsTemplate>
      <div id="cookie-consent-layer__privacy-links" [hidden]="showIndividualSettings" class="flex-grid flex-justify-cont-center width-full-important">
        <div class="flex-col-12-s flex-justify-cont-end">
          <a *ngIf="role === '' && !settings.settings['DisplaySettings'].ImpressumLink" target="_blank" href="#/Impressum" class="mp-button">{{ ls.locs['loc'].Impressum }}</a>
          <a *ngIf="role !== '' && !settings.settings['DisplaySettings'].ImpressumLink" target="_blank" href="#/{{role}}/Impressum" class="mp-button">{{ ls.locs['loc'].Impressum }}</a>
          <a *ngIf="settings.settings['DisplaySettings'].ImpressumLink" target="_blank" href="{{settings.settings['DisplaySettings'].ImpressumLink}}" class="mp-button">{{ ls.locs['loc'].Impressum }}</a>
        </div>
        <div class="flex-col-12-s">
          <a *ngIf="role === '' && !settings.settings['NetcentiveCoreSettings'].DatenschutzLink" target="_blank" href="#/Datenschutz" class="mp-button">{{ ls.locs['loc'].Datenschutz }}</a>
          <a *ngIf="role !== '' && !settings.settings['NetcentiveCoreSettings'].DatenschutzLink" target="_blank" href="#/{{role}}/Datenschutz" class="mp-button">{{ ls.locs['loc'].Datenschutz }}</a>
          <a *ngIf="settings.settings['NetcentiveCoreSettings'].DatenschutzLink" target="_blank" href="{{settings.settings['NetcentiveCoreSettings'].DatenschutzLink}}" class="mp-button">{{ ls.locs['loc'].Datenschutz }}</a>
        </div>
      </div>
      <div id="cookie-consent-layer__buttons" [hidden]="!showIndividualSettings" class="flex-grid flex-justify-cont-center cookie-consent-layer__buttons--settings">
        <div class="flex-col-6 flex-col-12-s">
          <button class="mp-button _prim width-full" (click)="acceptAll()">{{ ls.locs['loc'].AlleAkzeptieren }}</button>
        </div>
        <div *ngIf="showIndividualSettings" class="flex-col-6 flex-col-12-s">
          <button class="mp-button _sec width-full" (click)="saveConsents()">{{ ls.locs['loc'].AuswahlSpeichern }}</button>
        </div>
        <div class="flex-col-12 flex-justify-cont-center padding-top-16" [hidden]="!showIndividualSettings || hideBackButton">
          <button class="mp-button" (click)="toggleIndividualSettings()">
            <span>{{ ls.locs['loc'].Zurueck}}</span>
          </button>
        </div>
      </div>
    </ng-template>
  </mp-core-modal>
</div>
