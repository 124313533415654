import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';

/**
 * This class provides the functions to
 * create a participant account.
 */
@Component({
  selector: 'mp-core-create-participant',
  templateUrl: './create-participant.component.html'
})
export class CreateParticipantComponent implements OnInit, OnDestroy {

  public ptcpntCreated: boolean = false;
  public ptcpntCreatedResult: Array<any> = [];
  public ddlLoaded: boolean = false;
  public groupsLoaded: boolean = false;
  public errors: { [key: string]: any } = {};
  public data: { [key: string]: any } = {};
  public groupsList: Array<any> = [];
  public countriesList: Array<any> = [];
  public filteredSalutationsList: Array<any> = [];

  private _salutationsList: Array<any> = [];
  private _getLocalizationSubscription: Subscription | undefined;
  private _getDdlDataSubscription: Subscription | undefined;
  private _getGroupListsSusbcription: Subscription | undefined;
  private _saveParticipantSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _apiService: ApiService,
    private _mpMessaging: MpMessagingService,
    private _mpMenuHighlight: MpMenuHighlightService
  ) { }

  /**
   * Sets the menu highlighting, gets
   * the localization, and (re)loads
   * the needed data.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    if (Object.keys(this.ls.locs).length > 0) {
      this.reload();

      if (typeof this._getLocalizationSubscription !== 'undefined') {
        this._getLocalizationSubscription.unsubscribe();
      }
    } else {
      this._getLocalizationSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.reload

          if (typeof this._getLocalizationSubscription !== 'undefined') {
            this._getLocalizationSubscription.unsubscribe();
          }
        }
      });

      this.ls.getLocalization();
    }
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getLocalizationSubscription !== 'undefined') {
      this._getLocalizationSubscription.unsubscribe();
    }

    if (typeof this._getDdlDataSubscription !== 'undefined') {
      this._getDdlDataSubscription.unsubscribe();
    }

    if (typeof this._getGroupListsSusbcription !== 'undefined') {
      this._getGroupListsSusbcription.unsubscribe();
    }

    if (typeof this._saveParticipantSubscription !== 'undefined') {
      this._saveParticipantSubscription.unsubscribe();
    }
  }

  /**
   * Loads the data for the form selects.
   */
  private _loadItems(): void {
    this._getDdlDataSubscription = this._apiService.getRequest('api/Teilnehmer/getDdlData').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.countriesList = data.Records[0].Laenderliste;
        this._salutationsList = data.Records[0].Anreden;

        this.data['Land'] = this.countriesList.filter((country: { [key: string]: any }) => {
          return country['Kuerzel'] === 'DE';
        })[0];

        this.data['Kuerzel'] = this.data['Land'].Kuerzel;
        this.data['AnredeObjekt'] = this._salutationsList[0];

        if (this.groupsList.length > 0) {
          this.data['Gruppe'] = this.groupsList[0];
        }

        this.ddlLoaded = true;
        this.updateSalutationsList();
      }
    });

    this._getGroupListsSusbcription = this._apiService.getRequest('api/Config/getGruppenListe').subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.groupsList = data.Records;
        this.data['Gruppe'] = this.groupsList[0];
        this.groupsLoaded = true;
      }
    });
  }

  /**
   * Reloads the form (clears data).
   */
  reload(): void {
    this.ptcpntCreated = false;
    this.errors = {};
    this._loadItems();

    this.data = {
      Testuseranlegen: true,
      Manageranlegen: false,
      DemoBuchungen: false,
      Testuser: true,
      PWDGueltigBisParams: {
        Headline: this.ls.locs['loc'].PWDGueltigBis,
        minYearDif: moment().year() - 2051,
        maxYearDif: 0,
        date: moment().set({ 'hours': 23, 'minutes': 59, 'seconds': 0 })
      }
    };
  }

  /**
   * Updates the salutations list.
   */
  updateSalutationsList(): void {
    if (Object.keys(this.data).length > 0 && this.data['Kuerzel']) {
      this.filteredSalutationsList = this._salutationsList.filter((salutation: { [key: string]: any }) => {
        return salutation['Land'] === this.data['Kuerzel'];
      });

      this.data['AnredeObjekt'] = this.filteredSalutationsList[0];
    } else {
      this.filteredSalutationsList = [];
    }
  }

  /**
   * Saves the participant data.
   */
  save(data: { [key: string]: any }): void {
    if (typeof data['Land'] !== 'undefined') {
      data['GruppenID'] = data['Gruppe'].ID;
    } else {
      data['GruppenID'] = undefined;
    }

    data['PWDGueltigBis'] = data['PWDGueltigBisParams'].date.toDate();

    this._saveParticipantSubscription = this._apiService.postRequest('api/Teilnehmer/setTeilnehmerDaten', data).subscribe((data: any) => {
      if (data.Result === 'ERROR') {
        this._mpMessaging.openDangerPanel(data.Message);
      } else {
        this.ptcpntCreatedResult = data.Records;
        this._mpMessaging.openSuccessPanel('Der Teilnehmer wurde angelegt.');
        this.ptcpntCreated = true;
      }
    },
    (error: any) => {
      this.errors = error.ModelState;
    });
  }

}
