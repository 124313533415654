import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

/**
 * This class provides the functions to get the
 * status from the backend api.
 */
export class MpStatusService {

  private _status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public status: Observable<boolean> = this._status.asObservable();
  public currentHasStatus: boolean | undefined;
  private _requestRunning: boolean = false;

  constructor(private _apiService: ApiService) { }

  /**
   * Calls the api to get the status data.
   */
  hasStatus(): void {
    if (!this._requestRunning) {
      this._requestRunning = true;

      this._apiService.getRequest('/api/Status/HasStatus').subscribe((data: any) => {
        this._status.next(data.Records[0].HasStatus);
        this.currentHasStatus = data.Records[0].HasStatus;
        this._requestRunning = false;
      },
      (error: any) => {
        console.log(error.message);
      });
    }
  }
}
