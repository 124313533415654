<div *ngIf="(ls.locsLoaded | async)">
  <a href="{{rolePath}}/Reisebericht?key={{travelogue['Key']}}">
    <div class="card margin-bottom-16">
      <div class="row-inner">
        <div class="grid col-small-4 col-medium-5">
          <div class="rich-media text-align-center">
            <img class="float-left z-index-1 width-full" src="{{travelogue['BuehnenPfad']}}?h=170&w=170&mode=crop">
            <div class="primary-title z-index-1 text-align-left width-full box-sizing-border-box bottom-0 left-0 position-absolute padding-top-56 hidden-breakpoint-small">
              <div class="title display-block font-size-general bold" [innerText]="travelogue['Titel']"></div>
              <div class="subtitle display-block font-size-general" [innerText]="travelogue['Teaser']"></div>
            </div>
          </div>
        </div>
        <div class="grid col-small-8 col-medium-7">
          <div class="primary-title visible-breakpoint-small">
            <div class="title display-block font-size-general bold" [innerText]="travelogue['Titel']"></div>
            <div class="subtitle display-block font-size-general" [innerText]="travelogue['Teaser']"></div>
          </div>
          <div class="supplemental-actions padding-top-bottom-8 padding-left-right-0 position-absolute bottom-0 left-0 right-0">
            <div class="row-inner">
              <div class="grid offset-small-4 offset-medium-5 col-small-8 col-medium-7">
                <button class="mp-button details">{{ ls.locs['locReisekonfigurator'].Ansehen }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
