<div [ngClass]="{'invalid-error': hasError}" class="magma-input flex-grid flex-direction-row flex-wrap flex-justify-cont-start flex-align-items-end padding-left-right-8">
  <label class="flex-col-12 font-weight-700 font-size-xxs padding-left-right-0 display-block-important"
         for="{{mpIdHash}}">
    <span class="display-inline font-weight-700 font-size-xxs"
          [ngClass]="(!mpRequired && !mpDisabled) && labelText ? 'margin-right-4' : ''">{{labelText}}</span>
    <span *ngIf="(!mpRequired && !mpDisabled) && labelText && (ls.locsLoaded | async)"
          class="font-size-xxs text-color-grey-info display-inline">
      {{ ls.locs['loc'].OptionalField }}
    </span>
    <sup class="margin-left-4 text-color-grey-info"
         *ngIf="mpSup && labelText">
      {{mpSup}}
    </sup>
  </label>
  <input coreOnlyNumber
         [decimals]="0"
         class="flex-col-12 font-size-general padding-left-right-8 {{mpClass}}"
         type="{{mpType === '' ? 'text' : mpType}}"
         (ngModelChange)="modelChange.emit($event)"
         [(ngModel)]="model"
         id="{{mpIdHash}}"
         (focus)="executeEvent($event, 'focus')"
         (change)="executeEvent($event, 'change')"
         (blur)="executeEvent($event, 'blur')"
         (keyup)="executeKeyup($event)"
         [ngClass]="{active: (model !== undefined && model !== null && model !== '') || mpDisabled}"
         [required]="mpRequired"
         [disabled]="mpDisabled"
         mpCoreMpFocus
         [mpFocus]="focusOn"
         placeholder="{{mpPlaceholder}}" />
</div>
