<div class="layout-container" *ngIf="(ls.locsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['loc'].TipAndWinSpielstatistik }}</h1>
  <mp-core-data-table *ngIf="campaignsLoaded"
                      title="{{ ls.locs['loc'].TipAndWinSpielstatistik }}"
                      infotextShorten="Spielstatistik"
                      fieldsAction="/api/TipAndWin/GetSpielstatistikFields"
                      listAction="/api/TipAndWin/GetSpielstatistik"
                      loaderName="tipAndWinGameStatisticLoader"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="AktionId DESC, Rang"
                      [showDatePicker]="false"
                      [recLoadedFunc]="recLoadedFunc"
                      [disableCache]="true"
                      [headerSelectTransclude]="true">
    <mp-core-select [mpRequired]="true" [elements]="campaigns" textProperty="Name" valueProperty="AktionId" [model]="selectedCampaignId" (modelChange)="selectedCampaignId = $event; changeCampaign()" label="{{ ls.locs['loc'].Aktion }}"></mp-core-select>
  </mp-core-data-table>
  <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
</div>
