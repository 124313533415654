<section id="spielregeln" class="layout-container bg-color-white padding-bottom-48" *ngIf="(ls.locsLoaded | async)">
  <div class="white-content-container">
    <mp-core-breadcrumbs></mp-core-breadcrumbs>
    <h1>{{ ls.locs['loc'].Spielregeln }}</h1>
    <div class="spielregeln accordion">
      <p *ngIf="rulesOfGameOutside" [innerHTML]="trustAsHtml(rulesOfGameOutside['Text'])" class="margin-bottom-24"></p>
      <mp-core-accordion *ngIf="rulesOfGameLoaded" [accToggle]="true" [accOpenOnLoad]="defaultOpen" [accDataExpression]="getRulesOfGame" [sidebarAcc]="false"></mp-core-accordion>
    </div>
  </div>
</section>
