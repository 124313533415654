<div class="layout-container manager-text-editor" *ngIf="(mpSettings.settingsLoaded | async) && (ls.locsLoaded | async)">
  <div class="flex-grid position-relative">
    <a mpCoreSidebarHelpIcon
       #helpIcon="mpCoreSidebarHelpIcon"
       id="help-sidebar-icon"
       class="position-absolute right-4 top-4"
       [hidden]="!helpIcon.showHelpSidebar"
       (click)="helpIcon.openHelpSidebar($event)">
      <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].HelpSidebarHilfeAnzeigen }}" alignment="left">
        <i class="svg-icon __clr-info __size-32 __top-0 __info float-right-important sidebar-open"></i>
      </mp-core-tooltip>
    </a>
    <div id="nav-breadcrumb" class="display-flex flex-align-items-center margin-bottom-8">
      <div id="breadcrumb">
        <a class="mp-button text-color-grey-info" (click)="goBack($event)">{{ ls.locs['loc'].Zurueck }}</a>
        <span class="text-color-grey-info bold margin-left-8 margin-right-8">|</span>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].Editoren }}</a>
        <i class="svg-icon __clr-inactive __size-20 __top-5 __nav-next float-none-important default-cursor-hover"></i>
        <a class="mp-button text-color-grey-info font-weight-regular">{{ ls.locs['loc'].EMailTexte }}</a>
      </div>
    </div>
    <div class="margin-top-16 flex-col-12 flex-direction-column">
      <div class="flex-col-12 bg-color-white padding-16 flex-direction-column">
        {{ ls.locs['loc'].EMailTexte }}
      </div>
    </div>
    <section id="elig-requ-editor-content" class="flex-col-9 flex-col-8-l flex-col-7-m flex-col-12-s display-block-important margin-top-24">
      <div class="width-full bg-color-white padding-16 box-sizing-border-box">
        <ul class="magma-accordion editor-acc">
          <li *ngFor="let text of visibleEmailTexts; let index = index" id="acc-data-id-{{text['Name']}}" class="padding-left-right-0 padding-bottom-16 editor-Container" [ngClass]="{'accOpened': text['offen']}">
            <div class="display-flex margin-left-right-0 elig-requ-acc-flex-container">
              <div class="pointer-hover display-flex flex-align-items-center flex-justify-cont-between editor-accordions-ohne-loeschen padding-top-bottom-16 padding-left-8 padding-right-2 margin-left-right-0 acc-headline-open-trigger" (click)="text['offen'] = !text['offen']">
                <p class="margin-right-16">
                  <a [innerText]="text['Beschreibung'] || text['Name']"></a>
                </p>
                <i class="svg-icon __size-28 __top-0" [ngClass]="!text['offen'] ? '__clr-inactive __add' : '__clr-white __remove'"></i>
              </div>
            </div>
            <div id="editor-Container" [hidden]="!text['offen']" class="bg-color-white display-block padding-top-bottom-32 padding-left-right-16 overflow-hidden margin-top-8 margin-bottom-0">
              <div class="margin-bottom-16">
                <mp-core-input mpId="acc-editor-title" class="margin-bottom-16 display-block" [(model)]="text['Beschreibung']" label="{{ ls.locs['loc'].Beschreibung }}" [mpRequired]="true" [hasError]="errors['editorHeadline'] ? true : false"></mp-core-input>
                <p [hidden]="text['LetzteAusfuehrung'] || text['NaechsteAusfuehrung'] " class="margin-bottom-16">
                  <span [hidden]="!text['LetzteAusfuehrung']">{{ ls.locs['loc'].LetzterVersand }}: {{text['LetzteAusfuehrung']}}</span>
                  <span [hidden]="!text['NaechsteAusfuehrung']" class="float-right">{{ ls.locs['loc'].NaechsterVersand }}: {{text['NaechsteAusfuehrung']}}</span>
                </p>
                <mp-core-input mpId="acc-editor-title" class="margin-bottom-16 display-block" label="{{ ls.locs['loc'].Betreff }}" [(model)]="text['Subject']" [mpRequired]="true" [hasError]="errors['editorHeadline'] ? true : false"></mp-core-input>
                <div class="margin-bottom-16">
                  <mp-core-select mpId="ddlTBSText{{index}}" [(model)]="text['selectedTBS']" [elements]="tbsList" (ngChange)="tbsChange(text, index)" textProperty="Text" [mpRequired]="true" label="" placeholder="{{ ls.locs['loc'].Textbausteineinfuegen }}"></mp-core-select>
                </div>
                <textarea *ngIf="froalaOptionsLoaded" id="editor-{{index}}" [froalaEditor]="froalaOptions" [(froalaModel)]="text['Body']" class="form-control" name="editor-{{text['Id']}}"></textarea>
                <mp-core-form-error *ngIf="text['errors']" [model]="text['errors']"></mp-core-form-error>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section id="reward-editor-datapanel" class="flex-col-3 flex-col-4-l flex-col-5-m flex-col-12-s display-block-important margin-top-24">
      <div class="flex-col-12 flex-wrap padding-left-right-16 padding-bottom-32 bg-color-white width-full">
        <mp-core-select *ngIf="mpSettings.settings['NetcentiveCoreSettings'].HasMultipleLanguages"
                        class="width-full margin-bottom-16 margin-top-16"
                        mpId="reward-type"
                        [model]="language"
                        (modelChange)="language = $event; setFilteredMailTexts();"
                        [elements]="languages"
                        textProperty="Name"
                        valueProperty="Kuerzel"
                        [mpRequired]="true"
                        label="{{ ls.locs['loc'].Sprache }}">
        </mp-core-select>

        <div class="width-full margin-top-40">
          <mp-core-tooltip tooltipTitle="{{ ls.locs['loc'].EingabeZuruecksetzen }}" alignment="left" class="float-right-important">
            <button class="mp-button" (click)="hints['showRevertChanges'] = true">
              <i class="svg-icon __size-24 __clr-default __top-0 __refresh" id="elig-requ-refresh-icon"></i>
            </button>
          </mp-core-tooltip>
        </div>
        <button class="mp-button _prim margin-top-16 width-full" (click)="hints['showSaveEmailTexts'] = true">{{ ls.locs['loc'].SeiteVeroeffentlichen }}</button>
      </div>
    </section>
  </div>
  <mp-core-modal [show]="hints['showSaveEmailTexts']" title="{{ ls.locs['loc'].EMailTexteSpeichernHinweisTitel }}" [buttons]="[{text: ls.locs['loc'].Nein, action: setShowSaveEmailTextsFalse}, {text: ls.locs['loc'].Ja, action: saveFunc, class: '_prim'}]">
    {{ ls.locs['loc'].EMailTexteSpeichernHinweisText }}
  </mp-core-modal>
  <mp-core-modal [show]="hints['showRevertChanges']" title="{{ ls.locs['loc'].AenderungenRueckgaengigHinweisTitel }}" [buttons]="[{text: ls.locs['loc'].Nein, action: setShowRevertChangeFalse}, {text: ls.locs['loc'].Ja, action: loadDataFunc, class: '_prim'}]">
    {{ ls.locs['loc'].AenderungenRueckgaengigHinweisText }}
  </mp-core-modal>
</div>
