<div id="sidebar-locations-rental" class="rk-sidebar-form" [ngClass]="{noResultsMessage: noResults}">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="text-align-left">{{ ls.locs['locReisekonfigurator'].StandorteSidebarHeader }}</div>
    <div class="divider margin-bottom-8"></div>
    <mp-core-select label="{{ ls.locs['locReisekonfigurator'].AbholungRueckgabeSelect }}" mpId="rkFlugart" [model]="selection['AndereRueckgabe']" (modelChange)="selection['AndereRueckgabe'] = $event; changeOtherReturn();" [elements]="selection['RueckgabeOptions']" textProperty="Text" valueProperty="Value" class="padding-left-right-16 margin-left-0 box-sizing-border-box"></mp-core-select>
    <div class="margin-16 margin-top-24 display-flex" id="sidebar-tabs">
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-center pointer" [ngClass]="{ 'active-tab': selection['Abholung'] || !selection['AndereRueckgabe'] }" (click)="changeTab(true)">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['locReisekonfigurator'].Abholort }}</span>
      </div>
      <div class="padding-8 display-flex flex-direction-column flex-justify-cont-center pointer" [hidden]="!selection['AndereRueckgabe']" [ngClass]="{ 'active-tab': !selection['Abholung'] && selection['AndereRueckgabe']}" (click)="changeTab(false)">
        <span class="text-transform-uppercase font-size-xs">{{ ls.locs['locReisekonfigurator'].Rueckgabeort }}</span>
      </div>
    </div>
    <div class="overflow-hidden">
      <mp-core-search mpId="standorte-suche" [model]="selection['Suchbegriff']" (modelChange)="selection['Suchbegriff'] = $event; searchTermChange()" class="sb-search padding-16 display-block" mpPlaceholder="{{searchfieldPlaceholder}}" [focusOn]="focusOnInit">
          <i class="position-absolute-top-right svg-icon __clr-default __wide __top-0 __search"></i>
          <i [hidden]="!selection['Suchbegriff'] || selection['Suchbegriff'] && selection['Suchbegriff'].length <= 2" class="svg-icon __clr-default __size-18 __top-8 __clear position-absolute-top-right margin-right-48 margin-top-16" (click)="clearSearch($event)"></i>
       </mp-core-search>
    </div>
    <div [hidden]="!selection['standorteAbholung'] || selection['standorteAbholung'].length === 0 || !selection['Abholung'] || showAllSelectedLocationsCollection " class="padding-16" id="selected-destination">
      <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['locReisekonfigurator'].IhreAusgewaehltenAbholorte }}</h3>
      <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open" *ngFor="let item of selection['standorteAbholung'].slice(0, 4)">
        <span class="icon-text sidebar-open">{{item.Name+(item.IsDuplicate ? ' ('+item.TypeKuerzel+')' : '')}}</span>
        <i class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8" (click)="setGeoFilter(item, selection['standorteAbholung'])"></i>
      </div>
      <button class="mp-button"
              [hidden]="selection['standorteAbholung'].length <= 4 "
              (click)="toggleShowAllSelectedLocastionsCollection()">
        {{ ls.locs['loc'].AlleAnzeigen }}
      </button>
    </div>
    <div class="divider margin-bottom-8 margin-left-16" [hidden]="!selection['standorteAbholung'] || selection['standorteAbholung'].length === 0 || !selection['Abholung'] || showAllSelectedLocationsCollection"></div>
    <div [hidden]="!selection['standorteRueckgabe'] || selection['standorteRueckgabe'].length === 0 || selection['Abholung'] || showAllSelectedLocationsReturn" class="padding-16" id="selected-destination">
      <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['locReisekonfigurator'].IhreAusgewaehltenRueckgabeorte }}</h3>
        <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open" *ngFor="let item of selection['standorteRueckgabe'].slice(0, 4)">
          <span class="icon-text sidebar-open">{{item.Name}}</span>
          <i class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8" (click)="setGeoFilter(item, selection['standorteRueckgabe'])"></i>
        </div>
        <button class="mp-button"
                [hidden]="selection['standorteRueckgabe'].length <= 4 "
                (click)="toggleShowAllSelectedLocastionsReturn()">
          {{ ls.locs['loc'].AlleAnzeigen }}
        </button>
    </div>
    <div class="divider margin-bottom-8 margin-left-16" [hidden]="!selection['standorteRueckgabe'] || selection['standorteRueckgabe'].length === 0 || selection['Abholung'] || showAllSelectedLocationsReturn"></div>
    <mp-core-info-notification [hidden]="!noResults" iconPosition="left" infoState="warning" headline="{{ ls.locs['locReisekonfigurator'].StandortUeberpruefenTitel }}" copyText="{{ ls.locs['locReisekonfigurator'].StandortUeberpruefenText }}" class="padding-left-right-16"></mp-core-info-notification>
    <mp-core-info-notification [hidden]="!noSelection" iconPosition="left" infoState="warning" headline="{{ ls.locs['locReisekonfigurator'].StandortUeberpruefenTitel }}" copyText="{{ ls.locs['locReisekonfigurator'].StandortUeberpruefenTextKeineEingabe }}" class="padding-left-right-16"></mp-core-info-notification>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#standorte-scroll-area" scrollDirection="up" scrollFactor="1" [scopeObject]="selection" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-right-16 padding-right-16 scroll-area-container" id="standorte-scroll-area-container">
      <div id="standorte-scroll-area" class="sidebar-scroll-area position-relative" [hidden]="(selection['Suchbegriff'] && selection['Suchbegriff'].length <= 2) || showAllSelectedLocationsCollection || showAllSelectedLocationsReturn">
        <div [hidden]="!locations || locations.length === 0" class="margin-top-24">
          <div *ngFor="let location of locations | orderBy: 'RegionName'" class="repeat-locations">
            <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{location.RegionName}}</h3>
            <div *ngFor="let key of location.ChildNodeKeys">
              <h4 class="font-size-xs">{{key}}</h4>
              <span *ngFor="let station of location.ChildNodes[key]"
                    (click)="setGeoFilter(station, selection['Abholung'] ? selection['standorteAbholung'] : selection['standorteRueckgabe'])"
                    class="display-block display-flex margin-bottom-8 pointer repeat-locations"
                    [ngClass]="{'destination-active': station.active}">
                <span class="svg-icon __clr-info __wide-28 __top--2 __room"></span>
                <span mpCoreHighlight
                     #highlightDirective="mpCoreHighlight"
                     highlight="{{ selection['Suchbegriff'] }}"
                     originalText="{{ station.Name }}"
                     [innerHTML]="highlightDirective.highlightedText !== '' ? highlightDirective.highlightedText : station.Name"></span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="!selection['standorteAbholung'] || selection['standorteAbholung'].length === 0 || !selection['Abholung'] || !showAllSelectedLocationsCollection"
            class="sidebar-scroll-area position-relative"
            id="multiselect-scroll-area">
        <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['loc'].Ausgewaehlt }}:</h3>
        <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open" *ngFor="let item of selection['standorteAbholung']">
          <span class="icon-text sidebar-open">{{item.Name}}</span>
          <i class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8" (click)="setGeoFilter(item, selection['standorteAbholung'])"></i>
        </div>
        <button class="mp-button"
                (click)="toggleShowAllSelectedLocastionsCollection()">
          {{ ls.locs['loc'].AuswahlOeffnen }}
        </button>
      </div>

      <div [hidden]="!selection['standorteRueckgabe'] || selection['standorteRueckgabe'].length === 0 || selection['Abholung'] || !showAllSelectedLocationsReturn"
            class="sidebar-scroll-area position-relative"
            id="multiselect-scroll-area">
        <h3 class="margin-top-8 margin-bottom-8 font-size-general font-weight-700">{{ ls.locs['loc'].Ausgewaehlt }}:</h3>
        <div class="chip default-cursor font-weight-regular display-inline-flex flex-align-items-center flex-justify-cont-between padding-top-bottom-4 padding-left-right-0 position-relative sidebar-open" *ngFor="let item of selection['standorteRueckgabe']">
          <span class="icon-text sidebar-open">{{item.Name}}</span>
          <i class="svg-icon __clr-specific-1 __size-18 __top-0 __clear sidebar-open margin-left-8" (click)="setGeoFilter(item, selection['standorteRueckgabe'])"></i>
        </div>
        <button class="mp-button"
                (click)="toggleShowAllSelectedLocastionsReturn()">
          {{ ls.locs['loc'].AuswahlOeffnen }}
        </button>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#standorte-scroll-area" scrollDirection="down" scrollFactor="1" [scopeObject]="locations" class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].EingabeBestaetigen }}</a>
    </div>
  </div>
</div>
