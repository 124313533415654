<div *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="flex-grid margin-left-right-0" id="taw-day-tip-tabs">

    <div *ngIf="showTipps || showTippsCommingSoon"
         class="flex-col-3 flex-col-3-s flex-col-12-xs padding-top-bottom-8 padding-left-right-16 text-transform-uppercase flex-align-items-center font-size-s pointer"
         (click)="setTab('tagestipps')"
         [ngClass]="tabVisible === 'tagestipps' ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-light-grey'">
      {{ ls.locs['locTipAndWin'].Tagestipps }}
      <span class="badge position-relative margin-left-8 display-flex flex-align-items-center flex-justify-cont-center"
            *ngIf="showTipps && tipAndWinService.openTips > 0"
            [innerText]="tipAndWinService.openTips"></span>
    </div>

    <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideTaeglichesQuiz"
         class="flex-col-3 flex-col-3-s flex-col-12-xs padding-top-bottom-8 padding-left-right-16 text-transform-uppercase flex-align-items-center font-size-s pointer"
         (click)="setTab('taeglichesQuiz')"
         [ngClass]="tabVisible === 'taeglichesQuiz' ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-light-grey'">
      {{ ls.locs['locTipAndWin'].TaeglichesQuiz }}
      <span class="badge position-relative margin-left-8 display-flex flex-align-items-center flex-justify-cont-center"
            *ngIf="tipAndWinService.openQuestions > 0"
            [innerText]="tipAndWinService.openQuestions"></span>
    </div>

    <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideZusatzfragen"
         class="flex-col-3 flex-col-3-s flex-col-12-xs padding-top-bottom-8 padding-left-right-16 text-transform-uppercase flex-align-items-center font-size-s pointer"
         (click)="setTab('zusatzfragen')"
         [ngClass]="tabVisible === 'zusatzfragen' ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-light-grey'">
      {{ ls.locs['locTipAndWin'].Zusatzfragen }}
      <span class="badge position-relative margin-left-8 display-flex flex-align-items-center flex-justify-cont-center"
            *ngIf="tipAndWinService.openAdditionalQuestions > 0"
            [innerText]="tipAndWinService.openAdditionalQuestions"></span>
    </div>

    <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HidePerformance"
         class="flex-col-3 flex-col-3-s flex-col-12-xs padding-top-bottom-8 padding-left-right-16 text-transform-uppercase flex-align-items-center font-size-s pointer"
         (click)="setTab('performance')"
         [ngClass]="tabVisible === 'performance' ? 'bg-color-brand-primary text-color-brand-secondary' : 'bg-color-light-grey'">
      {{ ls.locs['locTipAndWin'].Performance }}
      <span class="badge position-relative margin-left-8 display-flex flex-align-items-center flex-justify-cont-center"
            *ngIf="tipAndWinService.openPerformance > 0"
            [innerText]="tipAndWinService.openPerformance"></span>
    </div>
  </div>
  <div>
    <mp-taw-tip-and-win-match-tips *ngIf="showTipps || showTippsCommingSoon" [hidden]="tabVisible !== 'tagestipps'"></mp-taw-tip-and-win-match-tips>
    <mp-taw-tip-and-win-daily-quiz *ngIf="!mpSettings.settings['TipAndWinSettings'].HideTaeglichesQuiz" [hidden]="tabVisible !== 'taeglichesQuiz'"></mp-taw-tip-and-win-daily-quiz>
    <mp-taw-tip-and-win-additional-questions *ngIf="!mpSettings.settings['TipAndWinSettings'].HideZusatzfragen" [hidden]="tabVisible !== 'zusatzfragen'"></mp-taw-tip-and-win-additional-questions>
    <mp-taw-tip-and-win-performance *ngIf="!mpSettings.settings['TipAndWinSettings'].HidePerformance" [hidden]="tabVisible !== 'performance'"></mp-taw-tip-and-win-performance>
  </div>
</div>
