import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MpMenuService {

  public activeMenuItem: any = undefined;

  constructor() { }

  setActiveMenuItem(item: any): void {
    this.activeMenuItem = item;
  }
}
