import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { MpLocalizationService } from './../../services/mp-localization.service';

/**
 * This class provides a simple tooltip, that
 * can be configured with different tooltip attributes
 * (e.g. for tooltip titles, for alignment).
 */
@Component({
  selector: 'mp-core-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit {
  @Input() public tooltipTitle: string = '';
  @Input() public alignment: string = '';

  constructor(public ls: MpLocalizationService) { }

  ngOnInit(): void {
    
  }

}
