import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpSettingsService } from '@core/services/mp-settings.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { MpSidebarService } from '@core/components/sidebar/mp-sidebar.service';
import { ApiService } from '@core/services/api.service';
import { MpCoreService } from '@core/services/mp-core.service';
import { TcSearchParamsService } from './../../../../services/tc-search-params.service';

/**
 * This class provides the data and
 * functions for the travel activities
 * editor.
 */
@Component({
  selector: 'mp-rk-travel-activities-editor',
  templateUrl: './travel-activities-editor.component.html',
  styleUrls: [
    './../../../participant/pages/travel-configurator/travel-configurator.component.scss',
    './../../../../components/sidebar/styles/tc-sidebars.scss',
    './../../../../components/search-area/styles/tc-search.scss',
    './travel-activities-editor.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TravelActivitiesEditorComponent implements OnInit, OnDestroy {

  public travelType: { [key: string]: any } = {};
  public travelTypeLoaded: boolean = false;
  public params: { [key: string]: any } = {};
  public tab: number = 0;
  public minHotelCategory: number = 0;
  public stage: { [key: string]: any } = {};
  public activity: { [key: string]: any } = {};
  public stages: Array<any> = [];
  public showTcFilter: boolean = false;
  public title: string = '';
  public offsettingHint: string = '';
  public success: boolean = false;
  public errors: { [key: string]: any } = {};

  private _activityId: string = '';
  private _routeParams: { [key: string]: any } = {};
  private _getEnumsSubscription: Subscription | undefined;
  private _getSettingsSubscription: Subscription | undefined;
  private _routeParamsSubscription: Subscription | undefined;
  private _getStagesSubscription: Subscription | undefined;
  private _getActivitySubscription: Subscription | undefined;
  private _saveSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _mpEnums: MpEnumsService,
    private _tcSearchParamsService: TcSearchParamsService,
    private _mpSidebar: MpSidebarService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _mpCoreService: MpCoreService
  ) { }

  /**
   * Gets the route params and the settings.
   */
  ngOnInit(): void {
    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      this._routeParams = params;

      if (typeof this._routeParams['id'] !== 'undefined') {
        this._activityId = this._routeParams['id'];
      } else {
        this._activityId = '';
      }

      if (typeof this._routeParamsSubscription !== 'undefined') {
        this._routeParamsSubscription.unsubscribe();
      }

      if (Object.keys(this.mpSettings.settings).length > 0) {
        this._getEnums();

        if (typeof this._getSettingsSubscription !== 'undefined') {
          this._getSettingsSubscription.unsubscribe();
        }
      } else {
        this._getSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
          this._getEnums();

          if (typeof this._getSettingsSubscription !== 'undefined') {
            this._getSettingsSubscription.unsubscribe();
          }
        });

        this.mpSettings.getSettings();
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getEnumsSubscription !== 'undefined') {
      this._getEnumsSubscription.unsubscribe();
    }

    if (typeof this._getSettingsSubscription !== 'undefined') {
      this._getSettingsSubscription.unsubscribe();
    }

    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }

    if (typeof this._getStagesSubscription !== 'undefined') {
      this._getStagesSubscription.unsubscribe();
    }

    if (typeof this._getActivitySubscription !== 'undefined') {
      this._getActivitySubscription.unsubscribe();
    }

    if (typeof this._saveSubscription !== 'undefined') {
      this._saveSubscription.unsubscribe();
    }
  }

  /**
   * Gets the enums from the backend.
   */
  private _getEnums(): void {
    if (Object.keys(this._mpEnums.enums).length > 0) {
      this._doInitialDataHandling();

      if (typeof this._getSettingsSubscription !== 'undefined') {
        this._getSettingsSubscription.unsubscribe();
      }

      if (typeof this._getEnumsSubscription !== 'undefined') {
        this._getEnumsSubscription.unsubscribe();
      }
    } else {
      this._getEnumsSubscription = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._doInitialDataHandling();

          if (typeof this._getSettingsSubscription !== 'undefined') {
            this._getSettingsSubscription.unsubscribe();
          }

          if (typeof this._getEnumsSubscription !== 'undefined') {
            this._getEnumsSubscription.unsubscribe();
          }
        }
      });

      this._mpEnums.getEnums();
    }
  }

  /**
   * Stes the initially tab, and gets the
   * activity from the backend.
   */
  private _doInitialDataHandling(): void {
    this.travelType = this._mpEnums.enums['Reisetyp'];
    this.travelTypeLoaded = true;

    if (this.tab === 0) {
      this.setTab(this.mpSettings.settings['ReisekonfiguratorSettings'].ShowHotel ? this.travelType['NurHotel'] : (this.mpSettings.settings['ReisekonfiguratorSettings'].ShowHotelUndFlug ? this.travelType['HotelUndFlug'] : (this.mpSettings.settings['ReisekonfiguratorSettings'].ShowNurFlug ? this.travelType['NurFlug'] : 0)));
    }

    const min = (nums: Array<any>) => {
      if (nums.length) {
        return Math.min(...nums);
      } else {
        return 0;
      }
    }

    this.minHotelCategory = min(this.mpSettings.settings['ReisekonfiguratorSettings'].AvailableHotelCategories);

    if (this._activityId !== '') {
      this._getActivitySubscription = this._apiService.getRequest('/api/RkAktion/GetAktion?id=' + this._activityId).subscribe((data: any) => {
        if (data.Result === 'OK' && data.Records[0]) {
          const activitiy = data.Records[0];
          this.title = activitiy['Titel'];
          this.offsettingHint = activitiy['Aktionscode'];
          this.stage = activitiy['ThemenbuehnenId'];
          this._tcSearchParamsService.setParams(JSON.parse(activitiy['Suchparameter']));
          this._getParams();
          this.showTcFilter = true;
        }
      });
    } else {
      this.showTcFilter = true;
      this._getParams();
    }

    this._getStagesSubscription = this._apiService.getRequest('/api/Home/GetEditierbareHomeBuehnen').subscribe((data: any) => {
      this.stages = data.Records;
    });
  }

  /**
   *  Sets the given tab to active.
   */
  setTab(tab: number): void {
    this.tab = tab;
  }

  /**
   * Validates the search params, and the
   * travel type.
   */
  private _validate(params: { [key: string]: any }, travelType: number): boolean {
    if (travelType === this.travelType['NurFlug']) {
      if (!(params['flugZielflughafen'] &&
        params['flugZielflughafen'].flughaefen &&
        params['flugZielflughafen'].flughaefen.length > 0)) {
        params['flugZielflughafen'].noSelection = true;
        this._mpSidebar.open('sidebarAirport', params['flugZielflughafen']);
        return false;
      }

      if (!(params['flugAbflughafen'] &&
        params['flugAbflughafen'].flughaefen &&
        params['flugAbflughafen'].flughaefen.length > 0)) {
        params['flugAbflughafen'].noSelection = true;
        this._mpSidebar.open('sidebarAirport', params['flugAbflughafen']);
        return false;
      }
    }
    if (travelType === this.travelType['HotelUndFlug'] || travelType === this.travelType['NurHotel']) {
      if (!(params['reisezieleHotel'] &&
        params['reisezieleHotel'].ziele &&
        params['reisezieleHotel'].ziele.length > 0)) {
        params['reisezieleHotel'].noSelection = true;
        this._mpSidebar.open('sidebarTravelDestinationsHotel', params['reisezieleHotel']);
        return false;
      }
    }
    if (travelType === this.travelType['HotelUndFlug']) {
      if (!(params['flugHotelUndFlug'] &&
        params['flugHotelUndFlug'].flughaefen &&
        params['flugHotelUndFlug'].flughaefen.length > 0)) {
        params['flugHotelUndFlug'].noSelection = true;
        this._mpSidebar.open('sidebarAirport', params['flugHotelUndFlug']);
        return false;
      }
    }

    return true;
  }

  /**
   * Gets the search parameters.
   */
  private _getParams(): void {
    const parameters = this._tcSearchParamsService.getParams() || {};
    this.tab = parameters['Reiseart'] || this.tab;
  }

  /**
   * Saves the activity.
   */
  save(): void {
    const travelType = this.tab;
    this.success = false;

    if (!this._validate(this.params, travelType)) {
      return;
    }

    this._tcSearchParamsService.setParamsForSearch(this.params, travelType);

    this._saveSubscription = this._apiService.postRequest('/api/RkAktion/SaveAktion', {
      Id: parseInt(this._activityId || '-1'),
      Titel: this.title,
      Aktionscode: this.offsettingHint,
      BuehnenKey: this.stage,
      Suchparameter: JSON.stringify(this._tcSearchParamsService.getParams())
    }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this.errors = {};
        this.success = true;
        this._activityId = data.Records[0];
      }
    },
    (error: any) => {
      this.errors = error['ModelState'];
    });
  }

  /**
   * Triggers the global goBack function.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

}
