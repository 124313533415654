import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { ApiService } from './../../../../services/api.service';
import { MpCommunicationProcessService } from './../../../../services/mp-communication-process.service';
import { AuthService } from './../../../../services/auth.service';
import { MpSettingsService } from './../../../../services/mp-settings.service';
import { MpCoreService } from './../../../../services/mp-core.service';

/**
 * This class provides the data and
 * functions for the reward editor.
 */
@Component({
  selector: 'mp-core-reward-editor',
  templateUrl: './reward-editor.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RewardEditorComponent implements OnInit, OnDestroy {

  public communicationProcess: boolean = false;
  public editable: boolean = true;
  public reward: { [key: string]: any } = {};
  public rewardType: { [key: string]: any } = {};
  public rewardHeight: { [key: string]: any } = {};
  public rewardHalfRoundedHeight: { [key: string]: any } = {};
  public rewardRoundedHeight: { [key: string]: any } = {};
  public errors: { [key: string]: any } = {};
  public rewardTypes: Array<any> = [];
  public languages: Array<any> = [];
  public rewardDate: { [key: string]: any } = {};
  public anniversaryElements: Array<any> = this._range(5, 61, 5);
  public rewardTypeLoaded: boolean = false;
  public setTitleChangeManuallyFunc = this.setTitleChangeManually.bind(this);
  public setPointsKeyUpFunc = this._setPointsKeyUpFunc.bind(this);

  private _cpKey: string = 'belohnungs-editor';
  private _rewardEditorPointsStep: number = 0;
  private _rewardEditorEuroStep: number = 0;
  private _showCpSkipStepConfirm: boolean = false;
  private _rewardLoaded: boolean = false;
  private _rewardTypesLoaded: boolean = false;
  private _repetitionOptionsLoaded: boolean = false;
  private _savedReward: { [key: string]: any } = {};
  private _defaultReward: { [key: string]: any } = {
    Belohnungsart: '',
    Titel: '',
    Sprache: '',
    Wiederholungsart: -1,
    BelohnungsHoehe: 0
  };
  private _titleChangedManually: boolean = false;
  private _updateIndizesRewardHeightFromPoints: { [key: string]: any } = {};
  private _updateIndizesRewardHeightFromEuro: { [key: string]: any } = {};
  private _loadSettingsSubscription: Subscription | undefined;
  private _loadLocsSubscription: Subscription | undefined;
  private _queryParamsSubscription: Subscription | undefined;
  private _cpStepsSubscription: Subscription | undefined;
  private _getRewardTypesSubscription: Subscription | undefined;
  private _getLanguagesSubscription: Subscription | undefined;
  private _getIterationsSubscription: Subscription | undefined;
  private _getPointsFromEuroSubscription: Subscription | undefined;
  private _getEuroFromPointsSubscription: Subscription | undefined;
  private _getPointsFromEuroSetPointsSubscription: Subscription | undefined;
  private _getEuroFromPointsSetPointsSubscription: Subscription | undefined;
  private _repetitionKind =  {
    oneTime: 1,
    monthly: 4,
    annual: 5,
    every5Years: 6,
    every10Years: 7
  };

  constructor(
    public ls: MpLocalizationService,
    public mpSettings: MpSettingsService,
    private _apiService: ApiService,
    public cp: MpCommunicationProcessService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _mpCoreService: MpCoreService
  ) { }

  ngOnInit(): void {
    if (Object.keys(this.mpSettings.settings).length > 0) {
      this._rewardEditorPointsStep = this.mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorPunkteStep;
      this._rewardEditorEuroStep = this.mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorEuroStep;
    } else {
      this._loadSettingsSubscription = this.mpSettings.settingsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._rewardEditorPointsStep = this.mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorPunkteStep;
          this._rewardEditorEuroStep = this.mpSettings.settings['BelohnungsEditorSettings'].BelohnungseditorEuroStep;
        }
      });

      this.mpSettings.getSettings();
    }

    if (Object.keys(this.ls.locs).length > 0) {
      this._initContent();
    } else {
      this._loadLocsSubscription = this.ls.locsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this._initContent();
        }
      });

      this.ls.getLocalization();
    }

    this._queryParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['p'] !== 'undefined' && params['p'] === '1') {
        this.communicationProcess = true;
        this.cp.isValidStep(this._cpKey);
        this.loadStep();

        this._cpStepsSubscription = this.cp.stepsObserver.subscribe((steps: { [key: string]: any }) => {
          if (Object.keys(this.cp.steps).length > 0) {
            this.cp.steps[this._cpKey].Save = this.saveStep;
          }
        });
      }
    });

    this._getRewardTypesSubscription = this._apiService.getRequest('/api/Belohnung/GetBelohnungsarten').subscribe((data: any) => {
      this.rewardTypes = data.Records;
      this._rewardTypesLoaded = true;
      this._initData();
    });

    this._getLanguagesSubscription = this._apiService.getRequest('/api/Language/GetLanguagesForDropdown').subscribe((data: any) => {
      if (data.Result === 'OK' && data.Records.length === 1 && data.Records[0].Languages) {
        this.languages = data.Records[0].Languages;
        this._defaultReward['Sprache'] = this.languages[0].Kuerzel;
        this.reward['Sprache'] = this.languages[0].Kuerzel;
      }
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._loadSettingsSubscription !== 'undefined') {
      this._loadSettingsSubscription.unsubscribe();
    }

    if (typeof this._loadLocsSubscription !== 'undefined') {
      this._loadLocsSubscription.unsubscribe();
    }

    if (typeof this._queryParamsSubscription !== 'undefined') {
      this._queryParamsSubscription.unsubscribe();
    }

    if (typeof this._cpStepsSubscription !== 'undefined') {
      this._cpStepsSubscription.unsubscribe();
    }

    if (typeof this._getRewardTypesSubscription !== 'undefined') {
      this._getRewardTypesSubscription.unsubscribe();
    }

    if (typeof this._getLanguagesSubscription !== 'undefined') {
      this._getLanguagesSubscription.unsubscribe();
    }

    if (typeof this._getIterationsSubscription !== 'undefined') {
      this._getIterationsSubscription.unsubscribe();
    }

    if (typeof this._getPointsFromEuroSubscription !== 'undefined') {
      this._getPointsFromEuroSubscription.unsubscribe();
    }

    if (typeof this._getEuroFromPointsSubscription !== 'undefined') {
      this._getEuroFromPointsSubscription.unsubscribe();
    }

    if (typeof this._getEuroFromPointsSetPointsSubscription !== 'undefined') {
      this._getEuroFromPointsSetPointsSubscription.unsubscribe();
    }

    if (typeof this._getPointsFromEuroSetPointsSubscription !== 'undefined') {
      this._getPointsFromEuroSetPointsSubscription.unsubscribe();
    }
  }

  /**
   * Polyfill for underscore.js range.
   */
  private _range(start: number, end?: number, increment?: number): Array<number> {
    const isEndDef = typeof end !== 'undefined';
    end = isEndDef ? end : start;
    start = isEndDef ? start : 0;
    let length = 0;

    if (typeof increment === 'undefined' && typeof end !== 'undefined') {
      increment = Math.sign(end - start);
    }

    if (typeof end !== 'undefined') {
      length = Math.abs((end - start) / (increment || 1));
    }

    const { result } = Array.from({ length }).reduce(
      ({ result, current }) => ({
        result: [...result, current],
        current: current + increment,
      }),
      { current: start, result: [] }
    );

    return result;
  }

  /**
   * Resets the reward editor.
   */
  private _reset(): void {
    const baseReward = Object.keys(this._savedReward).length > 0 ? this._savedReward : this._defaultReward;
    this.reward = JSON.parse(JSON.stringify(baseReward));
    this.rewardHeight = { Punkte: baseReward['BelohnungsHoehe'] || this._rewardEditorPointsStep, Euro: baseReward['BelohnungsHoeheEuro'] || this._rewardEditorEuroStep };
    this.rewardRoundedHeight = { Punkte: baseReward['PunkteRund'] || this._rewardEditorPointsStep, Euro: baseReward['EuroRund'] || this._rewardEditorEuroStep };
    this.rewardHalfRoundedHeight = { Punkte: baseReward['PunkteHalbrund'] || this._rewardEditorPointsStep, Euro: baseReward['EuroHalbrund'] || this._rewardEditorEuroStep };

    if (this.reward['Belohnungsart']) {
      const foundRewardType = this.rewardTypes.find((type: any) => {
        return type['Key'] === this.reward['Belohnungsart'];
      });

      this.rewardType = typeof foundRewardType === 'undefined' ? {} : foundRewardType;
      this.rewardTypeLoaded = true;
    }

    if (this.reward['Titel'] && this._getTitle() !== this.reward['Titel']) {
      this._titleChangedManually = true;
    } else {
      this._setTitle();
    }

    if (Object.keys(this.rewardType).length > 0) {
      this.rewardTypeChanged();
    }

    if (Object.keys(this._savedReward).length > 0) {
      this.rewardHeight['Punkte'] = baseReward['BelohnungsHoehe'] || this._rewardEditorPointsStep;
      this.rewardRoundedHeight['Punkte'] = baseReward['PunkteRund'] || this._rewardEditorPointsStep;
      this.rewardHalfRoundedHeight['Punkte'] = baseReward['PunkteHalbrund'] || this._rewardEditorPointsStep;
    }

    this.setPoints(this.rewardHeight['Punkte'], this.rewardHeight, 'BelohnungsHoehe');
    this.setPoints(this.rewardRoundedHeight['Punkte'], this.rewardRoundedHeight, 'PunkteRund');
    this.setPoints(this.rewardHalfRoundedHeight['Punkte'], this.rewardHalfRoundedHeight, 'PunkteHalbrund');
  }

  /**
   * Gets and sets some data, regarding
   * the reward date.
   */
  private _initContent(): void {
    this.rewardDate = {
      text: this.ls.locs['loc'].BelohnungsdatumUndWiederholung,
      repetitionOptions: [],
      minDate: moment(),
      save: () => {
        if (this.rewardDate['repetition']) {
          this.rewardDate['text'] = this.rewardDate['repetition'].value;
          if (this.rewardDate['rangeStart']) {
            if (!this.rewardDate['rangeEnd'] || this.rewardDate['rangeStart'] === this.rewardDate['rangeEnd']) {
              if (this.rewardDate['repetition'].id === 1) {
                this.rewardDate['text'] = this.ls.locs['loc'].ZeitpunktText
                  .replace('{0}', this.rewardDate['repetition'].value)
                  .replace('{1}', this.rewardDate['rangeStart'].format('L'));
              } else {
                this.rewardDate['text'] = this.ls.locs['loc'].ZeitraumOffenText2
                  .replace('{0}', this.rewardDate['repetition'].value)
                  .replace('{1}', this.rewardDate['rangeStart'].format('L'));
              }
            } else {
              this.rewardDate['text'] = this.ls.locs['loc'].ZeitraumWiederholungText
                .replace('{0}', this.rewardDate['repetition'].value)
                .replace('{1}', this.rewardDate['rangeStart'].format('L'))
                .replace('{2}', this.rewardDate['rangeEnd'].format('L'));
            }
          }
        } else if (this.rewardDate['rangeStart']) {
          this.rewardDate['text'] = this.ls.locs['loc'].ZeitpunktText
            .replace('{0}', this.ls.locs['loc'].Einmalig)
            .replace('{1}', this.rewardDate['rangeStart'].format('L'));
        } else {
          this.rewardDate['text'] = this.ls.locs['loc'].BelohnungsdatumUndWiederholung;
        }

        if (Object.keys(this.reward).length > 0) {
          this.reward['Wiederholungsart'] = this.rewardDate['repetition'] && this.rewardDate['repetition'].id || this._repetitionKind.oneTime;
          this.reward['StartDatum'] = this.rewardDate['rangeStart'];
          this.reward['EndDatum'] = this.rewardDate['rangeEnd'];
          this.reward['WannWirdBelohnt'] = this.rewardDate['text'];
        }

        this._setTitle();
      }
    };

    this._getIterationsSubscription = this._apiService.getRequest('/api/Belohnung/GetWiederholungen').subscribe((data: any) => {
      this.rewardDate['repetitionOptions'] = data.Records;
      this._repetitionOptionsLoaded = true;
      this._initData();
    });
  }

  /**
   * Inits the data for the reward.
   */
  private _initData(): void {
    //if (Object.keys(this.ls.locs).length === 0
    //  || !(Object.keys(this.rewardTypes).length)
    //  || Object.keys(this.reward).length === 0
    //  || !(Object.keys(this.rewardDate).length && this.rewardDate['repetitionOptions'] && this.rewardDate['repetitionOptions'].length)
    //  || !(Object.keys(this.languages).length)
    //  || !this._rewardLoaded) {
    //  return;
    //}

    if (this._rewardTypesLoaded && this._repetitionOptionsLoaded && this._rewardLoaded) {

      this._reset();

      // set rewardType, if not set before
      if (this.reward['Belohnungsart'] && (Object.keys(this.rewardType).length === 0)) {
        const foundRewardType = this.rewardTypes.find((type: any) => {
          return type['Key'] === this.reward['Belohnungsart'];
        });

        this.rewardType = typeof foundRewardType === 'undefined' ? {} : foundRewardType;
        this.rewardTypeLoaded = true;
      }

      // set rewardDate (repetition, rangeStart, rangeEnd)
      if (Object.keys(this.reward).length > 0 && (this.reward['Wiederholungsart'] || this.reward['StartDatum'])) {
        this.rewardDate['repetition'] = this.rewardDate['repetitionOptions'].find((options: any) => {
          return options['id'] === this.reward['Wiederholungsart'];
        });

        this.rewardDate['rangeStart'] = moment(this.reward['StartDatum']);

        if (this.reward['EndDatum']) {
          this.rewardDate['rangeEnd'] = moment(this.reward['EndDatum']);
        }

        this.rewardDate['save']();
      }

      if (this.reward['BelohnungsHoehe']) {
        this.setPoints(this.reward['BelohnungsHoehe'], this.rewardHeight, 'BelohnungsHoehe');
      }

      if (this.reward['Belohnungsart'] === 'geburtstag') {
        this.setPoints(this.reward['PunkteHalbrund'], this.rewardHalfRoundedHeight, 'PunkteHalbrund');
        this.setPoints(this.reward['PunkteRund'], this.rewardRoundedHeight, 'PunkteRund');
      }
    }
  }

  /**
   * Loads the data for the step
   * within the communication process.
   */
  loadStep(): void {
    const data = this.cp.loadStep(this._cpKey);

    if (this.cp.checkStepIsSkipped(this._cpKey)) {
      this._showCpSkipStepConfirm = true;
    }

    if (!data) {
      this._rewardLoaded = true;
      this._initData();
      return;
    }

    this._savedReward = data;
    this._rewardLoaded = true;
    this._initData();
  }

  /**
   * Saves the cp step.
   */
  saveStep(): void {
    this._setTitleFromReward();

    if (Object.keys(this.rewardType).length === 0) {
      return;
    }

    let data = JSON.parse(JSON.stringify(this.reward));
    data['WasWirdBelohnt'] = this.rewardType['Bezeichnung'];
    data['BelohnungsHoeheEuro'] = this.rewardHeight['Euro'];
    data['EuroHalbrund'] = this.rewardHalfRoundedHeight['Euro'];
    data['EuroRund'] = this.rewardRoundedHeight['Euro'];
    data['Freigegeben'] = false;
    this.cp.saveStep(this._cpKey, data);
    this.cp.saveStep('titel', this.reward['Titel']);
  }

  /**
   * Skips the cp step.
   */
  skipStep(): void {
    this.cp.skipStep(this._cpKey);
    this._showCpSkipStepConfirm = false;
  };

  /**
   * Does the cp step.
   */
  doStep(): void {
    this.cp.confirmStep(this._cpKey);
    this._showCpSkipStepConfirm = false;
  };

  /**
   * Saves the communication process.
   */
  saveCommunicationProcess(): void {
    this.saveStep();
    this.cp.save();
  }

  /**
   * Gets the title from the step of
   * the communication process.
   */
  private _getTitle(): string {
    this.cp.setDefaultTitle();
    return this.cp.loadStep('titel');
  }

  /**
   * Sets the title.
   */
  private _setTitle(): void {
    let title = this._getTitle();
    this.reward['Titel'] = title;
  }

  /**
   * Sets titleChangedManually to true;
   */
  setTitleChangeManually(): void {
    this._titleChangedManually = true;
  }

  /**
   * Sets the title from the reward.
   */
  private _setTitleFromReward(): void {
    if (Object.keys(this.rewardType).length > 0) {
      if (!this._titleChangedManually || this.communicationProcess) {
        const particpantChoiceData = this.cp.loadStep('teilnehmer-auswahl');
        const participantData = this.cp.loadStep('teilnehmer');
        const whenReward = (Object.keys(this.reward).length > 0 && this.reward['WannWirdBelohnt'])
          ? this.reward['WannWirdBelohnt']
          : ((Object.keys(this.reward).length > 0 && this.reward['StartDatum'])
            ? this.reward['StartDatum'].format('DD.MM.YYYY')
            : moment().format('DD.MM.YYYY'));
        const whenCreated = ' [' + moment().format('DD.MM.YYYY HH:mm') + '] [' + moment().format('ssSSS') + ']';

        if (particpantChoiceData &&
          particpantChoiceData['Teilnehmer'] &&
          particpantChoiceData['Teilnehmer'].length === 1 &&
          participantData &&
          participantData['Displaytext']) {
          this.reward['Titel'] =
            whenReward + " - " + this.rewardType['Bezeichnung'] + ': ' + participantData['Displaytext'] + whenCreated;
        } else {
          this.reward['Titel'] = whenReward + " - " + this.rewardType['Bezeichnung'] + whenCreated;
        }

        this.cp.saveStep('titel', this.reward['Titel']);
      }
    }
  }

  /**
   * Triggers "go back" in the browser.
   */
  goBack(evt: MouseEvent): void {
    evt.preventDefault();
    this._mpCoreService.goBack();
  }

  /**
   * Clears the errors object.
   */
  private _clearErrors(): void {
    this.errors = {};
  }

  /**
   * Validates the reward.
   */
  private _validateReward(setErrors: boolean): boolean {
    let result = true;

    if (setErrors) {
      this._clearErrors();
    }

    if (Object.keys(this.reward).length === 0)
      return false;

    if (Object.keys(this.rewardType).length === 0)
      return false;

    if (typeof this.reward['Belohnungsart'] === 'undefined' || this.reward['Belohnungsart'] === null) {
      if (setErrors)
        this.errors['belohnungsArt'] = this.ls.locs['loc'].BitteBelohnungsartWaehlen;
      result = false;
    }

    if (typeof this.reward['Titel'] === 'undefined' || this.reward['Titel'] === null) {
      if (setErrors)
        this.errors['rewardTitle'] = this.ls.locs['loc'].BitteTitelEingeben;
      result = false;
    }

    if (Object.keys(this.rewardType).length > 0 && this.rewardType['CanSelectZeitraum'] && !this.reward['StartDatum']) {
      if (setErrors)
        this.errors['rewardDate'] = this.ls.locs['loc'].BitteZeitpunktWaehlen;
      result = false;
    }

    if (this.reward['BelohnungsHoehe'] < 1) {
      if (setErrors)
        this.errors['rewardPunkte'] = this.ls.locs['loc'].BittePunkteVergeben;
      result = false;
    }

    if (this.rewardType['NeedsBegruendung'] && !this.reward['Begruendung']) {
      if (setErrors)
        this.errors['rewardReason'] = this.ls.locs['loc'].BelohnungsartBenoetigtBegruendung;
      result = false;
    }

    return result;
  }

  /**
   * Validates the cp step.
   */
  validateStep(setErrors?: boolean): boolean {
    return this._validateReward((typeof setErrors === 'undefined' ? false : setErrors));
  }

  /**
   * Goes to the next cp step.
   */
  goToNextStep(evt: MouseEvent): void {
    evt.preventDefault();

    if (!this.validateStep())
      return;

    this.saveStep();
    this.cp.next();
  };

  /**
   * Goes to the previous cp step.
   */
  goToPreviousStep(evt: MouseEvent): void {
    evt.preventDefault();

    this.saveStep();
    this.cp.previous();
  };

  /**
   * Sets the points of the reward.
   */
  setPoints(points: number, rewardHeight: { [key: string]: any }, property?: string): void {
    let updateIndex: number | undefined;

    if (typeof property !== 'undefined') {
      if (!this._updateIndizesRewardHeightFromPoints[property]) {
        this._updateIndizesRewardHeightFromPoints[property] = 0;
      }

      this._updateIndizesRewardHeightFromPoints[property]++;
      updateIndex = this._updateIndizesRewardHeightFromPoints[property];
    }

    if (Object.keys(this.rewardType).length > 0 &&
      this.rewardType['BelohnungshoeheMaxPunkte'] &&
      this.rewardType['BelohnungshoeheMaxPunkte'] < points)
      points = this.rewardType['BelohnungshoeheMaxPunkte'];

    if (typeof property !== 'undefined') {
      this.reward[property] = points;
    } else {
      this.reward['BelohnungsHoehe'] = points;
    }

    rewardHeight['Punkte'] = points;

    this._getEuroFromPointsSetPointsSubscription = this._apiService.getRequest('/api/Belohnung/getEuroFromPunkte', false, { params: { punkte: rewardHeight['Punkte'] } }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (!property || typeof updateIndex !== 'undefined' && updateIndex === this._updateIndizesRewardHeightFromPoints[property]) {
          let euro = data.Records[0];

          if (Object.keys(this.rewardType).length > 0 &&
            this.rewardType['BelohnungshoeheMaxEuro'] &&
            this.rewardType['BelohnungshoeheMaxEuro'] < euro)
            euro = this.rewardType['BelohnungshoeheMaxEuro'];

          rewardHeight['Euro'] = euro;

          this._getPointsFromEuroSetPointsSubscription = this._apiService.postRequest('/api/Belohnung/getPunkteFromEuro', { euro: rewardHeight['Euro'] }).subscribe((dataPoints: any) => {
            if (dataPoints.Result === 'OK') {
              if (!property || typeof updateIndex !== 'undefined' && updateIndex=== this._updateIndizesRewardHeightFromEuro[property]) {
                let points = dataPoints.Records[0];

                points = this._setRewardTypeAndHeight(points, property);
                rewardHeight['Punkte'] = points;
                rewardHeight = this._setRewardHeightProperties(rewardHeight);
              }
            } else {
              rewardHeight['Punkte'] = rewardHeight['PunkteLast'];
              rewardHeight['Euro'] = rewardHeight['EuroLast'];
            }
          });
        }
      } else {
        rewardHeight['Punkte'] = rewardHeight['PunkteLast'];
        rewardHeight['Euro'] = rewardHeight['EuroLast'];
      }
    });
  }

  /**
   * Sets the euro worth of the reward.
   */
  setEuro(euro: number, rewardHeight: { [key: string]: any }, property?: string): void {
    let updateIndex: number | undefined;

    if (typeof property !== 'undefined') {
      if (!this._updateIndizesRewardHeightFromEuro[property]) {
        this._updateIndizesRewardHeightFromEuro[property] = 0;
      }

      this._updateIndizesRewardHeightFromEuro[property]++;
      updateIndex = this._updateIndizesRewardHeightFromEuro[property];
    }

    if (Object.keys(this.rewardType).length > 0 &&
      this.rewardType['BelohnungshoeheMaxEuro'] &&
      this.rewardType['BelohnungshoeheMaxEuro'] < euro)
      euro = this.rewardType['BelohnungshoeheMaxEuro'];

    rewardHeight['Euro'] = euro;

    this._getPointsFromEuroSubscription = this._apiService.postRequest('/api/Belohnung/getPunkteFromEuro', { euro: rewardHeight['Euro'] }).subscribe((data: any) => {
      if (data.Result === 'OK') {
        if (!property || typeof updateIndex !== 'undefined' && updateIndex === this._updateIndizesRewardHeightFromEuro[property]) {
          let points = data.Records[0];
          points = this._setRewardTypeAndHeight(points, property);
          rewardHeight['Punkte'] = points;

          this._getEuroFromPointsSubscription = this._apiService.getRequest('/api/Belohnung/getEuroFromPunkte', false, { params: { punkte: rewardHeight['Punkte'] } }).subscribe((dataEuro: any) => {
            if (dataEuro.Result === 'OK') {
              if (!property || typeof updateIndex !== 'undefined' && updateIndex === this._updateIndizesRewardHeightFromPoints[property]) {
                let euro = dataEuro.Records[0];

                if (Object.keys(this.rewardType).length > 0 &&
                  this.rewardType['BelohnungshoeheMaxEuro'] &&
                  this.rewardType['BelohnungshoeheMaxEuro'] < euro)
                  euro = this.rewardType['BelohnungshoeheMaxEuro'];

                rewardHeight['Euro'] = euro;
                rewardHeight = this._setRewardHeightProperties(rewardHeight);
              }
            } else {
              rewardHeight['Punkte'] = rewardHeight['PunkteLast'];
              rewardHeight['Euro'] = rewardHeight['EuroLast'];
            }
          });
        }
      } else {
        rewardHeight['Punkte'] = rewardHeight['PunkteLast'];
        rewardHeight['Euro'] = rewardHeight['EuroLast'];
      }
    });
  }

  /**
   * Changes the properties, regarding to
   * the chosen reward type.
   */
  rewardTypeChanged(): void {
    if (this.reward['Belohnungsart'] === this.rewardType['Key'] || '')
      return;

    this.reward['Belohnungsart'] = this.rewardType['Key'] || '';
    this.reward['BelohnungsartObject'] = this.rewardType;
    this._setTitle();

    if (this.rewardDate && Object.keys(this.rewardType).length > 0 && this.rewardType['Key']) {
      const foundAnual = this.rewardDate['repetitionOptions'].find((option: any) => {
        return option['Id'] === this._repetitionKind.annual;
      });
      const foundOneTime = this.rewardDate['repetitionOptions'].find((option: any) => {
        return option['Id'] === this._repetitionKind.oneTime;
      });

      this.rewardDate['repetition'] = (this.rewardType['Key'] === 'geburtstag' || this.rewardType['Key'] === 'jubilaeum') ? foundAnual : foundOneTime;
      this.rewardDate['save']();
    }

    if (this.rewardType['BelohnungshoeheDefaultPunkte']) {
      this.setPoints(this.rewardType['BelohnungshoeheDefaultPunkte'], this.rewardHeight);

      if (this.rewardType['Key'] === 'geburtstag') {
        this.setPoints(this.rewardType['BelohnungshoeheDefaultPunkte'],
          this.rewardRoundedHeight,
          'PunkteRund');
        this.setPoints(this.rewardType['BelohnungshoeheDefaultPunkte'],
          this.rewardHalfRoundedHeight,
          'PunkteHalbrund');
      }

    }

    if (this.rewardType['Key'] === 'geburtstag') {
      this.reward['Wiederholungsart'] = this._repetitionKind.annual;
    }

    if (this.rewardType['Key'] === 'jubilaeum') {
      this.reward['Wiederholungsart'] = this._repetitionKind.annual;

      this.reward['Anstellungsdauer'] = this.reward['Anstellungsdauer'] ||
        (this.anniversaryElements && this.anniversaryElements[0]) ||
        this._repetitionKind.annual;
    }

    this.reward['Inhalte'] = Object.keys(this.rewardType).length > 0 && this.rewardType['Kommunikation'];
    this._setTitleFromReward();
    this.rewardTypeLoaded = true;
  }

  /**
   * Handles the change of the reward
   * points height.
   */
  rewardHeightPointsChange(): void {
    this.reward['BelohnungsHoehe'] = this.rewardHeight['Punkte'];
  }

  /**
   * Handles the change of the reward
   * half rounded points height.
   */
  rewardHeightHalfPointsChange(): void {
    this.reward['PunkteHalbrund'] = this.rewardHalfRoundedHeight['Punkte'];
  }

  /**
   * Handles the change of the reward
   * rounded points height.
   */
  rewardHeightRoundedPointsChange(): void {
    this.reward['PunkteRund'] = this.rewardRoundedHeight['Punkte'];
  }

  /**
   * Sets the standard reward points height.
   */
  private _setRewardHeightStandard(height: number): void {
    this.setPoints(height, this.rewardHeight);
  }

  /**
   * Sets the half rounded height of the
   * reward points.
   */
  private _setRewardHalfRoundedHeight(height: number): void {
    this.setPoints(height, this.rewardHalfRoundedHeight, 'PunkteHalbrund');
  }

  /**
   * Sets the rounded height of the
   * reward points.
   */
  private _setRewardRoundedHeight(height: number): void {
    this.setPoints(height, this.rewardRoundedHeight, 'PunkteRund');
  }

  /**
   * Raises the height of the points of
   * the reward
   */
  raiseRewardPoints(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      this._setRewardHeightStandard(this.rewardHeight['Punkte'] + this._rewardEditorPointsStep);
    }
  }

  /**
   * Reduces the height of the points of
   * the reward
   */
  reduceRewardPoints(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      if (this.rewardHeight['Punkte'] > this._rewardEditorPointsStep) {
        this._setRewardHeightStandard(this.rewardHeight['Punkte'] - this._rewardEditorPointsStep);
      }
    }
  }

  /**
   * Sets the reward type and height.
   */
  private _setRewardTypeAndHeight(points: number, property?: string): number {
    if (Object.keys(this.rewardType).length > 0 &&
      this.rewardType['BelohnungshoeheMaxPunkte'] &&
      this.rewardType['BelohnungshoeheMaxPunkte'] < points)
      points = this.rewardType['BelohnungshoeheMaxPunkte'];

    if (typeof property !== 'undefined') {
      this.reward[property] = points;
    } else {
      this.reward['BelohnungsHoehe'] = points;
    }

    return points;
  }

  /**
   * Sets the properties of the reward height.
   */
  private _setRewardHeightProperties(rewardHeight: { [key: string]: any }): { [key: string]: any } {
    if (rewardHeight['Punkte'] !== rewardHeight['PunkteLast'] &&
      rewardHeight['Euro'] === rewardHeight['EuroLast'] ||
      rewardHeight['Punkte'] === rewardHeight['PunkteLast'] &&
      rewardHeight['Euro'] !== rewardHeight['EuroLast']) {
      rewardHeight['Punkte'] = rewardHeight['PunkteLast'];
      rewardHeight['Euro'] = rewardHeight['EuroLast'];
    } else {
      rewardHeight['PunkteLast'] = rewardHeight['Punkte'];
      rewardHeight['EuroLast'] = rewardHeight['Euro'];
    }

    return rewardHeight;
  }

  /**
   * Raises the reward euro worth.
   */
  raiseRewardEuro(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      this.setEuro(this.rewardHeight['Euro'] + this._rewardEditorEuroStep, this.rewardHeight);
    }
  }

  /**
   * Reduces the reward euro worth.
   */
  reduceRewardEuro(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      if (this.rewardHeight['Euro'] > this._rewardEditorEuroStep) {
        this.setEuro(this.rewardHeight['Euro'] - this._rewardEditorEuroStep, this.rewardHeight);
      }
    }
  }

  /**
   * Raises the half rounded reward points.
   */
  raiseRewardHalfRoundedPoints(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      this._setRewardHalfRoundedHeight(this.rewardHalfRoundedHeight['Punkte'] + this._rewardEditorPointsStep);
    }
  }

  /**
   * Reduces the half rounded reward points.
   */
  reduceRewardHalfRoundedPoints(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      if (this.rewardHalfRoundedHeight['Punkte'] > this._rewardEditorPointsStep) {
        this._setRewardHalfRoundedHeight(this.rewardHalfRoundedHeight['Punkte'] - this._rewardEditorPointsStep);
      }
    }
  }

  /**
   * Raises the half rounded reward euro.
   */
  raiseRewardHalfRoundedEuro(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      this.setEuro(this.rewardHalfRoundedHeight['Euro'] + this._rewardEditorEuroStep, this.rewardHalfRoundedHeight, 'PunkteHalbrund');
    }
  }

  /**
   * Reduces the half rounded reward euro.
   */
  reduceRewardHalfRoundedEuro(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      if (this.rewardHalfRoundedHeight['Euro'] > this._rewardEditorEuroStep) {
        this.setEuro(this.rewardHalfRoundedHeight['Euro'] - this._rewardEditorEuroStep, this.rewardHalfRoundedHeight, 'PunkteHalbrund');
      }
    }
  }

  /**
   * Raises the rounded reward points.
   */
  raiseRewardRoundedPoints(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      this._setRewardRoundedHeight(this.rewardRoundedHeight['Punkte'] + this._rewardEditorPointsStep);
    }
  }

  /**
   * Reduces the rounded reward points.
   */
  reduceRewardRoundedPoints(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      if (this.rewardRoundedHeight['Punkte'] > this._rewardEditorPointsStep) {
        this._setRewardRoundedHeight(this.rewardRoundedHeight['Punkte'] - this._rewardEditorPointsStep);
      }
    }
  }

  /**
   * Raises the rounded reward euro.
   */
  raiseRewardRoundedEuro(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      this.setEuro(this.rewardRoundedHeight['Euro'] + this._rewardEditorEuroStep, this.rewardRoundedHeight, 'PunkteRund');
    }
  }

  /**
   * Reduces the rounded reward euro.
   */
  reduceRewardRoundedEuro(): void {
    if (this.editable && this.rewardType['CanChangeBelohnungshoehe']) {
      if (this.rewardRoundedHeight['Euro'] > this._rewardEditorEuroStep) {
        this.setEuro(this.rewardRoundedHeight['Euro'] - this._rewardEditorEuroStep, this.rewardRoundedHeight, 'PunkteRund');
      }
    }
  }

  /**
   * Triggers the showing of the
   * clear confirm modal of the
   * communication process.
   */
  cpShowClearConfirm(evt: MouseEvent): void {
    evt.preventDefault();
    this.cp.showClearConfirm();
  }

  /**
   * Triggers the showing of the
   * reset confirm modal of the
   * communication process.
   */
  cpShowResetConfirm(callback: Function): void {
    this.cp.showResetConfirm(callback.bind(this));
  }

  private _setPointsKeyUpFunc(): void {
    this.setPoints(this.rewardHeight['Punkte'], this.rewardHeight)
  }
}
