<div *ngIf="(ls.locsLoaded | async)">
  <h1>{{ ls.locs['locLeas'].BitteNehmenSieAnUnsererBefragungTeil }}</h1>
  <p class="margin-top-24">{{ ls.locs['locLeas'].HabenSieVerbesserungsvorschlaegeWuenscheOderAnregungenFuerDenBubbleClub }}</p>
  <mp-core-textarea class="margin-bottom-24" label="{{ ls.locs['locLeas'].VerbesserungsvorschlaegeWuenscheAnmerkungen }}" mpId="verbesserungen" [(model)]="improvements"></mp-core-textarea>
  <p class="margin-top-24">{{ ls.locs['locLeas'].VermissenSieEineBestimmtePraemie }}</p>
  <mp-core-textarea class="margin-bottom-24" label="{{ ls.locs['locLeas'].Praemienwunsch }}" mpId="praemienwunsch" [(model)]="awardWish"></mp-core-textarea>
  <p class="margin-top-24">{{ ls.locs['locLeas'].WarumBesuchenSieDenBubbleClub }}</p>
  <mp-core-radio-button name="reasonForVisit" label="{{ ls.locs['locLeas'].BestimmtePraemie }}" mpId="radioPraemiendetailJa" [(model)]="reasonForVisit" value="a"></mp-core-radio-button>
  <mp-core-radio-button name="reasonForVisit" label="{{ ls.locs['locLeas'].AnregungUndInspiration }}" mpId="radioPraemiendetailNein" [(model)]="reasonForVisit" value="b"></mp-core-radio-button>
</div>
