<div class="bg-color-white" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div id="taw-list-headline" class="padding-top-bottom-8 padding-left-right-16 text-transform-uppercase bg-color-brand-primary text-color-brand-secondary display-flex flex-align-items-center font-size-s">{{ ls.locs['locTipAndWin'].Rangliste }}</div>
  <div class="bg-color-white box-sizing-border-box width-full padding-24" id="taw-list-container">
    <div class="flex-grid margin-left-right-0 font-size-xs taw-list-data-headlines">
      <div class="flex-col-3 flex-col-3-s flex-col-2-xs flex-align-items-start padding-left-12">{{ ls.locs['locTipAndWin'].Platz }}</div>
      <div class="flex-col-5 flex-col-5-s flex-col-6-xs flex-align-items-start">{{ ls.locs['locTipAndWin'].Name }}</div>
      <div class="flex-col-4 flex-col-4-s flex-col-4-xs flex-align-items-start padding-right-12 flex-justify-cont-end">{{ ls.locs['locTipAndWin'].Gesamtpunkte }}</div>
    </div>
    <div class="flex-grid margin-left-right-0 font-size-s taw-list-data" *ngFor="let gambler of gamblers | orderBy: 'CurrentRang'" [ngClass]="{ 'taw-current-tn': gambler['IsCurrentTn'], 'taw-first-gambler': gambler['CurrentRang'] === 1, 'taw-second-gambler': gambler['CurrentRang'] === 2, 'taw-third-gambler': gambler['CurrentRang'] === 3 }">
      <div class="flex-col-3 flex-col-3-s flex-col-2-xs flex-align-items-center padding-left-12">{{gambler['CurrentRang']}}</div>
      <div class="flex-col-5 flex-col-5-s flex-col-6-xs flex-align-items-center">
        <i class="taw-first-three-icon svg-icon __clr-white __license __size-22 __top--2 margin-right-8"></i>
        <span>{{gambler['Name']}}</span>
      </div>
      <div class="flex-col-4 flex-col-4-s flex-col-4-xs flex-align-items-center flex-justify-cont-end padding-right-12">
        <i *ngIf="gambler['LastRang'] > gambler['CurrentRang']" class="svg-icon __sidebar-arrow-up __size-16 __top-0" [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'"></i>
        <i *ngIf="gambler['LastRang'] < gambler['CurrentRang']" class="svg-icon __sidebar-arrow-down __size-16 __top-0" [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'"></i>
        <i *ngIf="gambler['LastRang'] === gambler['CurrentRang']" class="svg-icon __nav-next __size-16 __top-0" [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'"></i>
        <span>{{gambler['CurrentPunkte']}}</span>
      </div>
    </div>
    <button class="mp-button _prim width-full margin-top-bottom-0" (click)="showAllGamblers = true">{{ ls.locs['locTipAndWin'].GesamteRangliste }}</button>
  </div>
  <mp-core-modal [setModalWideClass]="wideRanking" [show]="showAllGamblers" fixedLine=".taw-list-data-headlines" title="{{ ls.locs['locTipAndWin'].GesamteRangliste }}" [buttons]="[{text: ls.locs['loc'].Schliessen, action: setShowAllGamblersFalse, class: '_prim'}]">
    <div class="flex-grid margin-left-right-0 font-size-xs taw-list-data-headlines hide-on-mobile">
      <div [ngClass]="wideRanking === true ? 'flex-col-1' : 'flex-col-3'" class=" flex-align-items-start padding-left-12">{{ ls.locs['locTipAndWin'].Platz }}</div>
      <div class="flex-col-5 flex-align-items-start">{{ ls.locs['locTipAndWin'].Name }}</div>

      <div *ngIf="wideRanking" class="flex-col-1 flex-align-items-start">{{ ls.locs['locTipAndWin'].SpieltippPunkteKurz }}</div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideTaeglichesQuiz && wideRanking" class="flex-col-1 flex-align-items-start">{{ ls.locs['locTipAndWin'].TagesquizPunkteKurz }}</div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideZusatzfragen && wideRanking" class="flex-col-1 flex-align-items-start">{{ ls.locs['locTipAndWin'].ZusatzfragenPunkteKurz }}</div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HidePerformance && wideRanking" class="flex-col-1 flex-align-items-start">{{ ls.locs['locTipAndWin'].LeistungsPunkteKurz }}</div>
      <div [ngClass]="wideRanking === true ? 'flex-col-2' : 'flex-col-4'" class="flex-align-items-start flex-justify-cont-end padding-right-12">{{ ls.locs['locTipAndWin'].GesamtpunkteKurz }}</div>
    </div>
    <div class="flex-grid margin-left-right-0 font-size-s taw-list-data" *ngFor="let gambler of allGamblers | orderBy: 'CurrentRang'" [ngClass]="{ 'taw-current-tn': gambler['IsCurrentTn'], 'taw-first-gambler': gambler['CurrentRang'] === 1, 'taw-second-gambler': gambler['CurrentRang'] === 2, 'taw-third-gambler': gambler['CurrentRang'] === 3, 'mobile-row-opened': gambler['mobileRowOpened'] }">
      <div (click)="openMobileRow(gambler)" class="show-on-mobile flex-align-items-start flex-col-2-s flex-col-2-xs padding-left-12">
        <i [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'" class="svg-icon __nav-next __size-16 __top-2"></i>
      </div>
      <div (click)="openMobileRow(gambler)" [ngClass]="wideRanking === true ? 'flex-col-1' : 'flex-col-3'" class="flex-align-items-center flex-col-2-s flex-col-2-xs padding-left-12">{{gambler['CurrentRang']}}</div>
      <div (click)="openMobileRow(gambler)" class="flex-col-5 flex-col-8-s flex-col-8-xs flex-align-items-center">
        <i class="taw-first-three-icon svg-icon __clr-white __license __size-22 __top--2 margin-right-8"></i>
        <a href="#/{{role}}/Einstellungen">{{gambler['Name']}}</a>
      </div>
      <div *ngIf="wideRanking" class="show-on-mobile flex-col-6-s flex-col-6-xs bold flex-align-items-start padding-left-12">{{ ls.locs['locTipAndWin'].SpieltippPunkteKurz }}</div>
      <div *ngIf="wideRanking" class="flex-col-1 flex-col-6-s flex-col-6-xs flex-align-items-center">
        <span>{{gambler['SpieltippPunkte']}}</span>
      </div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideTaeglichesQuiz && wideRanking" class="show-on-mobile flex-col-6-s flex-col-6-xs bold flex-align-items-start padding-left-12">{{ ls.locs['locTipAndWin'].TagesquizPunkteKurz }}</div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideTaeglichesQuiz && wideRanking" class="flex-col-1 flex-col-6-s flex-col-6-xs flex-align-items-center">
        <span>{{gambler['TagesquizPunkte']}}</span>
      </div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideZusatzfragen && wideRanking" class="show-on-mobile flex-col-6-s flex-col-6-xs bold flex-align-items-start padding-left-12">{{ ls.locs['locTipAndWin'].ZusatzfragenPunkteKurz }}</div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HideZusatzfragen && wideRanking" class="flex-col-1 flex-col-6-s flex-col-6-xs flex-align-items-center">
        <span>{{gambler['ZusatzfragenPunkte']}}</span>
      </div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HidePerformance && wideRanking" class="show-on-mobile flex-col-6-s flex-col-6-xs bold flex-align-items-start padding-left-12">{{ ls.locs['locTipAndWin'].LeistungsPunkteKurz }}</div>
      <div *ngIf="!mpSettings.settings['TipAndWinSettings'].HidePerformance && wideRanking" class="flex-col-1 flex-col-6-s flex-col-6-xs flex-align-items-center">
        <a href="#/{{role}}/Konto">{{gambler['LeistungsPunkte']}}</a>
      </div>
      <div class="show-on-mobile flex-col-6-s flex-col-6-xs bold padding-left-12">{{ ls.locs['locTipAndWin'].GesamtpunkteKurz }}</div>
      <div class="flex-col-2 flex-col-6-s flex-col-6-xs flex-align-items-center flex-justify-cont-end padding-right-12">
        <i *ngIf="gambler['LastRang'] > gambler['CurrentRang']" class="svg-icon __sidebar-arrow-up __size-16 __top-0" [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'"></i>
        <i *ngIf="gambler['LastRang'] < gambler['CurrentRang']" class="svg-icon __sidebar-arrow-down __size-16 __top-0" [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'"></i>
        <i *ngIf="gambler['LastRang'] === gambler['CurrentRang']" class="svg-icon __nav-next __size-16 __top-0" [ngClass]="gambler['IsCurrentTn'] === true ? '__clr-white' : gambler['CurrentRang'] === 1 || gambler['CurrentRang'] === 2 || gambler['CurrentRang'] === 3 ? '__clr-white' : '__clr-default'"></i>
        <span>{{gambler['CurrentPunkte']}}</span>
      </div>
    </div>
  </mp-core-modal>
</div>
