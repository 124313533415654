import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { AuthService } from '@core/services/auth.service';
import { RoleMappingService } from '@core/services/role-mapping.service';
import { MpEnumsService } from '@core/services/mp-enums.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { ApiService } from '@core/services/api.service';
import { CustomEventService, CustomEvent, CustomEventType } from '@core/services/custom-event.service';

/**
 * This class provides the functions
 * for the hotel details.
 */
@Component({
  selector: 'mp-rk-hotel-details',
  templateUrl: './hotel-details.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HotelDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public hotel: { [key: string]: any } = {};
  @Input() public params: { [key: string]: any } = {};
  @Input() public filter: { [key: string]: any } = {};
  @Input() public sorting: { [key: string]: any } = {};
  @Input() public travelType: number = 0;
  @Input() public show: boolean = false;
  @Input() public getParamsForGetHotels: any;
  @Input() public filterOptionsMaxPrice: number = 0;

  @Output() filterOptionsMaxPriceChange = new EventEmitter<number>();

  public role: string = '';
  public tabIndex: number = 2;
  public pagesize: number = 4;
  public tabCounter: number = 0;
  public searchId: string = '';
  public transfer: { [key: string]: any } = {};
  public startindex: number = 0;
  public filteredOffers: Array<any> = [];
  public pagedAndFilteredOffers: Array<any> = [];

  private _routeParamsSubscription: Subscription | undefined;
  private _getEnumsSubscriptions: Subscription | undefined;
  private _getOffersSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _authService: AuthService,
    private _roleMapping: RoleMappingService,
    private _route: ActivatedRoute,
    private _mpEnums: MpEnumsService,
    private _svgLoader: SvgLoaderService,
    private _apiService: ApiService,
    private _customEventServicee: CustomEventService
  ) { }

  /**
   * Gets the role and the nums. Triggers
   * the loading function for loading offers.
   */
  ngOnInit(): void {
    const role = this._authService.getRole();

    if (typeof role === 'object') {
      this.role = window.location.href.replace(window.location.origin, '').split('/')[2];
    } else {
      this.role = this._roleMapping.getReverseMappedRole(role);
    }

    this._routeParamsSubscription = this._route.queryParams.subscribe((params: any) => {
      if (typeof params['sid'] !== 'undefined') {
        this.searchId = params['sid'];
      }
    });

    if (Object.keys(this._mpEnums.enums).length > 0) {
      this.transfer = this._mpEnums.enums['Transfer'];
    } else {
      this._getEnumsSubscriptions = this._mpEnums.enumsLoaded.subscribe((loaded: boolean) => {
        if (loaded) {
          this.transfer = this._mpEnums.enums['Transfer'];

          if (typeof this._getEnumsSubscriptions !== 'undefined') {
            this._getEnumsSubscriptions.unsubscribe();
          }
        }
      });

      this._mpEnums.getEnums();
    }

    this._loadOffers();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._routeParamsSubscription !== 'undefined') {
      this._routeParamsSubscription.unsubscribe();
    }

    if (typeof this._getEnumsSubscriptions !== 'undefined') {
      this._getEnumsSubscriptions.unsubscribe();
    }

    if (typeof this._getOffersSubscription !== 'undefined') {
      this._getOffersSubscription.unsubscribe();
    }
  }

  /**
   * Returns the index of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Loads the travel offers for the hotel.
   */
  private _loadOffers(): void {
    if (Object.keys(this.params).length === 0 || !this.show) {
      return;
    }

    if (this.hotel['angebote'] && this.hotel['angebote'].length > 0) {
      this._setFilteredOffers();
    } else {
      this._svgLoader.startLoading();
      this._customEventServicee.dispatch(new CustomEvent(CustomEventType.ShowSvgLoader, true));
      const parameters = this.getParamsForGetHotels();

      this._getOffersSubscription = this._apiService.postRequest('/api/RkHotelUndFlug/GetAngebote', Object.assign(parameters, { Hotelcode: this.hotel['HotelCode'] })).subscribe((data: any) => {
        this.hotel['angebote'] = data.Records;

        this.filterOptionsMaxPrice = Math.max(this.filterOptionsMaxPrice, this._maxBy(data.Records, (o: any) => o['Punkte'])['Punkte']);

        this._setFilteredOffers();
        this._customEventServicee.dispatch(new CustomEvent(CustomEventType.HideSvgLoader, true));
        this._svgLoader.finishLoading();
      },
      (error: any) => {
        this._customEventServicee.dispatch(new CustomEvent(CustomEventType.HideSvgLoader, true));
        this._svgLoader.finishLoading();
      });
    }
  }

  /**
   * Sorts the offers.
   */
  private _sortOffers(offers: Array<any>): Array<any> {
    const sortedOffers = offers.slice().sort((a: any, b: any) => {
      let sortValA, sortValB, sortFaktor = 1;

      if (Object.keys(this.sorting).length > 0 && this.sorting['SortierFeld']) {
        sortValA = a[this.sorting['SortierFeld']];
        sortValB = b[this.sorting['SortierFeld']];

        sortFaktor = this.sorting['Reverse'] ? -1 : 1;
      }

      if (sortValA === sortValB) {
        sortValA = a.Punkte;
        sortValB = b.Punkte;

        sortFaktor = 1;
      }

      return (sortValA < sortValB ? -1 : 1) * sortFaktor;
    });

    return sortedOffers;
  }

  /**
   * Filters the available offers.
   */
  private _setFilteredOffers(): void {
    this.startindex = 0;
    const sortedOffers = this._sortOffers(this.hotel['angebote']);

    this.filteredOffers = sortedOffers.filter((offer: any) => {
      return this.filter['Punkte'] >= offer['Punkte'];
    });

    this.pagedAndFilteredOffers = this.filteredOffers.slice(0, this.pagesize);
  }

  /**
   * Gets the max value of a special
   * property in an object in the given
   * array.
   */
  private _maxBy(arr: Array<any>, func: Function): any {
    // @ts-ignore
    const max = Math.max(...arr.map(func));
    return arr.find(item => func(item) === max);
  }

  /**
   * Hides the offers.
   */
  hideOffers(): void {
    this.startindex = 0;
    this.pagedAndFilteredOffers = this.filteredOffers.slice(0, this.pagesize);
  }

  /**
   * Loads additional offers.
   */
  loadAdditionalOffers(): void {
    this.startindex += this.pagesize;
    this.pagedAndFilteredOffers = this.filteredOffers.slice(0, this.startindex + this.pagesize);
  }

  /**
   * Gets the image html for the 
   * provider of the given offer.
   */
  getOfferHtml(offer: any): string {
    return '<img class="anbieter-logo" src="https://cdn.netcentive.de/logos/dt/' + offer['Anbieter'] + '.jpg?width=70&height=30" title="' + (offer['Veranstalter'] && offer['Veranstalter'].NameKurz || offer['Anbieter']) + '" alt="' + (offer['Veranstalter'] && offer['Veranstalter'].NameKurz || offer['Anbieter']) + '" />';
  }

  /**
   * Watches for changes of params and show.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['params'] !== 'undefined' && typeof changes['params'].currentValue !== 'undefined' && typeof changes['params'].previousValue !== 'undefined') {
      if (changes['params'].currentValue !== changes['params'].previousValue) {
        this._loadOffers();
      }
    }

    if (typeof changes['show'] !== 'undefined' && typeof changes['show'].currentValue !== 'undefined') {
      if (changes['show'].currentValue === true) {
        this._loadOffers();
      }
    }
  }

}
