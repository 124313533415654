<div class="layout-container" schulungstatistik mp-settings *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locSchulung'].Schulungsstatistik }}</h1>
  <mp-core-data-table title="{{ ls.locs['locSchulung'].Schulungsstatistik }}"
                      *ngIf="schoolingsLoaded"
                      infotextShorten="schulungsstatistik"
                      fieldsAction="/api/Schulung/GetSchulungStatistikFields"
                      listAction="/api/Schulung/GetSchulungStatistik"
                      loaderName="schoolingStatisticLoader"
                      [recLoadedFunc]="recLoadedFunc"
                      [paging]="true"
                      [footer]="true"
                      defaultSorting="Benutzer"
                      [showDatePicker]="false"
                      [disableCache]="true"
                      [headerSelectTransclude]="true">
    <mp-core-select [elements]="schoolings"
                    textProperty="Name"
                    placeholder="{{ ls.locs['loc'].BitteWaehlen }}"
                    valueProperty="Id"
                    [mpRequired]="true"
                    [model]="selectedSchoolingId"
                    (modelChange)="selectedSchoolingId = $event; doData()"
                    label="{{ ls.locs['loc'].Schulungen }}">

    </mp-core-select>
  </mp-core-data-table>
  <div *ngIf="loader !== null && loader.getExcelParams" class="floating-container">
    <a href="{{excelService}}?{{loader.getExcelParams()}}">
      <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
    </a>
  </div>
  <div class="ui-helper-clearfix"></div>
</div>
