<div class="bg-color-transparent" *ngIf="(ls.locsLoaded | async)">
  <div class="bg-color-white padding-left-right-24 padding-top-24 box-sizing-border-box width-full" id="taw-daily-quiz-question">
    <div *ngIf="question !== ''" class="flex-grid margin-left-right-0 font-size-s">
      <div class="flex-col-12 margin-bottom-32 padding-top-bottom-8 bg-color-light-grey taw-daily-quiz-question">{{question}}</div>
      <div *ngFor="let answer of answers" class="taw-daily-quiz-answer flex-col-6 padding-right-12 padding-left-0 padding-bottom-16 flex-align-item-center">
        <span (click)="saveAnswer(answer['label'])" class="padding-8 display-flex flex-align-items-center box-sizing-border-box width-full bg-color-light-grey">
          <span class="margin-right-32">{{answer['label']}}</span>
          <span>{{answer['antwort']}}</span>
        </span>
      </div>
      <div id="taw-daily-quiz-timer">{{ ls.locs['locTipAndWin'].VerbleibendeZeit }} {{leftoverTime}} s</div>
    </div>
    <div *ngIf="question === ''">
      <span [innerHTML]="dailyQuizHint"></span>
    </div>
    <div *ngIf="question === ''">
      <span>{{ ls.locs['locTipAndWin'].ZurzeitKeineFrage }}</span>
    </div>
  </div>
  <div *ngIf="previousAnswers.length > 0" class="bg-color-white padding-left-right-24 padding-bottom-0 padding-top-24 box-sizing-border-box width-full margin-top-64" id="taw-daily-quiz-previous-answers">
    <div class="padding-bottom-32 taw-phase-tips" *ngFor="let previousAnswer of previousAnswers">
      <h3 class="bold margin-bottom-0">{{formatQuestionDate(previousAnswer['Datum'])}}</h3>
      <div class="flex-grid margin-left-right-0 font-size-xs hide-on-mobile">
        <div class="flex-col-7 flex-align-items-center">{{ ls.locs['locTipAndWin'].Frage }}</div>
        <div class="flex-col-2 flex-align-items-center">{{ ls.locs['locTipAndWin'].Antwort }}</div>
        <div class="flex-col-2 flex-align-items-center">{{ ls.locs['locTipAndWin'].Tipp }}</div>
        <div class="flex-col-1 flex-align-items-center">{{ ls.locs['locTipAndWin'].Punkte }}</div>
      </div>
      <div class="flex-grid margin-left-right-0 font-size-s taw-match-data">
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Frage }}</div>
        <div class="flex-col-7 flex-col-6-s flex-col-7-xs flex-align-items-center">{{previousAnswer['Frage']}}</div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Antwort }}</div>
        <div class="flex-col-2 flex-col-6-s flex-col-7-xs flex-align-items-center">{{previousAnswer['Label']}}: {{previousAnswer['Antwort']}}</div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Tipp }}</div>
        <div class="flex-col-2 flex-col-6-s flex-col-7-xs flex-align-items-center">{{previousAnswer['TnLabel']}}: {{previousAnswer['TnAntwort']}}</div>
        <div class="flex-col-6-s flex-col-5-xs flex-align-items-center show-on-mobile">{{ ls.locs['locTipAndWin'].Punkte }}</div>
        <div class="flex-col-1 flex-col-6-s flex-col-7-xs flex-align-items-center" [ngClass]="previousAnswer['TnPunkte'] > 0 ? 'text-success' : 'text-danger'">{{previousAnswer['TnPunkte']}}</div>
      </div>
    </div>
  </div>
</div>
