<div class="layout-container" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <h1 class="mobile-only">{{ ls.locs['locTipAndWin'].Performance }}</h1>
  <mp-core-data-table title="{{ ls.locs['locTipAndWin'].Performance }}"
                      fieldsAction="/api/TipAndWin/GetTeilnehmerPerformanceFields" 
                      listAction="/api/TipAndWin/GetTeilnehmerPerformance" 
                      loaderName="tipAndWinGamePerformanceLoader"
                      [paging]="true" 
                      [footer]="true" 
                      [disableCache]="true"
                      defaultSorting="Datum DESC"
                      [showDatePicker]="true"
                      [recLoadedFunc]="recLoadedFunc"
                      [alwaysShowFilter]="mpSettings.settings['DisplaySettings'].ShowFilterAlways"></mp-core-data-table>
    <div *ngIf="canDownloadStatistics && loader !== null && loader.getExcelParams" class="floating-container">
      <a href="{{excelService}}?{{loader.getExcelParams()}}">
        <button class="mp-button _prim margin-top-24 float-right">{{ ls.locs['loc'].Export }}</button>
      </a>
    </div>
  <div class="ui-helper-clearfix"></div>
</div>
