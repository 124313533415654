import { Injectable } from '@angular/core';

/**
 * This class provides services to
 * set and get stage id(s).
 */
@Injectable({
  providedIn: 'root'
})
export class MpStageIdService {

  private _stageElementIds: Array<number> = [];
  private _scrolled: boolean = false;

  constructor() { }

  /**
   * Gets the stage elements ids.
   */
  getIds(): Array<number> {
    return this._stageElementIds;
  }

  /**
   * Sets the stage element ids.
   */
  setIds(ids: Array<number>): void {
    this._stageElementIds = ids;
  }

  /**
   * Gets the scrolled state.
   */
  getScrolled(): boolean {
    return this._scrolled;
  }

  /**
   * Sets the scrolled state.
   */
  setScrolled(val: boolean): void {
    this._scrolled = val;
  }
}
