import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from '@core/services/mp-localization.service';
import { MpMessagingService } from '@core/services/mp-messaging.service';
import { ApiService } from '@core/services/api.service';
import { SvgLoaderService } from '@core/components/svg-loader/svg-loader.service';
import { TipAndWinService } from './../../services/tip-and-win.service';

/**
 * This class provides the data and the
 * functions for the additional questions.
 */
@Component({
  selector: 'mp-taw-tip-and-win-additional-questions',
  templateUrl: './tip-and-win-additional-questions.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TipAndWinAdditionalQuestionsComponent implements OnInit, OnDestroy {

  public additionalQuestions: Array<any> = [];
  public hasAnswers: boolean = false;
  public hasDoneQuestions: boolean = false;

  private _getAdditionalQuestionsSubscription: Subscription | undefined;
  private _saveAdditionalQuestionSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService,
    private _svgLoader: SvgLoaderService,
    private _tipAndWinService: TipAndWinService
  ) { }

  /**
   * Triggers the getting of the additional
   * questions.
   */
  ngOnInit(): void {
    this._getAdditionalQuestions();
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._getAdditionalQuestionsSubscription !== 'undefined') {
      this._getAdditionalQuestionsSubscription.unsubscribe();
    }

    if (typeof this._saveAdditionalQuestionSubscription !== 'undefined') {
      this._saveAdditionalQuestionSubscription.unsubscribe();
    }
  }

  /**
   * Returns the question id of item in
   * ngFor. Is used for trackBy in ngFor.
   */
  trackByQuestionId(index: number, item: any): number {
    return item['FrageId'];
  }

  /**
   * Gets the correct answers for the
   * given question.
   */
  getCorrectAnswers(question: { [kexy: string]: any }): string {
    let correctAnswers = '';

    question['Antworten'].forEach((answer: { [kexy: string]: any }) => {
      if (answer['IsKorrekt'] === true) correctAnswers += (correctAnswers === '' ? '' : ', ') + answer['Antwort'];
    });

    return correctAnswers;
  }

  /**
   * Gets the additional questions.
   */
  private _getAdditionalQuestions(): void {
    this._svgLoader.startLoading();

    this._getAdditionalQuestionsSubscription = this._apiService.getRequest(`/api/TipAndWin/GetZusatzfragen?aktion=${this._tipAndWinService.campaign}`).subscribe((data: any) => {
      this._svgLoader.finishLoading();
      this.additionalQuestions = [];

      if (data.Result === 'OK') {
        this.additionalQuestions = data.Records;
        this.hasAnswers = false;
        this.hasDoneQuestions = false;

        for (let questionIndex = 0; questionIndex < this.additionalQuestions.length; questionIndex++) {
          if (this.additionalQuestions[questionIndex].Aenderbar !== false) {
            this.hasAnswers = true;
          } else {
            this.hasDoneQuestions = true;
          }
        }
      } else {
        this._mpMessaging.openWarningPanel(data.Message);
      }
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Sets the given answer for the
   * given question.
   */
  setAnswer(questionId: number, answerId: number): void {
    const data = {
      Aktion: this._tipAndWinService.campaign,
      FrageId: questionId,
      AntwortId: answerId
    };

    this._svgLoader.startLoading();

    this._saveAdditionalQuestionSubscription = this._apiService.postRequest('/api/TipAndWin/SaveZusatzfragenAntwort', data).subscribe((data: any) => {
      this._svgLoader.finishLoading();

      if (data.Result !== 'OK') {
        this._mpMessaging.openWarningPanel(data.Message);
      }

      this._getAdditionalQuestions();
    },
    (error: any) => {
      this._svgLoader.finishLoading();
      this._mpMessaging.openErrorPanel(error);
    });
  }

  /**
   * Shows a message notification, when
   * save button is clicked.
   */
  clickedSaveButton(): void {
    this._mpMessaging.openSuccessPanel(this.ls.locs['locTipAndWin'].EingabeWurdeGespeichert);
  }

}
