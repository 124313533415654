<div id="sidebar-target-airport-filter-flight" class="rk-sidebar-form" *ngIf="(ls.locsLoaded | async)">
  <div class="sidebar-grid">
    <div id="overlay-headline" class="texst-align-left">{{ ls.locs['locReisekonfigurator'].ZielflughafenSidebarHeader }}</div>
    <div class="divider margin-bottom-8 margin-left-16"></div>
    <mp-core-scroll-button id="overlay-scroll-up" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#zielflughafen-filter-scroll-area" scrollDirection="up" scrollFactor="1" [scopeObject]="targets" class="scrollbutton-up inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-up float-none-important"></i>
    </mp-core-scroll-button>
    <div class="padding-left-16 padding-right-16 scroll-area-container" id="zielflughafen-filter-scroll-area-container">
      <div id="zielflughafen-filter-scroll-area" class="flex-grid flex-direction-row flex-wrap sidebar-scroll-area position-relative">
        <div *ngFor="let target of targets; let index = index;" class="flex-col-6 flex-col-6-xs flex-col-6-s margin-bottom-8">
          <mp-core-checkbox mpId="target{{index}}" [(model)]="target.Active" label="{{target.KurzText}}"></mp-core-checkbox>
        </div>
      </div>
    </div>
    <mp-core-scroll-button id="overlay-scroll-down" [ifNeeded]="true" scrollItemHeight="80" scrollArea="#zielflughafen-filter-scroll-area" scrollDirection="down" scrollFactor="1" [scopeObject]="targets" class="scrollbutton-down inactive-scroll-button margin-top-16">
      <i class="svg-icon __clr-default __wide-48 __sidebar-arrow-down float-none-important"></i>
    </mp-core-scroll-button>
  </div>
  <div class="padding-bottom-8 padding-top-8 padding-left-0 padding-right-0 vertical-align-bottom width-100-pcnt bg-color-white z-index-3">
    <div id="overlay-buttons">
      <a href="" (click)="save($event)" class="mp-button _prim display-block">{{ ls.locs['loc'].AenderungenUebernehmen }}</a>
    </div>
  </div>
</div>
