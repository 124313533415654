import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CommonComponentsLoaderModule } from './common-components-loader.module';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { LogoutComponent } from './../components/logout/logout.component';
import { SidebarInboxComponent } from './../components/sidebar/sidebar-inbox/sidebar-inbox.component';
import { CalendarComponent } from './../components/calendar/calendar.component';
import { MenuComponent } from './../components/menu/menu.component';
import { SidebarCalendarComponent } from './../components/sidebar/sidebar-calendar/sidebar-calendar.component';
import { SidebarDateComponent } from './../components/sidebar/sidebar-date/sidebar-date.component';
import { SidebarDateTimeComponent } from './../components/sidebar/sidebar-date-time/sidebar-date-time.component';
import { SidebarPeriodComponent } from './../components/sidebar/sidebar-period/sidebar-period.component';
import { SidebarPeriodTimeComponent } from './../components/sidebar/sidebar-period-time/sidebar-period-time.component';
import { SidebarRewardPeriodComponent } from './../components/sidebar/sidebar-reward-period/sidebar-reward-period.component';
import { SidebarMultiselectComponent } from './../components/sidebar/sidebar-multiselect/sidebar-multiselect.component';
import { SidebarHelpComponent } from './../components/sidebar/sidebar-help/sidebar-help.component';
import { CategoryDropdownComponent } from './../components/category-dropdown/category-dropdown.component';
import { SvgLoaderComponent } from './../components/svg-loader/svg-loader.component';
import { D3AreaChartComponent } from './../components/d3/d3-area-chart/d3-area-chart.component';
import { D3BarPlotComponent } from './../components/d3/d3-bar-plot/d3-bar-plot.component';
import { D3CircularBarPlotComponent } from './../components/d3/d3-circular-bar-plot/d3-circular-bar-plot.component';
import { D3HistogramComponent } from './../components/d3/d3-histogram/d3-histogram.component';
import { D3LineChartComponent } from './../components/d3/d3-line-chart/d3-line-chart.component';
import { D3PieChartComponent } from './../components/d3/d3-pie-chart/d3-pie-chart.component';
import { D3LollipopChartComponent } from './../components/d3/d3-lollipop-chart/d3-lollipop-chart.component';
import { DashboardChartComponent } from './../components/dashboard-chart/dashboard-chart.component';
import { WishlistCardComponent } from './../components/card/wishlist-card/wishlist-card.component';
import { SbOverviewComponent } from './participant/pages/shopping-basket/sb-overview/sb-overview.component';
import { ExpressEditComponent } from './participant/pages/shipping/express-edit/express-edit.component';
import { UserProfileNavbarComponent } from './../components/user-profile-navbar/user-profile-navbar.component';
import { InboxComponent } from './../components/inbox/inbox.component';
import { InboxItemComponent } from './../components/inbox/inbox-item/inbox-item.component';
import { InboxDetailsMessageComponent } from './../components/inbox/inbox-details-message/inbox-details-message.component';
import { InboxDetailsCampaignComponent } from './../components/inbox/inbox-details-campaign/inbox-details-campaign.component';
import { InboxDetailsOrderComponent } from './../components/inbox/inbox-details-order/inbox-details-order.component';
import { InboxDetailsStageComponent } from './../components/inbox/inbox-details-stage/inbox-details-stage.component';
import { InboxPreviewComponent } from './../components/inbox/inbox-preview/inbox-preview.component';
import { AddressEditAreaComponent } from './../components/addresses/address-edit-area/address-edit-area.component';
import { AddressCardComponent } from './../components/addresses/address-card/address-card.component';
import { DataTableComponent } from './../components/data-table/data-table.component';
import { OrderAddressesComponent } from './../components/addresses/order-addresses/order-addresses.component';
import { SupportComponent } from './../components/support/support.component';
import { AccountComponent } from './../modules/participant/pages/account/account.component';
import { DashboardComponent } from './../components/dashboard/dashboard.component';
import { DetailPageContentComponent } from './../components/detail-page-content/detail-page-content.component';
import { ManagerDashboardComponent } from './../components/manager-dashboard/manager-dashboard.component';

import { FirstActiveDayDirective } from './../components/calendar/first-active-day.directive';
import { InboxStickHeadlineDirective } from './../components/inbox/inbox-stick-headline.directive';
import { StageImageLoadedDirective } from './../directives/stage-image-loaded.directive';
import { SidebarHelpIconDirective } from './../components/sidebar/sidebar-help/sidebar-help-icon.directive';
import { AccountDirective } from './../modules/participant/pages/account/account.directive';
import { StatusAccountDirective } from './../modules/participant/pages/account/status-account.directive';

@NgModule({
  declarations: [
    AddressEditAreaComponent,
    AddressCardComponent,
    LogoutComponent,
    SidebarInboxComponent,
    CalendarComponent,
    FirstActiveDayDirective,
    AccountDirective,
    StatusAccountDirective,
    MenuComponent,
    SidebarCalendarComponent,
    SidebarDateTimeComponent,
    SidebarDateComponent,
    SidebarPeriodComponent,
    SidebarPeriodTimeComponent,
    SidebarRewardPeriodComponent,
    SidebarMultiselectComponent,
    SidebarHelpComponent,
    CategoryDropdownComponent,
    SvgLoaderComponent,
    D3AreaChartComponent,
    D3BarPlotComponent,
    D3CircularBarPlotComponent,
    D3HistogramComponent,
    D3LineChartComponent,
    D3PieChartComponent,
    D3LollipopChartComponent,
    DashboardChartComponent,
    WishlistCardComponent,
    SbOverviewComponent,
    ExpressEditComponent,
    UserProfileNavbarComponent,
    InboxComponent,
    InboxItemComponent,
    InboxDetailsMessageComponent,
    InboxDetailsCampaignComponent,
    InboxDetailsOrderComponent,
    InboxDetailsStageComponent,
    InboxPreviewComponent,
    InboxStickHeadlineDirective,
    StageImageLoadedDirective,
    SidebarHelpIconDirective,
    DataTableComponent,
    OrderAddressesComponent,
    SupportComponent,
    AccountComponent,
    DashboardComponent,
    DetailPageContentComponent,
    ManagerDashboardComponent
  ],
  exports: [
    AddressEditAreaComponent,
    AddressCardComponent,
    LogoutComponent,
    SidebarInboxComponent,
    CalendarComponent,
    FirstActiveDayDirective,
    AccountDirective,
    StatusAccountDirective,
    MenuComponent,
    SidebarCalendarComponent,
    SidebarDateComponent,
    SidebarDateTimeComponent,
    SidebarPeriodComponent,
    SidebarPeriodTimeComponent,
    SidebarRewardPeriodComponent,
    SidebarMultiselectComponent,
    SidebarHelpComponent,
    CommonComponentsLoaderModule,
    CategoryDropdownComponent,
    SvgLoaderComponent,
    D3AreaChartComponent,
    D3BarPlotComponent,
    D3CircularBarPlotComponent,
    D3HistogramComponent,
    D3LineChartComponent,
    D3PieChartComponent,
    D3LollipopChartComponent,
    DashboardChartComponent,
    WishlistCardComponent,
    SbOverviewComponent,
    ExpressEditComponent,
    UserProfileNavbarComponent,
    InboxComponent,
    InboxItemComponent,
    InboxDetailsMessageComponent,
    InboxDetailsCampaignComponent,
    InboxDetailsOrderComponent,
    InboxPreviewComponent,
    InboxStickHeadlineDirective,
    StageImageLoadedDirective,
    SidebarHelpIconDirective,
    DataTableComponent,
    OrderAddressesComponent,
    SupportComponent,
    AccountComponent,
    DashboardComponent,
    DetailPageContentComponent,
    ManagerDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonComponentsLoaderModule,
    CarouselModule
  ]
})
export class CommonInternComponentsLoaderModule { }
