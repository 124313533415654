<div class="row" *ngIf="(ls.locsLoaded | async) && (mpSettings.settingsLoaded | async)">
  <div class="grid col-large-4 margin-bottom-24">
    <h3>{{ ls.locs['loc'].Profil }}</h3>
    <mp-core-input class="margin-bottom-24 fallback-input display-block"
              label="{{ ls.locs['loc'].Benutzer }}"
              [(model)]="data['Benutzer']"
              mpId="benutzer"
              [mpRequired]="true"
              [mpDisabled]="notAdmin">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.Benutzer']" [model]="errors['data.Benutzer']"></mp-core-form-error>

    <mp-core-select class="margin-bottom-24 fallback-select display-block"
               label="{{ ls.locs['loc'].LKZ }}"
               mpId="land"
               [elements]="data['Laenderliste']"
               textProperty="Land"
               [model]="data['Land']"
               (modelChange)="data['Land'] = $event; updateSalutationList()"
               [mpRequired]="true"
               [mpDisabled]="cantEdit">
    </mp-core-select>
    <mp-core-form-error *ngIf="errors['data.Land']" [model]="errors['data.Land']"></mp-core-form-error>

    <mp-core-select class="margin-bottom-24 fallback-select display-block"
               label="{{ ls.locs['loc'].Anrede }}"
               mpId="anrede"
               [elements]="filteredSalutationList"
               textProperty="Anrede"
               [(model)]="data['AnredenObjekt']"
               [mpRequired]="true"
               [mpDisabled]="cantEdit">
    </mp-core-select>
    <mp-core-form-error *ngIf="errors['data.AnredenObjekt']" [model]="errors['data.AnredenObjekt']"></mp-core-form-error>

    <mp-core-input class="margin-bottom-24 fallback-input display-block"
              label="{{ ls.locs['loc'].Name }}"
              [(model)]="data['Name']"
              mpId="name"
              [mpRequired]="true"
              [mpDisabled]="cantEdit">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.Name']" [model]="errors['data.Name']"></mp-core-form-error>

    <mp-core-input class="margin-bottom-24 fallback-input display-block"
              label="{{ ls.locs['loc'].Vorname }}"
              [(model)]="data['Vorname']"
              mpId="vorname"
              [mpRequired]="true"
              [mpDisabled]="cantEdit">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.Vorname']" [model]="errors['data.Vorname']"></mp-core-form-error>

    <mp-core-input label="{{ ls.locs['loc'].Firma }}"
              [(model)]="data['Firma']"
              mpId="firma"
              [mpRequired]="true"
              [mpDisabled]="cantEdit"
              class="margin-bottom-24 fallback-input display-block">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.Firma']" [model]="errors['data.Firma']"></mp-core-form-error>

    <div *ngIf="mpSettings.settings['DisplaySettings'].ShowPersonalnummer">
      <mp-core-input class="fallback-input"
                label="{{ ls.locs['loc'].Personalnummer }}"
                [(model)]="data['PersonalNr']"
                mpId="personalnummer"
                [mpRequired]="true"
                [mpDisabled]="cantEdit">
      </mp-core-input>
      <mp-core-form-error *ngIf="errors['data.PersonalNr']" [model]="errors['data.PersonalNr']"></mp-core-form-error>
    </div>

  </div>
  <div class="grid col-large-4 margin-bottom-24" id="tn-cockpit-contact-section">
    <h3>{{ ls.locs['loc'].Kontakt }}</h3>
    <div class="display-flex flex-direction-row flex-wrap margin-bottom-24">
      <mp-core-input class="fallback-input"
                mpType="tel"
                label="{{ ls.locs['loc'].Telefon }}"
                [(model)]="data['Telefon_G']"
                mpId="telefon"
                [mpRequired]="canEdit"
                [mpDisabled]="cantEdit"
                [hasError]="errors['data.Telefon_G'] ? true : false">
      </mp-core-input>
      <span [hidden]="!data['Telefon_G']"
            class="setDefault"
            [ngClass]="{active: data['TelefonStd'] == 'TG' || data['TelefonStd'] == 'G'}">
        <i class="svg-icon __size-18 __top-8 __check-circle"
           data-val="TG"
           (click)="setTelephoneStd($event)"
           [ngClass]="data['TelefonStd'] == 'TG' || data['TelefonStd'] == 'G' ? '__clr-primary' : '__clr-default'">
        </i>
      </span>
      <mp-core-form-error *ngIf="errors['data.Telefon_G']" [model]="errors['data.Telefon_G']"></mp-core-form-error>
    </div>

      <div *ngIf="admin"
           class="display-flex flex-direction-row flex-wrap margin-bottom-24">
        <mp-core-input class="fallback-input"
                  mpType="tel"
                  label="{{ ls.locs['loc'].Telefon }} {{ ls.locs['loc'].Privat }}"
                  [(model)]="data['Telefon_P']"
                  mpId="telefon"
                  [mpDisabled]="cantEdit"
                  [hasError]="errors['data.Telefon_P'] ? true : false">
        </mp-core-input>
        <span [hidden]="!data['Telefon_P']"
              class="setDefault"
              [ngClass]="{active: data['TelefonStd'] == 'TP' || data['TelefonStd'] == 'P'}">
          <i class="svg-icon __size-18 __top-8 __check-circle"
             data-val="TP"
             (click)="setTelephoneStd($event)"
             [ngClass]="data['TelefonStd'] == 'TP' || data['TelefonStd'] == 'P' ? '__clr-primary' : '__clr-default'">
          </i>
        </span>
        <mp-core-form-error *ngIf="errors['data.Telefon_P']" [model]="errors['data.Telefon_P']"></mp-core-form-error>
      </div>
      <div *ngIf="admin" class="display-flex flex-direction-row flex-wrap margin-bottom-24">
        <mp-core-input class="fallback-input"
                  mpType="tel"
                  label="{{ ls.locs['loc'].Mobil }}"
                  [(model)]="data['Mobil_G']"
                  mpId="Mobil"
                  [mpDisabled]="cantEdit"
                  [hasError]="errors['data.Mobil_G'] ? true : false">
        </mp-core-input>
        <span [hidden]="!data['Mobil_G']"
              class="setDefault"
              [ngClass]="{active: data['TelefonStd'] == 'MG'}">
          <i class="svg-icon __size-18 __top-8 __check-circle"
             data-val="MG"
             (click)="setTelephoneStd($event)"
             [ngClass]="data['TelefonStd'] == 'MG' ? '__clr-primary' : '__clr-default'">
          </i>
        </span>
        <mp-core-form-error *ngIf="errors['data.Mobil_G']" [model]="errors['data.Mobil_G']"></mp-core-form-error>
      </div>
      <div *ngIf="admin"
           class="display-flex flex-direction-row flex-wrap margin-bottom-24">
        <mp-core-input class="fallback-input"
                  mpType="tel"
                  label="{{ ls.locs['loc'].Mobil }} {{ ls.locs['loc'].Privat }}"
                  [(model)]="data['Mobil_P']"
                  mpId="Mobil"
                  [mpDisabled]="cantEdit"
                  [hasError]="errors['data.Mobil_P'] ? true : false">
        </mp-core-input>
        <span [hidden]="!data['Mobil_P']"
              class="setDefault"
              [ngClass]="{active: data['TelefonStd'] == 'MP'}">
          <i class="svg-icon __size-18 __top-8 __check-circle"
             data-val="MP"
             (click)="setTelephoneStd($event)"
             [ngClass]="data['TelefonStd'] == 'MP' ? '__clr-primary' : '__clr-default'">
          </i>
        </span>
        <mp-core-form-error *ngIf="errors['data.Mobil_P']" [model]="errors['data.Mobil_P']"></mp-core-form-error>
      </div>

    <div class="display-flex flex-direction-row flex-wrap margin-bottom-24">
      <mp-core-input class="fallback-input"
                label="{{ ls.locs['loc'].EMail }}"
                [(model)]="data['Email_G']"
                mpId="email-g"
                [mpRequired]="canEdit"
                [mpDisabled]="cantEdit"
                [hasError]="errors['data.Email_G'] ? true : false">
      </mp-core-input>
      <span [hidden]="!data['Email_G']"
            class="setDefault"
            [ngClass]="{active: data['EmailStd'] == 'G'}">
        <i class="svg-icon __size-18 __top-8 __check-circle"
           data-val="G"
           (click)="setEmailStd($event)"
           [ngClass]="data['EmailStd'] == 'G' ? '__clr-primary' : '__clr-default'">
        </i>
      </span>
      <mp-core-form-error *ngIf="errors['data.Email_G']" [model]="errors['data.Email_G']"></mp-core-form-error>
    </div>
    <div *ngIf="admin" class="display-flex flex-direction-row flex-wrap margin-bottom-24">
      <mp-core-input class="fallback-input"
                label="{{ ls.locs['loc'].EMail }} {{ ls.locs['loc'].Privat }}"
                [(model)]="data['Email_P']"
                mpId="email-g"
                [mpDisabled]="cantEdit"
                [hasError]="errors['data.Email_P'] ? true : false">
      </mp-core-input>
      <span [hidden]="!data['Email_P']"
            class="setDefault"
            [ngClass]="{active: data['EmailStd'] == 'P'}">
        <i class="svg-icon __size-18 __top-8 __check-circle"
           data-val="P"
           (click)="setEmailStd($event)"
           [ngClass]="data['EmailStd'] == 'P' ? '__clr-primary' : '__clr-default'">
        </i>
      </span>
      <mp-core-form-error *ngIf="errors['data.Email_P']" [model]="errors['data.Email_P']"></mp-core-form-error>
    </div>
  </div>
  <div class="grid col-large-4 margin-bottom-24">
    <h3>{{ ls.locs['loc'].PortalNutzung }}</h3>
    <mp-core-select *ngIf="mpSettings.settings['DisplaySettings'].ShowGruppe"
               class="margin-bottom-24 fallback-select"
               label="{{ ls.locs['loc'].Gruppe }}"
               mpId="gruppe"
               [elements]="data['Gruppenliste']"
               textProperty="Gruppe"
               [(model)]="data['GruppenObjekt']"
               [mpRequired]="true"
               [mpDisabled]="notAdmin">
    </mp-core-select>
    <mp-core-form-error *ngIf="mpSettings.settings['DisplaySettings'].ShowGruppe && errors['data.GruppenObjekt']"
                   [model]="errors['data.GruppenObjekt']"></mp-core-form-error>

    <mp-core-select *ngIf="hasStatus" 
               class="margin-bottom-24 fallback-select"
               label="{{ ls.locs['loc'].Status }}"
               mpId="status"
               [elements]="data['Statusliste']"
               textProperty="Status"
               [(model)]="data['StatusObjekt']"
               [mpRequired]="true"
               [mpDisabled]="notAdmin">
    </mp-core-select>
    <mp-core-form-error *ngIf="hasStatus && errors['data.StatusObjekt']"
                   [model]="errors['data.StatusObjekt']"></mp-core-form-error>

    <mp-core-checkbox *ngIf="admin"
                 class="margin-bottom-24"
                 label="{{ ls.locs['loc'].Testuser }}"
                 mpId="chkTestuser"
                 [(model)]="data['Testuser']">
    </mp-core-checkbox>
    <mp-core-form-error *ngIf="admin && errors['data.Testuser']" [model]="errors['data.Testuser']"></mp-core-form-error>

    <mp-core-checkbox *ngIf="admin" class="margin-bottom-24"
                 label="{{ ls.locs['loc'].Aktiviert }}"
                 mpId="chkAktiviert"
                 [(model)]="data['Aktiviert']">
    </mp-core-checkbox>
    <mp-core-form-error *ngIf="admin && errors['data.Aktiviert']"
                   [model]="errors['data.Aktiviert']"></mp-core-form-error>

    <mp-core-checkbox class="margin-bottom-24"
                 mpId="chkTeilnahmeberechtigt"
                 [(model)]="data['Teilnahmeberechtigt']"
                 [mpDisabled]="cantEdit"
                 label="{{ ls.locs['loc'].Teilnahmeberechtigt }}">
    </mp-core-checkbox>
    <mp-core-form-error *ngIf="errors['data.Teilnahmeberechtigt']" [model]="errors['data.Teilnahmeberechtigt']"></mp-core-form-error>

    <mp-core-checkbox class="margin-bottom-24"
                 mpId="chkNewsletter"
                 [(model)]="data['Newsletter']"
                 [mpDisabled]="cantEdit"
                 label="{{ ls.locs['loc'].Newsletter }}">
    </mp-core-checkbox>
    <mp-core-form-error *ngIf="errors['data.Newsletter']" [model]="errors['data.Newsletter']"></mp-core-form-error>

    <mp-core-input class="margin-bottom-24 fallback-input"
              label="{{ ls.locs['loc'].Anlagedatum }}"
              [(model)]="data['Anlagedatum']"
              mpId="anlagedatum"
              [mpRequired]="false"
              [mpDisabled]="true">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.Anlagedatum']" [model]="errors['data.Anlagedatum']"></mp-core-form-error>

    <mp-core-input class="margin-bottom-24 fallback-input"
              label="{{ ls.locs['loc'].Erstanmeldung }}"
              [(model)]="data['Erstanmeldung']"
              mpId="erstanmeldung"
              [mpRequired]="false"
              [mpDisabled]="true">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.Erstanmeldung']" [model]="errors['data.Erstanmeldung']"></mp-core-form-error>

    <mp-core-input class="margin-bottom-24 fallback-input"
              label="{{ ls.locs['loc'].PWDGueltigBis }}"
              [(model)]="data['PWDGueltigBis']"
              mpId="pwdgueltigbis"
              [mpRequired]="canEdit"
              [mpDisabled]="cantEdit">
    </mp-core-input>
    <mp-core-form-error *ngIf="errors['data.PWDGueltigBis']" [model]="errors['data.PWDGueltigBis']"></mp-core-form-error>

    <h3 *ngIf="canSendLoginData">{{ ls.locs['loc'].ZugangsdatenMail }}</h3>
    <button *ngIf="canSendLoginData" class="mp-button _prim margin-left-8 margin-bottom-24"
            (click)="sendUsernameMail()">
      {{ ls.locs['loc'].BenutzernameMailSenden }}
    </button>
    <button *ngIf="canSendLoginData" class="mp-button _prim margin-left-8 margin-bottom-24"
            (click)="sendPwdForgotMail()">
      {{ ls.locs['loc'].PasswortMailSenden }}
    </button>

    <h3 *ngIf="mpSettings.settings['SmsSettings'].SendPwdVergessenAsSms && canSendLoginData">{{ ls.locs['loc'].SendZugangsdatenSms }}</h3>
    <button *ngIf="mpSettings.settings['SmsSettings'].SendPwdVergessenAsSms && canSendLoginData"
            class="mp-button margin-left-8"
            (click)="sendLoginDataSms()">
      {{ ls.locs['loc'].Senden }}
    </button>

    <h3 *ngIf="admin && mpSettings.settings['DisplaySettings'].ShowAnonymisieren">{{ ls.locs['loc'].Anonymisieren }}</h3>
    <button *ngIf="admin && mpSettings.settings['DisplaySettings'].ShowAnonymisieren"
            class="mp-button _prim margin-left-8 margin-bottom-24"
            (click)="anonymize()">
      {{ ls.locs['loc'].TeilnehmerAnonymisieren }}
    </button>
  </div>
</div>
