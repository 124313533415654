import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MpLocalizationService } from './../../../../services/mp-localization.service';
import { MpLoaderService } from './../../../../services/mp-loader.service';
import { MpMenuHighlightService } from './../../../../components/menu/mp-menu-highlight.service';
import { MpMessagingService } from './../../../../services/mp-messaging.service';
import { ApiService } from './../../../../services/api.service';

import { MpLoader } from './../../../../services/interfaces/mp-loader';

/**
 * This class provides the data and
 * functions for the grouping overview 
 * table.
 */
@Component({
  selector: 'mp-core-grouping-overview',
  templateUrl: './grouping-overview.component.html'
})
export class GroupingOverviewComponent implements OnInit, OnDestroy {

  public excelService: string = '/api/Gruppierung/Gruppierungsuebersicht2Excel';
  public loader: MpLoader | null = null;
  public recLoadedFunc = this._recLoadedFunc.bind(this);
  public execClickFunctions: Array<any> = [
    {
      backendName: 'toggleStatus',
      func: this.toggleStatus.bind(this)
    },
    {
      backendName: 'deleteGruppierung',
      func: this.deleteGrouping.bind(this)
    }
  ];

  private _managerGroupingOverviewLoader: MpLoader = {
    name: 'managerGroupingOverviewLoader',
    params: {}
  };
  private _toggleStatusSubscription: Subscription | undefined;
  private _deleteGRoupingSubscription: Subscription | undefined;

  constructor(
    public ls: MpLocalizationService,
    private _mpLoaderService: MpLoaderService,
    private _mpMenuHighlight: MpMenuHighlightService,
    private _mpMessaging: MpMessagingService,
    private _apiService: ApiService
  ) { }

  /**
   * Sets the menu highlighting.
   * Registers the loader.
   */
  ngOnInit(): void {
    this._mpMenuHighlight.setMenuHighlight('verwaltung');

    this._mpLoaderService.registerLoader(this._managerGroupingOverviewLoader);

    this._mpLoaderService.extendLoader('managerGroupingOverviewLoader', {
      isReady: true
    });
  }

  /**
   * Unsubscribes the set subscriptions.
   */
  ngOnDestroy(): void {
    if (typeof this._toggleStatusSubscription !== 'undefined') {
      this._toggleStatusSubscription.unsubscribe();
    }

    if (typeof this._deleteGRoupingSubscription !== 'undefined') {
      this._deleteGRoupingSubscription.unsubscribe();
    }
  }

  /**
   * Gets the final loader, after
   * the table data are loaded.
   */
  private _recLoadedFunc(rows: any, footerRows: any, header: any, error?: any): void {
    this.loader = this._mpLoaderService.getLoader('managerGroupingOverviewLoader');
  }

  /**
   * Loads the data for the table.
   */
  private _doData(): void {
    if (this.loader !== null) {
      if (typeof this.loader.load !== 'undefined') {
        this.loader.load();
      }
    }
  }

  /**
   * Toggles the status of the given
   * grouping.
   */
  toggleStatus(row: { [key: string]: any }): void {
    const data = {
      GroupingId: row['Id'],
      CurrentState: row['Aktiviert']
    };

    this._toggleStatusSubscription = this._apiService.postRequest('/api/Gruppierung/toggleGruppierung', data).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openPanelFromResultResponse(data);
        this._doData();
      }
    });
  }

  /**
   * Deletes the given grouping.
   */
  deleteGrouping(row: { [key: string]: any }): void {
    const data = {
      GroupingId: row['Id'],
      CurrentState: true
    };

    this._deleteGRoupingSubscription = this._apiService.postRequest('/api/Gruppierung/deleteGruppierung', data).subscribe((data: any) => {
      if (data.Result === 'OK') {
        this._mpMessaging.openPanelFromResultResponse(data);
        this._doData();
      }
    });
  }

}
